import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from "../../../../custom/loader/LoadingSpinner";
import ResultCircleProgress from "../../../../custom/result-circle-progress/ResultCircleProgress";
import Button from "../../../../custom/button/Button";
import useTransition from "../../../../customHooks/translations";
import useAxios from "../../../../../axiosinstance";
import Share from "../../../Share";
import {
  updateAdultKneeTotalScores,
  updateAdultKneeQolScore,
  updateAdultKneeSymptomsScore,
  updateAdultKneeScores,
  updateAdultKneePainScores,
  updateAdultKneeSatisfactionScore,
  updateAdultKneeFunctionScore,
  updateAdultKneeNorwichInstaScore,
  updateAdultKneeFeedback,
} from "../../../../../redux/actions/index";
import NameCustom from "../../../../custom/name/NameCustom";
import SubmitModel from "../../../SubmissionModel/SubmitModel"
function AdultKneeResult() {
  const translation = useTransition();
  const axiosinstance = useAxios();
  const { rId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const evlAdultKnee = useSelector(
    (state) => state.adultKneeScore.evlAdultKnee
  );
  const [totalScore] = useSelector((state) => state.adultKneeScore.TotalScores);
  const [age, setAge] = useState("0");
  const [dominant, setDominant] = useState("0");
  const [isdoctorform, setisdoctorform] = useState(Number());
  const [chk, setChk] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  let type = 5;
  const [initialAssesment, setInitialAssesment] = useState(0);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const continueRedirect2 = (e) => {
    setDisplayConfirmationModal(true)
    // if (isdoctorform === 1) {
    //   window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-list";
    // } else {
    //   window.location.href = process.env.REACT_APP_PATIENT_URL;
    // }
  };

  // const continueRedirect2 = (e) => {
  //   window.open(process.env.REACT_APP_PATIENT_URL, "_blank");

  // };


  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=5`)
      .then((res) => {
        setInitialAssesment(res.data.data2[0].initial_assessment);
        setAge(res.data.data2[0].age);
        setDominant(res.data.data2[0].dominant);
        dispatch(updateAdultKneePainScores(res.data.data2[0].painScore));
        dispatch(updateAdultKneeSymptomsScore(res.data.data2[0].symptomsScore));
        dispatch(updateAdultKneeScores(res.data.data2[0].evlAdultKnee));
        dispatch(updateAdultKneeQolScore(res.data.data2[0].qolScore));
        dispatch(updateAdultKneeFunctionScore(res.data.data2[0].functionScore));
        dispatch(
          updateAdultKneeNorwichInstaScore(res.data.data2[0].norwichInstability)
        );
        dispatch(
          updateAdultKneeSatisfactionScore(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateAdultKneeFeedback(res.data.data2[0].doctorFeedback));
        dispatch(updateAdultKneeFeedback(res.data.data2[0].doctorFeedback));
        dispatch(updateAdultKneeTotalScores(res.data.data2[0].TotalScores));
        setisdoctorform(res.data.data2[0].is_doctor_form);
        setStatus(res.data.data2[0].status);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };

  useEffect(() => {
    save();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  // console.log(evlAdultKnee, "evlAdultKnee");
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="result_screen mb_100">
          <div className="row justify-content-center">
            <div className="col-xxl-8 col-lg-10 col-md-12 col-sm-12">
              <div className="panel">
                <div className="panel-body p_25">
                  <div className="row">
                    <div className="col-xxl-8 col-lg-7 col-md-6 col-sm-6">
                      <h3 className="text-start f_22 ">
                        {translation.patient_adult_knee_results}
                      </h3>
                      <p className="answer_txt">
                        {translation.patient_adult_knee_results_text1}
                      </p>
                    </div>
                    <div className="col-xxl-4 col-lg-5 col-md-6 col-sm-6">
                      <NameCustom />
                    </div>
                  </div>

                  <div className="row">
                    {status === "1" ? (
                      <div className="pb_50 text-center">
                       
                        <Button
                          onClick={continueRedirect2}
                          value={translation.Sign_out}
                          buttonStyle="btn_fill result_submit_btn"
                        />

                      </div>
                    ) : status === "2" ? (

                      <div className="pb_50 text-center">
                       
                        <Share type={type} rId={rId} />
                      </div>
                    ) : null}
                  </div>

                  <div className="row result_scorebox">
                    {String(age) === "1" && (
                      <>
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.patient_adult_knee_results_function}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.ikdcScoreL}
                              total="100"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee == "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.patient_adult_knee_results_function}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.ikdcScoreR}
                              total="100"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.KOOS_Pain}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.koosPainScoreR}
                              total="100"
                              date={new Date().toDateString()}
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.KOOS_Pain}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.koosPainScoreL}
                              total="100"
                              date={new Date().toDateString()}
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.KOOS_Symptoms}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.koosSymptomsScoreR}
                              total="100"
                              date={new Date().toDateString()}
                              color="#78b776"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.KOOS_Symptoms}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.koosSymptomsScoreL}
                              total="100"
                              date={new Date().toDateString()}
                              color="#78b776"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.Quality_of_Life}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.qolScoreR}
                              total="100"
                              date={new Date().toDateString()}
                              color="#78b776"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.Quality_of_Life}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.qolScoreL}
                              total="100"
                              date={new Date().toDateString()}
                              color="#78b776"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              ircleProgress
                              title={translation.KOOS_ADL}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.koosAdlScoreR}
                              total="100"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.KOOS_ADL}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.koosAdlScoreL}
                              total="100"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.KOOS_Sports}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.koosSportsScoreR}
                              total="100"
                              date="26/10/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.KOOS_Sports}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.koosSportsScoreL}
                              total="100"
                              date="26/10/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.patient_adult_knee_results_pain}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              persent={totalScore.VasScoreL * 10}
                              value={totalScore.VasScoreL}
                              total="10"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.patient_adult_knee_results_pain}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              persent={totalScore.VasScoreR * 10}
                              value={totalScore.VasScoreR}
                              total="10"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.LYSHOLM}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.lysholmScoreR}
                              total="100"
                              date="26/10/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.LYSHOLM}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.lysholmScoreL}
                              total="100"
                              date="26/10/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.SANE}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.SANE_R}
                              total="100"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.SANE}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.SANE_L}
                              total="100"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}


                        <div className="d-none">
                          {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                              <ResultCircleProgress
                                min_score={0}
                                reverse={false}
                                title={translation.SAPS_OVERALL}
                                type={translation.patient_adult_knee_results_self_evaluation}
                                arm={translation.Right_Knee}
                                value={totalScore.SAPS_OVERALLR}
                                total="100"
                                date={new Date().toDateString()}
                                color="#006DFF"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                              <ResultCircleProgress
                                min_score={0}
                                reverse={false}
                                title={translation.SAPS_OVERALL}
                                type={translation.patient_adult_knee_results_self_evaluation}
                                arm={translation.Left_Knee}
                                value={totalScore.SAPS_OVERALLL}
                                total="100"
                                date={new Date().toDateString()}
                                color="#006DFF"
                              />
                            </div>
                          ) : (
                            ""
                          )}

                          {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                              <ResultCircleProgress
                                min_score={0}
                                reverse={false}
                                title={translation.SAPS_PAIN}
                                type={translation.patient_adult_knee_results_self_evaluation}
                                arm={translation.Right_Knee}
                                value={totalScore.SAPS_PAINR}
                                total="100"
                                date={new Date().toDateString()}
                                color="#006DFF"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                              <ResultCircleProgress
                                min_score={0}
                                reverse={false}
                                title={translation.SAPS_PAIN}
                                type={translation.patient_adult_knee_results_self_evaluation}
                                arm={translation.Left_Knee}
                                value={totalScore.SAPS_PAINL}
                                total="100"
                                date={new Date().toDateString()}
                                color="#006DFF"
                              />
                            </div>
                          ) : (
                            ""
                          )}

                          {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                              <ResultCircleProgress
                                min_score={0}
                                reverse={false}
                                title={translation.SAPS_WORK}
                                type={translation.patient_adult_knee_results_self_evaluation}
                                arm={translation.Right_Knee}
                                value={totalScore.SAPS_WORKR}
                                total="100"
                                date={new Date().toDateString()}
                                color="#006DFF"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                              <ResultCircleProgress
                                min_score={0}
                                reverse={false}
                                title={translation.SAPS_WORK}
                                type={translation.patient_adult_knee_results_self_evaluation}
                                arm={translation.Left_Knee}
                                value={totalScore.SAPS_WORKL}
                                total="100"
                                date={new Date().toDateString()}
                                color="#006DFF"
                              />
                            </div>
                          ) : (
                            ""
                          )}

                          {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                              <ResultCircleProgress
                                min_score={0}
                                reverse={false}
                                title={translation.SAPS_RECREATION}
                                type={translation.patient_adult_knee_results_self_evaluation}
                                arm={translation.Right_Knee}
                                value={totalScore.SAPS_RECREATIONR}
                                total="100"
                                date={new Date().toDateString()}
                                color="#006DFF"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                              <ResultCircleProgress
                                min_score={0}
                                reverse={false}
                                title={translation.SAPS_RECREATION}
                                type={translation.patient_adult_knee_results_self_evaluation}
                                arm={translation.Left_Knee}
                                value={totalScore.SAPS_RECREATIONL}
                                total="100"
                                date={new Date().toDateString()}
                                color="#006DFF"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                      </>
                    )}
                  </div>

                  <div className="row result_scorebox">
                    {String(age) === "2" && dominant === "0" && (
                      <>
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.patient_adult_knee_results_function}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.ikdcScoreL}
                              total="100"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee == "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.patient_adult_knee_results_function}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.ikdcScoreR}
                              total="100"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}


                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none ">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.KOOS_Pain}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.koosPainScoreR}
                              total="100"
                              date={new Date().toDateString()}
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.KOOS_Pain}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.koosPainScoreL}
                              total="100"
                              date={new Date().toDateString()}
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.KOOS_Symptoms}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.koosSymptomsScoreR}
                              total="100"
                              date={new Date().toDateString()}
                              color="#78b776"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.KOOS_Symptoms}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.koosSymptomsScoreL}
                              total="100"
                              date={new Date().toDateString()}
                              color="#78b776"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.KOOS_ADL}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.koosAdlScoreR}
                              total="100"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.KOOS_ADL}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.koosAdlScoreL}
                              total="100"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.Quality_of_Life}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.qolScoreR}
                              total="100"
                              date={new Date().toDateString()}
                              color="#78b776"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.Quality_of_Life}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.qolScoreL}
                              total="100"
                              date={new Date().toDateString()}
                              color="#78b776"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.KOOS_Sports}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.koosSportsScoreR}
                              total="100"
                              date="26/10/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.KOOS_Sports}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.koosSportsScoreL}
                              total="100"
                              date="26/10/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.CKRS_Score}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.ckrsScoreR}
                              total="100"
                              date="26/10/2022"
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.CKRS_Score}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.ckrsScoreL}
                              total="100"
                              date="26/10/2022"
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.LYSHOLM}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.lysholmScoreR}
                              total="100"
                              date="26/10/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.LYSHOLM}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.lysholmScoreL}
                              total="100"
                              date="26/10/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.SANE_}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.SANE_R}
                              total="100"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.SANE_}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.SANE_L}
                              total="100"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 ">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.patient_adult_knee_results_pain}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              persent={totalScore.VasScoreL * 10}
                              value={totalScore.VasScoreL}
                              total="10"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 ">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.patient_adult_knee_results_pain}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              persent={totalScore.VasScoreR * 10}
                              value={totalScore.VasScoreR}
                              total="10"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}


                        {/* {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                                                <ResultCircleProgress min_score={0}  reverse={false} title={translation.KSS} type={translation.patient_adult_knee_results_self_evaluation} arm={translation.Right_Knee} value={totalScore.kssScoreR} total="25" date="26/10/2022" color="#ff0000" />
                                            </div>
                                        ) : ""}
                                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                                                <ResultCircleProgress min_score={0}  reverse={false} title={translation.KSS} type={translation.patient_adult_knee_results_self_evaluation} arm={translation.Left_Knee} value={totalScore.kssScoreL} total="25" date="26/10/2022" color="#ff0000" />
                                            </div>
                                        ) : ""} */}

                        {/* <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                      <ResultCircleProgress
                        min_score={0}
                        reverse={false}
                        title={translation.patient_adult_knee_results_health_perception}
                        type={translation.patient_adult_knee_results_self_evaluation}
                        // arm={translation.Knee}
                        value={totalScore.EQ_VAS}
                        total="100"
                        date={new Date().toDateString()}
                        color="#006DFF"
                      />
                    </div> */}

                        {/* {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                                                <ResultCircleProgress min_score={0}  reverse={false} title={translation.patient_adult_knee_results_health_perception} type={translation.patient_adult_knee_results_self_evaluation} arm={translation.Left_Knee} value={totalScore.EQ_VASL} total="10" date={new Date().toDateString()} color="#006DFF" />
                                            </div>
                                        ) : ""} */}
                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={1}
                              reverse={true}
                              title={translation.EQ_MOBILITY}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              hide={"1"}
                              persent={totalScore.EQMOBILITYR * 20}
                              value={`Level ${totalScore.EQMOBILITYR}`}
                              total="5"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={1}
                              reverse={true}
                              title={translation.EQ_MOBILITY}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              hide={"1"}
                              persent={totalScore.EQMOBILITYL * 20}
                              value={`Level ${totalScore.EQMOBILITYL}`}
                              total="5"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={1}
                              reverse={true}
                              title={translation.EQ_CARE}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              hide={"1"}
                              persent={totalScore.EQCARER * 20}
                              value={`Level ${totalScore.EQCARER}`}
                              total="5"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={1}
                              reverse={true}
                              title={translation.EQ_CARE}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              hide={"1"}
                              persent={totalScore.EQCAREL * 20}
                              value={`Level ${totalScore.EQCAREL}`}
                              total="5"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={1}
                              reverse={true}
                              title={translation.EQ_ACTIVITIES}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              hide={"1"}
                              persent={totalScore.EQACTIVITIESR * 20}
                              value={`Level ${totalScore.EQACTIVITIESR}`}
                              total="5"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={1}
                              reverse={true}
                              title={translation.EQ_ACTIVITIES}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              hide={"1"}
                              persent={totalScore.EQACTIVITIESL * 20}
                              value={`Level ${totalScore.EQACTIVITIESL}`}
                              total="5"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={1}
                              reverse={true}
                              title={translation.EQ_DISCOMFORT}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              hide={"1"}
                              persent={totalScore.EQDISCOMFORTR * 20}
                              value={`Level ${totalScore.EQDISCOMFORTR}`}
                              total="5"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={1}
                              reverse={true}
                              title={translation.EQ_DISCOMFORT}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              hide={"1"}
                              persent={totalScore.EQDISCOMFORTL * 20}
                              value={`Level ${totalScore.EQDISCOMFORTL}`}
                              total="5"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                          <ResultCircleProgress
                            min_score={1}
                            reverse={true}
                            title={translation.EQ_ANXIETY}
                            type={translation.patient_adult_knee_results_self_evaluation}
                            arm={translation.Knee}
                            hide={"1"}
                            persent={totalScore.EQANXIETYR * 20}
                            value={`Level ${totalScore.EQANXIETYR}`}
                            total="5"
                            date={new Date().toDateString()}
                            color="#006DFF"
                          />
                        </div>
                        {/* <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.Modified_Tegner} type={translation.patient_adult_knee_results_self_evaluation} arm="" value="" total="10" date={new Date().toDateString()} color="#006DFF" />
                                        </div> */}

                        <div className="d-none">
                          {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                              <ResultCircleProgress
                                min_score={0}
                                reverse={false}
                                title={translation.SAPS_OVERALL}
                                type={translation.patient_adult_knee_results_self_evaluation}
                                arm={translation.Right_Knee}
                                value={totalScore.SAPS_OVERALLR}
                                total="100"
                                date={new Date().toDateString()}
                                color="#006DFF"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                              <ResultCircleProgress
                                min_score={0}
                                reverse={false}
                                title={translation.SAPS_OVERALL}
                                type={translation.patient_adult_knee_results_self_evaluation}
                                arm={translation.Left_Knee}
                                value={totalScore.SAPS_OVERALLL}
                                total="100"
                                date={new Date().toDateString()}
                                color="#006DFF"
                              />
                            </div>
                          ) : (
                            ""
                          )}

                          {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                              <ResultCircleProgress
                                min_score={0}
                                reverse={false}
                                title={translation.SAPS_PAIN}
                                type={translation.patient_adult_knee_results_self_evaluation}
                                arm={translation.Right_Knee}
                                value={totalScore.SAPS_PAINR}
                                total="100"
                                date={new Date().toDateString()}
                                color="#006DFF"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                              <ResultCircleProgress
                                min_score={0}
                                reverse={false}
                                title={translation.SAPS_PAIN}
                                type={translation.patient_adult_knee_results_self_evaluation}
                                arm={translation.Left_Knee}
                                value={totalScore.SAPS_PAINL}
                                total="100"
                                date={new Date().toDateString()}
                                color="#006DFF"
                              />
                            </div>
                          ) : (
                            ""
                          )}

                          {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                              <ResultCircleProgress
                                min_score={0}
                                reverse={false}
                                title={translation.SAPS_WORK}
                                type={translation.patient_adult_knee_results_self_evaluation}
                                arm={translation.Right_Knee}
                                value={totalScore.SAPS_WORKR}
                                total="100"
                                date={new Date().toDateString()}
                                color="#006DFF"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                              <ResultCircleProgress
                                min_score={0}
                                reverse={false}
                                title={translation.SAPS_WORK}
                                type={translation.patient_adult_knee_results_self_evaluation}
                                arm={translation.Left_Knee}
                                value={totalScore.SAPS_WORKL}
                                total="100"
                                date={new Date().toDateString()}
                                color="#006DFF"
                              />
                            </div>
                          ) : (
                            ""
                          )}

                          {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                              <ResultCircleProgress
                                min_score={0}
                                reverse={false}
                                title={translation.SAPS_RECREATION}
                                type={translation.patient_adult_knee_results_self_evaluation}
                                arm={translation.Right_Knee}
                                value={totalScore.SAPS_RECREATIONR}
                                total="100"
                                date={new Date().toDateString()}
                                color="#006DFF"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                              <ResultCircleProgress
                                min_score={0}
                                reverse={false}
                                title={translation.SAPS_RECREATION}
                                type={translation.patient_adult_knee_results_self_evaluation}
                                arm={translation.Left_Knee}
                                value={totalScore.SAPS_RECREATIONL}
                                total="100"
                                date={new Date().toDateString()}
                                color="#006DFF"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                      </>
                    )}
                  </div>

                  <div className="row result_scorebox">
                    {String(age) === "2" && dominant === "1" && (
                      <>
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.patient_adult_knee_results_function}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.ikdcScoreL}
                              total="100"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee == "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.patient_adult_knee_results_function}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.ikdcScoreR}
                              total="100"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.KOOS_Pain}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.koosPainScoreR}
                              total="100"
                              date={new Date().toDateString()}
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.KOOS_Pain}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.koosPainScoreL}
                              total="100"
                              date={new Date().toDateString()}
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.KOOS_Symptoms}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.koosSymptomsScoreR}
                              total="100"
                              date={new Date().toDateString()}
                              color="#78b776"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.KOOS_Symptoms}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.koosSymptomsScoreL}
                              total="100"
                              date={new Date().toDateString()}
                              color="#78b776"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.KOOS_ADL}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.koosAdlScoreR}
                              total="100"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.KOOS_ADL}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.koosAdlScoreL}
                              total="100"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.KOOS_Sports}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.koosSportsScoreR}
                              total="100"
                              date="26/10/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.KOOS_Sports}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.koosSportsScoreL}
                              total="100"
                              date="26/10/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.Quality_of_Life}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.qolScoreR}
                              total="100"
                              date={new Date().toDateString()}
                              color="#78b776"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.Quality_of_Life}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.qolScoreL}
                              total="100"
                              date={new Date().toDateString()}
                              color="#78b776"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.CKRS_Score}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.ckrsScoreR}
                              total="100"
                              date="26/10/2022"
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.CKRS_Score}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.ckrsScoreL}
                              total="100"
                              date="26/10/2022"
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.LYSHOLM}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.lysholmScoreR}
                              total="100"
                              date="26/10/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.LYSHOLM}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.lysholmScoreL}
                              total="100"
                              date="26/10/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.SANE_}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.SANE_R}
                              total="100"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.SANE_}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.SANE_L}
                              total="100"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 ">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.patient_adult_knee_results_pain}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              persent={totalScore.VasScoreL * 10}
                              value={totalScore.VasScoreL}
                              total="10"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 ">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.patient_adult_knee_results_pain}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              persent={totalScore.VasScoreR * 10}
                              value={totalScore.VasScoreR}
                              total="10"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}


                        {/* {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                                                <ResultCircleProgress min_score={0}  reverse={false} title={translation.KSS} type={translation.patient_adult_knee_results_self_evaluation} arm={translation.Right_Knee} value={totalScore.kssScoreR} total="25" date="26/10/2022" color="#ff0000" />
                                            </div>
                                        ) : ""}
                                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                                                <ResultCircleProgress min_score={0}  reverse={false} title={translation.KSS} type={translation.patient_adult_knee_results_self_evaluation} arm={translation.Left_Knee} value={totalScore.kssScoreL} total="25" date="26/10/2022" color="#ff0000" />
                                            </div>
                                        ) : ""} */}
                        {/* <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                      <ResultCircleProgress
                        min_score={0}
                        reverse={false}
                        title={translation.patient_adult_knee_results_health_perception}
                        type={translation.patient_adult_knee_results_self_evaluation}
                        // arm={translation.Knee}
                        value={totalScore.EQ_VAS}
                        total="100"
                        date={new Date().toDateString()}
                        color="#006DFF"
                      />
                    </div> */}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={1}
                              reverse={true}
                              title={translation.EQ_MOBILITY}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              hide={"1"}
                              persent={totalScore.EQMOBILITYR * 20}
                              value={`Level ${totalScore.EQMOBILITYR}`}
                              total="5"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={1}
                              reverse={true}
                              title={translation.EQ_MOBILITY}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              hide={"1"}
                              persent={totalScore.EQMOBILITYL * 20}
                              value={`Level ${totalScore.EQMOBILITYL}`}
                              total="5"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={1}
                              reverse={true}
                              title={translation.EQ_CARE}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              hide={"1"}
                              persent={totalScore.EQCARER * 20}
                              value={`Level ${totalScore.EQCARER}`}
                              total="5"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={1}
                              reverse={true}
                              title={translation.EQ_CARE}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              hide={"1"}
                              persent={totalScore.EQCAREL * 20}
                              value={`Level ${totalScore.EQCAREL}`}
                              total="5"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={1}
                              reverse={true}
                              title={translation.EQ_ACTIVITIES}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              hide={"1"}
                              persent={totalScore.EQACTIVITIESR * 20}
                              value={`Level ${totalScore.EQACTIVITIESR}`}
                              total="5"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={1}
                              reverse={true}
                              title={translation.EQ_ACTIVITIES}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              hide={"1"}
                              persent={totalScore.EQACTIVITIESL * 20}
                              value={`Level ${totalScore.EQACTIVITIESL}`}
                              total="5"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={1}
                              reverse={true}
                              title={translation.EQ_DISCOMFORT}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              hide={"1"}
                              persent={totalScore.EQDISCOMFORTR * 20}
                              value={`Level ${totalScore.EQDISCOMFORTR}`}
                              total="5"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={1}
                              reverse={true}
                              title={translation.EQ_DISCOMFORT}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              hide={"1"}
                              persent={totalScore.EQDISCOMFORTL * 20}
                              value={`Level ${totalScore.EQDISCOMFORTL}`}
                              total="5"
                              date={new Date().toDateString()}
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                          <ResultCircleProgress
                            min_score={1}
                            reverse={true}
                            title={translation.EQ_ANXIETY}
                            type={translation.patient_adult_knee_results_self_evaluation}
                            arm={translation.Knee}
                            hide={"1"}
                            persent={totalScore.EQANXIETYR * 20}
                            value={`Level ${totalScore.EQANXIETYR}`}
                            total="5"
                            date={new Date().toDateString()}
                            color="#006DFF"
                          />
                        </div>
                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.OKS_Pain}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.oksPainScoreR}
                              total="100"
                              date="26/10/2022"
                              color="#78b776"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.OKS_Pain}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.oksPainScoreL}
                              total="100"
                              date="26/10/2022"
                              color="#78b776"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.OKS_Function}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.oksFuncScoreR}
                              total="0"
                              date="26/10/2022"
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.OKS_Function}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.oksFuncScoreL}
                              total="20"
                              date="26/10/2022"
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.OKS_overall}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.oksOverallR}
                              total="0"
                              date="26/10/2022"
                              color="#78b776"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.OKS_overall}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.oksOverallL}
                              total="20"
                              date="26/10/2022"
                              color="#78b776"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.WOMAC_Pain}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.womacPainScoreR}
                              total="100"
                              date="26/10/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.WOMAC_Pain}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.womacPainScoreL}
                              total="100"
                              date="26/10/2022"
                              color="#ff0000"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.WOMAC_Stiffness}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.womacStiffScoreR}
                              total="100"
                              date="26/10/2022"
                              color="#78b776"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.WOMAC_Stiffness}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.womacStiffScoreL}
                              total="100"
                              date="26/10/2022"
                              color="#78b776"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.WOMAC_Function}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Right_Knee}
                              value={totalScore.womacFuncScoreR}
                              total="100"
                              date="26/10/2022"
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                            <ResultCircleProgress
                              min_score={0}
                              reverse={false}
                              title={translation.WOMAC_Function}
                              type={translation.patient_adult_knee_results_self_evaluation}
                              arm={translation.Left_Knee}
                              value={totalScore.womacFuncScoreL}
                              total="100"
                              date="26/10/2022"
                              color="#006DFF"
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {/* <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.Modified_Tegner} type={translation.patient_adult_knee_results_self_evaluation} arm="" value="" total="10" date={new Date().toDateString()} color="#006DFF" />
                                        </div> */}


                        <div className="d-none">
                          <div className="row">
                            {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                              <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                <ResultCircleProgress
                                  min_score={0}
                                  reverse={false}
                                  title={translation.SAPS_OVERALL}
                                  type={translation.patient_adult_knee_results_self_evaluation}
                                  arm={translation.Right_Knee}
                                  value={totalScore.SAPS_OVERALLR}
                                  total="100"
                                  date={new Date().toDateString()}
                                  color="#006DFF"
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                              <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                <ResultCircleProgress
                                  min_score={0}
                                  reverse={false}
                                  title={translation.SAPS_OVERALL}
                                  type={translation.patient_adult_knee_results_self_evaluation}
                                  arm={translation.Left_Knee}
                                  value={totalScore.SAPS_OVERALLL}
                                  total="100"
                                  date={new Date().toDateString()}
                                  color="#006DFF"
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                              <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                <ResultCircleProgress
                                  min_score={0}
                                  reverse={false}
                                  title={translation.SAPS_PAIN}
                                  type={translation.patient_adult_knee_results_self_evaluation}
                                  arm={translation.Right_Knee}
                                  value={totalScore.SAPS_PAINR}
                                  total="100"
                                  date={new Date().toDateString()}
                                  color="#006DFF"
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                              <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                <ResultCircleProgress
                                  min_score={0}
                                  reverse={false}
                                  title={translation.SAPS_PAIN}
                                  type={translation.patient_adult_knee_results_self_evaluation}
                                  arm={translation.Left_Knee}
                                  value={totalScore.SAPS_PAINL}
                                  total="100"
                                  date={new Date().toDateString()}
                                  color="#006DFF"
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                              <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                <ResultCircleProgress
                                  min_score={0}
                                  reverse={false}
                                  title={translation.SAPS_WORK}
                                  type={translation.patient_adult_knee_results_self_evaluation}
                                  arm={translation.Right_Knee}
                                  value={totalScore.SAPS_WORKR}
                                  total="100"
                                  date={new Date().toDateString()}
                                  color="#006DFF"
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                              <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                <ResultCircleProgress
                                  min_score={0}
                                  reverse={false}
                                  title={translation.SAPS_WORK}
                                  type={translation.patient_adult_knee_results_self_evaluation}
                                  arm={translation.Left_Knee}
                                  value={totalScore.SAPS_WORKL}
                                  total="100"
                                  date={new Date().toDateString()}
                                  color="#006DFF"
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                              <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                <ResultCircleProgress
                                  min_score={0}
                                  reverse={false}
                                  title={translation.SAPS_RECREATION}
                                  type={translation.patient_adult_knee_results_self_evaluation}
                                  arm={translation.Right_Knee}
                                  value={totalScore.SAPS_RECREATIONR}
                                  total="100"
                                  date={new Date().toDateString()}
                                  color="#006DFF"
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                              <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                <ResultCircleProgress
                                  min_score={0}
                                  reverse={false}
                                  title={translation.SAPS_RECREATION}
                                  type={translation.patient_adult_knee_results_self_evaluation}
                                  arm={translation.Left_Knee}
                                  value={totalScore.SAPS_RECREATIONL}
                                  total="100"
                                  date={new Date().toDateString()}
                                  color="#006DFF"
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>{" "}
                        </div>

                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      )}
      <SubmitModel
        showModal={displayConfirmationModal} patientID={window.btoa(sessionStorage.getItem("patient_akid"))}

      />
    </>
  );
}

export default AdultKneeResult;
