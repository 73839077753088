import React, { useState, useEffect } from "react";
import AssessmentHeader from "../../../custom/assessment-header/AssessmentHeader";
import HeaderInnerBreadcrumb from "../../../custom/heade-inner-breadcrumb/HeaderInnerBreadcrumb";
import RadioSwitch from "../../../custom/radio-switch/RadioSwitch";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateHipFeedback } from "../../../../redux/actions/hipAction";
import useAxios from "../../../../axiosinstance";
import useTranslation from "../../../customHooks/doctorTranslation";
import HipTab from "../../tab-section/HipTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import InputField from "../../../custom/inputfield/InputField";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import SubmitModel from "../../SubmissionModel/SubmitModel"
import {
  updateHipScores,
  updateHipPainFieldScore,
  updateHIPPainScores,
  updateHIPSymptomsScores,
  updateHIPfunctionScores,
  updateHIPQOLScores,
  updateHIPSatisfactionScores,
  updateHipFeedbackAll,
  updateAllData,
} from "../../../../redux/actions/hipAction";
import DoctorNameCustom from "../../../custom/name/DoctorNameCustom";
import Promsschudle from "../../SubmissionModel/Promsschudle";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";

export default function Doctorefeedback2() {
  const translation = useTranslation();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const queryParams = new URLSearchParams(window.location.search);
  const dId = queryParams.get("doctor");
  const pId = queryParams.get("patient");
  const axiosinstance = useAxios();
  const json = useSelector((state) => state.hipScoreReducer.doctorFeedback);
  const navigate = useNavigate();
  const docFeedback = useSelector(
    (state) => state.hipScoreReducer.doctorFeedback[0]
  );

  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=6`)
      .then((res) => {
        // console.log(res.data.data2);
        dispatch(updateHIPPainScores(res.data.data2[0].painScore));
        dispatch(updateHIPSymptomsScores(res.data.data2[0].symptomsScore));
        dispatch(updateHipScores(res.data.data2[0].evlHipPain));
        dispatch(updateHIPfunctionScores(res.data.data2[0].functionScore));
        dispatch(updateHIPQOLScores(res.data.data2[0].qolScore));
        dispatch(
          updateHIPSatisfactionScores(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateHipFeedbackAll(res.data.data2[0].doctorFeedback));
        setChk(true);
      });
  };

  useEffect(() => {
    save();
  }, []);

  const dispatch = useDispatch();
  const [chk, setChk] = useState(false);
  const [greaterRightVal, setGreaterRightVal] = useState("");
  const [greaterLeftVal, setGreaterLeftVal] = useState("");
  const [inguinalRightVal, setInguinalRightVal] = useState("");
  const [inguinalLeftVal, setInguinalLeftVal] = useState("");
  const [LogRightVal, setLogRightVal] = useState("");
  const [LogLeftVal, setLogLeftVal] = useState("");
  const [GaleazziRightVal, setGaleazziRightVal] = useState("");
  const [GaleazziLeftVal, setGaleazziLeftVal] = useState("");
  const [TrendelenburgRightVal, setTrendelenburgRightVal] = useState("");
  const [TrendelenburgLeftVal, setTrendelenburgLeftVal] = useState("");
  const [ThomasRightVal, setThomasRightVal] = useState("");
  const [ThomasLeftVal, setThomasLeftVal] = useState("");
  const [FADIRRightVal, setFADIRRightVal] = useState("");
  const [FADIRLeftVal, setFADIRLeftVal] = useState("");
  const [PatrickRightVal, setPatrickRightVal] = useState("");
  const [PatrickLeftVal, setPatrickLeftVal] = useState("");
  const [OberRightVal, setOberRightVal] = useState("");
  const [OberLeftVal, setOberLeftVal] = useState("");
  const [StraightRightVal, setStraightRightVal] = useState("");
  const [StraightLeftVal, setStraightLeftVal] = useState("");
  const [BarlowRightVal, setBarlowRightVal] = useState("");
  const [BarlowLeftVal, setBarlowLeftVal] = useState("");
  const [OrtolaniRightVal, setOrtolaniRightVal] = useState("");
  const [OrtolaniLeftVal, setOrtolaniLeftVal] = useState("");
  const [AsymmetricRightVal, setAsymmetricRightVal] = useState("");
  const [asymmetricR, seteasymmetricR] = useState("");
  const [asymmetricOtherR, setasymmetricOtherR] = useState("");
  const [asymmetricOtherL, setasymmetricOtherL] = useState("");
  const [AsymmetricLeftVal, setAsymmetricLeftVal] = useState("");
  const [asymmetricL, seteasymmetricL] = useState("");
  const [others_r, setOthers_r] = useState("");
  const [others_l, setOthers_l] = useState("");
  const [others2_r, setOthers2_r] = useState("");
  const [others2_l, setOthers2_l] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setGreaterRightVal(docFeedback.greaterRightVal);
    setGreaterLeftVal(docFeedback.greaterLeftVal);
    setInguinalRightVal(docFeedback.inguinalRightVal);
    setInguinalLeftVal(docFeedback.inguinalLeftVal);
    setLogRightVal(docFeedback.LogRightVal);
    setLogLeftVal(docFeedback.LogLeftVal);
    setGaleazziRightVal(docFeedback.GaleazziRightVal);
    setGaleazziLeftVal(docFeedback.GaleazziLeftVal);
    setTrendelenburgRightVal(docFeedback.TrendelenburgRightVal);
    setTrendelenburgLeftVal(docFeedback.TrendelenburgLeftVal);
    setThomasRightVal(docFeedback.ThomasRightVal);
    setThomasLeftVal(docFeedback.ThomasLeftVal);
    setFADIRRightVal(docFeedback.FADIRRightVal);
    setFADIRLeftVal(docFeedback.FADIRLeftVal);
    setPatrickRightVal(docFeedback.PatrickRightVal);
    setPatrickLeftVal(docFeedback.PatrickLeftVal);
    setOberRightVal(docFeedback.OberRightVal);
    setOberLeftVal(docFeedback.OberLeftVal);
    setStraightRightVal(docFeedback.StraightRightVal);
    setStraightLeftVal(docFeedback.StraightLeftVal);
    setBarlowRightVal(docFeedback.BarlowRightVal);
    setBarlowLeftVal(docFeedback.BarlowLeftVal);
    setOrtolaniRightVal(docFeedback.OrtolaniRightVal);
    setOrtolaniLeftVal(docFeedback.OrtolaniLeftVal);
    setAsymmetricRightVal(docFeedback.AsymmetricRightVal);
    setAsymmetricLeftVal(docFeedback.AsymmetricLeftVal);
    seteasymmetricR(docFeedback.asymmetricR);
    seteasymmetricL(docFeedback.asymmetricL);
    setasymmetricOtherR(docFeedback.asymmetricOtherR);
    setasymmetricOtherL(docFeedback.asymmetricOtherL);
    setOthers_r(docFeedback.others_r);
    setOthers_l(docFeedback.others_l);
    setOthers2_r(docFeedback.others2_r);
    setOthers2_l(docFeedback.others2_l);
  }, [docFeedback]);

  const GreaterChangeRight = (value) => {
    setGreaterRightVal(value);
    dispatch(
      updateHipFeedback({
        key: "greaterRightVal",
        value: value,
      })
    );
  };
  const GreaterChangeLeft = (value) => {
    setGreaterLeftVal(value);
    dispatch(
      updateHipFeedback({
        key: "greaterLeftVal",
        value: value,
      })
    );
  };
  const InguinalChangeRight = (value) => {
    setInguinalRightVal(value);
    dispatch(
      updateHipFeedback({
        key: "inguinalRightVal",
        value: value,
      })
    );
  };
  const InguinalChangeLeft = (value) => {
    setInguinalLeftVal(value);
    dispatch(
      updateHipFeedback({
        key: "inguinalLeftVal",
        value: value,
      })
    );
  };

  const LogChangeRight = (e) => {
    setLogRightVal(e);
    dispatch(
      updateHipFeedback({
        key: "LogRightVal",
        value: e,
      })
    );
  };
  const LogChangeLeft = (e) => {
    setLogLeftVal(e);
    dispatch(
      updateHipFeedback({
        key: "LogLeftVal",
        value: e,
      })
    );
  };
  const GaleazziChangeRight = (e) => {
    setGaleazziRightVal(e);
    dispatch(
      updateHipFeedback({
        key: "GaleazziRightVal",
        value: e,
      })
    );
  };
  const GaleazziChangeLeft = (e) => {
    setGaleazziLeftVal(e);
    dispatch(
      updateHipFeedback({
        key: "GaleazziLeftVal",
        value: e,
      })
    );
  };

  const TrendelenburgChangeRight = (e) => {
    setTrendelenburgRightVal(e);
    dispatch(
      updateHipFeedback({
        key: "TrendelenburgRightVal",
        value: e,
      })
    );
  };
  const TrendelenburgChangeLeft = (e) => {
    setTrendelenburgLeftVal(e);
    dispatch(
      updateHipFeedback({
        key: "TrendelenburgLeftVal",
        value: e,
      })
    );
  };
  const ThomasChangeRight = (e) => {
    setThomasRightVal(e);
    dispatch(
      updateHipFeedback({
        key: "ThomasRightVal",
        value: e,
      })
    );
  };
  const ThomasChangeLeft = (e) => {
    setThomasLeftVal(e);
    dispatch(
      updateHipFeedback({
        key: "ThomasLeftVal",
        value: e,
      })
    );
  };

  const FADIRChangeRight = (e) => {
    setFADIRRightVal(e);
    dispatch(
      updateHipFeedback({
        key: "FADIRRightVal",
        value: e,
      })
    );
  };
  const FADIRChangeLeftt = (e) => {
    setFADIRLeftVal(e);
    dispatch(
      updateHipFeedback({
        key: "FADIRLeftVal",
        value: e,
      })
    );
  };
  const PatrickChangeRight = (e) => {
    setPatrickRightVal(e);
    dispatch(
      updateHipFeedback({
        key: "PatrickRightVal",
        value: e,
      })
    );
  };
  const PatrickChangeLeft = (e) => {
    setPatrickLeftVal(e);
    dispatch(
      updateHipFeedback({
        key: "PatrickLeftVal",
        value: e,
      })
    );
  };
  const OberChangeRight = (e) => {
    setOberRightVal(e);
    dispatch(
      updateHipFeedback({
        key: "OberRightVal",
        value: e,
      })
    );
  };
  const OberChangeLeft = (e) => {
    setOberLeftVal(e);
    dispatch(
      updateHipFeedback({
        key: "OberLeftVal",
        value: e,
      })
    );
  };
  const StraightChangeRight = (e) => {
    setStraightRightVal(e);
    dispatch(
      updateHipFeedback({
        key: "StraightRightVal",
        value: e,
      })
    );
  };
  const StraightChangeLeft = (e) => {
    setStraightLeftVal(e);
    dispatch(
      updateHipFeedback({
        key: "StraightLeftVal",
        value: e,
      })
    );
  };
  const BarlowChangeRight = (e) => {
    setBarlowRightVal(e);
    dispatch(
      updateHipFeedback({
        key: "BarlowRightVal",
        value: e,
      })
    );
  };
  const BarlowChangeLeft = (e) => {
    setBarlowLeftVal(e);
    dispatch(
      updateHipFeedback({
        key: "BarlowLeftVal",
        value: e,
      })
    );
  };
  const OrtolaniChangeRight = (e) => {
    setOrtolaniRightVal(e);
    dispatch(
      updateHipFeedback({
        key: "OrtolaniRightVal",
        value: e,
      })
    );
  };
  const OrtolaniChangeLeft = (e) => {
    setOrtolaniLeftVal(e);
    dispatch(
      updateHipFeedback({
        key: "OrtolaniLeftVal",
        value: e,
      })
    );
  };
  const AsymmetricChangeRight = (e) => {
    setAsymmetricRightVal(e);
    dispatch(
      updateHipFeedback({
        key: "AsymmetricRightVal",
        value: e,
      })
    );
  };

  const handleasymmetricR = (e) => {
    seteasymmetricR(e);
    dispatch(
      updateHipFeedback({
        key: "asymmetricR",
        value: e,
      })
    );
  };

  const handleAsymmetricOtherR = (e) => {
    setasymmetricOtherR(e);
    dispatch(
      updateHipFeedback({
        key: "asymmetricOtherR",
        value: e,
      })
    );
  };
  const handleAsymmetricOtherL = (e) => {
    setasymmetricOtherL(e);
    dispatch(
      updateHipFeedback({
        key: "asymmetricOtherL",
        value: e,
      })
    );
  };

  const handleasymmetricL = (e) => {
    seteasymmetricL(e);
    dispatch(
      updateHipFeedback({
        key: "asymmetricL",
        value: e,
      })
    );
  };
  const AsymmetricChangeLeft = (e) => {
    setAsymmetricLeftVal(e);
    dispatch(
      updateHipFeedback({
        key: "AsymmetricLeftVal",
        value: e,
      })
    );
  };

  const othersChangeR = (e) => {
    dispatch(
      updateHipFeedback({
        key: "others_r",
        value: e,
      })
    );
    setOthers_r(e);
  };

  const othersChangeL = (e) => {
    dispatch(
      updateHipFeedback({
        key: "others_l",
        value: e,
      })
    );
    setOthers_l(e);
  };

  const others2ChangeR = (e) => {
    dispatch(
      updateHipFeedback({
        key: "others2_r",
        value: e,
      })
    );
    setOthers2_r(e);
  };
  const others2ChangeL = (e) => {
    dispatch(
      updateHipFeedback({
        key: "others2_l",
        value: e,
      })
    );
    setOthers2_l(e);
  };
  const [displayConfirmationModal, setDisplayConfirmationModal] =
  useState(false);

    // --
    const [msg, setMsg] = useState('');
    const [msgType, setmsgType] = useState('');
  const updateDate = async () => {
    let response = await axiosinstance.current.put(
      `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}&edit=1`,
      json,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      setMsg(translation.update)
      setmsgType('success')
      setTimeout(() => {
        setMsg('')
        setmsgType('success')
      }, 2000);
      // window.open(
      //   process.env.REACT_APP_DOCTOR_URL + 'patient-list',
      //   "_blank"
      // );
      // window.close()
      // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + pId;
      setDisplayConfirmationModal(true)
    }
  };
  
  useEffect(()=>{
    const updateDate2 = async () => {
    const response = await axiosinstance.current.put(
      `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=0&ak_dc=${dId}`,
      json,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {

    }
    }
    if(chk){
      if(doctorEnable !== "true"){
    updateDate2()
    //  updateDate1()
    }
    }
  },[json])
  // useEffect(() => {
  //   // if (chk) {
  //     updateDate();
  //   // }
  // }, [json]);
  const handleSubmit = () => {
    updateDate();
  };
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  return (
    <>
    {isLoading ? (
      <LoadingSpinner/>
    ):(

      <>




     <div class="d-flex justify-content-end">
        <DoctorNameCustom />
      </div>
      <div className="patient_assessment_form bg_white pd_10 pt_50">
        {doctorEnable === "true" && <HipTab rId={rId} />}
        <CustomToaster msg={msg} msgType={msgType} />
        
      </div>
    
      <div className="patient_assessment_form mb_100 bg_white pd_10 pt_50 content_fx_w">
        <AssessmentHeader />
        <HeaderInnerBreadcrumb />

        <div className="input_tab_box mb_30 ">
          <div className="row">
            <label style={{ fontSize: "30px" }}>{translation.Tenderness}</label>
            <div className="row mb_30">
              <h4 className="range_sl_title">
                {translation.Greater_trochanter}
              </h4>
              <div className="col-md-6 col-sm-6">
                <RadioSwitch
                  labelLeft="Negative"
                  labelRight="Positive"
                  idLeft="bearHugnveR"
                  idRight="bearHugPveR"
                  name="bearHugR"
                  title="Right Hip"
                  styleClass=""
                  value={greaterRightVal}
                  onChange={GreaterChangeRight}
                  placeholder="select"
                />
              </div>
              <div className="col-md-6 col-sm-6">
                <RadioSwitch
                  labelLeft="Negative"
                  labelRight="Positive"
                  idLeft="bearHugnveL"
                  idRight="bearHugPveL"
                  name="bearHugL"
                  title="Left Hip"
                  styleClass=""
                  value={greaterLeftVal}
                  onChange={GreaterChangeLeft}
                  placeholder="select"
                />
              </div>

              
            </div>

            <div className="row mb_30">
              <h4 className="range_sl_title">{translation.Inguinal}</h4>
              <div className="col-md-6 col-sm-6">
                <RadioSwitch
                  labelLeft="Negative"
                  labelRight="Positive"
                  idLeft="InguinalnveR"
                  idRight="InguinalPveR"
                  name="InguinalR"
                  title="Right Hip"
                  styleClass=""
                  value={inguinalRightVal}
                  onChange={InguinalChangeRight}
                  placeholder="select"
                />
              </div>
              <div className="col-md-6 col-sm-6">
                <RadioSwitch
                  labelLeft="Negative"
                  labelRight="Positive"
                  idLeft="InguinalnveL"
                  idRight="InguinalPveL"
                  name="InguinalL"
                  title="Left Hip"
                  styleClass=""
                  value={inguinalLeftVal}
                  onChange={InguinalChangeLeft}
                  placeholder="select"
                />
              </div>

             
            </div>
            <div className="row mb_30">
              <h4 className="range_sl_title">{translation.Others}</h4>
            

              <div className="col-md-6 col-sm-6">
                <InputField
                  label="Right Hip"
                  value={others_r}
                  type="textarea"
                  onChange={othersChangeR}
                  styleClass="mxw_100 br_10"
                />
              </div>
              <div className="col-md-6 col-sm-6">
                <InputField
                  label="Left Hip"
                  type="textarea"
                  value={others_l}
                  onChange={othersChangeL}
                  styleClass="mxw_100 br_10"
                />
              </div>

            </div>
          </div>
        </div>

        <div className="input_tab_box mb_30 ">
          <div className="row">
            <label style={{ fontSize: "30px" }}>Special Tests</label>
            <div className="row mb_30">
              <h4 className="range_sl_title">{translation.Log_roll_test}</h4>
              <div className="col-md-6 col-sm-6">
                <RadioSwitch
                  labelLeft="Negative"
                  labelRight="Positive"
                  idLeft="LognveR"
                  idRight="LogPveR"
                  name="LogR"
                  title="Right Hip"
                  styleClass=""
                  value={LogRightVal}
                  onChange={LogChangeRight}
                  placeholder="select"
                />
              </div>
              <div className="col-md-6 col-sm-6">
                <RadioSwitch
                  labelLeft="Negative"
                  labelRight="Positive"
                  idLeft="LognveL"
                  idRight="LogPveL"
                  name="LogL"
                  title="Left Hip"
                  styleClass=""
                  value={LogLeftVal}
                  onChange={LogChangeLeft}
                  placeholder="select"
                />
              </div>
            
            </div>

            <div className="row mb_30">
              <h4 className="range_sl_title">{translation.Galeazzi_sign}</h4>
              <div className="col-md-6 col-sm-6">
                <RadioSwitch
                  labelLeft="Negative"
                  labelRight="Positive"
                  idLeft=" GaleazzinveR"
                  idRight=" GaleazziPveR"
                  name=" GaleazziR"
                  title="Right Hip"
                  styleClass=""
                  value={GaleazziRightVal}
                  onChange={GaleazziChangeRight}
                  placeholder="select"
                />
              </div>
              <div className="col-md-6 col-sm-6">
                <RadioSwitch
                  labelLeft="Negative"
                  labelRight="Positive"
                  idLeft="GaleazzinveL"
                  idRight="GaleazziPveL"
                  name="GaleazziL"
                  title="Left Hip"
                  styleClass=""
                  value={GaleazziLeftVal}
                  onChange={GaleazziChangeLeft}
                  placeholder="select"
                />
              </div>
             
            </div>

            <div className="row mb_30">
              <h4 className="range_sl_title">
                {translation.Trendelenburg_sign}
              </h4>
              <div className="col-md-6 col-sm-6">
                <RadioSwitch
                  labelLeft="Negative"
                  labelRight="Positive"
                  idLeft="TrendelenburgnveR"
                  idRight="TrendelenburgPveR"
                  name="TrendelenburgR"
                  title="Right Hip"
                  styleClass=""
                  value={TrendelenburgRightVal}
                  onChange={TrendelenburgChangeRight}
                  placeholder="select"
                />
              </div>
              <div className="col-md-6 col-sm-6">
                <RadioSwitch
                  labelLeft="Negative"
                  labelRight="Positive"
                  idLeft="TrendelenburgnveL"
                  idRight="TrendelenburgPveL"
                  name="TrendelenburgL"
                  title="Left Hip"
                  styleClass=""
                  value={TrendelenburgLeftVal}
                  onChange={TrendelenburgChangeLeft}
                  placeholder="select"
                />
              </div>
             
            </div>

            <div className="row mb_30">
              <h4 className="range_sl_title">{translation.Thomas_test}</h4>

            
              <div className="col-md-6 col-sm-6">
                <RadioSwitch
                  labelLeft="Negative"
                  labelRight="Positive"
                  idLeft="ThomasnveR"
                  idRight="ThomasPveR"
                  name="ThomasR"
                  title="Right Hip"
                  styleClass=""
                  value={ThomasRightVal}
                  onChange={ThomasChangeRight}
                  placeholder="select"
                />
              </div>
              <div className="col-md-6 col-sm-6">
                <RadioSwitch
                  labelLeft="Negative"
                  labelRight="Positive"
                  idLeft="ThomasnveL"
                  idRight="ThomasPveL"
                  name="ThomasL"
                  title="Left Hip"
                  styleClass=""
                  value={ThomasLeftVal}
                  onChange={ThomasChangeLeft}
                  placeholder="select"
                />
              </div>
            </div>

            <div className="row mb_30">
              <h4 className="range_sl_title">
                {translation.FADIR_Impingement_test}
              </h4>
             
              <div className="col-md-6 col-sm-6">
                <RadioSwitch
                  labelLeft="Negative"
                  labelRight="Positive"
                  idLeft="FADIRnveR"
                  idRight="FADIRPveR"
                  name="FADIRR"
                  title="Right Hip"
                  styleClass=""
                  value={FADIRRightVal}
                  onChange={FADIRChangeRight}
                  placeholder="select"
                />
              </div>
              <div className="col-md-6 col-sm-6">
                <RadioSwitch
                  labelLeft="Negative"
                  labelRight="Positive"
                  idLeft="FADIRnveL"
                  idRight="FADIRPveL"
                  name="FADIRL"
                  title="Left Hip"
                  styleClass=""
                  value={FADIRLeftVal}
                  onChange={FADIRChangeLeftt}
                  placeholder="select"
                />
              </div>
            </div>

            <div className="row mb_30">
              <h4 className="range_sl_title">
                {translation.Patrick_FABER_test}
              </h4>
             
              <div className="col-md-6 col-sm-6">
                <RadioSwitch
                  labelLeft="Negative"
                  labelRight="Positive"
                  idLeft="PatricknveR"
                  idRight="PatrickPveR"
                  name="PatrickR"
                  title="Right Hip"
                  styleClass=""
                  value={PatrickRightVal}
                  onChange={PatrickChangeRight}
                  placeholder="select"
                />
              </div>
              <div className="col-md-6 col-sm-6">
                <RadioSwitch
                  labelLeft="Negative"
                  labelRight="Positive"
                  idLeft="PatricknveL"
                  idRight="PatrickPveL"
                  name="PatrickL"
                  title="Left Hip"
                  styleClass=""
                  value={PatrickLeftVal}
                  onChange={PatrickChangeLeft}
                  placeholder="select"
                />
              </div>


            </div>

            <div className="row mb_30">
              <h4 className="range_sl_title">{translation.Ober_test}</h4>
             
              <div className="col-md-6 col-sm-6">
                <RadioSwitch
                  labelLeft="Negative"
                  labelRight="Positive"
                  idLeft="ObernveR"
                  idRight="OberPveR"
                  name="OberR"
                  title="Right Hip"
                  styleClass=""
                  value={OberRightVal}
                  onChange={OberChangeRight}
                  placeholder="select"
                />
              </div>
              <div className="col-md-6 col-sm-6">
                <RadioSwitch
                  labelLeft="Negative"
                  labelRight="Positive"
                  idLeft="ObernveL"
                  idRight="OberPveL"
                  name="OberL"
                  title="Left Hip"
                  styleClass=""
                  value={OberLeftVal}
                  onChange={OberChangeLeft}
                  placeholder="select"
                />
              </div>
            </div>

            <div className="row mb_30">
              <h4 className="range_sl_title">
                {translation.Straight_leg_raise}
              </h4>
             
              <div className="col-md-6 col-sm-6">
                <RadioSwitch
                  labelLeft="Negative"
                  labelRight="Positive"
                  idLeft="StraightnveR"
                  idRight="StraightPveR"
                  name="StraightR"
                  title="Right Hip"
                  styleClass=""
                  value={StraightRightVal}
                  onChange={StraightChangeRight}
                  placeholder="select"
                />
              </div>
              <div className="col-md-6 col-sm-6">
                <RadioSwitch
                  labelLeft="Negative"
                  labelRight="Positive"
                  idLeft="StraightnveL"
                  idRight="StraightPveL"
                  name="StraightL"
                  title="Left Hip"
                  styleClass=""
                  value={StraightLeftVal}
                  onChange={StraightChangeLeft}
                  placeholder="select"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="input_tab_box mb_30 ">
          <label style={{ fontSize: "30px" }}>Paediatric Hip Assessment</label>
          <div className="row mb_30">
            <h4 className="range_sl_title">
              {translation.Barlow}
              <label style={{ fontSize: "15px" }}></label>
            </h4>

            <div className="col-md-6 col-sm-6">
              <RadioSwitch
                labelLeft="Negative"
                labelRight="Positive"
                idLeft="BarlownveR"
                idRight="BarlowPveR"
                name="BarlowR"
                title="Right Hip"
                styleClass=""
                value={BarlowRightVal}
                onChange={BarlowChangeRight}
                placeholder="select"
              />
            </div>
            
            <div className="col-md-6 col-sm-6">
              <RadioSwitch
                labelLeft="Negative"
                labelRight="Positive"
                idLeft="BarlownveL"
                idRight="BarlowPveL"
                name="BarlowL"
                title="Left Hip"
                styleClass=""
                value={BarlowLeftVal}
                onChange={BarlowChangeLeft}
                placeholder="select"
              />
            </div>
           
          </div>
          <div className="row mb_30">
            <h4 className="range_sl_title">
              {translation.Ortolani}
              <label style={{ fontSize: "15px" }}></label>
            </h4>

            
            <div className="col-md-6 col-sm-6">
              <RadioSwitch
                labelLeft="Negative"
                labelRight="Positive"
                idLeft="OrtolaninveR"
                idRight="OrtolaniPveR"
                name="OrtolaniR"
                title="Right Hip"
                styleClass=""
                value={OrtolaniRightVal}
                onChange={OrtolaniChangeRight}
                placeholder="select"
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <RadioSwitch
                labelLeft="Negative"
                labelRight="Positive"
                idLeft="OrtolaninveL"
                idRight="OrtolaniPveL"
                name="OrtolaniL"
                title="Left Hip"
                styleClass=""
                value={OrtolaniLeftVal}
                onChange={OrtolaniChangeLeft}
                placeholder="select"
              />
            </div>
          </div>
          <div className="row mb_30">
            <h4 className="range_sl_title">
              {translation.Asymmetric_skin_creases}
              <label style={{ fontSize: "15px" }}></label>
            </h4>
            <div className="col-md-6 col-sm-6">
              <RadioSwitch
                labelLeft="Negative"
                labelRight="Positive"
                idLeft="AsymmetricnveR"
                idRight="AsymmetricPveR"
                name="AsymmetricR"
                title="Right Hip"
                styleClass=""
                value={AsymmetricRightVal}
                onChange={AsymmetricChangeRight}
                placeholder="select"
              />
              {AsymmetricRightVal === "1" && (
                <FullDropdown
                  data={[
                    { value: "1", label: "Inguina" },
                    { value: "2", label: "Gluteal" },
                    { value: "3", label: "Thigh" },
                    { value: "4", label: "Popliteal" },
                    { value: "5", label: "Others" },
                  ]}
                  styleClass=""
                  value={asymmetricR}
                  onChange={handleasymmetricR}
                />
              )}

              {asymmetricR === "5" && (
                <InputField
                  label="Specify"
                  value={asymmetricOtherR}
                  type="text"
                  onChange={handleAsymmetricOtherR}
                  styleClass="mxw_100 br_10"
                />
              )}
            </div>

            <div className="col-md-6 col-sm-6">
              <RadioSwitch
                labelLeft="Negative"
                labelRight="Positive"
                idLeft="AsymmetricnveL"
                idRight="AsymmetricPveL"
                name="AsymmetricL"
                title="Left Hip"
                styleClass=""
                value={AsymmetricLeftVal}
                onChange={AsymmetricChangeLeft}
                placeholder="select"
              />
              {AsymmetricLeftVal === "1" && (
                <FullDropdown
                  data={[
                    { value: "1", label: "Inguina" },
                    { value: "2", label: "Gluteal" },
                    { value: "3", label: "Thigh" },
                    { value: "4", label: "Popliteal" },
                    { value: "5", label: "Others" },
                  ]}
                  styleClass=""
                  value={asymmetricL}
                  onChange={handleasymmetricL}
                />
              )}
              {asymmetricL === "5" && (
                <InputField
                  label="Specify"
                  value={asymmetricOtherL}
                  type="text"
                  onChange={handleAsymmetricOtherL}
                  styleClass="mxw_100 br_10"
                />
              )}
            </div>

           

            <div className="col-md-6 col-sm-6">
              {/* <RadioSwitch
                labelLeft="Negative"
                labelRight="Positive"
                idLeft="AsymmetricnveL"
                idRight="AsymmetricPveL"
                name="AsymmetricL"
                title="Left Hip"
                styleClass=""
                value={AsymmetricLeftVal}
                onChange={AsymmetricChangeLeft}
                placeholder="select"
              />
              {AsymmetricLeftVal === "1" && (
                <FullDropdown
                  data={[
                    { value: "1", label: "Inguina" },
                    { value: "2", label: "Gluteal" },
                    { value: "3", label: "Thigh" },
                    { value: "4", label: "Popliteal" },
                    { value: "5", label: "Others" },
                  ]}
                  styleClass=""
                  value={asymmetricL}
                  onChange={handleasymmetricL}
                />
              )}
              {asymmetricL === "5" && (
                <InputField
                  label="Specify"
                  value={asymmetricOtherL}
                  type="text"
                  onChange={handleAsymmetricOtherL}
                  styleClass="mxw_100 br_10"
                />
              )
              } */}
            </div>
          </div>
        </div>

        <div className="input_tab_box mb_30 ">
          <div className="row mb_30">
            <h4 className="range_sl_title">{translation.Others}</h4>
           
            <div className="col-md-6 col-sm-6">
              <InputField
                label="Right Hip"
                value={others2_r}
                type="textarea"
                onChange={others2ChangeR}
                styleClass="mxw_100 br_10"
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <InputField
                label="Left Hip"
                type="textarea"
                value={others2_l}
                onChange={others2ChangeL}
                styleClass="mxw_100 br_10"
              />
            </div>
          </div>
        </div>

        <div className={`row mb_30 button_box`}>
          <div className="col-md-6 col-sm-6">
            {doctorEnable !== "true" && (
              <Link
                to={"/hip-feedback/" + rId + `?doctor=${dId}&patient=${pId}&is_doctor_form=true`}
                className="assm_back_btn"
              >
                <img src={backIcon} alt="" />
                <span>{translation.back_button}</span>
              </Link>
            )}
          </div>
          <div className="col-md-6 col-sm-6 text-end">
            <button
              className="next_btn btn_fill"
              onClick={() => handleSubmit()}
            >
              {translation.submit}
              <span>
                <img src={nextIcon} alt="" />
                <img src={nextWhiteIcon} alt="" />
              </span>
            </button>
          </div>
        </div>
      </div>
      {/* < SubmitModel
     showModal={displayConfirmationModal}
     
     /> */}
      <Promsschudle
     openModal={displayConfirmationModal}
     setDisplayConfirmationModal={setDisplayConfirmationModal}
     type={"6"}
     patientuserID={pId}
     />
      </>

    )

    }
    </>
  );
}
