import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Tabs, Tab, Badge } from "react-bootstrap";
import "./Tab.css";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import useAxios from "../../../axiosinstance";
export default function PediakneeTab({ rId }) {
    const instabilityACjoint = useSelector(
        (state) => state.shoulderScore.functionScore[0].instability_acjoint
    );
    const feel_unstable = useSelector(
        (state) => state.shoulderScore.functionScore[0].feel_unstable
    );
    const functionScore = useSelector(
        (state) => state.pediaKnee.functionScore[0]
    );
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(window.location.search);
    const pId = queryParams.get("akpt");
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    // const [key, setKey] = useState(splitLocation[1]);
    const [key, setKey] = useState(() => {
              const path = location.pathname;
              if (path.includes("pedia-knee-pain")) return "pain";
              if (path.includes("pedia-knee-symptoms")) return "symptoms";
              if (path.includes("pedia-knee-function")) return "function";
              if (path.includes("pedia-knee-quality-of-life")) return "quality-of-life";
              if (path.includes("pedia-knee-satisfaction")) return "satisfaction";
              if (path.includes("patellar-instability-pedia")) return "patellar-instability-pedia";
              if (path.includes("knee-feedback")) return "doctor-feedback/step-1";
              if (path.includes("knee-feedback-step-2")) return "doctor-feedback/step-2";
              return "personal"; // Default tab
          });
    const is_doctor_form = queryParams.get("is_doctor_form");
    // useEffect(()=>{
    //     localStorage.setItem("isdoctorForm",is_doctor_form)
    // },[is_doctor_form])
    const axiosinstance = useAxios();
    const [patientStatus, setPatientStatus] = useState(false);
    const [doctorStatus, setDoctorStatus] = useState(false);
    const [ptid, setPtId] = useState("");
    const getTab = () => {
        axiosinstance.current
            .get(`extapp/forms/all_forms?resource_id=${rId}&type=20`)
            .then((res) => {
                // console.log(res.data.doctorStatus, res.data.status, "responce");
                setPatientStatus(res.data.status);
                setDoctorStatus(res.data.doctorStatus);
                setPtId(res.data.data2[0].ak_pt);
                if (res.data.status) {
                    // handleTabSelect("pedia-knee-pain");
                } else if (res.data.doctorStatus) {
                    if (splitLocation[1] !== "knee-feedback-step-2") {
                    handleTabSelect("doctor-feedback/step-1");
                    }
                } else {
                    navigate("/un-authorized-access");
                }
            })
            .catch((err) => {
                // navigate('/un-authorized-access');
            });
    };
    useEffect(() => {
        getTab();
    }, []);
    let doctordata=localStorage.getItem("isdoctorForm")
    let akDc = sessionStorage.getItem("akDc");
    let akOu = sessionStorage.getItem("akOu");
    const handleTabSelect = (selectedTab) => {
        setKey(selectedTab);
        switch (selectedTab) {
            case "personal":
        navigate(
          `/personal-data-edit/${window.btoa(
            ptid
          )}/${akDc}/${akOu}/${window.btoa(
            20
          )}?is_doctor_form=true&doctorvalue=${0}&doctor=true&resource_id=${rId}`
        );
        break;
            case "pain":
                navigate(`/pedia-knee-pain/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
                break;
            case "symptoms":
                navigate(`/pedia-knee-symptoms/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
                break;
            case "function":
                navigate(`/pedia-knee-function/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
                break;
            case "quality-of-life":
                navigate(`/pedia-knee-quality-of-life/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
                break;
            case "satisfaction":
                navigate(`/pedia-knee-satisfaction/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
                break;
            case "patellar-instability-pedia":
                navigate(`/patellar-instability-pedia/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
                break;

            case "doctor-feedback/step-1":
                navigate(`/knee-feedback/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
                break;

            case "doctor-feedback/step-2":
                navigate(`/knee-feedback-step-2/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
                break;

            default:
                break;
        }
    };
    return (
        <>
            <div className="col-md-12">
                <Tabs
                    id="doctor-reg-tabs"
                    activeKey={key}
                    // onSelect={handleSelect}
                    className="doctor_reg_tabs mb-1"
                    onSelect={(e) => handleTabSelect(e)}
                >
                 {patientStatus && (
            <Tab
              eventKey="personal"
              title={<React.Fragment>Personal Information</React.Fragment>}
            ></Tab>
          )}
                    {patientStatus && (
                        <Tab
                            eventKey="pain"
                            title={<React.Fragment>Pain</React.Fragment>}
                        ></Tab>
                    )}
                    {patientStatus && (
                        <Tab
                            eventKey="symptoms"
                            title={<React.Fragment>Symptoms</React.Fragment>}
                        ></Tab>
                    )}
                    {patientStatus && (
                        <Tab
                            eventKey="function"
                            title={<React.Fragment>Function</React.Fragment>}
                        ></Tab>
                    )}
                    {patientStatus &&(functionScore.patellarInstability === "1" || functionScore.patellarInstabilityLeft === "1")? (
                      
                                <Tab
                                    eventKey="patellar-instability-pedia"
                                    title={<React.Fragment>Patellar Instability</React.Fragment>}
                                ></Tab>
                            ) : ""}
                       
                    {patientStatus && (
                        <Tab
                            eventKey="quality-of-life"
                            title={<React.Fragment>QOL</React.Fragment>}
                        ></Tab>
                    )}
                    {patientStatus && (
                        <Tab
                            eventKey="satisfaction"
                            title={<React.Fragment>Satisfaction</React.Fragment>}
                        ></Tab>

                    )}

                    {doctorStatus && (
                        <Tab
                            eventKey="doctor-feedback/step-1"
                            title={<React.Fragment>Patient-Assessment-Step 1</React.Fragment>}
                        ></Tab>
                    )}
                    {doctorStatus && (
                        <Tab
                            eventKey="doctor-feedback/step-2"
                            title={<React.Fragment>Patient-Assessment-Step 2</React.Fragment>}
                        ></Tab>
                    )}
                </Tabs>
            </div>
        </>
    );
}
