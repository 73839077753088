import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { validateInput } from "../../../utilities/Validator";
import degreeWidgetStyles from "./AssessmentDegree.module.css";
import RadioButton from "../radio-button/RadioButton";
export default function KneeDegree100({ title, value, validators, labelR,
    labelL, nameR, nameL, onChange, onChangeR, onChangeL, valueR, valueL, validator, validatorL, RadioChange, radioname, unit,RadioRight }) {
    const [countR, setCountR] = useState(valueR);
    const [countL, setCountL] = useState(valueL);
    const [errorValR, setErrorValR] = useState(false);
    const [errorValL, setErrorValL] = useState(false);
    const [errorR, setErrorR] = useState(false);
    const [errorL, setErrorL] = useState(false);
    const [errorMsgR, setErrorMsgR] = useState("Number Should be 0 to 100");
    const [errorMsgL, setErrorMsgL] = useState("Number Should be 0 to 100");
    const [ir1Left, setIr1Left] = useState("");
    const [ir1Right, setIr1Right] = useState("");
    const [angleR, setangleR] = useState("");
    const [angleL, setangleL] = useState("");

    useEffect(()=>{
        setIr1Left(RadioRight)
        setIr1Right(RadioRight)
        setangleR(RadioRight)
        setangleL(RadioRight)
    },[RadioRight])
    useEffect(() => {
        setCountR(valueR)
        setCountL(valueL)
    }, [valueR, valueL])


    // regex for avoiding negative values
    const regex = /^\-?[1-9]\d{0,2}(\.\d*)?$/;

    const handleChangeR = (event) => {
        const { value } = event.target;
        let newValue = value;
        setErrorValR(validateInput(validators, value));
        if (newValue >= 0 && newValue <= 100.0) {
            setCountR(parseFloat(newValue));
            onChangeR(parseFloat(newValue));
            setErrorR(false);
            // console.log("✅ num is between values");
        } else {
            // console.log("⛔️ num is NOT between values");
            setCountR(0);
            setErrorR(true);
        }
    };

    // Create handleIncrement event handler
    // const handleIncrementR = () => {
    //     setCountR(countR >= 100 ? 100 : countR + 1);
    //     onChangeR(countR >= 100 ? 100 : countR + 1);
    // };

    const handleIncrementR = () => {
        if (isNaN(countR) || countR === "") {
          setCountR(1);
        } else {
          setCountR(countR >= 100 ? 100 :Number( countR) + 1);
          onChangeR(countR >= 100 ? 100 : Number( countR) + 1);
        }
      };


    //Create handleDecrement event handler
    const handleDecrementR = () => {
        if (regex.test(countR)) {
            setCountR(countR <= 0 ? 0 : countR - 1);
            onChangeR(countR <= 0 ? 0 : countR - 1);
        }
    };

    const handleChangeL = (event) => {
        const { value } = event.target;
        let newValue = value;
        setErrorValL(validateInput(validators, value));

        if (newValue >= 0 && newValue <= 100.0) {
            setCountL(parseFloat(newValue));
            onChangeL(parseFloat(newValue));
            setErrorL(false);
            // console.log("✅ num is between values");
        } else {
            // console.log("⛔️ num is NOT between values");
            setCountL(0);
            setErrorL(true);
        }
    };

    // Create handleIncrement event handler
    const handleIncrementL = () => {
        setCountL(countL >= 100 ? 100 : countL + 1);
        onChangeL(countL >= 100 ? 100 : countL + 1);
    };

    //Create handleDecrement event handler
    const handleDecrementL = () => {
        if (regex.test(countL)) {
            setCountL(countL <= 0 ? 0 : countL - 1);
            onChangeL(countL <= 0 ? 0 : countL - 1);
        }
    };

    const ir1ChangeL = (e) => {
        setIr1Left(e);
        RadioChange(e)
    };
    const ir1ChangeR = (e) => {
        setIr1Right(e)
        RadioChange(e)
    }
    const handleangleR = (e) => {
        setangleR(e)
        RadioChange(e)
    }
    const handleangleL = (e) => {
        setangleL(e)
        RadioChange(e)
    }
    return (
        <>
            <div className={`${degreeWidgetStyles.input_box_main}`}>
                <h4 className={`${degreeWidgetStyles.title_txt}`}>{title}</h4>
                <div className={`${degreeWidgetStyles.number_box_row}`}>
                    <div className={`${degreeWidgetStyles.number_box_col}`}>
                        {/* <label className={`${degreeWidgetStyles.requiredFieldRightF}`}>
            {labelR}
          </label> */}
                        <div className={`${degreeWidgetStyles.degree_box}`}>
                            <span
                                className={`${degreeWidgetStyles.qty_minus}`}
                                onClick={handleDecrementR}
                            >
                                -
                            </span>
                            <input
                                type="number"
                                max={100}
                                min={0}
                                step={0.01}
                                className="qty trim"
                                name={nameR}
                                value={countR}
                                placeholder="000"
                                onChange={handleChangeR}
                                onBlur={handleChangeR}
                            />
                            <span
                                className={`${degreeWidgetStyles.qty_plus}`}
                                onClick={handleIncrementR}
                            >
                                +
                            </span>
                        </div>
                        <span className={`${degreeWidgetStyles.degree_txt}`}>{unit ? unit : "Degrees"}</span>
                        {/* {valueR ==0 || valueR==null  &&(
            <> */}
                        {errorR && (
                            <span className={`${degreeWidgetStyles.ValidationErrors}`}>
                                {errorMsgR}
                            </span>
                        )}
                        {/* </>
          )} */}

                        {valueR == "" || valueR == null ? (
                            <>
                                {validator === true && (
                                    <span className={`${degreeWidgetStyles.ValidationErrors}`}>
                                        This field is required
                                    </span>
                                )}
                            </>
                        ) : null}

                        {errorValR && (
                            <span className="text-danger">{errorValR.message}</span>
                        )}
                    </div>
                    <div className={`${degreeWidgetStyles.number_box_col}`}>
                        {/* <label className={`${degreeWidgetStyles.requiredFieldLeft}`}>
            {labelL}
          </label> */}
                        <div className={`${degreeWidgetStyles.degree_box}`}>
                            {radioname === "1" && (
                                <div className="col-md-6 col-sm-6">
                                    <RadioButton
                                        label={"Neutral"}
                                        selected={ir1Left === "1"}
                                        onChange={ir1ChangeL}
                                        styleClass="custom_radio_box large"
                                        value="1"
                                        id="ir1Left1"
                                        name="ir1Left"
                                    />
                                    <RadioButton
                                        label={"Varus"}
                                        selected={ir1Left === "2"}
                                        onChange={ir1ChangeL}
                                        styleClass="custom_radio_box large"
                                        value="2"
                                        id="ir1Left2"
                                        name="ir1Left"
                                    />
                                    <RadioButton
                                        label={"Valgus"}
                                        selected={ir1Left === "3"}
                                        onChange={ir1ChangeL}
                                        styleClass="custom_radio_box large"
                                        value="3"
                                        id="ir1Left3"
                                        name="ir1Left"
                                    />


                                </div>
                            )}
                            {radioname === "2" && (
                                <div className="col-md-6 col-sm-6">
                                    <RadioButton
                                        label={"Neutral"}
                                        selected={ir1Right === "1"}
                                        onChange={ir1ChangeR}
                                        styleClass="custom_radio_box large"
                                        value="1"
                                        id="ir1Right1"
                                        name="ir1Right"
                                    />
                                    <RadioButton
                                        label={"Varus"}
                                        selected={ir1Right === "2"}
                                        onChange={ir1ChangeR}
                                        styleClass="custom_radio_box large"
                                        value="2"
                                        id="ir1Right2"
                                        name="ir1Right"
                                    />
                                    <RadioButton
                                        label={"Valgus"}
                                        selected={ir1Right === "3"}
                                        onChange={ir1ChangeR}
                                        styleClass="custom_radio_box large"
                                        value="3"
                                        id="ir1Right3"
                                        name="ir1Right"
                                    />


                                </div>
                            )}

                            {radioname === "3" && (
                                <div className="col-md-6 col-sm-6">
                                    <RadioButton
                                        label={"Neutral"}
                                        selected={angleR === "1"}
                                        onChange={handleangleR}
                                        styleClass="custom_radio_box large"
                                        value="1"
                                        id="angleR1"
                                        name="angleR"
                                    />
                                    <RadioButton
                                        label={"Varus"}
                                        selected={angleR === "2"}
                                        onChange={handleangleR}
                                        styleClass="custom_radio_box large"
                                        value="2"
                                        id="angleR2"
                                        name="angleR"
                                    />
                                    <RadioButton
                                        label={"Valgus"}
                                        selected={angleR === "3"}
                                        onChange={handleangleR}
                                        styleClass="custom_radio_box large"
                                        value="3"
                                        id="angleR3"
                                        name="angleR"
                                    />


                                </div>
                            )}
                            {radioname === "4" && (
                                <div className="col-md-6 col-sm-6">
                                    <RadioButton
                                        label={"Neutral"}
                                        selected={angleL === "1"}
                                        onChange={handleangleL}
                                        styleClass="custom_radio_box large"
                                        value="1"
                                        id="angleL1"
                                        name="angleL"
                                    />
                                    <RadioButton
                                        label={"Varus"}
                                        selected={angleL === "2"}
                                        onChange={handleangleL}
                                        styleClass="custom_radio_box large"
                                        value="2"
                                        id="angleL2"
                                        name="angleL"
                                    />
                                    <RadioButton
                                        label={"Valgus"}
                                        selected={angleL === "3"}
                                        onChange={handleangleL}
                                        styleClass="custom_radio_box large"
                                        value="3"
                                        id="angleL3"
                                        name="angleL"
                                    />


                                </div>
                            )}
                            {/* <span
              className={`${degreeWidgetStyles.qty_minus}`}
              onClick={handleDecrementL}
            >
              -
            </span> */}
                            {/* <input
              type="number"
              max={100}
              min={0}
              step={0.01}
              className="qty trim"
              name={nameL}
              value={countL}
              placeholder="000"
              onChange={handleChangeL}
              onBlur={handleChangeL}
            />
            <span
              className={`${degreeWidgetStyles.qty_plus}`}
              onClick={handleIncrementL}
            >
              +
            </span> */}
                            {/* <RadioButton/> */}
                        </div>
                        {/* <span className={`${degreeWidgetStyles.degree_txt}`}>Degrees</span> */}

                        {valueL !== "" && (
                            <>
                                {errorL && (
                                    <span className={`${degreeWidgetStyles.ValidationErrors}`}>
                                        {errorMsgL}
                                    </span>
                                )}
                            </>
                        )}

                        {valueL == "" || valueL == null ? (
                            <>
                                {validatorL === true && (
                                    <span className={`${degreeWidgetStyles.ValidationErrors}`}>
                                        This field is required
                                    </span>
                                )}
                            </>
                        ) : null}
                        {errorValL && (
                            <span className="text-danger">{errorValL.message}</span>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

KneeDegree100.propTypes = {
    validators: PropTypes.array,
};

KneeDegree100.defaultProps = {
    value: "",
    title: "",
    labelR: "",
    labelL: "",
    nameR: "",
    nameL: "",
    validators: [],
};
