import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import {
  updateFootQolScore,
  updateFootQolData,
  updateFootAnkle,
  updateFootAnklePainData,
  updateFootSatisfactionData,
  updateFootInstabilityData,
  updateFootSymptomsData,
  updateFootFeedbackfootankle,
  updateFootFunctionData,
  updateFootComberlandData,
  updateAllData,
} from "../../../../redux/actions/footAnkle";
import useAxios from "../../../../axiosinstance";
import useTranslation from "../../../customHooks/translations";
import FootAnkleTab from "../../tab-section/FootAnkleTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import RangeSlider from "../../../custom/range-slider/RangeSlider";
import KneeDegree from "../../../custom/assessment-widgets/KneeDegree";
import KneeScoreRangeSlider from "../../hand&wrist/knee-score-range-slider/KneeScoreRangeSlider";
import {
  LeftSide,
  LeftTrans,
  RightSide,
  RigthTrans,
} from "../../../../utilities/RightLeftFormat";
import NameCustom from "../../../custom/name/NameCustom";

function FootAnkleQualityOfLife() {
  const translation = useTranslation();
  const dispatch = useDispatch();
  const axiosinstance = useAxios();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [oftenAnkleR, setOftenAnkleR] = useState("0");
  const [oftenAnkleL, setOftenAnkleL] = useState("0");
  const [lifestyleR, setLifeStyleR] = useState("0");
  const [lifestyleL, setLifeStyleL] = useState("0");
  const [troubledR, setTroubledR] = useState("0");
  const [troubledL, setTroubledL] = useState("0");
  const [difficultyR, setDifficultyR] = useState("0");
  const [difficultyL, setDifficultyL] = useState("0");
  const [anxiousR, setAnixiousR] = useState("0");
  const [anxiousL, setAnixiousL] = useState("0");
  const [healthToday, setHealthToday] = useState("0");
  const [healthTodayL, setHealthTodayL] = useState("0");
  const [initialAssesment, setInitialAssesment] = useState(0);
  // const [anklePain, setAnklePAin] = useState("1");

  const anklePain = useSelector((state) => state.footAnkle.anklePain);
  const [chk, setChk] = useState(false);
  const FootQol = useSelector((state) => state.footAnkle.qolScore[0]);
  const json = useSelector((state) => state.footAnkle);
  const [updateTotal, setUpdateTotal] = useState(false);
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=21`)
      .then((res) => {
        setInitialAssesment(res.data.data2[0].initial_assessment);
        dispatch(updateFootAnklePainData(res.data.data2[0].painScore));
        dispatch(updateFootSymptomsData(res.data.data2[0].symptomsScore));
        dispatch(updateFootAnkle(res.data.data2[0].anklePain));
        dispatch(updateFootInstabilityData(res.data.data2[0].instabilityScore));
        dispatch(updateFootComberlandData(res.data.data2[0].comberland));
        dispatch(updateFootQolData(res.data.data2[0].qolScore));
        dispatch(updateFootFunctionData(res.data.data2[0].functionScore));
        dispatch(
          updateFootSatisfactionData(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateFootFeedbackfootankle(res.data.data2[0].doctorFeedback));
        setChk(true);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };

  useEffect(() => {
    save();
  }, []);

  useEffect(() => {
    setOftenAnkleR(FootQol.oftenAnkleR);
    setOftenAnkleL(FootQol.oftenAnkleL);
    setLifeStyleR(FootQol.lifestyleR);
    setLifeStyleL(FootQol.lifestyleL);
    setTroubledR(FootQol.troubledR);
    setTroubledL(FootQol.troubledL);
    setDifficultyR(FootQol.difficultyR);
    setDifficultyL(FootQol.difficultyL);
    setAnixiousR(FootQol.anxiousR);
    setAnixiousL(FootQol.anxiousL);
    setHealthToday(FootQol.healthToday);
    setHealthTodayL(FootQol.healthTodayL);
  }, [FootQol]);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [FootQol, updateTotal]);

  const continueRedirect = (e) => {
    dispatch(
      updateFootQolData([
        {
          oftenAnkleR: oftenAnkleR,
          oftenAnkleL: oftenAnkleL,
          lifestyleR: lifestyleR,
          lifestyleL: lifestyleL,
          troubledR: troubledR,
          troubledL: troubledL,
          difficultyR: difficultyR,
          difficultyL: difficultyL,
          anxiousR: anxiousR,
          anxiousL: anxiousL,
          healthToday: healthToday,
          healthTodayL: healthTodayL,
        },
      ])
    );
   
      navigate("/foot-ankle-satisfaction/" + rId);
  };
  const handleOftenAnkleR = (e) => {
    setOftenAnkleR(e);
    dispatch(
      updateFootQolScore({
        key: "oftenAnkleR",
        value: e,
      })
    );
  };
  const handleOftenAnkleL = (e) => {
    setOftenAnkleL(e);
    dispatch(
      updateFootQolScore({
        key: "oftenAnkleL",
        value: e,
      })
    );
  };
  const handleLifestyleR = (e) => {
    setLifeStyleR(e);
    dispatch(
      updateFootQolScore({
        key: "lifestyleR",
        value: e,
      })
    );
  };
  const handleLifestyleL = (e) => {
    setLifeStyleL(e);
    dispatch(
      updateFootQolScore({
        key: "lifestyleL",
        value: e,
      })
    );
  };
  const handleTroubledR = (e) => {
    setTroubledR(e);
    dispatch(
      updateFootQolScore({
        key: "troubledR",
        value: e,
      })
    );
  };
  const handleTroubledL = (e) => {
    setTroubledL(e);
    dispatch(
      updateFootQolScore({
        key: "troubledL",
        value: e,
      })
    );
  };
  const handleDifficultyR = (e) => {
    setDifficultyR(e);
    dispatch(
      updateFootQolScore({
        key: "difficultyR",
        value: e,
      })
    );
  };
  const handleDifficultyL = (e) => {
    setDifficultyL(e);
    dispatch(
      updateFootQolScore({
        key: "difficultyL",
        value: e,
      })
    );
  };
  const handleAnxiousR = (e) => {
    setAnixiousR(e);
    dispatch(
      updateFootQolScore({
        key: "anxiousR",
        value: e,
      })
    );
  };
  const handleAnxiousL = (e) => {
    setAnixiousL(e);
    dispatch(
      updateFootQolScore({
        key: "anxiousL",
        value: e,
      })
    );
  };
  const handleHealthToday = (e) => {
    setHealthToday(e);
    dispatch(
      updateFootQolScore({
        key: "healthToday",
        value: e,
      })
    );
  };
  const handleHealthTodayL = (e) => {
    setHealthTodayL(e);
    dispatch(
      updateFootQolScore({
        key: "healthTodayL",
        value: e,
      })
    );
  };
  // const handleAnklePain = (e) => {
  //     setAnklePAin(e);
  // }
  let akpt = searchParams.get("akpt");

  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update);
    setmsgType("success");
     setTimeout(() => {
     let view_circular=  sessionStorage.getItem("view_circular")
      // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
      // window.location.href = process.env.REACT_APP_DOCTOR_URL +`view-management-plan/${akpt}/${window.btoa(
      //   rId
      // )}/?org=${window.btoa(sessionStorage.getItem("orgid")||'')}&formType=${
      //   window.btoa('21') || ""
      // }&view_circular=${view_circular==='false'?'false':'true'}`
      window.location.href = process.env.REACT_APP_DOCTOR_URL +`patient-details/${akpt}?formid=21`
      setMsg('')
      setmsgType('success')
    }, 2000);
  };

  const handleNext = () => {
    navigate(`/foot-ankle-satisfaction/${rId}?d=true&akpt=${akpt}`);
  }
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
          <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <FootAnkleTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />

              <h4 className="ph_title f_40 ">
                {translation.patient_foot_ankle_quality_of_life}
              </h4>

              <div className="function_formtop">
                <strong className="mt-2">
                  {translation.patient_foot_ankle_quality_of_life_instructions}
                </strong>
                <div className="row">
                  {anklePain === "1" || anklePain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_foot_ankle_quality_of_life_q1 +
                          RigthTrans(anklePain, translation)
                        }
                        styleClass=""
                        value={oftenAnkleR}
                        maximun="0"
                        minimum="4"
                        placeholder={translation.none}
                        onChange={handleOftenAnkleR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q1_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q1_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q1_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q1_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q1_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {anklePain === "2" || anklePain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_foot_ankle_quality_of_life_q1 +
                          LeftTrans(anklePain, translation)
                        }
                        styleClass=""
                        value={oftenAnkleL}
                        maximun="0"
                        minimum="4"
                        placeholder={translation.none}
                        onChange={handleOftenAnkleL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q1_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q1_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q1_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q1_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q1_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {anklePain === "1" || anklePain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_foot_ankle_quality_of_life_q2 +
                          RigthTrans(anklePain, translation)
                        }
                        styleClass=""
                        value={lifestyleR}
                        maximun="0"
                        minimum="4"
                        placeholder={translation.none}
                        onChange={handleLifestyleR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q2_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q2_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q2_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q2_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q2_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {anklePain === "2" || anklePain === "3" ? (
                    <div className="col-md-6 col-sm-6 mb_10">
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_foot_ankle_quality_of_life_q2 +
                          LeftTrans(anklePain, translation)
                        }
                        styleClass=""
                        value={lifestyleL}
                        maximun="0"
                        minimum="4"
                        placeholder={translation.none}
                        onChange={handleLifestyleL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q2_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q2_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q2_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q2_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q2_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {anklePain === "1" || anklePain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_foot_ankle_quality_of_life_q3 +
                          RigthTrans(anklePain, translation)
                        }
                        styleClass=""
                        value={troubledR}
                        maximun="0"
                        minimum="4"
                        placeholder={translation.none}
                        onChange={handleTroubledR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q3_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q3_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q3_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q3_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q3_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {anklePain === "2" || anklePain === "3" ? (
                    <div className="col-md-6 col-sm-6 mb_10">
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_foot_ankle_quality_of_life_q3 +
                          LeftTrans(anklePain, translation)
                        }
                        styleClass=""
                        value={troubledL}
                        maximun="0"
                        minimum="4"
                        placeholder={translation.none}
                        onChange={handleTroubledL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q3_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q3_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q3_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q3_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q3_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {anklePain === "1" || anklePain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_foot_ankle_quality_of_life_q4 +
                          RigthTrans(anklePain, translation)
                        }
                        styleClass=""
                        value={difficultyR}
                        maximun="0"
                        minimum="4"
                        placeholder={translation.none}
                        onChange={handleDifficultyR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q4_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q4_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q4_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q4_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q4_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {anklePain === "2" || anklePain === "3" ? (
                    <div className="col-md-6 col-sm-6 mb_10">
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_foot_ankle_quality_of_life_q4 +
                          LeftTrans(anklePain, translation)
                        }
                        styleClass=""
                        value={difficultyL}
                        maximun="0"
                        minimum="4"
                        placeholder={translation.none}
                        onChange={handleDifficultyL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q4_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q4_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q4_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q4_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q4_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {anklePain === "1" || anklePain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_foot_ankle_quality_of_life_q5
                        }
                        styleClass=""
                        value={anxiousR}
                        maximun="0"
                        minimum="4"
                        placeholder={translation.none}
                        onChange={handleAnxiousR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q5_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q5_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q5_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q5_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_foot_ankle_quality_of_life_q5_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {/* {anklePain === "2" || anklePain === "3" ? (

                                        <div className="col-md-6 col-sm-6 mb_10">
                                            <FullDropdown
                                                data={[
                                                    {
                                                        value: "4",
                                                        label: "Not anxious/depressed",
                                                    },
                                                    {
                                                        value: "3",
                                                        label: "Slightly anxious/depressed",
                                                    },
                                                    {
                                                        value: "2",
                                                        label: "Moderately anxious/depressed",
                                                    },
                                                    {
                                                        value: "1",
                                                        label: "Severely anxious/depressed",
                                                    },
                                                    {
                                                        value: "0",
                                                        label: "Extremely anxious/depressed",
                                                    },
                                                ]}
                                                label={anklePain === "3" ? translation.How_anxious_today_L : translation.How_anxious_today}
                                                styleClass=""
                                                value={anxiousL}
                                                placeholder={translation.none}
                                                onChange={handleAnxiousL}
                                            />
                                        </div>
                                    ) : ""} */}
                </div>
                <div className="row mt-5">
                  {/* <div className="row">
                                        <div className="col-md-6 col-sm-6 function_head">
                                            <label>
                                                {translation.How_good_bad}
                                            </label> <br />
                                        </div>
                                    </div> */}
                  {anklePain === "1" || anklePain === "3" ? (
                    <div className="row">
                      <div className="mb_20">
                        <RangeSlider
                          id="normal_percent"
                          min="0"
                          colorcode={"1"}
                          max="100"
                          step="1"
                          type="text"
                          label={
                            translation.patient_foot_ankle_quality_of_life_q6
                          }
                          // label="Left Arm"
                          leftTitle={
                            translation.patient_foot_ankle_quality_of_life_q6_a1
                          }
                          rightTitle={
                            translation.patient_foot_ankle_quality_of_life_q6_a2
                          }
                          value={healthToday}
                          onChange={handleHealthToday}
                          formGroupClass="label_style mb_40"
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* {anklePain === "2" || anklePain === "3" ? (
                                        <div className="row">
                                            <div className="mb_20">
                                                <RangeSlider
                                                    id="normal_percent"
                                                    min="0"
                                                    max="100"
                                                    step="1"
                                                    type="text"
                                                    // label="Left Arm"
                                                    label={anklePain === "3" ?translation.How_good_badL:translation.How_good_bad}
                                                    // label="Left Arm"
                                                    leftTitle="Worst health"
                                                    rightTitle="Best health"
                                                    value={healthTodayL}
                                                    onChange={handleHealthTodayL}
                                                    formGroupClass="label_style mb_40"
                                                />
                                            </div>

                                        </div>
                                    ) : ""} */}
                </div>
              </div>
              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6"></div>
                  <div className="col-md-6 col-sm-6 text-end">
                  <button
                      className="next_btn btn_fill me-3"
                      onClick={() => handleNext()}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}

              {doctorEnable !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    <Link
                      to={`/foot-ankle-function/${rId}`}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FootAnkleQualityOfLife;
