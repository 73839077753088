import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { validateInput } from "../../../utilities/Validator";
import kgWidgetStyles from "./AssessmentPowerKgWidget.module.css";

function AssessmentKgKgWidget({
  title,
  kg,
  power,
  validators,
  labelR,
  labelL,
  onChangeP,
  onChangeK,
  rightArmkgAction,
  leftArmkgAction,
  validator,
  validatorL,
  viewValidation
}) {
  const [countP, setCountP] = useState(power);
  const [countK, setCountK] = useState(kg);
  const [errorValP, setErrorValP] = useState(false);
  const [errorValK, setErrorValK] = useState(false);
  const [errorP, setErrorP] = useState(false);
  const [errorK, setErrorK] = useState(false);
  const [errorMsgP, setErrorMsgP] = useState("Number Should be 0 to 100");
  const [errorMsgK, setErrorMsgK] = useState("Number Should be 0 to 100");

  useEffect(() => {
    setCountP(power);
    setCountK(kg);
  }, [power, kg]);

  const handleChangeKgRight = (event) => {
    let { value } = event.target;
    // Ensure that there's only one decimal place
    value = parseFloat(value).toFixed(1);
    let newValue = parseFloat(value);
    setErrorValP(validateInput(validators, value));
  
    if (value === "" || (!isNaN(newValue) && newValue >= 0 && newValue <= 100)) {
      setCountP(isNaN(newValue) ? "" : newValue);
      onChangeP(isNaN(newValue) ? "" : newValue);
      setErrorP(false);
      // console.log("✅ num is between values");
    } else {
      // console.log("⛔️ num is NOT between values");
      setCountP("");
      setErrorP(true);
    }
  };
  useEffect(()=>{
    if(countP !==""){
      setErrorP(false);
    }
  },[countP])
  useEffect(()=>{
    if(countK !==""){
      setErrorK(false);
    }
  },[countK])

  useEffect(()=>{
    if(countP){
      onChangeP(countP);
    }
  },[countP])
  useEffect(()=>{
    if(countK){
      onChangeK(countK);
    }
  },[countK])

  const handleIncrementKgRight = () => {
    if (isNaN(countP) ||countP === "" ) {
      setCountP(0.1);
    } else {
      setCountP(countP >= 100 ? 100 :Number((countP + 0.1).toFixed(1)));
      onChangeP(countP >= 100 ? 100 : Number((countP + 0.1).toFixed(1)));
    }
  };

  const handleDecrementKgRight = () => {
    if (!isNaN(countP) && countP >= 0) {
      setCountP(countP <= 0 ? 0 :  Number((countP - 0.1).toFixed(1)));
      onChangeP(countP <= 0 ? 0 : Number((countP - 0.1).toFixed(1)));
    }
  };

  const handleChangeKgLeft = (event) => {
    let { value } = event.target;
    // Ensure that there's only one decimal place
    value = parseFloat(value).toFixed(1);
    let newValue = parseFloat(value);
    setErrorValK(validateInput(validators, value));
  
    if (!isNaN(newValue) && newValue >= 0 && newValue <= 100) {
      setCountK(isNaN(newValue) ? "" : newValue);
      onChangeK(isNaN(newValue) ? "" : newValue);
      setErrorK(false);
      // console.log("✅ num is between values");
    } else {
      // console.log("⛔️ num is NOT between values");
      setCountK("");
      setErrorK(true);
    }
  };

  const handleIncrementKgLeft = () => {
    if (isNaN(countK) || countK === "") {
      setCountK(0.1);
    } else {
      setCountK(countK >= 100 ? 100 : Number((countK + 0.1).toFixed(1)));
      onChangeK(countK >= 100 ? 100 : Number((countK+ 0.1).toFixed(1)));
    }
  };

  const handleDecrementKgLeft = () => {
    if (!isNaN(countK) && countK >= 0) {
      setCountK(countK <= 0 ? 0 : Number((countK - 0.1).toFixed(1)));
      onChangeK(countK <= 0 ? 0 : Number((countK - 0.1).toFixed(1)));
    }
  };
  return (
    <div className={`${kgWidgetStyles.input_box_main} single_kg`}>
      <h4 className={`${kgWidgetStyles.title_txt}`}>{title}</h4>
      <div className={`${kgWidgetStyles.number_box_row}`}>
        {rightArmkgAction && (
          <div className={`${kgWidgetStyles.number_box_col}`}>
            <label
              className={`${kgWidgetStyles.requiredFieldRightF}`}
              style={{ paddingBlock: "10px" }}
            >
              {labelR}
            </label>
            <div className={`${kgWidgetStyles.degree_box}`}>
              <span
                className={`${kgWidgetStyles.qty_minus}`}
                onClick={handleDecrementKgRight}
              >
                -
              </span>
              <input
                type="number"
                max={100}
                min={0}
                step={0.1}
                className="qty trim"
                name="right_kg"
                value={countP}
                placeholder="000"
                onChange={handleChangeKgRight}
                onBlur={handleChangeKgRight}
              />

              <span
                className={`${kgWidgetStyles.qty_plus}`}
                onClick={handleIncrementKgRight}
              >
                +
              </span>
            </div>
            <span className={`${kgWidgetStyles.degree_txt}`}>Kgs</span>

            {errorP && (
              <span className={`${kgWidgetStyles.ValidationErrors}`}>
                {errorMsgP}
              </span>
            )}
            {errorValP && (
              <span className="text-danger">{errorValP.message}</span>
            )}
            {viewValidation &&(
              <>
               {power === "" || isNaN(power) || power === null ? (
              <>
                {validator === true && (
                  <span className={`${kgWidgetStyles.ValidationErrors}`}>
                    This field is required
                  </span>
                )}
              </>
            ) : null}
              </>
            )}
           
          </div>
        )}

        {leftArmkgAction && (
          <div className={`${kgWidgetStyles.number_box_col}`}>
            <label
              className={`${kgWidgetStyles.requiredFieldRightF}`}
              style={{ paddingBlock: "10px" }}
            >
              {labelL}
            </label>
            <div className={`${kgWidgetStyles.degree_box}`}>
              <span
                className={`${kgWidgetStyles.qty_minus}`}
                onClick={handleDecrementKgLeft}
              >
                -
              </span>
              <input
                type="number"
                max={100}
                min={0}
                step={0.01}
                className="qty trim"
                name="left_kg"
                value={countK}
                placeholder="000"
                onChange={handleChangeKgLeft}
                onBlur={handleChangeKgLeft}
              />

              <span
                className={`${kgWidgetStyles.qty_plus}`}
                onClick={handleIncrementKgLeft}
              >
                +
              </span>
            </div>
            <span className={`${kgWidgetStyles.degree_txt}`}>Kgs</span>
            {viewValidation &&(
              <>
              {kg === "" || isNaN(kg) || kg === null ? (
              <>
                {validatorL === true && (
                  <span className={`${kgWidgetStyles.ValidationErrors}`}>
                    This field is required
                  </span>
                )}
              </>
            ) : null}
              </>
            )}
            
            {errorK && (
              <span className={`${kgWidgetStyles.ValidationErrors}`}>
                {errorMsgK}
              </span>
            )}
            {errorValK && (
              <span className="text-danger">{errorValK.message}</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

AssessmentKgKgWidget.propTypes = {
  validators: PropTypes.array,
  title: PropTypes.string,
  kg: PropTypes.number,
  power: PropTypes.number,
  labelR: PropTypes.string,
  labelL: PropTypes.string,
  onChangeP: PropTypes.func,
  onChangeK: PropTypes.func,
  rightArmkgAction: PropTypes.bool,
  leftArmkgAction: PropTypes.bool,
  validator: PropTypes.bool,
  validatorL: PropTypes.bool,
};

AssessmentKgKgWidget.defaultProps = {
  title: "",
  kg: "",
  power: "",
  labelR: "",
  labelL: "",
  onChangeP: () => {},
  onChangeK: () => {},
  rightArmkgAction: true,
  leftArmkgAction: true,
  validator: false,
  validatorL: false,
  validators: [],
};

export default AssessmentKgKgWidget;
