import React, { useState, useEffect } from "react";
import LoadingSpinner from "../../custom/loader/LoadingSpinner";
import Dropdown from "../../custom/dropdown/Dropdown";
import RadioSwitch from "../../custom/radio-switch/RadioSwitch";
import InputField from "../../custom/inputfield/InputField";
import Checkbox from "../../custom/checkbox/Checkbox";
import useTranslation from "../../customHooks/translations";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  updateHistoryComplaintFieldsScore,
  updateHistoryComplaintScore,
  updateSocialComplaintScore,
  updatePastComplaintScore,
  updatePastComplaintFieldsScore,
  updateGenScores,
} from "../../../redux/actions/GeneralAction";
import backIcon from "../../../images/back.png";
import nextWhiteIcon from "../../../images/next-white.png";
import nextIcon from "../../../images/next.png";

import {
  useSearchParams,
  useParams,
  useNavigate,
  Link,
} from "react-router-dom";
import useAxios from "../../../axiosinstance";
import { registerLocale, setDefaultLocale } from "react-datepicker";

export default function Past() {
  let lang = sessionStorage.getItem("language");
  const dispatch = useDispatch();
  const { rId, ptId } = useParams();
  const axiosinstance = useAxios();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  let doctorid = searchParams.get("doctor");
  const translation = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [chk, setChk] = useState(false);
  const navigate = useNavigate();
  let doctordata = localStorage.getItem("isdoctorForm");
  const minDate = sessionStorage.getItem("dob");
  // const [drop1, setDrop1] = useState("0");
  // const handleDrop = (e) => {
  //   setDrop1(e);
  // };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const Past = useSelector((state) => state.GeneralFormReduces.Past[0]);
  const json = useSelector((state) => state.GeneralFormReduces);
  const [backD, setbackD] = useState("");
  let akToken = localStorage.getItem("AkTOKENI");

  const save = () => {
    axiosinstance.current
      .get(
        `extapp/doctors/getMedicalSummaryList?ak_id=${window.atob(
          ptId
        )}&doctor_id=${doctorid}&is_patient=${doctorid ? 0 : 1}`
      )
      .then((res) => {
        dispatch(updateHistoryComplaintScore(res.data.data.History));
        dispatch(updateSocialComplaintScore(res.data.data.Social));
        dispatch(updateGenScores(res.data.data.status));
        dispatch(updatePastComplaintScore(res.data.data.Past));

        setChk(true);
      })
      .catch((err) => {
        if (err.response !== undefined ? err.response.status === 401 : null) {
          navigate("/un-authorized-access");
        }
      });
  };

  useEffect(() => {
    save();
  }, []);

  // q1 ---
  const [knowAllergie, setDrop1] = useState("0");
  const handle_switch1 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "knowAllergie",
        value: e,
      })
    );
    setDrop1(e);
  };

  const [reaction, setDrop2] = useState("");
  const handle_switch2 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "reaction",
        value: e,
      })
    );
    setDrop2(e);
  };
  const [allergicLatex, setDrop3] = useState("0");
  const handle_switch3 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "allergicLatex",
        value: e,
      })
    );
    setDrop3(e);
  };
  const [bloodPressure, setDrop4] = useState("0");
  const handle_switch4 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "bloodPressure",
        value: e,
      })
    );
    setDrop4(e);
  };
  const [heartIllness, setDrop5] = useState("0");
  const handle_switch5 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "heartIllness",
        value: e,
      })
    );
    setDrop5(e);
  };
  const [dropIllness, setDrop6] = useState("0");
  const handle_drop1 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "dropIllness",
        value: e,
      })
    );
    setDrop6(e);
  };
  const [cardiologist, setDrop7] = useState("");
  const handle_switch7 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "cardiologist",
        value: e,
      })
    );
    setDrop7(e);
  };
  const [vascularDisease, setDrop8] = useState("");
  const handle_switch8 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "vascularDisease",
        value: e,
      })
    );
    setDrop8(e);
  };
  const [drop9, setDrop9] = useState("0");
  const handle_switch9 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop9",
        value: e,
      })
    );
    setDrop9(e);
  };
  const [drop10, setDrop10] = useState([]);
  const handle_switch10 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop10",
        value: e,
      })
    );
    setDrop10(e);
  };
  const [drop11, setDrop11] = useState("0");
  const handle_switch11 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop11",
        value: e,
      })
    );
    setDrop11(e);
  };
  const [drop12, setDrop12] = useState("");
  const handle_switch12 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop12",
        value: e,
      })
    );
    setDrop12(e);
  };

  const [drop99, setDrop99] = useState("");
  const handle_switch99 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop99",
        value: e,
      })
    );
    setDrop99(e);
  };

  const [drop13, setDrop13] = useState("");
  const handle_switch13 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop13",
        value: e,
      })
    );
    setDrop13(e);
  };
  const [drop14, setDrop14] = useState("");
  const handle_switch14 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop14",
        value: e,
      })
    );
    setDrop14(e);
  };
  const [drop15, setDrop15] = useState("");
  const handle_switch15 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop15",
        value: e,
      })
    );
    setDrop15(e);
  };
  const [drop16, setDrop16] = useState("");
  const handle_drop16 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop16",
        value: e,
      })
    );
    setDrop16(e);
  };
  const [drop17, setDrop17] = useState("");
  const handle_switch17 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop17",
        value: e,
      })
    );
    setDrop17(e);
  };
  const [drop18, setDrop18] = useState("");
  const handle_switch18 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop18",
        value: e,
      })
    );
    setDrop18(e);
  };
  const [drop19, setDrop19] = useState("");
  const handle_switch19 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop19",
        value: e,
      })
    );
    setDrop19(e);
  };
  const [drop20, setDrop20] = useState("");
  const handle_switch20 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop20",
        value: e,
      })
    );
    setDrop20(e);
  };
  const [drop87, setDrop87] = useState("");
  const handle_switch87 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop87",
        value: e,
      })
    );
    setDrop87(e);
  };
  const [drop21, setDrop21] = useState("");
  const handle_switch21 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop21",
        value: e,
      })
    );
    setDrop21(e);
  };
  const [drop22, setDrop22] = useState("0");
  const handle_switch22 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop22",
        value: e,
      })
    );
    setDrop22(e);
  };
  const [drop23, setDrop23] = useState("");
  const handle_switch23 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop23",
        value: e,
      })
    );
    setDrop23(e);
  };

  const [drop75, setDrop75] = useState("");
  const handle_switch75 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop75",
        value: e,
      })
    );
    setDrop75(e);
  };
  const [drop24, setDrop24] = useState("");
  const handle_switch24 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop24",
        value: e,
      })
    );
    setDrop24(e);
  };
  const [drop24Ch, setDrop24Ch] = useState("");
  const handle_switch24Ch = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop24Ch",
        value: e,
      })
    );
    setDrop24Ch(e);
  };
  const [dropArt, setDropArt] = useState("");
  const handle_switchArt = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "dropArt",
        value: e,
      })
    );
    setDropArt(e);
  };
  const [drop25, setDrop25] = useState("");
  const handle_switch25 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop25",
        value: e,
      })
    );
    setDrop25(e);
  };

  const [drop51, setDrop51] = useState("");
  const handle_switch51 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop51",
        value: e,
      })
    );
    setDrop51(e);
  };

  const [bleed, setDropbleed] = useState("");
  const handle_switchbleed = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "bleed",
        value: e,
      })
    );
    setDropbleed(e);
  };
  const [droplung, setDropLung] = useState("");
  const handle_switchlung = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "droplung",
        value: e,
      })
    );
    setDropLung(e);
  };
  const [drop26, setDrop26] = useState("");
  const handle_switch26 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop26",
        value: e,
      })
    );
    setDrop26(e);
  };
  const [drop27, setDrop27] = useState("");
  const handle_switch504 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop27",
        value: e,
      })
    );
    setDrop27(e);
  };
  const [drop2445, setDrop2445] = useState("");
  const handle_switchdrop2445 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop2445",
        value: e,
      })
    );
    setDrop2445(e);
  };
  const [drop2446, setDrop2446] = useState("");
  const handle_switchdrop2446 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop2446",
        value: e,
      })
    );
    setDrop2446(e);
  };
  const [drop2447, setDrop2447] = useState("");
  const handle_switchdrop2447 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop2447",
        value: e,
      })
    );
    setDrop2447(e);
  };

  const [drop28, setDrop28] = useState("");
  const handle_switch28 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop28",
        value: e,
      })
    );
    setDrop28(e);
  };
  const [drop29, setDrop29] = useState("");
  const handle_switch29 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop29",
        value: e,
      })
    );
    setDrop29(e);
  };
  const [drop30, setDrop30] = useState("0");
  const handle_switch30 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop30",
        value: e,
      })
    );
    setDrop30(e);
  };
  const [drop31, setDrop31] = useState("");
  const handle_switch31 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop31",
        value: e,
      })
    );
    setDrop31(e);
  };

  // date ---
  const [startDate, setstartDate] = useState(new Date());
  const handleDate = (e) => {
    dispatch(
      updateHistoryComplaintFieldsScore({
        key: "startDate",
        value: e,
      })
    );
    setstartDate(e);
  };
  const [drop33, setDrop33] = useState("0");
  const handle_switch33 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop33",
        value: e,
      })
    );
    setDrop33(e);
  };
  const [drop34, setDrop34] = useState("0");
  const handle_switch34 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop34",
        value: e,
      })
    );
    setDrop34(e);
  };
  const [drop35, setDrop35] = useState("0");
  const handle_switch35 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop35",
        value: e,
      })
    );
    setDrop35(e);
  };
  const [drop36, setDrop36] = useState("0");
  const handle_switch36 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop36",
        value: e,
      })
    );
    setDrop36(e);
  };
  const [drop37, setDrop37] = useState("0");
  const handle_switch37 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop37",
        value: e,
      })
    );
    setDrop37(e);
  };
  const [drop38, setDrop38] = useState("0");
  const handle_switch38 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop38",
        value: e,
      })
    );
    setDrop38(e);
  };
  const [drop39, setDrop39] = useState("");
  const handle_switch39 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop39",
        value: e,
      })
    );
    setDrop39(e);
  };
  const [drop41, setDrop41] = useState("");
  const handle_switch41 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop41",
        value: e,
      })
    );
    setDrop41(e);
  };
  const [drop42, setDrop42] = useState("");
  const handle_switch42 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop42",
        value: e,
      })
    );
    setDrop42(e);
  };
  const [drop43, setDrop43] = useState("");
  const handle_switch43 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop43",
        value: e,
      })
    );
    setDrop43(e);
  };
  const [drop44, setDrop44] = useState("");
  const handle_switch44 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop44",
        value: e,
      })
    );
    setDrop44(e);
  };
  const [drop45, setDrop45] = useState("");
  const handle_switch45 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop45",
        value: e,
      })
    );
    setDrop45(e);
  };

  //  checkbox ------------------------------------
  const [checkvalue, setCheck_Value] = useState(false);
  const handle_check = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "checkvalue",
        value: e,
      })
    );
    setCheck_Value(e);
  };
  const [checkvalue1, setCheck_Value1] = useState(false);
  const handle_check1 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "checkvalue1",
        value: e,
      })
    );
    setCheck_Value1(e);
  };
  const [checkvalue3, setCheck_Value3] = useState(false);
  const handle_check3 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "checkvalue3",
        value: e,
      })
    );
    setCheck_Value3(e);
  };

  const [checkvalue80, setCheck_Value80] = useState(false);
  const handle_check80 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "checkvalue80",
        value: e,
      })
    );
    setCheck_Value80(e);
  };
  const [checkvalue81, setCheck_Value81] = useState(false);
  const handle_check81 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "checkvalue81",
        value: e,
      })
    );
    setCheck_Value81(e);
  };
  const [checkvalue82, setCheck_Value82] = useState(false);
  const handle_check82 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "checkvalue82",
        value: e,
      })
    );
    setCheck_Value82(e);
  };

  const [checkvalue50, setCheck_Value50] = useState(false);
  const handle_check50 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "checkvalue50",
        value: e,
      })
    );
    setCheck_Value50(e);
  };

  const [drop50, setDrop50] = useState(false);
  const handle_switch55 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop50",
        value: e,
      })
    );
    setDrop50(e);
  };

  const [dropProblem, setDrop_Problem] = useState(false);
  const handle_drop_Problem = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "dropProblem",
        value: e,
      })
    );
    setDrop_Problem(e);
  };

  const [drop52, setDrop52] = useState(false);
  const handle_switch52 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop52",
        value: e,
      })
    );
    setDrop52(e);
  };

  const [drop501, setDrop501] = useState(false);
  const handle_switch501 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop501",
        value: e,
      })
    );
    setDrop501(e);
  };

  const [drop502, setDrop502] = useState(new Date());
  const handle_switch502 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop502",
        value: e,
      })
    );
    setDrop502(new Date(e));
  };

  const [drop503, setDrop503] = useState(false);
  const handle_switch503 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop503",
        value: e,
      })
    );
    setDrop503(e);
  };
  const [drop510, setDrop510] = useState(false);
  const handle_switch510 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop510",
        value: e,
      })
    );
    setDrop510(e);
  };

  const [drop511, setDrop511] = useState(false);
  const handle_switch511 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop511",
        value: e,
      })
    );
    setDrop511(e);
  };
  const [drop512, setDrop512] = useState(false);
  const handle_switch512 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop512",
        value: e,
      })
    );
    setDrop512(e);
  };

  const [checkvalue4, setCheck_Value4] = useState(false);
  const handle_check4 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "checkvalue4",
        value: e,
      })
    );
    setCheck_Value4(e);
  };
  const [checkvalue5, setCheck_Value5] = useState(false);
  const handle_check5 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "checkvalue5",
        value: e,
      })
    );
    setCheck_Value5(e);
  };
  const [checkvalue6, setCheck_Value6] = useState(false);
  const handle_check6 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "checkvalue6",
        value: e,
      })
    );
    setCheck_Value6(e);
  };
  const [checkvalue7, setCheck_Value7] = useState(false);
  const handle_check7 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "checkvalue7",
        value: e,
      })
    );
    setCheck_Value7(e);
  };
  const [checkvalue8, setCheck_Value8] = useState(false);
  const handle_check8 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "checkvalue8",
        value: e,
      })
    );
    setCheck_Value8(e);
  };
  const [checkvalue9, setCheck_Value9] = useState(false);
  const handle_check9 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "checkvalue9",
        value: e,
      })
    );
    setCheck_Value9(e);
  };
  const [checkAsth, setCheck_Value10] = useState(false);
  const handle_checkAsth = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "checkAsth",
        value: e,
      })
    );
    setCheck_Value10(e);
  };
  const [checkCOPD, setCheck_Value11] = useState(false);
  const handle_checkCOPD = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "checkCOPD",
        value: e,
      })
    );
    setCheck_Value11(e);
  };
  const [checkHigh, setCheck_Value12] = useState(false);
  const handle_checkHigh = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "checkHigh",
        value: e,
      })
    );
    setCheck_Value12(e);
  };
  const [checkCystic, setCheck_Value13] = useState(false);
  const handle_checkCystic = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "checkCystic",
        value: e,
      })
    );
    setCheck_Value13(e);
  };
  const [checkPulmo, setCheck_Value14] = useState(false);
  const handle_checkPulmo = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "checkPulmo",
        value: e,
      })
    );
    setCheck_Value14(e);
  };
  const [checkTB, setCheck_Value15] = useState(false);
  const handle_checkTB = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "checkTB",
        value: e,
      })
    );
    setCheck_Value15(e);
  };
  const [checkSarco, setCheck_Value16] = useState(false);
  const handle_checkSarco = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "checkSarco",
        value: e,
      })
    );
    setCheck_Value16(e);
  };
  const [checkOther, setCheckOther] = useState(false);
  const handle_checkOther = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "checkOther",
        value: e,
      })
    );
    setCheckOther(e);
  };

  const [neurologicinput, setneurologicinput] = useState("");
  const [muscularinput, setmuscularinput] = useState("");
  const [checkvalue10, setcheckvalue10] = useState(false);
  const [otherinput, setotherinput] = useState("");
  const [arthritisdrop75, setarthritisdrop75] = useState("");
  const [Osteoarthritischeck, setOsteoarthritischeck] = useState(false);
  const [Rheumatoidcheck, setRheumatoidcheck] = useState(false);
  const [Othercheck, setOthercheck] = useState(false);
  const [psychiatric75, setpsychiatric75] = useState(false);
  const [psychiatriccheck1, setpsychiatriccheck1] = useState(false);
  const [psychiatriccheck2, setpsychiatriccheck2] = useState(false);
  const [psychiatriccheck3, setpsychiatriccheck3] = useState(false);
  const [bleeding75, setbleeding75] = useState(false);
  const [bleedingcheck1, setbleedingcheck1] = useState(false);
  const [bleedingcheck2, setbleedingcheck2] = useState(false);
  const [bleedingcheck3, setbleedingcheck3] = useState(false);
  const [bleedingcheck4, setbleedingcheck4] = useState(false);
  const [bleedingcheck5, setbleedingcheck5] = useState(false);
  const [bleedingcheck6, setbleedingcheck6] = useState(false);
  const [bleedingcheck6input, setbleedingcheck6input] = useState("");
  const [seriousradio, setseriousradio] = useState("");
  const [seriousradio2, setseriousradio2] = useState("");
  const [seriousradio3, setseriousradio3] = useState("");
  const [cancerinput, setcancerinput] = useState("");
  const [cancerinput2, setcancerinput2] = useState("");
  const [drop33check1, setdrop33check1] = useState(false);
  const [drop33check2, setdrop33check2] = useState(false);
  const [drop33check3, setdrop33check3] = useState(false);
  const [drop33check4, setdrop33check4] = useState(false);
  const [drop33check5, setdrop33check5] = useState(false);
  const [drop33checkinput, setdrop33checkinput] = useState(false);
  const [pregnantcheck, setpregnantcheck] = useState("");

  const handle_neurologicinput = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "neurologicinput",
        value: e,
      })
    );
    setneurologicinput(e);
  };
  const handle_muscularinput = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "muscularinput",
        value: e,
      })
    );
    setmuscularinput(e);
  };
  const handle_check10 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "checkvalue10",
        value: e,
      })
    );
    setcheckvalue10(e);
  };
  const handle_otherinput = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "otherinput",
        value: e,
      })
    );
    setotherinput(e);
  };
  const handle_arthritisswitch75 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "arthritisdrop75",
        value: e,
      })
    );
    setarthritisdrop75(e);
  };
  const handleOsteoarthritischeck = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "Osteoarthritischeck",
        value: e,
      })
    );
    setOsteoarthritischeck(e);
  };
  const handleRheumatoidcheck = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "Rheumatoidcheck",
        value: e,
      })
    );
    setRheumatoidcheck(e);
  };
  const handleOthercheck = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "Othercheck",
        value: e,
      })
    );
    setOthercheck(e);
  };
  const handlepsychiatric75 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "psychiatric75",
        value: e,
      })
    );
    setpsychiatric75(e);
  };

  const handlepsychiatriccheck1 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "psychiatriccheck1",
        value: e,
      })
    );
    setpsychiatriccheck1(e);
  };
  const handlepsychiatriccheck2 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "psychiatriccheck2",
        value: e,
      })
    );
    setpsychiatriccheck2(e);
  };
  const handlepsychiatriccheck3 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "psychiatriccheck3",
        value: e,
      })
    );
    setpsychiatriccheck3(e);
  };
  const handlebleeding75 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "bleeding75",
        value: e,
      })
    );
    setbleeding75(e);
  };
  const handlebleedingcheck1 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "bleedingcheck1",
        value: e,
      })
    );
    setbleedingcheck1(e);
  };
  const handlebleedingcheck2 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "bleedingcheck2",
        value: e,
      })
    );
    setbleedingcheck2(e);
  };
  const handlebleedingcheck3 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "bleedingcheck3",
        value: e,
      })
    );
    setbleedingcheck3(e);
  };
  const handlebleedingcheck4 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "bleedingcheck4",
        value: e,
      })
    );
    setbleedingcheck4(e);
  };
  const handlebleedingcheck5 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "bleedingcheck5",
        value: e,
      })
    );
    setbleedingcheck5(e);
  };
  const handlebleedingcheck6 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "bleedingcheck6",
        value: e,
      })
    );
    setbleedingcheck6(e);
  };
  const handlebleedingcheck6input = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "bleedingcheck6input",
        value: e,
      })
    );
    setbleedingcheck6input(e);
  };
  const handleseriousradio = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "seriousradio",
        value: e,
      })
    );
    setseriousradio(e);
  };
  const handleseriousradio2 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "seriousradio2",
        value: e,
      })
    );
    setseriousradio2(e);
  };
  const handleseriousradio3 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "seriousradio3",
        value: e,
      })
    );
    setseriousradio3(e);
  };
  const handlecancerinput = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "cancerinput",
        value: e,
      })
    );
    setcancerinput(e);
  };
  const handlecancerinput2 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "cancerinput2",
        value: e,
      })
    );
    setcancerinput2(e);
  };
  const handledrop33check1 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop33check1",
        value: e,
      })
    );
    setdrop33check1(e);
  };
  const handledrop33check2 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop33check2",
        value: e,
      })
    );
    setdrop33check2(e);
  };
  const handledrop33check3 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop33check3",
        value: e,
      })
    );
    setdrop33check3(e);
  };
  const handledrop33check4 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop33check4",
        value: e,
      })
    );
    setdrop33check4(e);
  };
  const handledrop33check5 = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop33check5",
        value: e,
      })
    );
    setdrop33check5(e);
  };
  const handledrop33checkinput = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop33checkinput",
        value: e,
      })
    );
    setdrop33checkinput(e);
  };
  const handlepregnantcheck = (e) => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "pregnantcheck",
        value: e,
      })
    );
    setpregnantcheck(e);
  };

  useEffect(() => {
    dispatch(
      updatePastComplaintFieldsScore({
        key: "drop38",
        value: "",
      })
    );
  }, [pregnantcheck]);

  useEffect(() => {
    setDrop1(Past.knowAllergie);
    setDrop2(Past.reaction);
    setDrop3(Past.allergicLatex);
    setDrop4(Past.bloodPressure);
    setDrop5(Past.heartIllness);
    setDrop_Problem(Past.dropProblem);
    setDrop6(Past.dropIllness);
    setDrop7(Past.cardiologist);
    setDrop8(Past.vascularDisease);
    setDrop9(Past.drop9);
    setDrop10(Past.drop10);
    setDrop11(Past.drop11);
    setDrop12(Past.drop12);
    setDrop13(Past.drop13);
    setDrop14(Past.drop14);
    setDrop15(Past.drop15);
    setDrop16(Past.drop16);
    setDrop17(Past.drop17);
    setDrop18(Past.drop18);
    setDrop19(Past.drop19);
    setDrop20(Past.drop20);
    setDrop87(Past.drop87);
    setDrop21(Past.drop21);
    setDrop22(Past.drop22);
    setDrop23(Past.drop23);
    setDrop75(Past.drop75);
    setDrop24(Past.drop24);
    setDrop25(Past.drop25);
    setDropbleed(Past.bleed);
    setDrop26(Past.drop26);
    setDrop27(Past.drop27);
    setDrop2445(Past.drop2445);
    setDrop2446(Past.drop2446);
    setDrop2447(Past.drop2447);
    setDrop28(Past.drop28);
    setDrop29(Past.drop29);
    setDrop30(Past.drop30);
    setDrop31(Past.drop31);
    setstartDate(new Date(Past.startDate));
    setDrop33(Past.drop33);
    setDrop34(Past.drop34);
    setDrop35(Past.drop35);
    setDrop36(Past.drop36);
    setDrop37(Past.drop37);
    setDrop38(Past.drop38);
    setDrop39(Past.drop39);
    setDrop41(Past.drop41);
    setDrop42(Past.drop42);
    setDrop43(Past.drop43);
    setDrop44(Past.drop44);
    setDrop45(Past.drop45);
    setDrop50(Past.drop50);
    setDrop52(Past.drop52);
    setDrop501(Past.drop501);
    if (Past.drop502) {
      setDrop502(new Date(Past.drop502));
    }
    setDrop503(Past.drop503);
    setDrop510(Past.drop510);
    setDrop511(Past.drop511);
    setDrop512(Past.drop512);
    setDrop99(Past.drop99);
    setCheck_Value(Past.checkvalue);
    setCheck_Value1(Past.checkvalue1);
    setCheck_Value3(Past.checkvalue3);
    setCheck_Value4(Past.checkvalue4);
    setCheck_Value5(Past.checkvalue5);
    setCheck_Value6(Past.checkvalue6);
    setCheck_Value7(Past.checkvalue7);
    setCheck_Value8(Past.checkvalue8);
    setCheck_Value9(Past.checkvalue9);
    setCheck_Value80(Past.checkvalue80);
    setCheck_Value81(Past.checkvalue81);
    setCheck_Value82(Past.checkvalue82);
    setCheck_Value50(Past.checkvalue50);
    setCheck_Value10(Past.checkAsth);
    setCheck_Value11(Past.checkCOPD);
    setCheck_Value12(Past.checkHigh);
    setCheck_Value13(Past.checkCystic);
    setCheck_Value14(Past.checkPulmo);
    setCheck_Value15(Past.checkTB);
    setCheck_Value16(Past.checkSarco);
    setCheckOther(Past.checkOther);
    setDrop24Ch(Past.drop24Ch);
    setDropArt(Past.dropArt);
    setDropLung(Past.droplung);
    setneurologicinput(Past.neurologicinput);
    setmuscularinput(Past.muscularinput);
    setcheckvalue10(Past.checkvalue10);
    setotherinput(Past.otherinput);
    setarthritisdrop75(Past.arthritisdrop75);
    setOsteoarthritischeck(Past.Osteoarthritischeck);
    setRheumatoidcheck(Past.Rheumatoidcheck);
    setOthercheck(Past.Othercheck);
    setpsychiatric75(Past.psychiatric75);
    setpsychiatriccheck1(Past.psychiatriccheck1);
    setpsychiatriccheck2(Past.psychiatriccheck2);
    setpsychiatriccheck3(Past.psychiatriccheck3);
    setbleeding75(Past.bleeding75);
    setbleedingcheck1(Past.bleedingcheck1);
    setbleedingcheck2(Past.bleedingcheck2);
    setbleedingcheck3(Past.bleedingcheck3);
    setbleedingcheck4(Past.bleedingcheck4);
    setbleedingcheck5(Past.bleedingcheck5);
    setbleedingcheck6(Past.bleedingcheck6);
    setbleedingcheck6input(Past.bleedingcheck6input);
    setseriousradio(Past.seriousradio);
    setseriousradio2(Past.seriousradio2);
    setseriousradio3(Past.seriousradio3);
    setcancerinput(Past.cancerinput);
    setcancerinput2(Past.cancerinput2);
    setdrop33check1(Past.drop33check1);
    setdrop33check2(Past.drop33check2);
    setdrop33check3(Past.drop33check3);
    setdrop33check4(Past.drop33check4);
    setdrop33check5(Past.drop33check5);
    setdrop33checkinput(Past.drop33checkinput);
    setpregnantcheck(Past.pregnantcheck);
  }, [Past]);

  // update end --------------------------------
  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${
          doctorEnable === "true" ? "1" : "0"
        }`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log(res);
      });
  };
  // useEffect(() => {
  //   if (chk) {
  //       updateDate();
  //   }
  // }, [Social]);

  const backData = () => {
    axiosinstance.current
      .get(`extapp/forms/urgent_data?resource_id=${rId}`)
      .then((res) => {
        setbackD(res.data.data);
        sessionStorage.setItem("ptid", res.data.data.ak_pt);
      });
  };
  useEffect(() => {
    // save();
    backData();
  }, []);

  const continueRedirect = (e) => {
    try {
      axiosinstance.current
        .put(
          `extapp/doctors/updateSummary?ak_id=${window.atob(
            ptId
          )}&doctor_id=${doctorid}&is_patient=${doctorid ? 0 : 1}`,
          json,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.status) {
            navigate(`/medical-social/${ptId}?doctor=${doctorid}`);
          }
        });
    } catch (err) {
      if (err.response !== undefined ? err.response.status === 401 : null) {
        navigate("/un-authorized-access");
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
          <div className="panel">
            <div className="panel-body p_25">
              {/* <NameCustom/>  */}
              <h4 className="ph_title f_40">
                {translation.patient_general_past_history}
              </h4>
              <div className="row pt_20">
                <div className="col-md-12 col-sm-12">
                  <div className="mb_10">
                    {/* <Dropdown
                      data={[
                        { value: "3", label: "None" },
                        { value: "2", label: "Mild,occasional" },
                        { value: "1", label: "Moderate,daily" },
                        { value: "0", label: "Severe,almost always preset" },
                      ]}
                      label={
                        "Do you currently or have you had any heart conditions/illnesses?"
                      }
                      styleClass=""
                      value={drop1}
                      placeholder={"None"}
                      onChange={handleDrop}
                    /> */}

                    <RadioSwitch
                      labelLeft={translation.patient_general_past_history_q1_a1}
                      labelRight={
                        translation.patient_general_past_history_q1_a2
                      }
                      idLeft={"1"}
                      idRight={"2"}
                      name={"unaffected_shoulder1"}
                      title={translation.patient_general_past_history_q1}
                      styleClass=""
                      value={knowAllergie}
                      onChange={handle_switch1}
                      placeholder="select"
                    />
                    {knowAllergie === "1" && (
                      <InputField
                        label=" "
                        value={reaction}
                        type="textarea"
                        placeholder={
                          translation.patient_general_past_history_placeholder
                        }
                        rows="4"
                        cols="20"
                        onChange={handle_switch2}
                        styleClass="h_150"
                      />
                    )}
                    <RadioSwitch
                      labelLeft={translation.patient_general_past_history_q2_a1}
                      labelRight={
                        translation.patient_general_past_history_q2_a2
                      }
                      idLeft={"3"}
                      idRight={"4"}
                      name={"unaffected_shoulder2"}
                      title={translation.patient_general_past_history_q2}
                      styleClass=""
                      value={allergicLatex}
                      onChange={handle_switch3}
                      placeholder="select"
                    />

                    <RadioSwitch
                      labelLeft={translation.patient_general_past_history_q3_a1}
                      labelRight={
                        translation.patient_general_past_history_q3_a2
                      }
                      idLeft={"5"}
                      idRight={"6"}
                      name={"unaffected_shoulder3"}
                      title={translation.patient_general_past_history_q3}
                      styleClass=""
                      value={bloodPressure}
                      onChange={handle_switch4}
                      placeholder="select"
                    />
                    {bloodPressure === "1" && (
                      <InputField
                        value={drop42}
                        type="textarea"
                        placeholder={
                          translation.patient_general_past_history_q3_a2_placeholder
                        }
                        rows="4"
                        cols="20"
                        onChange={handle_switch42}
                        styleClass="h_150"
                      />
                    )}

                    <RadioSwitch
                      labelLeft={translation.patient_general_past_history_q4_a1}
                      labelRight={
                        translation.patient_general_past_history_q4_a2
                      }
                      idLeft={"7"}
                      idRight={"8"}
                      name={"unaffected_shoulder4"}
                      title={translation.patient_general_past_history_q4}
                      styleClass=""
                      value={heartIllness}
                      onChange={handle_switch5}
                      placeholder="select"
                    />
                    {heartIllness === "1" && (
                      <>
                        <Select
                          isMulti
                          name="weekday"
                          options={[
                            {
                              value: "1",
                              label:
                                translation.patient_general_past_history_q4_a2_o2,
                            },
                            {
                              value: "2",
                              label:
                                translation.patient_general_past_history_q4_a2_o3,
                            },
                            {
                              value: "3",
                              label:
                                translation.patient_general_past_history_q4_a2_o4,
                            },
                            {
                              value: "4",
                              label:
                                translation.patient_general_past_history_q4_a2_o5,
                            },
                            {
                              value: "5",
                              label:
                                translation.patient_general_past_history_q4_a2_o6,
                            },
                            {
                              value: "6",
                              label:
                                translation.patient_general_past_history_q4_a2_o7,
                            },
                            {
                              value: "7",
                              label:
                                translation.patient_general_past_history_q4_a2_o8,
                            },
                            {
                              value: "8",
                              label:
                                translation.patient_general_past_history_q4_a2_o9,
                            },
                            {
                              value: "9",
                              label:
                                translation.patient_general_past_history_q4_a2_o10,
                            },
                          ]}
                          onChange={handle_drop1}
                          value={dropIllness}
                          placeholder={
                            <div>
                              {
                                translation.patient_general_past_history_q4_a2_o1
                              }
                            </div>
                          }
                          // className="form-control form-select"
                          classNamePrefix="select"
                          // placeholder={<div>Type to search</div>}
                        />
                      </>
                      // <Dropdown
                      //   data={[
                      //     {
                      //       value: "0",
                      //       label: translation.patient_general_past_history_q4_a2_o1,
                      //     },
                      //     {
                      //       value: "1",
                      //       label: translation.patient_general_past_history_q4_a2_o2,
                      //     },
                      //     {
                      //       value: "2",
                      //       label: translation.patient_general_past_history_q4_a2_o3,
                      //     },
                      //     {
                      //       value: "3",
                      //       label: translation.patient_general_past_history_q4_a2_o4,
                      //     },
                      //     {
                      //       value: "4",
                      //       label: translation.patient_general_past_history_q4_a2_o5,
                      //     },
                      //     {
                      //       value: "5",
                      //       label: translation.patient_general_past_history_q4_a2_o6,
                      //     },
                      //     {
                      //       value: "6",
                      //       label: translation.patient_general_past_history_q4_a2_o7,
                      //     },
                      //     {
                      //       value: "7",
                      //       label: translation.patient_general_past_history_q4_a2_o8,
                      //     },
                      //     {
                      //       value: "8",
                      //       label: translation.patient_general_past_history_q4_a2_o9,
                      //     },
                      //     {
                      //       value: "9",
                      //       label: translation.patient_general_past_history_q4_a2_o10,
                      //     },
                      //   ]}
                      //   styleClass=""
                      //   value={dropIllness}
                      //   onChange={handle_drop1}
                      // />
                    )}
                    <div className="mt-4">
                      {Array.isArray(dropIllness) &&
                        dropIllness.filter((item) => item.value === "7")[0] && (
                          <InputField
                            value={drop43}
                            type="textarea"
                            placeholder={
                              translation.patient_general_past_history_q4_a2_o8_q1_placeholder
                            }
                            rows="4"
                            cols="20"
                            onChange={handle_switch43}
                            styleClass="h_150"
                          />
                        )}
                      {Array.isArray(dropIllness) &&
                        dropIllness.filter((item) => item.value === "9")[0] && (
                          <InputField
                            value={drop44}
                            type="textarea"
                            placeholder={
                              translation.patient_general_past_history_q4_a2_o10_q1_placeholder
                            }
                            rows="4"
                            cols="20"
                            onChange={handle_switch44}
                            styleClass="h_150"
                          />
                        )}
                    </div>
                    <RadioSwitch
                      labelLeft={translation.patient_general_past_history_q5_a1}
                      labelRight={
                        translation.patient_general_past_history_q5_a2
                      }
                      idLeft={"seriousradio741"}
                      idRight={"seriousradio751"}
                      name={"arthritisseriousradio75"}
                      title={translation.patient_general_past_history_q5}
                      styleClass=""
                      formGroupClass="mt-2"
                      value={seriousradio}
                      onChange={handleseriousradio}
                      placeholder="select"
                    />
                    {seriousradio === "1" && (
                      <InputField
                        value={cardiologist}
                        type="textarea"
                        placeholder={
                          translation.patient_general_past_history_q5_a2_placeholder
                        }
                        rows="4"
                        cols="20"
                        onChange={handle_switch7}
                        styleClass="h_150"
                      />
                    )}

                    <RadioSwitch
                      labelLeft={translation.patient_general_past_history_q6_a1}
                      labelRight={
                        translation.patient_general_past_history_q6_a2
                      }
                      idLeft={"seriousradio2741"}
                      idRight={"seriousradio2751"}
                      name={"arthritisseriousradio275"}
                      title={translation.patient_general_past_history_q6}
                      styleClass=""
                      value={seriousradio2}
                      onChange={handleseriousradio2}
                      placeholder="select"
                    />
                    {seriousradio2 === "1" && (
                      <InputField
                        value={vascularDisease}
                        type="textarea"
                        placeholder={
                          translation.patient_general_past_history_q6_a2_placeholder
                        }
                        rows="4"
                        cols="20"
                        onChange={handle_switch8}
                        styleClass="h_150"
                      />
                    )}
                    <RadioSwitch
                      labelLeft={translation.patient_general_past_history_q7_a1}
                      labelRight={
                        translation.patient_general_past_history_q7_a2
                      }
                      idLeft={"9"}
                      idRight={"10"}
                      name={"unaffected_shoulder5"}
                      title={translation.patient_general_past_history_q7}
                      styleClass=""
                      value={drop9}
                      onChange={handle_switch9}
                      placeholder="select"
                    />
                    {drop9 === "1" && (
                      <>
                        <label>
                          {translation.patient_general_past_history_q7_a2_q1}
                        </label>
                        <Select
                          value={drop10}
                          isMulti
                          name="weekday"
                          options={[
                            {
                              value: "4",
                              label:
                                translation.patient_general_past_history_q7_a2_q1_a1,
                            },
                            {
                              value: "3",
                              label:
                                translation.patient_general_past_history_q7_a2_q1_a2,
                            },
                            {
                              value: "2",
                              label:
                                translation.patient_general_past_history_q7_a2_q1_a3,
                            },
                            {
                              value: "1",
                              label:
                                translation.patient_general_past_history_q7_a2_q1_a4,
                            },
                            {
                              value: "0",
                              label:
                                translation.patient_general_past_history_q7_a2_q1_a5,
                            },
                          ]}
                          onChange={handle_switch10}
                          // className="form-control form-select"
                          placeholder={
                            <div>
                              {
                                translation.patient_general_past_history_q7_a2_q1_a5_placeholder
                              }
                            </div>
                          }
                          classNamePrefix="select"
                        />
                        {/* <Dropdown
                          data={[
                            {
                              value: "4",
                              label: translation.patient_general_past_history_q7_a2_q1_a1,
                            },
                            {
                              value: "3",
                              label: translation.patient_general_past_history_q7_a2_q1_a2,
                            },
                            {
                              value: "2",
                              label: translation.patient_general_past_history_q7_a2_q1_a3,
                            },
                            {
                              value: "1",
                              label: translation.patient_general_past_history_q7_a2_q1_a4,
                            },
                            {
                              value: "0",
                              label: translation.patient_general_past_history_q7_a2_q1_a5,
                            },
                          ]}
                          label={translation.patient_general_past_history_q7_a2_q1}
                          styleClass=""
                          value={drop10}
                          onChange={handle_switch10}
                        /> */}
                        {Array.isArray(drop10) &&
                          drop10.filter((item) => item.value === "0")[0] && (
                            <div className="mt-4">
                              <InputField
                                value={drop503}
                                type="textarea"
                                placeholder={
                                  translation.patient_general_past_history_q7_a2_q1_a5_placeholder
                                }
                                rows="4"
                                cols="20"
                                onChange={handle_switch503}
                                // styleClass="h_150"
                              />
                            </div>
                          )}
                      </>
                    )}

                    {/* -----------checkbox ---------------- */}
                    <div className="mt-4">
                      <RadioSwitch
                        labelLeft={
                          translation.patient_general_past_history_q8_a1
                        }
                        labelRight={
                          translation.patient_general_past_history_q8_a2
                        }
                        idLeft={"seriousradio3741"}
                        idRight={"seriousradio3751"}
                        name={"arthritisseriousradio375"}
                        title={translation.patient_general_past_history_q8}
                        styleClass=""
                        value={seriousradio3}
                        onChange={handleseriousradio3}
                        placeholder="select"
                      />

                      {seriousradio3 === "1" && (
                        <>
                          <Select
                            isMulti
                            name="weekday"
                            options={[
                              {
                                value: "0",
                                label:
                                  translation.patient_general_past_history_q8_a2_o1,
                              },
                              {
                                value: "1",
                                label:
                                  translation.patient_general_past_history_q8_a2_o2,
                              },
                              {
                                value: "2",
                                label:
                                  translation.patient_general_past_history_q8_a2_o3,
                              },
                            ]}
                            onChange={handle_switchlung}
                            value={droplung}
                            // className="form-control form-select"
                            placeholder={
                              <div>
                                {
                                  translation.patient_general_past_history_q4_a2_o1
                                }
                              </div>
                            }
                          />
                          {/* <Dropdown
                            data={[
                              {
                                value: "0",
                                label: translation.patient_general_past_history_q8_a2_o1,
                              },
                              {
                                value: "1",
                                label: translation.patient_general_past_history_q8_a2_o2,
                              },
                              {
                                value: "2",
                                label: translation.patient_general_past_history_q8_a2_o3,
                              },
                            ]}
                            label={""}
                            styleClass=""
                            value={droplung}
                            placeholder={translation.none}
                            onChange={handle_switchlung}
                          /> */}
                          <div className="mt-4">
                            {Array.isArray(droplung) &&
                              droplung.filter(
                                (item) => item.value === "1"
                              )[0] && (
                                <div className="row col-12">
                                  <div className="col-6">
                                    <Checkbox
                                      label={
                                        translation.patient_general_past_history_q8_a2_o2_o1
                                      }
                                      selected={checkAsth}
                                      value={checkAsth}
                                      onChange={handle_checkAsth}
                                      styleClass=""
                                    />
                                  </div>
                                  <div className="col-6">
                                    <Checkbox
                                      label={
                                        translation.patient_general_past_history_q8_a2_o2_o2
                                      }
                                      selected={checkCOPD}
                                      value={checkCOPD}
                                      onChange={handle_checkCOPD}
                                      styleClass=""
                                    />
                                  </div>
                                  <div className="col-6">
                                    <Checkbox
                                      label={
                                        translation.patient_general_past_history_q8_a2_o2_o3
                                      }
                                      selected={checkHigh}
                                      value={checkHigh}
                                      onChange={handle_checkHigh}
                                      styleClass=""
                                    />
                                  </div>
                                  <div className="col-6">
                                    <Checkbox
                                      label={
                                        translation.patient_general_past_history_q8_a2_o2_o4
                                      }
                                      selected={checkCystic}
                                      value={checkCystic}
                                      onChange={handle_checkCystic}
                                      styleClass=""
                                    />
                                  </div>
                                  <div className="col-6">
                                    <Checkbox
                                      label={
                                        translation.patient_general_past_history_q8_a2_o2_o5
                                      }
                                      selected={checkPulmo}
                                      value={checkPulmo}
                                      onChange={handle_checkPulmo}
                                      styleClass=""
                                    />
                                  </div>
                                  <div className="col-6">
                                    <Checkbox
                                      label={
                                        translation.patient_general_past_history_q8_a2_o2_o6
                                      }
                                      selected={checkTB}
                                      value={checkTB}
                                      onChange={handle_checkTB}
                                      styleClass=""
                                    />
                                  </div>
                                  <div className="col-6">
                                    <Checkbox
                                      label={
                                        translation.patient_general_past_history_q8_a2_o2_o7
                                      }
                                      selected={checkSarco}
                                      value={checkSarco}
                                      onChange={handle_checkSarco}
                                      styleClass=""
                                    />
                                  </div>

                                  <div className="col-6">
                                    <Checkbox
                                      label={
                                        translation.patient_general_past_history_q8_a2_o2_o8
                                      }
                                      selected={checkOther}
                                      value={checkOther}
                                      onChange={handle_checkOther}
                                      styleClass=""
                                    />
                                  </div>

                                  {checkHigh && (
                                    <InputField
                                      value={drop511}
                                      type="textarea"
                                      placeholder={
                                        translation.patient_general_past_history_q8_a2_o3_placehold
                                      }
                                      rows="4"
                                      cols="20"
                                      onChange={handle_switch511}
                                    />
                                  )}

                                  {checkOther && (
                                    <InputField
                                      value={drop512}
                                      type="textarea"
                                      placeholder={
                                        translation.patient_general_past_history_q8_a2_o2_o8_placeholder
                                      }
                                      rows="4"
                                      cols="20"
                                      onChange={handle_switch512}
                                    />
                                  )}
                                </div>
                              )}
                            {Array.isArray(droplung) &&
                              droplung.filter(
                                (item) => item.value === "2"
                              )[0] && (
                                <InputField
                                  value={drop510}
                                  type="textarea"
                                  placeholder={
                                    translation.patient_general_past_history_q8_a2_o3_placehold
                                  }
                                  rows="4"
                                  cols="20"
                                  onChange={handle_switch510}
                                />
                              )}
                          </div>
                        </>
                      )}

                      {/* ------------------any trouble with breadthing ----------------- */}
                    </div>

                    <RadioSwitch
                      labelLeft={translation.patient_general_past_history_q9_a1}
                      labelRight={
                        translation.patient_general_past_history_q9_a2
                      }
                      idLeft={"13"}
                      idRight={"14"}
                      name={"unaffected_shoulder6"}
                      title={translation.patient_general_past_history_q9}
                      styleClass=""
                      formGroupClass="mt-2"
                      value={drop11}
                      onChange={handle_switch11}
                      placeholder="select"
                    />

                    <RadioSwitch
                      labelLeft={
                        translation.patient_general_past_history_q10_a1
                      }
                      labelRight={
                        translation.patient_general_past_history_q10_a2
                      }
                      idLeft={"98"}
                      idRight={"99"}
                      name={"unaffected_shoulder99"}
                      title={translation.patient_general_past_history_q10}
                      styleClass=""
                      value={drop12}
                      onChange={handle_switch12}
                      placeholder="select"
                    />

                    <RadioSwitch
                      labelLeft={
                        translation.patient_general_past_history_q11_a1
                      }
                      labelRight={
                        translation.patient_general_past_history_q11_a2
                      }
                      idLeft={"96"}
                      idRight={"97"}
                      name={"unaffected_shoulder98"}
                      title={translation.patient_general_past_history_q11}
                      styleClass=""
                      value={drop99}
                      onChange={handle_switch99}
                    />

                    {drop99 === "1" && (
                      <InputField
                        value={drop13}
                        type="textarea"
                        placeholder={
                          translation.patient_general_past_history_q11_a2_placeholder
                        }
                        rows="4"
                        cols="20"
                        onChange={handle_switch13}
                        // styleClass="h_150"
                      />
                    )}

                    <RadioSwitch
                      labelLeft={
                        translation.patient_general_past_history_q12_a1
                      }
                      labelRight={
                        translation.patient_general_past_history_q12_a2
                      }
                      idLeft={"92"}
                      idRight={"93"}
                      name={"unaffected_shoulder96"}
                      title={translation.patient_general_past_history_q12}
                      styleClass=""
                      value={drop14}
                      onChange={handle_switch14}
                    />

                    {drop14 === "1" && (
                      <RadioSwitch
                        labelLeft={
                          translation.patient_general_past_history_q12_a2_q1_a1
                        }
                        labelRight={
                          translation.patient_general_past_history_q12_a2_q1_a2
                        }
                        idLeft={"94"}
                        idRight={"95"}
                        name={"unaffected_shoulder97"}
                        title={
                          translation.patient_general_past_history_q12_a2_q1
                        }
                        styleClass=""
                        value={drop15}
                        onChange={handle_switch15}
                      />
                    )}

                    <RadioSwitch
                      labelLeft={
                        translation.patient_general_past_history_q13_a1
                      }
                      labelRight={
                        translation.patient_general_past_history_q13_a2
                      }
                      idLeft={"81"}
                      idRight={"80"}
                      name={"unaffected_shoulder80"}
                      title={translation.patient_general_past_history_q13}
                      styleClass=""
                      value={drop16}
                      onChange={handle_drop16}
                      placeholder="select"
                    />
                    <div className="mt-3">
                      {drop16 === "1" && (
                        <>
                          <Checkbox
                            label={
                              translation.patient_general_past_history_q13_a2_o2
                            }
                            selected={checkvalue80}
                            value={checkvalue80}
                            onChange={handle_check80}
                            styleClass=""
                          />
                          <Checkbox
                            label={
                              translation.patient_general_past_history_q13_a2_o3
                            }
                            selected={checkvalue81}
                            value={checkvalue81}
                            onChange={handle_check81}
                            styleClass=""
                          />
                          <Checkbox
                            label={
                              translation.patient_general_past_history_q13_a2_o4
                            }
                            selected={checkvalue82}
                            value={checkvalue82}
                            onChange={handle_check82}
                            styleClass=""
                          />
                          <Checkbox
                            label={
                              translation.patient_general_past_history_q13_a2_o5
                            }
                            selected={checkvalue50}
                            value={checkvalue50}
                            onChange={handle_check50}
                            styleClass=""
                          />

                          {checkvalue50 && (
                            <InputField
                              value={drop50}
                              type="textarea"
                              placeholder={
                                translation.patient_general_past_history_q13_a2_o5_placeholder
                              }
                              rows="4"
                              cols="20"
                              onChange={handle_switch55}
                            />
                          )}
                        </>
                      )}
                    </div>

                    <RadioSwitch
                      labelLeft={
                        translation.patient_general_past_history_q14_a1
                      }
                      labelRight={
                        translation.patient_general_past_history_q14_a2
                      }
                      idLeft={"90"}
                      idRight={"91"}
                      name={"unaffected_shoulder95"}
                      title={translation.patient_general_past_history_q14}
                      styleClass=""
                      value={drop17}
                      onChange={handle_switch17}
                      placeholder="select"
                    />

                    <RadioSwitch
                      labelLeft={
                        translation.patient_general_past_history_q15_a1
                      }
                      labelRight={
                        translation.patient_general_past_history_q15_a2
                      }
                      idLeft={"83"}
                      idRight={"84"}
                      name={"unaffected_shoulder84"}
                      title={translation.patient_general_past_history_q15}
                      styleClass=""
                      value={drop18}
                      onChange={handle_switch18}
                      placeholder="select"
                    />
                    {drop18 === "1" && (
                      <>
                        <InputField
                          value={drop52}
                          type="textarea"
                          placeholder={
                            translation.patient_general_past_history_q15_a2_placeholder
                          }
                          rows="4"
                          cols="20"
                          onChange={handle_switch52}
                        />

                        <RadioSwitch
                          labelLeft={
                            translation.patient_general_past_history_q15_a2_q1_a1
                          }
                          labelRight={
                            translation.patient_general_past_history_q15_a2_q1_a2
                          }
                          idLeft={"85"}
                          idRight={"86"}
                          name={"unaffected_shoulder85"}
                          title={
                            translation.patient_general_past_history_q15_a2_q1
                          }
                          styleClass=""
                          value={drop19}
                          onChange={handle_switch19}
                        />

                        {drop19 === "1" && (
                          <InputField
                            value={drop501}
                            type="textarea"
                            placeholder={
                              translation.patient_general_past_history_q15_a2_q1_a2_placeholder
                            }
                            rows="4"
                            cols="20"
                            onChange={handle_switch501}
                          />
                        )}
                      </>
                    )}

                    <RadioSwitch
                      labelLeft={
                        translation.patient_general_past_history_q16_a1
                      }
                      labelRight={
                        translation.patient_general_past_history_q16_a2
                      }
                      idLeft={"87"}
                      idRight={"88"}
                      name={"unaffected_shoulder87"}
                      title={translation.patient_general_past_history_q16}
                      styleClass=""
                      value={drop20}
                      onChange={handle_switch20}
                      placeholder="select"
                    />

                    {drop20 === "1" && (
                      <InputField
                        label={
                          translation.patient_general_past_history_q16_a2_q1
                        }
                        value={drop87}
                        type="textarea"
                        placeholder={
                          translation.patient_general_past_history_q16_a2_q1_placeholder
                        }
                        rows="4"
                        cols="20"
                        onChange={handle_switch87}
                      />
                    )}

                    <RadioSwitch
                      labelLeft={
                        translation.patient_general_past_history_q17_a1
                      }
                      labelRight={
                        translation.patient_general_past_history_q17_a2
                      }
                      idLeft={"72"}
                      idRight={"73"}
                      name={"unaffected_shoulder71"}
                      title={translation.patient_general_past_history_q17}
                      styleClass=""
                      value={drop21}
                      onChange={handle_switch21}
                      placeholder="select"
                    />

                    <RadioSwitch
                      labelLeft={
                        translation.patient_general_past_history_q18_a1
                      }
                      labelRight={
                        translation.patient_general_past_history_q18_a2
                      }
                      idLeft={"15"}
                      idRight={"16"}
                      name={"unaffected_shoulder7"}
                      title={translation.patient_general_past_history_q18}
                      styleClass=""
                      value={drop22}
                      onChange={handle_switch22}
                      placeholder="select"
                    />

                    {drop22 === "1" && (
                      <InputField
                        label={
                          translation.patient_general_past_history_q18_a2_q1
                        }
                        value={drop23}
                        type="textarea"
                        placeholder={
                          translation.patient_general_past_history_q18_a2_q1_placeholder
                        }
                        rows="4"
                        cols="20"
                        onChange={handle_switch23}
                        // styleClass="h_150"
                      />
                    )}

                    <RadioSwitch
                      labelLeft={
                        translation.patient_general_past_history_q19_a1
                      }
                      labelRight={
                        translation.patient_general_past_history_q19_a2
                      }
                      idLeft={"74"}
                      idRight={"75"}
                      name={"unaffected_shoulder75"}
                      title={translation.patient_general_past_history_q19}
                      styleClass=""
                      value={drop75}
                      onChange={handle_switch75}
                      placeholder="select"
                    />

                    {drop75 === "1" && (
                      <div>
                        <Checkbox
                          label={
                            translation.patient_general_past_history_q19_a2_o1
                          }
                          selected={checkvalue3}
                          value={checkvalue3}
                          onChange={handle_check3}
                          styleClass=""
                        />
                        {checkvalue3 === true && (
                          <InputField
                            value={drop24}
                            type="textarea"
                            placeholder={
                              translation.patient_general_past_history_q19_a2_o1_placeholder
                            }
                            rows="4"
                            cols="20"
                            onChange={handle_switch24}
                          />
                        )}

                        <Checkbox
                          label={
                            translation.patient_general_past_history_q19_a2_o2
                          }
                          selected={checkvalue4}
                          value={checkvalue4}
                          onChange={handle_check4}
                          styleClass=""
                        />
                        {checkvalue4 === true && (
                          <InputField
                            value={drop24Ch}
                            type="textarea"
                            placeholder={
                              translation.patient_general_past_history_q19_a2_o2_placeholder
                            }
                            rows="4"
                            cols="20"
                            onChange={handle_switch24Ch}
                          />
                        )}
                        <Checkbox
                          label={
                            translation.patient_general_past_history_q19_a2_o3
                          }
                          selected={checkvalue5}
                          value={checkvalue5}
                          onChange={handle_check5}
                          styleClass=""
                        />
                        <Checkbox
                          label={
                            translation.patient_general_past_history_q19_a2_o4
                          }
                          selected={checkvalue6}
                          value={checkvalue6}
                          onChange={handle_check6}
                          styleClass=""
                        />
                        {checkvalue6 && (
                          <InputField
                            value={neurologicinput}
                            type="textarea"
                            placeholder={
                              translation.patient_general_past_history_q19_a2_o4_placeholder
                            }
                            rows="4"
                            cols="20"
                            onChange={handle_neurologicinput}
                          />
                        )}
                        <Checkbox
                          label={
                            translation.patient_general_past_history_q19_a2_o5
                          }
                          selected={checkvalue7}
                          value={checkvalue7}
                          onChange={handle_check7}
                          styleClass=""
                        />
                        {checkvalue7 && (
                          <InputField
                            value={muscularinput}
                            type="textarea"
                            placeholder={
                              translation.patient_general_past_history_q19_a2_o5_placeholder
                            }
                            rows="4"
                            cols="20"
                            onChange={handle_muscularinput}
                          />
                        )}
                        <Checkbox
                          label={
                            translation.patient_general_past_history_q19_a2_o6
                          }
                          selected={checkvalue8}
                          onChange={handle_check8}
                          value={checkvalue8}
                          styleClass=""
                        />
                        <Checkbox
                          label={
                            translation.patient_general_past_history_q19_a2_o7
                          }
                          selected={checkvalue9}
                          onChange={handle_check9}
                          value={checkvalue9}
                          styleClass=""
                        />
                        <Checkbox
                          label={
                            translation.patient_general_past_history_q19_a2_o8
                          }
                          selected={checkvalue10}
                          onChange={handle_check10}
                          value={checkvalue10}
                          styleClass=""
                        />
                        {checkvalue10 && (
                          <InputField
                            value={otherinput}
                            type="textarea"
                            placeholder={
                              translation.patient_general_past_history_q19_a2_o8_placeholder
                            }
                            rows="4"
                            cols="20"
                            onChange={handle_otherinput}
                          />
                        )}
                      </div>
                    )}

                    {/* --------------------------- checkbox end -------------------------- */}
                    <RadioSwitch
                      labelLeft={
                        translation.patient_general_past_history_q20_a1
                      }
                      labelRight={
                        translation.patient_general_past_history_q20_a2
                      }
                      idLeft={"741"}
                      idRight={"751"}
                      name={"arthritis_shoulder75"}
                      title={translation.patient_general_past_history_q20}
                      styleClass=""
                      value={arthritisdrop75}
                      onChange={handle_arthritisswitch75}
                      placeholder="select"
                    />
                    {arthritisdrop75 === "1" && (
                      <div>
                        <Checkbox
                          label={
                            translation.patient_general_past_history_q20_a2_o1
                          }
                          selected={Osteoarthritischeck}
                          value={Osteoarthritischeck}
                          onChange={handleOsteoarthritischeck}
                          styleClass=""
                        />
                        <Checkbox
                          label={
                            translation.patient_general_past_history_q20_a2_o2
                          }
                          selected={Rheumatoidcheck}
                          value={Rheumatoidcheck}
                          onChange={handleRheumatoidcheck}
                          styleClass=""
                        />
                        <Checkbox
                          label={
                            translation.patient_general_past_history_q19_a2_o8
                          }
                          selected={Othercheck}
                          value={Othercheck}
                          onChange={handleOthercheck}
                          styleClass=""
                        />
                        {Othercheck && (
                          <InputField
                            // label={translation.arthritis}
                            value={dropArt}
                            type="textarea"
                            placeholder={
                              translation.patient_general_past_history_q20_a2_o3_placeholder
                            }
                            rows="4"
                            cols="20"
                            onChange={handle_switchArt}
                            // styleClass="h_150"
                          />
                        )}
                      </div>
                    )}

                    <RadioSwitch
                      labelLeft={
                        translation.patient_general_past_history_q21_a1
                      }
                      labelRight={
                        translation.patient_general_past_history_q21_a2
                      }
                      idLeft={"psychiatric741"}
                      idRight={"psychiatric751"}
                      name={"arthritispsychiatric75"}
                      title={translation.patient_general_past_history_q21}
                      styleClass=""
                      value={psychiatric75}
                      onChange={handlepsychiatric75}
                      placeholder="select"
                    />
                    {psychiatric75 === "1" && (
                      <div>
                        <Checkbox
                          label={
                            translation.patient_general_past_history_q21_a2_o1
                          }
                          selected={psychiatriccheck1}
                          value={psychiatriccheck1}
                          onChange={handlepsychiatriccheck1}
                          styleClass=""
                        />
                        <Checkbox
                          label={
                            translation.patient_general_past_history_q21_a2_o2
                          }
                          selected={psychiatriccheck2}
                          value={psychiatriccheck2}
                          onChange={handlepsychiatriccheck2}
                          styleClass=""
                        />
                        <Checkbox
                          label={
                            translation.patient_general_past_history_q21_a2_o3
                          }
                          selected={psychiatriccheck3}
                          value={psychiatriccheck3}
                          onChange={handlepsychiatriccheck3}
                          styleClass=""
                        />
                        {psychiatriccheck3 && (
                          <InputField
                            // label={translation.psychiatric}
                            value={drop25}
                            type="textarea"
                            placeholder={
                              translation.patient_general_past_history_q21_a2_o3_placeholder
                            }
                            rows="4"
                            cols="20"
                            onChange={handle_switch25}
                          />
                        )}
                      </div>
                    )}

                    <RadioSwitch
                      labelLeft={
                        translation.patient_general_past_history_q22_a1
                      }
                      labelRight={
                        translation.patient_general_past_history_q22_a2
                      }
                      idLeft={"bleeding741"}
                      idRight={"bleeding751"}
                      name={"arthritisbleeding75"}
                      title={translation.patient_general_past_history_q22}
                      styleClass=""
                      value={bleeding75}
                      onChange={handlebleeding75}
                      placeholder="select"
                    />
                    {bleeding75 === "1" && (
                      <div>
                        <Checkbox
                          label={
                            translation.patient_general_past_history_q22_a2_o1
                          }
                          selected={bleedingcheck1}
                          value={bleedingcheck1}
                          onChange={handlebleedingcheck1}
                          styleClass=""
                        />
                        <Checkbox
                          label={
                            translation.patient_general_past_history_q22_a2_o2
                          }
                          selected={bleedingcheck2}
                          value={bleedingcheck2}
                          onChange={handlebleedingcheck2}
                          styleClass=""
                        />
                        <Checkbox
                          label={
                            translation.patient_general_past_history_q22_a2_o3
                          }
                          selected={bleedingcheck3}
                          value={bleedingcheck3}
                          onChange={handlebleedingcheck3}
                          styleClass=""
                        />
                        <Checkbox
                          label={
                            translation.patient_general_past_history_q22_a2_o4
                          }
                          selected={bleedingcheck4}
                          value={bleedingcheck4}
                          onChange={handlebleedingcheck4}
                          styleClass=""
                        />
                        <Checkbox
                          label={
                            translation.patient_general_past_history_q22_a2_o5
                          }
                          selected={bleedingcheck5}
                          value={bleedingcheck5}
                          onChange={handlebleedingcheck5}
                          styleClass=""
                        />
                        <Checkbox
                          label={
                            translation.patient_general_past_history_q22_a2_o6
                          }
                          selected={bleedingcheck6}
                          value={bleedingcheck6}
                          onChange={handlebleedingcheck6}
                          styleClass=""
                        />
                        {bleedingcheck6 && (
                          <InputField
                            // label={translation.psychiatric}
                            value={bleedingcheck6input}
                            type="textarea"
                            placeholder={
                              translation.patient_general_past_history_q22_a2_o6_placeholder
                            }
                            rows="4"
                            cols="20"
                            onChange={handlebleedingcheck6input}
                          />
                        )}
                      </div>
                    )}
                    {/* <Dropdown
                      data={[
                        {
                          value: "5",
                          label: translation.Leukemia_Lymphoma,
                        },
                        {
                          value: "4",
                          label: translation.Haemophilia,
                        },
                        {
                          value: "3",
                          label: translation.Blood_Clots,
                        },
                        {
                          value: "2",
                          label: translation.Anaemia,
                        },
                        {
                          value: "1",
                          label: translation.Sickle_Cell,
                        },
                        {
                          value: "0",
                          label: translation.Selected,
                        },
                      ]}
                      label={translation.bleeding}
                      styleClass=""
                      value={bleed}
                      placeholder={"None"}
                      onChange={handle_switchbleed}
                    /> */}
                    <RadioSwitch
                      labelLeft={
                        translation.patient_general_past_history_q23_a1
                      }
                      labelRight={
                        translation.patient_general_past_history_q23_a2
                      }
                      idLeft={"drop26741"}
                      idRight={"drop26751"}
                      name={"arthritisdrop2675"}
                      title={translation.patient_general_past_history_q23}
                      styleClass=""
                      value={drop26}
                      onChange={handle_switch26}
                      placeholder="select"
                    />

                    <RadioSwitch
                      labelLeft={
                        translation.patient_general_past_history_q24_a1
                      }
                      labelRight={
                        translation.patient_general_past_history_q24_a2
                      }
                      idLeft={"drop27741"}
                      idRight={"drop27751"}
                      name={"arthritisdrop2775"}
                      title={translation.patient_general_past_history_q24}
                      styleClass=""
                      value={drop27}
                      onChange={handle_switch504}
                      placeholder="select"
                    />

                    <label className="mt-2 mb-2">
                      {translation.patient_general_past_history_q25}
                    </label>

                    <RadioSwitch
                      labelLeft={
                        translation.patient_general_past_history_q25_a1_o1
                      }
                      labelRight={
                        translation.patient_general_past_history_q25_a1_o2
                      }
                      idLeft={"drop2445"}
                      idRight={"drop2446"}
                      name={"arthritisdrop6666"}
                      title={translation.patient_general_past_history_q25_a1}
                      styleClass=""
                      value={drop2445}
                      onChange={handle_switchdrop2445}
                      placeholder="select"
                    />
                    <RadioSwitch
                      labelLeft={
                        translation.patient_general_past_history_q25_a2_o1
                      }
                      labelRight={
                        translation.patient_general_past_history_q25_a2_o2
                      }
                      idLeft={"drop2447"}
                      idRight={"drop2448"}
                      name={"arthritisdrop6667"}
                      title={translation.patient_general_past_history_q25_a2}
                      styleClass=""
                      value={drop2446}
                      onChange={handle_switchdrop2446}
                      placeholder="select"
                    />
                    <RadioSwitch
                      labelLeft={
                        translation.patient_general_past_history_q25_a3_o1
                      }
                      labelRight={
                        translation.patient_general_past_history_q25_a3_o2
                      }
                      idLeft={"drop2449"}
                      idRight={"drop2450"}
                      name={"arthritisdrop6668"}
                      title={translation.patient_general_past_history_q25_a3}
                      styleClass=""
                      value={drop2447}
                      onChange={handle_switchdrop2447}
                      placeholder="select"
                    />

                    <RadioSwitch
                      labelLeft={
                        translation.patient_general_past_history_q26_a1
                      }
                      labelRight={
                        translation.patient_general_past_history_q26_a2
                      }
                      idLeft={"drop293741"}
                      idRight={"drop293751"}
                      name={"arthritisdrop29375"}
                      title={translation.patient_general_past_history_q26}
                      formGroupClass="mt-3"
                      value={drop29}
                      onChange={handle_switch29}
                      placeholder="select"
                    />
                    {drop29 === "1" && (
                      <div>
                        <InputField
                          // label={translation.patient_general_past_history_q26_a2_placeholder1}
                          value={cancerinput}
                          type="textarea"
                          placeholder={
                            translation.patient_general_past_history_q26_a2_placeholder1
                          }
                          rows="4"
                          cols="20"
                          onChange={handlecancerinput}
                        />
                        <InputField
                          // label={translation.patient_general_past_history_q26_a2_placeholder2}
                          value={cancerinput2}
                          type="textarea"
                          placeholder={
                            translation.patient_general_past_history_q26_a2_placeholder2
                          }
                          rows="4"
                          cols="20"
                          onChange={handlecancerinput2}
                        />
                      </div>
                    )}
                    {/* <InputField
                      label={translation.cancer}
                      value={drop29}
                      type="textarea"
                      placeholder={translation.description}
                      rows="4"
                      cols="20"
                      onChange={handle_switch29}
                    // styleClass="h_150"
                    /> */}
                    <RadioSwitch
                      labelLeft={
                        translation.patient_general_past_history_q27_a1
                      }
                      labelRight={
                        translation.patient_general_past_history_q27_a2
                      }
                      idLeft={"17"}
                      idRight={"18"}
                      name={"unaffected_shoulder8"}
                      title={translation.patient_general_past_history_q27}
                      styleClass=""
                      value={drop30}
                      onChange={handle_switch30}
                      placeholder="select"
                    />
                    <div>
                      {drop30 === "1" && (
                        <>
                          <InputField
                            // label={translation.treatment}
                            value={drop31}
                            type="textarea"
                            placeholder={
                              translation.patient_general_past_history_q27_a2_placeholder1
                            }
                            rows="4"
                            cols="20"
                            onChange={handle_switch31}
                            // styleClass="h_150"
                          />

                          <label>
                            {
                              translation.patient_general_past_history_q27_a2_placeholder2
                            }
                          </label>
                          <DatePicker
                            dateFormat="dd/MM/yyyy"
                            // maxDate={maxDate}
                            minDate={new Date(minDate)}
                            filterDate={(date) => date >= new Date(minDate)}
                            className="form-control mb_2 br_10 w-25"
                            selected={new Date(startDate)}
                            onChange={handleDate}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            locale={
                              lang === "en"
                                ? "en-US"
                                : lang === "ara"
                                ? "ar"
                                : lang === "ger"
                                ? "de"
                                : lang === "ita"
                                ? "it"
                                : lang === "spa"
                                ? "es"
                                : lang === "tha"
                                ? "th"
                                : lang === "fr"
                                ? "fr"
                                : "en-US"
                            }
                          />
                        </>
                      )}
                    </div>
                    <div>
                      <RadioSwitch
                        labelLeft={
                          translation.patient_general_past_history_q28_a1
                        }
                        labelRight={
                          translation.patient_general_past_history_q28_a2
                        }
                        idLeft={"19"}
                        idRight={"20"}
                        name={"unaffected_shoulder9"}
                        title={translation.patient_general_past_history_q28}
                        styleClass=""
                        value={drop33}
                        onChange={handle_switch33}
                        placeholder="select"
                      />
                      {drop33 === "1" && (
                        <>
                          <label>
                            {translation.patient_general_past_history_q28_a2_q1}{" "}
                          </label>

                          <Select
                            isMulti
                            name="weekday"
                            options={[
                              {
                                value: "0",
                                label:
                                  translation.patient_general_past_history_q28_a2_q1_o1,
                              },
                              {
                                value: "2",
                                label:
                                  translation.patient_general_past_history_q28_a2_q1_o2,
                              },
                              {
                                value: "3",
                                label:
                                  translation.patient_general_past_history_q28_a2_q1_o3,
                              },
                              {
                                value: "4",
                                label:
                                  translation.patient_general_past_history_q28_a2_q1_o4,
                              },
                              {
                                value: "5",
                                label:
                                  translation.patient_general_past_history_q28_a2_q1_o5,
                              },
                            ]}
                            onChange={handledrop33check1}
                            value={drop33check1}
                            // className="form-control form-select"
                            placeholder={
                              <div>
                                {
                                  translation.patient_general_past_history_q28_a2_q1_placeholder
                                }
                              </div>
                            }
                          />
                          {Array.isArray(drop33check1) &&
                          drop33check1.filter((val) => val.value === "5")[0] ? (
                            <div className="mt-4">
                              <InputField
                                //  label={translation.treatment}
                                value={drop33checkinput}
                                type="textarea"
                                placeholder={
                                  translation.patient_general_past_history_q28_a2_q1_o5_placeholder
                                }
                                rows="4"
                                cols="20"
                                onChange={handledrop33checkinput}
                                // styleClass="h_150"
                              />
                            </div>
                          ) : (
                            ""
                          )}

                          {/* <div>
                            <Checkbox
                              label={translation.patient_general_past_history_q28_a2_q1_o1}
                              selected={drop33check1}
                              value={drop33check1}
                              onChange={handledrop33check1}
                              styleClass=""
                            />
                            <Checkbox
                              label={translation.patient_general_past_history_q28_a2_q1_o2}
                              selected={drop33check2}
                              value={drop33check2}
                              onChange={handledrop33check2}
                              styleClass=""
                            />
                            <Checkbox
                              label={translation.patient_general_past_history_q28_a2_q1_o3}
                              selected={drop33check3}
                              value={drop33check3}
                              onChange={handledrop33check3}
                              styleClass=""
                            />
                            <Checkbox
                              label={translation.patient_general_past_history_q28_a2_q1_o4}
                              selected={drop33check4}
                              value={drop33check4}
                              onChange={handledrop33check4}
                              styleClass=""
                            />
                            <Checkbox
                              label={translation.patient_general_past_history_q28_a2_q1_o5}
                              selected={drop33check5}
                              value={drop33check5}
                              onChange={handledrop33check5}
                              styleClass=""
                            />

                          </div> */}
                        </>
                      )}
                    </div>
                    <div className="mt-3">
                      <strong className="d-inline-block mb_15 form-label">
                        {translation.patient_general_past_history_q29}
                      </strong>
                      <RadioSwitch
                        labelLeft={
                          translation.patient_general_past_history_q29_q1_a1
                        }
                        labelRight={
                          translation.patient_general_past_history_q29_q1_a2
                        }
                        idLeft={"21"}
                        idRight={"22"}
                        name={"unaffected_shoulder10"}
                        title={translation.patient_general_past_history_q29_q1}
                        styleClass=""
                        value={drop35}
                        onChange={handle_switch35}
                        placeholder="select"
                      />
                      <RadioSwitch
                        labelLeft={
                          translation.patient_general_past_history_q29_q2_a1
                        }
                        labelRight={
                          translation.patient_general_past_history_q29_q2_a2
                        }
                        idLeft={"23"}
                        idRight={"24"}
                        name={"unaffected_shoulde11"}
                        title={translation.patient_general_past_history_q29_q2}
                        styleClass=""
                        value={drop36}
                        onChange={handle_switch36}
                        placeholder="select"
                      />
                      <RadioSwitch
                        labelLeft={
                          translation.patient_general_past_history_q29_q3_a1
                        }
                        labelRight={
                          translation.patient_general_past_history_q29_q3_a2
                        }
                        idLeft={"25"}
                        idRight={"26"}
                        name={"unaffected_shoulder12"}
                        title={translation.patient_general_past_history_q29_q3}
                        styleClass=""
                        value={drop37}
                        onChange={handle_switch37}
                        placeholder="select"
                      />

                      <RadioSwitch
                        labelLeft={
                          translation.patient_general_past_history_q30_a1
                        }
                        labelRight={
                          translation.patient_general_past_history_q30_a2
                        }
                        idLeft={"27"}
                        idRight={"28"}
                        name={"unaffected_shoulder13"}
                        title={translation.patient_general_past_history_q30}
                        styleClass=""
                        value={drop38}
                        onChange={handle_switch38}
                        placeholder="select"
                        disabled={pregnantcheck}
                      />
                      <Checkbox
                        label={
                          translation.patient_general_past_history_q30_not_applicable
                        }
                        selected={pregnantcheck}
                        value={pregnantcheck}
                        onChange={handlepregnantcheck}
                        styleClass=""
                      />

                      {!pregnantcheck && (
                        <>
                          <label>
                            {translation.patient_general_past_history_q30_a2_q1}
                          </label>
                          <DatePicker
                            minDate={new Date(minDate)}
                            filterDate={(date) => date >= new Date(minDate)}
                            dateFormat="dd/MM/yyyy"
                            // maxDate={maxDate}
                            className="form-control mb_0 br_10 w-25"
                            //  selected={new Date(startDate)}
                            placeholderText={
                              translation.patient_general_past_history_q30_a2_q1_placeholder
                            }
                            // placeholderText="Select End Date"
                            onChange={handle_switch502}
                            selected={drop502}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            locale={
                              lang === "en"
                                ? "en-US"
                                : lang === "ara"
                                ? "ar"
                                : lang === "ger"
                                ? "de"
                                : lang === "ita"
                                ? "it"
                                : lang === "spa"
                                ? "es"
                                : lang === "tha"
                                ? "th"
                                : lang === "fr"
                                ? "fr"
                                : "en-US"
                            }
                          />
                        </>
                      )}

                      <div className="mt-4"></div>
                      <InputField
                        label={translation.patient_general_past_history_q31}
                        value={drop39}
                        type="textarea"
                        rows="4"
                        cols="20"
                        onChange={handle_switch39}
                        styleClass="h_150"
                      />
                      <strong className="d-inline-block mb_15 form-label">
                        {translation.patient_general_past_history_q31_q1}
                      </strong>
                      <InputField
                        label={translation.patient_general_past_history_q31_q2}
                        value={drop41}
                        type="textarea"
                        placeholder={
                          translation.patient_general_past_history_q31_q2_placeholder
                        }
                        rows="4"
                        cols="20"
                        onChange={handle_switch41}
                        styleClass="h_150"
                      />

                      <InputField
                        label={translation.patient_general_past_history_q31_q6}
                        value={drop45}
                        type="textarea"
                        placeholder={
                          translation.patient_general_past_history_q31_q6_placeholder
                        }
                        rows="4"
                        cols="20"
                        onChange={handle_switch45}
                        styleClass="h_150"
                      />
                    </div>
                  </div>
                </div>
                {doctorEnable !== "true" && (
                  <div className="row mb_30 button_box">
                    <div className="col-md-6 col-sm-6 col-6">
                      <Link
                        to={`/medical-history/${ptId}?doctor=${doctorid}&is_doctor_form=${doctordata}`}
                        className="assm_back_btn"
                      >
                        <img src={backIcon} alt="" />
                        <span>{translation.back_button}</span>
                      </Link>
                    </div>
                    <div className="col-md-6 col-sm-6 col-6 text-end">
                      <button
                        className="next_btn btn_fill"
                        onClick={continueRedirect}
                      >
                        {translation.next_button}
                        <span>
                          <img src={nextIcon} alt="" />
                          <img src={nextWhiteIcon} alt="" />
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
