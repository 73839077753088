import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../../images/back.png";
import nextWhiteIcon from "../../../../../images/next-white.png";
import nextIcon from "../../../../../images/next.png";
import LoadingSpinner from "../../../../custom/loader/LoadingSpinner";
import Dropdown from "../../../../custom/dropdown/Dropdown";
import RangeSlider from "../../../../custom/range-slider/RangeSlider";
import {
  updateAdultKneeQolScore,
  updateAdultKneeQolFieldScore,
  updateAdultKneeSymptomsScore,
  updateAdultKneeScores,
  updateAdultKneePainScores,
  updateAdultKneeSatisfactionScore,
  updateAdultKneeFunctionScore,
  updateAdultKneeNorwichInstaScore,
  updateAllData,
  updateAdultKneeFeedback

} from "../../../../../redux/actions/index";
import useAxios from "../../../../../axiosinstance";
import useTransition from '../../../../customHooks/translations';
import AdultkneeTab from '../../../tab-section/AdultkneeTab';
import { useSearchParams } from "react-router-dom";
import CustomToaster from '../../../../custom/toaster/CustomToaster'
import KneeScoreRangeSlider from "../../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import { LeftSide, LeftTrans, RightSide, RigthTrans } from "../../../../../utilities/RightLeftFormat";
import NameCustom from "../../../../custom/name/NameCustom";




function AdultKneeQualityOfLife() {
  const translation = useTransition();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const [howOftenR, setHowOftenR] = useState("0");
  const [howOftenL, setHowOftenL] = useState("0");
  const [lifestyleR, setLifestyleR] = useState("0");
  const [lifestyleL, setLifestyleL] = useState("0");
  const [confidenceR, setConfidenceR] = useState("0");
  const [confidenceL, setConfidenceL] = useState("0");
  const [difficultyR, setDifficultyR] = useState("0");
  const [difficultyL, setDifficultyL] = useState("0");
  const [anxious, setAnxious] = useState("0");
  const [howGood, setHowGood] = useState("0");
  // const [howGoodL, setHowGoodL] = useState("0");
  const [age, setAge] = useState("0")
  const [initialAssesment, setInitialAssesment] = useState(0)

  const evlAdultKnee = useSelector(
    (state) => state.adultKneeScore.evlAdultKnee
  );
  const [patellarInstab] = useSelector(state => state.adultKneeScore.functionScore);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [chk, setChk] = useState(false);
  const [updateTotal, setUpdateTotal] = useState(false);

  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=5`)
      .then((res) => {
        // console.log(res.data.data2);
        setAge(res.data.data2[0].age)
        setInitialAssesment(res.data.data2[0].initial_assessment)
        dispatch(updateAdultKneePainScores(res.data.data2[0].painScore));
        dispatch(updateAdultKneeSymptomsScore(res.data.data2[0].symptomsScore));
        dispatch(updateAdultKneeScores(res.data.data2[0].evlAdultKnee));
        dispatch(updateAdultKneeQolScore(res.data.data2[0].qolScore));
        dispatch(updateAdultKneeFunctionScore(res.data.data2[0].functionScore));
        dispatch(
          updateAdultKneeSatisfactionScore(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateAdultKneeNorwichInstaScore(res.data.data2[0].norwichInstability));
        dispatch(
          updateAdultKneeFeedback(res.data.data2[0].doctorFeedback)
        );
        setChk(true);
      }).catch((err) => {

        navigate('/un-authorized-access');

      });
  };

  useEffect(() => {
    save();
  }, []);
  const KneeqolScore = useSelector((state) => state.adultKneeScore.qolScore[0]);
  const json = useSelector((state) => state.adultKneeScore);
  useEffect(() => {

    setHowOftenR(KneeqolScore.howOftenR)
    setHowOftenL(KneeqolScore.howOftenL)
    setLifestyleR(KneeqolScore.lifestyleR)
    setLifestyleL(KneeqolScore.lifestyleL)
    setConfidenceR(KneeqolScore.confidenceR)
    setConfidenceL(KneeqolScore.confidenceL)
    setDifficultyR(KneeqolScore.difficultyR)
    setDifficultyL(KneeqolScore.difficultyL)
    setAnxious(KneeqolScore.anxious)
    setHowGood(KneeqolScore.howGood)
    // setHowGoodL(KneeqolScore.howGoodL)



  }, [KneeqolScore])

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [KneeqolScore, updateTotal]);

  const handleHowOftenR = (e) => {
    setHowOftenR(e);
    dispatch(updateAdultKneeQolFieldScore({
      "key": 'howOftenR',
      "value": e
    }))
  };
  const handleHowOftenL = (e) => {
    setHowOftenL(e);
    dispatch(updateAdultKneeQolFieldScore({
      "key": 'howOftenL',
      "value": e
    }))
  };

  const handleLifestyleR = (e) => {
    setLifestyleR(e);
    dispatch(updateAdultKneeQolFieldScore({
      "key": 'lifestyleR',
      "value": e
    }))
  };
  const handleLifestyleL = (e) => {
    setLifestyleL(e);
    dispatch(updateAdultKneeQolFieldScore({
      "key": 'lifestyleL',
      "value": e
    }))
  };

  const handleConfidenceR = (e) => {
    setConfidenceR(e);
    dispatch(updateAdultKneeQolFieldScore({
      "key": 'confidenceR',
      "value": e
    }))
  };
  const handleConfidenceL = (e) => {
    setConfidenceL(e);
    dispatch(updateAdultKneeQolFieldScore({
      "key": 'confidenceL',
      "value": e
    }))
  };

  const handleDifficultyR = (e) => {
    setDifficultyR(e);
    dispatch(updateAdultKneeQolFieldScore({
      "key": 'difficultyR',
      "value": e
    }))
  };
  const handleDifficultyL = (e) => {
    setDifficultyL(e);
    dispatch(updateAdultKneeQolFieldScore({
      "key": 'difficultyL',
      "value": e
    }))
  };

  const handleAnxious = (e) => {
    setAnxious(e);
    dispatch(updateAdultKneeQolFieldScore({
      "key": 'anxious',
      "value": e
    }))
  };
  const handleHowGood = (e) => {
    setHowGood(e);
    dispatch(updateAdultKneeQolFieldScore({
      "key": 'howGood',
      "value": e
    }))
    // dispatch(updateAdultKneeQolFieldScore({
    //   "key": 'howGoodL',
    //   "value": e
    // }))
  };


  const continueRedirect = (e) => {
    dispatch(updateAdultKneeQolScore(
      [{
        "howOftenR": howOftenR,
        "howOftenL": howOftenL,
        "lifestyleR": lifestyleR,
        "lifestyleL": lifestyleL,
        "confidenceR": confidenceR,
        "confidenceL": confidenceL,
        "difficultyR": difficultyR,
        "difficultyL": difficultyL,
        "anxious": anxious,
        "howGood": howGood,
        // "howGoodL":howGoodL,
      }]
    ));

      navigate("/adult-knee-satisfaction/" + rId);
    
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  let akpt = searchParams.get("akpt");

  const [msg, setMsg] = useState('');
  const [msgType, setmsgType] = useState('');
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update)
    setmsgType('success')
    setTimeout(() => {
     let view_circular=  sessionStorage.getItem("view_circular")
      // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
      // window.location.href = process.env.REACT_APP_DOCTOR_URL +`view-management-plan/${akpt}/${window.btoa(
      //   rId
      // )}/?org=${window.btoa(sessionStorage.getItem("orgid")||'')}&formType=${
      //   window.btoa('5') || ""
      // }&view_circular=${view_circular==='false'?'false':'true'}`
       window.location.href = process.env.REACT_APP_DOCTOR_URL +`patient-details/${akpt}?formid=5`
      setMsg('')
      setmsgType('success')
    }, 2000);
  };
  const handleNext = () => {
    navigate(`/adult-knee-satisfaction/${rId}?d=true&akpt=${akpt}`);

  }

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
           <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <AdultkneeTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />
          
              <h4 className="ph_title f_40">{translation.patient_adult_knee_quality_of_life}</h4>
              <strong>{translation.patient_adult_knee_quality_of_life_instruction} </strong>
              <div className="function_formtop">
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>

                      <KneeScoreRangeSlider
                        label={translation.patient_adult_knee_quality_of_life_q1 +RigthTrans(evlAdultKnee,translation)}
                        styleClass=""
                        value={howOftenR}
                        placeholder={translation.none}
                        onChange={handleHowOftenR}
                        sliderData={[
                          { score: "0", label: translation.patient_adult_knee_quality_of_life_q1_a1 },
                          { score: "1", label: translation.patient_adult_knee_quality_of_life_q1_a2 },
                          { score: "2", label: translation.patient_adult_knee_quality_of_life_q1_a3 },
                          { score: "3", label: translation.patient_adult_knee_quality_of_life_q1_a4 },
                          { score: "4", label: translation.patient_adult_knee_quality_of_life_q1_a5 },
                        ]}
                      />

                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_adult_knee_quality_of_life_q1 +LeftTrans(evlAdultKnee,translation)}
                        styleClass=""
                        value={howOftenL}
                        placeholder={translation.none}
                        onChange={handleHowOftenL}
                        sliderData={[
                          { score: "0", label: translation.patient_adult_knee_quality_of_life_q1_a1 },
                          { score: "1", label: translation.patient_adult_knee_quality_of_life_q1_a2 },
                          { score: "2", label: translation.patient_adult_knee_quality_of_life_q1_a3 },
                          { score: "3", label: translation.patient_adult_knee_quality_of_life_q1_a4 },
                          { score: "4", label: translation.patient_adult_knee_quality_of_life_q1_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_adult_knee_quality_of_life_q2 +RigthTrans(evlAdultKnee,translation)}
                        styleClass=""
                        value={lifestyleR}
                        placeholder={translation.none}
                        onChange={handleLifestyleR}
                        sliderData={[
                          { score: "0", label: translation.patient_adult_knee_quality_of_life_q2_a1 },
                          { score: "1", label: translation.patient_adult_knee_quality_of_life_q2_a2 },
                          { score: "2", label: translation.patient_adult_knee_quality_of_life_q2_a3 },
                          { score: "3", label: translation.patient_adult_knee_quality_of_life_q2_a4 },
                          { score: "4", label: translation.patient_adult_knee_quality_of_life_q2_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_adult_knee_quality_of_life_q2 +LeftTrans(evlAdultKnee,translation)}
                        styleClass=""
                        value={lifestyleL}
                        placeholder={translation.none}
                        onChange={handleLifestyleL}
                        sliderData={[
                          { score: "0", label: translation.patient_adult_knee_quality_of_life_q2_a1 },
                          { score: "1", label: translation.patient_adult_knee_quality_of_life_q2_a2 },
                          { score: "2", label: translation.patient_adult_knee_quality_of_life_q2_a3 },
                          { score: "3", label: translation.patient_adult_knee_quality_of_life_q2_a4 },
                          { score: "4", label: translation.patient_adult_knee_quality_of_life_q2_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_adult_knee_quality_of_life_q3 +RigthTrans(evlAdultKnee,translation)}
                        styleClass=""
                        value={confidenceR}
                        placeholder={translation.none}
                        onChange={handleConfidenceR}
                        sliderData={[
                          { score: "0", label: translation.patient_adult_knee_quality_of_life_q3_a1 },
                          { score: "1", label: translation.patient_adult_knee_quality_of_life_q3_a2 },
                          { score: "2", label: translation.patient_adult_knee_quality_of_life_q3_a3 },
                          { score: "3", label: translation.patient_adult_knee_quality_of_life_q3_a4 },
                          { score: "4", label: translation.patient_adult_knee_quality_of_life_q3_a5 },
                        ]}
                      />

                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                         label={translation.patient_adult_knee_quality_of_life_q3 +LeftTrans(evlAdultKnee,translation)}
                        styleClass=""
                        value={confidenceL}
                        placeholder={translation.none}
                        onChange={handleConfidenceL}
                        sliderData={[
                          { score: "0", label: translation.patient_adult_knee_quality_of_life_q3_a1},
                          { score: "1", label: translation.patient_adult_knee_quality_of_life_q3_a2},
                          { score: "2", label: translation.patient_adult_knee_quality_of_life_q3_a3},
                          { score: "3", label: translation.patient_adult_knee_quality_of_life_q3_a4},
                          { score: "4", label: translation.patient_adult_knee_quality_of_life_q3_a5},
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <KneeScoreRangeSlider
                       label={translation.patient_adult_knee_quality_of_life_q4 +RigthTrans(evlAdultKnee,translation)}
                        styleClass=""
                        value={difficultyR}
                        placeholder={translation.none}
                        onChange={handleDifficultyR}
                        sliderData={[
                          { score: "0", label: translation.patient_adult_knee_quality_of_life_q4_a1 },
                          { score: "1", label: translation.patient_adult_knee_quality_of_life_q4_a2 },
                          { score: "2", label: translation.patient_adult_knee_quality_of_life_q4_a3 },
                          { score: "3", label: translation.patient_adult_knee_quality_of_life_q4_a4 },
                          { score: "4", label: translation.patient_adult_knee_quality_of_life_q4_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_adult_knee_quality_of_life_q4 +LeftTrans(evlAdultKnee,translation)}
                        styleClass=""
                        value={difficultyL}
                        placeholder={translation.none}
                        onChange={handleDifficultyL}
                        sliderData={[
                          { score: "0", label: translation.patient_adult_knee_quality_of_life_q4_a1 },
                          { score: "1", label: translation.patient_adult_knee_quality_of_life_q4_a2 },
                          { score: "2", label: translation.patient_adult_knee_quality_of_life_q4_a3 },
                          { score: "3", label: translation.patient_adult_knee_quality_of_life_q4_a4 },
                          { score: "4", label: translation.patient_adult_knee_quality_of_life_q4_a5 },
                        ]}
                      />

                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {
                  String(age) && String(age) !== "1" ?
                    <div className="row">
                      <div className="col-md-12 col-sm-12 mb_10">
                        <KneeScoreRangeSlider
                           label={translation.patient_adult_knee_quality_of_life_q5}
                          styleClass=""
                          value={anxious}
                          placeholder={translation.none}
                          onChange={handleAnxious}
                          sliderData={[
                            { score: "0", label: translation.patient_adult_knee_quality_of_life_q5_a1 },
                            { score: "1", label: translation.patient_adult_knee_quality_of_life_q5_a2 },
                            { score: "2", label: translation.patient_adult_knee_quality_of_life_q5_a3 },
                            { score: "3", label: translation.patient_adult_knee_quality_of_life_q5_a4 },
                            { score: "4", label: translation.patient_adult_knee_quality_of_life_q5_a5 },
                          ]}
                        />
                      </div>
                    </div> : <></>
                }

                {age === "2" && (
                  <div className="row mt-5 mb-4">

                    <div className="col-md-12 col-sm-12 mb_10">
                      <RangeSlider
                        id="howGood"
                        min="1"
                        max="100"
                        step="1"
                        type="text"
                        colorcode="1"
                        leftTitle={translation.patient_adult_knee_quality_of_life_q6_instruction_a1}
                        rightTitle={translation.patient_adult_knee_quality_of_life_q6_instruction_a2}
                        label={translation.patient_adult_knee_quality_of_life_q6 +" (" + translation.patient_adult_knee_quality_of_life_q6_instruction + ")"}
                        value={howGood}
                        onChange={handleHowGood}
                      />
                    </div>

                  </div>
                )}


                {/* <div className="row mt-3">
              {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className="col-md-12 col-sm-12 mb_10">
                      <RangeSlider
                        id="rateKneeFunctionL"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        leftTitle="Worst"
                        rightTitle="Best"
                        colorcode="1"
                        label={translation.How_good_bad_is_your_health_today}
                        // label={translation.How_bad_health_today}
                        value={howGoodL}
                        onChange={handleHowGoodL}
                      />
                    </div>
                  ) : ""}
              </div> */}
              </div>



              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6">
                  </div>
                  <div className="col-md-6 col-sm-6 text-end">
                  <button
                      className="next_btn btn_fill me-3"
                      onClick={() => handleNext()}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}

              {doctorEnable !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    <Link to={patellarInstab.patellarInstability === "1" ? `/patellar-instability/${rId}` : patellarInstab.patellarInstabilityL === "1"? `/patellar-instability/${rId}` : `/adult-knee-function/${rId}`} className="assm_back_btn">
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}


            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AdultKneeQualityOfLife;
