import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useAxios from '../../../axiosinstance';
import useTranslation from "../../customHooks/translations";


const DoctorNameCustom = () => {
    const translation = useTranslation();
    let name = sessionStorage.getItem("name")
    let age = sessionStorage.getItem("age")
    let id = sessionStorage.getItem("ptid")
    const axiosInstance = useAxios()
    const { rId ,akDc} = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const patient_id = queryParams.get('patient');
    const akpt = queryParams.get('akpt');
    const doctor_id = queryParams.get('doctor');
    const is_doctor_form = queryParams.get('is_doctor_form');
    const [username, setUserName] = useState("")
    const[userage,setUserage]=useState("")

    const getData2 = async () => {
        try {
            const result = await axiosInstance.current.get(
                `extapp/forms/getUserLanguage?res_id=${rId}&pat_id=${window.atob(patient_id!==null?patient_id:akpt)}&ak_dc=${doctor_id}&is_patient=${is_doctor_form==="true"?"":"1"}`
            );
            // console.log(result)
            if (result.status === 200) {
                setUserName(result.data.name)
                setUserage(result.data.age)
                sessionStorage.setItem("name", result.data.name)
                sessionStorage.setItem("age", result.data.age)
                sessionStorage.setItem("patient_akid", result.data.patient_akid)
                sessionStorage.setItem("dateformat", result.data.date)
                sessionStorage.setItem("timeZone", result.data.timeZone)
            }
        } catch (err) {
            console.log(err.Messages);
        } finally {
            // console.log("api response finally");
        }
    };
    useEffect(() => {
        getData2()
    }, [patient_id,akpt,doctor_id,is_doctor_form])
    return (
        <div className="">
            <div className="float-end whiteboxSmallProfile c_pointer">
                <div className="d-flex ">
                { username !=="" ?(
                    <label className="user-letter-smProfile text-capitalize f_24">{username ? username.charAt(0) : " "}</label>
                ):""}
                    <div className="ms-3">
                        <h6 className="text-capitalize">{`${username} (${window.atob(patient_id!==null?patient_id:akpt)})`}</h6>
                        <span className="">{translation.Age}: {age?age:userage} {userage <= 1 ? translation.year_pdata : translation.years_pdata }  </span>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default DoctorNameCustom;
