import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../images/back.png";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import Button from "../../../custom/button/Button";
import useAxios from "../../../../axiosinstance";
import useTranslation from "../../../customHooks/translations"
import NameCustom from "../../../custom/name/NameCustom";
import Share from "../../Share";
import SubmitModel from "../../SubmissionModel/SubmitModel"
export default function Complete() {
    const [chk, setChk] = useState(false);
    const translation = useTranslation()
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { rId } = useParams();
    const axiosInstance = useAxios();
    const [isdoctorform, setisdoctorform] = useState(Number())
    const [patientID, setpatientID] = useState('');
    const [status, setStatus] = useState("");
    let type = 33;

    const [displayConfirmationModal, setDisplayConfirmationModal] =
        useState(false);
    const continueRedirect2 = (e) => {
        setDisplayConfirmationModal(true)

    };
    const save = () => {
        axiosInstance.current
            .get(`extapp/forms/all_forms?resource_id=${rId}&type=33`)
            .then((res) => {
                setpatientID(res.data.data2[0].patient_id)
                setisdoctorform(res.data.data2[0].is_doctor_form)
                setChk(true);
                setStatus(res.data.data2[0].status);
            }).catch((err) => {

                navigate('/un-authorized-access');

            });
    };

    useEffect(() => {
        save();
    }, []);

    const continueRedirect = () => {
        setDisplayConfirmationModal(true)
        // if (isdoctorform === 1) {
        //     window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + window.btoa(patientID) + "/" + window.btoa(patientID);
        // } else {
        //     window.location.href = process.env.REACT_APP_PATIENT_URL;
        // }
    }
    // console.log(status, "statusstatus");

    return (
        <div>
            <>
                {/* {isLoading ? (
                // <LoadingSpinner />
            ) : ( */}
                <div className="complete_screen mb_100">
                    <div className="panel">
                        <div className="panel-body p_25">
                            <NameCustom />
                            <h4 className="ph_title f_40">{`${translation.patient_general_complete}`}</h4>
                            <div className="row">
                                <div className="col-md-12 col-sm-12 mb_20 pt_20">
                                    <p className="thanks_txt">
                                        {translation.patient_general_complete_text1}
                                        <br /> {translation.patient_general_complete_text2}
                                    </p>
                                    {status === "1" && (
                                        <>
                                            <Button
                                                onClick={continueRedirect}
                                                value={translation.submit}
                                                buttonStyle="btn_fill cmp_submit_btn"
                                            />
                                        </>
                                    )}
                                    {/* <span className="answer_txt">
                                        {translation.patient_general_complete_text3}
                                      
                                    </span> */}
                                </div>
                            </div>

                            <div className="row mb_30 button_box">
                                <div className="col-md-6 col-sm-6">

                                    <Link to={"/general-social/" + rId+`?status=${status}`} className="assm_back_btn">
                                        <img src={backIcon} alt="" />
                                        <span>{translation.back_button}</span>
                                    </Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* )} */}
            </>
            <SubmitModel
                showModal={displayConfirmationModal}
                patientID={window.btoa(patientID)}

            />

            {/* <Share type={type} rId={rId}/> */}
            <div className="row justify-content-center">
                <div className="col-xxl-8 col-lg-10 col-md-12 col-sm-12">
                    <div className="panel">
                        <div className="panel-body p_25">
                            {status === "1" ? (
                                <div className="pt_70 pb_50 text-center">
                                    <h6 className="mb_20 f_600">
                                        {/* {translation.patient_shoulder_results_text2} */}
                                    </h6>
                                    {/* <Button
                  onClick={continueRedirect2}
                  value={translation.Sign_out}
                  buttonStyle="btn_fill result_submit_btn"
                /> */}

                                </div>
                            ) : status === "2" ? (
                                <Share
                                    type={type}
                                    rId={rId}
                                />
                            ) : null}
                        </div>
                    </div>

                </div>
            </div>



        </div>
    )
}
