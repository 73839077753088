import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import KneeScoreRangeSlider from "../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import FootAnkleSatisfactionScoreRangeSlider from "../../../custom/foot-ankle-satisfaction-score-range-slider/FootAnkleSatisfactionScoreRangeSlider";
import {
    updateFootSatisfactionScore,
    updateFootSatisfactionData,
    updateFootAnklePainData,
    updateFootSymptomsData,
    updateFootAnkle,
    updateFootInstabilityData,
    updateFootQolData,
    updateFootFunctionData,
    updateFootFeedbackfootankle,
    updateFootComberlandData,
    updateAllData

} from "../../../../redux/actions/footAnkle";
import useAxios from "../../../../axiosinstance";
import useTranslation from "../../../customHooks/translations"
import FootAnkleTab from '../../tab-section/FootAnkleTab';
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster"
import HipScoreRangeSlider from "../../../custom/hip-score-range-slider/HipScoreRangeSlider";
import { LeftSide, LeftTrans, RightSide, RigthTrans } from "../../../../utilities/RightLeftFormat";
import NameCustom from "../../../custom/name/NameCustom";

function FootAnkleSatisfaction() {
    const translation = useTranslation()
    let lg = sessionStorage.getItem("language");
    const dispatch = useDispatch();
    const axiosinstance = useAxios();
    const { rId } = useParams();
    const [searchParams] = useSearchParams();
    let doctorEnable = searchParams.get("d");
    const [isLoading, setIsLoading] = useState(true);
    const [symptomR, setSymptomR] = useState("0");
    const [symptomL, setSymptomL] = useState("0");
    const [improveR, setImproveR] = useState("0");
    const [improveL, setImproveL] = useState("0");
    const [forgetR, setForgetR] = useState("0");
    const [forgetL, setForgetL] = useState("0");

    const [applicableR, setapplicableR] = useState(false)
    const [applicableL, setapplicableL] = useState(false)

    const [applicable1R, setapplicable1R] = useState(false)
    const [applicable1L, setapplicable1L] = useState(false)

    const [applicable2R, setapplicable2R] = useState(false)
    const [applicable2L, setapplicable2L] = useState(false)


    // const [anklePain, setAnklePAin] = useState("1");
    const [doctorStatus, setDoctorStatus] = useState(false);
    const anklePain = useSelector(state => state.footAnkle.anklePain);
    const [chk, setChk] = useState(false);
    const FootSatisfaction = useSelector((state) => state.footAnkle.satisfactionScore[0]);
    const json = useSelector((state) => state.footAnkle);
    const [updateTotal, setUpdateTotal] = useState(false);
    const save = () => {
        axiosinstance.current
            .get(`extapp/forms/all_forms?resource_id=${rId}&type=21`)
            .then((res) => {
                dispatch(updateFootAnklePainData(res.data.data2[0].painScore));
                dispatch(updateFootSymptomsData(res.data.data2[0].symptomsScore));
                dispatch(updateFootAnkle(res.data.data2[0].anklePain));
                dispatch(updateFootInstabilityData(res.data.data2[0].instabilityScore));
                dispatch(updateFootComberlandData(res.data.data2[0].comberland));
                dispatch(updateFootQolData(res.data.data2[0].qolScore));
                dispatch(updateFootFunctionData(res.data.data2[0].functionScore));
                dispatch(
                    updateFootSatisfactionData(res.data.data2[0].satisfactionScore)
                );
                dispatch(updateFootFeedbackfootankle(res.data.data2[0].doctorFeedback));
                setDoctorStatus(res.data.doctorStatus);
                setChk(true);
            }).catch((err) => {

                navigate('/un-authorized-access');

            });
    };

    useEffect(() => {
        save();
    }, []);
    useEffect(() => {
        setSymptomR(FootSatisfaction.symptomR)
        setSymptomL(FootSatisfaction.symptomL)
        setImproveR(FootSatisfaction.improveR)
        setImproveL(FootSatisfaction.improveL)
        setForgetR(FootSatisfaction.forgetR)
        setForgetL(FootSatisfaction.forgetL)
        setapplicableR(FootSatisfaction.applicableR)
        setapplicableL(FootSatisfaction.applicableL)
        setapplicable1R(FootSatisfaction.applicable1R)
        setapplicable1L(FootSatisfaction.applicable1L)
        setapplicable2R(FootSatisfaction.applicable2R)
        setapplicable2L(FootSatisfaction.applicable2L)

    }, [FootSatisfaction]);

    const updateDate = () => {
        axiosinstance.current
            .patch(
                `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
                json,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                // console.log(res);
            });
    };
    useEffect(() => {
        if (chk) {
            updateDate();
        }
    }, [FootSatisfaction, updateTotal]);

    const navigate = useNavigate();

    const continueRedirect = (e) => {
        dispatch(updateFootSatisfactionData(
            [{
                "symptomR": symptomR,
                "symptomL": symptomL,
                "improveR": improveR,
                "improveL": improveL,
                "forgetR": forgetR,
                "forgetL": forgetL,
            }]

        ))
        navigate("/foot-ankle-complete/" + rId);
    };
    const handleSymptomR = (e) => {
        setSymptomR(e);
        dispatch(updateFootSatisfactionScore({
            "key": 'symptomR',
            "value": e
        }))
    }
    const handleSymptomL = (e) => {
        setSymptomL(e);
        dispatch(updateFootSatisfactionScore({
            "key": 'symptomL',
            "value": e
        }))
    }
    const handleImproveR = (e) => {
        setImproveR(e);
        dispatch(updateFootSatisfactionScore({
            "key": 'improveR',
            "value": e
        }))
    }
    const handleImproveL = (e) => {
        setImproveL(e);
        dispatch(updateFootSatisfactionScore({
            "key": 'improveL',
            "value": e
        }))
    }
    const handleForgetR = (e) => {
        setForgetR(e);
        dispatch(updateFootSatisfactionScore({
            "key": 'forgetR',
            "value": e
        }))
    }
    const handleForgetL = (e) => {
        setForgetL(e);
        dispatch(updateFootSatisfactionScore({
            "key": 'forgetL',
            "value": e
        }))
    }
    // const handleAnklePain = (e) => {
    //     setAnklePAin(e);
    // }
    const [msg, setMsg] = useState('');
    const [msgType, setmsgType] = useState('');
    const SubmitForm = (id) => {
        axiosinstance.current.patch(`extapp/forms/update_scoring?resource_id=${id}`, {
            patient_scoring_date: new Date(),
            status: 1,
        });
    };
    let akpt = searchParams.get("akpt");
    const handleSubmit = () => {
        updateAllData(json, dispatch);
        setUpdateTotal(!updateTotal);
        setMsg(translation.update)
        setmsgType('success')
        SubmitForm(rId)
        setTimeout(() => {
            let view_circular=  sessionStorage.getItem("view_circular")
             // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
            //  window.location.href = process.env.REACT_APP_DOCTOR_URL +`view-management-plan/${akpt}/${window.btoa(
            //    rId
            //  )}/?org=${window.btoa(sessionStorage.getItem("orgid")||'')}&formType=${
            //    window.btoa('21') || ""
            //  }&view_circular=${view_circular==='false'?'false':'true'}`
            window.location.href = process.env.REACT_APP_DOCTOR_URL +`patient-details/${akpt}?formid=21`
             setMsg('')
             setmsgType('success')
           }, 2000);
    };

    const handleapplicableR = (e) => {
        setapplicableR(e)
        // setOverallR("0")
        dispatch(updateFootSatisfactionScore({
            "key": 'applicableR',
            "value": e
        }))
        dispatch(updateFootSatisfactionScore({
            "key": 'symptomR',
            "value": ""
        }))
    }
    const handleapplicableL = (e) => {
        setapplicableL(e)
        // setOverallR("0")
        dispatch(updateFootSatisfactionScore({
            "key": 'applicableL',
            "value": e
        }))
        dispatch(updateFootSatisfactionScore({
            "key": 'symptomL',
            "value": ""
        }))
    }
    const handleapplicable1R = (e) => {
        setapplicable1R(e)
        // setImprovePainR("0")
        dispatch(updateFootSatisfactionScore({
            "key": 'applicable1R',
            "value": e
        }))
        dispatch(updateFootSatisfactionScore({
            "key": 'improveR',
            "value": ""
        }))
    }

    const handleapplicable1L = (e) => {
        setapplicable1L(e)
        // setImprovePainL("0")
        dispatch(updateFootSatisfactionScore({
            "key": 'applicable1L',
            "value": e
        }))
        dispatch(updateFootSatisfactionScore({
            "key": 'improveL',
            "value": ""
        }))
    }
    const handleapplicable2R = (e) => {
        setapplicable2R(e)
        // setImproveHomeR("0")
        dispatch(updateFootSatisfactionScore({
            "key": 'applicable2R',
            "value": e
        }))
        dispatch(updateFootSatisfactionScore({
            "key": 'forgetR',
            "value": ""
        }))

    }

    const handleapplicable2L = (e) => {
        setapplicable2L(e)
        // setImproveHomeL("0")
        dispatch(updateFootSatisfactionScore({
            "key": 'applicable2L',
            "value": e
        }))
        dispatch(updateFootSatisfactionScore({
            "key": 'forgetL',
            "value": ""
        }))
    }

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, []);
   
    const handleNext=()=>{
        navigate(`/foot-doctor-feedback/${rId}?d=true&akpt=${akpt}`);
    
      }
    return (
        <>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <div className="adault_knee_satisfaction_screen mb_100">
                     <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
                    <div className="panel">
                        <div className="panel-body p_25">
                            {doctorEnable === "true" && <FootAnkleTab rId={rId} />}
                            <CustomToaster msg={msg} msgType={msgType} />
                          
                          
                            <h4 className="ph_title f_40">{translation.patient_foot_ankle_satisfaction}</h4>

                            <div className="function_formtop">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 function_head">
                                        <label>
                                            {translation.patient_foot_ankle_satisfaction_instruction}
                                        </label> <br />
                                        <span dir={lg === "ara" ? "rtl" :" "}>
                                            (<strong>1</strong> = {translation.patient_foot_ankle_satisfaction_instruction_a1}, <strong>2</strong> = {translation.patient_foot_ankle_satisfaction_instruction_a2},
                                            <strong> {" "}3</strong> = {translation.patient_foot_ankle_satisfaction_instruction_a3},  <strong>4</strong> = {translation.patient_foot_ankle_satisfaction_instruction_a4})
                                        </span>
                                    </div>

                                </div>
                                <div className="row">
                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className={"col-md-6 col-sm-6 mb_10 satis" + RightSide()}>
                                            <label class="form-check-label not-applicable">
                                                <input className="check_" type="checkbox" id="radio1" name="radio1" value={applicableR} checked={applicableR} style={{ marginRight: 10 }} onChange={(e) => handleapplicableR(e.target.checked)}></input>
                                                {translation.patient_foot_ankle_satisfaction_q1_not_applicable}
                                            </label>
                                            <HipScoreRangeSlider
                                                label={translation.patient_foot_ankle_satisfaction_q1 + RigthTrans(anklePain, translation)}
                                                classes=""
                                                value={symptomR}
                                                maximun="1"
                                                minimum="4"
                                                onChange={handleSymptomR}
                                                view={applicableR}
                                                sliderData={[
                                                    { score: "1", label: translation.patient_foot_ankle_satisfaction_q1_a1 },
                                                    { score: "2", label: translation.patient_foot_ankle_satisfaction_q1_a2 },
                                                    { score: "3", label: translation.patient_foot_ankle_satisfaction_q1_a3 },
                                                    { score: "4", label: translation.patient_foot_ankle_satisfaction_q1_a4 },

                                                ]}
                                            />

                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className={"col-md-6 col-sm-6 mb_10 satis" +LeftSide()}>
                                            <label class="form-check-label not-applicable">
                                                <input className="check_" type="checkbox" id="radio2" name="radio2" value={applicableL} checked={applicableL} style={{ marginRight: 10 }} onChange={(e) => handleapplicableL(e.target.checked)}></input>
                                                {translation.patient_foot_ankle_satisfaction_q1_not_applicable}
                                            </label>
                                            <HipScoreRangeSlider
                                                label={translation.patient_foot_ankle_satisfaction_q1 + LeftTrans(anklePain, translation)}
                                                classes=""
                                                value={symptomL}
                                                onChange={handleSymptomL}
                                                maximun="1"
                                                minimum="4"
                                                view={applicableL}
                                                sliderData={[
                                                    { score: "1", label: translation.patient_foot_ankle_satisfaction_q1_a1 },
                                                    { score: "2", label: translation.patient_foot_ankle_satisfaction_q1_a2 },
                                                    { score: "3", label: translation.patient_foot_ankle_satisfaction_q1_a3 },
                                                    { score: "4", label: translation.patient_foot_ankle_satisfaction_q1_a4 },

                                                ]}
                                            />

                                        </div>
                                    ) : ""}
                                </div>
                                <div className="row">
                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className={"col-md-6 col-sm-6 mb_10 satis" + RightSide()}>
                                            <label class="form-check-label not-applicable">
                                                <input className="check_" type="checkbox" id="radio3" name="radio3" value={applicable1R} checked={applicable1R} style={{ marginRight: 10 }} onChange={(e) => handleapplicable1R(e.target.checked)}></input>
                                                {translation.patient_foot_ankle_satisfaction_q2_not_applicable}
                                            </label>
                                            <HipScoreRangeSlider
                                                label={translation.patient_foot_ankle_satisfaction_q2 + RigthTrans(anklePain, translation)}
                                                classes=""
                                                value={improveR}
                                                onChange={handleImproveR}
                                                maximun="1"
                                                minimum="4"
                                                view={applicable1R}
                                                sliderData={[
                                                    { score: "1", label: translation.patient_foot_ankle_satisfaction_q2_a1 },
                                                    { score: "2", label: translation.patient_foot_ankle_satisfaction_q2_a2 },
                                                    { score: "3", label: translation.patient_foot_ankle_satisfaction_q2_a3 },
                                                    { score: "4", label: translation.patient_foot_ankle_satisfaction_q2_a4 },

                                                ]}
                                            />
                                        </div>

                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className={"col-md-6 col-sm-6 mb_10 satis" + LeftSide()}>
                                            <label class="form-check-label not-applicable">
                                                <input className="check_" type="checkbox" id="radio4" name="radio4" value={applicable1L} checked={applicable1L} style={{ marginRight: 10 }} onChange={(e) => handleapplicable1L(e.target.checked)}></input>
                                                {translation.patient_foot_ankle_satisfaction_q2_not_applicable}
                                            </label>
                                            <HipScoreRangeSlider
                                                label={translation.patient_foot_ankle_satisfaction_q2 + LeftTrans(anklePain, translation)}
                                                classes=""
                                                value={improveL}
                                                maximun="1"
                                                minimum="4"
                                                onChange={handleImproveL}
                                                // onClick={handleImproveL}
                                                view={applicable1L}
                                                sliderData={[
                                                    { score: "1", label: translation.patient_foot_ankle_satisfaction_q2_a1 },
                                                    { score: "2", label: translation.patient_foot_ankle_satisfaction_q2_a2 },
                                                    { score: "3", label: translation.patient_foot_ankle_satisfaction_q2_a3 },
                                                    { score: "4", label: translation.patient_foot_ankle_satisfaction_q2_a4 },

                                                ]}

                                            />
                                        </div>
                                    ) : ""}
                                </div>
                                <div className="row">
                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className={"col-md-6 col-sm-6 mb_10 satis" + RightSide()}>
                                            <label class="form-check-label not-applicable1">
                                                <input className="check_" type="checkbox" id="radio5" name="radio5" value={applicable2R} checked={applicable2R} style={{ marginRight: 10 }} onChange={(e) => handleapplicable2R(e.target.checked)}></input>
                                                {translation.patient_foot_ankle_satisfaction_q3_not_applicable}
                                            </label>
                                            <HipScoreRangeSlider
                                                label={translation.patient_foot_ankle_satisfaction_q3 + RigthTrans(anklePain, translation)}
                                                classes=""
                                                value={forgetR}
                                                onChange={handleForgetR}
                                                maximun="1"
                                                minimum="4"
                                                view={applicable2R}
                                                sliderData={[
                                                    { score: "1", label: translation.patient_foot_ankle_satisfaction_q3_a1 },
                                                    { score: "2", label: translation.patient_foot_ankle_satisfaction_q3_a2 },
                                                    { score: "3", label: translation.patient_foot_ankle_satisfaction_q3_a3 },
                                                    { score: "4", label: translation.patient_foot_ankle_satisfaction_q3_a4 },

                                                ]}
                                            />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className={"col-md-6 col-sm-6 mb_10 satis" + LeftSide()}>
                                            <label class="form-check-label not-applicable1">
                                                <input className="check_" type="checkbox" id="radio6" name="radio6" value={applicable2L} checked={applicable2L} style={{ marginRight: 10 }} onChange={(e) => handleapplicable2L(e.target.checked)}></input>
                                                {translation.patient_foot_ankle_satisfaction_q3_not_applicable}
                                            </label>
                                            <HipScoreRangeSlider
                                                label={translation.patient_foot_ankle_satisfaction_q3 + LeftTrans(anklePain, translation)}
                                                classes=""
                                                value={forgetL}
                                                maximun="1"
                                                minimum="4"
                                                onChange={handleForgetL}
                                                // onClick={handleForgetL}
                                                view={applicable2L}
                                                sliderData={[
                                                    { score: "1", label: translation.patient_foot_ankle_satisfaction_q3_a1 },
                                                    { score: "2", label: translation.patient_foot_ankle_satisfaction_q3_a2 },
                                                    { score: "3", label: translation.patient_foot_ankle_satisfaction_q3_a3 },
                                                    { score: "4", label: translation.patient_foot_ankle_satisfaction_q3_a4 },

                                                ]}
                                            />
                                        </div>
                                    ) : ""}
                                </div>
                            </div>
                            {doctorEnable === "true" && (
                                  <>
                                  {doctorStatus ? (
                                    <>
                                      <div className="row mb_30 button_box">
                                        <div className="col-md-6 col-sm-6"></div>
                                        <div className="col-md-6 col-sm-6 text-end">
                                          <button
                                            className="next_btn btn_fill me-3"
                                            onClick={() => handleNext()}
                                          >
                                            {translation.next_button}
                                            <span>
                                              <img src={nextIcon} alt="" />
                                              {/* <img src={nextWhiteIcon} alt="" /> */}
                                            </span>
                                          </button>
                                          <button
                                            className="next_btn btn_fill"
                                            onClick={() => handleSubmit()}
                                          >
                                            {translation.submit}
                                            <span>
                                              <img src={nextIcon} alt="" />
                                              {/* <img src={nextWhiteIcon} alt="" /> */}
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="row mb_30 button_box">
                                      <div className="col-md-6 col-sm-6"></div>
                                      <div className="col-md-6 col-sm-6 text-end">
                                        <button
                                          className="next_btn btn_fill"
                                          onClick={() => handleSubmit()}
                                        >
                                          {"Submit"}
                                          <span>
                                            <img src={nextIcon} alt="" />
                                            {/* <img src={nextWhiteIcon} alt="" /> */}
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </>
                            )}
                            {doctorEnable !== "true" && (
                                <div className="row mb_30 button_box">
                                    <div className="col-md-6 col-sm-6 col-6">
                                        <Link to={`/foot-ankle-quality-of-life/${rId}`} className="assm_back_btn">
                                            <img src={backIcon} alt="" />
                                            <span>{translation.back_button}</span>
                                        </Link>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-6 text-end">
                                        <button
                                            className="next_btn btn_fill"
                                            onClick={continueRedirect}
                                        >
                                            {translation.next_button}
                                            <span>
                                                <img src={nextIcon} alt="" />
                                                <img src={nextWhiteIcon} alt="" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            )}


                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default FootAnkleSatisfaction;
