import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import { useDispatch, useSelector } from "react-redux";
import Tab from "../../tab-section/ShoulderTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import {
  updateSatisfactionFunctionFieldScore,
  updateSatisfactionFunctionScores,
  updateShoulderFunctionFieldScore,
  updateShoulderFunctionScores,
  updateShoulder,
  updateShoulderPainScores,
  updateWOSIFunctionScores,
  updateSACSFunctionScores,
  updateFeedbackFunctionScores,
  updateTotalScores,
  updateAllData,
} from "../../../../redux/actions/shoulderAction";
import useAxios from "../../../../axiosinstance";
import useTransition from "../../../customHooks/translations";
import { LeftSide, LeftTrans, RightSide, RigthTrans } from "../../../../utilities/RightLeftFormat";
import NameCustom from "../../../custom/name/NameCustom";
function Satisfaction() {
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const translation = useTransition();
  const shoulserScore = useSelector(
    (state) => state.shoulderScore.shoulserScore
  );
  const axiosinstance = useAxios();
  const { rId } = useParams();
  const [chk, setChk] = useState(false);
  const [doctorStatus, setDoctorStatus] = useState(false);
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=4`)
      .then((res) => {
        dispatch(updateShoulder(res.data.data2[0].shoulserScore));
        dispatch(updateShoulderPainScores(res.data.data2[0].painScore));
        dispatch(updateShoulderFunctionScores(res.data.data2[0].functionScore));
        dispatch(updateWOSIFunctionScores(res.data.data2[0].wosiScore));
        dispatch(updateSACSFunctionScores(res.data.data2[0].sacsScore));
        dispatch(
          updateSatisfactionFunctionScores(res.data.data2[0].safisScore)
        );
        dispatch(updateTotalScores(res.data.data2[0].TotalScores));
        dispatch(
          updateFeedbackFunctionScores(res.data.data2[0].doctorFeedback)
        );
        setDoctorStatus(res.data.doctorStatus);
        setChk(true);
      }).catch((err) => {

        navigate('/un-authorized-access');

      });
  };

  useEffect(() => {
    save();
  }, []);
  const [isLoading, setIsLoading] = useState(true);
  const [satisfactionLevelL, setSatisfactionLevelL] = useState("1");
  const [satisfactionLevelR, setSatisfactionLevelR] = useState("1");

  const safisScore = useSelector((state) => state.shoulderScore.safisScore[0]);
  const feel_unstable = useSelector(
    (state) => state.shoulderScore.functionScore[0].feel_unstable
  );
  const instability_acjoint = useSelector(
    (state) => state.shoulderScore.functionScore[0].instability_acjoint
  );

  const json = useSelector((state) => state.shoulderScore);
  useEffect(() => {
    setSatisfactionLevelL(safisScore.satisfaction_left);
    setSatisfactionLevelR(safisScore.satisfaction_right);
  }, [safisScore]);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true" ? "1" : "0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [safisScore, doctorEnable]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSatisfactionLevelL = (e) => {
    dispatch(
      updateSatisfactionFunctionFieldScore({
        key: "satisfaction_left",
        value: e,
      })
    );
    setSatisfactionLevelL(e);
  };
  const handleSatisfactionLevelR = (e) => {
    dispatch(
      updateSatisfactionFunctionFieldScore({
        key: "satisfaction_right",
        value: e,
      })
    );
    setSatisfactionLevelR(e);
  };

  const continueRedirect = (e) => {
    dispatch(
      updateSatisfactionFunctionScores([
        {
          satisfaction_left: satisfactionLevelL,
          satisfaction_right: satisfactionLevelR,
        },
      ])
    );
    navigate("/complete/" + rId);
  };
  const Back = () => {
    navigate(-1, { replace: false });
    // if (feel_unstable == 0 || instability_acjoint === 0) {
    //   navigate("/function/" + rId);
    // } else if (feel_unstable == 0 || instability_acjoint === 1) {
    //   navigate("/sacs/" + rId);
    // } else if (feel_unstable == "" || instability_acjoint === "") {
    //   navigate("/function/" + rId);
    // } else {
    //   navigate("/function/" + rId);
    // }
  };
  const SubmitForm = async (id) => {
    await axiosinstance.current.patch(`extapp/forms/update_scoring?resource_id=${id}`, {
      patient_scoring_date: new Date(),
      status: "1",
    });
  };
  let akpt = searchParams.get("akpt");
  const [msg, setMsg] = useState('');
  const [msgType, setmsgType] = useState('');
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setMsg(translation.update)
    setmsgType('success')
    SubmitForm(rId)
   setTimeout(() => {
     let view_circular=  sessionStorage.getItem("view_circular")
      // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
      // window.location.href = process.env.REACT_APP_DOCTOR_URL +`view-management-plan/${akpt}/${window.btoa(
      //   rId
      // )}/?org=${window.btoa(sessionStorage.getItem("orgid")||'')}&formType=${
      //   window.btoa('4') || ""
      // }&view_circular=${view_circular==='false'?'false':'true'}`
      window.location.href = process.env.REACT_APP_DOCTOR_URL +`patient-details/${akpt}?formid=4`
      setMsg('')
      setmsgType('success')
    }, 2000);
  };
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  const handleNext = (e) => {
    navigate(`/shoulder-treatment-form/step-1/${rId}?d=true&akpt=${akpt}`);

  }
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="satisfaction_screen mb_100">
          <div class="d-flex flex-row-reverse">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <Tab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />

              <h4 className="ph_title f_40">{translation.patient_shoulder_satisfaction}</h4>
              <div className="row">
                {shoulserScore === "1" || shoulserScore === "3" ? (
                  <div className={"col-md-6 col-sm-6 mb_20 pt_20" + RightSide()}>
                    <div className="mb_10">
                      <FullDropdown
                        data={[
                          { value: "1", label: translation.patient_shoulder_satisfaction_q1_a1 },
                          { value: "0", label: translation.patient_shoulder_satisfaction_q1_a2 },
                        ]}
                        label={translation.patient_shoulder_satisfaction_q1 + RigthTrans(shoulserScore, translation)}
                        styleClass="satis_drp"
                        value={satisfactionLevelR}
                        placeholder={translation.none}
                        onChange={handleSatisfactionLevelR}
                      />
                    </div>
                  </div>
                ) : null}
                {shoulserScore === "2" || shoulserScore === "3" ? (
                  <div className={"col-md-6 col-sm-6 mb_20 pt_20" + LeftSide()}>
                    <div className="mb_10">
                      <FullDropdown
                        data={[
                          { value: "1", label: translation.patient_shoulder_satisfaction_q1_a1 },
                          { value: "0", label: translation.patient_shoulder_satisfaction_q1_a2 },
                        ]}
                        label={translation.patient_shoulder_satisfaction_q1 + LeftTrans(shoulserScore, translation)}
                        styleClass="satis_drp"
                        value={satisfactionLevelL}
                        placeholder={translation.none}
                        onChange={handleSatisfactionLevelL}
                      />
                    </div>

                  </div>
                ) : null}
              </div>
              {doctorEnable === "true" && (
                <>
                  {doctorStatus ? (
                    <>
                      <div className="row mb_30 button_box">
                        <div className="col-md-6 col-sm-6">

                        </div>
                        <div className="col-md-6 col-sm-6 text-end">
                          <button
                            className="next_btn btn_fill me-3"
                            onClick={() => handleNext()}
                          >
                            {translation.next_button}
                            <span>
                              <img src={nextIcon} alt="" />
                              {/* <img src={nextWhiteIcon} alt="" /> */}
                            </span>
                          </button>
                          <button
                            className="next_btn btn_fill"
                            onClick={() => handleSubmit()}
                          >
                            {translation.submit}
                            <span>
                              <img src={nextIcon} alt="" />
                              {/* <img src={nextWhiteIcon} alt="" /> */}
                            </span>
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="row mb_30 button_box">
                      <div className="col-md-6 col-sm-6">
                      </div>
                      <div className="col-md-6 col-sm-6 text-end">
                        <button
                          className="next_btn btn_fill"
                          onClick={() => handleSubmit()}
                        >
                          {"Submit"}
                          <span>
                            <img src={nextIcon} alt="" />
                            {/* <img src={nextWhiteIcon} alt="" /> */}
                          </span>
                        </button>
                      </div>
                    </div>
                  )}

                </>
              )}
              {doctorEnable !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    <div
                      onClick={() => Back()}
                      className="assm_back_btn c_pointer"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Satisfaction;
