import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";

export default function ElbowFunction({ elbowFunction, AllData }) {
  const translations = useTranslation();
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-content">
          <div className="treatment-row-header">
            2. {translations.patient_elbow_function}
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_elbow_function_q1} ({translations.Right}
                )
              </label>
              <p>
                {elbowFunction.funDescR === "0"
                  ? translations.patient_elbow_function_q1_a6
                  : elbowFunction.funDescR === "1"
                  ? translations.patient_elbow_function_q1_a5
                  : elbowFunction.funDescR === "2"
                  ? translations.patient_elbow_function_q1_a4
                  : elbowFunction.funDescR === "3"
                  ? translations.patient_elbow_function_q1_a3
                  : elbowFunction.funDescR === "4"
                  ? translations.patient_elbow_function_q1_a2
                  : translations.patient_elbow_function_q1_a1}
              </p>
            </div>
            <div className="treatment-col">
              <label>
                {translations.patient_elbow_function_q1} ({translations.Left})
              </label>
              <p>
                {elbowFunction.funDescL === "0"
                  ? translations.patient_elbow_function_q1_a6
                  : elbowFunction.funDescL === "1"
                  ? translations.patient_elbow_function_q1_a5
                  : elbowFunction.funDescL === "2"
                  ? translations.patient_elbow_function_q1_a4
                  : elbowFunction.funDescL === "3"
                  ? translations.patient_elbow_function_q1_a3
                  : elbowFunction.funDescL === "4"
                  ? translations.patient_elbow_function_q1_a2
                  : translations.patient_elbow_function_q1_a1}
              </p>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_elbow_function_q2} ({translations.Right})
              </label>
              <p>
                {elbowFunction.funActivityR === "0"
                  ? translations.patient_elbow_function_q2_a5
                  : elbowFunction.funActivityR === "1"
                  ? translations.patient_elbow_function_q2_a4
                  : elbowFunction.funActivityR === "2"
                  ? translations.patient_elbow_function_q2_a3
                  : elbowFunction.funActivityR === "3"
                  ? translations.patient_elbow_function_q2_a2
                  : elbowFunction.funActivityR === "4"
                  ? translations.patient_elbow_function_q2_a1
                  : ""}
              </p>
            </div>
            <div className="treatment-col">
              <label>
                {translations.patient_elbow_function_q2} ({translations.Left})
              </label>
              <p>
                {elbowFunction.funActivityL === "0"
                  ? translations.patient_elbow_function_q2_a5
                  : elbowFunction.funActivityL === "1"
                  ? translations.patient_elbow_function_q2_a4
                  : elbowFunction.funActivityL === "2"
                  ? translations.patient_elbow_function_q2_a3
                  : elbowFunction.funActivityL === "3"
                  ? translations.patient_elbow_function_q2_a2
                  : elbowFunction.funActivityL === "4"
                  ? translations.patient_elbow_function_q2_a1
                  : ""}
              </p>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_elbow_function_q3} ({translations.Right})
              </label>
              <p>
                {elbowFunction.funUselongR === "0"
                  ? translations.patient_elbow_function_q3_a4
                  : elbowFunction.funUselongR === "1"
                  ? translations.patient_elbow_function_q3_a3
                  : elbowFunction.funUselongR === "2"
                  ? translations.patient_elbow_function_q3_a2
                  : elbowFunction.funUselongR === "3"
                  ? translations.patient_elbow_function_q3_a1
                  : ""}
              </p>
            </div>
            <div className="treatment-col">
              <label>
                {translations.patient_elbow_function_q3} ({translations.Left})
              </label>
              <p>
                {elbowFunction.funUselongL === "0"
                  ? translations.patient_elbow_function_q3_a4
                  : elbowFunction.funUselongL === "1"
                  ? translations.patient_elbow_function_q3_a3
                  : elbowFunction.funUselongL === "2"
                  ? translations.patient_elbow_function_q3_a2
                  : elbowFunction.funUselongL === "3"
                  ? translations.patient_elbow_function_q3_a1
                  : ""}
              </p>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>

                    <th>{translations.Right_Elbow}</th>

                    <th>{translations.Left_Elbow}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{translations.patient_elbow_function_q4} :</td>

                    <td>{elbowFunction.tightR}</td>
                    <td>{elbowFunction.tightL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_function_q5} :</td>

                    <td>{elbowFunction.funshoppingR}</td>
                    <td>{elbowFunction.funshoppingL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_function_q6} :</td>

                    <td>{elbowFunction.funrubbishR}</td>
                    <td>{elbowFunction.funrubbishL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_function_q7} :</td>

                    <td>{elbowFunction.funWashR}</td>
                    <td>{elbowFunction.funWashL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_function_q8} :</td>

                    <td>{elbowFunction.funToiletinghR}</td>
                    <td>{elbowFunction.funToiletinghL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_function_q9} :</td>

                    <td>{elbowFunction.funWashingR}</td>
                    <td>{elbowFunction.funWashingL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_function_q10} :</td>

                    <td>{elbowFunction.funHygienegR}</td>
                    <td>{elbowFunction.funHygienegL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_function_q11} :</td>

                    <td>{elbowFunction.funKnifeR}</td>
                    <td>{elbowFunction.funKnifeL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_function_q12} :</td>

                    <td>{elbowFunction.funUtensilR}</td>
                    <td>{elbowFunction.funUtensilL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_function_q13} :</td>

                    <td>{elbowFunction.funRecreationalR}</td>
                    <td>{elbowFunction.funRecreationalL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_function_q14} :</td>

                    <td>{elbowFunction.funSleepingR}</td>
                    <td>{elbowFunction.funSleepingL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_function_q15} :</td>

                    <td>{elbowFunction.funShirtR}</td>
                    <td>{elbowFunction.funShirtL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_function_q16} :</td>

                    <td>{elbowFunction.funTopShirtR}</td>
                    <td>{elbowFunction.funTopShirtL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_function_q17} :</td>

                    <td>{elbowFunction.funDressingR}</td>
                    <td>{elbowFunction.funDressingL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_function_q18} :</td>

                    <td>{elbowFunction.funHairR}</td>
                    <td>{elbowFunction.funHairL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_function_q19} :</td>

                    <td>{elbowFunction.funPutShoesR}</td>
                    <td>{elbowFunction.funPutShoesL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_function_q20} :</td>

                    <td>{elbowFunction.funTieShoesR}</td>
                    <td>{elbowFunction.funTieShoesL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_function_q21} :</td>

                    <td>{elbowFunction.funHeavyR}</td>
                    <td>{elbowFunction.funHeavyL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_function_q22} :</td>

                    <td>{elbowFunction.funChairR}</td>
                    <td>{elbowFunction.funChairL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_function_q23} :</td>

                    <td>{elbowFunction.householdR}</td>
                    <td>{elbowFunction.householdL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_function_q24} :</td>

                    <td>{elbowFunction.funTurnR}</td>
                    <td>{elbowFunction.funTurnL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_function_q25} :</td>

                    <td>{elbowFunction.funBallR}</td>
                    <td>{elbowFunction.funBallL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_function_q26} :</td>

                    <td>{elbowFunction.funWorkR}</td>
                    <td>{elbowFunction.funWorkL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_function_q27} :</td>
                    <td>
                      {elbowFunction.funTextL !== "" ? (
                        <>
                          {elbowFunction.funTextL}
                          <br></br>
                        </>
                      ) : (
                        <></>
                      )}
                      {elbowFunction.checkR === true ? (
                        <>{translations.patient_elbow_function_i_donot_work}</>
                      ) : (
                        <></>
                      )}
                    </td>

                    {/* <td>{elbowFunction.funTextL}</td> */}
                  </tr>

                  {!elbowFunction.checkR && (
                    <>
                      <tr>
                        <td>{translations.patient_elbow_function_q28} :</td>

                        <td>{elbowFunction.funTechniqueR}</td>
                        <td>{elbowFunction.funTechniqueL}</td>
                      </tr>
                      <tr>
                        <td>{translations.patient_elbow_function_q29} :</td>

                        <td>{elbowFunction.funWorkAsWallR}</td>
                        <td>{elbowFunction.funWorkAsWallL}</td>
                      </tr>
                      <tr>
                        <td>{translations.patient_elbow_function_q30} :</td>

                        <td>{elbowFunction.funSpendingR}</td>
                        <td>{elbowFunction.funSpendingL}</td>
                      </tr>
                    </>
                  )}

                  <tr>
                    <td>{translations.patient_elbow_function_q31} :</td>

                    <td>{elbowFunction.funMusicalR}</td>
                    <td>{elbowFunction.funMusicalL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_function_q32} :</td>

                    <td>
                      {elbowFunction.funMusicaTextL !== "" ? (
                        <>
                          {elbowFunction.funMusicaTextL}
                          <br></br>
                        </>
                      ) : (
                        <></>
                      )}
                      {elbowFunction.checkMusicaR === true ? (
                        <>{translations.patient_elbow_function_i_donot_play}</>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>

                  {!elbowFunction.checkMusicaR && (
                    <>
                      <tr>
                        <td>{translations.patient_elbow_function_q33} :</td>

                        <td>{elbowFunction.funInstrumentR}</td>
                        <td>{elbowFunction.funInstrumentL}</td>
                      </tr>
                      <tr>
                        <td>{translations.patient_elbow_function_q34} :</td>

                        <td>{elbowFunction.funPlayingR}</td>
                        <td>{elbowFunction.funPlayingL}</td>
                      </tr>
                      <tr>
                        <td>{translations.patient_elbow_function_q35} :</td>

                        <td>{elbowFunction.funPractisingR}</td>
                        <td>{elbowFunction.funPractisingL}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_elbow_function_q36} ({translations.Right}
                ):
              </label>
              <p>
                {elbowFunction.funLeisureR === "4"
                  ? translations.patient_elbow_function_q36_a5
                  : elbowFunction.funLeisureR === "3"
                  ? translations.patient_elbow_function_q36_a4
                  : elbowFunction.funLeisureR === "2"
                  ? translations.patient_elbow_function_q36_a3
                  : elbowFunction.funLeisureR === "1"
                  ? translations.patient_elbow_function_q36_a2
                  : elbowFunction.funLeisureR === "0"
                  ? translations.patient_elbow_function_q36_a1
                  : ""}
              </p>
            </div>
            <div className="treatment-col">
              <label>
                {translations.patient_elbow_function_q36} ({translations.Left}):
              </label>
              <p>
                {elbowFunction.funLeisureL === "4"
                  ? translations.patient_elbow_function_q36_a5
                  : elbowFunction.funLeisureL === "3"
                  ? translations.patient_elbow_function_q36_a4
                  : elbowFunction.funLeisureL === "2"
                  ? translations.patient_elbow_function_q36_a3
                  : elbowFunction.funLeisureL === "1"
                  ? translations.patient_elbow_function_q36_a2
                  : elbowFunction.funLeisureL === "0"
                  ? translations.patient_elbow_function_q36_a1
                  : ""}
              </p>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_elbow_function_q37}({translations.Right}):
              </label>
              <p>
                {elbowFunction.funRegularR === "4"
                  ? translations.patient_elbow_function_q37_a5
                  : elbowFunction.funRegularR === "3"
                  ? translations.patient_elbow_function_q37_a4
                  : elbowFunction.funRegularR === "2"
                  ? translations.patient_elbow_function_q37_a3
                  : elbowFunction.funRegularR === "1"
                  ? translations.patient_elbow_function_q37_a2
                  : elbowFunction.funRegularR === "0"
                  ? translations.patient_elbow_function_q37_a1
                  : ""}
              </p>
            </div>
            <div className="treatment-col">
              <label>
                {translations.patient_elbow_function_q37}({translations.Left}):
              </label>
              <p>
                {elbowFunction.funRegularL === "4"
                  ? translations.patient_elbow_function_q37_a5
                  : elbowFunction.funRegularL === "3"
                  ? translations.patient_elbow_function_q37_a4
                  : elbowFunction.funRegularL === "2"
                  ? translations.patient_elbow_function_q37_a3
                  : elbowFunction.funRegularL === "1"
                  ? translations.patient_elbow_function_q37_a2
                  : elbowFunction.funRegularL === "0"
                  ? translations.patient_elbow_function_q37_a1
                  : ""}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
