import React, { useState, useEffect } from "react";
import useTranslation from "../../customHooks/translations";
import { Link, useNavigate, useParams } from "react-router-dom";
import backIcon from "../../../images/back.png";
import nextWhiteIcon from "../../../images/next-white.png";
import nextIcon from "../../../images/next.png";
import Dropdown from "../../custom/dropdown/Dropdown";
import LoadingSpinner from "../../custom/loader/LoadingSpinner";
import InputField from "../../custom/inputfield/InputField";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import useAxios from "../../../axiosinstance";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import Select from "react-select";
import {
  updateHistoryComplaintFieldsScore,
  updateHistoryComplaintScore,
  updateSocialComplaintScore,
  updatePastComplaintScore,
  updateGenScores,
} from "../../../redux/actions/GeneralAction";

export default function History() {
  let lang = sessionStorage.getItem("language");
  const dispatch = useDispatch();
  const translation = useTranslation();
  const axiosinstance = useAxios();
  const navigate = useNavigate();
  const { rId, ptId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("doctor");
  const [complaint, setComplaint] = useState("");
  const [startDate, setstartDate] = useState(new Date());
  const [drop1, setDrop1] = useState("0");
  const [Investigation, setInvestigation] = useState("");
  const [chk, setChk] = useState(false);
  const [backD, setbackD] = useState("");
  const [backDoc, setBackDoc] = useState("");
  const minDate = sessionStorage.getItem("dob");

  let isdoctorForm = searchParams.get("is_doctor_form");
  useEffect(() => {
    if (doctorEnable === "true") {
      let doctordata = localStorage.getItem("isdoctorForm");
      localStorage.setItem("isdoctorForm", doctordata);
    } else {
      localStorage.setItem("isdoctorForm", isdoctorForm);
    }
  }, [isdoctorForm]);

  let akToken = localStorage.getItem("AkTOKENI");
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  const History = useSelector((state) => state.GeneralFormReduces.History[0]);
  const json = useSelector((state) => state.GeneralFormReduces);
  useEffect(() => {
    if (ptId) {
      localStorage.setItem("medical_pt", window.atob(ptId));
    }
  }, [ptId]);
  const save = () => {
    axiosinstance.current
      .get(
        `extapp/doctors/getMedicalSummaryList?ak_id=${window.atob(
          ptId
        )}&doctor_id=${doctorEnable}&is_patient=${doctorEnable ? 0 : 1}`
      )
      .then((res) => {
        dispatch(updateHistoryComplaintScore(res.data.data.History));
        dispatch(updateSocialComplaintScore(res.data.data.Social));
        dispatch(updateGenScores(res.data.data.status));
        dispatch(updatePastComplaintScore(res.data.data.Past));

        setChk(true);
      })
      .catch((err) => {
        if (err.response !== undefined ? err.response.status === 401 : null) {
          navigate("/un-authorized-access");
        }
      });
  };

  useEffect(() => {
    save();
  }, []);

  // const backData = () => {
  //   axiosinstance.current
  //     .get(`extapp/forms/urgent_data?resource_id=${rId}`)
  //     .then((res) => {
  //       setbackD(res.data.data);
  //       sessionStorage.setItem("ptid",res.data.data.ak_pt)
  //     });
  // };
  // useEffect(() => {
  //   backData();
  // }, []);
  // const updateDate = () => {
  //   axiosinstance.current
  //     .put(
  //       `extapp/doctors/updateSummary?ak_id=${akToken}`,
  //       json,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     )
  //     .then((res) => {

  //     });
  // };
  // useEffect(() => {
  //   if (chk) {
  //     updateDate();
  //   }
  // }, [History]);

  useEffect(() => {
    if (History) {
      setComplaint(History.complaint);
      setstartDate(new Date(History.startDate));
      setDrop1(History.drop1);
      setInvestigation(History.Investigation);
    }
  }, [History]);
  const handleComplaint = (e) => {
    dispatch(
      updateHistoryComplaintFieldsScore({
        key: "complaint",
        value: e,
      })
    );
    setComplaint(e);
  };

  const handleDate = (e) => {
    dispatch(
      updateHistoryComplaintFieldsScore({
        key: "startDate",
        value: e,
      })
    );
    setstartDate(e);
  };

  const handleDrop = (e) => {
    dispatch(
      updateHistoryComplaintFieldsScore({
        key: "drop1",
        value: e,
      })
    );
    setDrop1(e);
  };

  const handleInvestigation = (e) => {
    dispatch(
      updateHistoryComplaintFieldsScore({
        key: "Investigation",
        value: e,
      })
    );
    setInvestigation(e);
  };
  const continueRedirect = async () => {
    try {
      await axiosinstance.current
        .put(
          `extapp/doctors/updateSummary?ak_id=${window.atob(
            ptId
          )}&doctor_id=${doctorEnable}&is_patient=${doctorEnable ? 0 : 1}`,
          json,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.status) {
            navigate(
              `/medical-past/${ptId}?doctor=${doctorEnable}&is_doctor_form=${isdoctorForm}`
            );
          }
        });
    } catch (err) {
      if (err.response !== undefined ? err.response.status === 401 : null) {
        navigate("/un-authorized-access");
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
          <div className="panel">
            <div className="panel-body p_25">
              <div className="row ">
                <div className=" col-md-12 col-sm-12 col-6">
                  {/* <NameCustom/> */}
                  <h4 className="ph_title f_40">
                    {translation.patient_general_history}
                  </h4>
                </div>
                <div className="row pt_20">
                  <div className="col-md-12 col-sm-12 col-12">
                    <div className="mb_10">
                      <InputField
                        label={translation.patient_general_history_q1}
                        value={complaint}
                        type="textarea"
                        placeholder=""
                        rows="4"
                        cols="20"
                        onChange={handleComplaint}
                        styleClass="h_150"
                      />
                      <br />
                      <label>{translation.patient_general_history_q2}</label>

                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        // maxDate={maxDate}
                        minDate={new Date(minDate)}
                        filterDate={(date) => date >= new Date(minDate)}
                        className="form-control mb_0 br_10 w-25"
                        selected={new Date(startDate)}
                        onChange={handleDate}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        locale={
                          lang === "en"
                            ? "en-US"
                            : lang === "ara"
                            ? "ar"
                            : lang === "ger"
                            ? "de"
                            : lang === "ita"
                            ? "it"
                            : lang === "spa"
                            ? "es"
                            : lang === "tha"
                            ? "th"
                            : lang === "fr"
                            ? "fr"
                            : "en-US"
                        }
                      />
                      <br />
                      <div className="mt-4 mb-4">
                        <label>{translation.patient_general_history_q3}</label>
                        <Select
                          isMulti
                          name="weekday"
                          options={[
                            {
                              value: "0",
                              label: translation.patient_general_history_q3_a1,
                            },
                            {
                              value: "1",
                              label: translation.patient_general_history_q3_a2,
                            },
                            {
                              value: "2",
                              label: translation.patient_general_history_q3_a3,
                            },
                            {
                              value: "3",
                              label: translation.patient_general_history_q3_a4,
                            },
                            {
                              value: "4",
                              label: translation.patient_general_history_q3_a5,
                            },
                            {
                              value: "5",
                              label: translation.patient_general_history_q3_a6,
                            },
                            {
                              value: "6",
                              label: translation.patient_general_history_q3_a7,
                            },
                          ]}
                          onChange={handleDrop}
                          value={drop1}
                          // className="form-control form-select"

                          classNamePrefix="select"
                          placeholder={
                            <div>
                              {
                                translation.patient_general_past_history_q28_a2_q1_placeholder
                              }
                            </div>
                          }
                        />
                        {/* <Dropdown
                          data={[
                            { value: "0", label: translation.patient_general_history_q3_a1 },
                            { value: "1", label: translation.patient_general_history_q3_a2 },
                            { value: "2", label: translation.patient_general_history_q3_a3 },
                            { value: "3", label: translation.patient_general_history_q3_a4 },
                            { value: "4", label: translation.patient_general_history_q3_a5 },
                            { value: "5", label: translation.patient_general_history_q3_a6 },
                            { value: "6", label: translation.patient_general_history_q3_a7 },
                          ]}
                          label={translation.patient_general_history_q3}
                          styleClass=""
                          value={drop1}
                          placeholder={translation.none}
                          onChange={handleDrop}
                        /> */}
                      </div>
                      <InputField
                        label={translation.patient_general_history_q4}
                        value={Investigation}
                        type="textarea"
                        placeholder={
                          translation.patient_general_history_q4_placeholder
                        }
                        rows="4"
                        cols="20"
                        onChange={handleInvestigation}
                        styleClass="h_150"
                      />
                      <div className="row mb_30 button_box">
                        {/* <div className="col-md-6 col-sm-6 col-6">
                          {backDoc !== 1 ? (
                            <Link
                              to={`/personal-data/${window.btoa(
                                backD.ak_pt
                              )}/${window.btoa(backD.ak_dc)}/${window.btoa(
                                backD.org_id
                              )}/${window.btoa(backD.type)}`}
                              className="assm_back_btn"
                            >
                              <img src={backIcon} alt="" />
                              <span>{translation.back_button}</span>
                            </Link>
                          ) : (
                            <Link
                              to={`/personal-data/${window.btoa(
                                backD.ak_pt
                              )}/${window.btoa(backD.ak_dc)}/${window.btoa(
                                backD.org_id
                              )}/${window.btoa(
                                backD.type
                              )}?is_doctor_form=true&doctorvalue=0`}
                              className="assm_back_btn"
                            >
                              <img src={backIcon} alt="" />
                              <span>{translation.back_button}</span>
                            </Link>
                          )}
                        </div> */}
                        <div className="text-end">
                          <button
                            className="next_btn btn_fill"
                            onClick={continueRedirect}
                          >
                            {translation.next_button}
                            <span>
                              <img src={nextIcon} alt="" />
                              <img src={nextWhiteIcon} alt="" />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
