import React, { useState, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";

import { validateInput } from "../../../utilities/Validator";

const InputField = forwardRef(
  (
    {
      value,
      label,
      placeholder,
      validators,
      type,
      styleClass,
      onChange,
      formGroupClass,
      rows,
      cols,
      disabled,
      maxLength,
      label_class
    },
    ref
  ) => {
    const [error, setError] = useState(false);

    const handleChange = (event) => {
      const { value } = event.target;
      setError(validateInput(validators, value));
      onChange(value, validateInput(validators, value));
    };

    useImperativeHandle(ref, () => ({
      errUpdate(e) {
        setError(validateInput(validators, ""));
      },
    }));

    return (
      <div className={`form-group ${formGroupClass}`}>
        {label && <label htmlFor="app-input-field" className={label_class?'label_class':''}>{label}</label>}

        {type === "textarea" ? (
          <textarea
            className={`text_area form-control ${styleClass}`}
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            onBlur={handleChange}
            rows={rows}
            cols={cols}
            disabled={disabled}
            maxLength={maxLength}
          />
        ) : (
          <input
            type={type}
            value={value}
            className={`form-control ${styleClass}`}
            placeholder={placeholder}
            disabled={disabled}
            onChange={handleChange}
            onBlur={handleChange}
            maxLength={maxLength}
          />
        )}
        {error && <span className="text-danger">{error.message}</span>}
        {/* {error && <span className="text-danger">{validators !==undefined?validators[0].message:error.message}</span>} */}
      </div>
    );
   }
);

InputField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  validators: PropTypes.array,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

InputField.defaultProps = {
  value: "",
  label: "",   
  placeholder: "",
  type: "text",
  validators: [],
  rows: "",
  cols: "",
  readOnly: "",
  errMsg: "",
};

export default InputField;
