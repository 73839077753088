import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import RangeSlider from "../../../custom/range-slider/RangeSlider";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import RadioButton from "../../../custom/radio-button/RadioButton";
import RadioSwitch from "../../../custom/radio-switch/RadioSwitch";
import KneeScoreRangeSlider from "../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import useTranslation from "../../../customHooks/translations";

import { useDispatch, useSelector } from "react-redux";
import {
  updateElbowScores,
  updateElbowPainFieldScore,
  updateElbowPainData,
  updateElbowFunctionData,
  updateElbowQOLData,
  updateElbowSatisficationData,
  updateElbowFeedbackScores,
  updateElbowSymptomsData,
  updateAllData,
} from "../../../../redux/actions/elbowAction";
import useAxios from "../../../../axiosinstance";
import ElbowTab from "../../tab-section/ElbowTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import {
  LeftSide,
  LeftTrans,
  RightSide,
  RigthTrans,
} from "../../../../utilities/RightLeftFormat";
import NameCustom from "../../../custom/name/NameCustom";

const ElbowPain = () => {
  const translation = useTranslation();
  const navigate = useNavigate();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");

  const axiosinstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  let isdoctorForm = searchParams.get("is_doctor_form");
 let viewicon= searchParams.get("edit")
  localStorage.setItem("viewicon",viewicon)
  useEffect(() => {
    if (doctorEnable === "true") {
      let doctordata = localStorage.getItem("isdoctorForm")
      localStorage.setItem("isdoctorForm", doctordata)
    } else {
      localStorage.setItem("isdoctorForm", isdoctorForm)
    }
    localStorage.setItem("isdoctorForm", isdoctorForm)
  }, [isdoctorForm])
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const handleEvolutedElbowPain = (e) => {
    dispatch(updateElbowScores(e));
  };
  let doctordata = localStorage.getItem("isdoctorForm")
  const continueRedirect = (e) => {
    // dispatch(updateElbowPainData(
    //   [{
    //     "painTodayRatingL": painTodayRatingL,

    //   }]
    // ))
    navigate("/elbow-function/" + rId + `?is_doctor_form=${doctordata}`);
  };

  const [elbowPainR, setelbowPainR] = useState(0);
  const [elbowPainL, setelbowPainL] = useState(0);
  const [decElbowPainR, setDecElbowPainR] = useState(0);
  const [decElbowPainL, setDecElbowPainL] = useState(0);
  const [rateElbow, setRateElbow] = useState(0);
  const [rateElbowL, setRateElbowL] = useState(0);
  const [moment, setMoment] = useState(0);
  const [momentL, setMomentL] = useState(0);
  const [oftenR, setOftenR] = useState(0);
  const [oftenL, setOftenL] = useState(0);
  const [troubledL, setTroubledL] = useState(0);
  const [troubledR, setTroubledR] = useState(0);
  const [rateWorst, setRateworst] = useState(0);
  const [rateWorstL, setRateworstL] = useState(0);
  const [rateRest, setrateRest] = useState(0);
  const [rateRestL, setrateRestL] = useState(0);
  const [rateObj, setrateObj] = useState(0);
  const [rateObjL, setrateObjL] = useState(0);
  const [rateMovements, setrateMovements] = useState(0);
  const [rateMovementsL, setrateMovementsL] = useState(0);
  const [rateNight, setRateNight] = useState(0);
  const [rateNightL, setRateNightL] = useState(0);
  const [chk, setChk] = useState(false);
  const [backD, setbackD] = useState({});
  const [updateTotal, setUpdateTotal] = useState(false);
  const [backDoc, setBackDoc] = useState("");
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=22`)
      .then((res) => {
        setBackDoc(res.data.data2[0].is_doctor_form);
        dispatch(updateElbowPainData(res.data.data2[0].painScore));
        dispatch(updateElbowSymptomsData(res.data.data2[0].symptomsScore));
        dispatch(updateElbowScores(res.data.data2[0].elbowPain));
        dispatch(updateElbowFunctionData(res.data.data2[0].functionScore));
        dispatch(updateElbowQOLData(res.data.data2[0].qolScore));
        dispatch(
          updateElbowSatisficationData(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateElbowFeedbackScores(res.data.data2[0].doctorFeedback));
        setChk(true);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };

  useEffect(() => {
    save();
  }, []);

  const evlElbowPain = useSelector((state) => state.elbowScore.elbowPain);
  const ElbowPain = useSelector((state) => state.elbowScore.painScore[0]);
  const json = useSelector((state) => state.elbowScore);

  useEffect(() => {
    setelbowPainR(ElbowPain.elbowPainR);
    setelbowPainL(ElbowPain.elbowPainL);
    setDecElbowPainR(ElbowPain.decElbowPainR);
    setDecElbowPainL(ElbowPain.decElbowPainL);
    setRateElbow(ElbowPain.rateElbow);
    setRateElbowL(ElbowPain.rateElbowL);
    setMoment(ElbowPain.moment);
    setMomentL(ElbowPain.momentL);
    setOftenR(ElbowPain.oftenR);
    setOftenL(ElbowPain.oftenL);
    setTroubledL(ElbowPain.troubledL);
    setTroubledR(ElbowPain.troubledR);
    setRateworst(ElbowPain.rateWorst);
    setRateworstL(ElbowPain.rateWorstL);
    setrateRest(ElbowPain.rateRest);
    setrateRestL(ElbowPain.rateRestL);
    setrateObj(ElbowPain.rateObj);
    setrateObjL(ElbowPain.rateObjL);
    setrateMovements(ElbowPain.rateMovements);
    setrateMovementsL(ElbowPain.rateMovementsL);
    setRateNight(ElbowPain.rateNight);
    setRateNightL(ElbowPain.rateNightL);
  }, [ElbowPain]);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [ElbowPain, updateTotal]);

  const backData = () => {
    axiosinstance.current
      .get(`extapp/forms/urgent_data?resource_id=${rId}`)
      .then((res) => {
        setbackD(res.data.data);
        sessionStorage.setItem("ptid", res.data.data.ak_pt);
        sessionStorage.setItem("doctor_id", res.data.data.ak_dc)
      });
  };
  useEffect(() => {
    save();
    backData();
  }, []);

  const handleElbowPainR = (e) => {
    setelbowPainR(e);
    dispatch(
      updateElbowPainFieldScore({
        key: "elbowPainR",
        value: e,
      })
    );
  };
  const handleElbowPainL = (e) => {
    setelbowPainL(e);
    dispatch(
      updateElbowPainFieldScore({
        key: "elbowPainL",
        value: e,
      })
    );
  };
  const handleDecElbowPainR = (e) => {
    setDecElbowPainR(e);
    dispatch(
      updateElbowPainFieldScore({
        key: "decElbowPainR",
        value: e,
      })
    );
  };

  const handleDecElbowPainL = (e) => {
    setDecElbowPainL(e);
    dispatch(
      updateElbowPainFieldScore({
        key: "decElbowPainL",
        value: e,
      })
    );
  };
  const handleMoment = (e) => {
    setMoment(e);
    dispatch(
      updateElbowPainFieldScore({
        key: "moment",
        value: e,
      })
    );
  };
  const handleMomentL = (e) => {
    setMomentL(e);
    dispatch(
      updateElbowPainFieldScore({
        key: "momentL",
        value: e,
      })
    );
  };
  const handleRateElbow = (e) => {
    setRateElbow(e);
    dispatch(
      updateElbowPainFieldScore({
        key: "rateElbow",
        value: e,
      })
    );
  };
  const handleRateElbowL = (e) => {
    setRateElbowL(e);
    dispatch(
      updateElbowPainFieldScore({
        key: "rateElbowL",
        value: e,
      })
    );
  };
  const handleOftenR = (e) => {
    setOftenR(e);
    dispatch(
      updateElbowPainFieldScore({
        key: "oftenR",
        value: e,
      })
    );
  };
  const handleOftenL = (e) => {
    setOftenL(e);
    dispatch(
      updateElbowPainFieldScore({
        key: "oftenL",
        value: e,
      })
    );
  };
  const handleTroubledL = (e) => {
    setTroubledL(e);
    dispatch(
      updateElbowPainFieldScore({
        key: "troubledL",
        value: e,
      })
    );
  };
  const handleTroubledR = (e) => {
    setTroubledR(e);
    dispatch(
      updateElbowPainFieldScore({
        key: "troubledR",
        value: e,
      })
    );
  };

  const handleWorst = (e) => {
    setRateworst(e);
    dispatch(
      updateElbowPainFieldScore({
        key: "rateWorst",
        value: e,
      })
    );
  };
  const handleWorstL = (e) => {
    setRateworstL(e);
    dispatch(
      updateElbowPainFieldScore({
        key: "rateWorstL",
        value: e,
      })
    );
  };
  const handleRest = (e) => {
    setrateRest(e);
    dispatch(
      updateElbowPainFieldScore({
        key: "rateRest",
        value: e,
      })
    );
  };
  const handleRestL = (e) => {
    setrateRestL(e);
    dispatch(
      updateElbowPainFieldScore({
        key: "rateRestL",
        value: e,
      })
    );
  };

  const handleObj = (e) => {
    setrateObj(e);
    dispatch(
      updateElbowPainFieldScore({
        key: "rateObj",
        value: e,
      })
    );
  };
  const handleObjL = (e) => {
    setrateObjL(e);
    dispatch(
      updateElbowPainFieldScore({
        key: "rateObjL",
        value: e,
      })
    );
  };

  const handleMovements = (e) => {
    setrateMovements(e);
    dispatch(
      updateElbowPainFieldScore({
        key: "rateMovements",
        value: e,
      })
    );
  };
  const handleMovementsL = (e) => {
    setrateMovementsL(e);
    dispatch(
      updateElbowPainFieldScore({
        key: "rateMovementsL",
        value: e,
      })
    );
  };

  const handleRateNight = (e) => {
    setRateNight(e);
    dispatch(
      updateElbowPainFieldScore({
        key: "rateNight",
        value: e,
      })
    );
  };
  const handleRateNightL = (e) => {
    setRateNightL(e);
    dispatch(
      updateElbowPainFieldScore({
        key: "rateNightL",
        value: e,
      })
    );
  };
  let akpt = searchParams.get("akpt");
  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update);
    setmsgType("success");
    setTimeout(() => {
      let view_circular=  sessionStorage.getItem("view_circular")
       // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
      //  window.location.href = process.env.REACT_APP_DOCTOR_URL +`view-management-plan/${akpt}/${window.btoa(
      //    rId
      //  )}/?org=${window.btoa(sessionStorage.getItem("orgid")||'')}&formType=${
      //    window.btoa('22') || ""
      //  }&view_circular=${view_circular==='false'?'false':'true'}`
       window.location.href = process.env.REACT_APP_DOCTOR_URL +`patient-details/${akpt}?formid=22`
       setMsg('')
       setmsgType('success')
     }, 2000);
  };
  const handleNext=()=>{
    navigate(`/elbow-function/${rId}?d=true&akpt=${akpt}&is_doctor_form=${doctordata}`);

  }
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
          <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <ElbowTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />

              <h4 className="ph_title f_40">
                {translation.patient_elbow_pain}
              </h4>
              <div className="row pt_20">
                <div className="col-md-12 col-sm-12">
                  {/* <div className="row">
                    <div className="mb_20">
                      <FullDropdown
                        data={[
                          {
                            value: "1",
                            label: "Right",
                          },
                          {
                            value: "2",
                            label: "Left",
                          },
                          {
                            value: "3",
                            label: "Both",
                          },
                        ]}
                        label="Evaluated Elbow"
                        styleClass=""
                        value={evlElbowPain}
                        onChange={handleEvolutedElbowPain}
                      />
                    </div>
                  </div> */}

                  <div className="row">
                    {evlElbowPain === "1" || evlElbowPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                        <RadioSwitch
                          labelLeft={translation.patient_elbow_pain_q1_a1}
                          labelRight={translation.patient_elbow_pain_q1_a2}
                          idLeft="PainRNo"
                          idRight="PainRYes"
                          name="elbowPainR"
                          title={
                            translation.patient_elbow_pain_q1 +
                            RigthTrans(evlElbowPain, translation)
                          }
                          styleClass=""
                          value={elbowPainR}
                          onChange={handleElbowPainR}
                          placeholder={translation.Select}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlElbowPain === "2" || evlElbowPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                        <RadioSwitch
                          labelLeft={translation.patient_elbow_pain_q1_a1}
                          labelRight={translation.patient_elbow_pain_q1_a2}
                          idLeft="PainLNo"
                          idRight="PainLYes"
                          name="elbowPainL"
                          title={
                            translation.patient_elbow_pain_q1 +
                            LeftTrans(evlElbowPain, translation)
                          }
                          styleClass=""
                          value={elbowPainL}
                          onChange={handleElbowPainL}
                          placeholder={translation.Select}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="row">
                    {evlElbowPain === "1" || evlElbowPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                        <FullDropdown
                          data={[
                            {
                              value: "4",
                              label: translation.patient_elbow_pain_q2_a1,
                            },
                            {
                              value: "3",
                              label: translation.patient_elbow_pain_q2_a2,
                            },
                            {
                              value: "2",
                              label: translation.patient_elbow_pain_q2_a3,
                            },
                            {
                              value: "1",
                              label: translation.patient_elbow_pain_q2_a4,
                            },
                            {
                              value: "0",
                              label: translation.patient_elbow_pain_q2_a5,
                            },
                          ]}
                          label={
                            translation.patient_elbow_pain_q2 +
                            RigthTrans(evlElbowPain, translation)
                          }
                          styleClass=""
                          value={decElbowPainR}
                          onChange={handleDecElbowPainR}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlElbowPain === "2" || evlElbowPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                        <FullDropdown
                          data={[
                            {
                              value: "4",
                              label: translation.patient_elbow_pain_q2_a1,
                            },
                            {
                              value: "3",
                              label: translation.patient_elbow_pain_q2_a2,
                            },
                            {
                              value: "2",
                              label: translation.patient_elbow_pain_q2_a3,
                            },
                            {
                              value: "1",
                              label: translation.patient_elbow_pain_q2_a4,
                            },
                            {
                              value: "0",
                              label: translation.patient_elbow_pain_q2_a5,
                            },
                          ]}
                          label={
                            translation.patient_elbow_pain_q2 +
                            LeftTrans(evlElbowPain, translation)
                          }
                          styleClass=""
                          value={decElbowPainL}
                          onChange={handleDecElbowPainL}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="function_formtop">
                    <div className="row">
                      <div className="col-md-6 col-sm-6 function_head">
                        <label>
                          {translation.patient_elbow_pain_instruction}
                        </label>{" "}
                        <br />
                        {/* <span>
                          (<strong>0</strong> = none <strong>1</strong> = mild{" "}
                          <strong>2</strong> = moderate <strong>3</strong> =
                          severe <strong>4</strong> = unable/extreme)
                        </span> */}
                      </div>
                    </div>

                    <div className="row mt-4">
                      {evlElbowPain === "1" || evlElbowPain === "3" ? (
                        <div
                          className={
                            `mb_20 ${evlElbowPain === "3" ? "col-sm-6 col-12" : ""
                            }` + RightSide()
                          }
                        >
                          <RangeSlider
                            id="moment"
                            min="0"
                            max="10"
                            step="1"
                            type="both"
                            label={
                              translation.patient_elbow_pain_q3 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            value={moment}
                            onChange={handleMoment}
                            rightTitle={translation.patient_elbow_pain_q3_a2}
                            leftTitle={translation.patient_elbow_pain_q3_a1}
                          />
                        </div>
                      ) : (
                        ""
                      )}

                      {evlElbowPain === "2" || evlElbowPain === "3" ? (
                        <div
                          className={
                            `mb_20 ${evlElbowPain === "3" ? "col-sm-6 col-12" : ""
                            }` + LeftSide()
                          }
                        >
                          <RangeSlider
                            id="moment"
                            min="0"
                            max="10"
                            step="1"
                            type="both"
                            label={
                              translation.patient_elbow_pain_q3 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            value={momentL}
                            onChange={handleMomentL}
                            rightTitle={translation.patient_elbow_pain_q3_a2}
                            leftTitle={translation.patient_elbow_pain_q3_a1}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="row mt-4">
                      <div
                        className={
                          `mb_20 ${evlElbowPain === "3" ? "col-sm-6 col-12" : ""
                          }` + RightSide()
                        }
                      >
                        {evlElbowPain === "1" || evlElbowPain === "3" ? (
                          <RangeSlider
                            id="rateElbow"
                            min="0"
                            max="10"
                            step="1"
                            type="both"
                            label={
                              translation.patient_elbow_pain_q4 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            value={rateElbow}
                            onChange={handleRateElbow}
                            rightTitle={translation.patient_elbow_pain_q4_a2}
                            leftTitle={translation.patient_elbow_pain_q4_a1}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div
                        className={
                          `mb_20 ${evlElbowPain === "3" ? "col-sm-6 col-12" : ""
                          }` + LeftSide()
                        }
                      >
                        {evlElbowPain === "2" || evlElbowPain === "3" ? (
                          <RangeSlider
                            id="rateElbow"
                            min="0"
                            max="10"
                            step="1"
                            type="both"
                            label={
                              translation.patient_elbow_pain_q4 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            value={rateElbowL}
                            onChange={handleRateElbowL}
                            rightTitle={translation.patient_elbow_pain_q4_a2}
                            leftTitle={translation.patient_elbow_pain_q4_a1}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div
                        className={
                          `mb_20 ${evlElbowPain === "3" ? "col-sm-6 col-12" : ""
                          }` + RightSide()
                        }
                      >
                        {evlElbowPain === "1" || evlElbowPain === "3" ? (
                          <RangeSlider
                            id="rateWorst"
                            min="0"
                            max="10"
                            step="1"
                            type="both"
                            label={
                              translation.patient_elbow_pain_q5 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            value={rateWorst}
                            onChange={handleWorst}
                            rightTitle={translation.patient_elbow_pain_q5_a2}
                            leftTitle={translation.patient_elbow_pain_q5_a1}
                          />
                        ) : (
                          ""
                        )}
                      </div>

                      <div
                        className={
                          `mb_20 ${evlElbowPain === "3" ? "col-sm-6 col-12" : ""
                          }` + LeftSide()
                        }
                      >
                        {evlElbowPain === "2" || evlElbowPain === "3" ? (
                          <RangeSlider
                            id="rateWorst"
                            min="0"
                            max="10"
                            step="1"
                            type="both"
                            label={
                              translation.patient_elbow_pain_q5 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            value={rateWorstL}
                            onChange={handleWorstL}
                            rightTitle={translation.patient_elbow_pain_q5_a2}
                            leftTitle={translation.patient_elbow_pain_q5_a1}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div
                        className={
                          `mb_20 ${evlElbowPain === "3" ? "col-sm-6 col-12" : ""
                          }` + RightSide()
                        }
                      >
                        {evlElbowPain === "1" || evlElbowPain === "3" ? (
                          <RangeSlider
                            id="rateRest"
                            min="0"
                            max="10"
                            step="1"
                            type="both"
                            label={
                              translation.patient_elbow_pain_q6 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            value={rateRest}
                            onChange={handleRest}
                            rightTitle={translation.patient_elbow_pain_q6_a2}
                            leftTitle={translation.patient_elbow_pain_q6_a1}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div
                        className={
                          `mb_20 ${evlElbowPain === "3" ? "col-sm-6 col-12" : ""
                          }` + LeftSide()
                        }
                      >
                        {evlElbowPain === "2" || evlElbowPain === "3" ? (
                          <RangeSlider
                            id="rateRest"
                            min="0"
                            max="10"
                            step="1"
                            type="both"
                            label={
                              translation.patient_elbow_pain_q6 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            value={rateRestL}
                            onChange={handleRestL}
                            rightTitle={translation.patient_elbow_pain_q6_a2}
                            leftTitle={translation.patient_elbow_pain_q6_a1}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div
                        className={
                          `mb_20 ${evlElbowPain === "3" ? "col-sm-6 col-12" : ""
                          }` + RightSide()
                        }
                      >
                        {evlElbowPain === "1" || evlElbowPain === "3" ? (
                          <RangeSlider
                            id="rateObj"
                            min="0"
                            max="10"
                            step="1"
                            type="both"
                            label={
                              translation.patient_elbow_pain_q7 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            value={rateObj}
                            onChange={handleObj}
                            rightTitle={translation.patient_elbow_pain_q7_a2}
                            leftTitle={translation.patient_elbow_pain_q7_a1}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div
                        className={
                          `mb_20 ${evlElbowPain === "3" ? "col-sm-6 col-12" : ""
                          }` + LeftSide()
                        }
                      >
                        {evlElbowPain === "2" || evlElbowPain === "3" ? (
                          <RangeSlider
                            id="rateObj"
                            min="0"
                            max="10"
                            step="1"
                            type="both"
                            label={
                              translation.patient_elbow_pain_q7 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            value={rateObjL}
                            onChange={handleObjL}
                            rightTitle={translation.patient_elbow_pain_q7_a2}
                            leftTitle={translation.patient_elbow_pain_q7_a1}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div
                        className={
                          `mb_20 ${evlElbowPain === "3" ? "col-sm-6 col-12" : ""
                          }` + RightSide()
                        }
                      >
                        {evlElbowPain === "1" || evlElbowPain === "3" ? (
                          <RangeSlider
                            id="rateElbow"
                            min="0"
                            max="10"
                            step="1"
                            type="both"
                            label={
                              translation.patient_elbow_pain_q8 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            value={rateMovements}
                            onChange={handleMovements}
                            rightTitle={translation.patient_elbow_pain_q8_a2}
                            leftTitle={translation.patient_elbow_pain_q8_a1}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div
                        className={
                          `mb_20 ${evlElbowPain === "3" ? "col-sm-6 col-12" : ""
                          }` + LeftSide()
                        }
                      >
                        {evlElbowPain === "2" || evlElbowPain === "3" ? (
                          <RangeSlider
                            id="rateElbow"
                            min="0"
                            max="10"
                            step="1"
                            type="both"
                            label={
                              translation.patient_elbow_pain_q8 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            value={rateMovementsL}
                            onChange={handleMovementsL}
                            rightTitle={translation.patient_elbow_pain_q8_a2}
                            leftTitle={translation.patient_elbow_pain_q8_a1}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div
                        className={
                          `mb_20 ${evlElbowPain === "3" ? "col-sm-6 col-12" : ""
                          }` + RightSide()
                        }
                      >
                        {evlElbowPain === "1" || evlElbowPain === "3" ? (
                          <RangeSlider
                            id="rateNight"
                            min="0"
                            max="10"
                            step="1"
                            type="both"
                            label={
                              translation.patient_elbow_pain_q9 +
                              RigthTrans(evlElbowPain, translation)
                            }
                            value={rateNight}
                            onChange={handleRateNight}
                            rightTitle={translation.patient_elbow_pain_q9_a2}
                            leftTitle={translation.patient_elbow_pain_q9_a1}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div
                        className={
                          `mb_20 ${evlElbowPain === "3" ? "col-sm-6 col-12" : ""
                          }` + LeftSide()
                        }
                      >
                        {evlElbowPain === "2" || evlElbowPain === "3" ? (
                          <RangeSlider
                            id="rateNight"
                            min="0"
                            max="10"
                            step="1"
                            type="both"
                            label={
                              translation.patient_elbow_pain_q9 +
                              LeftTrans(evlElbowPain, translation)
                            }
                            value={rateNightL}
                            onChange={handleRateNightL}
                            rightTitle={translation.patient_elbow_pain_q9_a2}
                            leftTitle={translation.patient_elbow_pain_q9_a1}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    {evlElbowPain === "1" || evlElbowPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                        <KneeScoreRangeSlider
                          label={
                            translation.patient_elbow_pain_q10 +
                            RigthTrans(evlElbowPain, translation)
                          }
                          styleClass=""
                          value={troubledR}
                          onChange={handleTroubledR}
                          sliderData={[
                            {
                              score: "0",
                              label: translation.patient_elbow_pain_q10_a1,
                            },
                            {
                              score: "1",
                              label: translation.patient_elbow_pain_q10_a2,
                            },
                            {
                              score: "2",
                              label: translation.patient_elbow_pain_q10_a3,
                            },
                            {
                              score: "3",
                              label: translation.patient_elbow_pain_q10_a4,
                            },
                            {
                              score: "4",
                              label: translation.patient_elbow_pain_q10_a5,
                            },
                          ]}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlElbowPain === "2" || evlElbowPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                        <KneeScoreRangeSlider
                          label={
                            translation.patient_elbow_pain_q10 +
                            LeftTrans(evlElbowPain, translation)
                          }
                          styleClass=""
                          value={troubledL}
                          onChange={handleTroubledL}
                          sliderData={[
                            {
                              score: "0",
                              label: translation.patient_elbow_pain_q10_a1,
                            },
                            {
                              score: "1",
                              label: translation.patient_elbow_pain_q10_a2,
                            },
                            {
                              score: "2",
                              label: translation.patient_elbow_pain_q10_a3,
                            },
                            {
                              score: "3",
                              label: translation.patient_elbow_pain_q10_a4,
                            },
                            {
                              score: "4",
                              label: translation.patient_elbow_pain_q10_a5,
                            },
                          ]}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="row mt-4">
                    {evlElbowPain === "1" || evlElbowPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                        <KneeScoreRangeSlider
                          label={
                            translation.patient_elbow_pain_q11 +
                            RigthTrans(evlElbowPain, translation)
                          }
                          styleClass=""
                          value={oftenR}
                          onChange={handleOftenR}
                          sliderData={[
                            {
                              score: "0",
                              label: translation.patient_elbow_pain_q11_a1,
                            },
                            {
                              score: "1",
                              label: translation.patient_elbow_pain_q11_a2,
                            },
                            {
                              score: "2",
                              label: translation.patient_elbow_pain_q11_a3,
                            },
                            {
                              score: "3",
                              label: translation.patient_elbow_pain_q11_a4,
                            },
                            {
                              score: "4",
                              label: translation.patient_elbow_pain_q11_a5,
                            },
                          ]}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlElbowPain === "2" || evlElbowPain === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                        <KneeScoreRangeSlider
                          label={
                            translation.patient_elbow_pain_q11 +
                            LeftTrans(evlElbowPain, translation)
                          }
                          styleClass=""
                          value={oftenL}
                          onChange={handleOftenL}
                          sliderData={[
                            {
                              score: "0",
                              label: translation.patient_elbow_pain_q11_a1,
                            },
                            {
                              score: "1",
                              label: translation.patient_elbow_pain_q11_a2,
                            },
                            {
                              score: "2",
                              label: translation.patient_elbow_pain_q11_a3,
                            },
                            {
                              score: "3",
                              label: translation.patient_elbow_pain_q11_a4,
                            },
                            {
                              score: "4",
                              label: translation.patient_elbow_pain_q11_a5,
                            },
                          ]}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

            
              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6">
                    {/* <Link to="/personal-data" className="assm_back_btn">
                       <img src={backIcon} alt="" />
                       <span>{translation.back_button}</span>
                     </Link> */}
                  </div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <button
                      className="next_btn btn_fill me-3"
                      onClick={() => handleNext()}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}

              {doctorEnable !== "true" && viewicon!=="true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    {backDoc != 1 ? <Link
                      to={`/patient-personal-data/${window.btoa(
                        backD.ak_pt
                      )}/${window.btoa(backD.type)}?r=${window.btoa(rId)}`}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link> : <Link
                      to={`/personal-data/${window.btoa(
                        backD.ak_pt
                      )}/${window.btoa(backD.ak_dc)}/${window.btoa(
                        backD.org_id
                      )}/${window.btoa(backD.type)}?is_doctor_form=${doctordata}&doctorvalue=0&r=${window.btoa(rId)}`}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>}


                    {/* <span onClick={()=>Back()} className="assm_back_btn c_pointer">
                    <img src={backIcon} alt="" />
                    <span>{translation.back_button}</span>
                  </span> */}
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
              { viewicon==="true"  &&(
                <div className="row mb_30 button_box">
                <div className="col-md-12 col-sm-12 col-12 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                  </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ElbowPain;
