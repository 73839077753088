export default{
  "patient_shoulder_pain": "Pijn",
  "patient_shoulder_pain_q1": "Heeft u pijn in uw schouder?",
  "patient_shoulder_pain_q1_a1": "Nee",
  "patient_shoulder_pain_q1_a2": "Ja",
  "patient_shoulder_pain_q2": "Op een schaal van 0-15, wat is het hoogste pijnniveau dat je hebt ervaren in je schouder tijdens alledaagse activiteiten in de afgelopen 24 uur? (0 betekent geen pijn, 15 betekent ondraaglijke pijn)",
  "patient_shoulder_pain_q2_a1": "Onverdraaglijke pijn",
  "patient_shoulder_pain_q2_a2": "Geen pijn",
  "patient_shoulder_pain_q3": "Wordt je slaap verstoord door je schouder?",
  "patient_shoulder_pain_q3_a1": "Onverstoorde slaap",
  "patient_shoulder_pain_q3_a2": "Af en toe verstoorde slaap",
  "patient_shoulder_pain_q3_a3": "Elke nacht een verstoorde slaap",
  "patient_shoulder_pain_q4": "Hoe erg is uw pijn vandaag?",
  "patient_shoulder_pain_q5": "Bent u momenteel een roker?",
  "patient_shoulder_pain_q5_a1": "Nee",
  "patient_shoulder_pain_q5_a2": "Ja",
  "patient_shoulder_pain_q5_a3": "Ex-roker",
  "patient_shoulder_pain_q5_a2_q1": "Hoeveel sigaretten rookt u per dag?",
  "patient_shoulder_pain_q5_a3_q1": "Hoe lang geleden bent u gestopt (jaren)?",
  "patient_shoulder_pain_q5_a3_q2": "Hoeveel jaar heeft u gerookt?",
  "patient_shoulder_pain_q6": "Gebruikt u pijnmedicatie (bijv. paracetamol, enz.)?",
  "patient_shoulder_pain_q6_a1": "Nee",
  "patient_shoulder_pain_q6_a2": "Ja",
  "patient_shoulder_pain_q6_a2_q1": "Gebruikt u sterke pijnmedicatie (bijv. codeïne, tramadol, morfine)?",
  "patient_shoulder_pain_q6_a2_q1_a1": "Nee",
  "patient_shoulder_pain_q6_a2_q1_a2": "Ja",
  "patient_shoulder_pain_q6_a2_q2": "Hoeveel tabletten neemt u gemiddeld per dag?",
  "patient_shoulder_pain_q7": "Welke van de volgende uitspraken beschrijft uw pijn het beste?",
  "patient_shoulder_pain_q7_a1": "Geen",
  "patient_shoulder_pain_q7_a2": "Af en toe en licht",
  "patient_shoulder_pain_q7_a3": "Aanwezig tijdens zware of specifieke activiteiten, af en toe aspirine gebruikt",
  "patient_shoulder_pain_q7_a4": "Aanwezig tijdens lichte activiteiten, weinig of geen pijn in rust, vaak aspirine gebruikt",
  "patient_shoulder_pain_q7_a5": "Altijd aanwezig, draaglijk, af en toe sterke medicatie nodig",
  "patient_shoulder_pain_q7_a6": "Altijd aanwezig, ondraaglijk, vaak sterke medicatie nodig",
  "patient_shoulder_pain_q8": "Heeft u tintelingen of prikkelingen in uw arm?",
  "patient_shoulder_pain_q8_a1": "Nee",
  "patient_shoulder_pain_q8_a2": "Ja",
  "patient_shoulder_pain_q9": "Markeer waar de pijn zich bevindt",
  "patient_shoulder_pain_q9_a1": "Voorzijde",
  "patient_shoulder_pain_q9_a2": "Achterzijde",
  "patient_shoulder_function": "Functie",
  "patient_shoulder_function_q1": "Welke van de volgende uitspraken beschrijft het functioneren van uw aangetaste schouder het beste?",
  "patient_shoulder_function_q1_a1": "Normaal",
  "patient_shoulder_function_q1_a2": "Lichte beperking, kan boven schouderniveau werken",
  "patient_shoulder_function_q1_a3": "Meeste huishoudelijke taken, winkelen, autorijden, haar doen, aankleden/uitkleden",
  "patient_shoulder_function_q1_a4": "Kan lichte huishoudelijke taken doen en de meeste dagelijkse activiteiten",
  "patient_shoulder_function_q1_a5": "Alleen lichte activiteiten mogelijk",
  "patient_shoulder_function_q1_a6": "Kan ledemaat niet gebruiken",
  "patient_shoulder_function_instruction": "Selecteer het nummer dat uw vermogen om de volgende activiteiten aan te geven",
  "patient_shoulder_function_instruction_0": "Niet in staat",
  "patient_shoulder_function_instruction_1": "Zeer moeilijk",
  "patient_shoulder_function_instruction_2": "Enigszins moeilijk",
  "patient_shoulder_function_instruction_3": "Niet moeilijk",
  "patient_shoulder_function_note": "Opmerking: Beantwoord alstublieft de volgende vragen met betrekking tot de functie van uw schouder.",
  "patient_shoulder_function_q2": "Jas / shirt aantrekken",
  "patient_shoulder_function_q2_a0": "Niet in staat",
  "patient_shoulder_function_q2_a1": "Zeer moeilijk",
  "patient_shoulder_function_q2_a2": "Enigszins moeilijk",
  "patient_shoulder_function_q2_a3": "Niet moeilijk",
  "patient_shoulder_function_q3": "Op uw pijnlijke of aangetaste zijde slapen",
  "patient_shoulder_function_q3_a0": "Niet in staat",
  "patient_shoulder_function_q3_a1": "Zeer moeilijk",
  "patient_shoulder_function_q3_a2": "Enigszins moeilijk",
  "patient_shoulder_function_q3_a3": "Niet moeilijk",
  "patient_shoulder_function_q4": "Uw rug wassen/maak je beha vast",
  "patient_shoulder_function_q4_a0": "Niet in staat",
  "patient_shoulder_function_q4_a1": "Zeer moeilijk",
  "patient_shoulder_function_q4_a2": "Enigszins moeilijk",
  "patient_shoulder_function_q4_a3": "Niet moeilijk",
  "patient_shoulder_function_q5": "Toiletbezoek regelen",
  "patient_shoulder_function_q5_a0": "Niet in staat",
  "patient_shoulder_function_q5_a1": "Zeer moeilijk",
  "patient_shoulder_function_q5_a2": "Enigszins moeilijk",
  "patient_shoulder_function_q5_a3": "Niet moeilijk",
  "patient_shoulder_function_q6": "Haar kammen",
  "patient_shoulder_function_q6_a0": "Niet in staat",
  "patient_shoulder_function_q6_a1": "Zeer moeilijk",
  "patient_shoulder_function_q6_a2": "Enigszins moeilijk",
  "patient_shoulder_function_q6_a3": "Niet moeilijk",
  "patient_shoulder_function_q7": "Scheren/make-up aanbrengen",
  "patient_shoulder_function_q7_a0": "Niet in staat",
  "patient_shoulder_function_q7_a1": "Zeer moeilijk",
  "patient_shoulder_function_q7_a2": "Enigszins moeilijk",
  "patient_shoulder_function_q7_a3": "Niet moeilijk",
  "patient_shoulder_function_q8": "Tanden poetsen",
  "patient_shoulder_function_q8_a0": "Niet in staat",
  "patient_shoulder_function_q8_a1": "Zeer moeilijk",
  "patient_shoulder_function_q8_a2": "Enigszins moeilijk",
  "patient_shoulder_function_q8_a3": "Niet moeilijk",
  "patient_shoulder_function_q9": "Een glas vullen met een volle fles",
  "patient_shoulder_function_q9_a0": "Niet in staat",
  "patient_shoulder_function_q9_a1": "Zeer moeilijk",
  "patient_shoulder_function_q9_a2": "Enigszins moeilijk",
  "patient_shoulder_function_q9_a3": "Niet moeilijk",
  "patient_shoulder_function_q10": "Drinken (een vol glas naar uw mond brengen)",
  "patient_shoulder_function_q10_a0": "Niet in staat",
  "patient_shoulder_function_q10_a1": "Zeer moeilijk",
  "patient_shoulder_function_q10_a2": "Enigszins moeilijk",
  "patient_shoulder_function_q10_a3": "Niet moeilijk",
  "patient_shoulder_function_q11": "Soep eten (volle lepel naar de mond brengen)",
  "patient_shoulder_function_q11_a0": "Niet in staat",
  "patient_shoulder_function_q11_a1": "Zeer moeilijk",
  "patient_shoulder_function_q11_a2": "Enigszins moeilijk",
  "patient_shoulder_function_q11_a3": "Niet moeilijk",
  "patient_shoulder_function_q12": "Iemand de hand schudden / een deur openen",
  "patient_shoulder_function_q12_a0": "Niet in staat",
  "patient_shoulder_function_q12_a1": "Zeer moeilijk",
  "patient_shoulder_function_q12_a2": "Enigszins moeilijk",
  "patient_shoulder_function_q12_a3": "Niet moeilijk",
  "patient_shoulder_function_q13": "Een telefoon gebruiken (op oorniveau)",
  "patient_shoulder_function_q13_a0": "Niet in staat",
  "patient_shoulder_function_q13_a1": "Zeer moeilijk",
  "patient_shoulder_function_q13_a2": "Enigszins moeilijk",
  "patient_shoulder_function_q13_a3": "Niet moeilijk",
  "patient_shoulder_function_q14": "Een brief schrijven / op papier tekenen / een toetsenbord gebruiken",
  "patient_shoulder_function_q14_a0": "Niet in staat",
  "patient_shoulder_function_q14_a1": "Zeer moeilijk",
  "patient_shoulder_function_q14_a2": "Enigszins moeilijk",
  "patient_shoulder_function_q14_a3": "Niet moeilijk",
  "patient_shoulder_function_q15": "Een hoog schap bereiken (boven schouderniveau)",
  "patient_shoulder_function_q15_a0": "Niet in staat",
  "patient_shoulder_function_q15_a1": "Zeer moeilijk",
  "patient_shoulder_function_q15_a2": "Enigszins moeilijk",
  "patient_shoulder_function_q15_a3": "Niet moeilijk",
  "patient_shoulder_function_q16": "0,5 kg (1 lbs) boven schouderniveau tillen",
  "patient_shoulder_function_q16_a0": "Niet in staat",
  "patient_shoulder_function_q16_a1": "Zeer moeilijk",
  "patient_shoulder_function_q16_a2": "Enigszins moeilijk",
  "patient_shoulder_function_q16_a3": "Niet moeilijk",
  "patient_shoulder_function_q17": "4,5 kg (10 lbs) boven schouderniveau tillen",
  "patient_shoulder_function_q17_a0": "Niet in staat",
  "patient_shoulder_function_q17_a1": "Zeer moeilijk",
  "patient_shoulder_function_q17_a2": "Enigszins moeilijk",
  "patient_shoulder_function_q17_a3": "Niet moeilijk",
  "patient_shoulder_function_q18": "10 kg (22 lbs) aan uw zijde dragen op de aangetaste schouder",
  "patient_shoulder_function_q18_a0": "Niet in staat",
  "patient_shoulder_function_q18_a1": "Zeer moeilijk",
  "patient_shoulder_function_q18_a2": "Enigszins moeilijk",
  "patient_shoulder_function_q18_a3": "Niet moeilijk",
  "patient_shoulder_function_q19": "Een bal onderhands gooien (minstens 3 meter)",
  "patient_shoulder_function_q19_a0": "Niet in staat",
  "patient_shoulder_function_q19_a1": "Zeer moeilijk",
  "patient_shoulder_function_q19_a2": "Enigszins moeilijk",
  "patient_shoulder_function_q19_a3": "Niet moeilijk",
  "patient_shoulder_function_q20": "Een bal overhands gooien (minstens 6 meter)",
  "patient_shoulder_function_q20_a0": "Niet in staat",
  "patient_shoulder_function_q20_a1": "Zeer moeilijk",
  "patient_shoulder_function_q20_a2": "Enigszins moeilijk",
  "patient_shoulder_function_q20_a3": "Niet moeilijk",
  "patient_shoulder_function_q21": "Uw normale werk volledig uitvoeren",
  "patient_shoulder_function_q21_a0": "Niet in staat",
  "patient_shoulder_function_q21_a1": "Zeer moeilijk",
  "patient_shoulder_function_q21_a2": "Enigszins moeilijk",
  "patient_shoulder_function_q21_a3": "Niet moeilijk",
  "patient_shoulder_function_q22": "In hoeverre staat je schouder je toe normaal werk uit te voeren?",
  "patient_shoulder_function_q22_a0": "geen",
  "patient_shoulder_function_q22_a1": "Alles",
  "patient_shoulder_function_q23": "Uw normale hobby's / sport volledig uitvoeren",
  "patient_shoulder_function_q23_a0": "Niet in staat",
  "patient_shoulder_function_q23_a1": "Zeer moeilijk",
  "patient_shoulder_function_q23_a2": "Enigszins moeilijk",
  "patient_shoulder_function_q23_a3": "Niet moeilijk",
  "patient_shoulder_function_q24": "In hoeverre staat je schouder je toe normale recreatieve activiteiten uit te voeren?",
  "patient_shoulder_function_q24_a0": "Geen ",
  "patient_shoulder_function_q24_a1": "Alles",
  "patient_shoulder_function_q25": "Gelieve werkactiviteiten op te sommen",
  "patient_shoulder_function_q26": "Gelieve hobby's / sportactiviteiten op te sommen",
  "patient_shoulder_feeling_instability": "Gevoel van instabiliteit",
  "patient_shoulder_feeling_instability_q1": "Voelt je schouder onstabiel of los aan, alsof hij eruit zou kunnen springen? Of ben je ooit gediagnosticeerd met of behandeld voor een ontwrichte of instabiele schouder?",
  "patient_shoulder_feeling_instability_q1_a1": "Nee",
  "patient_shoulder_feeling_instability_q1_a2": "Ja",
  "patient_shoulder_feeling_instability_q1_a2_q1": "Gelieve aan te geven hoe instabiel uw schouder is",
  "patient_shoulder_wosi": "Western Ontario Shoulder Instability Index",
  "patient_shoulder_wosi_instruction": "Instructies: U wordt gevraagd om op dit deel van de vragenlijst aan te geven hoeveel symptomen u in de afgelopen week hebt ervaren met betrekking tot uw probleemschouder. Verplaats eenvoudig de schuifregelaar langs de lijn zodat deze nauwkeurig overeenkomt met uw symptomen.",
  "patient_shoulder_wosi_note": "Opmerking: Hoe verder u de schuifregelaar naar rechts verplaatst, geeft aan dat u dat symptoom meer ervaart. Hoe verder u de schuifregelaar naar links verplaatst, geeft aan dat u dat symptoom minder ervaart. Als u vragen heeft over de bedoeling van een bepaalde vraag, vraag dan gerust.",
  "patient_shoulder_wosi_sectionA": "Sectie A: Fysieke symptomen",
  "patient_shoulder_wosi_q1": "Hoeveel pijn ervaart u in uw schouder bij activiteiten boven uw hoofd?",
  "patient_shoulder_wosi_q1_a1": "Geen pijn",
  "patient_shoulder_wosi_q1_a2": "Ernstige pijn",
  "patient_shoulder_wosi_q2": "Hoeveel pijn of zeurende pijn ervaart u in uw schouder?",
  "patient_shoulder_wosi_q2_a1": "Geen pijn / zeurende pijn",
  "patient_shoulder_wosi_q2_a2": "Ernstige pijn / zeurende pijn",
  "patient_shoulder_wosi_q3": "Hoeveel zwakte of gebrek aan kracht ervaart u in uw schouder?",
  "patient_shoulder_wosi_q3_a1": "Geen zwakte",
  "patient_shoulder_wosi_q3_a2": "Ernstige zwakte",
  "patient_shoulder_wosi_q4": "Hoeveel vermoeidheid of gebrek aan uithoudingsvermogen ervaart u in uw schouder?",
  "patient_shoulder_wosi_q4_a1": "Geen vermoeidheid",
  "patient_shoulder_wosi_q4_a2": "Ernstige vermoeidheid",
  "patient_shoulder_wosi_q5": "Hoeveel klikken, kraken of knappen ervaart u in uw schouder?",
  "patient_shoulder_wosi_q5_a1": "Geen klikken",
  "patient_shoulder_wosi_q5_a2": "Ernstig klikken",
  "patient_shoulder_wosi_q6": "Hoeveel stijfheid ervaart u in uw schouder?",
  "patient_shoulder_wosi_q6_a1": "Geen stijfheid",
  "patient_shoulder_wosi_q6_a2": "Ernstige stijfheid",
  "patient_shoulder_wosi_q7": "Hoeveel ongemak ervaart u in uw nekspieren als gevolg van uw schouder?",
  "patient_shoulder_wosi_q7_a1": "Geen ongemak",
  "patient_shoulder_wosi_q7_a2": "Ernstig ongemak",
  "patient_shoulder_wosi_q8": "Hoeveel gevoel van instabiliteit of losheid ervaart u in uw schouder?",
  "patient_shoulder_wosi_q8_a1": "Geen instabiliteit",
  "patient_shoulder_wosi_q8_a2": "Ernstige instabiliteit",
  "patient_shoulder_wosi_q9": "Hoeveel compenseert u uw schouder met andere spieren?",
  "patient_shoulder_wosi_q9_a1": "Helemaal niet",
  "patient_shoulder_wosi_q9_a2": "Heel erg",
  "patient_shoulder_wosi_q10": "Hoeveel verlies van bewegingsbereik heeft u in uw schouder?",
  "patient_shoulder_wosi_q10_a1": "Geen verlies",
  "patient_shoulder_wosi_q10_a2": "Ernstig verlies",
  "patient_shoulder_wosi_sectionB": "Sectie B: Sport / Recreatie / Werk",
  "patient_shoulder_wosi_q11": "Hoeveel heeft uw schouder de hoeveelheid sport- of recreatieactiviteiten die u kunt doen beperkt?",
  "patient_shoulder_wosi_q11_a1": "Niet beperkt",
  "patient_shoulder_wosi_q11_a2": "Extreem beperkt",
  "patient_shoulder_wosi_q12": "Hoeveel heeft uw schouder invloed gehad op uw vermogen om de specifieke vaardigheden die nodig zijn voor uw sport of werk uit te voeren?",
  "patient_shoulder_wosi_q12_note": "Als uw schouder zowel sport als werk beïnvloedt, overweeg dan het gebied dat het meest wordt beïnvloed.",
  "patient_shoulder_wosi_q12_a1": "Niet beïnvloed",
  "patient_shoulder_wosi_q12_a2": "Extreem beïnvloed",
  "patient_shoulder_wosi_q13": "Hoeveel voelt u de behoefte om uw arm te beschermen tijdens activiteiten?",
  "patient_shoulder_wosi_q13_a1": "Helemaal niet",
  "patient_shoulder_wosi_q13_a2": "Extreem",
  "patient_shoulder_wosi_q14": "Hoeveel moeite heeft u met het tillen van zware objecten onder schouderniveau?",
  "patient_shoulder_wosi_q14_a1": "Geen moeite",
  "patient_shoulder_wosi_q14_a2": "Ernstige moeite",
  "patient_shoulder_wosi_sectionC": "Sectie C: Levensstijl",
  "patient_shoulder_wosi_q15": "Hoeveel angst heeft u om op uw schouder te vallen?",
  "patient_shoulder_wosi_q15_a1": "Geen angst",
  "patient_shoulder_wosi_q15_a2": "Extreme angst",
  "patient_shoulder_wosi_q16": "Hoeveel moeite heeft u om uw gewenste fitnessniveau te behouden?",
  "patient_shoulder_wosi_q16_a1": "Geen moeite",
  "patient_shoulder_wosi_q16_a2": "Extreme moeite",
  "patient_shoulder_wosi_q17": "Hoeveel moeite heeft u om 'ruw te spelen of te dollen' met familie of vrienden?",
  "patient_shoulder_wosi_q17_a1": "Geen moeite",
  "patient_shoulder_wosi_q17_a2": "Extreme moeite",
  "patient_shoulder_wosi_q18": "Hoeveel moeite heeft u met slapen vanwege uw schouder?",
  "patient_shoulder_wosi_q18_a1": "Geen moeite",
  "patient_shoulder_wosi_q18_a2": "Extreme moeite",
  "patient_shoulder_wosi_sectionD": "Sectie D: Emoties",
  "patient_shoulder_wosi_q19": "Hoe bewust bent u zich van uw schouder?",
  "patient_shoulder_wosi_q19_a1": "Niet bewust",
  "patient_shoulder_wosi_q19_a2": "Extreem bewust",
  "patient_shoulder_wosi_q20": "Hoe bezorgd bent u dat uw schouder erger wordt?",
  "patient_shoulder_wosi_q20_a1": "Geen zorgen",
  "patient_shoulder_wosi_q20_a2": "Extreem bezorgd",
  "patient_shoulder_wosi_q21": "Hoeveel frustratie voelt u vanwege uw schouder?",
  "patient_shoulder_wosi_q21_a1": "Geen frustratie",
  "patient_shoulder_wosi_q21_a2": "Extreem gefrustreerd",
  "patient_shoulder_ac_joint": "AC-gewricht",
  "patient_shoulder_ac_joint_q1": "Heeft u instabiliteit in uw AC-gewricht?",
  "patient_shoulder_ac_joint_q1_note": "Opmerking: Het AC-gewricht is het gewricht aan de bovenkant van de schouder dat het sleutelbeen verbindt met de voorste punt van het schouderblad.",
  "patient_shoulder_ac_joint_q1_a1": "Nee",
  "patient_shoulder_ac_joint_q1_a2": "Ja",
  "patient_shoulder_sacs": "De Specifieke AC-score (SACS)",
  "patient_shoulder_sacs_explanation": "Uitleg: Voor elke vraag wordt u gevraagd om uw niveau van pijn of moeite bij verschillende taken te beoordelen. De waarderingsschaal is ontworpen zodat 0 geen pijn of moeite weerspiegelt en 10 de meest ernstige pijn of moeite weerspiegelt.",
  "patient_shoulder_sacs_note": "Opmerking: Een beoordeling van 2 zou minimale niveaus van pijn weerspiegelen. Als u vragen heeft over de bedoeling van een bepaalde vraag, vraag het dan gerust.",
  "patient_shoulder_sacs_sectionA": "Sectie A: Pijn",
  "patient_shoulder_sacs_a1": "Beoordeel uw niveau van pijn in uw schouder wanneer deze op zijn ergst is - meest ernstig",
  "patient_shoulder_sacs_a1_a1": "Geen pijn",
  "patient_shoulder_sacs_a1_a2": "Meest ernstige pijn",
  "patient_shoulder_sacs_a2": "Beoordeel uw niveau van pijn in uw schouder wanneer deze op zijn best is",
  "patient_shoulder_sacs_a2_a1": "Geen pijn",
  "patient_shoulder_sacs_a2_a2": "Meest ernstige pijn",
  "patient_shoulder_sacs_a3": "Beoordeel het niveau van pijn wanneer er druk of aanraking op de bovenkant van uw schouder wordt uitgeoefend",
  "patient_shoulder_sacs_a3_note": "bijv. Laptop of handtasriem",
  "patient_shoulder_sacs_a3_a1": "Geen pijn",
  "patient_shoulder_sacs_a3_a2": "Meest ernstige pijn",
  "patient_shoulder_sacs_a4": "Beoordeel het niveau van pijn, spanning of trekkend gevoel dat u voelt in uw nek en/of aangetaste schouderbladregio",
  "patient_shoulder_sacs_a4_a1": "Geen pijn",
  "patient_shoulder_sacs_a4_a2": "Meest ernstige pijn",
  "patient_shoulder_sacs_sectionB": "Sectie B: Functie",
  "patient_shoulder_sacs_b1": "Beoordeel de hoeveelheid klikken en/of slijpen en/of schokken die u in uw schouder ervaart",
  "patient_shoulder_sacs_b1_a1": "Geen klikken",
  "patient_shoulder_sacs_b1_a2": "Extreem klikken",
  "patient_shoulder_sacs_b2": "Geef aan of uw arm ondersteuning nodig heeft bij het lopen",
  "patient_shoulder_sacs_b2_note": "bijv. Uw arm rusten in een zak of een mitella gebruiken",
  "patient_shoulder_sacs_b2_a1": "Geen ondersteuning nodig",
  "patient_shoulder_sacs_b2_a2": "Extreem behoefte aan ondersteuning",
  "patient_shoulder_sacs_b3": "Beoordeel uw moeilijkheidsgraad om gewicht te dragen of te leunen op uw arm",
  "patient_shoulder_sacs_b3_note": "bijv. Opdrukken, fietsen, yoga",
  "patient_shoulder_sacs_b3_a1": "Geen moeite",
  "patient_shoulder_sacs_b3_a2": "Extreme moeilijkheid of niet in staat om uit te voeren",
  "patient_shoulder_sacs_b4": "Beoordeel uw moeilijkheidsgraad om zware voorwerpen gedurende meer dan 5 minuten aan uw zijde te dragen",
  "patient_shoulder_sacs_b4_note": "bijv. Tas met boodschappen",
  "patient_shoulder_sacs_b4_a1": "Geen moeite",
  "patient_shoulder_sacs_b4_a2": "Extreme moeilijkheid of niet in staat om uit te voeren",
  "patient_shoulder_sacs_b5": "Beoordeel het moeilijkheidsniveau dat u ervaart bij het reiken met uw aangetaste arm over uw lichaam",
  "patient_shoulder_sacs_b5_note": "bijv. Om over te reiken en de onaangetaste schouder aan te raken",
  "patient_shoulder_sacs_b5_a1": "Geen moeite",
  "patient_shoulder_sacs_b5_a2": "Extreme moeilijkheid of niet in staat om uit te voeren",
  "patient_shoulder_sacs_b6": "Beoordeel uw moeilijkheidsniveau bij het duwen van zware voorwerpen of gewichten boven uw hoofd",
  "patient_shoulder_sacs_b6_note": "bijv. Meer dan vijf kilogram",
  "patient_shoulder_sacs_b6_a1": "Geen moeilijkheid",
  "patient_shoulder_sacs_b6_a2": "Extreme moeilijkheid of niet in staat om uit te voeren",
  "patient_shoulder_sacs_b7": "Beoordeel het moeilijkheidsniveau dat u ervaart bij het voor langere tijd voor u houden van uw aangetaste arm",
  "patient_shoulder_sacs_b7_note": "bijv. Het stuurwiel vasthouden tijdens het autorijden",
  "patient_shoulder_sacs_b7_a1": "Geen moeilijkheid",
  "patient_shoulder_sacs_b7_a2": "Extreme moeilijkheid of niet in staat om uit te voeren",
  "patient_shoulder_sacs_b8": "Beoordeel het moeilijkheidsniveau dat u ervaart bij het uitvoeren van uw gebruikelijke beroep",
  "patient_shoulder_sacs_b8_a1": "Geen moeilijkheid",
  "patient_shoulder_sacs_b8_a2": "Extreme moeilijkheid of niet in staat om uit te voeren",
  "patient_shoulder_sacs_b8_a3": "Niet van toepassing / is niet van toepassing",
  "patient_shoulder_sacs_b9": "Beoordeel hoeveel u uw gebruikelijke beroep heeft moeten veranderen/aanpassen om uw schouderprobleem te compenseren",
  "patient_shoulder_sacs_b9_a1": "Geen moeilijkheid",
  "patient_shoulder_sacs_b9_a2": "Extreme moeilijkheid of niet in staat om uit te voeren",
  "patient_shoulder_sacs_b9_a3": "Niet van toepassing / is niet van toepassing",
  "patient_shoulder_sacs_b10": "Beoordeel het moeilijkheidsniveau dat u ervaart bij het uitoefenen van uw primaire sportieve activiteit",
  "patient_shoulder_sacs_b10_a1": "Geen moeilijkheid",
  "patient_shoulder_sacs_b10_a2": "Extreme moeilijkheid of niet in staat om uit te voeren",
  "patient_shoulder_sacs_b10_a3": "Niet van toepassing / is niet van toepassing",
  "patient_shoulder_sacs_b11": "Beoordeel hoeveel u uw primaire sportieve activiteit heeft moeten aanpassen om uw schouderprobleem te compenseren",
  "patient_shoulder_sacs_b11_a1": "Geen moeilijkheid",
  "patient_shoulder_sacs_b11_a2": "Extreme moeilijkheid of niet in staat om uit te voeren",
  "patient_shoulder_sacs_b11_a3": "Niet van toepassing / is niet van toepassing",
  "patient_shoulder_sacs_sectionC": "Sectie C: Kwaliteit van leven en tevredenheid",
  "patient_shoulder_sacs_c1": "Beoordeel het niveau van verschil in uiterlijk van de botten boven op uw schouder in vergelijking met een normale schouder",
  "patient_shoulder_sacs_c1_note": "Inclusief de aanwezigheid van een bult",
  "patient_shoulder_sacs_c1_a1": "Niet anders",
  "patient_shoulder_sacs_c1_a2": "Extreem anders",
  "patient_shoulder_sacs_c2": "Beoordeel het algemene niveau van angst of stress dat uw schouderprobleem heeft veroorzaakt.",
  "patient_shoulder_sacs_c2_a1": "Geen angst / stress",
  "patient_shoulder_sacs_c2_a2": "Ernstig niveau van angst / stress",
  "patient_shoulder_sacs_c3": "Hoe vaak bent u zich bewust van uw schouderprobleem?",
  "patient_shoulder_sacs_c3_a1": "Nooit",
  "patient_shoulder_sacs_c3_a2": "Constant",
  "patient_shoulder_sacs_c4": "Heeft u uw levensstijl aangepast om mogelijke schade of verergering van uw schouderprobleem te vermijden?",
  "patient_shoulder_sacs_c4_a1": "Helemaal niet",
  "patient_shoulder_sacs_c4_a2": "Volledig",
  "patient_shoulder_sacs_c5": "Hoe ongelukkig/ontevreden bent u met uw schouder?",
  "patient_shoulder_sacs_c5_a1": "Extreem gelukkig",
  "patient_shoulder_sacs_c5_a2": "Extreem ongelukkig",
  "patient_shoulder_sacs_c6": "Hoe zou u uw schouder over het algemeen beoordelen als een percentage in vergelijking met uw verwachtingen van een 'normale' schouder, waarbij 0% slecht is en 100% zeer goed is?",
  "patient_shoulder_satisfaction": "Tevredenheid",
  "patient_shoulder_satisfaction_q1": "Met betrekking tot uw aangetaste schouder, bent u momenteel",
  "patient_shoulder_satisfaction_q1_a1": "Tevreden en/of beter",
  "patient_shoulder_satisfaction_q1_a2": "Niet tevreden en/of slechter",
  "patient_shoulder_complete": "Einde van de vragenlijst",
  "patient_shoulder_complete_text1": "Bedankt dat u de tijd hebt genomen om dit formulier in te vullen.",
  "patient_shoulder_complete_text2": "Klik alstublieft op verzenden om te voltooien en uw scores te zien.",
  "patient_shoulder_complete_text3": "Uw antwoorden worden vertrouwelijk behandeld en alle rapporten zullen alleen geanonimiseerde informatie gebruiken.",
  "patient_shoulder_results": "Resultaten",
  "patient_shoulder_results_text1": "Hieronder staan uw scores voor pijn en schouderfunctie, die zijn berekend op basis van uw vragenlijst.",
  "patient_shoulder_results_pain": "Pijn",
  "patient_shoulder_results_function": "Functie",
  "patient_shoulder_results_instability": "Instabiliteit",
  "patient_shoulder_results_selfevaluation": "Scoren van de schouderzelfevaluatie",
  "patient_shoulder_results_text2": "Uw arts zal binnenkort bij u zijn",
  "patient_hip_pain": "Pijn",
  "patient_hip_pain_q1": "HOE zou u de pijn beschrijven die u meestal heeft aan uw heup IN DE LAATSTE MAAND?",
  "patient_hip_pain_q1_a1": "Geen",
  "patient_hip_pain_q1_a2": "Heel mild",
  "patient_hip_pain_q1_a3": "Mild",
  "patient_hip_pain_q1_a4": "Matig",
  "patient_hip_pain_q1_a5": "Ernstig",
  "patient_hip_pain_q2": "Hoe erg is de pijn aan uw heup OP DIT MOMENT? (0 betekent zonder pijn en 10 betekent de meest ernstige pijn die u zich kunt voorstellen)",
  "patient_hip_pain_q2_a1": "Geen pijn",
  "patient_hip_pain_q2_a2": "Meest ernstige pijn",
  "patient_hip_pain_q3": "Welke van de volgende beschrijft het beste uw pijn?",
  "patient_hip_pain_q3_a1": "Geen / negeert",
  "patient_hip_pain_q3_a2": "Lichte, af en toe, geen invloed op dagelijkse activiteiten",
  "patient_hip_pain_q3_a3": "Milde pijn, geen invloed op gemiddelde activiteiten, pijn na gebruikelijke activiteit, gebruikt aspirine",
  "patient_hip_pain_q3_a4": "Matig, draaglijk, doet concessies, af en toe codeïne",
  "patient_hip_pain_q3_a5": "Ernstige pijn, ernstige beperking",
  "patient_hip_pain_q3_a6": "Volledig gehandicapt",
  "patient_hip_pain_q4": "HOE VAAK heeft u pijn aan uw heup?",
  "patient_hip_pain_q4_a1": "Nooit",
  "patient_hip_pain_q4_a2": "Maandelijks",
  "patient_hip_pain_q4_a3": "Wekelijks",
  "patient_hip_pain_q4_a4": "Dagelijks",
  "patient_hip_pain_q4_a5": "Altijd",
  "patient_hip_pain_q5": "Hoeveel heeft pijn aan uw heup UW GEWONE WERKZAAMHEDEN (inclusief huishoudelijk werk) BEÏNVLOED?",
  "patient_hip_pain_q5_a1": "Helemaal niet",
  "patient_hip_pain_q5_a2": "Een beetje",
  "patient_hip_pain_q5_a3": "Redelijk",
  "patient_hip_pain_q5_a4": "Groot",
  "patient_hip_pain_q5_a5": "Volledig",
  "patient_hip_pain_instruction": "WELKE HOEVEELHEID HEUPPIJN heeft u ervaren tijdens de volgende activiteiten:",
  "patient_hip_pain_instruction_0": "Geen",
  "patient_hip_pain_instruction_1": "Mild",
  "patient_hip_pain_instruction_2": "Matig",
  "patient_hip_pain_instruction_3": "Ernstig",
  "patient_hip_pain_instruction_4": "Extreem",
  "patient_hip_pain_note": "Opmerking: Beantwoord alstublieft de volgende vragen met betrekking tot uw heupactiviteiten.",
  "patient_hip_pain_q6": "Heup volledig strekken",
  "patient_hip_pain_q6_a1": "Geen",
  "patient_hip_pain_q6_a2": "Mild",
  "patient_hip_pain_q6_a3": "Matig",
  "patient_hip_pain_q6_a4": "Ernstig",
  "patient_hip_pain_q6_a5": "Extreem",
  "patient_hip_pain_q7": "Heup volledig buigen",
  "patient_hip_pain_q7_a1": "Geen",
  "patient_hip_pain_q7_a2": "Mild",
  "patient_hip_pain_q7_a3": "Matig",
  "patient_hip_pain_q7_a4": "Ernstig",
  "patient_hip_pain_q7_a5": "Extreem",
  "patient_hip_pain_q8": "Lopen op een vlakke ondergrond",
  "patient_hip_pain_q8_a1": "Geen",
  "patient_hip_pain_q8_a2": "Mild",
  "patient_hip_pain_q8_a3": "Matig",
  "patient_hip_pain_q8_a4": "Ernstig",
  "patient_hip_pain_q8_a5": "Extreem",
  "patient_hip_pain_q9": "Hoe lang kunt u lopen voordat de pijn aan uw heup ernstig wordt? (met of zonder stok)",
  "patient_hip_pain_q9_a1": "Geen pijn / Meer dan 30 minuten / Onbeperkt",
  "patient_hip_pain_q9_a2": "16-30 minuten / 6 blokken",
  "patient_hip_pain_q9_a3": "5-15 minuten / 2-3 blokken",
  "patient_hip_pain_q9_a4": "Alleen binnenshuis",
  "patient_hip_pain_q9_a5": "Kan helemaal niet lopen / ernstige pijn bij het lopen / alleen bed en stoel",
  "patient_hip_pain_q10": "Lopen op een harde ondergrond (asfalt, beton, enz.)",
  "patient_hip_pain_q10_a1": "Geen",
  "patient_hip_pain_q10_a2": "Mild",
  "patient_hip_pain_q10_a3": "Matig",
  "patient_hip_pain_q10_a4": "Ernstig",
  "patient_hip_pain_q10_a5": "Extreem",
  "patient_hip_pain_q11": "Lopen op een oneffen ondergrond",
  "patient_hip_pain_q11_a1": "Geen",
  "patient_hip_pain_q11_a2": "Mild",
  "patient_hip_pain_q11_a3": "Matig",
  "patient_hip_pain_q11_a4": "Ernstig",
  "patient_hip_pain_q11_a5": "Extreem",
  "patient_hip_pain_q12": "Trap op of af gaan",
  "patient_hip_pain_q12_a1": "Geen",
  "patient_hip_pain_q12_a2": "Mild",
  "patient_hip_pain_q12_a3": "Matig",
  "patient_hip_pain_q12_a4": "Ernstig",
  "patient_hip_pain_q12_a5": "Extreem",
  "patient_hip_pain_q13": "s Nachts in bed",
  "patient_hip_pain_q13_a1": "Geen",
  "patient_hip_pain_q13_a2": "Mild",
  "patient_hip_pain_q13_a3": "Matig",
  "patient_hip_pain_q13_a4": "Ernstig",
  "patient_hip_pain_q13_a5": "Extreem",
  "patient_hip_pain_q14": "Hoe vaak heeft u 's nachts last van pijn aan uw heup in bed?",
  "patient_hip_pain_q14_a1": "Geen nachten",
  "patient_hip_pain_q14_a2": "Slechts 1 of 2 nachten",
  "patient_hip_pain_q14_a3": "Enkele nachten",
  "patient_hip_pain_q14_a4": "De meeste nachten",
  "patient_hip_pain_q14_a5": "Elke nacht",
  "patient_hip_pain_q15": "Zitten of liggen",
  "patient_hip_pain_q15_a1": "Geen",
  "patient_hip_pain_q15_a2": "Mild",
  "patient_hip_pain_q15_a3": "Matig",
  "patient_hip_pain_q15_a4": "Ernstig",
  "patient_hip_pain_q15_a5": "Extreem",
  "patient_hip_pain_q16": "Rechtop staan",
  "patient_hip_pain_q16_a1": "Geen",
  "patient_hip_pain_q16_a2": "Mild",
  "patient_hip_pain_q16_a3": "Matig",
  "patient_hip_pain_q16_a4": "Ernstig",
  "patient_hip_pain_q16_a5": "Extreem",
  "patient_hip_pain_q17": "Opstaan uit een stoel",
  "patient_hip_pain_q17_a1": "Geen",
  "patient_hip_pain_q17_a2": "Mild",
  "patient_hip_pain_q17_a3": "Matig",
  "patient_hip_pain_q17_a4": "Ernstig",
  "patient_hip_pain_q17_a5": "Extreem",
  "patient_hip_pain_q18": "Na een activiteit",
  "patient_hip_pain_q18_a1": "Geen",
  "patient_hip_pain_q18_a2": "Mild",
  "patient_hip_pain_q18_a3": "Matig",
  "patient_hip_pain_q18_a4": "Ernstig",
  "patient_hip_pain_q18_a5": "Extreem",
  "patient_hip_other_symptoms": "Andere symptomen",
  "patient_hip_other_symptoms_instruction1": "Kies alstublieft het antwoord dat het beste overeenkomt met uw symptomen aan de heup.",
  "patient_hip_other_symptoms_instruction2": "Als u twijfelt over hoe u een vraag moet beantwoorden, geef dan het beste antwoord dat u kunt.",
  "patient_hip_other_symptoms_instruction3": "Heeft u het volgende:",
  "patient_hip_other_symptoms_instruction_0": "geen",
  "patient_hip_other_symptoms_instruction_1": "mild",
  "patient_hip_other_symptoms_instruction_2": "matig",
  "patient_hip_other_symptoms_instruction_3": "ernstig",
  "patient_hip_other_symptoms_instruction_4": "extreem",
  "patient_hip_other_symptoms_q1": "Knarsen, klikken of een ander soort geluid van uw heup",
  "patient_hip_other_symptoms_q1_a1": "Nooit",
  "patient_hip_other_symptoms_q1_a2": "Zelden",
  "patient_hip_other_symptoms_q1_a3": "Soms",
  "patient_hip_other_symptoms_q1_a4": "Vaak",
  "patient_hip_other_symptoms_q1_a5": "Altijd",
  "patient_hip_other_symptoms_q2": "Haken of blokkeren",
  "patient_hip_other_symptoms_q2_a1": "Geen",
  "patient_hip_other_symptoms_q2_a2": "Mild",
  "patient_hip_other_symptoms_q2_a3": "Matig",
  "patient_hip_other_symptoms_q2_a4": "Ernstig",
  "patient_hip_other_symptoms_q2_a5": "Extreem",
  "patient_hip_other_symptoms_q3": "Stijfheid na het ontwaken 's ochtends",
  "patient_hip_other_symptoms_q3_a1": "Geen",
  "patient_hip_other_symptoms_q3_a2": "Mild",
  "patient_hip_other_symptoms_q3_a3": "Matig",
  "patient_hip_other_symptoms_q3_a4": "Ernstig",
  "patient_hip_other_symptoms_q3_a5": "Extreem",
  "patient_hip_other_symptoms_q4": "Stijfheid na het zitten, liggen of rusten later op de dag",
  "patient_hip_other_symptoms_q4_a1": "Geen",
  "patient_hip_other_symptoms_q4_a2": "Mild",
  "patient_hip_other_symptoms_q4_a3": "Matig",
  "patient_hip_other_symptoms_q4_a4": "Ernstig",
  "patient_hip_other_symptoms_q4_a5": "Extreem",
  "patient_hip_other_symptoms_q5": "Mankeert u?",
  "patient_hip_other_symptoms_q5_a1": "Geen, zelden/nooit",
  "patient_hip_other_symptoms_q5_a2": "Licht, soms of alleen in het begin",
  "patient_hip_other_symptoms_q5_a3": "Matig, vaak, niet alleen in het begin",
  "patient_hip_other_symptoms_q5_a4": "Ernstig, het grootste deel van de tijd",
  "patient_hip_other_symptoms_q5_a5": "De hele tijd of niet in staat om te lopen",
  "patient_hip_other_symptoms_q6": "Plotse, ernstige pijn - 'schietende', 'steken' of 'krampen' van de heup",
  "patient_hip_other_symptoms_q6_a1": "Geen dagen",
  "patient_hip_other_symptoms_q6_a2": "Slechts 1 of 2 dagen",
  "patient_hip_other_symptoms_q6_a3": "Enkele dagen",
  "patient_hip_other_symptoms_q6_a4": "De meeste dagen",
  "patient_hip_other_symptoms_q6_a5": "Elke dag",
  "patient_hip_other_symptoms_q7": "Heup geeft mee",
  "patient_hip_other_symptoms_q7_a1": "Geen",
  "patient_hip_other_symptoms_q7_a2": "Licht",
  "patient_hip_other_symptoms_q7_a3": "Matig",
  "patient_hip_other_symptoms_q7_a4": "Ernstig",
  "patient_hip_other_symptoms_q7_a5": "Extreem",
  "patient_hip_other_symptoms_q8": "Beperkte bewegelijkheid",
  "patient_hip_other_symptoms_q8_a1": "Geen",
  "patient_hip_other_symptoms_q8_a2": "Licht",
  "patient_hip_other_symptoms_q8_a3": "Matig",
  "patient_hip_other_symptoms_q8_a4": "Ernstig",
  "patient_hip_other_symptoms_q8_a5": "Extreem",
  "patient_hip_other_symptoms_q9": "Gebruikt u hulpmiddelen om te lopen?",
  "patient_hip_other_symptoms_q9_a1": "Geen",
  "patient_hip_other_symptoms_q9_a2": "Wandelstok voor lange wandelingen",
  "patient_hip_other_symptoms_q9_a3": "Meestal wandelstok",
  "patient_hip_other_symptoms_q9_a4": "Eén kruk",
  "patient_hip_other_symptoms_q9_a5": "Twee wandelstokken",
  "patient_hip_other_symptoms_q9_a6": "Twee krukken of niet kunnen lopen",
  "patient_hip_function": "Functie",
  "patient_hip_function_instruction": "Geef aan HOEVEEL MOEITE u de afgelopen week heeft gehad met de volgende activiteiten als gevolg van uw heup:",
  "patient_hip_function_instruction_0": "Geen",
  "patient_hip_function_instruction_1": "Licht",
  "patient_hip_function_instruction_2": "Matig",
  "patient_hip_function_instruction_3": "Ernstig",
  "patient_hip_function_instruction_4": "Extreem/niet mogelijk",
  "patient_hip_function_note": "Opmerking: Als u een bepaalde activiteit niet doet, stel u dan voor hoe uw heup zou aanvoelen als u het zou proberen.",
  "patient_hip_function_q1": "Afdalen van trappen",
  "patient_hip_function_q1_a1": "Geen",
  "patient_hip_function_q1_a2": "Licht",
  "patient_hip_function_q1_a3": "Matig",
  "patient_hip_function_q1_a4": "Ernstig",
  "patient_hip_function_q1_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q2": "Omhoog lopen van trappen",
  "patient_hip_function_q2_a1": "Geen",
  "patient_hip_function_q2_a2": "Licht",
  "patient_hip_function_q2_a3": "Matig",
  "patient_hip_function_q2_a4": "Ernstig",
  "patient_hip_function_q2_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q3": "Zitten",
  "patient_hip_function_q3_a1": "Geen",
  "patient_hip_function_q3_a2": "Licht",
  "patient_hip_function_q3_a3": "Matig",
  "patient_hip_function_q3_a4": "Ernstig",
  "patient_hip_function_q3_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q4": "Opstaan vanuit zitten",
  "patient_hip_function_q4_a1": "Geen",
  "patient_hip_function_q4_a2": "Licht",
  "patient_hip_function_q4_a3": "Matig",
  "patient_hip_function_q4_a4": "Ernstig",
  "patient_hip_function_q4_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q5": "Opstaan en gaan zitten van de vloer/grond",
  "patient_hip_function_q5_a1": "Geen",
  "patient_hip_function_q5_a2": "Licht",
  "patient_hip_function_q5_a3": "Matig",
  "patient_hip_function_q5_a4": "Ernstig",
  "patient_hip_function_q5_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q6": "Staan",
  "patient_hip_function_q6_a1": "Geen",
  "patient_hip_function_q6_a2": "Licht",
  "patient_hip_function_q6_a3": "Matig",
  "patient_hip_function_q6_a4": "Ernstig",
  "patient_hip_function_q6_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q7": "15 minuten staan",
  "patient_hip_function_q7_a1": "Geen",
  "patient_hip_function_q7_a2": "Licht",
  "patient_hip_function_q7_a3": "Matig",
  "patient_hip_function_q7_a4": "Ernstig",
  "patient_hip_function_q7_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q8": "Benen wijd uit elkaar zetten",
  "patient_hip_function_q8_a1": "Geen",
  "patient_hip_function_q8_a2": "Licht",
  "patient_hip_function_q8_a3": "Matig",
  "patient_hip_function_q8_a4": "Ernstig",
  "patient_hip_function_q8_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q9": "Grote stappen nemen tijdens het lopen",
  "patient_hip_function_q9_a1": "Geen",
  "patient_hip_function_q9_a2": "Licht",
  "patient_hip_function_q9_a3": "Matig",
  "patient_hip_function_q9_a4": "Ernstig",
  "patient_hip_function_q9_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q10": "Buigen om iets van de vloer op te pakken",
  "patient_hip_function_q10_a1": "Geen",
  "patient_hip_function_q10_a2": "Licht",
  "patient_hip_function_q10_a3": "Matig",
  "patient_hip_function_q10_a4": "Ernstig",
  "patient_hip_function_q10_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q11": "Lopen op een vlakke ondergrond",
  "patient_hip_function_q11_a1": "Geen",
  "patient_hip_function_q11_a2": "Licht",
  "patient_hip_function_q11_a3": "Matig",
  "patient_hip_function_q11_a4": "Ernstig",
  "patient_hip_function_q11_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q12": "Lopen op een ongelijke ondergrond",
  "patient_hip_function_q12_a1": "Geen",
  "patient_hip_function_q12_a2": "Licht",
  "patient_hip_function_q12_a3": "Matig",
  "patient_hip_function_q12_a4": "Ernstig",
  "patient_hip_function_q12_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q13": "Lange afstanden lopen",
  "patient_hip_function_q13_a1": "Geen",
  "patient_hip_function_q13_a2": "Licht",
  "patient_hip_function_q13_a3": "Matig",
  "patient_hip_function_q13_a4": "Ernstig",
  "patient_hip_function_q13_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q14": "Lopen voor lichaamsbeweging",
  "patient_hip_function_q14_a1": "Geen",
  "patient_hip_function_q14_a2": "Licht",
  "patient_hip_function_q14_a3": "Matig",
  "patient_hip_function_q14_a4": "Ernstig",
  "patient_hip_function_q14_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q15": "Omhoog lopen op steile heuvels",
  "patient_hip_function_q15_a1": "Geen",
  "patient_hip_function_q15_a2": "Licht",
  "patient_hip_function_q15_a3": "Matig",
  "patient_hip_function_q15_a4": "Ernstig",
  "patient_hip_function_q15_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q16": "Omlaag lopen op steile heuvels",
  "patient_hip_function_q16_a1": "Geen",
  "patient_hip_function_q16_a2": "Licht",
  "patient_hip_function_q16_a3": "Matig",
  "patient_hip_function_q16_a4": "Ernstig",
  "patient_hip_function_q16_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q17": "Initieel lopen",
  "patient_hip_function_q17_a1": "Geen",
  "patient_hip_function_q17_a2": "Licht",
  "patient_hip_function_q17_a3": "Matig",
  "patient_hip_function_q17_a4": "Ernstig",
  "patient_hip_function_q17_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q18": "Ongeveer 10 minuten lopen",
  "patient_hip_function_q18_a1": "Geen",
  "patient_hip_function_q18_a2": "Licht",
  "patient_hip_function_q18_a3": "Matig",
  "patient_hip_function_q18_a4": "Ernstig",
  "patient_hip_function_q18_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q19": "15 minuten of meer lopen",
  "patient_hip_function_q19_a1": "Geen",
  "patient_hip_function_q19_a2": "Licht",
  "patient_hip_function_q19_a3": "Matig",
  "patient_hip_function_q19_a4": "Ernstig",
  "patient_hip_function_q19_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q20": "Op- en afstappen van stoepranden",
  "patient_hip_function_q20_a1": "Geen",
  "patient_hip_function_q20_a2": "Licht",
  "patient_hip_function_q20_a3": "Matig",
  "patient_hip_function_q20_a4": "Ernstig",
  "patient_hip_function_q20_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q21": "In- en uitstappen uit de auto of het openbaar vervoer (bus), afhankelijk van wat u meestal gebruikt.",
  "patient_hip_function_q21_a1": "Geen",
  "patient_hip_function_q21_a2": "Licht",
  "patient_hip_function_q21_a3": "Matig",
  "patient_hip_function_q21_a4": "Ernstig",
  "patient_hip_function_q21_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q22": "Boodschappen doen",
  "patient_hip_function_q22_a1": "Geen",
  "patient_hip_function_q22_a2": "Licht",
  "patient_hip_function_q22_a3": "Matig",
  "patient_hip_function_q22_a4": "Ernstig",
  "patient_hip_function_q22_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q23": "Sokken/kousen aantrekken",
  "patient_hip_function_q23_a1": "Geen",
  "patient_hip_function_q23_a2": "Licht",
  "patient_hip_function_q23_a3": "Matig",
  "patient_hip_function_q23_a4": "Ernstig",
  "patient_hip_function_q23_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q24": "Sokken/kousen uittrekken",
  "patient_hip_function_q24_a1": "Geen",
  "patient_hip_function_q24_a2": "Licht",
  "patient_hip_function_q24_a3": "Matig",
  "patient_hip_function_q24_a4": "Ernstig",
  "patient_hip_function_q24_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q25": "Liggen in bed (omdraaien, heuppositie behouden)",
  "patient_hip_function_q25_a1": "Geen",
  "patient_hip_function_q25_a2": "Licht",
  "patient_hip_function_q25_a3": "Matig",
  "patient_hip_function_q25_a4": "Ernstig",
  "patient_hip_function_q25_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q26": "Omdraaien in bed",
  "patient_hip_function_q26_a1": "Geen",
  "patient_hip_function_q26_a2": "Licht",
  "patient_hip_function_q26_a3": "Matig",
  "patient_hip_function_q26_a4": "Ernstig",
  "patient_hip_function_q26_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q27": "Opstaan uit bed",
  "patient_hip_function_q27_a1": "Geen",
  "patient_hip_function_q27_a2": "Licht",
  "patient_hip_function_q27_a3": "Matig",
  "patient_hip_function_q27_a4": "Ernstig",
  "patient_hip_function_q27_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q28": "In- en uit de badkuip stappen",
  "patient_hip_function_q28_a1": "Geen",
  "patient_hip_function_q28_a2": "Licht",
  "patient_hip_function_q28_a3": "Matig",
  "patient_hip_function_q28_a4": "Ernstig",
  "patient_hip_function_q28_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q29": "Op en van het toilet gaan",
  "patient_hip_function_q29_a1": "Geen",
  "patient_hip_function_q29_a2": "Licht",
  "patient_hip_function_q29_a3": "Matig",
  "patient_hip_function_q29_a4": "Ernstig",
  "patient_hip_function_q29_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q30": "Jezelf wassen en drogen (overal)",
  "patient_hip_function_q30_a1": "Geen",
  "patient_hip_function_q30_a2": "Licht",
  "patient_hip_function_q30_a3": "Matig",
  "patient_hip_function_q30_a4": "Ernstig",
  "patient_hip_function_q30_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q31": "Lichte tot matige arbeid (staand, lopen)",
  "patient_hip_function_q31_a1": "Geen",
  "patient_hip_function_q31_a2": "Licht",
  "patient_hip_function_q31_a3": "Matig",
  "patient_hip_function_q31_a4": "Ernstig",
  "patient_hip_function_q31_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q32": "Zware huishoudelijke taken (zware dozen tillen, vloeren schrobben, enz.)",
  "patient_hip_function_q32_a1": "Geen",
  "patient_hip_function_q32_a2": "Licht",
  "patient_hip_function_q32_a3": "Matig",
  "patient_hip_function_q32_a4": "Ernstig",
  "patient_hip_function_q32_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q33": "Lichte huishoudelijke taken (koken, stoffen, enz.)",
  "patient_hip_function_q33_a1": "Geen",
  "patient_hip_function_q33_a2": "Licht",
  "patient_hip_function_q33_a3": "Matig",
  "patient_hip_function_q33_a4": "Ernstig",
  "patient_hip_function_q33_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q34": "Kinderen oppakken of dragen",
  "patient_hip_function_q34_a1": "Geen",
  "patient_hip_function_q34_a2": "Licht",
  "patient_hip_function_q34_a3": "Matig",
  "patient_hip_function_q34_a4": "Ernstig",
  "patient_hip_function_q34_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q35": "Hurken",
  "patient_hip_function_q35_a1": "Geen",
  "patient_hip_function_q35_a2": "Licht",
  "patient_hip_function_q35_a3": "Matig",
  "patient_hip_function_q35_a4": "Ernstig",
  "patient_hip_function_q35_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q36": "Joggen voor lichaamsbeweging",
  "patient_hip_function_q36_a1": "Geen",
  "patient_hip_function_q36_a2": "Licht",
  "patient_hip_function_q36_a3": "Matig",
  "patient_hip_function_q36_a4": "Ernstig",
  "patient_hip_function_q36_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q37": "Hardlopen",
  "patient_hip_function_q37_a1": "Geen",
  "patient_hip_function_q37_a2": "Licht",
  "patient_hip_function_q37_a3": "Matig",
  "patient_hip_function_q37_a4": "Ernstig",
  "patient_hip_function_q37_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q38": "Draaien/pivoteren op het belaste been",
  "patient_hip_function_q38_a1": "Geen",
  "patient_hip_function_q38_a2": "Licht",
  "patient_hip_function_q38_a3": "Matig",
  "patient_hip_function_q38_a4": "Ernstig",
  "patient_hip_function_q38_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q39": "Seksuele activiteit",
  "patient_hip_function_q39_a1": "Geen",
  "patient_hip_function_q39_a2": "Licht",
  "patient_hip_function_q39_a3": "Matig",
  "patient_hip_function_q39_a4": "Ernstig",
  "patient_hip_function_q39_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q39_not_relevant": "Dit is niet relevant voor mij",
  "patient_hip_function_q40": "Veeleisende sporten met sprinten of abrupte bewegingen (bijv. voetbal, basketbal, tennis, aerobics)",
  "patient_hip_function_q40_a1": "Geen",
  "patient_hip_function_q40_a2": "Licht",
  "patient_hip_function_q40_a3": "Matig",
  "patient_hip_function_q40_a4": "Ernstig",
  "patient_hip_function_q40_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q41": "Weinig veeleisende sporten (bijv. golf, bowlen)",
  "patient_hip_function_q41_a1": "Geen",
  "patient_hip_function_q41_a2": "Licht",
  "patient_hip_function_q41_a3": "Matig",
  "patient_hip_function_q41_a4": "Ernstig",
  "patient_hip_function_q41_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q42": "Recreatieve activiteiten",
  "patient_hip_function_q42_a1": "Geen",
  "patient_hip_function_q42_a2": "Licht",
  "patient_hip_function_q42_a3": "Matig",
  "patient_hip_function_q42_a4": "Ernstig",
  "patient_hip_function_q42_a5": "Extreem/niet mogelijk",
  "patient_hip_function_q43": "Hoe zou u uw heupen vandaag beoordelen als een percentage van normaal? (0% tot 100% met 100% als normaal)",
  "patient_hip_function_q44": "Gelieve het hoogste activiteitsniveau te specificeren waaraan u kunt deelnemen",
  "patient_hip_function_q44_q1": "Voorafgaand aan heupblessure/heupprobleem",
  "patient_hip_function_q44_q1_a1": "Competitief",
  "patient_hip_function_q44_q1_a2": "Recreatief, lichamelijke conditie",
  "patient_hip_function_q44_q1_a3": "Geen sport, alleen werk",
  "patient_hip_function_q44_q1_a4": "Alleen dagelijkse activiteiten",
  "patient_hip_function_q44_q1_a5": "Ziekteverlof of arbeidsongeschiktheidsuitkering vanwege gezondheidsproblemen",
  "patient_hip_function_q44_q1_a1_o1": "Competitiesporten op wereld- of olympisch niveau of professioneel gespeeld",
  "patient_hip_function_q44_q1_a1_o2": "Competitiesporten zoals atletiek, racketsporten, gymnastiek, roeien, skiën of vechtsporten op nationaal of internationaal niveau",
  "patient_hip_function_q44_q1_a1_o3": "Competitiesporten zoals watersporten, fietsen, hockey, curling of paardrijden/rodeo op nationaal of internationaal niveau",
  "patient_hip_function_q44_q1_a2_o1": "Recreatieve sporten zoals hardlopen, balsporten/racketsporten, krachttraining, curling, roeien, golf, yoga of gymnastiek minstens 5 keer per week",
  "patient_hip_function_q44_q1_a2_o2": "Recreatieve sporten zoals zwemmen, watersporten, skiën, hockey, skaten, fietsen, paardrijden of bergbeklimmen minstens 5 keer per week",
  "patient_hip_function_q44_q1_a2_o3": "Recreatieve sporten minstens tweemaal per week",
  "patient_hip_function_q44_q1_a2_o4": "Recreatieve sporten eens per week of minder",
  "patient_hip_function_q44_q1_a2_o5": "Recreatieve sporten minder dan eens per maand",
  "patient_hip_function_q44_q1_a3_o1": "Zwaar werk zoals bouw",
  "patient_hip_function_q44_q1_a3_o2": "Matig zwaar werk zoals vrachtwagenchauffeur",
  "patient_hip_function_q44_q1_a3_o3": "Licht werk zoals verpleging",
  "patient_hip_function_q44_q1_a3_o4": "Zittend werk zoals secretarieel of computerwerk",
  "patient_hip_function_q44_q1_a4_o1": "Dagelijkse activiteiten zoals tuinieren, meerdere trappen beklimmen, lasten dragen, duwen/trekken van een last, mogelijkheid om te rennen als u te laat bent",
  "patient_hip_function_q44_q1_a4_o2": "Dagelijkse activiteiten zoals schoonmaken, 1 of 2 trappen beklimmen of lopen met een lichte last",
  "patient_hip_function_q44_q1_a4_o3": "Dagelijkse activiteiten beperkt (bijv. geen trappen nemen, niet in staat om lasten te dragen)",
  "patient_hip_function_q44_q2": "Huidige status",
  "patient_hip_function_q44_q2_a1": "Competitief",
  "patient_hip_function_q44_q2_a2": "Recreatief, lichamelijke conditie",
  "patient_hip_function_q44_q2_a3": "Geen sport, alleen werk",
  "patient_hip_function_q44_q2_a4": "Alleen dagelijkse activiteiten",
  "patient_hip_function_q44_q2_a5": "Ziekteverlof of arbeidsongeschiktheidsuitkering vanwege gezondheidsproblemen",
  "patient_hip_function_q44_q2_a1_o1": "Competitiesporten op wereld- of olympisch niveau of professioneel gespeeld",
  "patient_hip_function_q44_q2_a1_o2": "Competitiesporten zoals atletiek, racketsporten, gymnastiek, roeien, skiën of vechtsporten op nationaal of internationaal niveau",
  "patient_hip_function_q44_q2_a1_o3": "Competitiesporten zoals watersporten, fietsen, hockey, curling of paardrijden/rodeo op nationaal of internationaal niveau",
  "patient_hip_function_q44_q2_a2_o1": "Recreatieve sporten zoals hardlopen, balsporten/racketsporten, krachttraining, curling, roeien, golf, yoga of gymnastiek minstens 5 keer per week",
  "patient_hip_function_q44_q2_a2_o2": "Recreatieve sporten zoals zwemmen, watersporten, skiën, hockey, skaten, fietsen, paardrijden of bergbeklimmen minstens 5 keer per week",
  "patient_hip_function_q44_q2_a2_o3": "Recreatieve sporten minstens tweemaal per week",
  "patient_hip_function_q44_q2_a2_o4": "Recreatieve sporten eens per week of minder",
  "patient_hip_function_q44_q2_a2_o5": "Recreatieve sporten minder dan eens per maand",
  "patient_hip_function_q44_q2_a3_o1": "Zwaar werk zoals bouw",
  "patient_hip_function_q44_q2_a3_o2": "Matig zwaar werk zoals vrachtwagenchauffeur",
  "patient_hip_function_q44_q2_a3_o3": "Licht werk zoals verpleging",
  "patient_hip_function_q44_q2_a3_o4": "Zittend werk zoals secretarieel of computerwerk",
  "patient_hip_function_q44_q2_a4_o1": "Dagelijkse activiteiten zoals tuinieren, meerdere trappen beklimmen, lasten dragen, duwen/trekken van een last, mogelijkheid om te rennen als u te laat bent",
  "patient_hip_function_q44_q2_a4_o2": "Dagelijkse activiteiten zoals schoonmaken, 1 of 2 trappen beklimmen of lopen met een lichte last",
  "patient_hip_function_q44_q2_a4_o3": "Dagelijkse activiteiten beperkt (bijv. geen trappen nemen, niet in staat om lasten te dragen)",
  "patient_hip_quality_of_life": "Kwaliteit van leven",
  "patient_hip_quality_of_life_instruction": "De volgende vragen gaan over sociale, emotionele en levensstijlgerelateerde zorgen die u mogelijk ervaart met betrekking tot uw heupprobleem. Denk alstublieft aan hoe u zich het grootste deel van de tijd heeft gevoeld in verband met uw heup.",
  "patient_hip_quality_of_life_q1": "Hoe vaak bent u zich bewust van uw heupprobleem?",
  "patient_hip_quality_of_life_q1_a1": "Nooit",
  "patient_hip_quality_of_life_q1_a2": "Maandelijks",
  "patient_hip_quality_of_life_q1_a3": "Wekelijks",
  "patient_hip_quality_of_life_q1_a4": "Dagelijks",
  "patient_hip_quality_of_life_q1_a5": "Constant",
  "patient_hip_quality_of_life_q2": "Heeft u uw levensstijl aangepast om potentieel schadelijke activiteiten voor uw heup te vermijden?",
  "patient_hip_quality_of_life_q2_a1": "Helemaal niet",
  "patient_hip_quality_of_life_q2_a2": "Licht",
  "patient_hip_quality_of_life_q2_a3": "Matig",
  "patient_hip_quality_of_life_q2_a4": "Ernstig",
  "patient_hip_quality_of_life_q2_a5": "Volledig",
  "patient_hip_quality_of_life_q3": "Hoe bezorgd bent u over uw vermogen om uw gewenste fitnessniveau te behouden?",
  "patient_hip_quality_of_life_q3_a1": "Helemaal niet bezorgd",
  "patient_hip_quality_of_life_q3_a2": "Uiterst bezorgd",
  "patient_hip_quality_of_life_q4": "Hoeveel last heeft u van het gebrek aan vertrouwen in uw heup?",
  "patient_hip_quality_of_life_q4_a1": "Helemaal geen last",
  "patient_hip_quality_of_life_q4_a2": "Licht",
  "patient_hip_quality_of_life_q4_a3": "Matig",
  "patient_hip_quality_of_life_q4_a4": "Ernstig",
  "patient_hip_quality_of_life_q4_a5": "Uiterst",
  "patient_hip_quality_of_life_q5": "In hoeverre leidt uw heupprobleem u af?",
  "patient_hip_quality_of_life_q5_a1": "Helemaal geen afleiding",
  "patient_hip_quality_of_life_q5_a2": "Uiterst afleidend",
  "patient_hip_quality_of_life_q6": "Over het algemeen, hoeveel moeite heeft u met uw heup?",
  "patient_hip_quality_of_life_q6_a1": "Geen",
  "patient_hip_quality_of_life_q6_a2": "Licht",
  "patient_hip_quality_of_life_q6_a3": "Matig",
  "patient_hip_quality_of_life_q6_a4": "Ernstig",
  "patient_hip_quality_of_life_q6_a5": "Uiterst",
  "patient_hip_quality_of_life_q7": "Hoe angstig/gedeprimeerd voelt u zich vandaag?",
  "patient_hip_quality_of_life_q7_a1": "Helemaal niet",
  "patient_hip_quality_of_life_q7_a2": "Een beetje",
  "patient_hip_quality_of_life_q7_a3": "Matig",
  "patient_hip_quality_of_life_q7_a4": "Ernstig",
  "patient_hip_quality_of_life_q7_a5": "Uiterst",
  "patient_hip_quality_of_life_q8": "Hoe goed/slecht is uw gezondheid vandaag? (100 betekent de beste gezondheid, 0 betekent de slechtste gezondheid die u zich kunt voorstellen)",
  "patient_hip_quality_of_life_q8_a1": "Slechtste gezondheid",
  "patient_hip_quality_of_life_q8_a2": "Beste gezondheid",
  "patient_hip_satisfaction": "Tevredenheid",
  "patient_hip_satisfaction_instructions": "Hoe tevreden bent u met de resultaten van uw heupoperatie/behandeling?",
  "patient_hip_satisfaction_instructions_1": "Heel ontevreden",
  "patient_hip_satisfaction_instructions_2": "Enigszins ontevreden",
  "patient_hip_satisfaction_instructions_3": "Enigszins tevreden",
  "patient_hip_satisfaction_instructions_4": "Heel tevreden",
  "patient_hip_satisfaction_q1": "Algemeen",
  "patient_hip_satisfaction_q1_not_applicable": "Niet van toepassing",
  "patient_hip_satisfaction_q1_a1": "Heel ontevreden",
  "patient_hip_satisfaction_q1_a2": "Enigszins ontevreden",
  "patient_hip_satisfaction_q1_a3": "Enigszins tevreden",
  "patient_hip_satisfaction_q1_a4": "Heel tevreden",
  "patient_hip_satisfaction_q2": "Voor het verminderen van uw pijn?",
  "patient_hip_satisfaction_q2_not_applicable": "Niet van toepassing",
  "patient_hip_satisfaction_q2_a1": "Heel ontevreden",
  "patient_hip_satisfaction_q2_a2": "Enigszins ontevreden",
  "patient_hip_satisfaction_q2_a3": "Enigszins tevreden",
  "patient_hip_satisfaction_q2_a4": "Heel tevreden",
  "patient_hip_satisfaction_q3": "Voor het verbeteren van uw vermogen om thuis of in de tuin te werken?",
  "patient_hip_satisfaction_q3_not_applicable": "Niet van toepassing",
  "patient_hip_satisfaction_q3_a1": "Heel ontevreden",
  "patient_hip_satisfaction_q3_a2": "Enigszins ontevreden",
  "patient_hip_satisfaction_q3_a3": "Enigszins tevreden",
  "patient_hip_satisfaction_q3_a4": "Heel tevreden",
  "patient_hip_satisfaction_q4": "Voor het verbeteren van uw vermogen om recreatieve activiteiten te doen?",
  "patient_hip_satisfaction_q4_not_applicable": "Niet van toepassing",
  "patient_hip_satisfaction_q4_a1": "Heel ontevreden",
  "patient_hip_satisfaction_q4_a2": "Enigszins ontevreden",
  "patient_hip_satisfaction_q4_a3": "Enigszins tevreden",
  "patient_hip_satisfaction_q4_a4": "Heel tevreden",
  "patient_hip_complete": "Einde van de vragenlijst",
  "patient_hip_complete_text1": "Bedankt voor uw tijd om dit formulier in te vullen.",
  "patient_hip_complete_text2": "Klik op verzenden om te voltooien en uw scores te bekijken.",
  "patient_hip_complete_text3": "Uw antwoorden worden vertrouwelijk behandeld en eventuele rapporten zullen alleen geanonimiseerde informatie gebruiken.",
  "patient_hip_results": "Resultaten",
  "patient_hip_results_text1": "Hieronder vindt u uw pijn- en heupfunctiescores, die worden berekend op basis van uw vragenlijst.",
  "patient_hip_results_pain": "Pijn",
  "patient_hip_results_function": "Functie",
  "patient_hip_results_health_perception": "Algemene gezondheidsperceptie",
  "patient_hip_results_self_evaluation": "Heupbeoordeling zelfevaluatie",
  "patient_hip_results_text2": "Uw arts komt zo bij u.",
  "": "",
  "patient_adult_knee_pain": "Pain",
  "patient_adult_knee_pain_q1": "Wat is het hoogste activiteitsniveau waarop u kunt functioneren zonder significante kniepijn?",
  "patient_adult_knee_pain_q1_a1": "Zeer inspannende activiteiten zoals springen of pivoteren, zoals basketbal of voetbal",
  "patient_adult_knee_pain_q1_a2": "Inspannende activiteiten zoals zwaar fysiek werk, skiën of tennis",
  "patient_adult_knee_pain_q1_a3": "Matige activiteiten zoals matig fysiek werk, rennen of joggen",
  "patient_adult_knee_pain_q1_a4": "Lichte activiteiten zoals wandelen, huishoudelijk werk of tuinieren",
  "patient_adult_knee_pain_q1_a5": "Niet in staat om een van bovenstaande activiteiten uit te voeren vanwege kniepijn",
  "patient_adult_knee_pain_q2": "Hoe vaak heeft u pijn?",
  "patient_adult_knee_pain_q2_a1": "Nooit",
  "patient_adult_knee_pain_q2_a2": "Maandelijks",
  "patient_adult_knee_pain_q2_a3": "Wekelijks",
  "patient_adult_knee_pain_q2_a4": "Dagelijks",
  "patient_adult_knee_pain_q2_a5": "Constant",
  "patient_adult_knee_pain_q3": "Welke van de volgende uitspraken beschrijft uw pijn het beste?",
  "patient_adult_knee_pain_q3_a1": "Geen pijn, normale knie, functioneert op 100%",
  "patient_adult_knee_pain_q3_a2": "Af en toe pijn bij inspannende sporten en zwaar werk, knie niet helemaal normaal, enkele beperkingen maar gering en draaglijk",
  "patient_adult_knee_pain_q3_a3": "Pijn treedt meestal op bij sporten, lichte recreatieve activiteiten of matig werk. Soms bij wandelen van meer dan 2 km, staan of licht werk",
  "patient_adult_knee_pain_q3_a4": "Af en toe pijn bij lichte recreatieve sporten of matige werkactiviteiten, rennen of zwaar lichamelijk werk, inspannende sporten",
  "patient_adult_knee_pain_q3_a5": "Pijn is een aanzienlijk probleem bij eenvoudige activiteiten zoals wandelen <2 km, verlicht door rust, niet in staat om aan sport te doen",
  "patient_adult_knee_pain_q3_a6": "Constant",
  "patient_adult_knee_pain_q4": "Als u pijn heeft, hoe ernstig is deze?",
  "patient_adult_knee_pain_q4_a1": "Geen",
  "patient_adult_knee_pain_q4_a2": "Heel mild",
  "patient_adult_knee_pain_q4_a3": "Mild",
  "patient_adult_knee_pain_q4_a4": "Matig",
  "patient_adult_knee_pain_q4_a5": "Ernstig",
  "patient_adult_knee_pain_q5": "Hoeveel heeft de pijn in uw knie uw gebruikelijke werkzaamheden belemmerd (inclusief huishoudelijk werk)?",
  "patient_adult_knee_pain_q5_a1": "Helemaal niet",
  "patient_adult_knee_pain_q5_a2": "Een beetje",
  "patient_adult_knee_pain_q5_a3": "Matig",
  "patient_adult_knee_pain_q5_a4": "Groot",
  "patient_adult_knee_pain_q5_a5": "Volledig",
  "patient_adult_knee_pain_instruction": "Hoeveel kniepijn heeft u ervaren tijdens de volgende activiteiten:",
  "patient_adult_knee_pain_instruction_0": "Geen",
  "patient_adult_knee_pain_instruction_1": "Licht",
  "patient_adult_knee_pain_instruction_2": "Matig",
  "patient_adult_knee_pain_instruction_3": "Ernstig",
  "patient_adult_knee_pain_instruction_4": "Extreem",
  "patient_adult_knee_pain_q6": "Knie draaien/draaien",
  "patient_adult_knee_pain_q6_a1": "Geen",
  "patient_adult_knee_pain_q6_a2": "Licht",
  "patient_adult_knee_pain_q6_a3": "Matig",
  "patient_adult_knee_pain_q6_a4": "Ernstig",
  "patient_adult_knee_pain_q6_a5": "Extreem",
  "patient_adult_knee_pain_q7": "Knie volledig strekken",
  "patient_adult_knee_pain_q7_a1": "Geen",
  "patient_adult_knee_pain_q7_a2": "Licht",
  "patient_adult_knee_pain_q7_a3": "Matig",
  "patient_adult_knee_pain_q7_a4": "Ernstig",
  "patient_adult_knee_pain_q7_a5": "Extreem",
  "patient_adult_knee_pain_q8": "Knie volledig buigen",
  "patient_adult_knee_pain_q8_a1": "Geen",
  "patient_adult_knee_pain_q8_a2": "Licht",
  "patient_adult_knee_pain_q8_a3": "Matig",
  "patient_adult_knee_pain_q8_a4": "Ernstig",
  "patient_adult_knee_pain_q8_a5": "Extreem",
  "patient_adult_knee_pain_q9": "Lopen op een vlakke ondergrond",
  "patient_adult_knee_pain_q9_a1": "Geen",
  "patient_adult_knee_pain_q9_a2": "Licht",
  "patient_adult_knee_pain_q9_a3": "Matig",
  "patient_adult_knee_pain_q9_a4": "Ernstig",
  "patient_adult_knee_pain_q9_a5": "Extreem",
  "patient_adult_knee_pain_q10": "Hoe lang kunt u lopen voordat de pijn in uw knie ernstig wordt? (met of zonder stok)",
  "patient_adult_knee_pain_q10_a1": "Meer dan 30 minuten",
  "patient_adult_knee_pain_q10_a2": "16-30 minuten",
  "patient_adult_knee_pain_q10_a3": "5-15 minuten",
  "patient_adult_knee_pain_q10_a4": "Alleen rondom het huis",
  "patient_adult_knee_pain_q10_a5": "Kan helemaal niet lopen/pijn is ernstig tijdens het lopen",
  "patient_adult_knee_pain_q11": "Trappen oplopen of afgaan",
  "patient_adult_knee_pain_q11_a1": "Geen",
  "patient_adult_knee_pain_q11_a2": "Licht",
  "patient_adult_knee_pain_q11_a3": "Matig",
  "patient_adult_knee_pain_q11_a4": "Ernstig",
  "patient_adult_knee_pain_q11_a5": "Extreem",
  "patient_adult_knee_pain_q12": "s Nachts in bed",
  "patient_adult_knee_pain_q12_a1": "Geen",
  "patient_adult_knee_pain_q12_a2": "Licht",
  "patient_adult_knee_pain_q12_a3": "Matig",
  "patient_adult_knee_pain_q12_a4": "Ernstig",
  "patient_adult_knee_pain_q12_a5": "Extreem",
  "patient_adult_knee_pain_q13": "Hoe vaak heeft u 's nachts last van kniepijn in bed?",
  "patient_adult_knee_pain_q13_a1": "Geen nachten",
  "patient_adult_knee_pain_q13_a2": "1 of 2 nachten",
  "patient_adult_knee_pain_q13_a3": "Sommige nachten",
  "patient_adult_knee_pain_q13_a4": "De meeste nachten",
  "patient_adult_knee_pain_q13_a5": "Elke nacht",
  "patient_adult_knee_pain_q14": "Zitten of liggen",
  "patient_adult_knee_pain_q14_a1": "Geen",
  "patient_adult_knee_pain_q14_a2": "Licht",
  "patient_adult_knee_pain_q14_a3": "Matig",
  "patient_adult_knee_pain_q14_a4": "Ernstig",
  "patient_adult_knee_pain_q14_a5": "Extreem",
  "patient_adult_knee_pain_q15": "Rechtop staan",
  "patient_adult_knee_pain_q15_a1": "Geen",
  "patient_adult_knee_pain_q15_a2": "Licht",
  "patient_adult_knee_pain_q15_a3": "Matig",
  "patient_adult_knee_pain_q15_a4": "Ernstig",
  "patient_adult_knee_pain_q15_a5": "Extreem",
  "patient_adult_knee_pain_q16": "Opstaan uit een stoel",
  "patient_adult_knee_pain_q16_a1": "Geen",
  "patient_adult_knee_pain_q16_a2": "Licht",
  "patient_adult_knee_pain_q16_a3": "Matig",
  "patient_adult_knee_pain_q16_a4": "Ernstig",
  "patient_adult_knee_pain_q16_a5": "Extreem",
  "patient_adult_knee_other_symptoms": "Andere Symptomen",
  "patient_adult_knee_other_symptoms_instruction1": "Kies alstublieft het antwoord dat het beste overeenkomt met uw kniesymptomen.",
  "patient_adult_knee_other_symptoms_instruction2": "Als u niet zeker weet hoe u een vraag moet beantwoorden, geef dan het beste antwoord dat u kunt.",
  "patient_adult_knee_other_symptoms_instruction3": "Heeft u het volgende:",
  "patient_adult_knee_other_symptoms_instruction_0": "Geen",
  "patient_adult_knee_other_symptoms_instruction_1": "Licht",
  "patient_adult_knee_other_symptoms_instruction_2": "Matig",
  "patient_adult_knee_other_symptoms_instruction_3": "Ernstig",
  "patient_adult_knee_other_symptoms_instruction_4": "Extreem",
  "patient_adult_knee_other_symptoms_q1": "Zwelling",
  "patient_adult_knee_other_symptoms_q1_a1": "Nooit",
  "patient_adult_knee_other_symptoms_q1_a2": "Zelden",
  "patient_adult_knee_other_symptoms_q1_a3": "Soms",
  "patient_adult_knee_other_symptoms_q1_a4": "Vaak",
  "patient_adult_knee_other_symptoms_q1_a5": "Altijd",
  "patient_adult_knee_other_symptoms_q2": "Kraken, klikken of enig ander geluid wanneer uw knie beweegt",
  "patient_adult_knee_other_symptoms_q2_a1": "Nooit",
  "patient_adult_knee_other_symptoms_q2_a2": "Zelden",
  "patient_adult_knee_other_symptoms_q2_a3": "Soms",
  "patient_adult_knee_other_symptoms_q2_a4": "Vaak",
  "patient_adult_knee_other_symptoms_q2_a5": "Altijd",
  "patient_adult_knee_other_symptoms_q3": "Haken of vastlopen",
  "patient_adult_knee_other_symptoms_q3_a1": "Nooit",
  "patient_adult_knee_other_symptoms_q3_a2": "Zelden",
  "patient_adult_knee_other_symptoms_q3_a3": "Soms",
  "patient_adult_knee_other_symptoms_q3_a4": "Vaak",
  "patient_adult_knee_other_symptoms_q3_a5": "Altijd",
  "patient_adult_knee_other_symptoms_q4": "Mank lopen",
  "patient_adult_knee_other_symptoms_q4_a1": "Zelden/nooit",
  "patient_adult_knee_other_symptoms_q4_a2": "Soms, of alleen in het begin",
  "patient_adult_knee_other_symptoms_q4_a3": "Vaak, niet alleen in het begin",
  "patient_adult_knee_other_symptoms_q4_a4": "Meestal",
  "patient_adult_knee_other_symptoms_q4_a5": "Altijd",
  "patient_adult_knee_other_symptoms_q5": "Hoe stijf of gezwollen was uw knie de afgelopen 4 weken of sinds uw knieblessure?",
  "patient_adult_knee_other_symptoms_q5_a1": "Helemaal niet",
  "patient_adult_knee_other_symptoms_q5_a2": "Licht",
  "patient_adult_knee_other_symptoms_q5_a3": "Matig",
  "patient_adult_knee_other_symptoms_q5_a4": "Zeer",
  "patient_adult_knee_other_symptoms_q5_a5": "Extreem",
  "patient_adult_knee_other_symptoms_q6": "Wat is het hoogste activiteitsniveau waarop u kunt functioneren zonder significante ZWELLING in uw knie?",
  "patient_adult_knee_other_symptoms_q6_a1": "Zeer inspannende activiteiten zoals springen of pivoteren, zoals basketbal of voetbal",
  "patient_adult_knee_other_symptoms_q6_a2": "Inspannende activiteiten zoals zwaar fysiek werk, skiën of tennis",
  "patient_adult_knee_other_symptoms_q6_a3": "Matige activiteiten zoals matig fysiek werk, rennen of joggen",
  "patient_adult_knee_other_symptoms_q6_a4": "Lichte activiteiten zoals wandelen, huishoudelijk werk of tuinieren",
  "patient_adult_knee_other_symptoms_q6_a5": "Niet in staat om een van bovenstaande activiteiten uit te voeren vanwege zwelling van de knie",
  "patient_adult_knee_other_symptoms_q7": "Hoe ernstig is uw gewrichtsstijfheid:",
  "patient_adult_knee_other_symptoms_q7_0": "Geen",
  "patient_adult_knee_other_symptoms_q7_1": "Licht",
  "patient_adult_knee_other_symptoms_q7_2": "Matig",
  "patient_adult_knee_other_symptoms_q7_3": "Ernstig",
  "patient_adult_knee_other_symptoms_q7_4": "Extreem",
  "patient_adult_knee_other_symptoms_q7_q1": "Direct na het ontwaken in de ochtend?",
  "patient_adult_knee_other_symptoms_q7_q1_a1": "Geen",
  "patient_adult_knee_other_symptoms_q7_q1_a2": "Licht",
  "patient_adult_knee_other_symptoms_q7_q1_a3": "Matig",
  "patient_adult_knee_other_symptoms_q7_q1_a4": "Ernstig",
  "patient_adult_knee_other_symptoms_q7_q1_a5": "Extreem",
  "patient_adult_knee_other_symptoms_q7_q2": "Na het zitten, liggen of rusten later op de dag?",
  "patient_adult_knee_other_symptoms_q7_q2_a1": "Geen",
  "patient_adult_knee_other_symptoms_q7_q2_a2": "Licht",
  "patient_adult_knee_other_symptoms_q7_q2_a3": "Matig",
  "patient_adult_knee_other_symptoms_q7_q2_a4": "Ernstig",
  "patient_adult_knee_other_symptoms_q7_q2_a5": "Extreem",
  "patient_adult_knee_other_symptoms_q8": "Wat is het hoogste activiteitsniveau waarop u kunt functioneren zonder significant knikken van uw knie?",
  "patient_adult_knee_other_symptoms_q8_a1": "Geen knikken",
  "patient_adult_knee_other_symptoms_q8_a2": "Af en toe knikken bij inspannende sporten of zwaar werk, kan deelnemen aan alle sporten maar enige bescherming of beperkingen aanwezig. Zelden tijdens atletiek of andere zware inspanning",
  "patient_adult_knee_other_symptoms_q8_a3": "Vaak tijdens atletiek of andere zware inspanning. Af en toe knikken bij lichte sporten of matig werk. In staat om krachtige activiteiten, sporten of zwaar werk te compenseren, niet in staat om plotseling te draaien of te draaien",
  "patient_adult_knee_other_symptoms_q8_a4": "Knikken beperkt sporten of matig werk, komt zelden voor bij wandelen of licht werk (ongeveer 3 keer per jaar). Soms in dagelijkse activiteiten",
  "patient_adult_knee_other_symptoms_q8_a5": "Knikken bij eenvoudige loopactiviteiten en licht werk. Komt eens per maand voor, vereist bescherming. Vaak in dagelijkse activiteiten",
  "patient_adult_knee_other_symptoms_q8_a6": "Ernstig probleem bij eenvoudige loopactiviteiten, knikken bij elke stap, kan niet draaien of draaien tijdens het lopen",
  "patient_adult_knee_other_symptoms_q9": "Gebruikt u enige ONDERSTEUNING bij het lopen?",
  "patient_adult_knee_other_symptoms_q9_a1": "Geen",
  "patient_adult_knee_other_symptoms_q9_a2": "Stok of kruk",
  "patient_adult_knee_other_symptoms_q9_a3": "Niet in staat om gewicht te dragen",
  "patient_adult_knee_function": "Functie",
  "patient_adult_knee_function_instruction": "Geef de MATE VAN MOEILIJKHEID aan die u de afgelopen week heeft ervaren bij de volgende activiteiten als gevolg van uw knie",
  "patient_adult_knee_function_instruction_0": "Geen moeilijkheden",
  "patient_adult_knee_function_instruction_1": "Minimale moeilijkheden",
  "patient_adult_knee_function_instruction_2": "Matige moeilijkheden",
  "patient_adult_knee_function_instruction_3": "Ernstige moeilijkheden",
  "patient_adult_knee_function_instruction_4": "Niet in staat om te doen",
  "patient_adult_knee_function_note": "Opmerking: Als u een bepaalde activiteit niet doet, stel u dan voor hoe uw knie zou aanvoelen als u het zou moeten proberen.",
  "patient_adult_knee_function_q1": "Trappen oplopen",
  "patient_adult_knee_function_q1_a1": "Geen moeilijkheden",
  "patient_adult_knee_function_q1_a2": "Minimale moeilijkheden",
  "patient_adult_knee_function_q1_a3": "Matige moeilijkheden",
  "patient_adult_knee_function_q1_a4": "Ernstige moeilijkheden",
  "patient_adult_knee_function_q1_a5": "Niet in staat om te doen",
  "patient_adult_knee_function_q2": "Trappen aflopen",
  "patient_adult_knee_function_q2_a1": "Geen moeilijkheden",
  "patient_adult_knee_function_q2_a2": "Minimale moeilijkheden",
  "patient_adult_knee_function_q2_a3": "Matige moeilijkheden",
  "patient_adult_knee_function_q2_a4": "Ernstige moeilijkheden",
  "patient_adult_knee_function_q2_a5": "Niet in staat om te doen",
  "patient_adult_knee_function_q3": "Knielen op de voorkant van uw knie",
  "patient_adult_knee_function_q3_a1": "Geen moeilijkheden",
  "patient_adult_knee_function_q3_a2": "Minimale moeilijkheden",
  "patient_adult_knee_function_q3_a3": "Matige moeilijkheden",
  "patient_adult_knee_function_q3_a4": "Ernstige moeilijkheden",
  "patient_adult_knee_function_q3_a5": "Niet in staat om te doen",
  "patient_adult_knee_function_q4": "Uw knie volledig strekken",
  "patient_adult_knee_function_q4_a1": "Geen moeilijkheden",
  "patient_adult_knee_function_q4_a2": "Minimale moeilijkheden",
  "patient_adult_knee_function_q4_a3": "Matige moeilijkheden",
  "patient_adult_knee_function_q4_a4": "Ernstige moeilijkheden",
  "patient_adult_knee_function_q4_a5": "Niet in staat om te doen",
  "patient_adult_knee_function_q5": "Uw knie buigen",
  "patient_adult_knee_function_q5_a1": "Geen moeilijkheden",
  "patient_adult_knee_function_q5_a2": "Minimale moeilijkheden",
  "patient_adult_knee_function_q5_a3": "Matige moeilijkheden",
  "patient_adult_knee_function_q5_a4": "Ernstige moeilijkheden",
  "patient_adult_knee_function_q5_a5": "Niet in staat om te doen",
  "patient_adult_knee_function_q6": "Hurken",
  "patient_adult_knee_function_q6_a1": "Geen moeilijkheden",
  "patient_adult_knee_function_q6_a2": "Minimale moeilijkheden",
  "patient_adult_knee_function_q6_a3": "Matige moeilijkheden",
  "patient_adult_knee_function_q6_a4": "Ernstige moeilijkheden",
  "patient_adult_knee_function_q6_a5": "Niet in staat om te doen",
  "patient_adult_knee_function_q7": "Zitten met uw knie gebogen",
  "patient_adult_knee_function_q7_a1": "Geen moeilijkheden",
  "patient_adult_knee_function_q7_a2": "Minimale moeilijkheden",
  "patient_adult_knee_function_q7_a3": "Matige moeilijkheden",
  "patient_adult_knee_function_q7_a4": "Ernstige moeilijkheden",
  "patient_adult_knee_function_q7_a5": "Niet in staat om te doen",
  "patient_adult_knee_function_q8": "Opstaan uit een stoel",
  "patient_adult_knee_function_q8_a1": "Geen moeilijkheden",
  "patient_adult_knee_function_q8_a2": "Minimale moeilijkheden",
  "patient_adult_knee_function_q8_a3": "Matige moeilijkheden",
  "patient_adult_knee_function_q8_a4": "Ernstige moeilijkheden",
  "patient_adult_knee_function_q8_a5": "Niet in staat om te doen",
  "patient_adult_knee_function_q9": "Opstaan uit bed",
  "patient_adult_knee_function_q9_a1": "Geen moeilijkheden",
  "patient_adult_knee_function_q9_a2": "Minimale moeilijkheden",
  "patient_adult_knee_function_q9_a3": "Matige moeilijkheden",
  "patient_adult_knee_function_q9_a4": "Ernstige moeilijkheden",
  "patient_adult_knee_function_q9_a5": "Niet in staat om te doen",
  "patient_adult_knee_function_q10": "Liggen in bed (omdraaien, handhaven van de positie van de knie)",
  "patient_adult_knee_function_q10_a1": "Geen moeilijkheden",
  "patient_adult_knee_function_q10_a2": "Minimale moeilijkheden",
  "patient_adult_knee_function_q10_a3": "Matige moeilijkheden",
  "patient_adult_knee_function_q10_a4": "Ernstige moeilijkheden",
  "patient_adult_knee_function_q10_a5": "Niet in staat om te doen",
  "patient_adult_knee_function_q11": "Buigen om iets van de vloer op te rapen",
  "patient_adult_knee_function_q11_a1": "Geen moeite",
  "patient_adult_knee_function_q11_a2": "Minimale moeite",
  "patient_adult_knee_function_q11_a3": "Matige moeite",
  "patient_adult_knee_function_q11_a4": "Extreme moeite",
  "patient_adult_knee_function_q11_a5": "Niet in staat om te doen",
  "patient_adult_knee_function_q12": "Sokken/kousen aantrekken",
  "patient_adult_knee_function_q12_a1": "Geen moeite",
  "patient_adult_knee_function_q12_a2": "Minimale moeite",
  "patient_adult_knee_function_q12_a3": "Matige moeite",
  "patient_adult_knee_function_q12_a4": "Extreme moeite",
  "patient_adult_knee_function_q12_a5": "Niet in staat om te doen",
  "patient_adult_knee_function_q13": "Sokken/kousen uittrekken",
  "patient_adult_knee_function_q13_a1": "Geen moeite",
  "patient_adult_knee_function_q13_a2": "Minimale moeite",
  "patient_adult_knee_function_q13_a3": "Matige moeite",
  "patient_adult_knee_function_q13_a4": "Extreme moeite",
  "patient_adult_knee_function_q13_a5": "Niet in staat om te doen",
  "patient_adult_knee_function_q14": "Staan",
  "patient_adult_knee_function_q14_a1": "Geen moeite",
  "patient_adult_knee_function_q14_a2": "Minimale moeite",
  "patient_adult_knee_function_q14_a3": "Matige moeite",
  "patient_adult_knee_function_q14_a4": "Extreme moeite",
  "patient_adult_knee_function_q14_a5": "Niet in staat om te doen",
  "patient_adult_knee_function_q15": "Lopen",
  "patient_adult_knee_function_q15_a1": "Geen moeite",
  "patient_adult_knee_function_q15_a2": "Minimale moeite",
  "patient_adult_knee_function_q15_a3": "Matige moeite",
  "patient_adult_knee_function_q15_a4": "Extreme moeite",
  "patient_adult_knee_function_q15_a5": "Niet in staat om te doen",
  "patient_adult_knee_function_q16": "Recht vooruit rennen",
  "patient_adult_knee_function_q16_a1": "Geen moeite",
  "patient_adult_knee_function_q16_a2": "Minimale moeite",
  "patient_adult_knee_function_q16_a3": "Matige moeite",
  "patient_adult_knee_function_q16_a4": "Extreme moeite",
  "patient_adult_knee_function_q16_a5": "Niet in staat om te doen",
  "patient_adult_knee_function_q17": "Springen en landen op uw aangedane been",
  "patient_adult_knee_function_q17_a1": "Geen moeite",
  "patient_adult_knee_function_q17_a2": "Minimale moeite",
  "patient_adult_knee_function_q17_a3": "Matige moeite",
  "patient_adult_knee_function_q17_a4": "Extreme moeite",
  "patient_adult_knee_function_q17_a5": "Niet in staat om te doen",
  "patient_adult_knee_function_q18": "Snel stoppen en starten",
  "patient_adult_knee_function_q18_a1": "Geen moeite",
  "patient_adult_knee_function_q18_a2": "Minimale moeite",
  "patient_adult_knee_function_q18_a3": "Matige moeite",
  "patient_adult_knee_function_q18_a4": "Extreme moeite",
  "patient_adult_knee_function_q18_a5": "Niet in staat om te doen",
  "patient_adult_knee_function_q19": "Draaien/pijleren op uw geblesseerde knie",
  "patient_adult_knee_function_q19_a1": "Geen moeite",
  "patient_adult_knee_function_q19_a2": "Minimale moeite",
  "patient_adult_knee_function_q19_a3": "Matige moeite",
  "patient_adult_knee_function_q19_a4": "Extreme moeite",
  "patient_adult_knee_function_q19_a5": "Niet in staat om te doen",
  "patient_adult_knee_function_q20": "In en uit de auto stappen of het openbaar vervoer gebruiken",
  "patient_adult_knee_function_q20_a1": "Geen moeite",
  "patient_adult_knee_function_q20_a2": "Minimale moeite",
  "patient_adult_knee_function_q20_a3": "Matige moeite",
  "patient_adult_knee_function_q20_a4": "Extreme moeite",
  "patient_adult_knee_function_q20_a5": "Niet in staat om te doen",
  "patient_adult_knee_function_q21": "In en uit bad stappen",
  "patient_adult_knee_function_q21_a1": "Geen moeite",
  "patient_adult_knee_function_q21_a2": "Minimale moeite",
  "patient_adult_knee_function_q21_a3": "Matige moeite",
  "patient_adult_knee_function_q21_a4": "Extreme moeite",
  "patient_adult_knee_function_q21_a5": "Niet in staat om te doen",
  "patient_adult_knee_function_q22": "Op en van het toilet stappen",
  "patient_adult_knee_function_q22_a1": "Geen moeite",
  "patient_adult_knee_function_q22_a2": "Minimale moeite",
  "patient_adult_knee_function_q22_a3": "Matige moeite",
  "patient_adult_knee_function_q22_a4": "Extreme moeite",
  "patient_adult_knee_function_q22_a5": "Niet in staat om te doen",
  "patient_adult_knee_function_q23": "Uzelf wassen en afdrogen",
  "patient_adult_knee_function_q23_a1": "Geen moeite",
  "patient_adult_knee_function_q23_a2": "Minimale moeite",
  "patient_adult_knee_function_q23_a3": "Matige moeite",
  "patient_adult_knee_function_q23_a4": "Extreme moeite",
  "patient_adult_knee_function_q23_a5": "Niet in staat om te doen",
  "patient_adult_knee_function_q24": "Boodschappen doen",
  "patient_adult_knee_function_q24_a1": "Geen moeite",
  "patient_adult_knee_function_q24_a2": "Minimale moeite",
  "patient_adult_knee_function_q24_a3": "Matige moeite",
  "patient_adult_knee_function_q24_a4": "Extreme moeite",
  "patient_adult_knee_function_q24_a5": "Niet in staat om te doen",
  "patient_adult_knee_function_q25": "Zware huishoudelijke taken (zware dozen verplaatsen, vloeren schrobben, etc.)",
  "patient_adult_knee_function_q25_a1": "Geen moeite",
  "patient_adult_knee_function_q25_a2": "Minimale moeite",
  "patient_adult_knee_function_q25_a3": "Matige moeite",
  "patient_adult_knee_function_q25_a4": "Extreme moeite",
  "patient_adult_knee_function_q25_a5": "Niet in staat om te doen",
  "patient_adult_knee_function_q26": "Lichte huishoudelijke taken (koken, stoffen, etc.)",
  "patient_adult_knee_function_q26_a1": "Geen moeite",
  "patient_adult_knee_function_q26_a2": "Minimale moeite",
  "patient_adult_knee_function_q26_a3": "Matige moeite",
  "patient_adult_knee_function_q26_a4": "Extreme moeite",
  "patient_adult_knee_function_q26_a5": "Niet in staat om te doen",
  "patient_adult_knee_function_q27": "Hoe zou u de HUIDIGE functie van uw knie beoordelen op een schaal van 0-10?",
  "patient_adult_knee_function_q28": "10 betekent normaal en 0 betekent het onvermogen om een van uw gebruikelijke dagelijkse activiteiten, inclusief sport, uit te voeren.",
  "patient_adult_knee_function_q28_a1": "Slechtst",
  "patient_adult_knee_function_q28_a2": "Beste",
  "patient_adult_knee_function_q29": "Wat is het hoogste activiteitsniveau waaraan u regelmatig kunt deelnemen?",
  "patient_adult_knee_function_q29_a1": "Zeer inspannende activiteiten zoals springen of draaien zoals bij basketbal of voetbal",
  "patient_adult_knee_function_q29_a2": "Inspannende activiteiten zoals zwaar fysiek werk, skiën of tennis",
  "patient_adult_knee_function_q29_a3": "Matige activiteiten zoals matig fysiek werk, rennen of joggen",
  "patient_adult_knee_function_q29_a4": "Lichte activiteiten zoals wandelen, huishoudelijk werk of tuinwerk",
  "patient_adult_knee_function_q29_a5": "Niet in staat om een van de bovenstaande activiteiten uit te voeren vanwege uw knie",
  "patient_adult_knee_function_q30": "Specificeer het hoogste activiteitsniveau waaraan u kunt deelnemen",
  "patient_adult_knee_function_q30_q1": "Vóór uw knieblessure/knieprobleem",
  "patient_adult_knee_function_q30_q1_a1": "Competitief",
  "patient_adult_knee_function_q30_q1_a2": "Recreatief, lichamelijke fitheid",
  "patient_adult_knee_function_q30_q1_a3": "Geen sport, alleen werk",
  "patient_adult_knee_function_q30_q1_a4": "Alleen activiteiten van het dagelijks leven",
  "patient_adult_knee_function_q30_q1_a5": "Ziekteverlof of arbeidsongeschiktheid vanwege gezondheidsproblemen",
  "patient_adult_knee_function_q30_q1_a1_o1": "Competitiesport op wereld- of olympisch niveau of professioneel",
  "patient_adult_knee_function_q30_q1_a1_o2": "Competitiesporten zoals atletiek, racket-/balsporten, gymnastiek, roeien, skiën of vechtsporten op nationaal of internationaal niveau",
  "patient_adult_knee_function_q30_q1_a1_o3": "Competitiesporten zoals watersporten, fietsen, hockey, curling of paardrijden/rodeo op nationaal of internationaal niveau",
  "patient_adult_knee_function_q30_q1_a2_o1": "Recreatiesporten zoals hardlopen, balsporten/racketsporten, krachttraining, curling, roeien, golf, yoga of gymnastiek minstens 5 keer per week",
  "patient_adult_knee_function_q30_q1_a2_o2": "Recreatiesporten zoals zwemmen, watersporten, skiën, hockey, skeeleren, fietsen, paardrijden of bergbeklimmen minstens 5 keer per week",
  "patient_adult_knee_function_q30_q1_a2_o3": "Recreatiesporten minstens twee keer per week",
  "patient_adult_knee_function_q30_q1_a2_o4": "Recreatiesporten één keer per week of minder",
  "patient_adult_knee_function_q30_q1_a2_o5": "Recreatiesporten minder dan eenmaal per maand",
  "patient_adult_knee_function_q30_q1_a3_o1": "Zwaar werk zoals bouw",
  "patient_adult_knee_function_q30_q1_a3_o2": "Matig zwaar werk zoals vrachtw agenchauffeur",
  "patient_adult_knee_function_q30_q1_a3_o3": "Licht werk zoals verpleging",
  "patient_adult_knee_function_q30_q1_a3_o4": "Sedentair werk zoals secretaresse- of computerwerk",
  "patient_adult_knee_function_q30_q1_a4_o1": "Dagelijkse activiteiten zoals tuinieren, meerdere trappen beklimmen, ladingen dragen, duwen/trekken van een lading, rennen als je laat bent",
  "patient_adult_knee_function_q30_q1_a4_o2": "Dagelijkse activiteiten zoals huishouden, 1 of 2 trappen beklimmen, lopen met een lichte last",
  "patient_adult_knee_function_q30_q1_a4_o3": "Beperkte dagelijkse activiteiten (bijv. geen trappen nemen, niet in staat om ladingen te dragen)",
  "patient_adult_knee_function_q30_q2": "Huidige status",
  "patient_adult_knee_function_q30_q2_a1": "Competitief",
  "patient_adult_knee_function_q30_q2_a2": "Recreatief, lichamelijke fitheid",
  "patient_adult_knee_function_q30_q2_a3": "Geen sport, alleen werk",
  "patient_adult_knee_function_q30_q2_a4": "Alleen activiteiten van het dagelijks leven",
  "patient_adult_knee_function_q30_q2_a5": "Ziekteverlof of arbeidsongeschiktheid vanwege gezondheidsproblemen",
  "patient_adult_knee_function_q30_q2_a1_o1": "Competitiesport op wereld- of olympisch niveau of professioneel",
  "patient_adult_knee_function_q30_q2_a1_o2": "Competitiesporten zoals atletiek, racket-/balsporten, gymnastiek, roeien, skiën of vechtsporten op nationaal of internationaal niveau",
  "patient_adult_knee_function_q30_q2_a1_o3": "Competitiesporten zoals watersporten, fietsen, hockey, curling of paardrijden/rodeo op nationaal of internationaal niveau",
  "patient_adult_knee_function_q30_q2_a2_o1": "Recreatiesporten zoals hardlopen, balsporten/racketsporten, krachttraining, curling, roeien, golf, yoga of gymnastiek minstens 5 keer per week",
  "patient_adult_knee_function_q30_q2_a2_o2": "Recreatiesporten zoals zwemmen, watersporten, skiën, hockey, skeeleren, fietsen, paardrijden of bergbeklimmen minstens 5 keer per week",
  "patient_adult_knee_function_q30_q2_a2_o3": "Recreatiesporten minstens twee keer per week",
  "patient_adult_knee_function_q30_q2_a2_o4": "Recreatiesporten één keer per week of minder",
  "patient_adult_knee_function_q30_q2_a2_o5": "Recreatiesporten minder dan eenmaal per maand",
  "patient_adult_knee_function_q30_q2_a3_o1": "Zwaar werk zoals bouw",
  "patient_adult_knee_function_q30_q2_a3_o2": "Matig zwaar werk zoals vrachtwagenchauffeur",
  "patient_adult_knee_function_q30_q2_a3_o3": "Licht werk zoals verpleging",
  "patient_adult_knee_function_q30_q2_a3_o4": "Sedentair werk zoals secretaresse- of computerwerk",
  "patient_adult_knee_function_q30_q2_a4_o1": "Dagelijkse activiteiten zoals tuinieren, meerdere trappen beklimmen, ladingen dragen, duwen/trekken van een lading, rennen als je laat bent",
  "patient_adult_knee_function_q30_q2_a4_o2": "Dagelijkse activiteiten zoals huishouden, 1 of 2 trappen beklimmen, lopen met een lichte last",
  "patient_adult_knee_function_q30_q2_a4_o3": "Beperkte dagelijkse activiteiten (bijv. geen trappen nemen, niet in staat om ladingen te dragen)",
  "patient_adult_knee_function_q31": "Kan uw knieschijf uit de plaats raken of voelt het alsof deze uit de plaats raakt?",
  "patient_adult_knee_function_q31_a1": "Nee",
  "patient_adult_knee_function_q31_a2": "Ja",
  "patient_adult_knee_patellofemoral_instability": "Patellofemorale instabiliteit",
  "patient_adult_knee_patellofemoral_instability_instruction": "Kies wat het beste beschrijft HOE VAAK UW KNIESCHIJF HET GEVOEL HEEFT DAT HET \"UIT DE GEWRICHT\" ZAL SPRINGEN OF ONSTABIEL AANVOELT wanneer u elk van de volgende activiteiten uitvoert",
  "patient_adult_knee_patellofemoral_instability_instruction_0": "Nooit",
  "patient_adult_knee_patellofemoral_instability_instruction_1": "Zelden",
  "patient_adult_knee_patellofemoral_instability_instruction_2": "Soms",
  "patient_adult_knee_patellofemoral_instability_instruction_3": "Vaak",
  "patient_adult_knee_patellofemoral_instability_instruction_4": "Altijd",
  "patient_adult_knee_patellofemoral_instability_q1": "Tijdens sport-/spelactiviteiten draaien/richting veranderen",
  "patient_adult_knee_patellofemoral_instability_q1_do_not_do": "Niet van toepassing",
  "patient_adult_knee_patellofemoral_instability_q1_a1": "Nooit",
  "patient_adult_knee_patellofemoral_instability_q1_a2": "Zelden",
  "patient_adult_knee_patellofemoral_instability_q1_a3": "Soms",
  "patient_adult_knee_patellofemoral_instability_q1_a4": "Vaak",
  "patient_adult_knee_patellofemoral_instability_q1_a5": "Altijd",
  "patient_adult_knee_patellofemoral_instability_q2": "Richting veranderen bij het rennen",
  "patient_adult_knee_patellofemoral_instability_q2_do_not_do": "Niet van toepassing",
  "patient_adult_knee_patellofemoral_instability_q2_a1": "Nooit",
  "patient_adult_knee_patellofemoral_instability_q2_a2": "Zelden",
  "patient_adult_knee_patellofemoral_instability_q2_a3": "Soms",
  "patient_adult_knee_patellofemoral_instability_q2_a4": "Vaak",
  "patient_adult_knee_patellofemoral_instability_q2_a5": "Altijd",
  "patient_adult_knee_patellofemoral_instability_q3": "Recht rennen op ONEVEN oppervlakken",
  "patient_adult_knee_patellofemoral_instability_q3_do_not_do": "Niet van toepassing",
  "patient_adult_knee_patellofemoral_instability_q3_a1": "Nooit",
  "patient_adult_knee_patellofemoral_instability_q3_a2": "Zelden",
  "patient_adult_knee_patellofemoral_instability_q3_a3": "Soms",
  "patient_adult_knee_patellofemoral_instability_q3_a4": "Vaak",
  "patient_adult_knee_patellofemoral_instability_q3_a5": "Altijd",
  "patient_adult_knee_patellofemoral_instability_q4": "Lopen op gladde, natte of ijzige oppervlakken",
  "patient_adult_knee_patellofemoral_instability_q4_do_not_do": "Niet van toepassing",
  "patient_adult_knee_patellofemoral_instability_q4_a1": "Nooit",
  "patient_adult_knee_patellofemoral_instability_q4_a2": "Zelden",
  "patient_adult_knee_patellofemoral_instability_q4_a3": "Soms",
  "patient_adult_knee_patellofemoral_instability_q4_a4": "Vaak",
  "patient_adult_knee_patellofemoral_instability_q4_a5": "Altijd",
  "patient_adult_knee_patellofemoral_instability_q5": "Zijwaarts rennen",
  "patient_adult_knee_patellofemoral_instability_q5_do_not_do": "Niet van toepassing",
  "patient_adult_knee_patellofemoral_instability_q5_a1": "Nooit",
  "patient_adult_knee_patellofemoral_instability_q5_a2": "Zelden",
  "patient_adult_knee_patellofemoral_instability_q5_a3": "Soms",
  "patient_adult_knee_patellofemoral_instability_q5_a4": "Vaak",
  "patient_adult_knee_patellofemoral_instability_q5_a5": "Altijd",
  "patient_adult_knee_patellofemoral_instability_q6": "Hinkelen",
  "patient_adult_knee_patellofemoral_instability_q6_do_not_do": "Niet van toepassing",
  "patient_adult_knee_patellofemoral_instability_q6_a1": "Nooit",
  "patient_adult_knee_patellofemoral_instability_q6_a2": "Zelden",
  "patient_adult_knee_patellofemoral_instability_q6_a3": "Soms",
  "patient_adult_knee_patellofemoral_instability_q6_a4": "Vaak",
  "patient_adult_knee_patellofemoral_instability_q6_a5": "Altijd",
  "patient_adult_knee_patellofemoral_instability_q7": "Springen",
  "patient_adult_knee_patellofemoral_instability_q7_do_not_do": "Niet doen",
  "patient_adult_knee_patellofemoral_instability_q7_a1": "Nooit",
  "patient_adult_knee_patellofemoral_instability_q7_a2": "Zelden",
  "patient_adult_knee_patellofemoral_instability_q7_a3": "Soms",
  "patient_adult_knee_patellofemoral_instability_q7_a4": "Vaak",
  "patient_adult_knee_patellofemoral_instability_q7_a5": "Altijd",
  "patient_adult_knee_patellofemoral_instability_q8": "Rennen in een rechte lijn op VLAKKE ondergrond",
  "patient_adult_knee_patellofemoral_instability_q8_do_not_do": "Niet doen",
  "patient_adult_knee_patellofemoral_instability_q8_a1": "Nooit",
  "patient_adult_knee_patellofemoral_instability_q8_a2": "Zelden",
  "patient_adult_knee_patellofemoral_instability_q8_a3": "Soms",
  "patient_adult_knee_patellofemoral_instability_q8_a4": "Vaak",
  "patient_adult_knee_patellofemoral_instability_q8_a5": "Altijd",
  "patient_adult_knee_patellofemoral_instability_q9": "Traplopen",
  "patient_adult_knee_patellofemoral_instability_q9_do_not_do": "Niet doen",
  "patient_adult_knee_patellofemoral_instability_q9_a1": "Nooit",
  "patient_adult_knee_patellofemoral_instability_q9_a2": "Zelden",
  "patient_adult_knee_patellofemoral_instability_q9_a3": "Soms",
  "patient_adult_knee_patellofemoral_instability_q9_a4": "Vaak",
  "patient_adult_knee_patellofemoral_instability_q9_a5": "Altijd",
  "patient_adult_knee_patellofemoral_instability_q10": "Hurken",
  "patient_adult_knee_patellofemoral_instability_q10_do_not_do": "Niet doen",
  "patient_adult_knee_patellofemoral_instability_q10_a1": "Nooit",
  "patient_adult_knee_patellofemoral_instability_q10_a2": "Zelden",
  "patient_adult_knee_patellofemoral_instability_q10_a3": "Soms",
  "patient_adult_knee_patellofemoral_instability_q10_a4": "Vaak",
  "patient_adult_knee_patellofemoral_instability_q10_a5": "Altijd",
  "patient_adult_knee_patellofemoral_instability_q11": "Knielen",
  "patient_adult_knee_patellofemoral_instability_q11_do_not_do": "Niet doen",
  "patient_adult_knee_patellofemoral_instability_q11_a1": "Nooit",
  "patient_adult_knee_patellofemoral_instability_q11_a2": "Zelden",
  "patient_adult_knee_patellofemoral_instability_q11_a3": "Soms",
  "patient_adult_knee_patellofemoral_instability_q11_a4": "Vaak",
  "patient_adult_knee_patellofemoral_instability_q11_a5": "Altijd",
  "patient_adult_knee_patellofemoral_instability_q12": "Lopen in een rechte lijn op ONEVEN ondergrond",
  "patient_adult_knee_patellofemoral_instability_q12_do_not_do": "Niet doen",
  "patient_adult_knee_patellofemoral_instability_q12_a1": "Nooit",
  "patient_adult_knee_patellofemoral_instability_q12_a2": "Zelden",
  "patient_adult_knee_patellofemoral_instability_q12_a3": "Soms",
  "patient_adult_knee_patellofemoral_instability_q12_a4": "Vaak",
  "patient_adult_knee_patellofemoral_instability_q12_a5": "Altijd",
  "patient_adult_knee_patellofemoral_instability_q13": "Traplopen",
  "patient_adult_knee_patellofemoral_instability_q13_do_not_do": "Niet doen",
  "patient_adult_knee_patellofemoral_instability_q13_a1": "Nooit",
  "patient_adult_knee_patellofemoral_instability_q13_a2": "Zelden",
  "patient_adult_knee_patellofemoral_instability_q13_a3": "Soms",
  "patient_adult_knee_patellofemoral_instability_q13_a4": "Vaak",
  "patient_adult_knee_patellofemoral_instability_q13_a5": "Altijd",
  "patient_adult_knee_patellofemoral_instability_q14": "Opstappen op of over een hoge drempel",
  "patient_adult_knee_patellofemoral_instability_q14_do_not_do": "Niet doen",
  "patient_adult_knee_patellofemoral_instability_q14_a1": "Nooit",
  "patient_adult_knee_patellofemoral_instability_q14_a2": "Zelden",
  "patient_adult_knee_patellofemoral_instability_q14_a3": "Soms",
  "patient_adult_knee_patellofemoral_instability_q14_a4": "Vaak",
  "patient_adult_knee_patellofemoral_instability_q14_a5": "Altijd",
  "patient_adult_knee_patellofemoral_instability_q15": "Je benen kruisen tijdens het zitten",
  "patient_adult_knee_patellofemoral_instability_q15_do_not_do": "Niet doen",
  "patient_adult_knee_patellofemoral_instability_q15_a1": "Nooit",
  "patient_adult_knee_patellofemoral_instability_q15_a2": "Zelden",
  "patient_adult_knee_patellofemoral_instability_q15_a3": "Soms",
  "patient_adult_knee_patellofemoral_instability_q15_a4": "Vaak",
  "patient_adult_knee_patellofemoral_instability_q15_a5": "Altijd",
  "patient_adult_knee_patellofemoral_instability_q16": "Lopen in een rechte lijn op VLAKKE ondergrond",
  "patient_adult_knee_patellofemoral_instability_q16_do_not_do": "Niet doen",
  "patient_adult_knee_patellofemoral_instability_q16_a1": "Nooit",
  "patient_adult_knee_patellofemoral_instability_q16_a2": "Zelden",
  "patient_adult_knee_patellofemoral_instability_q16_a3": "Soms",
  "patient_adult_knee_patellofemoral_instability_q16_a4": "Vaak",
  "patient_adult_knee_patellofemoral_instability_q16_a5": "Altijd",
  "patient_adult_knee_patellofemoral_instability_q17": "In- of uitstappen uit een auto",
  "patient_adult_knee_patellofemoral_instability_q17_do_not_do": "Niet doen",
  "patient_adult_knee_patellofemoral_instability_q17_a1": "Nooit",
  "patient_adult_knee_patellofemoral_instability_q17_a2": "Zelden",
  "patient_adult_knee_patellofemoral_instability_q17_a3": "Soms",
  "patient_adult_knee_patellofemoral_instability_q17_a4": "Vaak",
  "patient_adult_knee_patellofemoral_instability_q17_a5": "Altijd",
  "patient_adult_knee_patellofemoral_instability_q18": "Een zware boodschappenkar door een supermarktpad duwen",
  "patient_adult_knee_patellofemoral_instability_q18_do_not_do": "Niet doen",
  "patient_adult_knee_patellofemoral_instability_q18_a1": "Nooit",
  "patient_adult_knee_patellofemoral_instability_q18_a2": "Zelden",
  "patient_adult_knee_patellofemoral_instability_q18_a3": "Soms",
  "patient_adult_knee_patellofemoral_instability_q18_a4": "Vaak",
  "patient_adult_knee_patellofemoral_instability_q18_a5": "Altijd",
  "patient_adult_knee_patellofemoral_instability_q19": "Over je schouder kijken",
  "patient_adult_knee_patellofemoral_instability_q19_do_not_do": "Niet doen",
  "patient_adult_knee_patellofemoral_instability_q19_a1": "Nooit",
  "patient_adult_knee_patellofemoral_instability_q19_a2": "Zelden",
  "patient_adult_knee_patellofemoral_instability_q19_a3": "Soms",
  "patient_adult_knee_patellofemoral_instability_q19_a4": "Vaak",
  "patient_adult_knee_patellofemoral_instability_q19_a5": "Altijd",
  "patient_adult_knee_quality_of_life": "Kwaliteit van leven",
  "patient_adult_knee_quality_of_life_instruction": "De volgende vragen gaan over sociale, emotionele en levensstijlzorgen die u mogelijk ervaart met betrekking tot uw knieprobleem. Denk na over hoe u zich meestal voelt met betrekking tot uw knie.",
  "patient_adult_knee_quality_of_life_q1": "Hoe vaak bent u zich bewust van uw knieprobleem?",
  "patient_adult_knee_quality_of_life_q1_a1": "Nooit",
  "patient_adult_knee_quality_of_life_q1_a2": "Maandelijks",
  "patient_adult_knee_quality_of_life_q1_a3": "Wekelijks",
  "patient_adult_knee_quality_of_life_q1_a4": "Dagelijks",
  "patient_adult_knee_quality_of_life_q1_a5": "Constant",
  "patient_adult_knee_quality_of_life_q2": "Heeft u uw levensstijl aangepast om potentieel schadelijke activiteiten voor uw knie te vermijden?",
  "patient_adult_knee_quality_of_life_q2_a1": "Helemaal niet",
  "patient_adult_knee_quality_of_life_q2_a2": "Licht",
  "patient_adult_knee_quality_of_life_q2_a3": "Matig",
  "patient_adult_knee_quality_of_life_q2_a4": "Ernstig",
  "patient_adult_knee_quality_of_life_q2_a5": "Volledig",
  "patient_adult_knee_quality_of_life_q3": "Hoeveel last heeft u van het gebrek aan vertrouwen in uw knie?",
  "patient_adult_knee_quality_of_life_q3_a1": "Helemaal niet",
  "patient_adult_knee_quality_of_life_q3_a2": "Licht",
  "patient_adult_knee_quality_of_life_q3_a3": "Matig",
  "patient_adult_knee_quality_of_life_q3_a4": "Ernstig",
  "patient_adult_knee_quality_of_life_q3_a5": "Extreem",
  "patient_adult_knee_quality_of_life_q4": "In het algemeen, hoeveel moeite heeft u met uw knie?",
  "patient_adult_knee_quality_of_life_q4_a1": "Geen",
  "patient_adult_knee_quality_of_life_q4_a2": "Licht",
  "patient_adult_knee_quality_of_life_q4_a3": "Matig",
  "patient_adult_knee_quality_of_life_q4_a4": "Ernstig",
  "patient_adult_knee_quality_of_life_q4_a5": "Extreem",
  "patient_adult_knee_quality_of_life_q5": "Hoe angstig/depressief bent u vandaag?",
  "patient_adult_knee_quality_of_life_q5_a1": "Niet angstig/depressief",
  "patient_adult_knee_quality_of_life_q5_a2": "Licht",
  "patient_adult_knee_quality_of_life_q5_a3": "Matig",
  "patient_adult_knee_quality_of_life_q5_a4": "Ernstig",
  "patient_adult_knee_quality_of_life_q5_a5": "Extreem",
  "patient_adult_knee_quality_of_life_q6": "Hoe goed/slecht is uw gezondheid vandaag?",
  "patient_adult_knee_quality_of_life_q6_instruction": "100 betekent de beste gezondheid, 0 betekent de slechtst denkbare gezondheid",
  "patient_adult_knee_quality_of_life_q6_instruction_a1": "Slechtst",
  "patient_adult_knee_quality_of_life_q6_instruction_a2": "Beste",
  "patient_adult_knee_satisfaction": "Tevredenheid",
  "patient_adult_knee_satisfaction_instructions": "Hoe tevreden bent u met de resultaten van uw knieoperatie/behandeling?",
  "patient_adult_knee_satisfaction_instructions_1": "Zeer ontevreden",
  "patient_adult_knee_satisfaction_instructions_2": "Enigszins ontevreden",
  "patient_adult_knee_satisfaction_instructions_3": "Enigszins tevreden",
  "patient_adult_knee_satisfaction_instructions_4": "Zeer tevreden",
  "patient_adult_knee_satisfaction_q1": "Algemeen",
  "patient_adult_knee_satisfaction_q1_not_applicable": "Niet van toepassing",
  "patient_adult_knee_satisfaction_q1_a1": "Zeer ontevreden",
  "patient_adult_knee_satisfaction_q1_a2": "Enigszins ontevreden",
  "patient_adult_knee_satisfaction_q1_a3": "Enigszins tevreden",
  "patient_adult_knee_satisfaction_q1_a4": "Zeer tevreden",
  "patient_adult_knee_satisfaction_q2": "Voor het verbeteren van uw pijn?",
  "patient_adult_knee_satisfaction_q2_not_applicable": "Niet van toepassing",
  "patient_adult_knee_satisfaction_q2_a1": "Zeer ontevreden",
  "patient_adult_knee_satisfaction_q2_a2": "Enigszins ontevreden",
  "patient_adult_knee_satisfaction_q2_a3": "Enigszins tevreden",
  "patient_adult_knee_satisfaction_q2_a4": "Zeer tevreden",
  "patient_adult_knee_satisfaction_q3": "Voor het verbeteren van uw vermogen om thuis of in de tuin te werken?",
  "patient_adult_knee_satisfaction_q3_not_applicable": "Niet van toepassing",
  "patient_adult_knee_satisfaction_q3_a1": "Zeer ontevreden",
  "patient_adult_knee_satisfaction_q3_a2": "Enigszins ontevreden",
  "patient_adult_knee_satisfaction_q3_a3": "Enigszins tevreden",
  "patient_adult_knee_satisfaction_q3_a4": "Zeer tevreden",
  "patient_adult_knee_satisfaction_q4": "Voor het verbeteren van uw vermogen om recreatieve activiteiten te doen?",
  "patient_adult_knee_satisfaction_q4_not_applicable": "Niet van toepassing",
  "patient_adult_knee_satisfaction_q4_a1": "Zeer ontevreden",
  "patient_adult_knee_satisfaction_q4_a2": "Enigszins ontevreden",
  "patient_adult_knee_satisfaction_q4_a3": "Enigszins tevreden",
  "patient_adult_knee_satisfaction_q4_a4": "Zeer tevreden",
  "patient_adult_knee_complete": "Einde van de vragenlijst",
  "patient_adult_knee_complete_text1": "Bedankt voor de tijd die u heeft genomen om dit formulier in te vullen. ",
  "patient_adult_knee_complete_text2": "Klik alstublieft op verzenden om te voltooien en uw scores te bekijken. ",
  "patient_adult_knee_complete_text3": "Uw antwoorden worden vertrouwelijk behandeld en eventuele rapporten zullen alleen geanonimiseerde informatie bevatten.",
  "patient_adult_knee_results": "Resultaten",
  "patient_adult_knee_results_text1": "Hieronder staan uw scores voor pijn en knie functie, die zijn berekend uit uw vragenlijst.",
  "patient_adult_knee_results_pain": "Pijn",
  "patient_adult_knee_results_function": "Functie",
  "patient_adult_knee_results_health_perception": "Algemene gezondheidsperceptie",
  "patient_adult_knee_results_self_evaluation": "Zelfevaluatie van kniescore",
  "patient_adult_knee_results_text2": "Uw arts zal binnenkort bij u zijn.",
  "patient_adolescent_knee_pain": "Pijn",
  "patient_adolescent_knee_function_q23": "Boodschappen doen",
  "patient_adolescent_knee_function_q23_a1": "Geen moeite",
  "patient_adolescent_knee_function_q23_a2": "Minimale moeite",
  "patient_adolescent_knee_function_q23_a3": "Matige moeite",
  "patient_adolescent_knee_function_q23_a4": "Ernstige moeite",
  "patient_adolescent_knee_function_q23_a5": "Niet in staat om te doen",
  "patient_pedia_knee_pain": "Pijn",
  "patient_pedia_knee_pain_q1": "Gedurende de afgelopen 4 weken of sinds uw blessure, hoe vaak deed uw geblesseerde knie pijn?",
  "patient_pedia_knee_pain_q1_a1": "Nooit pijn",
  "patient_pedia_knee_pain_q1_a2": "Zelden",
  "patient_pedia_knee_pain_q1_a3": "Soms",
  "patient_pedia_knee_pain_q1_a4": "Vaak",
  "patient_pedia_knee_pain_q1_a5": "Constant",
  "patient_pedia_knee_pain_q2": "Hoe erg doet uw geblesseerde knie vandaag pijn?",
  "patient_pedia_knee_pain_q2_a1": "Doet helemaal geen pijn",
  "patient_pedia_knee_pain_q2_a2": "Doet zo veel pijn dat ik het niet kan verdragen",
  "patient_pedia_knee_pain_instructions": "Hoeveel kniepijn heeft u in de afgelopen 7 dagen ervaren tijdens de volgende activiteiten?",
  "patient_pedia_knee_pain_instructions_0": "Geen pijn",
  "patient_pedia_knee_pain_instructions_1": "Een beetje pijn",
  "patient_pedia_knee_pain_instructions_2": "Wat pijn",
  "patient_pedia_knee_pain_instructions_3": "Veel pijn",
  "patient_pedia_knee_pain_instructions_4": "Ernstige pijn",
  "patient_pedia_knee_pain_note": "Opmerking: Beantwoord alstublieft de volgende vragen met betrekking tot uw knieactiviteiten.",
  "patient_pedia_knee_pain_q3": "Uw knie verdraaien/draaien bij het lopen/staan/rennen",
  "patient_pedia_knee_pain_q3_a1": "Geen pijn",
  "patient_pedia_knee_pain_q3_a2": "Een beetje pijn",
  "patient_pedia_knee_pain_q3_a3": "Wat pijn",
  "patient_pedia_knee_pain_q3_a4": "Veel pijn",
  "patient_pedia_knee_pain_q3_a5": "Ernstige pijn",
  "patient_pedia_knee_pain_q4": "Uw knie volledig strekken",
  "patient_pedia_knee_pain_q4_a1": "Geen pijn",
  "patient_pedia_knee_pain_q4_a2": "Een beetje pijn",
  "patient_pedia_knee_pain_q4_a3": "Wat pijn",
  "patient_pedia_knee_pain_q4_a4": "Veel pijn",
  "patient_pedia_knee_pain_q4_a5": "Ernstige pijn",
  "patient_pedia_knee_pain_q5": "Uw knie volledig buigen",
  "patient_pedia_knee_pain_q5_a1": "Geen pijn",
  "patient_pedia_knee_pain_q5_a2": "Een beetje pijn",
  "patient_pedia_knee_pain_q5_a3": "Wat pijn",
  "patient_pedia_knee_pain_q5_a4": "Veel pijn",
  "patient_pedia_knee_pain_q5_a5": "Ernstige pijn",
  "patient_pedia_knee_pain_q6": "Trappen oplopen",
  "patient_pedia_knee_pain_q6_a1": "Geen pijn",
  "patient_pedia_knee_pain_q6_a2": "Een beetje pijn",
  "patient_pedia_knee_pain_q6_a3": "Wat pijn",
  "patient_pedia_knee_pain_q6_a4": "Veel pijn",
  "patient_pedia_knee_pain_q6_a5": "Ernstige pijn",
  "patient_pedia_knee_pain_q7": "Trappen aflopen",
  "patient_pedia_knee_pain_q7_a1": "Geen pijn",
  "patient_pedia_knee_pain_q7_a2": "Een beetje pijn",
  "patient_pedia_knee_pain_q7_a3": "Wat pijn",
  "patient_pedia_knee_pain_q7_a4": "Veel pijn",
  "patient_pedia_knee_pain_q7_a5": "Ernstige pijn",
  "patient_pedia_knee_pain_q8": "Zitten met uw knie gebogen",
  "patient_pedia_knee_pain_q8_a1": "Geen pijn",
  "patient_pedia_knee_pain_q8_a2": "Een beetje pijn",
  "patient_pedia_knee_pain_q8_a3": "Wat pijn",
  "patient_pedia_knee_pain_q8_a4": "Veel pijn",
  "patient_pedia_knee_pain_q8_a5": "Ernstige pijn",
  "patient_pedia_knee_pain_q9": "Rechtop staan op beide benen voor elke hoeveelheid tijd",
  "patient_pedia_knee_pain_q9_a1": "Geen pijn",
  "patient_pedia_knee_pain_q9_a2": "Een beetje pijn",
  "patient_pedia_knee_pain_q9_a3": "Wat pijn",
  "patient_pedia_knee_pain_q9_a4": "Veel pijn",
  "patient_pedia_knee_pain_q9_a5": "Ernstige pijn",
  "patient_pedia_knee_other_symptoms": "Andere symptomen",
  "patient_pedia_knee_other_symptoms_instruction1": "Hoe vaak heeft u het volgende gehad:",
  "patient_pedia_knee_other_symptoms_instruction1_0": "Nooit",
  "patient_pedia_knee_other_symptoms_instruction1_1": "Zelden",
  "patient_pedia_knee_other_symptoms_instruction1_2": "Soms",
  "patient_pedia_knee_other_symptoms_instruction1_3": "Vaak",
  "patient_pedia_knee_other_symptoms_instruction1_4": "Altijd",
  "patient_pedia_knee_other_symptoms_q1": "Uw knie maakt geluid/geluiden",
  "patient_pedia_knee_other_symptoms_q1_a1": "Nooit",
  "patient_pedia_knee_other_symptoms_q1_a2": "Zelden",
  "patient_pedia_knee_other_symptoms_q1_a3": "Soms",
  "patient_pedia_knee_other_symptoms_q1_a4": "Vaak",
  "patient_pedia_knee_other_symptoms_q1_a5": "Altijd",
  "patient_pedia_knee_other_symptoms_q2": "Uw knie blijft vastzitten of op slot",
  "patient_pedia_knee_other_symptoms_q2_a1": "Nooit",
  "patient_pedia_knee_other_symptoms_q2_a2": "Zelden",
  "patient_pedia_knee_other_symptoms_q2_a3": "Soms",
  "patient_pedia_knee_other_symptoms_q2_a4": "Vaak",
  "patient_pedia_knee_other_symptoms_q2_a5": "Altijd",
  "patient_pedia_knee_other_symptoms_q3": "Het gevoel hebben dat de knie vastzit (blokkeert), maar u kunt deze nog steeds bewegen",
  "patient_pedia_knee_other_symptoms_q3_a1": "Nooit",
  "patient_pedia_knee_other_symptoms_q3_a2": "Zelden",
  "patient_pedia_knee_other_symptoms_q3_a3": "Soms",
  "patient_pedia_knee_other_symptoms_q3_a4": "Vaak",
  "patient_pedia_knee_other_symptoms_q3_a5": "Altijd",
  "patient_pedia_knee_other_symptoms_q4": "Zwelling/gezwollen knie",
  "patient_pedia_knee_other_symptoms_q4_a1": "Nooit",
  "patient_pedia_knee_other_symptoms_q4_a2": "Zelden",
  "patient_pedia_knee_other_symptoms_q4_a3": "Soms",
  "patient_pedia_knee_other_symptoms_q4_a4": "Vaak",
  "patient_pedia_knee_other_symptoms_q4_a5": "Altijd",
  "patient_pedia_knee_other_symptoms_q5": "Hoe GEZWOLLEN was uw knie?",
  "patient_pedia_knee_other_symptoms_q5_a1": "Helemaal niet gezwollen",
  "patient_pedia_knee_other_symptoms_q5_a2": "Een beetje gezwollen",
  "patient_pedia_knee_other_symptoms_q5_a3": "Enigszins gezwollen",
  "patient_pedia_knee_other_symptoms_q5_a4": "Heel erg gezwollen",
  "patient_pedia_knee_other_symptoms_q5_a5": "Extreem gezwollen",
  "patient_pedia_knee_other_symptoms_instruction2": "Als u zou worden gevraagd om de onderstaande activiteiten uit te voeren, wat is dan het meeste dat u VANDAAG zou kunnen doen...",
  "patient_pedia_knee_other_symptoms_instruction2_0": "Heel moeilijke activiteiten zoals springen of snel draaien om van richting te veranderen zoals bij basketbal of voetbal",
  "patient_pedia_knee_other_symptoms_instruction2_1": "Moeilijke activiteiten zoals zwaar tillen, skiën of tennis",
  "patient_pedia_knee_other_symptoms_instruction2_2": "Soort van moeilijke activiteiten zoals snel lopen of joggen",
  "patient_pedia_knee_other_symptoms_instruction2_3": "Lichte activiteiten zoals normaal wandelen",
  "patient_pedia_knee_other_symptoms_instruction2_4": "Ik kan geen van de bovenstaande activiteiten doen",
  "patient_pedia_knee_other_symptoms_q6": "Zonder dat uw knie VEEL PIJN doet",
  "patient_pedia_knee_other_symptoms_q6_a1": "Heel moeilijke activiteiten",
  "patient_pedia_knee_other_symptoms_q6_a2": "Moeilijke activiteiten",
  "patient_pedia_knee_other_symptoms_q6_a3": "Soort van moeilijke activiteiten",
  "patient_pedia_knee_other_symptoms_q6_a4": "Lichte activiteiten",
  "patient_pedia_knee_other_symptoms_q6_a5": "Ik kan geen van het bovenstaande doen",
  "patient_pedia_knee_other_symptoms_q7": "Zonder dat uw knie GEZWOLLEN is",
  "patient_pedia_knee_other_symptoms_q7_a1": "Heel moeilijke activiteiten",
  "patient_pedia_knee_other_symptoms_q7_a2": "Moeilijke activiteiten",
  "patient_pedia_knee_other_symptoms_q7_a3": "Soort van moeilijke activiteiten",
  "patient_pedia_knee_other_symptoms_q7_a4": "Lichte activiteiten",
  "patient_pedia_knee_other_symptoms_q7_a5": "Ik kan geen van het bovenstaande doen",
  "patient_pedia_knee_other_symptoms_q8": "Zonder dat uw knie het GEVOEL HEEFT DAT ZIJ U NIET KAN DRAGEN",
  "patient_pedia_knee_other_symptoms_q8_a1": "Heel moeilijke activiteiten",
  "patient_pedia_knee_other_symptoms_q8_a2": "Moeilijke activiteiten",
  "patient_pedia_knee_other_symptoms_q8_a3": "Soort van moeilijke activiteiten",
  "patient_pedia_knee_other_symptoms_q8_a5": "Ik kan geen van het bovenstaande doen",
  "patient_pedia_knee_other_symptoms_q9": "Meestal",
  "patient_pedia_knee_other_symptoms_q9_a1": "Heel moeilijke activiteiten",
  "patient_pedia_knee_other_symptoms_q9_a2": "Moeilijke activiteiten",
  "patient_pedia_knee_other_symptoms_q9_a3": "Soort van moeilijke activiteiten",
  "patient_pedia_knee_other_symptoms_q9_a4": "Lichte activiteiten",
  "patient_pedia_knee_other_symptoms_q9_a5": "Ik kan geen van het bovenstaande doen",
  "patient_pedia_knee_function": "Functie",
  "patient_pedia_knee_function_instruction": "Hoeveel moeite heeft u gehad met het doen van de volgende activiteiten vanwege uw knie:",
  "patient_pedia_knee_function_instruction_0": "Geen moeite",
  "patient_pedia_knee_function_instruction_1": "Een beetje",
  "patient_pedia_knee_function_instruction_2": "Sommige",
  "patient_pedia_knee_function_instruction_3": "Veel",
  "patient_pedia_knee_function_instruction_4": "Ernstige moeilijkheid/kan niet doen",
  "patient_pedia_knee_function_note": "Opmerking: Als u een bepaalde activiteit niet doet, stel u dan voor hoe uw knie zou aanvoelen als u het zou moeten proberen.",
  "patient_pedia_knee_function_q1": "Trappen oplopen",
  "patient_pedia_knee_function_q1_a1": "Geen moeite",
  "patient_pedia_knee_function_q1_a2": "Een beetje",
  "patient_pedia_knee_function_q1_a3": "Sommige",
  "patient_pedia_knee_function_q1_a4": "Veel",
  "patient_pedia_knee_function_q1_a5": "Ernstige moeilijkheid/kan niet doen",
  "patient_pedia_knee_function_q2": "Trappen aflopen",
  "patient_pedia_knee_function_q2_a1": "Geen moeite",
  "patient_pedia_knee_function_q2_a2": "Een beetje",
  "patient_pedia_knee_function_q2_a3": "Sommige",
  "patient_pedia_knee_function_q2_a4": "Veel",
  "patient_pedia_knee_function_q2_a5": "Ernstige moeilijkheid/kan niet doen",
  "patient_pedia_knee_function_q3": "Op de voorkant van uw knie knielen",
  "patient_pedia_knee_function_q3_a1": "Geen moeite",
  "patient_pedia_knee_function_q3_a2": "Een beetje",
  "patient_pedia_knee_function_q3_a3": "Sommige",
  "patient_pedia_knee_function_q3_a4": "Veel",
  "patient_pedia_knee_function_q3_a5": "Ernstige moeilijkheid/kan niet doen",
  "patient_pedia_knee_function_q4": "Uw knie volledig strekken",
  "patient_pedia_knee_function_q4_a1": "Geen moeite",
  "patient_pedia_knee_function_q4_a2": "Een beetje",
  "patient_pedia_knee_function_q4_a3": "Sommige",
  "patient_pedia_knee_function_q4_a4": "Veel",
  "patient_pedia_knee_function_q4_a5": "Ernstige moeilijkheid/kan niet doen",
  "patient_pedia_knee_function_q5": "Uw knie volledig buigen",
  "patient_pedia_knee_function_q5_a1": "Geen moeite",
  "patient_pedia_knee_function_q5_a2": "Een beetje",
  "patient_pedia_knee_function_q5_a3": "Sommige",
  "patient_pedia_knee_function_q5_a4": "Veel",
  "patient_pedia_knee_function_q5_a5": "Ernstige moeilijkheid/kan niet doen",
  "patient_pedia_knee_function_q6": "Uw knie meteen na het wakker worden 's morgens bewegen",
  "patient_pedia_knee_function_q6_a1": "Geen moeite",
  "patient_pedia_knee_function_q6_a2": "Een beetje",
  "patient_pedia_knee_function_q6_a3": "Sommige",
  "patient_pedia_knee_function_q6_a4": "Veel",
  "patient_pedia_knee_function_q6_a5": "Ernstige moeilijkheid/kan niet doen",
  "patient_pedia_knee_function_q7": "Uw knie later op de dag bewegen na lange tijd te hebben gezeten",
  "patient_pedia_knee_function_q7_a1": "Geen moeite",
  "patient_pedia_knee_function_q7_a2": "Een beetje",
  "patient_pedia_knee_function_q7_a3": "Sommige",
  "patient_pedia_knee_function_q7_a4": "Veel",
  "patient_pedia_knee_function_q7_a5": "Ernstige moeilijkheid/kan niet doen",
  "patient_pedia_knee_function_q8": "Neerzitten zoals een honkbalvanger",
  "patient_pedia_knee_function_q8_a1": "Geen moeite",
  "patient_pedia_knee_function_q8_a2": "Een beetje",
  "patient_pedia_knee_function_q8_a3": "Sommige",
  "patient_pedia_knee_function_q8_a4": "Veel",
  "patient_pedia_knee_function_q8_a5": "Ernstige moeilijkheid/kan niet doen",
  "patient_pedia_knee_function_q9": "Op een stoel zitten met uw knie gebogen en uw voeten plat op de vloer",
  "patient_pedia_knee_function_q9_a1": "Geen moeite",
  "patient_pedia_knee_function_q9_a2": "Een beetje",
  "patient_pedia_knee_function_q9_a3": "Sommige",
  "patient_pedia_knee_function_q9_a4": "Veel",
  "patient_pedia_knee_function_q9_a5": "Ernstige moeilijkheid/kan niet doen",
  "patient_pedia_knee_function_q10": "Opstaan uit een stoel",
  "patient_pedia_knee_function_q10_a1": "Geen moeite",
  "patient_pedia_knee_function_q10_a2": "Een beetje",
  "patient_pedia_knee_function_q10_a3": "Sommige",
  "patient_pedia_knee_function_q10_a4": "Veel",
  "patient_pedia_knee_function_q10_a5": "Ernstige moeilijkheid/kan niet doen",
  "patient_pedia_knee_function_q11": "Buigen om een voorwerp van de vloer op te rapen",
  "patient_pedia_knee_function_q11_a1": "Geen moeite",
  "patient_pedia_knee_function_q11_a2": "Een beetje",
  "patient_pedia_knee_function_q11_a3": "Sommige",
  "patient_pedia_knee_function_q11_a4": "Veel",
  "patient_pedia_knee_function_q11_a5": "Ernstige moeilijkheid/kan niet doen",
  "patient_pedia_knee_function_q12": "Rennen tijdens spelen of sportactiviteiten",
  "patient_pedia_knee_function_q12_a1": "Geen moeite",
  "patient_pedia_knee_function_q12_a2": "Een beetje",
  "patient_pedia_knee_function_q12_a3": "Sommige",
  "patient_pedia_knee_function_q12_a4": "Veel",
  "patient_pedia_knee_function_q12_a5": "Ernstige moeilijkheid/kan niet doen",
  "patient_pedia_knee_function_q13": "Springen tijdens spelen of sportactiviteiten",
  "patient_pedia_knee_function_q13_a1": "Geen moeite",
  "patient_pedia_knee_function_q13_a2": "Een beetje",
  "patient_pedia_knee_function_q13_a3": "Sommige",
  "patient_pedia_knee_function_q13_a4": "Veel",
  "patient_pedia_knee_function_q13_a5": "Ernstige moeilijkheid/kan niet doen",
  "patient_pedia_knee_function_q14": "Snel beginnen en stoppen met bewegen",
  "patient_pedia_knee_function_q14_a1": "Geen moeite",
  "patient_pedia_knee_function_q14_a2": "Een beetje",
  "patient_pedia_knee_function_q14_a3": "Sommige",
  "patient_pedia_knee_function_q14_a4": "Veel",
  "patient_pedia_knee_function_q14_a5": "Ernstige moeilijkheid/kan niet doen",
  "patient_pedia_knee_function_q15": "Draaien/pivoteren tijdens spelen of sportactiviteiten",
  "patient_pedia_knee_function_q15_a1": "Geen moeite",
  "patient_pedia_knee_function_q15_a2": "Een beetje",
  "patient_pedia_knee_function_q15_a3": "Sommige",
  "patient_pedia_knee_function_q15_a4": "Veel",
  "patient_pedia_knee_function_q15_a5": "Ernstige moeilijkheid/kan niet doen",
  "patient_pedia_knee_function_q16": "Uw evenwicht bewaren bij lopen/rennen op oneffen grond",
  "patient_pedia_knee_function_q16_a1": "Geen moeite",
  "patient_pedia_knee_function_q16_a2": "Een beetje",
  "patient_pedia_knee_function_q16_a3": "Sommige",
  "patient_pedia_knee_function_q16_a4": "Veel",
  "patient_pedia_knee_function_q16_a5": "Ernstige moeilijkheid/kan niet doen",
  "patient_pedia_knee_function_q17": "Sporten",
  "patient_pedia_knee_function_q17_a1": "Geen moeite",
  "patient_pedia_knee_function_q17_a2": "Een beetje",
  "patient_pedia_knee_function_q17_a3": "Sommige",
  "patient_pedia_knee_function_q17_a4": "Veel",
  "patient_pedia_knee_function_q17_a5": "Ernstige moeilijkheid/kan niet doen",
  "patient_pedia_knee_function_q18": "In/uit een auto stappen",
  "patient_pedia_knee_function_q18_a1": "Geen moeite",
  "patient_pedia_knee_function_q18_a2": "Een beetje",
  "patient_pedia_knee_function_q18_a3": "Sommige",
  "patient_pedia_knee_function_q18_a4": "Veel",
  "patient_pedia_knee_function_q18_a5": "Ernstige moeilijkheid/kan niet doen",
  "patient_pedia_knee_function_q19": "Uit bed komen",
  "patient_pedia_knee_function_q19_a1": "Geen moeite",
  "patient_pedia_knee_function_q19_a2": "Een beetje",
  "patient_pedia_knee_function_q19_a3": "Sommige",
  "patient_pedia_knee_function_q19_a4": "Veel",
  "patient_pedia_knee_function_q19_a5": "Ernstige moeilijkheid/kan niet doen",
  "patient_pedia_knee_function_q20": "De positie van uw knie veranderen als u in bed ligt",
  "patient_pedia_knee_function_q20_a1": "Geen moeite",
  "patient_pedia_knee_function_q20_a2": "Een beetje",
  "patient_pedia_knee_function_q20_a3": "Sommige",
  "patient_pedia_knee_function_q20_a4": "Veel",
  "patient_pedia_knee_function_q20_a5": "Ernstige moeilijkheid/kan niet doen",
  "patient_pedia_knee_function_q21": "In/uit het bad/douche stappen",
  "patient_pedia_knee_function_q21_a1": "Geen moeite",
  "patient_pedia_knee_function_q21_a2": "Een beetje",
  "patient_pedia_knee_function_q21_a3": "Sommige",
  "patient_pedia_knee_function_q21_a4": "Veel",
  "patient_pedia_knee_function_q21_a5": "Ernstige moeilijkheid/kan niet doen",
  "patient_pedia_knee_function_q22": "Zware tassen/rugzakken dragen, etc.",
  "patient_pedia_knee_function_q22_a1": "Geen moeite",
  "patient_pedia_knee_function_q22_a2": "Een beetje",
  "patient_pedia_knee_function_q22_a3": "Wat",
  "patient_pedia_knee_function_q22_a4": "Veel",
  "patient_pedia_knee_function_q22_a5": "Ernstige moeilijkheid/kan niet doen",
  "patient_pedia_knee_function_q23": "Lichte klusjes doen zoals uw kamer opruimen, de vaatwasser vullen/leegmaken, uw bed opmaken, etc.",
  "patient_pedia_knee_function_q23_a1": "Geen moeite",
  "patient_pedia_knee_function_q23_a2": "Een beetje",
  "patient_pedia_knee_function_q23_a3": "Wat",
  "patient_pedia_knee_function_q23_a4": "Veel",
  "patient_pedia_knee_function_q23_a5": "Ernstige moeilijkheid/kan niet doen",
  "patient_pedia_knee_function_q24": "Naar school gaan of rondlopen op school (trappen beklimmen, deuren openen, boeken dragen, deelnemen aan de pauze)",
  "patient_pedia_knee_function_q24_a1": "Geen moeite",
  "patient_pedia_knee_function_q24_a2": "Een beetje",
  "patient_pedia_knee_function_q24_a3": "Wat",
  "patient_pedia_knee_function_q24_a4": "Veel",
  "patient_pedia_knee_function_q24_a5": "Ernstige moeilijkheid/kan niet doen",
  "patient_pedia_knee_function_q25": "Dingen doen met vrienden",
  "patient_pedia_knee_function_q25_a1": "Geen moeite",
  "patient_pedia_knee_function_q25_a2": "Een beetje",
  "patient_pedia_knee_function_q25_a3": "Wat",
  "patient_pedia_knee_function_q25_a4": "Veel",
  "patient_pedia_knee_function_q25_a5": "Ernstige moeilijkheid/kan niet doen",
  "patient_pedia_knee_function_q26": "Hoe goed werkte uw knie VOORDAT U HET BLESSEERDE?",
  "patient_pedia_knee_function_q26_instruction": "10 is normaal en 0 betekent dat u geen van uw gebruikelijke dagelijkse activiteiten, inclusief sport, kon uitvoeren",
  "patient_pedia_knee_function_q26_instruction_a1": "Ik kon helemaal niets doen",
  "patient_pedia_knee_function_q26_instruction_a2": "Ik kon alles doen wat ik wilde",
  "patient_pedia_knee_function_q27": "Hoe goed werkt uw knie NU?",
  "patient_pedia_knee_function_q27_instruction": "10 is normaal en 0 betekent dat u geen van uw gebruikelijke dagelijkse activiteiten, inclusief sport, kunt uitvoeren",
  "patient_pedia_knee_function_q27_instruction_a1": "Ik kan helemaal niets doen",
  "patient_pedia_knee_function_q27_instruction_a2": "Ik kan alles doen wat ik wil doen",
  "patient_pedia_knee_function_q28": "Gaat uw knieschijf uit de kom of voelt het alsof het uit de kom gaat?",
  "patient_pedia_knee_function_q28_a1": "Nee",
  "patient_pedia_knee_function_q28_a2": "Ja",
  "patient_pedia_knee_instability": "Patellofemorale instabiliteit",
  "patient_pedia_knee_instability_instruction": "Wat beschrijft het BESTE HOE VAAK UW KNIESCHIJF HET GEVOEL HEEFT DAT HET \"UIT DE KOM\" ZAL SPRINGEN OF ONSTABIEL AANVOELT wanneer u elk van de volgende activiteiten doet",
  "patient_pedia_knee_instability_instruction_0": "Nooit",
  "patient_pedia_knee_instability_instruction_1": "Zelden",
  "patient_pedia_knee_instability_instruction_2": "Soms",
  "patient_pedia_knee_instability_instruction_3": "Vaak",
  "patient_pedia_knee_instability_instruction_4": "Altijd",
  "patient_pedia_knee_instability_note": "Opmerking: Beantwoord alstublieft de volgende vragen met betrekking tot uw knieactiviteiten",
  "patient_pedia_knee_instability_q1": "Tijdens sport-/spelactiviteiten draaien/richting veranderen",
  "patient_pedia_knee_instability_q1_do_not_do": "Doe ik niet",
  "patient_pedia_knee_instability_q1_a1": "Nooit",
  "patient_pedia_knee_instability_q1_a2": "Zelden",
  "patient_pedia_knee_instability_q1_a3": "Soms",
  "patient_pedia_knee_instability_q1_a4": "Vaak",
  "patient_pedia_knee_instability_q1_a5": "Altijd",
  "patient_pedia_knee_instability_q2": "Richting veranderen tijdens het rennen",
  "patient_pedia_knee_instability_q2_do_not_do": "Doe ik niet",
  "patient_pedia_knee_instability_q2_a1": "Nooit",
  "patient_pedia_knee_instability_q2_a2": "Zelden",
  "patient_pedia_knee_instability_q2_a3": "Soms",
  "patient_pedia_knee_instability_q2_a4": "Vaak",
  "patient_pedia_knee_instability_q2_a5": "Altijd",
  "patient_pedia_knee_instability_q3": "Rennen op oneffen oppervlakken",
  "patient_pedia_knee_instability_q3_do_not_do": "Doe ik niet",
  "patient_pedia_knee_instability_q3_a1": "Nooit",
  "patient_pedia_knee_instability_q3_a2": "Zelden",
  "patient_pedia_knee_instability_q3_a3": "Soms",
  "patient_pedia_knee_instability_q3_a4": "Vaak",
  "patient_pedia_knee_instability_q3_a5": "Altijd",
  "patient_pedia_knee_instability_q4": "Lopen op gladde, natte of ijzige oppervlakken",
  "patient_pedia_knee_instability_q4_do_not_do": "Doe ik niet",
  "patient_pedia_knee_instability_q4_a1": "Nooit",
  "patient_pedia_knee_instability_q4_a2": "Zelden",
  "patient_pedia_knee_instability_q4_a3": "Soms",
  "patient_pedia_knee_instability_q4_a4": "Vaak",
  "patient_pedia_knee_instability_q4_a5": "Altijd",
  "patient_pedia_knee_instability_q5": "Zijwaarts rennen",
  "patient_pedia_knee_instability_q5_do_not_do": "Doe ik niet",
  "patient_pedia_knee_instability_q5_a1": "Nooit",
  "patient_pedia_knee_instability_q5_a2": "Zelden",
  "patient_pedia_knee_instability_q5_a3": "Soms",
  "patient_pedia_knee_instability_q5_a4": "Vaak",
  "patient_pedia_knee_instability_q5_a5": "Altijd",
  "patient_pedia_knee_instability_q6": "Springen",
  "patient_pedia_knee_instability_q6_do_not_do": "Doe ik niet",
  "patient_pedia_knee_instability_q6_a1": "Nooit",
  "patient_pedia_knee_instability_q6_a2": "Zelden",
  "patient_pedia_knee_instability_q6_a3": "Soms",
  "patient_pedia_knee_instability_q6_a4": "Vaak",
  "patient_pedia_knee_instability_q6_a5": "Altijd",
  "patient_pedia_knee_instability_q7": "Springen",
  "patient_pedia_knee_instability_q7_do_not_do": "Doe ik niet",
  "patient_pedia_knee_instability_q7_a1": "Nooit",
  "patient_pedia_knee_instability_q7_a2": "Zelden",
  "patient_pedia_knee_instability_q7_a3": "Soms",
  "patient_pedia_knee_instability_q7_a4": "Vaak",
  "patient_pedia_knee_instability_q7_a5": "Altijd",
  "patient_pedia_knee_instability_q8": "Rennen in een rechte lijn op EGALE oppervlakken",
  "patient_pedia_knee_instability_q8_do_not_do": "Doe ik niet",
  "patient_pedia_knee_instability_q8_a1": "Nooit",
  "patient_pedia_knee_instability_q8_a2": "Zelden",
  "patient_pedia_knee_instability_q8_a3": "Soms",
  "patient_pedia_knee_instability_q8_a4": "Vaak",
  "patient_pedia_knee_instability_q8_a5": "Altijd",
  "patient_pedia_knee_instability_q9": "Trappen aflopen",
  "patient_pedia_knee_instability_q9_do_not_do": "Doe ik niet",
  "patient_pedia_knee_instability_q9_a1": "Nooit",
  "patient_pedia_knee_instability_q9_a2": "Zelden",
  "patient_pedia_knee_instability_q9_a3": "Soms",
  "patient_pedia_knee_instability_q9_a4": "Vaak",
  "patient_pedia_knee_instability_q9_a5": "Altijd",
  "patient_pedia_knee_instability_q10": "Hurken",
  "patient_pedia_knee_instability_q10_do_not_do": "Doe ik niet",
  "patient_pedia_knee_instability_q10_a1": "Nooit",
  "patient_pedia_knee_instability_q10_a2": "Zelden",
  "patient_pedia_knee_instability_q10_a3": "Soms",
  "patient_pedia_knee_instability_q10_a4": "Vaak",
  "patient_pedia_knee_instability_q10_a5": "Altijd",
  "patient_pedia_knee_instability_q11": "Knielen",
  "patient_pedia_knee_instability_q11_do_not_do": "Doe ik niet",
  "patient_pedia_knee_instability_q11_a1": "Nooit",
  "patient_pedia_knee_instability_q11_a2": "Zelden",
  "patient_pedia_knee_instability_q11_a3": "Soms",
  "patient_pedia_knee_instability_q11_a4": "Vaak",
  "patient_pedia_knee_instability_q11_a5": "Altijd",
  "patient_pedia_knee_instability_q12": "Lopen in een rechte lijn op ONEGALE oppervlakken",
  "patient_pedia_knee_instability_q12_do_not_do": "Doe ik niet",
  "patient_pedia_knee_instability_q12_a1": "Nooit",
  "patient_pedia_knee_instability_q12_a2": "Zelden",
  "patient_pedia_knee_instability_q12_a3": "Soms",
  "patient_pedia_knee_instability_q12_a4": "Vaak",
  "patient_pedia_knee_instability_q12_a5": "Altijd",
  "patient_pedia_knee_instability_q13": "Trappen beklimmen",
  "patient_pedia_knee_instability_q13_do_not_do": "Doe ik niet",
  "patient_pedia_knee_instability_q13_a1": "Nooit",
  "patient_pedia_knee_instability_q13_a2": "Zelden",
  "patient_pedia_knee_instability_q13_a3": "Soms",
  "patient_pedia_knee_instability_q13_a4": "Vaak",
  "patient_pedia_knee_instability_q13_a5": "Altijd",
  "patient_pedia_knee_instability_q14": "Op een hoge trede stappen of eroverheen stappen",
  "patient_pedia_knee_instability_q14_do_not_do": "Doe ik niet",
  "patient_pedia_knee_instability_q14_a1": "Nooit",
  "patient_pedia_knee_instability_q14_a2": "Zelden",
  "patient_pedia_knee_instability_q14_a3": "Soms",
  "patient_pedia_knee_instability_q14_a4": "Vaak",
  "patient_pedia_knee_instability_q14_a5": "Altijd",
  "patient_pedia_knee_instability_q15": "Je benen kruisen tijdens het zitten",
  "patient_pedia_knee_instability_q15_do_not_do": "Doe ik niet",
  "patient_pedia_knee_instability_q15_a1": "Nooit",
  "patient_pedia_knee_instability_q15_a2": "Zelden",
  "patient_pedia_knee_instability_q15_a3": "Soms",
  "patient_pedia_knee_instability_q15_a4": "Vaak",
  "patient_pedia_knee_instability_q15_a5": "Altijd",
  "patient_pedia_knee_instability_q16": "Lopen in een rechte lijn op EGALE oppervlakken",
  "patient_pedia_knee_instability_q16_do_not_do": "Doe ik niet",
  "patient_pedia_knee_instability_q16_a1": "Nooit",
  "patient_pedia_knee_instability_q16_a2": "Zelden",
  "patient_pedia_knee_instability_q16_a3": "Soms",
  "patient_pedia_knee_instability_q16_a4": "Vaak",
  "patient_pedia_knee_instability_q16_a5": "Altijd",
  "patient_pedia_knee_instability_q17": "In of uit een auto stappen",
  "patient_pedia_knee_instability_q17_do_not_do": "Doe ik niet",
  "patient_pedia_knee_instability_q17_a1": "Nooit",
  "patient_pedia_knee_instability_q17_a2": "Zelden",
  "patient_pedia_knee_instability_q17_a3": "Soms",
  "patient_pedia_knee_instability_q17_a4": "Vaak",
  "patient_pedia_knee_instability_q17_a5": "Altijd",
  "patient_pedia_knee_instability_q18": "Een zware winkelwagen door een supermarktgangpad duwen",
  "patient_pedia_knee_instability_q18_do_not_do": "Doe ik niet",
  "patient_pedia_knee_instability_q18_a1": "Nooit",
  "patient_pedia_knee_instability_q18_a2": "Zelden",
  "patient_pedia_knee_instability_q18_a3": "Soms",
  "patient_pedia_knee_instability_q18_a4": "Vaak",
  "patient_pedia_knee_instability_q18_a5": "Altijd",
  "patient_pedia_knee_instability_q19": "Over je schouder kijken",
  "patient_pedia_knee_instability_q19_do_not_do": "Doe ik niet",
  "patient_pedia_knee_instability_q19_a1": "Nooit",
  "patient_pedia_knee_instability_q19_a2": "Zelden",
  "patient_pedia_knee_instability_q19_a3": "Soms",
  "patient_pedia_knee_instability_q19_a4": "Vaak",
  "patient_pedia_knee_instability_q19_a5": "Altijd",
  "patient_pedia_knee_quality_of_life": "Kwaliteit van leven",
  "patient_pedia_knee_quality_of_life_instruction": "Hoe heeft uw letsel uw leven beïnvloed?",
  "patient_pedia_knee_quality_of_life_q1": "Hoe vaak denkt u aan uw knieprobleem?",
  "patient_pedia_knee_quality_of_life_q1_a1": "Nooit",
  "patient_pedia_knee_quality_of_life_q1_a2": "Zelden",
  "patient_pedia_knee_quality_of_life_q1_a3": "Soms",
  "patient_pedia_knee_quality_of_life_q1_a4": "Vaak",
  "patient_pedia_knee_quality_of_life_q1_a5": "De hele tijd",
  "patient_pedia_knee_problem_q2": "Hoezeer heeft u uw levensstijl veranderd vanwege uw geblesseerde knie?",
  "patient_pedia_knee_problem_q2_a1": "Helemaal niet",
  "patient_pedia_knee_problem_q2_a2": "Een beetje",
  "patient_pedia_knee_problem_q2_a3": "Enigszins",
  "patient_pedia_knee_problem_q2_a4": "Veel",
  "patient_pedia_knee_problem_q2_a5": "Heel veel",
  "patient_pedia_knee_problem_q3": "Hoeveel vertrouwt u op uw geblesseerde knie?",
  "patient_pedia_knee_problem_q3_a1": "Volledig",
  "patient_pedia_knee_problem_q3_a2": "Veel",
  "patient_pedia_knee_problem_q3_a3": "Enigszins",
  "patient_pedia_knee_problem_q3_a4": "Een beetje",
  "patient_pedia_knee_problem_q3_a5": "Helemaal niet",
  "patient_pedia_knee_problem_q4": "Over het algemeen, hoeveel moeite heeft u met uw geblesseerde knie?",
  "patient_pedia_knee_problem_q4_a1": "Geen moeite",
  "patient_pedia_knee_problem_q4_a2": "Een beetje",
  "patient_pedia_knee_problem_q4_a3": "Enigszins",
  "patient_pedia_knee_problem_q4_a4": "Veel",
  "patient_pedia_knee_problem_q4_a5": "Extreem moeilijk",
  "patient_pedia_knee_satisfaction": "Tevredenheid",
  "patient_pedia_knee_satisfaction_instruction": "Hoe tevreden bent u over de resultaten van uw knieoperatie/behandeling?",
  "patient_pedia_knee_satisfaction_instruction_a1": "Zeer ontevreden",
  "patient_pedia_knee_satisfaction_instruction_a2": "Iets ontevreden",
  "patient_pedia_knee_satisfaction_instruction_a3": "Iets tevreden",
  "patient_pedia_knee_satisfaction_instruction_a4": "Zeer tevreden",
  "patient_pedia_knee_satisfaction_q1": "Algemeen",
  "patient_pedia_knee_satisfaction_q1_NA": "Niet van toepassing",
  "patient_pedia_knee_satisfaction_q1_a1": "Zeer ontevreden",
  "patient_pedia_knee_satisfaction_q1_a2": "Iets ontevreden",
  "patient_pedia_knee_satisfaction_q1_a3": "Iets tevreden",
  "patient_pedia_knee_satisfaction_q1_a4": "Zeer tevreden",
  "patient_pedia_knee_satisfaction_q2": "Voor verbetering van uw pijn?",
  "patient_pedia_knee_satisfaction_q2_NA": "Niet van toepassing",
  "patient_pedia_knee_satisfaction_q2_a1": "Zeer ontevreden",
  "patient_pedia_knee_satisfaction_q2_a2": "Iets ontevreden",
  "patient_pedia_knee_satisfaction_q2_a3": "Iets tevreden",
  "patient_pedia_knee_satisfaction_q2_a4": "Zeer tevreden",
  "patient_pedia_knee_satisfaction_q3": "Voor verbetering van uw vermogen om thuis of in de tuin te werken?",
  "patient_pedia_knee_satisfaction_q3_NA": "Niet van toepassing",
  "patient_pedia_knee_satisfaction_q3_a1": "Zeer ontevreden",
  "patient_pedia_knee_satisfaction_q3_a2": "Iets ontevreden",
  "patient_pedia_knee_satisfaction_q3_a3": "Iets tevreden",
  "patient_pedia_knee_satisfaction_q3_a4": "Zeer tevreden",
  "patient_pedia_knee_satisfaction_q4": "Voor verbetering van uw vermogen om recreatieve activiteiten uit te voeren?",
  "patient_pedia_knee_satisfaction_q4_NA": "Niet van toepassing",
  "patient_pedia_knee_satisfaction_q4_a1": "Zeer ontevreden",
  "patient_pedia_knee_satisfaction_q4_a2": "Iets ontevreden",
  "patient_pedia_knee_satisfaction_q4_a3": "Iets tevreden",
  "patient_pedia_knee_satisfaction_q4_a4": "Zeer tevreden",
  "patient_pedia_knee_complete": "Einde van de vragenlijst",
  "patient_pedia_knee_complete_text1": "Bedankt dat u de tijd heeft genomen om dit formulier in te vullen.",
  "patient_pedia_knee_complete_text2": "Klik alstublieft op verzenden om het in te vullen en uw scores te zien.",
  "patient_pedia_knee_complete_text3": "Uw antwoorden worden vertrouwelijk behandeld en alle rapporten zullen alleen gebruikmaken van gedepersonaliseerde informatie.",
  "patient_pedia_knee_results": "Resultaten",
  "patient_pedia_knee_results_text1": "Hieronder vindt u uw scores voor pijn en kniefunctionaliteit, die zijn berekend op basis van uw vragenlijst.",
  "patient_pedia_knee_results_pain": "Pijn",
  "patient_pedia_knee_results_function": "Functie",
  "patient_pedia_knee_results_self_evaluation": "Zelfevaluatie van kniescore",
  "patient_pedia_knee_results_text2": "Uw arts zal binnenkort bij u zijn.",
  "patient_foot_ankle_pain": "Pijn",
  "patient_foot_ankle_pain_q1": "Wat is het HUIDIGE NIVEAU VAN PIJN in uw voet/enkel?",
  "patient_foot_ankle_pain_q1_0": "Geen",
  "patient_foot_ankle_pain_q1_1": "Ergst denkbare pijn",
  "patient_foot_ankle_pain_q2": "Wat is de beste beschrijving van PIJN in uw voet/enkel?",
  "patient_foot_ankle_pain_q2_a1": "Geen",
  "patient_foot_ankle_pain_q2_a2": "Mild, af en toe",
  "patient_foot_ankle_pain_q2_a3": "Matig, dagelijks",
  "patient_foot_ankle_pain_q2_a4": "Ernstig, bijna altijd aanwezig",
  "patient_foot_ankle_pain_q3": "Hoe VAAK ervaart u pijn in uw voet/enkel?",
  "patient_foot_ankle_pain_q3_a1": "Nooit",
  "patient_foot_ankle_pain_q3_a2": "Maandelijks",
  "patient_foot_ankle_pain_q3_a3": "Wekelijks",
  "patient_foot_ankle_pain_q3_a4": "Dagelijks",
  "patient_foot_ankle_pain_q3_a5": "Altijd",
  "patient_foot_ankle_pain_instruction": "Welke mate van PIJN AAN UW VOET/ENKEL heeft u ervaren tijdens de volgende activiteiten:",
  "patient_foot_ankle_pain_instruction_0": "Geen",
  "patient_foot_ankle_pain_instruction_1": "Mild",
  "patient_foot_ankle_pain_instruction_2": "Matig",
  "patient_foot_ankle_pain_instruction_3": "Ernstig",
  "patient_foot_ankle_pain_instruction_4": "Extreem",
  "patient_foot_ankle_pain_q4": "Algemene pijnniveau",
  "patient_foot_ankle_pain_q4_a1": "Geen",
  "patient_foot_ankle_pain_q4_a2": "Mild",
  "patient_foot_ankle_pain_q4_a3": "Matig",
  "patient_foot_ankle_pain_q4_a4": "Ernstig",
  "patient_foot_ankle_pain_q4_a5": "Extreem",
  "patient_foot_ankle_pain_q5": "In rust",
  "patient_foot_ankle_pain_q5_a1": "Geen",
  "patient_foot_ankle_pain_q5_a2": "Mild",
  "patient_foot_ankle_pain_q5_a3": "Matig",
  "patient_foot_ankle_pain_q5_a4": "Ernstig",
  "patient_foot_ankle_pain_q5_a5": "Extreem",
  "patient_foot_ankle_pain_q6": "Tijdens normale activiteit",
  "patient_foot_ankle_pain_q6_a1": "Geen",
  "patient_foot_ankle_pain_q6_a2": "Mild",
  "patient_foot_ankle_pain_q6_a3": "Matig",
  "patient_foot_ankle_pain_q6_a4": "Ernstig",
  "patient_foot_ankle_pain_q6_a5": "Extreem",
  "patient_foot_ankle_pain_q7": "s Ochtends vroeg",
  "patient_foot_ankle_pain_q7_a1": "Geen",
  "patient_foot_ankle_pain_q7_a2": "Mild",
  "patient_foot_ankle_pain_q7_a3": "Matig",
  "patient_foot_ankle_pain_q7_a4": "Ernstig",
  "patient_foot_ankle_pain_q7_a5": "Extreem",
  "patient_foot_ankle_pain_q8": "Draaien/draaien op uw voet/enkel",
  "patient_foot_ankle_pain_q8_a1": "Geen",
  "patient_foot_ankle_pain_q8_a2": "Mild",
  "patient_foot_ankle_pain_q8_a3": "Matig",
  "patient_foot_ankle_pain_q8_a4": "Ernstig",
  "patient_foot_ankle_pain_q8_a5": "Extreem",
  "patient_foot_ankle_pain_q9": "Voet/enkel volledig strekken",
  "patient_foot_ankle_pain_q9_a1": "Geen",
  "patient_foot_ankle_pain_q9_a2": "Mild",
  "patient_foot_ankle_pain_q9_a3": "Matig",
  "patient_foot_ankle_pain_q9_a4": "Ernstig",
  "patient_foot_ankle_pain_q9_a5": "Extreem",
  "patient_foot_ankle_pain_q10": "Voet/enkel volledig buigen",
  "patient_foot_ankle_pain_q10_a1": "Geen",
  "patient_foot_ankle_pain_q10_a2": "Mild",
  "patient_foot_ankle_pain_q10_a3": "Matig",
  "patient_foot_ankle_pain_q10_a4": "Ernstig",
  "patient_foot_ankle_pain_q10_a5": "Extreem",
  "patient_foot_ankle_pain_q11": "Lopen op een vlakke ondergrond",
  "patient_foot_ankle_pain_q11_a1": "Geen",
  "patient_foot_ankle_pain_q11_a2": "Mild",
  "patient_foot_ankle_pain_q11_a3": "Matig",
  "patient_foot_ankle_pain_q11_a4": "Ernstig",
  "patient_foot_ankle_pain_q11_a5": "Extreem",
  "patient_foot_ankle_pain_q12": "Trap op of af gaan",
  "patient_foot_ankle_pain_q12_a1": "Geen",
  "patient_foot_ankle_pain_q12_a2": "Mild",
  "patient_foot_ankle_pain_q12_a3": "Matig",
  "patient_foot_ankle_pain_q12_a4": "Ernstig",
  "patient_foot_ankle_pain_q12_a5": "Extreem",
  "patient_foot_ankle_pain_q13": "s Nachts in bed",
  "patient_foot_ankle_pain_q13_a1": "Geen",
  "patient_foot_ankle_pain_q13_a2": "Mild",
  "patient_foot_ankle_pain_q13_a3": "Matig",
  "patient_foot_ankle_pain_q13_a4": "Ernstig",
  "patient_foot_ankle_pain_q13_a5": "Extreem",
  "patient_foot_ankle_pain_q14": "Zitten of liggen",
  "patient_foot_ankle_pain_q14_a1": "Geen",
  "patient_foot_ankle_pain_q14_a2": "Mild",
  "patient_foot_ankle_pain_q14_a3": "Matig",
  "patient_foot_ankle_pain_q14_a4": "Ernstig",
  "patient_foot_ankle_pain_q14_a5": "Extreem",
  "patient_foot_ankle_pain_q15": "Rechtop staan",
  "patient_foot_ankle_pain_q15_a1": "Geen",
  "patient_foot_ankle_pain_q15_a2": "Mild",
  "patient_foot_ankle_pain_q15_a3": "Matig",
  "patient_foot_ankle_pain_q15_a4": "Ernstig",
  "patient_foot_ankle_pain_q15_a5": "Extreem",
  "patient_foot_ankle_instability": "Instabiliteit",
  "patient_foot_ankle_instability_q1": "Ervaart u herhaalde verstuikingen, zwakte van uw enkel, of voelt u alsof het gaat verzwikken?",
  "patient_foot_ankle_instability_q1_a1": "Nee",
  "patient_foot_ankle_instability_q1_a2": "Ja",
  "patient_foot_ankle_instability_q2": "Bent u ooit gediagnosticeerd of behandeld voor enkelinstabiliteit/herhaalde verstuikingen?",
  "patient_foot_ankle_instability_q2_a1": "Nee",
  "patient_foot_ankle_instability_q2_a2": "Ja",
  "patient_foot_ankle_instability_q3": "Voelt uw onaangetaste enkel volledig stabiel?",
  "patient_foot_ankle_instability_q3_a1": "Nee",
  "patient_foot_ankle_instability_q3_a2": "Ja",
  "patient_foot_ankle_cumberland": "CUMBERLAND ENKELINSTABILITEIT INSTRUMENT",
  "patient_foot_ankle_cumberland_instruction": "Kies alstublieft één verklaring in elke vraag die uw enkel het beste beschrijft.",
  "patient_foot_ankle_cumberland_q1": "Ik heb pijn aan mijn enkel",
  "patient_foot_ankle_cumberland_q1_a1": "Nooit",
  "patient_foot_ankle_cumberland_q1_a2": "Tijdens sport",
  "patient_foot_ankle_cumberland_q1_a3": "Bij hardlopen op oneffen ondergrond",
  "patient_foot_ankle_cumberland_q1_a4": "Bij hardlopen op vlakke ondergrond",
  "patient_foot_ankle_cumberland_q1_a5": "Bij lopen op oneffen ondergrond",
  "patient_foot_ankle_cumberland_q1_a6": "Bij lopen op vlakke ondergrond",
  "patient_foot_ankle_cumberland_q2": "Mijn enkel voelt ONSTABIEL",
  "patient_foot_ankle_cumberland_q2_a1": "Nooit",
  "patient_foot_ankle_cumberland_q2_a2": "Soms tijdens sport (niet altijd)",
  "patient_foot_ankle_cumberland_q2_a3": "Vaak tijdens sport (altijd)",
  "patient_foot_ankle_cumberland_q2_a4": "Soms tijdens dagelijkse activiteiten",
  "patient_foot_ankle_cumberland_q2_a5": "Vaak tijdens dagelijkse activiteiten",
  "patient_foot_ankle_cumberland_q3": "Als ik SCHERPE bochten maak, voelt mijn enkel ONSTABIEL",
  "patient_foot_ankle_cumberland_q3_a1": "Nooit",
  "patient_foot_ankle_cumberland_q3_a2": "Soms tijdens het rennen",
  "patient_foot_ankle_cumberland_q3_a3": "Vaak tijdens het rennen",
  "patient_foot_ankle_cumberland_q3_a4": "Bij het lopen",
  "patient_foot_ankle_cumberland_q4": "Bij het afdalen van de trap, voelt mijn enkel ONSTABIEL",
  "patient_foot_ankle_cumberland_q4_a1": "Nooit",
  "patient_foot_ankle_cumberland_q4_a2": "Als ik snel ga",
  "patient_foot_ankle_cumberland_q4_a3": "Af en toe",
  "patient_foot_ankle_cumberland_q4_a4": "Altijd",
  "patient_foot_ankle_cumberland_q5": "Mijn enkel voelt ONSTABIEL bij staan op ÉÉN been",
  "patient_foot_ankle_cumberland_q5_a1": "Nooit",
  "patient_foot_ankle_cumberland_q5_a2": "Op de bal van de voet",
  "patient_foot_ankle_cumberland_q5_a3": "Met mijn voet plat",
  "patient_foot_ankle_cumberland_q6": "Mijn enkel voelt ONSTABIEL wanneer",
  "patient_foot_ankle_cumberland_q6_a1": "Nooit",
  "patient_foot_ankle_cumberland_q6_a2": "Ik van de zijkant naar de zijkant spring",
  "patient_foot_ankle_cumberland_q6_a3": "Ik op de plek spring",
  "patient_foot_ankle_cumberland_q6_a4": "Ik spring",
  "patient_foot_ankle_cumberland_q7": "Mijn enkel voelt ONSTABIEL wanneer",
  "patient_foot_ankle_cumberland_q7_a1": "Nooit",
  "patient_foot_ankle_cumberland_q7_a2": "Ik op oneffen ondergrond ren",
  "patient_foot_ankle_cumberland_q7_a3": "Ik jog op oneffen ondergrond",
  "patient_foot_ankle_cumberland_q7_a4": "Ik loop op oneffen ondergrond",
  "patient_foot_ankle_cumberland_q7_a5": "Ik loop op vlakke ondergrond",
  "patient_foot_ankle_cumberland_q8": "TYPISCH, wanneer ik begin te verzwikken op mijn enkel, kan ik het stoppen",
  "patient_foot_ankle_cumberland_q8_a1": "Onmiddellijk",
  "patient_foot_ankle_cumberland_q8_a2": "Vaak",
  "patient_foot_ankle_cumberland_q8_a3": "Soms",
  "patient_foot_ankle_cumberland_q8_a4": "Nooit",
  "patient_foot_ankle_cumberland_q8_a5": "Ik ben nog nooit op mijn enkel verzwikt",
  "patient_foot_ankle_cumberland_q9": "Na een TYPISCH incident van mijn enkel verzwikken, keert mijn enkel terug naar \"normaal\"",
  "patient_foot_ankle_cumberland_q9_a1": "Bijna onmiddellijk",
  "patient_foot_ankle_cumberland_q9_a2": "Minder dan een dag",
  "patient_foot_ankle_cumberland_q9_a3": "1-2 dagen",
  "patient_foot_ankle_cumberland_q9_a4": "Meer dan 2 dagen",
  "patient_foot_ankle_cumberland_q9_a5": "Ik ben nog nooit op mijn enkel verzwikt",
  "patient_foot_ankle_other_symptoms": "Andere symptomen",
  "patient_foot_ankle_other_symptoms_instruction1": "Kies alstublieft het antwoord dat het beste overeenkomt met uw symptomen aan uw voet/enkel.",
  "patient_foot_ankle_other_symptoms_instruction2": "Als u niet zeker weet hoe u een vraag moet beantwoorden, geef dan het beste antwoord dat u kunt.",
  "patient_foot_ankle_other_symptoms_note": "Opmerking: Deze vragen moeten worden beantwoord met betrekking tot uw symptomen aan uw voet/enkel gedurende de afgelopen week.",
  "patient_foot_ankle_other_symptoms_instruction3": "Heeft u het volgende:",
  "patient_foot_ankle_other_symptoms_instruction3_0": "Nooit",
  "patient_foot_ankle_other_symptoms_instruction3_1": "Zelden",
  "patient_foot_ankle_other_symptoms_instruction3_2": "Soms",
  "patient_foot_ankle_other_symptoms_instruction3_3": "Vaak",
  "patient_foot_ankle_other_symptoms_instruction3_4": "Altijd",
  "patient_foot_ankle_other_symptoms_q1": "Zwelling in uw voet/enkel",
  "patient_foot_ankle_other_symptoms_q1_a1": "Nooit",
  "patient_foot_ankle_other_symptoms_q1_a2": "Zelden",
  "patient_foot_ankle_other_symptoms_q1_a3": "Soms",
  "patient_foot_ankle_other_symptoms_q1_a4": "Vaak",
  "patient_foot_ankle_other_symptoms_q1_a5": "Altijd",
  "patient_foot_ankle_other_symptoms_q2": "Malen, klikken of geluid horen wanneer uw voet/enkel beweegt",
  "patient_foot_ankle_other_symptoms_q2_a1": "Nooit",
  "patient_foot_ankle_other_symptoms_q2_a2": "Zelden",
  "patient_foot_ankle_other_symptoms_q2_a3": "Soms",
  "patient_foot_ankle_other_symptoms_q2_a4": "Vaak",
  "patient_foot_ankle_other_symptoms_q2_a5": "Altijd",
  "patient_foot_ankle_other_symptoms_q3": "Vastlopen of vasthaken bij beweging",
  "patient_foot_ankle_other_symptoms_q3_a1": "Nooit",
  "patient_foot_ankle_other_symptoms_q3_a2": "Zelden",
  "patient_foot_ankle_other_symptoms_q3_a3": "Soms",
  "patient_foot_ankle_other_symptoms_q3_a4": "Vaak",
  "patient_foot_ankle_other_symptoms_q3_a5": "Altijd",
  "patient_foot_ankle_other_symptoms_instruction4": "Hoe ernstig is uw voet/enkel STIJFHEID:",
  "patient_foot_ankle_other_symptoms_instruction4_0": "Geen",
  "patient_foot_ankle_other_symptoms_instruction4_1": "Mild",
  "patient_foot_ankle_other_symptoms_instruction4_2": "Matig",
  "patient_foot_ankle_other_symptoms_instruction4_3": "Ernstig",
  "patient_foot_ankle_other_symptoms_instruction4_4": "Extreem",
  "patient_foot_ankle_other_symptoms_q4": "Direct na het ontwaken 's ochtends",
  "patient_foot_ankle_other_symptoms_q4_a1": "Geen",
  "patient_foot_ankle_other_symptoms_q4_a2": "Mild",
  "patient_foot_ankle_other_symptoms_q4_a3": "Matig",
  "patient_foot_ankle_other_symptoms_q4_a4": "Ernstig",
  "patient_foot_ankle_other_symptoms_q4_a5": "Extreem",
  "patient_foot_ankle_other_symptoms_q5": "Na zitten, liggen, of rusten later op de dag",
  "patient_foot_ankle_other_symptoms_q5_a1": "Geen",
  "patient_foot_ankle_other_symptoms_q5_a2": "Mild",
  "patient_foot_ankle_other_symptoms_q5_a3": "Matig",
  "patient_foot_ankle_other_symptoms_q5_a4": "Ernstig",
  "patient_foot_ankle_other_symptoms_q5_a5": "Extreem",
  "patient_foot_ankle_function": "Functie",
  "patient_foot_ankle_function_q1": "Welke beperkingen heeft u in uw dagelijkse leven als gevolg van uw voet/enkel?",
  "patient_foot_ankle_function_q1_a1": "Geen beperkingen, geen ondersteuning",
  "patient_foot_ankle_function_q1_a2": "Geen beperking van dagelijkse activiteiten, beperking van recreatieve activiteiten, geen ondersteuning",
  "patient_foot_ankle_function_q1_a3": "Beperkte dagelijkse en recreatieve activiteiten, stok",
  "patient_foot_ankle_function_q1_a4": "Ernstige beperking van dagelijkse en recreatieve activiteiten, rollator, krukken, rolstoel, brace",
  "patient_foot_ankle_function_q2": "Welk schoeisel draagt u meestal?",
  "patient_foot_ankle_function_q2_a1": "Modieuze, conventionele schoenen, geen inlegzool nodig",
  "patient_foot_ankle_function_q2_a2": "Comfortschoeisel, inlegzool",
  "patient_foot_ankle_function_q2_a3": "Aangepaste schoenen of brace",
  "patient_foot_ankle_function_q3": "Op welk soort ondergrond heeft u moeite met lopen?",
  "patient_foot_ankle_function_q3_a1": "Geen moeite op welke ondergrond dan ook",
  "patient_foot_ankle_function_q3_a2": "Enige moeite op oneffen terrein, trappen, hellingen, ladders",
  "patient_foot_ankle_function_q3_a3": "Ernstige moeite op oneffen terrein, trappen, hellingen, ladders",
  "patient_foot_ankle_function_instruction": "Geef de MATE VAN MOEILIJKHEID aan die u in de afgelopen week heeft ervaren als gevolg van uw enkel/voet.",
  "patient_foot_ankle_function_instruction_0": "Geen moeilijkheden",
  "patient_foot_ankle_function_instruction_1": "Geringe moeilijkheden",
  "patient_foot_ankle_function_instruction_2": "Matige moeilijkheden",
  "patient_foot_ankle_function_instruction_3": "Ernstige moeilijkheden",
  "patient_foot_ankle_function_instruction_4": "Niet in staat om te doen",
  "patient_foot_ankle_function_note": "Opmerking: Als de activiteit in kwestie beperkt wordt door iets anders dan uw voet of enkel, markeer dan 'Niet van toepassing' bij items waar dit een optie is. Anders, beantwoord alstublieft elke vraag met één antwoord dat het meest nauwkeurig uw toestand van de afgelopen week beschrijft.",
  "patient_foot_ankle_function_q4": "Uw voet/enkel volledig strekken",
  "patient_foot_ankle_function_q4_a1": "Geen moeite",
  "patient_foot_ankle_function_q4_a2": "Lichte moeite",
  "patient_foot_ankle_function_q4_a3": "Matige moeite",
  "patient_foot_ankle_function_q4_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q4_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q5": "Uw voet/enkel volledig buigen",
  "patient_foot_ankle_function_q5_a1": "Geen moeite",
  "patient_foot_ankle_function_q5_a2": "Lichte moeite",
  "patient_foot_ankle_function_q5_a3": "Matige moeite",
  "patient_foot_ankle_function_q5_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q5_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q6": "Trappen aflopen",
  "patient_foot_ankle_function_q6_a1": "Geen moeite",
  "patient_foot_ankle_function_q6_a2": "Lichte moeite",
  "patient_foot_ankle_function_q6_a3": "Matige moeite",
  "patient_foot_ankle_function_q6_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q6_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q7": "Trappen oplopen",
  "patient_foot_ankle_function_q7_a1": "Geen moeite",
  "patient_foot_ankle_function_q7_a2": "Lichte moeite",
  "patient_foot_ankle_function_q7_a3": "Matige moeite",
  "patient_foot_ankle_function_q7_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q7_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q8": "Opstaan vanuit zitten",
  "patient_foot_ankle_function_q8_a1": "Geen moeite",
  "patient_foot_ankle_function_q8_a2": "Lichte moeite",
  "patient_foot_ankle_function_q8_a3": "Matige moeite",
  "patient_foot_ankle_function_q8_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q8_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q9": "Staan",
  "patient_foot_ankle_function_q9_a1": "Geen moeite",
  "patient_foot_ankle_function_q9_a2": "Lichte moeite",
  "patient_foot_ankle_function_q9_a3": "Matige moeite",
  "patient_foot_ankle_function_q9_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q9_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q10": "Buigen om iets van de grond op te rapen",
  "patient_foot_ankle_function_q10_a1": "Geen moeite",
  "patient_foot_ankle_function_q10_a2": "Lichte moeite",
  "patient_foot_ankle_function_q10_a3": "Matige moeite",
  "patient_foot_ankle_function_q10_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q10_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q11": "Lopen op een vlakke ondergrond",
  "patient_foot_ankle_function_q11_a1": "Geen moeite",
  "patient_foot_ankle_function_q11_a2": "Lichte moeite",
  "patient_foot_ankle_function_q11_a3": "Matige moeite",
  "patient_foot_ankle_function_q11_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q11_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q12": "Wat is de maximale afstand die u kunt lopen?",
  "patient_foot_ankle_function_q12_a1": "Meer dan 6 straten",
  "patient_foot_ankle_function_q12_a2": "4-6 straten",
  "patient_foot_ankle_function_q12_a3": "1-3 straten",
  "patient_foot_ankle_function_q12_a4": "Minder dan 1 straat",
  "patient_foot_ankle_function_q13": "Lopen op een gelijkmatige ondergrond zonder schoenen",
  "patient_foot_ankle_function_q13_a1": "Geen moeite",
  "patient_foot_ankle_function_q13_a2": "Lichte moeite",
  "patient_foot_ankle_function_q13_a3": "Matige moeite",
  "patient_foot_ankle_function_q13_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q13_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q14": "Lopen bergopwaarts",
  "patient_foot_ankle_function_q14_a1": "Geen moeite",
  "patient_foot_ankle_function_q14_a2": "Lichte moeite",
  "patient_foot_ankle_function_q14_a3": "Matige moeite",
  "patient_foot_ankle_function_q14_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q14_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q15": "Lopen bergafwaarts",
  "patient_foot_ankle_function_q15_a1": "Geen moeite",
  "patient_foot_ankle_function_q15_a2": "Lichte moeite",
  "patient_foot_ankle_function_q15_a3": "Matige moeite",
  "patient_foot_ankle_function_q15_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q15_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q16": "Lopen op oneffen terrein",
  "patient_foot_ankle_function_q16_a1": "Geen moeite",
  "patient_foot_ankle_function_q16_a2": "Lichte moeite",
  "patient_foot_ankle_function_q16_a3": "Matige moeite",
  "patient_foot_ankle_function_q16_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q16_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q17": "Op- en afstappen van stoepen",
  "patient_foot_ankle_function_q17_a1": "Geen moeite",
  "patient_foot_ankle_function_q17_a2": "Lichte moeite",
  "patient_foot_ankle_function_q17_a3": "Matige moeite",
  "patient_foot_ankle_function_q17_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q17_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q18": "Op uw tenen staan",
  "patient_foot_ankle_function_q18_a1": "Geen moeite",
  "patient_foot_ankle_function_q18_a2": "Lichte moeite",
  "patient_foot_ankle_function_q18_a3": "Matige moeite",
  "patient_foot_ankle_function_q18_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q18_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q19": "In eerste instantie lopen",
  "patient_foot_ankle_function_q19_a1": "Geen moeite",
  "patient_foot_ankle_function_q19_a2": "Lichte moeite",
  "patient_foot_ankle_function_q19_a3": "Matige moeite",
  "patient_foot_ankle_function_q19_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q19_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q20": "5 minuten of minder lopen",
  "patient_foot_ankle_function_q20_a1": "Geen moeite",
  "patient_foot_ankle_function_q20_a2": "Lichte moeite",
  "patient_foot_ankle_function_q20_a3": "Matige moeite",
  "patient_foot_ankle_function_q20_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q20_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q21": "Ongeveer 10 minuten lopen",
  "patient_foot_ankle_function_q21_a1": "Geen moeite",
  "patient_foot_ankle_function_q21_a2": "Lichte moeite",
  "patient_foot_ankle_function_q21_a3": "Matige moeite",
  "patient_foot_ankle_function_q21_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q21_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q22": "15 minuten of langer lopen",
  "patient_foot_ankle_function_q22_a1": "Geen moeite",
  "patient_foot_ankle_function_q22_a2": "Lichte moeite",
  "patient_foot_ankle_function_q22_a3": "Matige moeite",
  "patient_foot_ankle_function_q22_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q22_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q23": "In/uit de auto stappen",
  "patient_foot_ankle_function_q23_a1": "Geen moeite",
  "patient_foot_ankle_function_q23_a2": "Lichte moeite",
  "patient_foot_ankle_function_q23_a3": "Matige moeite",
  "patient_foot_ankle_function_q23_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q23_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q24": "Boodschappen doen",
  "patient_foot_ankle_function_q24_a1": "Geen moeite",
  "patient_foot_ankle_function_q24_a2": "Lichte moeite",
  "patient_foot_ankle_function_q24_a3": "Matige moeite",
  "patient_foot_ankle_function_q24_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q24_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q25": "Sokken/kousen aantrekken",
  "patient_foot_ankle_function_q25_a1": "Geen moeite",
  "patient_foot_ankle_function_q25_a2": "Lichte moeite",
  "patient_foot_ankle_function_q25_a3": "Matige moeite",
  "patient_foot_ankle_function_q25_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q25_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q26": "Sokken/kousen uittrekken",
  "patient_foot_ankle_function_q26_a1": "Geen moeite",
  "patient_foot_ankle_function_q26_a2": "Lichte moeite",
  "patient_foot_ankle_function_q26_a3": "Matige moeite",
  "patient_foot_ankle_function_q26_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q26_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q27": "Opstaan uit bed",
  "patient_foot_ankle_function_q27_a1": "Geen moeite",
  "patient_foot_ankle_function_q27_a2": "Lichte moeite",
  "patient_foot_ankle_function_q27_a3": "Matige moeite",
  "patient_foot_ankle_function_q27_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q27_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q28": "Liggen in bed (omdraaien, voet/enkelpositie behouden)",
  "patient_foot_ankle_function_q28_a1": "Geen moeite",
  "patient_foot_ankle_function_q28_a2": "Lichte moeite",
  "patient_foot_ankle_function_q28_a3": "Matige moeite",
  "patient_foot_ankle_function_q28_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q28_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q29": "Slapen",
  "patient_foot_ankle_function_q29_a1": "Geen moeite",
  "patient_foot_ankle_function_q29_a2": "Lichte moeite",
  "patient_foot_ankle_function_q29_a3": "Matige moeite",
  "patient_foot_ankle_function_q29_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q29_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q30": "In en uit bad stappen",
  "patient_foot_ankle_function_q30_a1": "Geen moeite",
  "patient_foot_ankle_function_q30_a2": "Lichte moeite",
  "patient_foot_ankle_function_q30_a3": "Matige moeite",
  "patient_foot_ankle_function_q30_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q30_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q31": "Zitten",
  "patient_foot_ankle_function_q31_a1": "Geen moeite",
  "patient_foot_ankle_function_q31_a2": "Lichte moeite",
  "patient_foot_ankle_function_q31_a3": "Matige moeite",
  "patient_foot_ankle_function_q31_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q31_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q32": "Op en van het toilet gaan",
  "patient_foot_ankle_function_q32_a1": "Geen moeite",
  "patient_foot_ankle_function_q32_a2": "Lichte moeite",
  "patient_foot_ankle_function_q32_a3": "Matige moeite",
  "patient_foot_ankle_function_q32_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q32_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q33": "Huistaken",
  "patient_foot_ankle_function_q33_a1": "Geen moeite",
  "patient_foot_ankle_function_q33_a2": "Lichte moeite",
  "patient_foot_ankle_function_q33_a3": "Matige moeite",
  "patient_foot_ankle_function_q33_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q33_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q34": "Alledaagse activiteiten",
  "patient_foot_ankle_function_q34_a1": "Geen moeite",
  "patient_foot_ankle_function_q34_a2": "Lichte moeite",
  "patient_foot_ankle_function_q34_a3": "Matige moeite",
  "patient_foot_ankle_function_q34_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q34_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q35": "Persoonlijke verzorging (inclusief wassen of aankleden)",
  "patient_foot_ankle_function_q35_a1": "Geen moeite",
  "patient_foot_ankle_function_q35_a2": "Lichte moeite",
  "patient_foot_ankle_function_q35_a3": "Matige moeite",
  "patient_foot_ankle_function_q35_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q35_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q36": "Zwaar werk (duwen/trekken, klimmen, tillen, verplaatsen van zware dozen, vloeren schrobben, enz.)",
  "patient_foot_ankle_function_q36_a1": "Geen moeite",
  "patient_foot_ankle_function_q36_a2": "Lichte moeite",
  "patient_foot_ankle_function_q36_a3": "Matige moeite",
  "patient_foot_ankle_function_q36_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q36_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q37": "Lichte huishoudelijke taken (koken, stoffen, enz.)",
  "patient_foot_ankle_function_q37_a1": "Geen moeite",
  "patient_foot_ankle_function_q37_a2": "Lichte moeite",
  "patient_foot_ankle_function_q37_a3": "Matige moeite",
  "patient_foot_ankle_function_q37_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q37_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q38": "Licht tot matig werk (staan, lopen)",
  "patient_foot_ankle_function_q38_a1": "Geen moeite",
  "patient_foot_ankle_function_q38_a2": "Lichte moeite",
  "patient_foot_ankle_function_q38_a3": "Matige moeite",
  "patient_foot_ankle_function_q38_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q38_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q39": "Recreatieve activiteiten",
  "patient_foot_ankle_function_q39_a1": "Geen moeite",
  "patient_foot_ankle_function_q39_a2": "Lichte moeite",
  "patient_foot_ankle_function_q39_a3": "Matige moeite",
  "patient_foot_ankle_function_q39_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q39_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q40": "Hurken",
  "patient_foot_ankle_function_q40_a1": "Geen moeite",
  "patient_foot_ankle_function_q40_a2": "Lichte moeite",
  "patient_foot_ankle_function_q40_a3": "Matige moeite",
  "patient_foot_ankle_function_q40_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q40_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q41": "Draaien/kantelen op uw voet/enkel",
  "patient_foot_ankle_function_q41_a1": "Geen moeite",
  "patient_foot_ankle_function_q41_a2": "Lichte moeite",
  "patient_foot_ankle_function_q41_a3": "Matige moeite",
  "patient_foot_ankle_function_q41_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q41_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q42": "Knielen",
  "patient_foot_ankle_function_q42_a1": "Geen moeite",
  "patient_foot_ankle_function_q42_a2": "Lichte moeite",
  "patient_foot_ankle_function_q42_a3": "Matige moeite",
  "patient_foot_ankle_function_q42_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q42_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q43": "Rennen",
  "patient_foot_ankle_function_q43_not_applicable": "Niet van toepassing",
  "patient_foot_ankle_function_q43_a1": "Geen moeite",
  "patient_foot_ankle_function_q43_a2": "Lichte moeite",
  "patient_foot_ankle_function_q43_a3": "Matige moeite",
  "patient_foot_ankle_function_q43_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q43_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q44": "Springen",
  "patient_foot_ankle_function_q44_not_applicable": "Niet van toepassing",
  "patient_foot_ankle_function_q44_a1": "Geen moeite",
  "patient_foot_ankle_function_q44_a2": "Lichte moeite",
  "patient_foot_ankle_function_q44_a3": "Matige moeite",
  "patient_foot_ankle_function_q44_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q44_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q45": "Landen",
  "patient_foot_ankle_function_q45_not_applicable": "Niet van toepassing",
  "patient_foot_ankle_function_q45_a1": "Geen moeite",
  "patient_foot_ankle_function_q45_a2": "Lichte moeite",
  "patient_foot_ankle_function_q45_a3": "Matige moeite",
  "patient_foot_ankle_function_q45_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q45_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q46": "Snel starten en stoppen",
  "patient_foot_ankle_function_q46_not_applicable": "Niet van toepassing",
  "patient_foot_ankle_function_q46_a1": "Geen moeite",
  "patient_foot_ankle_function_q46_a2": "Lichte moeite",
  "patient_foot_ankle_function_q46_a3": "Matige moeite",
  "patient_foot_ankle_function_q46_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q46_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q47": "Snijden/laterale bewegingen",
  "patient_foot_ankle_function_q47_not_applicable": "Niet van toepassing",
  "patient_foot_ankle_function_q47_a1": "Geen moeite",
  "patient_foot_ankle_function_q47_a2": "Lichte moeite",
  "patient_foot_ankle_function_q47_a3": "Matige moeite",
  "patient_foot_ankle_function_q47_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q47_a5": "Niet van toepassing",
  "patient_foot_ankle_function_q48": "Lage-impactactiviteiten",
  "patient_foot_ankle_function_q48_not_applicable": "Niet van toepassing",
  "patient_foot_ankle_function_q48_a1": "Geen moeite",
  "patient_foot_ankle_function_q48_a2": "Lichte moeite",
  "patient_foot_ankle_function_q48_a3": "Matige moeite",
  "patient_foot_ankle_function_q48_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q48_a5": "Niet van toepassing",
  "patient_foot_ankle_function_q49": "Kunt u de activiteit uitvoeren met uw normale techniek?",
  "patient_foot_ankle_function_q49_not_applicable": "Niet van toepassing",
  "patient_foot_ankle_function_q49_a1": "Geen moeite",
  "patient_foot_ankle_function_q49_a2": "Lichte moeite",
  "patient_foot_ankle_function_q49_a3": "Matige moeite",
  "patient_foot_ankle_function_q49_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q49_a5": "Niet van toepassing",
  "patient_foot_ankle_function_q50": "Kunt u deelnemen aan uw gewenste sport zolang u wilt?",
  "patient_foot_ankle_function_q50_not_applicable": "Niet van toepassing",
  "patient_foot_ankle_function_q50_a1": "Geen moeite",
  "patient_foot_ankle_function_q50_a2": "Lichte moeite",
  "patient_foot_ankle_function_q50_a3": "Matige moeite",
  "patient_foot_ankle_function_q50_a4": "Ernstige moeite",
  "patient_foot_ankle_function_q50_a5": "Niet in staat om te doen",
  "patient_foot_ankle_function_q51": "Hoe zou u uw ENKEL/VOET vandaag beoordelen als een percentage van normaal? (0 tot 100%, 100% is normaal)",
  "patient_foot_ankle_function_q51_a1": "Normaal",
  "patient_foot_ankle_function_q52": "Wat is het hoogste activiteitsniveau waaraan u op regelmatige basis kunt deelnemen?",
  "patient_foot_ankle_function_q52_q1": "Vóór de voet- of enkelblessure/probleem",
  "patient_foot_ankle_function_q52_q1_a1": "Topniveau",
  "patient_foot_ankle_function_q52_q1_a2": "Lagere competitieniveaus",
  "patient_foot_ankle_function_q52_q1_a3": "Recreatief",
  "patient_foot_ankle_function_q52_q1_a4": "Geen sportactiviteit",
  "patient_foot_ankle_function_q52_q1_a5": "Geen sporten, geen werk",
  "patient_foot_ankle_function_q52_q1_a4_o7": "Niet in staat om te lopen, gehandicapt door enkelproblemen",
  "patient_foot_ankle_function_q52_q1_a1_title": "Topniveau (internationaal elite, professioneel, nationaal team, of eerste divisie)",
  "patient_foot_ankle_function_q52_q1_a2_title": "Lagere competitieniveaus",
  "patient_foot_ankle_function_q52_q1_a3_title": "Recreatief",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o1": "Aerobics, fitness",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o2": "Alpineskiën en snowboarden",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o3": "American football",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o4": "Badminton",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o5": "Honkbal",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o6": "Basketbal",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o7": "Bowlen/curling",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_08": "Boksen",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o9": "Cross-country hardlopen",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o10": "Fietsen",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o11": "Dans",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o12": "Duiken",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o13": "Paardrijden",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o14": "Schermen",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o15": "Floorball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o16": "Freestyle snowboarden",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o17": "Golf",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o18": "Gymnastiek",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o19": "Handbal",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o20": "Hockey",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o21": "IJshockey",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o22": "Korfbal",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o23": "Vechtsporten",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o24": "Moderne vijfkamp",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o25": "Motorsport, technische sporten",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o26": "Berg- en heuvelbeklimmen",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o27": "Mountainbiken/bmx",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o28": "Nordic of cross country skiën",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o29": "Oriënteringsloop ",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o30": "Parachutespringen",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o31": "Gewichtheffen",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o32": "Ritmische gymnastiek",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o33": "Roeien, kajakken",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o34": "Rugby",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o35": "Zeilen",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o36": "Diepzeeduiken",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o37": "Schieten, boogschieten",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o38": "Schaatsen, inlineskaten",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o39": "Voetbal",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o40": "Softbal",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o41": "Squash",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o42": "Surfen, windsurfen",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o43": "Tafeltennis",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o44": "Tennis",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o45": "Atletiek: veldonderdelen",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o46": "Atletiek: looponderdelen",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o47": "Triatlon",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o48": "Volleybal",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o49": "Waterpolo en zwemmen",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o50": "Waterskiën",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o51": "Gewichtheffen, bodybuilding",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o52": "Worstelen",
  "patient_foot_ankle_function_q52_q1_a4_o1": "Zwaar lichamelijk werk",
  "patient_foot_ankle_function_q52_q1_a4_o2": "Lichamelijk werk",
  "patient_foot_ankle_function_q52_q1_a4_o3": "Speciale beroepen: balletdanser, professionele soldaat, speciale reddingswerker, stuntpersonage",
  "patient_foot_ankle_function_q52_q1_a4_o4": "In staat om op elk oneffen terrein te lopen",
  "patient_foot_ankle_function_q52_q1_a4_o5": "Alledaagse activiteiten niet beperkt",
  "patient_foot_ankle_function_q52_q1_a4_o6": "In staat om op een gelijkmatige ondergrond te lopen, maar alledaagse activiteiten beperkt",
  "patient_foot_ankle_function_q52_q2": "Huidige status",
  "patient_foot_ankle_function_q52_q2_a1": "Topniveau",
  "patient_foot_ankle_function_q52_q2_a2": "Lagere competitieniveaus",
  "patient_foot_ankle_function_q52_q2_a3": "Recreatief",
  "patient_foot_ankle_function_q52_q2_a4": "Geen sportactiviteit",
  "patient_foot_ankle_function_q52_q2_a5": "Geen sporten, geen werk",
  "patient_foot_ankle_function_q52_q2_a4_o7": "Niet in staat om te lopen, gehandicapt door enkelproblemen",
  "patient_foot_ankle_function_q52_q2_a1_title": "Topniveau (internationaal elite, professioneel, nationaal team, of eerste divisie)",
  "patient_foot_ankle_function_q52_q2_a2_title": "Lagere competitieniveaus",
  "patient_foot_ankle_function_q52_q2_a3_title": "Recreatief",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o1": "Aerobics, fitness",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o2": "Alpineskiën en snowboarden",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o3": "American football",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o4": "Badminton",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o5": "Honkbal",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o6": "Basketbal",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o7": "Bowlen/curling",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o8": "Boksen",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o9": "Cross-country hardlopen",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o10": "Fietsen",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o11": "Dans",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o12": "Duiken",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o13": "Paardrijden",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o14": "Schermen",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o15": "Floorball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o16": "Freestyle snowboarden",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o17": "Golf",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o18": "Gymnastiek",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o19": "Handbal",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o20": "Hockey",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o21": "IJshockey",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o22": "Korfbal",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o23": "Vechtsporten",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o24": "Moderne vijfkamp",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o25": "Motorsport, technische sporten",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o26": "Berg- en heuvelbeklimmen",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o27": "Mountainbiken/bmx",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o28": "Noordskiën",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o29": "Oriëntatielopen",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o30": "Parachutespringen",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o31": "Gewichtheffen",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o32": "Ritmische gymnastiek",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o33": "Roeien, kajakken",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o34": "Rugby",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o35": "Zeilen",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o36": "Diepzeeduiken",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o37": "Schieten, boogschieten",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o38": "Schaatsen, inlineskaten",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o39": "Voetbal",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o40": "Softbal",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o41": "Squash",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o42": "Surfen, windsurfen",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o43": "Tafeltennis",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o44": "Tennis",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o45": "Atletiek: veldonderdelen",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o46": "Atletiek: looponderdelen",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o47": "Triatlon",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o48": "Volleybal",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o49": "Waterpolo en zwemmen",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o50": "Water skiën",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o51": "Gewichtheffen, bodybuilding",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o52": "Worstelen",
  "patient_foot_ankle_function_q52_q2_a4_o1": "Zwaar lichamelijk werk",
  "patient_foot_ankle_function_q52_q2_a4_o2": "Lichamelijk werk",
  "patient_foot_ankle_function_q52_q2_a4_o3": "Speciale beroepen: balletdanser, professionele soldaat, speciale reddingswerker, stuntpersoon",
  "patient_foot_ankle_function_q52_q2_a4_o4": "In staat om op elk oneffen terrein te lopen",
  "patient_foot_ankle_function_q52_q2_a4_o5": "Geen beperkingen in dagelijkse activiteiten",
  "patient_foot_ankle_function_q52_q2_a4_o6": "In staat om op een gelijkmatige ondergrond te lopen, beperkingen in alle activiteiten",
  "patient_foot_ankle_quality_of_life": "Kwaliteit van leven",
  "patient_foot_ankle_quality_of_life_instructions": "De volgende vragen gaan over sociale, emotionele en levensstijlzorgen die u mogelijk ervaart met betrekking tot uw voet/enkelprobleem. Denk alstublieft aan hoe u zich meestal voelt met betrekking tot uw voet/enkel.",
  "patient_foot_ankle_quality_of_life_q1": "Hoe vaak bent u zich bewust van uw voet/enkelprobleem?",
  "patient_foot_ankle_quality_of_life_q1_a1": "Nooit",
  "patient_foot_ankle_quality_of_life_q1_a2": "Maandelijks",
  "patient_foot_ankle_quality_of_life_q1_a3": "Wekelijks",
  "patient_foot_ankle_quality_of_life_q1_a4": "Dagelijks",
  "patient_foot_ankle_quality_of_life_q1_a5": "Constant",
  "patient_foot_ankle_quality_of_life_q2": "Heeft u uw levensstijl aangepast om potentieel schadelijke activiteiten voor uw voet/enkel te vermijden?",
  "patient_foot_ankle_quality_of_life_q2_a1": "Helemaal niet",
  "patient_foot_ankle_quality_of_life_q2_a2": "Mild",
  "patient_foot_ankle_quality_of_life_q2_a3": "Matig",
  "patient_foot_ankle_quality_of_life_q2_a4": "Ernstig",
  "patient_foot_ankle_quality_of_life_q2_a5": "Volledig",
  "patient_foot_ankle_quality_of_life_q3": "Hoeveel last heeft u van het gebrek aan vertrouwen in uw voet/enkel?",
  "patient_foot_ankle_quality_of_life_q3_a1": "Helemaal niet",
  "patient_foot_ankle_quality_of_life_q3_a2": "Mild",
  "patient_foot_ankle_quality_of_life_q3_a3": "Matig",
  "patient_foot_ankle_quality_of_life_q3_a4": "Ernstig",
  "patient_foot_ankle_quality_of_life_q3_a5": "Extreem",
  "patient_foot_ankle_quality_of_life_q4": "Hoeveel moeite heeft u over het algemeen met uw voet/enkel?",
  "patient_foot_ankle_quality_of_life_q4_a1": "Geen",
  "patient_foot_ankle_quality_of_life_q4_a2": "Mild",
  "patient_foot_ankle_quality_of_life_q4_a3": "Matig",
  "patient_foot_ankle_quality_of_life_q4_a4": "Ernstig",
  "patient_foot_ankle_quality_of_life_q4_a5": "Extreem",
  "patient_foot_ankle_quality_of_life_q5": "Hoe angstig/gedeprimeerd voelt u zich vandaag?",
  "patient_foot_ankle_quality_of_life_q5_a1": "Niet angstig/gedeprimeerd",
  "patient_foot_ankle_quality_of_life_q5_a2": "Licht",
  "patient_foot_ankle_quality_of_life_q5_a3": "Matig",
  "patient_foot_ankle_quality_of_life_q5_a4": "Ernstig",
  "patient_foot_ankle_quality_of_life_q5_a5": "Extreem",
  "patient_foot_ankle_quality_of_life_q6": "Hoe goed/slecht is uw GEZONDHEID vandaag? (100 betekent de beste gezondheid, 0 betekent de slechtste gezondheid die u zich kunt voorstellen)",
  "patient_foot_ankle_quality_of_life_q6_a1": "Slechtste gezondheid",
  "patient_foot_ankle_quality_of_life_q6_a2": "Beste gezondheid",
  "patient_foot_ankle_satisfaction": "Tevredenheid",
  "patient_foot_ankle_satisfaction_instruction": "Hoe tevreden bent u met de resultaten van uw voet/enkeloperatie of behandeling?",
  "patient_foot_ankle_satisfaction_instruction_a1": "Zeer ontevreden",
  "patient_foot_ankle_satisfaction_instruction_a2": "Enigszins ontevreden",
  "patient_foot_ankle_satisfaction_instruction_a3": "Enigszins tevreden",
  "patient_foot_ankle_satisfaction_instruction_a4": "Zeer tevreden",
  "patient_foot_ankle_satisfaction_q1": "Voor het verlichten van symptomen?",
  "patient_foot_ankle_satisfaction_q1_not_applicable": "Niet van toepassing",
  "patient_foot_ankle_satisfaction_q1_a1": "Zeer ontevreden",
  "patient_foot_ankle_satisfaction_q1_a2": "Enigszins ontevreden",
  "patient_foot_ankle_satisfaction_q1_a3": "Enigszins tevreden",
  "patient_foot_ankle_satisfaction_q1_a4": "Zeer tevreden",
  "patient_foot_ankle_satisfaction_q2": "Voor het verbeteren van uw vermogen om dagelijkse activiteiten uit te voeren?",
  "patient_foot_ankle_satisfaction_q2_not_applicable": "Niet van toepassing",
  "patient_foot_ankle_satisfaction_q2_a1": "Zeer ontevreden",
  "patient_foot_ankle_satisfaction_q2_a2": "Enigszins ontevreden",
  "patient_foot_ankle_satisfaction_q2_a3": "Enigszins tevreden",
  "patient_foot_ankle_satisfaction_q2_a4": "Zeer tevreden",
  "patient_foot_ankle_satisfaction_q3": "Voor het \"vergeten\" van uw voet/enkelprobleem? (bewustzijn van voet/enkelprobleem)",
  "patient_foot_ankle_satisfaction_q3_not_applicable": "Niet van toepassing",
  "patient_foot_ankle_satisfaction_q3_a1": "Zeer ontevreden",
  "patient_foot_ankle_satisfaction_q3_a2": "Enigszins ontevreden",
  "patient_foot_ankle_satisfaction_q3_a3": "Enigszins tevreden",
  "patient_foot_ankle_satisfaction_q3_a4": "Zeer tevreden",
  "patient_foot_ankle_complete": "Einde van de vragenlijst",
  "patient_foot_ankle_complete_text1": "Dank u voor het invullen van dit formulier.",
  "patient_foot_ankle_complete_text2": "Klik op verzenden om te voltooien en uw scores te bekijken.",
  "patient_foot_ankle_complete_text3": "Uw antwoorden worden vertrouwelijk behandeld en eventuele rapporten zullen alleen gebruik maken van gedepersonaliseerde informatie.",
  "patient_foot_ankle_results": "Resultaten",
  "patient_foot_ankle_results_text1": "Hieronder staan uw pijn- en voet/enkelfunctiescores, die zijn berekend op basis van uw vragenlijst.",
  "patient_foot_ankle_results_pain": "Pijn",
  "patient_foot_ankle_results_function": "Functie",
  "patient_foot_ankle_results_overall_health_perception": "Algemene gezondheidsperceptie",
  "patient_foot_ankle_results_feeling_of_instability": "Gevoel van instabiliteit",
  "patient_foot_ankle_results_self_evaluation": "Zelfevaluatie voet/enkelbeoordeling",
  "patient_foot_ankle_results_text2": "Uw arts komt zo bij u.",
  "patient_elbow_pain": "Pijn",
  "patient_elbow_pain_q1": "Heeft u pijn in uw elleboog?",
  "patient_elbow_pain_q1_a1": "Nee",
  "patient_elbow_pain_q1_a2": "Ja",
  "patient_elbow_pain_q2": "Wat is de beste beschrijving van uw elleboogpijn?",
  "patient_elbow_pain_q2_a1": "Geen of genegeerd",
  "patient_elbow_pain_q2_a2": "Mild, met activiteit, af en toe medicatie/pijnstillers gebruikt",
  "patient_elbow_pain_q2_a3": "Matig, met of zonder activiteit, dagelijks pijnstillers gebruikt",
  "patient_elbow_pain_q2_a4": "Matige pijn in rust of 's nachts",
  "patient_elbow_pain_q2_a5": "Ernstige pijn in rust, constant medicatiegebruik, beperkend",
  "patient_elbow_pain_instruction": "Beoordeel uw PIJN in uw elleboog",
  "patient_elbow_pain_q3": "Op dit moment",
  "patient_elbow_pain_q3_a1": "Geen pijn",
  "patient_elbow_pain_q3_a2": "Ergste pijn, ondraaglijk",
  "patient_elbow_pain_q4": "Gewone pijn gedurende de afgelopen 4 weken",
  "patient_elbow_pain_q4_a1": "Geen pijn",
  "patient_elbow_pain_q4_a2": "Ergste pijn, ondraaglijk",
  "patient_elbow_pain_q5": "Wanneer het gedurende de afgelopen 4 weken op zijn ergst was",
  "patient_elbow_pain_q5_a1": "Geen pijn",
  "patient_elbow_pain_q5_a2": "Ergste pijn, ondraaglijk",
  "patient_elbow_pain_q6": "In rust",
  "patient_elbow_pain_q6_a1": "Geen pijn",
  "patient_elbow_pain_q6_a2": "Ergste pijn, ondraaglijk",
  "patient_elbow_pain_q7": "Het optillen van een zwaar voorwerp",
  "patient_elbow_pain_q7_a1": "Geen pijn",
  "patient_elbow_pain_q7_a2": "Ergste pijn, ondraaglijk",
  "patient_elbow_pain_q8": "Bij het uitvoeren van een taak met herhaalde elleboogbewegingen",
  "patient_elbow_pain_q8_a1": "Geen pijn",
  "patient_elbow_pain_q8_a2": "Ergste pijn, ondraaglijk",
  "patient_elbow_pain_q9": "s Nachts",
  "patient_elbow_pain_q9_a1": "Geen pijn",
  "patient_elbow_pain_q9_a2": "Ergste pijn, ondraaglijk",
  "patient_elbow_pain_q10": "Hoe vaak heeft u 's nachts last van elleboogpijn gehad?",
  "patient_elbow_pain_q10_a1": "Helemaal niet",
  "patient_elbow_pain_q10_a2": "1-2 nachten",
  "patient_elbow_pain_q10_a3": "Enkele nachten",
  "patient_elbow_pain_q10_a4": "De meeste nachten",
  "patient_elbow_pain_q10_a5": "Elke nacht",
  "patient_elbow_pain_q11": "Hoe vaak heeft uw elleboogpijn uw slaap verstoord?",
  "patient_elbow_pain_q11_a1": "Helemaal niet",
  "patient_elbow_pain_q11_a2": "Af en toe",
  "patient_elbow_pain_q11_a3": "Soms",
  "patient_elbow_pain_q11_a4": "Meestal",
  "patient_elbow_pain_q11_a5": "Altijd",
  "patient_elbow_function": "Functie",
  "patient_elbow_function_q1": "Wat is de beste beschrijving van uw elleboogfunctie?",
  "patient_elbow_function_q1_a1": "Geen beperkingen",
  "patient_elbow_function_q1_a2": "Lichte beperkingen, geen beperkingen in dagelijkse activiteiten",
  "patient_elbow_function_q1_a3": "Niet in staat om voorwerpen >4,5 kg op te tillen",
  "patient_elbow_function_q1_a4": "Matige beperkingen in dagelijkse activiteiten",
  "patient_elbow_function_q1_a5": "Niet in staat om haar te kammen of hoofd aan te raken",
  "patient_elbow_function_q1_a6": "Niet in staat om zichzelf te voeden",
  "patient_elbow_function_q2": "Wat is de beste beschrijving van de ACTIVITEIT die u kunt doen?",
  "patient_elbow_function_q2_a1": "Onbeperkt gebruik van elleboog",
  "patient_elbow_function_q2_a2": "Beperkt gebruik voor recreatie",
  "patient_elbow_function_q2_a3": "Beperkt tot huishoudelijke en beroepsactiviteiten",
  "patient_elbow_function_q2_a4": "In staat om voor zichzelf te zorgen",
  "patient_elbow_function_q2_a5": "Ongeldig",
  "patient_elbow_function_q3": "Hoe lang kunt u uw elleboog gebruiken?",
  "patient_elbow_function_q3_a1": "Kan activiteiten gedurende 30 minuten uitvoeren",
  "patient_elbow_function_q3_a2": "Kan activiteiten gedurende 15 minuten uitvoeren",
  "patient_elbow_function_q3_a3": "Kan activiteiten gedurende 5 minuten uitvoeren",
  "patient_elbow_function_q3_a4": "Kan elleboog niet gebruiken",
  "patient_elbow_function_instruction": "Geef de MATE VAN MOEILIJKHEID aan om de volgende activiteiten in de afgelopen week te doen vanwege uw elleboog",
  "patient_elbow_function_instruction_0": "Geen moeite",
  "patient_elbow_function_instruction_1": "Mild",
  "patient_elbow_function_instruction_2": "Matig",
  "patient_elbow_function_instruction_3": "Ernstig",
  "patient_elbow_function_instruction_4": "Niet in staat",
  "patient_elbow_function_q4": "Een strakke of nieuwe pot openen",
  "patient_elbow_function_q4_a1": "Geen moeite",
  "patient_elbow_function_q4_a2": "Mild",
  "patient_elbow_function_q4_a3": "Matig",
  "patient_elbow_function_q4_a4": "Ernstig",
  "patient_elbow_function_q4_a5": "Niet in staat",
  "patient_elbow_function_q5": "Een boodschappentas of aktetas dragen",
  "patient_elbow_function_q5_a1": "Geen moeite",
  "patient_elbow_function_q5_a2": "Mild",
  "patient_elbow_function_q5_a3": "Matig",
  "patient_elbow_function_q5_a4": "Ernstig",
  "patient_elbow_function_q5_a5": "Niet in staat",
  "patient_elbow_function_q6": "Dingen in uw huis optillen, zoals het buitenzetten van het afval",
  "patient_elbow_function_q6_a1": "Geen moeite",
  "patient_elbow_function_q6_a2": "Mild",
  "patient_elbow_function_q6_a3": "Matig",
  "patient_elbow_function_q6_a4": "Ernstig",
  "patient_elbow_function_q6_a5": "Niet in staat",
  "patient_elbow_function_q7": "Uw rug wassen",
  "patient_elbow_function_q7_a1": "Geen moeite",
  "patient_elbow_function_q7_a2": "Mild",
  "patient_elbow_function_q7_a3": "Matig",
  "patient_elbow_function_q7_a4": "Ernstig",
  "patient_elbow_function_q7_a5": "Niet in staat",
  "patient_elbow_function_q8": "Naar het toilet gaan",
  "patient_elbow_function_q8_a1": "Geen moeite",
  "patient_elbow_function_q8_a2": "Licht",
  "patient_elbow_function_q8_a3": "Matig",
  "patient_elbow_function_q8_a4": "Ernstig",
  "patient_elbow_function_q8_a5": "Niet in staat",
  "patient_elbow_function_q9": "Uzelf helemaal wassen",
  "patient_elbow_function_q9_a1": "Geen moeite",
  "patient_elbow_function_q9_a2": "Mild",
  "patient_elbow_function_q9_a3": "Matig",
  "patient_elbow_function_q9_a4": "Ernstig",
  "patient_elbow_function_q9_a5": "Niet in staat",
  "patient_elbow_function_q10": "Persoonlijke hygiëne uitvoeren",
  "patient_elbow_function_q10_a1": "Geen moeite",
  "patient_elbow_function_q10_a2": "Mild",
  "patient_elbow_function_q10_a3": "Matig",
  "patient_elbow_function_q10_a4": "Ernstig",
  "patient_elbow_function_q10_a5": "Niet in staat",
  "patient_elbow_function_q11": "Een mes gebruiken om voedsel te snijden",
  "patient_elbow_function_q11_a1": "Geen moeite",
  "patient_elbow_function_q11_a2": "Mild",
  "patient_elbow_function_q11_a3": "Matig",
  "patient_elbow_function_q11_a4": "Ernstig",
  "patient_elbow_function_q11_a5": "Niet in staat",
  "patient_elbow_function_q12": "Eten met bestek",
  "patient_elbow_function_q12_a1": "Geen moeite",
  "patient_elbow_function_q12_a2": "Mild",
  "patient_elbow_function_q12_a3": "Matig",
  "patient_elbow_function_q12_a4": "Ernstig",
  "patient_elbow_function_q12_a5": "Niet in staat",
  "patient_elbow_function_q13": "Recreatieve activiteiten waarbij u kracht of impact uitoefent via uw arm, schouder of hand (bijv. golf, hameren, tennis, enz.)",
  "patient_elbow_function_q13_a1": "Geen moeite",
  "patient_elbow_function_q13_a2": "Mild",
  "patient_elbow_function_q13_a3": "Matig",
  "patient_elbow_function_q13_a4": "Ernstig",
  "patient_elbow_function_q13_a5": "Niet in staat",
  "patient_elbow_function_q14": "Slapen vanwege pijn in uw arm, schouder of hand",
  "patient_elbow_function_q14_a1": "Geen moeite",
  "patient_elbow_function_q14_a2": "Mild",
  "patient_elbow_function_q14_a3": "Matig",
  "patient_elbow_function_q14_a4": "Ernstig",
  "patient_elbow_function_q14_a5": "Niet in staat",
  "patient_elbow_function_q15": "Een shirt aantrekken",
  "patient_elbow_function_q15_a1": "Geen moeite",
  "patient_elbow_function_q15_a2": "Mild",
  "patient_elbow_function_q15_a3": "Matig",
  "patient_elbow_function_q15_a4": "Ernstig",
  "patient_elbow_function_q15_a5": "Niet in staat",
  "patient_elbow_function_q16": "Het bovenste knoopje van een shirt vastmaken",
  "patient_elbow_function_q16_a1": "Geen moeite",
  "patient_elbow_function_q16_a2": "Mild",
  "patient_elbow_function_q16_a3": "Matig",
  "patient_elbow_function_q16_a4": "Ernstig",
  "patient_elbow_function_q16_a5": "Niet in staat",
  "patient_elbow_function_q17": "Uzelf aankleden",
  "patient_elbow_function_q17_a1": "Geen moeite",
  "patient_elbow_function_q17_a2": "Mild",
  "patient_elbow_function_q17_a3": "Matig",
  "patient_elbow_function_q17_a4": "Ernstig",
  "patient_elbow_function_q17_a5": "Niet in staat",
  "patient_elbow_function_q18": "Uw haar kammen",
  "patient_elbow_function_q18_a1": "Geen moeite",
  "patient_elbow_function_q18_a2": "Mild",
  "patient_elbow_function_q18_a3": "Matig",
  "patient_elbow_function_q18_a4": "Ernstig",
  "patient_elbow_function_q18_a5": "Niet in staat",
  "patient_elbow_function_q19": "Schoenen aantrekken",
  "patient_elbow_function_q19_a1": "Geen moeite",
  "patient_elbow_function_q19_a2": "Mild",
  "patient_elbow_function_q19_a3": "Matig",
  "patient_elbow_function_q19_a4": "Ernstig",
  "patient_elbow_function_q19_a5": "Niet in staat",
  "patient_elbow_function_q20": "Schoenen vastbinden",
  "patient_elbow_function_q20_a1": "Geen moeite",
  "patient_elbow_function_q20_a2": "Mild",
  "patient_elbow_function_q20_a3": "Matig",
  "patient_elbow_function_q20_a4": "Ernstig",
  "patient_elbow_function_q20_a5": "Niet in staat",
  "patient_elbow_function_q21": "Een zwaar voorwerp dragen",
  "patient_elbow_function_q21_a1": "Geen moeite",
  "patient_elbow_function_q21_a2": "Mild",
  "patient_elbow_function_q21_a3": "Matig",
  "patient_elbow_function_q21_a4": "Ernstig",
  "patient_elbow_function_q21_a5": "Niet in staat",
  "patient_elbow_function_q22": "Opstaan uit een stoel met de arm duwen",
  "patient_elbow_function_q22_a1": "Geen moeite",
  "patient_elbow_function_q22_a2": "Mild",
  "patient_elbow_function_q22_a3": "Matig",
  "patient_elbow_function_q22_a4": "Ernstig",
  "patient_elbow_function_q22_a5": "Niet in staat",
  "patient_elbow_function_q23": "Zware huishoudelijke taken uitvoeren (bijv. muren wassen, vloeren schrobben)",
  "patient_elbow_function_q23_a1": "Geen moeite",
  "patient_elbow_function_q23_a2": "Mild",
  "patient_elbow_function_q23_a3": "Matig",
  "patient_elbow_function_q23_a4": "Ernstig",
  "patient_elbow_function_q23_a5": "Niet in staat",
  "patient_elbow_function_q24": "Een sleutel omdraaien",
  "patient_elbow_function_q24_a1": "Geen moeite",
  "patient_elbow_function_q24_a2": "Mild",
  "patient_elbow_function_q24_a3": "Matig",
  "patient_elbow_function_q24_a4": "Ernstig",
  "patient_elbow_function_q24_a5": "Niet in staat",
  "patient_elbow_function_q25": "Een bal gooien",
  "patient_elbow_function_q25_a1": "Geen moeite",
  "patient_elbow_function_q25_a2": "Mild",
  "patient_elbow_function_q25_a3": "Matig",
  "patient_elbow_function_q25_a4": "Ernstig",
  "patient_elbow_function_q25_a5": "Niet in staat",
  "patient_elbow_function_q26": "Uw gebruikelijke werk doen vanwege pijn in uw arm, schouder of hand (werk omvat huishoudelijk werk als dat uw belangrijkste taak is)",
  "patient_elbow_function_q26_a1": "Geen moeite",
  "patient_elbow_function_q26_a2": "Mild",
  "patient_elbow_function_q26_a3": "Matig",
  "patient_elbow_function_q26_a4": "Ernstig",
  "patient_elbow_function_q26_a5": "Niet in staat",
  "patient_elbow_function_q27": "Specificeer uw gebruikelijke werk",
  "patient_elbow_function_q27_placeholder": "Specificeer werk",
  "patient_elbow_function_i_donot_work": "Ik werk niet",
  "patient_elbow_function_q28": "Uw gebruikelijke techniek gebruiken voor werk",
  "patient_elbow_function_q28_a1": "Geen moeite",
  "patient_elbow_function_q28_a2": "Mild",
  "patient_elbow_function_q28_a3": "Matig",
  "patient_elbow_function_q28_a4": "Ernstig",
  "patient_elbow_function_q28_a5": "Niet in staat",
  "patient_elbow_function_q29": "Uw werk doen zoals u zou willen",
  "patient_elbow_function_q29_a1": "Geen moeite",
  "patient_elbow_function_q29_a2": "Mild",
  "patient_elbow_function_q29_a3": "Matig",
  "patient_elbow_function_q29_a4": "Ernstig",
  "patient_elbow_function_q29_a5": "Niet in staat",
  "patient_elbow_function_q30": "Uw gebruikelijke hoeveelheid tijd besteden aan uw werk",
  "patient_elbow_function_q30_a1": "Geen moeite",
  "patient_elbow_function_q30_a2": "Mild",
  "patient_elbow_function_q30_a3": "Matig",
  "patient_elbow_function_q30_a4": "Ernstig",
  "patient_elbow_function_q30_a5": "Niet in staat",
  "patient_elbow_function_q31": "Uw muziekinstrument of sport spelen vanwege pijn in uw arm, schouder of hand",
  "patient_elbow_function_q31_a1": "Geen moeite",
  "patient_elbow_function_q31_a2": "Mild",
  "patient_elbow_function_q31_a3": "Matig",
  "patient_elbow_function_q31_a4": "Ernstig",
  "patient_elbow_function_q31_a5": "Niet in staat",
  "patient_elbow_function_q32": "Specificeer de sport of het muziekinstrument dat u bespeelt",
  "patient_elbow_function_q32_placeholder": "Specificeer sport/muziekinstrument",
  "patient_elbow_function_i_donot_play": "Ik speel geen sport of instrument",
  "patient_elbow_function_q33": "Uw gebruikelijke techniek gebruiken voor het bespelen van uw instrument of sport",
  "patient_elbow_function_q33_a1": "Geen moeite",
  "patient_elbow_function_q33_a2": "Mild",
  "patient_elbow_function_q33_a3": "Matig",
  "patient_elbow_function_q33_a4": "Ernstig",
  "patient_elbow_function_q33_a5": "Niet in staat",
  "patient_elbow_function_q34": "Uw muziekinstrument of sport spelen zoals u zou willen",
  "patient_elbow_function_q34_a1": "Geen moeite",
  "patient_elbow_function_q34_a2": "Mild",
  "patient_elbow_function_q34_a3": "Matig",
  "patient_elbow_function_q34_a4": "Ernstig",
  "patient_elbow_function_q34_a5": "Niet in staat",
  "patient_elbow_function_q35": "Tijd besteden aan het beoefenen van uw instrument of sport zoals gewoonlijk",
  "patient_elbow_function_q35_a1": "Geen moeite",
  "patient_elbow_function_q35_a2": "Mild",
  "patient_elbow_function_q35_a3": "Matig",
  "patient_elbow_function_q35_a4": "Ernstig",
  "patient_elbow_function_q35_a5": "Niet in staat",
  "patient_elbow_function_q36": "In de afgelopen week, in hoeverre heeft uw probleem met uw arm, schouder of hand uw NORMALE SOCIALE/VRIJETIJDSACTIVITEITEN beïnvloed met familie, vrienden, buren of groepen?",
  "patient_elbow_function_q36_a1": "Helemaal niet",
  "patient_elbow_function_q36_a2": "Een beetje",
  "patient_elbow_function_q36_a3": "Matig",
  "patient_elbow_function_q36_a4": "Redelijk veel",
  "patient_elbow_function_q36_a5": "Extreem",
  "patient_elbow_function_q37": "In de afgelopen week, was u beperkt in uw WERK OF ANDERE DAGELIJKSE ACTIVITEITEN als gevolg van uw arm, schouder of hand probleem?",
  "patient_elbow_function_q37_a1": "Helemaal niet",
  "patient_elbow_function_q37_a2": "Een beetje",
  "patient_elbow_function_q37_a3": "Matig",
  "patient_elbow_function_q37_a4": "Redelijk veel",
  "patient_elbow_function_q37_a5": "Extreem",
  "patient_elbow_symptoms": "Andere Symptomen",
  "patient_elbow_symptoms_instruction": "Beoordeel de ernst van de volgende symptomen in de afgelopen week:",
  "patient_elbow_symptoms_instruction_0": "Geen",
  "patient_elbow_symptoms_instruction_1": "Mild",
  "patient_elbow_symptoms_instruction_2": "Matig",
  "patient_elbow_symptoms_instruction_3": "Ernstig",
  "patient_elbow_symptoms_instruction_4": "Extreem",
  "patient_elbow_symptoms_q1": "Pijn in de arm, schouder of hand",
  "patient_elbow_symptoms_q1_a1": "Geen",
  "patient_elbow_symptoms_q1_a2": "Mild",
  "patient_elbow_symptoms_q1_a3": "Matig",
  "patient_elbow_symptoms_q1_a4": "Ernstig",
  "patient_elbow_symptoms_q1_a5": "Extreem",
  "patient_elbow_symptoms_q2": "Tintelend gevoel (spelden en naalden) in uw arm, schouder of hand",
  "patient_elbow_symptoms_q2_a1": "Geen",
  "patient_elbow_symptoms_q2_a2": "Mild",
  "patient_elbow_symptoms_q2_a3": "Matig",
  "patient_elbow_symptoms_q2_a4": "Ernstig",
  "patient_elbow_symptoms_q2_a5": "Extreem",
  "patient_elbow_symptoms_q3": "Hoe zou u uw elleboog vandaag beoordelen als een percentage van normaal? (0-100% waarbij 100% normaal is)",
  "patient_elbow_quality_of_life": "Kwaliteit van Leven",
  "patient_elbow_quality_of_life_instruction": "De volgende vragen gaan over sociale, emotionele en levensstijlzorgen die u mogelijk ervaart met betrekking tot uw elleboogprobleem. Denk aan hoe u zich meestal hebt gevoeld met betrekking tot uw elleboog.",
  "patient_elbow_quality_of_life_q1": "In de afgelopen 4 weken, heeft u het gevoel gehad dat uw elleboogprobleem \"uw leven beheerst\"?",
  "patient_elbow_quality_of_life_q1_a1": "Helemaal niet",
  "patient_elbow_quality_of_life_q1_a2": "Af en toe",
  "patient_elbow_quality_of_life_q1_a3": "Enkele dagen",
  "patient_elbow_quality_of_life_q1_a4": "De meeste dagen",
  "patient_elbow_quality_of_life_q1_a5": "Elke dag",
  "patient_elbow_quality_of_life_q2": "In de afgelopen 4 weken, hoeveel heeft uw elleboog \"in uw gedachten\" gezeten?",
  "patient_elbow_quality_of_life_q2_a1": "Helemaal niet",
  "patient_elbow_quality_of_life_q2_a2": "Af en toe",
  "patient_elbow_quality_of_life_q2_a3": "Enkele keren",
  "patient_elbow_quality_of_life_q2_a4": "Meestal",
  "patient_elbow_quality_of_life_q2_a5": "Altijd",
  "patient_elbow_quality_of_life_instruction1": "Beoordeel het volgende om uw GEZONDHEID VANDAAG het beste te beschrijven:",
  "patient_elbow_quality_of_life_instruction1_0": "Geen probleem",
  "patient_elbow_quality_of_life_instruction1_1": "Licht",
  "patient_elbow_quality_of_life_instruction1_2": "Matig",
  "patient_elbow_quality_of_life_instruction1_3": "Ernstig",
  "patient_elbow_quality_of_life_instruction1_4": "Extreem/niet in staat",
  "patient_elbow_quality_of_life_q3": "Probleem met lopen",
  "patient_elbow_quality_of_life_q3_a1": "Geen probleem",
  "patient_elbow_quality_of_life_q3_a2": "Licht",
  "patient_elbow_quality_of_life_q3_a3": "Matig",
  "patient_elbow_quality_of_life_q3_a4": "Ernstig",
  "patient_elbow_quality_of_life_q3_a5": "Niet in staat",
  "patient_elbow_quality_of_life_q4": "Probleem met wassen of aankleden",
  "patient_elbow_quality_of_life_q4_a1": "Geen probleem",
  "patient_elbow_quality_of_life_q4_a2": "Licht",
  "patient_elbow_quality_of_life_q4_a3": "Matig",
  "patient_elbow_quality_of_life_q4_a4": "Ernstig",
  "patient_elbow_quality_of_life_q4_a5": "Niet in staat",
  "patient_elbow_quality_of_life_q5": "Probleem met het uitvoeren van gebruikelijke activiteiten (bijv. werk, studie, huishouden, gezin of vrijetijdsactiviteiten)",
  "patient_elbow_quality_of_life_q5_a1": "Geen probleem",
  "patient_elbow_quality_of_life_q5_a2": "Licht",
  "patient_elbow_quality_of_life_q5_a3": "Matig",
  "patient_elbow_quality_of_life_q5_a4": "Ernstig",
  "patient_elbow_quality_of_life_q5_a5": "Niet in staat",
  "patient_elbow_quality_of_life_q6": "Pijn of ongemak",
  "patient_elbow_quality_of_life_q6_a1": "Geen",
  "patient_elbow_quality_of_life_q6_a2": "Licht",
  "patient_elbow_quality_of_life_q6_a3": "Matig",
  "patient_elbow_quality_of_life_q6_a4": "Ernstig",
  "patient_elbow_quality_of_life_q6_a5": "Extreem",
  "patient_elbow_quality_of_life_q7": "Angst/Depressie",
  "patient_elbow_quality_of_life_q7_a1": "Niet angstig/depressief",
  "patient_elbow_quality_of_life_q7_a2": "Licht angstig/depressief",
  "patient_elbow_quality_of_life_q7_a3": "Matig angstig/depressief",
  "patient_elbow_quality_of_life_q7_a4": "Ernstig angstig/depressief",
  "patient_elbow_quality_of_life_q7_a5": "Extreem angstig/depressief",
  "patient_elbow_quality_of_life_q8": "Hoe goed/slecht is uw gezondheid VANDAAG? ",
  "patient_elbow_quality_of_life_q8_instruction": "100 betekent de beste gezondheid, 0 betekent de slechtste gezondheid die u zich kunt voorstellen)",
  "patient_elbow_quality_of_life_q8_a1": "Slechtst",
  "patient_elbow_quality_of_life_q8_a2": "Beste",
  "patient_elbow_satisfaction": "Tevredenheid",
  "patient_elbow_satisfaction_q1": "Bent u tevreden met de behandeling voor uw elleboog?",
  "patient_elbow_satisfaction_na": "Niet van toepassing",
  "patient_elbow_satisfaction_q1_a1": "Helemaal niet tevreden",
  "patient_elbow_satisfaction_q1_a2": "Zeer tevreden",
  "patient_elbow_complete": "Einde van de vragenlijst",
  "patient_elbow_complete_text1": "Bedankt voor de tijd die u heeft genomen om dit formulier in te vullen.",
  "patient_elbow_complete_text2": "Klik op verzenden om te voltooien en uw scores te bekijken.",
  "patient_elbow_complete_text3": "Uw antwoorden worden vertrouwelijk behandeld en eventuele rapporten zullen alleen geanonimiseerde informatie gebruiken.",
  "patient_elbow_results": "Resultaten",
  "patient_elbow_results_text1": "Hieronder staan uw pijn- en elleboogfunctiescores, die zijn berekend op basis van uw vragenlijst.",
  "patient_elbow_results_function": "Functie",
  "patient_elbow_results_pain": "Pijn",
  "patient_elbow_results_health_perception": "Algehele Gezondheidsperceptie",
  "patient_elbow_results_self_evaluation": "Elleboogscoringszelfevaluatie",
  "patient_elbow_results_text4": "Uw arts zal binnenkort bij u zijn.",
  "patient_hand_pain": "Pijn",
  "patient_hand_pain_q1": "Beschrijf de pijn in uw hand/pols van de AFGELOPEN WEEK",
  "patient_hand_pain_q1_a1": "Geen tot zeer lichte pijn",
  "patient_hand_pain_q1_a2": "Licht",
  "patient_hand_pain_q1_a3": "Matig",
  "patient_hand_pain_q1_a4": "Ernstig",
  "patient_hand_pain_q1_a5": "Zeer ernstig/extreem",
  "patient_hand_pain_q2": "Hoe erg is de pijn in uw hand/pols OP DIT MOMENT?",
  "patient_hand_pain_q2_a1": "Geen pijn",
  "patient_hand_pain_q2_a2": "Ergste pijn",
  "patient_hand_pain_q3": "Hoe vaak heeft de pijn in uw hand/pols uw dagelijkse activiteiten (zoals eten of douchen) in de afgelopen week verstoord?",
  "patient_hand_pain_q3_a1": "Nooit",
  "patient_hand_pain_q3_a2": "Zelden",
  "patient_hand_pain_q3_a3": "Soms",
  "patient_hand_pain_q3_a4": "Vaak",
  "patient_hand_pain_q3_a5": "Altijd",
  "patient_hand_symptoms": "Andere Symptomen",
  "patient_hand_symptoms_q1": "Hoe ernstig was het tintelende (spelden en naalden) gevoel in uw arm, schouder of hand in de afgelopen week?",
  "patient_hand_symptoms_q1_a1": "Geen",
  "patient_hand_symptoms_q1_a2": "Licht",
  "patient_hand_symptoms_q1_a3": "Matig",
  "patient_hand_symptoms_q1_a4": "Ernstig",
  "patient_hand_symptoms_q1_a5": "Extreem",
  "patient_hand_symptoms_q2": "Hoe was het gevoel in uw hand gedurende de afgelopen week?",
  "patient_hand_symptoms_q2_a1": "Zeer goed",
  "patient_hand_symptoms_q2_a2": "Goed",
  "patient_hand_symptoms_q2_a3": "Redelijk",
  "patient_hand_symptoms_q2_a4": "Slecht",
  "patient_hand_symptoms_q2_a5": "Zeer slecht",
  "patient_hand_symptoms_q3": "Het uiterlijk van mijn hand bemoeit zich met mijn normale dagelijkse activiteiten",
  "patient_hand_symptoms_q3_a1": "Sterk oneens",
  "patient_hand_symptoms_q3_a2": "Oneens",
  "patient_hand_symptoms_q3_a3": "Noch eens, noch oneens",
  "patient_hand_symptoms_q3_a4": "Mee eens",
  "patient_hand_symptoms_q3_a5": "Helemaal mee eens",
  "patient_hand_symptoms_q3_a6": "Hoe zou u uw hand/pols vandaag beoordelen als een percentage van normaal? (0-100% waarbij 100% normaal is)",
  "patient_hand_symptoms_q3_a6_normal": "Normaal",
  "patient_hand_function": "Functie",
  "patient_hand_function_instruction1": "Als u een bepaalde taak niet uitvoert, schat dan de moeilijkheid in die u zou hebben om deze uit te voeren.",
  "patient_hand_function_instruction2": "Als u niet zeker weet hoe u een vraag moet beantwoorden, geef dan het beste antwoord dat u kunt.",
  "patient_hand_function_q1": "Over het algemeen, hoe goed heeft uw hand gewerkt gedurende de afgelopen week?",
  "patient_hand_function_q1_a1": "Zeer goed",
  "patient_hand_function_q1_a2": "Goed",
  "patient_hand_function_q1_a3": "Redelijk",
  "patient_hand_function_q1_a4": "Slecht",
  "patient_hand_function_q1_a5": "Zeer slecht",
  "patient_hand_function_q2": "Hoe vaak KON U UW WERK NIET DOEN vanwege problemen met uw hand/pols?",
  "patient_hand_function_q2_a1": "Nooit",
  "patient_hand_function_q2_a2": "Zelden",
  "patient_hand_function_q2_a3": "Soms",
  "patient_hand_function_q2_a4": "Vaak",
  "patient_hand_function_q2_a5": "Altijd",
  "patient_hand_function_q3": "Hoe vaak duurde het LANGER OM TAKEN UIT TE VOEREN in uw werk vanwege problemen met uw hand/pols?",
  "patient_hand_function_q3_a1": "Nooit",
  "patient_hand_function_q3_a2": "Zelden",
  "patient_hand_function_q3_a3": "Soms",
  "patient_hand_function_q3_a4": "Vaak",
  "patient_hand_function_q3_a5": "Altijd",
  "patient_hand_function_instruction": "Geef de MATE VAN MOEILIJKHEID aan om de volgende activiteiten in de afgelopen week uit te voeren vanwege problemen met uw hand/pols:",
  "patient_hand_function_instruction_0": "Geen moeite",
  "patient_hand_function_instruction_1": "Lichte moeite",
  "patient_hand_function_instruction_2": "Matige moeite",
  "patient_hand_function_instruction_3": "Ernstige moeite",
  "patient_hand_function_instruction_4": "Ernstige moeite/onmogelijk",
  "patient_hand_function_q4": "Een strak of nieuw potje openen",
  "patient_hand_function_q4_a1": "Geen moeite",
  "patient_hand_function_q4_a2": "Lichte moeite",
  "patient_hand_function_q4_a3": "Matige moeite",
  "patient_hand_function_q4_a4": "Ernstige moeite",
  "patient_hand_function_q4_a5": "Ernstige moeite/onmogelijk",
  "patient_hand_function_q5": "Een koekenpan vasthouden",
  "patient_hand_function_q5_a1": "Geen moeite",
  "patient_hand_function_q5_a2": "Lichte moeite",
  "patient_hand_function_q5_a3": "Matige moeite",
  "patient_hand_function_q5_a4": "Ernstige moeite",
  "patient_hand_function_q5_a5": "Ernstige moeite/onmogelijk",
  "patient_hand_function_q6": "Een shirt of blouse dichtknopen",
  "patient_hand_function_q6_a1": "Geen moeite",
  "patient_hand_function_q6_a2": "Lichte moeite",
  "patient_hand_function_q6_a3": "Matige moeite",
  "patient_hand_function_q6_a4": "Ernstige moeite",
  "patient_hand_function_q6_a5": "Ernstige moeite/onmogelijk",
  "patient_hand_function_q7": "Zware huishoudelijke taken uitvoeren (bijv. muren wassen, vloeren)",
  "patient_hand_function_q7_a1": "Geen moeite",
  "patient_hand_function_q7_a2": "Lichte moeite",
  "patient_hand_function_q7_a3": "Matige moeite",
  "patient_hand_function_q7_a4": "Ernstige moeite",
  "patient_hand_function_q7_a5": "Ernstige moeite/onmogelijk",
  "patient_hand_function_q8": "Een boodschappentas of aktetas dragen",
  "patient_hand_function_q8_a1": "Geen moeite",
  "patient_hand_function_q8_a2": "Lichte moeite",
  "patient_hand_function_q8_a3": "Matige moeite",
  "patient_hand_function_q8_a4": "Ernstige moeite",
  "patient_hand_function_q8_a5": "Ernstige moeite/onmogelijk",
  "patient_hand_function_q9": "Uw rug wassen",
  "patient_hand_function_q9_a1": "Geen moeite",
  "patient_hand_function_q9_a2": "Lichte moeite",
  "patient_hand_function_q9_a3": "Matige moeite",
  "patient_hand_function_q9_a4": "Ernstige moeite",
  "patient_hand_function_q9_a5": "Ernstige moeite/onmogelijk",
  "patient_hand_function_q10": "Een mes gebruiken om voedsel te snijden",
  "patient_hand_function_q10_a1": "Geen moeite",
  "patient_hand_function_q10_a2": "Lichte moeite",
  "patient_hand_function_q10_a3": "Matige moeite",
  "patient_hand_function_q10_a4": "Ernstige moeite",
  "patient_hand_function_q10_a5": "Ernstige moeite/onmogelijk",
  "patient_hand_function_q11": "Recreatieve activiteiten waarbij u kracht of impact op uw arm, schouder of hand uitoefent (bijv. golf, timmeren, tennis, enz.)",
  "patient_hand_function_q11_a1": "Geen moeite",
  "patient_hand_function_q11_a2": "Lichte moeite",
  "patient_hand_function_q11_a3": "Matige moeite",
  "patient_hand_function_q11_a4": "Ernstige moeite",
  "patient_hand_function_q11_a5": "Ernstige moeite/onmogelijk",
  "patient_hand_function_q12": "Slapen vanwege pijn in uw arm, schouder of hand",
  "patient_hand_function_q12_a1": "Geen moeite",
  "patient_hand_function_q12_a2": "Lichte moeite",
  "patient_hand_function_q12_a3": "Matige moeite",
  "patient_hand_function_q12_a4": "Ernstige moeite",
  "patient_hand_function_q12_a5": "Ernstige moeite/onmogelijk",
  "patient_hand_function_q13": "Uw gebruikelijke werk doen vanwege pijn in uw arm, schouder of hand (werk omvat huishoudelijk werk als dat uw belangrijkste rol is)",
  "patient_hand_function_q13_a1": "Geen moeite",
  "patient_hand_function_q13_a2": "Lichte moeite",
  "patient_hand_function_q13_a3": "Matige moeite",
  "patient_hand_function_q13_a4": "Ernstige moeite",
  "patient_hand_function_q13_a5": "Ernstige moeite/onmogelijk",
  "patient_hand_function_q14": "Specificeer uw gebruikelijke werk",
  "patient_hand_function_q14_placeholder": "Specificeer werk",
  "patient_hand_function_do_dot_work": "Ik werk niet",
  "patient_hand_function_q15": "Uw gebruikelijke techniek gebruiken om te werken",
  "patient_hand_function_q15_a1": "Geen moeite",
  "patient_hand_function_q15_a2": "Lichte moeite",
  "patient_hand_function_q15_a3": "Matige moeite",
  "patient_hand_function_q15_a4": "Ernstige moeite",
  "patient_hand_function_q15_a5": "Ernstige moeite/onmogelijk",
  "patient_hand_function_q16": "Uw werk zo goed doen zoals u wilt",
  "patient_hand_function_q16_a1": "Geen moeite",
  "patient_hand_function_q16_a2": "Lichte moeite",
  "patient_hand_function_q16_a3": "Matige moeite",
  "patient_hand_function_q16_a4": "Ernstige moeite",
  "patient_hand_function_q16_a5": "Ernstige moeite/onmogelijk",
  "patient_hand_function_q17": "Uw gebruikelijke hoeveelheid tijd besteden aan oefenen of uw instrument of sport spelen",
  "patient_hand_function_q17_a1": "Geen moeite",
  "patient_hand_function_q17_a2": "Lichte moeite",
  "patient_hand_function_q17_a3": "Matige moeite",
  "patient_hand_function_q17_a4": "Ernstige moeite",
  "patient_hand_function_q17_a5": "Ernstige moeite/onmogelijk",
  "patient_hand_function_q18": "Het bespelen van uw muziekinstrument of sport vanwege pijn in uw arm, schouder of hand",
  "patient_hand_function_q18_a1": "Geen moeite",
  "patient_hand_function_q18_a2": "Lichte moeite",
  "patient_hand_function_q18_a3": "Matige moeite",
  "patient_hand_function_q18_a4": "Ernstige moeite",
  "patient_hand_function_q18_a5": "Ernstige moeite/onmogelijk",
  "patient_hand_function_q19": "Specificeer sport of muziekinstrument bespeeld",
  "patient_hand_function_q19_placeholder": "Specificeer sport/muziekinstrument",
  "patient_hand_function_donot_play": "Ik speel geen sport of instrument",
  "patient_hand_function_20": "Het gebruik van uw gebruikelijke techniek voor het bespelen van uw instrument of sport",
  "patient_hand_function_q20_a1": "Geen moeite",
  "patient_hand_function_q20_a2": "Lichte moeite",
  "patient_hand_function_q20_a3": "Matige moeite",
  "patient_hand_function_q20_a4": "Ernstige moeite",
  "patient_hand_function_q20_a5": "Ernstige moeite/onmogelijk",
  "patient_hand_function_q21": "Het bespelen van uw muziekinstrument of sport zoals u zou willen",
  "patient_hand_function_q21_a1": "Geen moeite",
  "patient_hand_function_q21_a2": "Lichte moeite",
  "patient_hand_function_q21_a3": "Matige moeite",
  "patient_hand_function_q21_a4": "Ernstige moeite",
  "patient_hand_function_q21_a5": "Ernstige moeite/onmogelijk",
  "patient_hand_function_q22": "Het besteden van uw gebruikelijke hoeveelheid tijd aan oefenen of het bespelen van uw instrument of sport",
  "patient_hand_function_q22_a1": "Geen moeite",
  "patient_hand_function_q22_a2": "Lichte moeite",
  "patient_hand_function_q22_a3": "Matige moeite",
  "patient_hand_function_q22_a4": "Ernstige moeite",
  "patient_hand_function_q22_a5": "Ernstige moeite/onmogelijk",
  "patient_hand_function_q23": "In de afgelopen week, in hoeverre heeft uw arm, schouder of handprobleem uw NORMALE SOCIALE/VRIJETIJDSACTIVITEITEN met familie, vrienden, buren of groepen verstoord?",
  "patient_hand_function_q23_a1": "Helemaal niet",
  "patient_hand_function_q23_a2": "Een beetje",
  "patient_hand_function_q23_a3": "Matig",
  "patient_hand_function_q23_a4": "Aardig wat",
  "patient_hand_function_q23_a5": "Extreem",
  "patient_hand_function_q24": "In de afgelopen week, was u beperkt in uw WERK OF ANDERE DAGELIJKSE ACTIVITEITEN als gevolg van uw arm, schouder of handprobleem?",
  "patient_hand_function_q24_a1": "Helemaal niet",
  "patient_hand_function_q24_a2": "Een beetje",
  "patient_hand_function_q24_a3": "Matig",
  "patient_hand_function_q24_a4": "Aardig wat",
  "patient_hand_function_q24_a5": "Extreem",
  "patient_hand_questionnaire": "Boston Carpaal Tunnel Syndroom Vragenlijst",
  "patient_hand_questionnaire_symptom": "Symptoom",
  "patient_hand_questionnaire_symptom_instruction": "Kies het antwoord dat het beste overeenkomt met uw symptomen.",
  "patient_hand_questionnaire_symptom_instruction_0": "normaal",
  "patient_hand_questionnaire_symptom_instruction_1": "licht",
  "patient_hand_questionnaire_symptom_instruction_2": "gemiddeld",
  "patient_hand_questionnaire_symptom_instruction_3": "ernstig",
  "patient_hand_questionnaire_symptom_instruction_4": "zeer ernstig",
  "patient_hand_questionnaire_symptom_q1": "Hoe ernstig is de pijn in uw hand of pols 's nachts?",
  "patient_hand_questionnaire_symptom_q1_a1": "Normaal",
  "patient_hand_questionnaire_symptom_q1_a2": "Licht",
  "patient_hand_questionnaire_symptom_q1_a3": "Gemiddeld",
  "patient_hand_questionnaire_symptom_q1_a4": "Ernstig",
  "patient_hand_questionnaire_symptom_q1_a5": "Zeer ernstig",
  "patient_hand_questionnaire_symptom_q2": "Hoe vaak heeft de pijn in uw hand of pols u 's nachts wakker gemaakt tijdens een typische nacht in de afgelopen 2 weken?",
  "patient_hand_questionnaire_symptom_q2_a1": "Normaal",
  "patient_hand_questionnaire_symptom_q2_a2": "Eenmaal",
  "patient_hand_questionnaire_symptom_q2_a3": "2-3 keer",
  "patient_hand_questionnaire_symptom_q2_a4": "4-5 keer",
  "patient_hand_questionnaire_symptom_q2_a5": "Meer dan 5 keer",
  "patient_hand_questionnaire_symptom_q3": "Heeft u meestal pijn in uw hand of pols overdag?",
  "patient_hand_questionnaire_symptom_q3_a1": "Geen pijn",
  "patient_hand_questionnaire_symptom_q3_a2": "Licht",
  "patient_hand_questionnaire_symptom_q3_a3": "Gemiddeld",
  "patient_hand_questionnaire_symptom_q3_a4": "Ernstig",
  "patient_hand_questionnaire_symptom_q3_a5": "Zeer ernstig",
  "patient_hand_questionnaire_symptom_q4": "Hoe vaak heeft u overdag pijn in uw hand of pols?",
  "patient_hand_questionnaire_symptom_q4_a1": "Normaal",
  "patient_hand_questionnaire_symptom_q4_a2": "1-2 keer per dag",
  "patient_hand_questionnaire_symptom_q4_a3": "3-5 keer per dag",
  "patient_hand_questionnaire_symptom_q4_a4": "Meer dan 5 keer per dag",
  "patient_hand_questionnaire_symptom_q4_a5": "Vervolg",
  "patient_hand_questionnaire_symptom_q5": "Hoe lang duurt een episode van pijn gemiddeld tijdens de dag?",
  "patient_hand_questionnaire_symptom_q5_a1": "Normaal",
  "patient_hand_questionnaire_symptom_q5_a2": "<10 minuten",
  "patient_hand_questionnaire_symptom_q5_a3": "10-60 minuten",
  "patient_hand_questionnaire_symptom_q5_a4": ">60 minuten",
  "patient_hand_questionnaire_symptom_q5_a5": "Vervolg",
  "patient_hand_questionnaire_symptom_q6": "Heeft u gevoelloosheid (verlies van sensatie) in uw hand?",
  "patient_hand_questionnaire_symptom_q6_a1": "Normaal",
  "patient_hand_questionnaire_symptom_q6_a2": "Licht",
  "patient_hand_questionnaire_symptom_q6_a3": "Gemiddeld",
  "patient_hand_questionnaire_symptom_q6_a4": "Ernstig",
  "patient_hand_questionnaire_symptom_q6_a5": "Zeer ernstig",
  "patient_hand_questionnaire_symptom_q7": "Heeft u zwakte in uw hand of pols?",
  "patient_hand_questionnaire_symptom_q7_a1": "Normaal",
  "patient_hand_questionnaire_symptom_q7_a2": "Licht",
  "patient_hand_questionnaire_symptom_q7_a3": "Gemiddeld",
  "patient_hand_questionnaire_symptom_q7_a4": "Ernstig",
  "patient_hand_questionnaire_symptom_q7_a5": "Zeer ernstig",
  "patient_hand_questionnaire_symptom_q8": "Heeft u een tintelend gevoel in uw hand?",
  "patient_hand_questionnaire_symptom_q8_a1": "Normaal",
  "patient_hand_questionnaire_symptom_q8_a2": "Licht",
  "patient_hand_questionnaire_symptom_q8_a3": "Gemiddeld",
  "patient_hand_questionnaire_symptom_q8_a4": "Ernstig",
  "patient_hand_questionnaire_symptom_q8_a5": "Zeer ernstig",
  "patient_hand_questionnaire_symptom_q9": "Hoe ernstig is de gevoelloosheid (verlies van sensatie) of tinteling 's nachts?",
  "patient_hand_questionnaire_symptom_q9_a1": "Normaal",
  "patient_hand_questionnaire_symptom_q9_a2": "Licht",
  "patient_hand_questionnaire_symptom_q9_a3": "Gemiddeld",
  "patient_hand_questionnaire_symptom_q9_a4": "Ernstig",
  "patient_hand_questionnaire_symptom_q9_a5": "Zeer ernstig",
  "patient_hand_questionnaire_symptom_q10": "Hoe vaak heeft gevoelloosheid of tinteling in uw hand u wakker gemaakt tijdens een typische nacht in de afgelopen 2 weken?",
  "patient_hand_questionnaire_symptom_q10_a1": "Normaal",
  "patient_hand_questionnaire_symptom_q10_a2": "Eenmaal",
  "patient_hand_questionnaire_symptom_q10_a3": "2-3 keer",
  "patient_hand_questionnaire_symptom_q10_a4": "4-5 keer",
  "patient_hand_questionnaire_symptom_q10_a5": "Meer dan 5 keer",
  "patient_hand_questionnaire_function": "Functie",
  "patient_hand_questionnaire_function_instructions": "Beoordeel de mate van moeilijkheid bij het uitvoeren van de volgende activiteiten als gevolg van uw symptomen:",
  "patient_hand_questionnaire_function_instructions_0": "Geen moeilijkheid",
  "patient_hand_questionnaire_function_instructions_1": "Kleine moeilijkheid",
  "patient_hand_questionnaire_function_instructions_2": "Matige moeilijkheid",
  "patient_hand_questionnaire_function_instructions_3": "Ernstige moeilijkheid",
  "patient_hand_questionnaire_function_instructions_4": "Kan helemaal niet uitvoeren",
  "patient_hand_questionnaire_function_q1": "Moeilijkheid bij het vasthouden en gebruiken van kleine objecten zoals sleutels of pennen",
  "patient_hand_questionnaire_function_q1_a1": "Geen moeilijkheid",
  "patient_hand_questionnaire_function_q1_a2": "Kleine moeilijkheid",
  "patient_hand_questionnaire_function_q1_a3": "Matige moeilijkheid",
  "patient_hand_questionnaire_function_q1_a4": "Ernstige moeilijkheid",
  "patient_hand_questionnaire_function_q1_a5": "Kan helemaal niet uitvoeren",
  "patient_hand_questionnaire_function_q2": "Schrijven",
  "patient_hand_questionnaire_function_q2_a1": "Geen moeilijkheid",
  "patient_hand_questionnaire_function_q2_a2": "Kleine moeilijkheid",
  "patient_hand_questionnaire_function_q2_a3": "Matige moeilijkheid",
  "patient_hand_questionnaire_function_q2_a4": "Ernstige moeilijkheid",
  "patient_hand_questionnaire_function_q2_a5": "Kan helemaal niet uitvoeren",
  "patient_hand_questionnaire_function_q3": "Kleding knopen",
  "patient_hand_questionnaire_function_q3_a1": "Geen moeilijkheid",
  "patient_hand_questionnaire_function_q3_a2": "Kleine moeilijkheid",
  "patient_hand_questionnaire_function_q3_a3": "Matige moeilijkheid",
  "patient_hand_questionnaire_function_q3_a4": "Ernstige moeilijkheid",
  "patient_hand_questionnaire_function_q3_a5": "Kan helemaal niet uitvoeren",
  "patient_hand_questionnaire_function_q4": "Een boek vasthouden tijdens het lezen",
  "patient_hand_questionnaire_function_q4_a1": "Geen moeilijkheid",
  "patient_hand_questionnaire_function_q4_a2": "Kleine moeilijkheid",
  "patient_hand_questionnaire_function_q4_a3": "Matige moeilijkheid",
  "patient_hand_questionnaire_function_q4_a4": "Ernstige moeilijkheid",
  "patient_hand_questionnaire_function_q4_a5": "Kan helemaal niet uitvoeren",
  "patient_hand_questionnaire_function_q5": "Een telefoonhoorn vastgrijpen",
  "patient_hand_questionnaire_function_q5_a1": "Geen moeilijkheid",
  "patient_hand_questionnaire_function_q5_a2": "Kleine moeilijkheid",
  "patient_hand_questionnaire_function_q5_a3": "Matige moeilijkheid",
  "patient_hand_questionnaire_function_q5_a4": "Ernstige moeilijkheid",
  "patient_hand_questionnaire_function_q5_a5": "Kan helemaal niet uitvoeren",
  "patient_hand_questionnaire_function_q6": "Potten openen",
  "patient_hand_questionnaire_function_q6_a1": "Geen moeilijkheid",
  "patient_hand_questionnaire_function_q6_a2": "Kleine moeilijkheid",
  "patient_hand_questionnaire_function_q6_a3": "Matige moeilijkheid",
  "patient_hand_questionnaire_function_q6_a4": "Ernstige moeilijkheid",
  "patient_hand_questionnaire_function_q6_a5": "Kan helemaal niet uitvoeren",
  "patient_hand_questionnaire_function_q7": "Huishoudelijke taken",
  "patient_hand_questionnaire_function_q7_a1": "Geen moeilijkheid",
  "patient_hand_questionnaire_function_q7_a2": "Kleine moeilijkheid",
  "patient_hand_questionnaire_function_q7_a3": "Matige moeilijkheid",
  "patient_hand_questionnaire_function_q7_a4": "Ernstige moeilijkheid",
  "patient_hand_questionnaire_function_q7_a5": "Kan helemaal niet uitvoeren",
  "patient_hand_questionnaire_function_q8": "Een boodschappenmand dragen",
  "patient_hand_questionnaire_function_q8_a1": "Geen moeilijkheid",
  "patient_hand_questionnaire_function_q8_a2": "Kleine moeilijkheid",
  "patient_hand_questionnaire_function_q8_a3": "Matige moeilijkheid",
  "patient_hand_questionnaire_function_q8_a4": "Ernstige moeilijkheid",
  "patient_hand_questionnaire_function_q8_a5": "Kan helemaal niet uitvoeren",
  "patient_hand_questionnaire_function_q9": "Baden en aankleden",
  "patient_hand_questionnaire_function_q9_a1": "Geen moeilijkheid",
  "patient_hand_questionnaire_function_q9_a2": "Kleine moeilijkheid",
  "patient_hand_questionnaire_function_q9_a3": "Matige moeilijkheid",
  "patient_hand_questionnaire_function_q9_a4": "Ernstige moeilijkheid",
  "patient_hand_questionnaire_function_q9_a5": "Kan helemaal niet uitvoeren",
  "patient_hand_uram": "Hand- en Pols URAM Schaal",
  "patient_hand_uram_canyou": "Kunt u...",
  "patient_hand_uram_q1": "Uzelf wassen met een washandje, uw hand plat houdend",
  "patient_hand_uram_q1_a1": "Zonder moeite",
  "patient_hand_uram_q1_a2": "Met zeer weinig moeite",
  "patient_hand_uram_q1_a3": "Met enige moeite",
  "patient_hand_uram_q1_a4": "Met veel moeite",
  "patient_hand_uram_q1_a5": "Bijna onmogelijk",
  "patient_hand_uram_q1_a6": "Onmogelijk",
  "patient_hand_uram_q2": "Uw gezicht wassen",
  "patient_hand_uram_q2_a1": "Zonder moeite",
  "patient_hand_uram_q2_a2": "Met zeer weinig moeite",
  "patient_hand_uram_q2_a3": "Met enige moeite",
  "patient_hand_uram_q2_a4": "Met veel moeite",
  "patient_hand_uram_q2_a5": "Bijna onmogelijk",
  "patient_hand_uram_q2_a6": "Onmogelijk",
  "patient_hand_uram_q3": "Een fles in één hand vasthouden",
  "patient_hand_uram_q3_a1": "Zonder moeite",
  "patient_hand_uram_q3_a2": "Met zeer weinig moeite",
  "patient_hand_uram_q3_a3": "Met enige moeite",
  "patient_hand_uram_q3_a4": "Met veel moeite",
  "patient_hand_uram_q3_a5": "Bijna onmogelijk",
  "patient_hand_uram_q3_a6": "Onmogelijk",
  "patient_hand_uram_q4": "Iemand een hand geven",
  "patient_hand_uram_q4_a1": "Zonder moeite",
  "patient_hand_uram_q4_a2": "Met zeer weinig moeite",
  "patient_hand_uram_q4_a3": "Met enige moeite",
  "patient_hand_uram_q4_a4": "Met veel moeite",
  "patient_hand_uram_q4_a5": "Bijna onmogelijk",
  "patient_hand_uram_q4_a6": "Onmogelijk",
  "patient_hand_uram_q5": "Iets strelen of iemand strelen",
  "patient_hand_uram_q5_a1": "Zonder moeite",
  "patient_hand_uram_q5_a2": "Met zeer weinig moeite",
  "patient_hand_uram_q5_a3": "Met enige moeite",
  "patient_hand_uram_q5_a4": "Met veel moeite",
  "patient_hand_uram_q5_a5": "Bijna onmogelijk",
  "patient_hand_uram_q5_a6": "Onmogelijk",
  "patient_hand_uram_q6": "In uw handen klappen",
  "patient_hand_uram_q6_a1": "Zonder moeite",
  "patient_hand_uram_q6_a2": "Met zeer weinig moeite",
  "patient_hand_uram_q6_a3": "Met enige moeite",
  "patient_hand_uram_q6_a4": "Met veel moeite",
  "patient_hand_uram_q6_a5": "Bijna onmogelijk",
  "patient_hand_uram_q6_a6": "Onmogelijk",
  "patient_hand_uram_q7": "Uw vingers spreiden",
  "patient_hand_uram_q7_a1": "Zonder moeite",
  "patient_hand_uram_q7_a2": "Met zeer weinig moeite",
  "patient_hand_uram_q7_a3": "Met enige moeite",
  "patient_hand_uram_q7_a4": "Met veel moeite",
  "patient_hand_uram_q7_a5": "Bijna onmogelijk",
  "patient_hand_uram_q7_a6": "Onmogelijk",
  "patient_hand_uram_q8": "Leunen op uw hand",
  "patient_hand_uram_q8_a1": "Zonder moeite",
  "patient_hand_uram_q8_a2": "Met zeer weinig moeite",
  "patient_hand_uram_q8_a3": "Met enige moeite",
  "patient_hand_uram_q8_a4": "Met veel moeite",
  "patient_hand_uram_q8_a5": "Bijna onmogelijk",
  "patient_hand_uram_q8_a6": "Onmogelijk",
  "patient_hand_uram_q9": "Kleine voorwerpen oppakken met uw duim en wijsvinger",
  "patient_hand_uram_q9_a1": "Zonder moeite",
  "patient_hand_uram_q9_a2": "Met zeer weinig moeite",
  "patient_hand_uram_q9_a3": "Met enige moeite",
  "patient_hand_uram_q9_a4": "Met veel moeite",
  "patient_hand_uram_q9_a5": "Bijna onmogelijk",
  "patient_hand_uram_q9_a6": "Onmogelijk",
  "patient_hand_quality_of_life": "Kwaliteit van Leven",
  "patient_hand_quality_of_life_instruction": "Beoordeel het volgende om uw GEZONDHEID VANDAAG het beste te beschrijven:",
  "patient_hand_quality_of_life_instruction_0": "Geen probleem",
  "patient_hand_quality_of_life_instruction_1": "Licht",
  "patient_hand_quality_of_life_instruction_2": "Matig",
  "patient_hand_quality_of_life_instruction_3": "Ernstig",
  "patient_hand_quality_of_life_instruction_4": "Extreem/onmogelijk",
  "patient_hand_quality_of_life_q1": "Probleem bij het lopen",
  "patient_hand_quality_of_life_q1_a1": "Geen probleem",
  "patient_hand_quality_of_life_q1_a2": "Licht",
  "patient_hand_quality_of_life_q1_a3": "Matig",
  "patient_hand_quality_of_life_q1_a4": "Ernstig",
  "patient_hand_quality_of_life_q1_a5": "Onmogelijk",
  "patient_hand_quality_of_life_q2": "Probleem bij het wassen of aankleden",
  "patient_hand_quality_of_life_q2_a1": "Geen probleem",
  "patient_hand_quality_of_life_q2_a2": "Licht",
  "patient_hand_quality_of_life_q2_a3": "Matig",
  "patient_hand_quality_of_life_q2_a4": "Ernstig",
  "patient_hand_quality_of_life_q2_a5": "Onmogelijk",
  "patient_hand_quality_of_life_q3": "Probleem bij het uitvoeren van gebruikelijke activiteiten (bijv. werk, studie, huishouden, gezin of vrijetijdsactiviteiten)",
  "patient_hand_quality_of_life_q3_a1": "Geen probleem",
  "patient_hand_quality_of_life_q3_a2": "Licht",
  "patient_hand_quality_of_life_q3_a3": "Matig",
  "patient_hand_quality_of_life_q3_a4": "Ernstig",
  "patient_hand_quality_of_life_q3_a5": "Onmogelijk",
  "patient_hand_quality_of_life_q4": "Pijn of ongemak",
  "patient_hand_quality_of_life_q4_a1": "Geen",
  "patient_hand_quality_of_life_q4_a2": "Licht",
  "patient_hand_quality_of_life_q4_a3": "Matig",
  "patient_hand_quality_of_life_q4_a4": "Ernstig",
  "patient_hand_quality_of_life_q4_a5": "Extreem",
  "patient_hand_quality_of_life_q5": "Angst/Depressie",
  "patient_hand_quality_of_life_q5_a1": "Niet angstig/depressief",
  "patient_hand_quality_of_life_q5_a2": "Licht angstig/depressief",
  "patient_hand_quality_of_life_q5_a3": "Matig angstig/depressief",
  "patient_hand_quality_of_life_q5_a4": "Ernstig angstig/depressief",
  "patient_hand_quality_of_life_q5_a5": "Extreem angstig/depressief",
  "patient_hand_quality_of_life_q6": "Hoe goed/slecht is uw gezondheid VANDAAG? (100 betekent de beste gezondheid, 0 betekent de slechtste gezondheid die u zich kunt voorstellen)",
  "patient_hand_quality_of_life_q6_a1": "Slechtste",
  "patient_hand_quality_of_life_q6_a2": "Beste",
  "patient_hand_satisfaction": "Tevredenheid",
  "patient_hand_satisfaction_instrucion": "Geef aan hoe tevreden je bent met het volgende:",
  "patient_hand_satisfaction_instrucion_0": "Zeer ontevreden",
  "patient_hand_satisfaction_instrucion_1": "Ontevreden",
  "patient_hand_satisfaction_instrucion_2": "Noch tevreden noch ontevreden",
  "patient_hand_satisfaction_instrucion_3": "Enigszins tevreden",
  "patient_hand_satisfaction_instrucion_4": "Zeer tevreden",
  "patient_hand_satisfaction_q1": "Beweging van je VINGERS",
  "patient_hand_satisfaction_q1_na": "Niet van toepassing",
  "patient_hand_satisfaction_q1_a1": "Zeer ontevreden",
  "patient_hand_satisfaction_q1_a2": "Ontevreden",
  "patient_hand_satisfaction_q1_a3": "Noch tevreden noch ontevreden",
  "patient_hand_satisfaction_q1_a4": "Enigszins tevreden",
  "patient_hand_satisfaction_q1_a5": "Zeer tevreden",
  "patient_hand_satisfaction_q2": "Beweging van je POLS",
  "patient_hand_satisfaction_q2_na": "Niet van toepassing",
  "patient_hand_satisfaction_q2_a1": "Zeer ontevreden",
  "patient_hand_satisfaction_q2_a2": "Ontevreden",
  "patient_hand_satisfaction_q2_a3": "Noch tevreden noch ontevreden",
  "patient_hand_satisfaction_q2_a4": "Enigszins tevreden",
  "patient_hand_satisfaction_q2_a5": "Zeer tevreden",
  "patient_hand_satisfaction_q3": "Beweging van je HAND",
  "patient_hand_satisfaction_q3_na": "Niet van toepassing",
  "patient_hand_satisfaction_q3_a1": "Zeer ontevreden",
  "patient_hand_satisfaction_q3_a2": "Ontevreden",
  "patient_hand_satisfaction_q3_a3": "Noch tevreden noch ontevreden",
  "patient_hand_satisfaction_q3_a4": "Enigszins tevreden",
  "patient_hand_satisfaction_q3_a5": "Zeer tevreden",
  "patient_hand_satisfaction_q4": "Uw hand/post behandeling",
  "patient_hand_satisfaction_q4_na": "Niet toepasbaar",
  "patient_hand_satisfaction_q4_a1": "Erg ontevreden",
  "patient_hand_satisfaction_q4_a2": "Ontevreden",
  "patient_hand_satisfaction_q4_a3": "Noch tevreden noch ontevreden",
  "patient_hand_satisfaction_q4_a4": "Enigszins tevreden",
  "patient_hand_satisfaction_q4_a5": "Zeer tevreden",
  "patient_hand_complete": "Einde van de vragenlijst.",
  "patient_hand_complete_text1": "Dank u wel voor de tijd die u hebt genomen om dit formulier in te vullen.",
  "patient_hand_complete_text2": "Klik alstublieft op 'indienen' om het af te ronden en uw scores te bekijken.",
  "patient_hand_complete_text3": "Uw antwoorden worden vertrouwelijk behandeld, en alle rapporten zullen alleen de-geïdentificeerde informatie gebruiken",
  "patient_hand_result": "resultaten",
  "patient_hand_result_text1": "Hieronder staan uw pijn- en hand- en polsfunctiescores, die zijn berekend aan de hand van uw vragenlijst.",
  "patient_hand_result_function": "Functie ",
  "patient_hand_result_pain": "Pijn",
  "patient_hand_result_health_perception": "Algemene gezondheidsperceptie.",
  "patient_hand_result_self_evaluation": " ",
  "patient_hand_result_text2": "Uw arts zal binnenkort bij u zijn.",
  "patient_general_history": "GESCHIEDENIS VAN HUIDIGE KLACHT/LETSEL/ZIEKTE",
  "patient_general_history_q1": "Belangrijkste klacht (leg uw klacht in uw eigen woorden uit)",
  "patient_general_history_q2": "Datum waarop het probleem begon",
  "patient_general_history_q3": "Heeft u enige beeldvorming gehad?",
  "patient_general_history_q3_a1": "Geen",
  "patient_general_history_q3_a2": "Rontgen foto",
  "patient_general_history_q3_a3": "Echo",
  "patient_general_history_q3_a4": "MRI",
  "patient_general_history_q3_a5": "MRI Arthrogram (met contrast)",
  "patient_general_history_q3_a6": "CT",
  "patient_general_history_q3_a7": "CT Arthrogram (CT met contrast in het gewricht)",
  "patient_general_history_q4": "Heeft u verder onderzoek naar uw aandoening gehad dat niet hierboven is vermeld?",
  "patient_general_history_q4_placeholder": "Overige onderzoeken",
  "patient_general_past_history": "MEDISCHE VOORGESCHIEDENIS / LOPENDE MEDISCHE PROBLEMEN",
  "patient_general_past_history_q1": "Heeft u bekende allergieën? (geneesmiddelen of voeding)",
  "patient_general_past_history_q1_a1": "Nee ",
  "patient_general_past_history_q1_a2": "Ja",
  "patient_general_past_history_placeholder": "Graag specificeren: allergie en soort reactie",
  "patient_general_past_history_q2": "Bent u allergisch voor Latex?",
  "patient_general_past_history_q2_a1": "Nee ",
  "patient_general_past_history_q2_a2": "Ja",
  "patient_general_past_history_q3": "Heeft u last van hoge bloeddruk of wordt u hiervoor behandeld?",
  "patient_general_past_history_q3_a1": "Nee ",
  "patient_general_past_history_q3_a2": "Ja",
  "patient_general_past_history_q3_a2_placeholder": "Specificeer uw medicatie voor uw hoge bloeddruk.",
  "patient_general_past_history_q4": "Heeft u momenteel of heeft u in het verleden last gehad van hartkwalen/ziekten",
  "patient_general_past_history_q4_a1": "Nee ",
  "patient_general_past_history_q4_a2": "Ja",
  "patient_general_past_history_q4_a2_o1": "Selecteer een aandoening",
  "patient_general_past_history_q4_a2_o2": " Angina pectoris",
  "patient_general_past_history_q4_a2_o3": "Hartinfarct",
  "patient_general_past_history_q4_a2_o4": "Hartfalen (vocht in uw longen)",
  "patient_general_past_history_q4_a2_o5": "Hartruis of Hartklep Probleem",
  "patient_general_past_history_q4_a2_o6": "Hartkloppingen/Onregelmatige Hartslag",
  "patient_general_past_history_q4_a2_o7": "Geïmplanteerd Apparaat (ICD, pacemaker)",
  "patient_general_past_history_q4_a2_o8": "Cardiale chirurgie",
  "patient_general_past_history_q4_a2_o9": "Chirurgie aan de halsslagader",
  "patient_general_past_history_q4_a2_o10": "Andere hartconditie",
  "patient_general_past_history_q4_a2_o8_q1_placeholder": "Specificeer hartchirurgie",
  "patient_general_past_history_q4_a2_o10_q1_placeholder": "Specificeer andere hartaandoening",
  "patient_general_past_history_q5": "Heeft u een cardioloog?",
  "patient_general_past_history_q5_a1": "Nee ",
  "patient_general_past_history_q5_a2": "ja",
  "patient_general_past_history_q5_a2_placeholder": "Naam en Praktijk",
  "patient_general_past_history_q6": "Heeft u problemen met de circulatie in uw benen (perifeer vaatlijden)",
  "patient_general_past_history_q6_a1": "Nee ",
  "patient_general_past_history_q6_a2": "Ja",
  "patient_general_past_history_q6_a2_placeholder": "Beschrijf",
  "patient_general_past_history_q7": "Heeft u bloedverdunners gebruikt in de afgelopen maand?",
  "patient_general_past_history_q7_a1": "Nee ",
  "patient_general_past_history_q7_a2": "ja",
  "patient_general_past_history_q7_a2_q1": "Wat is dat?",
  "patient_general_past_history_q7_a2_q1_a1": "Aspirine",
  "patient_general_past_history_q7_a2_q1_a2": "Clopidogrel/Plavix",
  "patient_general_past_history_q7_a2_q1_a3": "Rivaroxaban",
  "patient_general_past_history_q7_a2_q1_a4": "Eliquis",
  "patient_general_past_history_q7_a2_q1_a5": "Andere",
  "patient_general_past_history_q7_a2_q1_a5_placeholder": "Specificeer bloedverdunner",
  "patient_general_past_history_q8": "Heeft u problemen gehad met uw longen of borst?",
  "patient_general_past_history_q8_a1": "Nee",
  "patient_general_past_history_q8_a2": "Ja",
  "patient_general_past_history_q8_a2_o1": "Hoest met sputum?",
  "patient_general_past_history_q8_a2_o2": "Problemen met uw ademhaling?",
  "patient_general_past_history_q8_a2_o3": "Andere",
  "patient_general_past_history_q8_a2_o2_o1": "Astma",
  "patient_general_past_history_q8_a2_o2_o2": "Hoge bloeddruk in de longen (pulmonale hypertensie)",
  "patient_general_past_history_q8_a2_o2_o3": "Longfibrose",
  "patient_general_past_history_q8_a2_o2_o4": "Sarcoïdose",
  "patient_general_past_history_q8_a2_o2_o5": "COPD of emfyseem",
  "patient_general_past_history_q8_a2_o2_o6": "Cystic fibrosis",
  "patient_general_past_history_q8_a2_o2_o7": "Tuberculose (TBC)",
  "patient_general_past_history_q8_a2_o2_o8": "Andere",
  "patient_general_past_history_q8_a2_o3_placehold": "Specificeer",
  "patient_general_past_history_q8_a2_o2_o8_placeholder": "Specificeer",
  "patient_general_past_history_q9": "Heeft u in het afgelopen jaar steroïden (prednison of cortison) genomen?",
  "patient_general_past_history_q9_a1": "Nee",
  "patient_general_past_history_q9_a2": "Ja",
  "patient_general_past_history_q10": "Gebruikt u thuis zuurstof?",
  "patient_general_past_history_q10_a1": "Nee",
  "patient_general_past_history_q10_a2": "Ja",
  "patient_general_past_history_q11": "Heeft u een longarts?",
  "patient_general_past_history_q11_a1": "Nee",
  "patient_general_past_history_q11_a2": "Ja",
  "patient_general_past_history_q11_a2_placeholder": "Naam en Praktijk",
  "patient_general_past_history_q12": "Heeft u overmatig snurken of slaapapneu?",
  "patient_general_past_history_q12_a1": "Nee",
  "patient_general_past_history_q12_a2": "Ja",
  "patient_general_past_history_q12_a2_q1": "Wordt uw slaapapneu behandeld met CPAP of BiPAP?",
  "patient_general_past_history_q12_a2_q1_a1": "Nee",
  "patient_general_past_history_q12_a2_q1_a2": "Ja",
  "patient_general_past_history_q13": "Heeft u ooit leverproblemen gehad?",
  "patient_general_past_history_q13_a1": "Nee",
  "patient_general_past_history_q13_a2": "Ja",
  "patient_general_past_history_q13_a2_o1": "Geselecteerd",
  "patient_general_past_history_q13_a2_o2": "Cirrose",
  "patient_general_past_history_q13_a2_o3": "Hepatitis A, B of C",
  "patient_general_past_history_q13_a2_o4": "Geelzucht",
  "patient_general_past_history_q13_a2_o5": "Andere",
  "patient_general_past_history_q13_a2_o5_placeholder": "Specificeer",
  "patient_general_past_history_q14": "Heeft u vaak last van brandend maagzuur, maagzweren of een middenrifbreuk?",
  "patient_general_past_history_q14_a1": "Nee",
  "patient_general_past_history_q14_a2": "Ja",
  "patient_general_past_history_q15": "Heeft u ooit nierziekte gehad?",
  "patient_general_past_history_q15_a1": "Nee",
  "patient_general_past_history_q15_a2": "Ja",
  "patient_general_past_history_q15_a2_q1": "Bent u een dialysepatiënt? Peritoneale? Hemodialyse",
  "patient_general_past_history_q15_a2_q1_a1": "Nee",
  "patient_general_past_history_q15_a2_q1_a2": "Ja",
  "patient_general_past_history_q15_a2_placeholder": "Specificeer nierziekte",
  "patient_general_past_history_q15_a2_q1_a2_placeholder": "Specificeer type en schema van dialyse",
  "patient_general_past_history_q16": "Heeft u diabetes?",
  "patient_general_past_history_q16_a1": "Nee",
  "patient_general_past_history_q16_a2": "Ja",
  "patient_general_past_history_q16_a2_q1": "Hoe wordt uw diabetes behandeld? Dieet/orale medicatie of insuline?",
  "patient_general_past_history_q16_a2_q1_placeholder": "Specificeer",
  "patient_general_past_history_q17": "Heeft u schildklierproblemen?",
  "patient_general_past_history_q17_a1": "Nee",
  "patient_general_past_history_q17_a2": "Ja",
  "patient_general_past_history_q18": "Heeft u ooit epilepsie, aanvallen of stuipen gehad?",
  "patient_general_past_history_q18_a1": "Nee",
  "patient_general_past_history_q18_a2": "Ja",
  "patient_general_past_history_q18_a2_q1": "Wanneer was uw laatste aanval?",
  "patient_general_past_history_q18_a2_q1_placeholder": "Specificeer",
  "patient_general_past_history_q19": "Heeft u problemen gehad met uw zenuwen of spieren?",
  "patient_general_past_history_q19_a1": "Nee",
  "patient_general_past_history_q19_a2": "Ja",
  "patient_general_past_history_q19_a2_o1": "Aanvallen",
  "patient_general_past_history_q19_a2_o1_placeholder": "Wanneer?",
  "patient_general_past_history_q19_a2_o2": "TIA (Transient Ischemic Attack/ministroke) of beroerte",
  "patient_general_past_history_q19_a2_o2_placeholder": "Wanneer?",
  "patient_general_past_history_q19_a2_o3": "Gezichts-, been- of armslapte",
  "patient_general_past_history_q19_a2_o4": "Neurologische aandoening (bijvoorbeeld multiple sclerose, ALS, de ziekte van Alzheimer)",
  "patient_general_past_history_q19_a2_o4_placeholder": "Specificeer",
  "patient_general_past_history_q19_a2_o5": "Spierziekte (bijvoorbeeld myasthenia gravis, spierdystrofie)",
  "patient_general_past_history_q19_a2_o5_placeholder": "Specificeer",
  "patient_general_past_history_q19_a2_o6": "Problemen met horen, zien of geheugen",
  "patient_general_past_history_q19_a2_o7": "Chronische pijn",
  "patient_general_past_history_q19_a2_o8": "Andere",
  "patient_general_past_history_q19_a2_o8_placeholder": "Specificeer",
  "patient_general_past_history_q20": "Heeft u artritis?",
  "patient_general_past_history_q20_a1": "Nee",
  "patient_general_past_history_q20_a2": "Ja",
  "patient_general_past_history_q20_a2_o1": "Osteoartritis",
  "patient_general_past_history_q20_a2_o2": "Reumatoïde artritis",
  "patient_general_past_history_q20_a2_o3": "Andere",
  "patient_general_past_history_q20_a2_o3_placeholder": "Specificeer",
  "patient_general_past_history_q21": "Bent u ooit behandeld voor een psychiatrische aandoening?",
  "patient_general_past_history_q21_a1": "Nee",
  "patient_general_past_history_q21_a2": "Ja",
  "patient_general_past_history_q21_a2_o1": "Ernstige angst",
  "patient_general_past_history_q21_a2_o2": "Depressie",
  "patient_general_past_history_q21_a2_o3": "Andere",
  "patient_general_past_history_q21_a2_o3_placeholder": "Specificeer",
  "patient_general_past_history_q22": "Bent u ooit gediagnosticeerd met een bloedingsstoornis?",
  "patient_general_past_history_q22_a1": "Nee",
  "patient_general_past_history_q22_a2": "Ja",
  "patient_general_past_history_q22_a2_o1": "Leukemie of lymfoom",
  "patient_general_past_history_q22_a2_o2": "Hemofilie",
  "patient_general_past_history_q22_a2_o3": "Bloedstolsels",
  "patient_general_past_history_q22_a2_o4": "Bloedarmoede",
  "patient_general_past_history_q22_a2_o5": "Sikkelcelziekte",
  "patient_general_past_history_q22_a2_o6": "Andere",
  "patient_general_past_history_q22_a2_o6_placeholder": "Specificeer",
  "patient_general_past_history_q23": "Bent u bloedarmoede?",
  "patient_general_past_history_q23_a1": "Nee",
  "patient_general_past_history_q23_a2": "Ja",
  "patient_general_past_history_q24": "Heeft u ooit een bloedstolsel in uw benen of longen gehad?",
  "patient_general_past_history_q24_a1": "Nee",
  "patient_general_past_history_q24_a2": "Ja",
  "patient_general_past_history_q25": "Heeft u of iemand in uw familie ernstige problemen gehad met:",
  "patient_general_past_history_q25_a1": "Neusbloedingen",
  "patient_general_past_history_q25_a1_o1": "Nee",
  "patient_general_past_history_q25_a1_o2": "Ja",
  "patient_general_past_history_q25_a2": "Bloeden na tandextracties",
  "patient_general_past_history_q25_a2_o1": "Nee",
  "patient_general_past_history_q25_a2_o2": "Ja",
  "patient_general_past_history_q25_a3": "Bloedingen na een operatie",
  "patient_general_past_history_q25_a3_o1": "Nee",
  "patient_general_past_history_q25_a3_o2": "Ja",
  "patient_general_past_history_q26": "Bent u ooit gediagnosticeerd met kanker?",
  "patient_general_past_history_q26_a1": "Nee",
  "patient_general_past_history_q26_a2": "Ja",
  "patient_general_past_history_q26_a2_placeholder1": "Specificeer het type kanker",
  "patient_general_past_history_q26_a2_placeholder2": "Wanneer?",
  "patient_general_past_history_q27": "Bent u ooit behandeld met chemotherapie of bestralingstherapie?",
  "patient_general_past_history_q27_a1": "Nee",
  "patient_general_past_history_q27_a2": "Ja",
  "patient_general_past_history_q27_a2_placeholder1": "Naam van de behandeling",
  "patient_general_past_history_q27_a2_placeholder2": "Laatste behandeldatum",
  "patient_general_past_history_q28": "Heeft u of uw familielid problemen gehad met anesthesie?",
  "patient_general_past_history_q28_a1": "Nee",
  "patient_general_past_history_q28_a2": "Ja",
  "patient_general_past_history_q28_a2_q1": "Welke problemen?",
  "patient_general_past_history_q28_a2_q1_placeholder": "Selecteer",
  "patient_general_past_history_q28_a2_q1_o1": "Ernstige misselijkheid of braken na de operatie",
  "patient_general_past_history_q28_a2_q1_o2": "Maligne hyperthermie (in de familie van bloedverwanten of bij uzelf)",
  "patient_general_past_history_q28_a2_q1_o3": "Ademhalingsprobleem of moeite met het inbrengen van de anesthesie-ademhalingsbuis?",
  "patient_general_past_history_q28_a2_q1_o4": "Problemen met het plaatsen van een ademhalingsbuis",
  "patient_general_past_history_q28_a2_q1_o5": "Andere",
  "patient_general_past_history_q28_a2_q1_o5_placeholder": "Specificeer",
  "patient_general_past_history_q29": "Heeft u een van de volgende:",
  "patient_general_past_history_q29_q1": "Afgebroken of losse tanden, kunstgebitten, partiële gebitsprothesen",
  "patient_general_past_history_q29_q1_a1": "Nee",
  "patient_general_past_history_q29_q1_a2": "Ja",
  "patient_general_past_history_q29_q2": "Problemen met het bewegen van uw nek",
  "patient_general_past_history_q29_q2_a1": "Nee",
  "patient_general_past_history_q29_q2_a2": "Ja",
  "patient_general_past_history_q29_q3": "Problemen met het openen van uw mond",
  "patient_general_past_history_q29_q3_a1": "Nee",
  "patient_general_past_history_q29_q3_a2": "Ja",
  "patient_general_past_history_q30": "Kunt u zwanger zijn?",
  "patient_general_past_history_q30_a1": "Nee",
  "patient_general_past_history_q30_a2": "Ja",
  "patient_general_past_history_q30_not_applicable": "Niet van toepassing",
  "patient_general_past_history_q30_a2_q1": "Laatste menstruatie",
  "patient_general_past_history_q30_a2_q1_placeholder": "Laatste menstruatie",
  "patient_general_past_history_q31": "Medische geschiedenis: Vermeld alstublieft eventuele aanvullende medische aandoeningen die u heeft die hierboven niet zijn genoteerd.",
  "patient_general_past_history_q31_q1": "Lijst alle medicijnen op (inclusief vrij verkrijgbare medicijnen, inhalatoren, pleisters, druppels, vitamines, mineralen, supplementen, kruiden)",
  "patient_general_past_history_q31_q2": "Geef de naam van het medicijn, de dosering, de frequentie en de reden van gebruik aan",
  "patient_general_past_history_q31_q2_placeholder": "Medicijnnaam, dosering, frequentie, reden voor gebruik",
  "patient_general_past_history_q31_q3": "Dosis",
  "patient_general_past_history_q31_q4": "Vaak",
  "patient_general_past_history_q31_q5": "Reden voor gebruik",
  "patient_general_past_history_q31_q6": "Chirurgische geschiedenis",
  "patient_general_past_history_q31_q6_placeholder": "Specificeer procedure en procedure datum",
  "patient_general_social_history": "SOCIALE GESCHIEDENIS",
  "patient_general_social_history_q1": "Woont u alleen?",
  "patient_general_social_history_q1_a1": "Nee",
  "patient_general_social_history_q1_a2": "Ja",
  "patient_general_social_history_q1_a1_q1": "Met wie woont u?",
  "patient_general_social_history_q1_a1_q1_placeholder": "Met wie woont u?",
  "patient_general_social_history_q2": "Rookt u?",
  "patient_general_social_history_q2_a1": "Nee",
  "patient_general_social_history_q2_a2": "Ja",
  "patient_general_social_history_q2_a2_q1": "Hoeveel sigaretten per dag?",
  "patient_general_social_history_q2_a2_q1_placeholder": "Aantal sigaretten",
  "patient_general_social_history_q2_a2_q2": "Aantal jaren?",
  "patient_general_social_history_q2_a2_q2_placeholder": "Jaren",
  "patient_general_social_history_q3": "Heeft u in het verleden gerookt?",
  "patient_general_social_history_q3_a1": "Nee",
  "patient_general_social_history_q3_a2": "Ja",
  "patient_general_social_history_q3_a2_q1": "Wanneer bent u gestopt met roken?",
  "patient_general_social_history_q3_a2_q1_placeholder": "Wanneer bent u gestopt met roken?",
  "patient_general_social_history_q3_a2_q2_placeholder": "Jaren",
  "patient_general_social_history_q4": "Drinkt u alcohol?",
  "patient_general_social_history_q4_a1": "Nee",
  "patient_general_social_history_q4_a2": "Ja",
  "patient_general_social_history_q4_a2_q1_placeholder": "Aantal standaard drankjes per week",
  "patient_general_social_history_q5": "Gebruikt u of heeft u ooit recreatieve of straat (illegale) drugs, marihuana of opioïden gebruikt die niet aan u zijn voorgeschreven?",
  "patient_general_social_history_q5_a1": "Nee",
  "patient_general_social_history_q5_a2": "Ja",
  "patient_general_social_history_q5_a2_placeholder": "Specificeer drugs",
  "patient_general_complete": "Einde van de vragenlijst",
  "patient_general_complete_text1": "Bedankt voor de tijd die u heeft genomen om dit formulier in te vullen.",
  "patient_general_complete_text2": "Klik op verzenden om de voltooiing te bevestigen en uw scores te bekijken.",
  "patient_general_complete_text3": "Uw antwoorden worden vertrouwelijk behandeld en eventuele rapporten zullen alleen geanonimiseerde informatie bevatten.",
  "AppVersion": "App-versie",
  "Complete_personal_info": "Vul uw persoonlijke gegevens in ",
  "To_complete_the_sign_up": "Vul uw informatie in om het aanmelden te voltooien.",
  "Select_Existing_Profile": "Selecteer bestaand profiel",
  "Get_In": "Voer in",
  "Create_new_patient": "Maak een nieuwe patiënt",
  "myProfile": "Mijn profiel",
  "First_Name": "Voornaam*",
  "Last_Name": "Achternaam*",
  "DOB": "Geboortedatum*",
  "Sex": "Geslacht*",
  "Address": "Adres*",
  "Postcode": "Postcode*",
  "Suburb": "Woonplaats*",
  "Country_Code": "Landcode",
  "Mobile_Phone": "Mobiele telefoon",
  "Home_Phone": "Huis telefoon",
  "Email": "E-mail*",
  "Emergency_Contact": "Noodcontactpersoon*",
  "Phone": "Telefoon*",
  "Relationship": "Relatie*",
  "Regular_GP_Name": "Naam van reguliere huisarts*",
  "Practice_Name": "Naam van de praktijk*",
  "Medicare_Card_Number": "Medicare-kaartnummer",
  "Ref": "Ref",
  "Message": "Berichten",
  "Expiry": "Vervaldatum",
  "Private_Health_Fund": "Particuliere zorgverzekering",
  "Membership_Number": "Lidmaatschapsnummer",
  "Claim_Type": "Claimtype",
  "Work_Cover": "Arbeidsongevallenverzekering",
  "Employer": "Werkgever",
  "Third_Party_Claim_Number": "Claimnummer derde partij",
  "Third_Party_Name": "Naam derde partij",
  "DVA_Concession_Card": "DVA-concessiekaartnummer",
  "Number": "Nummer",
  "Defence_Personnel": "Defensiepersoneel",
  "I_agree": "Ik ga akkoord met de verwerking van mijn persoonsgegevens en heb het privacybeleid gelezen.",
  "Privacy_Policy": "Privacy Policy.",
  "visitClinic": "Maak afspraak",
  "selectdocOrg": "Selecteer arts & organisatie*",
  "doctorOrganization": "Arts Organisatie\"",
  "Select_Treatment_Category": "Selecteer behandelingscategorie*",
  "Do_you_want_to_submit_patient_form_or_not": "Wilt u het patiëntenformulier indienen of niet?",
  "areyousurewanttocontinueyourform": "Uw vorige formulier is in behandeling. Wilt u doorgaan?",
  "myVisit": "Eerdere bezoeken",
  "View_score": "Bekijk score",
  "upcoming_visits": "Aankomende",
  "No_data_found": "Geen gegevens gevonden",
  "Doctor_Name": "Naam arts",
  "Appointment_Date_Time": "Afspraakdatum & -tijd",
  "Clinic_Name": "Klinieknaam",
  "Clinic_Address": "Kliniekadres",
  "akmed": "Akunah Med",
  "Type": "Type",
  "Date_Time": "Datum & Tijd",
  "Action": "Actie",
  "View": "Bekijken",
  "No_Messages": "Geen berichten",
  "settings": "Instellingen",
  "Language": "Taal",
  "language": "Taal en regio",
  "timezone": "Tijdzone",
  "Timeformat": "Tijdformaat",
  "List_of_my_profile": "Mijn profielen",
  "next_button": "Volgende",
  "back_button": "Terug",
  "Continue": "Doorgaan",
  "Close": "Sluiten",
  "submit": "Verzenden",
  "Sign_out": "Terug naar profiel",
  "Results": "Resultaten",
  "update": "Formulier succesvol bijgewerkt",
  "Personal_Data": "Persoonsgegevens",
  "Date_of_Birth": "Geboortedatum",
  "Hip_arthritis": "Heupartrose?*",
  "Area_of_foot_ankle": "Gebied van de voet/enkel aangetast?*",
  "Diagnosis": "Diagnose?*",
  "Evaluated_Shoulder": "Beoordeelde schouder",
  "Dominant_Hand": "Dominante hand*",
  "Age": "Leeftijd",
  "No": "Nee",
  "Yes": "Ja",
  "visitNo": "visitNo",
  "visitYes": "visitYes",
  "shoulderPainR": "shoulderPainR",
  "Is_it_initial_visit": "Is dit uw eerste bezoek voor dit probleem?*",
  "for_the_problem": "for this problem?*",
  "Diagnosis_Procedure": "Diagnose / Procedure",
  "Procedure": "Procedure",
  "Evaluated_sdfs": "Beoordeeld*",
  "Please_Select_previous_visits": "Selecteer eerdere bezoeken*",
  "Follow_Up": "Follow-up",
  "Assessment_Date": "Beoordelingsdatum",
  "Do_you_have_Dupuytren_disease": "Heeft u Dupuytren's ziekte?",
  "Do_you_have_carpal_tunnel_syndrome": "Heeft u het carpaletunnelsyndroom?",
  "Is_your_unaffected_shoulder_pain": "Is uw niet-aangedane schouder pijnvrij en staat deze normale functie toe?",
  "Is_your_unaffected_hip_pain": "Is uw niet-aangedane heup pijnvrij en staat deze normale functie toe?",
  "Is_your_unaffected_knee_pain": "Is uw niet-aangedane knie pijnvrij en staat deze normale functie toe?",
  "Is_your_unaffected_elbow_pain": "Is uw niet-aangedane elleboog pijnvrij en staat deze normale functie toe?",
  "Is_your_unaffected_foot_and_ankle_pain": "Is uw niet-aangedane voet en enkel pijnvrij en staat deze normale functie toe?",
  "Is_your_unaffected_hand_pain": "Is uw niet-aangedane hand en pols pijnvrij en staat deze normale functie toe?",
  "Patient_Consent": "Akunah Patiëntinformatie- en voorafgaande toestemmingsformulier",
  "Patient_Last_Name": "Achternaam patiënt",
  "Patient_First_Name": "Voornaam patiënt",
  "Patient_Date_of_Birth": "Geboortedatum patiënt",
  "information": "Informatie",
  "I_am_informedDDD": "Voor het doel van de evaluatie, planning en beoordeling van de uitkomst van uw orthopedische behandeling gebruikt uw arts, ",
  "Clinician": "(hierna de \"Clinicus\"), evaluatie, gegevensverzameling en -analyse van uitkomsten en/of voorafgaande chirurgische planningsdiensten en software geleverd door Akunah Medical Technology Pty Ltd en zijn dochterondernemingen (\"Akunah\").",
  "For_that": "Voor dat doel zal de arts uw persoonsgegevens, inclusief computer tomografie (CT)-scans van uw lichaam en andere medische gegevens, overdragen naar Akunah.",
  "Akunah_softawre": "De software en diensten van Akunah kunnen de evaluatie van beelden, beoordeling van medische gegevens, creatie van gepersonaliseerde 3D-modellen van uw anatomie samen met belangrijke anatomische metingen, chirurgische planning en ondersteuning voor chirurgie omvatten, die met de arts zullen worden gedeeld.",
  "the_clinic": "De arts zal de evaluatie en voorafgaande chirurgische planning voor uw orthopedische behandeling gebruiken.",
  "oportunnity": "U heeft ook de mogelijkheid om persoonsgegevens te verstrekken via het patiëntenportaal van Akunah en toegang te krijgen tot de modellen en plannen van Akunah.",
  "akunah_approch": "Benadering van Akunah met betrekking tot privacy en beveiliging",
  "akunah_approch1": "De privacy en beveiliging van de gegevens die Akunah van u verzamelt om uw arts te helpen, staan voorop in onze gedachten.",
  "akunah_approch2": "Akunah volgt vrijwillig de toepasselijke Europese wetgeving inzake gegevensbescherming, waaronder de Algemene Verordening Gegevensbescherming EU/2016/679, aangezien deze wetten de sterkste privacywetten zijn die wereldwijd zijn ingevoerd. Deze wetten overtreffen de privacywetten die zijn ingevoerd door Australië, de Verenigde Staten en andere landen.",
  "akunah_approch3": "De \"Privacycode voor de verwerking van persoonsgegevens van klantenindividuen\" van Akunah beschrijft hoe Akunah voldoet aan de toepasselijke Europese wetgeving inzake gegevensbescherming ",
  "akunah_approch4": "de Privacycode",
  "akunah_approch5": "De \"Overeenkomst gegevensverwerking\" van Akunah beschrijft de regelingen die het heeft met zijn arts om uw persoonlijke informatie te beschermen en beveiligen ",
  "akunah_approch6": "(de Overeenkomst gegevensverwerking).",
  "akunah_approch7": "Die beleidsregels zijn beschikbaar op de website van Akunah op",
  "terms_": "https://akunah.com/termsofservices",
  "akunah_approch8": "Voor zover er enige inconsistentie is tussen de Privacycode, de Overeenkomst gegevensverwerking en dit document, zullen de voorwaarden van het eerstgenoemde document van toepassing zijn voor zover er inconsistentie is met dit document.",
  "Data_Collection_and_Processing": "Gegevensverzameling en -verwerking",
  "I_am_informed": "Akunah kan uw gegevens verzamelen, inclusief informatie met betrekking tot uw contactgegevens, authenticatie, identificatie, beroep en demografische gegevens. Akunah kan ook medische gegevens verzamelen met betrekking tot uw gezondheid, zoals",
  "Assessment_and_evaluation": "Beoordelings- en evaluatiegegevens, fysieke kenmerken en andere informatie die relevant is voor de voorgestelde behandelingen en uitkomsten;",
  "Previous_surgery": "Eerdere operaties, diagnoses, radiologische scans, 3D-modellen, anatomie, anatomische metingen; en",
  "Health_and_medical_history": "Gezondheids- en medische geschiedenis, inclusief uw symptomen, medicijnen en eventuele eerdere diagnose en behandeling die aan u is gegeven.",
  "I_have_been_assured": "Akunah verzamelt altijd de informatie op een wettige en eerlijke manier, rechtstreeks van een patiënt, gedeeld door een arts of via de producten van Akunah. Akunah zal deze gegevens verwerken in overeenstemming met uw hierna uitgedrukte toestemming, om de levering van de evaluatie- en voorafgaande chirurgische planningsdienst indien nodig mogelijk te maken.",
  "My_data": "Daarnaast kan Akunah uw persoonsgegevens gebruiken om te voldoen aan alle toepasselijke wet- en regelgeving.",
  "your_data": "Uw gegevens kunnen ook worden bekendgemaakt aan derden, indien vereist om de voorgestelde diensten te leveren of op grond van de wet.",
  "Data_Access_and_Storage": "Toegang tot gegevens, opslag en beveiliging",
  "Personal_data_provided": "Gegevens die door u en uw arts zijn ingevoerd, zijn alleen toegankelijk voor degenen die bijdragen aan uw behandeling of herstel (bijv. anesthesist, fysiotherapeut) en die toegang moeten hebben tot uw gegevens.",
  "data1": "Gegevens die zijn ingevoerd via de software van Akunah worden veilig gehost in het Gemenebest van Australië door een goedgekeurde hostingprovider. Dit zorgt voor een passend niveau van vertrouwelijkheid en beveiliging dat overeenkomt met de aard van uw gegevens die worden verwerkt via de software van Akunah.",
  "data2": "Als u zich buiten het Gemenebest van Australië bevindt en/of gegevens die zijn ingevoerd via de software van Akunah mogelijk toegankelijk zijn voor dochterondernemingen van Akunah buiten het Gemenebest van Australië, inclusief in de Europese Unie of de Verenigde Staten, kunnen verschillende privacynormen van toepassing zijn op hoe uw gegevens in die landen worden gebruikt en beschermd. In dat geval worden uw persoonsgegevens in dat land opgeslagen.",
  "data3": "Akunah heeft passende waarborgen ingesteld in overeenstemming met alle toepasselijke wettelijke vereisten om ervoor te zorgen dat gegevens vertrouwelijk worden bewaard en beschermd met een adequaat niveau van gegevensbescherming bij alle derde partijen. We vereisen dat iedereen met toegang normen hanteert die een gelijkwaardig niveau van bescherming voor gegevens waarborgen, zoals dat aangenomen is door Akunah.",
  "data4": "Uw arts deelt de verantwoordelijkheid om gegevens te verzamelen, toegang te verkrijgen, te analyseren en te delen volgens de wettelijke vereisten.",
  "p_data1": "Uw persoonsgegevens worden door Akunah bewaard voor een periode die kan variëren. De bewaarperiode wordt bepaald door de volgende criteria: (i) het doel waarvoor Akunah of de arts uw persoonsgegevens gebruikt (ze moeten de gegevens alleen bewaren zolang als nodig is voor dat doel); en (ii) toepasselijke wet- of regelgeving die een minimumperiode kan bepalen waarvoor Akunah uw persoonsgegevens moet bewaren.",
  "Deidentified_Data": "Gegevens geanonimiseerd",
  "d_txt1": "Akunah kan uw gegevens anonimiseren en deze geanonimiseerde gegevens vervolgens gebruiken - meestal geaggregeerd met geanonimiseerde gegevens van andere patiënten - voor administratieve doeleinden, onderzoek en ontwikkeling, gegevensanalyse, software-/apparaatontwikkeling en verbetering van de producten en diensten van Akunah. Dit stelt Akunah in staat om voortdurend hun producten en diensten te verbeteren ten behoeve van patiënten zoals u.",
  "Patient_Rights": "Uw rechten",
  "I_understand_that": "U bent vrij om toestemming te weigeren om uw persoonsgegevens aan Akunah te verstrekken. In dat geval kunt u contact opnemen met uw arts, aangezien uw Clinicus mogelijk geen medische diensten of behandeling aan u kan verlenen zonder gebruik te maken van de software en diensten van Akunah.",
  "I_understand_that_1txt": "U heeft het recht om uw toestemming te allen tijde en op enigerlei wijze in te trekken, en, indien van toepassing, heeft u het recht om: (i) informatie te verkrijgen over de verwerking van uw persoonsgegevens en toegang te verkrijgen tot dergelijke persoonsgegevens; (ii) verzoeken om rectificatie indien deze onjuist zijn; (iii) verzoeken om beperking van de verwerking van uw persoonsgegevens, inclusief bezwaar maken tegen bepaalde verwerking van uw persoonsgegevens en intrekking van de door u verstrekte toestemming; (iv) verzoeken om het wissen van uw persoonsgegevens; (v) richtlijnen vaststellen met betrekking tot de verwerking van uw gegevens na uw overlijden; en (vi) een kopie verkrijgen van uw persoonsgegevens die zijn verwerkt onder de hierboven vermelde voorwaarden in een gestructureerd, gangbaar en machinaal leesbaar formaat. U kunt deze rechten uitoefenen door uw verzoek rechtstreeks aan de arts te richten.",
  "I_understand_that_2txt": "Uw rechten zijn onderworpen aan de toepasselijke vrijstellingen en beperkingen in de wet.",
  "I_understand_that_3txt": "Na intrekking kan uw Clinicus u mogelijk geen medische diensten of behandeling meer verlenen, als u deze rechten uitoefent vóór de voorgestelde procedure.",
  "I_understand_that_4txt": "U bent ook vrij om een klacht in te dienen over de verwerking van uw persoonsgegevens bij de toezichthoudende autoriteit in uw land van verblijf.",
  "I_understand_that_5txt": "Wat betreft geanonimiseerde gegevens, vallen geanonimiseerde gegevens niet onder dezelfde wettelijke beperkingen als persoonsgegevens. Houd er rekening mee dat u geen toestemming kunt intrekken voor toegang tot en gebruik van uw geanonimiseerde gegevens of het wissen van deze gegevens kunt aanvragen, omdat Akunah uw gegevens niet kan vinden. Na anonimisering kan Akunah gegevens niet naar u terugleiden.",
  "Contact": "Toestemming",
  "I_am_able_to_contact": "Ik heb de Informatie hierboven gelezen en begrepen, of iemand heeft deze aan mij voorgelezen in een taal die ik begrijp.",
  "or_by_post": "Ik heb de gelegenheid gehad om vragen te stellen en ben tevreden met de antwoorden die ik heb ontvangen. Ik begrijp dat mijn informatie vertrouwelijk zal blijven.",
  "or_by_post2": "Ik geef toestemming voor mijn arts",
  "or_by_post2_1": "(de \"klinicus\") om mijn persoonsgegevens te delen met Akunah.",
  "or_by_post3": "Ik geef toestemming dat Akunah mijn persoonlijke en geanonimiseerde gegevens verzamelt, opslaat, gebruikt en deelt zoals beschreven in de Informatie hierboven.",
  "or_by_post4": "Ik begrijp dat ik op elk moment vrij ben om mijn toestemming in te trekken.",
  "or_by_post5": "Ik begrijp dat ik een ondertekend exemplaar van dit document zal ontvangen om te bewaren.",
  "or_by_post6": "Ik kan contact opnemen met Akunah over de verwerking van mijn gegevens via e-mail naar",
  "info_akunah": "info@akunah.com ",
  "or_by_post7": "of per post naar Suite 307 Nicholson Street Specialist Centre, Level 9, 121 Newdegate Street, Greenslopes, QLD, 4120, Australië.",
  "Clear": "Duidelijk",
  "Date": "Datum",
  "Signed_by_Mouse": "Ondertekend door muis *",
  "Name_if_consent_on_behalf_of_patient": "Naam (indien toestemming namens patiënt)",
  "Relationship_to_patient_if_applicable": "Relatie met patiënt indien van toepassing",
  "Choose_Sign_Type": "Kies ondertekeningstype*",
  "Draw": "Tekenen",
  "Signed_by_Keyboard": "Ondertekend door toetsenbord *",
  "Agreement": "Akkoord",
  "agree_that_I_have_read": "Ik ga akkoord dat ik dit toestemmingsformulier voor patiënten heb gelezen en begrepen en dat ik expliciet toestemming geef voor de verwerking van mijn persoonsgegevens voor de hierboven beschreven doeleinden.",
  "NOTE": "OPMERKING",
  "If_the_patient": "Indien de patiënt jonger is dan 18 jaar, moet dit worden ondertekend door hun ouder of wettelijke voogd.",
  "If_the_patient_is_deceased": "Indien de patiënt overleden is, moet dit worden ondertekend door hun naaste familielid.",
  "patient_signature": "Handtekening patiënt",
  "profile_updated": "Profiel bijgewerkt",
  "profile_not_updated": "Profiel niet bijgewerkt",
  "video": "Video",
  "radiology_form": "Radiologieformulier",
  "doc_document": "Document",
  "pathology_form": "Pathologieformulier",
  "text": "Tekst",
  "Document_Name": "Documentnaam",
  "email_sent_successfully": "Verzoek tot verwijdering succesvol verzonden.",
  "messages": "Berichten",
  "adult_knee": "Volwassen knie",
  "paediatric_knee": "Knie van een kind",
  "document": "Mijn documenten",
  "Check_in": "Inchecken",
  "info_icon": "Vul de patiëntenvragenlijst in",
  "reminder": "Notities",
  "Search_with_minimum_three_characters": "Zoeken met minimaal drie tekens",
  "Select": "Selecteer",
  "delete_account": "Account verwijderen",
  "DD_MM_YYYY": "DD / MM / JJJJ",
  "Female": "Vrouw",
  "Male": "Man",
  "want_to_disclose": "Niet bekendmaken",
  "enter_address": "Voer adres in",
  "logout": "Uitloggen",
  "full_name": "Volledige naam",
  "mmyy": "MM / JJ",
  "health_found": "Zorgverzekering",
  "Not_Applicable": "Niet van toepassing",
  "not_available": "Niet beschikbaar",
  "available": "Beschikbaar",
  "shared": "Gedeeld",
  "private": "Privé",
  "add_document": "Document toevoegen",
  "drap_and_drop": "Bestanden hier slepen en neerzetten",
  "or_browser_files": "of blader door bestanden op uw computer",
  "browse_files": "Bestanden bladeren",
  "download": "downloaden",
  "Oops_error": "Oeps! De gevraagde gegevens zijn niet gevonden.",
  "go_to_home": "Ga terug naar startpagina",
  "This_field_is_required": "Dit veld is verplicht",
  "PendingForm": "Ingediend formulier",
  "NoFollowUpRecords": "Geen follow-uprecords",
  "Right": "Rechts",
  "Both": "Beide",
  "Left": "Links",
  "shoulder": "Schouder",
  "hip_form": "Heup",
  "Foot_And_Ankle": "Voet en enkel",
  "Hand_And_Wrist": "Hand en pols",
  "General": "Algemeen",
  "adolescent_knee": "Adolescentie knie",
  "Elbow": "Elleboog",
  "title": "Titel",
  "description": "Omschrijving",
  "enter_your_description_here": "Voer hier de omschrijving in",
  "enter_your_title_here": "Voer hier de titel in",
  "progress": "In behandeling",
  "done": "Voltooid",
  "reset": "Reset",
  "send": "Verzenden",
  "Edit": "Bewerken",
  "delete": "Verwijderen",
  "Are_you_sure_want_to_done_the_remainder": "Weet u zeker dat u deze notitie wilt voltooien?",
  "Confirm": "Bevestigen",
  "Update": "Bijwerken",
  "success": "Succes",
  "error": "Fout",
  "Are_you_sure_you_want_to_delete": "Weet u zeker dat u wilt verwijderen?",
  "cancel": "Annuleren",
  "Hour_Time": "24-uurs tijd",
  "Knee": "Knie",
  "no": "Nee",
  "yes": "Ja",
  "Year": "0 jaar, 0 maanden, 0 dagen",
  "Bilateral": "Beidhandig",
  "Left_Arm": "Linkerschouder",
  "Right_Arm": "Rechterschouder",
  "out_of": "uit",
  "Right_Elbow": "Rechterelleboog",
  "Left_Elbow": "Linkerelleboog",
  "right_foot_ankle": "Rechtervoet/enkel",
  "left_foot_ankle": "Linkervoet/enkel",
  "Right_hand_and_wrist": "Rechterhand en pols",
  "Left_hand_and_wrist": "Linkerhand en pols",
  "Hip_Function_R": "Rechterheup",
  "Hip_Function_L": "Linkerheup",
  "Right_Knee": "Rechterknie",
  "Left_Knee": "Linkerknie",
  "Right_Pedi_Knee": "Rechterknie",
  "Left_Pedi_Knee": "Linkerknie",
  "Scoring_Self_Evaluation": "Score Zelfevaluatie",
  "presciption_form": "Voorschrift",
  "insurance_form": "Verzekering",
  "letter_form": "Brief",
  "refferal_form": "Verwijzing",
  "medical_form": "Medisch",
  "are_you_sure_delete_account": "Weet u zeker dat u uw account wilt verwijderen?",
  "Adult_Knee_Pain": "Pijn in volwassen knie",
  "Knee_pain_13_18_year": "Kniepijn",
  "day_pdata": "Dag",
  "days_pdata": "Dagen",
  "month_pdata": "Maand",
  "months_pdata": "Maanden",
  "year_pdata": "Jaar",
  "years_pdata": "Jaren",
  "Name": "Naam",
  "akunah_id": "Akunah ID",
  "mobile": "Telefoon",
  "email": "E-mail",
  "upload_a_profile": "Afbeelding uploaden",
  "No_special_characters_are_allowed": "Speciale tekens zijn niet toegestaan",
  "numbers_are_not_allowed": "Cijfers zijn niet toegestaan",
  "maximum_50_character_are_allowed": "Maximaal 50 tekens zijn toegestaan",
  "invalid_character": "Ongeldig teken",
  "fields_only_contain_10_character": "Veld bevat slechts 10 tekens",
  "maximum_10_Numbers_are_allowed": "Maximaal 10 cijfers zijn toegestaan",
  "Only_I": "Alleen cijfers zijn toegestaan",
  "invalid_home_phone_number": "Ongeldig telefoonnummer thuis",
  "special_characters_are_not_allowed": "Speciale tekens zijn niet toegestaan",
  "maximum_digit_are_allowed": "Maximaal 1 cijfer is toegestaan",
  "invalid_date": "Ongeldige datum",
  "maximum_10_digits_are_allowed": "Maximaal 10 cijfers zijn toegestaan",
  "switch_profile": "Profiel wisselen",
  "loading": "Bezig met laden",
  "error_max_profile_limit": "U heeft het maximum aantal accounts bereikt dat is toegestaan met één e-mailadres",
  "error_upcoming_appointments": "Geen aankomende afspraken gevonden",
  "error_past_visits": "Geen eerdere bezoeken gevonden",
  "error_documents": "Geen documenten gevonden",
  "country_hawaii": "(UTC-10:00) Hawaï-tijd",
  "country_tahiti": "(UTC-10:00) Tahiti",
  "country_pitcairn": "(UTC-08:00) Pitcairneilanden",
  "country_niue": "(UTC-11:00) Niue",
  "country_mountain": "(UTC-07:00) Mountain Time",
  "country_arizona": "(UTC-07:00) Mountain Time - Arizona",
  "country_whitehorse": "(UTC-08:00) Pacifische tijd - Whitehorse",
  "country_chihuahua": "(UTC-07:00) Mountain Time - Chihuahua, Mazatlan",
  "country_edmonton": "(UTC-07:00) Mountain Time - Edmonton",
  "country_gambier": "(UTC-09:00) Gambiereilanden",
  "country_yellowknife": "(UTC-07:00) Mountain Time - Yellowknife",
  "country_dawson": "(UTC-07:00) Mountain Time - Dawson Creek",
  "country_belize": "(UTC-06:00) Belize",
  "country_hermosillo": "(UTC-07:00) Mountain Time - Hermosillo",
  "country_mexico": "(UTC-06:00) Centrale tijd - Mexico-Stad",
  "country_regina": "(UTC-06:00) Centrale tijd - Regina",
  "country_tegucigalpa": "(UTC-06:00) Centrale tijd - Tegucigalpa",
  "country_centraltime": "(UTC-06:00) Centrale tijd",
  "country_rarotonga": "(UTC-10:00) Rarotonga",
  "country_pago": "(UTC-11:00) Pago Pago",
  "country_pacific": "(UTC-08:00) Pacific Time",
  "country_alaska": "(UTC-09:00) Alaskatijd",
  "country_vancouver": "(UTC-08:00) Pacific Time - Vancouver",
  "country_tijuana": "(UTC-08:00) Pacific Time - Tijuana",
  "country_salvador": "(UTC-06:00) El Salvador",
  "country_costa": "(UTC-06:00) Costa Rica",
  "country_winnipeg": "(UTC-06:00) Central Time - Winnipeg",
  "country_galapagos": "(UTC-06:00) Galapagoseilanden",
  "country_managua": "(UTC-06:00) Managua",
  "country_america_cancun": "(UTC-05:00) Amerika Cancun",
  "country_guatemala": "(UTC-06:00) Guatemala",
  "country_bogota": "(UTC-05:00) Bogota",
  "country_easter_island": "(UTC-05:00) Paaseiland",
  "country_iqaluit": "(UTC-05:00) Oostelijke Tijd - Iqaluit",
  "country_toronto": "(UTC-05:00) Oostelijke Tijd - Toronto",
  "country_eastern_time": "(UTC-05:00) Oostelijke Tijd",
  "country_havana": "(UTC-05:00) Havana",
  "country_lima": "(UTC-05:00) Lima",
  "country_jamaica": "(UTC-05:00) Jamaica",
  "country_panama": "(UTC-05:00) Panama",
  "country_port_au_prince": "(UTC-05:00) Port-au-Prince",
  "country_nassau": "(UTC-05:00) Nassau",
  "country_rio_branco": "(UTC-05:00) Rio Branco",
  "country_barbados": "(UTC-04:00) Barbados",
  "country_halifax": "(UTC-04:00) Atlantische Tijd - Halifax",
  "country_bermuda": "(UTC-04:00) Bermuda",
  "country_boa_vista": "(UTC-04:00) Boa Vista",
  "country_curacao": "(UTC-04:00) Curaçao",
  "country_marquesas": "(UTC-09:30) Marquesaseilanden",
  "country_caracas": "(UTC-04:00) Caracas",
  "country_grand_turk": "(UTC-04:00) Grand Turk",
  "country_la_paz": "(UTC-04:00) La Paz",
  "timezone_guyana": "(UTC-04:00) Guyana",
  "timezone_port_of_spain": "(UTC-04:00) Port of Spain",
  "timezone_manaus": "(UTC-04:00) Manaus",
  "timezone_martinique": "(UTC-04:00) Martinique",
  "timezone_porto_velho": "(UTC-04:00) Porto Velho",
  "timezone_santo_domingo": "(UTC-04:00) Santo Domingo",
  "timezone_puerto_rico": "(UTC-04:00) Puerto Rico",
  "timezone_araguaina": "(UTC-03:00) Araguaina",
  "timezone_belem": "(UTC-03:00) Belém",
  "timezone_buenos_aires": "(UTC-03:00) Buenos Aires",
  "timezone_newfound_time": "(UTC-03:30) Newfoundland-tijd - St. Johns",
  "timezone_asuncion": "(UTC-03:00) Asuncion",
  "timezone_campo": "(UTC-03:00) Campo Grande",
  "timezone_cayenne": "(UTC-03:00) Cayenne",
  "timezone_cuiaba": "(UTC-03:00) Cuiaba",
  "timezone_maceio": "(UTC-03:00) Maceio",
  "timezone_godthab": "(UTC-03:00) Godthab",
  "timezone_fortaleza": "(UTC-03:00) Fortaleza",
  "timezone_miquelon": "(UTC-03:00) Miquelon",
  "timezone_palmer": "(UTC-03:00) Palmer",
  "timezone_montevideo": "(UTC-03:00) Montevideo",
  "timezone_recife": "(UTC-03:00) Recife",
  "timezone_rothera": "(UTC-03:00) Rothera",
  "timezone_paramaribo": "(UTC-03:00) Paramaribo",
  "timezone_punta_arenas": "(UTC-03:00) Punta Arenas",
  "timezone_santig": "(UTC-03:00) Santiago",
  "timezone_salvador": "(UTC-03:00) Salvador",
  "timezone_stanley": "(UTC-03:00) Stanley",
  "timezone_south_georgia": "(UTC-02:00) Zuid-Georgië",
  "timezone_noronha": "(UTC-02:00) Noronha",
  "timezone_sao_pulo": "(UTC-02:00) São Paulo",
  "timezone_azores": "(UTC-01:00) Azoren",
  "timezone_abidjan": "(UTC+00:00) Abidjan",
  "timezone_cape_verde": "(UTC-01:00) Kaapverdië",
  "timezone_scoresbysund": "(UTC-01:00) Scoresbysund",
  "timezone_bissau": "(UTC+00:00) Bissau",
  "timezone_accra": "(UTC+00:00) Accra",
  "timezone_danmarkshavn": "(UTC+00:00) Danmarkshavn",
  "timezone_casablanca": "(UTC+00:00) Casablanca",
  "timezone_dublin": "(UTC+00:00) Dublin",
  "timezone_canary_islands": "(UTC+00:00) Canarische Eilanden",
  "timezone_el_aaiun": "(UTC+00:00) El Aaiun",
  "timezone_thule": "(UTC-04:00) Thule",
  "timezone_gmt": "(UTC+00:00) GMT (geen zomertijd)",
  "timezone_lisbon": "(UTC+00:00) Lissabon",
  "timezone_faeroe": "(UTC+00:00) Faeröer",
  "timezone_reykjavik": "(UTC+00:00) Reykjavik",
  "timezone_monrovia": "(UTC+00:00) Monrovia",
  "timezone_algiers": "(UTC+01:00) Algiers",
  "timezone_london": "(UTC+00:00) Londen",
  "timezone_amsterdam": "(UTC+01:00) Amsterdam",
  "timezone_andorra": "(UTC+01:00) Andorra",
  "timezone_berlin": "(UTC+01:00) Berlijn",
  "timezone_budapest": "(UTC+01:00) Boedapest",
  "timezone_ceuta": "(UTC+01:00) Ceuta",
  "timezone_brussels": "(UTC+01:00) Brussel",
  "timezone_copenhagen": "(UTC+01:00) Kopenhagen",
  "timezone_gibrltar": "(UTC+01:00) Gibraltar",
  "timezone_prague": "(UTC+01:00) Midden-Europese Tijd - Praag",
  "timezone_lagos": "(UTC+01:00) Lagos",
  "timezone_luxembourg": "(UTC+01:00) Luxemburg",
  "timezone_madrid": "(UTC+01:00) Madrid",
  "timezone_malta": "(UTC+01:00) Malta",
  "timezone_ndjamena": "(UTC+01:00) Ndjamena",
  "timezone_paris": "(UTC+01:00) Parijs",
  "timezone_oslo": "(UTC+01:00) Oslo",
  "timezone_monaco": "(UTC+01:00) Monaco",
  "timezone_tirane": "(UTC+01:00) Tirana",
  "timezone_rome": "(UTC+01:00) Rome",
  "timezone_vienna": "(UTC+01:00) Wenen",
  "timezone_tunis": "(UTC+01:00) Tunis",
  "timezone_warsaw": "(UTC+01:00) Warschau",
  "timezone_amman": "(UTC+02:00) Amman",
  "timezone_zurich": "(UTC+01:00) Zürich",
  "timezone_athens": "(UTC+02:00) Athene",
  "timezone_burcharest": "(UTC+02:00) Boekarest",
  "timezone_chisinau": "(UTC+02:00) Chisinau",
  "timezone_stockholm": "(UTC+01:00) Stockholm",
  "timezone_cairo": "(UTC+02:00) Caïro",
  "timezone_gaza": "(UTC+02:00) Gaza",
  "timezone_jerusalem": "(UTC+02:00) Jeruzalem",
  "timezone_johannesburg": "(UTC+02:00) Johannesburg",
  "timezone_helsinki": "(UTC+02:00) Helsinki",
  "timezone_khartoum": "(UTC+02:00) Khartoem",
  "timezone_belgrade": "(UTC+01:00) Midden-Europese Tijd - Belgrado",
  "timezone_damascus": "(UTC+02:00) Damascus",
  "timezone_maputo": "(UTC+02:00) Maputo",
  "timezone_kaliningrad": "(UTC+02:00) Moskou-01 - Kaliningrad",
  "timezone_riga": "(UTC+02:00) Riga",
  "timezone_kiev": "(UTC+02:00) Kiev",
  "timezone_nicosia": "(UTC+02:00) Nicosia",
  "timezone_tallinn": "(UTC+02:00) Tallinn",
  "timezone_tripoli": "(UTC+02:00) Tripoli",
  "timezone_sofia": "(UTC+02:00) Sofia",
  "timezone_vilnius": "(UTC+02:00) Vilnius",
  "timezone_istanbul": "(UTC+03:00) Istanbul",
  "timezone_baghdad": "(UTC+03:00) Bagdad",
  "timezone_minsk": "(UTC+03:00) Minsk",
  "timezone_windhoek": "(UTC+02:00) Windhoek",
  "timezone_nairobi": "(UTC+03:00) Nairobi",
  "timezone_moscow": "(UTC+03:00) Moskou+00 - Moskou",
  "timezone_qatar": "(UTC+03:00) Qatar",
  "timezone_beirut": "(UTC+02:00) Beiroet",
  "timezone_riyadh": "(UTC+03:00) Riyad",
  "timezone_syowa": "(UTC+03:00) Syowa",
  "timezone_tehran": "(UTC+03:30) Teheran",
  "timezone_baku": "(UTC+04:00) Bakoe",
  "timezone_dubai": "(UTC+04:00) Dubai",
  "timezone_mahe": "(UTC+04:00) Mahe",
  "timezone_mauritius": "(UTC+04:00) Mauritius",
  "timezone_tbilisi": "(UTC+04:00) Tbilisi",
  "timezone_samara": "(UTC+04:00) Moskou+01 - Samara",
  "timezone_reunion": "(UTC+04:00) Réunion",
  "timezone_yerevan": "(UTC+04:00) Jerevan",
  "timezone_kabul": "(UTC+04:30) Kaboel",
  "timezone_aqtau": "(UTC+05:00) Aktau",
  "timezone_aqtobe": "(UTC+05:00) Aqtöbe",
  "timezone_ashgabat": "(UTC+05:00) Asjchabad",
  "timezone_dushanbe": "(UTC+05:00) Dushanbe",
  "timezone_maldives": "(UTC+05:00) Malediven",
  "timezone_yekaterinburg": "(UTC+05:00) Moskou+02 - Jekaterinenburg",
  "timezone_kerguelen": "(UTC+05:00) Kerguelen",
  "timezone_mawson": "(UTC+05:00) Mawson",
  "timezone_tashkent": "(UTC+05:00) Tasjkent",
  "timezone_colombo": "(UTC+05:30) Colombo",
  "timezone_kathmandu": "(UTC+05:45) Kathmandu",
  "timezone_omsk": "(UTC+06:00) Moskou+03 - Omsk",
  "timezone_india_standard_time": "(UTC+05:30) India Standard Time",
  "timezone_chagos": "(UTC+06:00) Chagos",
  "timezone_dhaka": "(UTC+06:00) Dhaka",
  "timezone_bishkek": "(UTC+06:00) Bisjkek",
  "timezone_cocos": "(UTC+06:30) Cocoseilanden",
  "timezone_thimphu": "(UTC+06:00) Thimphu",
  "timezone_almaty": "(UTC+06:00) Almaty",
  "timezone_vostok": "(UTC+06:00) Vostok",
  "timezone_rangoon": "(UTC+06:30) Rangoon",
  "timezone_christmas": "(UTC+07:00) Christmas",
  "timezone_davis": "(UTC+07:00) Davis",
  "timezone_karachi": "(UTC+05:00) Karachi",
  "timezone_hanoi": "(UTC+07:00) Hanoi",
  "timezone_beijing": "(UTC+08:00) China-tijd - Peking",
  "timezone_hongkong": "(UTC+08:00) Hongkong",
  "timezone_hovd": "(UTC+07:00) Hovd",
  "timezone_jakarta": "(UTC+07:00) Jakarta",
  "timezone_kuala_lumpur": "(UTC+08:00) Kuala Lumpur",
  "timezone_bangkok": "(UTC+07:00) Bangkok",
  "timezone_krasnoyarsk": "(UTC+07:00) Moskou+04 - Krasnojarsk",
  "timezone_brunei": "(UTC+08:00) Brunei",
  "timezone_macau": "(UTC+08:00) Macau",
  "timezone_makassar": "(UTC+08:00) Makassar",
  "timezone_choibalsan": "(UTC+08:00) Choibalsan",
  "timezone_ulaanbaatar": "(UTC+08:00) Ulaanbaatar",
  "timezone_manila": "(UTC+08:00) Manilla",
  "timezone_pyongyang": "(UTC+08:30) Pyongyang",
  "timezone_singapore": "(UTC+08:00) Singapore",
  "timezone_irkutsk": "(UTC+08:00) Moskou+05 - Irkoetsk",
  "timezone_taipei": "(UTC+08:00) Taipei",
  "timezone_jayapura": "(UTC+09:00) Jayapura",
  "timezone_dili": "(UTC+09:00) Dili",
  "timezone_perth": "(UTC+08:00) Westelijke tijd - Perth",
  "timezone_yakutsk": "(UTC+09:00) Moskou+06 - Jakutsk",
  "timezone_palau": "(UTC+09:00) Palau",
  "timezone_seoul": "(UTC+09:00) Seoul",
  "timezone_tokyo": "(UTC+09:00) Tokio",
  "timezone_darwin": "(UTC+09:30) Centrale tijd - Darwin",
  "timezone_dumont": "(UTC+10:00) Dumont d'Urville",
  "timezone_brisbane": "(UTC+10:00) Oostelijke tijd - Brisbane",
  "timezone_guam": "(UTC+10:00) Guam",
  "timezone_vladivostok": "(UTC+10:00) Moskou+07 - Vladivostok",
  "timezone_port_moresby": "(UTC+10:00) Port Moresby",
  "timezone_truk": "(UTC+10:00) Truk",
  "timezone_adelaide": "(UTC+10:30) Centrale tijd - Adelaide",
  "timezone_casey": "(UTC+11:00) Oostelijke tijd - Hobart",
  "timezone_melbourne_sydney": "(UTC+11:00) Oostelijke tijd - Melbourne, Sydney",
  "timezone_kosrae": "(UTC+11:00) Kosrae",
  "timezone_efate": "(UTC+11:00) Efate",
  "timezone_guadalcanal": "(UTC+11:00) Guadalcanal",
  "timezone_ponape": "(UTC+11:00) Ponape",
  "timezone_norfolk": "(UTC+11:00) Norfolk",
  "timezone_magadan": "(UTC+11:00) Moskou+08 - Magadan",
  "timezone_noumea": "(UTC+11:00) Nouméa",
  "timezone_majuro": "(UTC+12:00) Majuro",
  "timezone_funafuti": "(UTC+12:00) Funafuti",
  "timezone_nauru": "(UTC+12:00) Nauru",
  "timezone_tarawa": "(UTC+12:00) Tarawa",
  "timezone_kwajalein": "(UTC+12:00) Kwajalein",
  "timezone_enderbury": "(UTC+13:00) Enderbury",
  "timezone_wake": "(UTC+12:00) Wake",
  "timezone_fakaofo": "(UTC+13:00) Fakaofo",
  "timezone_wallis": "(UTC+12:00) Wallis",
  "timezone_fiji": "(UTC+13:00) Fiji",
  "timezone_apia": "(UTC+14:00) Apia",
  "timezone_kiritimati": "(UTC+14:00) Kiritimati",
  "timezone_tongatapu": "(UTC+13:00) Tongatapu",
  "timezone_auckland": "(UTC+13:00) Auckland",
  "dateFormet": "Datum format",
  "Org_success": "Verzoek om lid te worden van de organisatie is succesvol verzonden",
  "Org_err": "Je bent al lid van de organisatie",
  "Request_Organization": "Verzoek organisatie",
  "organization_requested": "Organisatie aangevraagd",
  "join": "Lid worden",
  "your_email": "email@adres.com",
  "medical_summary": "You have not filled up your medical summary yet.",
  "medical_summary_msg": "My medical summary",
  "medical_success": "Medical summary submitted successfully",
  "patient_form_popup": "Do you wish to continue filling out the questionnaire you were working on earlier?",
  "Please_confirm_that_your_details_are_correct": "Please confirm that your details are correct",
  "Welcome_to_Akunah_your_Health_Passport": "Welcome to Akunah, your Health Passport.",
  "Your_helth_is_in_your_hands": "Your health is in your hands.",
  "Lets_begin_your_journey": "Let's begin your journey.",
  "Please_complete_your_profile": "Please complete your profile.",
  "Profile_complete": "Profile complete!",
  "Would_you_like_to_add_another_profile_for_a_family_member": "Would you like to add another profile for a family member?",
  "Alert_detected": "Alert: Multiple logins detected",
  "To_safeguard_your_data": "To safeguard your data privacy and security, the webpage has timed out.",
  "Kindly_close_all_other_Akunah": "Kindly close all other Akunah tabs and press “Back to home” to continue.",
  "Akunah_Team_is_dedicated": "Akunah Team is dedicated to protecting your data.",
  "Last_Created": "Last Created",
  "First_Created": "First Created",
  "Alphabetically": "Alphabetically (A-Z)",
  "Alphabetically1": "Alphabetically (Z-A)",
  "Your_form_submission_was_successful_Clinician": "Your form submission was successful. We are now automatically redirecting to the Clinician app",
  "Your_form_submission_was_successful_Patient": "Your form submission was successful. We are now automatically redirecting to the Patient app",
  "If_this_doesnt_happen_within": "If this doesn’t happen within 5 seconds, please",
  "click_here": "click here",
  "Save": "Save",
  "Proceed_to_my_dashboard": "Proceed to my dashboard",
  "myJourney": "My Journey",
  "Home": "Home",
  "No_journey": "No journey found",
  "Share_results_with_your_doctor": "Share results with your doctor",
  "If_you_would_like_to_share_your_results_with": "If you would like to share your results with your doctor, select Share NOW below",
  "Link_PROMs": "Link PROMs",
  "Welcome_to_akunah": "Welcome to akunah",
  "Share_to_your_Doctor_using_one_of_the_3_options_below": "Share with your Doctor using one of the 3 options below",
  "Doctor_Code": "Doctor Code",
  "Enter_the_code_that_has_been_provided_to_you_by_your": "Enter the code that has been provided to you by your doctor in your email or in the clinic. If the code is not available, please check your recent visits or search for your doctor",
  "Recent_Visits": "Recent Visits",
  "Select_your_recent_doctor_or": "Select your recent doctor or",
  "organisation_here": "organisation here",
  "Search": "Search",
  "Search_for_your_doctor_or": "Search for your doctor or",
  "Please_confirm_that_the_following_details": "Please confirm that the following details are correct",
  "Organisation": "Organisation",
  "PROMs_Specialty_Date": "PROMs Specialty & Date",
  "Get_started_by_completing_your": "Get started by completing your PROMs questionnaires",
  "and_exploring_your_progress": "and exploring your progress",
  "Fill_up_your_PROMs_questionnaire": "Fill up your PROMs questionnaire",
  "Hip": "Hip",
  "My_Progress": "My Progress",
  "Completed": "Completed",
  "Expired": "Expired",
  "Incomplete": "Incomplete",
  "Specialty": "Specialty",
  "Doctor": "Doctor",
  "Status": "Status",
  "View_form": "View form",
  "Share_to_another_doctor": "Share with another doctor",
  "Edit_form": "Edit form",
  "Answer_the_form": "Answer the form",
  "Complete_the_form": "Complete the form",
  "Delete_the_form": "Delete the form",
  "Share_to_doctor": "Share with doctor",
  "Your_account_has_been_created": "Your account has been created",
  "Please_click_below_to_proceed_to_your_dashboard": "Please click below to proceed to your dashboard",
  "If_you_would_like_to_add_another_profile_for_a_family": "If you would like to add another profile for a family member please",
  "Share": "Share",
  "Hip_Scoring_Self_Evaluation_Questionnaire": "Hip Scoring Self-Evaluation Questionnaire",
  "Hand_and_Wrist_Scoring_Self_Evaluation_Questionnaire": "Hand & Wrist Scoring Self-Evaluation Questionnaire",
  "Right_Hand_nd_Wrist": "Right Hand & Wrist",
  "Left_Hand_nd_Wrist": "Left Hand & Wrist",
  "DOB_1": "Date of Birth",
  "Sex_1": "Sex",
  "Address_1": "Address",
  "Please_Choose_PROMs_to_link": "Please choose PROMs to link",
  "Please_select_atleast_one_form_or_close": "Please select at least one PROMs or close",
  "cant_find_appropriate_PROMs_to_link": "Can't find appropriate PROMs to link?",
  "Request_New_PROMs": "Request New PROMs",
  "Warning_This_patient_has_an_incomplete_patient_PROMs_from_a_recent_visit": "Warning: You have an incomplete questionnaire from a recent visit.",
  "If_you_select_No_a_new_patient_PROMs_will_be_created_and_the_incomplete_PROMs_will_be_deleted": "If you select \"No\", a new questionnaire will be created and the incomplete one will be deleted.",
  "Would_you_like_to_continue_this_form": "Would you like to continue with this?",
  "Note": "Note:",
  "Expiring_in": "Expiring in",
  "Date_Created": "Date Created",
  "Days": "Days",
  "Shared": "Shared",
  "Pending": "Pending",
  "Initial": "Initial",
  "Recent": "Recent",
  "Pain": "Pain",
  "Function": "Function",
  "Share_Now": "Share Now",
  "Share_Later": "Share Later",
  "Updated": "Updated",
  "Display_More": "Display More",
  "Address_Now": "Address Now",
  "Address_Later": "Address Later",
  "You_have_pending_questionnaires": "You have pending questionnaires",
  "Notifications": "Notifications",
  "Form_shared_successfully": "Form shared successfully",
  "You_have_already_shared_with_same_doctor_and_organization": "You have already shared with same doctor and organization",
  "No_recent_notifications": "No recent notifications",
  "Have_a_good_day": "Have a good day",
  "Patient_scores": "Patient scores",
  "Display less": "Display less",
  "myJourney_Shoulder": "Shoulder",
  "myJourney_Shoulder_txt": "Select shoulder to view your Shoulder journey",
  "myJourney_Elbow": "Elbow",
  "myJourney_Elbow_txt": "Select elbow to view your Elbow journey",
  "myJourney_Hand_Wrist": "Hand & Wrist",
  "myJourney_Hand_Wrist_txt": "Select Hand & Wrist to view your Hand & wrist journey",
  "myJourney_Hip": "Hip",
  "myJourney_Hip_txt": "Select Hip to view your Hip journey",
  "myJourney_knee": "Knee",
  "myJourney_knee_txt": "Select Knee to view your Knee journey",
  "myJourney_Foot_Ankle": "Foot & Ankle",
  "myJourney_Foot_Ankle_txt": "Select Foot & Ankle to view your Foot & Ankle journey",
  "data_sort": "Sort",
  "Visit_Clinic": "Visit Clinic",
  "My_Visits": "My Visits",
  "Patient_Scoring_Forms": "Patient Scoring Forms",
  "Other_findings": "Other findings",
  "Organization": "Organization",
  "more": "More",
  "mediacal_summary": "Medical Summary",
  "my_consent": "My Consents",
  "Are_you_sure_you_want_to_reject": "Are you sure you want to block this referral?",
  "reject": "Reject",
  "i_accept_consent": "I consent to this",
  "grant": "Grant",
  "no_conscent_found": "No consent found",
  "rejected": "Rejected",
  "No_Time_Lines": "No Timelines",
  "Hide_Score": "Hide Score",
  "Show_Score": "Show Score",
  "patient_sex": "Geslacht",
  "Dominant_hand_1": "Dominante hand",
  "patient_Initial_Assessment": "Initial Assessment",
  "patient_Treatment_Category": "Behandelingscategorie",
  "View_all": "View all",
  "Display_Less": "Display Less",
  "patient_general_past_Medical_history": "Medische geschiedenis",
  "patient_general_past_Medication_name": "List of medications",
  "Evaluated_Knee": "Evaluated Knee",
  "Is_your_unaffected_Hand_Wrist_pain_free_and_allows_normal_function": "Is uw niet-aangedane hand en pols pijnvrij en staat deze normale functie toe?",
  "Evaluated_Hip": "Evaluated Hip",
  "Evaluated_Foot_Ankle": "Evaluated Foot and Ankle",
  "patient_Evaluated_Elbow": "Evaluated Elbow",
  "Is_your_unaffected_Foot_Ankle_pain_free_and_allows_normal_function": "Is uw niet-aangedane voet en enkel pijnvrij en staat deze normale functie toe?",
  "Evaluated_Hand_Wrist": "Evaluated Hand and Wrist",
  "patient_shoulder_feeling_instability_q2": "Gelieve aan te geven hoe instabiel uw schouder is",
  "patient_shoulder_feeling_instability_q3": "Gelieve aan te geven hoe instabiel uw schouder is",
  "patient_Hand_BCTQ": "Hand And Wrist BCTQ",
  "Paediatric_Knee_Satisfaction": "Paediatric Knee Satisfaction",
  "patient_foot_ankle_symptoms": "Foot And Ankle Other Symptoms",
  "Right_Foot_and_Ankle": "Right Foot & Ankle",
  "Left_Foot_and_Ankle": "Left Foot & Ankle",
  "summary": "Summary",
  "Shoulder_Treatment_Form": "Shoulder Treatment Form",
  "Adolescent_knee_Treatment_Form": "Adolescent knee Treatment Form",
  "Adult_Knee_Treatment_Form": "Adult Knee Treatment Form",
  "Paediatric_Knee_Treatment_Form": "Paediatric Knee Treatment Form",
  "Hip_Treatment_Form": "Hip Treatment Form",
  "Foot_Ankle_Treatment_Form": "Foot and Ankle Treatment Form",
  "Elbow_Treatment_Form": "Elbow Treatment Form",
  "Hand_Wrist_Treatment_Form": "Hand & Wrist Treatment Form",
  "Upload_complete": "Upload complete",
  "Select_Date_Format": "Select Date Format",
  "timezone_casey1": "(UTC+11:00) Casey",
  "timezone_Kamchatskiy": "(UTC+12:00) Moskou+09 - Petropavlovsk-Kamtsjatski",
  "timezone_Guayaquil": "(UTC-05:00) Guayaquil",
  "requested": "requested a",
  "Display_name": "Display name",
  "Are_you_sure_you_want_to_delete_Consent": "Are you sure you want to reject this consent?",
  "form": "form",
  "Requested_by": "Requested by",
  "requested_hip_form": "requested a Hip form",
  "requested_shoulder_form": "requested a Shoulder form",
  "requested_knee_form": "requested a Knee form",
  "requested_foot_form": "requested a Foot and Ankle form",
  "requested_hand_form": "requested a Hand And Wrist form",
  "requested_elbow_form": "requested an Elbow form",
  "requested_General_form": "requested a General form",
  "requested_Paediatric_form": "requested a Paediatric Knee form",
  "requested_Adolescent_form": "requested a Adolescent Knee form",
  "Expiring_in_Tomorrow": "Expiring tomorrow",
  "PROMs_questionnaire": "Dr. [doctor’s full name who requested] has requested you to fill out [specialty] PROMs questionnaire",
  "new_message": "You have a new message from [full name of message sender]",
  "new_appointment": "You have a new appointment with Dr. [doctor’s full name]",
  "upcoming_appointment": "Reminder: upcoming appointment with Dr. [full name] on [date]",
  "pending_message": "Reminder: you have a pending [specialty] PROMs questionnaire",
  "document_message": "Dr. [full name of who shared] shared a document with you",
  "requested_message": "Dr. [full name of who requested] has requested for a consent",
  "patient_added_message": "You have been added as a patient of Dr [full name] in [organisation]",
  "cancelled_appointment_message": "Your appointment with Dr [full name] on [date of appointment] has been cancelled.",
  "Mark_all_read": "Mark all as read",
  "Delete_All": "Delete All",
  "No_Notification": "No notifications found",
  "You_have_not": "You have not started your journey yet. Please fill out a PROMs questionnaire to begin your journey.",
  "pass_verification1": "Password should be at least 8 characters long *",
  "pass_verification2": "Password must contain at least 1 upper case character*",
  "pass_verification3": "Password must contain at least 1 lower case character*",
  "pass_verification4": "Password must contain at least 1 digit *",
  "loginTitle": "Akunah:Sign In ::",
  "Create_patient_mobile": "Create new profile",
  "Select_Profile_mobile": "Select your profile",
  "Profile_Created_mobile": "Profile Created",
  "Your_account_has_been_created_mobile": "Your account has been created.",
  "Please_tap_below_to_sign_in_to_your_workspace_mobile": "Please tap below to sign in to your workspace.",
  "Start_Journey_mobile": "Start Journey",
  "If_add_another_profile_mobile": "If you would like to add another profile for a family member, please",
  "Create_Profile_mobile": "Create Profile",
  "change_Profile_Pic_mobile": "Change profile photo",
  "MobileImp_mobile": "Mobile*",
  "Gender_mobile": "Gender",
  "Country_1_mobile": "Country",
  "Med_1_mobile": "Med",
  "Appoinments_mobile": "Appointments",
  "View_Profile_mobile": "View Profile",
  "Sign_out_mobile": "Sign out",
  "My_Appointments_mobile": "My Appointments",
  "Appoinments_Past_mobile": "Past",
  "No_appointments_mobile": "No appointments",
  "When_appointment_available_mobile": "When your next appointment is available, it will be displayed here",
  "Available_soon_mobile": "Available soon!",
  "please_akunah_desktop_mobile": "To access it now, please login tome.akunah.comon your desktop.",
  "All_Notes_mobile": "All Notes",
  "AddButton_mobile": "AddButton",
  "Start_your_note_mobile": "Start your note...",
  "Done_mobile": "Done",
  "Note_Title_mobile": "Note Title",
  "New_Consents_mobile": "New Consents",
  "Signed_Consents_mobile": "Signed Consents",
  "Consent_mobile": "Consent",
  "Signed_Type_mobile": "Signed by Type",
  "Submit_Consent_mobile": "Submit Consent",
  "Reject_Consent_mobile": "Reject Consent",
  "Type_name_mobile": "Type your name",
  "Signature_mobile": "Signature",
  "No_consents_mobile": "No consents",
  "Any_display_ready_mobile": "Any new or signed consents will display here when ready",
  "Clinician_Documents_mobile": "Clinician Documents",
  "Uploaded_Documents_mobile": "Uploaded Documents",
  "Upload_Document_mobile": "Upload Document",
  "Upload_file_mobile": "Upload file",
  "Upload_photo_mobile": "Upload photo",
  "Take_photo_mobile": "Take photo",
  "Uploading_1_mobile": "Uploading",
  "New_1_mobile": "New",
  "Share_document_mobile": "Share this document",
  "Enter_email_mobile": "Enter email",
  "Add_message_like_mobile": "Add a message, if you’d like.",
  "Are_delete_account_mobile": "Are you sure you want to delete your account?",
  "No_display_mobile": "No documents to display",
  "All_clinician_documents_mobile": "All clinician documents will display here",
  "New_Messages_mobile": "New Messages",
  "Read_Messages_mobile": "Read Messages",
  "Any_new_unread_messages_ready_mobile": "Any new or unread messages will be displayed here when ready",
  "Shoulder_Journey_mobile": "Shoulder Journey",
  "Hip_Journey_mobile": "Hip Journey",
  "Foot_Ankle_Journey_mobile": "Foot and Ankle Journey",
  "Elbow_Journey_mobile": "Elbow Journey",
  "Knee_Journey_mobile": "Knee Journey",
  "Hand_Wrists_Journey_mobile": "Hand and Wrist Journey",
  "New_Form_mobile": "New Form",
  "Start_new_form_mobile": "Start new form",
  "Requested_Forms_mobile": "Requested Forms",
  "Incomplete_Forms_mobile": "Incomplete Forms",
  "Completed_Forms_mobile": "Completed Forms",
  "No_Available_mobile": "No Forms Available",
  "Start_new_journey_mobile": "Start a new form to begin your journey.",
  "View_Results_mobile": "View Results",
  "Complete_Form_mobile": "Complete Form",
  "Delete_Form_mobile": "Delete Form",
  "Edit_Details_mobile": "Edit Details",
  "Personal_Details_mobile": "Personal Details",
  "DOB_1_mobile": "DOB",
  "Unknown_Gender_mobile": "Unknown Gender",
  "Mobile_mobile": "Mobile",
  "Next_kin’s_name_mobile": "Next of kin’s name",
  "Next_kin’s_relationship_mobile": "Next of kin’s relationship",
  "GP_Details_mobile": "GP Details",
  "GP_Name_mobile": "GP Name",
  "Practice_mobile": "Practice",
  "Edit_Profile_mobile": "Edit Profile",
  "Full_Name_mobile": "Full Name",
  "Country_Code_mobile": "Country Code",
  "GP_Full_Name_mobile": "GP Full Name",
  "Doctor’s_full_name_mobile": "Doctor’s full name",
  "All_mobile": "All",
  "No_notifications_mobile": "No recent notifications",
  "Any_new_available_mobile": "Any new or unread notifications will be displayed here when available",
  "Account_mobile": "Account",
  "Manage_My_Account_mobile": "Manage My Account",
  "Change_Password_mobile": "Change Password",
  "Privacy_mobile": "Privacy",
  "Time_mobile": "Time",
  "Date_Format_mobile": "Date & Format",
  "Support_mobile": "Support",
  "All_Notifications_mobile": "All Notifications",
  "Receive_notifications_mobile": "Receive all notifications",
  "Journey_Updates_mobile": "Journey Updates",
  "Receive_appointment_notifications_mobile": "Receive all appointment notifications",
  "Start_Form_mobile": "Start Form",
  "Your_journey_starts_here_mobile": "Your journey starts here",
  "To_recovery_questions_mobile": "To get started with your journey to recovery, we just ask you to please best answer the following questions.",
  "Please_estimate_questions_mobile": "Please estimate 12-15 minutes to answer these questions.",
  "Shoulder_Form_mobile": "Shoulder Form",
  "Save_and_Continue_mobile": "Save & Continue",
  "How_years_smoke_mobile": "How many years did you smoke?",
  "patient_shoulder_pain_inner_value_mobile": "(0 means no pain, 15 means intolerable pain)",
  "Scoring_Key_mobile": "Scoring Key",
  "patient_shoulder_wosi_1_mobile": "Western Ontario Shoulder Instability Index (WOSI)",
  "patient_shoulder_wosi_sectionA_phy_mobile": "PHYSICAL SYMPTOMS",
  "patient_shoulder_wosi_sectionB_sp_mobile": "SPORTS/RECREATION/WORK",
  "patient_shoulder_wosi_sectionC_life_mobile": "LIFESTYLE",
  "patient_shoulder_wosi_sectionD_emo_mobile": "EMOTIONS",
  "Specific_AC_Score_mobile": "Specific AC Score (SACS)",
  "patient_shoulder_sacs_sectionA_pain_mobile": "Pain",
  "patient_shoulder_sacs_sectionB_function_mobile": "FUNCTION",
  "patient_shoulder_sacs_sectionC_Qua_mobile": "Quality of life and satisfaction",
  "Finish_mobile": "Finish",
  "Share_Results_mobile": "Share Results",
  "Share_Doctor_mobile": "Share to your Doctor",
  "Choose_share_results_doctor_mobile": "Choose one of the 3 options below to share your results with your doctor",
  "Share_your_Clinician_mobile": "Share with your Clinician",
  "Select_recent_doctor_organisation_mobile": "Select your recent doctor or organisation here",
  "Clinician_Search_mobile": "Clinician Search",
  "Search_doctor_organisation_mobile": "Search for your doctor or organisation here",
  "Clinician_Code_mobile": "Clinician Code",
  "Enter_doctor_code_search_mobile": "Enter the code that has been provided to you by your doctor in your email or in the clinic. If the code is not available, please check you recent visits or use the search.",
  "Skip_sharing_now_mobile": "Skip sharing for now",
  "Code_please_try_again_mobile": "Code incorrect, please try again.",
  "Confirm_details_mobile": "Confirm details",
  "PROMs_Speciality_Date_mobile": "PROMs Speciality & Date",
  "Results_Shared_mobile": "Results Shared!",
  "Your_successfully_doctor_mobile": "Your results has been successfully shared with your doctor.",
  "Please_results_mobile": "Please tap below to view your results.",
  "If_another_doctor_mobile": "If you would like to share with another doctor",
  "please_mobile": "please",
  "click_here_mobile": "click here",
  "Pain_Score_mobile": "Pain Score",
  "Recent_Scores_mobile": "Recent Scores",
  "Right_Arm_mobile": "Right Arm",
  "SACS_Score_mobile": "SACS",
  "complete_mobile": "Complete",
  "Add_Note_mobile": "Add Note",
  "Pending_Forms_mobile": "Pending Forms",
  "Your_pending__will_display_mobile": "Your pending forms will be displayed here",
  "Your_Incomplete_display_mobile": "Your Incomplete Forms will be displayed here",
  "Your_complete_display_mobile": "Your complete forms will be displayed here",
  "PROMs_Shoulder_request_mobile": "PROMs Shoulder request",
  "New_message_mobile": "New message",
  "New_appointment_mobile": "New appointment",
  "New_Consent_request_mobile": "New Consent request",
  "New_shared_document_mobile": "New shared document",
  "New_Organisation_Clinician_mobile": "New Organisation/Clinician",
  "This_field_cannot_empty_mobile": "This field cannot be empty.",
  "List_work_activities_mobile": "List work activities ...",
  "List_hobby_activities_mobile": "List hobby and sport activities ...",
  "patient_shoulder_wosi_sectionA_mobile": "SECTION A:",
  "patient_shoulder_wosi_sectionB_mobile": "SECTION B:",
  "patient_shoulder_wosi_sectionC_mobile": "Section C:",
  "patient_shoulder_wosi_sectionD_mobile": "Section D:",
  "File_size_should_than_10MB_mobile": "File size should not more than 10MB",
  "Share_this_document_mobile": "Share this document",
  "sac_explaination": "Explanation",
  "patient_shoulder_sacs_explanation_mobile": "For each question, you will be asked to rate either your level of pain or difficulty performing different tasks. The rating scale is designed so that 0 reflects no pain or difficulty while 10 reflects the most severe amount of pain or difficulty.",
  "patient_shoulder_sacs_a3_note_mobile": "(e.g. Laptop or hand bag strap).",
  "patient_shoulder_sacs_b2_note_mobile": "(e.g. Resting your arm in a pocket or using a sling).",
  "patient_shoulder_sacs_b3_note_mobile": "(e.g. Push up, cycling, yoga).",
  "patient_shoulder_sacs_b4_note_mobile": "(e.g. Bag of shopping).",
  "patient_shoulder_sacs_b5_note_mobile": "(e.g. To reach across and touch the unaffected shoulder).",
  "patient_shoulder_sacs_b6_note_mobile": "(e.g. Greater than five kilograms).",
  "patient_shoulder_sacs_b7_note_mobile": "(e.g. Holding the steering wheel whilst driving).",
  "patient_shoulder_sacs_c1_note_mobile": "(Including the presence of a lump).",
  "Function_Score_mobile": "Function Score",
  "Instability_Score_mobile": "Instability Score",
  "patient_shoulder_pain_q2_mobile": "On a scale of 0-15, what is the highest level of pain that you have experienced in your shoulder during ordinary activities within the last 24 hours?",
  "patient_shoulder_pain_q6_mobile": "Do you take pain medication",
  "patient_shoulder_pain_q6_mobile_sub": "(eg paracetamol etc)?",
  "patient_shoulder_pain_q6_a2_q1_mobile": "Do you take strong pain medication",
  "patient_shoulder_pain_q6_a2_q1_mobile_sub": "(eg codeine, tramadol, morphine etc)?",
  "patient_shoulder_feeling_instability_q1_b1_mobile_sub": "(as if it is going to dislocate)?",
  "patient_shoulder_feeling_instability_q1_mobile": "Does your shoulder feel unstable",
  "patient_shoulder_wosi_instruction_mobile": "INSTRUCTIONS",
  "Next_of_kins_number_mobile": "Next of kin’s number",
  "Next_of_Kin_mobile": "Next of Kin",
  "Doctor_Name_mobile": "Doctor's name",
  "Doctors_practice_mobile": "Doctor’s practice",
  "Doctors_practice_address_mobile": "Doctor’s practice address",
  "Language_mobile": "Languages",
  "No_Internet_Connection_mobile": "No Internet Connection",
  "Please_type_your_name_mobile": "Please type your name",
  "Choose_Sign_Type_mobile": "Choose Sign Type",
  "Signed_by_Draw_mobile": "Signed by Draw",
  "select_image_mobile": "select image",
  "Consent_rejected_mobile": "Consent rejected",
  "Consent_submitted_mobile": "Consent submitted",
  "Today_mobile": "Today",
  "New_mobile": "New",
  "No_recent_scores_display_mobile": "No recent scores to display",
  "All_recent_scores_display_here_mobile": "All recent scores will display here",
  "Shared_Documents_mobile": "Shared Documents"
}