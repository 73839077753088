import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Tabs, Tab, Badge } from "react-bootstrap";
import "./Tab.css";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import useAxios from "../../../axiosinstance";
export default function FootAnkleTab({ rId }) {
    const instabilityACjoint = useSelector(
        (state) => state.shoulderScore.functionScore[0].instability_acjoint
    );
    const feel_unstable = useSelector(
        (state) => state.shoulderScore.functionScore[0].feel_unstable
    );
    const FootInsta = useSelector((state) => state.footAnkle.instabilityScore[0]);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(window.location.search);
    const pId = queryParams.get("akpt");
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    // const [key, setKey] = useState(splitLocation[1]);
      const [key, setKey] = useState(() => {
        const path = location.pathname;
        if (path.includes("foot-ankle-pain")) return "pain";
        if (path.includes("foot-ankle-instability")) return "instability";
        if (path.includes("foot-ankle-other-symptoms")) return "symptoms";
        if (path.includes("foot-ankle-function")) return "function";
        if (path.includes("foot-ankle-quality-of-life")) return "quality-of-life";
        if (path.includes("foot-ankle-satisfaction")) return "satisfaction";
        if (path.includes("cumberland-ankle")) return "cumberland";
        if (path.includes("doctor-feedback/step-1")) return "foot-doctor-feedback";
        if (path.includes("doctor-feedback/step-2")) return "foot-doctor-feedback-step-2";
        if (path.includes("doctor-feedback/step-3")) return "foot-doctor-feedback-step-3";
        return "personal"; // Default tab
    });
    const is_doctor_form = queryParams.get("is_doctor_form");
    // useEffect(()=>{
    //     localStorage.setItem("isdoctorForm",is_doctor_form)
    // },[is_doctor_form])
    const axiosinstance = useAxios();
    const [patientStatus, setPatientStatus] = useState(false);
    const [doctorStatus, setDoctorStatus] = useState(false);
    const [ptid, setPtId] = useState("");
    const getTab = () => {
        axiosinstance.current
            .get(`extapp/forms/all_forms?resource_id=${rId}&type=21`)
            .then((res) => {
                // console.log(res.data.doctorStatus, res.data.status, "responce");
                setPatientStatus(res.data.status);
                setDoctorStatus(res.data.doctorStatus);
                setPtId(res.data.data2[0].ak_pt);
                if (res.data.status) {
                    // handleTabSelect("pain");
                } else if (res.data.doctorStatus) {
                    handleTabSelect("doctor-feedback/step-1");
                } else {
                    navigate("/un-authorized-access");
                }
            })
            .catch((err) => {
                // navigate('/un-authorized-access');
            });
    };
    useEffect(() => {
        getTab();
    }, []);
    const [ankleFeelL, setAnkleFeelL] = useState("");
    const [ankleFeelR, setAnkleFeelR] = useState("");
    const [ankleDiagnosedL, setAnkleDiagnosedL] = useState("");
    const [ankleDiagnosedR, setAnkleDiagnosedR] = useState("");
    const [doesUneffectedR, setDoesUneffectedR] = useState("0");
    useEffect(() => {
        setAnkleFeelL(FootInsta.ankleFeelL);
        setAnkleFeelR(FootInsta.ankleFeelR);
        setAnkleDiagnosedL(FootInsta.ankleDiagnosedL);
        setAnkleDiagnosedR(FootInsta.ankleDiagnosedR);
        // setDoesUneffectedL(FootInsta.doesUneffectedL);
        setDoesUneffectedR(FootInsta.doesUneffectedR);
      }, [FootInsta]);
      let doctordata=localStorage.getItem("isdoctorForm")
      let akDc = sessionStorage.getItem("akDc");
      let akOu = sessionStorage.getItem("akOu");
    const handleTabSelect = (selectedTab) => {
        setKey(selectedTab);
        switch (selectedTab) {
            case "personal":
        navigate(
          `/personal-data-edit/${window.btoa(
            ptid
          )}/${akDc}/${akOu}/${window.btoa(
            21
          )}?is_doctor_form=true&doctorvalue=${0}&doctor=true&resource_id=${rId}`
        );
        break;
            case "pain":
                navigate(`/foot-ankle-pain/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
                break;
            case "instability":
                navigate(`/foot-ankle-instability/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
                break;
            case "cumberland":
                navigate(`/cumberland-ankle/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
                break;
            case "symptoms":
                navigate(`/foot-ankle-other-symptoms/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
                break;
            case "function":
                navigate(`/foot-ankle-function/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
                break;
            case "quality-of-life":
                navigate(`/foot-ankle-quality-of-life/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
                break;
            case "satisfaction":
                navigate(`/foot-ankle-satisfaction/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
                break;


            case "doctor-feedback/step-1":
                navigate(`/foot-doctor-feedback/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
                break;

            case "doctor-feedback/step-2":
                navigate(`/foot-doctor-feedback-step-2/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
                break;
            case "doctor-feedback/step-3":
                navigate(`/foot-doctor-feedback-step-3/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
                break;

            default:
                break;
        }
    };
    return (
        <>
            <div className="col-md-12">
                <Tabs
                    id="doctor-reg-tabs"
                    activeKey={key}
                    // onSelect={handleSelect}
                    className="doctor_reg_tabs mb-1"
                    onSelect={(e) => handleTabSelect(e)}
                >
                 {patientStatus && (
            <Tab
              eventKey="personal"
              title={<React.Fragment>Personal Information</React.Fragment>}
            ></Tab>
          )}
                    {patientStatus && (
                        <Tab
                            eventKey="pain"
                            title={<React.Fragment>Pain</React.Fragment>}
                        ></Tab>
                    )}
                    {patientStatus && (
                        <Tab
                            eventKey="instability"
                            title={<React.Fragment>Instability</React.Fragment>}
                        ></Tab>
                    )}
                    {patientStatus && (ankleDiagnosedR === "1" ||
                        ankleDiagnosedL === "1" ||
                        ankleFeelL === "1" ||
                        ankleFeelR === "1") ? (
                        <Tab
                            eventKey="cumberland"
                            title={<React.Fragment>Cumberland</React.Fragment>}
                        ></Tab>
                    ) : ""}
                    {patientStatus && (
                        <Tab
                            eventKey="symptoms"
                            title={<React.Fragment>Symptoms</React.Fragment>}
                        ></Tab>
                    )}
                    {patientStatus && (

                        <Tab
                            eventKey="function"
                            title={<React.Fragment>Function</React.Fragment>}
                        ></Tab>
                    )}
                    {patientStatus && (

                        <Tab
                            eventKey="quality-of-life"
                            title={<React.Fragment>QOL</React.Fragment>}
                        ></Tab>
                    )}
                    {patientStatus && (
                        <Tab
                            eventKey="satisfaction"
                            title={<React.Fragment>Satisfaction</React.Fragment>}
                        ></Tab>
                    )}

                    {doctorStatus && (
                        <Tab
                            eventKey="doctor-feedback/step-1"
                            title={<React.Fragment>Patient-Assessment-Step 1</React.Fragment>}
                        ></Tab>
                    )}
                    {/* <Tab
                        eventKey="doctor-feedback/step-2"
                        title={<React.Fragment>Patient-Assessment-Step 2</React.Fragment>}
                    ></Tab>
                      <Tab
                        eventKey="doctor-feedback/step-3"
                        title={<React.Fragment>Patient-Assessment-Step 3</React.Fragment>}
                    ></Tab> */}
                </Tabs>
            </div>
        </>
    );
}
