import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import vertMenuStyles from "./FormVerticalMenu.module.css";
import { useDispatch, useSelector } from "react-redux";
export default function Footformvertical1({ setGetId }) {
  const docFeedback = useSelector((state) => state.footAnkle.doctorFeedback[0]);
  // console.log("DATA ", docFeedback.AnkleHL);
  return (
    <>
      <Navbar
        bg="light"
        className={`scrollbar_vert ${vertMenuStyles.vm_sidebar}`}
      >
        <Nav
          className={`me-auto my-2 my-lg-0 ${vertMenuStyles.vm_sidebar_inner}`}
        >
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={()=>setGetId("Gait")}
        
            // href="#Gait"
          >
            Gait
          </Nav.Link>

          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("Contractures")}
        
            // href="#Contractures"
          >
            Contractures
          </Nav.Link>

          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("Ankle")}
        
            // href="#Ankle"
          >
            Ankle/Hindfoot
          </Nav.Link>

          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={()=>setGetId("Midfoot")}
        
            // href="#Midfoot"
          >
            Midfoot
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={()=>setGetId("Hallux")}
        
            // href="#Hallux"
          >
            Hallux
          </Nav.Link>

          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={()=>setGetId("2ndtoe")}
        
            // href="#2ndtoe"
          >
            2nd toe
          </Nav.Link>

          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={()=>setGetId("3rdtoe")}
        
            // href="#3rdtoe"
          >
            3rd toe
          </Nav.Link>

          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={()=>setGetId("4thtoe")}
        
            // href="#4thtoe"
          >
            4th toe
          </Nav.Link>

          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={()=>setGetId("5thtoe")}
        
            // href="#5thtoe"
          >
            5th toe
          </Nav.Link>

          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={()=>setGetId("Deformity")}
        
            // href="#Deformity"
          >
            Other Deformity
          </Nav.Link>

          {/* {docFeedback.AnkleHR === true || docFeedback.AnkleHL === true ? (
            <Nav.Link className={`${vertMenuStyles.vm_item}`} onClick={setGetId("nikhil")} setGetId href="#Ankle">
              Ankle
            </Nav.Link>
          ) : (
            ""
          )} */}

          {/* {docFeedback.ForefootR === true || docFeedback.ForefootL === true ? (
            <Nav.Link className={`${vertMenuStyles.vm_item}`} href="#Forefoot">
              Forefoot
            </Nav.Link>
          ) : (
            ""
          )} */}

          <Nav.Link className={`${vertMenuStyles.vm_item}`} onClick={()=>setGetId("Ligamentous")}>
            Ligamentous Laxity
          </Nav.Link>

          <Nav.Link className={`${vertMenuStyles.vm_item}`} onClick={()=>setGetId("Others")}>
            Others Tests
          </Nav.Link>
        </Nav>
      </Navbar>
    </>
  );
}
