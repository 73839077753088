import React, { useState, useEffect } from "react";
import AssessmentHeader from "../../../custom/assessment-header/AssessmentHeader";
import HeaderInnerBreadcrumb from "../../../custom/heade-inner-breadcrumb/HeaderInnerBreadcrumb";
import RadioButton from "../../../custom/radio-button/RadioButton";
import RadioSwitch from "../../../custom/radio-switch/RadioSwitch";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import InputField from "../../../custom/inputfield/InputField";
import AssessmentDegreeWidget from "../../../custom/assessment-widgets/AssessmentDegreeWidget";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import useTranslation from "../../../customHooks/doctorTranslation";
import ElbowTab from "../../tab-section/ElbowTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import KneeScoreRangeSlider from "../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import { useDispatch, useSelector } from "react-redux";
import FormVerticalMenu from "./form-vertical-menu/FormVerticalMenu_1";

import {
  updateElbowScores,
  updateElbowPainFieldScore,
  updateElbowPainData,
  updateElbowFunctionData,
  updateElbowQOLData,
  updateElbowSatisficationData,
  updateElbowFeedbackScores,
  updateElbowSymptomsData,
  updateAllData,
  updateElbowFeedback,
} from "../../../../redux/actions/elbowAction";
import useAxios from "../../../../axiosinstance";
import DoctorNameCustom from "../../../custom/name/DoctorNameCustom";
import { findScoreStatus } from "../../../../utilities/FindScoreStatus";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
export default function ElbowFeedback() {
  const translation = useTranslation();
  const dispatch = useDispatch();
  const { rId } = useParams();
  const axiosinstance = useAxios();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const queryParams = new URLSearchParams(window.location.search);
  const doctorid = queryParams.get("doctor");
  let doctor_id = localStorage.getItem('doctor')
  let dId = doctorid ? doctorid : doctor_id
  const pId = queryParams.get("patient");
  let isdoctorForm = queryParams.get("is_doctor_form");
   useEffect(() => {
  // if(doctorEnable === "true"){
    // let doctordata=localStorage.getItem("isdoctorForm")
    localStorage.setItem("isdoctorForm", isdoctorForm)
  // }else{
  //   localStorage.setItem("isdoctorForm", isdoctorForm)
  // }

  }, [isdoctorForm])
  const [bearHugRightVal, setBearHugRightVal] = useState("");
  const [bearHugLeftVal, setBearHugLeftVal] = useState("");
  const [rightFixed, setRightFixed] = useState("");
  const [leftFixed, setLeftFixed] = useState("");
  const [RightFlexionR, setRightFlexionR] = useState("");
  const [RightFlexionL, setRightFlexionL] = useState("");
  const [LeftFlexionR, setLeftFlexionR] = useState("");
  const [LeftFlexionL, setLeftFlexionL] = useState("");
  const [RightPronationR, setRightPronationR] = useState("");
  const [RightPronationL, setRightPronationL] = useState("");
  const [LeftPronationR, setLeftPronationR] = useState("");
  const [LeftPronationL, setLeftPronationL] = useState("");
  const [RightSupinationR, setRightSupinationR] = useState("");
  const [RightSupinationL, setRightSupinationL] = useState("");
  const [LeftSupinationR, setLeftSupinationR] = useState("");
  const [LeftSupinationL, setLeftSupinationL] = useState("");
  const [RightPronationSupinationarcR, setRightPronationSupinationarcR] =
    useState("");
  const [RightPronationSupinationarcL, setRightPronationSupinationarcL] =
    useState("");
  const [LeftPronationSupinationarcR, setLeftPronationSupinationarcR] =
    useState("");
  const [LeftPronationSupinationarcL, setLeftPronationSupinationarcL] =
    useState("");

  const [scarsR, setScarsR] = useState("");
  const [scarsL, setScarsL] = useState("");
  const [scarsTextR, setScarsTextR] = useState("");
  const [scarsTextL, setScarsTextL] = useState("");

  const [AtrophyR, setAtrophyR] = useState("");
  const [AtrophyL, setAtrophyL] = useState("");
  const [AtrophyTextR, setAtrophyTextR] = useState("");
  const [AtrophyTextL, setAtrophyTextL] = useState("");
  const [DeformityR, setDeformityR] = useState("");
  const [DeformityL, setDeformityL] = useState("");
  const [contractureR, setContractureR] = useState("");
  const [contracture1R, setContracture1R] = useState("");
  const [contracture2R, setContracture2R] = useState("");
  const [contracture3R, setContracture3R] = useState("");

  const [contractureL, setContractureL] = useState("");
  const [contracture1L, setContracture1L] = useState("");
  const [contracture2L, setContracture2L] = useState("");
  const [contracture3L, setContracture3L] = useState("");

  const [otherfieldR, setotherfieldR] = useState("");
  const [otherfieldL, setotherfieldL] = useState("");

  const [ulnohumeralR, setUlnohumeralR] = useState("");
  const [ulnohumeralL, setUlnohumeralL] = useState("");
  const [RadiocapitellarR, setRadiocapitellarR] = useState("");
  const [RadiocapitellarL, setRadiocapitellarL] = useState("");
  const [MedialR, setMedialR] = useState("");
  const [MedialL, setMedialL] = useState("");
  const [LateralR, setLateralR] = useState("");
  const [LateralL, setLateralL] = useState("");
  const [MCLR, setMCLR] = useState("");
  const [MCLL, setMCLL] = useState("");
  const [PINR, setPINR] = useState("");
  const [PINL, setPINL] = useState("");
  const [otherTextL, setOtherTextL] = useState("");
  const [otherText, setOtherText] = useState("");

  const [crepitusR, setCrepitusR] = useState("");
  const [crepitusL, setCrepitusL] = useState("");

  const [RadiocapitellarCrR, setRadiocapitellarCrR] = useState("");
  const [RadiocapitellarCrL, setRadiocapitellarCrL] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const [chk, setChk] = useState(false);
  const docFeedback1 = useSelector((state) => state.elbowScore.doctorFeedback);
  const docFeedback = useSelector(
    (state) => state.elbowScore.doctorFeedback[0]
  );

  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=22`)
      .then((res) => {
        // console.log(res.data.data2);
        dispatch(updateElbowPainData(res.data.data2[0].painScore));
        dispatch(updateElbowSymptomsData(res.data.data2[0].symptomsScore));
        dispatch(updateElbowScores(res.data.data2[0].elbowPain));
        dispatch(updateElbowFunctionData(res.data.data2[0].functionScore));
        dispatch(updateElbowQOLData(res.data.data2[0].qolScore));
        dispatch(
          updateElbowSatisficationData(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateElbowFeedback(res.data.data2[0].doctorFeedback));
        setChk(true);
      });
  };

  useEffect(() => {
    save();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  useEffect(() => {
    setRightFlexionR(docFeedback.RightFlexionR);
    setRightFlexionL(docFeedback.RightFlexionL);
    setLeftFlexionR(docFeedback.LeftFlexionR);
    setLeftFlexionL(docFeedback.LeftFlexionL);
    setRightPronationR(docFeedback.RightPronationR);
    setRightPronationL(docFeedback.RightPronationL);
    setLeftPronationR(docFeedback.LeftPronationR);
    setLeftPronationL(docFeedback.LeftPronationL);
    setRightSupinationR(docFeedback.RightSupinationR);
    setRightSupinationL(docFeedback.RightSupinationL);
    setLeftSupinationR(docFeedback.LeftSupinationR);
    setLeftSupinationL(docFeedback.LeftSupinationL);
    setRightPronationSupinationarcR(docFeedback.RightPronationSupinationarcR);
    setRightPronationSupinationarcL(docFeedback.RightPronationSupinationarcL);
    setLeftPronationSupinationarcR(docFeedback.LeftPronationSupinationarcR);
    setLeftPronationSupinationarcL(docFeedback.LeftPronationSupinationarcL);
    setScarsR(docFeedback.scarsR);
    setScarsL(docFeedback.scarsL);
    setScarsTextR(docFeedback.scarsTextR);
    setScarsTextL(docFeedback.scarsTextL);
    setAtrophyR(docFeedback.AtrophyR);
    setAtrophyL(docFeedback.AtrophyL);
    setAtrophyTextR(docFeedback.AtrophyTextR);
    setAtrophyTextL(docFeedback.AtrophyTextL);
    setDeformityR(docFeedback.DeformityR);
    setDeformityL(docFeedback.DeformityL);
    setContractureR(docFeedback.contractureR);
    setContracture1R(docFeedback.contracture1R);
    setContracture2R(docFeedback.contracture2R);
    setContracture3R(docFeedback.contracture3R);
    setContractureL(docFeedback.contractureL);
    setContracture1L(docFeedback.contracture1L);
    setContracture2L(docFeedback.contracture2L);
    setContracture3L(docFeedback.contracture3L);
    setotherfieldR(docFeedback.otherfieldR);
    setotherfieldL(docFeedback.otherfieldL);
    setUlnohumeralR(docFeedback.ulnohumeralR);
    setUlnohumeralL(docFeedback.ulnohumeralL);
    setRadiocapitellarR(docFeedback.RadiocapitellarR);
    setRadiocapitellarL(docFeedback.RadiocapitellarL);
    setMedialR(docFeedback.MedialR);
    setMedialL(docFeedback.MedialL);
    setLateralR(docFeedback.LateralR);
    setLateralL(docFeedback.LateralL);
    setMCLR(docFeedback.MCLR);
    setMCLL(docFeedback.MCLL);
    setPINR(docFeedback.PINR);
    setPINL(docFeedback.PINL);
    setOtherTextL(docFeedback.otherTextL);
    setOtherText(docFeedback.otherText);
    setCrepitusR(docFeedback.crepitusR);
    setCrepitusL(docFeedback.crepitusL);
    setRadiocapitellarCrR(docFeedback.RadiocapitellarCrR);
    setRadiocapitellarCrL(docFeedback.RadiocapitellarCrL);
  }, [docFeedback]);

// console.log(doctor_id, "doctor_id")

  const ir2RightFlexionR = (e) => {
    dispatch(
      updateElbowFeedbackScores({
        key: "RightFlexionR",
        value: e,
      })
    );
    setRightFlexionR(e);
  };
  const ir2RightFlexionL = (e) => {
    dispatch(
      updateElbowFeedbackScores({
        key: "RightFlexionL",
        value: e,
      })
    );
    setRightFlexionL(e);
  };

  const ir2LeftFlexionR = (e) => {
    setLeftFlexionR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "LeftFlexionR",
        value: e,
      })
    );
  };
  const ir2LeftFlexionL = (e) => {
    setLeftFlexionL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "LeftFlexionL",
        value: e,
      })
    );
  };
  const ir2RightPronationR = (e) => {
    setRightPronationR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "RightPronationR",
        value: e,
      })
    );
  };
  const ir2RightPronationL = (e) => {
    setRightPronationL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "RightPronationL",
        value: e,
      })
    );
  };
  const ir2LeftPronationR = (e) => {
    setLeftPronationR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "LeftPronationR",
        value: e,
      })
    );
  };
  const ir2LeftPronationL = (e) => {
    setLeftPronationL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "LeftPronationL",
        value: e,
      })
    );
  };

  const ir2RightSupinationR = (e) => {
    setRightSupinationR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "RightSupinationR",
        value: e,
      })
    );
  };
  const ir2RightSupinationL = (e) => {
    setRightSupinationL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "RightSupinationL",
        value: e,
      })
    );
  };
  const ir2LeftSupinationR = (e) => {
    setLeftSupinationR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "LeftSupinationR",
        value: e,
      })
    );
  };
  const ir2LeftSupinationL = (e) => {
    setLeftSupinationL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "LeftSupinationL",
        value: e,
      })
    );
  };
  const ir2RightPronationSupinationarcR = (e) => {
    setRightPronationSupinationarcR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "RightPronationSupinationarcR",
        value: e,
      })
    );
  };
  const ir2RightPronationSupinationarcL = (e) => {
    setRightPronationSupinationarcL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "RightPronationSupinationarcL",
        value: e,
      })
    );
  };
  const ir2LeftPronationSupinationarcR = (e) => {
    setLeftPronationSupinationarcR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "LeftPronationSupinationarcR",
        value: e,
      })
    );
  };
  const ir2LeftPronationSupinationarcL = (e) => {
    setLeftPronationSupinationarcL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "LeftPronationSupinationarcL",
        value: e,
      })
    );
  };

  const handleDeformityR = (e) => {
    setDeformityR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "DeformityR",
        value: e,
      })
    );
  };
  const handleDeformityL = (e) => {
    setDeformityL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "DeformityL",
        value: e,
      })
    );
  };
  const handleScarsR = (e) => {
    setScarsR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "scarsR",
        value: e,
      })
    );
  };
  const handleScarsL = (e) => {
    setScarsL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "scarsL",
        value: e,
      })
    );
  };

  const handleScarsText = (e) => {
    setScarsTextR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "scarsTextR",
        value: e,
      })
    );
  };
  const handleScarsTextL = (e) => {
    setScarsTextL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "scarsTextL",
        value: e,
      })
    );
  };
  const handleAtrophyR = (e) => {
    setAtrophyR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "AtrophyR",
        value: e,
      })
    );
  };
  const handleAtrophyL = (e) => {
    setAtrophyL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "AtrophyL",
        value: e,
      })
    );
  };

  const handleAtrophyText = (e) => {
    setAtrophyTextR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "AtrophyTextR",
        value: e,
      })
    );
  };
  const handleAtrophyTextL = (e) => {
    setAtrophyTextL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "AtrophyTextL",
        value: e,
      })
    );
  };
  const handleContractureR = (e) => {
    const newValue = e.replace(/[^0-9]/g, "");
    setContractureR(newValue);
    dispatch(
      updateElbowFeedbackScores({
        key: "contractureR",
        value: newValue,
      })
    );
  };
  const handleContracture1R = (e) => {
    const newValue = e.replace(/[^0-9]/g, "");
    setContracture1R(newValue);
    dispatch(
      updateElbowFeedbackScores({
        key: "contracture1R",
        value: newValue,
      })
    );
  };
  const handleContracture2R = (e) => {
    const newValue = e.replace(/[^0-9]/g, "");
    setContracture2R(newValue);
    dispatch(
      updateElbowFeedbackScores({
        key: "contracture2R",
        value: newValue,
      })
    );
  };
  const handleContracture3R = (e) => {
    const newValue = e.replace(/[^0-9]/g, "");
    setContracture3R(newValue);
    dispatch(
      updateElbowFeedbackScores({
        key: "contracture3R",
        value: newValue,
      })
    );
  };
  const handleotherfieldL = (e) => {
    
    setotherfieldL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "otherfieldL",
        value: e,
      })
    );
  };
  const handleotherfieldR = (e) => {
   
    setotherfieldR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "otherfieldR",
        value: e,
      })
    );
  };
  const handleContractureL = (e) => {
    const newValue = e.replace(/[^0-9]/g, "");
    setContractureL(newValue);
    dispatch(
      updateElbowFeedbackScores({
        key: "contractureL",
        value: newValue,
      })
    );
  };
  const handleContracture1L = (e) => {
    const newValue = e.replace(/[^0-9]/g, "");
    setContracture1L(newValue);
    dispatch(
      updateElbowFeedbackScores({
        key: "contracture1L",
        value: newValue,
      })
    );
  };
  const handleContracture2L = (e) => {
    const newValue = e.replace(/[^0-9]/g, "");
    setContracture2L(newValue);
    dispatch(
      updateElbowFeedbackScores({
        key: "contracture2L",
        value: newValue,
      })
    );
  };
  const handleContracture3L = (e) => {
    const newValue = e.replace(/[^0-9]/g, "");
    setContracture3L(newValue);
    dispatch(
      updateElbowFeedbackScores({
        key: "contracture3L",
        value: newValue,
      })
    );
  };

  const handleUlnohumeralR = (e) => {
    setUlnohumeralR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "ulnohumeralR",
        value: e,
      })
    );
  };
  const handleUlnohumeralL = (e) => {
    setUlnohumeralL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "ulnohumeralL",
        value: e,
      })
    );
  };
  const handleRadiocapitellarR = (e) => {
    setRadiocapitellarR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "RadiocapitellarR",
        value: e,
      })
    );
  };
  const handleRadiocapitellarL = (e) => {
    setRadiocapitellarL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "RadiocapitellarL",
        value: e,
      })
    );
  };
  const handleMedialR = (e) => {
    setMedialR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "MedialR",
        value: e,
      })
    );
  };
  const handleMedialL = (e) => {
    setMedialL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "MedialL",
        value: e,
      })
    );
  };
  const handleLateralR = (e) => {
    setLateralR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "LateralR",
        value: e,
      })
    );
  };
  const handleLateralL = (e) => {
    setLateralL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "LateralL",
        value: e,
      })
    );
  };

  const handleMCLR = (e) => {
    setMCLR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "MCLR",
        value: e,
      })
    );
  };
  const handleMCLL = (e) => {
    setMCLL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "MCLL",
        value: e,
      })
    );
  };
  const handlePINR = (e) => {
    setPINR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "PINR",
        value: e,
      })
    );
  };
  const handlePINL = (e) => {
    setPINL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "PINL",
        value: e,
      })
    );
  };

  const hendleOtherText = (e) => {
    setOtherText(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "otherText",
        value: e,
      })
    );
  };

  const hendleOtherTextL = (e) => {
    setOtherTextL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "otherTextL",
        value: e,
      })
    );
  };

  const handleCrepitusR = (e) => {
    setCrepitusR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "crepitusR",
        value: e,
      })
    );
  };
  const handleCrepitusL = (e) => {
    setCrepitusL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "crepitusL",
        value: e,
      })
    );
  };
  const handleRadiocapitellarCrR = (e) => {
    setRadiocapitellarCrR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "RadiocapitellarCrR",
        value: e,
      })
    );
  };
  const handleRadiocapitellarCrL = (e) => {
    setRadiocapitellarCrL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "RadiocapitellarCrL",
        value: e,
      })
    );
  };

  let doctor_ids=sessionStorage.getItem("doctor_id")
  const [Scores, setScores] = useState([]);
  const GetScores = () => {
    axiosinstance.current
      .get(
        `/extapp/doctors/getSpecialityScoresSettingsOfUser/${dId ? dId:doctor_ids}?type_id=22`
      )
      .then((res) => {
        setScores(res.data.data);
      });
  };
  useEffect(() => {
    if (chk) {
      GetScores();
    }
  }, [chk]);
  const MEPSStatus = findScoreStatus("MEPS", Scores);
  const [validator, setValidators] = useState(false);
  const [validatorL, setValidatorsL] = useState(false);
// console.log(RightFlexionR,"RightFlexionRRightFlexionR");
  const continueRedirect = async () => {
    
    if (MEPSStatus) {
      if (
        isNaN(RightFlexionR) ||
        RightFlexionR === null ||
        RightFlexionR === ""
      ) {
       
        setValidatorsL(true);
        window.scrollTo(2000,2000);
        return
      }
      // if (isNaN(RightFlexionL) || RightFlexionL === null || RightFlexionL==="") {
      //   setValidators(true)

      // }
      if (isNaN(LeftFlexionR) || LeftFlexionR === null || LeftFlexionR === "") {
        setValidatorsL(true)
        window.scrollTo(2000,2000);

      }
      // if (isNaN(LeftFlexionL) || LeftFlexionL === null || LeftFlexionL==="") {
      //   setValidators(true)
      // } 
      // if(RightFlexionR &&RightFlexionL && LeftFlexionR&&LeftFlexionL ) {
      if (RightFlexionR!==null &&RightFlexionR!=="" && LeftFlexionR!==null && LeftFlexionR!=="") {

        const response = await axiosinstance.current.put(
          `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=0&ak_dc=${dId}&edit=1`,
          docFeedback1,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
         
          navigate(
            "/elbow-feedback-step2/" + rId + `?doctor=${dId}&patient=${pId}&is_doctor_form=${isdoctorForm}`
          );
        }
      }
    }
    if (!MEPSStatus) {
      const response = await axiosinstance.current.put(
        `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=0&ak_dc=${dId}&edit=1`,
        docFeedback1,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        let doctordata=localStorage.getItem("isdoctorForm")
        navigate(
          "/elbow-feedback-step2/" + rId + `?doctor=${dId}&patient=${pId}&is_doctor_form=${isdoctorForm}`
        );
      }
    }
  };

  const updateDate1 = async () => {
    const response = await axiosinstance.current.put(
      `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=0&ak_dc=${dId}&edit=1`,
      docFeedback1,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };
  // const continueRedirect = (e) => {
  //   updateDate()
  //   navigate("/elbow-feedback-step2/" + rId + `?doctor=${dId}&patient=${pId}`);
  // };



  useEffect(()=>{
    const updateDate2 = async () => {
    const response = await axiosinstance.current.put(
      `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=0&ak_dc=${dId}`,
      docFeedback1,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {

    }
    }
    if(chk){
      if(doctorEnable !== "true"){
    updateDate2()
    // updateDate1()
    }
    }
  },[docFeedback1])
  let akpt = searchParams.get("akpt");
  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  const handleSubmit = () => {
 
    if (MEPSStatus) {
      if (isNaN(RightFlexionR) || RightFlexionR === null || RightFlexionR === "") {
        setValidatorsL(true)
        window.scrollTo(0, 0);
      }
      // if (isNaN(RightFlexionL) || RightFlexionL === null) {
      //   setValidators(true)

      // }
      if (isNaN(LeftFlexionR) || LeftFlexionR === null || LeftFlexionR === "") {
        setValidatorsL(true)
        window.scrollTo(0, 0);
      }
      // if (isNaN(LeftFlexionL) || LeftFlexionL === null) {
      //   setValidators(true)
      // } 
      if (RightFlexionR!==null &&RightFlexionR!=="" && LeftFlexionR!==null && LeftFlexionR!=="") {
        updateDate1()
        setMsg(translation.update)
        setmsgType('success')
        setTimeout(() => {
          window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" +akpt
          setMsg("");
          setmsgType("success");
        }, 2000);
      }
    }
    if (!MEPSStatus) {
      updateDate1();
      setMsg(translation.update);
      setmsgType("success");
      setTimeout(() => {
        setMsg("");
        setmsgType("success");
      }, 2000);
    }
  };

  const handleNext=()=>{
    let doctordata=localStorage.getItem("isdoctorForm")
    navigate(`/elbow-feedback-step2/${rId}?d=true&akpt=${akpt}&isdoctorForm=${doctordata}`);

  }

  useEffect(() => {
    if (contractureR > 100) {
      setContractureR(100);
    }
    if (contracture1R > 100) {
      setContracture1R(100);
    }
    if (contracture2R > 100) {
      setContracture2R(100);
    }
    if (contracture3R > 100) {
      setContracture3R(100);
    }
 
   
    if (contractureL > 100) {
      setContractureL(100);
    }
    if (contracture1L > 100) {
      setContracture1L(100);
    }
    if (contracture2L > 100) {
      setContracture2L(100);
    }
    if (contracture3L > 100) {
      setContracture3L(100);
    }

  });



  const [getId, setGetId] = useState('');
  useEffect(() => {
    // Get the Gait section element by its ID
    const gaitSection = document.getElementById(getId);
  // console.log(gaitSection , 'gaitSection')
    // Scroll to the Gait section
    if (gaitSection) {
      const yOffset = gaitSection.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: yOffset - window.innerHeight / 10, behavior: 'smooth' });
    }
    // console.log(getId , "getId")
  }, [getId]);

  return (
    <>
    {isLoading ? (
      <LoadingSpinner/>
    ):
    (
      <>

      <div class="d-flex justify-content-end">
        <DoctorNameCustom />
      </div>
      <div className="patient_assessment_form bg_white pd_10 ">
        {doctorEnable === "true" && <ElbowTab rId={rId} />}

        <CustomToaster msg={msg} msgType={msgType} />
        {/* <DoctorNameCustom /> */}
      </div>

      <div className="patient_assessment_form mb_100 bg_white pd_10 pt_50 content_fx_w">
        <HeaderInnerBreadcrumb />

        <div className="row">
          <div className="col-sm-2 col-md-2 ">
            <div
              className="sticky "
              style={{
                position: "sticky",
                top: "75px",
              }}
            >
              <FormVerticalMenu setGetId={setGetId} />
            </div>
          </div>

          <div className="col-sm-10 col-md-10">
            <div className="">
              {/* <AssessmentHeader /> */}
              <div className="input_tab_box mb_30" id="inspection">
                <h4 className="range_sl_title">{translation.Inspection}</h4>
                <div className="row" >
                  <div className="col-md-6 col-sm-6 mb_10">
                    <RadioSwitch
                      labelLeft="Negative"
                      labelRight="Positive"
                      idLeft="ScarsR"
                      idRight="ScarsL"
                      name="ScarsR"
                      title={translation.Scars_R}
                      styleClass=""
                      value={scarsR}
                      onChange={handleScarsR}
                      placeholder="select"
                    />
                    {scarsR === "1" && (
                      <>
                        <label>{translation.Location}</label>
                        <InputField
                          type="text"
                          value={scarsTextR}
                          placeholder="Location"
                          onChange={handleScarsText}
                        />
                      </>
                    )}
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <RadioSwitch
                      labelLeft="Negative"
                      labelRight="Positive"
                      idLeft="ScarsLL"
                      idRight="ScarsLR"
                      name="ScarsLL"
                      title={translation.Scars_L}
                      styleClass=""
                      value={scarsL}
                      onChange={handleScarsL}
                      placeholder="select"
                    />
                    {scarsL === "1" && (
                      <>
                        <label>{translation.Location}</label>
                        <InputField
                          type="text"
                          value={scarsTextL}
                          placeholder="Location"
                          onChange={handleScarsTextL}
                        />
                      </>
                    )}
                  </div>
                </div>

                <div className="row" >
                  <div className="col-md-6 col-sm-6 mb_10">
                    <RadioSwitch
                      labelLeft="Negative"
                      labelRight="Positive"
                      idLeft="AtrophyR"
                      idRight="AtrophyL"
                      name="AtrophyR"
                      title={translation.Atrophy_R}
                      styleClass=""
                      value={AtrophyR}
                      onChange={handleAtrophyR}
                      placeholder="select"
                    />
                    {AtrophyR === "1" && (
                      <>
                        <label>Location :</label>
                        <InputField
                          type="text"
                          value={AtrophyTextR}
                          placeholder="Location"
                          onChange={handleAtrophyText}
                        />
                      </>
                    )}
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <RadioSwitch
                      labelLeft="Negative"
                      labelRight="Positive"
                      idLeft="AtrophyLL"
                      idRight="AtrophyLR"
                      name="AtrophyLL"
                      title={translation.Atrophy_L}
                      styleClass=""
                      value={AtrophyL}
                      onChange={handleAtrophyL}
                      placeholder="select"
                    />
                    {AtrophyL === "1" && (
                      <>
                        <label>{translation.Location}</label>
                        <InputField
                          type="text"
                          value={AtrophyTextL}
                          placeholder="Location"
                          onChange={handleAtrophyTextL}
                        />
                      </>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <RadioSwitch
                      labelLeft="Negative"
                      labelRight="Positive"
                      idLeft="DeformityR"
                      idRight="DeformityL"
                      name="DeformityR"
                      title={translation.Deformity_R}
                      styleClass=""
                      value={DeformityR}
                      onChange={handleDeformityR}
                      placeholder="select"
                    />
                    {DeformityR === "1" && (
                      <>
                         <div className="d-flex deformity mt-2 row">

                          <label className="col-5">
                            {translation.Flexion_contracture}
                          </label>
                          <div className="col-7">

                          <InputField
                            placeholder="degrees"
                            type="text"
                            onChange={handleContractureR}
                            value={contractureR}
                          />
                          </div>
                          {/* {translation.Degrees} */}
                        </div>
                        <div className="d-flex deformity mt-2 row">

                          <label className="col-5">
                            {translation.Extension_contracture}{" "}
                          </label>
                          <div className="col-7">

                          <InputField
                            placeholder="degrees"
                            type="text"
                            onChange={handleContracture1R}
                            value={contracture1R}
                          />
                          </div>
                          {/* {translation.Degrees} */}
                        </div>
                        <div className="d-flex deformity mt-2 row">

                          <label className="col-5">
                            {translation.Cubitus_valgus}{" "}
                          </label>
                          <div className="col-7">

                          <InputField
                            placeholder="degrees"
                            type="text"
                            onChange={handleContracture2R}
                            value={contracture2R}
                          />
                          </div>
                          {/* {translation.Degrees} */}
                        </div>
                        <div className="d-flex deformity mt-2 row">

                          <label className="col-5">
                            {translation.Cubitus_varus}
                          </label>
                          <div className="col-7">

                          <InputField
                            placeholder="degrees"
                            type="text"
                            onChange={handleContracture3R}
                            value={contracture3R}
                          />
                          </div>
                          {/* {translation.Degrees} */}
                        </div>
                        <div className="d-flex deformity mt-2 row">

                          <label className="col-5">
                            {translation.other_field}
                          </label>
                          <div className="col-7">

                          <InputField
                            placeholder="Specify"
                            type="text"
                            onChange={handleotherfieldR}
                            value={otherfieldR}
                          />
                          </div>
                          {/* {translation.Degrees} */}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <RadioSwitch
                      labelLeft="Negative"
                      labelRight="Positive"
                      idLeft="DeformityLL"
                      idRight="DeformityLR"
                      name="DeformityLL"
                      title={translation.Deformity_L}
                      styleClass=""
                      value={DeformityL}
                      onChange={handleDeformityL}
                      placeholder="select"
                    />
                    {DeformityL === "1" && (
                      <>
                        <div className="d-flex deformity mt-2 row">

                          <label className="col-5">
                            {translation.Flexion_contracture}{" "}
                          </label>

                          <div className="col-7">

                            <InputField
                              type="text"
                              placeholder="degrees"
                              onChange={handleContractureL}
                              value={contractureL}
                            />
                          </div>

                          {/* {translation.Degrees} */}
                        </div>
                        <div className="d-flex deformity mt-2 row">

                          <label className="col-5">
                            {translation.Extension_contracture}{" "}
                          </label>
                          <div className="col-7">

                          <InputField
                            placeholder="degrees"
                            type="text"
                            onChange={handleContracture1L}
                            value={contracture1L}
                          />
                          </div>
                          {/* {translation.Degrees} */}
                        </div>
                        <div className="d-flex deformity mt-2 row">

                          <label className="col-5">
                            {translation.Cubitus_valgus}{" "}
                          </label>
                          <div className="col-7">

                          <InputField
                            placeholder="degrees"
                            type="text"
                            onChange={handleContracture2L}
                            value={contracture2L}
                          />
                          </div>
                          {/* {translation.Degrees} */}
                        </div>
                        <div className="d-flex deformity mt-2 row">

                          <label className="col-5">
                            {translation.Cubitus_varus}
                          </label>
                          <div className="col-7">

                          <InputField
                            placeholder="degrees"
                            type="text"
                            onChange={handleContracture3L}
                            value={contracture3L}
                          />
                          </div>
                          {/* {translation.Degrees} */}
                        </div>
                        <div className="d-flex deformity mt-2 row">

                          <label className="col-5">
                            {translation.other_field}
                          </label>
                          <div className="col-7">

                          <InputField
                            placeholder="Specify"
                            type="text"
                            onChange={handleotherfieldL}
                            value={otherfieldL}
                          />
                          </div>
                          {/* {translation.Degrees} */}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="input_tab_box mb_30" id="tenderness">
                <h4 className="range_sl_title">{translation.Tenderness}</h4>
                <div className="row">
                  <div className="col-md-12 col-sm-12 function_head">
                    {/* <label className="text-capitalize">
                   {translation.Please_rate_the_severity_of_the_following_STRENGTH_in_the_last_week}
                  </label>{" "} */}
                    <br />
                    <span>
                      (<strong>0</strong> = {translation.none}{" "}
                      <strong>1</strong> = {translation.mild}
                      <strong> 2</strong> = {translation.moderate}
                      <strong> 3</strong> = {translation.severe_h} )
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <KneeScoreRangeSlider
                      label={translation.Ulnohumeral_tenderness_R}
                      classes=""
                      minnum="0"
                      maxnum="3"
                      value={ulnohumeralR}
                      onChange={handleUlnohumeralR}
                      sliderData={[
                        { score: "0", label: translation.none },
                        { score: "1", label: translation.mild },
                        { score: "2", label: translation.moderate },
                        { score: "3", label: translation.severe_h },
                      ]}
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <KneeScoreRangeSlider
                      label={translation.Ulnohumeral_tenderness_L}
                      classes=""
                      minnum="0"
                      maxnum="3"
                      value={ulnohumeralL}
                      onChange={handleUlnohumeralL}
                      sliderData={[
                        { score: "0", label: translation.none },
                        { score: "1", label: translation.mild },
                        { score: "2", label: translation.moderate },
                        { score: "3", label: translation.severe_h },
                      ]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <KneeScoreRangeSlider
                      minnum="0"
                      maxnum="3"
                      label={translation.Radiocapitellar_tenderness_R}
                      classes=""
                      value={RadiocapitellarR}
                      onChange={handleRadiocapitellarR}
                      sliderData={[
                        { score: "0", label: translation.none },
                        { score: "1", label: translation.mild },
                        { score: "2", label: translation.moderate },
                        { score: "3", label: translation.severe_h },
                      ]}
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <KneeScoreRangeSlider
                      minnum="0"
                      maxnum="3"
                      label={translation.Radiocapitellar_tenderness_L}
                      classes=""
                      value={RadiocapitellarL}
                      onChange={handleRadiocapitellarL}
                      sliderData={[
                        { score: "0", label: translation.none },
                        { score: "1", label: translation.mild },
                        { score: "2", label: translation.moderate },
                        { score: "3", label: translation.severe_h },
                      ]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <KneeScoreRangeSlider
                      minnum="0"
                      maxnum="3"
                      label={translation.Medial_flexor_origin_tenderness_R}
                      classes=""
                      value={MedialR}
                      onChange={handleMedialR}
                      sliderData={[
                        { score: "0", label: translation.none },
                        { score: "1", label: translation.mild },
                        { score: "2", label: translation.moderate },
                        { score: "3", label: translation.severe_h },
                      ]}
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <KneeScoreRangeSlider
                      minnum="0"
                      maxnum="3"
                      label={translation.Medial_flexor_origin_tenderness_L}
                      classes=""
                      value={MedialL}
                      onChange={handleMedialL}
                      sliderData={[
                        { score: "0", label: translation.none },
                        { score: "1", label: translation.mild },
                        { score: "2", label: translation.moderate },
                        { score: "3", label: translation.severe_h },
                      ]}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <KneeScoreRangeSlider
                      minnum="0"
                      maxnum="3"
                      label={translation.Lateral_flexor_origin_tenderness_R}
                      classes=""
                      value={LateralR}
                      onChange={handleLateralR}
                      sliderData={[
                        { score: "0", label: translation.none },
                        { score: "1", label: translation.mild },
                        { score: "2", label: translation.moderate },
                        { score: "3", label: translation.severe_h },
                      ]}
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <KneeScoreRangeSlider
                      minnum="0"
                      maxnum="3"
                      label={translation.Lateral_flexor_origin_tenderness_L}
                      classes=""
                      value={LateralL}
                      onChange={handleLateralL}
                      sliderData={[
                        { score: "0", label: translation.none },
                        { score: "1", label: translation.mild },
                        { score: "2", label: translation.moderate },
                        { score: "3", label: translation.severe_h },
                      ]}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <KneeScoreRangeSlider
                      minnum="0"
                      maxnum="3"
                      label={translation.MCL_tenderness_R}
                      classes=""
                      value={MCLR}
                      onChange={handleMCLR}
                      sliderData={[
                        { score: "0", label: translation.none },
                        { score: "1", label: translation.mild },
                        { score: "2", label: translation.moderate },
                        { score: "3", label: translation.severe_h },
                      ]}
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <KneeScoreRangeSlider
                      minnum="0"
                      maxnum="3"
                      label={translation.MCL_tenderness_L}
                      classes=""
                      value={MCLL}
                      onChange={handleMCLL}
                      sliderData={[
                        { score: "0", label: translation.none },
                        { score: "1", label: translation.mild },
                        { score: "2", label: translation.moderate },
                        { score: "3", label: translation.severe_h },
                      ]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <KneeScoreRangeSlider
                      minnum="0"
                      maxnum="3"
                      label={translation.PIN_tenderness_R}
                      classes=""
                      value={PINR}
                      onChange={handlePINR}
                      sliderData={[
                        { score: "0", label: translation.none },
                        { score: "1", label: translation.mild },
                        { score: "2", label: translation.moderate },
                        { score: "3", label: translation.severe_h },
                      ]}
                    />
                    <label>
                      {translation.Other_tenderness_specify} (Right)
                    </label>
                    <InputField
                      type="textarea"
                      onChange={hendleOtherText}
                      value={otherText}
                      placeholder="Specify"
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <KneeScoreRangeSlider
                      minnum="0"
                      maxnum="3"
                      label={translation.PIN_tenderness_L}
                      classes=""
                      value={PINL}
                      onChange={handlePINL}
                      sliderData={[
                        { score: "0", label: translation.none },
                        { score: "1", label: translation.mild },
                        { score: "2", label: translation.moderate },
                        { score: "3", label: translation.severe_h },
                      ]}
                    />
                    <label>{translation.Other_tenderness_specify} (Left)</label>
                    <InputField
                      type="textarea"
                      placeholder="Specify"
                      onChange={hendleOtherTextL}
                      value={otherTextL}
                    />
                  </div>
                </div>
              </div>

              <div className="input_tab_box mb_30" id="crepitus">
                <h4 className="range_sl_title">{translation.Crepitus}</h4>
                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <RadioSwitch
                      labelLeft="Negative"
                      labelRight="Positive"
                      idLeft="crepitusR"
                      idRight="crepitusL"
                      name="crepitusR"
                      title={translation.Ulnohumeral_crepitus_R}
                      styleClass=""
                      value={crepitusR}
                      onChange={handleCrepitusR}
                      placeholder="select"
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <RadioSwitch
                      labelLeft="Negative"
                      labelRight="Positive"
                      idLeft="crepitusLL"
                      idRight="crepitusLR"
                      name="crepitusLL"
                      title={translation.Ulnohumeral_crepitus_L}
                      styleClass=""
                      value={crepitusL}
                      onChange={handleCrepitusL}
                      placeholder="select"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <RadioSwitch
                      labelLeft="Negative"
                      labelRight="Positive"
                      idLeft="RadiocapitellarCrRR"
                      idRight="RadiocapitellarCrRL"
                      name="RadiocapitellarCrRR"
                      title={translation.Radiocapitellar_crepitus_R}
                      styleClass=""
                      value={RadiocapitellarCrR}
                      onChange={handleRadiocapitellarCrR}
                      placeholder="select"
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <RadioSwitch
                      labelLeft="Negative"
                      labelRight="Positive"
                      idLeft="RadiocapitellarCrRLL"
                      idRight="RadiocapitellarCrRLR"
                      name="RadiocapitellarCrRLL"
                      title={translation.Radiocapitellar_crepitus_L}
                      styleClass=""
                      value={RadiocapitellarCrL}
                      onChange={handleRadiocapitellarCrL}
                      placeholder="select"
                    />
                  </div>
                </div>
              </div>

              <div className="input_tab_box mb_30" id="rom">
                <h4 className="range_sl_title">
                  {translation.Range_of_Motion}
                </h4>
                <div className="row mb_30">
                  <div className="col-md-6 col-sm-6">
                    <AssessmentDegreeWidget
                      title={translation.Flexion_Extension_R}
                      validator={validator}
                      validatorL={validatorL}
                      onChangeR={ir2RightFlexionR}
                      onChangeL={ir2RightFlexionL}
                      valueR={RightFlexionR}
                      valueL={RightFlexionL}
                      labelR={"Active" + (MEPSStatus ? "*" : "")}
                      labelL="Passive"
                      nameR="Flexion Right"
                      nameL="Flexion Left"
                      viewValidation={MEPSStatus}
                    />
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <AssessmentDegreeWidget
                      title={translation.Flexion_Extension_L}
                      validator={validator}
                      validatorL={validatorL}
                      onChangeR={ir2LeftFlexionR}
                      onChangeL={ir2LeftFlexionL}
                      valueR={LeftFlexionR}
                      valueL={LeftFlexionL}
                      labelR={"Active" + (MEPSStatus ? "*" : "")}
                      labelL="Passive"
                      nameR="Flexion Right"
                      nameL="Flexion Left"
                      viewValidation={MEPSStatus}
                    />
                  </div>
                </div>

                <div className="row mb_30">
                  <div className="col-md-6 col-sm-6">
                    <AssessmentDegreeWidget
                      title={translation.Right_Pronation}
                      onChangeR={ir2RightPronationR}
                      onChangeL={ir2RightPronationL}
                      valueR={RightPronationR}
                      valueL={RightPronationL}
                      labelR="Active"
                      labelL="Passive"
                      nameR="Pronation Right"
                      nameL="Pronation Left"
                    />
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <AssessmentDegreeWidget
                      title={translation.Left_Pronation}
                      onChangeR={ir2LeftPronationR}
                      onChangeL={ir2LeftPronationL}
                      valueR={LeftPronationR}
                      valueL={LeftPronationL}
                      labelR="Active"
                      labelL="Passive"
                      nameR="Pronation Right"
                      nameL="Pronation Left"
                    />
                  </div>
                </div>

                <div className="row mb_30">
                  <div className="col-md-6 col-sm-6">
                    <AssessmentDegreeWidget
                      title={translation.Right_Supination}
                      onChangeR={ir2RightSupinationR}
                      onChangeL={ir2RightSupinationL}
                      valueR={RightSupinationR}
                      valueL={RightSupinationL}
                      labelR="Active"
                      labelL="Passive"
                      nameR="Supination Right"
                      nameL="Supination Left"
                    />
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <AssessmentDegreeWidget
                      title={translation.Left_Supination}
                      onChangeR={ir2LeftSupinationR}
                      onChangeL={ir2LeftSupinationL}
                      valueR={LeftSupinationR}
                      valueL={LeftSupinationL}
                      labelR="Active"
                      labelL="Passive"
                      nameR="Supination Right"
                      nameL="Supination Left"
                    />
                  </div>
                </div>

                <div className="row mb_30">
                  <div className="col-md-6 col-sm-6">
                    <AssessmentDegreeWidget
                      title={translation.Right_Pronation_Supination_arc}
                      onChangeR={ir2RightPronationSupinationarcR}
                      onChangeL={ir2RightPronationSupinationarcL}
                      valueR={RightPronationSupinationarcR}
                      valueL={RightPronationSupinationarcL}
                      labelR="Active"
                      labelL="Passive"
                      nameR="Pronation/Supination arc Right"
                      nameL="Pronation/Supination arc Left"
                    />
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <AssessmentDegreeWidget
                      title={translation.Left_Pronation_Supination_arc}
                      onChangeR={ir2LeftPronationSupinationarcR}
                      onChangeL={ir2LeftPronationSupinationarcL}
                      valueR={LeftPronationSupinationarcR}
                      valueL={LeftPronationSupinationarcL}
                      labelR="Active"
                      labelL="Passive"
                      nameR="Pronation/Supination arc Right"
                      nameL="Pronation/Supination arc Left"
                    />
                  </div>
                </div>
              </div>

              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6">
                    {/* <Link to="/personal-data" className="assm_back_btn">
                       <img src={backIcon} alt="" />
                       <span>{translation.back_button}</span>
                     </Link> */}
                  </div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <button
                      className="next_btn btn_fill me-3"
                      onClick={() => handleNext()}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}

              {doctorEnable !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6">
                    {/* <Link to="/patient-list" className="assm_back_btn">
    <img src={backIcon} alt="" />
    <span>Back</span>
  </Link> */}
                  </div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      </>

    )}
    </>
  );
}
