import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import ScoreRangeSlider from "../../../custom/score-range-slider/ScoreRangeSlider";
import InputField from "../../../custom/inputfield/InputField";
import RadioSwitch from "../../../custom/radio-switch/RadioSwitch";
import RangeSlider from "../../../custom/range-slider/RangeSlider";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import Tab from "../../tab-section/ShoulderTab";
import { useSearchParams } from "react-router-dom";

import "./Function.css";
import { useDispatch, useSelector } from "react-redux";
import {
  updateShoulderFunctionFieldScore,
  updateShoulderFunctionScores,
  updateShoulder,
  updateShoulderPainScores,
  updateWOSIFunctionScores,
  updateSACSFunctionScores,
  updateSatisfactionFunctionScores,
  updateTotalScores,
  updateAllData,
  updateFeedbackFunctionScores
} from "../../../../redux/actions/shoulderAction";
import useAxios from "../../../../axiosinstance";
import useTransition from "../../../customHooks/translations";
// import Button from "../../custom/button/Button";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import { LeftSide, LeftTrans, RightSide, RigthTrans } from "../../../../utilities/RightLeftFormat";
import NameCustom from "../../../custom/name/NameCustom";
function Function() {
  const translation = useTransition();
  let lg = sessionStorage.getItem("language");
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const shoulserScore = useSelector(
    (state) => state.shoulderScore.shoulserScore
  );
  let isdoctorForm = localStorage.getItem("isdoctorForm");
  let viewicon = localStorage.getItem("viewicon")
  const [chk, setChk] = useState(false);
  const [updateTotal, setUpdateTotal] = useState(false);
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=4`)
      .then((res) => {
        dispatch(updateShoulder(res.data.data2[0].shoulserScore));
        dispatch(updateShoulderPainScores(res.data.data2[0].painScore));
        dispatch(updateShoulderFunctionScores(res.data.data2[0].functionScore));
        dispatch(updateWOSIFunctionScores(res.data.data2[0].wosiScore));
        dispatch(updateSACSFunctionScores(res.data.data2[0].sacsScore));
        dispatch(
          updateSatisfactionFunctionScores(res.data.data2[0].safisScore)
        );
        dispatch(updateTotalScores(res.data.data2[0].TotalScores));
        dispatch(updateFeedbackFunctionScores(res.data.data2[0].doctorFeedback))
        setChk(true);
      }).catch((err) => {

        navigate('/un-authorized-access');

      });
  };

  useEffect(() => {
    save();
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [affectedShoulderL, setAffectedShoulderL] = useState("0");
  const [affectedShoulderR, setAffectedShoulderR] = useState("0");
  const [putShirtRight, setPutShirtRight] = useState("3");
  const [putShirtLeft, setPutShirtLeft] = useState("3");
  const [affectedSideRight, setAffectedSideRight] = useState("3");
  const [affectedSideLeft, setAffectedSideLeft] = useState("3");
  const [washBackRight, setWashBackRight] = useState("3");
  const [washBackLeft, setWashBackLeft] = useState("3");
  const [manageToiletRight, setManageToiletRight] = useState("3");
  const [manageToiletLeft, setManageToiletLeft] = useState("3");
  const [combHairRight, setCombHairRight] = useState("3");
  const [combHairLeft, setCombHairLeft] = useState("3");
  const [applyMakupRight, setApplyMakupRight] = useState("3");
  const [applyMakupLeft, setApplyMakupLeft] = useState("3");
  const [brushTeethRight, setBrushTeethRight] = useState("3");
  const [brushTeethLeft, setBrushTeethLeft] = useState("3");
  const [fullBottleRight, setFullBottleRight] = useState("3");
  const [fullBottleLeft, setFullBottleLeft] = useState("3");
  const [bringToMouthRight, setBringToMouthRight] = useState("3");
  const [bringToMouthLeft, setBringToMouthLeft] = useState("3");
  const [eatSoupRight, setEatSoupRight] = useState("3");
  const [eatSoupLeft, setEatSoupLeft] = useState("3");
  const [shakeHandRight, setShakeHandRight] = useState("3");
  const [shakeHandLeft, setShakeHandLeft] = useState("3");
  const [usePhoneRight, setUsePhoneRight] = useState("3");
  const [usePhoneLeft, setUsePhoneLeft] = useState("3");
  const [writeLetterRight, setWriteLetterRight] = useState("3");
  const [writeLetterLeft, setWriteLetterLeft] = useState("3");
  const [reachShelfRight, setReachShelfRight] = useState("3");
  const [reachShelfLeft, setReachShelfLeft] = useState("3");
  const [lift05Right, setLift05Right] = useState("3");
  const [lift05Left, setLift05Left] = useState("3");
  const [lift45Right, setLift45Right] = useState("3");
  const [lift45Left, setLift45Left] = useState("3");
  const [lift10Right, setLift10Right] = useState("3");
  const [lift10Left, setLift10Left] = useState("3");
  const [throwUnderHandRight, setThrowUnderHandRight] = useState("3");
  const [throwUnderHandLeft, setThrowUnderHandLeft] = useState("3");
  const [throwOverHandRight, setThrowOverHandRight] = useState("3");
  const [throwOverHandLeft, setThrowOverHandLeft] = useState("3");
  const [fullUsualWorkRight, setFullUsualWorkRight] = useState("3");
  const [fullUsualWorkLeft, setFullUsualWorkLeft] = useState("3");
  const [usualHobbies, setUsualHobbies] = useState("");
  const [usualSportRight, setUsualSportRight] = useState("3");
  const [usualSportLeft, setUsualSportLeft] = useState("3");
  const [hobbies, setHobbies] = useState("");
  const [feelUnstable, setFeelUnstable] = useState("");
  const [instabilityACjoint, setInstabilityACjoint] = useState("");
  const [unstableShoulderRangeL, setUnstableShoulderRangeL] = useState("0");
  const [unstableShoulderRangeR, setUnstableShoulderRangeR] = useState("0");
  const [recreational, setRecreationalR] = useState("0");
  const [recreationalL, setRecreationalL] = useState("0");
  const [normal, setNormalR] = useState("4");
  const [normalL, setNormalL] = useState("4");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const json = useSelector((state) => state.shoulderScore);
  const functionScore = useSelector(
    (state) => state.shoulderScore.functionScore[0]
  );
  useEffect(() => {
    setAffectedShoulderL(functionScore.affected_shoulder_left);
    setAffectedShoulderR(functionScore.affected_shoulder_right);
    setPutShirtRight(functionScore.put_shirt_right);
    setPutShirtLeft(functionScore.put_shirt_left);
    setAffectedSideRight(functionScore.affected_side_right);
    setAffectedSideLeft(functionScore.affected_side_left);
    setWashBackRight(functionScore.wash_back_right);
    setWashBackLeft(functionScore.wash_back_left);
    setManageToiletRight(functionScore.manage_toilet_right);
    setManageToiletLeft(functionScore.manage_toilet_left);
    setCombHairRight(functionScore.comb_hair_right);
    setCombHairLeft(functionScore.comb_hair_left);
    setApplyMakupRight(functionScore.apply_makup_right);
    setApplyMakupLeft(functionScore.apply_makup_left);
    setBrushTeethRight(functionScore.brush_teeth_right);
    setBrushTeethLeft(functionScore.brush_teeth_left);
    setFullBottleRight(functionScore.full_bottle_right);
    setFullBottleLeft(functionScore.full_bottle_left);
    setBringToMouthRight(functionScore.bring_mouth_right);
    setBringToMouthLeft(functionScore.bring_mouth_left);
    setEatSoupRight(functionScore.eat_soup_right);
    setEatSoupLeft(functionScore.eat_soup_left);
    setShakeHandRight(functionScore.shake_hand_right);
    setShakeHandLeft(functionScore.shake_hand_left);
    setUsePhoneRight(functionScore.use_phone_right);
    setUsePhoneLeft(functionScore.use_phone_left);
    setWriteLetterRight(functionScore.write_letter_right);
    setWriteLetterLeft(functionScore.write_letter_left);
    setReachShelfRight(functionScore.reach_shelf_right);
    setReachShelfLeft(functionScore.reach_shelf_left);
    setLift05Right(functionScore.lift05_right);
    setLift05Left(functionScore.lift05_left);
    setLift45Right(functionScore.lift45_right);
    setLift45Left(functionScore.lift45_left);
    setLift10Right(functionScore.lift10_right);
    setLift10Left(functionScore.lift10_left);
    setThrowUnderHandRight(functionScore.throwunder_hand_right);
    setThrowUnderHandLeft(functionScore.throwunder_hand_left);
    setThrowOverHandRight(functionScore.throwover_hand_right);
    setThrowOverHandLeft(functionScore.throwover_hand_left);
    setFullUsualWorkRight(functionScore.fullusual_work_right);
    setFullUsualWorkLeft(functionScore.fullusual_work_left);
    setUsualHobbies(functionScore.usual_hobbies);
    setUsualSportRight(functionScore.usual_sport_right);
    setUsualSportLeft(functionScore.usual_sport_left);
    setHobbies(functionScore.hobbies);
    setFeelUnstable(functionScore.feel_unstable);
    setInstabilityACjoint(functionScore.instability_acjoint);
    setUnstableShoulderRangeL(functionScore.unstable_shoulder_range_left);
    setUnstableShoulderRangeR(functionScore.unstable_shoulder_range_right);
    setRecreationalR(functionScore.recreational_right);
    setRecreationalL(functionScore.recreational_left);
    setNormalR(functionScore.normal_right);
    setNormalL(functionScore.normal_left);
  }, [functionScore]);
  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true" ? "1" : "0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [functionScore, updateTotal, doctorEnable]);


  // useEffect(() => {
  //   if (feelUnstable === "0") {
  //     dispatch(
  //       updateShoulderFunctionFieldScore({
  //         key: "unstable_shoulder_range_left",
  //         value: "0",
  //       })
  //     );
  //     dispatch(
  //       updateShoulderFunctionFieldScore({
  //         key: "unstable_shoulder_range_right",
  //         value: "0",
  //       })
  //     );
  //     dispatch(
  //       updateWOSIFunctionScores([
  //         {
  //           wosi_q1_left: "0",
  //           wosi_q1_right: "0",
  //           wosi_q2_left: "0",
  //           wosi_q2_right: "0",
  //           wosi_q3_left: "0",
  //           wosi_q3_right: "0",
  //           wosi_q4_left: "0",
  //           wosi_q4_right: "0",
  //           wosi_q5_left: "0",
  //           wosi_q5_right: "0",
  //           wosi_q6_left: "0",
  //           wosi_q6_right: "0",
  //           wosi_q7_left: "0",
  //           wosi_q7_right: "0",
  //           wosi_q8_left: "0",
  //           wosi_q8_right: "0",
  //           wosi_q9_left: "0",
  //           wosi_q9_right: "0",
  //           wosi_q10_left: "0",
  //           wosi_q10_right: "0",
  //           wosi_q11_left: "0",
  //           wosi_q11_right: "0",
  //           wosi_q12_left: "0",
  //           wosi_q12_right: "0",
  //           wosi_q13_left: "0",
  //           wosi_q13_right: "0",
  //           wosi_q14_left: "0",
  //           wosi_q14_right: "0",
  //           wosi_q15_left: "0",
  //           wosi_q15_right: "0",
  //           wosi_q16_left: "0",
  //           wosi_q16_right: "0",
  //           wosi_q17_left: "0",
  //           wosi_q17_right: "0",
  //           wosi_q18_left: "0",
  //           wosi_q18_right: "0",
  //           wosi_q19_left: "0",
  //           wosi_q19_right: "0",
  //           wosi_q20_left: "0",
  //           wosi_q20_right: "0",
  //           wosi_q21_left: "0",
  //           wosi_q21_right: "0",
  //         },
  //       ])
  //     );
  //   }

  // }, [feelUnstable === "0"])

  // useEffect(() => {
  //   if (instabilityACjoint === "0") {
  //     dispatch(
  //       updateSACSFunctionScores([
  //         {
  //           sacs_q1_left: "0",
  //           sacs_q1_right: "0",
  //           sacs_q2_left: "0",
  //           sacs_q2_right: "0",
  //           sacs_q3_left: "0",
  //           sacs_q3_right: "0",
  //           sacs_q4_left: "0",
  //           sacs_q4_right: "0",
  //           sacs_q5_left: "0",
  //           sacs_q5_right: "0",
  //           sacs_q6_left: "0",
  //           sacs_q6_right: "0",
  //           sacs_q7_left: "0",
  //           sacs_q7_right: "0",
  //           sacs_q8_left: "0",
  //           sacs_q8_right: "0",
  //           sacs_q9_left: "0",
  //           sacs_q9_right: "0",
  //           sacs_q10_left: "0",
  //           sacs_q10_right: "0",
  //           sacs_q11_left: "0",
  //           sacs_q11_right: "0",
  //           sacs_q12_left: "0",
  //           sacs_q12_right: "0",
  //           sacs_q13_left: "0",
  //           sacs_q13_right: "0",
  //           sacs_q14_left: "0",
  //           sacs_q14_right: "0",
  //           sacs_q15_left: "0",
  //           sacs_q15_right: "0",
  //           sacs_q16_left: "0",
  //           sacs_q16_right: "0",
  //           sacs_q17_left: "0",
  //           sacs_q17_right: "0",
  //           sacs_q18_left: "0",
  //           sacs_q18_right: "0",
  //           sacs_q19_left: "0",
  //           sacs_q19_right: "0",
  //           sacs_q20_left: "0",
  //           sacs_q20_right: "0",
  //           sacs_q21_left: "0",
  //           sacs_q21_right: "0",

  //         },
  //       ])
  //     );
  //   }
  // }, [instabilityACjoint === "0"])

  const handleAffectedShoulderL = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "affected_shoulder_left",
        value: e,
      })
    );
    setAffectedShoulderL(e);
  };
  const handleAffectedShoulderR = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "affected_shoulder_right",
        value: e,
      })
    );
    setAffectedShoulderR(e);
  };
  const handlePutShirtRight = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "put_shirt_right",
        value: e,
      })
    );
    setPutShirtRight(e);
  };
  const handlePutShirtLeft = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "put_shirt_left",
        value: e,
      })
    );
    setPutShirtLeft(e);
  };
  const handleAffectedSideRight = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "affected_side_right",
        value: e,
      })
    );
    setAffectedSideRight(e);
  };
  const handleAffectedSideLeft = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "affected_side_left",
        value: e,
      })
    );
    setAffectedSideLeft(e);
  };
  const handleWashBackRight = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "wash_back_right",
        value: e,
      })
    );
    setWashBackRight(e);
  };
  const handleWashBackLeft = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "wash_back_left",
        value: e,
      })
    );
    setWashBackLeft(e);
  };
  const handleManageToiletRight = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "manage_toilet_right",
        value: e,
      })
    );
    setManageToiletRight(e);
  };
  const handleManageToiletLeft = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "manage_toilet_left",
        value: e,
      })
    );
    setManageToiletLeft(e);
  };
  const handleCombHairRight = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "comb_hair_right",
        value: e,
      })
    );
    setCombHairRight(e);
  };
  const handleCombHairLeft = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "comb_hair_left",
        value: e,
      })
    );
    setCombHairLeft(e);
  };
  const handleApplyMakupRight = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "apply_makup_right",
        value: e,
      })
    );
    setApplyMakupRight(e);
  };
  const handleApplyMakupLeft = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "apply_makup_left",
        value: e,
      })
    );
    setApplyMakupLeft(e);
  };
  const handleBrushTeethRight = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "brush_teeth_right",
        value: e,
      })
    );
    setBrushTeethRight(e);
  };
  const handleBrushTeethLeft = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "brush_teeth_left",
        value: e,
      })
    );
    setBrushTeethLeft(e);
  };
  const handleFullBottleRight = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "full_bottle_right",
        value: e,
      })
    );
    setFullBottleRight(e);
  };
  const handleFullBottleLeft = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "full_bottle_left",
        value: e,
      })
    );
    setFullBottleLeft(e);
  };
  const handleBringToMouthRight = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "bring_mouth_right",
        value: e,
      })
    );
    setBringToMouthRight(e);
  };
  const handleBringToMouthLeft = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "bring_mouth_left",
        value: e,
      })
    );
    setBringToMouthLeft(e);
  };
  const handleEatSoupRight = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "eat_soup_right",
        value: e,
      })
    );
    setEatSoupRight(e);
  };
  const handleEatSoupLeft = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "eat_soup_left",
        value: e,
      })
    );
    setEatSoupLeft(e);
  };
  const handleShakeHandRight = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "shake_hand_right",
        value: e,
      })
    );
    setShakeHandRight(e);
  };
  const handleShakeHandLeft = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "shake_hand_left",
        value: e,
      })
    );
    setShakeHandLeft(e);
  };
  const handleUsePhoneRight = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "use_phone_right",
        value: e,
      })
    );
    setUsePhoneRight(e);
  };
  const handleUsePhoneLeft = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "use_phone_left",
        value: e,
      })
    );
    setUsePhoneLeft(e);
  };
  const handleWriteLetterRight = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "write_letter_right",
        value: e,
      })
    );
    setWriteLetterRight(e);
  };
  const handleWriteLetterLeft = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "write_letter_left",
        value: e,
      })
    );
    setWriteLetterLeft(e);
  };
  const handleReachShelfRight = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "reach_shelf_right",
        value: e,
      })
    );
    setReachShelfRight(e);
  };
  const handleReachShelfLeft = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "reach_shelf_left",
        value: e,
      })
    );
    setReachShelfLeft(e);
  };
  const handleLift05Right = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "lift05_right",
        value: e,
      })
    );
    setLift05Right(e);
  };
  const handleLift05Left = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "lift05_left",
        value: e,
      })
    );
    setLift05Left(e);
  };
  const handleLift45Right = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "lift45_right",
        value: e,
      })
    );
    setLift45Right(e);
  };
  const handleLift45Left = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "lift45_left",
        value: e,
      })
    );
    setLift45Left(e);
  };
  const handleLift10Right = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "lift10_right",
        value: e,
      })
    );
    setLift10Right(e);
  };
  const handleLift10Left = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "lift10_left",
        value: e,
      })
    );
    setLift10Left(e);
  };
  const handleThrowUnderHandRight = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "throwunder_hand_right",
        value: e,
      })
    );
    setThrowUnderHandRight(e);
  };
  const handleThrowUnderHandLeft = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "throwunder_hand_left",
        value: e,
      })
    );
    setThrowUnderHandLeft(e);
  };
  const handleThrowOverHandRight = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "throwover_hand_right",
        value: e,
      })
    );
    setThrowOverHandRight(e);
  };
  const handleThrowOverHandLeft = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "throwover_hand_left",
        value: e,
      })
    );
    setThrowOverHandLeft(e);
  };
  const handleFullUsualWorkRight = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "fullusual_work_right",
        value: e,
      })
    );
    setFullUsualWorkRight(e);
  };
  const handleFullUsualWorkLeft = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "fullusual_work_left",
        value: e,
      })
    );
    setFullUsualWorkLeft(e);
  };
  const handleUsualHobbies = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "usual_hobbies",
        value: e,
      })
    );
    setUsualHobbies(e);
  };
  const handleUsualSportRight = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "usual_sport_right",
        value: e,
      })
    );
    setUsualSportRight(e);
  };
  const handleUsualSportLeft = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "usual_sport_left",
        value: e,
      })
    );
    setUsualSportLeft(e);
  };
  const handleHobbies = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "hobbies",
        value: e,
      })
    );
    setHobbies(e);
  };
  const handleFeelUnstable = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "feel_unstable",
        value: e,
      })
    );
    setFeelUnstable(e);
  };
  const handleInstabilityACjoint = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "instability_acjoint",
        value: e,
      })
    );
    setInstabilityACjoint(e);
  };
  const handleUnstableShoulderRangeL = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "unstable_shoulder_range_left",
        value: e,
      })
    );
    setUnstableShoulderRangeL(e);
  };
  const handleUnstableShoulderRangeR = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "unstable_shoulder_range_right",
        value: e,
      })
    );
    setUnstableShoulderRangeR(e);
  };
  const handlerecreational = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "recreational_right",
        value: e,
      })
    );
    setRecreationalR(e);
  };
  const handlerecreationalL = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "recreational_left",
        value: e,
      })
    );
    setRecreationalL(e);
  };
  const handlenormal = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "normal_right",
        value: e,
      })
    );
    setNormalR(e);
  };
  const handlenormalL = (e) => {
    dispatch(
      updateShoulderFunctionFieldScore({
        key: "normal_left",
        value: e,
      })
    );
    setNormalL(e);
  };



  const continueRedirect = (e) => {
    navigate("/feeling-insta/" + rId)
    // dispatch(
    //   updateShoulderFunctionScores([
    //     {
    //       affected_shoulder_left: affectedShoulderL,
    //       affected_shoulder_right: affectedShoulderR,
    //       put_shirt_right: putShirtRight,
    //       put_shirt_left: putShirtLeft,
    //       affected_side_right: affectedSideRight,
    //       affected_side_left: affectedSideLeft,
    //       wash_back_right: washBackRight,
    //       wash_back_left: washBackLeft,
    //       manage_toilet_right: manageToiletRight,
    //       manage_toilet_left: manageToiletLeft,
    //       comb_hair_right: combHairRight,
    //       comb_hair_left: combHairLeft,
    //       apply_makup_right: applyMakupRight,
    //       apply_makup_left: applyMakupLeft,
    //       brush_teeth_right: brushTeethRight,
    //       brush_teeth_left: brushTeethLeft,
    //       full_bottle_right: fullBottleRight,
    //       full_bottle_left: fullBottleLeft,
    //       bring_mouth_right: bringToMouthRight,
    //       bring_mouth_left: bringToMouthLeft,
    //       eat_soup_right: eatSoupRight,
    //       eat_soup_left: eatSoupLeft,
    //       shake_hand_right: shakeHandRight,
    //       shake_hand_left: shakeHandLeft,
    //       use_phone_right: usePhoneRight,
    //       use_phone_left: usePhoneLeft,
    //       write_letter_right: writeLetterRight,
    //       write_letter_left: writeLetterLeft,
    //       reach_shelf_right: reachShelfRight,
    //       reach_shelf_left: reachShelfLeft,
    //       lift05_right: lift05Right,
    //       lift05_left: lift05Left,
    //       lift45_right: lift45Right,
    //       lift45_left: lift45Left,
    //       lift10_right: lift10Right,
    //       lift10_left: lift10Left,
    //       throwunder_hand_right: throwUnderHandRight,
    //       throwunder_hand_left: throwUnderHandLeft,
    //       throwover_hand_right: throwOverHandRight,
    //       throwover_hand_left: throwOverHandLeft,
    //       fullusual_work_right: fullUsualWorkRight,
    //       fullusual_work_left: fullUsualWorkLeft,
    //       usual_hobbies: usualHobbies,
    //       usual_sport_right: usualSportRight,
    //       usual_sport_left: usualSportLeft,
    //       hobbies: hobbies,
    //       feel_unstable: feelUnstable,
    //       instability_acjoint: instabilityACjoint,
    //       unstable_shoulder_range_right: unstableShoulderRangeR,
    //       unstable_shoulder_range_left: unstableShoulderRangeL,
    //       recreational_right: recreational,
    //       recreational_left: recreationalL,
    //       normal_right: normal,
    //       normal_left: normalL,
    //     },
    //   ])
    // );
   
    // if (feelUnstable === "1" && instabilityACjoint == "") {
    //   navigate("/wosi/" + rId);
    // }
    // if (feelUnstable === "0" && instabilityACjoint == "") {
    //   navigate("/satisfaction/" + rId);
    // }
    // if (feelUnstable === "" && instabilityACjoint == "0") {
    //   navigate("/satisfaction/" + rId);
    // }
    // if (feelUnstable === "" && instabilityACjoint == "1") {
    //   navigate("/sacs/" + rId);
    // }

    // if (feelUnstable === "0" && instabilityACjoint == "0") {
    //   navigate("/satisfaction/" + rId);
    // }
    // if (feelUnstable === "" && instabilityACjoint == "") {
    //   navigate("/satisfaction/" + rId);
    // }
    // if (feelUnstable === "1" && instabilityACjoint == "0") {
    //   navigate("/wosi/" + rId);
    // }
    // if (instabilityACjoint == "1" && feelUnstable === "1") {
    //   navigate("/wosi/" + rId);
    // }
    // if (feelUnstable === "0" && instabilityACjoint == "1") {
    //   navigate("/sacs/" + rId);
    // }
  };
  let akpt = searchParams.get("akpt");
  const [msg, setMsg] = useState('');
  const [msgType, setmsgType] = useState('');
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(true);
    setMsg(translation.update);
    setUpdateTotal(!updateTotal);
    setmsgType('success')
   setTimeout(() => {
     let view_circular=  sessionStorage.getItem("view_circular")
      // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
      // window.location.href = process.env.REACT_APP_DOCTOR_URL +`view-management-plan/${akpt}/${window.btoa(
      //   rId
      // )}/?org=${window.btoa(sessionStorage.getItem("orgid")||'')}&formType=${
      //   window.btoa('4') || ""
      // }&view_circular=${view_circular==='false'?'false':'true'}`
      window.location.href = process.env.REACT_APP_DOCTOR_URL +`patient-details/${akpt}?formid=4`
      setMsg('')
      setmsgType('success')
    }, 2000);

  };
  const handleNext = (e) => {
    navigate(`/feeling-insta/${rId}?d=true&akpt=${akpt}`);

  }
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  const Back = () => {
    navigate(-1, { replace: false });

  };
  // console.log(unstableShoulderRangeR, unstableShoulderRangeL)
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="function_screen mb_100">
          <div class="d-flex flex-row-reverse">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <Tab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />

              <h4 className="ph_title f_40">{translation.patient_shoulder_function}</h4>
              <div className="row">
                {shoulserScore === "1" || shoulserScore === "3" ? (
                  <div className={"col-md-6 col-sm-6 pt_20" + RightSide()}>
                    <div className="mb_10">
                      <FullDropdown
                        data={[
                          { value: "0", label: translation.patient_shoulder_function_q1_a1 },
                          {
                            value: "1",
                            label:
                              translation.patient_shoulder_function_q1_a2,
                          },
                          {
                            value: "2",
                            label:
                              translation.patient_shoulder_function_q1_a3,
                          },
                          {
                            value: "3",
                            label:
                              translation.patient_shoulder_function_q1_a4,
                          },
                          {
                            value: "4",
                            label: translation.patient_shoulder_function_q1_a5,
                          },
                          { value: "5", label: translation.patient_shoulder_function_q1_a6 },
                        ]}
                        label={translation.patient_shoulder_function_q1 + RigthTrans(shoulserScore, translation)}

                        value={affectedShoulderR}
                        placeholder={translation.none}
                        onChange={handleAffectedShoulderR}
                      />
                    </div>
                  </div>
                ) : null}
                {shoulserScore === "2" || shoulserScore === "3" ? (
                  <div className={"col-md-6 col-sm-6 pt_20" + LeftSide()}>

                    <div className="mb_10">
                      <FullDropdown
                        data={[
                          { value: "0", label: translation.patient_shoulder_function_q1_a1 },
                          {
                            value: "1",
                            label:
                              translation.patient_shoulder_function_q1_a2,
                          },
                          {
                            value: "2",
                            label:
                              translation.patient_shoulder_function_q1_a3,
                          },
                          {
                            value: "3",
                            label:
                              translation.patient_shoulder_function_q1_a4,
                          },
                          {
                            value: "4",
                            label: translation.patient_shoulder_function_q1_a5,
                          },
                          { value: "5", label: translation.patient_shoulder_function_q1_a6 },
                        ]}
                        label={translation.patient_shoulder_function_q1 + LeftTrans(shoulserScore, translation)}

                        value={affectedShoulderL}
                        placeholder={translation.none}
                        onChange={handleAffectedShoulderL}
                      />
                    </div>

                  </div>
                ) : null}
              </div>
              <div className="function_formtop">
                <div className="row">
                  <div className="col-md-6 col-sm-6 function_head">
                    <label>
                      {translation.patient_shoulder_function_instruction}
                    </label><br />
                    <span dir={lg === "ara" ? "rtl" : " "}>
                      (<strong>0</strong> = {translation.patient_shoulder_function_instruction_0},{" "}
                      <strong>1</strong> = {translation.patient_shoulder_function_instruction_1},{" "}
                      <strong>2</strong> = {translation.patient_shoulder_function_instruction_2},{" "}
                      <strong>3</strong> = {translation.patient_shoulder_function_instruction_3})
                    </span>
                  </div>
                  <div className="col-md-6 col-sm-6 function_note">
                    <p className="info-note">
                      {translation.patient_shoulder_function_note}
                    </p>
                  </div>
                </div>
                <div className="row">
                  {shoulserScore === "1" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q2 + RigthTrans(shoulserScore, translation)}
                        classes=""
                        value={putShirtRight}
                        onChange={handlePutShirtRight}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q2_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q2_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q2_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q2_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                  {shoulserScore === "2" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q2 + LeftTrans(shoulserScore, translation)}
                        classes=""
                        value={putShirtLeft}
                        onChange={handlePutShirtLeft}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q2_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q2_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q2_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q2_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {shoulserScore === "1" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q3 + RigthTrans(shoulserScore, translation)}
                        classes=""
                        value={affectedSideRight}
                        onChange={handleAffectedSideRight}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q3_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q3_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q3_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q3_a3 },
                        ]}

                      />
                    </div>
                  ) : null}
                  {shoulserScore === "2" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q3 + LeftTrans(shoulserScore, translation)}
                        classes=""
                        value={affectedSideLeft}
                        onChange={handleAffectedSideLeft}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q3_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q3_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q3_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q3_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {shoulserScore === "1" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q4 + RigthTrans(shoulserScore, translation)}
                        classes=""
                        value={washBackRight}
                        onChange={handleWashBackRight}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q4_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q4_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q4_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q4_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                  {shoulserScore === "2" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q4 + LeftTrans(shoulserScore, translation)}
                        classes=""
                        value={washBackLeft}
                        onChange={handleWashBackLeft}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q4_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q4_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q4_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q4_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {shoulserScore === "1" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q5 + RigthTrans(shoulserScore, translation)}
                        classes=""
                        value={manageToiletRight}
                        onChange={handleManageToiletRight}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q5_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q5_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q5_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q5_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                  {shoulserScore === "2" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q5 + LeftTrans(shoulserScore, translation)}
                        classes=""
                        value={manageToiletLeft}
                        onChange={handleManageToiletLeft}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q5_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q5_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q5_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q5_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {shoulserScore === "1" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q6 + RigthTrans(shoulserScore, translation)}
                        classes=""
                        value={combHairRight}
                        onChange={handleCombHairRight}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q6_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q6_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q6_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q6_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                  {shoulserScore === "2" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q6 + LeftTrans(shoulserScore, translation)}
                        classes=""
                        value={combHairLeft}
                        onChange={handleCombHairLeft}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q6_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q6_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q6_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q6_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {shoulserScore === "1" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q7 + RigthTrans(shoulserScore, translation)}
                        classes=""
                        value={applyMakupRight}
                        onChange={handleApplyMakupRight}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q7_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q7_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q7_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q7_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                  {shoulserScore === "2" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q7 + LeftTrans(shoulserScore, translation)}
                        classes=""
                        value={applyMakupLeft}
                        onChange={handleApplyMakupLeft}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q7_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q7_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q7_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q7_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {shoulserScore === "1" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q8 + RigthTrans(shoulserScore, translation)}
                        classes=""
                        value={brushTeethRight}
                        onChange={handleBrushTeethRight}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q8_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q8_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q8_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q8_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                  {shoulserScore === "2" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q8 + LeftTrans(shoulserScore, translation)}
                        classes=""
                        value={brushTeethLeft}
                        onChange={handleBrushTeethLeft}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q8_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q8_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q8_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q8_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {shoulserScore === "1" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q9 + RigthTrans(shoulserScore, translation)}
                        classes=""
                        value={fullBottleRight}
                        onChange={handleFullBottleRight}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q9_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q9_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q9_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q9_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                  {shoulserScore === "2" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q9 + LeftTrans(shoulserScore, translation)}
                        classes=""
                        value={fullBottleLeft}
                        onChange={handleFullBottleLeft}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q9_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q9_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q9_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q9_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {shoulserScore === "1" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q10 + RigthTrans(shoulserScore, translation)}
                        classes=""
                        value={bringToMouthRight}
                        onChange={handleBringToMouthRight}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q10_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q10_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q10_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q10_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                  {shoulserScore === "2" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q10 + LeftTrans(shoulserScore, translation)}
                        classes=""
                        value={bringToMouthLeft}
                        onChange={handleBringToMouthLeft}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q10_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q10_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q10_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q10_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {shoulserScore === "1" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q11 + RigthTrans(shoulserScore, translation)}
                        classes=""
                        value={eatSoupRight}
                        onChange={handleEatSoupRight}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q11_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q11_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q11_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q11_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                  {shoulserScore === "2" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q11 + LeftTrans(shoulserScore, translation)}
                        classes=""
                        value={eatSoupLeft}
                        onChange={handleEatSoupLeft}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q11_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q11_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q11_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q11_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {shoulserScore === "1" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q12 + RigthTrans(shoulserScore, translation)}
                        classes=""
                        value={shakeHandRight}
                        onChange={handleShakeHandRight}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q12_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q12_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q12_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q12_a3 },
                        ]}
                      />

                    </div>
                  ) : null}
                  {shoulserScore === "2" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q12 + LeftTrans(shoulserScore, translation)}
                        classes=""
                        value={shakeHandLeft}
                        onChange={handleShakeHandLeft}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q12_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q12_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q12_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q12_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {shoulserScore === "1" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q13 + RigthTrans(shoulserScore, translation)}
                        classes=""
                        value={usePhoneRight}
                        onChange={handleUsePhoneRight}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q13_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q13_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q13_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q13_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                  {shoulserScore === "2" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q13 + LeftTrans(shoulserScore, translation)}
                        classes=""
                        value={usePhoneLeft}
                        onChange={handleUsePhoneLeft}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q13_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q13_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q13_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q13_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {shoulserScore === "1" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q14 + RigthTrans(shoulserScore, translation)}
                        classes=""
                        value={writeLetterRight}
                        onChange={handleWriteLetterRight}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q14_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q14_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q14_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q14_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                  {shoulserScore === "2" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q14 + LeftTrans(shoulserScore, translation)}
                        classes=""
                        value={writeLetterLeft}
                        onChange={handleWriteLetterLeft}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q14_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q14_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q14_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q14_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {shoulserScore === "1" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q15 + RigthTrans(shoulserScore, translation)}
                        classes=""
                        value={reachShelfRight}
                        onChange={handleReachShelfRight}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q15_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q15_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q15_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q15_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                  {shoulserScore === "2" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q15 + LeftTrans(shoulserScore, translation)}
                        classes=""
                        value={reachShelfLeft}
                        onChange={handleReachShelfLeft}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q15_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q15_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q15_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q15_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {shoulserScore === "1" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q16 + RigthTrans(shoulserScore, translation)}
                        classes=""
                        value={lift05Right}
                        onChange={handleLift05Right}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q16_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q16_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q16_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q16_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                  {shoulserScore === "2" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q16 + LeftTrans(shoulserScore, translation)}
                        classes=""
                        value={lift05Left}
                        onChange={handleLift05Left}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q16_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q16_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q16_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q16_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {shoulserScore === "1" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q17 + RigthTrans(shoulserScore, translation)}
                        classes=""
                        value={lift45Right}
                        onChange={handleLift45Right}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q17_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q17_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q17_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q17_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                  {shoulserScore === "2" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q17 + LeftTrans(shoulserScore, translation)}
                        classes=""
                        value={lift45Left}
                        onChange={handleLift45Left}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q17_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q17_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q17_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q17_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {shoulserScore === "1" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q18 + RigthTrans(shoulserScore, translation)}
                        classes=""
                        value={lift10Right}
                        onChange={handleLift10Right}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q18_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q18_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q18_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q18_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                  {shoulserScore === "2" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q18 + LeftTrans(shoulserScore, translation)}
                        classes=""
                        value={lift10Left}
                        onChange={handleLift10Left}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q18_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q18_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q18_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q18_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {shoulserScore === "1" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q19 + RigthTrans(shoulserScore, translation)}
                        classes=""
                        value={throwUnderHandRight}
                        onChange={handleThrowUnderHandRight}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q19_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q19_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q19_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q19_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                  {shoulserScore === "2" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q19 + LeftTrans(shoulserScore, translation)}
                        classes=""
                        value={throwUnderHandLeft}
                        onChange={handleThrowUnderHandLeft}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q19_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q19_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q19_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q19_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {shoulserScore === "1" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q20 + RigthTrans(shoulserScore, translation)}
                        classes=""
                        value={throwOverHandRight}
                        onChange={handleThrowOverHandRight}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q20_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q20_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q20_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q20_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                  {shoulserScore === "2" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q20 + LeftTrans(shoulserScore, translation)}
                        classes=""
                        value={throwOverHandLeft}
                        onChange={handleThrowOverHandLeft}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q20_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q20_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q20_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q20_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {shoulserScore === "1" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q21 + RigthTrans(shoulserScore, translation)}
                        classes=""
                        value={fullUsualWorkRight}
                        onChange={handleFullUsualWorkRight}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q21_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q21_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q21_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q21_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                  {shoulserScore === "2" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q21 + LeftTrans(shoulserScore, translation)}
                        classes=""
                        value={fullUsualWorkLeft}
                        onChange={handleFullUsualWorkLeft}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q21_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q21_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q21_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q21_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {shoulserScore === "1" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6" + RightSide()}>
                      <div className="mb_10">
                        <RangeSlider
                          id="painTodayRatingR"
                          min="0"
                          max="4"
                          step="1"
                          type="text"
                          colorcode="1"
                          color="#000"
                          leftTitle={translation.patient_shoulder_function_q22_a0}
                          rightTitle={translation.patient_shoulder_function_q22_a1}
                          label={translation.patient_shoulder_function_q22 + RigthTrans(shoulserScore, translation)}
                          value={normal}
                          onChange={handlenormal}
                        />
                      </div>
                    </div>
                  ) : null}
                  {shoulserScore === "2" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6" + LeftSide()}>
                      <div className="mb_10">
                        <RangeSlider
                          id="painTodayRatingR"
                          min="0"
                          max="4"
                          step="1"
                          type="text"
                          colorcode="1"
                          color="#000"
                          leftTitle={translation.patient_shoulder_function_q22_a0}
                          rightTitle={translation.patient_shoulder_function_q22_a1}
                          label={translation.patient_shoulder_function_q22 + LeftTrans(shoulserScore, translation)}
                          value={normalL}
                          onChange={handlenormalL}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="row mt-5">
                  {shoulserScore === "1" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q23 + RigthTrans(shoulserScore, translation)}
                        classes=""
                        value={usualSportRight}
                        onChange={handleUsualSportRight}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q23_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q23_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q23_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q23_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                  {shoulserScore === "2" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <ScoreRangeSlider
                        label={translation.patient_shoulder_function_q23 + LeftTrans(shoulserScore, translation)}
                        classes=""
                        value={usualSportLeft}
                        onChange={handleUsualSportLeft}
                        sliderData={[
                          { score: "0", label: translation.patient_shoulder_function_q23_a0 },
                          { score: "1", label: translation.patient_shoulder_function_q23_a1 },
                          { score: "2", label: translation.patient_shoulder_function_q23_a2 },
                          { score: "3", label: translation.patient_shoulder_function_q23_a3 },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>

                <div className="row">
                  {shoulserScore === "1" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6" + RightSide()}>
                      <div className="mb_10">
                        <RangeSlider
                          id="painTodayRatingR"
                          min="0"
                          max="4"
                          step="1"
                          type="text"
                          colorcode="1"
                          color="#000"
                          leftTitle={translation.patient_shoulder_function_q24_a0}
                          rightTitle={translation.patient_shoulder_function_q24_a1}
                          label={translation.patient_shoulder_function_q24 + RigthTrans(shoulserScore, translation)}
                          value={recreational}
                          onChange={handlerecreational}
                        />
                      </div>
                    </div>
                  ) : null}
                  {shoulserScore === "2" || shoulserScore === "3" ? (
                    <div className={"col-md-6 col-sm-6" + LeftSide()}>
                      <div className="mb_10">
                        <RangeSlider
                          id="painTodayRatingR"
                          min="0"
                          max="4"
                          step="1"
                          type="text"
                          colorcode="1"
                          color="#000"
                          leftTitle={translation.patient_shoulder_function_q24_a0}
                          rightTitle={translation.patient_shoulder_function_q24_a1}
                          label={translation.patient_shoulder_function_q24 + LeftTrans(shoulserScore, translation)}
                          value={recreationalL}
                          onChange={handlerecreationalL}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>


                <div className="row mt-5">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <InputField
                      label={translation.patient_shoulder_function_q25}
                      value={usualHobbies}
                      type="textarea"
                      placeholder={translation.List_work_activities}
                      onChange={handleUsualHobbies}
                      styleClass="h_150"
                      formGroupClass="label_back"
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <InputField
                      label={translation.patient_shoulder_function_q26}
                      value={hobbies}
                      type="textarea"
                      placeholder={translation.List_hobbies}
                      onChange={handleHobbies}
                      styleClass="h_150"
                      formGroupClass="label_back"
                    />
                  </div>
                </div>
              </div>
              {/* <div className="function_formbottom">
                <div className="row">
                  <div className="col-md-12 col-sm-12 mb_10">
                    <RadioSwitch
                      labelLeft="No"
                      labelRight="Yes"
                      idLeft="feelUnstableNo"
                      idRight="feelUnstableYes"
                      name="feelUnstable"
                      title={
                        <>
                          {translation.Does_shoulder_feel_unstable}{" "}
                          <small>({translation.as_going_dislocate})</small>?
                        </>
                      }
                      styleClass=""
                      value={feelUnstable}
                      onChange={handleFeelUnstable}
                      placeholder="select"
                    />
                  </div>
                </div>
                {feelUnstable === "1" && (
                  <>
                    <div className="row">
                      {shoulserScore === "2" || shoulserScore === "3" ? (
                        <div className="col-md-12 col-sm-12 mb_20">
                          <RangeSlider
                            id="unstableShoulderRangeL"
                            min="0"
                            max="10"
                            step="0.1"
                            type="image"
                            leftTitle="Stable"
                            rightTitle="Unstable"
                            label={shoulserScore === "3" ? translation.Please_indicate_shoulder_L : translation.Please_indicate_shoulder}
                            value={unstableShoulderRangeL}
                            onChange={handleUnstableShoulderRangeL}
                          />
                        </div>
                      ) : null}
                    </div>
                    <div className="row">
                      {shoulserScore === "1" || shoulserScore === "3" ? (
                        <div className="col-md-12 col-sm-12 mb_20">
                          <RangeSlider
                            id="unstableShoulderRangeR"
                            min="0"
                            max="10"
                            step="0.1"
                            type="image"
                            leftTitle="Stable"
                            rightTitle="Unstable"
                            label={shoulserScore === "3" ? translation.Please_indicate_shoulder_R : translation.Please_indicate_shoulder}
                            value={unstableShoulderRangeR}
                            onChange={handleUnstableShoulderRangeR}
                          />
                        </div>
                      ) : null}
                    </div>
                  </>
                )}
                <div className="row">
                  <div className="col-md-12 col-sm-12 mb_10">
                    <RadioSwitch
                      labelLeft="No"
                      labelRight="Yes"
                      idLeft="instabilityACjointNo"
                      idRight="instabilityACjointYes"
                      name="instabilityACjoint"
                      title={translation.Does_instability_joint}
                      styleClass=""
                      value={instabilityACjoint}
                      onChange={handleInstabilityACjoint}
                      placeholder="select"
                    />
                  </div>
                </div>
              </div> */}
              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6">
                    {/* <Link to="/personal-data" className="assm_back_btn">
                       <img src={backIcon} alt="" />
                       <span>{translation.back_button}</span>
                     </Link> */}
                  </div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <button
                      className="next_btn btn_fill me-3"
                      onClick={(e) => handleNext(e)}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}
              {doctorEnable !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    <Link to={"/pain/" + rId + `?is_doctor_form=${isdoctorForm}&edit=${viewicon}`} className="assm_back_btn">
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                    {/* <span onClick={()=>Back()} className="assm_back_btn">
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </span> */}
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Function;
