import React, { useMemo, useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

import LoadingSpinner from "../custom/loader/LoadingSpinner";
import Dropdown from "../custom/dropdown/Dropdown";
import { Validators } from "../../utilities/Validator";
import nextWhiteIcon from "../../images/next-white.png";
import nextIcon from "../../images/next.png";
import useTranslation from "../customHooks/translations";

// import Button from "../../custom/button/Button";

function FormsMap() {
const translation=useTranslation();
  const { akPt, akDc, akOu } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [scoringForm, setScoringForm] = useState("");
  const [formVaild, setFormVaild] = useState(false);
  const scoringFormRef = useRef();
  const navigate = useNavigate();
  // console.log(atob(akPt));
  // console.log(atob(akDc));
  // console.log(atob(akOu));
  
  const handleScoringForm = (e) => {
    setScoringForm(e);
  };
  const continueRedirect = (e) => {
    if (!scoringForm) {
      setFormVaild(true);
      scoringFormRef.current.errUpdate();
    } else {
      if (scoringForm === "shoulderForm") {
        navigate("/personal-data");
      }
      if (scoringForm === "adultKnee") {
        navigate("/adult-knee-pain");
      }
      if (scoringForm === "pediaKnee") {
        navigate("/pedia-knee-pain");
      }
      if (scoringForm === "hip") {
        navigate("/hip-personal-data");
      }
      if (scoringForm === "footAnkleForm") {
        navigate("/Foot-ankle-personal-data");
      }
      if (scoringForm === "elbow") {
        navigate("/elbow-pain");
      }
      if (scoringForm === "hand&wrist") {
        navigate("/hand-and-wrist-personal-data");
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="forms_map_screen mb_100">
          <div className="panel">
            <div className="panel-body p_25">
              <h4 className="ph_title f_40">{translation.Patient_Scoring_Forms}</h4>

              <div className="row">
                <div className="col-md-5 col-sm-6">
                  <div className="mb_10">
                    <Dropdown
                      data={[
                        { value: "", label: "Select" },
                        {
                          value: "shoulderForm",
                          label: "Shoulder Scoring Form",
                        },
                        { value: "adultKnee", label: "Adult Knee Form" },
                        { value: "pediaKnee", label: "Pedia Knee Form" },
                        { value: "hip", label: "Hip Form" },
                        { value: "footAnkleForm", label: "Foot Ankle Form" },

                        { value: "elbow", label: "Elbow Form" },

                        { value: "hand&wrist", label: "Hand And Wrist Form" },
                      ]}
                      label="Select form"
                      styleClass="mxw_100 br_10"
                      value={scoringForm}
                      placeholder="Select"
                      ref={scoringFormRef}
                      onChange={handleScoringForm}
                      validators={[
                        {
                          check: Validators.required,
                          message: "This field is required",
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb_30 button_box">
                <div className="col-md-6 col-sm-6">
                  <button
                    className="next_btn btn_fill"
                    onClick={continueRedirect}
                  >
                    {translation.next_button}
                    <span>
                      <img src={nextIcon} alt="" />
                      <img src={nextWhiteIcon} alt="" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FormsMap;
