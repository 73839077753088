export default{
  "patient_shoulder_pain": "Pain",
  "patient_shoulder_pain_q1": "Are you having pain in your shoulder?",
  "patient_shoulder_pain_q1_a1": "No",
  "patient_shoulder_pain_q1_a2": "Yes",
  "patient_shoulder_pain_q2": "On a scale of 0-15, what is the highest level of pain that you have experienced in your shoulder during ordinary activities within the last 24 hours? (0 means no pain, 15 means intolerable pain)",
  "patient_shoulder_pain_q2_a1": "Intolerable Pain",
  "patient_shoulder_pain_q2_a2": "No Pain",
  "patient_shoulder_pain_q3": "Is your sleep disturbed by your shoulder?",
  "patient_shoulder_pain_q3_a1": "Undisturbed sleep",
  "patient_shoulder_pain_q3_a2": "Occasional disturbance",
  "patient_shoulder_pain_q3_a3": "Disturbance every night",
  "patient_shoulder_pain_q4": "How bad is your pain today?",
  "patient_shoulder_pain_q5": "Are you a current smoker?",
  "patient_shoulder_pain_q5_a1": "No",
  "patient_shoulder_pain_q5_a2": "Yes",
  "patient_shoulder_pain_q5_a3": "Ex-Smoker",
  "patient_shoulder_pain_q5_a2_q1": "How many cigarettes per day?",
  "patient_shoulder_pain_q5_a3_q1": "How long ago did you quit (years)?",
  "patient_shoulder_pain_q5_a3_q2": "How many years did you smoke?",
  "patient_shoulder_pain_q6": "Do you take pain medication (eg paracetamol etc)?",
  "patient_shoulder_pain_q6_a1": "No",
  "patient_shoulder_pain_q6_a2": "Yes",
  "patient_shoulder_pain_q6_a2_q1": "Do you take strong pain medication (eg codeine, tramadol, morphine)?",
  "patient_shoulder_pain_q6_a2_q1_a1": "No",
  "patient_shoulder_pain_q6_a2_q1_a2": "Yes",
  "patient_shoulder_pain_q6_a2_q2": "How many tablets do you take each day on average ?",
  "patient_shoulder_pain_q7": "Which of the following statements best describes your pain?",
  "patient_shoulder_pain_q7_a1": "None",
  "patient_shoulder_pain_q7_a2": "Occasional and slight",
  "patient_shoulder_pain_q7_a3": "Present during heavy or particular activity, aspirin used occasionally",
  "patient_shoulder_pain_q7_a4": "Present during light activity, little or none at rest, aspirin used frequently",
  "patient_shoulder_pain_q7_a5": "Always present, bearable, strong medication required occasionally",
  "patient_shoulder_pain_q7_a6": "Always present, unbearable, strong medication required frequently",
  "patient_shoulder_pain_q8": "Do you get pins and needles or tingling down your arm ?",
  "patient_shoulder_pain_q8_a1": "No",
  "patient_shoulder_pain_q8_a2": "Yes",
  "patient_shoulder_pain_q9": "Mark where the pain is",
  "patient_shoulder_pain_q9_a1": "Front",
  "patient_shoulder_pain_q9_a2": "Back",
  "patient_shoulder_function": "Function",
  "patient_shoulder_function_q1": "Which of the following statements best describes the function of the affected shoulder?",
  "patient_shoulder_function_q1_a1": "Normal",
  "patient_shoulder_function_q1_a2": "Slight restriction only, able to work above shoulder level",
  "patient_shoulder_function_q1_a3": "Most housework, shopping, driving, do hair, dress/undress",
  "patient_shoulder_function_q1_a4": "Able to do light housework and most daily living activities",
  "patient_shoulder_function_q1_a5": "Only light activities possible",
  "patient_shoulder_function_q1_a6": "Unable to use limb",
  "patient_shoulder_function_instruction": "Select the number that indicates your ability to do the following activities",
  "patient_shoulder_function_instruction_0": "Unable",
  "patient_shoulder_function_instruction_1": "Very difficult",
  "patient_shoulder_function_instruction_2": "Somewhat difficult",
  "patient_shoulder_function_instruction_3": "Not difficult",
  "patient_shoulder_function_note": "Note: Please answer the following questions relating to your shoulder function.",
  "patient_shoulder_function_q2": "Put on a coat / shirt",
  "patient_shoulder_function_q2_a0": "Unable",
  "patient_shoulder_function_q2_a1": "Very difficult",
  "patient_shoulder_function_q2_a2": "Somewhat difficult",
  "patient_shoulder_function_q2_a3": "Not difficult",
  "patient_shoulder_function_q3": "Sleep on your painful or affected side",
  "patient_shoulder_function_q3_a0": "Unable",
  "patient_shoulder_function_q3_a1": "Very difficult",
  "patient_shoulder_function_q3_a2": "Somewhat difficult",
  "patient_shoulder_function_q3_a3": "Not difficult",
  "patient_shoulder_function_q4": "Wash your back/do up bra",
  "patient_shoulder_function_q4_a0": "Unable",
  "patient_shoulder_function_q4_a1": "Very difficult",
  "patient_shoulder_function_q4_a2": "Somewhat difficult",
  "patient_shoulder_function_q4_a3": "Not difficult",
  "patient_shoulder_function_q5": "Manage toileting",
  "patient_shoulder_function_q5_a0": "Unable",
  "patient_shoulder_function_q5_a1": "Very difficult",
  "patient_shoulder_function_q5_a2": "Somewhat difficult",
  "patient_shoulder_function_q5_a3": "Not difficult",
  "patient_shoulder_function_q6": "Comb hair",
  "patient_shoulder_function_q6_a0": "Unable",
  "patient_shoulder_function_q6_a1": "Very difficult",
  "patient_shoulder_function_q6_a2": "Somewhat difficult",
  "patient_shoulder_function_q6_a3": "Not difficult",
  "patient_shoulder_function_q7": "Shave/apply makeup",
  "patient_shoulder_function_q7_a0": "Unable",
  "patient_shoulder_function_q7_a1": "Very difficult",
  "patient_shoulder_function_q7_a2": "Somewhat difficult",
  "patient_shoulder_function_q7_a3": "Not difficult",
  "patient_shoulder_function_q8": "Brush teeth",
  "patient_shoulder_function_q8_a0": "Unable",
  "patient_shoulder_function_q8_a1": "Very difficult",
  "patient_shoulder_function_q8_a2": "Somewhat difficult",
  "patient_shoulder_function_q8_a3": "Not difficult",
  "patient_shoulder_function_q9": "Fill a glass with a full bottle",
  "patient_shoulder_function_q9_a0": "Unable",
  "patient_shoulder_function_q9_a1": "Very difficult",
  "patient_shoulder_function_q9_a2": "Somewhat difficult",
  "patient_shoulder_function_q9_a3": "Not difficult",
  "patient_shoulder_function_q10": "Drink (bring full glass to mouth)",
  "patient_shoulder_function_q10_a0": "Unable",
  "patient_shoulder_function_q10_a1": "Very difficult",
  "patient_shoulder_function_q10_a2": "Somewhat difficult",
  "patient_shoulder_function_q10_a3": "Not difficult",
  "patient_shoulder_function_q11": "Eat soup (full spoon to mouth)",
  "patient_shoulder_function_q11_a0": "Unable",
  "patient_shoulder_function_q11_a1": "Very difficult",
  "patient_shoulder_function_q11_a2": "Somewhat difficult",
  "patient_shoulder_function_q11_a3": "Not difficult",
  "patient_shoulder_function_q12": "Shake someone’s hand / open a door",
  "patient_shoulder_function_q12_a0": "Unable",
  "patient_shoulder_function_q12_a1": "Very difficult",
  "patient_shoulder_function_q12_a2": "Somewhat difficult",
  "patient_shoulder_function_q12_a3": "Not difficult",
  "patient_shoulder_function_q13": "Use a phone (at ear level)",
  "patient_shoulder_function_q13_a0": "Unable",
  "patient_shoulder_function_q13_a1": "Very difficult",
  "patient_shoulder_function_q13_a2": "Somewhat difficult",
  "patient_shoulder_function_q13_a3": "Not difficult",
  "patient_shoulder_function_q14": "Write a letter / sign on paper / use a keyboard",
  "patient_shoulder_function_q14_a0": "Unable",
  "patient_shoulder_function_q14_a1": "Very difficult",
  "patient_shoulder_function_q14_a2": "Somewhat difficult",
  "patient_shoulder_function_q14_a3": "Not difficult",
  "patient_shoulder_function_q15": "Reach a high shelf (above shoulder)",
  "patient_shoulder_function_q15_a0": "Unable",
  "patient_shoulder_function_q15_a1": "Very difficult",
  "patient_shoulder_function_q15_a2": "Somewhat difficult",
  "patient_shoulder_function_q15_a3": "Not difficult",
  "patient_shoulder_function_q16": "Lift 0.5kg (1 lbs) above the shoulder",
  "patient_shoulder_function_q16_a0": "Unable",
  "patient_shoulder_function_q16_a1": "Very difficult",
  "patient_shoulder_function_q16_a2": "Somewhat difficult",
  "patient_shoulder_function_q16_a3": "Not difficult",
  "patient_shoulder_function_q17": "Lift 4.5kg (10 lbs) above the shoulder",
  "patient_shoulder_function_q17_a0": "Unable",
  "patient_shoulder_function_q17_a1": "Very difficult",
  "patient_shoulder_function_q17_a2": "Somewhat difficult",
  "patient_shoulder_function_q17_a3": "Not difficult",
  "patient_shoulder_function_q18": "Carry 10kg (22 lbs) at your side on the affected shoulder",
  "patient_shoulder_function_q18_a0": "Unable",
  "patient_shoulder_function_q18_a1": "Very difficult",
  "patient_shoulder_function_q18_a2": "Somewhat difficult",
  "patient_shoulder_function_q18_a3": "Not difficult",
  "patient_shoulder_function_q19": "Throw a ball underhand (at least 3 meters)",
  "patient_shoulder_function_q19_a0": "Unable",
  "patient_shoulder_function_q19_a1": "Very difficult",
  "patient_shoulder_function_q19_a2": "Somewhat difficult",
  "patient_shoulder_function_q19_a3": "Not difficult",
  "patient_shoulder_function_q20": "Throw a ball overhand (at least 6 meters)",
  "patient_shoulder_function_q20_a0": "Unable",
  "patient_shoulder_function_q20_a1": "Very difficult",
  "patient_shoulder_function_q20_a2": "Somewhat difficult",
  "patient_shoulder_function_q20_a3": "Not difficult",
  "patient_shoulder_function_q21": "Do full usual work",
  "patient_shoulder_function_q21_a0": "Unable",
  "patient_shoulder_function_q21_a1": "Very difficult",
  "patient_shoulder_function_q21_a2": "Somewhat difficult",
  "patient_shoulder_function_q21_a3": "Not difficult",
  "patient_shoulder_function_q22": "How much of your normal work does your shoulder allow you to perform?",
  "patient_shoulder_function_q22_a0": "None",
  "patient_shoulder_function_q22_a1": "All",
  "patient_shoulder_function_q23": "Do full usual hobbies / sport",
  "patient_shoulder_function_q23_a0": "Unable",
  "patient_shoulder_function_q23_a1": "Very difficult",
  "patient_shoulder_function_q23_a2": "Somewhat difficult",
  "patient_shoulder_function_q23_a3": "Not difficult",
  "patient_shoulder_function_q24": "How much of your normal recreational activity does your shoulder allow you to perform?",
  "patient_shoulder_function_q24_a0": "None",
  "patient_shoulder_function_q24_a1": "All",
  "patient_shoulder_function_q25": "Please list work activities",
  "patient_shoulder_function_q26": "Please list hobbies / sport activities",
  "patient_shoulder_feeling_instability": "Feeling of instability",
  "patient_shoulder_feeling_instability_q1": "Does your shoulder feel unstable or loose, like it might pop out? Or have you ever been diagnosed with or treated for a dislocated or unstable shoulder?",
  "patient_shoulder_feeling_instability_q1_a1": "No ",
  "patient_shoulder_feeling_instability_q1_a2": "Yes",
  "patient_shoulder_feeling_instability_q1_a2_q1": "Please indicate how unstable your shoulder is",
  "patient_shoulder_wosi": "Western Ontario Shoulder Instability Index",
  "patient_shoulder_wosi_instruction": "Instructions: You are asked to indicate on this part of the questionnaire the amount of symptoms you have experienced in the past week related to your problematic shoulder. Simply move the slider along the line so that it corresponds accurately with your symptoms.",
  "patient_shoulder_wosi_note": "Note: The further you move the slider to the right indicates the more you experience that symptom. The further you move the slider to the left indicates the less you experience that symptom. If you have any questions regarding the intent of any particular question, please ask.",
  "patient_shoulder_wosi_sectionA": "Section A: Physical Symptoms",
  "patient_shoulder_wosi_q1": "How much pain do you experience in your shoulder with overhead activities?",
  "patient_shoulder_wosi_q1_a1": "No pain",
  "patient_shoulder_wosi_q1_a2": "Extreme pain",
  "patient_shoulder_wosi_q2": "How much aching or throbbing do you experience in your shoulder?",
  "patient_shoulder_wosi_q2_a1": "No aching / throbbing",
  "patient_shoulder_wosi_q2_a2": "Extreme aching / throbbing",
  "patient_shoulder_wosi_q3": "How much weakness or lack of strength do you experience in your shoulder?",
  "patient_shoulder_wosi_q3_a1": "No weakness",
  "patient_shoulder_wosi_q3_a2": "Extreme weakness",
  "patient_shoulder_wosi_q4": "How much fatigue or lack of stamina do you experience in your shoulder?",
  "patient_shoulder_wosi_q4_a1": "No fatigue",
  "patient_shoulder_wosi_q4_a2": "Extreme fatigue",
  "patient_shoulder_wosi_q5": "How much clicking, cracking, or snapping do you experience in your shoulder?",
  "patient_shoulder_wosi_q5_a1": "No clicking",
  "patient_shoulder_wosi_q5_a2": "Extreme clicking",
  "patient_shoulder_wosi_q6": "How much stiffness do you experience in your shoulder?",
  "patient_shoulder_wosi_q6_a1": "No stiffness",
  "patient_shoulder_wosi_q6_a2": "Extreme stiffness",
  "patient_shoulder_wosi_q7": "How much discomfort do you experience in your neck muscles as a result of your shoulder?",
  "patient_shoulder_wosi_q7_a1": "No discomfort",
  "patient_shoulder_wosi_q7_a2": "Extreme discomfort",
  "patient_shoulder_wosi_q8": "How much feeling of instability or looseness do you experience in your shoulder?",
  "patient_shoulder_wosi_q8_a1": "No instability",
  "patient_shoulder_wosi_q8_a2": "Extreme instability",
  "patient_shoulder_wosi_q9": "How much do you compensate for your shoulder with other muscles?",
  "patient_shoulder_wosi_q9_a1": "Not at all",
  "patient_shoulder_wosi_q9_a2": "Extreme",
  "patient_shoulder_wosi_q10": "How much loss of range of motion do you have in your shoulder?",
  "patient_shoulder_wosi_q10_a1": "No loss",
  "patient_shoulder_wosi_q10_a2": "Extreme loss",
  "patient_shoulder_wosi_sectionB": "Section B: Sports / Recreation / Work",
  "patient_shoulder_wosi_q11": "How much has your shoulder limited the amount you can participate in sports or recreational activities?",
  "patient_shoulder_wosi_q11_a1": "Not limited",
  "patient_shoulder_wosi_q11_a2": "Extremely limited",
  "patient_shoulder_wosi_q12": "How much has your shoulder affected your ability to perform the specific skills required for your sport or work?",
  "patient_shoulder_wosi_q12_note": "If your shoulder affects both sports and work, consider the area that is most affected.",
  "patient_shoulder_wosi_q12_a1": "Not affected",
  "patient_shoulder_wosi_q12_a2": "Extremely affected",
  "patient_shoulder_wosi_q13": "How much do you feel the need to protect your arm during activities?",
  "patient_shoulder_wosi_q13_a1": "Not at all",
  "patient_shoulder_wosi_q13_a2": "Extreme",
  "patient_shoulder_wosi_q14": "How much difficulty do you experience lifting heavy objects below shoulder level?",
  "patient_shoulder_wosi_q14_a1": "No difficulty",
  "patient_shoulder_wosi_q14_a2": "Extreme difficulty",
  "patient_shoulder_wosi_sectionC": "Section C: Lifestyle",
  "patient_shoulder_wosi_q15": "How much fear do you have of falling on your shoulder?",
  "patient_shoulder_wosi_q15_a1": "No fear",
  "patient_shoulder_wosi_q15_a2": "Extreme fear",
  "patient_shoulder_wosi_q16": "How much difficulty do you experience maintaining your desired level of fitness?",
  "patient_shoulder_wosi_q16_a1": "No difficulty",
  "patient_shoulder_wosi_q16_a2": "Extreme difficulty",
  "patient_shoulder_wosi_q17": "How much difficulty do you have “roughhousing or horsing around” with family or friends?",
  "patient_shoulder_wosi_q17_a1": "No difficulty",
  "patient_shoulder_wosi_q17_a2": "Extreme difficulty",
  "patient_shoulder_wosi_q18": "How much difficulty do you have sleeping because of your shoulder?",
  "patient_shoulder_wosi_q18_a1": "No difficulty",
  "patient_shoulder_wosi_q18_a2": "Extreme difficulty",
  "patient_shoulder_wosi_sectionD": "Section D: Emotions",
  "patient_shoulder_wosi_q19": "How conscious are you of your shoulder?",
  "patient_shoulder_wosi_q19_a1": "Not conscious",
  "patient_shoulder_wosi_q19_a2": "Extremely conscious",
  "patient_shoulder_wosi_q20": "How concerned are you about your shoulder becoming worse?",
  "patient_shoulder_wosi_q20_a1": "No concern",
  "patient_shoulder_wosi_q20_a2": "Extremely concerned",
  "patient_shoulder_wosi_q21": "How much frustration do you feel because of your shoulder?",
  "patient_shoulder_wosi_q21_a1": "No frustration",
  "patient_shoulder_wosi_q21_a2": "Extremely frustrated",
  "patient_shoulder_ac_joint": "AC Joint",
  "patient_shoulder_ac_joint_q1": "Do you have instability in your AC joint?",
  "patient_shoulder_ac_joint_q1_note": "Note: AC joint is the joint at the top of the shoulder connecting the collar bone and the front tip of the shoulder blade.",
  "patient_shoulder_ac_joint_q1_a1": "No",
  "patient_shoulder_ac_joint_q1_a2": "Yes",
  "patient_shoulder_sacs": "The Specific AC Score (SACS)",
  "patient_shoulder_sacs_explanation": "Explanation: For each question, you will be asked to rate either your level of pain or difficulty performing different tasks. The rating scale is designed so that 0 reflects no pain or difficulty while 10 reflects the most severe amount of pain or difficulty.",
  "patient_shoulder_sacs_note": "Note: A rating of 2 would represent minimal levels of pain. If you have any questions regarding the intent of any particular question, please ask.",
  "patient_shoulder_sacs_sectionA": "Section A: Pain",
  "patient_shoulder_sacs_a1": "Rate your level of pain in your shoulder when it is at its worst – most severe",
  "patient_shoulder_sacs_a1_a1": "No pain",
  "patient_shoulder_sacs_a1_a2": "Most severe pain",
  "patient_shoulder_sacs_a2": "Rate your level of pain in your shoulder when it is at its best",
  "patient_shoulder_sacs_a2_a1": "No pain",
  "patient_shoulder_sacs_a2_a2": "Most severe pain",
  "patient_shoulder_sacs_a3": "Rate the level of pain when you press/ touch or there is pressure applied to the top of your shoulder",
  "patient_shoulder_sacs_a3_note": "e.g. Laptop or hand bag strap",
  "patient_shoulder_sacs_a3_a1": "No pain",
  "patient_shoulder_sacs_a3_a2": "Most severe pain",
  "patient_shoulder_sacs_a4": "Rate the level of pain, tension, or pulling sensation you feel in your neck and/or affected shoulder blade region",
  "patient_shoulder_sacs_a4_a1": "No pain",
  "patient_shoulder_sacs_a4_a2": "Most severe pain",
  "patient_shoulder_sacs_sectionB": "Section B: Function",
  "patient_shoulder_sacs_b1": "Rate the amount of clicking and/or grinding and/or clunking you experience in your shoulder",
  "patient_shoulder_sacs_b1_a1": "No clicking",
  "patient_shoulder_sacs_b1_a2": "Extreme clicking",
  "patient_shoulder_sacs_b2": "Indicate if your arm feels the need to be supported when walking",
  "patient_shoulder_sacs_b2_note": "e.g. Resting your arm in a pocket or using a sling",
  "patient_shoulder_sacs_b2_a1": "No need to support",
  "patient_shoulder_sacs_b2_a2": "Extreme need to support",
  "patient_shoulder_sacs_b3": "Rate your level of difficulty taking weight or leaning through your arm",
  "patient_shoulder_sacs_b3_note": "e.g. Push up, cycling, yoga",
  "patient_shoulder_sacs_b3_a1": "No difficulty",
  "patient_shoulder_sacs_b3_a2": "Extreme difficulty or unable to perform",
  "patient_shoulder_sacs_b4": "Rate your level of difficulty carrying heavy objects by your side for greater than 5 minutes",
  "patient_shoulder_sacs_b4_note": "e.g. Bag of shopping",
  "patient_shoulder_sacs_b4_a1": "No difficulty",
  "patient_shoulder_sacs_b4_a2": "Extreme difficulty or unable to perform",
  "patient_shoulder_sacs_b5": "Rate the level of difficulty reaching across your body with your affected arm",
  "patient_shoulder_sacs_b5_note": "e.g. To reach across and touch the unaffected shoulder",
  "patient_shoulder_sacs_b5_a1": "No difficulty",
  "patient_shoulder_sacs_b5_a2": "Extreme difficulty or unable to perform",
  "patient_shoulder_sacs_b6": "Rate your level of difficulty pushing heavy objects or weights overhead",
  "patient_shoulder_sacs_b6_note": "e.g. Greater than five kilograms",
  "patient_shoulder_sacs_b6_a1": "No difficulty",
  "patient_shoulder_sacs_b6_a2": "Extreme difficulty or unable to perform",
  "patient_shoulder_sacs_b7": "Rate the level of difficulty holding your affected arm in front of you for a prolonged period",
  "patient_shoulder_sacs_b7_note": "e.g. Holding the steering wheel whilst driving",
  "patient_shoulder_sacs_b7_a1": "No difficulty",
  "patient_shoulder_sacs_b7_a2": "Extreme difficulty or unable to perform",
  "patient_shoulder_sacs_b8": "Rate the level of difficulty you experience in performing your usual occupation",
  "patient_shoulder_sacs_b8_a1": "No difficulty",
  "patient_shoulder_sacs_b8_a2": "Extreme difficulty or unable to perform",
  "patient_shoulder_sacs_b8_a3": "Not applicable/does not apply",
  "patient_shoulder_sacs_b9": "Rate how much you have had to change/modify your usual occupation in order to compensate for your shoulder problem",
  "patient_shoulder_sacs_b9_a1": "No difficulty",
  "patient_shoulder_sacs_b9_a2": "Extreme difficulty or unable to perform",
  "patient_shoulder_sacs_b9_a3": "Not applicable/does not apply",
  "patient_shoulder_sacs_b10": "Rate the level of difficulty you experience in performing your primary sporting activity",
  "patient_shoulder_sacs_b10_a1": "No difficulty",
  "patient_shoulder_sacs_b10_a2": "Extreme difficulty or unable to perform",
  "patient_shoulder_sacs_b10_a3": "Not applicable/does not apply",
  "patient_shoulder_sacs_b11": "Rate how much you have had to modify your primary sporting activity in order to compensate for your shoulder problem",
  "patient_shoulder_sacs_b11_a1": "No difficulty",
  "patient_shoulder_sacs_b11_a2": "Extreme difficulty or unable to perform",
  "patient_shoulder_sacs_b11_a3": "Not applicable/does not apply",
  "patient_shoulder_sacs_sectionC": "Section C: Quality of life and satisfaction",
  "patient_shoulder_sacs_c1": "Rate the level of difference in appearance of the bones on the top of your shoulder compared to a normal shoulder",
  "patient_shoulder_sacs_c1_note": "Including the presence of a lump",
  "patient_shoulder_sacs_c1_a1": "No different",
  "patient_shoulder_sacs_c1_a2": "Extremely different",
  "patient_shoulder_sacs_c2": "Rate the overall level of anxiety or stress your shoulder problem has caused you.",
  "patient_shoulder_sacs_c2_a1": "No anxiety / stress",
  "patient_shoulder_sacs_c2_a2": "Severe level of anxiety / stress",
  "patient_shoulder_sacs_c3": "How often are you aware of your shoulder problem?",
  "patient_shoulder_sacs_c3_a1": "Never",
  "patient_shoulder_sacs_c3_a2": "Constantly",
  "patient_shoulder_sacs_c4": "Have you modified your lifestyle to avoid potentially damaging or aggravating your shoulder problem?",
  "patient_shoulder_sacs_c4_a1": "Not at all",
  "patient_shoulder_sacs_c4_a2": "Totally",
  "patient_shoulder_sacs_c5": "How unhappy/dissatisfied are you with your shoulder?",
  "patient_shoulder_sacs_c5_a1": "Extremely happy",
  "patient_shoulder_sacs_c5_a2": "Extremely unhappy",
  "patient_shoulder_sacs_c6": "How would you rate your shoulder overall as a percentage compared to your expectations of a ‘normal’ shoulder where 0% is poor and 100% is very good?",
  "patient_shoulder_satisfaction": "Satisfaction",
  "patient_shoulder_satisfaction_q1": "In regards to your affected shoulder, at present are you",
  "patient_shoulder_satisfaction_q1_a1": "Satisfied and/or better",
  "patient_shoulder_satisfaction_q1_a2": "Not satisfied and/or worse",
  "patient_shoulder_complete": "End of Questionnaire",
  "patient_shoulder_complete_text1": "Thank you for taking the time to fill out this form.",
  "patient_shoulder_complete_text2": "Please click submit to complete and see your scores.",
  "patient_shoulder_complete_text3": "Your answers will be treated confidentially and any reports will only use de-identified information.",
  "patient_shoulder_results": "Results",
  "patient_shoulder_results_text1": "Below are your pain and shoulder function scores, which are calculated from your questionnaire.",
  "patient_shoulder_results_pain": "Pain",
  "patient_shoulder_results_function": "Function",
  "patient_shoulder_results_instability": "Instability",
  "patient_shoulder_results_selfevaluation": "Shoulder Scoring Self-Evaluation",
  "patient_shoulder_results_text2": "Your Doctor will be with you shortly",
  "patient_hip_pain": "Pain",
  "patient_hip_pain_q1": "OVERALL, how would you describe the pain you usually have from your hip IN THE LAST MONTH?",
  "patient_hip_pain_q1_a1": "None",
  "patient_hip_pain_q1_a2": "Very mild",
  "patient_hip_pain_q1_a3": "Mild",
  "patient_hip_pain_q1_a4": "Moderate",
  "patient_hip_pain_q1_a5": "Severe",
  "patient_hip_pain_q2": "How bad is the pain from your hip AT THIS TIME? (0 meaning without pain and 10 meaning the most severe pain you can imagine)",
  "patient_hip_pain_q2_a1": "No pain",
  "patient_hip_pain_q2_a2": "Most severe pain",
  "patient_hip_pain_q3": "Which of the following best describes your pain?",
  "patient_hip_pain_q3_a1": "None/ignores",
  "patient_hip_pain_q3_a2": "Slight, occasional, no compromise in activity",
  "patient_hip_pain_q3_a3": "Mild pain, no effect on average activities, pain after usual activity, uses aspirin",
  "patient_hip_pain_q3_a4": "Moderate, tolerable, makes concession, occasional codeine",
  "patient_hip_pain_q3_a5": "Marked pain, serious limitation",
  "patient_hip_pain_q3_a6": "Totally disabled",
  "patient_hip_pain_q4": "How OFTEN is your hip painful?",
  "patient_hip_pain_q4_a1": "Never",
  "patient_hip_pain_q4_a2": "Monthly",
  "patient_hip_pain_q4_a3": "Weekly",
  "patient_hip_pain_q4_a4": "Daily",
  "patient_hip_pain_q4_a5": "Always",
  "patient_hip_pain_q5": "How much has pain from your hip INTERFERED WITH YOUR USUAL WORK (including housework)?",
  "patient_hip_pain_q5_a1": "Not at all",
  "patient_hip_pain_q5_a2": "A little bit",
  "patient_hip_pain_q5_a3": "Moderately",
  "patient_hip_pain_q5_a4": "Greatly",
  "patient_hip_pain_q5_a5": "Totally",
  "patient_hip_pain_instruction": "What AMOUNT OF HIP PAIN have you experienced during the following activities:",
  "patient_hip_pain_instruction_0": "None",
  "patient_hip_pain_instruction_1": "Mild",
  "patient_hip_pain_instruction_2": "Moderate",
  "patient_hip_pain_instruction_3": "Severe",
  "patient_hip_pain_instruction_4": "Extreme",
  "patient_hip_pain_note": "Note: Please answer the following questions relating to your hip activities.",
  "patient_hip_pain_q6": "Straightening hip fully",
  "patient_hip_pain_q6_a1": "None",
  "patient_hip_pain_q6_a2": "Mild",
  "patient_hip_pain_q6_a3": "Moderate",
  "patient_hip_pain_q6_a4": "Severe",
  "patient_hip_pain_q6_a5": "Extreme",
  "patient_hip_pain_q7": "Bending hip fully",
  "patient_hip_pain_q7_a1": "None",
  "patient_hip_pain_q7_a2": "Mild",
  "patient_hip_pain_q7_a3": "Moderate",
  "patient_hip_pain_q7_a4": "Severe",
  "patient_hip_pain_q7_a5": "Extreme",
  "patient_hip_pain_q8": "Walking on flat surface",
  "patient_hip_pain_q8_a1": "None",
  "patient_hip_pain_q8_a2": "Mild",
  "patient_hip_pain_q8_a3": "Moderate",
  "patient_hip_pain_q8_a4": "Severe",
  "patient_hip_pain_q8_a5": "Extreme",
  "patient_hip_pain_q9": "How long can you walk before the pain from your hip becomes severe? (with or without stick)",
  "patient_hip_pain_q9_a1": "No pain/More than 30 minutes/Unlimited",
  "patient_hip_pain_q9_a2": "16-30 minutes/6 blocks",
  "patient_hip_pain_q9_a3": "5-15 minutes/2-3 blocks",
  "patient_hip_pain_q9_a4": "Around the house/Indoors only",
  "patient_hip_pain_q9_a5": "Cannot walk at all/pain severe when walking/bed and chair only",
  "patient_hip_pain_q10": "Walking on a hard surface (asphalt, concrete, etc)",
  "patient_hip_pain_q10_a1": "None",
  "patient_hip_pain_q10_a2": "Mild",
  "patient_hip_pain_q10_a3": "Moderate",
  "patient_hip_pain_q10_a4": "Severe",
  "patient_hip_pain_q10_a5": "Extreme",
  "patient_hip_pain_q11": "Walking on an uneven surface",
  "patient_hip_pain_q11_a1": "None",
  "patient_hip_pain_q11_a2": "Mild",
  "patient_hip_pain_q11_a3": "Moderate",
  "patient_hip_pain_q11_a4": "Severe",
  "patient_hip_pain_q11_a5": "Extreme",
  "patient_hip_pain_q12": "Going up or down stairs",
  "patient_hip_pain_q12_a1": "None",
  "patient_hip_pain_q12_a2": "Mild",
  "patient_hip_pain_q12_a3": "Moderate",
  "patient_hip_pain_q12_a4": "Severe",
  "patient_hip_pain_q12_a5": "Extreme",
  "patient_hip_pain_q13": "At night while in bed",
  "patient_hip_pain_q13_a1": "None",
  "patient_hip_pain_q13_a2": "Mild",
  "patient_hip_pain_q13_a3": "Moderate",
  "patient_hip_pain_q13_a4": "Severe",
  "patient_hip_pain_q13_a5": "Extreme",
  "patient_hip_pain_q14": "How often have you been troubled by pain from your hip in bed at night?",
  "patient_hip_pain_q14_a1": "No nights",
  "patient_hip_pain_q14_a2": "Only 1 or 2 nights",
  "patient_hip_pain_q14_a3": "Some nights",
  "patient_hip_pain_q14_a4": "Most nights",
  "patient_hip_pain_q14_a5": "Every night",
  "patient_hip_pain_q15": "Sitting or lying",
  "patient_hip_pain_q15_a1": "None",
  "patient_hip_pain_q15_a2": "Mild",
  "patient_hip_pain_q15_a3": "Moderate",
  "patient_hip_pain_q15_a4": "Severe",
  "patient_hip_pain_q15_a5": "Extreme",
  "patient_hip_pain_q16": "Standing upright",
  "patient_hip_pain_q16_a1": "None",
  "patient_hip_pain_q16_a2": "Mild",
  "patient_hip_pain_q16_a3": "Moderate",
  "patient_hip_pain_q16_a4": "Severe",
  "patient_hip_pain_q16_a5": "Extreme",
  "patient_hip_pain_q17": "Standing up from a chair",
  "patient_hip_pain_q17_a1": "None",
  "patient_hip_pain_q17_a2": "Mild",
  "patient_hip_pain_q17_a3": "Moderate",
  "patient_hip_pain_q17_a4": "Severe",
  "patient_hip_pain_q17_a5": "Extreme",
  "patient_hip_pain_q18": "After an activity",
  "patient_hip_pain_q18_a1": "None",
  "patient_hip_pain_q18_a2": "Mild",
  "patient_hip_pain_q18_a3": "Moderate",
  "patient_hip_pain_q18_a4": "Severe",
  "patient_hip_pain_q18_a5": "Extreme",
  "patient_hip_other_symptoms": "Other Symptoms",
  "patient_hip_other_symptoms_instruction1": "Please choose the answer that best corresponds to your hip symptoms.",
  "patient_hip_other_symptoms_instruction2": "If you are unsure about how to answer a question, please give the best answer you can.",
  "patient_hip_other_symptoms_instruction3": "Do you have the following:",
  "patient_hip_other_symptoms_instruction_0": "none",
  "patient_hip_other_symptoms_instruction_1": "mild",
  "patient_hip_other_symptoms_instruction_2": "moderate",
  "patient_hip_other_symptoms_instruction_3": "severe",
  "patient_hip_other_symptoms_instruction_4": "extreme",
  "patient_hip_other_symptoms_q1": "Grinding, clicking, or any other type of noise from your hip",
  "patient_hip_other_symptoms_q1_a1": "Never",
  "patient_hip_other_symptoms_q1_a2": "Rarely",
  "patient_hip_other_symptoms_q1_a3": "Sometimes",
  "patient_hip_other_symptoms_q1_a4": "Often",
  "patient_hip_other_symptoms_q1_a5": "Always",
  "patient_hip_other_symptoms_q2": "Catching or locking",
  "patient_hip_other_symptoms_q2_a1": "None",
  "patient_hip_other_symptoms_q2_a2": "Mild",
  "patient_hip_other_symptoms_q2_a3": "Moderate",
  "patient_hip_other_symptoms_q2_a4": "Severe",
  "patient_hip_other_symptoms_q2_a5": "Extreme",
  "patient_hip_other_symptoms_q3": "Stiffness after first wakening in the morning",
  "patient_hip_other_symptoms_q3_a1": "None",
  "patient_hip_other_symptoms_q3_a2": "Mild",
  "patient_hip_other_symptoms_q3_a3": "Moderate",
  "patient_hip_other_symptoms_q3_a4": "Severe",
  "patient_hip_other_symptoms_q3_a5": "Extreme",
  "patient_hip_other_symptoms_q4": "Stiffness after sitting, lying, or resting later in the day",
  "patient_hip_other_symptoms_q4_a1": "None",
  "patient_hip_other_symptoms_q4_a2": "Mild",
  "patient_hip_other_symptoms_q4_a3": "Moderate",
  "patient_hip_other_symptoms_q4_a4": "Severe",
  "patient_hip_other_symptoms_q4_a5": "Extreme",
  "patient_hip_other_symptoms_q5": "Limping",
  "patient_hip_other_symptoms_q5_a1": "None, rarely/never",
  "patient_hip_other_symptoms_q5_a2": "Slight, sometimes or just at first",
  "patient_hip_other_symptoms_q5_a3": "Moderate, often, not just at first",
  "patient_hip_other_symptoms_q5_a4": "Severe, most of the time",
  "patient_hip_other_symptoms_q5_a5": "All of the time or unable to walk",
  "patient_hip_other_symptoms_q6": "Sudden, severe pain - 'shooting', 'stabbing' or 'spasms' from the hip",
  "patient_hip_other_symptoms_q6_a1": "No days",
  "patient_hip_other_symptoms_q6_a2": "Only 1 or 2 days",
  "patient_hip_other_symptoms_q6_a3": "Some days",
  "patient_hip_other_symptoms_q6_a4": "Most days",
  "patient_hip_other_symptoms_q6_a5": "Every day",
  "patient_hip_other_symptoms_q7": "Hip giving way",
  "patient_hip_other_symptoms_q7_a1": "None",
  "patient_hip_other_symptoms_q7_a2": "Mild",
  "patient_hip_other_symptoms_q7_a3": "Moderate",
  "patient_hip_other_symptoms_q7_a4": "Severe",
  "patient_hip_other_symptoms_q7_a5": "Extreme",
  "patient_hip_other_symptoms_q8": "Decreased movement",
  "patient_hip_other_symptoms_q8_a1": "None",
  "patient_hip_other_symptoms_q8_a2": "Mild",
  "patient_hip_other_symptoms_q8_a3": "Moderate",
  "patient_hip_other_symptoms_q8_a4": "Severe",
  "patient_hip_other_symptoms_q8_a5": "Extreme",
  "patient_hip_other_symptoms_q9": "Do you use any support for walking?",
  "patient_hip_other_symptoms_q9_a1": "None",
  "patient_hip_other_symptoms_q9_a2": "Cane/walking stick for long walks",
  "patient_hip_other_symptoms_q9_a3": "Cane/walking stick most of the time",
  "patient_hip_other_symptoms_q9_a4": "One crutch",
  "patient_hip_other_symptoms_q9_a5": "Two canes/walking sticks",
  "patient_hip_other_symptoms_q9_a6": "Two crutches or not able to walk",
  "patient_hip_function": "Function",
  "patient_hip_function_instruction": "Indicate the DEGREE OF DIFFICULTY you have experienced doing the following activities in the last week due to your hip",
  "patient_hip_function_instruction_0": "None",
  "patient_hip_function_instruction_1": "Mild",
  "patient_hip_function_instruction_2": "Moderate",
  "patient_hip_function_instruction_3": "Severe",
  "patient_hip_function_instruction_4": "Extreme/unable to do",
  "patient_hip_function_note": "Note: If you don't do a certain activity, imagine how your hip would feel if you had to try it",
  "patient_hip_function_q1": "Descending stairs",
  "patient_hip_function_q1_a1": "None",
  "patient_hip_function_q1_a2": "Mild",
  "patient_hip_function_q1_a3": "Moderate",
  "patient_hip_function_q1_a4": "Severe",
  "patient_hip_function_q1_a5": "Extreme/unable to do",
  "patient_hip_function_q2": "Ascending stairs",
  "patient_hip_function_q2_a1": "None",
  "patient_hip_function_q2_a2": "Mild",
  "patient_hip_function_q2_a3": "Moderate",
  "patient_hip_function_q2_a4": "Severe",
  "patient_hip_function_q2_a5": "Extreme/unable to do",
  "patient_hip_function_q3": "Sitting",
  "patient_hip_function_q3_a1": "None",
  "patient_hip_function_q3_a2": "Mild",
  "patient_hip_function_q3_a3": "Moderate",
  "patient_hip_function_q3_a4": "Severe",
  "patient_hip_function_q3_a5": "Extreme/unable to do",
  "patient_hip_function_q4": "Rising from sitting",
  "patient_hip_function_q4_a1": "None",
  "patient_hip_function_q4_a2": "Mild",
  "patient_hip_function_q4_a3": "Moderate",
  "patient_hip_function_q4_a4": "Severe",
  "patient_hip_function_q4_a5": "Extreme/unable to do",
  "patient_hip_function_q5": "Get up and down off the floor/ground",
  "patient_hip_function_q5_a1": "None",
  "patient_hip_function_q5_a2": "Mild",
  "patient_hip_function_q5_a3": "Moderate",
  "patient_hip_function_q5_a4": "Severe",
  "patient_hip_function_q5_a5": "Extreme/unable to do",
  "patient_hip_function_q6": "Standing",
  "patient_hip_function_q6_a1": "None",
  "patient_hip_function_q6_a2": "Mild",
  "patient_hip_function_q6_a3": "Moderate",
  "patient_hip_function_q6_a4": "Severe",
  "patient_hip_function_q6_a5": "Extreme/unable to do",
  "patient_hip_function_q7": "Standing for 15 minutes",
  "patient_hip_function_q7_a1": "None",
  "patient_hip_function_q7_a2": "Mild",
  "patient_hip_function_q7_a3": "Moderate",
  "patient_hip_function_q7_a4": "Severe",
  "patient_hip_function_q7_a5": "Extreme/unable to do",
  "patient_hip_function_q8": "Spreading legs wide apart",
  "patient_hip_function_q8_a1": "None",
  "patient_hip_function_q8_a2": "Mild",
  "patient_hip_function_q8_a3": "Moderate",
  "patient_hip_function_q8_a4": "Severe",
  "patient_hip_function_q8_a5": "Extreme/unable to do",
  "patient_hip_function_q9": "Stride out when walking",
  "patient_hip_function_q9_a1": "None",
  "patient_hip_function_q9_a2": "Mild",
  "patient_hip_function_q9_a3": "Moderate",
  "patient_hip_function_q9_a4": "Severe",
  "patient_hip_function_q9_a5": "Extreme/unable to do",
  "patient_hip_function_q10": "Bending to floor/pick up an object",
  "patient_hip_function_q10_a1": "None",
  "patient_hip_function_q10_a2": "Mild",
  "patient_hip_function_q10_a3": "Moderate",
  "patient_hip_function_q10_a4": "Severe",
  "patient_hip_function_q10_a5": "Extreme/unable to do",
  "patient_hip_function_q11": "Walking on flat surface",
  "patient_hip_function_q11_a1": "None",
  "patient_hip_function_q11_a2": "Mild",
  "patient_hip_function_q11_a3": "Moderate",
  "patient_hip_function_q11_a4": "Severe",
  "patient_hip_function_q11_a5": "Extreme/unable to do",
  "patient_hip_function_q12": "Walking on uneven surface",
  "patient_hip_function_q12_a1": "None",
  "patient_hip_function_q12_a2": "Mild",
  "patient_hip_function_q12_a3": "Moderate",
  "patient_hip_function_q12_a4": "Severe",
  "patient_hip_function_q12_a5": "Extreme/unable to do",
  "patient_hip_function_q13": "Walking long distances",
  "patient_hip_function_q13_a1": "None",
  "patient_hip_function_q13_a2": "Mild",
  "patient_hip_function_q13_a3": "Moderate",
  "patient_hip_function_q13_a4": "Severe",
  "patient_hip_function_q13_a5": "Extreme/unable to do",
  "patient_hip_function_q14": "Walking for exercise",
  "patient_hip_function_q14_a1": "None",
  "patient_hip_function_q14_a2": "Mild",
  "patient_hip_function_q14_a3": "Moderate",
  "patient_hip_function_q14_a4": "Severe",
  "patient_hip_function_q14_a5": "Extreme/unable to do",
  "patient_hip_function_q15": "Walking up steep hills",
  "patient_hip_function_q15_a1": "None",
  "patient_hip_function_q15_a2": "Mild",
  "patient_hip_function_q15_a3": "Moderate",
  "patient_hip_function_q15_a4": "Severe",
  "patient_hip_function_q15_a5": "Extreme/unable to do",
  "patient_hip_function_q16": "Walking down steep hills",
  "patient_hip_function_q16_a1": "None",
  "patient_hip_function_q16_a2": "Mild",
  "patient_hip_function_q16_a3": "Moderate",
  "patient_hip_function_q16_a4": "Severe",
  "patient_hip_function_q16_a5": "Extreme/unable to do",
  "patient_hip_function_q17": "Walking initially",
  "patient_hip_function_q17_a1": "None",
  "patient_hip_function_q17_a2": "Mild",
  "patient_hip_function_q17_a3": "Moderate",
  "patient_hip_function_q17_a4": "Severe",
  "patient_hip_function_q17_a5": "Extreme/unable to do",
  "patient_hip_function_q18": "Walking approximately 10 minutes",
  "patient_hip_function_q18_a1": "None",
  "patient_hip_function_q18_a2": "Mild",
  "patient_hip_function_q18_a3": "Moderate",
  "patient_hip_function_q18_a4": "Severe",
  "patient_hip_function_q18_a5": "Extreme/unable to do",
  "patient_hip_function_q19": "Walking 15 minutes or greater",
  "patient_hip_function_q19_a1": "None",
  "patient_hip_function_q19_a2": "Mild",
  "patient_hip_function_q19_a3": "Moderate",
  "patient_hip_function_q19_a4": "Severe",
  "patient_hip_function_q19_a5": "Extreme/unable to do",
  "patient_hip_function_q20": "Stepping up and down curbs",
  "patient_hip_function_q20_a1": "None",
  "patient_hip_function_q20_a2": "Mild",
  "patient_hip_function_q20_a3": "Moderate",
  "patient_hip_function_q20_a4": "Severe",
  "patient_hip_function_q20_a5": "Extreme/unable to do",
  "patient_hip_function_q21": "Getting in/out of car or public transportation (bus) whichever you tend to use",
  "patient_hip_function_q21_a1": "None",
  "patient_hip_function_q21_a2": "Mild",
  "patient_hip_function_q21_a3": "Moderate",
  "patient_hip_function_q21_a4": "Severe",
  "patient_hip_function_q21_a5": "Extreme/unable to do",
  "patient_hip_function_q22": "Going shopping",
  "patient_hip_function_q22_a1": "None",
  "patient_hip_function_q22_a2": "Mild",
  "patient_hip_function_q22_a3": "Moderate",
  "patient_hip_function_q22_a4": "Severe",
  "patient_hip_function_q22_a5": "Extreme/unable to do",
  "patient_hip_function_q23": "Putting on socks/stockings",
  "patient_hip_function_q23_a1": "None",
  "patient_hip_function_q23_a2": "Mild",
  "patient_hip_function_q23_a3": "Moderate",
  "patient_hip_function_q23_a4": "Severe",
  "patient_hip_function_q23_a5": "Extreme/unable to do",
  "patient_hip_function_q24": "Taking off socks/stockings",
  "patient_hip_function_q24_a1": "None",
  "patient_hip_function_q24_a2": "Mild",
  "patient_hip_function_q24_a3": "Moderate",
  "patient_hip_function_q24_a4": "Severe",
  "patient_hip_function_q24_a5": "Extreme/unable to do",
  "patient_hip_function_q25": "Lying in bed (turning over, maintaining hip position)",
  "patient_hip_function_q25_a1": "None",
  "patient_hip_function_q25_a2": "Mild",
  "patient_hip_function_q25_a3": "Moderate",
  "patient_hip_function_q25_a4": "Severe",
  "patient_hip_function_q25_a5": "Extreme/unable to do",
  "patient_hip_function_q26": "Rolling over in bed",
  "patient_hip_function_q26_a1": "None",
  "patient_hip_function_q26_a2": "Mild",
  "patient_hip_function_q26_a3": "Moderate",
  "patient_hip_function_q26_a4": "Severe",
  "patient_hip_function_q26_a5": "Extreme/unable to do",
  "patient_hip_function_q27": "Rising from bed",
  "patient_hip_function_q27_a1": "None",
  "patient_hip_function_q27_a2": "Mild",
  "patient_hip_function_q27_a3": "Moderate",
  "patient_hip_function_q27_a4": "Severe",
  "patient_hip_function_q27_a5": "Extreme/unable to do",
  "patient_hip_function_q28": "Getting in and out of bath",
  "patient_hip_function_q28_a1": "None",
  "patient_hip_function_q28_a2": "Mild",
  "patient_hip_function_q28_a3": "Moderate",
  "patient_hip_function_q28_a4": "Severe",
  "patient_hip_function_q28_a5": "Extreme/unable to do",
  "patient_hip_function_q29": "Getting on and off toilet",
  "patient_hip_function_q29_a1": "None",
  "patient_hip_function_q29_a2": "Mild",
  "patient_hip_function_q29_a3": "Moderate",
  "patient_hip_function_q29_a4": "Severe",
  "patient_hip_function_q29_a5": "Extreme/unable to do",
  "patient_hip_function_q30": "Washing and drying yourself (all over)",
  "patient_hip_function_q30_a1": "None",
  "patient_hip_function_q30_a2": "Mild",
  "patient_hip_function_q30_a3": "Moderate",
  "patient_hip_function_q30_a4": "Severe",
  "patient_hip_function_q30_a5": "Extreme/unable to do",
  "patient_hip_function_q31": "Light to moderate work (standing, walking)",
  "patient_hip_function_q31_a1": "None",
  "patient_hip_function_q31_a2": "Mild",
  "patient_hip_function_q31_a3": "Moderate",
  "patient_hip_function_q31_a4": "Severe",
  "patient_hip_function_q31_a5": "Extreme/unable to do",
  "patient_hip_function_q32": "Heavy domestic duties (moving heavy boxes, scrubbing floors, etc)",
  "patient_hip_function_q32_a1": "None",
  "patient_hip_function_q32_a2": "Mild",
  "patient_hip_function_q32_a3": "Moderate",
  "patient_hip_function_q32_a4": "Severe",
  "patient_hip_function_q32_a5": "Extreme/unable to do",
  "patient_hip_function_q33": "Light domestic duties (cooking, dusting, etc)",
  "patient_hip_function_q33_a1": "None",
  "patient_hip_function_q33_a2": "Mild",
  "patient_hip_function_q33_a3": "Moderate",
  "patient_hip_function_q33_a4": "Severe",
  "patient_hip_function_q33_a5": "Extreme/unable to do",
  "patient_hip_function_q34": "Picking up or carrying children",
  "patient_hip_function_q34_a1": "None",
  "patient_hip_function_q34_a2": "Mild",
  "patient_hip_function_q34_a3": "Moderate",
  "patient_hip_function_q34_a4": "Severe",
  "patient_hip_function_q34_a5": "Extreme/unable to do",
  "patient_hip_function_q35": "Squatting",
  "patient_hip_function_q35_a1": "None",
  "patient_hip_function_q35_a2": "Mild",
  "patient_hip_function_q35_a3": "Moderate",
  "patient_hip_function_q35_a4": "Severe",
  "patient_hip_function_q35_a5": "Extreme/unable to do",
  "patient_hip_function_q36": "Jogging for exercise",
  "patient_hip_function_q36_a1": "None",
  "patient_hip_function_q36_a2": "Mild",
  "patient_hip_function_q36_a3": "Moderate",
  "patient_hip_function_q36_a4": "Severe",
  "patient_hip_function_q36_a5": "Extreme/unable to do",
  "patient_hip_function_q37": "Running",
  "patient_hip_function_q37_a1": "None",
  "patient_hip_function_q37_a2": "Mild",
  "patient_hip_function_q37_a3": "Moderate",
  "patient_hip_function_q37_a4": "Severe",
  "patient_hip_function_q37_a5": "Extreme/unable to do",
  "patient_hip_function_q38": "Twisting/pivoting on loaded leg",
  "patient_hip_function_q38_a1": "None",
  "patient_hip_function_q38_a2": "Mild",
  "patient_hip_function_q38_a3": "Moderate",
  "patient_hip_function_q38_a4": "Severe",
  "patient_hip_function_q38_a5": "Extreme/unable to do",
  "patient_hip_function_q39": "Sexual activity",
  "patient_hip_function_q39_a1": "None",
  "patient_hip_function_q39_a2": "Mild",
  "patient_hip_function_q39_a3": "Moderate",
  "patient_hip_function_q39_a4": "Severe",
  "patient_hip_function_q39_a5": "Extreme/unable to do",
  "patient_hip_function_q39_not_relevant": "This is not relevant to me",
  "patient_hip_function_q40": "High demand sports involving sprinting or cutting (eg football, basketball, tennis, exercise aerobics)",
  "patient_hip_function_q40_a1": "None",
  "patient_hip_function_q40_a2": "Mild",
  "patient_hip_function_q40_a3": "Moderate",
  "patient_hip_function_q40_a4": "Severe",
  "patient_hip_function_q40_a5": "Extreme/unable to do",
  "patient_hip_function_q41": "Low demand sports (eg golf, bowling)",
  "patient_hip_function_q41_a1": "None",
  "patient_hip_function_q41_a2": "Mild",
  "patient_hip_function_q41_a3": "Moderate",
  "patient_hip_function_q41_a4": "Severe",
  "patient_hip_function_q41_a5": "Extreme/unable to do",
  "patient_hip_function_q42": "Recreational activities",
  "patient_hip_function_q42_a1": "None",
  "patient_hip_function_q42_a2": "Mild",
  "patient_hip_function_q42_a3": "Moderate",
  "patient_hip_function_q42_a4": "Severe",
  "patient_hip_function_q42_a5": "Extreme/unable to do",
  "patient_hip_function_q43": "How would you rate your hips today as a percentage of normal? (0% to 100% with 100% being normal)",
  "patient_hip_function_q44": "Please specify the highest level of activity that you can participate in",
  "patient_hip_function_q44_q1": "Prior to hip injury/hip problem",
  "patient_hip_function_q44_q1_a1": "Competitive",
  "patient_hip_function_q44_q1_a2": "Recreational, physical fitness",
  "patient_hip_function_q44_q1_a3": "No sports, only work",
  "patient_hip_function_q44_q1_a4": "Only activities of daily living",
  "patient_hip_function_q44_q1_a5": "Sick leave or disability pension because of health problems",
  "patient_hip_function_q44_q1_a1_o1": "Competitive sports played at a world or Olympic level or professionally",
  "patient_hip_function_q44_q1_a1_o2": "Competitive sports such as track and field, racquet/ball sports, gymnastics, rowing, skiing, or martial arts played at a national or international level",
  "patient_hip_function_q44_q1_a1_o3": "Competitive sports such as water sports, cycling, hockey, curling, or horseback riding/rodeo played at a national or international level",
  "patient_hip_function_q44_q1_a2_o1": "Recreational sports such as running, ball/racquet sports, weight training, curling, rowing, golf, yoga, or gymnastics at least 5 times per week",
  "patient_hip_function_q44_q1_a2_o2": "Recreational sports such as swimming, water sports, skiing, hockey, rollerblading, cycling, horseback riding, or mountain climbing at least 5 times per week",
  "patient_hip_function_q44_q1_a2_o3": "Recreational sports at least twice weekly",
  "patient_hip_function_q44_q1_a2_o4": "Recreational sports once a week or less",
  "patient_hip_function_q44_q1_a2_o5": "Recreational sports less than once a month",
  "patient_hip_function_q44_q1_a3_o1": "Heavy labor such as construction",
  "patient_hip_function_q44_q1_a3_o2": "Moderately heavy labor such as truck driving",
  "patient_hip_function_q44_q1_a3_o3": "Light labor such as nursing",
  "patient_hip_function_q44_q1_a3_o4": "Sedentary work such as secretarial or computer-based work",
  "patient_hip_function_q44_q1_a4_o1": "Daily activities such as gardening, climbing multiple flights of stairs, carrying loads, pushing/pulling a load, ability to run if late",
  "patient_hip_function_q44_q1_a4_o2": "Daily activities such as cleaning house, climbing 1 or 2 flights of stairs, or walking carrying a light load",
  "patient_hip_function_q44_q1_a4_o3": "Daily activities limited (eg do not take stairs, unable to carry loads)",
  "patient_hip_function_q44_q2": "Current status",
  "patient_hip_function_q44_q2_a1": "Competitive",
  "patient_hip_function_q44_q2_a2": "Recreational, physical fitness",
  "patient_hip_function_q44_q2_a3": "No sports, only work",
  "patient_hip_function_q44_q2_a4": "Only activities of daily living",
  "patient_hip_function_q44_q2_a5": "Sick leave or disability pension because of health problems",
  "patient_hip_function_q44_q2_a1_o1": "Competitive sports played at a world or Olympic level or professionally",
  "patient_hip_function_q44_q2_a1_o2": "Competitive sports such as track and field, racquet/ball sports, gymnastics, rowing, skiing, or martial arts played at a national or international level",
  "patient_hip_function_q44_q2_a1_o3": "Competitive sports such as water sports, cycling, hockey, curling, or horseback riding/rodeo played at a national or international level",
  "patient_hip_function_q44_q2_a2_o1": "Recreational sports such as running, ball/racquet sports, weight training, curling, rowing, golf, yoga, or gymnastics at least 5 times per week",
  "patient_hip_function_q44_q2_a2_o2": "Recreational sports such as swimming, water sports, skiing, hockey, rollerblading, cycling, horseback riding, or mountain climbing at least 5 times per week",
  "patient_hip_function_q44_q2_a2_o3": "Recreational sports at least twice weekly",
  "patient_hip_function_q44_q2_a2_o4": "Recreational sports once a week or less",
  "patient_hip_function_q44_q2_a2_o5": "Recreational sports less than once a month",
  "patient_hip_function_q44_q2_a3_o1": "Heavy labor such as construction",
  "patient_hip_function_q44_q2_a3_o2": "Moderately heavy labor such as truck driving",
  "patient_hip_function_q44_q2_a3_o3": "Light labor such as nursing",
  "patient_hip_function_q44_q2_a3_o4": "Sedentary work such as secretarial or computer-based work",
  "patient_hip_function_q44_q2_a4_o1": "Daily activities such as gardening, climbing multiple flights of stairs, carrying loads, pushing/pulling a load, ability to run if late",
  "patient_hip_function_q44_q2_a4_o2": "Daily activities such as cleaning house, climbing 1 or 2 flights of stairs, or walking carrying a light load",
  "patient_hip_function_q44_q2_a4_o3": "Daily activities limited (eg do not take stairs, unable to carry loads)",
  "patient_hip_quality_of_life": "Quality of Life",
  "patient_hip_quality_of_life_instruction": "The following questions ask about social, emotional, and lifestyle concerns that you may feel with respect to your hip problem. Please think about how you have felt most of the time in relation to your hip.",
  "patient_hip_quality_of_life_q1": "How often are you aware of your hip problem?",
  "patient_hip_quality_of_life_q1_a1": "Never",
  "patient_hip_quality_of_life_q1_a2": "Monthly",
  "patient_hip_quality_of_life_q1_a3": "Weekly",
  "patient_hip_quality_of_life_q1_a4": "Daily",
  "patient_hip_quality_of_life_q1_a5": "Constantly",
  "patient_hip_quality_of_life_q2": "Have you modified your lifestyle to avoid potentially damaging activities to your hip?",
  "patient_hip_quality_of_life_q2_a1": "Not at all",
  "patient_hip_quality_of_life_q2_a2": "Mildly",
  "patient_hip_quality_of_life_q2_a3": "Moderately",
  "patient_hip_quality_of_life_q2_a4": "Severely",
  "patient_hip_quality_of_life_q2_a5": "Totally",
  "patient_hip_quality_of_life_q3": "How concerned are you about your ability to maintain your desired fitness level?",
  "patient_hip_quality_of_life_q3_a1": "Not concerned at all",
  "patient_hip_quality_of_life_q3_a2": "Extremely concerned",
  "patient_hip_quality_of_life_q4": "How much are you troubled with the lack of confidence in your hip?",
  "patient_hip_quality_of_life_q4_a1": "Not at all",
  "patient_hip_quality_of_life_q4_a2": "Mildly",
  "patient_hip_quality_of_life_q4_a3": "Moderately",
  "patient_hip_quality_of_life_q4_a4": "Severely",
  "patient_hip_quality_of_life_q4_a5": "Extremely",
  "patient_hip_quality_of_life_q5": "How much of a distraction is your hip problem?",
  "patient_hip_quality_of_life_q5_a1": "No distraction at all",
  "patient_hip_quality_of_life_q5_a2": "Extreme distraction",
  "patient_hip_quality_of_life_q6": "In general, how much difficulty do you have with your hip?",
  "patient_hip_quality_of_life_q6_a1": "None",
  "patient_hip_quality_of_life_q6_a2": "Mild",
  "patient_hip_quality_of_life_q6_a3": "Moderate",
  "patient_hip_quality_of_life_q6_a4": "Severe",
  "patient_hip_quality_of_life_q6_a5": "Extreme",
  "patient_hip_quality_of_life_q7": "How anxious/depressed are you today?",
  "patient_hip_quality_of_life_q7_a1": "Not at all",
  "patient_hip_quality_of_life_q7_a2": "Slightly",
  "patient_hip_quality_of_life_q7_a3": "Moderately",
  "patient_hip_quality_of_life_q7_a4": "Severely",
  "patient_hip_quality_of_life_q7_a5": "Extremely",
  "patient_hip_quality_of_life_q8": "How good/bad is your health today? (100 means best health, 0 means worst health you can imagine)",
  "patient_hip_quality_of_life_q8_a1": "Worst health",
  "patient_hip_quality_of_life_q8_a2": "Best Health",
  "patient_hip_satisfaction": "Satisfaction",
  "patient_hip_satisfaction_instructions": "How satisfied are you with the results of your hip surgery/treatment?",
  "patient_hip_satisfaction_instructions_1": "Very dissatisfied",
  "patient_hip_satisfaction_instructions_2": "Somewhat dissatisfied",
  "patient_hip_satisfaction_instructions_3": "Somewhat satisfied",
  "patient_hip_satisfaction_instructions_4": "Very satisfied",
  "patient_hip_satisfaction_q1": "Overall",
  "patient_hip_satisfaction_q1_not_applicable": "Not applicable",
  "patient_hip_satisfaction_q1_a1": "Very dissatisfied",
  "patient_hip_satisfaction_q1_a2": "Somewhat dissatisfied",
  "patient_hip_satisfaction_q1_a3": "Somewhat satisfied",
  "patient_hip_satisfaction_q1_a4": "Very satisfied",
  "patient_hip_satisfaction_q2": "For improving your pain?",
  "patient_hip_satisfaction_q2_not_applicable": "Not applicable",
  "patient_hip_satisfaction_q2_a1": "Very dissatisfied",
  "patient_hip_satisfaction_q2_a2": "Somewhat dissatisfied",
  "patient_hip_satisfaction_q2_a3": "Somewhat satisfied",
  "patient_hip_satisfaction_q2_a4": "Very satisfied",
  "patient_hip_satisfaction_q3": "For improving your ability to do home or yard work?",
  "patient_hip_satisfaction_q3_not_applicable": "Not applicable",
  "patient_hip_satisfaction_q3_a1": "Very dissatisfied",
  "patient_hip_satisfaction_q3_a2": "Somewhat dissatisfied",
  "patient_hip_satisfaction_q3_a3": "Somewhat satisfied",
  "patient_hip_satisfaction_q3_a4": "Very satisfied",
  "patient_hip_satisfaction_q4": "For improving your ability to do recreational activites?",
  "patient_hip_satisfaction_q4_not_applicable": "Not applicable",
  "patient_hip_satisfaction_q4_a1": "Very dissatisfied",
  "patient_hip_satisfaction_q4_a2": "Somewhat dissatisfied",
  "patient_hip_satisfaction_q4_a3": "Somewhat satisfied",
  "patient_hip_satisfaction_q4_a4": "Very satisfied",
  "patient_hip_complete": "End of Questionnaire",
  "patient_hip_complete_text1": "Thank you for taking the time to fill out this form.",
  "patient_hip_complete_text2": "Please click submit to complete and see your scores.",
  "patient_hip_complete_text3": "Your answers will be treated confidentially and any reports will only use de-identified information.",
  "patient_hip_results": "Results",
  "patient_hip_results_text1": "Below are your pain and hip function scores, which are calculated from your questionnaire.",
  "patient_hip_results_pain": "Pain",
  "patient_hip_results_function": "Function",
  "patient_hip_results_health_perception": "Overall Health Perception",
  "patient_hip_results_self_evaluation": "Hip Scoring Self-Evaluation",
  "patient_hip_results_text2": "Your Doctor will be with you shortly.",
  "": "",
  "patient_adult_knee_pain": "Pain",
  "patient_adult_knee_pain_q1": "What is the highest level of activity that you can perform without significant knee pain?",
  "patient_adult_knee_pain_q1_a1": "Very strenuous activities like jumping or pivoting as in basketball or soccer",
  "patient_adult_knee_pain_q1_a2": "Strenuous activities like heavy physical work, skiing or tennis",
  "patient_adult_knee_pain_q1_a3": "Moderate activities like moderate physical work, running or jogging",
  "patient_adult_knee_pain_q1_a4": "Light activities like walking, housework or yard work",
  "patient_adult_knee_pain_q1_a5": "Unable to perform any of the above activities due to knee pain",
  "patient_adult_knee_pain_q2": "How often have you had pain?",
  "patient_adult_knee_pain_q2_a1": "Never",
  "patient_adult_knee_pain_q2_a2": "Monthly",
  "patient_adult_knee_pain_q2_a3": "Weekly",
  "patient_adult_knee_pain_q2_a4": "Daily",
  "patient_adult_knee_pain_q2_a5": "Constant",
  "patient_adult_knee_pain_q3": "Which of the following statements best describes your pain?",
  "patient_adult_knee_pain_q3_a1": "No pain, normal knee, performs 100%",
  "patient_adult_knee_pain_q3_a2": "Occasional pain with strenuous sports and heavy work, knee not entirely normal, some limitations but minor and tolerable",
  "patient_adult_knee_pain_q3_a3": "Pain usually brought on by sports, light recreational activities or moderate work. Occasionally occurs with walking more than 2km, standing or light work",
  "patient_adult_knee_pain_q3_a4": "Occasional pain with light recreational sports or moderate work activities, running or heavy labor, strenuous sports",
  "patient_adult_knee_pain_q3_a5": "Pain is a significant problem with simple activity such as walking <2 km, relieved by rest, unable to do sports",
  "patient_adult_knee_pain_q3_a6": "Constant",
  "patient_adult_knee_pain_q4": "If you have pain, how severe is it?",
  "patient_adult_knee_pain_q4_a1": "None",
  "patient_adult_knee_pain_q4_a2": "Very mild",
  "patient_adult_knee_pain_q4_a3": "Mild",
  "patient_adult_knee_pain_q4_a4": "Moderate",
  "patient_adult_knee_pain_q4_a5": "Severe",
  "patient_adult_knee_pain_q5": "How much has pain from your knee interfered with your usual work (including housework)?",
  "patient_adult_knee_pain_q5_a1": "Not at all",
  "patient_adult_knee_pain_q5_a2": "A little bit",
  "patient_adult_knee_pain_q5_a3": "Moderately",
  "patient_adult_knee_pain_q5_a4": "Greatly",
  "patient_adult_knee_pain_q5_a5": "Totally",
  "patient_adult_knee_pain_instruction": "What amount of knee pain have you experienced during the following activities:",
  "patient_adult_knee_pain_instruction_0": "None",
  "patient_adult_knee_pain_instruction_1": "Mild",
  "patient_adult_knee_pain_instruction_2": "Moderate",
  "patient_adult_knee_pain_instruction_3": "Severe",
  "patient_adult_knee_pain_instruction_4": "Extreme",
  "patient_adult_knee_pain_q6": "Twisting/pivoting knee",
  "patient_adult_knee_pain_q6_a1": "None",
  "patient_adult_knee_pain_q6_a2": "Mild",
  "patient_adult_knee_pain_q6_a3": "Moderate",
  "patient_adult_knee_pain_q6_a4": "Severe",
  "patient_adult_knee_pain_q6_a5": "Extreme",
  "patient_adult_knee_pain_q7": "Straightening knee fully",
  "patient_adult_knee_pain_q7_a1": "None",
  "patient_adult_knee_pain_q7_a2": "Mild",
  "patient_adult_knee_pain_q7_a3": "Moderate",
  "patient_adult_knee_pain_q7_a4": "Severe",
  "patient_adult_knee_pain_q7_a5": "Extreme",
  "patient_adult_knee_pain_q8": "Bending knee fully",
  "patient_adult_knee_pain_q8_a1": "None",
  "patient_adult_knee_pain_q8_a2": "Mild",
  "patient_adult_knee_pain_q8_a3": "Moderate",
  "patient_adult_knee_pain_q8_a4": "Severe",
  "patient_adult_knee_pain_q8_a5": "Extreme",
  "patient_adult_knee_pain_q9": "Walking on flat surface",
  "patient_adult_knee_pain_q9_a1": "None",
  "patient_adult_knee_pain_q9_a2": "Mild",
  "patient_adult_knee_pain_q9_a3": "Moderate",
  "patient_adult_knee_pain_q9_a4": "Severe",
  "patient_adult_knee_pain_q9_a5": "Extreme",
  "patient_adult_knee_pain_q10": "How long can you walk before the pain from your knee becomes severe? (with or without stick)",
  "patient_adult_knee_pain_q10_a1": "More than 30 minutes",
  "patient_adult_knee_pain_q10_a2": "16-30 minutes",
  "patient_adult_knee_pain_q10_a3": "5-15 minutes",
  "patient_adult_knee_pain_q10_a4": "Around the house only",
  "patient_adult_knee_pain_q10_a5": "Cannot walk at all/pain severe when walking",
  "patient_adult_knee_pain_q11": "Going up or down stairs",
  "patient_adult_knee_pain_q11_a1": "None",
  "patient_adult_knee_pain_q11_a2": "Mild",
  "patient_adult_knee_pain_q11_a3": "Moderate",
  "patient_adult_knee_pain_q11_a4": "Severe",
  "patient_adult_knee_pain_q11_a5": "Extreme",
  "patient_adult_knee_pain_q12": "At night while in bed",
  "patient_adult_knee_pain_q12_a1": "None",
  "patient_adult_knee_pain_q12_a2": "Mild",
  "patient_adult_knee_pain_q12_a3": "Moderate",
  "patient_adult_knee_pain_q12_a4": "Severe",
  "patient_adult_knee_pain_q12_a5": "Extreme",
  "patient_adult_knee_pain_q13": "How often have you been troubled by pain from your knee in bed at night?",
  "patient_adult_knee_pain_q13_a1": "No nights",
  "patient_adult_knee_pain_q13_a2": "1 or 2 nights",
  "patient_adult_knee_pain_q13_a3": "Some nights",
  "patient_adult_knee_pain_q13_a4": "Most nights",
  "patient_adult_knee_pain_q13_a5": "Every night",
  "patient_adult_knee_pain_q14": "Sitting or lying",
  "patient_adult_knee_pain_q14_a1": "None",
  "patient_adult_knee_pain_q14_a2": "Mild",
  "patient_adult_knee_pain_q14_a3": "Moderate",
  "patient_adult_knee_pain_q14_a4": "Severe",
  "patient_adult_knee_pain_q14_a5": "Extreme",
  "patient_adult_knee_pain_q15": "Standing upright",
  "patient_adult_knee_pain_q15_a1": "None",
  "patient_adult_knee_pain_q15_a2": "Mild",
  "patient_adult_knee_pain_q15_a3": "Moderate",
  "patient_adult_knee_pain_q15_a4": "Severe",
  "patient_adult_knee_pain_q15_a5": "Extreme",
  "patient_adult_knee_pain_q16": "Standing up from a chair",
  "patient_adult_knee_pain_q16_a1": "None",
  "patient_adult_knee_pain_q16_a2": "Mild",
  "patient_adult_knee_pain_q16_a3": "Moderate",
  "patient_adult_knee_pain_q16_a4": "Severe",
  "patient_adult_knee_pain_q16_a5": "Extreme",
  "patient_adult_knee_other_symptoms": "Other Symptoms",
  "patient_adult_knee_other_symptoms_instruction1": "Please choose the answer that best corresponds to your knee symptoms.",
  "patient_adult_knee_other_symptoms_instruction2": "If you are unsure about how to answer a question, please give the best answer you can.",
  "patient_adult_knee_other_symptoms_instruction3": "Do you have the following:",
  "patient_adult_knee_other_symptoms_instruction_0": "None",
  "patient_adult_knee_other_symptoms_instruction_1": "Mild",
  "patient_adult_knee_other_symptoms_instruction_2": "Moderate",
  "patient_adult_knee_other_symptoms_instruction_3": "Severe",
  "patient_adult_knee_other_symptoms_instruction_4": "Extreme",
  "patient_adult_knee_other_symptoms_q1": "Swelling",
  "patient_adult_knee_other_symptoms_q1_a1": "Never",
  "patient_adult_knee_other_symptoms_q1_a2": "Rarely",
  "patient_adult_knee_other_symptoms_q1_a3": "Sometimes",
  "patient_adult_knee_other_symptoms_q1_a4": "Often",
  "patient_adult_knee_other_symptoms_q1_a5": "Always",
  "patient_adult_knee_other_symptoms_q2": "Grinding, clicking, or any other type of noise when your knee moves",
  "patient_adult_knee_other_symptoms_q2_a1": "Never",
  "patient_adult_knee_other_symptoms_q2_a2": "Rarely",
  "patient_adult_knee_other_symptoms_q2_a3": "Sometimes",
  "patient_adult_knee_other_symptoms_q2_a4": "Often",
  "patient_adult_knee_other_symptoms_q2_a5": "Always",
  "patient_adult_knee_other_symptoms_q3": "Catching or locking",
  "patient_adult_knee_other_symptoms_q3_a1": "Never",
  "patient_adult_knee_other_symptoms_q3_a2": "Rarely",
  "patient_adult_knee_other_symptoms_q3_a3": "Sometimes",
  "patient_adult_knee_other_symptoms_q3_a4": "Often",
  "patient_adult_knee_other_symptoms_q3_a5": "Always",
  "patient_adult_knee_other_symptoms_q4": "Limping",
  "patient_adult_knee_other_symptoms_q4_a1": "Rarely/Never",
  "patient_adult_knee_other_symptoms_q4_a2": "Sometimes, or just at first",
  "patient_adult_knee_other_symptoms_q4_a3": "Often, not just at first",
  "patient_adult_knee_other_symptoms_q4_a4": "Most of the time",
  "patient_adult_knee_other_symptoms_q4_a5": "All of the time",
  "patient_adult_knee_other_symptoms_q5": "During the past 4 weeks or since your knee injury, how stiff or swollen was your knee?",
  "patient_adult_knee_other_symptoms_q5_a1": "Not at all",
  "patient_adult_knee_other_symptoms_q5_a2": "Mildly",
  "patient_adult_knee_other_symptoms_q5_a3": "Moderately",
  "patient_adult_knee_other_symptoms_q5_a4": "Very",
  "patient_adult_knee_other_symptoms_q5_a5": "Extremely",
  "patient_adult_knee_other_symptoms_q6": "What is the highest level of activity that you can perform without significant SWELLING in your knee?",
  "patient_adult_knee_other_symptoms_q6_a1": "Very strenuous activities like jumping or pivoting as in basketball or soccer",
  "patient_adult_knee_other_symptoms_q6_a2": "Strenuous activities like heavy physical work, skiing or tennis",
  "patient_adult_knee_other_symptoms_q6_a3": "Moderate activities like moderate physical work, running or jogging",
  "patient_adult_knee_other_symptoms_q6_a4": "Light activities like walking, housework or yard work",
  "patient_adult_knee_other_symptoms_q6_a5": "Unable to perform any of the above activities due to knee swelling",
  "patient_adult_knee_other_symptoms_q7": "How severe is your joint STIFFNESS:",
  "patient_adult_knee_other_symptoms_q7_0": "None",
  "patient_adult_knee_other_symptoms_q7_1": "Mild",
  "patient_adult_knee_other_symptoms_q7_2": "Moderate",
  "patient_adult_knee_other_symptoms_q7_3": "Severe",
  "patient_adult_knee_other_symptoms_q7_4": "Extreme",
  "patient_adult_knee_other_symptoms_q7_q1": "After first awakening in the morning?",
  "patient_adult_knee_other_symptoms_q7_q1_a1": "None",
  "patient_adult_knee_other_symptoms_q7_q1_a2": "Mild",
  "patient_adult_knee_other_symptoms_q7_q1_a3": "Moderate",
  "patient_adult_knee_other_symptoms_q7_q1_a4": "Severe",
  "patient_adult_knee_other_symptoms_q7_q1_a5": "Extreme",
  "patient_adult_knee_other_symptoms_q7_q2": "After sitting, lying, or resting later in the day?",
  "patient_adult_knee_other_symptoms_q7_q2_a1": "None",
  "patient_adult_knee_other_symptoms_q7_q2_a2": "Mild",
  "patient_adult_knee_other_symptoms_q7_q2_a3": "Moderate",
  "patient_adult_knee_other_symptoms_q7_q2_a4": "Severe",
  "patient_adult_knee_other_symptoms_q7_q2_a5": "Extreme",
  "patient_adult_knee_other_symptoms_q8": "What is the highest level of activity that you can perform without significant giving way in your knee?",
  "patient_adult_knee_other_symptoms_q8_a1": "No giving way",
  "patient_adult_knee_other_symptoms_q8_a2": "Occasional giving way with strenuous sports or heavy work, can participate in all sports but some guarding or limitations present. Rarely during athletics or other severe exertion",
  "patient_adult_knee_other_symptoms_q8_a3": "Frequently during athletics or other severe exertion. Occasional giving way with light sports or moderate work. Able to compensate but limits vigorous activities, sports, or heavy work, not able to cut or twist suddenly",
  "patient_adult_knee_other_symptoms_q8_a4": "Giving way limits sports or moderate work, occurs infrequently with walking or light work (approx. 3 times per year). Occasionally in daily activities",
  "patient_adult_knee_other_symptoms_q8_a5": "Giving way with simple walking activities and light work. Occurs once per month, requires guarding. Often in daily activities",
  "patient_adult_knee_other_symptoms_q8_a6": "Severe problem with simple walking activities, giving way in every step, cannot turn or twist while walking without giving way",
  "patient_adult_knee_other_symptoms_q9": "Do you use any SUPPORT for walking?",
  "patient_adult_knee_other_symptoms_q9_a1": "None",
  "patient_adult_knee_other_symptoms_q9_a2": "Stick or crutch",
  "patient_adult_knee_other_symptoms_q9_a3": "Weight-bearing impossible",
  "patient_adult_knee_function": "Function",
  "patient_adult_knee_function_instruction": "Indicate the DEGREE OF DIFFICULTY you have experienced doing the following activities in the last week due to your knee",
  "patient_adult_knee_function_instruction_0": "No difficulty",
  "patient_adult_knee_function_instruction_1": "Minimal difficulty",
  "patient_adult_knee_function_instruction_2": "Moderate difficulty",
  "patient_adult_knee_function_instruction_3": "Extreme difficulty",
  "patient_adult_knee_function_instruction_4": "Unable to do",
  "patient_adult_knee_function_note": "Note: If you don't do a certain activity, imagine how your knee would feel if you had to try it.",
  "patient_adult_knee_function_q1": "Going up stairs",
  "patient_adult_knee_function_q1_a1": "No difficulty",
  "patient_adult_knee_function_q1_a2": "Minimal difficulty",
  "patient_adult_knee_function_q1_a3": "Moderate difficulty",
  "patient_adult_knee_function_q1_a4": "Extreme difficulty",
  "patient_adult_knee_function_q1_a5": "Unable to do",
  "patient_adult_knee_function_q2": "Going down stairs",
  "patient_adult_knee_function_q2_a1": "No difficulty",
  "patient_adult_knee_function_q2_a2": "Minimal difficulty",
  "patient_adult_knee_function_q2_a3": "Moderate difficulty",
  "patient_adult_knee_function_q2_a4": "Extreme difficulty",
  "patient_adult_knee_function_q2_a5": "Unable to do",
  "patient_adult_knee_function_q3": "Kneeling on the front of your knee",
  "patient_adult_knee_function_q3_a1": "No difficulty",
  "patient_adult_knee_function_q3_a2": "Minimal difficulty",
  "patient_adult_knee_function_q3_a3": "Moderate difficulty",
  "patient_adult_knee_function_q3_a4": "Extreme difficulty",
  "patient_adult_knee_function_q3_a5": "Unable to do",
  "patient_adult_knee_function_q4": "Straightening your knee",
  "patient_adult_knee_function_q4_a1": "No difficulty",
  "patient_adult_knee_function_q4_a2": "Minimal difficulty",
  "patient_adult_knee_function_q4_a3": "Moderate difficulty",
  "patient_adult_knee_function_q4_a4": "Extreme difficulty",
  "patient_adult_knee_function_q4_a5": "Unable to do",
  "patient_adult_knee_function_q5": "Bending your knee",
  "patient_adult_knee_function_q5_a1": "No difficulty",
  "patient_adult_knee_function_q5_a2": "Minimal difficulty",
  "patient_adult_knee_function_q5_a3": "Moderate difficulty",
  "patient_adult_knee_function_q5_a4": "Extreme difficulty",
  "patient_adult_knee_function_q5_a5": "Unable to do",
  "patient_adult_knee_function_q6": "Squatting",
  "patient_adult_knee_function_q6_a1": "No difficulty",
  "patient_adult_knee_function_q6_a2": "Minimal difficulty",
  "patient_adult_knee_function_q6_a3": "Moderate difficulty",
  "patient_adult_knee_function_q6_a4": "Extreme difficulty",
  "patient_adult_knee_function_q6_a5": "Unable to do",
  "patient_adult_knee_function_q7": "Sitting with your knee bent",
  "patient_adult_knee_function_q7_a1": "No difficulty",
  "patient_adult_knee_function_q7_a2": "Minimal difficulty",
  "patient_adult_knee_function_q7_a3": "Moderate difficulty",
  "patient_adult_knee_function_q7_a4": "Extreme difficulty",
  "patient_adult_knee_function_q7_a5": "Unable to do",
  "patient_adult_knee_function_q8": "Rising from a chair",
  "patient_adult_knee_function_q8_a1": "No difficulty",
  "patient_adult_knee_function_q8_a2": "Minimal difficulty",
  "patient_adult_knee_function_q8_a3": "Moderate difficulty",
  "patient_adult_knee_function_q8_a4": "Extreme difficulty",
  "patient_adult_knee_function_q8_a5": "Unable to do",
  "patient_adult_knee_function_q9": "Rising from bed",
  "patient_adult_knee_function_q9_a1": "No difficulty",
  "patient_adult_knee_function_q9_a2": "Minimal difficulty",
  "patient_adult_knee_function_q9_a3": "Moderate difficulty",
  "patient_adult_knee_function_q9_a4": "Extreme difficulty",
  "patient_adult_knee_function_q9_a5": "Unable to do",
  "patient_adult_knee_function_q10": "Lying in bed (turning over, maintaining knee position)",
  "patient_adult_knee_function_q10_a1": "No difficulty",
  "patient_adult_knee_function_q10_a2": "Minimal difficulty",
  "patient_adult_knee_function_q10_a3": "Moderate difficulty",
  "patient_adult_knee_function_q10_a4": "Extreme difficulty",
  "patient_adult_knee_function_q10_a5": "Unable to do",
  "patient_adult_knee_function_q11": "Bending to floor / picking up an object",
  "patient_adult_knee_function_q11_a1": "No difficulty",
  "patient_adult_knee_function_q11_a2": "Minimal difficulty",
  "patient_adult_knee_function_q11_a3": "Moderate difficulty",
  "patient_adult_knee_function_q11_a4": "Extreme difficulty",
  "patient_adult_knee_function_q11_a5": "Unable to do",
  "patient_adult_knee_function_q12": "Putting on socks/stockings",
  "patient_adult_knee_function_q12_a1": "No difficulty",
  "patient_adult_knee_function_q12_a2": "Minimal difficulty",
  "patient_adult_knee_function_q12_a3": "Moderate difficulty",
  "patient_adult_knee_function_q12_a4": "Extreme difficulty",
  "patient_adult_knee_function_q12_a5": "Unable to do",
  "patient_adult_knee_function_q13": "Taking off socks/stockings",
  "patient_adult_knee_function_q13_a1": "No difficulty",
  "patient_adult_knee_function_q13_a2": "Minimal difficulty",
  "patient_adult_knee_function_q13_a3": "Moderate difficulty",
  "patient_adult_knee_function_q13_a4": "Extreme difficulty",
  "patient_adult_knee_function_q13_a5": "Unable to do",
  "patient_adult_knee_function_q14": "Standing",
  "patient_adult_knee_function_q14_a1": "No difficulty",
  "patient_adult_knee_function_q14_a2": "Minimal difficulty",
  "patient_adult_knee_function_q14_a3": "Moderate difficulty",
  "patient_adult_knee_function_q14_a4": "Extreme difficulty",
  "patient_adult_knee_function_q14_a5": "Unable to do",
  "patient_adult_knee_function_q15": "Walking",
  "patient_adult_knee_function_q15_a1": "No difficulty",
  "patient_adult_knee_function_q15_a2": "Minimal difficulty",
  "patient_adult_knee_function_q15_a3": "Moderate difficulty",
  "patient_adult_knee_function_q15_a4": "Extreme difficulty",
  "patient_adult_knee_function_q15_a5": "Unable to do",
  "patient_adult_knee_function_q16": "Running straight ahead",
  "patient_adult_knee_function_q16_a1": "No difficulty",
  "patient_adult_knee_function_q16_a2": "Minimal difficulty",
  "patient_adult_knee_function_q16_a3": "Moderate difficulty",
  "patient_adult_knee_function_q16_a4": "Extreme difficulty",
  "patient_adult_knee_function_q16_a5": "Unable to do",
  "patient_adult_knee_function_q17": "Jumping and landing on your involved leg",
  "patient_adult_knee_function_q17_a1": "No difficulty",
  "patient_adult_knee_function_q17_a2": "Minimal difficulty",
  "patient_adult_knee_function_q17_a3": "Moderate difficulty",
  "patient_adult_knee_function_q17_a4": "Extreme difficulty",
  "patient_adult_knee_function_q17_a5": "Unable to do",
  "patient_adult_knee_function_q18": "Stopping and starting quickly",
  "patient_adult_knee_function_q18_a1": "No difficulty",
  "patient_adult_knee_function_q18_a2": "Minimal difficulty",
  "patient_adult_knee_function_q18_a3": "Moderate difficulty",
  "patient_adult_knee_function_q18_a4": "Extreme difficulty",
  "patient_adult_knee_function_q18_a5": "Unable to do",
  "patient_adult_knee_function_q19": "Twisting/pivoting on your injured knee",
  "patient_adult_knee_function_q19_a1": "No difficulty",
  "patient_adult_knee_function_q19_a2": "Minimal difficulty",
  "patient_adult_knee_function_q19_a3": "Moderate difficulty",
  "patient_adult_knee_function_q19_a4": "Extreme difficulty",
  "patient_adult_knee_function_q19_a5": "Unable to do",
  "patient_adult_knee_function_q20": "Getting in and out of car or using public transport",
  "patient_adult_knee_function_q20_a1": "No difficulty",
  "patient_adult_knee_function_q20_a2": "Minimal difficulty",
  "patient_adult_knee_function_q20_a3": "Moderate difficulty",
  "patient_adult_knee_function_q20_a4": "Extreme difficulty",
  "patient_adult_knee_function_q20_a5": "Unable to do",
  "patient_adult_knee_function_q21": "Getting in and out of bath",
  "patient_adult_knee_function_q21_a1": "No difficulty",
  "patient_adult_knee_function_q21_a2": "Minimal difficulty",
  "patient_adult_knee_function_q21_a3": "Moderate difficulty",
  "patient_adult_knee_function_q21_a4": "Extreme difficulty",
  "patient_adult_knee_function_q21_a5": "Unable to do",
  "patient_adult_knee_function_q22": "Getting on/off toilet",
  "patient_adult_knee_function_q22_a1": "No difficulty",
  "patient_adult_knee_function_q22_a2": "Minimal difficulty",
  "patient_adult_knee_function_q22_a3": "Moderate difficulty",
  "patient_adult_knee_function_q22_a4": "Extreme difficulty",
  "patient_adult_knee_function_q22_a5": "Unable to do",
  "patient_adult_knee_function_q23": "Washing and drying yourself",
  "patient_adult_knee_function_q23_a1": "No difficulty",
  "patient_adult_knee_function_q23_a2": "Minimal difficulty",
  "patient_adult_knee_function_q23_a3": "Moderate difficulty",
  "patient_adult_knee_function_q23_a4": "Extreme difficulty",
  "patient_adult_knee_function_q23_a5": "Unable to do",
  "patient_adult_knee_function_q24": "Household shopping",
  "patient_adult_knee_function_q24_a1": "No difficulty",
  "patient_adult_knee_function_q24_a2": "Minimal difficulty",
  "patient_adult_knee_function_q24_a3": "Moderate difficulty",
  "patient_adult_knee_function_q24_a4": "Extreme difficulty",
  "patient_adult_knee_function_q24_a5": "Unable to do",
  "patient_adult_knee_function_q25": "Heavy domestic duties (moving heavy boxes, scrubbing floors, etc)",
  "patient_adult_knee_function_q25_a1": "No difficulty",
  "patient_adult_knee_function_q25_a2": "Minimal difficulty",
  "patient_adult_knee_function_q25_a3": "Moderate difficulty",
  "patient_adult_knee_function_q25_a4": "Extreme difficulty",
  "patient_adult_knee_function_q25_a5": "Unable to do",
  "patient_adult_knee_function_q26": "Light domestic duties (cooking, dusting, etc)",
  "patient_adult_knee_function_q26_a1": "No difficulty",
  "patient_adult_knee_function_q26_a2": "Minimal difficulty",
  "patient_adult_knee_function_q26_a3": "Moderate difficulty",
  "patient_adult_knee_function_q26_a4": "Extreme difficulty",
  "patient_adult_knee_function_q26_a5": "Unable to do",
  "patient_adult_knee_function_q27": "How would you rate the CURRENT function of your knee on a scale of 0-10?",
  "patient_adult_knee_function_q28": "10 being normal and 0 being inability to perform any of your usual daily activities which may include sports",
  "patient_adult_knee_function_q28_a1": "Worst",
  "patient_adult_knee_function_q28_a2": "Best",
  "patient_adult_knee_function_q29": "What is the highest level of activity that you can participate in on a regular basis?",
  "patient_adult_knee_function_q29_a1": "Very strenuous activities like jumping or pivoting as in basketball or soccer",
  "patient_adult_knee_function_q29_a2": "Strenuous activities like heavy physical work, skiing or tennis",
  "patient_adult_knee_function_q29_a3": "Moderate activities like moderate physical work, running or jogging",
  "patient_adult_knee_function_q29_a4": "Light activities like walking, housework or yard work",
  "patient_adult_knee_function_q29_a5": "Unable to perform any of the above activities due to knee",
  "patient_adult_knee_function_q30": "Please specify the highest level of activity that you can participate in",
  "patient_adult_knee_function_q30_q1": "Prior to knee injury/knee problem",
  "patient_adult_knee_function_q30_q1_a1": "Competitive",
  "patient_adult_knee_function_q30_q1_a2": "Recreational, physical fitness",
  "patient_adult_knee_function_q30_q1_a3": "No sports, only work",
  "patient_adult_knee_function_q30_q1_a4": "Only activities of daily living",
  "patient_adult_knee_function_q30_q1_a5": "Sick leave or disability pension because of health problems",
  "patient_adult_knee_function_q30_q1_a1_o1": "Competitive sports played at a world or Olympic level or professionally",
  "patient_adult_knee_function_q30_q1_a1_o2": "Competitive sports such as track and field, racquet/ball sports, gymnastics, rowing, skiing, or martial arts played at a national or international level",
  "patient_adult_knee_function_q30_q1_a1_o3": "Competitive sports such as water sports, cycling, hockey, curling, or horseback riding/rodeo played at a national or international level",
  "patient_adult_knee_function_q30_q1_a2_o1": "Recreational sports such as running, ball/racquet sports, weight training, curling, rowing, golf, yoga, or gymnastics at least 5 times per week",
  "patient_adult_knee_function_q30_q1_a2_o2": "Recreational sports such as swimming, water sports, skiing, hockey, rollerblading, cycling, horseback riding, or mountain climbing at least 5 times per week",
  "patient_adult_knee_function_q30_q1_a2_o3": "Recreational sports at least twice weekly",
  "patient_adult_knee_function_q30_q1_a2_o4": "Recreational sports once a week or less",
  "patient_adult_knee_function_q30_q1_a2_o5": "Recreational sports less than once a month",
  "patient_adult_knee_function_q30_q1_a3_o1": "Heavy labor such as construction",
  "patient_adult_knee_function_q30_q1_a3_o2": "Moderately heavy labor such as truck driving",
  "patient_adult_knee_function_q30_q1_a3_o3": "Light labor such as nursing",
  "patient_adult_knee_function_q30_q1_a3_o4": "Sedentary work such as secretarial or computer-based work",
  "patient_adult_knee_function_q30_q1_a4_o1": "Daily activities such as gardening, climbing multiple flights of stairs, carrying loads, pushing/pulling a load, ability to run if late",
  "patient_adult_knee_function_q30_q1_a4_o2": "Daily activities such as cleaning house, climbing 1 or 2 flights of stairs, or walking carrying a light load",
  "patient_adult_knee_function_q30_q1_a4_o3": "Daily activities limited (eg do not take stairs, unable to carry loads)",
  "patient_adult_knee_function_q30_q2": "Current status",
  "patient_adult_knee_function_q30_q2_a1": "Competitive",
  "patient_adult_knee_function_q30_q2_a2": "Recreational, physical fitness",
  "patient_adult_knee_function_q30_q2_a3": "No sports, only work",
  "patient_adult_knee_function_q30_q2_a4": "Only activities of daily living",
  "patient_adult_knee_function_q30_q2_a5": "Sick leave or disability pension because of health problems",
  "patient_adult_knee_function_q30_q2_a1_o1": "Competitive sports played at a world or Olympic level or professionally",
  "patient_adult_knee_function_q30_q2_a1_o2": "Competitive sports such as track and field, racquet/ball sports, gymnastics, rowing, skiing, or martial arts played at a national or international level",
  "patient_adult_knee_function_q30_q2_a1_o3": "Competitive sports such as water sports, cycling, hockey, curling, or horseback riding/rodeo played at a national or international level",
  "patient_adult_knee_function_q30_q2_a2_o1": "Recreational sports such as running, ball/racquet sports, weight training, curling, rowing, golf, yoga, or gymnastics at least 5 times per week",
  "patient_adult_knee_function_q30_q2_a2_o2": "Recreational sports such as swimming, water sports, skiing, hockey, rollerblading, cycling, horseback riding, or mountain climbing at least 5 times per week",
  "patient_adult_knee_function_q30_q2_a2_o3": "Recreational sports at least twice weekly",
  "patient_adult_knee_function_q30_q2_a2_o4": "Recreational sports once a week or less",
  "patient_adult_knee_function_q30_q2_a2_o5": "Recreational sports less than once a month",
  "patient_adult_knee_function_q30_q2_a3_o1": "Heavy labor such as construction",
  "patient_adult_knee_function_q30_q2_a3_o2": "Moderately heavy labor such as truck driving",
  "patient_adult_knee_function_q30_q2_a3_o3": "Light labor such as nursing",
  "patient_adult_knee_function_q30_q2_a3_o4": "Sedentary work such as secretarial or computer-based work",
  "patient_adult_knee_function_q30_q2_a4_o1": "Daily activities such as gardening, climbing multiple flights of stairs, carrying loads, pushing/pulling a load, ability to run if late",
  "patient_adult_knee_function_q30_q2_a4_o2": "Daily activities such as cleaning house, climbing 1 or 2 flights of stairs, or walking carrying a light load",
  "patient_adult_knee_function_q30_q2_a4_o3": "Daily activities limited (eg do not take stairs, unable to carry loads)",
  "patient_adult_knee_function_q31": "Does your kneecap get out of place or does it feel like it is getting out of place?",
  "patient_adult_knee_function_q31_a1": "No",
  "patient_adult_knee_function_q31_a2": "Yes",
  "patient_adult_knee_patellofemoral_instability": "Patellofemoral Instability",
  "patient_adult_knee_patellofemoral_instability_instruction": "Please choose what best describes HOW OFTEN YOUR KNEECAP FEELS LIKE IT WILL “POP OUT” OF JOINT OR FEELS UNSTABLE when you are doing each of the following activities",
  "patient_adult_knee_patellofemoral_instability_instruction_0": "Never",
  "patient_adult_knee_patellofemoral_instability_instruction_1": "Rarely",
  "patient_adult_knee_patellofemoral_instability_instruction_2": "Sometimes",
  "patient_adult_knee_patellofemoral_instability_instruction_3": "Often",
  "patient_adult_knee_patellofemoral_instability_instruction_4": "Always",
  "patient_adult_knee_patellofemoral_instability_q1": "Twisting/changing direction during sports/games",
  "patient_adult_knee_patellofemoral_instability_q1_do_not_do": "Do not do",
  "patient_adult_knee_patellofemoral_instability_q1_a1": "Never",
  "patient_adult_knee_patellofemoral_instability_q1_a2": "Rarely",
  "patient_adult_knee_patellofemoral_instability_q1_a3": "Sometimes",
  "patient_adult_knee_patellofemoral_instability_q1_a4": "Often",
  "patient_adult_knee_patellofemoral_instability_q1_a5": "Always",
  "patient_adult_knee_patellofemoral_instability_q2": "Changing direction when running",
  "patient_adult_knee_patellofemoral_instability_q2_do_not_do": "Do not do",
  "patient_adult_knee_patellofemoral_instability_q2_a1": "Never",
  "patient_adult_knee_patellofemoral_instability_q2_a2": "Rarely",
  "patient_adult_knee_patellofemoral_instability_q2_a3": "Sometimes",
  "patient_adult_knee_patellofemoral_instability_q2_a4": "Often",
  "patient_adult_knee_patellofemoral_instability_q2_a5": "Always",
  "patient_adult_knee_patellofemoral_instability_q3": "Running in a straight line on UNEVEN surfaces",
  "patient_adult_knee_patellofemoral_instability_q3_do_not_do": "Do not do",
  "patient_adult_knee_patellofemoral_instability_q3_a1": "Never",
  "patient_adult_knee_patellofemoral_instability_q3_a2": "Rarely",
  "patient_adult_knee_patellofemoral_instability_q3_a3": "Sometimes",
  "patient_adult_knee_patellofemoral_instability_q3_a4": "Often",
  "patient_adult_knee_patellofemoral_instability_q3_a5": "Always",
  "patient_adult_knee_patellofemoral_instability_q4": "Walking on slippery, wet or icy surfaces",
  "patient_adult_knee_patellofemoral_instability_q4_do_not_do": "Do not do",
  "patient_adult_knee_patellofemoral_instability_q4_a1": "Never",
  "patient_adult_knee_patellofemoral_instability_q4_a2": "Rarely",
  "patient_adult_knee_patellofemoral_instability_q4_a3": "Sometimes",
  "patient_adult_knee_patellofemoral_instability_q4_a4": "Often",
  "patient_adult_knee_patellofemoral_instability_q4_a5": "Always",
  "patient_adult_knee_patellofemoral_instability_q5": "Running sideways",
  "patient_adult_knee_patellofemoral_instability_q5_do_not_do": "Do not do",
  "patient_adult_knee_patellofemoral_instability_q5_a1": "Never",
  "patient_adult_knee_patellofemoral_instability_q5_a2": "Rarely",
  "patient_adult_knee_patellofemoral_instability_q5_a3": "Sometimes",
  "patient_adult_knee_patellofemoral_instability_q5_a4": "Often",
  "patient_adult_knee_patellofemoral_instability_q5_a5": "Always",
  "patient_adult_knee_patellofemoral_instability_q6": "Hopping",
  "patient_adult_knee_patellofemoral_instability_q6_do_not_do": "Do not do",
  "patient_adult_knee_patellofemoral_instability_q6_a1": "Never",
  "patient_adult_knee_patellofemoral_instability_q6_a2": "Rarely",
  "patient_adult_knee_patellofemoral_instability_q6_a3": "Sometimes",
  "patient_adult_knee_patellofemoral_instability_q6_a4": "Often",
  "patient_adult_knee_patellofemoral_instability_q6_a5": "Always",
  "patient_adult_knee_patellofemoral_instability_q7": "Jumping",
  "patient_adult_knee_patellofemoral_instability_q7_do_not_do": "Do not do",
  "patient_adult_knee_patellofemoral_instability_q7_a1": "Never",
  "patient_adult_knee_patellofemoral_instability_q7_a2": "Rarely",
  "patient_adult_knee_patellofemoral_instability_q7_a3": "Sometimes",
  "patient_adult_knee_patellofemoral_instability_q7_a4": "Often",
  "patient_adult_knee_patellofemoral_instability_q7_a5": "Always",
  "patient_adult_knee_patellofemoral_instability_q8": "Running in a straight line on EVEN surfaces",
  "patient_adult_knee_patellofemoral_instability_q8_do_not_do": "Do not do",
  "patient_adult_knee_patellofemoral_instability_q8_a1": "Never",
  "patient_adult_knee_patellofemoral_instability_q8_a2": "Rarely",
  "patient_adult_knee_patellofemoral_instability_q8_a3": "Sometimes",
  "patient_adult_knee_patellofemoral_instability_q8_a4": "Often",
  "patient_adult_knee_patellofemoral_instability_q8_a5": "Always",
  "patient_adult_knee_patellofemoral_instability_q9": "Going down stairs",
  "patient_adult_knee_patellofemoral_instability_q9_do_not_do": "Do not do",
  "patient_adult_knee_patellofemoral_instability_q9_a1": "Never",
  "patient_adult_knee_patellofemoral_instability_q9_a2": "Rarely",
  "patient_adult_knee_patellofemoral_instability_q9_a3": "Sometimes",
  "patient_adult_knee_patellofemoral_instability_q9_a4": "Often",
  "patient_adult_knee_patellofemoral_instability_q9_a5": "Always",
  "patient_adult_knee_patellofemoral_instability_q10": "Squatting",
  "patient_adult_knee_patellofemoral_instability_q10_do_not_do": "Do not do",
  "patient_adult_knee_patellofemoral_instability_q10_a1": "Never",
  "patient_adult_knee_patellofemoral_instability_q10_a2": "Rarely",
  "patient_adult_knee_patellofemoral_instability_q10_a3": "Sometimes",
  "patient_adult_knee_patellofemoral_instability_q10_a4": "Often",
  "patient_adult_knee_patellofemoral_instability_q10_a5": "Always",
  "patient_adult_knee_patellofemoral_instability_q11": "Kneeling",
  "patient_adult_knee_patellofemoral_instability_q11_do_not_do": "Do not do",
  "patient_adult_knee_patellofemoral_instability_q11_a1": "Never",
  "patient_adult_knee_patellofemoral_instability_q11_a2": "Rarely",
  "patient_adult_knee_patellofemoral_instability_q11_a3": "Sometimes",
  "patient_adult_knee_patellofemoral_instability_q11_a4": "Often",
  "patient_adult_knee_patellofemoral_instability_q11_a5": "Always",
  "patient_adult_knee_patellofemoral_instability_q12": "Walking in a straight line on UNEVEN surfaces",
  "patient_adult_knee_patellofemoral_instability_q12_do_not_do": "Do not do",
  "patient_adult_knee_patellofemoral_instability_q12_a1": "Never",
  "patient_adult_knee_patellofemoral_instability_q12_a2": "Rarely",
  "patient_adult_knee_patellofemoral_instability_q12_a3": "Sometimes",
  "patient_adult_knee_patellofemoral_instability_q12_a4": "Often",
  "patient_adult_knee_patellofemoral_instability_q12_a5": "Always",
  "patient_adult_knee_patellofemoral_instability_q13": "Climbing stairs",
  "patient_adult_knee_patellofemoral_instability_q13_do_not_do": "Do not do",
  "patient_adult_knee_patellofemoral_instability_q13_a1": "Never",
  "patient_adult_knee_patellofemoral_instability_q13_a2": "Rarely",
  "patient_adult_knee_patellofemoral_instability_q13_a3": "Sometimes",
  "patient_adult_knee_patellofemoral_instability_q13_a4": "Often",
  "patient_adult_knee_patellofemoral_instability_q13_a5": "Always",
  "patient_adult_knee_patellofemoral_instability_q14": "Stepping onto or over a high step",
  "patient_adult_knee_patellofemoral_instability_q14_do_not_do": "Do not do",
  "patient_adult_knee_patellofemoral_instability_q14_a1": "Never",
  "patient_adult_knee_patellofemoral_instability_q14_a2": "Rarely",
  "patient_adult_knee_patellofemoral_instability_q14_a3": "Sometimes",
  "patient_adult_knee_patellofemoral_instability_q14_a4": "Often",
  "patient_adult_knee_patellofemoral_instability_q14_a5": "Always",
  "patient_adult_knee_patellofemoral_instability_q15": "Crossing your legs when sitting",
  "patient_adult_knee_patellofemoral_instability_q15_do_not_do": "Do not do",
  "patient_adult_knee_patellofemoral_instability_q15_a1": "Never",
  "patient_adult_knee_patellofemoral_instability_q15_a2": "Rarely",
  "patient_adult_knee_patellofemoral_instability_q15_a3": "Sometimes",
  "patient_adult_knee_patellofemoral_instability_q15_a4": "Often",
  "patient_adult_knee_patellofemoral_instability_q15_a5": "Always",
  "patient_adult_knee_patellofemoral_instability_q16": "Walking in a straight line on EVEN surfaces",
  "patient_adult_knee_patellofemoral_instability_q16_do_not_do": "Do not do",
  "patient_adult_knee_patellofemoral_instability_q16_a1": "Never",
  "patient_adult_knee_patellofemoral_instability_q16_a2": "Rarely",
  "patient_adult_knee_patellofemoral_instability_q16_a3": "Sometimes",
  "patient_adult_knee_patellofemoral_instability_q16_a4": "Often",
  "patient_adult_knee_patellofemoral_instability_q16_a5": "Always",
  "patient_adult_knee_patellofemoral_instability_q17": "Getting into or out of a car",
  "patient_adult_knee_patellofemoral_instability_q17_do_not_do": "Do not do",
  "patient_adult_knee_patellofemoral_instability_q17_a1": "Never",
  "patient_adult_knee_patellofemoral_instability_q17_a2": "Rarely",
  "patient_adult_knee_patellofemoral_instability_q17_a3": "Sometimes",
  "patient_adult_knee_patellofemoral_instability_q17_a4": "Often",
  "patient_adult_knee_patellofemoral_instability_q17_a5": "Always",
  "patient_adult_knee_patellofemoral_instability_q18": "Turning a heavy trolley round a supermarket aisle",
  "patient_adult_knee_patellofemoral_instability_q18_do_not_do": "Do not do",
  "patient_adult_knee_patellofemoral_instability_q18_a1": "Never",
  "patient_adult_knee_patellofemoral_instability_q18_a2": "Rarely",
  "patient_adult_knee_patellofemoral_instability_q18_a3": "Sometimes",
  "patient_adult_knee_patellofemoral_instability_q18_a4": "Often",
  "patient_adult_knee_patellofemoral_instability_q18_a5": "Always",
  "patient_adult_knee_patellofemoral_instability_q19": "Turning to look over your shoulder",
  "patient_adult_knee_patellofemoral_instability_q19_do_not_do": "Do not do",
  "patient_adult_knee_patellofemoral_instability_q19_a1": "Never",
  "patient_adult_knee_patellofemoral_instability_q19_a2": "Rarely",
  "patient_adult_knee_patellofemoral_instability_q19_a3": "Sometimes",
  "patient_adult_knee_patellofemoral_instability_q19_a4": "Often",
  "patient_adult_knee_patellofemoral_instability_q19_a5": "Always",
  "patient_adult_knee_quality_of_life": "Quality of Life",
  "patient_adult_knee_quality_of_life_instruction": "The following questions ask about social, emotional, and lifestyle concerns that you may feel with respect to your knee problem. Please think about how you have felt most of the time in relation to your knee.",
  "patient_adult_knee_quality_of_life_q1": "How often are you aware of your knee problem?",
  "patient_adult_knee_quality_of_life_q1_a1": "Never",
  "patient_adult_knee_quality_of_life_q1_a2": "Monthly",
  "patient_adult_knee_quality_of_life_q1_a3": "Weekly",
  "patient_adult_knee_quality_of_life_q1_a4": "Daily",
  "patient_adult_knee_quality_of_life_q1_a5": "Constantly",
  "patient_adult_knee_quality_of_life_q2": "Have you modified your lifestyle to avoid potentially damaging activities to your knee?",
  "patient_adult_knee_quality_of_life_q2_a1": "Not at all",
  "patient_adult_knee_quality_of_life_q2_a2": "Mildly",
  "patient_adult_knee_quality_of_life_q2_a3": "Moderately",
  "patient_adult_knee_quality_of_life_q2_a4": "Severely",
  "patient_adult_knee_quality_of_life_q2_a5": "Totally",
  "patient_adult_knee_quality_of_life_q3": "How much are you troubled with the lack of confidence in your knee?",
  "patient_adult_knee_quality_of_life_q3_a1": "Not at all",
  "patient_adult_knee_quality_of_life_q3_a2": "Mildly",
  "patient_adult_knee_quality_of_life_q3_a3": "Moderately",
  "patient_adult_knee_quality_of_life_q3_a4": "Severely",
  "patient_adult_knee_quality_of_life_q3_a5": "Extremely",
  "patient_adult_knee_quality_of_life_q4": "In general, how much difficulty do you have with your knee?",
  "patient_adult_knee_quality_of_life_q4_a1": "None",
  "patient_adult_knee_quality_of_life_q4_a2": "Mild",
  "patient_adult_knee_quality_of_life_q4_a3": "Moderate",
  "patient_adult_knee_quality_of_life_q4_a4": "Severe",
  "patient_adult_knee_quality_of_life_q4_a5": "Extreme",
  "patient_adult_knee_quality_of_life_q5": "How anxious/depressed are you today?",
  "patient_adult_knee_quality_of_life_q5_a1": "Not anxious/depressed",
  "patient_adult_knee_quality_of_life_q5_a2": "Slightly",
  "patient_adult_knee_quality_of_life_q5_a3": "Moderately",
  "patient_adult_knee_quality_of_life_q5_a4": "Severely",
  "patient_adult_knee_quality_of_life_q5_a5": "Extremely",
  "patient_adult_knee_quality_of_life_q6": "How good/bad is your health today?",
  "patient_adult_knee_quality_of_life_q6_instruction": "100 means best health, 0 means worst health you can imagine",
  "patient_adult_knee_quality_of_life_q6_instruction_a1": "Worst",
  "patient_adult_knee_quality_of_life_q6_instruction_a2": "Best",
  "patient_adult_knee_satisfaction": "Satisfaction",
  "patient_adult_knee_satisfaction_instructions": "How satisfied are you with the results of your knee surgery/treatment?",
  "patient_adult_knee_satisfaction_instructions_1": "Very dissatisfied",
  "patient_adult_knee_satisfaction_instructions_2": "Somewhat dissatisfied",
  "patient_adult_knee_satisfaction_instructions_3": "Somewhat satisfied",
  "patient_adult_knee_satisfaction_instructions_4": "Very satisfied",
  "patient_adult_knee_satisfaction_q1": "Overall",
  "patient_adult_knee_satisfaction_q1_not_applicable": "Not applicable",
  "patient_adult_knee_satisfaction_q1_a1": "Very dissatisfied",
  "patient_adult_knee_satisfaction_q1_a2": "Somewhat dissatisfied",
  "patient_adult_knee_satisfaction_q1_a3": "Somewhat satisfied",
  "patient_adult_knee_satisfaction_q1_a4": "Very satisfied",
  "patient_adult_knee_satisfaction_q2": "For improving your pain?",
  "patient_adult_knee_satisfaction_q2_not_applicable": "Not applicable",
  "patient_adult_knee_satisfaction_q2_a1": "Very dissatisfied",
  "patient_adult_knee_satisfaction_q2_a2": "Somewhat dissatisfied",
  "patient_adult_knee_satisfaction_q2_a3": "Somewhat satisfied",
  "patient_adult_knee_satisfaction_q2_a4": "Very satisfied",
  "patient_adult_knee_satisfaction_q3": "For improving your ability to do home or yard work?",
  "patient_adult_knee_satisfaction_q3_not_applicable": "Not applicable",
  "patient_adult_knee_satisfaction_q3_a1": "Very dissatisfied",
  "patient_adult_knee_satisfaction_q3_a2": "Somewhat dissatisfied",
  "patient_adult_knee_satisfaction_q3_a3": "Somewhat satisfied",
  "patient_adult_knee_satisfaction_q3_a4": "Very satisfied",
  "patient_adult_knee_satisfaction_q4": "For improving your ability to do recreational activites?",
  "patient_adult_knee_satisfaction_q4_not_applicable": "Not applicable",
  "patient_adult_knee_satisfaction_q4_a1": "Very dissatisfied",
  "patient_adult_knee_satisfaction_q4_a2": "Somewhat dissatisfied",
  "patient_adult_knee_satisfaction_q4_a3": "Somewhat satisfied",
  "patient_adult_knee_satisfaction_q4_a4": "Very satisfied",
  "patient_adult_knee_complete": "End of Questionnaire",
  "patient_adult_knee_complete_text1": "Thank you for taking the time to fill out this form.",
  "patient_adult_knee_complete_text2": "Please click submit to complete and see your scores.",
  "patient_adult_knee_complete_text3": "Your answers will be treated confidentially and any reports will only use de-identified information.",
  "patient_adult_knee_results": "Results",
  "patient_adult_knee_results_text1": "Below are your pain and knee function scores, which are calculated from your questionnaire.",
  "patient_adult_knee_results_pain": "Pain",
  "patient_adult_knee_results_function": "Function",
  "patient_adult_knee_results_health_perception": "Overall Health Perception",
  "patient_adult_knee_results_self_evaluation": "Knee Scoring Self-Evaluation",
  "patient_adult_knee_results_text2": "Your Doctor will be with you shortly.",
  "patient_adolescent_knee_pain": "Pain",
  "patient_adolescent_knee_function_q23": "Going shopping",
  "patient_adolescent_knee_function_q23_a1": "No difficulty",
  "patient_adolescent_knee_function_q23_a2": "Minimal difficulty",
  "patient_adolescent_knee_function_q23_a3": "Moderate difficulty",
  "patient_adolescent_knee_function_q23_a4": "Extreme difficulty",
  "patient_adolescent_knee_function_q23_a5": "Unable to do",
  "patient_pedia_knee_pain": "Pain",
  "patient_pedia_knee_pain_q1": "During the past 4 weeks or since your injury, how much of the time did your injured knee hurt?",
  "patient_pedia_knee_pain_q1_a1": "Never hurt",
  "patient_pedia_knee_pain_q1_a2": "Rarely",
  "patient_pedia_knee_pain_q1_a3": "Sometimes",
  "patient_pedia_knee_pain_q1_a4": "Often",
  "patient_pedia_knee_pain_q1_a5": "Constant",
  "patient_pedia_knee_pain_q2": "How badly does your injured knee hurt today?",
  "patient_pedia_knee_pain_q2_a1": "Does not hurt at all",
  "patient_pedia_knee_pain_q2_a2": "Hurts so much I can't stand it",
  "patient_pedia_knee_pain_instructions": "How much knee pain have you experienced in the past 7 days during the following activities?",
  "patient_pedia_knee_pain_instructions_0": "No pain",
  "patient_pedia_knee_pain_instructions_1": "A little pain",
  "patient_pedia_knee_pain_instructions_2": "Some pain",
  "patient_pedia_knee_pain_instructions_3": "A lot of pain",
  "patient_pedia_knee_pain_instructions_4": "Extreme pain",
  "patient_pedia_knee_pain_note": "Note: Please answer the following questions relating to your knee activities.",
  "patient_pedia_knee_pain_q3": "Twisting/pivoting knee when walking/standing/running",
  "patient_pedia_knee_pain_q3_a1": "No pain",
  "patient_pedia_knee_pain_q3_a2": "A little pain",
  "patient_pedia_knee_pain_q3_a3": "Some pain",
  "patient_pedia_knee_pain_q3_a4": "A lot of pain",
  "patient_pedia_knee_pain_q3_a5": "Extreme pain",
  "patient_pedia_knee_pain_q4": "Fully straightening your knee",
  "patient_pedia_knee_pain_q4_a1": "No pain",
  "patient_pedia_knee_pain_q4_a2": "A little pain",
  "patient_pedia_knee_pain_q4_a3": "Some pain",
  "patient_pedia_knee_pain_q4_a4": "A lot of pain",
  "patient_pedia_knee_pain_q4_a5": "Extreme pain",
  "patient_pedia_knee_pain_q5": "Fully bending your knee",
  "patient_pedia_knee_pain_q5_a1": "No pain",
  "patient_pedia_knee_pain_q5_a2": "A little pain",
  "patient_pedia_knee_pain_q5_a3": "Some pain",
  "patient_pedia_knee_pain_q5_a4": "A lot of pain",
  "patient_pedia_knee_pain_q5_a5": "Extreme pain",
  "patient_pedia_knee_pain_q6": "Walking up stairs",
  "patient_pedia_knee_pain_q6_a1": "No pain",
  "patient_pedia_knee_pain_q6_a2": "A little pain",
  "patient_pedia_knee_pain_q6_a3": "Some pain",
  "patient_pedia_knee_pain_q6_a4": "A lot of pain",
  "patient_pedia_knee_pain_q6_a5": "Extreme pain",
  "patient_pedia_knee_pain_q7": "Walking down stairs",
  "patient_pedia_knee_pain_q7_a1": "No pain",
  "patient_pedia_knee_pain_q7_a2": "A little pain",
  "patient_pedia_knee_pain_q7_a3": "Some pain",
  "patient_pedia_knee_pain_q7_a4": "A lot of pain",
  "patient_pedia_knee_pain_q7_a5": "Extreme pain",
  "patient_pedia_knee_pain_q8": "Sitting with your knee bent",
  "patient_pedia_knee_pain_q8_a1": "No pain",
  "patient_pedia_knee_pain_q8_a2": "A little pain",
  "patient_pedia_knee_pain_q8_a3": "Some pain",
  "patient_pedia_knee_pain_q8_a4": "A lot of pain",
  "patient_pedia_knee_pain_q8_a5": "Extreme pain",
  "patient_pedia_knee_pain_q9": "Standing upright on both legs for any amount of time",
  "patient_pedia_knee_pain_q9_a1": "No pain",
  "patient_pedia_knee_pain_q9_a2": "A little pain",
  "patient_pedia_knee_pain_q9_a3": "Some pain",
  "patient_pedia_knee_pain_q9_a4": "A lot of pain",
  "patient_pedia_knee_pain_q9_a5": "Extreme pain",
  "patient_pedia_knee_other_symptoms": "Other Symptoms",
  "patient_pedia_knee_other_symptoms_instruction1": "How often have you had the following:",
  "patient_pedia_knee_other_symptoms_instruction1_0": "Never",
  "patient_pedia_knee_other_symptoms_instruction1_1": "Rarely",
  "patient_pedia_knee_other_symptoms_instruction1_2": "Sometimes",
  "patient_pedia_knee_other_symptoms_instruction1_3": "Often",
  "patient_pedia_knee_other_symptoms_instruction1_4": "Always",
  "patient_pedia_knee_other_symptoms_q1": "Knee making any noise/sounds",
  "patient_pedia_knee_other_symptoms_q1_a1": "Never",
  "patient_pedia_knee_other_symptoms_q1_a2": "Rarely",
  "patient_pedia_knee_other_symptoms_q1_a3": "Sometimes",
  "patient_pedia_knee_other_symptoms_q1_a4": "Often",
  "patient_pedia_knee_other_symptoms_q1_a5": "Always",
  "patient_pedia_knee_other_symptoms_q2": "Knee getting stuck or locked in place",
  "patient_pedia_knee_other_symptoms_q2_a1": "Never",
  "patient_pedia_knee_other_symptoms_q2_a2": "Rarely",
  "patient_pedia_knee_other_symptoms_q2_a3": "Sometimes",
  "patient_pedia_knee_other_symptoms_q2_a4": "Often",
  "patient_pedia_knee_other_symptoms_q2_a5": "Always",
  "patient_pedia_knee_other_symptoms_q3": "Feeling like the knee is getting stuck (catching) but you could still move it",
  "patient_pedia_knee_other_symptoms_q3_a1": "Never",
  "patient_pedia_knee_other_symptoms_q3_a2": "Rarely",
  "patient_pedia_knee_other_symptoms_q3_a3": "Sometimes",
  "patient_pedia_knee_other_symptoms_q3_a4": "Often",
  "patient_pedia_knee_other_symptoms_q3_a5": "Always",
  "patient_pedia_knee_other_symptoms_q4": "Swelling/puffy knee",
  "patient_pedia_knee_other_symptoms_q4_a1": "Never",
  "patient_pedia_knee_other_symptoms_q4_a2": "Rarely",
  "patient_pedia_knee_other_symptoms_q4_a3": "Sometimes",
  "patient_pedia_knee_other_symptoms_q4_a4": "Often",
  "patient_pedia_knee_other_symptoms_q4_a5": "Always",
  "patient_pedia_knee_other_symptoms_q5": "How PUFFY (OR SWOLLEN) was your knee?",
  "patient_pedia_knee_other_symptoms_q5_a1": "Not at all puffy",
  "patient_pedia_knee_other_symptoms_q5_a2": "A little puffy",
  "patient_pedia_knee_other_symptoms_q5_a3": "Somewhat puffy",
  "patient_pedia_knee_other_symptoms_q5_a4": "Very puffy",
  "patient_pedia_knee_other_symptoms_q5_a5": "Extremely puffy",
  "patient_pedia_knee_other_symptoms_instruction2": "If you were asked to do the activities below, what is the most you could do TODAY...",
  "patient_pedia_knee_other_symptoms_instruction2_0": "Very hard activities like jumping or turning fast to change direction like in basketball or soccer",
  "patient_pedia_knee_other_symptoms_instruction2_1": "Hard activities like heavy lifting skiing or tennis",
  "patient_pedia_knee_other_symptoms_instruction2_2": "Sort of hard activities like walking fast or jogging",
  "patient_pedia_knee_other_symptoms_instruction2_3": "Light activities like walking at a normal speed",
  "patient_pedia_knee_other_symptoms_instruction2_4": "I can’t do any of the activities listed above",
  "patient_pedia_knee_other_symptoms_q6": "Without making your knee HURT A LOT",
  "patient_pedia_knee_other_symptoms_q6_a1": "Very hard activities",
  "patient_pedia_knee_other_symptoms_q6_a2": "Hard activities",
  "patient_pedia_knee_other_symptoms_q6_a3": "Sort of hard activities",
  "patient_pedia_knee_other_symptoms_q6_a4": "Light activities",
  "patient_pedia_knee_other_symptoms_q6_a5": "I can’t do any of the above",
  "patient_pedia_knee_other_symptoms_q7": "Without making your knee PUFFY (OR SWOLLEN)",
  "patient_pedia_knee_other_symptoms_q7_a1": "Very hard activities",
  "patient_pedia_knee_other_symptoms_q7_a2": "Hard activities",
  "patient_pedia_knee_other_symptoms_q7_a3": "Sort of hard activities",
  "patient_pedia_knee_other_symptoms_q7_a4": "Light activities",
  "patient_pedia_knee_other_symptoms_q7_a5": "I can’t do any of the above",
  "patient_pedia_knee_other_symptoms_q8": "Without your knee FEELING LIKE IT CAN’T HOLD YOU UP",
  "patient_pedia_knee_other_symptoms_q8_a1": "Very hard activities",
  "patient_pedia_knee_other_symptoms_q8_a2": "Hard activities",
  "patient_pedia_knee_other_symptoms_q8_a3": "Sort of hard activities",
  "patient_pedia_knee_other_symptoms_q8_a5": "I can’t do any of the above",
  "patient_pedia_knee_other_symptoms_q9": "Most of the time",
  "patient_pedia_knee_other_symptoms_q9_a1": "Very hard activities",
  "patient_pedia_knee_other_symptoms_q9_a2": "Hard activities",
  "patient_pedia_knee_other_symptoms_q9_a3": "Sort of hard activities",
  "patient_pedia_knee_other_symptoms_q9_a4": "Light activities",
  "patient_pedia_knee_other_symptoms_q9_a5": "I can’t do any of the above",
  "patient_pedia_knee_function": "Function",
  "patient_pedia_knee_function_instruction": "How much difficulty have you had doing the following because of your knee:",
  "patient_pedia_knee_function_instruction_0": "No difficulty",
  "patient_pedia_knee_function_instruction_1": "A little",
  "patient_pedia_knee_function_instruction_2": "Some",
  "patient_pedia_knee_function_instruction_3": "A lot",
  "patient_pedia_knee_function_instruction_4": "Extreme difficulty/cannot do",
  "patient_pedia_knee_function_note": "Note: If you don't do a certain activity, imagine how your knee would feel if you had to try it.",
  "patient_pedia_knee_function_q1": "Go up stairs",
  "patient_pedia_knee_function_q1_a1": "No difficulty",
  "patient_pedia_knee_function_q1_a2": "A little",
  "patient_pedia_knee_function_q1_a3": "Some",
  "patient_pedia_knee_function_q1_a4": "A lot",
  "patient_pedia_knee_function_q1_a5": "Extreme difficulty/cannot do",
  "patient_pedia_knee_function_q2": "Go down stairs",
  "patient_pedia_knee_function_q2_a1": "No difficulty",
  "patient_pedia_knee_function_q2_a2": "A little",
  "patient_pedia_knee_function_q2_a3": "Some",
  "patient_pedia_knee_function_q2_a4": "A lot",
  "patient_pedia_knee_function_q2_a5": "Extreme difficulty/cannot do",
  "patient_pedia_knee_function_q3": "Kneel on the front of your knee",
  "patient_pedia_knee_function_q3_a1": "No difficulty",
  "patient_pedia_knee_function_q3_a2": "A little",
  "patient_pedia_knee_function_q3_a3": "Some",
  "patient_pedia_knee_function_q3_a4": "A lot",
  "patient_pedia_knee_function_q3_a5": "Extreme difficulty/cannot do",
  "patient_pedia_knee_function_q4": "Fully straighten your knee",
  "patient_pedia_knee_function_q4_a1": "No difficulty",
  "patient_pedia_knee_function_q4_a2": "A little",
  "patient_pedia_knee_function_q4_a3": "Some",
  "patient_pedia_knee_function_q4_a4": "A lot",
  "patient_pedia_knee_function_q4_a5": "Extreme difficulty/cannot do",
  "patient_pedia_knee_function_q5": "Fully bend your knee",
  "patient_pedia_knee_function_q5_a1": "No difficulty",
  "patient_pedia_knee_function_q5_a2": "A little",
  "patient_pedia_knee_function_q5_a3": "Some",
  "patient_pedia_knee_function_q5_a4": "A lot",
  "patient_pedia_knee_function_q5_a5": "Extreme difficulty/cannot do",
  "patient_pedia_knee_function_q6": "Move your knee just after waking up in the morning",
  "patient_pedia_knee_function_q6_a1": "No difficulty",
  "patient_pedia_knee_function_q6_a2": "A little",
  "patient_pedia_knee_function_q6_a3": "Some",
  "patient_pedia_knee_function_q6_a4": "A lot",
  "patient_pedia_knee_function_q6_a5": "Extreme difficulty/cannot do",
  "patient_pedia_knee_function_q7": "Move your knee later in the day after being sedentary for a while",
  "patient_pedia_knee_function_q7_a1": "No difficulty",
  "patient_pedia_knee_function_q7_a2": "A little",
  "patient_pedia_knee_function_q7_a3": "Some",
  "patient_pedia_knee_function_q7_a4": "A lot",
  "patient_pedia_knee_function_q7_a5": "Extreme difficulty/cannot do",
  "patient_pedia_knee_function_q8": "Squat down like a baseball catcher",
  "patient_pedia_knee_function_q8_a1": "No difficulty",
  "patient_pedia_knee_function_q8_a2": "A little",
  "patient_pedia_knee_function_q8_a3": "Some",
  "patient_pedia_knee_function_q8_a4": "A lot",
  "patient_pedia_knee_function_q8_a5": "Extreme difficulty/cannot do",
  "patient_pedia_knee_function_q9": "Sit on a chair with your knee bent and feet flat on the floor",
  "patient_pedia_knee_function_q9_a1": "No difficulty",
  "patient_pedia_knee_function_q9_a2": "A little",
  "patient_pedia_knee_function_q9_a3": "Some",
  "patient_pedia_knee_function_q9_a4": "A lot",
  "patient_pedia_knee_function_q9_a5": "Extreme difficulty/cannot do",
  "patient_pedia_knee_function_q10": "Get up from a chair",
  "patient_pedia_knee_function_q10_a1": "No difficulty",
  "patient_pedia_knee_function_q10_a2": "A little",
  "patient_pedia_knee_function_q10_a3": "Some",
  "patient_pedia_knee_function_q10_a4": "A lot",
  "patient_pedia_knee_function_q10_a5": "Extreme difficulty/cannot do",
  "patient_pedia_knee_function_q11": "Bend down and pick up an object from the floor",
  "patient_pedia_knee_function_q11_a1": "No difficulty",
  "patient_pedia_knee_function_q11_a2": "A little",
  "patient_pedia_knee_function_q11_a3": "Some",
  "patient_pedia_knee_function_q11_a4": "A lot",
  "patient_pedia_knee_function_q11_a5": "Extreme difficulty/cannot do",
  "patient_pedia_knee_function_q12": "Run during play or sports activities",
  "patient_pedia_knee_function_q12_a1": "No difficulty",
  "patient_pedia_knee_function_q12_a2": "A little",
  "patient_pedia_knee_function_q12_a3": "Some",
  "patient_pedia_knee_function_q12_a4": "A lot",
  "patient_pedia_knee_function_q12_a5": "Extreme difficulty/cannot do",
  "patient_pedia_knee_function_q13": "Jump during play or sports activities",
  "patient_pedia_knee_function_q13_a1": "No difficulty",
  "patient_pedia_knee_function_q13_a2": "A little",
  "patient_pedia_knee_function_q13_a3": "Some",
  "patient_pedia_knee_function_q13_a4": "A lot",
  "patient_pedia_knee_function_q13_a5": "Extreme difficulty/cannot do",
  "patient_pedia_knee_function_q14": "Start and stop moving quickly",
  "patient_pedia_knee_function_q14_a1": "No difficulty",
  "patient_pedia_knee_function_q14_a2": "A little",
  "patient_pedia_knee_function_q14_a3": "Some",
  "patient_pedia_knee_function_q14_a4": "A lot",
  "patient_pedia_knee_function_q14_a5": "Extreme difficulty/cannot do",
  "patient_pedia_knee_function_q15": "Twist/pivot during play or sports activities",
  "patient_pedia_knee_function_q15_a1": "No difficulty",
  "patient_pedia_knee_function_q15_a2": "A little",
  "patient_pedia_knee_function_q15_a3": "Some",
  "patient_pedia_knee_function_q15_a4": "A lot",
  "patient_pedia_knee_function_q15_a5": "Extreme difficulty/cannot do",
  "patient_pedia_knee_function_q16": "Keep your balance when walking/running on uneven ground",
  "patient_pedia_knee_function_q16_a1": "No difficulty",
  "patient_pedia_knee_function_q16_a2": "A little",
  "patient_pedia_knee_function_q16_a3": "Some",
  "patient_pedia_knee_function_q16_a4": "A lot",
  "patient_pedia_knee_function_q16_a5": "Extreme difficulty/cannot do",
  "patient_pedia_knee_function_q17": "Play sports",
  "patient_pedia_knee_function_q17_a1": "No difficulty",
  "patient_pedia_knee_function_q17_a2": "A little",
  "patient_pedia_knee_function_q17_a3": "Some",
  "patient_pedia_knee_function_q17_a4": "A lot",
  "patient_pedia_knee_function_q17_a5": "Extreme difficulty/cannot do",
  "patient_pedia_knee_function_q18": "Get in to/out of a car",
  "patient_pedia_knee_function_q18_a1": "No difficulty",
  "patient_pedia_knee_function_q18_a2": "A little",
  "patient_pedia_knee_function_q18_a3": "Some",
  "patient_pedia_knee_function_q18_a4": "A lot",
  "patient_pedia_knee_function_q18_a5": "Extreme difficulty/cannot do",
  "patient_pedia_knee_function_q19": "Get out of bed",
  "patient_pedia_knee_function_q19_a1": "No difficulty",
  "patient_pedia_knee_function_q19_a2": "A little",
  "patient_pedia_knee_function_q19_a3": "Some",
  "patient_pedia_knee_function_q19_a4": "A lot",
  "patient_pedia_knee_function_q19_a5": "Extreme difficulty/cannot do",
  "patient_pedia_knee_function_q20": "Change knee position when lying in bed",
  "patient_pedia_knee_function_q20_a1": "No difficulty",
  "patient_pedia_knee_function_q20_a2": "A little",
  "patient_pedia_knee_function_q20_a3": "Some",
  "patient_pedia_knee_function_q20_a4": "A lot",
  "patient_pedia_knee_function_q20_a5": "Extreme difficulty/cannot do",
  "patient_pedia_knee_function_q21": "Get in to/out of the bathtub/shower",
  "patient_pedia_knee_function_q21_a1": "No difficulty",
  "patient_pedia_knee_function_q21_a2": "A little",
  "patient_pedia_knee_function_q21_a3": "Some",
  "patient_pedia_knee_function_q21_a4": "A lot",
  "patient_pedia_knee_function_q21_a5": "Extreme difficulty/cannot do",
  "patient_pedia_knee_function_q22": "Carry heavy bags/backpacks etc",
  "patient_pedia_knee_function_q22_a1": "No difficulty",
  "patient_pedia_knee_function_q22_a2": "A little",
  "patient_pedia_knee_function_q22_a3": "Some",
  "patient_pedia_knee_function_q22_a4": "A lot",
  "patient_pedia_knee_function_q22_a5": "Extreme difficulty/cannot do",
  "patient_pedia_knee_function_q23": "Do light chores such as cleaning your room, filling/emptying the dishwasher, making your bed, etc",
  "patient_pedia_knee_function_q23_a1": "No difficulty",
  "patient_pedia_knee_function_q23_a2": "A little",
  "patient_pedia_knee_function_q23_a3": "Some",
  "patient_pedia_knee_function_q23_a4": "A lot",
  "patient_pedia_knee_function_q23_a5": "Extreme difficulty/cannot do",
  "patient_pedia_knee_function_q24": "Getting to school or walking around in school (climbing stairs, opening doors, carrying books, participating during recess)",
  "patient_pedia_knee_function_q24_a1": "No difficulty",
  "patient_pedia_knee_function_q24_a2": "A little",
  "patient_pedia_knee_function_q24_a3": "Some",
  "patient_pedia_knee_function_q24_a4": "A lot",
  "patient_pedia_knee_function_q24_a5": "Extreme difficulty/cannot do",
  "patient_pedia_knee_function_q25": "Do things with friends",
  "patient_pedia_knee_function_q25_a1": "No difficulty",
  "patient_pedia_knee_function_q25_a2": "A little",
  "patient_pedia_knee_function_q25_a3": "Some",
  "patient_pedia_knee_function_q25_a4": "A lot",
  "patient_pedia_knee_function_q25_a5": "Extreme difficulty/cannot do",
  "patient_pedia_knee_function_q26": "How well did your knee work BEFORE YOU INJURED it?",
  "patient_pedia_knee_function_q26_instruction": "10 being normal and 0 being the inability to perform any of your usual daily activities including sports",
  "patient_pedia_knee_function_q26_instruction_a1": "I could not do anything at all",
  "patient_pedia_knee_function_q26_instruction_a2": "I could do anything I wanted to",
  "patient_pedia_knee_function_q27": "How well does your knee work NOW?",
  "patient_pedia_knee_function_q27_instruction": "10 being normal and 0 being the inability to perform any of your usual daily activities including sports",
  "patient_pedia_knee_function_q27_instruction_a1": "I am not able to do anything at all",
  "patient_pedia_knee_function_q27_instruction_a2": "I am able to do anything I want to do",
  "patient_pedia_knee_function_q28": "Does your kneecap get out of place or does it feel like it is getting out of place?",
  "patient_pedia_knee_function_q28_a1": "No",
  "patient_pedia_knee_function_q28_a2": "Yes",
  "patient_pedia_knee_instability": "Patellofemoral Instability",
  "patient_pedia_knee_instability_instruction": "What best describes HOW OFTEN YOUR KNEECAP FEELS LIKE IT WILL “POP OUT” OF JOINT OR FEELS UNSTABLE when you are doing each of the following activities",
  "patient_pedia_knee_instability_instruction_0": "Never",
  "patient_pedia_knee_instability_instruction_1": "Rarely",
  "patient_pedia_knee_instability_instruction_2": "Sometimes",
  "patient_pedia_knee_instability_instruction_3": "Often",
  "patient_pedia_knee_instability_instruction_4": "Always",
  "patient_pedia_knee_instability_note": "Note: Please answer the following questions relating to your knee activities",
  "patient_pedia_knee_instability_q1": "Twisting/changing direction during sports/games",
  "patient_pedia_knee_instability_q1_do_not_do": "Do not do",
  "patient_pedia_knee_instability_q1_a1": "Never",
  "patient_pedia_knee_instability_q1_a2": "Rarely",
  "patient_pedia_knee_instability_q1_a3": "Sometimes",
  "patient_pedia_knee_instability_q1_a4": "Often",
  "patient_pedia_knee_instability_q1_a5": "Always",
  "patient_pedia_knee_instability_q2": "Changing direction when running",
  "patient_pedia_knee_instability_q2_do_not_do": "Do not do",
  "patient_pedia_knee_instability_q2_a1": "Never",
  "patient_pedia_knee_instability_q2_a2": "Rarely",
  "patient_pedia_knee_instability_q2_a3": "Sometimes",
  "patient_pedia_knee_instability_q2_a4": "Often",
  "patient_pedia_knee_instability_q2_a5": "Always",
  "patient_pedia_knee_instability_q3": "Running in a straight line on UNEVEN surfaces",
  "patient_pedia_knee_instability_q3_do_not_do": "Do not do",
  "patient_pedia_knee_instability_q3_a1": "Never",
  "patient_pedia_knee_instability_q3_a2": "Rarely",
  "patient_pedia_knee_instability_q3_a3": "Sometimes",
  "patient_pedia_knee_instability_q3_a4": "Often",
  "patient_pedia_knee_instability_q3_a5": "Always",
  "patient_pedia_knee_instability_q4": "Walking on slippery, wet, or icy surfaces",
  "patient_pedia_knee_instability_q4_do_not_do": "Do not do",
  "patient_pedia_knee_instability_q4_a1": "Never",
  "patient_pedia_knee_instability_q4_a2": "Rarely",
  "patient_pedia_knee_instability_q4_a3": "Sometimes",
  "patient_pedia_knee_instability_q4_a4": "Often",
  "patient_pedia_knee_instability_q4_a5": "Always",
  "patient_pedia_knee_instability_q5": "Running sideways",
  "patient_pedia_knee_instability_q5_do_not_do": "Do not do",
  "patient_pedia_knee_instability_q5_a1": "Never",
  "patient_pedia_knee_instability_q5_a2": "Rarely",
  "patient_pedia_knee_instability_q5_a3": "Sometimes",
  "patient_pedia_knee_instability_q5_a4": "Often",
  "patient_pedia_knee_instability_q5_a5": "Always",
  "patient_pedia_knee_instability_q6": "Hopping",
  "patient_pedia_knee_instability_q6_do_not_do": "Do not do",
  "patient_pedia_knee_instability_q6_a1": "Never",
  "patient_pedia_knee_instability_q6_a2": "Rarely",
  "patient_pedia_knee_instability_q6_a3": "Sometimes",
  "patient_pedia_knee_instability_q6_a4": "Often",
  "patient_pedia_knee_instability_q6_a5": "Always",
  "patient_pedia_knee_instability_q7": "Jumping",
  "patient_pedia_knee_instability_q7_do_not_do": "Do not do",
  "patient_pedia_knee_instability_q7_a1": "Never",
  "patient_pedia_knee_instability_q7_a2": "Rarely",
  "patient_pedia_knee_instability_q7_a3": "Sometimes",
  "patient_pedia_knee_instability_q7_a4": "Often",
  "patient_pedia_knee_instability_q7_a5": "Always",
  "patient_pedia_knee_instability_q8": "Running in a straight line on EVEN surfaces",
  "patient_pedia_knee_instability_q8_do_not_do": "Do not do",
  "patient_pedia_knee_instability_q8_a1": "Never",
  "patient_pedia_knee_instability_q8_a2": "Rarely",
  "patient_pedia_knee_instability_q8_a3": "Sometimes",
  "patient_pedia_knee_instability_q8_a4": "Often",
  "patient_pedia_knee_instability_q8_a5": "Always",
  "patient_pedia_knee_instability_q9": "Going down stairs",
  "patient_pedia_knee_instability_q9_do_not_do": "Do not do",
  "patient_pedia_knee_instability_q9_a1": "Never",
  "patient_pedia_knee_instability_q9_a2": "Rarely",
  "patient_pedia_knee_instability_q9_a3": "Sometimes",
  "patient_pedia_knee_instability_q9_a4": "Often",
  "patient_pedia_knee_instability_q9_a5": "Always",
  "patient_pedia_knee_instability_q10": "Squatting",
  "patient_pedia_knee_instability_q10_do_not_do": "Do not do",
  "patient_pedia_knee_instability_q10_a1": "Never",
  "patient_pedia_knee_instability_q10_a2": "Rarely",
  "patient_pedia_knee_instability_q10_a3": "Sometimes",
  "patient_pedia_knee_instability_q10_a4": "Often",
  "patient_pedia_knee_instability_q10_a5": "Always",
  "patient_pedia_knee_instability_q11": "Kneeling",
  "patient_pedia_knee_instability_q11_do_not_do": "Do not do",
  "patient_pedia_knee_instability_q11_a1": "Never",
  "patient_pedia_knee_instability_q11_a2": "Rarely",
  "patient_pedia_knee_instability_q11_a3": "Sometimes",
  "patient_pedia_knee_instability_q11_a4": "Often",
  "patient_pedia_knee_instability_q11_a5": "Always",
  "patient_pedia_knee_instability_q12": "Walking in a straight line on UNEVEN surfaces",
  "patient_pedia_knee_instability_q12_do_not_do": "Do not do",
  "patient_pedia_knee_instability_q12_a1": "Never",
  "patient_pedia_knee_instability_q12_a2": "Rarely",
  "patient_pedia_knee_instability_q12_a3": "Sometimes",
  "patient_pedia_knee_instability_q12_a4": "Often",
  "patient_pedia_knee_instability_q12_a5": "Always",
  "patient_pedia_knee_instability_q13": "Climbing stairs",
  "patient_pedia_knee_instability_q13_do_not_do": "Do not do",
  "patient_pedia_knee_instability_q13_a1": "Never",
  "patient_pedia_knee_instability_q13_a2": "Rarely",
  "patient_pedia_knee_instability_q13_a3": "Sometimes",
  "patient_pedia_knee_instability_q13_a4": "Often",
  "patient_pedia_knee_instability_q13_a5": "Always",
  "patient_pedia_knee_instability_q14": "Stepping onto or over a high step",
  "patient_pedia_knee_instability_q14_do_not_do": "Do not do",
  "patient_pedia_knee_instability_q14_a1": "Never",
  "patient_pedia_knee_instability_q14_a2": "Rarely",
  "patient_pedia_knee_instability_q14_a3": "Sometimes",
  "patient_pedia_knee_instability_q14_a4": "Often",
  "patient_pedia_knee_instability_q14_a5": "Always",
  "patient_pedia_knee_instability_q15": "Crossing your legs when sitting",
  "patient_pedia_knee_instability_q15_do_not_do": "Do not do",
  "patient_pedia_knee_instability_q15_a1": "Never",
  "patient_pedia_knee_instability_q15_a2": "Rarely",
  "patient_pedia_knee_instability_q15_a3": "Sometimes",
  "patient_pedia_knee_instability_q15_a4": "Often",
  "patient_pedia_knee_instability_q15_a5": "Always",
  "patient_pedia_knee_instability_q16": "Walking in a straight line on EVEN surfaces",
  "patient_pedia_knee_instability_q16_do_not_do": "Do not do",
  "patient_pedia_knee_instability_q16_a1": "Never",
  "patient_pedia_knee_instability_q16_a2": "Rarely",
  "patient_pedia_knee_instability_q16_a3": "Sometimes",
  "patient_pedia_knee_instability_q16_a4": "Often",
  "patient_pedia_knee_instability_q16_a5": "Always",
  "patient_pedia_knee_instability_q17": "Getting into or out of a car",
  "patient_pedia_knee_instability_q17_do_not_do": "Do not do",
  "patient_pedia_knee_instability_q17_a1": "Never",
  "patient_pedia_knee_instability_q17_a2": "Rarely",
  "patient_pedia_knee_instability_q17_a3": "Sometimes",
  "patient_pedia_knee_instability_q17_a4": "Often",
  "patient_pedia_knee_instability_q17_a5": "Always",
  "patient_pedia_knee_instability_q18": "Turning a heavy trolley round a supermarket aisle",
  "patient_pedia_knee_instability_q18_do_not_do": "Do not do",
  "patient_pedia_knee_instability_q18_a1": "Never",
  "patient_pedia_knee_instability_q18_a2": "Rarely",
  "patient_pedia_knee_instability_q18_a3": "Sometimes",
  "patient_pedia_knee_instability_q18_a4": "Often",
  "patient_pedia_knee_instability_q18_a5": "Always",
  "patient_pedia_knee_instability_q19": "Turning to look over your shoulder",
  "patient_pedia_knee_instability_q19_do_not_do": "Do not do",
  "patient_pedia_knee_instability_q19_a1": "Never",
  "patient_pedia_knee_instability_q19_a2": "Rarely",
  "patient_pedia_knee_instability_q19_a3": "Sometimes",
  "patient_pedia_knee_instability_q19_a4": "Often",
  "patient_pedia_knee_instability_q19_a5": "Always",
  "patient_pedia_knee_quality_of_life": "Quality of Life",
  "patient_pedia_knee_quality_of_life_instruction": "How has your injury affected your life?",
  "patient_pedia_knee_quality_of_life_q1": "How often do you think about your knee problem?",
  "patient_pedia_knee_quality_of_life_q1_a1": "Never",
  "patient_pedia_knee_quality_of_life_q1_a2": "Rarely",
  "patient_pedia_knee_quality_of_life_q1_a3": "Sometimes",
  "patient_pedia_knee_quality_of_life_q1_a4": "Often",
  "patient_pedia_knee_quality_of_life_q1_a5": "All the time",
  "patient_pedia_knee_problem_q2": "How much have you changed your lifestyle because of your injured knee?",
  "patient_pedia_knee_problem_q2_a1": "Not at all",
  "patient_pedia_knee_problem_q2_a2": "A little",
  "patient_pedia_knee_problem_q2_a3": "Some",
  "patient_pedia_knee_problem_q2_a4": "A lot",
  "patient_pedia_knee_problem_q2_a5": "Very much",
  "patient_pedia_knee_problem_q3": "How much do you trust your injured knee?",
  "patient_pedia_knee_problem_q3_a1": "Completely",
  "patient_pedia_knee_problem_q3_a2": "A lot",
  "patient_pedia_knee_problem_q3_a3": "Some",
  "patient_pedia_knee_problem_q3_a4": "A little",
  "patient_pedia_knee_problem_q3_a5": "Not at all",
  "patient_pedia_knee_problem_q4": "Overall, how much difficulty do you have with your injured knee?",
  "patient_pedia_knee_problem_q4_a1": "No difficulty",
  "patient_pedia_knee_problem_q4_a2": "A little",
  "patient_pedia_knee_problem_q4_a3": "Some",
  "patient_pedia_knee_problem_q4_a4": "A lot",
  "patient_pedia_knee_problem_q4_a5": "Extreme difficulty",
  "patient_pedia_knee_satisfaction": "Satisfaction",
  "patient_pedia_knee_satisfaction_instruction": "How satisfied are you with the results of your knee surgery/treatment?",
  "patient_pedia_knee_satisfaction_instruction_a1": "Very dissatisfied",
  "patient_pedia_knee_satisfaction_instruction_a2": "Somewhat dissatisfied",
  "patient_pedia_knee_satisfaction_instruction_a3": "Somewhat satisfied",
  "patient_pedia_knee_satisfaction_instruction_a4": "Very satisfied",
  "patient_pedia_knee_satisfaction_q1": "Overall",
  "patient_pedia_knee_satisfaction_q1_NA": "Not applicable",
  "patient_pedia_knee_satisfaction_q1_a1": "Very dissatisfied",
  "patient_pedia_knee_satisfaction_q1_a2": "Somewhat dissatisfied",
  "patient_pedia_knee_satisfaction_q1_a3": "Somewhat satisfied",
  "patient_pedia_knee_satisfaction_q1_a4": "Very satisfied",
  "patient_pedia_knee_satisfaction_q2": "For improving your pain?",
  "patient_pedia_knee_satisfaction_q2_NA": "Not applicable",
  "patient_pedia_knee_satisfaction_q2_a1": "Very dissatisfied",
  "patient_pedia_knee_satisfaction_q2_a2": "Somewhat dissatisfied",
  "patient_pedia_knee_satisfaction_q2_a3": "Somewhat satisfied",
  "patient_pedia_knee_satisfaction_q2_a4": "Very satisfied",
  "patient_pedia_knee_satisfaction_q3": "For improving your ability to do home or yard work?",
  "patient_pedia_knee_satisfaction_q3_NA": "Not applicable",
  "patient_pedia_knee_satisfaction_q3_a1": "Very dissatisfied",
  "patient_pedia_knee_satisfaction_q3_a2": "Somewhat dissatisfied",
  "patient_pedia_knee_satisfaction_q3_a3": "Somewhat satisfied",
  "patient_pedia_knee_satisfaction_q3_a4": "Very satisfied",
  "patient_pedia_knee_satisfaction_q4": "For improving your ability to do recreational activities?",
  "patient_pedia_knee_satisfaction_q4_NA": "Not applicable",
  "patient_pedia_knee_satisfaction_q4_a1": "Very dissatisfied",
  "patient_pedia_knee_satisfaction_q4_a2": "Somewhat dissatisfied",
  "patient_pedia_knee_satisfaction_q4_a3": "Somewhat satisfied",
  "patient_pedia_knee_satisfaction_q4_a4": "Very satisfied",
  "patient_pedia_knee_complete": "End of Questionnaire",
  "patient_pedia_knee_complete_text1": "Thank you for taking the time to fill out this form.",
  "patient_pedia_knee_complete_text2": "Please click submit to complete and see your scores.",
  "patient_pedia_knee_complete_text3": "Your answers will be treated confidentially and any reports will only use de-identified information.",
  "patient_pedia_knee_results": "Results",
  "patient_pedia_knee_results_text1": "Below are your pain and knee function scores, which are calculated from your questionnaire.",
  "patient_pedia_knee_results_pain": "Pain",
  "patient_pedia_knee_results_function": "Function",
  "patient_pedia_knee_results_self_evaluation": "Knee Scoring Self-Evaluation",
  "patient_pedia_knee_results_text2": "Your Doctor will be with you shortly.",
  "patient_foot_ankle_pain": "Pain",
  "patient_foot_ankle_pain_q1": "What is the CURRENT LEVEL OF PAIN from your foot/ankle?",
  "patient_foot_ankle_pain_q1_0": "None",
  "patient_foot_ankle_pain_q1_1": "Worst pain imaginable",
  "patient_foot_ankle_pain_q2": "What is the best description of PAIN from your foot/ankle?",
  "patient_foot_ankle_pain_q2_a1": "None",
  "patient_foot_ankle_pain_q2_a2": "Mild, occasional",
  "patient_foot_ankle_pain_q2_a3": "Moderate, daily",
  "patient_foot_ankle_pain_q2_a4": "Severe, almost always present",
  "patient_foot_ankle_pain_q3": "How OFTEN do you experience foot/ankle pain?",
  "patient_foot_ankle_pain_q3_a1": "Never",
  "patient_foot_ankle_pain_q3_a2": "Monthly",
  "patient_foot_ankle_pain_q3_a3": "Weekly",
  "patient_foot_ankle_pain_q3_a4": "Daily",
  "patient_foot_ankle_pain_q3_a5": "Always",
  "patient_foot_ankle_pain_instruction": "What amount of FOOT/ANKLE PAIN have you experienced during the following activities:",
  "patient_foot_ankle_pain_instruction_0": "None",
  "patient_foot_ankle_pain_instruction_1": "Mild",
  "patient_foot_ankle_pain_instruction_2": "Moderate",
  "patient_foot_ankle_pain_instruction_3": "Severe",
  "patient_foot_ankle_pain_instruction_4": "Extreme",
  "patient_foot_ankle_pain_q4": "General level of pain",
  "patient_foot_ankle_pain_q4_a1": "None",
  "patient_foot_ankle_pain_q4_a2": "Mild",
  "patient_foot_ankle_pain_q4_a3": "Moderate",
  "patient_foot_ankle_pain_q4_a4": "Severe",
  "patient_foot_ankle_pain_q4_a5": "Extreme",
  "patient_foot_ankle_pain_q5": "At rest",
  "patient_foot_ankle_pain_q5_a1": "None",
  "patient_foot_ankle_pain_q5_a2": "Mild",
  "patient_foot_ankle_pain_q5_a3": "Moderate",
  "patient_foot_ankle_pain_q5_a4": "Severe",
  "patient_foot_ankle_pain_q5_a5": "Extreme",
  "patient_foot_ankle_pain_q6": "During normal activity",
  "patient_foot_ankle_pain_q6_a1": "None",
  "patient_foot_ankle_pain_q6_a2": "Mild",
  "patient_foot_ankle_pain_q6_a3": "Moderate",
  "patient_foot_ankle_pain_q6_a4": "Severe",
  "patient_foot_ankle_pain_q6_a5": "Extreme",
  "patient_foot_ankle_pain_q7": "First thing in the morning",
  "patient_foot_ankle_pain_q7_a1": "None",
  "patient_foot_ankle_pain_q7_a2": "Mild",
  "patient_foot_ankle_pain_q7_a3": "Moderate",
  "patient_foot_ankle_pain_q7_a4": "Severe",
  "patient_foot_ankle_pain_q7_a5": "Extreme",
  "patient_foot_ankle_pain_q8": "Twisting/pivoting on your foot/ankle",
  "patient_foot_ankle_pain_q8_a1": "None",
  "patient_foot_ankle_pain_q8_a2": "Mild",
  "patient_foot_ankle_pain_q8_a3": "Moderate",
  "patient_foot_ankle_pain_q8_a4": "Severe",
  "patient_foot_ankle_pain_q8_a5": "Extreme",
  "patient_foot_ankle_pain_q9": "Straightening foot/ankle fully",
  "patient_foot_ankle_pain_q9_a1": "None",
  "patient_foot_ankle_pain_q9_a2": "Mild",
  "patient_foot_ankle_pain_q9_a3": "Moderate",
  "patient_foot_ankle_pain_q9_a4": "Severe",
  "patient_foot_ankle_pain_q9_a5": "Extreme",
  "patient_foot_ankle_pain_q10": "Bending foot/ankle fully",
  "patient_foot_ankle_pain_q10_a1": "None",
  "patient_foot_ankle_pain_q10_a2": "Mild",
  "patient_foot_ankle_pain_q10_a3": "Moderate",
  "patient_foot_ankle_pain_q10_a4": "Severe",
  "patient_foot_ankle_pain_q10_a5": "Extreme",
  "patient_foot_ankle_pain_q11": "Walking on flat surface",
  "patient_foot_ankle_pain_q11_a1": "None",
  "patient_foot_ankle_pain_q11_a2": "Mild",
  "patient_foot_ankle_pain_q11_a3": "Moderate",
  "patient_foot_ankle_pain_q11_a4": "Severe",
  "patient_foot_ankle_pain_q11_a5": "Extreme",
  "patient_foot_ankle_pain_q12": "Going up or down stairs",
  "patient_foot_ankle_pain_q12_a1": "None",
  "patient_foot_ankle_pain_q12_a2": "Mild",
  "patient_foot_ankle_pain_q12_a3": "Moderate",
  "patient_foot_ankle_pain_q12_a4": "Severe",
  "patient_foot_ankle_pain_q12_a5": "Extreme",
  "patient_foot_ankle_pain_q13": "At night while in bed",
  "patient_foot_ankle_pain_q13_a1": "None",
  "patient_foot_ankle_pain_q13_a2": "Mild",
  "patient_foot_ankle_pain_q13_a3": "Moderate",
  "patient_foot_ankle_pain_q13_a4": "Severe",
  "patient_foot_ankle_pain_q13_a5": "Extreme",
  "patient_foot_ankle_pain_q14": "Sitting or lying",
  "patient_foot_ankle_pain_q14_a1": "None",
  "patient_foot_ankle_pain_q14_a2": "Mild",
  "patient_foot_ankle_pain_q14_a3": "Moderate",
  "patient_foot_ankle_pain_q14_a4": "Severe",
  "patient_foot_ankle_pain_q14_a5": "Extreme",
  "patient_foot_ankle_pain_q15": "Standing upright",
  "patient_foot_ankle_pain_q15_a1": "None",
  "patient_foot_ankle_pain_q15_a2": "Mild",
  "patient_foot_ankle_pain_q15_a3": "Moderate",
  "patient_foot_ankle_pain_q15_a4": "Severe",
  "patient_foot_ankle_pain_q15_a5": "Extreme",
  "patient_foot_ankle_instability": "Instability",
  "patient_foot_ankle_instability_q1": "Do you experience repeated sprains, giving way of your ankle, or feel like it is going to give way?",
  "patient_foot_ankle_instability_q1_a1": "No",
  "patient_foot_ankle_instability_q1_a2": "Yes",
  "patient_foot_ankle_instability_q2": "Have you ever been diagnosed with/treated for ankle instability/recurrent sprains?",
  "patient_foot_ankle_instability_q2_a1": "No",
  "patient_foot_ankle_instability_q2_a2": "Yes",
  "patient_foot_ankle_instability_q3": "Does your unaffected ankle feel completely stable?",
  "patient_foot_ankle_instability_q3_a1": "No",
  "patient_foot_ankle_instability_q3_a2": "Yes",
  "patient_foot_ankle_cumberland": "CUMBERLAND ANKLE INSTABILITY TOOL",
  "patient_foot_ankle_cumberland_instruction": "Please choose one statement in each question that best describes your ankle.",
  "patient_foot_ankle_cumberland_q1": "I have pain in my ankle",
  "patient_foot_ankle_cumberland_q1_a1": "Never",
  "patient_foot_ankle_cumberland_q1_a2": "During sport",
  "patient_foot_ankle_cumberland_q1_a3": "Running on uneven surfaces",
  "patient_foot_ankle_cumberland_q1_a4": "Running on level surfaces",
  "patient_foot_ankle_cumberland_q1_a5": "Walking on uneven surfaces",
  "patient_foot_ankle_cumberland_q1_a6": "Walking on level surfaces",
  "patient_foot_ankle_cumberland_q2": "My ankle feels UNSTABLE",
  "patient_foot_ankle_cumberland_q2_a1": "Never",
  "patient_foot_ankle_cumberland_q2_a2": "Sometimes during sport (not every time)",
  "patient_foot_ankle_cumberland_q2_a3": "Frequently during sport (every time)",
  "patient_foot_ankle_cumberland_q2_a4": "Sometimes during daily activity",
  "patient_foot_ankle_cumberland_q2_a5": "Frequently during daily activity",
  "patient_foot_ankle_cumberland_q3": "When I make SHARP turns, my ankle feels UNSTABLE",
  "patient_foot_ankle_cumberland_q3_a1": "Never",
  "patient_foot_ankle_cumberland_q3_a2": "Sometimes when running",
  "patient_foot_ankle_cumberland_q3_a3": "Often when running",
  "patient_foot_ankle_cumberland_q3_a4": "When walking",
  "patient_foot_ankle_cumberland_q4": "When going down the stairs, my ankle feels UNSTABLE",
  "patient_foot_ankle_cumberland_q4_a1": "Never",
  "patient_foot_ankle_cumberland_q4_a2": "If I go fast",
  "patient_foot_ankle_cumberland_q4_a3": "Occasionally",
  "patient_foot_ankle_cumberland_q4_a4": "Always",
  "patient_foot_ankle_cumberland_q5": "My ankle feels UNSTABLE when standing on ONE leg",
  "patient_foot_ankle_cumberland_q5_a1": "Never",
  "patient_foot_ankle_cumberland_q5_a2": "On the ball of the foot",
  "patient_foot_ankle_cumberland_q5_a3": "With my foot flat",
  "patient_foot_ankle_cumberland_q6": "My ankle feels UNSTABLE when",
  "patient_foot_ankle_cumberland_q6_a1": "Never",
  "patient_foot_ankle_cumberland_q6_a2": "I hop from side to side",
  "patient_foot_ankle_cumberland_q6_a3": "I hop on the spot",
  "patient_foot_ankle_cumberland_q6_a4": "I jump",
  "patient_foot_ankle_cumberland_q7": "My ankle feels UNSTABLE when",
  "patient_foot_ankle_cumberland_q7_a1": "Never",
  "patient_foot_ankle_cumberland_q7_a2": "I run on uneven surfaces",
  "patient_foot_ankle_cumberland_q7_a3": "I jog on uneven surfaces",
  "patient_foot_ankle_cumberland_q7_a4": "I walk on uneven surfaces",
  "patient_foot_ankle_cumberland_q7_a5": "I walk on flat surfaces",
  "patient_foot_ankle_cumberland_q8": "TYPICALLY, when I start to roll over (or “twist”) on my ankle, I can stop it",
  "patient_foot_ankle_cumberland_q8_a1": "Immediately",
  "patient_foot_ankle_cumberland_q8_a2": "Often",
  "patient_foot_ankle_cumberland_q8_a3": "Sometimes",
  "patient_foot_ankle_cumberland_q8_a4": "Never",
  "patient_foot_ankle_cumberland_q8_a5": "I have never rolled over on my ankle",
  "patient_foot_ankle_cumberland_q9": "After a TYPICAL incident of my ankle rolling over, my ankle returns to “normal”",
  "patient_foot_ankle_cumberland_q9_a1": "Almost immediately",
  "patient_foot_ankle_cumberland_q9_a2": "Less than one day",
  "patient_foot_ankle_cumberland_q9_a3": "1-2 days",
  "patient_foot_ankle_cumberland_q9_a4": "More than 2 days",
  "patient_foot_ankle_cumberland_q9_a5": "I have never rolled over on my ankle",
  "patient_foot_ankle_other_symptoms": "Other Symptoms",
  "patient_foot_ankle_other_symptoms_instruction1": "Please choose the answer that best corresponds to your foot/ankle symptoms.",
  "patient_foot_ankle_other_symptoms_instruction2": "If you are unsure about how to answer a question, please give the best answer you can.",
  "patient_foot_ankle_other_symptoms_note": "Note: These questions should be answered thinking of your foot/ankle symptoms during the last week.",
  "patient_foot_ankle_other_symptoms_instruction3": "Do you have the following:",
  "patient_foot_ankle_other_symptoms_instruction3_0": "Never",
  "patient_foot_ankle_other_symptoms_instruction3_1": "Rarely",
  "patient_foot_ankle_other_symptoms_instruction3_2": "Sometimes",
  "patient_foot_ankle_other_symptoms_instruction3_3": "Often",
  "patient_foot_ankle_other_symptoms_instruction3_4": "Always",
  "patient_foot_ankle_other_symptoms_q1": "Swelling in your foot/ankle",
  "patient_foot_ankle_other_symptoms_q1_a1": "Never",
  "patient_foot_ankle_other_symptoms_q1_a2": "Rarely",
  "patient_foot_ankle_other_symptoms_q1_a3": "Sometimes",
  "patient_foot_ankle_other_symptoms_q1_a4": "Often",
  "patient_foot_ankle_other_symptoms_q1_a5": "Always",
  "patient_foot_ankle_other_symptoms_q2": "Grinding, clicking, or hear any type of noise when you foot/ankle moves",
  "patient_foot_ankle_other_symptoms_q2_a1": "Never",
  "patient_foot_ankle_other_symptoms_q2_a2": "Rarely",
  "patient_foot_ankle_other_symptoms_q2_a3": "Sometimes",
  "patient_foot_ankle_other_symptoms_q2_a4": "Often",
  "patient_foot_ankle_other_symptoms_q2_a5": "Always",
  "patient_foot_ankle_other_symptoms_q3": "Catching or hanging up when moving",
  "patient_foot_ankle_other_symptoms_q3_a1": "Never",
  "patient_foot_ankle_other_symptoms_q3_a2": "Rarely",
  "patient_foot_ankle_other_symptoms_q3_a3": "Sometimes",
  "patient_foot_ankle_other_symptoms_q3_a4": "Often",
  "patient_foot_ankle_other_symptoms_q3_a5": "Always",
  "patient_foot_ankle_other_symptoms_instruction4": "How severe is your foot/ankle STIFFNESS:",
  "patient_foot_ankle_other_symptoms_instruction4_0": "None",
  "patient_foot_ankle_other_symptoms_instruction4_1": "Mild",
  "patient_foot_ankle_other_symptoms_instruction4_2": "Moderate",
  "patient_foot_ankle_other_symptoms_instruction4_3": "Severe",
  "patient_foot_ankle_other_symptoms_instruction4_4": "Extreme",
  "patient_foot_ankle_other_symptoms_q4": "After first wakening in the morning",
  "patient_foot_ankle_other_symptoms_q4_a1": "None",
  "patient_foot_ankle_other_symptoms_q4_a2": "Mild",
  "patient_foot_ankle_other_symptoms_q4_a3": "Moderate",
  "patient_foot_ankle_other_symptoms_q4_a4": "Severe",
  "patient_foot_ankle_other_symptoms_q4_a5": "Extreme",
  "patient_foot_ankle_other_symptoms_q5": "After sitting, lying, or resting later in the day",
  "patient_foot_ankle_other_symptoms_q5_a1": "None",
  "patient_foot_ankle_other_symptoms_q5_a2": "Mild",
  "patient_foot_ankle_other_symptoms_q5_a3": "Moderate",
  "patient_foot_ankle_other_symptoms_q5_a4": "Severe",
  "patient_foot_ankle_other_symptoms_q5_a5": "Extreme",
  "patient_foot_ankle_function": "Function",
  "patient_foot_ankle_function_q1": "What limitations do you have to activity in your daily life due to your foot/ankle?",
  "patient_foot_ankle_function_q1_a1": "No limitations, no support",
  "patient_foot_ankle_function_q1_a2": "No limitation of daily activities, limitation of recreational activities, no support",
  "patient_foot_ankle_function_q1_a3": "Limited daily and recreational activities,cane",
  "patient_foot_ankle_function_q1_a4": "Severe limitation of daily and recreational activities, walker, crutches, wheelchair, brace",
  "patient_foot_ankle_function_q2": "What footwear do you usually wear?",
  "patient_foot_ankle_function_q2_a1": "Fashionable, conventional shoes, no insert required",
  "patient_foot_ankle_function_q2_a2": "Comfort footwear, shoe insert",
  "patient_foot_ankle_function_q2_a3": "Modified shoes or brace",
  "patient_foot_ankle_function_q3": "What kind of walking surface gives you difficulty walking?",
  "patient_foot_ankle_function_q3_a1": "No difficulty on any surface",
  "patient_foot_ankle_function_q3_a2": "Some difficulty on uneven terrain, stairs, inclines, ladders",
  "patient_foot_ankle_function_q3_a3": "Severe difficulty on uneven terrain, stairs, inclines, ladders",
  "patient_foot_ankle_function_instruction": "Indicate the DEGREE OF DIFFICULTY you have experienced in the last week DUE TO YOUR FOOT/ANKLE",
  "patient_foot_ankle_function_instruction_0": "No difficulty",
  "patient_foot_ankle_function_instruction_1": "Slight difficulty",
  "patient_foot_ankle_function_instruction_2": "Moderate difficulty",
  "patient_foot_ankle_function_instruction_3": "Extreme difficulty",
  "patient_foot_ankle_function_instruction_4": "Unable to do",
  "patient_foot_ankle_function_note": "Note: If the activity in question is limited by something other than your foot or ankle, mark 'Not Applicable' in items where this is an option. Otherwise, please answer every question with one response that most closely describes your condition within the past week.",
  "patient_foot_ankle_function_q4": "Straightening your foot/ankle fully",
  "patient_foot_ankle_function_q4_a1": "No difficulty",
  "patient_foot_ankle_function_q4_a2": "Slight difficulty",
  "patient_foot_ankle_function_q4_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q4_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q4_a5": "Unable to do",
  "patient_foot_ankle_function_q5": "Bending your foot/ankle fully",
  "patient_foot_ankle_function_q5_a1": "No difficulty",
  "patient_foot_ankle_function_q5_a2": "Slight difficulty",
  "patient_foot_ankle_function_q5_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q5_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q5_a5": "Unable to do",
  "patient_foot_ankle_function_q6": "Descending stairs",
  "patient_foot_ankle_function_q6_a1": "No difficulty",
  "patient_foot_ankle_function_q6_a2": "Slight difficulty",
  "patient_foot_ankle_function_q6_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q6_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q6_a5": "Unable to do",
  "patient_foot_ankle_function_q7": "Ascending stairs",
  "patient_foot_ankle_function_q7_a1": "No difficulty",
  "patient_foot_ankle_function_q7_a2": "Slight difficulty",
  "patient_foot_ankle_function_q7_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q7_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q7_a5": "Unable to do",
  "patient_foot_ankle_function_q8": "Rising from sitting",
  "patient_foot_ankle_function_q8_a1": "No difficulty",
  "patient_foot_ankle_function_q8_a2": "Slight difficulty",
  "patient_foot_ankle_function_q8_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q8_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q8_a5": "Unable to do",
  "patient_foot_ankle_function_q9": "Standing",
  "patient_foot_ankle_function_q9_a1": "No difficulty",
  "patient_foot_ankle_function_q9_a2": "Slight difficulty",
  "patient_foot_ankle_function_q9_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q9_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q9_a5": "Unable to do",
  "patient_foot_ankle_function_q10": "Bending to floor/pick up an object",
  "patient_foot_ankle_function_q10_a1": "No difficulty",
  "patient_foot_ankle_function_q10_a2": "Slight difficulty",
  "patient_foot_ankle_function_q10_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q10_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q10_a5": "Unable to do",
  "patient_foot_ankle_function_q11": "Walking on flat surface",
  "patient_foot_ankle_function_q11_a1": "No difficulty",
  "patient_foot_ankle_function_q11_a2": "Slight difficulty",
  "patient_foot_ankle_function_q11_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q11_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q11_a5": "Unable to do",
  "patient_foot_ankle_function_q12": "What is the maximum distance that you can walk?",
  "patient_foot_ankle_function_q12_a1": "Greater than 6 blocks",
  "patient_foot_ankle_function_q12_a2": "4-6 blocks",
  "patient_foot_ankle_function_q12_a3": "1-3 blocks",
  "patient_foot_ankle_function_q12_a4": "Less than 1 block",
  "patient_foot_ankle_function_q13": "Walking on even ground without shoes",
  "patient_foot_ankle_function_q13_a1": "No difficulty",
  "patient_foot_ankle_function_q13_a2": "Slight difficulty",
  "patient_foot_ankle_function_q13_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q13_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q13_a5": "Unable to do",
  "patient_foot_ankle_function_q14": "Walking up hills",
  "patient_foot_ankle_function_q14_a1": "No difficulty",
  "patient_foot_ankle_function_q14_a2": "Slight difficulty",
  "patient_foot_ankle_function_q14_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q14_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q14_a5": "Unable to do",
  "patient_foot_ankle_function_q15": "Walking down hills",
  "patient_foot_ankle_function_q15_a1": "No difficulty",
  "patient_foot_ankle_function_q15_a2": "Slight difficulty",
  "patient_foot_ankle_function_q15_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q15_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q15_a5": "Unable to do",
  "patient_foot_ankle_function_q16": "Walking on uneven ground",
  "patient_foot_ankle_function_q16_a1": "No difficulty",
  "patient_foot_ankle_function_q16_a2": "Slight difficulty",
  "patient_foot_ankle_function_q16_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q16_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q16_a5": "Unable to do",
  "patient_foot_ankle_function_q17": "Stepping up and down curbs",
  "patient_foot_ankle_function_q17_a1": "No difficulty",
  "patient_foot_ankle_function_q17_a2": "Slight difficulty",
  "patient_foot_ankle_function_q17_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q17_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q17_a5": "Unable to do",
  "patient_foot_ankle_function_q18": "Coming up to your toes",
  "patient_foot_ankle_function_q18_a1": "No difficulty",
  "patient_foot_ankle_function_q18_a2": "Slight difficulty",
  "patient_foot_ankle_function_q18_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q18_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q18_a5": "Unable to do",
  "patient_foot_ankle_function_q19": "Walking initially",
  "patient_foot_ankle_function_q19_a1": "No difficulty",
  "patient_foot_ankle_function_q19_a2": "Slight difficulty",
  "patient_foot_ankle_function_q19_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q19_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q19_a5": "Unable to do",
  "patient_foot_ankle_function_q20": "Walking 5 minutes or less",
  "patient_foot_ankle_function_q20_a1": "No difficulty",
  "patient_foot_ankle_function_q20_a2": "Slight difficulty",
  "patient_foot_ankle_function_q20_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q20_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q20_a5": "Unable to do",
  "patient_foot_ankle_function_q21": "Walking approximately 10 minutes",
  "patient_foot_ankle_function_q21_a1": "No difficulty",
  "patient_foot_ankle_function_q21_a2": "Slight difficulty",
  "patient_foot_ankle_function_q21_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q21_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q21_a5": "Unable to do",
  "patient_foot_ankle_function_q22": "Walking 15 minutes or greater",
  "patient_foot_ankle_function_q22_a1": "No difficulty",
  "patient_foot_ankle_function_q22_a2": "Slight difficulty",
  "patient_foot_ankle_function_q22_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q22_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q22_a5": "Unable to do",
  "patient_foot_ankle_function_q23": "Getting in/out of car",
  "patient_foot_ankle_function_q23_a1": "No difficulty",
  "patient_foot_ankle_function_q23_a2": "Slight difficulty",
  "patient_foot_ankle_function_q23_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q23_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q23_a5": "Unable to do",
  "patient_foot_ankle_function_q24": "Going shopping",
  "patient_foot_ankle_function_q24_a1": "No difficulty",
  "patient_foot_ankle_function_q24_a2": "Slight difficulty",
  "patient_foot_ankle_function_q24_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q24_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q24_a5": "Unable to do",
  "patient_foot_ankle_function_q25": "Putting on socks/stockings",
  "patient_foot_ankle_function_q25_a1": "No difficulty",
  "patient_foot_ankle_function_q25_a2": "Slight difficulty",
  "patient_foot_ankle_function_q25_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q25_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q25_a5": "Unable to do",
  "patient_foot_ankle_function_q26": "Taking off socks/stockings",
  "patient_foot_ankle_function_q26_a1": "No difficulty",
  "patient_foot_ankle_function_q26_a2": "Slight difficulty",
  "patient_foot_ankle_function_q26_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q26_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q26_a5": "Unable to do",
  "patient_foot_ankle_function_q27": "Rising from bed",
  "patient_foot_ankle_function_q27_a1": "No difficulty",
  "patient_foot_ankle_function_q27_a2": "Slight difficulty",
  "patient_foot_ankle_function_q27_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q27_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q27_a5": "Unable to do",
  "patient_foot_ankle_function_q28": "Lying in bed (turning over, maintaining foot/ankle position)",
  "patient_foot_ankle_function_q28_a1": "No difficulty",
  "patient_foot_ankle_function_q28_a2": "Slight difficulty",
  "patient_foot_ankle_function_q28_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q28_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q28_a5": "Unable to do",
  "patient_foot_ankle_function_q29": "Sleeping",
  "patient_foot_ankle_function_q29_a1": "No difficulty",
  "patient_foot_ankle_function_q29_a2": "Slight difficulty",
  "patient_foot_ankle_function_q29_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q29_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q29_a5": "Unable to do",
  "patient_foot_ankle_function_q30": "Getting in and out of bath",
  "patient_foot_ankle_function_q30_a1": "No difficulty",
  "patient_foot_ankle_function_q30_a2": "Slight difficulty",
  "patient_foot_ankle_function_q30_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q30_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q30_a5": "Unable to do",
  "patient_foot_ankle_function_q31": "Sitting",
  "patient_foot_ankle_function_q31_a1": "No difficulty",
  "patient_foot_ankle_function_q31_a2": "Slight difficulty",
  "patient_foot_ankle_function_q31_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q31_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q31_a5": "Unable to do",
  "patient_foot_ankle_function_q32": "Getting on and off toilet",
  "patient_foot_ankle_function_q32_a1": "No difficulty",
  "patient_foot_ankle_function_q32_a2": "Slight difficulty",
  "patient_foot_ankle_function_q32_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q32_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q32_a5": "Unable to do",
  "patient_foot_ankle_function_q33": "Home responsibilities",
  "patient_foot_ankle_function_q33_a1": "No difficulty",
  "patient_foot_ankle_function_q33_a2": "Slight difficulty",
  "patient_foot_ankle_function_q33_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q33_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q33_a5": "Unable to do",
  "patient_foot_ankle_function_q34": "Activities of daily living",
  "patient_foot_ankle_function_q34_a1": "No difficulty",
  "patient_foot_ankle_function_q34_a2": "Slight difficulty",
  "patient_foot_ankle_function_q34_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q34_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q34_a5": "Unable to do",
  "patient_foot_ankle_function_q35": "Personal care (including washing or dressing self)",
  "patient_foot_ankle_function_q35_a1": "No difficulty",
  "patient_foot_ankle_function_q35_a2": "Slight difficulty",
  "patient_foot_ankle_function_q35_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q35_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q35_a5": "Unable to do",
  "patient_foot_ankle_function_q36": "Heavy work (push/pulling, climbing, carrying, moving heavy boxes, scrubbing floors, etc)",
  "patient_foot_ankle_function_q36_a1": "No difficulty",
  "patient_foot_ankle_function_q36_a2": "Slight difficulty",
  "patient_foot_ankle_function_q36_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q36_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q36_a5": "Unable to do",
  "patient_foot_ankle_function_q37": "Light domestic duties (cooking, dusting, etc)",
  "patient_foot_ankle_function_q37_a1": "No difficulty",
  "patient_foot_ankle_function_q37_a2": "Slight difficulty",
  "patient_foot_ankle_function_q37_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q37_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q37_a5": "Unable to do",
  "patient_foot_ankle_function_q38": "Light to moderate work (standing, walking)",
  "patient_foot_ankle_function_q38_a1": "No difficulty",
  "patient_foot_ankle_function_q38_a2": "Slight difficulty",
  "patient_foot_ankle_function_q38_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q38_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q38_a5": "Unable to do",
  "patient_foot_ankle_function_q39": "Recreational activities",
  "patient_foot_ankle_function_q39_a1": "No difficulty",
  "patient_foot_ankle_function_q39_a2": "Slight difficulty",
  "patient_foot_ankle_function_q39_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q39_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q39_a5": "Unable to do",
  "patient_foot_ankle_function_q40": "Squatting",
  "patient_foot_ankle_function_q40_a1": "No difficulty",
  "patient_foot_ankle_function_q40_a2": "Slight difficulty",
  "patient_foot_ankle_function_q40_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q40_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q40_a5": "Unable to do",
  "patient_foot_ankle_function_q41": "Twisting/pivoting on your foot/ankle",
  "patient_foot_ankle_function_q41_a1": "No difficulty",
  "patient_foot_ankle_function_q41_a2": "Slight difficulty",
  "patient_foot_ankle_function_q41_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q41_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q41_a5": "Unable to do",
  "patient_foot_ankle_function_q42": "Kneeling",
  "patient_foot_ankle_function_q42_a1": "No difficulty",
  "patient_foot_ankle_function_q42_a2": "Slight difficulty",
  "patient_foot_ankle_function_q42_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q42_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q42_a5": "Unable to do",
  "patient_foot_ankle_function_q43": "Running",
  "patient_foot_ankle_function_q43_not_applicable": "Not applicable",
  "patient_foot_ankle_function_q43_a1": "No difficulty",
  "patient_foot_ankle_function_q43_a2": "Slight difficulty",
  "patient_foot_ankle_function_q43_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q43_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q43_a5": "Unable to do",
  "patient_foot_ankle_function_q44": "Jumping",
  "patient_foot_ankle_function_q44_not_applicable": "Not applicable",
  "patient_foot_ankle_function_q44_a1": "No difficulty",
  "patient_foot_ankle_function_q44_a2": "Slight difficulty",
  "patient_foot_ankle_function_q44_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q44_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q44_a5": "Unable to do",
  "patient_foot_ankle_function_q45": "Landing",
  "patient_foot_ankle_function_q45_not_applicable": "Not applicable",
  "patient_foot_ankle_function_q45_a1": "No difficulty",
  "patient_foot_ankle_function_q45_a2": "Slight difficulty",
  "patient_foot_ankle_function_q45_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q45_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q45_a5": "Unable to do",
  "patient_foot_ankle_function_q46": "Starting and stopping quickly",
  "patient_foot_ankle_function_q46_not_applicable": "Not applicable",
  "patient_foot_ankle_function_q46_a1": "No difficulty",
  "patient_foot_ankle_function_q46_a2": "Slight difficulty",
  "patient_foot_ankle_function_q46_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q46_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q46_a5": "Unable to do",
  "patient_foot_ankle_function_q47": "Cutting / lateral movements",
  "patient_foot_ankle_function_q47_not_applicable": "Not applicable",
  "patient_foot_ankle_function_q47_a1": "No difficulty",
  "patient_foot_ankle_function_q47_a2": "Slight difficulty",
  "patient_foot_ankle_function_q47_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q47_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q47_a5": "Unable to do",
  "patient_foot_ankle_function_q48": "Low-impact activities",
  "patient_foot_ankle_function_q48_not_applicable": "Not applicable",
  "patient_foot_ankle_function_q48_a1": "No difficulty",
  "patient_foot_ankle_function_q48_a2": "Slight difficulty",
  "patient_foot_ankle_function_q48_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q48_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q48_a5": "Unable to do",
  "patient_foot_ankle_function_q49": "Ability to perform activity with your normal technique",
  "patient_foot_ankle_function_q49_not_applicable": "Not applicable",
  "patient_foot_ankle_function_q49_a1": "No difficulty",
  "patient_foot_ankle_function_q49_a2": "Slight difficulty",
  "patient_foot_ankle_function_q49_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q49_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q49_a5": "Unable to do",
  "patient_foot_ankle_function_q50": "Ability to participate in your desired sport as long as you like",
  "patient_foot_ankle_function_q50_not_applicable": "Not applicable",
  "patient_foot_ankle_function_q50_a1": "No difficulty",
  "patient_foot_ankle_function_q50_a2": "Slight difficulty",
  "patient_foot_ankle_function_q50_a3": "Moderate difficulty",
  "patient_foot_ankle_function_q50_a4": "Extreme difficulty",
  "patient_foot_ankle_function_q50_a5": "Unable to do",
  "patient_foot_ankle_function_q51": "How would you rate your ANKLE/FOOT today as a percentage of normal? (0 to 100%, 100% being normal)",
  "patient_foot_ankle_function_q51_a1": "Normal",
  "patient_foot_ankle_function_q52": "What is the highest level of activity that you can participate in on a regular basis?",
  "patient_foot_ankle_function_q52_q1": "Prior to foot or ankle injury/problem",
  "patient_foot_ankle_function_q52_q1_a1": "Top level",
  "patient_foot_ankle_function_q52_q1_a2": "Lower competitive levels",
  "patient_foot_ankle_function_q52_q1_a3": "Recreational",
  "patient_foot_ankle_function_q52_q1_a4": "No sporting activity",
  "patient_foot_ankle_function_q52_q1_a5": "No sports, no work",
  "patient_foot_ankle_function_q52_q1_a4_o7": "Unable to walk, disabled because of ankle problems",
  "patient_foot_ankle_function_q52_q1_a1_title": "Top level (international elite, professional, national team, or first division)",
  "patient_foot_ankle_function_q52_q1_a2_title": "Lower competitive levels",
  "patient_foot_ankle_function_q52_q1_a3_title": "Recreational",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o1": "Aerobics, fitness",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o2": "Alpine skiing and snowboarding",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o3": "American football",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o4": "Badminton",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o5": "Baseball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o6": "Basketball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o7": "Bowling/curling",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_08": "Boxing",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o9": "Cross-country running",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o10": "Cycling",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o11": "Dancing",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o12": "Diving",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o13": "Equestrian",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o14": "Fencing",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o15": "Floorball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o16": "Freestyle snowboarding",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o17": "Golf",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o18": "Gymnastics",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o19": "Handball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o20": "Hockey",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o21": "Ice hockey",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o22": "Korfball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o23": "Martial arts",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o24": "Modern pentathlon",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o25": "Motorsports, technical sports",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o26": "Mountain and hill climbing",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o27": "Mountain biking/bmx",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o28": "Nordic skiing",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o29": "Orienteering",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o30": "Parachuting",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o31": "Power lifting",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o32": "Rhythmic gymnastics",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o33": "Rowing, kayaking",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o34": "Rugby",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o35": "Sailing",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o36": "Scuba diving",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o37": "Shooting, archery",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o38": "Skating, in-line skating",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o39": "Soccer",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o40": "Softball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o41": "Squash",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o42": "Surfing, windsurfing",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o43": "Table tennis",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o44": "Tennis",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o45": "Track and field: field events",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o46": "Track and field: track events",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o47": "Triathlon",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o48": "Volleyball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o49": "Water polo and swimming",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o50": "Water skiing",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o51": "Weightlifting, body building",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o52": "Wrestling",
  "patient_foot_ankle_function_q52_q1_a4_o1": "Heavy physical work",
  "patient_foot_ankle_function_q52_q1_a4_o2": "Physical work",
  "patient_foot_ankle_function_q52_q1_a4_o3": "Special professions: ballet dancer, professional soldier, special rescue worker, stuntman",
  "patient_foot_ankle_function_q52_q1_a4_o4": "Able to walk on any uneven ground",
  "patient_foot_ankle_function_q52_q1_a4_o5": "Everyday activities not limited",
  "patient_foot_ankle_function_q52_q1_a4_o6": "Able to walk on even ground, but everyday activities limited",
  "patient_foot_ankle_function_q52_q2": "Current status",
  "patient_foot_ankle_function_q52_q2_a1": "Top level",
  "patient_foot_ankle_function_q52_q2_a2": "Lower competitive levels",
  "patient_foot_ankle_function_q52_q2_a3": "Recreational",
  "patient_foot_ankle_function_q52_q2_a4": "No sporting activity",
  "patient_foot_ankle_function_q52_q2_a5": "No sports, no work",
  "patient_foot_ankle_function_q52_q2_a4_o7": "Unable to walk, disabled because of ankle problems",
  "patient_foot_ankle_function_q52_q2_a1_title": "Top level (international elite, professional, national team, or first division)",
  "patient_foot_ankle_function_q52_q2_a2_title": "Lower competitive levels",
  "patient_foot_ankle_function_q52_q2_a3_title": "Recreational",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o1": "Aerobics, fitness",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o2": "Alpine skiing and snowboarding",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o3": "American football",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o4": "Badminton",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o5": "Baseball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o6": "Basketball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o7": "Bowling/curling",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o8": "Boxing",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o9": "Cross-country running",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o10": "Cycling",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o11": "Dancing",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o12": "Diving",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o13": "Equestrian",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o14": "Fencing",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o15": "Floorball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o16": "Freestyle snowboarding",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o17": "Golf",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o18": "Gymnastics",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o19": "Handball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o20": "Hockey",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o21": "Ice hockey",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o22": "Korfball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o23": "Martial arts",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o24": "Modern pentathlon",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o25": "Motorsports, technical sports",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o26": "Mountain and hill climbing",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o27": "Mountain biking/bmx",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o28": "Nordic skiing",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o29": "Orienteering",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o30": "Parachuting",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o31": "Power lifting",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o32": "Rhythmic gymnastics",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o33": "Rowing, kayaking",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o34": "Rugby",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o35": "Sailing",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o36": "Scuba diving",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o37": "Shooting, archery",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o38": "Skating, in-line skating",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o39": "Soccer",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o40": "Softball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o41": "Squash",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o42": "Surfing, windsurfing",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o43": "Table tennis",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o44": "Tennis",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o45": "Track and field: field events",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o46": "Track and field: track events",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o47": "Triathlon",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o48": "Volleyball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o49": "Water polo and swimming",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o50": "Water skiing",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o51": "Weightlifting, body building",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o52": "Wrestling",
  "patient_foot_ankle_function_q52_q2_a4_o1": "Heavy physical work",
  "patient_foot_ankle_function_q52_q2_a4_o2": "Physical work",
  "patient_foot_ankle_function_q52_q2_a4_o3": "Special professions: ballet dancer, professional soldier, special rescue worker, stuntman",
  "patient_foot_ankle_function_q52_q2_a4_o4": "Able to walk on any uneven ground",
  "patient_foot_ankle_function_q52_q2_a4_o5": "Everyday activities not limited",
  "patient_foot_ankle_function_q52_q2_a4_o6": "Able to walk on even ground, every activities limited",
  "patient_foot_ankle_quality_of_life": "Quality of Life",
  "patient_foot_ankle_quality_of_life_instructions": "The following questions ask about social, emotional, and lifestyle concerns that you may feel with respect to your foot/ankle problem. Please think about how you have felt most of the time in relation to your foot/ankle.",
  "patient_foot_ankle_quality_of_life_q1": "How often are you aware of your foot/ankle problem?",
  "patient_foot_ankle_quality_of_life_q1_a1": "Never",
  "patient_foot_ankle_quality_of_life_q1_a2": "Monthly",
  "patient_foot_ankle_quality_of_life_q1_a3": "Weekly",
  "patient_foot_ankle_quality_of_life_q1_a4": "Daily",
  "patient_foot_ankle_quality_of_life_q1_a5": "Constantly",
  "patient_foot_ankle_quality_of_life_q2": "Have you modified your lifestyle to avoid potentially damaging activities to your foot/ankle?",
  "patient_foot_ankle_quality_of_life_q2_a1": "Not at all",
  "patient_foot_ankle_quality_of_life_q2_a2": "Mildly",
  "patient_foot_ankle_quality_of_life_q2_a3": "Moderately",
  "patient_foot_ankle_quality_of_life_q2_a4": "Severely",
  "patient_foot_ankle_quality_of_life_q2_a5": "Totally",
  "patient_foot_ankle_quality_of_life_q3": "How much are you troubled with the lack of confidence in your foot/ankle?",
  "patient_foot_ankle_quality_of_life_q3_a1": "Not at all",
  "patient_foot_ankle_quality_of_life_q3_a2": "Mildly",
  "patient_foot_ankle_quality_of_life_q3_a3": "Moderately",
  "patient_foot_ankle_quality_of_life_q3_a4": "Severely",
  "patient_foot_ankle_quality_of_life_q3_a5": "Extremely",
  "patient_foot_ankle_quality_of_life_q4": "In general, how much difficulty do you have with your foot/ankle?",
  "patient_foot_ankle_quality_of_life_q4_a1": "None",
  "patient_foot_ankle_quality_of_life_q4_a2": "Mild",
  "patient_foot_ankle_quality_of_life_q4_a3": "Moderate",
  "patient_foot_ankle_quality_of_life_q4_a4": "Severe",
  "patient_foot_ankle_quality_of_life_q4_a5": "Extreme",
  "patient_foot_ankle_quality_of_life_q5": "How anxious/depressed are you today?",
  "patient_foot_ankle_quality_of_life_q5_a1": "Not anxious/depressed",
  "patient_foot_ankle_quality_of_life_q5_a2": "Slightly",
  "patient_foot_ankle_quality_of_life_q5_a3": "Moderately",
  "patient_foot_ankle_quality_of_life_q5_a4": "Severely",
  "patient_foot_ankle_quality_of_life_q5_a5": "Extremely",
  "patient_foot_ankle_quality_of_life_q6": "How good/bad is your HEALTH today?(100 means best health, 0 means worst health you can imagine )",
  "patient_foot_ankle_quality_of_life_q6_a1": "Worst health",
  "patient_foot_ankle_quality_of_life_q6_a2": "Best health",
  "patient_foot_ankle_satisfaction": "Satisfaction",
  "patient_foot_ankle_satisfaction_instruction": "How satisfied are you with the results of your foot/ankle surgery or treatment?",
  "patient_foot_ankle_satisfaction_instruction_a1": "Very dissatisfied",
  "patient_foot_ankle_satisfaction_instruction_a2": "Somewhat dissatisfied",
  "patient_foot_ankle_satisfaction_instruction_a3": "Somewhat satisfied",
  "patient_foot_ankle_satisfaction_instruction_a4": "Very satisfied",
  "patient_foot_ankle_satisfaction_q1": "For providing symptom relief?",
  "patient_foot_ankle_satisfaction_q1_not_applicable": "Not applicable",
  "patient_foot_ankle_satisfaction_q1_a1": "Very dissatisfied",
  "patient_foot_ankle_satisfaction_q1_a2": "Somewhat dissatisfied",
  "patient_foot_ankle_satisfaction_q1_a3": "Somewhat satisfied",
  "patient_foot_ankle_satisfaction_q1_a4": "Very satisfied",
  "patient_foot_ankle_satisfaction_q2": "For improving your ability to perform daily activities?",
  "patient_foot_ankle_satisfaction_q2_not_applicable": "Not applicable",
  "patient_foot_ankle_satisfaction_q2_a1": "Very dissatisfied",
  "patient_foot_ankle_satisfaction_q2_a2": "Somewhat dissatisfied",
  "patient_foot_ankle_satisfaction_q2_a3": "Somewhat satisfied",
  "patient_foot_ankle_satisfaction_q2_a4": "Very satisfied",
  "patient_foot_ankle_satisfaction_q3": "For \"forgetting\" your foot/ankle problem? (awareness of foot/ankle problem)",
  "patient_foot_ankle_satisfaction_q3_not_applicable": "Not applicable",
  "patient_foot_ankle_satisfaction_q3_a1": "Very dissatisfied",
  "patient_foot_ankle_satisfaction_q3_a2": "Somewhat dissatisfied",
  "patient_foot_ankle_satisfaction_q3_a3": "Somewhat satisfied",
  "patient_foot_ankle_satisfaction_q3_a4": "Very satisfied",
  "patient_foot_ankle_complete": "End of Questionnaire",
  "patient_foot_ankle_complete_text1": "Thank you for taking the time to fill out this form.",
  "patient_foot_ankle_complete_text2": "Please click submit to complete and see your scores.",
  "patient_foot_ankle_complete_text3": "Your answers will be treated confidentially and any reports will only use de-identified information.",
  "patient_foot_ankle_results": "Results",
  "patient_foot_ankle_results_text1": "Below are your pain and foot/ankle function scores, which are calculated from your questionnaire.",
  "patient_foot_ankle_results_pain": "Pain",
  "patient_foot_ankle_results_function": "Function",
  "patient_foot_ankle_results_overall_health_perception": "Overall Health Perception",
  "patient_foot_ankle_results_feeling_of_instability": "Feeling of instability",
  "patient_foot_ankle_results_self_evaluation": "Foot/Ankle Scoring Self-Evaluation",
  "patient_foot_ankle_results_text2": "Your Doctor will be with you shortly.",
  "patient_elbow_pain": "Pain",
  "patient_elbow_pain_q1": "Do you experience pain in your elbow?",
  "patient_elbow_pain_q1_a1": "No",
  "patient_elbow_pain_q1_a2": "Yes",
  "patient_elbow_pain_q2": "What is the best description of your elbow pain?",
  "patient_elbow_pain_q2_a1": "None or ignored",
  "patient_elbow_pain_q2_a2": "Mild, with activity, none to occasionally uses medication/analgesics",
  "patient_elbow_pain_q2_a3": "Moderate, with or without activity, uses analgesics daily",
  "patient_elbow_pain_q2_a4": "Moderate at rest or at night",
  "patient_elbow_pain_q2_a5": "Severe at rest, constant medication, disabling",
  "patient_elbow_pain_instruction": "Rate your PAIN in your elbow",
  "patient_elbow_pain_q3": "At this moment",
  "patient_elbow_pain_q3_a1": "No pain",
  "patient_elbow_pain_q3_a2": "Worst pain, unbearable",
  "patient_elbow_pain_q4": "Usual pain during the past 4 weeks",
  "patient_elbow_pain_q4_a1": "No pain",
  "patient_elbow_pain_q4_a2": "Worst pain, unbearable",
  "patient_elbow_pain_q5": "When it is at its worst during the past 4 weeks",
  "patient_elbow_pain_q5_a1": "No pain",
  "patient_elbow_pain_q5_a2": "Worst pain, unbearable",
  "patient_elbow_pain_q6": "At rest",
  "patient_elbow_pain_q6_a1": "No pain",
  "patient_elbow_pain_q6_a2": "Worst pain, unbearable",
  "patient_elbow_pain_q7": "Lifting a heavy object",
  "patient_elbow_pain_q7_a1": "No pain",
  "patient_elbow_pain_q7_a2": "Worst pain, unbearable",
  "patient_elbow_pain_q8": "When doing a task with repeated elbow movements",
  "patient_elbow_pain_q8_a1": "No pain",
  "patient_elbow_pain_q8_a2": "Worst pain, unbearable",
  "patient_elbow_pain_q9": "At night",
  "patient_elbow_pain_q9_a1": "No pain",
  "patient_elbow_pain_q9_a2": "Worst pain, unbearable",
  "patient_elbow_pain_q10": "How often have you been troubled by pain from your elbow in bed at night?",
  "patient_elbow_pain_q10_a1": "Not at all",
  "patient_elbow_pain_q10_a2": "1-2 nights",
  "patient_elbow_pain_q10_a3": "Some nights",
  "patient_elbow_pain_q10_a4": "Most nights",
  "patient_elbow_pain_q10_a5": "Every night",
  "patient_elbow_pain_q11": "How often has your elbow pain interfered with your sleeping?",
  "patient_elbow_pain_q11_a1": "Not at all",
  "patient_elbow_pain_q11_a2": "Occasionally",
  "patient_elbow_pain_q11_a3": "Some of the time",
  "patient_elbow_pain_q11_a4": "Most of the time",
  "patient_elbow_pain_q11_a5": "All of the time",
  "patient_elbow_function": "Function",
  "patient_elbow_function_q1": "What is the best description of your elbow FUNCTION ?",
  "patient_elbow_function_q1_a1": "No limitations",
  "patient_elbow_function_q1_a2": "Slight limitations, no restrictions in activities of daily living",
  "patient_elbow_function_q1_a3": "Unable to lift objects >4.5kg",
  "patient_elbow_function_q1_a4": "Moderate restrictions in activities of daily living",
  "patient_elbow_function_q1_a5": "Unable to comb hair or touch head",
  "patient_elbow_function_q1_a6": "Unable to feed self",
  "patient_elbow_function_q2": "What is the best description of the ACTIVITY you can do?",
  "patient_elbow_function_q2_a1": "Unlimited use of elbow",
  "patient_elbow_function_q2_a2": "Limited use for recreation",
  "patient_elbow_function_q2_a3": "Limited to household and employment activities",
  "patient_elbow_function_q2_a4": "Able to care for self",
  "patient_elbow_function_q2_a5": "Invalid",
  "patient_elbow_function_q3": "How long can you use your elbow?",
  "patient_elbow_function_q3_a1": "Can perform activities for 30 minutes",
  "patient_elbow_function_q3_a2": "Can perform activities for 15 minutes",
  "patient_elbow_function_q3_a3": "Can perform activities for 5 minutes",
  "patient_elbow_function_q3_a4": "Cannot use elbow",
  "patient_elbow_function_instruction": "Indicate the DEGREE OF DIFFICULTY to do the following activities in the last week due to your elbow",
  "patient_elbow_function_instruction_0": "No difficulty",
  "patient_elbow_function_instruction_1": "Mild",
  "patient_elbow_function_instruction_2": "Moderate",
  "patient_elbow_function_instruction_3": "Severe",
  "patient_elbow_function_instruction_4": "Unable",
  "patient_elbow_function_q4": "Open a tight or new jar",
  "patient_elbow_function_q4_a1": "No difficulty",
  "patient_elbow_function_q4_a2": "Mild",
  "patient_elbow_function_q4_a3": "Moderate",
  "patient_elbow_function_q4_a4": "Severe",
  "patient_elbow_function_q4_a5": "Unable",
  "patient_elbow_function_q5": "Carry a shopping bag or briefcase",
  "patient_elbow_function_q5_a1": "No difficulty",
  "patient_elbow_function_q5_a2": "Mild",
  "patient_elbow_function_q5_a3": "Moderate",
  "patient_elbow_function_q5_a4": "Severe",
  "patient_elbow_function_q5_a5": "Unable",
  "patient_elbow_function_q6": "Lifting things in your home, such as putting out the rubbish",
  "patient_elbow_function_q6_a1": "No difficulty",
  "patient_elbow_function_q6_a2": "Mild",
  "patient_elbow_function_q6_a3": "Moderate",
  "patient_elbow_function_q6_a4": "Severe",
  "patient_elbow_function_q6_a5": "Unable",
  "patient_elbow_function_q7": "Wash your back",
  "patient_elbow_function_q7_a1": "No difficulty",
  "patient_elbow_function_q7_a2": "Mild",
  "patient_elbow_function_q7_a3": "Moderate",
  "patient_elbow_function_q7_a4": "Severe",
  "patient_elbow_function_q7_a5": "Unable",
  "patient_elbow_function_q8": "Manage toileting",
  "patient_elbow_function_q8_a1": "No difficulty",
  "patient_elbow_function_q8_a2": "Mild",
  "patient_elbow_function_q8_a3": "Moderate",
  "patient_elbow_function_q8_a4": "Severe",
  "patient_elbow_function_q8_a5": "Unable",
  "patient_elbow_function_q9": "Washing yourself all over",
  "patient_elbow_function_q9_a1": "No difficulty",
  "patient_elbow_function_q9_a2": "Mild",
  "patient_elbow_function_q9_a3": "Moderate",
  "patient_elbow_function_q9_a4": "Severe",
  "patient_elbow_function_q9_a5": "Unable",
  "patient_elbow_function_q10": "Perform personal hygiene",
  "patient_elbow_function_q10_a1": "No difficulty",
  "patient_elbow_function_q10_a2": "Mild",
  "patient_elbow_function_q10_a3": "Moderate",
  "patient_elbow_function_q10_a4": "Severe",
  "patient_elbow_function_q10_a5": "Unable",
  "patient_elbow_function_q11": "Use a knife to cut food",
  "patient_elbow_function_q11_a1": "No difficulty",
  "patient_elbow_function_q11_a2": "Mild",
  "patient_elbow_function_q11_a3": "Moderate",
  "patient_elbow_function_q11_a4": "Severe",
  "patient_elbow_function_q11_a5": "Unable",
  "patient_elbow_function_q12": "Eat with utensil",
  "patient_elbow_function_q12_a1": "No difficulty",
  "patient_elbow_function_q12_a2": "Mild",
  "patient_elbow_function_q12_a3": "Moderate",
  "patient_elbow_function_q12_a4": "Severe",
  "patient_elbow_function_q12_a5": "Unable",
  "patient_elbow_function_q13": "Recreational activities in which you take some force or impact through your arm, shoulder or hand (eg golf, hammering, tennis, etc)",
  "patient_elbow_function_q13_a1": "No difficulty",
  "patient_elbow_function_q13_a2": "Mild",
  "patient_elbow_function_q13_a3": "Moderate",
  "patient_elbow_function_q13_a4": "Severe",
  "patient_elbow_function_q13_a5": "Unable",
  "patient_elbow_function_q14": "Sleeping because of pain in your arm, shoulder, or hand",
  "patient_elbow_function_q14_a1": "No difficulty",
  "patient_elbow_function_q14_a2": "Mild",
  "patient_elbow_function_q14_a3": "Moderate",
  "patient_elbow_function_q14_a4": "Severe",
  "patient_elbow_function_q14_a5": "Unable",
  "patient_elbow_function_q15": "Put on shirt",
  "patient_elbow_function_q15_a1": "No difficulty",
  "patient_elbow_function_q15_a2": "Mild",
  "patient_elbow_function_q15_a3": "Moderate",
  "patient_elbow_function_q15_a4": "Severe",
  "patient_elbow_function_q15_a5": "Unable",
  "patient_elbow_function_q16": "Do up top button on shirt",
  "patient_elbow_function_q16_a1": "No difficulty",
  "patient_elbow_function_q16_a2": "Mild",
  "patient_elbow_function_q16_a3": "Moderate",
  "patient_elbow_function_q16_a4": "Severe",
  "patient_elbow_function_q16_a5": "Unable",
  "patient_elbow_function_q17": "Dressing yourself",
  "patient_elbow_function_q17_a1": "No difficulty",
  "patient_elbow_function_q17_a2": "Mild",
  "patient_elbow_function_q17_a3": "Moderate",
  "patient_elbow_function_q17_a4": "Severe",
  "patient_elbow_function_q17_a5": "Unable",
  "patient_elbow_function_q18": "Comb hair",
  "patient_elbow_function_q18_a1": "No difficulty",
  "patient_elbow_function_q18_a2": "Mild",
  "patient_elbow_function_q18_a3": "Moderate",
  "patient_elbow_function_q18_a4": "Severe",
  "patient_elbow_function_q18_a5": "Unable",
  "patient_elbow_function_q19": "Put on shoes",
  "patient_elbow_function_q19_a1": "No difficulty",
  "patient_elbow_function_q19_a2": "Mild",
  "patient_elbow_function_q19_a3": "Moderate",
  "patient_elbow_function_q19_a4": "Severe",
  "patient_elbow_function_q19_a5": "Unable",
  "patient_elbow_function_q20": "Tie shoes",
  "patient_elbow_function_q20_a1": "No difficulty",
  "patient_elbow_function_q20_a2": "Mild",
  "patient_elbow_function_q20_a3": "Moderate",
  "patient_elbow_function_q20_a4": "Severe",
  "patient_elbow_function_q20_a5": "Unable",
  "patient_elbow_function_q21": "Carry a heavy object",
  "patient_elbow_function_q21_a1": "No difficulty",
  "patient_elbow_function_q21_a2": "Mild",
  "patient_elbow_function_q21_a3": "Moderate",
  "patient_elbow_function_q21_a4": "Severe",
  "patient_elbow_function_q21_a5": "Unable",
  "patient_elbow_function_q22": "Rise from a chair pushing with arm",
  "patient_elbow_function_q22_a1": "No difficulty",
  "patient_elbow_function_q22_a2": "Mild",
  "patient_elbow_function_q22_a3": "Moderate",
  "patient_elbow_function_q22_a4": "Severe",
  "patient_elbow_function_q22_a5": "Unable",
  "patient_elbow_function_q23": "Do heavy household chores (eg wash walls, floors)",
  "patient_elbow_function_q23_a1": "No difficulty",
  "patient_elbow_function_q23_a2": "Mild",
  "patient_elbow_function_q23_a3": "Moderate",
  "patient_elbow_function_q23_a4": "Severe",
  "patient_elbow_function_q23_a5": "Unable",
  "patient_elbow_function_q24": "Turn a key",
  "patient_elbow_function_q24_a1": "No difficulty",
  "patient_elbow_function_q24_a2": "Mild",
  "patient_elbow_function_q24_a3": "Moderate",
  "patient_elbow_function_q24_a4": "Severe",
  "patient_elbow_function_q24_a5": "Unable",
  "patient_elbow_function_q25": "Throw a ball",
  "patient_elbow_function_q25_a1": "No difficulty",
  "patient_elbow_function_q25_a2": "Mild",
  "patient_elbow_function_q25_a3": "Moderate",
  "patient_elbow_function_q25_a4": "Severe",
  "patient_elbow_function_q25_a5": "Unable",
  "patient_elbow_function_q26": "Doing your usual work because of arm, shoulder, or hand pain (work includes homemaking if that is your main role)",
  "patient_elbow_function_q26_a1": "No difficulty",
  "patient_elbow_function_q26_a2": "Mild",
  "patient_elbow_function_q26_a3": "Moderate",
  "patient_elbow_function_q26_a4": "Severe",
  "patient_elbow_function_q26_a5": "Unable",
  "patient_elbow_function_q27": "Specify usual work",
  "patient_elbow_function_q27_placeholder": "Specify work",
  "patient_elbow_function_i_donot_work": "I do not work",
  "patient_elbow_function_q28": "Using your usual technique for work",
  "patient_elbow_function_q28_a1": "No difficulty",
  "patient_elbow_function_q28_a2": "Mild",
  "patient_elbow_function_q28_a3": "Moderate",
  "patient_elbow_function_q28_a4": "Severe",
  "patient_elbow_function_q28_a5": "Unable",
  "patient_elbow_function_q29": "Doing your work as well as you would like",
  "patient_elbow_function_q29_a1": "No difficulty",
  "patient_elbow_function_q29_a2": "Mild",
  "patient_elbow_function_q29_a3": "Moderate",
  "patient_elbow_function_q29_a4": "Severe",
  "patient_elbow_function_q29_a5": "Unable",
  "patient_elbow_function_q30": "Spending your usual amount of time doing your work",
  "patient_elbow_function_q30_a1": "No difficulty",
  "patient_elbow_function_q30_a2": "Mild",
  "patient_elbow_function_q30_a3": "Moderate",
  "patient_elbow_function_q30_a4": "Severe",
  "patient_elbow_function_q30_a5": "Unable",
  "patient_elbow_function_q31": "Playing your musical instrument or sport because of arm, shoulder, or hand pain",
  "patient_elbow_function_q31_a1": "No difficulty",
  "patient_elbow_function_q31_a2": "Mild",
  "patient_elbow_function_q31_a3": "Moderate",
  "patient_elbow_function_q31_a4": "Severe",
  "patient_elbow_function_q31_a5": "Unable",
  "patient_elbow_function_q32": "Specify sport or musical instrument played",
  "patient_elbow_function_q32_placeholder": "Specify sport/musical instrument",
  "patient_elbow_function_i_donot_play": "I do not play a sport or instrument",
  "patient_elbow_function_q33": "Using your usual technique for playing your instrument or sport",
  "patient_elbow_function_q33_a1": "No difficulty",
  "patient_elbow_function_q33_a2": "Mild",
  "patient_elbow_function_q33_a3": "Moderate",
  "patient_elbow_function_q33_a4": "Severe",
  "patient_elbow_function_q33_a5": "Unable",
  "patient_elbow_function_q34": "Playing your musical instrument or sport as well as you would like",
  "patient_elbow_function_q34_a1": "No difficulty",
  "patient_elbow_function_q34_a2": "Mild",
  "patient_elbow_function_q34_a3": "Moderate",
  "patient_elbow_function_q34_a4": "Severe",
  "patient_elbow_function_q34_a5": "Unable",
  "patient_elbow_function_q35": "Spending your usual amount of time practising or playing your instrument or sport",
  "patient_elbow_function_q35_a1": "No difficulty",
  "patient_elbow_function_q35_a2": "Mild",
  "patient_elbow_function_q35_a3": "Moderate",
  "patient_elbow_function_q35_a4": "Severe",
  "patient_elbow_function_q35_a5": "Unable",
  "patient_elbow_function_q36": "During the past week, to what extent has your arm, shoulder, or hand problem interfered with your NORMAL SOCIAL/LEISURE ACTIVITIES with family, friends, neighbours, or groups?",
  "patient_elbow_function_q36_a1": "Not at all",
  "patient_elbow_function_q36_a2": "Slightly",
  "patient_elbow_function_q36_a3": "Moderately",
  "patient_elbow_function_q36_a4": "Quite a bit",
  "patient_elbow_function_q36_a5": "Extremely",
  "patient_elbow_function_q37": "During the past week, were you limited in your WORK OR OTHER REGULAR DAILY ACTIVITIES as a result of your arm, shoulder, or hand problem?",
  "patient_elbow_function_q37_a1": "Not at all",
  "patient_elbow_function_q37_a2": "Slightly",
  "patient_elbow_function_q37_a3": "Moderately",
  "patient_elbow_function_q37_a4": "Quite a bit",
  "patient_elbow_function_q37_a5": "Extremely",
  "patient_elbow_symptoms": "Other Symptoms",
  "patient_elbow_symptoms_instruction": "Please rate the severity of the following symptoms in the last week:",
  "patient_elbow_symptoms_instruction_0": "None",
  "patient_elbow_symptoms_instruction_1": "Mild",
  "patient_elbow_symptoms_instruction_2": "Moderate",
  "patient_elbow_symptoms_instruction_3": "Severe",
  "patient_elbow_symptoms_instruction_4": "Extreme",
  "patient_elbow_symptoms_q1": "Arm, shoulder, or hand pain",
  "patient_elbow_symptoms_q1_a1": "None",
  "patient_elbow_symptoms_q1_a2": "Mild",
  "patient_elbow_symptoms_q1_a3": "Moderate",
  "patient_elbow_symptoms_q1_a4": "Severe",
  "patient_elbow_symptoms_q1_a5": "Extreme",
  "patient_elbow_symptoms_q2": "Tingling (pins and needles) in your arm, shoulder, or hand",
  "patient_elbow_symptoms_q2_a1": "None",
  "patient_elbow_symptoms_q2_a2": "Mild",
  "patient_elbow_symptoms_q2_a3": "Moderate",
  "patient_elbow_symptoms_q2_a4": "Severe",
  "patient_elbow_symptoms_q2_a5": "Extreme",
  "patient_elbow_symptoms_q3": "How would you rate your elbow today as a percentage of normal? (0-100% with 100% being normal)",
  "patient_elbow_quality_of_life": "Quality of Life",
  "patient_elbow_quality_of_life_instruction": "The following questions ask about social, emotional, and lifestyle concerns that you may feel with respect to your elbow problem. Please think about how you have felt most of the time in relation to your elbow.",
  "patient_elbow_quality_of_life_q1": "During the past 4 weeks, have you felt that your elbow problem is “controlling your life”?",
  "patient_elbow_quality_of_life_q1_a1": "Not at all",
  "patient_elbow_quality_of_life_q1_a2": "Occasionally",
  "patient_elbow_quality_of_life_q1_a3": "Some days",
  "patient_elbow_quality_of_life_q1_a4": "Most days",
  "patient_elbow_quality_of_life_q1_a5": "Everyday",
  "patient_elbow_quality_of_life_q2": "During the past 4 weeks, how much has your elbow been “on your mind”?",
  "patient_elbow_quality_of_life_q2_a1": "Not at all",
  "patient_elbow_quality_of_life_q2_a2": "A little of the time",
  "patient_elbow_quality_of_life_q2_a3": "Some of the time",
  "patient_elbow_quality_of_life_q2_a4": "Most of the time",
  "patient_elbow_quality_of_life_q2_a5": "All of the time",
  "patient_elbow_quality_of_life_instruction1": "Please rate the following to best describe your HEALTH TODAY:",
  "patient_elbow_quality_of_life_instruction1_0": "No problem",
  "patient_elbow_quality_of_life_instruction1_1": "Slight",
  "patient_elbow_quality_of_life_instruction1_2": "Moderate",
  "patient_elbow_quality_of_life_instruction1_3": "Severe",
  "patient_elbow_quality_of_life_instruction1_4": "Extreme/unable",
  "patient_elbow_quality_of_life_q3": "Problem in walking about",
  "patient_elbow_quality_of_life_q3_a1": "No problem",
  "patient_elbow_quality_of_life_q3_a2": "Slight",
  "patient_elbow_quality_of_life_q3_a3": "Moderate",
  "patient_elbow_quality_of_life_q3_a4": "Severe",
  "patient_elbow_quality_of_life_q3_a5": "Unable",
  "patient_elbow_quality_of_life_q4": "Problem with washing or dressing self",
  "patient_elbow_quality_of_life_q4_a1": "No problem",
  "patient_elbow_quality_of_life_q4_a2": "Slight",
  "patient_elbow_quality_of_life_q4_a3": "Moderate",
  "patient_elbow_quality_of_life_q4_a4": "Severe",
  "patient_elbow_quality_of_life_q4_a5": "Unable",
  "patient_elbow_quality_of_life_q5": "Problem with doing usual activities (eg work, study, housework, family, or leisure activities)",
  "patient_elbow_quality_of_life_q5_a1": "No problem",
  "patient_elbow_quality_of_life_q5_a2": "Slight",
  "patient_elbow_quality_of_life_q5_a3": "Moderate",
  "patient_elbow_quality_of_life_q5_a4": "Severe",
  "patient_elbow_quality_of_life_q5_a5": "Unable",
  "patient_elbow_quality_of_life_q6": "Pain or discomfort",
  "patient_elbow_quality_of_life_q6_a1": "None",
  "patient_elbow_quality_of_life_q6_a2": "Slight",
  "patient_elbow_quality_of_life_q6_a3": "Moderate",
  "patient_elbow_quality_of_life_q6_a4": "Severe",
  "patient_elbow_quality_of_life_q6_a5": "Extreme",
  "patient_elbow_quality_of_life_q7": "Anxiety/Depression",
  "patient_elbow_quality_of_life_q7_a1": "Not anxious/depressed",
  "patient_elbow_quality_of_life_q7_a2": "Slightly anxious/depressed",
  "patient_elbow_quality_of_life_q7_a3": "Moderately anxious/depressed",
  "patient_elbow_quality_of_life_q7_a4": "Severely anxious/depressed",
  "patient_elbow_quality_of_life_q7_a5": "Extremely anxious/depressed",
  "patient_elbow_quality_of_life_q8": "How good/bad is your health TODAY?",
  "patient_elbow_quality_of_life_q8_instruction": "100 means best health, 0 means worst health you can imagine",
  "patient_elbow_quality_of_life_q8_a1": "Worst",
  "patient_elbow_quality_of_life_q8_a2": "Best",
  "patient_elbow_satisfaction": "Satisfaction",
  "patient_elbow_satisfaction_q1": "Are you satisfied with the treatment for your elbow?",
  "patient_elbow_satisfaction_na": "Not applicable",
  "patient_elbow_satisfaction_q1_a1": "Not at all satisfied",
  "patient_elbow_satisfaction_q1_a2": "Very satisfied",
  "patient_elbow_complete": "End of Questionnaire",
  "patient_elbow_complete_text1": "Thank you for taking the time to fill out this form.",
  "patient_elbow_complete_text2": "Please click submit to complete and see your scores.",
  "patient_elbow_complete_text3": "Your answers will be treated confidentially and any reports will only use de-identified information",
  "patient_elbow_results": "Results",
  "patient_elbow_results_text1": "Below are your pain and elbow function scores, which are calculated from your questionnaire.",
  "patient_elbow_results_function": "Function",
  "patient_elbow_results_pain": "Pain",
  "patient_elbow_results_health_perception": "Overall Health Perception",
  "patient_elbow_results_self_evaluation": "Elbow Scoring Self-Evaluation",
  "patient_elbow_results_text4": "Your Doctor will be with you shortly.",
  "patient_hand_pain": "Pain",
  "patient_hand_pain_q1": "Describe the pain in your hand/wrist in the PAST WEEK",
  "patient_hand_pain_q1_a1": "None to very mild",
  "patient_hand_pain_q1_a2": "Mild",
  "patient_hand_pain_q1_a3": "Moderate",
  "patient_hand_pain_q1_a4": "Severe",
  "patient_hand_pain_q1_a5": "Very severe/extreme",
  "patient_hand_pain_q2": "How bad is the pain in your hand/wrist AT THIS MOMENT?",
  "patient_hand_pain_q2_a1": "No pain",
  "patient_hand_pain_q2_a2": "Worst pain",
  "patient_hand_pain_q3": "How often did the pain in your hand/wrist interfere with your daily activities (such as eating or bathing) in the past week?",
  "patient_hand_pain_q3_a1": "Never",
  "patient_hand_pain_q3_a2": "Rarely",
  "patient_hand_pain_q3_a3": "Sometimes",
  "patient_hand_pain_q3_a4": "Often",
  "patient_hand_pain_q3_a5": "Always",
  "patient_hand_symptoms": "Other Symptoms",
  "patient_hand_symptoms_q1": "How severe was the tingling (pins and needles) in your arm, shoulder, or hand in the past week?",
  "patient_hand_symptoms_q1_a1": "None",
  "patient_hand_symptoms_q1_a2": "Mild",
  "patient_hand_symptoms_q1_a3": "Moderate",
  "patient_hand_symptoms_q1_a4": "Severe",
  "patient_hand_symptoms_q1_a5": "Extreme",
  "patient_hand_symptoms_q2": "How was the sensation (feeling) in your hand during the past week?",
  "patient_hand_symptoms_q2_a1": "Very good",
  "patient_hand_symptoms_q2_a2": "Good",
  "patient_hand_symptoms_q2_a3": "Fair",
  "patient_hand_symptoms_q2_a4": "Poor",
  "patient_hand_symptoms_q2_a5": "Very poor",
  "patient_hand_symptoms_q3": "The appearance of my hand interferes with my normal daily activities",
  "patient_hand_symptoms_q3_a1": "Strongly disagree",
  "patient_hand_symptoms_q3_a2": "Disagree",
  "patient_hand_symptoms_q3_a3": "Neither agree nor disagree",
  "patient_hand_symptoms_q3_a4": "Agree",
  "patient_hand_symptoms_q3_a5": "Strongly agree",
  "patient_hand_symptoms_q3_a6": "How would you rate your hand/wrist today as a percentage of normal? (0-100% with 100% being normal)",
  "patient_hand_symptoms_q3_a6_normal": "Normal",
  "patient_hand_function": "Function",
  "patient_hand_function_instruction1": "If you do not do a certain task, please estimate the difficulty with which you would have in performing it. ",
  "patient_hand_function_instruction2": "If you are unsure about how to answer a question, please give it the best answer you can. ",
  "patient_hand_function_q1": "Overall, how well did your hand work during the past week?",
  "patient_hand_function_q1_a1": "Very good",
  "patient_hand_function_q1_a2": "Good",
  "patient_hand_function_q1_a3": "Fair",
  "patient_hand_function_q1_a4": "Poor",
  "patient_hand_function_q1_a5": "Very poor",
  "patient_hand_function_q2": "How often were you UNABLE TO DO YOUR WORK because of problems with your hand/wrist?",
  "patient_hand_function_q2_a1": "Never",
  "patient_hand_function_q2_a2": "Rarely",
  "patient_hand_function_q2_a3": "Sometimes",
  "patient_hand_function_q2_a4": "Often",
  "patient_hand_function_q2_a5": "Always",
  "patient_hand_function_q3": "How often did you TAKE LONGER TO DO TASKS in your work because of problems with your hand/wrist?",
  "patient_hand_function_q3_a1": "Never",
  "patient_hand_function_q3_a2": "Rarely",
  "patient_hand_function_q3_a3": "Sometimes",
  "patient_hand_function_q3_a4": "Often",
  "patient_hand_function_q3_a5": "Always",
  "patient_hand_function_instruction": "Indicate the DEGREE OF DIFFICULTY to do the following activities in the last week because of problems with your hand/wrist",
  "patient_hand_function_instruction_0": "No difficulty",
  "patient_hand_function_instruction_1": "Mild difficulty",
  "patient_hand_function_instruction_2": "Moderate difficulty",
  "patient_hand_function_instruction_3": "Severe difficulty",
  "patient_hand_function_instruction_4": "Extreme difficulty/unable",
  "patient_hand_function_q4": "Open a tight or new jar",
  "patient_hand_function_q4_a1": "No difficulty",
  "patient_hand_function_q4_a2": "Mild difficulty",
  "patient_hand_function_q4_a3": "Moderate difficulty",
  "patient_hand_function_q4_a4": "Severe difficulty",
  "patient_hand_function_q4_a5": "Extreme difficulty/unable",
  "patient_hand_function_q5": "Hold a frying pain",
  "patient_hand_function_q5_a1": "No difficulty",
  "patient_hand_function_q5_a2": "Mild difficulty",
  "patient_hand_function_q5_a3": "Moderate difficulty",
  "patient_hand_function_q5_a4": "Severe difficulty",
  "patient_hand_function_q5_a5": "Extreme difficulty/unable",
  "patient_hand_function_q6": "Button a shirt or blouse",
  "patient_hand_function_q6_a1": "No difficulty",
  "patient_hand_function_q6_a2": "Mild difficulty",
  "patient_hand_function_q6_a3": "Moderate difficulty",
  "patient_hand_function_q6_a4": "Severe difficulty",
  "patient_hand_function_q6_a5": "Extreme difficulty/unable",
  "patient_hand_function_q7": "Do heavy household chores (eg wash walls, floors)",
  "patient_hand_function_q7_a1": "No difficulty",
  "patient_hand_function_q7_a2": "Mild difficulty",
  "patient_hand_function_q7_a3": "Moderate difficulty",
  "patient_hand_function_q7_a4": "Severe difficulty",
  "patient_hand_function_q7_a5": "Extreme difficulty/unable",
  "patient_hand_function_q8": "Carry a shopping bag or briefcase",
  "patient_hand_function_q8_a1": "No difficulty",
  "patient_hand_function_q8_a2": "Mild difficulty",
  "patient_hand_function_q8_a3": "Moderate difficulty",
  "patient_hand_function_q8_a4": "Severe difficulty",
  "patient_hand_function_q8_a5": "Extreme difficulty/unable",
  "patient_hand_function_q9": "Wash your back",
  "patient_hand_function_q9_a1": "No difficulty",
  "patient_hand_function_q9_a2": "Mild difficulty",
  "patient_hand_function_q9_a3": "Moderate difficulty",
  "patient_hand_function_q9_a4": "Severe difficulty",
  "patient_hand_function_q9_a5": "Extreme difficulty/unable",
  "patient_hand_function_q10": "Use a knife to cut food",
  "patient_hand_function_q10_a1": "No difficulty",
  "patient_hand_function_q10_a2": "Mild difficulty",
  "patient_hand_function_q10_a3": "Moderate difficulty",
  "patient_hand_function_q10_a4": "Severe difficulty",
  "patient_hand_function_q10_a5": "Extreme difficulty/unable",
  "patient_hand_function_q11": "Recreational activities in which you take some force or impact through your arm, shoulder, or hand (eg golf, hammering, tennis, etc)",
  "patient_hand_function_q11_a1": "No difficulty",
  "patient_hand_function_q11_a2": "Mild difficulty",
  "patient_hand_function_q11_a3": "Moderate difficulty",
  "patient_hand_function_q11_a4": "Severe difficulty",
  "patient_hand_function_q11_a5": "Extreme difficulty/unable",
  "patient_hand_function_q12": "Sleeping because of pain in your arm, shoulder, or hand",
  "patient_hand_function_q12_a1": "No difficulty",
  "patient_hand_function_q12_a2": "Mild difficulty",
  "patient_hand_function_q12_a3": "Moderate difficulty",
  "patient_hand_function_q12_a4": "Severe difficulty",
  "patient_hand_function_q12_a5": "Extreme difficulty/unable",
  "patient_hand_function_q13": "Doing your usual work because of arm, shoulder, or hand pain (work includes homemaking if that is your main role)",
  "patient_hand_function_q13_a1": "No difficulty",
  "patient_hand_function_q13_a2": "Mild difficulty",
  "patient_hand_function_q13_a3": "Moderate difficulty",
  "patient_hand_function_q13_a4": "Severe difficulty",
  "patient_hand_function_q13_a5": "Extreme difficulty/unable",
  "patient_hand_function_q14": "Specify usual work",
  "patient_hand_function_q14_placeholder": "Specify work",
  "patient_hand_function_do_dot_work": "I do not work",
  "patient_hand_function_q15": "Using your usual technique for work",
  "patient_hand_function_q15_a1": "No difficulty",
  "patient_hand_function_q15_a2": "Mild difficulty",
  "patient_hand_function_q15_a3": "Moderate difficulty",
  "patient_hand_function_q15_a4": "Severe difficulty",
  "patient_hand_function_q15_a5": "Extreme difficulty/unable",
  "patient_hand_function_q16": "Doing your work as well as you would like",
  "patient_hand_function_q16_a1": "No difficulty",
  "patient_hand_function_q16_a2": "Mild difficulty",
  "patient_hand_function_q16_a3": "Moderate difficulty",
  "patient_hand_function_q16_a4": "Severe difficulty",
  "patient_hand_function_q16_a5": "Extreme difficulty/unable",
  "patient_hand_function_q17": "Spending your usual amount of time doing your work",
  "patient_hand_function_q17_a1": "No difficulty",
  "patient_hand_function_q17_a2": "Mild difficulty",
  "patient_hand_function_q17_a3": "Moderate difficulty",
  "patient_hand_function_q17_a4": "Severe difficulty",
  "patient_hand_function_q17_a5": "Extreme difficulty/unable",
  "patient_hand_function_q18": "Playing your musical instrument or sport because of arm, shoulder, or hand pain",
  "patient_hand_function_q18_a1": "No difficulty",
  "patient_hand_function_q18_a2": "Mild difficulty",
  "patient_hand_function_q18_a3": "Moderate difficulty",
  "patient_hand_function_q18_a4": "Severe difficulty",
  "patient_hand_function_q18_a5": "Extreme difficulty/unable",
  "patient_hand_function_q19": "Specify sport or musical instrument played",
  "patient_hand_function_q19_placeholder": "Specify sport/musical instrument",
  "patient_hand_function_donot_play": "I do not play a sport or instrument ",
  "patient_hand_function_20": "Using your usual technique for playing your instrument or sport",
  "patient_hand_function_q20_a1": "No difficulty",
  "patient_hand_function_q20_a2": "Mild difficulty",
  "patient_hand_function_q20_a3": "Moderate difficulty",
  "patient_hand_function_q20_a4": "Severe difficulty",
  "patient_hand_function_q20_a5": "Extreme difficulty/unable",
  "patient_hand_function_q21": "Playing your musical instrument or sport as well as you would like",
  "patient_hand_function_q21_a1": "No difficulty",
  "patient_hand_function_q21_a2": "Mild difficulty",
  "patient_hand_function_q21_a3": "Moderate difficulty",
  "patient_hand_function_q21_a4": "Severe difficulty",
  "patient_hand_function_q21_a5": "Extreme difficulty/unable",
  "patient_hand_function_q22": "Spending your usual amount of time practising or playing your instrument or sport",
  "patient_hand_function_q22_a1": "No difficulty",
  "patient_hand_function_q22_a2": "Mild difficulty",
  "patient_hand_function_q22_a3": "Moderate difficulty",
  "patient_hand_function_q22_a4": "Severe difficulty",
  "patient_hand_function_q22_a5": "Extreme difficulty/unable",
  "patient_hand_function_q23": "During the past week, to what extent has your arm, shoulder, or hand problem interfered with your NORMAL SOCIAL/LEISURE ACTIVITIES with family, friends, neighbours, or groups?",
  "patient_hand_function_q23_a1": "Not at all",
  "patient_hand_function_q23_a2": "Slightly",
  "patient_hand_function_q23_a3": "Moderately",
  "patient_hand_function_q23_a4": "Quite a bit",
  "patient_hand_function_q23_a5": "Extremely",
  "patient_hand_function_q24": "During the past week, were you limited in your WORK OR OTHER REGULAR DAILY ACTIVITIES as a result of your arm, shoulder, or hand problem?",
  "patient_hand_function_q24_a1": "Not at all",
  "patient_hand_function_q24_a2": "Slightly",
  "patient_hand_function_q24_a3": "Moderately",
  "patient_hand_function_q24_a4": "Quite a bit",
  "patient_hand_function_q24_a5": "Extremely",
  "patient_hand_questionnaire": "Boston Carpal Tunnel Syndrome Questionnaire",
  "patient_hand_questionnaire_symptom": "Symptom",
  "patient_hand_questionnaire_symptom_instruction": "Please choose the answer that best corresponds to your symptoms.",
  "patient_hand_questionnaire_symptom_instruction_0": "normal",
  "patient_hand_questionnaire_symptom_instruction_1": "slight",
  "patient_hand_questionnaire_symptom_instruction_2": "medium",
  "patient_hand_questionnaire_symptom_instruction_3": "severe",
  "patient_hand_questionnaire_symptom_instruction_4": "very serious",
  "patient_hand_questionnaire_symptom_q1": "How severe is the hand or wrist pain that you have at night?",
  "patient_hand_questionnaire_symptom_q1_a1": "Normal",
  "patient_hand_questionnaire_symptom_q1_a2": "Slight",
  "patient_hand_questionnaire_symptom_q1_a3": "Medium",
  "patient_hand_questionnaire_symptom_q1_a4": "Severe",
  "patient_hand_questionnaire_symptom_q1_a5": "Very serious",
  "patient_hand_questionnaire_symptom_q2": "How often did hand or wrist pain wake you up during a typical night in the past 2 weeks?",
  "patient_hand_questionnaire_symptom_q2_a1": "Normal",
  "patient_hand_questionnaire_symptom_q2_a2": "Once",
  "patient_hand_questionnaire_symptom_q2_a3": "2-3 times",
  "patient_hand_questionnaire_symptom_q2_a4": "4-5 times",
  "patient_hand_questionnaire_symptom_q2_a5": "More than 5 times",
  "patient_hand_questionnaire_symptom_q3": "Do you typically have pain in your hand or wrist during the daytime?",
  "patient_hand_questionnaire_symptom_q3_a1": "No pain",
  "patient_hand_questionnaire_symptom_q3_a2": "Slight",
  "patient_hand_questionnaire_symptom_q3_a3": "Medium",
  "patient_hand_questionnaire_symptom_q3_a4": "Severe",
  "patient_hand_questionnaire_symptom_q3_a5": "Very serious",
  "patient_hand_questionnaire_symptom_q4": "How often do you have hand or wrist pain during daytime?",
  "patient_hand_questionnaire_symptom_q4_a1": "Normal",
  "patient_hand_questionnaire_symptom_q4_a2": "1-2 times/day",
  "patient_hand_questionnaire_symptom_q4_a3": "3-5 times/day",
  "patient_hand_questionnaire_symptom_q4_a4": "More than 5 times/day",
  "patient_hand_questionnaire_symptom_q4_a5": "Continued",
  "patient_hand_questionnaire_symptom_q5": "How long on average does an episode of pain last during the daytime?",
  "patient_hand_questionnaire_symptom_q5_a1": "Normal",
  "patient_hand_questionnaire_symptom_q5_a2": "<10 minutes",
  "patient_hand_questionnaire_symptom_q5_a3": "10-60 minutes continued",
  "patient_hand_questionnaire_symptom_q5_a4": ">60 minutes",
  "patient_hand_questionnaire_symptom_q5_a5": "Continued",
  "patient_hand_questionnaire_symptom_q6": "Do you have numbness (loss of sensation) in your hand",
  "patient_hand_questionnaire_symptom_q6_a1": "Normal",
  "patient_hand_questionnaire_symptom_q6_a2": "Slight",
  "patient_hand_questionnaire_symptom_q6_a3": "Medium",
  "patient_hand_questionnaire_symptom_q6_a4": "Severe",
  "patient_hand_questionnaire_symptom_q6_a5": "Very serious",
  "patient_hand_questionnaire_symptom_q7": "Do you have weakness in your hand or wrist?",
  "patient_hand_questionnaire_symptom_q7_a1": "Normal",
  "patient_hand_questionnaire_symptom_q7_a2": "Slight",
  "patient_hand_questionnaire_symptom_q7_a3": "Medium",
  "patient_hand_questionnaire_symptom_q7_a4": "Severe",
  "patient_hand_questionnaire_symptom_q7_a5": "Very serious",
  "patient_hand_questionnaire_symptom_q8": "Do you have tingling sensation in your hand?",
  "patient_hand_questionnaire_symptom_q8_a1": "Normal",
  "patient_hand_questionnaire_symptom_q8_a2": "Slight",
  "patient_hand_questionnaire_symptom_q8_a3": "Medium",
  "patient_hand_questionnaire_symptom_q8_a4": "Severe",
  "patient_hand_questionnaire_symptom_q8_a5": "Very serious",
  "patient_hand_questionnaire_symptom_q9": "How severe is numbness (loss of sensation) or tingling at night?",
  "patient_hand_questionnaire_symptom_q9_a1": "Normal",
  "patient_hand_questionnaire_symptom_q9_a2": "Slight",
  "patient_hand_questionnaire_symptom_q9_a3": "Medium",
  "patient_hand_questionnaire_symptom_q9_a4": "Severe",
  "patient_hand_questionnaire_symptom_q9_a5": "Very serious",
  "patient_hand_questionnaire_symptom_q10": "How often did hand numbness or tingling wake you up during a typical night during the past 2 weeks?",
  "patient_hand_questionnaire_symptom_q10_a1": "Normal",
  "patient_hand_questionnaire_symptom_q10_a2": "Once",
  "patient_hand_questionnaire_symptom_q10_a3": "2-3 times",
  "patient_hand_questionnaire_symptom_q10_a4": "4-5 times",
  "patient_hand_questionnaire_symptom_q10_a5": "More than 5 times",
  "patient_hand_questionnaire_function": "Function",
  "patient_hand_questionnaire_function_instructions": "Rate the degree of difficulty in doing the following activities due to your symptoms:",
  "patient_hand_questionnaire_function_instructions_0": "No difficulty",
  "patient_hand_questionnaire_function_instructions_1": "Little difficulty",
  "patient_hand_questionnaire_function_instructions_2": "Moderate difficulty",
  "patient_hand_questionnaire_function_instructions_3": "Intense difficulty",
  "patient_hand_questionnaire_function_instructions_4": "Cannot perform at all",
  "patient_hand_questionnaire_function_q1": "Difficulty grasping and using small objects such as keys or pens",
  "patient_hand_questionnaire_function_q1_a1": "No difficulty",
  "patient_hand_questionnaire_function_q1_a2": "Little difficulty",
  "patient_hand_questionnaire_function_q1_a3": "Moderate difficulty",
  "patient_hand_questionnaire_function_q1_a4": "Intense difficulty",
  "patient_hand_questionnaire_function_q1_a5": "Cannot perform at all",
  "patient_hand_questionnaire_function_q2": "Writing",
  "patient_hand_questionnaire_function_q2_a1": "No difficulty",
  "patient_hand_questionnaire_function_q2_a2": "Little difficulty",
  "patient_hand_questionnaire_function_q2_a3": "Moderate difficulty",
  "patient_hand_questionnaire_function_q2_a4": "Intense difficulty",
  "patient_hand_questionnaire_function_q2_a5": "Cannot perform at all",
  "patient_hand_questionnaire_function_q3": "Buttoning of clothes",
  "patient_hand_questionnaire_function_q3_a1": "No difficulty",
  "patient_hand_questionnaire_function_q3_a2": "Little difficulty",
  "patient_hand_questionnaire_function_q3_a3": "Moderate difficulty",
  "patient_hand_questionnaire_function_q3_a4": "Intense difficulty",
  "patient_hand_questionnaire_function_q3_a5": "Cannot perform at all",
  "patient_hand_questionnaire_function_q4": "Holding a book while reading",
  "patient_hand_questionnaire_function_q4_a1": "No difficulty",
  "patient_hand_questionnaire_function_q4_a2": "Little difficulty",
  "patient_hand_questionnaire_function_q4_a3": "Moderate difficulty",
  "patient_hand_questionnaire_function_q4_a4": "Intense difficulty",
  "patient_hand_questionnaire_function_q4_a5": "Cannot perform at all",
  "patient_hand_questionnaire_function_q5": "Gripping of a telephone handle",
  "patient_hand_questionnaire_function_q5_a1": "No difficulty",
  "patient_hand_questionnaire_function_q5_a2": "Little difficulty",
  "patient_hand_questionnaire_function_q5_a3": "Moderate difficulty",
  "patient_hand_questionnaire_function_q5_a4": "Intense difficulty",
  "patient_hand_questionnaire_function_q5_a5": "Cannot perform at all",
  "patient_hand_questionnaire_function_q6": "Opening of jars",
  "patient_hand_questionnaire_function_q6_a1": "No difficulty",
  "patient_hand_questionnaire_function_q6_a2": "Little difficulty",
  "patient_hand_questionnaire_function_q6_a3": "Moderate difficulty",
  "patient_hand_questionnaire_function_q6_a4": "Intense difficulty",
  "patient_hand_questionnaire_function_q6_a5": "Cannot perform at all",
  "patient_hand_questionnaire_function_q7": "Household chores",
  "patient_hand_questionnaire_function_q7_a1": "No difficulty",
  "patient_hand_questionnaire_function_q7_a2": "Little difficulty",
  "patient_hand_questionnaire_function_q7_a3": "Moderate difficulty",
  "patient_hand_questionnaire_function_q7_a4": "Intense difficulty",
  "patient_hand_questionnaire_function_q7_a5": "Cannot perform at all",
  "patient_hand_questionnaire_function_q8": "Carrying of grocery basket",
  "patient_hand_questionnaire_function_q8_a1": "No difficulty",
  "patient_hand_questionnaire_function_q8_a2": "Little difficulty",
  "patient_hand_questionnaire_function_q8_a3": "Moderate difficulty",
  "patient_hand_questionnaire_function_q8_a4": "Intense difficulty",
  "patient_hand_questionnaire_function_q8_a5": "Cannot perform at all",
  "patient_hand_questionnaire_function_q9": "Bathing and dressing",
  "patient_hand_questionnaire_function_q9_a1": "No difficulty",
  "patient_hand_questionnaire_function_q9_a2": "Little difficulty",
  "patient_hand_questionnaire_function_q9_a3": "Moderate difficulty",
  "patient_hand_questionnaire_function_q9_a4": "Intense difficulty",
  "patient_hand_questionnaire_function_q9_a5": "Cannot perform at all",
  "patient_hand_uram": "Hand And Wrist URAM Scale",
  "patient_hand_uram_canyou": "Can you...",
  "patient_hand_uram_q1": "Wash yourself with a flannel, keeping your hand flat",
  "patient_hand_uram_q1_a1": "Without difficulty",
  "patient_hand_uram_q1_a2": "With very little difficulty",
  "patient_hand_uram_q1_a3": "With some difficulty",
  "patient_hand_uram_q1_a4": "With much difficulty",
  "patient_hand_uram_q1_a5": "Almost impossible",
  "patient_hand_uram_q1_a6": "Impossible",
  "patient_hand_uram_q2": "Wash your face",
  "patient_hand_uram_q2_a1": "Without difficulty",
  "patient_hand_uram_q2_a2": "With very little difficulty",
  "patient_hand_uram_q2_a3": "With some difficulty",
  "patient_hand_uram_q2_a4": "With much difficulty",
  "patient_hand_uram_q2_a5": "Almost impossible",
  "patient_hand_uram_q2_a6": "Impossible",
  "patient_hand_uram_q3": "Hold a bottle in one hand",
  "patient_hand_uram_q3_a1": "Without difficulty",
  "patient_hand_uram_q3_a2": "With very little difficulty",
  "patient_hand_uram_q3_a3": "With some difficulty",
  "patient_hand_uram_q3_a4": "With much difficulty",
  "patient_hand_uram_q3_a5": "Almost impossible",
  "patient_hand_uram_q3_a6": "Impossible",
  "patient_hand_uram_q4": "Shake someone’s hand",
  "patient_hand_uram_q4_a1": "Without difficulty",
  "patient_hand_uram_q4_a2": "With very little difficulty",
  "patient_hand_uram_q4_a3": "With some difficulty",
  "patient_hand_uram_q4_a4": "With much difficulty",
  "patient_hand_uram_q4_a5": "Almost impossible",
  "patient_hand_uram_q4_a6": "Impossible",
  "patient_hand_uram_q5": "Stroke something or caress someone",
  "patient_hand_uram_q5_a1": "Without difficulty",
  "patient_hand_uram_q5_a2": "With very little difficulty",
  "patient_hand_uram_q5_a3": "With some difficulty",
  "patient_hand_uram_q5_a4": "With much difficulty",
  "patient_hand_uram_q5_a5": "Almost impossible",
  "patient_hand_uram_q5_a6": "Impossible",
  "patient_hand_uram_q6": "Clap your hands",
  "patient_hand_uram_q6_a1": "Without difficulty",
  "patient_hand_uram_q6_a2": "With very little difficulty",
  "patient_hand_uram_q6_a3": "With some difficulty",
  "patient_hand_uram_q6_a4": "With much difficulty",
  "patient_hand_uram_q6_a5": "Almost impossible",
  "patient_hand_uram_q6_a6": "Impossible",
  "patient_hand_uram_q7": "Spread out your fingers",
  "patient_hand_uram_q7_a1": "Without difficulty",
  "patient_hand_uram_q7_a2": "With very little difficulty",
  "patient_hand_uram_q7_a3": "With some difficulty",
  "patient_hand_uram_q7_a4": "With much difficulty",
  "patient_hand_uram_q7_a5": "Almost impossible",
  "patient_hand_uram_q7_a6": "Impossible",
  "patient_hand_uram_q8": "Lean on your hand",
  "patient_hand_uram_q8_a1": "Without difficulty",
  "patient_hand_uram_q8_a2": "With very little difficulty",
  "patient_hand_uram_q8_a3": "With some difficulty",
  "patient_hand_uram_q8_a4": "With much difficulty",
  "patient_hand_uram_q8_a5": "Almost impossible",
  "patient_hand_uram_q8_a6": "Impossible",
  "patient_hand_uram_q9": "Pick up small objects with your thumb and index finger",
  "patient_hand_uram_q9_a1": "Without difficulty",
  "patient_hand_uram_q9_a2": "With very little difficulty",
  "patient_hand_uram_q9_a3": "With some difficulty",
  "patient_hand_uram_q9_a4": "With much difficulty",
  "patient_hand_uram_q9_a5": "Almost impossible",
  "patient_hand_uram_q9_a6": "Impossible",
  "patient_hand_quality_of_life": "Quality of Life",
  "patient_hand_quality_of_life_instruction": "Please rate the following to best describe your HEALTH TODAY:",
  "patient_hand_quality_of_life_instruction_0": "No problem",
  "patient_hand_quality_of_life_instruction_1": "Slight",
  "patient_hand_quality_of_life_instruction_2": "Moderate",
  "patient_hand_quality_of_life_instruction_3": "Severe",
  "patient_hand_quality_of_life_instruction_4": "Extreme/unable",
  "patient_hand_quality_of_life_q1": "Problem in walking about",
  "patient_hand_quality_of_life_q1_a1": "No problem",
  "patient_hand_quality_of_life_q1_a2": "Slight",
  "patient_hand_quality_of_life_q1_a3": "Moderate",
  "patient_hand_quality_of_life_q1_a4": "Severe",
  "patient_hand_quality_of_life_q1_a5": "Unable",
  "patient_hand_quality_of_life_q2": "Problem with washing or dressing self",
  "patient_hand_quality_of_life_q2_a1": "No problem",
  "patient_hand_quality_of_life_q2_a2": "Slight",
  "patient_hand_quality_of_life_q2_a3": "Moderate",
  "patient_hand_quality_of_life_q2_a4": "Severe",
  "patient_hand_quality_of_life_q2_a5": "Unable",
  "patient_hand_quality_of_life_q3": "Problem with doing usual activities (eg work, study, housework, family, or leisure activities)",
  "patient_hand_quality_of_life_q3_a1": "No problem",
  "patient_hand_quality_of_life_q3_a2": "Slight",
  "patient_hand_quality_of_life_q3_a3": "Moderate",
  "patient_hand_quality_of_life_q3_a4": "Severe",
  "patient_hand_quality_of_life_q3_a5": "Unable",
  "patient_hand_quality_of_life_q4": "Pain or discomfort",
  "patient_hand_quality_of_life_q4_a1": "None",
  "patient_hand_quality_of_life_q4_a2": "Slight",
  "patient_hand_quality_of_life_q4_a3": "Moderate",
  "patient_hand_quality_of_life_q4_a4": "Severe",
  "patient_hand_quality_of_life_q4_a5": "Extreme",
  "patient_hand_quality_of_life_q5": "Anxiety/Depression",
  "patient_hand_quality_of_life_q5_a1": "Not anxious/depressed",
  "patient_hand_quality_of_life_q5_a2": "Slightly anxious/depressed",
  "patient_hand_quality_of_life_q5_a3": "Moderately anxious/depressed",
  "patient_hand_quality_of_life_q5_a4": "Severely anxious/depressed",
  "patient_hand_quality_of_life_q5_a5": "Extremely anxious/depressed",
  "patient_hand_quality_of_life_q6": "How good or bad your health is TODAY? (100 means best health, 0 means worst health you can imagine)",
  "patient_hand_quality_of_life_q6_a1": "Worst",
  "patient_hand_quality_of_life_q6_a2": "Best",
  "patient_hand_satisfaction": "Satisfaction",
  "patient_hand_satisfaction_instrucion": "Please state how satisfied you are with the following:",
  "patient_hand_satisfaction_instrucion_0": "Very dissatisfied",
  "patient_hand_satisfaction_instrucion_1": "Dissatisfied",
  "patient_hand_satisfaction_instrucion_2": "Neither satisfied nor dissatisfied",
  "patient_hand_satisfaction_instrucion_3": "Somewhat satisfied",
  "patient_hand_satisfaction_instrucion_4": "Very satisfied",
  "patient_hand_satisfaction_q1": "Motion of your FINGERS",
  "patient_hand_satisfaction_q1_na": "Not applicable",
  "patient_hand_satisfaction_q1_a1": "Very dissatisfied",
  "patient_hand_satisfaction_q1_a2": "Dissatisfied",
  "patient_hand_satisfaction_q1_a3": "Neither satisfied nor dissatisfied",
  "patient_hand_satisfaction_q1_a4": "Somewhat satisfied",
  "patient_hand_satisfaction_q1_a5": "Very satisfied",
  "patient_hand_satisfaction_q2": "Motion of your WRIST",
  "patient_hand_satisfaction_q2_na": "Not applicable",
  "patient_hand_satisfaction_q2_a1": "Very dissatisfied",
  "patient_hand_satisfaction_q2_a2": "Dissatisfied",
  "patient_hand_satisfaction_q2_a3": "Neither satisfied nor dissatisfied",
  "patient_hand_satisfaction_q2_a4": "Somewhat satisfied",
  "patient_hand_satisfaction_q2_a5": "Very satisfied",
  "patient_hand_satisfaction_q3": "Look of your HAND",
  "patient_hand_satisfaction_q3_na": "Not applicable",
  "patient_hand_satisfaction_q3_a1": "Very dissatisfied",
  "patient_hand_satisfaction_q3_a2": "Dissatisfied",
  "patient_hand_satisfaction_q3_a3": "Neither satisfied nor dissatisfied",
  "patient_hand_satisfaction_q3_a4": "Somewhat satisfied",
  "patient_hand_satisfaction_q3_a5": "Very satisfied",
  "patient_hand_satisfaction_q4": "Your hand/wrist treatment",
  "patient_hand_satisfaction_q4_na": "Not applicable",
  "patient_hand_satisfaction_q4_a1": "Very dissatisfied",
  "patient_hand_satisfaction_q4_a2": "Dissatisfied",
  "patient_hand_satisfaction_q4_a3": "Neither satisfied nor dissatisfied",
  "patient_hand_satisfaction_q4_a4": "Somewhat satisfied",
  "patient_hand_satisfaction_q4_a5": "Very satisfied",
  "patient_hand_complete": "End of Questionnaire",
  "patient_hand_complete_text1": "Thank you for taking the time to fill out this form.",
  "patient_hand_complete_text2": "Please click submit to complete and see your scores.",
  "patient_hand_complete_text3": "Your answers will be treated confidentially and any reports will only use de-identified information",
  "patient_hand_result": "Results",
  "patient_hand_result_text1": "Below are your pain and hand & wrist function scores, which are calculated from your questionnaire.",
  "patient_hand_result_function": "Function",
  "patient_hand_result_pain": "Pain",
  "patient_hand_result_health_perception": "Overall Health Perception",
  "patient_hand_result_self_evaluation": "Hand and Wrist Scoring Self-Evaluation",
  "patient_hand_result_text2": "Your Doctor will be with you shortly.",
  "patient_general_history": "HISTORY OF PRESENT COMPLAINT/INJURY/ILLNESS",
  "patient_general_history_q1": "Main Complaint (explain your complaint in your own words)",
  "patient_general_history_q2": "Date Problem Began",
  "patient_general_history_q3": "Have you had any imaging?",
  "patient_general_history_q3_a1": "None",
  "patient_general_history_q3_a2": "X-ray",
  "patient_general_history_q3_a3": "Ultrasound",
  "patient_general_history_q3_a4": "MRI",
  "patient_general_history_q3_a5": "MRI Arthrogram (MRI with dye in joint)",
  "patient_general_history_q3_a6": "CT",
  "patient_general_history_q3_a7": "CT Arthrogram (CT with dye in joint)",
  "patient_general_history_q4": "Have you had any further investigations into your condition that are not listed above?",
  "patient_general_history_q4_placeholder": "Other investigations",
  "patient_general_past_history": "PAST MEDICAL HISTORY / ONGOING MEDICAL ISSUES",
  "patient_general_past_history_q1": "Do you have any known allergies? (drug or food)",
  "patient_general_past_history_q1_a1": "No",
  "patient_general_past_history_q1_a2": "Yes",
  "patient_general_past_history_placeholder": "Specify allergy and type of reaction",
  "patient_general_past_history_q2": "Are you allergic to latex?",
  "patient_general_past_history_q2_a1": "No",
  "patient_general_past_history_q2_a2": "Yes",
  "patient_general_past_history_q3": "Do you have or are you being treated for high blood pressure?",
  "patient_general_past_history_q3_a1": "No",
  "patient_general_past_history_q3_a2": "Yes",
  "patient_general_past_history_q3_a2_placeholder": "Specify medications for high blood pressure",
  "patient_general_past_history_q4": "Do you currently or have you had any heart conditions/illnesses?",
  "patient_general_past_history_q4_a1": "No",
  "patient_general_past_history_q4_a2": "Yes",
  "patient_general_past_history_q4_a2_o1": "Select condition",
  "patient_general_past_history_q4_a2_o2": "Angina",
  "patient_general_past_history_q4_a2_o3": "Heart attack",
  "patient_general_past_history_q4_a2_o4": "Heart Failure (fluid in your lungs)",
  "patient_general_past_history_q4_a2_o5": "Murmur or Heart Valve Problem",
  "patient_general_past_history_q4_a2_o6": "Palpitations/Irregular Heartbeat",
  "patient_general_past_history_q4_a2_o7": "Implanted Device (ICD, pacemaker)",
  "patient_general_past_history_q4_a2_o8": "Cardiac surgery ",
  "patient_general_past_history_q4_a2_o9": "Carotid artery surgery ",
  "patient_general_past_history_q4_a2_o10": "Other heart condition",
  "patient_general_past_history_q4_a2_o8_q1_placeholder": "Specify cardiac surgery",
  "patient_general_past_history_q4_a2_o10_q1_placeholder": "Specify other heart condition",
  "patient_general_past_history_q5": "Do you have a cardiologist?",
  "patient_general_past_history_q5_a1": "No",
  "patient_general_past_history_q5_a2": "Yes",
  "patient_general_past_history_q5_a2_placeholder": "Name and Practice",
  "patient_general_past_history_q6": "Do you have problems with circulations in the legs (peripheral vascular disease)?",
  "patient_general_past_history_q6_a1": "No",
  "patient_general_past_history_q6_a2": "Yes",
  "patient_general_past_history_q6_a2_placeholder": "Describe",
  "patient_general_past_history_q7": "Have you taken a blood thinner in the last month?",
  "patient_general_past_history_q7_a1": "No",
  "patient_general_past_history_q7_a2": "Yes",
  "patient_general_past_history_q7_a2_q1": "What is it ?",
  "patient_general_past_history_q7_a2_q1_a1": "Aspirin",
  "patient_general_past_history_q7_a2_q1_a2": "Clopidogrel/Plavix",
  "patient_general_past_history_q7_a2_q1_a3": "Rivaroxaban",
  "patient_general_past_history_q7_a2_q1_a4": "Eliquis",
  "patient_general_past_history_q7_a2_q1_a5": "Others",
  "patient_general_past_history_q7_a2_q1_a5_placeholder": "Specify blood thinner",
  "patient_general_past_history_q8": "Have you had any problems with your lungs or chest?",
  "patient_general_past_history_q8_a1": "No",
  "patient_general_past_history_q8_a2": "Yes",
  "patient_general_past_history_q8_a2_o1": "Cough with sputum?",
  "patient_general_past_history_q8_a2_o2": "Any trouble with your breathing?",
  "patient_general_past_history_q8_a2_o3": "Others",
  "patient_general_past_history_q8_a2_o2_o1": "Asthma",
  "patient_general_past_history_q8_a2_o2_o2": "High Blood Pressure in the Lungs (pulmonary hypertension)",
  "patient_general_past_history_q8_a2_o2_o3": "Pulmonary Fibrosis",
  "patient_general_past_history_q8_a2_o2_o4": "Sarcoidosis",
  "patient_general_past_history_q8_a2_o2_o5": "COPD or Emphysema",
  "patient_general_past_history_q8_a2_o2_o6": "Cystic Fibrosis",
  "patient_general_past_history_q8_a2_o2_o7": "TB (tuberculosis)",
  "patient_general_past_history_q8_a2_o2_o8": "Others",
  "patient_general_past_history_q8_a2_o3_placehold": "Specify",
  "patient_general_past_history_q8_a2_o2_o8_placeholder": "Specify",
  "patient_general_past_history_q9": "Have you taken steroids (prednisone or cortisone) in the last year?",
  "patient_general_past_history_q9_a1": "No",
  "patient_general_past_history_q9_a2": "Yes",
  "patient_general_past_history_q10": "Do you use home oxygen?",
  "patient_general_past_history_q10_a1": "No",
  "patient_general_past_history_q10_a2": "Yes",
  "patient_general_past_history_q11": "Do you have a respiratory specialist?",
  "patient_general_past_history_q11_a1": "No",
  "patient_general_past_history_q11_a2": "Yes",
  "patient_general_past_history_q11_a2_placeholder": "Name and Practice",
  "patient_general_past_history_q12": "Do you have excessive snoring or sleep apnoea?",
  "patient_general_past_history_q12_a1": "No",
  "patient_general_past_history_q12_a2": "Yes",
  "patient_general_past_history_q12_a2_q1": "Is your sleep apnoea treated with CPAP or BiPAP?",
  "patient_general_past_history_q12_a2_q1_a1": "No",
  "patient_general_past_history_q12_a2_q1_a2": "Yes",
  "patient_general_past_history_q13": "Have you ever had liver problems?",
  "patient_general_past_history_q13_a1": "No",
  "patient_general_past_history_q13_a2": "Yes",
  "patient_general_past_history_q13_a2_o1": "Selected",
  "patient_general_past_history_q13_a2_o2": "Cirrhosis",
  "patient_general_past_history_q13_a2_o3": "Hepatitis A, B, or C",
  "patient_general_past_history_q13_a2_o4": "Jaundice",
  "patient_general_past_history_q13_a2_o5": "Others",
  "patient_general_past_history_q13_a2_o5_placeholder": "Specify",
  "patient_general_past_history_q14": "Do you have frequent heartburn, ulcers, or hiatus hernia?",
  "patient_general_past_history_q14_a1": "No",
  "patient_general_past_history_q14_a2": "Yes",
  "patient_general_past_history_q15": "Have you ever had kidney disease?",
  "patient_general_past_history_q15_a1": "No",
  "patient_general_past_history_q15_a2": "Yes",
  "patient_general_past_history_q15_a2_q1": "Are you a dialysis patient? Peritoneal? Haemodialysis",
  "patient_general_past_history_q15_a2_q1_a1": "No",
  "patient_general_past_history_q15_a2_q1_a2": "Yes",
  "patient_general_past_history_q15_a2_placeholder": "Specify kidney disease",
  "patient_general_past_history_q15_a2_q1_a2_placeholder": "Specify type and schedule of dialysis",
  "patient_general_past_history_q16": "Do you have diabetes? ",
  "patient_general_past_history_q16_a1": "No",
  "patient_general_past_history_q16_a2": "Yes",
  "patient_general_past_history_q16_a2_q1": "How is your diabetes managed? Diet/Oral medication or insulin?",
  "patient_general_past_history_q16_a2_q1_placeholder": "Specify",
  "patient_general_past_history_q17": "Do you have thyroid problems?",
  "patient_general_past_history_q17_a1": "No",
  "patient_general_past_history_q17_a2": "Yes",
  "patient_general_past_history_q18": "Have you ever had Epilepsy, Seizures, or Convulsions?",
  "patient_general_past_history_q18_a1": "No",
  "patient_general_past_history_q18_a2": "Yes",
  "patient_general_past_history_q18_a2_q1": "When was your last seizure?",
  "patient_general_past_history_q18_a2_q1_placeholder": "Specify",
  "patient_general_past_history_q19": "Have you had problems with your Nerves or Muscles?",
  "patient_general_past_history_q19_a1": "No",
  "patient_general_past_history_q19_a2": "Yes",
  "patient_general_past_history_q19_a2_o1": "Seizures",
  "patient_general_past_history_q19_a2_o1_placeholder": "When ?",
  "patient_general_past_history_q19_a2_o2": "TIA (Transient ischaemic attack/Ministroke) or stroke",
  "patient_general_past_history_q19_a2_o2_placeholder": "When ?",
  "patient_general_past_history_q19_a2_o3": "Facial, leg or arm weakness",
  "patient_general_past_history_q19_a2_o4": "Neurologic disorder (examples: Multiple sclerosis, ALS, Alzheimer’s)",
  "patient_general_past_history_q19_a2_o4_placeholder": "Specify",
  "patient_general_past_history_q19_a2_o5": "Muscular disorder (examples: Myasthenia gravis, Muscular dystrophy)",
  "patient_general_past_history_q19_a2_o5_placeholder": "Specify",
  "patient_general_past_history_q19_a2_o6": "Problems with hearing, vision or memory",
  "patient_general_past_history_q19_a2_o7": "Chronic Pain",
  "patient_general_past_history_q19_a2_o8": "Others",
  "patient_general_past_history_q19_a2_o8_placeholder": "Specify",
  "patient_general_past_history_q20": "Do you have arthritis? ",
  "patient_general_past_history_q20_a1": "No",
  "patient_general_past_history_q20_a2": "Yes",
  "patient_general_past_history_q20_a2_o1": "Osteoarthritis",
  "patient_general_past_history_q20_a2_o2": "Rheumatoid arthritis",
  "patient_general_past_history_q20_a2_o3": "Others",
  "patient_general_past_history_q20_a2_o3_placeholder": "Specify",
  "patient_general_past_history_q21": "Have you ever been treated for a psychiatric illness?",
  "patient_general_past_history_q21_a1": "No",
  "patient_general_past_history_q21_a2": "Yes",
  "patient_general_past_history_q21_a2_o1": "Severe anxiety",
  "patient_general_past_history_q21_a2_o2": "Depression",
  "patient_general_past_history_q21_a2_o3": "Others",
  "patient_general_past_history_q21_a2_o3_placeholder": "Specify",
  "patient_general_past_history_q22": "Have you ever been diagnosed with a bleeding disorder?",
  "patient_general_past_history_q22_a1": "No",
  "patient_general_past_history_q22_a2": "Yes",
  "patient_general_past_history_q22_a2_o1": "Leukemia or Lymphoma",
  "patient_general_past_history_q22_a2_o2": "Haemophilia",
  "patient_general_past_history_q22_a2_o3": "Blood Clots",
  "patient_general_past_history_q22_a2_o4": "Anaemia",
  "patient_general_past_history_q22_a2_o5": "Sickle Cell Disease",
  "patient_general_past_history_q22_a2_o6": "Others",
  "patient_general_past_history_q22_a2_o6_placeholder": "Specify",
  "patient_general_past_history_q23": "Are you anaemic?",
  "patient_general_past_history_q23_a1": "No",
  "patient_general_past_history_q23_a2": "Yes",
  "patient_general_past_history_q24": "Have you ever had a blood clot in your legs or lungs?",
  "patient_general_past_history_q24_a1": "No",
  "patient_general_past_history_q24_a2": "Yes",
  "patient_general_past_history_q25": "Have you or anyone in your family had serious problems with:",
  "patient_general_past_history_q25_a1": "Nose bleeds",
  "patient_general_past_history_q25_a1_o1": "No",
  "patient_general_past_history_q25_a1_o2": "Yes",
  "patient_general_past_history_q25_a2": "Bleeding with tooth extractions",
  "patient_general_past_history_q25_a2_o1": "No",
  "patient_general_past_history_q25_a2_o2": "Yes",
  "patient_general_past_history_q25_a3": "Bleeding after surgery",
  "patient_general_past_history_q25_a3_o1": "No",
  "patient_general_past_history_q25_a3_o2": "Yes",
  "patient_general_past_history_q26": "Have you ever been diagnosed with cancer? ",
  "patient_general_past_history_q26_a1": "No",
  "patient_general_past_history_q26_a2": "Yes",
  "patient_general_past_history_q26_a2_placeholder1": " Specify type of cancer",
  "patient_general_past_history_q26_a2_placeholder2": "When?",
  "patient_general_past_history_q27": "Have you ever been treated with chemotherapy or radiation therapy?",
  "patient_general_past_history_q27_a1": "No",
  "patient_general_past_history_q27_a2": "Yes",
  "patient_general_past_history_q27_a2_placeholder1": "Name of treatment",
  "patient_general_past_history_q27_a2_placeholder2": "Last Treatment Date",
  "patient_general_past_history_q28": "Have you or your relative had any problem with anaesthesia?",
  "patient_general_past_history_q28_a1": "No",
  "patient_general_past_history_q28_a2": "Yes",
  "patient_general_past_history_q28_a2_q1": "Which problems?",
  "patient_general_past_history_q28_a2_q1_placeholder": "Select",
  "patient_general_past_history_q28_a2_q1_o1": "Severe nausea or vomiting after surgery",
  "patient_general_past_history_q28_a2_q1_o2": "Malignant hyperthermia (in family who are blood relatives or in yourself)",
  "patient_general_past_history_q28_a2_q1_o3": "Breathing problem or difficulty with insertion of the anaesthesia breathing tube? ",
  "patient_general_past_history_q28_a2_q1_o4": "Problems with placement of a breathing tube",
  "patient_general_past_history_q28_a2_q1_o5": "Others",
  "patient_general_past_history_q28_a2_q1_o5_placeholder": "Specify",
  "patient_general_past_history_q29": "Do you have any of the following?",
  "patient_general_past_history_q29_q1": "Chipped or loose teeth, dentures, partials",
  "patient_general_past_history_q29_q1_a1": "No",
  "patient_general_past_history_q29_q1_a2": "Yes",
  "patient_general_past_history_q29_q2": "Problems moving your neck",
  "patient_general_past_history_q29_q2_a1": "No",
  "patient_general_past_history_q29_q2_a2": "Yes",
  "patient_general_past_history_q29_q3": "Problems opening your mouth",
  "patient_general_past_history_q29_q3_a1": "No",
  "patient_general_past_history_q29_q3_a2": "Yes",
  "patient_general_past_history_q30": "Could you be pregnant?",
  "patient_general_past_history_q30_a1": "No",
  "patient_general_past_history_q30_a2": "Yes",
  "patient_general_past_history_q30_not_applicable": "Not applicable",
  "patient_general_past_history_q30_a2_q1": "Last Menstrual Period",
  "patient_general_past_history_q30_a2_q1_placeholder": "Last Menstrual Period",
  "patient_general_past_history_q31": "Medical History : Please list any additional medical illnesses you have that were not noted above",
  "patient_general_past_history_q31_q1": "List all medications (include over the counter medicine ,inhalers, patches, drops, vitamins, minerals,supplements, herbs)",
  "patient_general_past_history_q31_q2": "Indicate medication name, dose, frequency, and reason for taking it",
  "patient_general_past_history_q31_q2_placeholder": "Medication name, dose, frequency, reason for taking",
  "patient_general_past_history_q31_q3": "Dose",
  "patient_general_past_history_q31_q4": "Often",
  "patient_general_past_history_q31_q5": "Reason of taking it",
  "patient_general_past_history_q31_q6": "Surgical History",
  "patient_general_past_history_q31_q6_placeholder": "Specify procedure and procedure date",
  "patient_general_social_history": "SOCIAL HISTORY",
  "patient_general_social_history_q1": "Do you live alone?",
  "patient_general_social_history_q1_a1": "No",
  "patient_general_social_history_q1_a2": "Yes",
  "patient_general_social_history_q1_a1_q1": "Who lives with you?",
  "patient_general_social_history_q1_a1_q1_placeholder": "Who lives with you?",
  "patient_general_social_history_q2": "Do you smoke?",
  "patient_general_social_history_q2_a1": "No",
  "patient_general_social_history_q2_a2": "Yes",
  "patient_general_social_history_q2_a2_q1": "How many cigarettes per day?",
  "patient_general_social_history_q2_a2_q1_placeholder": "Number of cigarettes ",
  "patient_general_social_history_q2_a2_q2": "Number of years?",
  "patient_general_social_history_q2_a2_q2_placeholder": "Years",
  "patient_general_social_history_q3": "Have you smoked in the past?",
  "patient_general_social_history_q3_a1": "No",
  "patient_general_social_history_q3_a2": "Yes",
  "patient_general_social_history_q3_a2_q1": "When did you stop smoking?",
  "patient_general_social_history_q3_a2_q1_placeholder": "When did you stop smoking?",
  "patient_general_social_history_q3_a2_q2_placeholder": "Years",
  "patient_general_social_history_q4": "Do you drink alcohol?",
  "patient_general_social_history_q4_a1": "No",
  "patient_general_social_history_q4_a2": "Yes",
  "patient_general_social_history_q4_a2_q1_placeholder": "Number of standard drinks a week ",
  "patient_general_social_history_q5": "Do you use or have you ever used any recreational or street (illicit) drugs, marijuana or opioids not prescribed for you?",
  "patient_general_social_history_q5_a1": "No",
  "patient_general_social_history_q5_a2": "Yes",
  "patient_general_social_history_q5_a2_placeholder": "Specify drugs",
  "patient_general_complete": "End of Questionnaire",
  "patient_general_complete_text1": "Thank you for taking the time to fill out this form.",
  "patient_general_complete_text2": "Please click submit to complete and see your scores.",
  "patient_general_complete_text3": "Your answers will be treated confidentially and any reports will only use de-identified information",
  "AppVersion": "App Version",
  "Complete_personal_info": "Complete personal information",
  "To_complete_the_sign_up": "To complete the sign up, please fill in your information below.",
  "Select_Existing_Profile": "Select Existing Profile",
  "Get_In": "Enter",
  "Create_new_patient": "Create a new patient",
  "myProfile": "My Profile",
  "First_Name": "First Name*",
  "Last_Name": "Last Name*",
  "DOB": "Date of Birth*",
  "Sex": "Sex*",
  "Address": "Address*",
  "Postcode": "Postcode*",
  "Suburb": "Suburb*",
  "Country_Code": "Country Code",
  "Mobile_Phone": "Mobile Phone",
  "Home_Phone": "Home Phone",
  "Email": "Email*",
  "Emergency_Contact": "Emergency Contact*",
  "Phone": "Phone*",
  "Relationship": "Relationship*",
  "Regular_GP_Name": "Regular GP Name*",
  "Practice_Name": "Practice Name*",
  "Medicare_Card_Number": "Medicare Card Number",
  "Ref": "Ref",
  "Message": "Messages",
  "Expiry": "Expiry",
  "Private_Health_Fund": "Private Health Fund",
  "Membership_Number": "Membership Number",
  "Claim_Type": "Claim Type",
  "Work_Cover": "Work Cover",
  "Employer": "Employer",
  "Third_Party_Claim_Number": "Third Party Claim Number",
  "Third_Party_Name": "Third Party Name",
  "DVA_Concession_Card": "DVA Concession Card",
  "Number": "Number",
  "Defence_Personnel": "Defence Personnel",
  "I_agree": "I agree to the processing of my personal data and have read the",
  "Privacy_Policy": "Privacy Policy.",
  "visitClinic": "Book Appointment",
  "selectdocOrg": "Select Doctor & Organization*",
  "doctorOrganization": "Doctor Organization",
  "Select_Treatment_Category": "Select Treatment Category*",
  "Do_you_want_to_submit_patient_form_or_not": "Do you want to submit patient form or not?",
  "areyousurewanttocontinueyourform": "Your previous form is pending. Do you want to continue?",
  "myVisit": "Past Visits",
  "View_score": "View score",
  "upcoming_visits": "Upcoming",
  "No_data_found": "No data found",
  "Doctor_Name": "Doctor Name",
  "Appointment_Date_Time": "Appointment Date & Time",
  "Clinic_Name": "Clinic Name",
  "Clinic_Address": "Clinic Address",
  "akmed": "Akunah Med",
  "Type": "Type",
  "Date_Time": "Date & Time",
  "Action": "Action",
  "View": "View",
  "No_Messages": "No Messages",
  "settings": "Settings",
  "Language": "Language",
  "language": "Language & Region",
  "timezone": "Timezone",
  "Timeformat": "Time format",
  "List_of_my_profile": "My Profiles",
  "next_button": "Next",
  "back_button": "Back",
  "Continue": "Continue",
  "Close": "Close",
  "submit": "Submit",
  "Sign_out": "Back to profile",
  "Results": "Results",
  "update": "Form updated successfully",
  "Personal_Data": "Personal Data",
  "Date_of_Birth": "Date of Birth",
  "Hip_arthritis": "Hip arthritis ?*",
  "Area_of_foot_ankle": "Area of foot/ankle affected?*",
  "Diagnosis": "Diagnosis ?*",
  "Evaluated_Shoulder": "Evaluated Shoulder",
  "Dominant_Hand": "Dominant Hand*",
  "Age": "Age",
  "No": "No",
  "Yes": "Yes",
  "visitNo": "visitNo",
  "visitYes": "visitYes",
  "shoulderPainR": "shoulderPainR",
  "Is_it_initial_visit": "Is this your initial visit with",
  "for_the_problem": "for this problem?*",
  "Diagnosis_Procedure": "Diagnosis / Procedure",
  "Procedure": "Procedure",
  "Evaluated_sdfs": "Evaluated side*",
  "Please_Select_previous_visits": "Please select previous visits*",
  "Follow_Up": "Follow Up",
  "Assessment_Date": "Assessment Date",
  "Do_you_have_Dupuytren_disease": "Do you have Dupuytren's disease?",
  "Do_you_have_carpal_tunnel_syndrome": "Do you have carpal tunnel syndrome?",
  "Is_your_unaffected_shoulder_pain": "Is your unaffected shoulder pain-free and allows normal function?",
  "Is_your_unaffected_hip_pain": "Is your unaffected hip pain-free and allows normal function?",
  "Is_your_unaffected_knee_pain": "Is your unaffected knee pain-free and allows normal function?",
  "Is_your_unaffected_elbow_pain": "Is your unaffected elbow pain-free and allows normal function?",
  "Is_your_unaffected_foot_and_ankle_pain": "Is your unaffected foot and ankle pain-free and allows normal function?",
  "Is_your_unaffected_hand_pain": "Is your unaffected hand and wrist pain-free and allows normal function?",
  "Patient_Consent": "Akunah Patient Information and Free Prior Consent Form",
  "Patient_Last_Name": "Patient Last Name",
  "Patient_First_Name": "Patient First Name",
  "Patient_Date_of_Birth": "Patient Date of Birth",
  "information": "Information",
  "I_am_informedDDD": "For the purpose of the evaluation, planning, and outcome assessment of your orthopaedic treatment, your doctor,",
  "Clinician": "(hereafter the “Clinician”) uses evaluation, outcome data collection and analysis, and/or pre-surgical planning services and software provided by Akunah Medical Technology Pty Ltd and its subsidiaries (“Akunah”).",
  "For_that": "For that purpose, the Clinician will transfer your personal data to Akunah, including computed tomography (CT) scans of your body and other medical data.",
  "Akunah_softawre": "Akunah's software and services may involve the evaluation of images, assessment of medical data, creation of personalised 3D models of your anatomy along with key anatomical measurements, surgery planning, and support for surgery, which will be shared with the Clinician.",
  "the_clinic": "The Clinician will use the evaluation and pre-surgical planning for your orthopaedic treatment.",
  "oportunnity": "You also have the opportunity to provide personal data through Akunah's patient portal and access Akunah's models and plans.",
  "akunah_approch": "Akunah’s approach to privacy and security",
  "akunah_approch1": "The privacy and security of the data, which Akunah will collect from you to assist your Clinician, are foremost in our minds.",
  "akunah_approch2": "Akunah voluntarily complies with the Applicable European Data Protection Laws, which includes the EU General Data Protection Regulation EU/2016/679, as those laws are the strongest privacy laws enacted globally. Those laws exceed the privacy laws enacted by Australia, the United States and other countries.",
  "akunah_approch3": "Akunah’s “Privacy Code for Processing Personal Information of Customer Individuals” describes how Akunah complies with the Applicable European Data Protection Laws (the",
  "akunah_approch4": "Privacy Code",
  "akunah_approch5": "Akunah’s “Data Processing Agreement” describe the arrangements which it has with its Clinicians to protect and secure your personal information (the",
  "akunah_approch6": "Data Processing Agreement",
  "akunah_approch7": "Those policies are available on the Akunah website at",
  "terms_": "https://akunah.com/termsofservices",
  "akunah_approch8": "To the extent there is any inconsistency between the Privacy Code, Data Processing Agreement and this document, then the terms of the first mentioned document will apply to the extent of any inconsistency with this document.",
  "Data_Collection_and_Processing": "Data Collection and Processing",
  "I_am_informed": "Akunah may collect your data, including information relating to your contact information, authentication, identification, occupation and demographics. Akunah may also collect medical data relating to your health, such as",
  "Assessment_and_evaluation": "• Assessment and evaluation data, physical characteristics, and other information relevant to the proposed treatments and outcomes;",
  "Previous_surgery": "• Previous surgery, diagnosis, radiology scans, 3D models, anatomy, anatomical measurements; and",
  "Health_and_medical_history": "• Health and medical history including your symptoms medications and any previous diagnosis and treatment given to you.",
  "I_have_been_assured": "Akunah always collects the information in a lawful and fair manner, whether directly from a patient, shared by a Clinician, or through the Akunah products. Akunah will process these data in compliance with your consent as expressed hereafter, to allow the delivery of the evaluation and pre-surgical planning service if necessary.",
  "My_data": "In addition, Akunah may use your personal data to comply with all applicable laws and regulations",
  "your_data": "Your data may also be disclosed to third parties, if required by to provide the proposed services or by law.",
  "Data_Access_and_Storage": "Data Access, Storage and Security",
  "Personal_data_provided": "Data provided by you and your Clinician will only be accessible by those staff of Akunah and at third parties, who contribute to your treatment or recovery (e.g. anaesthetist, physiotherapist) and need to have access to your data.",
  "data1": "Data entered via Akunah's software is securely hosted in the Commonwealth of Australia by an approved hosting provider. This ensures an appropriate level of confidentiality and security reflecting the nature of your data processed via the Akunah software.",
  "data2": "If you are located outside the Commonwealth of Australia and/or data entered via the Akunah software may be accessed by Akunah subsidiaries located outside the Commonwealth of Australia, including in the European Union or the United States, different privacy standards may apply to how your data is used and protected in those countries. In this case, your personal data will be stored in that country.",
  "data3": "Akunah has appropriate safeguards in place in accordance with all applicable legal requirements to ensure that data is kept confidential and protected with an adequate level of data protection with all third parties. We require all those with access to adopt standards that ensure an equivalent level of protection for data as that adopted by Akunah.",
  "data4": "Your Clinician shares the responsibility to collect, access, analyse, and share your data according to legal requirements.",
  "p_data1": "Your personal data will be kept by Akunah for a length of time that will vary. The retention period will be determined by the following criteria: (i) the purpose for which Akunah or the Clinician is using your personal data (they will need to keep the data only for as long as is necessary for that purpose); and (ii) applicable laws or regulation that may set a minimum period for which Akunah has to keep your personal data.",
  "Deidentified_Data": "De-identified Data",
  "d_txt1": "Akunah may de-identify your data, and then use these de-identified data – usually aggregated with de-identified data from other patients - for record purposes, research and development, data analysis, software/device development and improvement of Akunah’s products and services. This enables Akunah to continuously improve their products and services for the benefit of patients like you.",
  "Patient_Rights": "Your Rights",
  "I_understand_that": "You are free to refuse to give consent to provide your personal data to Akunah. In this case, please talk to your Clinician, as your Clinician may not be able to provide you with medical services or treatment without using Akunah’s software and services.",
  "I_understand_that_1txt": "You have the right to withdraw your consent at any time and by any means, and, where applicable, you have the right to: (i) obtain information regarding the processing of your personal data and to access such personal data; (ii) to request their rectification where they are inaccurate; (iii) to request the restriction of the processing of your personal data, including objecting to certain processing of your personal data and requesting the withdrawal of the consent you provided; (iv) to request the erasure of your personal data; (v) to define guidelines relating to the processing of your data after your death; and (vi) to obtain a copy of your personal data processed in the above-mentioned conditions in a structured, commonly used and machine-readable format. You can exercise these rights by addressing your request directly to the Clinician.",
  "I_understand_that_2txt": "Your rights are subject to the applicable exemptions and restrictions in law.",
  "I_understand_that_3txt": "Following withdrawal, your clinician may not be able to provide you with medical services or treatment, if you exercise those rights before the proposed procedure.",
  "I_understand_that_4txt": "You also are free to make a complaint about the processing of your personal data to the supervisory authority in your country of residence.",
  "I_understand_that_5txt": "With respect to de-identified data, de-identified data do not fall under the same legal restrictions as personal data. Please note that you cannot withdraw consent for access to and use of your de-identified data or request erasing these data, because Akunah will not be able to find your data. After de-identification, Akunah cannot trace data back to you.",
  "Contact": "Consent",
  "I_am_able_to_contact": "I have read and understood the Information section above, or someone has read it to me in a language that I understand.",
  "or_by_post": "I have had an opportunity to ask questions and I am satisfied with the answers I have received. I understand that my information will remain confidential.",
  "or_by_post2": "I give permission for my doctor",
  "or_by_post2_1": "(the “Clinician”) to share my personal data with Akunah.",
  "or_by_post3": "I give permission that Akunah collects, stores, uses, and shares my personal and de-identified data as described in the Information section above.",
  "or_by_post4": "I understand that I am free to withdraw my consent at any time.",
  "or_by_post5": "I understand that I will be given a signed copy of this document to keep.",
  "or_by_post6": "I am able to contact Akunah regarding the processing of my data by email to",
  "info_akunah": "info@akunah.com ",
  "or_by_post7": "or by post to Suite 307 Nicholson Street Specialist Centre, Level 9, 121 Newdegate Street, Greenslopes, QLD, 4120, Australia.",
  "Clear": "Clear",
  "Date": "Date",
  "Signed_by_Mouse": "Signed by Mouse *",
  "Name_if_consent_on_behalf_of_patient": "Name (if consent on behalf of patient)",
  "Relationship_to_patient_if_applicable": "Relationship to patient if applicable",
  "Choose_Sign_Type": "Choose Sign Type*",
  "Draw": "Draw",
  "Signed_by_Keyboard": "Signed by Keyboard *",
  "Agreement": "Agreement",
  "agree_that_I_have_read": "I agree that I have read and understood this patient consent form and I explicitly consent to the processing of my personal data for the purposes described above.",
  "NOTE": "NOTE",
  "If_the_patient": "If the patient is less than 18 years of age, this must be signed by their parent or legal guardian.",
  "If_the_patient_is_deceased": "If the patient is deceased, this must be signed by their next of kin.",
  "patient_signature": "Patient Signature",
  "profile_updated": "Profile updated",
  "profile_not_updated": "Profile not updated",
  "video": "Video",
  "radiology_form": "Radiology Form",
  "doc_document": "Document",
  "pathology_form": "Pathology Form",
  "text": "Text",
  "Document_Name": "Document Name",
  "email_sent_successfully": "Delete request sent successfully.",
  "messages": "Messages",
  "adult_knee": "Adult Knee",
  "paediatric_knee": "Paediatric Knee",
  "document": "My Documents",
  "Check_in": "Check-in",
  "info_icon": "Fill in patient questionnaire",
  "reminder": "Notes",
  "Search_with_minimum_three_characters": "Search with minimum of three characters",
  "Select": "Select",
  "delete_account": "Request to delete my account",
  "DD_MM_YYYY": "DD / MM / YYYY",
  "Female": "Female",
  "Male": "Male",
  "want_to_disclose": "Do not want to disclose",
  "enter_address": "Enter Address",
  "logout": "Logout",
  "full_name": "Full Name",
  "mmyy": "MM / YY",
  "health_found": "Health fund",
  "Not_Applicable": "Not Applicable",
  "not_available": "Not Available",
  "available": "Available",
  "shared": "Shared",
  "private": "Private",
  "add_document": "Add Document",
  "drap_and_drop": "Drag and Drop files here",
  "or_browser_files": "or browse files on your computer",
  "browse_files": "Browse files",
  "download": "download",
  "Oops_error": "Oops! The data you requested was not found.",
  "go_to_home": "Go Back to Home",
  "This_field_is_required": "This field is required",
  "PendingForm": "Pending Form",
  "NoFollowUpRecords": "No Follow-Up Records",
  "Right": "Right",
  "Both": "Both",
  "Left": "Left",
  "shoulder": "Shoulder",
  "hip_form": "Hip",
  "Foot_And_Ankle": "Foot And Ankle",
  "Hand_And_Wrist": "Hand And Wrist",
  "General": "General",
  "adolescent_knee": "Adolescent Knee",
  "Elbow": "Elbow",
  "title": "Title",
  "description": "Description",
  "enter_your_description_here": "Enter description here",
  "enter_your_title_here": "Enter title here",
  "progress": "Pending",
  "done": "Completed",
  "reset": "Reset",
  "send": "Send",
  "Edit": "Edit",
  "delete": "Delete",
  "Are_you_sure_want_to_done_the_remainder": "Are you sure you want to complete this note?",
  "Confirm": "Confirm",
  "Update": "Update",
  "success": "Success",
  "error": "Error",
  "Are_you_sure_you_want_to_delete": "Are you sure you want to delete?",
  "cancel": "Cancel",
  "Hour_Time": "24-Hour Time",
  "Knee": "Knee",
  "no": "No",
  "yes": "Yes",
  "Year": "0 Year, 0 Month, 0 Days",
  "Bilateral": "Ambidextrous",
  "Left_Arm": "Left Shoulder",
  "Right_Arm": "Right Shoulder",
  "out_of": "out of",
  "Right_Elbow": "Right Elbow",
  "Left_Elbow": "Left Elbow",
  "right_foot_ankle": "Right Foot/ankle",
  "left_foot_ankle": "Left Foot/ankle",
  "Right_hand_and_wrist": "Right Hand and Wrist",
  "Left_hand_and_wrist": "Left Hand and Wrist",
  "Hip_Function_R": "Right Hip",
  "Hip_Function_L": "Left Hip",
  "Right_Knee": "Right Knee",
  "Left_Knee": "Left Knee",
  "Right_Pedi_Knee": "Right Knee",
  "Left_Pedi_Knee": "Left Knee",
  "Scoring_Self_Evaluation": "Scoring Self-Evaluation",
  "presciption_form": "Prescription",
  "insurance_form": "Insurance",
  "letter_form": "Letter",
  "refferal_form": "Referral",
  "medical_form": "Medical",
  "are_you_sure_delete_account": "Are you sure you want to delete your account?",
  "Adult_Knee_Pain": "Adult Knee Pain",
  "Knee_pain_13_18_year": "Knee Pain",
  "day_pdata": "Day",
  "days_pdata": "Days",
  "month_pdata": "Month",
  "months_pdata": "Months",
  "year_pdata": "Year",
  "years_pdata": "Years",
  "Name": "Name",
  "akunah_id": "Akunah ID",
  "mobile": "Phone",
  "email": "Email",
  "upload_a_profile": "Upload an Image",
  "No_special_characters_are_allowed": "No special characters are allowed",
  "numbers_are_not_allowed": "Numbers are not allowed",
  "maximum_50_character_are_allowed": "Maximum 50 characters are allowed",
  "invalid_character": "Invalid character",
  "fields_only_contain_10_character": "Field only contains 10 characters",
  "maximum_10_Numbers_are_allowed": "Maximum 10 numbers are allowed",
  "Only_I": "Only numbers are allowed",
  "invalid_home_phone_number": "Invalid home phone number",
  "special_characters_are_not_allowed": "Special characters are not allowed",
  "maximum_digit_are_allowed": "Maximum 1 digit is allowed",
  "invalid_date": "Invalid Date",
  "maximum_10_digits_are_allowed": "Maximum 10 digits are allowed",
  "switch_profile": "Switch Profile",
  "loading": "Loading",
  "error_max_profile_limit": "You have reached maximum number of accounts allowed using one email address",
  "error_upcoming_appointments": "No upcoming appointments found",
  "error_past_visits": "No past visits found",
  "error_documents": "No documents found",
  "country_hawaii": "(UTC-10:00) Hawaii Time",
  "country_tahiti": "(UTC-10:00) Tahiti",
  "country_pitcairn": "(UTC-08:00) Pitcairn",
  "country_niue": "(UTC-11:00) Niue",
  "country_mountain": "(UTC-07:00) Mountain Time",
  "country_arizona": "(UTC-07:00) Mountain Time - Arizona",
  "country_whitehorse": "(UTC-08:00) Pacific Time - Whitehorse",
  "country_chihuahua": "(UTC-07:00) Mountain Time - Chihuahua, Mazatlan",
  "country_edmonton": "(UTC-07:00) Mountain Time - Edmonton",
  "country_gambier": "(UTC-09:00) Gambier",
  "country_yellowknife": "(UTC-07:00) Mountain Time - Yellowknife",
  "country_dawson": "(UTC-07:00) Mountain Time - Dawson Creek",
  "country_belize": "(UTC-06:00) Belize",
  "country_hermosillo": "(UTC-07:00) Mountain Time - Hermosillo",
  "country_mexico": "(UTC-06:00) Central Time - Mexico City",
  "country_regina": "(UTC-06:00) Central Time - Regina",
  "country_tegucigalpa": "(UTC-06:00) Central Time - Tegucigalpa",
  "country_centraltime": "(UTC-06:00) Central Time",
  "country_rarotonga": "(UTC-10:00) Rarotonga",
  "country_pago": "(UTC-11:00) Pago Pago",
  "country_pacific": "(UTC-08:00) Pacific Time",
  "country_alaska": "(UTC-09:00) Alaska Time",
  "country_vancouver": "(UTC-08:00) Pacific Time - Vancouver",
  "country_tijuana": "(UTC-08:00) Pacific Time - Tijuana",
  "country_salvador": "(UTC-06:00) El Salvador",
  "country_costa": "(UTC-06:00) Costa Rica",
  "country_winnipeg": "(UTC-06:00) Central Time - Winnipeg",
  "country_galapagos": "(UTC-06:00) Galapagos",
  "country_managua": "(UTC-06:00) Managua",
  "country_america_cancun": "(UTC-05:00) America Cancun",
  "country_guatemala": "(UTC-06:00) Guatemala",
  "country_bogota": "(UTC-05:00) Bogota",
  "country_easter_island": "(UTC-05:00) Easter Island",
  "country_iqaluit": "(UTC-05:00) Eastern Time - Iqaluit",
  "country_toronto": "(UTC-05:00) Eastern Time - Toronto",
  "country_eastern_time": "(UTC-05:00) Eastern Time",
  "country_havana": "(UTC-05:00) Havana",
  "country_lima": "(UTC-05:00) Lima",
  "country_jamaica": "(UTC-05:00) Jamaica",
  "country_panama": "(UTC-05:00) Panama",
  "country_port_au_prince": "(UTC-05:00) Port-au-Prince",
  "country_nassau": "(UTC-05:00) Nassau",
  "country_rio_branco": "(UTC-05:00) Rio Branco",
  "country_barbados": "(UTC-04:00) Barbados",
  "country_halifax": "(UTC-04:00) Atlantic Time - Halifax",
  "country_bermuda": "(UTC-04:00) Bermuda",
  "country_boa_vista": "(UTC-04:00) Boa Vista",
  "country_curacao": "(UTC-04:00) Curacao",
  "country_marquesas": "(UTC-09:30) Marquesas",
  "country_caracas": "(UTC-04:00) Caracas",
  "country_grand_turk": "(UTC-04:00) Grand Turk",
  "country_la_paz": "(UTC-04:00) La Paz",
  "timezone_guyana": "(UTC-04:00) Guyana",
  "timezone_port_of_spain": "(UTC-04:00) Port of Spain",
  "timezone_manaus": "(UTC-04:00) Manaus",
  "timezone_martinique": "(UTC-04:00) Martinique",
  "timezone_porto_velho": "(UTC-04:00) Porto Velho",
  "timezone_santo_domingo": "(UTC-04:00) Santo Domingo",
  "timezone_puerto_rico": "(UTC-04:00) Puerto Rico",
  "timezone_araguaina": "(UTC-03:00) Araguaina",
  "timezone_belem": "(UTC-03:00) Belem",
  "timezone_buenos_aires": "(UTC-03:00) Buenos Aires",
  "timezone_newfound_time": "(UTC-03:30) Newfoundland Time - St. Johns",
  "timezone_asuncion": "(UTC-03:00) Asuncion",
  "timezone_campo": "(UTC-03:00) Campo Grande",
  "timezone_cayenne": "(UTC-03:00) Cayenne",
  "timezone_cuiaba": "(UTC-03:00) Cuiaba",
  "timezone_maceio": "(UTC-03:00) Maceio",
  "timezone_godthab": "(UTC-03:00) Godthab",
  "timezone_fortaleza": "(UTC-03:00) Fortaleza",
  "timezone_miquelon": "(UTC-03:00) Miquelon",
  "timezone_palmer": "(UTC-03:00) Palmer",
  "timezone_montevideo": "(UTC-03:00) Montevideo",
  "timezone_recife": "(UTC-03:00) Recife",
  "timezone_rothera": "(UTC-03:00) Rothera",
  "timezone_paramaribo": "(UTC-03:00) Paramaribo",
  "timezone_punta_arenas": "(UTC-03:00) Punta Arenas",
  "timezone_santig": "(UTC-03:00) Santiago",
  "timezone_salvador": "(UTC-03:00) Salvador",
  "timezone_stanley": "(UTC-03:00) Stanley",
  "timezone_south_georgia": "(UTC-02:00) South Georgia",
  "timezone_noronha": "(UTC-02:00) Noronha",
  "timezone_sao_pulo": "(UTC-02:00) Sao Paulo",
  "timezone_azores": "(UTC-01:00) Azores",
  "timezone_abidjan": "(UTC+00:00) Abidjan",
  "timezone_cape_verde": "(UTC-01:00) Cape Verde",
  "timezone_scoresbysund": "(UTC-01:00) Scoresbysund",
  "timezone_bissau": "(UTC+00:00) Bissau",
  "timezone_accra": "(UTC+00:00) Accra",
  "timezone_danmarkshavn": "(UTC+00:00) Danmarkshavn",
  "timezone_casablanca": "(UTC+00:00) Casablanca",
  "timezone_dublin": "(UTC+00:00) Dublin",
  "timezone_canary_islands": "(UTC+00:00) Canary Islands",
  "timezone_el_aaiun": "(UTC+00:00) El Aaiun",
  "timezone_thule": "(UTC-04:00) Thule",
  "timezone_gmt": "(UTC+00:00) GMT (no daylight saving)",
  "timezone_lisbon": "(UTC+00:00) Lisbon",
  "timezone_faeroe": "(UTC+00:00) Faeroe",
  "timezone_reykjavik": "(UTC+00:00) Reykjavik",
  "timezone_monrovia": "(UTC+00:00) Monrovia",
  "timezone_algiers": "(UTC+01:00) Algiers",
  "timezone_london": "(UTC+00:00) London",
  "timezone_amsterdam": "(UTC+01:00) Amsterdam",
  "timezone_andorra": "(UTC+01:00) Andorra",
  "timezone_berlin": "(UTC+01:00) Berlin",
  "timezone_budapest": "(UTC+01:00) Budapest",
  "timezone_ceuta": "(UTC+01:00) Ceuta",
  "timezone_brussels": "(UTC+01:00) Brussels",
  "timezone_copenhagen": "(UTC+01:00) Copenhagen",
  "timezone_gibrltar": "(UTC+01:00) Gibraltar",
  "timezone_prague": "(UTC+01:00) Central European Time - Prague",
  "timezone_lagos": "(UTC+01:00) Lagos",
  "timezone_luxembourg": "(UTC+01:00) Luxembourg",
  "timezone_madrid": "(UTC+01:00) Madrid",
  "timezone_malta": "(UTC+01:00) Malta",
  "timezone_ndjamena": "(UTC+01:00) Ndjamena",
  "timezone_paris": "(UTC+01:00) Paris",
  "timezone_oslo": "(UTC+01:00) Oslo",
  "timezone_monaco": "(UTC+01:00) Monaco",
  "timezone_tirane": "(UTC+01:00) Tirane",
  "timezone_rome": "(UTC+01:00) Rome",
  "timezone_vienna": "(UTC+01:00) Vienna",
  "timezone_tunis": "(UTC+01:00) Tunis",
  "timezone_warsaw": "(UTC+01:00) Warsaw",
  "timezone_amman": "(UTC+02:00) Amman",
  "timezone_zurich": "(UTC+01:00) Zurich",
  "timezone_athens": "(UTC+02:00) Athens",
  "timezone_burcharest": "(UTC+02:00) Bucharest",
  "timezone_chisinau": "(UTC+02:00) Chisinau",
  "timezone_stockholm": "(UTC+01:00) Stockholm",
  "timezone_cairo": "(UTC+02:00) Cairo",
  "timezone_gaza": "(UTC+02:00) Gaza",
  "timezone_jerusalem": "(UTC+02:00) Jerusalem",
  "timezone_johannesburg": "(UTC+02:00) Johannesburg",
  "timezone_helsinki": "(UTC+02:00) Helsinki",
  "timezone_khartoum": "(UTC+02:00) Khartoum",
  "timezone_belgrade": "(UTC+01:00) Central European Time - Belgrade",
  "timezone_damascus": "(UTC+02:00) Damascus",
  "timezone_maputo": "(UTC+02:00) Maputo",
  "timezone_kaliningrad": "(UTC+02:00) Moscow-01 - Kaliningrad",
  "timezone_riga": "(UTC+02:00) Riga",
  "timezone_kiev": "(UTC+02:00) Kiev",
  "timezone_nicosia": "(UTC+02:00) Nicosia",
  "timezone_tallinn": "(UTC+02:00) Tallinn",
  "timezone_tripoli": "(UTC+02:00) Tripoli",
  "timezone_sofia": "(UTC+02:00) Sofia",
  "timezone_vilnius": "(UTC+02:00) Vilnius",
  "timezone_istanbul": "(UTC+03:00) Istanbul",
  "timezone_baghdad": "(UTC+03:00) Baghdad",
  "timezone_minsk": "(UTC+03:00) Minsk",
  "timezone_windhoek": "(UTC+02:00) Windhoek",
  "timezone_nairobi": "(UTC+03:00) Nairobi",
  "timezone_moscow": "(UTC+03:00) Moscow+00 - Moscow",
  "timezone_qatar": "(UTC+03:00) Qatar",
  "timezone_beirut": "(UTC+02:00) Beirut",
  "timezone_riyadh": "(UTC+03:00) Riyadh",
  "timezone_syowa": "(UTC+03:00) Syowa",
  "timezone_tehran": "(UTC+03:30) Tehran",
  "timezone_baku": "(UTC+04:00) Baku",
  "timezone_dubai": "(UTC+04:00) Dubai",
  "timezone_mahe": "(UTC+04:00) Mahe",
  "timezone_mauritius": "(UTC+04:00) Mauritius",
  "timezone_tbilisi": "(UTC+04:00) Tbilisi",
  "timezone_samara": "(UTC+04:00) Moscow+01 - Samara",
  "timezone_reunion": "(UTC+04:00) Reunion",
  "timezone_yerevan": "(UTC+04:00) Yerevan",
  "timezone_kabul": "(UTC+04:30) Kabul",
  "timezone_aqtau": "(UTC+05:00) Aqtau",
  "timezone_aqtobe": "(UTC+05:00) Aqtobe",
  "timezone_ashgabat": "(UTC+05:00) Ashgabat",
  "timezone_dushanbe": "(UTC+05:00) Dushanbe",
  "timezone_maldives": "(UTC+05:00) Maldives",
  "timezone_yekaterinburg": "(UTC+05:00) Moscow+02 - Yekaterinburg",
  "timezone_kerguelen": "(UTC+05:00) Kerguelen",
  "timezone_mawson": "(UTC+05:00) Mawson",
  "timezone_tashkent": "(UTC+05:00) Tashkent",
  "timezone_colombo": "(UTC+05:30) Colombo",
  "timezone_kathmandu": "(UTC+05:45) Kathmandu",
  "timezone_omsk": "(UTC+06:00) Moscow+03 - Omsk",
  "timezone_india_standard_time": "(UTC+05:30) India Standard Time",
  "timezone_chagos": "(UTC+06:00) Chagos",
  "timezone_dhaka": "(UTC+06:00) Dhaka",
  "timezone_bishkek": "(UTC+06:00) Bishkek",
  "timezone_cocos": "(UTC+06:30) Cocos",
  "timezone_thimphu": "(UTC+06:00) Thimphu",
  "timezone_almaty": "(UTC+06:00) Almaty",
  "timezone_vostok": "(UTC+06:00) Vostok",
  "timezone_rangoon": "(UTC+06:30) Rangoon",
  "timezone_christmas": "(UTC+07:00) Christmas",
  "timezone_davis": "(UTC+07:00) Davis",
  "timezone_karachi": "(UTC+05:00) Karachi",
  "timezone_hanoi": "(UTC+07:00) Hanoi",
  "timezone_beijing": "(UTC+08:00) China Time - Beijing",
  "timezone_hongkong": "(UTC+08:00) Hong Kong",
  "timezone_hovd": "(UTC+07:00) Hovd",
  "timezone_jakarta": "(UTC+07:00) Jakarta",
  "timezone_kuala_lumpur": "(UTC+08:00) Kuala Lumpur",
  "timezone_bangkok": "(UTC+07:00) Bangkok",
  "timezone_krasnoyarsk": "(UTC+07:00) Moscow+04 - Krasnoyarsk",
  "timezone_brunei": "(UTC+08:00) Brunei",
  "timezone_macau": "(UTC+08:00) Macau",
  "timezone_makassar": "(UTC+08:00) Makassar",
  "timezone_choibalsan": "(UTC+08:00) Choibalsan",
  "timezone_ulaanbaatar": "(UTC+08:00) Ulaanbaatar",
  "timezone_manila": "(UTC+08:00) Manila",
  "timezone_pyongyang": "(UTC+08:30) Pyongyang",
  "timezone_singapore": "(UTC+08:00) Singapore",
  "timezone_irkutsk": "(UTC+08:00) Moscow+05 - Irkutsk",
  "timezone_taipei": "(UTC+08:00) Taipei",
  "timezone_jayapura": "(UTC+09:00) Jayapura",
  "timezone_dili": "(UTC+09:00) Dili",
  "timezone_perth": "(UTC+08:00) Western Time - Perth",
  "timezone_yakutsk": "(UTC+09:00) Moscow+06 - Yakutsk",
  "timezone_palau": "(UTC+09:00) Palau",
  "timezone_seoul": "(UTC+09:00) Seoul",
  "timezone_tokyo": "(UTC+09:00) Tokyo",
  "timezone_darwin": "(UTC+09:30) Central Time - Darwin",
  "timezone_dumont": "(UTC+10:00) Dumont D'Urville",
  "timezone_brisbane": "(UTC+10:00) Eastern Time - Brisbane",
  "timezone_guam": "(UTC+10:00) Guam",
  "timezone_vladivostok": "(UTC+10:00) Moscow+07 - Vladivostok",
  "timezone_port_moresby": "(UTC+10:00) Port Moresby",
  "timezone_truk": "(UTC+10:00) Truk",
  "timezone_adelaide": "(UTC+10:30) Central Time - Adelaide",
  "timezone_casey": "(UTC+11:00) Eastern Time - Hobart",
  "timezone_melbourne_sydney": "(UTC+11:00) Eastern Time - Melbourne, Sydney",
  "timezone_kosrae": "(UTC+11:00) Kosrae",
  "timezone_efate": "(UTC+11:00) Efate",
  "timezone_guadalcanal": "(UTC+11:00) Guadalcanal",
  "timezone_ponape": "(UTC+11:00) Ponape",
  "timezone_norfolk": "(UTC+11:00) Norfolk",
  "timezone_magadan": "(UTC+11:00) Moscow+08 - Magadan",
  "timezone_noumea": "(UTC+11:00) Noumea",
  "timezone_majuro": "(UTC+12:00) Majuro",
  "timezone_funafuti": "(UTC+12:00) Funafuti",
  "timezone_nauru": "(UTC+12:00) Nauru",
  "timezone_tarawa": "(UTC+12:00) Tarawa",
  "timezone_kwajalein": "(UTC+12:00) Kwajalein",
  "timezone_enderbury": "(UTC+13:00) Enderbury",
  "timezone_wake": "(UTC+12:00) Wake",
  "timezone_fakaofo": "(UTC+13:00) Fakaofo",
  "timezone_wallis": "(UTC+12:00) Wallis",
  "timezone_fiji": "(UTC+13:00) Fiji",
  "timezone_apia": "(UTC+14:00) Apia",
  "timezone_kiritimati": "(UTC+14:00) Kiritimati",
  "timezone_tongatapu": "(UTC+13:00) Tongatapu",
  "timezone_auckland": "(UTC+13:00) Auckland",
  "dateFormet": "Date format",
  "Org_success": "Request to join the organization was sent successfully",
  "Org_err": "You have already joined the organization",
  "Request_Organization": "Request organization",
  "organization_requested": "Organization requested",
  "join": "Join",
  "your_email": "email@address.com",
  "medical_summary": "You have not filled up your medical summary yet.",
  "medical_summary_msg": "My medical summary",
  "medical_success": "Medical summary submitted successfully",
  "patient_form_popup": "Do you wish to continue filling out the questionnaire you were working on earlier?",
  "Please_confirm_that_your_details_are_correct": "Please confirm that your details are correct",
  "Welcome_to_Akunah_your_Health_Passport": "Welcome to Akunah, your Health Passport.",
  "Your_helth_is_in_your_hands": "Your health is in your hands.",
  "Lets_begin_your_journey": "Let's begin your journey.",
  "Please_complete_your_profile": "Please complete your profile.",
  "Profile_complete": "Profile complete!",
  "Would_you_like_to_add_another_profile_for_a_family_member": "Would you like to add another profile for a family member?",
  "Alert_detected": "Alert: Multiple logins detected",
  "To_safeguard_your_data": "To safeguard your data privacy and security, the webpage has timed out.",
  "Kindly_close_all_other_Akunah": "Kindly close all other Akunah tabs and press “Back to home” to continue.",
  "Akunah_Team_is_dedicated": "Akunah Team is dedicated to protecting your data.",
  "Last_Created": "Last Created",
  "First_Created": "First Created",
  "Alphabetically": "Alphabetically (A-Z)",
  "Alphabetically1": "Alphabetically (Z-A)",
  "Your_form_submission_was_successful_Clinician": "Your form submission was successful. We are now automatically redirecting to the Clinician app",
  "Your_form_submission_was_successful_Patient": "Your form submission was successful. We are now automatically redirecting to the Patient app",
  "If_this_doesnt_happen_within": "If this doesn’t happen within 5 seconds, please",
  "click_here": "click here",
  "Save": "Save",
  "Proceed_to_my_dashboard": "Proceed to my dashboard",
  "myJourney": "My Journey",
  "Home": "Home",
  "No_journey": "No journey found",
  "Share_results_with_your_doctor": "Share results with your doctor",
  "If_you_would_like_to_share_your_results_with": "If you would like to share your results with your doctor, select Share NOW below",
  "Link_PROMs": "Link PROMs",
  "Welcome_to_akunah": "Welcome to akunah",
  "Share_to_your_Doctor_using_one_of_the_3_options_below": "Share with your Doctor using one of the 3 options below",
  "Doctor_Code": "Doctor Code",
  "Enter_the_code_that_has_been_provided_to_you_by_your": "Enter the code that has been provided to you by your doctor in your email or in the clinic. If the code is not available, please check your recent visits or search for your doctor",
  "Recent_Visits": "Recent Visits",
  "Select_your_recent_doctor_or": "Select your recent doctor or",
  "organisation_here": "organisation here",
  "Search": "Search",
  "Search_for_your_doctor_or": "Search for your doctor or",
  "Please_confirm_that_the_following_details": "Please confirm that the following details are correct",
  "Organisation": "Organisation",
  "PROMs_Specialty_Date": "PROMs Specialty & Date",
  "Get_started_by_completing_your": "Get started by completing your PROMs questionnaires",
  "and_exploring_your_progress": "and exploring your progress",
  "Fill_up_your_PROMs_questionnaire": "Fill up your PROMs questionnaire",
  "Hip": "Hip",
  "My_Progress": "My Progress",
  "Completed": "Completed",
  "Expired": "Expired",
  "Incomplete": "Incomplete",
  "Specialty": "Specialty",
  "Doctor": "Doctor",
  "Status": "Status",
  "View_form": "View form",
  "Share_to_another_doctor": "Share with another doctor",
  "Edit_form": "Edit form",
  "Answer_the_form": "Answer the form",
  "Complete_the_form": "Complete the form",
  "Delete_the_form": "Delete the form",
  "Share_to_doctor": "Share with doctor",
  "Your_account_has_been_created": "Your account has been created",
  "Please_click_below_to_proceed_to_your_dashboard": "Please click below to proceed to your dashboard",
  "If_you_would_like_to_add_another_profile_for_a_family": "If you would like to add another profile for a family member please",
  "Share": "Share",
  "Hip_Scoring_Self_Evaluation_Questionnaire": "Hip Scoring Self-Evaluation Questionnaire",
  "Hand_and_Wrist_Scoring_Self_Evaluation_Questionnaire": "Hand & Wrist Scoring Self-Evaluation Questionnaire",
  "Right_Hand_nd_Wrist": "Right Hand & Wrist",
  "Left_Hand_nd_Wrist": "Left Hand & Wrist",
  "DOB_1": "Date of Birth",
  "Sex_1": "Sex",
  "Address_1": "Address",
  "Please_Choose_PROMs_to_link": "Please choose PROMs to link",
  "Please_select_atleast_one_form_or_close": "Please select at least one PROMs or close",
  "cant_find_appropriate_PROMs_to_link": "Can't find appropriate PROMs to link?",
  "Request_New_PROMs": "Request New PROMs",
  "Warning_This_patient_has_an_incomplete_patient_PROMs_from_a_recent_visit": "Warning: You have an incomplete questionnaire from a recent visit.",
  "If_you_select_No_a_new_patient_PROMs_will_be_created_and_the_incomplete_PROMs_will_be_deleted": "If you select \"No\", a new questionnaire will be created and the incomplete one will be deleted.",
  "Would_you_like_to_continue_this_form": "Would you like to continue with this?",
  "Note": "Note:",
  "Expiring_in": "Expiring in",
  "Date_Created": "Date Created",
  "Days": "Days",
  "Shared": "Shared",
  "Pending": "Pending",
  "Initial": "Initial",
  "Recent": "Recent",
  "Pain": "Pain",
  "Function": "Function",
  "Share_Now": "Share Now",
  "Share_Later": "Share Later",
  "Updated": "Updated",
  "Display_More": "Display More",
  "Address_Now": "Address Now",
  "Address_Later": "Address Later",
  "You_have_pending_questionnaires": "You have pending questionnaires",
  "Notifications": "Notifications",
  "Form_shared_successfully": "Form shared successfully",
  "You_have_already_shared_with_same_doctor_and_organization": "You have already shared with same doctor and organization",
  "No_recent_notifications": "No recent notifications",
  "Have_a_good_day": "Have a good day",
  "Patient_scores": "Patient scores",
  "Display less": "Display less",
  "myJourney_Shoulder": "Shoulder",
  "myJourney_Shoulder_txt": "Select shoulder to view your Shoulder journey",
  "myJourney_Elbow": "Elbow",
  "myJourney_Elbow_txt": "Select elbow to view your Elbow journey",
  "myJourney_Hand_Wrist": "Hand & Wrist",
  "myJourney_Hand_Wrist_txt": "Select Hand & Wrist to view your Hand & wrist journey",
  "myJourney_Hip": "Hip",
  "myJourney_Hip_txt": "Select Hip to view your Hip journey",
  "myJourney_knee": "Knee",
  "myJourney_knee_txt": "Select Knee to view your Knee journey",
  "myJourney_Foot_Ankle": "Foot & Ankle",
  "myJourney_Foot_Ankle_txt": "Select Foot & Ankle to view your Foot & Ankle journey",
  "data_sort": "Sort",
  "Visit_Clinic": "Visit Clinic",
  "My_Visits": "My Visits",
  "Patient_Scoring_Forms": "Patient Scoring Forms",
  "Other_findings": "Other findings",
  "Organization": "Organization",
  "more": "More",
  "mediacal_summary": "Medical Summary",
  "my_consent": "My Consents",
  "Are_you_sure_you_want_to_reject": "Are you sure you want to block this referral?",
  "reject": "Reject",
  "i_accept_consent": "I consent to this",
  "grant": "Grant",
  "no_conscent_found": "No consent found",
  "rejected": "Rejected",
  "No_Time_Lines": "No Timelines",
  "Hide_Score": "Hide Score",
  "Show_Score": "Show Score",
  "patient_sex": "Sex",
  "Dominant_hand_1": "Dominant hand",
  "patient_Initial_Assessment": "Initial Assessment",
  "patient_Treatment_Category": "Treatment Category",
  "View_all": "View all",
  "Display_Less": "Display Less",
  "patient_general_past_Medical_history": "Medical History",
  "patient_general_past_Medication_name": "List of medications",
  "Evaluated_Knee": "Evaluated Knee",
  "Is_your_unaffected_Hand_Wrist_pain_free_and_allows_normal_function": "Is your unaffected hand and wrist pain-free and allows normal function?",
  "Evaluated_Hip": "Evaluated Hip",
  "Evaluated_Foot_Ankle": "Evaluated Foot and Ankle",
  "patient_Evaluated_Elbow": "Evaluated Elbow",
  "Is_your_unaffected_Foot_Ankle_pain_free_and_allows_normal_function": "Is your unaffected foot and ankle pain-free and allows normal function?",
  "Evaluated_Hand_Wrist": "Evaluated Hand and Wrist",
  "patient_shoulder_feeling_instability_q2": "Please indicate how unstable your shoulder is ",
  "patient_shoulder_feeling_instability_q3": "Please indicate how unstable your shoulder is ",
  "patient_Hand_BCTQ": "Hand And Wrist BCTQ",
  "Paediatric_Knee_Satisfaction": "Paediatric Knee Satisfaction",
  "patient_foot_ankle_symptoms": "Foot And Ankle Other Symptoms",
  "Right_Foot_and_Ankle": "Right Foot & Ankle",
  "Left_Foot_and_Ankle": "Left Foot & Ankle",
  "summary": "Summary",
  "Shoulder_Treatment_Form": "Shoulder Treatment Form",
  "Adolescent_knee_Treatment_Form": "Adolescent knee Treatment Form",
  "Adult_Knee_Treatment_Form": "Adult Knee Treatment Form",
  "Paediatric_Knee_Treatment_Form": "Paediatric Knee Treatment Form",
  "Hip_Treatment_Form": "Hip Treatment Form",
  "Foot_Ankle_Treatment_Form": "Foot and Ankle Treatment Form",
  "Elbow_Treatment_Form": "Elbow Treatment Form",
  "Hand_Wrist_Treatment_Form": "Hand & Wrist Treatment Form",
  "Upload_complete": "Upload complete",
  "Select_Date_Format": "Select Date Format",
  "timezone_casey1": "(UTC+11:00) Casey",
  "timezone_Kamchatskiy": "(UTC+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy",
  "timezone_Guayaquil": "(UTC-05:00) Guayaquil",
  "requested": "requested a",
  "Display_name": "Display name",
  "Are_you_sure_you_want_to_delete_Consent": "Are you sure you want to reject this consent?",
  "form": "form",
  "Requested_by": "Requested by",
  "requested_hip_form": "requested a Hip form",
  "requested_shoulder_form": "requested a Shoulder form",
  "requested_knee_form": "requested a Knee form",
  "requested_foot_form": "requested a Foot and Ankle form",
  "requested_hand_form": "requested a Hand And Wrist form",
  "requested_elbow_form": "requested an Elbow form",
  "requested_General_form": "requested a General form",
  "requested_Paediatric_form": "requested a Paediatric Knee form",
  "requested_Adolescent_form": "requested a Adolescent Knee form",
  "Expiring_in_Tomorrow": "Expiring tomorrow",
  "PROMs_questionnaire": "Dr. [doctor’s full name who requested] has requested you to fill out [specialty] PROMs questionnaire",
  "new_message": "You have a new message from [full name of message sender]",
  "new_appointment": "You have a new appointment with Dr. [doctor’s full name]",
  "upcoming_appointment": "Reminder: upcoming appointment with Dr. [full name] on [date]",
  "pending_message": "Reminder: you have a pending [specialty] PROMs questionnaire",
  "document_message": "Dr. [full name of who shared] shared a document with you",
  "requested_message": "Dr. [full name of who requested] has requested for a consent",
  "patient_added_message": "You have been added as a patient of Dr [full name] in [organisation]",
  "cancelled_appointment_message": "Your appointment with Dr [full name] on [date of appointment] has been cancelled.",
  "Mark_all_read": "Mark all as read",
  "Delete_All": "Delete All",
  "No_Notification": "No notifications found",
  "You_have_not": "You have not started your journey yet. Please fill out a PROMs questionnaire to begin your journey.",
  "pass_verification1": "Password should be at least 8 characters long *",
  "pass_verification2": "Password must contain at least 1 upper case character*",
  "pass_verification3": "Password must contain at least 1 lower case character*",
  "pass_verification4": "Password must contain at least 1 digit *",
  "loginTitle": "Akunah:Sign In ::",
  "Create_patient_mobile": "Create new profile",
  "Select_Profile_mobile": "Select your profile",
  "Profile_Created_mobile": "Profile Created",
  "Your_account_has_been_created_mobile": "Your account has been created.",
  "Please_tap_below_to_sign_in_to_your_workspace_mobile": "Please tap below to sign in to your workspace.",
  "Start_Journey_mobile": "Start Journey",
  "If_add_another_profile_mobile": "If you would like to add another profile for a family member, please",
  "Create_Profile_mobile": "Create Profile",
  "change_Profile_Pic_mobile": "Change profile photo",
  "MobileImp_mobile": "Mobile*",
  "Gender_mobile": "Gender",
  "Country_1_mobile": "Country",
  "Med_1_mobile": "Med",
  "Appoinments_mobile": "Appointments",
  "View_Profile_mobile": "View Profile",
  "Sign_out_mobile": "Sign out",
  "My_Appointments_mobile": "My Appointments",
  "Appoinments_Past_mobile": "Past",
  "No_appointments_mobile": "No appointments",
  "When_appointment_available_mobile": "When your next appointment is available, it will be displayed here",
  "Available_soon_mobile": "Available soon!",
  "please_akunah_desktop_mobile": "To access it now, please login tome.akunah.comon your desktop.",
  "All_Notes_mobile": "All Notes",
  "AddButton_mobile": "AddButton",
  "Start_your_note_mobile": "Start your note...",
  "Done_mobile": "Done",
  "Note_Title_mobile": "Note Title",
  "New_Consents_mobile": "New Consents",
  "Signed_Consents_mobile": "Signed Consents",
  "Consent_mobile": "Consent",
  "Signed_Type_mobile": "Signed by Type",
  "Submit_Consent_mobile": "Submit Consent",
  "Reject_Consent_mobile": "Reject Consent",
  "Type_name_mobile": "Type your name",
  "Signature_mobile": "Signature",
  "No_consents_mobile": "No consents",
  "Any_display_ready_mobile": "Any new or signed consents will display here when ready",
  "Clinician_Documents_mobile": "Clinician Documents",
  "Uploaded_Documents_mobile": "Uploaded Documents",
  "Upload_Document_mobile": "Upload Document",
  "Upload_file_mobile": "Upload file",
  "Upload_photo_mobile": "Upload photo",
  "Take_photo_mobile": "Take photo",
  "Uploading_1_mobile": "Uploading",
  "New_1_mobile": "New",
  "Share_document_mobile": "Share this document",
  "Enter_email_mobile": "Enter email",
  "Add_message_like_mobile": "Add a message, if you’d like.",
  "Are_delete_account_mobile": "Are you sure you want to delete your account?",
  "No_display_mobile": "No documents to display",
  "All_clinician_documents_mobile": "All clinician documents will display here",
  "New_Messages_mobile": "New Messages",
  "Read_Messages_mobile": "Read Messages",
  "Any_new_unread_messages_ready_mobile": "Any new or unread messages will be displayed here when ready",
  "Shoulder_Journey_mobile": "Shoulder Journey",
  "Hip_Journey_mobile": "Hip Journey",
  "Foot_Ankle_Journey_mobile": "Foot and Ankle Journey",
  "Elbow_Journey_mobile": "Elbow Journey",
  "Knee_Journey_mobile": "Knee Journey",
  "Hand_Wrists_Journey_mobile": "Hand and Wrist Journey",
  "New_Form_mobile": "New Form",
  "Start_new_form_mobile": "Start new form",
  "Requested_Forms_mobile": "Requested Forms",
  "Incomplete_Forms_mobile": "Incomplete Forms",
  "Completed_Forms_mobile": "Completed Forms",
  "No_Available_mobile": "No Forms Available",
  "Start_new_journey_mobile": "Start a new form to begin your journey.",
  "View_Results_mobile": "View Results",
  "Complete_Form_mobile": "Complete Form",
  "Delete_Form_mobile": "Delete Form",
  "Edit_Details_mobile": "Edit Details",
  "Personal_Details_mobile": "Personal Details",
  "DOB_1_mobile": "DOB",
  "Unknown_Gender_mobile": "Unknown Gender",
  "Mobile_mobile": "Mobile",
  "Next_kin’s_name_mobile": "Next of kin’s name",
  "Next_kin’s_relationship_mobile": "Next of kin’s relationship",
  "GP_Details_mobile": "GP Details",
  "GP_Name_mobile": "GP Name",
  "Practice_mobile": "Practice",
  "Edit_Profile_mobile": "Edit Profile",
  "Full_Name_mobile": "Full Name",
  "Country_Code_mobile": "Country Code",
  "GP_Full_Name_mobile": "GP Full Name",
  "Doctor’s_full_name_mobile": "Doctor’s full name",
  "All_mobile": "All",
  "No_notifications_mobile": "No recent notifications",
  "Any_new_available_mobile": "Any new or unread notifications will be displayed here when available",
  "Account_mobile": "Account",
  "Manage_My_Account_mobile": "Manage My Account",
  "Change_Password_mobile": "Change Password",
  "Privacy_mobile": "Privacy",
  "Time_mobile": "Time",
  "Date_Format_mobile": "Date & Format",
  "Support_mobile": "Support",
  "All_Notifications_mobile": "All Notifications",
  "Receive_notifications_mobile": "Receive all notifications",
  "Journey_Updates_mobile": "Journey Updates",
  "Receive_appointment_notifications_mobile": "Receive all appointment notifications",
  "Start_Form_mobile": "Start Form",
  "Your_journey_starts_here_mobile": "Your journey starts here",
  "To_recovery_questions_mobile": "To get started with your journey to recovery, we just ask you to please best answer the following questions.",
  "Please_estimate_questions_mobile": "Please estimate 12-15 minutes to answer these questions.",
  "Shoulder_Form_mobile": "Shoulder Form",
  "Save_and_Continue_mobile": "Save & Continue",
  "How_years_smoke_mobile": "How many years did you smoke?",
  "patient_shoulder_pain_inner_value_mobile": "(0 means no pain, 15 means intolerable pain)",
  "Scoring_Key_mobile": "Scoring Key",
  "patient_shoulder_wosi_1_mobile": "Western Ontario Shoulder Instability Index (WOSI)",
  "patient_shoulder_wosi_sectionA_phy_mobile": "PHYSICAL SYMPTOMS",
  "patient_shoulder_wosi_sectionB_sp_mobile": "SPORTS/RECREATION/WORK",
  "patient_shoulder_wosi_sectionC_life_mobile": "LIFESTYLE",
  "patient_shoulder_wosi_sectionD_emo_mobile": "EMOTIONS",
  "Specific_AC_Score_mobile": "Specific AC Score (SACS)",
  "patient_shoulder_sacs_sectionA_pain_mobile": "Pain",
  "patient_shoulder_sacs_sectionB_function_mobile": "FUNCTION",
  "patient_shoulder_sacs_sectionC_Qua_mobile": "Quality of life and satisfaction",
  "Finish_mobile": "Finish",
  "Share_Results_mobile": "Share Results",
  "Share_Doctor_mobile": "Share to your Doctor",
  "Choose_share_results_doctor_mobile": "Choose one of the 3 options below to share your results with your doctor",
  "Share_your_Clinician_mobile": "Share with your Clinician",
  "Select_recent_doctor_organisation_mobile": "Select your recent doctor or organisation here",
  "Clinician_Search_mobile": "Clinician Search",
  "Search_doctor_organisation_mobile": "Search for your doctor or organisation here",
  "Clinician_Code_mobile": "Clinician Code",
  "Enter_doctor_code_search_mobile": "Enter the code that has been provided to you by your doctor in your email or in the clinic. If the code is not available, please check you recent visits or use the search.",
  "Skip_sharing_now_mobile": "Skip sharing for now",
  "Code_please_try_again_mobile": "Code incorrect, please try again.",
  "Confirm_details_mobile": "Confirm details",
  "PROMs_Speciality_Date_mobile": "PROMs Speciality & Date",
  "Results_Shared_mobile": "Results Shared!",
  "Your_successfully_doctor_mobile": "Your results has been successfully shared with your doctor.",
  "Please_results_mobile": "Please tap below to view your results.",
  "If_another_doctor_mobile": "If you would like to share with another doctor",
  "please_mobile": "please",
  "click_here_mobile": "click here",
  "Pain_Score_mobile": "Pain Score",
  "Recent_Scores_mobile": "Recent Scores",
  "Right_Arm_mobile": "Right Arm",
  "SACS_Score_mobile": "SACS",
  "complete_mobile": "Complete",
  "Add_Note_mobile": "Add Note",
  "Pending_Forms_mobile": "Pending Forms",
  "Your_pending__will_display_mobile": "Your pending forms will be displayed here",
  "Your_Incomplete_display_mobile": "Your Incomplete Forms will be displayed here",
  "Your_complete_display_mobile": "Your complete forms will be displayed here",
  "PROMs_Shoulder_request_mobile": "PROMs Shoulder request",
  "New_message_mobile": "New message",
  "New_appointment_mobile": "New appointment",
  "New_Consent_request_mobile": "New Consent request",
  "New_shared_document_mobile": "New shared document",
  "New_Organisation_Clinician_mobile": "New Organisation/Clinician",
  "This_field_cannot_empty_mobile": "This field cannot be empty.",
  "List_work_activities_mobile": "List work activities ...",
  "List_hobby_activities_mobile": "List hobby and sport activities ...",
  "patient_shoulder_wosi_sectionA_mobile": "SECTION A:",
  "patient_shoulder_wosi_sectionB_mobile": "SECTION B:",
  "patient_shoulder_wosi_sectionC_mobile": "Section C:",
  "patient_shoulder_wosi_sectionD_mobile": "Section D:",
  "File_size_should_than_10MB_mobile": "File size should not more than 10MB",
  "Share_this_document_mobile": "Share this document",
  "sac_explaination": "Explanation",
  "patient_shoulder_sacs_explanation_mobile": "For each question, you will be asked to rate either your level of pain or difficulty performing different tasks. The rating scale is designed so that 0 reflects no pain or difficulty while 10 reflects the most severe amount of pain or difficulty.",
  "patient_shoulder_sacs_a3_note_mobile": "(e.g. Laptop or hand bag strap).",
  "patient_shoulder_sacs_b2_note_mobile": "(e.g. Resting your arm in a pocket or using a sling).",
  "patient_shoulder_sacs_b3_note_mobile": "(e.g. Push up, cycling, yoga).",
  "patient_shoulder_sacs_b4_note_mobile": "(e.g. Bag of shopping).",
  "patient_shoulder_sacs_b5_note_mobile": "(e.g. To reach across and touch the unaffected shoulder).",
  "patient_shoulder_sacs_b6_note_mobile": "(e.g. Greater than five kilograms).",
  "patient_shoulder_sacs_b7_note_mobile": "(e.g. Holding the steering wheel whilst driving).",
  "patient_shoulder_sacs_c1_note_mobile": "(Including the presence of a lump).",
  "Function_Score_mobile": "Function Score",
  "Instability_Score_mobile": "Instability Score",
  "patient_shoulder_pain_q2_mobile": "On a scale of 0-15, what is the highest level of pain that you have experienced in your shoulder during ordinary activities within the last 24 hours?",
  "patient_shoulder_pain_q6_mobile": "Do you take pain medication",
  "patient_shoulder_pain_q6_mobile_sub": "(eg paracetamol etc)?",
  "patient_shoulder_pain_q6_a2_q1_mobile": "Do you take strong pain medication",
  "patient_shoulder_pain_q6_a2_q1_mobile_sub": "(eg codeine, tramadol, morphine etc)?",
  "patient_shoulder_feeling_instability_q1_b1_mobile_sub": "(as if it is going to dislocate)?",
  "patient_shoulder_feeling_instability_q1_mobile": "Does your shoulder feel unstable",
  "patient_shoulder_wosi_instruction_mobile": "INSTRUCTIONS",
  "Next_of_kins_number_mobile": "Next of kin’s number",
  "Next_of_Kin_mobile": "Next of Kin",
  "Doctor_Name_mobile": "Doctor's name",
  "Doctors_practice_mobile": "Doctor’s practice",
  "Doctors_practice_address_mobile": "Doctor’s practice address",
  "Language_mobile": "Languages",
  "No_Internet_Connection_mobile": "No Internet Connection",
  "Please_type_your_name_mobile": "Please type your name",
  "Choose_Sign_Type_mobile": "Choose Sign Type",
  "Signed_by_Draw_mobile": "Signed by Draw",
  "select_image_mobile": "select image",
  "Consent_rejected_mobile": "Consent rejected",
  "Consent_submitted_mobile": "Consent submitted",
  "Today_mobile": "Today",
  "New_mobile": "New",
  "No_recent_scores_display_mobile": "No recent scores to display",
  "All_recent_scores_display_here_mobile": "All recent scores will display here",
  "Shared_Documents_mobile": "Shared Documents",
  "greater_than_I": "Not Greater than 100",
}