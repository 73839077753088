import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../images/back.png";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import Button from "../../../custom/button/Button";
import {
  updateHIPPainScores,
  updateHipTotalScores,
  updateHIPSymptomsScores,
  updateHipScores,
  updateHIPfunctionScores,
  updateHIPQOLScores,
  updateHIPSatisfactionScores,
  updateHipFeedbackAll,
} from "../../../../redux/actions/hipAction";
import useAxios from "../../../../axiosinstance";
import useTranslation from "../../../customHooks/translations";
import NameCustom from "../../../custom/name/NameCustom";
import SubmitModel from "../../SubmissionModel/SubmitModel";
function HipComplete() {
  const translation = useTranslation();
  const axiosInstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const [chk, setChk] = useState(false);
  const [initialAssesment, setInitialAssesment] = useState(0);
  const { rId } = useParams();
  const [patientID, setpatientID] = useState("");
  const [painSco] = useSelector((state) => state.hipScoreReducer.painScore);
  const [symptomsScore] = useSelector(
    (state) => state.hipScoreReducer.symptomsScore
  );
  const [functionScore] = useSelector(
    (state) => state.hipScoreReducer.functionScore
  );
  const [qolScore] = useSelector((state) => state.hipScoreReducer.qolScore);
  const [satisfactionScore] = useSelector(
    (state) => state.hipScoreReducer.satisfactionScore
  );

  const [isdoctorform, setisdoctorform] = useState(localStorage.getItem('isdoctorForm')==='true'?1:0)


  const json = useSelector((state) => state.hipScoreReducer);

  const save = () => {
    axiosInstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=6`)
      .then((res) => {
        // console.log(res.data.data2);
        setInitialAssesment(res.data.data2[0].initial_assessment);
        dispatch(updateHIPPainScores(res.data.data2[0].painScore));
        dispatch(updateHIPSymptomsScores(res.data.data2[0].symptomsScore));
        dispatch(updateHipScores(res.data.data2[0].evlHipPain));
        dispatch(updateHIPfunctionScores(res.data.data2[0].functionScore));
        dispatch(updateHIPQOLScores(res.data.data2[0].qolScore));
        dispatch(
          updateHIPSatisfactionScores(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateHipFeedbackAll(res.data.data2[0].doctorFeedback));
        setpatientID(res.data.data2[0].patient_id);
        // setisdoctorform(res.data.data2[0].is_doctor_form);
        setChk(true);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };
  useEffect(() => {
    save();
  }, []);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  function sumAll(...numbers) {
    let total = 0;
    for (const number of numbers) {
      total += number;
    }
    return total;
  }

  // mHHS (MODIFIED HARRIS HIP SCORE) calculation starting

  const mHHSdescribesR =
    painSco.describesR === "0"
      ? 44
      : painSco.describesR === "1"
      ? 40
      : painSco.describesR === "2"
      ? 30
      : painSco.describesR === "3"
      ? 20
      : painSco.describesR === "4"
      ? 10
      : painSco.describesR === "5"
      ? 0
      : 0;

  const mHHSdescribesL =
    painSco.describesL === "0"
      ? 44
      : painSco.describesL === "1"
      ? 40
      : painSco.describesL === "2"
      ? 30
      : painSco.describesL === "3"
      ? 20
      : painSco.describesL === "4"
      ? 10
      : painSco.describesL === "5"
      ? 0
      : 0;

  const mHHSwalkR =
    painSco.walkR === "0"
      ? 0
      : painSco.walkR === "1"
      ? 2
      : painSco.walkR === "2"
      ? 5
      : painSco.walkR === "3"
      ? 8
      : painSco.walkR === "4"
      ? 11
      : 0;
  const mHHSwalkL =
    painSco.walkL === "0"
      ? 0
      : painSco.walkL === "1"
      ? 2
      : painSco.walkL === "2"
      ? 5
      : painSco.walkL === "3"
      ? 8
      : painSco.walkL === "4"
      ? 11
      : 0;
  const mHHSlimpingR =
    symptomsScore.limpingR === "0"
      ? 11
      : symptomsScore.limpingR === "1"
      ? 8
      : symptomsScore.limpingR === "2"
      ? 5
      : symptomsScore.limpingR === "3"
      ? 0
      : symptomsScore.limpingR === "4"
      ? 0
      : 0;
  const mHHSlimpingL =
    symptomsScore.limpingL === "0"
      ? 11
      : symptomsScore.limpingL === "1"
      ? 8
      : symptomsScore.limpingL === "2"
      ? 5
      : symptomsScore.limpingL === "3"
      ? 0
      : symptomsScore.limpingL === "4"
      ? 0
      : 0;
  // Changes
  const mHHSsupportR =
    symptomsScore.supportR === "0"
      ? 11
      : symptomsScore.supportR === "1"
      ? 7
      : symptomsScore.supportR === "2"
      ? 5
      : symptomsScore.supportR === "3"
      ? 3
      : symptomsScore.supportR === "4"
      ? 2
      : symptomsScore.supportR === "5"
      ? 0
      : 0;
  const mHHSsupportL =
    symptomsScore.supportL === "0"
      ? 11
      : symptomsScore.supportL === "1"
      ? 7
      : symptomsScore.supportL === "2"
      ? 5
      : symptomsScore.supportL === "3"
      ? 3
      : symptomsScore.supportL === "4"
      ? 2
      : symptomsScore.supportL === "5"
      ? 0
      : 0;

  const mHHSascstairsR =
    functionScore.ascstairsR === "0"
      ? 4
      : functionScore.ascstairsR === "1"
      ? 3
      : functionScore.ascstairsR === "2"
      ? 2
      : functionScore.ascstairsR === "3"
      ? 1
      : functionScore.ascstairsR === "4"
      ? 0
      : 0;
  const mHHSascstairsL =
    functionScore.ascstairsL == "0"
      ? 4
      : functionScore.ascstairsL == "1"
      ? 3
      : functionScore.ascstairsL == "2"
      ? 2
      : functionScore.ascstairsL == "3"
      ? 1
      : functionScore.ascstairsL == "4"
      ? 0
      : 0;
  // Not used
  const mHHSsittingfR =
    functionScore.sittingR === "0"
      ? 5
      : functionScore.sittingR === "1"
      ? 3
      : functionScore.sittingR === "2"
      ? 3
      : functionScore.sittingR === "3"
      ? 0
      : functionScore.sittingR === "4"
      ? 0
      : 0;

  const mHHSsittingfL =
    functionScore.sittingL == "0"
      ? 5
      : functionScore.sittingL == "1"
      ? 3
      : functionScore.sittingL == "2"
      ? 3
      : functionScore.sittingL == "3"
      ? 0
      : functionScore.sittingL == "4"
      ? 0
      : 0;

  const mHHSpublicTransR =
    functionScore.publicTransR === "0"
      ? 1
      : functionScore.publicTransR === "1"
      ? 1
      : functionScore.publicTransR === "2"
      ? 1
      : functionScore.publicTransR === "3"
      ? 0
      : functionScore.publicTransR === "4"
      ? 0
      : 0;
  const mHHSpublicTransL =
    functionScore.publicTransL == "0"
      ? 1
      : functionScore.publicTransL == "1"
      ? 1
      : functionScore.publicTransL == "2"
      ? 1
      : functionScore.publicTransL == "3"
      ? 0
      : functionScore.publicTransL == "4"
      ? 0
      : 0;
  const mHHSputtingR =
    functionScore.puttingR === "0"
      ? 4
      : functionScore.puttingR === "1"
      ? 2
      : functionScore.puttingR === "2"
      ? 2
      : functionScore.puttingR === "3"
      ? 2
      : functionScore.puttingR === "4"
      ? 0
      : 0;
  const mHHSputtingL =
    functionScore.puttingL == "0"
      ? 4
      : functionScore.puttingL == "1"
      ? 2
      : functionScore.puttingL == "2"
      ? 2
      : functionScore.puttingL == "3"
      ? 2
      : functionScore.puttingL == "4"
      ? 0
      : 0;

  //mHHS End============================================

  // HOOS (Hip disability and Osteoarthritis Outcome Score)

  // const hoosoftenR = parseFloat(painSco.oftenR);
  // const hoosoftenL = parseFloat(painSco.oftenL);
  const hoosoftenR =
    painSco.oftenR === "0"
      ? 4
      : painSco.oftenR === "1"
      ? 3
      : painSco.oftenR === "2"
      ? 2
      : painSco.oftenR === "3"
      ? 1
      : painSco.oftenR === "4"
      ? 0
      : 0;
  const hoosoftenL =
    painSco.oftenL === "0"
      ? 4
      : painSco.oftenL === "1"
      ? 3
      : painSco.oftenL === "2"
      ? 2
      : painSco.oftenL === "3"
      ? 1
      : painSco.oftenL === "4"
      ? 0
      : 0;

  const hoosstraighteningR =
    painSco.straighteningR === "0"
      ? 4
      : painSco.straighteningR === "1"
      ? 3
      : painSco.straighteningR === "2"
      ? 2
      : painSco.straighteningR === "3"
      ? 1
      : painSco.straighteningR === "4"
      ? 0
      : 0;

  const hoosstraighteningL =
    painSco.straighteningL === "0"
      ? 4
      : painSco.straighteningL === "1"
      ? 3
      : painSco.straighteningL === "2"
      ? 2
      : painSco.straighteningL === "3"
      ? 1
      : painSco.straighteningL === "4"
      ? 0
      : 0;

  // Use only HOOS PAIN
  const hoosbendingR =
    painSco.bendingR === "0"
      ? 4
      : painSco.bendingR === "1"
      ? 3
      : painSco.bendingR === "2"
      ? 2
      : painSco.bendingR === "3"
      ? 1
      : painSco.bendingR === "4"
      ? 0
      : 0;

  const hoosbendingL =
    painSco.bendingL === "0"
      ? 4
      : painSco.bendingL === "1"
      ? 3
      : painSco.bendingL === "2"
      ? 2
      : painSco.bendingL === "3"
      ? 1
      : painSco.bendingL === "4"
      ? 0
      : 0;
  const hoossurfaceR =
    painSco.surfaceR === "0"
      ? 4
      : painSco.surfaceR === "1"
      ? 3
      : painSco.surfaceR === "2"
      ? 2
      : painSco.surfaceR === "3"
      ? 1
      : painSco.surfaceR === "4"
      ? 0
      : 0;

  const hoossurfaceL =
    painSco.surfaceL === "0"
      ? 4
      : painSco.surfaceL === "1"
      ? 3
      : painSco.surfaceL === "2"
      ? 2
      : painSco.surfaceL === "3"
      ? 1
      : painSco.surfaceL === "4"
      ? 0
      : 0;

  const hooshardSurfaceR =
    painSco.hardSurfaceR === "0"
      ? 4
      : painSco.hardSurfaceR === "1"
      ? 3
      : painSco.hardSurfaceR === "2"
      ? 2
      : painSco.hardSurfaceR === "3"
      ? 1
      : painSco.hardSurfaceR === "4"
      ? 0
      : 0;

  const hooshardSurfaceL =
    painSco.hardSurfaceL === "0"
      ? 4
      : painSco.hardSurfaceL === "1"
      ? 3
      : painSco.hardSurfaceL === "2"
      ? 2
      : painSco.hardSurfaceL === "3"
      ? 1
      : painSco.hardSurfaceL === "4"
      ? 0
      : 0;

  const hoosunevenSurfaceR =
    painSco.unevenSurfaceR === "0"
      ? 4
      : painSco.unevenSurfaceR === "1"
      ? 3
      : painSco.unevenSurfaceR === "2"
      ? 2
      : painSco.unevenSurfaceR === "3"
      ? 1
      : painSco.unevenSurfaceR === "4"
      ? 0
      : 0;

  const hoosunevenSurfaceL =
    painSco.unevenSurfaceL === "0"
      ? 4
      : painSco.unevenSurfaceL === "1"
      ? 3
      : painSco.unevenSurfaceL === "2"
      ? 2
      : painSco.unevenSurfaceL === "3"
      ? 1
      : painSco.unevenSurfaceL === "4"
      ? 0
      : 0;

  const hoosstairsR =
    painSco.stairsR === "0"
      ? 4
      : painSco.stairsR === "1"
      ? 3
      : painSco.stairsR === "2"
      ? 2
      : painSco.stairsR === "3"
      ? 1
      : painSco.stairsR === "4"
      ? 0
      : 0;

  const hoosstairsL =
    painSco.stairsL === "0"
      ? 4
      : painSco.stairsL === "1"
      ? 3
      : painSco.stairsL === "2"
      ? 2
      : painSco.stairsL === "3"
      ? 1
      : painSco.stairsL === "4"
      ? 0
      : 0;
  const hoosbedR =
    painSco.bedR === "0"
      ? 4
      : painSco.bedR === "1"
      ? 3
      : painSco.bedR === "2"
      ? 2
      : painSco.bedR === "3"
      ? 1
      : painSco.bedR === "4"
      ? 0
      : 0;

  const hoosbedL =
    painSco.bedL === "0"
      ? 4
      : painSco.bedL === "1"
      ? 3
      : painSco.bedL === "2"
      ? 2
      : painSco.bedL === "3"
      ? 1
      : painSco.bedL === "4"
      ? 0
      : 0;

  const hoossittingR =
    painSco.sittingR === "0"
      ? 4
      : painSco.sittingR === "1"
      ? 3
      : painSco.sittingR === "2"
      ? 2
      : painSco.sittingR === "3"
      ? 1
      : painSco.sittingR === "4"
      ? 0
      : 0;

  const hoossittingL =
    painSco.sittingL === "0"
      ? 4
      : painSco.sittingL === "1"
      ? 3
      : painSco.sittingL === "2"
      ? 2
      : painSco.sittingL === "3"
      ? 1
      : painSco.sittingL === "4"
      ? 0
      : 0;

  const hoosuprightR =
    painSco.uprightR === "0"
      ? 4
      : painSco.uprightR === "1"
      ? 3
      : painSco.uprightR === "2"
      ? 2
      : painSco.uprightR === "3"
      ? 1
      : painSco.uprightR === "4"
      ? 0
      : 0;

  const hoosuprightL =
    painSco.uprightL === "0"
      ? 4
      : painSco.uprightL === "1"
      ? 3
      : painSco.uprightL === "2"
      ? 2
      : painSco.uprightL === "3"
      ? 1
      : painSco.uprightL === "4"
      ? 0
      : 0;

  const hoosgrindingR =
    symptomsScore.grindingR === "0"
      ? 4
      : symptomsScore.grindingR === "1"
      ? 3
      : symptomsScore.grindingR === "2"
      ? 2
      : symptomsScore.grindingR === "3"
      ? 1
      : symptomsScore.grindingR === "4"
      ? 0
      : 0;
  const hoosgrindingL =
    symptomsScore.grindingL === "0"
      ? 4
      : symptomsScore.grindingL === "1"
      ? 3
      : symptomsScore.grindingL === "2"
      ? 2
      : symptomsScore.grindingL === "3"
      ? 1
      : symptomsScore.grindingL === "4"
      ? 0
      : 0;
  const hoosstifR =
    symptomsScore.stifR === "0"
      ? 4
      : symptomsScore.stifR === "1"
      ? 3
      : symptomsScore.stifR === "2"
      ? 2
      : symptomsScore.stifR === "3"
      ? 1
      : symptomsScore.stifR === "4"
      ? 0
      : 0;
  const hoosstifL =
    symptomsScore.stifL === "0"
      ? 4
      : symptomsScore.stifL === "1"
      ? 3
      : symptomsScore.stifL === "2"
      ? 2
      : symptomsScore.stifL === "3"
      ? 1
      : symptomsScore.stifL === "4"
      ? 0
      : 0;
  const hoosstifSitR =
    symptomsScore.stifSitR === "0"
      ? 4
      : symptomsScore.stifSitR === "1"
      ? 3
      : symptomsScore.stifSitR === "2"
      ? 2
      : symptomsScore.stifSitR === "3"
      ? 1
      : symptomsScore.stifSitR === "4"
      ? 0
      : 0;
  const hoosstifSitL =
    symptomsScore.stifSitL === "0"
      ? 4
      : symptomsScore.stifSitL === "1"
      ? 3
      : symptomsScore.stifSitL === "2"
      ? 2
      : symptomsScore.stifSitL === "3"
      ? 1
      : symptomsScore.stifSitL === "4"
      ? 0
      : 0;

  const hoosdesstairsR =
    functionScore.desstairsR === "0"
      ? 4
      : functionScore.desstairsR === "1"
      ? 3
      : functionScore.desstairsR === "2"
      ? 2
      : functionScore.desstairsR === "3"
      ? 1
      : functionScore.desstairsR === "4"
      ? 0
      : 0;

  const hoosdesstairsL =
    functionScore.desstairsL == "0"
      ? 4
      : functionScore.desstairsL == "1"
      ? 3
      : functionScore.desstairsL == "2"
      ? 2
      : functionScore.desstairsL == "3"
      ? 1
      : functionScore.desstairsL == "4"
      ? 0
      : 0;

  const hoosascstairsR =
    functionScore.ascstairsR === "0"
      ? 4
      : functionScore.ascstairsR === "1"
      ? 3
      : functionScore.ascstairsR === "2"
      ? 2
      : functionScore.ascstairsR === "3"
      ? 1
      : functionScore.ascstairsR === "4"
      ? 0
      : 0;

  const hoosascstairsL =
    functionScore.ascstairsL == "0"
      ? 4
      : functionScore.ascstairsL == "1"
      ? 3
      : functionScore.ascstairsL == "2"
      ? 2
      : functionScore.ascstairsL == "3"
      ? 1
      : functionScore.ascstairsL == "4"
      ? 0
      : 0;
  // Not used
  const hoossittingfR =
    functionScore.sittingR === "0"
      ? 4
      : functionScore.sittingR === "1"
      ? 3
      : functionScore.sittingR === "2"
      ? 2
      : functionScore.sittingR === "3"
      ? 1
      : functionScore.sittingR === "4"
      ? 0
      : 0;
  const hoossittingfL =
    functionScore.sittingL == "0"
      ? 4
      : functionScore.sittingL == "1"
      ? 3
      : functionScore.sittingL == "2"
      ? 2
      : functionScore.sittingL == "3"
      ? 1
      : functionScore.sittingL == "4"
      ? 0
      : 0;

  const hoosrisingSittingR =
    functionScore.risingSittingR === "0"
      ? 4
      : functionScore.risingSittingR === "1"
      ? 3
      : functionScore.risingSittingR === "2"
      ? 2
      : functionScore.risingSittingR === "3"
      ? 1
      : functionScore.risingSittingR === "4"
      ? 0
      : 0;
  const hoosrisingSittingL =
    functionScore.risingSittingL == "0"
      ? 4
      : functionScore.risingSittingL == "1"
      ? 3
      : functionScore.risingSittingL == "2"
      ? 2
      : functionScore.risingSittingL == "3"
      ? 1
      : functionScore.risingSittingL == "4"
      ? 0
      : 0;

  const hoosspreadingR =
    functionScore.spreadingR === "0"
      ? 4
      : functionScore.spreadingR === "1"
      ? 3
      : functionScore.spreadingR === "2"
      ? 2
      : functionScore.spreadingR === "3"
      ? 1
      : functionScore.spreadingR === "4"
      ? 0
      : 0;
  const hoosspreadingL =
    functionScore.spreadingL == "0"
      ? 4
      : functionScore.spreadingL == "1"
      ? 3
      : functionScore.spreadingL == "2"
      ? 2
      : functionScore.spreadingL == "3"
      ? 1
      : functionScore.spreadingL == "4"
      ? 0
      : 0;
  const hoosstrideR =
    functionScore.strideR === "0"
      ? 4
      : functionScore.strideR === "1"
      ? 3
      : functionScore.strideR === "2"
      ? 2
      : functionScore.strideR === "3"
      ? 1
      : functionScore.strideR === "4"
      ? 0
      : 0;
  const hoosstrideL =
    functionScore.strideL == "0"
      ? 4
      : functionScore.strideL == "1"
      ? 3
      : functionScore.strideL == "2"
      ? 2
      : functionScore.strideL == "3"
      ? 1
      : functionScore.strideL == "4"
      ? 0
      : 0;
  // Not Present
  const hoosfbendingFR =
    functionScore.funBendlinR === "0"
      ? 4
      : functionScore.funBendlinR === "1"
      ? 3
      : functionScore.funBendlinR === "2"
      ? 2
      : functionScore.funBendlinR === "3"
      ? 1
      : functionScore.funBendlinR === "4"
      ? 0
      : 0;
  const hoosfbendingFL =
    functionScore.funBendlinL == "0"
      ? 4
      : functionScore.funBendlinL == "1"
      ? 3
      : functionScore.funBendlinL == "2"
      ? 2
      : functionScore.funBendlinL == "3"
      ? 1
      : functionScore.funBendlinL == "4"
      ? 0
      : 0;
  const hooswalkingR =
    functionScore.walkingR === "0"
      ? 4
      : functionScore.walkingR === "1"
      ? 3
      : functionScore.walkingR === "2"
      ? 2
      : functionScore.walkingR === "3"
      ? 1
      : functionScore.walkingR === "4"
      ? 0
      : 0;
  const hooswalkingL =
    functionScore.walkingL == "0"
      ? 4
      : functionScore.walkingL == "1"
      ? 3
      : functionScore.walkingL == "2"
      ? 2
      : functionScore.walkingL == "3"
      ? 1
      : functionScore.walkingL == "4"
      ? 0
      : 0;
  const hooswalkUnevenR =
    functionScore.walkUnevenR === "0"
      ? 4
      : functionScore.walkUnevenR === "1"
      ? 3
      : functionScore.walkUnevenR === "2"
      ? 2
      : functionScore.walkUnevenR === "3"
      ? 1
      : functionScore.walkUnevenR === "4"
      ? 0
      : 0;
  const hooswalkUnevenL =
    functionScore.walkUnevenL == "0"
      ? 4
      : functionScore.walkUnevenL == "1"
      ? 3
      : functionScore.walkUnevenL == "2"
      ? 2
      : functionScore.walkUnevenL == "3"
      ? 1
      : functionScore.walkUnevenL == "4"
      ? 0
      : 0;

  const hoospublicTransR =
    functionScore.publicTransR === "0"
      ? 4
      : functionScore.publicTransR === "1"
      ? 3
      : functionScore.publicTransR === "2"
      ? 2
      : functionScore.publicTransR === "3"
      ? 1
      : functionScore.publicTransR === "4"
      ? 0
      : 0;
  const hoospublicTransL =
    functionScore.publicTransL == "0"
      ? 4
      : functionScore.publicTransL == "1"
      ? 3
      : functionScore.publicTransL == "2"
      ? 2
      : functionScore.publicTransL == "3"
      ? 1
      : functionScore.publicTransL == "4"
      ? 0
      : 0;

  const hoosgoingShopR =
    functionScore.goingShopR === "0"
      ? 4
      : functionScore.goingShopR === "1"
      ? 3
      : functionScore.goingShopR === "2"
      ? 2
      : functionScore.goingShopR === "3"
      ? 1
      : functionScore.goingShopR === "4"
      ? 0
      : 0;
  const hoosgoingShopL =
    functionScore.goingShopL == "0"
      ? 4
      : functionScore.goingShopL == "1"
      ? 3
      : functionScore.goingShopL == "2"
      ? 2
      : functionScore.goingShopL == "3"
      ? 1
      : functionScore.goingShopL == "4"
      ? 0
      : 0;

  const hoosputtingR =
    functionScore.puttingR === "0"
      ? 4
      : functionScore.puttingR === "1"
      ? 3
      : functionScore.puttingR === "2"
      ? 2
      : functionScore.puttingR === "3"
      ? 1
      : functionScore.puttingR === "4"
      ? 0
      : 0;
  const hoosputtingL =
    functionScore.puttingL == "0"
      ? 4
      : functionScore.puttingL == "1"
      ? 3
      : functionScore.puttingL == "2"
      ? 2
      : functionScore.puttingL == "3"
      ? 1
      : functionScore.puttingL == "4"
      ? 0
      : 0;

  const hoostakingR =
    functionScore.takingR === "0"
      ? 4
      : functionScore.takingR === "1"
      ? 3
      : functionScore.takingR === "2"
      ? 2
      : functionScore.takingR === "3"
      ? 1
      : functionScore.takingR === "4"
      ? 0
      : 0;
  const hoostakingL =
    functionScore.takingL == "0"
      ? 4
      : functionScore.takingL == "1"
      ? 3
      : functionScore.takingL == "2"
      ? 2
      : functionScore.takingL == "3"
      ? 1
      : functionScore.takingL == "4"
      ? 0
      : 0;
  const hooslyingR =
    functionScore.lyingR === "0"
      ? 4
      : functionScore.lyingR === "1"
      ? 3
      : functionScore.lyingR === "2"
      ? 2
      : functionScore.lyingR === "3"
      ? 1
      : functionScore.lyingR === "4"
      ? 0
      : 0;
  const hooslyingL =
    functionScore.lyingL == "0"
      ? 4
      : functionScore.lyingL == "1"
      ? 3
      : functionScore.lyingL == "2"
      ? 2
      : functionScore.lyingL == "3"
      ? 1
      : functionScore.lyingL == "4"
      ? 0
      : 0;
  const hoosrisingR =
    functionScore.risingR === "0"
      ? 4
      : functionScore.risingR === "1"
      ? 3
      : functionScore.risingR === "2"
      ? 2
      : functionScore.risingR === "3"
      ? 1
      : functionScore.risingR === "4"
      ? 0
      : 0;
  const hoosrisingL =
    functionScore.risingL == "0"
      ? 4
      : functionScore.risingL == "1"
      ? 3
      : functionScore.risingL == "2"
      ? 2
      : functionScore.risingL == "3"
      ? 1
      : functionScore.risingL == "4"
      ? 0
      : 0;
  const hoosgetbathR =
    functionScore.getbathR === "0"
      ? 4
      : functionScore.getbathR === "1"
      ? 3
      : functionScore.getbathR === "2"
      ? 2
      : functionScore.getbathR === "3"
      ? 1
      : functionScore.getbathR === "4"
      ? 0
      : 0;
  const hoosgetbathL =
    functionScore.getbathL == "0"
      ? 4
      : functionScore.getbathL == "1"
      ? 3
      : functionScore.getbathL == "2"
      ? 2
      : functionScore.getbathL == "3"
      ? 1
      : functionScore.getbathL == "4"
      ? 0
      : 0;
  const hoosgettoiletR =
    functionScore.gettoiletR === "0"
      ? 4
      : functionScore.gettoiletR === "1"
      ? 3
      : functionScore.gettoiletR === "2"
      ? 2
      : functionScore.gettoiletR === "3"
      ? 1
      : functionScore.gettoiletR === "4"
      ? 0
      : 0;
  const hoosgettoiletL =
    functionScore.gettoiletL == "0"
      ? 4
      : functionScore.gettoiletL == "1"
      ? 3
      : functionScore.gettoiletL == "2"
      ? 2
      : functionScore.gettoiletL == "3"
      ? 1
      : functionScore.gettoiletL == "4"
      ? 0
      : 0;

  const hoosheavyR =
    functionScore.heavyR === "0"
      ? 4
      : functionScore.heavyR === "1"
      ? 3
      : functionScore.heavyR === "2"
      ? 2
      : functionScore.heavyR === "3"
      ? 1
      : functionScore.heavyR === "4"
      ? 0
      : 0;
  const hoosheavyL =
    functionScore.heavyL == "0"
      ? 4
      : functionScore.heavyL == "1"
      ? 3
      : functionScore.heavyL == "2"
      ? 2
      : functionScore.heavyL == "3"
      ? 1
      : functionScore.heavyL == "4"
      ? 0
      : 0;
  const hooslightDomesticR =
    functionScore.lightDomesticR === "0"
      ? 4
      : functionScore.lightDomesticR === "1"
      ? 3
      : functionScore.lightDomesticR === "2"
      ? 2
      : functionScore.lightDomesticR === "3"
      ? 1
      : functionScore.lightDomesticR === "4"
      ? 0
      : 0;
  const hooslightDomesticL =
    functionScore.lightDomesticL == "0"
      ? 4
      : functionScore.lightDomesticL == "1"
      ? 3
      : functionScore.lightDomesticL == "2"
      ? 2
      : functionScore.lightDomesticL == "3"
      ? 1
      : functionScore.lightDomesticL == "4"
      ? 0
      : 0;
  const hoossquattingR =
    functionScore.squattingR === "0"
      ? 4
      : functionScore.squattingR === "1"
      ? 3
      : functionScore.squattingR === "2"
      ? 2
      : functionScore.squattingR === "3"
      ? 1
      : functionScore.squattingR === "4"
      ? 0
      : 0;
  const hoossquattingL =
    functionScore.squattingL == "0"
      ? 4
      : functionScore.squattingL == "1"
      ? 3
      : functionScore.squattingL == "2"
      ? 2
      : functionScore.squattingL == "3"
      ? 1
      : functionScore.squattingL == "4"
      ? 0
      : 0;
  const hoosrunningR =
    functionScore.runningR === "0"
      ? 4
      : functionScore.runningR === "1"
      ? 3
      : functionScore.runningR === "2"
      ? 2
      : functionScore.runningR === "3"
      ? 1
      : functionScore.runningR === "4"
      ? 0
      : 0;
  const hoosrunningL =
    functionScore.runningL == "0"
      ? 4
      : functionScore.runningL == "1"
      ? 3
      : functionScore.runningL == "2"
      ? 2
      : functionScore.runningL == "3"
      ? 1
      : functionScore.runningL == "4"
      ? 0
      : 0;

  const hoostwistingR =
    functionScore.twistingR === "0"
      ? 4 //
      : functionScore.twistingR === "1"
      ? 3
      : functionScore.twistingR === "2"
      ? 2
      : functionScore.twistingR === "3"
      ? 1
      : functionScore.twistingR === "4"
      ? 0
      : 0;
  const hoostwistingL =
    functionScore.twistingL == "0"
      ? 4
      : functionScore.twistingL == "1"
      ? 3
      : functionScore.twistingL == "2"
      ? 2
      : functionScore.twistingL == "3"
      ? 1
      : functionScore.twistingL == "4"
      ? 0
      : 0;
  const hoosaware =
    qolScore.aware == "0"
      ? 4
      : qolScore.aware == "1"
      ? 3
      : qolScore.aware == "2"
      ? 2
      : qolScore.aware == "3"
      ? 1
      : qolScore.aware == "4"
      ? 0
      : 0;
  const hoosawareL =
    qolScore.awareL == "0"
      ? 4
      : qolScore.awareL == "1"
      ? 3
      : qolScore.awareL == "2"
      ? 2
      : qolScore.awareL == "3"
      ? 1
      : qolScore.awareL == "4"
      ? 0
      : 0;

  const hoosmodified =
    qolScore.modified == "0"
      ? 4
      : qolScore.modified == "1"
      ? 3
      : qolScore.modified == "2"
      ? 2
      : qolScore.modified == "3"
      ? 1
      : qolScore.modified == "4"
      ? 0
      : 0;

  const hoosmodifiedL =
    qolScore.modifiedL == "0"
      ? 4
      : qolScore.modifiedL == "1"
      ? 3
      : qolScore.modifiedL == "2"
      ? 2
      : qolScore.modifiedL == "3"
      ? 1
      : qolScore.modifiedL == "4"
      ? 0
      : 0;

  const hoostroubled =
    qolScore.troubled == "0"
      ? 4
      : qolScore.troubled == "1"
      ? 3
      : qolScore.troubled == "2"
      ? 2
      : qolScore.troubled == "3"
      ? 1
      : qolScore.troubled == "4"
      ? 0
      : 0;
  const hoostroubledL =
    qolScore.troubledL == "0"
      ? 4
      : qolScore.troubledL == "1"
      ? 3
      : qolScore.troubledL == "2"
      ? 2
      : qolScore.troubledL == "3"
      ? 1
      : qolScore.troubledL == "4"
      ? 0
      : 0;
  const hoosdifficulty =
    qolScore.difficulty == "0"
      ? 4
      : qolScore.difficulty == "1"
      ? 3
      : qolScore.difficulty == "2"
      ? 2
      : qolScore.difficulty == "3"
      ? 1
      : qolScore.difficulty == "4"
      ? 0
      : 0;
  const hoosdifficultyL =
    qolScore.difficultyL == "0"
      ? 4
      : qolScore.difficultyL == "1"
      ? 3
      : qolScore.difficultyL == "2"
      ? 2
      : qolScore.difficultyL == "3"
      ? 1
      : qolScore.difficultyL == "4"
      ? 0
      : 0;

  // hoos End ========================================

  // OHS (OXFORD HIP SCORE)

  const ohspainTodayRatingL =
    painSco.painTodayRatingL === "0"
      ? 4
      : painSco.painTodayRatingL === "1"
      ? 3
      : painSco.painTodayRatingL === "2"
      ? 2
      : painSco.painTodayRatingL === "3"
      ? 2
      : painSco.painTodayRatingL === "4"
      ? 1
      : painSco.painTodayRatingL === "5"
      ? 1
      : painSco.painTodayRatingL === "6"
      ? 1
      : painSco.painTodayRatingL === "7"
      ? 0
      : painSco.painTodayRatingL === "8"
      ? 0
      : painSco.painTodayRatingL === "9"
      ? 0
      : painSco.painTodayRatingL === "10"
      ? 0
      : 0;

  const ohspainTodayRatingR =
    painSco.painTodayRatingR === "0"
      ? 4
      : painSco.painTodayRatingR === "1"
      ? 3
      : painSco.painTodayRatingR === "2"
      ? 2
      : painSco.painTodayRatingR === "3"
      ? 2
      : painSco.painTodayRatingR === "4"
      ? 1
      : painSco.painTodayRatingR === "5"
      ? 1
      : painSco.painTodayRatingR === "6"
      ? 1
      : painSco.painTodayRatingR === "7"
      ? 0
      : painSco.painTodayRatingR === "8"
      ? 0
      : painSco.painTodayRatingR === "9"
      ? 0
      : painSco.painTodayRatingR === "10"
      ? 0
      : 0;

  const ohsinterferedR =
    painSco.interferedR === "0"
      ? 0
      : painSco.interferedR === "1"
      ? 1
      : painSco.interferedR === "2"
      ? 2
      : painSco.interferedR === "3"
      ? 3
      : painSco.interferedR === "4"
      ? 4
      : painSco.interferedR === "5"
      ? 4
      : 0;

  const ohsinterferedL =
    painSco.interferedL === "0"
      ? 0
      : painSco.interferedL === "1"
      ? 1
      : painSco.interferedL === "2"
      ? 2
      : painSco.interferedL === "3"
      ? 3
      : painSco.interferedL === "4"
      ? 4
      : painSco.interferedL === "5"
      ? 4
      : 0;

  const ohswalkR = parseFloat(painSco.walkR);
  const ohswalkL = parseFloat(painSco.walkL);
  // Changes
  const ohstroubledR =
    painSco.troubledR === "0"
      ? 4
      : painSco.troubledR === "1"
      ? 3
      : painSco.troubledR === "2"
      ? 2
      : painSco.troubledR === "3"
      ? 1
      : painSco.troubledR === "4"
      ? 0
      : 0;

  const ohstroubledL =
    painSco.troubledL === "0"
      ? 4
      : painSco.troubledL === "1"
      ? 3
      : painSco.troubledL === "2"
      ? 2
      : painSco.troubledL === "3"
      ? 1
      : painSco.troubledL === "4"
      ? 0
      : 0;

  const ohschairR =
    painSco.chairR === "0"
      ? 4
      : painSco.chairR === "1"
      ? 3
      : painSco.chairR === "2"
      ? 2
      : painSco.chairR === "3"
      ? 1
      : painSco.chairR === "4"
      ? 0
      : 0;

  const ohschairL =
    painSco.chairL === "0"
      ? 4
      : painSco.chairL === "1"
      ? 3
      : painSco.chairL === "2"
      ? 2
      : painSco.chairL === "3"
      ? 1
      : painSco.chairL === "4"
      ? 0
      : 0;

  const ohslimpingR =
    symptomsScore.limpingR === "0"
      ? 4
      : symptomsScore.limpingR === "1"
      ? 3
      : symptomsScore.limpingR === "2"
      ? 2
      : symptomsScore.limpingR === "3"
      ? 1
      : symptomsScore.limpingR === "4"
      ? 0
      : 0;

  const ohslimpingL =
    symptomsScore.limpingL === "0"
      ? 4
      : symptomsScore.limpingL === "1"
      ? 3
      : symptomsScore.limpingL === "2"
      ? 2
      : symptomsScore.limpingL === "3"
      ? 1
      : symptomsScore.limpingL === "4"
      ? 0
      : 0;

  const ohssuddenR =
    symptomsScore.suddenR === "0"
      ? 4
      : symptomsScore.suddenR === "1"
      ? 3
      : symptomsScore.suddenR === "2"
      ? 2
      : symptomsScore.suddenR === "3"
      ? 1
      : symptomsScore.suddenR === "4"
      ? 0
      : 0;

  const ohssuddenL =
    symptomsScore.suddenL === "0"
      ? 4
      : symptomsScore.suddenL === "1"
      ? 3
      : symptomsScore.suddenL === "2"
      ? 2
      : symptomsScore.suddenL === "3"
      ? 1
      : symptomsScore.suddenL === "4"
      ? 0
      : 0;

  const ohssascstairsR =
    functionScore.ascstairsR === "0"
      ? 4
      : functionScore.ascstairsR === "1"
      ? 3
      : functionScore.ascstairsR === "2"
      ? 2
      : functionScore.ascstairsR === "3"
      ? 1
      : functionScore.ascstairsR === "4"
      ? 0
      : 0;
  const ohssascstairsL =
    functionScore.ascstairsL == "0"
      ? 4
      : functionScore.ascstairsL == "1"
      ? 3
      : functionScore.ascstairsL == "2"
      ? 2
      : functionScore.ascstairsL == "3"
      ? 1
      : functionScore.ascstairsL == "4"
      ? 0
      : 0;
  const ohspublicTransR =
    functionScore.publicTransR === "0"
      ? 4
      : functionScore.publicTransR === "1"
      ? 3
      : functionScore.publicTransR === "2"
      ? 2
      : functionScore.publicTransR === "3"
      ? 1
      : functionScore.publicTransR === "4"
      ? 0
      : 0;
  const ohspublicTransL =
    functionScore.publicTransL == "0"
      ? 4
      : functionScore.publicTransL == "1"
      ? 3
      : functionScore.publicTransL == "2"
      ? 2
      : functionScore.publicTransL == "3"
      ? 1
      : functionScore.publicTransL == "4"
      ? 0
      : 0;
  const ohsgoingShopR =
    functionScore.goingShopR === "0"
      ? 4
      : functionScore.goingShopR === "1"
      ? 3
      : functionScore.goingShopR === "2"
      ? 2
      : functionScore.goingShopR === "3"
      ? 1
      : functionScore.goingShopR === "4"
      ? 0
      : 0;
  const ohsgoingShopL =
    functionScore.goingShopL == "0"
      ? 4
      : functionScore.goingShopL == "1"
      ? 3
      : functionScore.goingShopL == "2"
      ? 2
      : functionScore.goingShopL == "3"
      ? 1
      : functionScore.goingShopL == "4"
      ? 0
      : 0;

  const ohsputtingR =
    functionScore.puttingR === "0"
      ? 4
      : functionScore.puttingR === "1"
      ? 3
      : functionScore.puttingR === "2"
      ? 2
      : functionScore.puttingR === "3"
      ? 1
      : functionScore.puttingR === "4"
      ? 0
      : 0;
  const ohsputtingL =
    functionScore.puttingL == "0"
      ? 4
      : functionScore.puttingL == "1"
      ? 3
      : functionScore.puttingL == "2"
      ? 2
      : functionScore.puttingL == "3"
      ? 1
      : functionScore.puttingL == "4"
      ? 0
      : 0;

  const ohswashingR =
    functionScore.washingR === "0"
      ? 4
      : functionScore.washingR === "1"
      ? 3
      : functionScore.washingR === "2"
      ? 2
      : functionScore.washingR === "3"
      ? 1
      : functionScore.washingR === "4"
      ? 0
      : 0;
  const ohswashingL =
    functionScore.washingL == "0"
      ? 4
      : functionScore.washingL == "1"
      ? 3
      : functionScore.washingL == "2"
      ? 2
      : functionScore.washingL == "3"
      ? 1
      : functionScore.washingL == "4"
      ? 0
      : 0;

  // WOMAC (Western Ontario and McMaster Universities Osteoarthritis Index)
  const womacsurfaceR =
    painSco.surfaceR === "0"
      ? 4
      : painSco.surfaceR === "1"
      ? 3
      : painSco.surfaceR === "2"
      ? 2
      : painSco.surfaceR === "3"
      ? 1
      : painSco.surfaceR === "4"
      ? 0
      : 0;
  const womacsurfaceL =
    painSco.surfaceL === "0"
      ? 4
      : painSco.surfaceL === "1"
      ? 3
      : painSco.surfaceL === "2"
      ? 2
      : painSco.surfaceL === "3"
      ? 1
      : painSco.surfaceL === "4"
      ? 0
      : 0;
  const womacstairsR =
    painSco.stairsR === "0"
      ? 4
      : painSco.stairsR === "1"
      ? 3
      : painSco.stairsR === "2"
      ? 2
      : painSco.stairsR === "3"
      ? 1
      : painSco.stairsR === "4"
      ? 0
      : 0;
  const womacstairsL =
    painSco.stairsL === "0"
      ? 4
      : painSco.stairsL === "1"
      ? 3
      : painSco.stairsL === "2"
      ? 2
      : painSco.stairsL === "3"
      ? 1
      : painSco.stairsL === "4"
      ? 0
      : 0;
  const womacbedR =
    painSco.bedR === "0"
      ? 4
      : painSco.bedR === "1"
      ? 3
      : painSco.bedR === "2"
      ? 2
      : painSco.bedR === "3"
      ? 1
      : painSco.bedR === "4"
      ? 0
      : 0;
  const womacbedL =
    painSco.bedL === "0"
      ? 4
      : painSco.bedL === "1"
      ? 3
      : painSco.bedL === "2"
      ? 2
      : painSco.bedL === "3"
      ? 1
      : painSco.bedL === "4"
      ? 0
      : 0;

  const womacsittingR =
    painSco.sittingR === "0"
      ? 4
      : painSco.sittingR === "1"
      ? 3
      : painSco.sittingR === "2"
      ? 2
      : painSco.sittingR === "3"
      ? 1
      : painSco.sittingR === "4"
      ? 0
      : 0;
  const womacsittingL =
    painSco.sittingL === "0"
      ? 4
      : painSco.sittingL === "1"
      ? 3
      : painSco.sittingL === "2"
      ? 2
      : painSco.sittingL === "3"
      ? 1
      : painSco.sittingL === "4"
      ? 0
      : 0;
  const womacuprightR =
    painSco.uprightR === "0"
      ? 4
      : painSco.uprightR === "1"
      ? 3
      : painSco.uprightR === "2"
      ? 2
      : painSco.uprightR === "3"
      ? 1
      : painSco.uprightR === "4"
      ? 0
      : 0;
  const womacuprightL =
    painSco.uprightL === "0"
      ? 4
      : painSco.uprightL === "1"
      ? 3
      : painSco.uprightL === "2"
      ? 2
      : painSco.uprightL === "3"
      ? 1
      : painSco.uprightL === "4"
      ? 0
      : 0;
  const womacstifR =
    symptomsScore.stifR === "0"
      ? 4
      : symptomsScore.stifR === "1"
      ? 3
      : symptomsScore.stifR === "2"
      ? 2
      : symptomsScore.stifR === "3"
      ? 1
      : symptomsScore.stifR === "4"
      ? 0
      : 0;
  const womacstifL =
    symptomsScore.stifL === "0"
      ? 4
      : symptomsScore.stifL === "1"
      ? 3
      : symptomsScore.stifL === "2"
      ? 2
      : symptomsScore.stifL === "3"
      ? 1
      : symptomsScore.stifL === "4"
      ? 0
      : 0;
  const womacstifSitR =
    symptomsScore.stifSitR === "0"
      ? 4
      : symptomsScore.stifSitR === "1"
      ? 3
      : symptomsScore.stifSitR === "2"
      ? 2
      : symptomsScore.stifSitR === "3"
      ? 1
      : symptomsScore.stifSitR === "4"
      ? 0
      : 0;
  const womacstifSitL =
    symptomsScore.stifSitL === "0"
      ? 4
      : symptomsScore.stifSitL === "1"
      ? 3
      : symptomsScore.stifSitL === "2"
      ? 2
      : symptomsScore.stifSitL === "3"
      ? 1
      : symptomsScore.stifSitL === "4"
      ? 0
      : 0;
  const womacdesstairsR =
    functionScore.desstairsR === "0"
      ? 4
      : functionScore.desstairsR === "1"
      ? 3
      : functionScore.desstairsR === "2"
      ? 2
      : functionScore.desstairsR === "3"
      ? 1
      : functionScore.desstairsR === "4"
      ? 0
      : 0;
  const womacdesstairsL =
    functionScore.desstairsL == "0"
      ? 4
      : functionScore.desstairsL == "1"
      ? 3
      : functionScore.desstairsL == "2"
      ? 2
      : functionScore.desstairsL == "3"
      ? 1
      : functionScore.desstairsL == "4"
      ? 0
      : 0;
  const womacsascstairsR =
    functionScore.ascstairsR === "0"
      ? 4
      : functionScore.ascstairsR === "1"
      ? 3
      : functionScore.ascstairsR === "2"
      ? 2
      : functionScore.ascstairsR === "3"
      ? 1
      : functionScore.ascstairsR === "4"
      ? 0
      : 0;
  const womacsascstairsL =
    functionScore.ascstairsL == "0"
      ? 4
      : functionScore.ascstairsL == "1"
      ? 3
      : functionScore.ascstairsL == "2"
      ? 2
      : functionScore.ascstairsL == "3"
      ? 1
      : functionScore.ascstairsL == "4"
      ? 0
      : 0;
  const womacsittingfR =
    functionScore.sittingR === "0"
      ? 4
      : functionScore.sittingR === "1"
      ? 3
      : functionScore.sittingR === "2"
      ? 2
      : functionScore.sittingR === "3"
      ? 1
      : functionScore.sittingR === "4"
      ? 0
      : 0;
  const womacsittingfL =
    functionScore.sittingL == "0"
      ? 4
      : functionScore.sittingL == "1"
      ? 3
      : functionScore.sittingL == "2"
      ? 2
      : functionScore.sittingL == "3"
      ? 1
      : functionScore.sittingL == "4"
      ? 0
      : 0;

  const womacrisingSittingR =
    functionScore.risingSittingR === "0"
      ? 4
      : functionScore.risingSittingR === "1"
      ? 3
      : functionScore.risingSittingR === "2"
      ? 2
      : functionScore.risingSittingR === "3"
      ? 1
      : functionScore.risingSittingR === "4"
      ? 0
      : 0;
  const womacrisingSittingL =
    functionScore.risingSittingL == "0"
      ? 4
      : functionScore.risingSittingL == "1"
      ? 3
      : functionScore.risingSittingL == "2"
      ? 2
      : functionScore.risingSittingL == "3"
      ? 1
      : functionScore.risingSittingL == "4"
      ? 0
      : 0;
  const womacstandingR =
    functionScore.standingR === "0"
      ? 4
      : functionScore.standingR === "1"
      ? 3
      : functionScore.standingR === "2"
      ? 2
      : functionScore.standingR === "3"
      ? 1
      : functionScore.standingR === "4"
      ? 0
      : 0;
  const womacstandingL =
    functionScore.standingL == "0"
      ? 4
      : functionScore.standingL == "1"
      ? 3
      : functionScore.standingL == "2"
      ? 2
      : functionScore.standingL == "3"
      ? 1
      : functionScore.standingL == "4"
      ? 0
      : 0;
  // Not present in WOMAC
  const womacbendingR =
    functionScore.funBendlinR === "0"
      ? 4
      : functionScore.funBendlinR === "1"
      ? 3
      : functionScore.funBendlinR === "2"
      ? 2
      : functionScore.funBendlinR === "3"
      ? 1
      : functionScore.funBendlinR === "4"
      ? 0
      : 0;
  const womacbendingL =
    functionScore.funBendlinL == "0"
      ? 4
      : functionScore.funBendlinL == "1"
      ? 3
      : functionScore.funBendlinL == "2"
      ? 2
      : functionScore.funBendlinL == "3"
      ? 1
      : functionScore.funBendlinL == "4"
      ? 0
      : 0;

  const womacwalkingR =
    functionScore.walkingR === "0"
      ? 4
      : functionScore.walkingR === "1"
      ? 3
      : functionScore.walkingR === "2"
      ? 2
      : functionScore.walkingR === "3"
      ? 1
      : functionScore.walkingR === "4"
      ? 0
      : 0;
  const womacwalkingL =
    functionScore.walkingL == "0"
      ? 4
      : functionScore.walkingL == "1"
      ? 3
      : functionScore.walkingL == "2"
      ? 2
      : functionScore.walkingL == "3"
      ? 1
      : functionScore.walkingL == "4"
      ? 0
      : 0;
  const womacpublicTransR =
    functionScore.publicTransR === "0"
      ? 4
      : functionScore.publicTransR === "1"
      ? 3
      : functionScore.publicTransR === "2"
      ? 2
      : functionScore.publicTransR === "3"
      ? 1
      : functionScore.publicTransR === "4"
      ? 0
      : 0;
  const womacpublicTransL =
    functionScore.publicTransL == "0"
      ? 4
      : functionScore.publicTransL == "1"
      ? 3
      : functionScore.publicTransL == "2"
      ? 2
      : functionScore.publicTransL == "3"
      ? 1
      : functionScore.publicTransL == "4"
      ? 0
      : 0;
  const womacgoingShopR =
    functionScore.goingShopR === "0"
      ? 4
      : functionScore.goingShopR === "1"
      ? 3
      : functionScore.goingShopR === "2"
      ? 2
      : functionScore.goingShopR === "3"
      ? 1
      : functionScore.goingShopR === "4"
      ? 0
      : 0;
  const womacgoingShopL =
    functionScore.goingShopL == "0"
      ? 4
      : functionScore.goingShopL == "1"
      ? 3
      : functionScore.goingShopL == "2"
      ? 2
      : functionScore.goingShopL == "3"
      ? 1
      : functionScore.goingShopL == "4"
      ? 0
      : 0;
  const womacputtingR =
    functionScore.puttingR === "0"
      ? 4
      : functionScore.puttingR === "1"
      ? 3
      : functionScore.puttingR === "2"
      ? 2
      : functionScore.puttingR === "3"
      ? 1
      : functionScore.puttingR === "4"
      ? 0
      : 0;
  const womacputtingL =
    functionScore.puttingL == "0"
      ? 4
      : functionScore.puttingL == "1"
      ? 3
      : functionScore.puttingL == "2"
      ? 2
      : functionScore.puttingL == "3"
      ? 1
      : functionScore.puttingL == "4"
      ? 0
      : 0;

  const womactakingR =
    functionScore.takingR === "0"
      ? 4
      : functionScore.takingR === "1"
      ? 3
      : functionScore.takingR === "2"
      ? 2
      : functionScore.takingR === "3"
      ? 1
      : functionScore.takingR === "4"
      ? 0
      : 0;
  const womactakingL =
    functionScore.takingL == "0"
      ? 4
      : functionScore.takingL == "1"
      ? 3
      : functionScore.takingL == "2"
      ? 2
      : functionScore.takingL == "3"
      ? 1
      : functionScore.takingL == "4"
      ? 0
      : 0;

  const womaclyingR =
    functionScore.lyingR === "0"
      ? 4
      : functionScore.lyingR === "1"
      ? 3
      : functionScore.lyingR === "2"
      ? 2
      : functionScore.lyingR === "3"
      ? 1
      : functionScore.lyingR === "4"
      ? 0
      : 0;
  const womaclyingL =
    functionScore.lyingL == "0"
      ? 4
      : functionScore.lyingL == "1"
      ? 3
      : functionScore.lyingL == "2"
      ? 2
      : functionScore.lyingL == "3"
      ? 1
      : functionScore.lyingL == "4"
      ? 0
      : 0;
  const womacrisingR =
    functionScore.risingR === "0"
      ? 4
      : functionScore.risingR === "1"
      ? 3
      : functionScore.risingR === "2"
      ? 2
      : functionScore.risingR === "3"
      ? 1
      : functionScore.risingR === "4"
      ? 0
      : 0;
  const womacrisingL =
    functionScore.risingL == "0"
      ? 4
      : functionScore.risingL == "1"
      ? 3
      : functionScore.risingL == "2"
      ? 2
      : functionScore.risingL == "3"
      ? 1
      : functionScore.risingL == "4"
      ? 0
      : 0;
  const womacgetbathR =
    functionScore.getbathR === "0"
      ? 4
      : functionScore.getbathR === "1"
      ? 3
      : functionScore.getbathR === "2"
      ? 2
      : functionScore.getbathR === "3"
      ? 1
      : functionScore.getbathR === "4"
      ? 0
      : 0;
  const womacgetbathL =
    functionScore.getbathL == "0"
      ? 4
      : functionScore.getbathL == "1"
      ? 3
      : functionScore.getbathL == "2"
      ? 2
      : functionScore.getbathL == "3"
      ? 1
      : functionScore.getbathL == "4"
      ? 0
      : 0;
  const womacgettoiletR =
    functionScore.gettoiletR === "0"
      ? 4
      : functionScore.gettoiletR === "1"
      ? 3
      : functionScore.gettoiletR === "2"
      ? 2
      : functionScore.gettoiletR === "3"
      ? 1
      : functionScore.gettoiletR === "4"
      ? 0
      : 0;
  const womacgettoiletL =
    functionScore.gettoiletL == "0"
      ? 4
      : functionScore.gettoiletL == "1"
      ? 3
      : functionScore.gettoiletL == "2"
      ? 2
      : functionScore.gettoiletL == "3"
      ? 1
      : functionScore.gettoiletL == "4"
      ? 0
      : 0;
  const womacheavyR =
    functionScore.heavyR === "0"
      ? 4
      : functionScore.heavyR === "1"
      ? 3
      : functionScore.heavyR === "2"
      ? 2
      : functionScore.heavyR === "3"
      ? 1
      : functionScore.heavyR === "4"
      ? 0
      : 0;
  const womacheavyL =
    functionScore.heavyL == "0"
      ? 4
      : functionScore.heavyL == "1"
      ? 3
      : functionScore.heavyL == "2"
      ? 2
      : functionScore.heavyL == "3"
      ? 1
      : functionScore.heavyL == "4"
      ? 0
      : 0;
  const womaclightDomesticR =
    functionScore.lightDomesticR === "0"
      ? 4
      : functionScore.lightDomesticR === "1"
      ? 3
      : functionScore.lightDomesticR === "2"
      ? 2
      : functionScore.lightDomesticR === "3"
      ? 1
      : functionScore.lightDomesticR === "4"
      ? 0
      : 0;
  const womaclightDomesticL =
    functionScore.lightDomesticL == "0"
      ? 4
      : functionScore.lightDomesticL == "1"
      ? 3
      : functionScore.lightDomesticL == "2"
      ? 2
      : functionScore.lightDomesticL == "3"
      ? 1
      : functionScore.lightDomesticL == "4"
      ? 0
      : 0;
  // NAHS (NON ARTHRITIC HIP SCORE)
  const nahssurfaceR =
    painSco.surfaceR === "0"
      ? 4
      : painSco.surfaceR === "1"
      ? 3
      : painSco.surfaceR === "2"
      ? 2
      : painSco.surfaceR === "3"
      ? 1
      : painSco.surfaceR === "4"
      ? 0
      : 0;
  const nahssurfaceL =
    painSco.surfaceL === "0"
      ? 4
      : painSco.surfaceL === "1"
      ? 3
      : painSco.surfaceL === "2"
      ? 2
      : painSco.surfaceL === "3"
      ? 1
      : painSco.surfaceL === "4"
      ? 0
      : 0;
  const nahsstairsR =
    painSco.stairsR === "0"
      ? 4
      : painSco.stairsR === "1"
      ? 3
      : painSco.stairsR === "2"
      ? 2
      : painSco.stairsR === "3"
      ? 1
      : painSco.stairsR === "4"
      ? 0
      : 0;
  const nahsstairsL =
    painSco.stairsL === "0"
      ? 4
      : painSco.stairsL === "1"
      ? 3
      : painSco.stairsL === "2"
      ? 2
      : painSco.stairsL === "3"
      ? 1
      : painSco.stairsL === "4"
      ? 0
      : 0;
  const nahsbedR =
    painSco.bedR === "0"
      ? 4
      : painSco.bedR === "1"
      ? 3
      : painSco.bedR === "2"
      ? 2
      : painSco.bedR === "3"
      ? 1
      : painSco.bedR === "4"
      ? 0
      : 0;
  const nahsbedL =
    painSco.bedL === "0"
      ? 4
      : painSco.bedL === "1"
      ? 3
      : painSco.bedL === "2"
      ? 2
      : painSco.bedL === "3"
      ? 1
      : painSco.bedL === "4"
      ? 0
      : 0;
  const nahssittingR =
    painSco.sittingR === "0"
      ? 4
      : painSco.sittingR === "1"
      ? 3
      : painSco.sittingR === "2"
      ? 2
      : painSco.sittingR === "3"
      ? 1
      : painSco.sittingR === "4"
      ? 0
      : 0;
  const nahssittingL =
    painSco.sittingL === "0"
      ? 4
      : painSco.sittingL === "1"
      ? 3
      : painSco.sittingL === "2"
      ? 2
      : painSco.sittingL === "3"
      ? 1
      : painSco.sittingL === "4"
      ? 0
      : 0;
  const nahsuprightR =
    painSco.uprightR === "0"
      ? 4
      : painSco.uprightR === "1"
      ? 3
      : painSco.uprightR === "2"
      ? 2
      : painSco.uprightR === "3"
      ? 1
      : painSco.uprightR === "4"
      ? 0
      : 0;
  const nahsuprightL =
    painSco.uprightL === "0"
      ? 4
      : painSco.uprightL === "1"
      ? 3
      : painSco.uprightL === "2"
      ? 2
      : painSco.uprightL === "3"
      ? 1
      : painSco.uprightL === "4"
      ? 0
      : 0;
  const nahscatchingR =
    symptomsScore.catchingR === "0"
      ? 4
      : symptomsScore.catchingR === "1"
      ? 3
      : symptomsScore.catchingR === "2"
      ? 2
      : symptomsScore.catchingR === "3"
      ? 1
      : symptomsScore.catchingR === "4"
      ? 0
      : 0;
  const nahscatchingL =
    symptomsScore.catchingL === "0"
      ? 4
      : symptomsScore.catchingL === "1"
      ? 3
      : symptomsScore.catchingL === "2"
      ? 2
      : symptomsScore.catchingL === "3"
      ? 1
      : symptomsScore.catchingL === "4"
      ? 0
      : 0;
  const nahsstifR =
    symptomsScore.stifR === "0"
      ? 4
      : symptomsScore.stifR === "1"
      ? 3
      : symptomsScore.stifR === "2"
      ? 2
      : symptomsScore.stifR === "3"
      ? 1
      : symptomsScore.stifR === "4"
      ? 0
      : 0;
  const nahsstifL =
    symptomsScore.stifL === "0"
      ? 4
      : symptomsScore.stifL === "1"
      ? 3
      : symptomsScore.stifL === "2"
      ? 2
      : symptomsScore.stifL === "3"
      ? 1
      : symptomsScore.stifL === "4"
      ? 0
      : 0;
  const nahsstifSitR =
    symptomsScore.stifSitR === "0"
      ? 4
      : symptomsScore.stifSitR === "1"
      ? 3
      : symptomsScore.stifSitR === "2"
      ? 2
      : symptomsScore.stifSitR === "3"
      ? 1
      : symptomsScore.stifSitR === "4"
      ? 0
      : 0;
  const nahsstifSitL =
    symptomsScore.stifSitL === "0"
      ? 4
      : symptomsScore.stifSitL === "1"
      ? 3
      : symptomsScore.stifSitL === "2"
      ? 2
      : symptomsScore.stifSitL === "3"
      ? 1
      : symptomsScore.stifSitL === "4"
      ? 0
      : 0;
  const nahshipGivingR =
    symptomsScore.hipGivingR === "0"
      ? 4
      : symptomsScore.hipGivingR === "1"
      ? 3
      : symptomsScore.hipGivingR === "2"
      ? 2
      : symptomsScore.hipGivingR === "3"
      ? 1
      : symptomsScore.hipGivingR === "4"
      ? 0
      : 0;
  const nahshipGivingL =
    symptomsScore.hipGivingL === "0"
      ? 4
      : symptomsScore.hipGivingL === "1"
      ? 3
      : symptomsScore.hipGivingL === "2"
      ? 2
      : symptomsScore.hipGivingL === "3"
      ? 1
      : symptomsScore.hipGivingL === "4"
      ? 0
      : 0;
  const nahsdecreasedR =
    symptomsScore.decreasedR === "0"
      ? 4
      : symptomsScore.decreasedR === "1"
      ? 3
      : symptomsScore.decreasedR === "2"
      ? 2
      : symptomsScore.decreasedR === "3"
      ? 1
      : symptomsScore.decreasedR === "4"
      ? 0
      : 0;
  const nahsdecreasedL =
    symptomsScore.decreasedL === "0"
      ? 4
      : symptomsScore.decreasedL === "1"
      ? 3
      : symptomsScore.decreasedL === "2"
      ? 2
      : symptomsScore.decreasedL === "3"
      ? 1
      : symptomsScore.decreasedL === "4"
      ? 0
      : 0;
  const nahsdesstairsR =
    functionScore.desstairsR === "0"
      ? 4
      : functionScore.desstairsR === "1"
      ? 3
      : functionScore.desstairsR === "2"
      ? 2
      : functionScore.desstairsR === "3"
      ? 1
      : functionScore.desstairsR === "4"
      ? 0
      : 0;
  const nahsdesstairsL =
    functionScore.desstairsL == "0"
      ? 4
      : functionScore.desstairsL == "1"
      ? 3
      : functionScore.desstairsL == "2"
      ? 2
      : functionScore.desstairsL == "3"
      ? 1
      : functionScore.desstairsL == "4"
      ? 0
      : 0;

  const nahsascstairsR =
    functionScore.ascstairsR === "0"
      ? 4
      : functionScore.ascstairsR === "1"
      ? 3
      : functionScore.ascstairsR === "2"
      ? 2
      : functionScore.ascstairsR === "3"
      ? 1
      : functionScore.ascstairsR === "4"
      ? 0
      : 0;
  const nahsascstairsL =
    functionScore.ascstairsL == "0"
      ? 4
      : functionScore.ascstairsL == "1"
      ? 3
      : functionScore.ascstairsL == "2"
      ? 2
      : functionScore.ascstairsL == "3"
      ? 1
      : functionScore.ascstairsL == "4"
      ? 0
      : 0;
  const nahsrisingSittingR =
    functionScore.risingSittingR === "0"
      ? 4
      : functionScore.risingSittingR === "1"
      ? 3
      : functionScore.risingSittingR === "2"
      ? 2
      : functionScore.risingSittingR === "3"
      ? 1
      : functionScore.risingSittingR === "4"
      ? 0
      : 0;
  const nahsrisingSittingL =
    functionScore.risingSittingL == "0"
      ? 4
      : functionScore.risingSittingL == "1"
      ? 3
      : functionScore.risingSittingL == "2"
      ? 2
      : functionScore.risingSittingL == "3"
      ? 1
      : functionScore.risingSittingL == "4"
      ? 0
      : 0;

  const nahswalkExeR =
    functionScore.walkExeR === "0"
      ? 4
      : functionScore.walkExeR === "1"
      ? 3
      : functionScore.walkExeR === "2"
      ? 2
      : functionScore.walkExeR === "3"
      ? 1
      : functionScore.walkExeR === "4"
      ? 0
      : 0;
  const nahswalkExeL =
    functionScore.walkExeL == "0"
      ? 4
      : functionScore.walkExeL == "1"
      ? 3
      : functionScore.walkExeL == "2"
      ? 2
      : functionScore.walkExeL == "3"
      ? 1
      : functionScore.walkExeL == "4"
      ? 0
      : 0;

  const nahsputtingR =
    functionScore.puttingR === "0"
      ? 4
      : functionScore.puttingR === "1"
      ? 3
      : functionScore.puttingR === "2"
      ? 2
      : functionScore.puttingR === "3"
      ? 1
      : functionScore.puttingR === "4"
      ? 0
      : 0;
  const nahsputtingL =
    functionScore.puttingL == "0"
      ? 4
      : functionScore.puttingL == "1"
      ? 3
      : functionScore.puttingL == "2"
      ? 2
      : functionScore.puttingL == "3"
      ? 1
      : functionScore.puttingL == "4"
      ? 0
      : 0;

  const nahsrisingR =
    functionScore.risingR === "0"
      ? 4
      : functionScore.risingR === "1"
      ? 3
      : functionScore.risingR === "2"
      ? 2
      : functionScore.risingR === "3"
      ? 1
      : functionScore.risingR === "4"
      ? 0
      : 0;
  const nahsrisingL =
    functionScore.risingL == "0"
      ? 4
      : functionScore.risingL == "1"
      ? 3
      : functionScore.risingL == "2"
      ? 2
      : functionScore.risingL == "3"
      ? 1
      : functionScore.risingL == "4"
      ? 0
      : 0;
  const nahsheavyR =
    functionScore.heavyR === "0"
      ? 4
      : functionScore.heavyR === "1"
      ? 3
      : functionScore.heavyR === "2"
      ? 2
      : functionScore.heavyR === "3"
      ? 1
      : functionScore.heavyR === "4"
      ? 0
      : 0;
  const nahsheavyL =
    functionScore.heavyL == "0"
      ? 4
      : functionScore.heavyL == "1"
      ? 3
      : functionScore.heavyL == "2"
      ? 2
      : functionScore.heavyL == "3"
      ? 1
      : functionScore.heavyL == "4"
      ? 0
      : 0;
  const nahslightDomesticR =
    functionScore.lightDomesticR === "0"
      ? 4
      : functionScore.lightDomesticR === "1"
      ? 3
      : functionScore.lightDomesticR === "2"
      ? 2
      : functionScore.lightDomesticR === "3"
      ? 1
      : functionScore.lightDomesticR === "4"
      ? 0
      : 0;
  const nahslightDomesticL =
    functionScore.lightDomesticL == "0"
      ? 4
      : functionScore.lightDomesticL == "1"
      ? 3
      : functionScore.lightDomesticL == "2"
      ? 2
      : functionScore.lightDomesticL == "3"
      ? 1
      : functionScore.lightDomesticL == "4"
      ? 0
      : 0;

  const nahsjoggingR =
    functionScore.joggingR === "0"
      ? 4
      : functionScore.joggingR === "1"
      ? 3
      : functionScore.joggingR === "2"
      ? 2
      : functionScore.joggingR === "3"
      ? 1
      : functionScore.joggingR === "4"
      ? 0
      : 0;
  const nahsjoggingL =
    functionScore.joggingL == "0"
      ? 4
      : functionScore.joggingL == "1"
      ? 3
      : functionScore.joggingL == "2"
      ? 2
      : functionScore.joggingL == "3"
      ? 1
      : functionScore.joggingL == "4"
      ? 0
      : 0;
  const nahssportsR =
    functionScore.sportsR === "0"
      ? 4
      : functionScore.sportsR === "1"
      ? 3
      : functionScore.sportsR === "2"
      ? 2
      : functionScore.sportsR === "3"
      ? 1
      : functionScore.sportsR === "4"
      ? 0
      : 0;
  const nahssportsL =
    functionScore.sportsL == "0"
      ? 4
      : functionScore.sportsL == "1"
      ? 3
      : functionScore.sportsL == "2"
      ? 2
      : functionScore.sportsL == "3"
      ? 1
      : functionScore.sportsL == "4"
      ? 0
      : 0;
  const nahslowR =
    functionScore.lowR === "0"
      ? 4
      : functionScore.lowR === "1"
      ? 3
      : functionScore.lowR === "2"
      ? 2
      : functionScore.lowR === "3"
      ? 1
      : functionScore.lowR === "4"
      ? 0
      : 0;
  const nahslowL =
    functionScore.lowL == "0"
      ? 4
      : functionScore.lowL == "1"
      ? 3
      : functionScore.lowL == "2"
      ? 2
      : functionScore.lowL == "3"
      ? 1
      : functionScore.lowL == "4"
      ? 0
      : 0;

  // HOS (HIP OUTCOME SCORE)

  const hosdesstairsR =
    functionScore.desstairsR === "0"
      ? 4
      : functionScore.desstairsR === "1"
      ? 3
      : functionScore.desstairsR === "2"
      ? 2
      : functionScore.desstairsR === "3"
      ? 1
      : functionScore.desstairsR === "4"
      ? 0
      : 0;
  const hosdesstairsL =
    functionScore.desstairsL == "0"
      ? 4
      : functionScore.desstairsL == "1"
      ? 3
      : functionScore.desstairsL == "2"
      ? 2
      : functionScore.desstairsL == "3"
      ? 1
      : functionScore.desstairsL == "4"
      ? 0
      : 0;
  const hosascstairsR =
    functionScore.ascstairsR === "0"
      ? 4
      : functionScore.ascstairsR === "1"
      ? 3
      : functionScore.ascstairsR === "2"
      ? 2
      : functionScore.ascstairsR === "3"
      ? 1
      : functionScore.ascstairsR === "4"
      ? 0
      : 0;
  const hosascstairsL =
    functionScore.ascstairsL == "0"
      ? 4
      : functionScore.ascstairsL == "1"
      ? 3
      : functionScore.ascstairsL == "2"
      ? 2
      : functionScore.ascstairsL == "3"
      ? 1
      : functionScore.ascstairsL == "4"
      ? 0
      : 0;
  const hosstandingR =
    functionScore.standingR === "0"
      ? 4
      : functionScore.standingR === "1"
      ? 3
      : functionScore.standingR === "2"
      ? 2
      : functionScore.standingR === "3"
      ? 1
      : functionScore.standingR === "4"
      ? 0
      : 0;
  const hosstandingL =
    functionScore.standingL == "0"
      ? 4
      : functionScore.standingL == "1"
      ? 3
      : functionScore.standingL == "2"
      ? 2
      : functionScore.standingL == "3"
      ? 1
      : functionScore.standingL == "4"
      ? 0
      : 0;
  const hosstandingMinR =
    functionScore.standingMinR === "0"
      ? 4
      : functionScore.standingMinR === "1"
      ? 3
      : functionScore.standingMinR === "2"
      ? 2
      : functionScore.standingMinR === "3"
      ? 1
      : functionScore.standingMinR === "4"
      ? 0
      : 0;
  const hosstandingMinL =
    functionScore.standingMinL == "0"
      ? 4
      : functionScore.standingMinL == "1"
      ? 3
      : functionScore.standingMinL == "2"
      ? 2
      : functionScore.standingMinL == "3"
      ? 1
      : functionScore.standingMinL == "4"
      ? 0
      : 0;

  const hoswalkSteepR =
    functionScore.walkSteepR === "0"
      ? 4
      : functionScore.walkSteepR === "1"
      ? 3
      : functionScore.walkSteepR === "2"
      ? 2
      : functionScore.walkSteepR === "3"
      ? 1
      : functionScore.walkSteepR === "4"
      ? 0
      : 0;
  const hoswalkSteepL =
    functionScore.walkSteepL == "0"
      ? 4
      : functionScore.walkSteepL == "1"
      ? 3
      : functionScore.walkSteepL == "2"
      ? 2
      : functionScore.walkSteepL == "3"
      ? 1
      : functionScore.walkSteepL == "4"
      ? 0
      : 0;

  const hoswalkHillsR =
    functionScore.walkHillsR === "0"
      ? 4
      : functionScore.walkHillsR === "1"
      ? 3
      : functionScore.walkHillsR === "2"
      ? 2
      : functionScore.walkHillsR === "3"
      ? 1
      : functionScore.walkHillsR === "4"
      ? 0
      : 0;
  const hoswalkHillsL =
    functionScore.walkHillsL == "0"
      ? 4
      : functionScore.walkHillsL == "1"
      ? 3
      : functionScore.walkHillsL == "2"
      ? 2
      : functionScore.walkHillsL == "3"
      ? 1
      : functionScore.walkHillsL == "4"
      ? 0
      : 0;

  const hoswalkInitR =
    functionScore.walkInitR === "0"
      ? 4
      : functionScore.walkInitR === "1"
      ? 3
      : functionScore.walkInitR === "2"
      ? 2
      : functionScore.walkInitR === "3"
      ? 1
      : functionScore.walkInitR === "4"
      ? 0
      : 0;
  const hoswalkInitL =
    functionScore.walkInitL == "0"
      ? 4
      : functionScore.walkInitL == "1"
      ? 3
      : functionScore.walkInitL == "2"
      ? 2
      : functionScore.walkInitL == "3"
      ? 1
      : functionScore.walkInitL == "4"
      ? 0
      : 0;

  const hoswalkAppTenR =
    functionScore.walkAppTenR === "0"
      ? 4
      : functionScore.walkAppTenR === "1"
      ? 3
      : functionScore.walkAppTenR === "2"
      ? 2
      : functionScore.walkAppTenR === "3"
      ? 1
      : functionScore.walkAppTenR === "4"
      ? 0
      : 0;
  const hoswalkAppTenL =
    functionScore.walkAppTenL == "0"
      ? 4
      : functionScore.walkAppTenL == "1"
      ? 3
      : functionScore.walkAppTenL == "2"
      ? 2
      : functionScore.walkAppTenL == "3"
      ? 1
      : functionScore.walkAppTenL == "4"
      ? 0
      : 0;

  const hoswalkFiftR =
    functionScore.walkFiftR === "0"
      ? 4
      : functionScore.walkFiftR === "1"
      ? 3
      : functionScore.walkFiftR === "2"
      ? 2
      : functionScore.walkFiftR === "3"
      ? 1
      : functionScore.walkFiftR === "4"
      ? 0
      : 0;
  const hoswalkFiftL =
    functionScore.walkFiftL == "0"
      ? 4
      : functionScore.walkFiftL == "1"
      ? 3
      : functionScore.walkFiftL == "2"
      ? 2
      : functionScore.walkFiftL == "3"
      ? 1
      : functionScore.walkFiftL == "4"
      ? 0
      : 0;

  const hossteppingR =
    functionScore.steppingR === "0"
      ? 4
      : functionScore.steppingR === "1"
      ? 3
      : functionScore.steppingR === "2"
      ? 2
      : functionScore.steppingR === "3"
      ? 1
      : functionScore.steppingR === "4"
      ? 0
      : 0;
  const hossteppingL =
    functionScore.steppingL == "0"
      ? 4
      : functionScore.steppingL == "1"
      ? 3
      : functionScore.steppingL == "2"
      ? 2
      : functionScore.steppingL == "3"
      ? 1
      : functionScore.steppingL == "4"
      ? 0
      : 0;
  const hospublicTransR =
    functionScore.publicTransR === "0"
      ? 4
      : functionScore.publicTransR === "1"
      ? 3
      : functionScore.publicTransR === "2"
      ? 2
      : functionScore.publicTransR === "3"
      ? 1
      : functionScore.publicTransR === "4"
      ? 0
      : 0;
  const hospublicTransL =
    functionScore.publicTransL == "0"
      ? 4
      : functionScore.publicTransL == "1"
      ? 3
      : functionScore.publicTransL == "2"
      ? 2
      : functionScore.publicTransL == "3"
      ? 1
      : functionScore.publicTransL == "4"
      ? 0
      : 0;
  const hosrollingR =
    functionScore.rollingR === "0"
      ? 4
      : functionScore.rollingR === "1"
      ? 3
      : functionScore.rollingR === "2"
      ? 2
      : functionScore.rollingR === "3"
      ? 1
      : functionScore.rollingR === "4"
      ? 0
      : 0;
  const hosrollingL =
    functionScore.rollingL == "0"
      ? 4
      : functionScore.rollingL == "1"
      ? 3
      : functionScore.rollingL == "2"
      ? 2
      : functionScore.rollingL == "3"
      ? 1
      : functionScore.rollingL == "4"
      ? 0
      : 0;
  const hosgetbathR =
    functionScore.getbathR === "0"
      ? 4
      : functionScore.getbathR === "1"
      ? 3
      : functionScore.getbathR === "2"
      ? 2
      : functionScore.getbathR === "3"
      ? 1
      : functionScore.getbathR === "4"
      ? 0
      : 0;
  const hosgetbathL =
    functionScore.getbathL == "0"
      ? 4
      : functionScore.getbathL == "1"
      ? 3
      : functionScore.getbathL == "2"
      ? 2
      : functionScore.getbathL == "3"
      ? 1
      : functionScore.getbathL == "4"
      ? 0
      : 0;
  const hoslightR =
    functionScore.lightR === "0"
      ? 4
      : functionScore.lightR === "1"
      ? 3
      : functionScore.lightR === "2"
      ? 2
      : functionScore.lightR === "3"
      ? 1
      : functionScore.lightR === "4"
      ? 0
      : 0;
  const hoslightL =
    functionScore.lightL == "0"
      ? 4
      : functionScore.lightL == "1"
      ? 3
      : functionScore.lightL == "2"
      ? 2
      : functionScore.lightL == "3"
      ? 1
      : functionScore.lightL == "4"
      ? 0
      : 0;
  const hosheavyR =
    functionScore.heavyR === "0"
      ? 4
      : functionScore.heavyR === "1"
      ? 3
      : functionScore.heavyR === "2"
      ? 2
      : functionScore.heavyR === "3"
      ? 1
      : functionScore.heavyR === "4"
      ? 0
      : 0;
  const hosheavyL =
    functionScore.heavyL == "0"
      ? 4
      : functionScore.heavyL == "1"
      ? 3
      : functionScore.heavyL == "2"
      ? 2
      : functionScore.heavyL == "3"
      ? 1
      : functionScore.heavyL == "4"
      ? 0
      : 0;
  const hossquattingR =
    functionScore.squattingR === "0"
      ? 4
      : functionScore.squattingR === "1"
      ? 3
      : functionScore.squattingR === "2"
      ? 2
      : functionScore.squattingR === "3"
      ? 1
      : functionScore.squattingR === "4"
      ? 0
      : 0;
  const hossquattingL =
    functionScore.squattingL == "0"
      ? 4
      : functionScore.squattingL == "1"
      ? 3
      : functionScore.squattingL == "2"
      ? 2
      : functionScore.squattingL == "3"
      ? 1
      : functionScore.squattingL == "4"
      ? 0
      : 0;
  const hosrunningOneR =
    functionScore.runningOneR === "0"
      ? 4
      : functionScore.runningOneR === "1"
      ? 3
      : functionScore.runningOneR === "2"
      ? 2
      : functionScore.runningOneR === "3"
      ? 1
      : functionScore.runningOneR === "4"
      ? 0
      : 0;
  const hosrunningOneL =
    functionScore.runningOneL == "0"
      ? 4
      : functionScore.runningOneL == "1"
      ? 3
      : functionScore.runningOneL == "2"
      ? 2
      : functionScore.runningOneL == "3"
      ? 1
      : functionScore.runningOneL == "4"
      ? 0
      : 0;
  const hostwistingR =
    functionScore.twistingR === "0"
      ? 4
      : functionScore.twistingR === "1"
      ? 3
      : functionScore.twistingR === "2"
      ? 2
      : functionScore.twistingR === "3"
      ? 1
      : functionScore.twistingR === "4"
      ? 0
      : 0;
  const hostwistingL =
    functionScore.twistingL == "0"
      ? 4
      : functionScore.twistingL == "1"
      ? 3
      : functionScore.twistingL == "2"
      ? 2
      : functionScore.twistingL == "3"
      ? 1
      : functionScore.twistingL == "4"
      ? 0
      : 0;
  const hosjumpingR =
    functionScore.jumpingR === "0"
      ? 4
      : functionScore.jumpingR === "1"
      ? 3
      : functionScore.jumpingR === "2"
      ? 2
      : functionScore.jumpingR === "3"
      ? 1
      : functionScore.jumpingR === "4"
      ? 0
      : 0;
  const hosjumpingL =
    functionScore.jumpingL == "0"
      ? 4
      : functionScore.jumpingL == "1"
      ? 3
      : functionScore.jumpingL == "2"
      ? 2
      : functionScore.jumpingL == "3"
      ? 1
      : functionScore.jumpingL == "4"
      ? 0
      : 0;
  const hoslandingR =
    functionScore.landingR === "0"
      ? 4
      : functionScore.landingR === "1"
      ? 3
      : functionScore.landingR === "2"
      ? 2
      : functionScore.landingR === "3"
      ? 1
      : functionScore.landingR === "4"
      ? 0
      : 0;
  const hoslandingL =
    functionScore.landingL == "0"
      ? 4
      : functionScore.landingL == "1"
      ? 3
      : functionScore.landingL == "2"
      ? 2
      : functionScore.landingL == "3"
      ? 1
      : functionScore.landingL == "4"
      ? 0
      : 0;
  const hosswingingR =
    functionScore.swingingR === "0"
      ? 4
      : functionScore.swingingR === "1"
      ? 3
      : functionScore.swingingR === "2"
      ? 2
      : functionScore.swingingR === "3"
      ? 1
      : functionScore.swingingR === "4"
      ? 0
      : 0;
  const hosswingingL =
    functionScore.swingingL == "0"
      ? 4
      : functionScore.swingingL == "1"
      ? 3
      : functionScore.swingingL == "2"
      ? 2
      : functionScore.swingingL == "3"
      ? 1
      : functionScore.swingingL == "4"
      ? 0
      : 0;
  const hosstartingR =
    functionScore.startingR === "0"
      ? 4
      : functionScore.startingR === "1"
      ? 3
      : functionScore.startingR === "2"
      ? 2
      : functionScore.startingR === "3"
      ? 1
      : functionScore.startingR === "4"
      ? 0
      : 0;
  const hosstartingL =
    functionScore.startingL == "0"
      ? 4
      : functionScore.startingL == "1"
      ? 3
      : functionScore.startingL == "2"
      ? 2
      : functionScore.startingL == "3"
      ? 1
      : functionScore.startingL == "4"
      ? 0
      : 0;
  const hoscuttingR =
    functionScore.cuttingR === "0"
      ? 4
      : functionScore.cuttingR === "1"
      ? 3
      : functionScore.cuttingR === "2"
      ? 2
      : functionScore.cuttingR === "3"
      ? 1
      : functionScore.cuttingR === "4"
      ? 0
      : 0;
  const hoscuttingL =
    functionScore.cuttingL == "0"
      ? 4
      : functionScore.cuttingL == "1"
      ? 3
      : functionScore.cuttingL == "2"
      ? 2
      : functionScore.cuttingL == "3"
      ? 1
      : functionScore.cuttingL == "4"
      ? 0
      : 0;
  const hosrecreationalR =
    functionScore.recreationalR === "0"
      ? 4
      : functionScore.recreationalR === "1"
      ? 3
      : functionScore.recreationalR === "2"
      ? 2
      : functionScore.recreationalR === "3"
      ? 1
      : functionScore.recreationalR === "4"
      ? 0
      : 0;
  const hosrecreationalL =
    functionScore.recreationalL == "0"
      ? 4
      : functionScore.recreationalL == "1"
      ? 3
      : functionScore.recreationalL == "2"
      ? 2
      : functionScore.recreationalL == "3"
      ? 1
      : functionScore.recreationalL == "4"
      ? 0
      : 0;
  const hosfastWalkR =
    functionScore.fastWalkR === "0"
      ? 4
      : functionScore.fastWalkR === "1"
      ? 3
      : functionScore.fastWalkR === "2"
      ? 2
      : functionScore.fastWalkR === "3"
      ? 1
      : functionScore.fastWalkR === "4"
      ? 0
      : 0;
  const hosfastWalkL =
    functionScore.fastWalkL == "0"
      ? 4
      : functionScore.fastWalkL == "1"
      ? 3
      : functionScore.fastWalkL == "2"
      ? 2
      : functionScore.fastWalkL == "3"
      ? 1
      : functionScore.fastWalkL == "4"
      ? 0
      : 0;
  const hosabilityPerformR =
    functionScore.abilityPerformR === "0"
      ? 4
      : functionScore.abilityPerformR === "1"
      ? 3
      : functionScore.abilityPerformR === "2"
      ? 2
      : functionScore.abilityPerformR === "3"
      ? 1
      : functionScore.abilityPerformR === "4"
      ? 0
      : 0;
  const hosabilityPerformL =
    functionScore.abilityPerformL == "0"
      ? 4
      : functionScore.abilityPerformL == "1"
      ? 3
      : functionScore.abilityPerformL == "2"
      ? 2
      : functionScore.abilityPerformL == "3"
      ? 1
      : functionScore.abilityPerformL == "4"
      ? 0
      : 0;
  const hosabilityPartR =
    functionScore.abilityPartR === "0"
      ? 4
      : functionScore.abilityPartR === "1"
      ? 3
      : functionScore.abilityPartR === "2"
      ? 2
      : functionScore.abilityPartR === "3"
      ? 1
      : functionScore.abilityPartR === "4"
      ? 0
      : 0;
  const hosabilityPartL =
    functionScore.abilityPartL == "0"
      ? 4
      : functionScore.abilityPartL == "1"
      ? 3
      : functionScore.abilityPartL == "2"
      ? 2
      : functionScore.abilityPartL == "3"
      ? 1
      : functionScore.abilityPartL == "4"
      ? 0
      : 0;
  // iHOT-12

  // const ihotpainTodayRatingL =
  //   painSco.painTodayRatingL === "0"
  //     ? 100
  //     : painSco.painTodayRatingL === "1"
  //       ? 90
  //       : painSco.painTodayRatingL === "2"
  //         ? 80
  //         : painSco.painTodayRatingL === "3"
  //           ? 70
  //           : painSco.painTodayRatingL === "4"
  //             ? 60
  //             : painSco.painTodayRatingL === "5"
  //               ? 50
  //               : painSco.painTodayRatingL === "6"
  //                 ? 40
  //                 : painSco.painTodayRatingL === "7"
  //                   ? 30
  //                   : painSco.painTodayRatingL === "8"
  //                     ? 20
  //                     : painSco.painTodayRatingL === "9"
  //                       ? 10
  //                       : painSco.painTodayRatingL === "10"
  //                         ? 0
  //                         : 0;
  // const ihotpainTodayRatingR =
  //   painSco.painTodayRatingR === "0"
  //     ? 100
  //     : painSco.painTodayRatingR === "1"
  //       ? 90
  //       : painSco.painTodayRatingR === "2"
  //         ? 80
  //         : painSco.painTodayRatingR === "3"
  //           ? 70
  //           : painSco.painTodayRatingR === "4"
  //             ? 60
  //             : painSco.painTodayRatingR === "5"
  //               ? 50
  //               : painSco.painTodayRatingR === "6"
  //                 ? 40
  //                 : painSco.painTodayRatingR === "7"
  //                   ? 30
  //                   : painSco.painTodayRatingR === "8"
  //                     ? 20
  //                     : painSco.painTodayRatingR === "9"
  //                       ? 10
  //                       : painSco.painTodayRatingR === "10"
  //                         ? 0
  //                         : 0;
  const ihotactivityR =
    painSco.activityR === "0"
      ? 100
      : painSco.activityR === "1"
      ? 80
      : painSco.activityR === "2"
      ? 50
      : painSco.activityR === "3"
      ? 20
      : painSco.activityR === "4"
      ? 0
      : 0;
  const ihotactivityL =
    painSco.activityL === "0"
      ? 100
      : painSco.activityL === "1"
      ? 80
      : painSco.activityL === "2"
      ? 50
      : painSco.activityL === "3"
      ? 20
      : painSco.activityL === "4"
      ? 0
      : 0;
  const ihotgrindingR =
    symptomsScore.grindingR === "0"
      ? 100
      : symptomsScore.grindingR === "1"
      ? 80
      : symptomsScore.grindingR === "2"
      ? 50
      : symptomsScore.grindingR === "3"
      ? 20
      : symptomsScore.grindingR === "4"
      ? 0
      : 0;
  const ihotgrindingL =
    symptomsScore.grindingL === "0"
      ? 100
      : symptomsScore.grindingL === "1"
      ? 80
      : symptomsScore.grindingL === "2"
      ? 50
      : symptomsScore.grindingL === "3"
      ? 20
      : symptomsScore.grindingL === "4"
      ? 0
      : 0;
  const ihotfloorR =
    functionScore.floorR === "0"
      ? 100
      : functionScore.floorR === "1"
      ? 80
      : functionScore.floorR === "2"
      ? 50
      : functionScore.floorR === "3"
      ? 20
      : functionScore.floorR === "4"
      ? 0
      : 0;
  const ihotfloorL =
    functionScore.floorL === "0"
      ? 100
      : functionScore.floorL === "1"
      ? 80
      : functionScore.floorL === "2"
      ? 50
      : functionScore.floorL === "3"
      ? 20
      : functionScore.floorL === "4"
      ? 0
      : 0;
  const ihotwalkLongR =
    functionScore.walkLongR === "0"
      ? 100
      : functionScore.walkLongR === "1"
      ? 80
      : functionScore.walkLongR === "2"
      ? 50
      : functionScore.walkLongR === "3"
      ? 20
      : functionScore.walkLongR === "4"
      ? 0
      : 0;
  const ihotwalkLongL =
    functionScore.walkLongL === "0"
      ? 100
      : functionScore.walkLongL === "1"
      ? 80
      : functionScore.walkLongL === "2"
      ? 50
      : functionScore.walkLongL === "3"
      ? 20
      : functionScore.walkLongL === "4"
      ? 0
      : 0;
  const ihotheavyR =
    functionScore.heavyR === "0"
      ? 100
      : functionScore.heavyR === "1"
      ? 80
      : functionScore.heavyR === "2"
      ? 50
      : functionScore.heavyR === "3"
      ? 20
      : functionScore.heavyR === "4"
      ? 0
      : 0;
  const ihotheavyL =
    functionScore.heavyL == "0"
      ? 100
      : functionScore.heavyL == "1"
      ? 80
      : functionScore.heavyL == "2"
      ? 50
      : functionScore.heavyL == "3"
      ? 20
      : functionScore.heavyL == "4"
      ? 0
      : 0;
  const ihotpickingR =
    functionScore.pickingR === "0"
      ? 100
      : functionScore.pickingR === "1"
      ? 80
      : functionScore.pickingR === "2"
      ? 50
      : functionScore.pickingR === "3"
      ? 20
      : functionScore.pickingR === "4"
      ? 0
      : 0;
  const ihotpickingL =
    functionScore.pickingL == "0"
      ? 100
      : functionScore.pickingL == "1"
      ? 80
      : functionScore.pickingL == "2"
      ? 50
      : functionScore.pickingL == "3"
      ? 20
      : functionScore.pickingL == "4"
      ? 0
      : 0;
  const ihotsexualR =
    functionScore.sexualR === "0"
      ? 100
      : functionScore.sexualR === "1"
      ? 80
      : functionScore.sexualR === "2"
      ? 50
      : functionScore.sexualR === "3"
      ? 20
      : functionScore.sexualR === "4"
      ? 0
      : 0;
  const ihotsexualL =
    functionScore.sexualL == "0"
      ? 100
      : functionScore.sexualL == "1"
      ? 80
      : functionScore.sexualL == "2"
      ? 50
      : functionScore.sexualL == "3"
      ? 20
      : functionScore.sexualL == "4"
      ? 0
      : 0;
  const ihotsportsR =
    functionScore.sportsR === "0"
      ? 100
      : functionScore.sportsR === "1"
      ? 80
      : functionScore.sportsR === "2"
      ? 50
      : functionScore.sportsR === "3"
      ? 20
      : functionScore.sportsR === "4"
      ? 0
      : 0;
  const ihotsportsL =
    functionScore.sportsL == "0"
      ? 100
      : functionScore.sportsL == "1"
      ? 80
      : functionScore.sportsL == "2"
      ? 50
      : functionScore.sportsL == "3"
      ? 20
      : functionScore.sportsL == "4"
      ? 0
      : 0;
  const ihotaware =
    qolScore.aware == "0"
      ? 100
      : qolScore.aware == "1"
      ? 90
      : qolScore.aware == "2"
      ? 80
      : qolScore.aware == "3"
      ? 70
      : qolScore.aware == "4"
      ? 60
      : qolScore.aware == "5"
      ? 50
      : qolScore.aware == "6"
      ? 40
      : qolScore.aware == "7"
      ? 30
      : qolScore.aware == "8"
      ? 20
      : qolScore.aware == "9"
      ? 10
      : qolScore.aware == "10"
      ? 0
      : 0;
  const ihotawareL =
    qolScore.awareL == "0"
      ? 100
      : qolScore.awareL == "1"
      ? 90
      : qolScore.awareL == "2"
      ? 80
      : qolScore.awareL == "3"
      ? 70
      : qolScore.awareL == "4"
      ? 60
      : qolScore.awareL == "5"
      ? 50
      : qolScore.awareL == "6"
      ? 40
      : qolScore.awareL == "7"
      ? 30
      : qolScore.awareL == "8"
      ? 20
      : qolScore.awareL == "9"
      ? 10
      : qolScore.awareL == "10"
      ? 0
      : 0;
  const ihotconcerned =
    qolScore.concerned == "0"
      ? 100
      : qolScore.concerned == "1"
      ? 90
      : qolScore.concerned == "2"
      ? 80
      : qolScore.concerned == "3"
      ? 70
      : qolScore.concerned == "4"
      ? 60
      : qolScore.concerned == "5"
      ? 50
      : qolScore.concerned == "6"
      ? 40
      : qolScore.concerned == "7"
      ? 30
      : qolScore.concerned == "8"
      ? 20
      : qolScore.concerned == "9"
      ? 10
      : qolScore.concerned == "10"
      ? 0
      : 0;
  const ihotconcernedL =
    qolScore.concernedL == "0"
      ? 100
      : qolScore.concernedL == "1"
      ? 90
      : qolScore.concernedL == "2"
      ? 80
      : qolScore.concernedL == "3"
      ? 70
      : qolScore.concernedL == "4"
      ? 60
      : qolScore.concernedL == "5"
      ? 50
      : qolScore.concernedL == "6"
      ? 40
      : qolScore.concernedL == "7"
      ? 30
      : qolScore.concernedL == "8"
      ? 20
      : qolScore.concernedL == "9"
      ? 10
      : qolScore.concernedL == "10"
      ? 0
      : 0;

  const ihotdistraction =
    qolScore.distraction == "0"
      ? 100
      : qolScore.distraction == "1"
      ? 90
      : qolScore.distraction == "2"
      ? 80
      : qolScore.distraction == "3"
      ? 70
      : qolScore.distraction == "4"
      ? 60
      : qolScore.distraction == "5"
      ? 50
      : qolScore.distraction == "6"
      ? 40
      : qolScore.distraction == "7"
      ? 30
      : qolScore.distraction == "8"
      ? 20
      : qolScore.distraction == "9"
      ? 10
      : qolScore.distraction == "10"
      ? 0
      : 0;
  const ihotdistractionL =
    qolScore.distractionL == "0"
      ? 100
      : qolScore.distractionL == "1"
      ? 90
      : qolScore.distractionL == "2"
      ? 80
      : qolScore.distractionL == "3"
      ? 70
      : qolScore.distractionL == "4"
      ? 60
      : qolScore.distractionL == "5"
      ? 50
      : qolScore.distractionL == "6"
      ? 40
      : qolScore.distractionL == "7"
      ? 30
      : qolScore.distractionL == "8"
      ? 20
      : qolScore.distractionL == "9"
      ? 10
      : qolScore.distractionL == "10"
      ? 0
      : 0;

  // VAS
  // Changes
  const vaspainTodayRatingL = parseFloat(painSco.howBadPainRatingL);
  const vaspainTodayRatingR = parseFloat(painSco.howBadPainRatingR);

  //SANE (Single Assessment Numeric Evaluation)
  const sanehipPer = parseFloat(functionScore.hipPer);
  const sanehipPerL = parseFloat(functionScore.hipPerL);

  // EQ-5D-5L

  const eq5dpainTodayRatingL =
    painSco.painTodayRatingL === "0"
      ? 1
      : painSco.painTodayRatingL === "1"
      ? 2
      : painSco.painTodayRatingL === "2"
      ? 2
      : painSco.painTodayRatingL === "3"
      ? 2
      : painSco.painTodayRatingL === "4"
      ? 3
      : painSco.painTodayRatingL === "5"
      ? 3
      : painSco.painTodayRatingL === "6"
      ? 3
      : painSco.painTodayRatingL === "7"
      ? 4
      : painSco.painTodayRatingL === "8"
      ? 4
      : painSco.painTodayRatingL === "9"
      ? 4
      : painSco.painTodayRatingL === "10"
      ? 5
      : 0;
  const eq5dpainTodayRatingR =
    painSco.painTodayRatingR === "0"
      ? 1
      : painSco.painTodayRatingR === "1"
      ? 2
      : painSco.painTodayRatingR === "2"
      ? 2
      : painSco.painTodayRatingR === "3"
      ? 2
      : painSco.painTodayRatingR === "4"
      ? 3
      : painSco.painTodayRatingR === "5"
      ? 3
      : painSco.painTodayRatingR === "6"
      ? 3
      : painSco.painTodayRatingR === "7"
      ? 4
      : painSco.painTodayRatingR === "8"
      ? 4
      : painSco.painTodayRatingR === "9"
      ? 4
      : painSco.painTodayRatingR === "10"
      ? 5
      : 0;

  const eq5dwalkingR =
    functionScore.walkingR === "0"
      ? 1
      : functionScore.walkingR === "1"
      ? 2
      : functionScore.walkingR === "2"
      ? 3
      : functionScore.walkingR === "3"
      ? 4
      : functionScore.walkingR === "4"
      ? 5
      : 0;
  const eq5dwalkingL =
    functionScore.walkingL == "0"
      ? 1
      : functionScore.walkingL == "1"
      ? 2
      : functionScore.walkingL == "2"
      ? 3
      : functionScore.walkingL == "3"
      ? 4
      : functionScore.walkingL == "4"
      ? 5
      : 0;
  const eq5washingR =
    functionScore.washingR === "0"
      ? 1
      : functionScore.washingR === "1"
      ? 2
      : functionScore.washingR === "2"
      ? 3
      : functionScore.washingR === "3"
      ? 4
      : functionScore.washingR === "4"
      ? 5
      : 0;
  const eq5washingL =
    functionScore.washingL == "0"
      ? 1
      : functionScore.washingL == "1"
      ? 2
      : functionScore.washingL == "2"
      ? 3
      : functionScore.washingL == "3"
      ? 4
      : functionScore.washingL == "4"
      ? 5
      : 0;
  const eq5difficulty =
    qolScore.difficulty == "0"
      ? 1
      : qolScore.difficulty == "1"
      ? 2
      : qolScore.difficulty == "2"
      ? 3
      : qolScore.difficulty == "3"
      ? 4
      : qolScore.difficulty == "4"
      ? 5
      : 0;

  const eq5difficultyL =
    qolScore.difficultyL == "0"
      ? 1
      : qolScore.difficultyL == "1"
      ? 2
      : qolScore.difficultyL == "2"
      ? 3
      : qolScore.difficultyL == "3"
      ? 4
      : qolScore.difficultyL == "4"
      ? 5
      : 0;

  const eq5depressed =
    qolScore.depressed == "0"
      ? 1
      : qolScore.depressed == "1"
      ? 2
      : qolScore.depressed == "2"
      ? 3
      : qolScore.depressed == "3"
      ? 4
      : qolScore.depressed == "4"
      ? 5
      : 0;

  const eq5depressedL =
    qolScore.depressedL == "0"
      ? 1
      : qolScore.depressedL == "1"
      ? 2
      : qolScore.depressedL == "2"
      ? 3
      : qolScore.depressedL == "3"
      ? 4
      : qolScore.depressedL == "4"
      ? 5
      : 0;

  //EQ VAS

  const eqvashealth = parseFloat(qolScore.health);
  // const eqvashealthL = parseFloat(qolScore.healthL);

  //SAPS (Self-Administered Patient Satisfaction Scale)

  const sapsoverallR =
    satisfactionScore.overallR === "0"
      ? 0
      : satisfactionScore.overallR === "1"
      ? 25
      : satisfactionScore.overallR === "2"
      ? 50
      : satisfactionScore.overallR === "3"
      ? 75
      : satisfactionScore.overallR === "4"
      ? 100
      : 0;
  const sapsoverallL =
    satisfactionScore.overallL === "0"
      ? 0
      : satisfactionScore.overallL === "1"
      ? 25
      : satisfactionScore.overallL === "2"
      ? 50
      : satisfactionScore.overallL === "3"
      ? 75
      : satisfactionScore.overallL === "4"
      ? 100
      : 0;
  const sapsimprovePainR =
    satisfactionScore.improvePainR === "0"
      ? 0
      : satisfactionScore.improvePainR === "1"
      ? 25
      : satisfactionScore.improvePainR === "2"
      ? 50
      : satisfactionScore.improvePainR === "3"
      ? 75
      : satisfactionScore.improvePainR === "4"
      ? 100
      : 0;
  const sapsimprovePainL =
    satisfactionScore.improvePainL === "0"
      ? 0
      : satisfactionScore.improvePainL === "1"
      ? 25
      : satisfactionScore.improvePainL === "2"
      ? 50
      : satisfactionScore.improvePainL === "3"
      ? 75
      : satisfactionScore.improvePainL === "4"
      ? 100
      : 0;
  const sapsimproveHomeR =
    satisfactionScore.improveHomeR === "0"
      ? 0
      : satisfactionScore.improveHomeR === "1"
      ? 25
      : satisfactionScore.improveHomeR === "2"
      ? 50
      : satisfactionScore.improveHomeR === "3"
      ? 75
      : satisfactionScore.improveHomeR === "4"
      ? 100
      : 0;
  const sapsimproveHomeL =
    satisfactionScore.improveHomeL === "0"
      ? 0
      : satisfactionScore.improveHomeL === "1"
      ? 25
      : satisfactionScore.improveHomeL === "2"
      ? 50
      : satisfactionScore.improveHomeL === "3"
      ? 75
      : satisfactionScore.improveHomeL === "4"
      ? 100
      : 0;
  const sapsimproveRecrR =
    satisfactionScore.improveRecrR === "0"
      ? 0
      : satisfactionScore.improveRecrR === "1"
      ? 25
      : satisfactionScore.improveRecrR === "2"
      ? 50
      : satisfactionScore.improveRecrR === "3"
      ? 75
      : satisfactionScore.improveRecrR === "4"
      ? 100
      : 0;
  const sapsimproveRecrL =
    satisfactionScore.improveRecrL === "0"
      ? 0
      : satisfactionScore.improveRecrL === "1"
      ? 25
      : satisfactionScore.improveRecrL === "2"
      ? 50
      : satisfactionScore.improveRecrL === "3"
      ? 75
      : satisfactionScore.improveRecrL === "4"
      ? 100
      : 0;

  /// End ================

  //mHHS (MODIFIED HARRIS HIP SCORE)

  let mHHSR = sumAll(
    mHHSdescribesR,
    mHHSwalkR,
    mHHSlimpingR,
    mHHSsupportR,
    mHHSascstairsR,
    mHHSsittingfR,
    mHHSpublicTransR,
    mHHSputtingR
  );
  let mHHSL = sumAll(
    mHHSdescribesL,
    mHHSwalkL,
    mHHSlimpingL,
    mHHSsupportL,
    mHHSascstairsL,
    mHHSsittingfL,
    mHHSpublicTransL,
    mHHSputtingL
  );

  const mhhsTR = Math.round(mHHSR * 1.1);
  const mhhsTL = Math.round(mHHSL * 1.1);

  //mHHS End

  //Hoos start

  let hoosQOL = sumAll(
    hoosawareL,
    hoosmodifiedL,
    hoostroubledL,
    hoosdifficultyL
  );
  let hoosQOR = sumAll(hoosaware, hoosmodified, hoostroubled, hoosdifficulty);

  let hossSportR = sumAll(
    hossquattingR,
    hoosrunningR,
    hoostwistingR,
    hooswalkUnevenR
  );

  let hossSportL = sumAll(
    hossquattingL,
    hoosrunningL,
    hoostwistingL,
    hooswalkUnevenL
  );

  let hoosADLR = sumAll(
    hoosdesstairsR,
    hoosascstairsR,
    hoossittingfR,
    hoosrisingSittingR,
    hosstandingR,
    hoosfbendingFR,
    hooswalkingR,
    hoospublicTransR,
    hoosgoingShopR,
    hoosputtingR,
    hoostakingR,
    hooslyingR,
    hoosrisingR,
    hoosgetbathR,
    hoosgettoiletR,
    hoosheavyR,
    hooslightDomesticR
  );

  let hoosADLL = sumAll(
    hoosdesstairsL,
    hoosascstairsL,
    hoossittingfL,
    hoosrisingSittingL,
    hosstandingL,
    hoosfbendingFL,
    hooswalkingL,
    hoospublicTransL,
    hoosgoingShopL,
    hoosputtingL,
    hoostakingL,
    hooslyingL,
    hoosrisingL,
    hoosgetbathL,
    hoosgettoiletL,
    hoosheavyL,
    hooslightDomesticL
  );

  let hoosPainR = sumAll(
    hoosoftenR,
    hoosstraighteningR,
    hoosbendingR,
    hoossurfaceR,
    hooshardSurfaceR,
    hoosunevenSurfaceR,
    hoosstairsR,
    hoosbedR,
    hoossittingR,
    hoosuprightR
  );

  let hoosPainL = sumAll(
    hoosoftenL,
    hoosstraighteningL,
    hoosbendingL,
    hoossurfaceL,
    hooshardSurfaceL,
    hoosunevenSurfaceL,
    hoosstairsL,
    hoosbedL,
    hoossittingL,
    hoosuprightL
  );

  let hoosSymptomsR = sumAll(
    hoosgrindingR,
    hoosstifR,
    hoosstifSitR,
    hoosspreadingR,
    hoosstrideR
  );
  let hoosSymptomsL = sumAll(
    hoosgrindingL,
    hoosstifL,
    hoosstifSitL,
    hoosspreadingL,
    hoosstrideL
  );

  const hoosQOLT = ((hoosQOL / 16) * 100).toFixed();
  const hoosQORT = ((hoosQOR / 16) * 100).toFixed();

  const hossSportTR = ((hossSportR / 16) * 100).toFixed();
  const hossSportTL = ((hossSportL / 16) * 100).toFixed();

  const hoosADLTR = ((hoosADLR / 68) * 100).toFixed();
  const hoosADLTL = ((hoosADLL / 68) * 100).toFixed();

  const hoosPainTR = ((hoosPainR / 40) * 100).toFixed();
  const hoosPainTL = ((hoosPainL / 40) * 100).toFixed();



  const hoosSymptomsTR = ((hoosSymptomsR / 20) * 100).toFixed();
  const hoosSymptomsTL = ((hoosSymptomsL / 20) * 100).toFixed();

  //Hoos End

  //OHS start

  let ohsFunctionR = sumAll(
    ohswalkR,
    ohssascstairsR,
    ohsputtingR,
    ohswashingR,
    ohspublicTransR,
    ohsgoingShopR
  );

  let ohsFunctionL = sumAll(
    ohswalkL,
    ohssascstairsL,
    ohsputtingL,
    ohswashingL,
    ohspublicTransL,
    ohsgoingShopL
  );

  let ohsPainR = sumAll(
    ohspainTodayRatingR,
    ohsinterferedR,
    ohstroubledR,
    ohschairR,
    ohslimpingR,
    ohssuddenR
  );

  let ohsPainL = sumAll(
    ohspainTodayRatingL,
    ohsinterferedL,
    ohstroubledL,
    ohschairL,
    ohslimpingL,
    ohssuddenL
  );

  const ohsFuncTR = ((ohsFunctionR / 24) * 100).toFixed();
  const ohsFuncTL = ((ohsFunctionL / 24) * 100).toFixed();

  const ohsPainTR = ((ohsPainR / 24) * 100).toFixed();
  const ohsPainTL = ((ohsPainL / 24) * 100).toFixed();

  //overall ohs

  let overallohsR = sumAll(
    ohswalkR,
    ohssascstairsR,
    ohsputtingR,
    ohswashingR,
    ohspublicTransR,
    ohsgoingShopR,
    ohspainTodayRatingR,
    ohsinterferedR,
    ohstroubledR, // Not used
    ohschairR,
    ohslimpingR,
    ohssuddenR
  );

  let overallohsL = sumAll(
    ohswalkL,
    ohssascstairsL,
    ohsputtingL,
    ohswashingL,
    ohspublicTransL,
    ohsgoingShopL,
    ohspainTodayRatingL,
    ohsinterferedL,
    ohstroubledL,
    ohschairL,
    ohslimpingL,
    ohssuddenL
  );

  //OHS End

  //WOMAC Start

  let womacPainR = sumAll(
    womacsurfaceR,
    womacstairsR,
    womacbedR,
    womacsittingR,
    womacuprightR
  );

  let womacPainL = sumAll(
    womacsurfaceL,
    womacstairsL,
    womacbedL,
    womacsittingL,
    womacuprightL
  );

  let womacStiffR = sumAll(womacstifR, womacstifSitR);
  let womacStiffL = sumAll(womacstifL, womacstifSitL);

  let womacFunctionR = sumAll(
    womacdesstairsR,
    womacsascstairsR,
    womacsittingfR,
    womacrisingSittingR,
    womacstandingR,
    womacbendingR,
    womacwalkingR,
    womacpublicTransR,
    womacgoingShopR,
    womacputtingR,
    womactakingR,
    womaclyingR,
    womacrisingR,
    womacgetbathR,
    womacgettoiletR,
    womacheavyR,
    womaclightDomesticR
  );

  let womacFunctionL = sumAll(
    womacdesstairsL,
    womacsascstairsL,
    womacsittingfL,
    womacrisingSittingL,
    womacstandingL,
    womacbendingL,
    womacwalkingL,
    womacpublicTransL,
    womacgoingShopL,
    womacputtingL,
    womactakingL,
    womaclyingL,
    womacrisingL,
    womacgetbathL,
    womacgettoiletL,
    womacheavyL,
    womaclightDomesticL
  );

  const womacPainTR = ((womacPainR / 20) * 100).toFixed();
  const womacPainTL = ((womacPainL / 20) * 100).toFixed();

  const womacstiffTR = ((womacStiffR / 8) * 100).toFixed();
  const womacstiffTL = ((womacStiffL / 8) * 100).toFixed();

  const womacFunctionTR = ((womacFunctionR / 68) * 100).toFixed();
  const womacFunctionTL = ((womacFunctionL / 68) * 100).toFixed();

  //WOMAC End

  //NAHS Start
  const nahsMinR = Math.min(nahsstifR, nahsstifSitR);
  const nahsMinL = Math.min(nahsstifL, nahsstifSitL);

  let nahsR = sumAll(
    nahssurfaceR,
    nahsstairsR,
    nahsbedR,
    nahssittingR,
    nahsuprightR,
    nahscatchingR,
    nahsMinR,
    // nahsstifR,
    // nahsstifSitR,
    nahshipGivingR,
    nahsdecreasedR,
    nahsdesstairsR,
    nahsascstairsR,
    nahsrisingSittingR,
    nahswalkExeR,
    nahsputtingR,
    nahsrisingR,
    nahsheavyR,
    nahslightDomesticR,
    nahsjoggingR,
    nahssportsR,
    nahslowR
  );

  // console.log(
  //   nahssurfaceR,
  //   nahsstairsR,
  //   nahsbedR,
  //   nahssittingR,
  //   nahsuprightR,
  //   nahscatchingR,
  //   nahsMinR,
  //   // nahsstifR,
  //   // nahsstifSitR,
  //   nahshipGivingR,
  //   nahsdecreasedR,
  //   nahsdesstairsR,
  //   nahsascstairsR,
  //   nahsrisingSittingR,
  //   nahswalkExeR,
  //   nahsputtingR,
  //   nahsrisingR,
  //   nahsheavyR,
  //   nahslightDomesticR,
  //   nahsjoggingR,
  //   nahssportsR,
  //   nahslowR,
  //   "NAHS RIGHT"
  // );

  let nahsL = sumAll(
    nahssurfaceL,
    nahsstairsL,
    nahsbedL,
    nahssittingL,
    nahsuprightL,
    nahscatchingL,
    nahsMinL,
    // nahsstifL,
    // nahsstifSitL,
    nahshipGivingL,
    nahsdecreasedL,
    nahsdesstairsL,
    nahsascstairsL,
    nahsrisingSittingL,
    nahswalkExeL,
    nahsputtingL,
    nahsrisingL,
    nahsheavyL,
    nahslightDomesticL,
    nahsjoggingL,
    nahssportsL,
    nahslowL
  );

  // console.log(
  //   nahssurfaceL,
  //   nahsstairsL,
  //   nahsbedL,
  //   nahssittingL,
  //   nahsuprightL,
  //   nahscatchingL,
  //   nahsMinL,
  //   // nahsstifL,
  //   // nahsstifSitL,
  //   nahshipGivingL,
  //   nahsdecreasedL,
  //   nahsdesstairsL,
  //   nahsascstairsL,
  //   nahsrisingSittingL,
  //   nahswalkExeL,
  //   nahsputtingL,
  //   nahsrisingL,
  //   nahsheavyL,
  //   nahslightDomesticL,
  //   nahsjoggingL,
  //   nahssportsL,
  //   nahslowL,
  //   "NAHS LEFT"
  // );

  const nahsTR = (nahsR * 1.25).toFixed();
  const nahsTL = (nahsL * 1.25).toFixed();

  //NAHS End

  //HOS Start
  let hosadlR = sumAll(
    hosdesstairsR,
    hosascstairsR,
    hosstandingMinR,
    hoswalkSteepR,
    hoswalkHillsR,
    hoswalkInitR,
    hoswalkAppTenR,
    hoswalkFiftR,
    hossteppingR,
    hospublicTransR,
    hosrollingR,
    hosgetbathR,
    hoslightR,
    hosheavyR,
    hossquattingR,
    hostwistingR,
    hosrecreationalR
  );

  let hosadlL = sumAll(
    hosdesstairsL,
    hosascstairsL,
    hosstandingMinL,
    hoswalkSteepL,
    hoswalkHillsL,
    hoswalkInitL,
    hoswalkAppTenL,
    hoswalkFiftL,
    hossteppingL,
    hospublicTransL,
    hosrollingL,
    hosgetbathL,
    hoslightL,
    hosheavyL,
    hossquattingL,
    hostwistingL,
    hosrecreationalL
  );

  let hossportR = sumAll(
    hossquattingR,
    nahsjoggingR,
    hoosrunningR,
    hoostwistingR
  );

  let hossportL = sumAll(
    hossquattingL,
    nahsjoggingL,
    hoosrunningL,
    hoostwistingL
  );

  const hosadlTR = ((hosadlR / 68) * 100).toFixed();
  const hosadlTL = ((hosadlL / 68) * 100).toFixed();

  const hossportTR = ((hossportR / 16) * 100).toFixed();
  const hossportTL = ((hossportL / 16) * 100).toFixed();

  //HOS End

  //IHOT-12

  let ihotsR = sumAll(
    // ihotpainTodayRatingR,
    ihotactivityR,
    ihotgrindingR,
    ihotfloorR,
    ihotwalkLongR,
    ihotheavyR,
    ihotpickingR,
    ihotsexualR,
    ihotsportsR,
    ihotaware,
    ihotconcerned,
    ihotdistraction
  );

  let ihotR = (ihotsR / (functionScore.select1 ? 11 : 12)).toFixed();

  let ihotsL = sumAll(
    // ihotpainTodayRatingL,
    ihotactivityL,
    ihotgrindingL,
    ihotfloorL,
    ihotwalkLongL,
    ihotheavyL,
    ihotpickingL,
    ihotsexualL,
    ihotsportsL,
    ihotawareL,
    ihotconcernedL,
    ihotdistractionL
  );

  let ihotL = (ihotsL / (functionScore.select1 ? 11 : 12)).toFixed();

  //IHOT-12 End

  //VAS
  // Changes
  let vashowBadPainRatingL = vaspainTodayRatingL;
  let vashowBadPainRatingR = vaspainTodayRatingR;

  //VAS End

  //SANE
  let sane = sumAll(sanehipPer).toFixed();
  let saneL = sumAll(sanehipPerL).toFixed();

  //SANE End

  //EQ5d

  let eq4deq5dwalkingR = sumAll(eq5dwalkingR).toFixed();
  let eq4deq5dwalkingL = sumAll(eq5dwalkingL).toFixed();

  let eqdSelfR = sumAll(eq5washingR).toFixed();
  let eqdSelfL = sumAll(eq5washingL).toFixed();

  let eqdUsual = sumAll(eq5difficulty).toFixed();
  let eqdUsualL = sumAll(eq5difficultyL).toFixed();

  let eqdPain = sumAll(eq5dpainTodayRatingL).toFixed();
  let eqdPainR = sumAll(eq5dpainTodayRatingR).toFixed();

  let eqdAnxi = sumAll(eq5depressed).toFixed();
  // let eqdAnxiL = sumAll(eq5depressedL).toFixed();

  //EQ5d End

  //EQ VAS
  let eqVas = sumAll(eqvashealth).toFixed();
  // let eqVasL = sumAll(eqvashealthL).toFixed();
  //EQ VAS End

  //SAPS

  // let SapsR = ((sumAll(
  //   sapsoverallR,
  //   sapsimprovePainR,
  //   sapsimproveHomeR,
  //   sapsimproveRecrR
  // ))/4).toFixed();

  let SapsOverR = sapsoverallR;
  let SapsOverL = sapsoverallL;
  let sapsPainR = sapsimprovePainR;
  let sapsPainL = sapsimprovePainL;
  let sapsWorkR = sapsimproveHomeR;
  let sapsWorkL = sapsimproveHomeL;
  let sapsRecreR = sapsimproveRecrR;
  let sapsRecreL = sapsimproveRecrL;

  // let SapsL = ((sumAll(
  //   sapsoverallL,
  //   sapsimprovePainL,
  //   sapsimproveHomeL,
  //   sapsimproveRecrL
  // ))/4).toFixed();

  //SAPS End
  // Tigner Start
  const tignerRight =
    functionScore.sports === "1"
      ? 10
      : functionScore.sports === "2"
      ? 9
      : functionScore.sports === "3"
      ? 8
      : functionScore.sports === "4"
      ? 7
      : functionScore.sports === "5"
      ? 6
      : functionScore.sports === "6"
      ? 5
      : functionScore.sports === "7"
      ? 4
      : functionScore.sports === "8"
      ? 3
      : functionScore.sports === "9"
      ? 5
      : functionScore.sports === "10"
      ? 4
      : functionScore.sports === "11"
      ? 3
      : functionScore.sports === "12"
      ? 1
      : functionScore.sports === "13"
      ? 3
      : functionScore.sports === "14"
      ? 2
      : functionScore.sports === "15"
      ? 1
      : 0;

  const tignerLeft =
    functionScore.currsports === "16"
      ? 10
      : functionScore.currsports === "17"
      ? 9
      : functionScore.currsports === "18"
      ? 8
      : functionScore.currsports === "19"
      ? 7
      : functionScore.currsports === "20"
      ? 6
      : functionScore.currsports === "21"
      ? 5
      : functionScore.currsports === "22"
      ? 4
      : functionScore.currsports === "23"
      ? 3
      : functionScore.currsports === "24"
      ? 5
      : functionScore.currsports === "25"
      ? 4
      : functionScore.currsports === "26"
      ? 3
      : functionScore.currsports === "27"
      ? 1
      : functionScore.currsports === "28"
      ? 3
      : functionScore.currsports === "29"
      ? 2
      : functionScore.currsports === "30"
      ? 1
      : 0;

  const TignerRight = functionScore.highestActivity > "0" ? tignerRight : 0;
  const TignerLeft = functionScore.highestActivityL > "0" ? tignerLeft : 0;

  // Tigner End
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const updateDate = () => {
    axiosInstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=1`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (isdoctorform == 1) {
          setDisplayConfirmationModal(true);
          // window.location.href = process.env.REACT_APP_DOCTOR_URL+"patient-view/" + window.btoa(patientID) +"/"+ window.btoa(patientID);
        } else {
          navigate("/hip-result/" + rId);
        }
      });
  };
  const [submit, setSubmit] = useState(false);
  const continueRedirect = (e) => {
    dispatch(
      updateHipTotalScores([
        {
          mhhsTR,
          mhhsTL,
          hoosQORT,
          hoosQOLT,
          hossSportTR,
          hossSportTL,
          hoosADLTR,
          hoosADLTL,
          hoosPainTR,
          hoosPainTL,
          hoosSymptomsTR,
          hoosSymptomsTL,
          ohsFuncTR,
          ohsFuncTL,
          ohsPainTR,
          ohsPainTL,
          womacPainTR,
          womacPainTL,
          womacstiffTR,
          womacstiffTL,
          womacFunctionTR,
          womacFunctionTL,
          nahsTR,
          nahsTL,
          hosadlTR,
          hosadlTL,
          hossportTR,
          hossportTL,
          ihotR,
          ihotL,
          vashowBadPainRatingL,
          vashowBadPainRatingR,
          sane,
          saneL,
          eq4deq5dwalkingR,
          eq4deq5dwalkingL,
          eqdSelfR,
          eqdSelfL,
          eqdUsual,
          eqdUsualL,
          eqdPain,
          eqdPainR,
          eqdAnxi,
          // eqdAnxiL,
          eqVas,
          SapsOverR,
          SapsOverL,
          sapsPainR,
          sapsPainL,
          sapsWorkR,
          sapsWorkL,
          sapsRecreR,
          sapsRecreL,
          // eqVasL,
          overallohsR,
          overallohsL,
          TignerRight,
          TignerLeft,
        },
      ])
    );

    setSubmit(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  useEffect(() => {
    if (submit) {
      updateDate();
    }
  }, [json]);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="complete_screen mb_100">
          <div className="panel">
            <div className="panel-body p_25">
              <NameCustom />
              <h4 className="ph_title f_40">
                {translation.patient_hip_complete}{" "}
              </h4>
              <div className="row">
                <div className="col-md-12 col-sm-12 mb_20 pt_20">
                  <p className="thanks_txt">
                    {translation.patient_hip_complete_text1}
                    <br /> {translation.patient_hip_complete_text2}
                  </p>
                  <Button
                    onClick={continueRedirect}
                    value={translation.submit}
                    buttonStyle="btn_fill cmp_submit_btn"
                  />
                  <span className="answer_txt">
                    {translation.patient_hip_complete_text3}
                    {/* <br /> {translation.Identifier} */}
                  </span>
                </div>
              </div>

              <div className="row mb_30 button_box">
                <div className="col-md-6 col-sm-6">
                  {/* <Link
                      // to={`${to}`}
                    onClick={handleBack}
                    to={`/hip-satisfaction/${rId}` }
                    className="assm_back_btn"
                  >
                    <img src={backIcon} alt="" />
                    <span>{translation.back_button}</span>
                  </Link> */}
              
                    <Link
                      to={`/hip-satisfaction/${rId}`}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <SubmitModel showModal={displayConfirmationModal}  patientID={window.btoa(patientID)} />
    </>
  );
}

export default HipComplete;
