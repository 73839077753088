import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";
import { UserDateFormat } from "../../../utilities/DateFormate";

function WesternOntarioShoulder({ wosiScore, AllData,functionData,profileAllData }) {
  const translation = useTranslation();
  return (
    <>
      {functionData.feel_unstable === "1" && (
        <div className="whitebox padding-none">
          <div className="treatment-header">
            <h2>{translation.patient_shoulder_wosi}</h2>
            <div className="treatment-header-right">
              <p>{translation.Assessment_Date} :  {AllData.assessment_date}
                {/* {UserDateFormat(AllData.assessment_date,profileAllData.ing_date_formate) } */}
              </p>
            </div>
          </div>

          <div className="treatment-content">
            <div className="treatment-row-header bd-none mb-0">
              {translation.patient_shoulder_wosi_sectionA}
            </div>

            <div className="treatment-row">
              <div className="treatment-table ">
                <table cellspacing="0" cellpadding="0">
                  <thead>
                    <tr>
                      <th></th>



                      <th>{translation.Right_Arm}</th>
                      <th>{translation.Left_Arm}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        1. {translation.patient_shoulder_wosi_q1}
                      </td>

                      <td>{wosiScore.wosi_q1_right}</td>
                      <td>{wosiScore.wosi_q1_left}</td>
                    </tr>

                    <tr>
                      <td>
                        2. {translation.patient_shoulder_wosi_q2}
                      </td>

                      <td>{wosiScore.wosi_q2_right}</td>
                      <td>{wosiScore.wosi_q2_left}</td>
                    </tr>

                    <tr>
                      <td>
                        3. {translation.patient_shoulder_wosi_q3}
                      </td>

                      <td>{wosiScore.wosi_q3_right}</td>
                      <td>{wosiScore.wosi_q3_left}</td>
                    </tr>

                    <tr>
                      <td>
                        4. {translation.patient_shoulder_wosi_q4}
                      </td>

                      <td>{wosiScore.wosi_q4_right}</td>
                      <td>{wosiScore.wosi_q4_left}</td>
                    </tr>

                    <tr>
                      <td>
                        5. {translation.patient_shoulder_wosi_q5}
                      </td>

                      <td>{wosiScore.wosi_q5_right}</td>
                      <td>{wosiScore.wosi_q5_left}</td>
                    </tr>

                    <tr>
                      <td>
                        6. {translation.patient_shoulder_wosi_q6}
                      </td>

                      <td>{wosiScore.wosi_q6_right}</td>
                      <td>{wosiScore.wosi_q6_left}</td>
                    </tr>

                    <tr>
                      <td>
                        7. {translation.patient_shoulder_wosi_q7}
                      </td>

                      <td>{wosiScore.wosi_q7_right}</td>
                      <td>{wosiScore.wosi_q7_left}</td>
                    </tr>

                    <tr>
                      <td>
                        8. {translation.patient_shoulder_wosi_q8}
                      </td>

                      <td>{wosiScore.wosi_q8_right}</td>
                      <td>{wosiScore.wosi_q8_left}</td>
                    </tr>

                    <tr>
                      <td>
                        9.{translation.patient_shoulder_wosi_q9}
                      </td>

                      <td>{wosiScore.wosi_q9_right}</td>
                      <td>{wosiScore.wosi_q9_left}</td>
                    </tr>

                    <tr>
                      <td>
                        10.{translation.patient_shoulder_wosi_q10}
                      </td>

                      <td>{wosiScore.wosi_q10_right}</td>
                      <td>{wosiScore.wosi_q10_left}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="treatment-content">
            <div className="treatment-row-header bd-none mb-0">
            {translation.patient_shoulder_wosi_sectionB}
            </div>

            <div className="treatment-row">
              <div className="treatment-table">
                <table cellspacing="0" cellpadding="0">
                  <thead>
                    <tr>
                      <th></th>


                      <th>{translation.Right_Arm}</th>
                      <th>{translation.Left_Arm}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        11. {translation.patient_shoulder_wosi_q11}
                      </td>

                      <td>{wosiScore.wosi_q11_right}</td>
                      <td>{wosiScore.wosi_q11_left}</td>
                    </tr>

                    <tr>
                      <td>
                        12. {translation.patient_shoulder_wosi_q12}
                      </td>

                      <td>{wosiScore.wosi_q12_right}</td>
                      <td>{wosiScore.wosi_q12_left}</td>
                    </tr>

                    <tr>
                      <td>
                        13. {translation.patient_shoulder_wosi_q13}
                      </td>

                      <td>{wosiScore.wosi_q13_right}</td>
                      <td>{wosiScore.wosi_q13_left}</td>
                    </tr>

                    <tr>
                      <td>
                        14. {translation.patient_shoulder_wosi_q14}
                      </td>

                      <td>{wosiScore.wosi_q14_right}</td>
                      <td>{wosiScore.wosi_q14_left}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="treatment-content">
            <div className="treatment-row-header bd-none mb-0">
            {translation.patient_shoulder_wosi_sectionC}
            </div>

            <div className="treatment-row">
              <div className="treatment-table">
                <table cellspacing="0" cellpadding="0">
                  <thead>
                    <tr>
                      <th></th>


                      <th>{translation.Right_Arm}</th>
                      <th>{translation.Left_Arm}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        15. {translation.patient_shoulder_wosi_q15}
                      </td>

                      <td>{wosiScore.wosi_q15_right}</td>
                      <td>{wosiScore.wosi_q15_left}</td>
                    </tr>

                    <tr>
                      <td>
                        16. {translation.patient_shoulder_wosi_q16}
                      </td>

                      <td>{wosiScore.wosi_q16_right}</td>
                      <td>{wosiScore.wosi_q16_left}</td>
                    </tr>

                    <tr>
                      <td>
                        17. {translation.patient_shoulder_wosi_q17}
                      </td>

                      <td>{wosiScore.wosi_q17_right}</td>
                      <td>{wosiScore.wosi_q17_left}</td>
                    </tr>

                    <tr>
                      <td>
                        18. {translation.patient_shoulder_wosi_q18}
                      </td>

                      <td>{wosiScore.wosi_q18_right}</td>
                      <td>{wosiScore.wosi_q18_left}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="treatment-content">
            <div className="treatment-row-header bd-none mb-0">
             {translation.patient_shoulder_wosi_sectionD}
            </div>

            <div className="treatment-row">
              <div className="treatment-table border-bottom pb-30">
                <table cellspacing="0" cellpadding="0">
                  <thead>
                    <tr>
                      <th></th>


                      <th>{translation.Right_Arm}</th>
                      <th>{translation.Left_Arm}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>19. {translation.patient_shoulder_wosi_q19} </td>

                      <td>{wosiScore.wosi_q19_right}</td>
                      <td>{wosiScore.wosi_q19_left}</td>
                    </tr>

                    <tr>
                      <td>
                        20. {translation.patient_shoulder_wosi_q20}
                      </td>

                      <td>{wosiScore.wosi_q20_right}</td>
                      <td>{wosiScore.wosi_q20_left}</td>
                    </tr>

                    <tr>
                      <td>
                        21. {translation.patient_shoulder_wosi_q21}
                      </td>

                      <td>{wosiScore.wosi_q21_right}</td>
                      <td>{wosiScore.wosi_q21_left}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default WesternOntarioShoulder;
