import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import KneeScoreRangeSlider from "../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import {
  updateHipScores,
  updateHIPPainScores,
  updateHipSymptomsFieldScore,
  updateHIPSymptomsScores,
  updateHIPfunctionScores,
  updateHIPQOLScores,
  updateHIPSatisfactionScores,
  updateHipFeedbackAll,
  updateAllData,
} from "../../../../redux/actions/hipAction";
import useAxios from "../../../../axiosinstance";
import useTranslation from "../../../customHooks/translations";
import HipTab from "../../tab-section/HipTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import { LeftTrans, RightSide,LeftSide, RigthTrans } from "../../../../utilities/RightLeftFormat";
import NameCustom from "../../../custom/name/NameCustom";

function HipSymptoms() {
  const translation = useTranslation();
  const dispatch = useDispatch();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const evlHipPain = useSelector((state) => state.hipScoreReducer.evlHipPain);
  const symptomsScore = useSelector(
    (state) => state.hipScoreReducer.symptomsScore[0]
  );
  const json = useSelector((state) => state.hipScoreReducer);
  const [isLoading, setIsLoading] = useState(true);
  const [grindingL, setGrindingL] = useState("0");
  const [grindingR, setGrindingR] = useState("0");
  const [catchingL, setCatchingL] = useState("0");
  const [catchingR, setCatchingR] = useState("0");
  const [stifL, setStifL] = useState("0");
  const [stifR, setStifR] = useState("0");
  const [stifSitL, setStifSitL] = useState("0");
  const [stifSitR, setStifSitR] = useState("0");
  const [limpingL, setLimpingL] = useState("0");
  const [limpingR, setLimpingR] = useState("0");
  const [suddenL, setSuddenL] = useState("0");
  const [suddenR, setSuddenR] = useState("0");
  const [hipGivingL, setHipGivingL] = useState("0");
  const [hipGivingR, setHipGivingR] = useState("0");
  const [decreasedL, setDecreasedL] = useState("0");
  const [decreasedR, setDecreasedR] = useState("0");
  const [supportL, setSupportL] = useState("5");
  const [supportR, setSupportR] = useState("5");
  const navigate = useNavigate();
  const [updateTotal, setUpdateTotal] = useState(false);
  const [chk, setChk] = useState(false);
  let doctordata =localStorage.getItem("isdoctorForm");
  let viewicon=localStorage.getItem("viewicon")
  useEffect(() => {
    setGrindingL(symptomsScore.grindingL);
    setGrindingR(symptomsScore.grindingR);
    setCatchingL(symptomsScore.catchingL);
    setCatchingR(symptomsScore.catchingR);
    setStifL(symptomsScore.stifL);
    setStifR(symptomsScore.stifR);
    setStifSitL(symptomsScore.stifSitL);
    setStifSitR(symptomsScore.stifSitR);
    setLimpingL(symptomsScore.limpingL);
    setLimpingR(symptomsScore.limpingR);
    setSuddenL(symptomsScore.suddenL);
    setSuddenR(symptomsScore.suddenR);
    setHipGivingL(symptomsScore.hipGivingL);
    setHipGivingR(symptomsScore.hipGivingR);
    setDecreasedL(symptomsScore.decreasedL);
    setDecreasedR(symptomsScore.decreasedR);
    setSupportL(symptomsScore.supportL);
    setSupportR(symptomsScore.supportR);
  }, [symptomsScore]);

  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=6`)
      .then((res) => {
        // console.log(res.data.data2[0].evlHipPain)
        dispatch(updateHIPPainScores(res.data.data2[0].painScore));
        dispatch(updateHIPSymptomsScores(res.data.data2[0].symptomsScore));
        dispatch(updateHipScores(res.data.data2[0].evlHipPain));
        dispatch(updateHIPfunctionScores(res.data.data2[0].functionScore));
        dispatch(updateHIPQOLScores(res.data.data2[0].qolScore));
        dispatch(
          updateHIPSatisfactionScores(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateHipFeedbackAll, res.data.data2[0].doctorFeedback);
        setChk(true);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };
  useEffect(() => {
    save();
  }, []);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log(res);
      });
  };

  const handleGrindingL = (e) => {
    dispatch(
      updateHipSymptomsFieldScore({
        key: "grindingL",
        value: e,
      })
    );
    setGrindingL(e);
  };
  const handleGrindingR = (e) => {
    dispatch(
      updateHipSymptomsFieldScore({
        key: "grindingR",
        value: e,
      })
    );
    setGrindingR(e);
  };
  const handleCatchingL = (e) => {
    dispatch(
      updateHipSymptomsFieldScore({
        key: "catchingL",
        value: e,
      })
    );
    setCatchingL(e);
  };
  const handleCatchingR = (e) => {
    dispatch(
      updateHipSymptomsFieldScore({
        key: "catchingR",
        value: e,
      })
    );
    setCatchingR(e);
  };
  const handleStifL = (e) => {
    dispatch(
      updateHipSymptomsFieldScore({
        key: "stifL",
        value: e,
      })
    );
    setStifL(e);
  };
  const handleStifR = (e) => {
    dispatch(
      updateHipSymptomsFieldScore({
        key: "stifR",
        value: e,
      })
    );
    setStifR(e);
  };
  const handleStifSitL = (e) => {
    dispatch(
      updateHipSymptomsFieldScore({
        key: "stifSitL",
        value: e,
      })
    );
    setStifSitL(e);
  };
  const handleStifSitR = (e) => {
    dispatch(
      updateHipSymptomsFieldScore({
        key: "stifSitR",
        value: e,
      })
    );
    setStifSitR(e);
  };
  const handleLimpingL = (e) => {
    dispatch(
      updateHipSymptomsFieldScore({
        key: "limpingL",
        value: e,
      })
    );
    setLimpingL(e);
  };
  const handleLimpingR = (e) => {
    dispatch(
      updateHipSymptomsFieldScore({
        key: "limpingR",
        value: e,
      })
    );
    setLimpingR(e);
  };
  const handleSuddenL = (e) => {
    dispatch(
      updateHipSymptomsFieldScore({
        key: "suddenL",
        value: e,
      })
    );
    setSuddenL(e);
  };
  const handleSuddenR = (e) => {
    dispatch(
      updateHipSymptomsFieldScore({
        key: "suddenR",
        value: e,
      })
    );
    setSuddenR(e);
  };
  const handleHipGivingL = (e) => {
    dispatch(
      updateHipSymptomsFieldScore({
        key: "hipGivingL",
        value: e,
      })
    );
    setHipGivingL(e);
  };
  const handleHipGivingR = (e) => {
    dispatch(
      updateHipSymptomsFieldScore({
        key: "hipGivingR",
        value: e,
      })
    );
    setHipGivingR(e);
  };
  const handleDecreasedL = (e) => {
    dispatch(
      updateHipSymptomsFieldScore({
        key: "decreasedL",
        value: e,
      })
    );
    setDecreasedL(e);
  };
  const handleDecreasedR = (e) => {
    dispatch(
      updateHipSymptomsFieldScore({
        key: "decreasedR",
        value: e,
      })
    );
    setDecreasedR(e);
  };
  const handleSupportL = (e) => {
    dispatch(
      updateHipSymptomsFieldScore({
        key: "supportL",
        value: e,
      })
    );
    setSupportL(e);
  };
  const handleSupportR = (e) => {
    dispatch(
      updateHipSymptomsFieldScore({
        key: "supportR",
        value: e,
      })
    );
    setSupportR(e);
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [symptomsScore, updateTotal]);
  const continueRedirect = (e) => {
    dispatch(
      updateHIPSymptomsScores([
        {
          grindingL: grindingL,
          grindingR: grindingR,
          catchingL: catchingL,
          catchingR: catchingR,
          stifL: stifL,
          stifR: stifR,
          stifSitL: stifSitL,
          stifSitR: stifSitR,
          limpingL: limpingL,
          limpingR: limpingR,
          suddenL: suddenL,
          suddenR: suddenR,
          hipGivingL: hipGivingL,
          hipGivingR: hipGivingR,
          decreasedL: decreasedL,
          decreasedR: decreasedR,
          supportL: supportL,
          supportR: supportR,
        },
      ])
    );
    navigate("/hip-function/" + rId);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);


  let akpt = searchParams.get("akpt");
  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update);
    setmsgType("success");
     setTimeout(() => {
     let view_circular=  sessionStorage.getItem("view_circular")
      // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
      // window.location.href = process.env.REACT_APP_DOCTOR_URL +`view-management-plan/${akpt}/${window.btoa(
      //   rId
      // )}/?org=${window.btoa(sessionStorage.getItem("orgid")||'')}&formType=${
      //   window.btoa('6') || ""
      // }&view_circular=${view_circular==='false'?'false':'true'}`
      window.location.href = process.env.REACT_APP_DOCTOR_URL +`patient-details/${akpt}?formid=6`
      setMsg('')
      setmsgType('success')
    }, 2000);
  };


  const handleNext=()=>{
    navigate(`/hip-function/${rId}?d=true&akpt=${akpt}`);

  }


//   console.log("supportR",
// supportR);
//   console.log("supportL",
// supportL);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
          <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <HipTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />
           
              <h4 className="ph_title f_40">{translation.patient_hip_other_symptoms}</h4>

              <div className="function_formtop">
                <div className="row">
                  <div className="col-md-12 col-sm-12 function_head">
                    <label>{translation.patient_hip_other_symptoms_instruction1}</label> <br />
                    <label >{translation.patient_hip_other_symptoms_instruction2}</label> <br />
                    <label className="mt-3">{translation.patient_hip_other_symptoms_instruction3}</label> <br />
                  </div>
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                     <div className={"col-md-6 col-sm-6 mb_10" +RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_other_symptoms_q1 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={grindingR}
                        onChange={handleGrindingR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_other_symptoms_q1_a1 },
                          { score: "1", label: translation.patient_hip_other_symptoms_q1_a2 },
                          { score: "2", label: translation.patient_hip_other_symptoms_q1_a3 },
                          { score: "3", label: translation.patient_hip_other_symptoms_q1_a4 },
                          { score: "4", label: translation.patient_hip_other_symptoms_q1_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                     <div className={"col-md-6 col-sm-6 mb_10" +LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_other_symptoms_q1 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={grindingL}
                        onChange={handleGrindingL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_other_symptoms_q1_a1 },
                          { score: "1", label: translation.patient_hip_other_symptoms_q1_a2 },
                          { score: "2", label: translation.patient_hip_other_symptoms_q1_a3 },
                          { score: "3", label: translation.patient_hip_other_symptoms_q1_a4 },
                          { score: "4", label: translation.patient_hip_other_symptoms_q1_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                     <div className={"col-md-6 col-sm-6 mb_10" +RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_other_symptoms_q2 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={catchingR}
                        onChange={handleCatchingR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_other_symptoms_q2_a1 },
                          { score: "1", label: translation.patient_hip_other_symptoms_q2_a2 },
                          { score: "2", label: translation.patient_hip_other_symptoms_q2_a3 },
                          { score: "3", label: translation.patient_hip_other_symptoms_q2_a4 },
                          { score: "4", label: translation.patient_hip_other_symptoms_q2_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                     <div className={"col-md-6 col-sm-6 mb_10" +LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_other_symptoms_q2 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={catchingL}
                        onChange={handleCatchingL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_other_symptoms_q2_a1 },
                          { score: "1", label: translation.patient_hip_other_symptoms_q2_a2 },
                          { score: "2", label: translation.patient_hip_other_symptoms_q2_a3 },
                          { score: "3", label: translation.patient_hip_other_symptoms_q2_a4 },
                          { score: "4", label: translation.patient_hip_other_symptoms_q2_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                     <div className={"col-md-6 col-sm-6 mb_10" +RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_other_symptoms_q3 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={stifR}
                        onChange={handleStifR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_other_symptoms_q3_a1 },
                          { score: "1", label: translation.patient_hip_other_symptoms_q3_a2 },
                          { score: "2", label: translation.patient_hip_other_symptoms_q3_a3 },
                          { score: "3", label: translation.patient_hip_other_symptoms_q3_a4 },
                          { score: "4", label: translation.patient_hip_other_symptoms_q3_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                     <div className={"col-md-6 col-sm-6 mb_10" +LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_other_symptoms_q3 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={stifL}
                        onChange={handleStifL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_other_symptoms_q3_a1 },
                          { score: "1", label: translation.patient_hip_other_symptoms_q3_a2 },
                          { score: "2", label: translation.patient_hip_other_symptoms_q3_a3 },
                          { score: "3", label: translation.patient_hip_other_symptoms_q3_a4 },
                          { score: "4", label: translation.patient_hip_other_symptoms_q3_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                     <div className={"col-md-6 col-sm-6 mb_10" +RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_other_symptoms_q4 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={stifSitR}
                        onChange={handleStifSitR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_other_symptoms_q4_a1 },
                          { score: "1", label: translation.patient_hip_other_symptoms_q4_a2 },
                          { score: "2", label: translation.patient_hip_other_symptoms_q4_a3 },
                          { score: "3", label: translation.patient_hip_other_symptoms_q4_a4 },
                          { score: "4", label: translation.patient_hip_other_symptoms_q4_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                     <div className={"col-md-6 col-sm-6 mb_10" +LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_other_symptoms_q4 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={stifSitL}
                        onChange={handleStifSitL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_other_symptoms_q4_a1 },
                          { score: "1", label: translation.patient_hip_other_symptoms_q4_a2 },
                          { score: "2", label: translation.patient_hip_other_symptoms_q4_a3 },
                          { score: "3", label: translation.patient_hip_other_symptoms_q4_a4 },
                          { score: "4", label: translation.patient_hip_other_symptoms_q4_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                     <div className={"col-md-6 col-sm-6 mb_10" +RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_other_symptoms_q5 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={limpingR}
                        onChange={handleLimpingR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_other_symptoms_q5_a1 },
                          { score: "1", label: translation.patient_hip_other_symptoms_q5_a2 },
                          { score: "2", label: translation.patient_hip_other_symptoms_q5_a3 },
                          { score: "3", label: translation.patient_hip_other_symptoms_q5_a4 },
                          { score: "4", label: translation.patient_hip_other_symptoms_q5_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                     <div className={"col-md-6 col-sm-6 mb_10" +LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_other_symptoms_q5 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={limpingL}
                        onChange={handleLimpingL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_other_symptoms_q5_a1 },
                          { score: "1", label: translation.patient_hip_other_symptoms_q5_a2 },
                          { score: "2", label: translation.patient_hip_other_symptoms_q5_a3 },
                          { score: "3", label: translation.patient_hip_other_symptoms_q5_a4 },
                          { score: "4", label: translation.patient_hip_other_symptoms_q5_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row mt-3">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                     <div className={"col-md-6 col-sm-6 mb_10" +RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_other_symptoms_q6 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={suddenR}
                        onChange={handleSuddenR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_other_symptoms_q6_a1 },
                          { score: "1", label: translation.patient_hip_other_symptoms_q6_a2 },
                          { score: "2", label: translation.patient_hip_other_symptoms_q6_a3 },
                          { score: "3", label: translation.patient_hip_other_symptoms_q6_a4 },
                          { score: "4", label: translation.patient_hip_other_symptoms_q6_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                     <div className={"col-md-6 col-sm-6 mb_10" +LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_other_symptoms_q6 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={suddenL}
                        onChange={handleSuddenL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_other_symptoms_q6_a1 },
                          { score: "1", label: translation.patient_hip_other_symptoms_q6_a2 },
                          { score: "2", label: translation.patient_hip_other_symptoms_q6_a3 },
                          { score: "3", label: translation.patient_hip_other_symptoms_q6_a4 },
                          { score: "4", label: translation.patient_hip_other_symptoms_q6_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                     <div className={"col-md-6 col-sm-6 mb_10" +RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_other_symptoms_q7 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={hipGivingR}
                        onChange={handleHipGivingR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_other_symptoms_q7_a1 },
                          { score: "1", label: translation.patient_hip_other_symptoms_q7_a2 },
                          { score: "2", label: translation.patient_hip_other_symptoms_q7_a3 },
                          { score: "3", label: translation.patient_hip_other_symptoms_q7_a4 },
                          { score: "4", label: translation.patient_hip_other_symptoms_q7_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                     <div className={"col-md-6 col-sm-6 mb_10" +LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_other_symptoms_q7 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={hipGivingL}
                        onChange={handleHipGivingL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_other_symptoms_q7_a1 },
                          { score: "1", label: translation.patient_hip_other_symptoms_q7_a2 },
                          { score: "2", label: translation.patient_hip_other_symptoms_q7_a3 },
                          { score: "3", label: translation.patient_hip_other_symptoms_q7_a4 },
                          { score: "4", label: translation.patient_hip_other_symptoms_q7_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                     <div className={"col-md-6 col-sm-6 mb_10" +RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_other_symptoms_q8 + RigthTrans(evlHipPain, translation)}
                        classes=""
                        value={decreasedR}
                        onChange={handleDecreasedR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_other_symptoms_q8_a1 },
                          { score: "1", label: translation.patient_hip_other_symptoms_q8_a2 },
                          { score: "2", label: translation.patient_hip_other_symptoms_q8_a3 },
                          { score: "3", label: translation.patient_hip_other_symptoms_q8_a4 },
                          { score: "4", label: translation.patient_hip_other_symptoms_q8_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                     <div className={"col-md-6 col-sm-6 mb_10" +LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_other_symptoms_q8 + LeftTrans(evlHipPain, translation)}
                        classes=""
                        value={decreasedL}
                        onChange={handleDecreasedL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_other_symptoms_q8_a1 },
                          { score: "1", label: translation.patient_hip_other_symptoms_q8_a2 },
                          { score: "2", label: translation.patient_hip_other_symptoms_q8_a3 },
                          { score: "3", label: translation.patient_hip_other_symptoms_q8_a4 },
                          { score: "4", label: translation.patient_hip_other_symptoms_q8_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                     <div className={"col-md-6 col-sm-6 mb_10" +RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_other_symptoms_q9 + RigthTrans(evlHipPain, translation)}
                        styleClass=""
                        value={supportR}
                        maxnum="5"
                        onChange={handleSupportR}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_other_symptoms_q9_a1 },
                          {
                            score: "1",
                            label: translation.patient_hip_other_symptoms_q9_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hip_other_symptoms_q9_a3,
                          },
                          { score: "3", label: translation.patient_hip_other_symptoms_q9_a4 },
                          { score: "4", label: translation.patient_hip_other_symptoms_q9_a5 },
                          {
                            score: "5",
                            label: translation.patient_hip_other_symptoms_q9_a6,
                          },
                        ]}
                      />
                      {/* <div className="mb_10">
                        <FullDropdown
                          data={[
                            { value: "0", label: " None" },
                            {
                              value: "1",
                              label: " Cane/walking stick for long walks",
                            },
                            {
                              value: "2",
                              label: "Cane/walking stick most of the time ",
                            },
                            { value: "3", label: " One crutch" },
                            { value: "4", label: " Two canes/walking sticks" },
                            {
                              value: "5",
                              label: " Two crutches or not able to walk",
                            },
                          ]}
                          label={
                            evlHipPain === "3"
                              ? translation.Do_you_support_walkingR
                              : translation.Do_you_support_walking
                          }
                          styleClass=""
                          value={supportR}
                          onChange={handleSupportR}
                        />
                      </div> */}
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                     <div className={"col-md-6 col-sm-6 mb_10" +LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_other_symptoms_q9 + LeftTrans(evlHipPain, translation)}
                        styleClass=""
                        value={supportL}
                        maxnum="5"
                        onChange={handleSupportL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_other_symptoms_q9_a1 },
                          {
                            score: "1",
                            label: translation.patient_hip_other_symptoms_q9_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_hip_other_symptoms_q9_a3,
                          },
                          { score: "3", label: translation.patient_hip_other_symptoms_q9_a4 },
                          { score: "4", label: translation.patient_hip_other_symptoms_q9_a5 },
                          {
                            score: "5",
                            label: translation.patient_hip_other_symptoms_q9_a6,
                          },
                        ]}
                      />
                      {/* <div className="mb_10">
                        <FullDropdown
                          data={[
                            { value: "0", label: " None" },
                            {
                              value: "1",
                              label: " Cane/walking stick for long walks",
                            },
                            {
                              value: "2",
                              label: "Cane/walking stick most of the time ",
                            },
                            { value: "3", label: " One crutch" },
                            { value: "4", label: " Two canes/walking sticks" },
                            {
                              value: "5",
                              label: " Two crutches or not able to walk",
                            },
                          ]}
                          label={
                            evlHipPain === "3"
                              ? translation.Do_you_support_walkingL
                              : translation.Do_you_support_walking
                          }
                          styleClass=""
                          value={supportL}
                          onChange={handleSupportL}
                        />
                      </div> */}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <br />
              </div>
              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6"></div>
                  <div className="col-md-6 col-sm-6 text-end">
                  <button
                  className="next_btn btn_fill me-3"
                  onClick={() => handleNext()}
                >
                  {translation.next_button}
                  <span>
                    <img src={nextIcon} alt="" />
                    {/* <img src={nextWhiteIcon} alt="" /> */}
                  </span>
                </button>
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}

              {doctorEnable !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    <Link to={`/hip-pain/${rId}?is_doctor_form=${doctordata}&edit=${viewicon}`} className="assm_back_btn">
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HipSymptoms;
