import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../../images/back.png";
import nextWhiteIcon from "../../../../../images/next-white.png";
import nextIcon from "../../../../../images/next.png";
import LoadingSpinner from "../../../../custom/loader/LoadingSpinner";
import RangeSlider from "../../../../custom/range-slider/RangeSlider";
import KneeScoreRangeSlider from "../../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import Dropdown from "../../../../custom/dropdown/Dropdown";
import FullDropdown from "../../../../custom/dropdown/FullDropdown";
import {
  updateAdultKneeScores,
  updateAdultKneePainScores,
  updateAdultKneePainFieldScore,
  updateAdultKneeQolScore,
  updateAdultKneeSatisfactionScore,
  updateAdultKneeSymptomsScore,
  updateAdultKneeFunctionScore,
  updateAdultKneeNorwichInstaScore,
  updateAllData,
  updateAdultKneeFeedback,
} from "../../../../../redux/actions/index";
import useAxios from "../../../../../axiosinstance";
import useTransition from "../../../../customHooks/translations";
import AdultkneeTab from "../../../tab-section/AdultkneeTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../../custom/toaster/CustomToaster";
import {
  LeftSide,
  LeftTrans,
  RightSide,
  RigthTrans,
} from "../../../../../utilities/RightLeftFormat";
import NameCustom from "../../../../custom/name/NameCustom";

function AdultKneePain() {
  const translation = useTransition();
  let lg = sessionStorage.getItem("language");
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const [describePainR, setDescribePainR] = useState("0");
  const [describePainL, setDescribePainL] = useState("0");
  const [painTodayRatingR, setPainTodayRatingR] = useState("0");
  const [painTodayRatingL, setPainTodayRatingL] = useState("0");
  const [bestStatmentForPainR, setBestStatmentForPainR] = useState("0");
  const [bestStatmentForPainL, setBestStatmentForPainL] = useState("0");
  const [howSevereR, setHowSevereR] = useState("0");
  const [howSevereL, setHowSevereL] = useState("0");
  const [kneeInterferedR, setKneeInterferedR] = useState("0");
  const [kneeInterferedL, setKneeInterferedL] = useState("0");
  const [twistingR, setTwistingR] = useState("0");
  const [twistingL, setTwistingL] = useState("0");
  const [straighteningR, setStraighteningR] = useState("0");
  const [straighteningL, setStraighteningL] = useState("0");
  const [bendingR, setBendingR] = useState("0");
  const [bendingL, setBendingL] = useState("0");
  const [walkingSurfaceR, setWalkingSurfaceR] = useState("0");
  const [walkingSurfaceL, setWalkingSurfaceL] = useState("0");
  const [howLongWalkR, setHowLongWalkR] = useState("0");
  const [howLongWalkL, setHowLongWalkL] = useState("0");
  const [useStepsR, setUseStepsR] = useState("0");
  const [useStepsL, setUseStepsL] = useState("0");
  const [whileBedR, setWhileBedR] = useState("0");
  const [whileBedL, setWhileBedL] = useState("0");
  const [painAtNightR, setPainAtNightR] = useState("0");
  const [painAtNightL, setPainAtNightL] = useState("0");
  const [sittingR, setSittingR] = useState("0");
  const [sittingL, setSittingL] = useState("0");
  const [standingR, setStandingR] = useState("0");
  const [standingL, setStandingL] = useState("0");
  const [standingFromChairR, setStandingFromChairR] = useState("0");
  const [standingFromChairL, setStandingFromChairL] = useState("0");
  const [backD, setbackD] = useState({});
  const [age, setAge] = useState("1");
  const evlAdultKnee = useSelector(
    (state) => state.adultKneeScore.evlAdultKnee
  );
  let isdoctorForm = searchParams.get("is_doctor_form");
  let viewicon = searchParams.get("edit")
  localStorage.setItem("viewicon", viewicon)
  useEffect(() => {
    if (doctorEnable === "true") {
      let doctordata = localStorage.getItem("isdoctorForm")
      localStorage.setItem("isdoctorForm", doctordata)
    } else {
      localStorage.setItem("isdoctorForm", isdoctorForm)
    }
    localStorage.setItem("isdoctorForm", isdoctorForm)
  }, [isdoctorForm])
  const adlPainScore = useSelector((state) => state.adultKneeScore.painScore);
  const dispatch = useDispatch();
  const [updateTotal, setUpdateTotal] = useState(false);
  const navigate = useNavigate();
  const [chk, setChk] = useState(false);
  const [backDoc, setBackDoc] = useState("");
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=5`)
      .then((res) => {
        setBackDoc(res.data.data2[0].is_doctor_form);
        setAge(res.data.data2[0].age);
        dispatch(updateAdultKneePainScores(res.data.data2[0].painScore));
        dispatch(updateAdultKneeSymptomsScore(res.data.data2[0].symptomsScore));
        dispatch(updateAdultKneeScores(res.data.data2[0].evlAdultKnee));
        dispatch(updateAdultKneeQolScore(res.data.data2[0].qolScore));
        dispatch(updateAdultKneeFunctionScore(res.data.data2[0].functionScore));
        dispatch(
          updateAdultKneeNorwichInstaScore(res.data.data2[0].norwichInstability)
        );

        dispatch(
          updateAdultKneeSatisfactionScore(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateAdultKneeFeedback(res.data.data2[0].doctorFeedback));
        setChk(true);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };

  useEffect(() => {
    save();
  }, []);

  const KneePain = useSelector((state) => state.adultKneeScore.painScore[0]);
  const json = useSelector((state) => state.adultKneeScore);

  useEffect(() => {
    setDescribePainR(KneePain.describePainR);
    setDescribePainL(KneePain.describePainL);
    setPainTodayRatingR(KneePain.painTodayRatingR);
    setPainTodayRatingL(KneePain.painTodayRatingL);
    setBestStatmentForPainR(KneePain.bestStatmentForPainR);
    setBestStatmentForPainL(KneePain.bestStatmentForPainL);
    setHowSevereR(KneePain.howSevereR);
    setHowSevereL(KneePain.howSevereL);
    setKneeInterferedR(KneePain.kneeInterferedR);
    setKneeInterferedL(KneePain.kneeInterferedL);
    setTwistingR(KneePain.twistingR);
    setTwistingL(KneePain.twistingL);
    setStraighteningR(KneePain.straighteningR);
    setStraighteningL(KneePain.straighteningL);
    setBendingR(KneePain.bendingR);
    setBendingL(KneePain.bendingL);
    setWalkingSurfaceR(KneePain.walkingSurfaceR);
    setWalkingSurfaceL(KneePain.walkingSurfaceL);
    setHowLongWalkR(KneePain.howLongWalkR);
    setHowLongWalkL(KneePain.howLongWalkL);
    setUseStepsR(KneePain.useStepsR);
    setUseStepsL(KneePain.useStepsL);
    setWhileBedR(KneePain.whileBedR);
    setWhileBedL(KneePain.whileBedL);
    setPainAtNightR(KneePain.painAtNightR);
    setPainAtNightL(KneePain.painAtNightL);
    setSittingR(KneePain.sittingR);
    setSittingL(KneePain.sittingL);
    setStandingR(KneePain.standingR);
    setStandingL(KneePain.standingL);
    setStandingFromChairR(KneePain.standingFromChairR);
    setStandingFromChairL(KneePain.standingFromChairL);
  }, [KneePain]);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true" ? "1" : "0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [KneePain, updateTotal, json]);
  // console.log(chk);
  const handleEvolutedAdultKnee = (e) => {
    dispatch(updateAdultKneeScores(e));
  };

  const handleDescribePainL = (e) => {
    setDescribePainL(e);

    dispatch(
      updateAdultKneePainFieldScore({
        key: "describePainL",
        value: e,
      })
    );
  };
  const handleDescribePainR = (e) => {
    setDescribePainR(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "describePainR",
        value: e,
      })
    );
  };

  const handlePainTodayRatingL = (e) => {
    setPainTodayRatingL(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "painTodayRatingL",
        value: e,
      })
    );
  };
  const handlePainTodayRatingR = (e) => {
    setPainTodayRatingR(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "painTodayRatingR",
        value: e,
      })
    );
  };

  const handleBestStatmentForPainL = (e) => {
    setBestStatmentForPainL(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "bestStatmentForPainL",
        value: e,
      })
    );
  };
  const handleBestStatmentForPainR = (e) => {
    setBestStatmentForPainR(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "bestStatmentForPainR",
        value: e,
      })
    );
  };

  const handleHowSevereL = (e) => {
    setHowSevereL(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "howSevereL",
        value: e,
      })
    );
  };
  const handleHowSevereR = (e) => {
    setHowSevereR(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "howSevereR",
        value: e,
      })
    );
  };

  const handleKneeInterferedL = (e) => {
    setKneeInterferedL(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "kneeInterferedL",
        value: e,
      })
    );
  };
  const handleKneeInterferedR = (e) => {
    setKneeInterferedR(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "kneeInterferedR",
        value: e,
      })
    );
  };

  const handleTwistingR = (e) => {
    setTwistingR(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "twistingR",
        value: e,
      })
    );
  };
  const handleTwistingL = (e) => {
    setTwistingL(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "twistingL",
        value: e,
      })
    );
  };

  const handleStraighteningR = (e) => {
    setStraighteningR(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "straighteningR",
        value: e,
      })
    );
  };
  const handleStraighteningL = (e) => {
    setStraighteningL(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "straighteningL",
        value: e,
      })
    );
  };

  const handleBendingR = (e) => {
    setBendingR(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "bendingR",
        value: e,
      })
    );
  };
  const handleBendingL = (e) => {
    setBendingL(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "bendingL",
        value: e,
      })
    );
  };

  const handleWalkingSurfaceR = (e) => {
    setWalkingSurfaceR(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "walkingSurfaceR",
        value: e,
      })
    );
  };
  const handleWalkingSurfaceL = (e) => {
    setWalkingSurfaceL(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "walkingSurfaceL",
        value: e,
      })
    );
  };

  const handleHowLongWalkL = (e) => {
    setHowLongWalkL(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "howLongWalkL",
        value: e,
      })
    );
  };
  const handleHowLongWalkR = (e) => {
    setHowLongWalkR(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "howLongWalkR",
        value: e,
      })
    );
  };

  const handleUseStepsL = (e) => {
    setUseStepsL(e);
    setHowLongWalkR(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "useStepsL",
        value: e,
      })
    );
  };
  const handleUseStepsR = (e) => {
    setUseStepsR(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "useStepsR",
        value: e,
      })
    );
  };

  const handleWhileBedL = (e) => {
    setWhileBedL(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "whileBedL",
        value: e,
      })
    );
  };
  const handleWhileBedR = (e) => {
    setWhileBedR(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "whileBedR",
        value: e,
      })
    );
  };

  const handlePainAtNightL = (e) => {
    setPainAtNightL(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "painAtNightL",
        value: e,
      })
    );
  };
  const handlePainAtNightR = (e) => {
    setPainAtNightR(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "painAtNightR",
        value: e,
      })
    );
  };

  const handleSittingR = (e) => {
    setSittingR(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "sittingR",
        value: e,
      })
    );
  };
  const handleSittingL = (e) => {
    setSittingL(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "sittingL",
        value: e,
      })
    );
  };

  const handleStandingR = (e) => {
    setStandingR(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "standingR",
        value: e,
      })
    );
  };
  const handleStandingL = (e) => {
    setStandingL(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "standingL",
        value: e,
      })
    );
  };

  const handleStandingFromChairR = (e) => {
    setStandingFromChairR(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "standingFromChairR",
        value: e,
      })
    );
  };
  const handleStandingFromChairL = (e) => {
    setStandingFromChairL(e);
    dispatch(
      updateAdultKneePainFieldScore({
        key: "standingFromChairL",
        value: e,
      })
    );
  };

  const continueRedirect = (e) => {
    dispatch(
      updateAdultKneePainScores([
        {
          describePainR: describePainR,
          describePainL: describePainL,
          painTodayRatingR: painTodayRatingR,
          painTodayRatingL: painTodayRatingL,
          bestStatmentForPainR: bestStatmentForPainR,
          bestStatmentForPainL: bestStatmentForPainL,
          howSevereR: howSevereR,
          howSevereL: howSevereL,
          kneeInterferedR: kneeInterferedR,
          kneeInterferedL: kneeInterferedL,
          twistingR: twistingR,
          twistingL: twistingL,
          straighteningR: straighteningR,
          straighteningL: straighteningL,
          bendingR: bendingR,
          bendingL: bendingL,
          walkingSurfaceR: walkingSurfaceR,
          walkingSurfaceL: walkingSurfaceL,
          howLongWalkR: howLongWalkR,
          howLongWalkL: howLongWalkL,
          useStepsR: useStepsR,
          useStepsL: useStepsL,
          whileBedR: whileBedR,
          whileBedL: whileBedL,
          painAtNightR: painAtNightR,
          painAtNightL: painAtNightL,
          sittingR: sittingR,
          sittingL: sittingL,
          standingR: standingR,
          standingL: standingL,
          standingFromChairR: standingFromChairR,
          standingFromChairL: standingFromChairL,
        },
      ])
    );
    navigate("/adult-knee-symptoms/" + rId + `?is_doctor_form=${isdoctorForm}`);
  };


  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const backData = () => {
    axiosinstance.current
      .get(`extapp/forms/urgent_data?resource_id=${rId}`)
      .then((res) => {
        setbackD(res.data.data);
        sessionStorage.setItem("ptid", res.data.data.ak_pt);
      });
  };
  useEffect(() => {
    save();
    backData();
  }, []);
  let akpt = searchParams.get("akpt");

  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update);
    setmsgType("success");
     setTimeout(() => {
     let view_circular=  sessionStorage.getItem("view_circular")
      // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
      // window.location.href = process.env.REACT_APP_DOCTOR_URL +`view-management-plan/${akpt}/${window.btoa(
      //   rId
      // )}/?org=${window.btoa(sessionStorage.getItem("orgid")||'')}&formType=${
      //   window.btoa('5') || ""
      // }&view_circular=${view_circular==='false'?'false':'true'}`
       window.location.href = process.env.REACT_APP_DOCTOR_URL +`patient-details/${akpt}?formid=5`
      setMsg('')
      setmsgType('success')
    }, 2000);
  };

  const handleNext = () => {
    navigate(`/adult-knee-symptoms/${rId}?d=true&akpt=${akpt}&is_doctor_form=${isdoctorForm}`);

  }
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
          <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <AdultkneeTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />

              {String(age) && String(age) === "1" ? (
                <h4 className="ph_title f_40">
                  {translation.patient_adolescent_knee_pain}
                </h4>
              ) : (
                <h4 className="ph_title f_40">
                  {translation.patient_adult_knee_pain}
                </h4>
              )}
              <div className="row pt_20">
                <div className="col-md-12 col-sm-12">
                  {/* <div className={`mb_20 ${evlAdultKnee === "3" ? "col-sm-6 col-12" : ""}`}>
                    <FullDropdown
                      data={[
                        {
                          value: "1",
                          label: "Right",
                        },
                        {
                          value: "2",
                          label: "Left",
                        },
                        {
                          value: "3",
                          label: "Both",
                        },
                      ]}
                      label="Evaluated Knee"
                      styleClass=""
                      value={evlAdultKnee}
                      onChange={handleEvolutedAdultKnee}
                    />
                  </div> */}
                  <div className="row">
                    {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                      <div
                        className={
                          `mb_20 ${evlAdultKnee === "3" ? "col-sm-6 col-12" : ""
                          }` + RightSide()
                        }
                      >
                        <FullDropdown
                          data={[
                            {
                              value: "4",
                              label: translation.patient_adult_knee_pain_q1_a1,
                            },
                            {
                              value: "3",
                              label: translation.patient_adult_knee_pain_q1_a2,
                            },
                            {
                              value: "2",
                              label: translation.patient_adult_knee_pain_q1_a3,
                            },
                            {
                              value: "1",
                              label: translation.patient_adult_knee_pain_q1_a4,
                            },
                            {
                              value: "0",
                              label: translation.patient_adult_knee_pain_q1_a5,
                            },
                          ]}
                          label={
                            translation.patient_adult_knee_pain_q1 +
                            RigthTrans(evlAdultKnee, translation)
                          }
                          styleClass=""
                          value={describePainR}
                          placeholder={translation.none}
                          onChange={handleDescribePainR}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                      <div
                        className={
                          `mb_20 ${evlAdultKnee === "3" ? "col-sm-6 col-12" : ""
                          }` + LeftSide()
                        }
                      >
                        <FullDropdown
                          data={[
                            {
                              value: "4",
                              label: translation.patient_adult_knee_pain_q1_a1,
                            },
                            {
                              value: "3",
                              label: translation.patient_adult_knee_pain_q1_a2,
                            },
                            {
                              value: "2",
                              label: translation.patient_adult_knee_pain_q1_a3,
                            },
                            {
                              value: "1",
                              label: translation.patient_adult_knee_pain_q1_a4,
                            },
                            {
                              value: "0",
                              label: translation.patient_adult_knee_pain_q1_a5,
                            },
                          ]}
                          label={
                            translation.patient_adult_knee_pain_q1 +
                            LeftTrans(evlAdultKnee, translation)
                          }
                          styleClass=""
                          value={describePainL}
                          placeholder={translation.none}
                          onChange={handleDescribePainL}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="row">
                    {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                      <div
                        className={
                          `mb_30 ${evlAdultKnee === "3" ? "col-sm-6 col-12" : ""
                          } ` + RightSide()
                        }
                      >
                        <RangeSlider
                          kneeSlider="true"
                          id="painTodayRatingR"
                          min="0"
                          max="10"
                          step="1"
                          type="text"
                          styleClass="w_90"
                          // leftTitle="Never"
                          // rightTitle="Constant"
                          label={
                            translation.patient_adult_knee_pain_q2 +
                            RigthTrans(evlAdultKnee, translation)
                          }
                          value={painTodayRatingR}
                          onChange={handlePainTodayRatingR}
                          sliderData={
                            painTodayRatingR === "0"
                              ? translation.patient_adult_knee_pain_q2_a1
                              : painTodayRatingR === "1" ||
                                painTodayRatingR === "2" ||
                                painTodayRatingR === "3"
                                ? translation.patient_adult_knee_pain_q2_a2
                                : painTodayRatingR === "4" ||
                                  painTodayRatingR === "5" ||
                                  painTodayRatingR === "6"
                                  ? translation.patient_adult_knee_pain_q2_a3
                                  : painTodayRatingR === "7" ||
                                    painTodayRatingR === "8" ||
                                    painTodayRatingR === "9"
                                    ? translation.patient_adult_knee_pain_q2_a4
                                    : painTodayRatingR === "10"
                                      ? translation.patient_adult_knee_pain_q2_a5
                                      : ""
                          }
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                      <div
                        className={
                          `mb_30 ${evlAdultKnee === "3" ? "col-sm-6 col-12" : ""
                          }` + LeftSide()
                        }
                      >
                        <RangeSlider
                          id="painTodayRatingL"
                          kneeSlider="true"
                          min="0"
                          max="10"
                          step="1"
                          type="text"
                          styleClass="w_90"
                          // leftTitle="Never"
                          // rightTitle="Constant"
                          label={
                            translation.patient_adult_knee_pain_q2 +
                            LeftTrans(evlAdultKnee, translation)
                          }
                          value={painTodayRatingL}
                          onChange={handlePainTodayRatingL}
                          sliderData={
                            painTodayRatingL === "0"
                              ? translation.patient_adult_knee_pain_q2_a1
                              : painTodayRatingL === "1" ||
                                painTodayRatingL === "2" ||
                                painTodayRatingL === "3"
                                ? translation.patient_adult_knee_pain_q2_a2
                                : painTodayRatingL === "4" ||
                                  painTodayRatingL === "5" ||
                                  painTodayRatingL === "6"
                                  ? translation.patient_adult_knee_pain_q2_a3
                                  : painTodayRatingL === "7" ||
                                    painTodayRatingL === "8" ||
                                    painTodayRatingL === "9"
                                    ? translation.patient_adult_knee_pain_q2_a4
                                    : painTodayRatingL === "10"
                                      ? translation.patient_adult_knee_pain_q2_a5
                                      : ""
                          }
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="row mt-5">
                    {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                      <div
                        className={
                          `mb_20 ${evlAdultKnee === "3" ? "col-sm-6 col-12" : ""
                          }` + RightSide()
                        }
                      >
                        <FullDropdown
                          data={[
                            {
                              value: "5",
                              label: translation.patient_adult_knee_pain_q3_a1,
                            },
                            {
                              value: "4",
                              label: translation.patient_adult_knee_pain_q3_a2,
                            },
                            {
                              value: "3",
                              label: translation.patient_adult_knee_pain_q3_a4,
                            },
                            {
                              value: "2",
                              label: translation.patient_adult_knee_pain_q3_a3,
                            },
                            {
                              value: "1",
                              label: translation.patient_adult_knee_pain_q3_a5,
                            },
                            {
                              value: "0",
                              label: translation.patient_adult_knee_pain_q3_a6,
                            },
                          ]}
                          label={
                            translation.patient_adult_knee_pain_q3 +
                            RigthTrans(evlAdultKnee, translation)
                          }
                          value={bestStatmentForPainR}
                          placeholder={translation.none}
                          onChange={handleBestStatmentForPainR}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                      <div
                        className={
                          `mb_20 ${evlAdultKnee === "3" ? "col-sm-6 col-12" : ""
                          }` + LeftSide()
                        }
                      >
                        <FullDropdown
                          data={[
                            {
                              value: "5",
                              label: translation.patient_adult_knee_pain_q3_a1,
                            },
                            {
                              value: "4",
                              label: translation.patient_adult_knee_pain_q3_a2,
                            },
                            {
                              value: "3",
                              label: translation.patient_adult_knee_pain_q3_a4,
                            },
                            {
                              value: "2",
                              label: translation.patient_adult_knee_pain_q3_a3,
                            },
                            {
                              value: "1",
                              label: translation.patient_adult_knee_pain_q3_a5,
                            },
                            {
                              value: "0",
                              label: translation.patient_adult_knee_pain_q3_a6,
                            },
                          ]}
                          label={
                            translation.patient_adult_knee_pain_q3 +
                            LeftTrans(evlAdultKnee, translation)
                          }
                          value={bestStatmentForPainL}
                          placeholder={translation.none}
                          onChange={handleBestStatmentForPainL}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="row">
                    {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                      <div
                        className={
                          `mb_30 ${evlAdultKnee === "3" ? "col-sm-6 col-12" : ""
                          }` + RightSide()
                        }
                      >
                        <RangeSlider
                          id="howSevereR"
                          min="0"
                          max="10"
                          step="1"
                          type="both"
                          styleClass="w_90"
                          // leftTitle="No Pain"
                          // rightTitle="Worst Pain"
                          kneeSlider_severe_none="true"
                          label={
                            translation.patient_adult_knee_pain_q4 +
                            RigthTrans(evlAdultKnee, translation)
                          }
                          value={howSevereR}
                          onChange={handleHowSevereR}
                          sliderData={
                            howSevereR === "0"
                              ? translation.patient_adult_knee_pain_q4_a1
                              : howSevereR === "1"
                                ? translation.patient_adult_knee_pain_q4_a2
                                : howSevereR === "2" || howSevereR === "3"
                                  ? translation.patient_adult_knee_pain_q4_a3
                                  : howSevereR === "4" ||
                                    howSevereR === "5" ||
                                    howSevereR === "6"
                                    ? translation.patient_adult_knee_pain_q4_a4
                                    : howSevereR === "7" ||
                                      howSevereR === "8" ||
                                      howSevereR === "9" ||
                                      howSevereR === "10"
                                      ? translation.patient_adult_knee_pain_q4_a5
                                      : ""
                          }
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                      <div
                        className={
                          `mb_30 ${evlAdultKnee === "3" ? "col-sm-6 col-12" : ""
                          }` + LeftSide()
                        }
                      >
                        <RangeSlider
                          id="howSevereL"
                          min="0"
                          max="10"
                          step="1"
                          type="both"
                          styleClass="w_90"
                          // leftTitle="No Pain"
                          // rightTitle="Worst Pain"
                          kneeSlider_severe_none="true"
                          label={
                            translation.patient_adult_knee_pain_q4 +
                            LeftTrans(evlAdultKnee, translation)
                          }
                          value={howSevereL}
                          onChange={handleHowSevereL}
                          sliderData={
                            howSevereL === "0"
                              ? translation.patient_adult_knee_pain_q4_a1
                              : howSevereL === "1"
                                ? translation.patient_adult_knee_pain_q4_a2
                                : howSevereL === "2" || howSevereL === "3"
                                  ? translation.patient_adult_knee_pain_q4_a3
                                  : howSevereL === "4" ||
                                    howSevereL === "5" ||
                                    howSevereL === "6"
                                    ? translation.patient_adult_knee_pain_q4_a4
                                    : howSevereL === "7" ||
                                      howSevereL === "8" ||
                                      howSevereL === "9" ||
                                      howSevereL === "10"
                                      ? translation.patient_adult_knee_pain_q4_a5
                                      : ""
                          }
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="row mt-5">
                    {String(age) && String(age) !== "1" ? (
                      <>
                        {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                          <div
                            className={
                              `mb_20 ${evlAdultKnee === "3" ? "col-sm-6 col-12" : ""
                              }` + RightSide()
                            }
                          >
                            <KneeScoreRangeSlider
                              label={
                                translation.patient_adult_knee_pain_q5 +
                                RigthTrans(evlAdultKnee, translation)
                              }
                              value={kneeInterferedR}
                              placeholder={translation.none}
                              onChange={handleKneeInterferedR}
                              sliderData={[
                                {
                                  score: "0",
                                  label:
                                    translation.patient_adult_knee_pain_q5_a1,
                                },
                                {
                                  score: "1",
                                  label:
                                    translation.patient_adult_knee_pain_q5_a2,
                                },
                                {
                                  score: "2",
                                  label:
                                    translation.patient_adult_knee_pain_q5_a3,
                                },
                                {
                                  score: "3",
                                  label:
                                    translation.patient_adult_knee_pain_q5_a4,
                                },
                                {
                                  score: "4",
                                  label:
                                    translation.patient_adult_knee_pain_q5_a5,
                                },
                              ]}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                          <div
                            className={
                              `mb_20 ${evlAdultKnee === "3" ? "col-sm-6 col-12" : ""
                              }` + LeftSide()
                            }
                          >
                            <KneeScoreRangeSlider
                              label={
                                translation.patient_adult_knee_pain_q5 +
                                LeftTrans(evlAdultKnee, translation)
                              }
                              value={kneeInterferedL}
                              placeholder={translation.none}
                              onChange={handleKneeInterferedL}
                              sliderData={[
                                {
                                  score: "0",
                                  label:
                                    translation.patient_adult_knee_pain_q5_a1,
                                },
                                {
                                  score: "1",
                                  label:
                                    translation.patient_adult_knee_pain_q5_a2,
                                },
                                {
                                  score: "2",
                                  label:
                                    translation.patient_adult_knee_pain_q5_a3,
                                },
                                {
                                  score: "3",
                                  label:
                                    translation.patient_adult_knee_pain_q5_a4,
                                },
                                {
                                  score: "4",
                                  label:
                                    translation.patient_adult_knee_pain_q5_a5,
                                },
                              ]}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="function_formtop">
                <div className="row">
                  <div className="col-md-6 col-sm-6 function_head">
                    <label>
                      {translation.patient_adult_knee_pain_instruction}
                    </label>
                    <div>
                      <span dir={lg === "ara" ? "rtl" : " "}>
                        (<strong>0</strong> ={" "}
                        {translation.patient_adult_knee_pain_instruction_0},{" "}
                        <strong>1</strong> ={" "}
                        {translation.patient_adult_knee_pain_instruction_1},{" "}
                        <strong>2</strong> ={" "}
                        {translation.patient_adult_knee_pain_instruction_2},{" "}
                        <strong>3</strong> ={" "}
                        {translation.patient_adult_knee_pain_instruction_3},{" "}
                        <strong>4</strong> ={" "}
                        {translation.patient_adult_knee_pain_instruction_4})
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <strong className="d-inline-block mb_15">
                        {translation.Right_Knee}
                      </strong>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_pain_q6 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={twistingR}
                        onChange={handleTwistingR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_adult_knee_pain_q6_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_adult_knee_pain_q6_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_adult_knee_pain_q6_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_adult_knee_pain_q6_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_adult_knee_pain_q6_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <strong className="d-inline-block mb_15">
                        {translation.Left_Knee}
                      </strong>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_pain_q6 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={twistingL}
                        onChange={handleTwistingL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_adult_knee_pain_q6_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_adult_knee_pain_q6_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_adult_knee_pain_q6_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_adult_knee_pain_q6_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_adult_knee_pain_q6_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_pain_q7 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={straighteningR}
                        onChange={handleStraighteningR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_adult_knee_pain_q7_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_adult_knee_pain_q7_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_adult_knee_pain_q7_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_adult_knee_pain_q7_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_adult_knee_pain_q7_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_pain_q7 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={straighteningL}
                        onChange={handleStraighteningL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_adult_knee_pain_q7_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_adult_knee_pain_q7_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_adult_knee_pain_q7_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_adult_knee_pain_q7_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_adult_knee_pain_q7_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_pain_q8 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={bendingR}
                        onChange={handleBendingR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_adult_knee_pain_q8_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_adult_knee_pain_q8_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_adult_knee_pain_q8_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_adult_knee_pain_q8_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_adult_knee_pain_q8_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_pain_q8 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={bendingL}
                        onChange={handleBendingL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_adult_knee_pain_q8_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_adult_knee_pain_q8_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_adult_knee_pain_q8_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_adult_knee_pain_q8_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_adult_knee_pain_q8_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_pain_q9 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={walkingSurfaceR}
                        onChange={handleWalkingSurfaceR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_adult_knee_pain_q9_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_adult_knee_pain_q9_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_adult_knee_pain_q9_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_adult_knee_pain_q9_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_adult_knee_pain_q9_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_pain_q9 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={walkingSurfaceL}
                        onChange={handleWalkingSurfaceL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_adult_knee_pain_q9_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_adult_knee_pain_q9_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_adult_knee_pain_q9_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_adult_knee_pain_q9_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_adult_knee_pain_q9_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {String(age) && String(age) !== "1" ? (
                  <div className="row">
                    {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                        <FullDropdown
                          data={[
                            {
                              value: "4",
                              label: translation.patient_adult_knee_pain_q10_a1,
                            },
                            {
                              value: "3",
                              label: translation.patient_adult_knee_pain_q10_a2,
                            },
                            {
                              value: "2",
                              label: translation.patient_adult_knee_pain_q10_a3,
                            },
                            {
                              value: "1",
                              label: translation.patient_adult_knee_pain_q10_a4,
                            },
                            {
                              value: "0",
                              label: translation.patient_adult_knee_pain_q10_a5,
                            },
                          ]}
                          label={
                            translation.patient_adult_knee_pain_q10 +
                            RigthTrans(evlAdultKnee, translation)
                          }
                          styleClass=""
                          value={howLongWalkR}
                          placeholder={translation.none}
                          onChange={handleHowLongWalkR}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                        <FullDropdown
                          data={[
                            {
                              value: "4",
                              label: translation.patient_adult_knee_pain_q10_a1,
                            },
                            {
                              value: "3",
                              label: translation.patient_adult_knee_pain_q10_a2,
                            },
                            {
                              value: "2",
                              label: translation.patient_adult_knee_pain_q10_a3,
                            },
                            {
                              value: "1",
                              label: translation.patient_adult_knee_pain_q10_a4,
                            },
                            {
                              value: "0",
                              label: translation.patient_adult_knee_pain_q10_a5,
                            },
                          ]}
                          label={
                            translation.patient_adult_knee_pain_q10 +
                            LeftTrans(evlAdultKnee, translation)
                          }
                          styleClass=""
                          value={howLongWalkL}
                          placeholder={translation.none}
                          onChange={handleHowLongWalkL}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <></>
                )}

                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_pain_q11 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={useStepsR}
                        onChange={handleUseStepsR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_adult_knee_pain_q11_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_adult_knee_pain_q11_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_adult_knee_pain_q11_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_adult_knee_pain_q11_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_adult_knee_pain_q11_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_pain_q11 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={useStepsL}
                        onChange={handleUseStepsL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_adult_knee_pain_q11_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_adult_knee_pain_q11_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_adult_knee_pain_q11_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_adult_knee_pain_q11_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_adult_knee_pain_q11_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_pain_q12 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={whileBedR}
                        onChange={handleWhileBedR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_adult_knee_pain_q12_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_adult_knee_pain_q12_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_adult_knee_pain_q12_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_adult_knee_pain_q12_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_adult_knee_pain_q12_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_pain_q12 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={whileBedL}
                        onChange={handleWhileBedL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_adult_knee_pain_q12_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_adult_knee_pain_q12_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_adult_knee_pain_q12_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_adult_knee_pain_q12_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_adult_knee_pain_q12_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {String(age) && String(age) !== "1" ? (
                  <div className="row">
                    {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                        <KneeScoreRangeSlider
                          label={
                            translation.patient_adult_knee_pain_q13 +
                            RigthTrans(evlAdultKnee, translation)
                          }
                          styleClass=""
                          value={painAtNightR}
                          placeholder={translation.none}
                          onChange={handlePainAtNightR}
                          sliderData={[
                            {
                              score: "0",
                              label: translation.patient_adult_knee_pain_q13_a1,
                            },
                            {
                              score: "1",
                              label: translation.patient_adult_knee_pain_q13_a2,
                            },
                            {
                              score: "2",
                              label: translation.patient_adult_knee_pain_q13_a3,
                            },
                            {
                              score: "3",
                              label: translation.patient_adult_knee_pain_q13_a4,
                            },
                            {
                              score: "4",
                              label: translation.patient_adult_knee_pain_q13_a5,
                            },
                          ]}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                        <KneeScoreRangeSlider
                          label={
                            translation.patient_adult_knee_pain_q13 +
                            LeftTrans(evlAdultKnee, translation)
                          }
                          styleClass=""
                          value={painAtNightL}
                          placeholder={translation.none}
                          onChange={handlePainAtNightL}
                          sliderData={[
                            {
                              score: "0",
                              label: translation.patient_adult_knee_pain_q13_a1,
                            },
                            {
                              score: "1",
                              label: translation.patient_adult_knee_pain_q13_a2,
                            },
                            {
                              score: "2",
                              label: translation.patient_adult_knee_pain_q13_a3,
                            },
                            {
                              score: "3",
                              label: translation.patient_adult_knee_pain_q13_a4,
                            },
                            {
                              score: "4",
                              label: translation.patient_adult_knee_pain_q13_a5,
                            },
                          ]}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <></>
                )}

                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_pain_q14 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={sittingR}
                        onChange={handleSittingR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_adult_knee_pain_q14_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_adult_knee_pain_q14_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_adult_knee_pain_q14_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_adult_knee_pain_q14_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_adult_knee_pain_q14_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_pain_q14 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={sittingL}
                        onChange={handleSittingL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_adult_knee_pain_q14_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_adult_knee_pain_q14_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_adult_knee_pain_q14_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_adult_knee_pain_q14_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_adult_knee_pain_q14_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_pain_q15 +
                          RigthTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={standingR}
                        onChange={handleStandingR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_adult_knee_pain_q15_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_adult_knee_pain_q15_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_adult_knee_pain_q15_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_adult_knee_pain_q15_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_adult_knee_pain_q15_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_adult_knee_pain_q15 +
                          LeftTrans(evlAdultKnee, translation)
                        }
                        classes=""
                        value={standingL}
                        onChange={handleStandingL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_adult_knee_pain_q15_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_adult_knee_pain_q15_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_adult_knee_pain_q15_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_adult_knee_pain_q15_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_adult_knee_pain_q15_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {String(age) && String(age) !== "1" ? (
                  <div className="row">
                    {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                        <KneeScoreRangeSlider
                          label={
                            translation.patient_adult_knee_pain_q16 +
                            RigthTrans(evlAdultKnee, translation)
                          }
                          classes=""
                          value={standingFromChairR}
                          onChange={handleStandingFromChairR}
                          sliderData={[
                            {
                              score: "0",
                              label: translation.patient_adult_knee_pain_q16_a1,
                            },
                            {
                              score: "1",
                              label: translation.patient_adult_knee_pain_q16_a2,
                            },
                            {
                              score: "2",
                              label: translation.patient_adult_knee_pain_q16_a3,
                            },
                            {
                              score: "3",
                              label: translation.patient_adult_knee_pain_q16_a4,
                            },
                            {
                              score: "4",
                              label: translation.patient_adult_knee_pain_q16_a5,
                            },
                          ]}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                      <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                        <KneeScoreRangeSlider
                          label={
                            translation.patient_adult_knee_pain_q16 +
                            LeftTrans(evlAdultKnee, translation)
                          }
                          classes=""
                          value={standingFromChairL}
                          onChange={handleStandingFromChairL}
                          sliderData={[
                            {
                              score: "0",
                              label: translation.patient_adult_knee_pain_q16_a1,
                            },
                            {
                              score: "1",
                              label: translation.patient_adult_knee_pain_q16_a2,
                            },
                            {
                              score: "2",
                              label: translation.patient_adult_knee_pain_q16_a3,
                            },
                            {
                              score: "3",
                              label: translation.patient_adult_knee_pain_q16_a4,
                            },
                            {
                              score: "4",
                              label: translation.patient_adult_knee_pain_q16_a5,
                            },
                          ]}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6"></div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <button
                      className="next_btn btn_fill me-3"
                      onClick={() => handleNext()}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}
              {doctorEnable !== "true" && viewicon !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    {backDoc != 1 ? <Link
                      to={`/patient-personal-data/${window.btoa(
                        backD.ak_pt
                      )}/${window.btoa(backD.type)}?r=${window.btoa(rId)}`}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link> : <Link
                      to={`/personal-data/${window.btoa(
                        backD.ak_pt
                      )}/${window.btoa(backD.ak_dc)}/${window.btoa(
                        backD.org_id
                      )}/${window.btoa(backD.type)}?is_doctor_form=${isdoctorForm}&doctorvalue=0&r=${window.btoa(rId)}`}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>}


                    {/* <span onClick={()=>Back()} className="assm_back_btn c_pointer">
                    <img src={backIcon} alt="" />
                    <span>{translation.back_button}</span>
                  </span> */}
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
              {viewicon === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-12 col-sm-12 col-12 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AdultKneePain;
