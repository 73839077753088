import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import RangeSlider from "../../../custom/range-slider/RangeSlider";
import Dropdown from "../../../custom/dropdown/Dropdown";
import HandWristTab from "../../tab-section/HandWristTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import {
  updateHandWristSymptomsFieldScore,
  updateHandWristSymptomsScores,
  updateHandWristPainScores,
  updateHandWristScores,
  updateHandWristfunctionScores,
  updateHandWristqolScores,
  updateHandWristsatisfactionScores,
  updateHandWristFeedbackScores,
  updateHandWristUramScores,
  updateHandWristbctqScores,
  updateAllData,
} from "../../../../redux/actions/handWrist";
import useAxios from "../../../../axiosinstance";
import useTranslation from "../../../customHooks/translations";
import KneeScoreRangeSlider from "../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import {
  LeftSide,
  LeftTrans,
  RightSide,
  RigthTrans,
} from "../../../../utilities/RightLeftFormat";
import NameCustom from "../../../custom/name/NameCustom";

function HandWristSymptoms() {
  const translation = useTranslation();

  const dispatch = useDispatch();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const [severeR, setSevereR] = useState("0");
  const [severeL, setSevereL] = useState("0");
  const [sensationR, setSensationR] = useState("0");
  const [sensationL, setSensationL] = useState("0");
  const [apperanceR, setApperanceR] = useState("0");
  const [apperanceL, setApperanceL] = useState("0");
  const [percent, setPercent] = useState("0");
  const [percentL, setPercentL] = useState("0");
  const [isLoading, setIsLoading] = useState(true);
  const [chk, setChk] = useState(false);
  const [updateTotal, setUpdateTotal] = useState(false);
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=23`)
      .then((res) => {
        // console.log(res.data.data2);
        dispatch(updateHandWristPainScores(res.data.data2[0].painScore));
        dispatch(
          updateHandWristSymptomsScores(res.data.data2[0].symptomsScore)
        );
        dispatch(updateHandWristScores(res.data.data2[0].evaluated));
        dispatch(
          updateHandWristfunctionScores(res.data.data2[0].functionScore)
        );
        dispatch(updateHandWristqolScores(res.data.data2[0].qolScore));
        dispatch(updateHandWristUramScores(res.data.data2[0].uramScore));
        dispatch(updateHandWristbctqScores(res.data.data2[0].bctq));

        dispatch(
          updateHandWristsatisfactionScores(res.data.data2[0].satisfactionScore)
        );
        dispatch(
          updateHandWristFeedbackScores(res.data.data2[0].doctorFeedback)
        );
        setChk(true);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };

  useEffect(() => {
    save();
  }, []);
  const HandSymptoms = useSelector((state) => state.handWrist.symptomsScore[0]);
  const json = useSelector((state) => state.handWrist);

  useEffect(() => {
    setSevereR(HandSymptoms.severeR);
    setSevereL(HandSymptoms.severeL);
    setSensationR(HandSymptoms.sensationR);
    setSensationL(HandSymptoms.sensationL);
    setApperanceR(HandSymptoms.apperanceR);
    setApperanceL(HandSymptoms.apperanceL);
    setPercent(HandSymptoms.percent);
    setPercentL(HandSymptoms.percentL);
  }, [HandSymptoms]);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${
          doctorEnable === "true" ? "1" : "0"
        }`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [HandSymptoms, updateTotal]);

  const navigate = useNavigate();
  const evaluated = useSelector((state) => state.handWrist.evaluated);

  const handleSevereR = (e) => {
    setSevereR(e);
    dispatch(
      updateHandWristSymptomsFieldScore({
        key: "severeR",
        value: e,
      })
    );
  };
  const handleSevereL = (e) => {
    setSevereL(e);
    dispatch(
      updateHandWristSymptomsFieldScore({
        key: "severeL",
        value: e,
      })
    );
  };
  const handleSensationR = (e) => {
    setSensationR(e);
    dispatch(
      updateHandWristSymptomsFieldScore({
        key: "sensationR",
        value: e,
      })
    );
  };
  const handleSensationL = (e) => {
    setSensationL(e);
    dispatch(
      updateHandWristSymptomsFieldScore({
        key: "sensationL",
        value: e,
      })
    );
  };
  const handleApperanceR = (e) => {
    setApperanceR(e);
    dispatch(
      updateHandWristSymptomsFieldScore({
        key: "apperanceR",
        value: e,
      })
    );
  };
  const handleApperanceL = (e) => {
    setApperanceL(e);
    dispatch(
      updateHandWristSymptomsFieldScore({
        key: "apperanceL",
        value: e,
      })
    );
  };
  const handlePercent = (e) => {
    setPercent(e);
    dispatch(
      updateHandWristSymptomsFieldScore({
        key: "percent",
        value: e,
      })
    );
  };
  const handlePercentL = (e) => {
    setPercentL(e);
    dispatch(
      updateHandWristSymptomsFieldScore({
        key: "percentL",
        value: e,
      })
    );
  };
  const handleEvoluted = (e) => {
    dispatch(updateHandWristScores(e));
  };
  const continueRedirect = (e) => {
    dispatch(
      updateHandWristSymptomsScores([
        {
          severeR: severeR,
          severeL: severeL,
          sensationR: sensationR,
          sensationL: sensationL,
          apperanceR: apperanceR,
          apperanceL: apperanceL,
          percent: percent,
          percentL: percentL,
        },
      ])
    );
    navigate("/hand-wrist-function/" + rId);
  };

  let akpt = searchParams.get("akpt");
  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update);
    setmsgType("success");
    setmsgType("success");
     setTimeout(() => {
     let view_circular=  sessionStorage.getItem("view_circular")
      // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
      // window.location.href = process.env.REACT_APP_DOCTOR_URL +`view-management-plan/${akpt}/${window.btoa(
      //   rId
      // )}/?org=${window.btoa(sessionStorage.getItem("orgid")||'')}&formType=${
      //   window.btoa('23') || ""
      // }&view_circular=${view_circular==='false'?'false':'true'}`
      window.location.href = process.env.REACT_APP_DOCTOR_URL +`patient-details/${akpt}?formid=23`
      setMsg('')
      setmsgType('success')
    }, 2000);
  };
  const handleNext=()=>{
    navigate(`/hand-wrist-function/${rId}?d=true&akpt=${akpt}`);

  }
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  let doctordata = localStorage.getItem("isdoctorForm");
  let viewicon = localStorage.getItem("viewicon");
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
          <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <HandWristTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />

              <h4 className="ph_title f_40">
                {translation.patient_hand_symptoms}
              </h4>

              <div className="row mt-5">
                {evaluated === "1" || evaluated === "3" ? (
                  <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                    <KneeScoreRangeSlider
                      label={
                        translation.patient_hand_symptoms_q1 +
                        RigthTrans(evaluated, translation)
                      }
                      styleClass=""
                      value={severeR}
                      onChange={handleSevereR}
                      sliderData={[
                        {
                          score: "0",
                          label: translation.patient_hand_symptoms_q1_a1,
                        },
                        {
                          score: "1",
                          label: translation.patient_hand_symptoms_q1_a2,
                        },
                        {
                          score: "2",
                          label: translation.patient_hand_symptoms_q1_a3,
                        },
                        {
                          score: "3",
                          label: translation.patient_hand_symptoms_q1_a4,
                        },
                        {
                          score: "4",
                          label: translation.patient_hand_symptoms_q1_a5,
                        },
                      ]}
                    />
                  </div>
                ) : (
                  ""
                )}
                {evaluated === "2" || evaluated === "3" ? (
                  <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                    <KneeScoreRangeSlider
                      label={
                        translation.patient_hand_symptoms_q1 +
                        LeftTrans(evaluated, translation)
                      }
                      styleClass=""
                      value={severeL}
                      onChange={handleSevereL}
                      sliderData={[
                        {
                          score: "0",
                          label: translation.patient_hand_symptoms_q1_a1,
                        },
                        {
                          score: "1",
                          label: translation.patient_hand_symptoms_q1_a2,
                        },
                        {
                          score: "2",
                          label: translation.patient_hand_symptoms_q1_a3,
                        },
                        {
                          score: "3",
                          label: translation.patient_hand_symptoms_q1_a4,
                        },
                        {
                          score: "4",
                          label: translation.patient_hand_symptoms_q1_a5,
                        },
                      ]}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="row">
                {evaluated === "1" || evaluated === "3" ? (
                  <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                    <KneeScoreRangeSlider
                      label={
                        translation.patient_hand_symptoms_q2 +
                        RigthTrans(evaluated, translation)
                      }
                      styleClass=""
                      value={sensationR}
                      onChange={handleSensationR}
                      sliderData={[
                        {
                          score: "0",
                          label: translation.patient_hand_symptoms_q2_a1,
                        },
                        {
                          score: "1",
                          label: translation.patient_hand_symptoms_q2_a2,
                        },
                        {
                          score: "2",
                          label: translation.patient_hand_symptoms_q2_a3,
                        },
                        {
                          score: "3",
                          label: translation.patient_hand_symptoms_q2_a4,
                        },
                        {
                          score: "4",
                          label: translation.patient_hand_symptoms_q2_a5,
                        },
                      ]}
                    />
                  </div>
                ) : (
                  ""
                )}
                {evaluated === "2" || evaluated === "3" ? (
                  <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                    <KneeScoreRangeSlider
                      label={
                        translation.patient_hand_symptoms_q2 +
                        LeftTrans(evaluated, translation)
                      }
                      styleClass=""
                      value={sensationL}
                      onChange={handleSensationL}
                      sliderData={[
                        {
                          score: "0",
                          label: translation.patient_hand_symptoms_q2_a1,
                        },
                        {
                          score: "1",
                          label: translation.patient_hand_symptoms_q2_a2,
                        },
                        {
                          score: "2",
                          label: translation.patient_hand_symptoms_q2_a3,
                        },
                        {
                          score: "3",
                          label: translation.patient_hand_symptoms_q2_a4,
                        },
                        {
                          score: "4",
                          label: translation.patient_hand_symptoms_q2_a5,
                        },
                      ]}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="row">
                {evaluated === "1" || evaluated === "3" ? (
                  <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                    <KneeScoreRangeSlider
                      label={
                        translation.patient_hand_symptoms_q3 +
                        RigthTrans(evaluated, translation)
                      }
                      styleClass=""
                      value={apperanceR}
                      onChange={handleApperanceR}
                      sliderData={[
                        {
                          score: "0",
                          label: translation.patient_hand_symptoms_q3_a1,
                        },
                        {
                          score: "1",
                          label: translation.patient_hand_symptoms_q3_a2,
                        },
                        {
                          score: "2",
                          label: translation.patient_hand_symptoms_q3_a3,
                        },
                        {
                          score: "3",
                          label: translation.patient_hand_symptoms_q3_a4,
                        },
                        {
                          score: "4",
                          label: translation.patient_hand_symptoms_q3_a5,
                        },
                      ]}
                    />
                  </div>
                ) : (
                  ""
                )}
                {evaluated === "2" || evaluated === "3" ? (
                  <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                    <KneeScoreRangeSlider
                      label={
                        translation.patient_hand_symptoms_q3 +
                        LeftTrans(evaluated, translation)
                      }
                      styleClass=""
                      value={apperanceL}
                      onChange={handleApperanceL}
                      sliderData={[
                        {
                          score: "0",
                          label: translation.patient_hand_symptoms_q3_a1,
                        },
                        {
                          score: "1",
                          label: translation.patient_hand_symptoms_q3_a2,
                        },
                        {
                          score: "2",
                          label: translation.patient_hand_symptoms_q3_a3,
                        },
                        {
                          score: "3",
                          label: translation.patient_hand_symptoms_q3_a4,
                        },
                        {
                          score: "4",
                          label: translation.patient_hand_symptoms_q3_a5,
                        },
                      ]}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="row mt-5">
                {evaluated === "2" || evaluated === "3" ? (
                  <div className="mb_20">
                    <RangeSlider
                      id="percent"
                      min="0"
                      max="100"
                      step="1"
                      rightTitle={
                        translation.patient_hand_symptoms_q3_a6_normal
                      }
                      type="both1"
                      colorcode="1"
                      label={
                        translation.patient_hand_symptoms_q3_a6 +
                        LeftTrans(evaluated, translation)
                      }
                      value={percentL}
                      onChange={handlePercentL}
                    />
                  </div>
                ) : (
                  ""
                )}
                {evaluated === "1" || evaluated === "3" ? (
                  <div className="mb_20">
                    <RangeSlider
                      id="percent"
                      min="0"
                      max="100"
                      step="1"
                      type="both1"
                      rightTitle={
                        translation.patient_hand_symptoms_q3_a6_normal
                      }
                      colorcode="1"
                      label={
                        translation.patient_hand_symptoms_q3_a6 +
                        RigthTrans(evaluated, translation)
                      }
                      // label={translation.How_would_you_rate_your_hand_wrist_today_percentage_of_normal}
                      value={percent}
                      onChange={handlePercent}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>

              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6"></div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <button
                      className="next_btn btn_fill me-3"
                      onClick={() => handleNext()}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}
              {doctorEnable !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    <Link
                      to={
                        "/hand-and-wrist-pain/" +
                        rId +
                        `?is_doctor_form=${doctordata}&edit=${viewicon}`
                      }
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HandWristSymptoms;
