import React from "react";
import "../../../css/ViewTreatmentForm.css";
import Dropdown from "../../custom/dropdown/Dropdown";
import useTranslation from "../../customHooks/translations";
import { UserDateFormat } from "../../../utilities/DateFormate";
export default function HipPain({ hipPain, AllData,profileAllData }) {
  const translations = useTranslation();
  return (
    <div className="whitebox padding-none">
      <div className="treatment-header">
        <h2>{translations.Hip_Scoring_Self_Evaluation_Questionnaire}</h2>
        <div className="treatment-header-right">
          <p>
            {translations.Assessment_Date} :  {AllData.assessment_date}
            {/* {UserDateFormat(AllData.assessment_date,profileAllData.ing_date_formate) } */}
          </p>
        </div>
      </div>

      <div className="treatment-content">
        <div className="treatment-row-header">
          1. {translations.patient_hip_pain}
        </div>

        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_hip_pain_q1} ({translations.Right}):
            </label>
            <p>{hipPain.painTodayRatingR}</p>
          </div>
          <div className="treatment-col">
            <label>{translations.patient_hip_pain_q1} (Left):</label>
            <p>{hipPain.painTodayRatingL}</p>
          </div>
        </div>

        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_hip_pain_q2} ({translations.Right}):
            </label>
            <p>{hipPain.howBadPainRatingR}</p>
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_hip_pain_q2} ({translations.Left}):
            </label>
            <p>{hipPain.howBadPainRatingL}</p>
          </div>
        </div>

        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_hip_pain_q3} ({translations.Right}):
            </label>
            <p>
              {hipPain.describesR === "0"
                ? translations.patient_hip_pain_q3_a1
                : hipPain.describesR === "1"
                ? translations.patient_hip_pain_q3_a2
                : hipPain.describesR === "2"
                ? translations.patient_hip_pain_q3_a3
                : hipPain.describesR === "3"
                ? translations.patient_hip_pain_q3_a4
                : hipPain.describesR === "4"
                ? translations.patient_hip_pain_q3_a5
                : hipPain.describesR === "5"
                ? translations.patient_hip_pain_q3_a6
                : ""}
            </p>
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_hip_pain_q3} ({translations.Left}):
            </label>
            <p>
              {hipPain.describesL === "0"
                ? translations.patient_hip_pain_q3_a1
                : hipPain.describesL === "1"
                ? translations.patient_hip_pain_q3_a2
                : hipPain.describesL === "2"
                ? translations.patient_hip_pain_q3_a3
                : hipPain.describesL === "3"
                ? translations.patient_hip_pain_q3_a4
                : hipPain.describesL === "4"
                ? translations.patient_hip_pain_q3_a5
                : hipPain.describesL === "5"
                ? translations.patient_hip_pain_q3_a6
                : ""}
            </p>
          </div>
        </div>

        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_hip_pain_q4} ({translations.Right}):
            </label>
            <p> {hipPain.oftenR}</p>
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_hip_pain_q4} ({translations.Left}):
            </label>

            {/* { value: "4", label: "Never" },
              { value: "3", label: "Monthly" },
              { value: "2", label: "Weekly" },
              { value: "1", label: "Daily" },
              { value: "0", label: "Always" }, */}
            <p> {hipPain.oftenL}</p>
          </div>
        </div>
        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_hip_pain_q5} ({translations.Right}):
            </label>
            <p> {hipPain.interferedR}</p>
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_hip_pain_q5} ({translations.Left}):
            </label>
            <p> {hipPain.interferedL}</p>
          </div>
        </div>
        <div className="treatment-row">
          <div className="treatment-table">
            <table cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th></th>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <th>{translations.Hip_Function_R}</th>
                  {/* ) : (
                    ""
                  )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <th>{translations.Hip_Function_L}</th>
                  {/* // ) : (
                  //   ""
                  // )} */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{translations.patient_hip_pain_q6}:</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td> {hipPain.straighteningR}</td>

                  {/* ) : (
                    ""
                  )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <td> {hipPain.straighteningL}</td>
                  {/* // ) : (
                  //   ""
                  // )} */}
                </tr>
                <tr>
                  <td>{translations.patient_hip_pain_q7}:</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td>{hipPain.bendingR}</td>

                  {/* ) : (
                    ""
                  )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <td> {hipPain.bendingL}</td>
                  {/* // ) : (
                  //   ""
                  // )} */}
                </tr>
                <tr>
                  <td>{translations.patient_hip_pain_q8}:</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td> {hipPain.surfaceR}</td>

                  {/* ) : (
                    ""
                  )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <td> {hipPain.surfaceL}</td>
                  {/* // ) : (
                  //   ""
                  // )} */}
                </tr>
                <tr>
                  <td>{translations.patient_hip_pain_q9} :</td>

                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                  <td>
                    {hipPain.walkR === "0"
                      ? translations.patient_hip_pain_q9_a5
                      : hipPain.walkR === "1"
                      ? translations.patient_hip_pain_q9_a4
                      : hipPain.walkR === "2"
                      ? translations.patient_hip_pain_q9_a3
                      : hipPain.walkR === "3"
                      ? translations.patient_hip_pain_q9_a2
                      : hipPain.walkR === "4"
                      ? translations.patient_hip_pain_q9_a1
                      : ""}
                  </td>

                  {/* ) : (
                    ""
                  )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {" "}
                    {hipPain.walkL === "0"
                      ? translations.patient_hip_pain_q9_a5
                      : hipPain.walkL === "1"
                      ? translations.patient_hip_pain_q9_a4
                      : hipPain.walkL === "2"
                      ? translations.patient_hip_pain_q9_a3
                      : hipPain.walkL === "3"
                      ? translations.patient_hip_pain_q9_a2
                      : hipPain.walkL === "4"
                      ? translations.patient_hip_pain_q9_a1
                      : ""}
                  </td>
                  {/* // ) : (
                  //   ""
                  // )} */}
                </tr>
                <tr>
                  <td>{translations.patient_hip_pain_q10}:</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td>{hipPain.hardSurfaceR}</td>

                  {/* ) : (
                    ""
                  )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <td>{hipPain.hardSurfaceL}</td>

                  {/* // ) : (
                  //   ""
                  // )} */}
                </tr>
                <tr>
                  <td>{translations.patient_hip_pain_q11} :</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td>{hipPain.unevenSurfaceR}</td>

                  {/* ) : (
                    ""
                  )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <td> {hipPain.unevenSurfaceL}</td>
                  {/* // ) : (
                  //   ""
                  // )} */}
                </tr>
                <tr>
                  <td>{translations.patient_hip_pain_q12} :</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td> {hipPain.stairsR}</td>
                  {/* ) : (
                    ""
                  )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <td>{hipPain.stairsL}</td>

                  {/* // ) : (
                  //   ""
                  // )} */}
                </tr>
                <tr>
                  <td>{translations.patient_hip_pain_q13} :</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td> {hipPain.bedR}</td>
                  {/* ) : (
                    ""
                  )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <td> {hipPain.bedL}</td>

                  {/* // ) : (
                  //   ""
                  // )} */}
                </tr>
                <tr>
                  <td>{translations.patient_hip_pain_q14} :</td>
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  {/* <td> {hipPain.troubledL=== "0" ? "No nights" : hipPain.troubledL=== "1"  ? "Only 1 or 2 nights" : hipPain.troubledL=== "2" ? "Some nights" : hipPain.troubledL=== "3" ? "Most nights" : hipPain.troubledL=== "4" ? "Every night" :""}</td> */}
                  <td>{hipPain.troubledR}</td>
                  {/* // ) : (
                  //   ""
                  // )} */}
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  {/* <td> {hipPain.troubledR=== "0" ? "No nights" : hipPain.troubledR=== "1"  ? "Only 1 or 2 nights" : hipPain.troubledR=== "2" ? "Some nights" : hipPain.troubledR=== "3" ? "Most nights" : hipPain.troubledR=== "4" ? "Every night" :""}</td> */}
                  <td>{hipPain.troubledL}</td>
                  {/* ) : (
                    ""
                  )} */}
                </tr>
                <tr>
                  <td>{translations.patient_hip_pain_q15} :</td>
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                  <td>{hipPain.sittingR}</td>
                  {/* // ) : (
                  //   ""
                  // )} */}
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td>{hipPain.sittingL}</td>
                  {/* ) : (
                    ""
                  )} */}
                </tr>
                <tr>
                  <td>{translations.patient_hip_pain_q16} :</td>
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                  <td>{hipPain.uprightR}</td>
                  {/* // ) : (
                  //   ""
                  // )} */}
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td>{hipPain.uprightL}</td>
                  {/* ) : (
                    ""
                  )} */}
                </tr>
                <tr>
                  <td>{translations.patient_hip_pain_q17}:</td>
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                  <td>{hipPain.chairR}</td>
                  {/* // ) : (
                  //   ""
                  // )} */}
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td>{hipPain.chairL}</td>
                  {/* ) : (
                    ""
                  )} */}
                </tr>
                <tr>
                  <td>{translations.patient_hip_pain_q18}:</td>
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                  <td>{hipPain.activityR}</td>
                  {/* // ) : (
                  //   ""
                  // )} */}
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td>{hipPain.activityL}</td>
                  {/* ) : (
                    ""
                  )} */}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
