export const DateFormate = (item) => {
  return `${
    new Date(item).getDate() + 1 >= 10
      ? `${new Date(item).getDate()}`
      : `0${new Date(item).getDate()}`
  }/${
    new Date(item).getMonth() + 1 >= 10
      ? `${new Date(item).getMonth() + 1}`
      : `0${new Date(item).getMonth() + 1}`
  }/${new Date(item).getFullYear()}`;
};

export const yearDate = (item) => {
  return new Date().getFullYear() - new Date(item).getFullYear();
};

export const ScoreColor = (value, type) => {

  if (type) {
    if (value >= 1 && value <= 10) {
      return ["#42E0CE", "#48DEFF"];
    } else if (value >= 11 && value <= 20) {
      return ["#7AE292", "#42E0CE"];
    } else if (value >= 21 && value <= 30) {
      return ["#A4E363", "#7AE292"];
    } else if (value >= 31 && value <= 40) {
      return ["#C8E43C", "#A0E368"];
    } else if (value >= 41 && value <= 50) {
      return ["#F9E607", "#C8E43C"];
    } else if (value >= 51 && value <= 60) {
      return ["#FFD001", "#F9E607"];
    } else if (value >= 61 && value <= 70) {
      return ["#FFA501", "#FFD001"];
    } else if (value >= 71 && value <= 80) {
      return ["#FF7301", "#FFA501"];
    } else if (value >= 81 && value <= 90) {
      return ["#FF4601", "#FF7301"];
    } else if (value >= 91 && value <= 100) {
      return ["#FF0301", "#FF4601"];
    } else {
      return ["#42E0CE", "#48DEFF"];
    }
  } else {
    if (value >= 1 && value <= 10) {
      return ["#FF0301", "#FF4601"];
    } else if (value >= 11 && value <= 20) {
      return ["#FF4601", "#FF7301"];
    } else if (value >= 21 && value <= 30) {
      return ["#FF7301", "#FFA501"];
    } else if (value >= 31 && value <= 40) {
      return ["#FFA501", "#FFD001"];
    } else if (value >= 41 && value <= 50) {
      return ["#FFD001", "#F9E607"];
    } else if (value >= 51 && value <= 60) {
      return ["#F9E607", "#C8E43C"];
    } else if (value >= 61 && value <= 70) {
      return ["#C8E43C", "#A0E368"];
    } else if (value >= 71 && value <= 80) {
      return ["#A4E363", "#7AE292"];
    } else if (value >= 81 && value <= 90) {
      return ["#7AE292", "#42E0CE"];
    } else if (value >= 91 && value <= 100) {
      return ["#42E0CE", "#48DEFF"];
    } else {
      return ["#FF0301", "#FF4601"];
    }
  }
};

export const vasColor = (value, type) => {
  if (type) {
    if (value >= 0 && value <= 1) {
      return ["#FF0301", "#FF4601"];
    } else if (value >= 1 && value <= 2) {
      return ["#FF4601", "#FF7301"];
    } else if (value >= 2 && value <= 3) {
      return ["#FF7301", "#FFA501"];
    } else if (value >= 3 && value <= 4) {
      return ["#FFA501", "#FFD001"];
    } else if (value >= 4 && value <= 5) {
      return ["#FFD001", "#F9E607"];
    } else if (value >= 5 && value <= 6) {
      return ["#F9E607", "#C8E43C"];
    } else if (value >= 6 && value <= 7) {
      return ["#C8E43C", "#A0E368"];
    } else if (value >= 7 && value <= 8) {
      return ["#A4E363", "#7AE292"];
    } else if (value >= 8 && value <= 9) {
      return ["#7AE292", "#42E0CE"];
    } else if (value >= 9 && value <= 10) {
      return ["#42E0CE", "#48DEFF"];
    } else {
      return ["#FF0301", "#FF4601"];
    }
  } else {
    if (value >= 0 && value <= 1) {
      return ["#42E0CE", "#48DEFF"];
    } else if (value >= 1 && value <= 2) {
      return ["#7AE292", "#42E0CE"];
    } else if (value >= 2 && value <= 3) {
      return ["#A4E363", "#7AE292"];
    } else if (value >= 3 && value <= 4) {
      return ["#C8E43C", "#A0E368"];
    } else if (value >= 4 && value <= 5) {
      return ["#F9E607", "#C8E43C"];
    } else if (value >= 5 && value <= 6) {
      return ["#FFD001", "#F9E607"];
    } else if (value >= 6 && value <= 7) {
      return ["#FFA501", "#FFD001"];
    } else if (value >= 7 && value <= 8) {
      return ["#FF7301", "#FFA501"];
    } else if (value >= 8 && value <= 9) {
      return ["#FF4601", "#FF7301"];
    } else if (value >= 9 && value <= 10) {
      return ["#FF0301", "#FF4601"];
    } else {
      return ["#42E0CE", "#48DEFF"];
    }
  }
};
export const FiveColor = (value, type) => {

  if (!type) {
    if (value >= 0 && value <= 1) {
      return ["#FF0301", "#FF4601"];
    } else if (value >= 1 && value <= 2) {
      return ["#FF7301", "#FFA501"];
    } else if (value >= 2 && value <= 3) {
      return ["#F9E607", "#C8E43C"];
    } else if (value >= 3 && value <= 4) {
      return ["#A4E363", "#7AE292"];
    } else if (value >= 4 && value <= 5) {
      return ["#42E0CE", "#48DEFF"];
    } else {
      return ["#FF0301", "#FF4601"];
    }
  } else {
    if (value >= 0 && value <= 1) {
      return ["#42E0CE", "#48DEFF"];
    } else if (value >= 1 && value <= 2) {
      return ["#A4E363", "#7AE292"];
    } else if (value >= 2 && value <= 3) {
      return ["#F9E607", "#C8E43C"];
    } else if (value >= 3 && value <= 4) {
      return ["#FF7301", "#FFA501"];
    } else if (value >= 4 && value <= 5) {
      return ["#FF0301", "#FF4601"];
    } else {
      return ["#42E0CE", "#48DEFF"];
    }
  }
};

export const CircleColor = (total, score, res, min_score) => {

  if (res) {
    return min_score + (total - score) ;
  } else {
    return score;
  }
};

export const UserDateFormat = (date, dateformate) => {
  // const login = useSelector((state) => state.mainReducer.loginData.arr);
  if (dateformate === "1") {
    // "DD/MM/YYYY"
    return new Date(date).toLocaleDateString("en-GB", { timeZone: "UTC" });
  }
  if (dateformate === "2") {
    let mmdd = new Date(date).toLocaleDateString("en-GB", { timeZone: "UTC" });
    // "MM/DD/YYYY"
    let y = mmdd.slice(6, Infinity);
    let m = mmdd.slice(3, 6);
    let d = mmdd.slice(0, 3);
    return `${m}${d}${y}`;
  } else {
    let mmdd = new Date(date).toLocaleDateString("en-GB", { timeZone: "UTC" });
    // "YYYY/MM/DD"
    let y = mmdd.slice(6, Infinity);
    let m = mmdd.slice(3, 6);
    let d = mmdd.slice(0, 2);
    return `${y}/${m}${d}`;
  }
};
export const ConsentFormDateFormat = ( dateFormat) => {
  if (dateFormat === "1") {
    // "DD/MM/YYYY"
    return new Date().toLocaleDateString("en-GB", { timeZone: "UTC" });
  }
  if (dateFormat === "2") {
    let currentDate = new Date();
    let y = currentDate.getFullYear();
    let m = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    let d = currentDate.getDate().toString().padStart(2, '0');
    return `${m}/${d}/${y}`;
  } else {
    // Default format: "YYYY/MM/DD"
    let currentDate = new Date();
    let y = currentDate.getFullYear();
    let m = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    let d = currentDate.getDate().toString().padStart(2, '0');
    return `${y}/${m}/${d}`;
  }
};

export const UserDateFormat2 = (date, dateformate) => {
  // const login = useSelector((state) => state.mainReducer.loginData.arr);

  if (dateformate === "1") {
    // "DD/MM/YYYY"
    return date
  }
  if (dateformate === "2") {
    let mmdd = date
    // "MM/DD/YYYY"
    let y = mmdd.slice(6, Infinity);
    let m = mmdd.slice(3, 6);
    let d = mmdd.slice(0, 3);
    return `${m}${d}${y}`;
  } else {
    let mmdd = date
    // "YYYY/MM/DD"
    let y = mmdd.slice(6, Infinity);
    let m = mmdd.slice(3, 6);
    let d = mmdd.slice(0, 2);
    return `${y}/${m}${d}`;
  }
};

function convertDateFormat(inputDate,dateformate) {
  // if(inputDate ){
    const dateParts = inputDate.match(/\w+/g); // Extract individual parts of the input date
  

    const day = dateParts[2].padStart(2, '0'); // Pad day with leading zero if needed
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const month = (monthNames.indexOf(dateParts[1]) + 1).toString().padStart(2, '0'); // Convert month name to a number and pad with a leading zero
    const year = new Date().getFullYear(); // Use the current year or provide the year as needed
  if(dateformate=== "1"){
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }
  if (dateformate === "2") {
      // "MM/DD/YYYY"
      return `${month}/${day}/${year}`;
  
  }if (dateformate === "3"){
    // "YYYY/MM/DD"
    return `${year}/${month}/${day}`;
  // }
  }
 
}

export const DateFormatWithTimeZone = ( dateformate,timeZone) => {
  const event = new Date();
  const options = {
    day: 'numeric',
    month: 'long',
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
    timeZone: timeZone?timeZone:"australia/brisbane",
  };
  let date1=new Intl.DateTimeFormat('en-US', options).format(event)
 return convertDateFormat(date1,dateformate)
  
};



