import React, { useState, useEffect } from "react";
import Button from "../../custom/button/Button";
import "../../../css/ViewTreatmentForm.css";
import PersonalInformation from "./PersonalInformation";
import FootAnklePain from "./FootAnklePain";
import FootAnkleInsta from "./FootAnkleInsta";
import FootAnkleSymptoms from "./FootAnkleSymptoms";
import FootAnkleFunction from "./FootAnkleFunction";
import FootAnkleQOL from "./FootAnkleQOL";
import FootAnkleSatisfaction from "./FootAnkleSatisfaction";
import FootAnkleDoctorFeddback from "./FootAnkleDoctorFeddback";
import LoadingSpinner from "../../custom/loader/LoadingSpinner";
import useAxios from "../../../axiosinstance";
import { useParams, useNavigate } from "react-router-dom";
import DeleteConfirmation from "../../custom/delete-confirmation/DeleteConfirmation";
import useTranslation from "../../customHooks/translations";
export default function FootAnkleTreatment() {
  const translation = useTranslation();
  const { rId } = useParams();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  // const dId = queryParams.get("doctor");
  const pId = queryParams.get("patient");
  const management = queryParams.get("management");
  const pScore = queryParams.get("form");
  const action = queryParams.get("action");
  const patientEdit = queryParams.get("patientEdit");

  const axiosInstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  let isdoctorForm = queryParams.get("is_doctor_form");
  useEffect(() => {
    localStorage.setItem("isdoctorForm", isdoctorForm);
  }, [isdoctorForm]);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  const [AllData, setAllData] = useState("");
  const [profileData, setProfileData] = useState({});
  const [FootAnklePaindata, setFootAnklePaindata] = useState({});
  const [FootinstabilityScore, setFootinstabilityScore] = useState({});
  const [FootcumberlandScore, setFootCumberlandScore] = useState({});
  const [FootsymptomsScore, setFootsymptomsScore] = useState({});
  const [FootfunctionScore, setFootfunctionScore] = useState({});
  const [FootqolScore, setFootqolScore] = useState({});
  const [FootsatisfactionScore, setFootsatisfactionScore] = useState({});
  const [FootAnkleDoctor, setFootDoctorScore] = useState({});
  const [editShow, seteditShow] = useState(false);
  const [viewDoctor, setViewDoctor] = useState("0");
  const [initialAssesment, setInitialAssesment] = useState(0);
  const [viewPatient, setViewPatient] = useState("0");
  const [doctorView, setdoctorView] = useState(false);

  const [displayConfirmationModalDelete, setDisplayConfirmationModalDelete] =
    useState(false);
  const [editShow1, setEditShow1] = useState(false);
  const doctor_id = queryParams.get("doctor")
  let orgid=sessionStorage.getItem("orgid")
  const save = () => {
    axiosInstance.current
      .get(
        `extapp/forms/all_forms?resource_id=${rId}&type=21&akpt=${window.atob(
          pId
        )}`
      )
      .then((res) => {
        setInitialAssesment(res.data.data2[0].initial_assessment);
        setAllData(res.data.data2[0]);
        setProfileData(res.data.data1);
        setFootAnklePaindata(res.data.data2[0].painScore[0]);
        setFootfunctionScore(res.data.data2[0].functionScore[0]);
        setFootinstabilityScore(res.data.data2[0].instabilityScore[0]);
        setFootCumberlandScore(res.data.data2[0].comberland[0]);
        setFootqolScore(res.data.data2[0].qolScore[0]);
        setFootsatisfactionScore(res.data.data2[0].satisfactionScore[0]);
        setFootsymptomsScore(res.data.data2[0].symptomsScore[0]);
        setFootDoctorScore(res.data.data2[0].doctorFeedback[0]);
        seteditShow(res.data.status);
        setdoctorView(res.data.doctorStatus);
        setEditShow1(res.data.doctorStatus);
        setViewDoctor(res.data.data2[0].doctor_scoring_date);
        setViewPatient(res.data.data2[0].patient_scoring_date);
        setIsLoading(false);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };
  useEffect(() => {
    save();
  }, []);
  const handleEdit = () => {
   
    // navigate(
    //   "/foot-ankle-pain/" + rId + "?d=true&akpt=" + pId + "&is_doctor_form=true"
    // );

    navigate(
      `/personal-data-edit/${
        pId
      }/${window.btoa(doctor_id)}/${window.btoa(orgid)}/${window.btoa(
        21
      )}?is_doctor_form=true&doctorvalue=${0}&doctor=true&resource_id=${rId}`
    );
  };
  const handlePatientEdit = () => {
    navigate("/foot-ankle-pain/" + rId + "?is_doctor_form=null&edit=true");
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModalDelete(false);
  };
  const submitDelete = (type, e) => {
    handleDelByrID();
  };
  const handleDelByrID = async () => {
    try {
      const result = await axiosInstance.current.patch(
        `extapp/forms/soft_delete_form?resource_id=${rId}`
      );
      if (result.status === 200) {
        window.open(
          process.env.REACT_APP_DOCTOR_URL + "patient-list",
          "_blank"
        );
        window.close();
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      // console.log("api response finally");
    }
  };
  const handleDelete = (e, te) => {
    setDisplayConfirmationModalDelete(true);
  };
  const profileAllData = React.useMemo(() => profileData, [profileData]);

  const handleBack = () => {
    window.open(process.env.REACT_APP_DOCTOR_URL + "patient-view" + "/" + pId);
    window.close();
  };
  const handleEdit1 = () => {
    window.open(
      
        "/foot-doctor-feedback/" +
        rId +
        `?doctor=${doctor_id}&patient=${pId}&is_doctor_form=true`
    );
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="view_treatment_form">
          <div className="row page_header">
            <div className="col-sm-6 col-md-6">
              <h4>{translation.Foot_Ankle_Treatment_Form}</h4>
            </div>
            {management === "true" && (
              <div className={`col-sm-6 col-md-6 text-end`}>
                <Button
                  onClick={() => handleDelete()}
                  value={translation.Delete_Form_mobile}
                  buttonStyle="btn_fill"
                />
                {editShow || doctorView ? (
                  <Button
                    onClick={() => handleEdit()}
                    value={translation.Edit_form}
                    buttonStyle="btn_fill ml_15 "
                  />
                ) : (
                  <Button
                    onClick={() => console.log("")}
                    value={translation.Edit_form}
                    buttonStyle=" ml_15 button_disabled "
                  />
                )}
              </div>
            )}

            {isdoctorForm && management !== "true"&& (
              <div className={`col-sm-6 col-md-6 text-end`}>
                <Button
                  onClick={() => handleBack()}
                  value={translation.back_button}
                  buttonStyle="btn_fill ml_15 "
                />

                {editShow === true && (
                  <Button
                    onClick={() => handleEdit1()}
                    value={translation.Edit_form}
                    buttonStyle="btn_fill ml_15 "
                  />
                )}
              </div>
            )}

            {patientEdit === "true" && (
              <div className={`col-sm-6 col-md-6 text-end`}>
                {editShow && (
                  <Button
                    onClick={() => handlePatientEdit()}
                    value={translation.Edit_form}
                    buttonStyle="btn_fill ml_15 "
                  />
                )}
              </div>
            )}
            <DeleteConfirmation
              showModal={displayConfirmationModalDelete}
              confirmModal={submitDelete}
              hideModal={hideConfirmationModal}
              message={"Form"}
              type="task_id"
              id={rId}
            />
          </div>
          {pScore === "true" && action === "1" && (
            <>
              <PersonalInformation
                profileAllData={profileAllData}
                AllData={AllData}
              />
              {viewPatient === "0" ? (
                <div className="no_data">Patient form is not filled yet </div>
              ) : (
                <>
                  <FootAnklePain
                    FootAnklePaindata={FootAnklePaindata}
                    AllData={AllData}
                    profileAllData={profileAllData}

                  />
                  <FootAnkleInsta
                    FootinstabilityScore={FootinstabilityScore}
                    AllData={AllData}
                    FootcumberlandScore={FootcumberlandScore}
                  />
                  <FootAnkleSymptoms
                    FootsymptomsScore={FootsymptomsScore}
                    AllData={AllData}
                  />
                  <FootAnkleFunction
                    FootfunctionScore={FootfunctionScore}
                    AllData={AllData}
                  />

                  <FootAnkleQOL FootqolScore={FootqolScore} AllData={AllData} />

                  <FootAnkleSatisfaction
                    FootsatisfactionScore={FootsatisfactionScore}
                    AllData={AllData}
                  />

                </>
              )}
            </>
          )}
          {pScore === "true" && action === "2" && (
            <FootAnkleDoctorFeddback
              doctorFeedback={FootAnkleDoctor}
              AllData={AllData}
              profileAllData={profileAllData}
            />
          )}
          {management === "true" && (
            <>
              <PersonalInformation
                profileAllData={profileAllData}
                AllData={AllData}
              />
              {viewPatient === "0" ? (
                <div className="no_data">Patient form is not filled yet </div>
              ) : (
                <>
                  <FootAnklePain
                    FootAnklePaindata={FootAnklePaindata}
                    AllData={AllData}
                    profileAllData={profileAllData}
                  />
                  <FootAnkleInsta
                    FootinstabilityScore={FootinstabilityScore}
                    AllData={AllData}
                    FootcumberlandScore={FootcumberlandScore}
                  />
                  <FootAnkleSymptoms
                    FootsymptomsScore={FootsymptomsScore}
                    AllData={AllData}
                  />
                  <FootAnkleFunction
                    FootfunctionScore={FootfunctionScore}
                    AllData={AllData}
                  />
                  <FootAnkleQOL FootqolScore={FootqolScore} AllData={AllData} />

                  <FootAnkleSatisfaction
                    FootsatisfactionScore={FootsatisfactionScore}
                    AllData={AllData}
                  />

                </>
              )}
            </>
          )}
          {viewDoctor !== "0" && management === "true" && (
            <>
              <FootAnkleDoctorFeddback
                doctorFeedback={FootAnkleDoctor}
                AllData={AllData}
                profileAllData={profileAllData}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}
