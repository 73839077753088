import React from "react";
import { UserDateFormat } from "../../../utilities/DateFormate";

export default function FootAnkleDoctorFeddback({ doctorFeedback, AllData,profileAllData }) {
  // let x = doctorFeedback.value2?.map((row) => row.label);
  // let y = doctorFeedback.value?.map((row) => row.label);

  // console.log(doctorFeedback.value2, "Induuuuu");

  // let Leftdatafoot2 =
  //   doctorFeedback.LeftForefoot !== ""
  //     ? doctorFeedback.LeftForefoot.filter((item) => item.value === "2")[0]
  //     : doctorFeedback.LeftForefoot;
  return (
    <>
      {/*  ===================Step 1 ============== */}

      <div className="whitebox padding-none">
        <div className="treatment-header">
          <h2>Foot Ankle Doctor Feedback Form</h2>
          <div className="treatment-header-right">
          <p>Assessment Date : {AllData.assessment_date}
            {/* {UserDateFormat (AllData.assessment_date,profileAllData.ing_date_formate)} */}
          </p>
          </div>
        </div>
        <div className="treatment-content">
          <div className="treatment-row mt-3">
            <div className="treatment-table border-0">
              <div className="treatment-row-header">
                Please choose the part of the Foot and Ankle that is being evaluated
              </div>
              <div className="treatment-row mt-3">
                <div className="treatment-col">
                  <label>Foot and Ankle:</label>

                  <p>{doctorFeedback.AnkleHL ? "Ankle/Hindfoot" : ""}</p>
                  <p>{doctorFeedback.MidfootHL ? "Midfoot" : ""}</p>
                  <p>{doctorFeedback.ForefootL ? "Forefoot" : ""}</p>
                  <p>{doctorFeedback.Hallux ? "Hallux" : ""}</p>
                  <p>{doctorFeedback.Toe2nd ? "2nd toe" : ""}</p>
                  <p>{doctorFeedback.Toe3rd ? "3rd toe" : ""}</p>
                  <p>{doctorFeedback.Toe4th ? "4th toe" : ""}</p>
                  <p>{doctorFeedback.Toe5th ? "5th toe" : ""}</p>
                </div>
                {/* <div className="treatment-col">
                  <label>Right Foot and Ankle:</label>
                  <p>{doctorFeedback.AnkleHR ? "Ankle/Hindfoot" : ""}</p>
                  <p>{doctorFeedback.MidfootHR ? "Midfoot" : ""}</p>
                  <p>{doctorFeedback.ForefootR ? "Forefoot" : ""}</p>
                </div> */}
              </div>

              {/* <div className="treatment-row-header"></div> */}
              {/* <div className="treatment-row ">
                <div className="treatment-table">
                  <div className="treatment-row ">
                    <div className="treatment-col">
                      
                      <>
                        <label>Gait abnormality (Right):</label>
                        <p>
                          {doctorFeedback.RightGAIT === "4"
                            ? "Marked"
                            : doctorFeedback.RightGAIT === "3"
                              ? "Obvious"
                              : doctorFeedback.RightGAIT === "2"
                                ? "Slight"
                                : doctorFeedback.RightGAIT === "1" ? "None" : ""}
                        </p>
                        {doctorFeedback.RightGAIT !== "1" && (
                          <>
                            <div className="treatment-col">
                              <label>Abnormal gait (Right):</label>
                              <p>
                                {doctorFeedback.RightABNORMAL === "13"
                                  ? "Others"
                                  : doctorFeedback.RightABNORMAL === "12"
                                    ? "Waddling"
                                    : doctorFeedback.RightABNORMAL === "11"
                                      ? "Trendelenburg / Lurching"
                                      : doctorFeedback.RightABNORMAL === "10"
                                        ? "Steppage"
                                        : doctorFeedback.RightABNORMAL === "9"
                                          ? "Short leg /Equinus"
                                          : doctorFeedback.RightABNORMAL === "8"
                                            ? "Scissoring"
                                            : doctorFeedback.RightABNORMAL === "7"
                                              ? "Quadriceps"
                                              : doctorFeedback.RightABNORMAL === "6"
                                                ? "Rocking horse / Gluteus maximus"
                                                : doctorFeedback.RightABNORMAL === "5"
                                                  ? "Hemiplegic / Circumductory"
                                                  : doctorFeedback.RightABNORMAL === "4"
                                                    ? "Equinovarus"
                                                    : doctorFeedback.RightABNORMAL === "3"
                                                      ? "Equinus / Tiptoe"
                                                      : doctorFeedback.RightABNORMAL === "2"
                                                        ? "Ataxic"
                                                        : doctorFeedback.RightABNORMAL === "1"
                                                          ? "Antalgic"
                                                          : doctorFeedback.RightABNORMAL === "none"
                                                            ? "none"
                                                            : ""}
                              </p>
                            </div>
                          </>
                        )}
                        {doctorFeedback.RightABNORMAL === "13" && (
                          <>
                            <div className="treatment-col">
                              <label>Others:</label>
                              <p>{doctorFeedback.RABNORMALF}</p>
                            </div>
                          </>
                        )}
                      </>
                     
                    </div>
                    <div className="treatment-col">
                     
                      <>
                        <label>Gait abnormality (Left):</label>
                        <p>
                          {doctorFeedback.LeftGAIT === "4"
                            ? "Marked"
                            : doctorFeedback.LeftGAIT === "3"
                              ? "Obvious"
                              : doctorFeedback.LeftGAIT === "2"
                                ? "Slight"
                                : doctorFeedback.LeftGAIT === "1" ? "None" : ""}
                        </p>

                        {doctorFeedback.LeftGAIT !== "1" && (
                          <>
                            <div className="treatment-col">
                              <label>Abnormal gait (Left):</label>
                              <p>
                                {doctorFeedback.LeftABNORMAL === "13"
                                  ? "Others"
                                  : doctorFeedback.LeftABNORMAL === "12"
                                    ? "Waddling"
                                    : doctorFeedback.LeftABNORMAL === "11"
                                      ? "Trendelenburg / Lurching"
                                      : doctorFeedback.LeftABNORMAL === "10"
                                        ? "Steppage"
                                        : doctorFeedback.LeftABNORMAL === "9"
                                          ? "Short leg /Equinus"
                                          : doctorFeedback.LeftABNORMAL === "8"
                                            ? "Scissoring"
                                            : doctorFeedback.LeftABNORMAL === "7"
                                              ? "Quadriceps"
                                              : doctorFeedback.LeftABNORMAL === "6"
                                                ? "Rocking horse / Gluteus maximus"
                                                : doctorFeedback.LeftABNORMAL === "5"
                                                  ? "Hemiplegic / Circumductory"
                                                  : doctorFeedback.LeftABNORMAL === "4"
                                                    ? "Equinovarus"
                                                    : doctorFeedback.LeftABNORMAL === "3"
                                                      ? "Equinus / Tiptoe"
                                                      : doctorFeedback.LeftABNORMAL === "2"
                                                        ? "Ataxic"
                                                        : doctorFeedback.LeftABNORMAL === "1"
                                                          ? "Antalgic"
                                                          : doctorFeedback.LeftABNORMAL === "none"
                                                            ? "none"
                                                            : ""}
                              </p>
                            </div>
                          </>
                        )}
                        {doctorFeedback.LeftABNORMAL === "13" && (
                          <>
                            <div className="treatment-col">
                              <label>Others:</label>
                              <p>{doctorFeedback.LABNORMALF}</p>
                            </div>
                          </>
                        )}
                      </>
                      
                    </div>
                  </div>
                </div>
              </div> */}


              <table className="treatment-table">
                <tr className="treatment-row">
                  <th>GAIT</th>
                  <th>Right</th>
                  <th>Left</th>
                </tr>
                <tr className="treatment-row">
                  <td>
                    Gait abnormality:
                  </td>
                  <td>
                    <p>
                      {doctorFeedback.RightGAIT === "4"
                        ? "Marked"
                        : doctorFeedback.RightGAIT === "3"
                          ? "Obvious"
                          : doctorFeedback.RightGAIT === "2"
                            ? "Slight"
                            : doctorFeedback.RightGAIT === "1"
                              ? "None"
                              : ""}
                    </p>
                  </td>
                  <td>
                    <p>
                      {doctorFeedback.LeftGAIT === "4"
                        ? "Marked"
                        : doctorFeedback.LeftGAIT === "3"
                          ? "Obvious"
                          : doctorFeedback.LeftGAIT === "2"
                            ? "Slight"
                            : doctorFeedback.LeftGAIT === "1"
                              ? "None"
                              : ""}
                    </p>
                  </td>
                </tr>
                <tr className="treatment-row">
                  <td>
                    Abnormal gait:
                  </td>
                  <td>
                    <p>
                      {doctorFeedback.RightABNORMAL === "13"
                        ? "Others"
                        : doctorFeedback.RightABNORMAL === "12"
                          ? "Waddling"
                          : doctorFeedback.RightABNORMAL === "11"
                            ? "Trendelenburg / Lurching"
                            : doctorFeedback.RightABNORMAL === "10"
                              ? "Steppage"
                              : doctorFeedback.RightABNORMAL === "9"
                                ? "Short leg /Equinus"
                                : doctorFeedback.RightABNORMAL === "8"
                                  ? "Scissoring"
                                  : doctorFeedback.RightABNORMAL === "7"
                                    ? "Quadriceps"
                                    : doctorFeedback.RightABNORMAL === "6"
                                      ? "Rocking horse / Gluteus maximus"
                                      : doctorFeedback.RightABNORMAL === "5"
                                        ? "Hemiplegic / Circumductory"
                                        : doctorFeedback.RightABNORMAL === "4"
                                          ? "Equinovarus"
                                          : doctorFeedback.RightABNORMAL === "3"
                                            ? "Equinus / Tiptoe"
                                            : doctorFeedback.RightABNORMAL === "2"
                                              ? "Ataxic"
                                              : doctorFeedback.RightABNORMAL === "1"
                                                ? "Antalgic"
                                                : doctorFeedback.RightABNORMAL === "none"
                                                  ? "none"
                                                  : ""}
                    </p>
                    {doctorFeedback.RightABNORMAL === "13" && (
                      <>
                        <p>{doctorFeedback.RABNORMALF}</p>
                      </>
                    )}
                  </td>
                  <td>
                    <p>
                      {doctorFeedback.LeftABNORMAL === "13"
                        ? "Others"
                        : doctorFeedback.LeftABNORMAL === "12"
                          ? "Waddling"
                          : doctorFeedback.LeftABNORMAL === "11"
                            ? "Trendelenburg / Lurching"
                            : doctorFeedback.LeftABNORMAL === "10"
                              ? "Steppage"
                              : doctorFeedback.LeftABNORMAL === "9"
                                ? "Short leg /Equinus"
                                : doctorFeedback.LeftABNORMAL === "8"
                                  ? "Scissoring"
                                  : doctorFeedback.LeftABNORMAL === "7"
                                    ? "Quadriceps"
                                    : doctorFeedback.LeftABNORMAL === "6"
                                      ? "Rocking horse / Gluteus maximus"
                                      : doctorFeedback.LeftABNORMAL === "5"
                                        ? "Hemiplegic / Circumductory"
                                        : doctorFeedback.LeftABNORMAL === "4"
                                          ? "Equinovarus"
                                          : doctorFeedback.LeftABNORMAL === "3"
                                            ? "Equinus / Tiptoe"
                                            : doctorFeedback.LeftABNORMAL === "2"
                                              ? "Ataxic"
                                              : doctorFeedback.LeftABNORMAL === "1"
                                                ? "Antalgic"
                                                : doctorFeedback.LeftABNORMAL === "none"
                                                  ? "none"
                                                  : ""}
                    </p>
                    {doctorFeedback.LeftABNORMAL === "13" && (
                      <>
                        <p>{doctorFeedback.LABNORMALF}</p>
                      </>
                    )}
                  </td>
                </tr>



                {/* <div className="treatment-row-header">CONTRACTURES</div> */}
                {/* <div className="treatment-row mt-3">
                <div className="treatment-col">
                  <label>Right:</label>
                  <p>
                    {doctorFeedback.ContraR === "0"
                      ? "Absent"
                      : doctorFeedback.ContraR === "1"
                        ? "Present"
                        : ""}
                  </p>
                  {doctorFeedback.ContraR === "1" && (

                    <p>{doctorFeedback.SpecifyR}</p>
                  )}
                </div>
                <div className="treatment-col">
                  <label>Left:</label>
                  <p>
                    {doctorFeedback.ContraL === "0"
                      ? "Absent"
                      : doctorFeedback.ContraL === "1"
                        ? "Present"
                        : ""}
                  </p>
                  {doctorFeedback.ContraL === "1" && (

                    <p>{doctorFeedback.SpecifyL}</p>
                  )}
                </div>
              </div> */}


                <tr className="treatment-row">
                  <th>CONTRACTURES</th>
                  <th>Right</th>
                  <th>Left</th>
                </tr>
                <tr className="treatment-row">

                  <td></td>
                  <td>
                    <p>
                      {doctorFeedback.ContraR === "0"
                        ? "Absent"
                        : doctorFeedback.ContraR === "1"
                          ? "Present"
                          : ""}
                    </p>
                    {doctorFeedback.ContraR === "1" && (
                      <p>{doctorFeedback.SpecifyR}</p>
                    )}
                  </td>

                  <td>
                    <p>
                      {doctorFeedback.ContraL === "0"
                        ? "Absent"
                        : doctorFeedback.ContraL === "1"
                          ? "Present"
                          : ""}
                    </p>
                    {doctorFeedback.ContraL === "1" && (
                      <p>{doctorFeedback.SpecifyL}</p>
                    )}
                  </td>
                </tr>
              </table>


              {doctorFeedback.EvaluatedR === "1" && (
                <div className="treatment-row-header"> </div>
              )}


              {/* <div className="whitebox padding-none">
                <div className="treatment-row-header">Ankle/Hindfoot</div>
                <div className="treatment-row mt-3">
                  <div className="treatment-col">

                    <>
                      <label>Alignment(Right):</label>
                      {doctorFeedback.AnkleR === "1"
                        ? "Good,plantigrade foot, ankle-hindfoot well aligned"
                        : doctorFeedback.AnkleR === "2"
                          ? "Fair,plantigrade foot, some degree of ankle-hindfoot malalignment observed, no symptoms"
                          : doctorFeedback.AnkleR === "3"
                            ? "Poor,nonplantigrade foot, severe symptomatic malalignment"
                            : ""}
                    </>

                  </div>

                  <div className="treatment-col">
                    <label>Alignment(Left):</label>

                    {doctorFeedback.AnkleL === "1"
                      ? "Good,plantigrade foot, ankle-hindfoot well aligned"
                      : doctorFeedback.AnkleL === "2"
                        ? "Fair,plantigrade foot, some degree of ankle-hindfoot malalignment observed, no symptoms"
                        : doctorFeedback.AnkleL === "3"
                          ? "Poor,nonplantigrade foot, severe symptomatic malalignment"
                          : ""}
                  </div>

                </div>
              </div>

              */}

              {/*<div className="whitebox padding-none">
                <div className="">
                  <div className="treatment-row-header">
                    Motion
                  </div>
                  <div className="treatment-row mt-3">
                    <div className="treatment-table">
                      <div className="treatment-row mt-3">
                        
                        <div className="treatment-col">
                          <label>
                            Ankle Dorsiflexion-Plantarflexion Arc Right:
                          </label>
                          {doctorFeedback.MotionRtext !== null && (
                            <p>Active: {doctorFeedback.MotionRtext} Degrees</p>
                          )}
                          {doctorFeedback.MotionLtext !== null && (
                            <p>Passive: {doctorFeedback.MotionLtext} Degrees</p>
                          )}
                        </div>
                        
                       
                        <div className="treatment-col">
                          <label>Ankle Dorsiflexion-Plantarflexion Arc Left:</label>
                          {doctorFeedback.MotionLRtext !== null && (
                            <p>Active: {doctorFeedback.MotionLRtext} Degrees</p>
                          )}
                          {doctorFeedback.MotionLLtext !== null && (
                            <p>
                              Passive: {doctorFeedback.MotionLLtext} Degrees
                            </p>
                          )}
                        </div>
                       
                      </div>

                      <div className="treatment-row mt-3">
                        
                        <div className="treatment-col">
                          <label>
                            Hindfoot Inversion-Eversion Arc Right:
                          </label>
                          {doctorFeedback.Motion3Rtext !== null && (
                            <p>Active: {doctorFeedback.Motion3Rtext} Degrees</p>
                          )}
                          {doctorFeedback.Motion4Rtext !== null && (
                            <p>
                              Passive: {doctorFeedback.Motion4Rtext} Degrees
                            </p>
                          )}
                        </div>
                        
                        
                        <div className="treatment-col">
                          <label>Hindfoot Inversion-Eversion Arc Left:</label>
                          {doctorFeedback.Motion3RRtext !== null && (
                            <p>
                              Active: {doctorFeedback.Motion3RRtext} Degrees
                            </p>
                          )}
                          {doctorFeedback.Motion3RLtext !== null && (
                            <p>
                              Passive: {doctorFeedback.Motion3RLtext} Degrees
                            </p>
                          )}
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <table className="treatment-table">
                <tr className="treatment-row-header">
                  <th>ANKLE/HINDFOOT </th>
                  <th>Right</th>
                  <th>Left</th>
                </tr>
                <tr >
                  <td>
                    Alignment
                  </td>
                  <td>
                    {doctorFeedback.AnkleR === "1"
                      ? "Good,plantigrade foot, ankle-hindfoot well aligned"
                      : doctorFeedback.AnkleR === "2"
                        ? "Fair,plantigrade foot, some degree of ankle-hindfoot malalignment observed, no symptoms"
                        : doctorFeedback.AnkleR === "3"
                          ? "Poor,nonplantigrade foot, severe symptomatic malalignment"
                          : ""}
                  </td>
                  <td>
                    {doctorFeedback.AnkleL === "1"
                      ? "Good,plantigrade foot, ankle-hindfoot well aligned"
                      : doctorFeedback.AnkleL === "2"
                        ? "Fair,plantigrade foot, some degree of ankle-hindfoot malalignment observed, no symptoms"
                        : doctorFeedback.AnkleL === "3"
                          ? "Poor,nonplantigrade foot, severe symptomatic malalignment"
                          : ""}
                  </td>
                </tr>
                {/* <tr >
                  <td>
                    Motion:
                  </td>
                  <td>
                    Ankle Dorsiflexion-Plantarflexion Arc:
                    {doctorFeedback.MotionRtext !== null && (
                      <p>Active: {doctorFeedback.MotionRtext} Degrees</p>
                    )}
                    {doctorFeedback.MotionLtext !== null && (
                      <p>Passive: {doctorFeedback.MotionLtext} Degrees</p>
                    )}
                    Hindfoot Inversion-Eversion Arc:
                    {doctorFeedback.Motion3Rtext !== null && (
                      <p>Active: {doctorFeedback.Motion3Rtext} Degrees</p>
                    )}
                    {doctorFeedback.Motion4Rtext !== null && (
                      <p>Passive: {doctorFeedback.Motion4Rtext} Degrees</p>
                    )}
                  </td>
                  <td>
                    Ankle Dorsiflexion-Plantarflexion Arc:
                    {doctorFeedback.MotionLRtext !== null && (
                      <p>Active: {doctorFeedback.MotionLRtext} Degrees</p>
                    )}
                    {doctorFeedback.MotionLLtext !== null && (
                      <p>Passive: {doctorFeedback.MotionLLtext} Degrees</p>
                    )}
                    Hindfoot Inversion-Eversion Arc:
                    {doctorFeedback.Motion3RRtext !== null && (
                      <p>Active: {doctorFeedback.Motion3RRtext} Degrees</p>
                    )}
                    {doctorFeedback.Motion3RLtext !== null && (
                      <p>Passive: {doctorFeedback.Motion3RLtext} Degrees</p>
                    )}
                  </td>
                </tr> */}

                <tr>
                  <td>Motion:</td>
                  <td></td>
                  <td></td>

                  </tr>
                  <tr>

                  <td>
                    Ankle Dorsiflexion-Plantarflexion Arc:
                   
                  </td>
                  <td>
                  {doctorFeedback.MotionRtext !== null && (
                      <p>Active: {doctorFeedback.MotionRtext} Degrees</p>
                    )}
                    {doctorFeedback.MotionLtext !== null && (
                      <p>Passive: {doctorFeedback.MotionLtext} Degrees</p>
                    )}
                  </td>
                  <td>
                   
                    {doctorFeedback.MotionLRtext !== null && (
                      <p>Active: {doctorFeedback.MotionLRtext} Degrees</p>
                    )}
                    {doctorFeedback.MotionLLtext !== null && (
                      <p>Passive: {doctorFeedback.MotionLLtext} Degrees</p>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>  Hindfoot Inversion-Eversion Arc:</td>
                  <td>
                  
                    {doctorFeedback.Motion3Rtext !== null && (
                      <p>Active: {doctorFeedback.Motion3Rtext} Degrees</p>
                    )}
                    {doctorFeedback.Motion4Rtext !== null && (
                      <p>Passive: {doctorFeedback.Motion4Rtext} Degrees</p>
                    )}
                  </td>
                  <td>
               
                    {doctorFeedback.Motion3RRtext !== null && (
                      <p>Active: {doctorFeedback.Motion3RRtext} Degrees</p>
                    )}
                    {doctorFeedback.Motion3RLtext !== null && (
                      <p>Passive: {doctorFeedback.Motion3RLtext} Degrees</p>
                    )}
                  </td>
                </tr>

              </table>


              {/* <div className="whitebox padding-none">
                <div className="">
                  <div className="treatment-row-header">STABILITY</div>
                  <div className="treatment-row mt-3">
                    <div className="treatment-table">
                      <div className="treatment-row mt-3">

                        <div className="treatment-col">
                          <label>AP, Varus-Valgus (Right):</label>
                          <p>
                            {" "}
                            {doctorFeedback.StabilityR === "0"
                              ? "Stable"
                              : doctorFeedback.StabilityR === "1"
                                ? "Unstable"
                                : ""}
                          </p>
                        </div>

                        <div className="treatment-col">
                          <label>AP, Varus-Valgus (Left):</label>
                          <p>
                            {" "}
                            {doctorFeedback.StabilityL === "0"
                              ? "Stable"
                              : doctorFeedback.StabilityL === "1"
                                ? "Unstable"
                                : ""}
                          </p>
                        </div>

                      </div>
                      <div className="treatment-row mt-3">

                        <div className="treatment-col">
                          <label>Anterior drawer test (Right):</label>
                          <p>
                            {" "}
                            {doctorFeedback.Stability2R === "0"
                              ? "Negative"
                              : doctorFeedback.Stability2R === "1"
                                ? "Positive"
                                : ""}
                          </p>
                        </div>

                        <div className="treatment-col">
                          <label>Anterior drawer test (Left):</label>
                          <p>
                            {" "}
                            {doctorFeedback.Stability2L === "0"
                              ? "Negative"
                              : doctorFeedback.Stability2L === "1"
                                ? "Positive"
                                : ""}
                          </p>
                        </div>

                      </div>
                      <div className="treatment-row mt-3">

                        <div className="treatment-col">
                          <label>Posterior drawer test (Right):</label>
                          <p>
                            {" "}
                            {doctorFeedback.Stability3R === "0"
                              ? "Negative"
                              : doctorFeedback.Stability3R === "1"
                                ? "Positive"
                                : ""}
                          </p>
                        </div>

                        <div className="treatment-col">
                          <label>Posterior drawer test (Left):</label>
                          <p>
                            {" "}
                            {doctorFeedback.Stability3L === "0"
                              ? "Negative"
                              : doctorFeedback.Stability3L === "1"
                                ? "Positive"
                                : ""}
                          </p>
                        </div>

                      </div>

                      <div className="treatment-row mt-3">

                        <div className="treatment-col">
                          <label>Talar tilt test Inversion (Right):</label>
                          <p>
                            {" "}
                            {doctorFeedback.TalarR === "0"
                              ? "Negative"
                              : doctorFeedback.TalarR === "1"
                                ? "Positive"
                                : ""}
                          </p>
                        </div>

                        <div className="treatment-col">
                          <label>Talar tilt test Inversion (Left):</label>
                          <p>
                            {" "}
                            {doctorFeedback.TalarL === "0"
                              ? "Negative"
                              : doctorFeedback.TalarL === "1"
                                ? "Positive"
                                : ""}
                          </p>
                        </div>
                       
                      </div>

                      <div className="treatment-row mt-3">
                        
                        <div className="treatment-col">
                          <label>Talar tilt test Eversion (Right):</label>
                          <p>
                            {" "}
                            {doctorFeedback.Talar2R === "0"
                              ? "Negative"
                              : doctorFeedback.Talar2R === "1"
                                ? "Positive"
                                : ""}
                          </p>
                        </div>
                        
                        <div className="treatment-col">
                          <label>Talar tilt test Eversion (Left):</label>
                          <p>
                            {" "}
                            {doctorFeedback.Talar2L === "0"
                              ? "Negative"
                              : doctorFeedback.Talar2L === "1"
                                ? "Positive"
                                : ""}
                          </p>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="whitebox padding-none">
                <table className="treatment-table">
                  <tr className="treatment-row">
                    <th>STABILITY</th>

                    <th>Right</th>
                    <th>Left</th>
                  </tr>
                  <tr className="treatment-row">
                    <td>AP, Varus-Valgus</td>

                    <td>{doctorFeedback.StabilityR === "0" ? "Stable" : doctorFeedback.StabilityR === "1" ? "Unstable" : ""}</td>
                    <td>{doctorFeedback.StabilityL === "0" ? "Stable" : doctorFeedback.StabilityL === "1" ? "Unstable" : ""}</td>
                  </tr>
                  <tr className="treatment-row">
                    <td>Anterior drawer test</td>

                    <td>{doctorFeedback.Stability2R === "0" ? "Negative" : doctorFeedback.Stability2R === "1" ? "Positive" : ""}</td>
                    <td>{doctorFeedback.Stability2L === "0" ? "Negative" : doctorFeedback.Stability2L === "1" ? "Positive" : ""}</td>
                  </tr>
                  <tr className="treatment-row">
                    <td>Posterior drawer test</td>

                    <td>{doctorFeedback.Stability3R === "0" ? "Negative" : doctorFeedback.Stability3R === "1" ? "Positive" : ""}</td>
                    <td>{doctorFeedback.Stability3L === "0" ? "Negative" : doctorFeedback.Stability3L === "1" ? "Positive" : ""}</td>
                  </tr>
                  <tr className="treatment-row">
                    <td>Talar tilt test Inversion</td>

                    <td>{doctorFeedback.TalarR === "0" ? "Negative" : doctorFeedback.TalarR === "1" ? "Positive" : ""}</td>
                    <td>{doctorFeedback.TalarL === "0" ? "Negative" : doctorFeedback.TalarL === "1" ? "Positive" : ""}</td>
                  </tr>
                  <tr className="treatment-row">
                    <td>Talar tilt test Eversion</td>

                    <td>{doctorFeedback.Talar2R === "0" ? "Negative" : doctorFeedback.Talar2R === "1" ? "Positive" : ""}</td>
                    <td>{doctorFeedback.Talar2L === "0" ? "Negative" : doctorFeedback.Talar2L === "1" ? "Positive" : ""}</td>
                  </tr>
                </table>
              </div>

              {/* <div className="treatment-row"></div> */}
              {/* <div className="treatment-row mt-3">
                <div className="treatment-table">
                  <div className="treatment-row mt-3">

                    <div className="treatment-col">
                      <label>Calf compression or “squeeze” test (Right):</label>
                      <p>
                        {" "}
                        {doctorFeedback.SyndesmosisR === "0"
                          ? "Negative"
                          : doctorFeedback.SyndesmosisR === "1"
                            ? "Positive"
                            : ""}
                      </p>
                    </div>

                    <div className="treatment-col">
                      <label>Calf compression or “squeeze” test (Left):</label>
                      <p>
                        {" "}
                        {doctorFeedback.SyndesmosisL === "0"
                          ? "Negative"
                          : doctorFeedback.SyndesmosisL === "1"
                            ? "Positive"
                            : ""}
                      </p>
                    </div>

                  </div>
                  <div className="treatment-row mt-3">

                    <div className="treatment-col">
                      <label>
                        External rotation stress or Kleiger’s test (Right):
                      </label>
                      <p>
                        {" "}
                        {doctorFeedback.Syndesmosis1R === "0"
                          ? "Negative"
                          : doctorFeedback.Syndesmosis1R === "1"
                            ? "Positive"
                            : ""}
                      </p>
                    </div>

                    <div className="treatment-col">
                      <label>
                        External rotation stress or Kleiger’s test (Left):
                      </label>
                      <p>
                        {" "}
                        {doctorFeedback.Syndesmosis1L === "0"
                          ? "Negative"
                          : doctorFeedback.Syndesmosis1L === "1"
                            ? "Positive"
                            : ""}
                      </p>
                    </div>

                  </div>
                </div>
              </div> */}
              <div className="treatment-row mt-3">
                <table className="treatment-table">
                  <tr className="treatment-row">
                    <th>Syndesmosis</th>
                    <th>Right</th>
                    <th>Left</th>
                  </tr>
                  <tr className="treatment-row">
                    <td>Calf compression or "squeeze" test</td>
                    <td>{doctorFeedback.SyndesmosisR === "0" ? "Negative" : doctorFeedback.SyndesmosisR === "1" ? "Positive" : ""}</td>
                    <td>{doctorFeedback.SyndesmosisL === "0" ? "Negative" : doctorFeedback.SyndesmosisL === "1" ? "Positive" : ""}</td>
                  </tr>
                  <tr className="treatment-row">
                    <td>External rotation stress or Kleiger's test</td>
                    <td>{doctorFeedback.Syndesmosis1R === "0" ? "Negative" : doctorFeedback.Syndesmosis1R === "1" ? "Positive" : ""}</td>
                    <td>{doctorFeedback.Syndesmosis1L === "0" ? "Negative" : doctorFeedback.Syndesmosis1L === "1" ? "Positive" : ""}</td>
                  </tr>
                </table>
              </div>




              {/* <div className="treatment-row-header"></div> */}
              {/* <div className="treatment-row mt-3">
                <div className="treatment-table">
                  <div className="treatment-row mt-3">

                    <>
                      <div className="treatment-col">
                        <label>Tenderness (Right):</label>
                        <p>
                          {" "}
                          {doctorFeedback.ImpingementR === "0"
                            ? "Negative"
                            : doctorFeedback.ImpingementR === "1"
                              ? "Positive"
                              : ""}
                        </p>
                      </div>
                    </>

                    <>
                      <div className="treatment-col">
                        <label>Tenderness (Left):</label>
                        <p>
                          {" "}
                          {doctorFeedback.ImpingementL === "0"
                            ? "Negative"
                            : doctorFeedback.ImpingementL === "1"
                              ? "Positive"
                              : ""}
                        </p>
                      </div>
                    </>

                  </div>
                  <div className="treatment-row mt-3">
                    {doctorFeedback.ImpingementR == "1" && (
                      <div className="treatment-col ">
                        <label>Location (Right):</label>
                        <p>
                          {" "}
                          {doctorFeedback.ImpingementdropR === "1"
                            ? "Anteromedial"
                            : doctorFeedback.ImpingementdropR === "2"
                              ? "Anterolateral"
                              : doctorFeedback.ImpingementdropR === "3"
                                ? "Posterolateral"
                                : "None"}
                        </p>
                      </div>
                    )}
                    {doctorFeedback.ImpingementL == "1" && (
                      <>
                        <div className="treatment-col">
                          <label>Location (Left):</label>
                          <p>
                            {" "}
                            {doctorFeedback.ImpingementLdrop === "1"
                              ? "Anteromedial"
                              : doctorFeedback.ImpingementLdrop === "2"
                                ? "Anterolateral"
                                : doctorFeedback.ImpingementLdrop === "3"
                                  ? "Posterolateral"
                                  : "None"}
                          </p>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="treatment-row mt-3">

                    <div className="treatment-col">
                      <label>
                        Anterior ankle impingement test (Hyperdorsiflexion test)
                        (Right):
                      </label>
                      <p>
                        {" "}
                        {doctorFeedback.Impingement1R === "0"
                          ? "Negative"
                          : doctorFeedback.Impingement1R === "1"
                            ? "Positive"
                            : ""}
                      </p>
                    </div>

                    <div className="treatment-col">
                      <label>
                        Anterior ankle impingement test (Hyperdorsiflexion
                        test) (Left):
                      </label>
                      <p>
                        {" "}
                        {doctorFeedback.Impingement1L === "0"
                          ? "Negative"
                          : doctorFeedback.Impingement1L === "1"
                            ? "Positive"
                            : ""}
                      </p>
                    </div>

                  </div>

                  <div className="treatment-row mt-3">

                    <div className="treatment-col">
                      <label>
                        Posterior ankle impingement test (Hyperplantarflexion
                        test) (Right):
                      </label>
                      <p>
                        {" "}
                        {doctorFeedback.Impingement3R === "0"
                          ? "Negative"
                          : doctorFeedback.Impingement3R === "1"
                            ? "Positive"
                            : ""}
                      </p>
                    </div>

                    <div className="treatment-col">
                      <label>
                        Posterior ankle impingement test (Hyperplantarflexion
                        test) (Left):
                      </label>
                      <p>
                        {" "}
                        {doctorFeedback.Impingement3L === "0"
                          ? "Negative"
                          : doctorFeedback.Impingement3L === "1"
                            ? "Positive"
                            : ""}
                      </p>
                    </div>

                  </div>

                  <div className="treatment-row mt-3">

                    <div className="treatment-col">
                      <label>Ankle impingement sign (Right):</label>
                      <p>
                        {" "}
                        {doctorFeedback.Impingement4R === "0"
                          ? "Negative"
                          : doctorFeedback.Impingement4R === "1"
                            ? "Positive"
                            : ""}
                      </p>
                    </div>

                    <div className="treatment-col">
                      <label>Ankle impingement sign (Left):</label>
                      <p>
                        {" "}
                        {doctorFeedback.Impingement4L === "0"
                          ? "Negative"
                          : doctorFeedback.Impingement4L === "1"
                            ? "Positive"
                            : ""}
                      </p>
                    </div>

                  </div>
                </div>
              </div> */}

              <div className="treatment-row mt-3">
                <table className="treatment-table">
                  <tr className="treatment-row">
                    <th>Impingement</th>
                    <th>Right</th>
                    <th>Left</th>
                  </tr>
                  <tr className="treatment-row">
                    <td>Tenderness</td>
                    <td>{doctorFeedback.ImpingementR === "0" ? "Negative" : doctorFeedback.ImpingementR === "1" ? "Positive" : ""}</td>
                    <td>{doctorFeedback.ImpingementL === "0" ? "Negative" : doctorFeedback.ImpingementL === "1" ? "Positive" : ""}</td>
                  </tr>
                  {doctorFeedback.ImpingementR == "1" && (
                    <tr className="treatment-row">
                      <td>Location</td>
                      <td>
                        {doctorFeedback.ImpingementdropR === "1"
                          ? "Anteromedial"
                          : doctorFeedback.ImpingementdropR === "2"
                            ? "Anterolateral"
                            : doctorFeedback.ImpingementdropR === "3"
                              ? "Posterolateral"
                              : "None"}
                      </td>
                      <td>
                        {doctorFeedback.ImpingementLdrop === "1"
                          ? "Anteromedial"
                          : doctorFeedback.ImpingementLdrop === "2"
                            ? "Anterolateral"
                            : doctorFeedback.ImpingementLdrop === "3"
                              ? "Posterolateral"
                              : "None"}
                      </td>
                    </tr>
                  )}
                  <tr className="treatment-row">
                    <td>Anterior ankle impingement test (Hyperdorsiflexion test)</td>
                    <td>{doctorFeedback.Impingement1R === "0" ? "Negative" : doctorFeedback.Impingement1R === "1" ? "Positive" : ""}</td>
                    <td>{doctorFeedback.Impingement1L === "0" ? "Negative" : doctorFeedback.Impingement1L === "1" ? "Positive" : ""}</td>
                  </tr>
                  <tr className="treatment-row">
                    <td>Posterior ankle impingement test (Hyperplantarflexion test)</td>
                    <td>{doctorFeedback.Impingement3R === "0" ? "Negative" : doctorFeedback.Impingement3R === "1" ? "Positive" : ""}</td>
                    <td>{doctorFeedback.Impingement3L === "0" ? "Negative" : doctorFeedback.Impingement3L === "1" ? "Positive" : ""}</td>
                  </tr>
                  <tr className="treatment-row">
                    <td>Ankle impingement sign</td>
                    <td>{doctorFeedback.Impingement4R === "0" ? "Negative" : doctorFeedback.Impingement4R === "1" ? "Positive" : ""}</td>
                    <td>{doctorFeedback.Impingement4L === "0" ? "Negative" : doctorFeedback.Impingement4L === "1" ? "Positive" : ""}</td>
                  </tr>
                </table>
              </div>


              {/* <div className="treatment-row"></div> */}
              {/* <div className="treatment-row mt-3">
                <div className="treatment-table">
                  <div className="treatment-row mt-3">

                    <div className="treatment-col">
                      <label>Silfverskiold test (Right):</label>
                      <p>
                        {" "}
                        {doctorFeedback.AchillesR === "0"
                          ? "Negative"
                          : doctorFeedback.AchillesR === "1"
                            ? "Positive"
                            : ""}
                      </p>
                    </div>

                    <div className="treatment-col">
                      <label>Silfverskiold test (Left):</label>
                      <p>
                        {" "}
                        {doctorFeedback.AchillesL === "0"
                          ? "Negative"
                          : doctorFeedback.AchillesL === "1"
                            ? "Positive"
                            : ""}
                      </p>
                    </div>

                  </div>

                  <div className="treatment-row mt-3">

                    <div className="treatment-col">
                      <label>Thompson’s test (Right):</label>
                      <p>
                        {" "}
                        {doctorFeedback.Achilles2R === "0"
                          ? "Negative"
                          : doctorFeedback.Achilles2R === "1"
                            ? "Positive"
                            : ""}
                      </p>
                    </div>

                    <div className="treatment-col">
                      <label>Thompson’s test (Left):</label>
                      <p>
                        {" "}
                        {doctorFeedback.Achilles2L === "0"
                          ? "Negative"
                          : doctorFeedback.Achilles2L === "1"
                            ? "Positive"
                            : ""}
                      </p>
                    </div>

                  </div>
                  <div className="treatment-row mt-3">

                    <div className="treatment-col">
                      <label>Matles test (Right):</label>
                      <p>
                        {" "}
                        {doctorFeedback.Achilles4R === "0"
                          ? "Negative"
                          : doctorFeedback.Achilles4R === "1"
                            ? "Positive"
                            : ""}
                      </p>
                    </div>

                    <div className="treatment-col">
                      <label>Matles test (Left):</label>
                      <p>
                        {" "}
                        {doctorFeedback.Achilles4L === "0"
                          ? "Negative"
                          : doctorFeedback.Achilles4L === "1"
                            ? "Positive"
                            : ""}
                      </p>
                    </div>

                  </div>


                  <>
                    <div className="treatment-row mt-3">
                      <div className="treatment-col">
                        <label>Palpable gap (Right):</label>
                        <p>
                          {" "}
                          {doctorFeedback.Achilles6R === "0"
                            ? "Negative"
                            : doctorFeedback.Achilles6R === "1"
                              ? "Positive"
                              : ""}
                        </p>
                      </div>
                      <div className="treatment-col">
                        <label>Palpable gap (Left):</label>
                        <p>
                          {" "}
                          {doctorFeedback.Achilles6L === "0"
                            ? "Negative"
                            : doctorFeedback.Achilles6L === "1"
                              ? "Positive"
                              : ""}
                        </p>
                      </div>
                    </div>


                    <div className="treatment-row mt-3">
                      {doctorFeedback.Achilles6R === "1" && (
                        <>
                          <div className="treatment-col">
                            <label>Gap measurement (Right):</label>
                            <p>{doctorFeedback.Pal1LtextL} {doctorFeedback.Pal1LtextL ? "mm" : ""}</p>
                          </div>
                        </>
                      )}
                      {doctorFeedback.Achilles6L === "1" && (
                        <>
                          <div className="treatment-col">
                            <label>Gap measurement (Left):</label>
                            <p>{doctorFeedback.Pal1Ltext} {doctorFeedback.Pal1Ltext ? "mm" : ""}</p>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="treatment-row mt-3">
                      {doctorFeedback.Achilles6R === "1" && (
                        <>
                          <div className="treatment-col">
                            <label>
                              Distance from calcaneal insertion (Right):
                            </label>
                            <p>{doctorFeedback.Pal1RtextL} {doctorFeedback.Pal1RtextL ? "mm" : ""}</p>
                          </div>
                        </>
                      )}
                      {doctorFeedback.Achilles6L === "1" && (
                        <>
                          <div className="treatment-col">
                            <label>
                              Distance from calcaneal insertion (Left):
                            </label>
                            <p>{doctorFeedback.Pal1Rtext} {doctorFeedback.Pal1Rtext ? "mm" : ""}</p>
                          </div>
                        </>
                      )}
                    </div>

                  </>

                  <>
                    <div className="treatment-row mt-3"></div>
                    {doctorFeedback.Achilles6R === "1" && (
                      <>
                        <div className="treatment-row mt-3"></div>
                        <div className="treatment-row mt-3"></div>
                      </>
                    )}
                  </>

                </div>
              </div> */}

              <div className="treatment-row mt-3">
                <table className="treatment-table">
                  <tr className="treatment-row">
                    <th>Achilles tendon</th>
                    <th>Right</th>
                    <th>Left</th>
                  </tr>
                  <tr className="treatment-row">
                    <td>Silfverskiold test</td>
                    <td>{doctorFeedback.AchillesR === "0" ? "Negative" : doctorFeedback.AchillesR === "1" ? "Positive" : ""}</td>
                    <td>{doctorFeedback.AchillesL === "0" ? "Negative" : doctorFeedback.AchillesL === "1" ? "Positive" : ""}</td>
                  </tr>
                  <tr className="treatment-row">
                    <td>Thompson’s test</td>
                    <td>{doctorFeedback.Achilles2R === "0" ? "Negative" : doctorFeedback.Achilles2R === "1" ? "Positive" : ""}</td>
                    <td>{doctorFeedback.Achilles2L === "0" ? "Negative" : doctorFeedback.Achilles2L === "1" ? "Positive" : ""}</td>
                  </tr>
                  <tr className="treatment-row">
                    <td>Matles test</td>
                    <td>{doctorFeedback.Achilles4R === "0" ? "Negative" : doctorFeedback.Achilles4R === "1" ? "Positive" : ""}</td>
                    <td>{doctorFeedback.Achilles4L === "0" ? "Negative" : doctorFeedback.Achilles4L === "1" ? "Positive" : ""}</td>
                  </tr>
                  <tr className="treatment-row">
                    <td>Palpable gap</td>
                    <td>{doctorFeedback.Achilles6R === "0" ? "Negative" : doctorFeedback.Achilles6R === "1" ? "Positive" : ""}</td>
                    <td>{doctorFeedback.Achilles6L === "0" ? "Negative" : doctorFeedback.Achilles6L === "1" ? "Positive" : ""}</td>
                  </tr>

                  <tr className="treatment-row">
                    <td>Gap measurement</td>
                    <td>{doctorFeedback.Pal1LtextL} {doctorFeedback.Pal1LtextL ? "mm" : ""}</td>
                    <td>{doctorFeedback.Pal1Ltext} {doctorFeedback.Pal1Ltext ? "mm" : ""}</td>
                  </tr>


                  <tr className="treatment-row">
                    <td>Distance from calcaneal insertion</td>
                    <td>{doctorFeedback.Pal1RtextL} {doctorFeedback.Pal1RtextL ? "mm" : ""}</td>
                    <td>{doctorFeedback.Pal1Rtext} {doctorFeedback.Pal1Rtext ? "mm" : ""}</td>
                  </tr>

                </table>
              </div>


              {/* <div className="treatment-row"></div> */}
              {/* <div className="treatment-row mt-3">
                <div className="treatment-table">
                  <div className="treatment-row mt-3">

                    <div className="treatment-col">
                      <label>Too many toes sign (Right):</label>
                      <p>
                        {" "}
                        {doctorFeedback.DeformityR === "0"
                          ? "Negative"
                          : doctorFeedback.DeformityR === "1"
                            ? "Positive"
                            : ""}
                      </p>
                    </div>

                    <div className="treatment-col">
                      <label>Too many toes sign (Left):</label>
                      <p>
                        {" "}
                        {doctorFeedback.DeformityL === "0"
                          ? "Negative"
                          : doctorFeedback.DeformityL === "1"
                            ? "Positive"
                            : ""}
                      </p>
                    </div>

                  </div>
                  <div className="treatment-row mt-3">

                    <div className="treatment-col">
                      <label>Peek-a-boo heel sign (Right):</label>
                      <p>
                        {" "}
                        {doctorFeedback.Deformity2R === "0"
                          ? "Negative"
                          : doctorFeedback.Deformity2R === "1"
                            ? "Positive"
                            : ""}
                      </p>
                    </div>

                    <div className="treatment-col">
                      <label>Peek-a-boo heel sign (Left):</label>
                      <p>
                        {" "}
                        {doctorFeedback.Deformity2L === "0"
                          ? "Negative"
                          : doctorFeedback.Deformity2L === "1"
                            ? "Positive"
                            : ""}
                      </p>
                    </div>

                  </div>

                  <div className="treatment-row mt-3">

                    <div className="treatment-col">
                      <label>Coleman block test (Right):</label>
                      <p>
                        {" "}
                        {doctorFeedback.Deformity4R === "0"
                          ? "Negative"
                          : doctorFeedback.Deformity4R === "1"
                            ? "Positive"
                            : ""}
                      </p>
                    </div>

                    <div className="treatment-col">
                      <label>Coleman block test (Left):</label>
                      <p>
                        {" "}
                        {doctorFeedback.Deformity4L === "0"
                          ? "Negative"
                          : doctorFeedback.Deformity4L === "1"
                            ? "Positive"
                            : ""}
                      </p>
                    </div>

                  </div>

                  <div className="treatment-row mt-3">

                    <div className="treatment-col">
                      <label>Double-leg heel raise (Right):</label>
                      <p>
                        {" "}
                        {doctorFeedback.Deformity6R === "0"
                          ? "Negative"
                          : doctorFeedback.Deformity6R === "1"
                            ? "Positive"
                            : ""}
                      </p>
                    </div>

                    <div className="treatment-col">
                      <label>Double-leg heel raise (Left):</label>
                      <p>
                        {" "}
                        {doctorFeedback.Deformity6L === "0"
                          ? "Negative"
                          : doctorFeedback.Deformity6L === "1"
                            ? "Positive"
                            : ""}
                      </p>
                    </div>

                  </div>

                  <div className="treatment-row mt-3">

                    <div className="treatment-col">
                      <label>Single-leg heel raise (Right):</label>
                      <p>
                        {" "}
                        {doctorFeedback.Deformity8R === "0"
                          ? "Negative"
                          : doctorFeedback.Deformity8R === "1"
                            ? "Positive"
                            : ""}
                      </p>
                    </div>

                    <div className="treatment-col">
                      <label>Single-leg heel raise (Left):</label>
                      <p>
                        {" "}
                        {doctorFeedback.Deformity8L === "0"
                          ? "Negative"
                          : doctorFeedback.Deformity8L === "1"
                            ? "Positive"
                            : ""}
                      </p>
                    </div>

                  </div>
                </div>
              </div> */}

              <div className="treatment-row mt-3">
                <table className="treatment-table">
                  <tr className="treatment-row">
                    <th>Deformity</th>
                    <th>Right</th>
                    <th>Left</th>
                  </tr>
                  <tr className="treatment-row">
                    <td>Too many toes sign</td>
                    <td>{doctorFeedback.DeformityR === "0" ? "Negative" : doctorFeedback.DeformityR === "1" ? "Positive" : ""}</td>
                    <td>{doctorFeedback.DeformityL === "0" ? "Negative" : doctorFeedback.DeformityL === "1" ? "Positive" : ""}</td>
                  </tr>
                  <tr className="treatment-row">
                    <td>Peek-a-boo heel sign</td>
                    <td>{doctorFeedback.Deformity2R === "0" ? "Negative" : doctorFeedback.Deformity2R === "1" ? "Positive" : ""}</td>
                    <td>{doctorFeedback.Deformity2L === "0" ? "Negative" : doctorFeedback.Deformity2L === "1" ? "Positive" : ""}</td>
                  </tr>
                  <tr className="treatment-row">
                    <td>Coleman block test</td>
                    <td>{doctorFeedback.Deformity4R === "0" ? "Negative" : doctorFeedback.Deformity4R === "1" ? "Positive" : ""}</td>
                    <td>{doctorFeedback.Deformity4L === "0" ? "Negative" : doctorFeedback.Deformity4L === "1" ? "Positive" : ""}</td>
                  </tr>
                  <tr className="treatment-row">
                    <td>Double-leg heel raise</td>
                    <td>{doctorFeedback.Deformity6R === "0" ? "Negative" : doctorFeedback.Deformity6R === "1" ? "Positive" : ""}</td>
                    <td>{doctorFeedback.Deformity6L === "0" ? "Negative" : doctorFeedback.Deformity6L === "1" ? "Positive" : ""}</td>
                  </tr>
                  <tr className="treatment-row">
                    <td>Single-leg heel raise</td>
                    <td>{doctorFeedback.Deformity8R === "0" ? "Negative" : doctorFeedback.Deformity8R === "1" ? "Positive" : ""}</td>
                    <td>{doctorFeedback.Deformity8L === "0" ? "Negative" : doctorFeedback.Deformity8L === "1" ? "Positive" : ""}</td>
                  </tr>
                </table>
              </div>


              {/* <div className="treatment-table">
                <div className="treatment-row-header">Midfoot</div>
                <div className="treatment-row mt-3">
                  
                  <div className="treatment-col">
                    <label>Alignment (Right):</label>
                    <p>
                      {" "}
                      {doctorFeedback.MidfootR === "1"
                        ? "Good, plantigrade foot, midfoot well aligned"
                        : doctorFeedback.MidfootR === "2"
                          ? "Fair, plantigrade foot, some degree of midfoot malalignment observed, no symptoms"
                          : doctorFeedback.MidfootR === "3"
                            ? "Poor, nonplantigrade foot, severe malalignment, symptoms"
                            : ""}
                    </p>
                  </div>
                  
                  <div className="treatment-col">
                    <label>Alignment (Left):</label>
                    <p>
                      {" "}
                      {doctorFeedback.MidfootL === "1"
                        ? "Good, plantigrade foot, midfoot well aligned"
                        : doctorFeedback.MidfootL === "2"
                          ? "Fair, plantigrade foot, some degree of midfoot malalignment observed, no symptoms"
                          : doctorFeedback.MidfootL === "3"
                            ? "Poor, nonplantigrade foot, severe malalignment, symptoms"
                            : ""}
                    </p>
                  </div>
                 
                </div>
              </div> */}

              <div className="treatment-row mt-3">
                <table className="treatment-table">
                  <tr className="treatment-row-header">
                    <th>MIDFOOT</th>
                    <th>Right</th>
                    <th>Left</th>
                  </tr>
                  <tr >
                    <td>Alignment</td>
                    <td>
                      {doctorFeedback.MidfootR === "1"
                        ? "Good, plantigrade foot, midfoot well aligned"
                        : doctorFeedback.MidfootR === "2"
                          ? "Fair, plantigrade foot, some degree of midfoot malalignment observed, no symptoms"
                          : doctorFeedback.MidfootR === "3"
                            ? "Poor, nonplantigrade foot, severe malalignment, symptoms"
                            : ""}
                    </td>
                    <td>
                      {doctorFeedback.MidfootL === "1"
                        ? "Good, plantigrade foot, midfoot well aligned"
                        : doctorFeedback.MidfootL === "2"
                          ? "Fair, plantigrade foot, some degree of midfoot malalignment observed, no symptoms"
                          : doctorFeedback.MidfootL === "3"
                            ? "Poor, nonplantigrade foot, severe malalignment, symptoms"
                            : ""}
                    </td>
                  </tr>
                </table>
              </div>




              <div className="treatment-row mt-3">

                <div className="col">

                  <>
                    {/* <div className="treatment-row-header">Hallux</div> */}


                    {/* <div className="treatment-row mt-3">
                      <div className="treatment-col">
                        <label>Alignment (Right)</label>

                        {doctorFeedback.AlignmentR === "1"
                          ? "Good, hallux well aligned"
                          : doctorFeedback.AlignmentR === "2"
                            ? "Fair, some degree of hallux malalignment observed, no symptoms"
                            : doctorFeedback.AlignmentR === "3"
                              ? "Poor, severe malalignment, symptoms"
                              : ""}
                      </div>
                      <div className="treatment-col">
                        <label>Alignment (Left)</label>
                        <p>
                          {doctorFeedback.AlignmentL === "1"
                            ? "Good, hallux well aligned"
                            : doctorFeedback.AlignmentL === "2"
                              ? "Fair, some degree of hallux malalignment observed, no symptoms"
                              : doctorFeedback.AlignmentL === "3"
                                ? "Poor, severe malalignment, symptoms"
                                : ""}
                        </p>
                      </div>
                    </div>
                    <div className="treatment-row mt-3">
                      <div className="treatment-col">
                        <label>Callus (Right)</label>{" "}
                        {doctorFeedback.AlignmentradioL === "0"
                          ? "Absent"
                          : doctorFeedback.AlignmentradioL === "1"
                            ? "Present"
                            : ""}
                        {doctorFeedback.AlignmentradioL === "1" && (
                          <p>

                            {doctorFeedback.Alignmentdrop === "1"
                              ? "Asymptomatic"
                              : doctorFeedback.Alignmentdrop === "2"
                                ? "Symptomatic"
                                : ""}
                          </p>
                        )}

                      </div>
                      <div className="treatment-col">
                        <label>Callus (Left)</label>
                        <p>
                          {doctorFeedback.Alignmen1L === "0"
                            ? "Absent"
                            : doctorFeedback.Alignmen1L === "1"
                              ? "Present"
                              : ""}
                          {doctorFeedback.Alignmen1L === "1" && (
                            <p>
                              {doctorFeedback.Alignmen1drop === "1"
                                ? "Asymptomatic"
                                : doctorFeedback.Alignmen1drop === "2"
                                  ? "Symptomatic"
                                  : ""}
                            </p>
                          )}

                        </p>
                      </div>
                    </div> */}

                    <div className="treatment-row mt-3">
                      <table className="treatment-table">
                        <tr>
                          <th></th>
                          <th>Right</th>
                          <th>Left</th>
                        </tr>
                        <tr className="treatment-row-header">
                          <th>Hallux</th>
                          <th></th>
                          <th></th>
                        </tr>

                        <tr >
                          <td>Alignment</td>
                          <td>
                            {doctorFeedback.AlignmentR === "1"
                              ? "Good, hallux well aligned"
                              : doctorFeedback.AlignmentR === "2"
                                ? "Fair, some degree of hallux malalignment observed, no symptoms"
                                : doctorFeedback.AlignmentR === "3"
                                  ? "Poor, obvious symptomatic alignment"
                                  : ""}
                          </td>
                          <td>
                            {doctorFeedback.AlignmentL === "1"
                              ? "Good, hallux well aligned"
                              : doctorFeedback.AlignmentL === "2"
                                ? "Fair, some degree of hallux malalignment observed, no symptoms"
                                : doctorFeedback.AlignmentL === "3"
                                  ? "Poor, obvious symptomatic alignment"
                                  : ""}
                          </td>
                        </tr>
                        <tr >
                          <td>Callus</td>
                          <td>
                            {doctorFeedback.AlignmentradioL === "0"
                              ? "Absent"
                              : doctorFeedback.AlignmentradioL === "1"
                                ? "Present"
                                : ""}
                          </td>
                          <td>
                            {doctorFeedback.Alignmen1L === "0"
                              ? "Absent"
                              : doctorFeedback.Alignmen1L === "1"
                                ? "Present"
                                : ""}
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            {doctorFeedback.Alignmentdrop === "1" ? " Asymptomatic" : doctorFeedback.Alignmentdrop === "2" ? " Symptomatic" : ""}
                          </td>
                          <td>
                            {doctorFeedback.Alignmen1drop === "1" ? " Asymptomatic" : doctorFeedback.Alignmen1drop === "2" ? " Symptomatic" : ""}
                          </td>
                        </tr>
                      </table>
                    </div>

                  </>
                </div>


              </div>
              {/* <div className="whitebox padding-none">
                <div className="">
                  <div className="treatment-row-header">Motion</div>
                  <div className="treatment-row mt-3">
                    <div className="treatment-table">
                      <div className="treatment-row mt-3">

                        <div className="treatment-col">
                          <label>
                            MTP Joint (Dorsiflexion plus Plantarflexion) Right:
                          </label>
                          {doctorFeedback.Motion2Rtext !== null && (
                            <p>Active: {doctorFeedback.Motion2Rtext} Degrees</p>
                          )}
                          {doctorFeedback.Motion2Ltext !== null && (
                            <p>
                              Passive: {doctorFeedback.Motion2Ltext} Degrees
                            </p>
                          )}
                        </div>
                        
                        <div className="treatment-col">
                          <label>MTP Joint (Dorsiflexion plus Plantarflexion) Left:</label>
                          {doctorFeedback.Motion2LRtext !== null && (
                            <p>
                              Active: {doctorFeedback.Motion2LRtext} Degrees
                            </p>
                          )}
                          {doctorFeedback.Motion2LLtext !== null && (
                            <p>
                              Passive: {doctorFeedback.Motion2LLtext} Degrees
                            </p>
                          )}
                        </div>
                        
                      </div>

                      <div className="treatment-row mt-3">
                       
                        <div className="treatment-col">
                          <label>
                            IP Joint (Plantarflexion) Right:
                          </label>
                          {doctorFeedback.Motion23Rtext !== null && (
                            <p>
                              Active: {doctorFeedback.Motion23Rtext} Degrees
                            </p>
                          )}
                          {doctorFeedback.Motion24Rtext !== null && (
                            <p>
                              Passive: {doctorFeedback.Motion24Rtext} Degrees
                            </p>
                          )}
                        </div>
                       
                        <div className="treatment-col">
                          <label>IP Joint (Plantarflexion) Left:</label>
                          {doctorFeedback.Motion23RRtext !== null && (
                            <p>
                              Active: {doctorFeedback.Motion23RRtext} Degrees
                            </p>
                          )}
                          {doctorFeedback.Motion23RLtext !== null && (
                            <p>
                              Passive: {doctorFeedback.Motion23RLtext} Degrees
                            </p>
                          )}
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="whitebox padding-none">
                <div className="">
                  {/* <div className="treatment-row-header"></div> */}
                  <div className="treatment-row mt-3">
                    <div className="treatment-table">
                      <table className="treatment-table">
                        <tr className="treatment-row mt-3">
                          <th>Motion</th>
                          <th>Right</th>
                          <th>Left</th>
                        </tr>
                        <tr className="treatment-row mt-3">
                          <td>MTP Joint (Dorsiflexion plus Plantarflexion)</td>
                          <td>
                            {doctorFeedback.Motion2Rtext !== null && (
                              <p>Active: {doctorFeedback.Motion2Rtext} Degrees</p>
                            )}
                            {doctorFeedback.Motion2Ltext !== null && (
                              <p>Passive: {doctorFeedback.Motion2Ltext} Degrees</p>
                            )}
                          </td>
                          <td>
                            {doctorFeedback.Motion2LRtext !== null && (
                              <p>Active: {doctorFeedback.Motion2LRtext} Degrees</p>
                            )}
                            {doctorFeedback.Motion2LLtext !== null && (
                              <p>Passive: {doctorFeedback.Motion2LLtext} Degrees</p>
                            )}
                          </td>
                        </tr>
                        <tr className="treatment-row mt-3">
                          <th>IP Joint (Plantarflexion)</th>
                          <th>Right</th>
                          <th>Left</th>
                        </tr>
                        <tr className="treatment-row mt-3">
                          <td></td>
                          <td>
                            {doctorFeedback.Motion23Rtext !== null && (
                              <p>Active: {doctorFeedback.Motion23Rtext} Degrees</p>
                            )}
                            {doctorFeedback.Motion24Rtext !== null && (
                              <p>Passive: {doctorFeedback.Motion24Rtext} Degrees</p>
                            )}
                          </td>
                          <td>
                            {doctorFeedback.Motion23RRtext !== null && (
                              <p>Active: {doctorFeedback.Motion23RRtext} Degrees</p>
                            )}
                            {doctorFeedback.Motion23RLtext !== null && (
                              <p>Passive: {doctorFeedback.Motion23RLtext} Degrees</p>
                            )}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>


              {/* <div className="treatment-row-header"></div> */}
              {/* <div className="treatment-row mt-3">
                <div className="treatment-col">
                  <label>MTP-IP, in all directions (Right)</label>
                  {doctorFeedback.Alignmen2R === "0"
                    ? "Stable"
                    : doctorFeedback.Alignmen2R === "1"
                      ? "Unstable"
                      : ""}
                </div>

                <div className="treatment-col">
                  <label>MTP-IP, in all directions (Left)</label>
                  <p>
                    {doctorFeedback.Alignmen2L === "0"
                      ? "Stable"
                      : doctorFeedback.Alignmen2L === "1"
                        ? "Unstable"
                        : ""}
                  </p>
                </div>
              </div> */}

              <div className="treatment-row mt-3">
                <div className="treatment-table">
                  <table className="treatment-table">
                    <tr className="treatment-row mt-3">
                      <th>STABILITY</th>
                      <th>Right</th>
                      <th>Left</th>
                    </tr>
                    <tr className="treatment-row mt-3">
                      <td>MTP-IP, in all directions</td>
                      <td>
                        {doctorFeedback.Alignmen2R === "0" ? "Stable" : doctorFeedback.Alignmen2R === "1" ? "Unstable" : ""}
                      </td>
                      <td>{doctorFeedback.Alignmen2L === "0" ? "Stable" : doctorFeedback.Alignmen2L === "1" ? "Unstable" : ""}</td>

                    </tr>

                  </table>
                </div>
              </div>



              {/* <div className="treatment-row mt-3">
                <div className="col">
                  <>
                    <div className="treatment-row-header">2nd Toe</div>
                    <div className="treatment-row mt-3">
                      <div className="treatment-col">
                        <label>Alignment (Right)</label>
                        {doctorFeedback.LesserR2 === "1"
                          ? "Good, lesser toes well aligned"
                          : doctorFeedback.LesserR2 === "2"
                            ? "Fair, some degree of lesser toe malalignment observed, no symptoms"
                            : doctorFeedback.LesserR2 === "3"
                              ? "Poor, severe malalignment, symptoms"
                              : ""}
                      </div>
                      <div className="treatment-col">
                        <label>Alignment(Left)</label>
                        {doctorFeedback.LesserL2 === "1"
                          ? "Good, lesser toes well aligned"
                          : doctorFeedback.LesserL2 === "2"
                            ? "Fair, some degree of lesser toe malalignment observed, no symptoms"
                            : doctorFeedback.LesserL2 === "3"
                              ? "Poor, severe malalignment, symptoms"
                              : ""}
                      </div>
                    </div>
                    <div className="treatment-row mt-3">
                      <div className="treatment-col">
                        <label>Callus(Right)</label>
                        {doctorFeedback.LesserradioR2 === "0"
                          ? "Absent"
                          : doctorFeedback.LesserradioR2 === "1" ? "Present" : ''}
                        {doctorFeedback.LesserradioR2 === "1" && (

                          <p> {doctorFeedback.LesserrRdrop2}</p>
                        )}
                      </div>
                      <div className="treatment-col">
                        <label>Callus(Left)</label>
                        {doctorFeedback.LesserradioL2 === "0"
                          ? "Absent"
                          : doctorFeedback.LesserradioL2 === "1" ? "Present" : ''}
                        {doctorFeedback.LesserradioL2 === "1" && (
                          <p>{doctorFeedback.LesserrLdrop2}</p>
                        )}
                      </div>
                    </div>


                    <div className="whitebox padding-none">
                      <div className="">
                        <div className="treatment-row-header">Motion</div>
                        <div className="treatment-row mt-3">
                          <div className="treatment-table">
                            <div className="treatment-row mt-3">

                              <div className="treatment-col">
                                <label>
                                  MTP Joint (Dorsiflexion plus Plantarflexion) Right:
                                </label>
                                {doctorFeedback.Motion2ndRtext !== null && (
                                  <p>
                                    Active: {doctorFeedback.Motion2ndRtext}{" "}
                                    Degrees
                                  </p>
                                )}
                                {doctorFeedback.Motion2ndLtext !== null && (
                                  <p>
                                    Passive: {doctorFeedback.Motion2ndLtext}{" "}
                                    Degrees
                                  </p>
                                )}
                              </div>

                              <div className="treatment-col">
                                <label>
                                  MTP Joint (Dorsiflexion plus Plantarflexion) Left:
                                </label>
                                {doctorFeedback.Motion2ndLRtext !== null && (
                                  <p>
                                    Active: {doctorFeedback.Motion2ndLRtext}{" "}
                                    Degrees
                                  </p>
                                )}
                                {doctorFeedback.Motion2ndLLtext !== null && (
                                  <p>
                                    Passive: {doctorFeedback.Motion2ndLLtext}{" "}
                                    Degrees
                                  </p>
                                )}
                              </div>

                            </div>

                            <div className="treatment-row mt-3">

                              <div className="treatment-col">
                                <label>
                                  IP Joint (Plantarflexion) Right:
                                </label>
                                {doctorFeedback.Motion2nRtext !== null && (
                                  <p>
                                    Active: {doctorFeedback.Motion2nRtext}{" "}
                                    Degrees
                                  </p>
                                )}
                                {doctorFeedback.Motion2nlRtext !== null && (
                                  <p>
                                    Passive: {doctorFeedback.Motion2nlRtext}{" "}
                                    Degrees
                                  </p>
                                )}
                              </div>
                              
                              <div className="treatment-col">
                                <label>
                                  IP Joint (Plantarflexion) Left:
                                </label>
                                {doctorFeedback.Motion2ndRRtext !== null && (
                                  <p>
                                    Active: {doctorFeedback.Motion2ndRRtext}{" "}
                                    Degrees
                                  </p>
                                )}
                                {doctorFeedback.Motion2ndRLtext !== null && (
                                  <p>
                                    Passive: {doctorFeedback.Motion2ndRLtext}{" "}
                                    Degrees
                                  </p>
                                )}
                              </div>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="treatment-row-header">STABILITY</div>
                    <div className="treatment-row mt-3">
                      <div className="treatment-col">
                        <label>MTP-IP, in all directions (Right):</label>
                        {doctorFeedback.SpecifyRradio2 === "0"
                          ? "Stable"
                          : doctorFeedback.SpecifyRradio2 === "1" ? "Unstable" : ''}
                      </div>
                      
                      <div className="treatment-col">
                        <label>MTP-IP, in all directions (Left):</label>
                        {doctorFeedback.SpecifyLradio2 === "0"
                          ? "Stable"
                          : doctorFeedback.SpecifyLradio2 === "1" ? "Unstable" : ''}
                      </div>
                      
                    </div>
                  </>
                </div>
                </div> */}

              <div className="treatment-row mt-3">
                <div className="treatment-table">
                  <table className="treatment-table">
                    <tr>
                      <th></th>
                      <th>Right</th>
                      <th>Left</th>
                    </tr>
                    <tr className="treatment-row-header mt-3">
                      <th>2nd Toe</th>
                      <th></th>
                      <th></th>
                    </tr>
                    <tr>
                      <td>Alignment</td>
                      <td>
                        {doctorFeedback.LesserR2 === "1"
                          ? "Good, lesser toes well aligned"
                          : doctorFeedback.LesserR2 === "2"
                            ? "Fair, some degree of lesser toe malalignment observed, no symptoms"
                            : doctorFeedback.LesserR2 === "3"
                              ? "Poor, severe malalignment, symptoms"
                              : ""}
                      </td>
                      <td>
                        {doctorFeedback.LesserL2 === "1"
                          ? "Good, lesser toes well aligned"
                          : doctorFeedback.LesserL2 === "2"
                            ? "Fair, some degree of lesser toe malalignment observed, no symptoms"
                            : doctorFeedback.LesserL2 === "3"
                              ? "Poor, severe malalignment, symptoms"
                              : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>Callus</td>
                      <td>
                        {doctorFeedback.LesserradioR2 === "0" ? "Absent" : doctorFeedback.LesserradioR2 === "1" ? "Present" : ""}
                        {doctorFeedback.LesserradioR2 === "1" && <p> {doctorFeedback.LesserrRdrop2}</p>}
                      </td>
                      <td>
                        {doctorFeedback.LesserradioL2 === "0" ? "Absent" : doctorFeedback.LesserradioL2 === "1" ? "Present" : ""}
                        {doctorFeedback.LesserradioL2 === "1" && <p>{doctorFeedback.LesserrLdrop2}</p>}
                      </td>
                    </tr>
                    <tr >
                      <th>2nd Toe Motion</th>
                      <th>Right</th>
                      <th>Left</th>
                    </tr>
                    <tr >
                      <td>MTP Joint (Dorsiflexion plus Plantarflexion)</td>
                      <td>
                        {doctorFeedback.Motion2ndRtext !== null && <p>Active: {doctorFeedback.Motion2ndRtext} Degrees</p>}
                        {doctorFeedback.Motion2ndLtext !== null && <p>Passive: {doctorFeedback.Motion2ndLtext} Degrees</p>}
                      </td>
                      <td>
                        {doctorFeedback.Motion2ndLRtext !== null && <p>Active: {doctorFeedback.Motion2ndLRtext} Degrees</p>}
                        {doctorFeedback.Motion2ndLLtext !== null && <p>Passive: {doctorFeedback.Motion2ndLLtext} Degrees</p>}
                      </td>
                    </tr>
                    <tr >
                      <td>IP Joint (Plantarflexion)</td>
                      <td>
                        {doctorFeedback.Motion2nRtext !== null && <p>Active: {doctorFeedback.Motion2nRtext} Degrees</p>}
                        {doctorFeedback.Motion2nlRtext !== null && <p>Passive: {doctorFeedback.Motion2nlRtext} Degrees</p>}
                      </td>
                      <td>
                        {doctorFeedback.Motion2ndRRtext !== null && <p>Active: {doctorFeedback.Motion2ndRRtext} Degrees</p>}
                        {doctorFeedback.Motion2ndRLtext !== null && <p>Passive: {doctorFeedback.Motion2ndRLtext} Degrees</p>}
                      </td>
                    </tr>
                    <tr >
                      <th>STABILITY</th>
                      {/* <th> Right</th>
                      <th>Left</th> */}
                    </tr>
                    <tr >
                      <td>MTP-IP, in all directions</td>
                      <td>{doctorFeedback.SpecifyRradio2 === "0" ? "Stable" : doctorFeedback.SpecifyRradio2 === "1" ? "Unstable" : ""}</td>
                      <td>{doctorFeedback.SpecifyLradio2 === "0" ? "Stable" : doctorFeedback.SpecifyLradio2 === "1" ? "Unstable" : ""}</td>
                    </tr>
                  </table>
                </div>
              </div>


              {/* <div className="treatment-row mt-3">
                <div className="col">
                  <div className="treatment-row-header">3rd Toe</div>
                  <div className="treatment-row mt-3">
                    <div className="treatment-col">
                      <label>Alignment (Right)</label>
                      {doctorFeedback.LesserR23rd === "1"
                        ? "Good, lesser toes well aligned"
                        : doctorFeedback.LesserR23rd === "2"
                          ? "Fair, some degree of lesser toe malalignment observed, no symptoms"
                          : doctorFeedback.LesserR23rd === "3"
                            ? "Poor, severe malalignment, symptoms"
                            : ""}
                    </div>
                    <div className="treatment-col">
                      <label>Alignment (Left)</label>
                      {doctorFeedback.LesserL23rd === "1"
                        ? "Good, lesser toes well aligned"
                        : doctorFeedback.LesserL23rd === "2"
                          ? "Fair, some degree of lesser toe malalignment observed, no symptoms"
                          : doctorFeedback.LesserL23rd === "3"
                            ? "Poor, severe malalignment, symptoms"
                            : ""}
                    </div>
                  </div>
                  <div className="treatment-row mt-3">
                    <div className="treatment-col">
                      <label>Callus(Right)</label>
                      {doctorFeedback.LesserradioR23rd === "0"
                        ? "Absent"
                        : doctorFeedback.LesserradioR23rd === "1" ? "Present" : ''}
                      {doctorFeedback.LesserradioR23rd === "1" && (

                        <p>{doctorFeedback.LesserrRdrop23rd}</p>
                      )}
                    </div>
                    <div className="treatment-col">
                      <label>Callus(Left)</label>
                      {doctorFeedback.LesserradioL23rd === "0"
                        ? "Absent"
                        : doctorFeedback.LesserradioL23rd === "1" ? "Present" : ''}
                      {doctorFeedback.LesserradioL23rd === "1" && (

                        <p>{doctorFeedback.LesserrLdrop23rd}</p>
                      )}
                    </div>
                  </div>
                  

                  <div className="whitebox padding-none">
                    <div className="">
                      <div className="treatment-row-header">Motion</div>
                      <div className="treatment-row mt-3">
                        <div className="treatment-table">
                          <div className="treatment-row mt-3">
                          
                            <div className="treatment-col">
                              <label>
                                MTP Joint (Dorsiflexion plus Plantarflexion) Right:
                              </label>
                              {doctorFeedback.Motion3ndRtext !== null && (
                                <p>
                                  Active: {doctorFeedback.Motion3ndRtext}{" "}
                                  Degrees
                                </p>
                              )}
                              {doctorFeedback.Motion3ndLtext !== null && (
                                <p>
                                  Passive: {doctorFeedback.Motion3ndLtext}{" "}
                                  Degrees
                                </p>
                              )}
                            </div>
                            
                          
                            <div className="treatment-col">
                              <label>
                                MTP Joint (Dorsiflexion plus Plantarflexion) Left:
                              </label>
                              {doctorFeedback.Motion3ndLRtext !== null && (
                                <p>
                                  Active: {doctorFeedback.Motion3ndLRtext}{" "}
                                  Degrees
                                </p>
                              )}
                              {doctorFeedback.Motion3ndLLtext !== null && (
                                <p>
                                  Passive: {doctorFeedback.Motion3ndLLtext}{" "}
                                  Degrees
                                </p>
                              )}
                            </div>
                            
                          </div>

                          <div className="treatment-row mt-3">
                          
                            <div className="treatment-col">
                              <label>
                                IP Joint (Plantarflexion) Right:
                              </label>
                              {doctorFeedback.Motion3nRtext !== null && (
                                <p>
                                  Active: {doctorFeedback.Motion3nRtext} Degrees
                                </p>
                              )}
                              {doctorFeedback.Motion3nlRtext !== null && (
                                <p>
                                  Passive: {doctorFeedback.Motion3nlRtext}{" "}
                                  Degrees
                                </p>
                              )}
                            </div>
                            
                          
                            <div className="treatment-col">
                              <label>
                                IP Joint (Plantarflexion) Left:
                              </label>
                              {doctorFeedback.Motion3ndRRtext !== null && (
                                <p>
                                  Active: {doctorFeedback.Motion3ndRRtext}{" "}
                                  Degrees
                                </p>
                              )}
                              {doctorFeedback.Motion3ndRLtext !== null && (
                                <p>
                                  Passive: {doctorFeedback.Motion3ndRLtext}{" "}
                                  Degrees
                                </p>
                              )}
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="treatment-row-header">STABILITY</div>
                  <div className="treatment-row mt-3">
                    <div className="treatment-col">
                      <label>MTP-IP, in all directions (Right):</label>
                      {doctorFeedback.SpecifyRradio23rd === "0"
                        ? "Stable"
                        : doctorFeedback.SpecifyRradio23rd === "1" ? "Unstable" : ''}
                    </div>
                    <div className="treatment-col">
                      <label>MTP-IP, in all directions Left</label>
                      {doctorFeedback.SpecifyLradio23rd === "0"
                        ? "Stable"
                        : doctorFeedback.SpecifyLradio23rd === "1" ? "Unstable" : ''}
                    </div>
                  </div>
                </div>
                
              </div> */}

              <div className="treatment-row mt-3">
                <div className="treatment-table">
                  <table className="treatment-table">
                    <tr>
                      <th></th>
                      <th>Right</th>
                      <th>Left</th>
                    </tr>
                    <tr className="treatment-row-header">

                      <th>3rd Toe</th>
                      <th></th>
                      <th></th>
                    </tr>



                    <tbody>
                      <tr >
                        <td>Alignment</td>
                        <td>
                          {doctorFeedback.LesserR23rd === "1"
                            ? "Good, lesser toes well aligned"
                            : doctorFeedback.LesserR23rd === "2"
                              ? "Fair, some degree of lesser toe malalignment observed, no symptoms"
                              : doctorFeedback.LesserR23rd === "3"
                                ? "Poor, severe malalignment, symptoms"
                                : ""}
                        </td>
                        <td>
                          {doctorFeedback.LesserL23rd === "1"
                            ? "Good, lesser toes well aligned"
                            : doctorFeedback.LesserL23rd === "2"
                              ? "Fair, some degree of lesser toe malalignment observed, no symptoms"
                              : doctorFeedback.LesserL23rd === "3"
                                ? "Poor, severe malalignment, symptoms"
                                : ""}
                        </td>
                      </tr>

                      <tr >
                        <td>Callus</td>
                        <td>
                          {doctorFeedback.LesserradioR23rd === "0" ? "Absent" : doctorFeedback.LesserradioR23rd === "1" ? "Present" : ""}
                          {doctorFeedback.LesserradioR23rd === "1" && <p>{doctorFeedback.LesserrRdrop23rd}</p>}
                        </td>
                        <td>
                          {doctorFeedback.LesserradioL23rd === "0" ? "Absent" : doctorFeedback.LesserradioL23rd === "1" ? "Present" : ""}
                          {doctorFeedback.LesserradioL23rd === "1" && <p>{doctorFeedback.LesserrLdrop23rd}</p>}
                        </td>
                      </tr>



                      <tr >
                        <th>Motion</th>
                      </tr>
                      <tr >
                        <td>MTP Joint (Dorsiflexion plus Plantarflexion) </td>
                        <td>
                          {doctorFeedback.Motion3ndRtext !== null && <p>Active: {doctorFeedback.Motion3ndRtext} Degrees</p>}
                          {doctorFeedback.Motion3ndLtext !== null && <p>Passive: {doctorFeedback.Motion3ndLtext} Degrees</p>}
                        </td>
                        <td>
                          {doctorFeedback.Motion3ndLRtext !== null && <p>Active: {doctorFeedback.Motion3ndLRtext} Degrees</p>}
                          {doctorFeedback.Motion3ndLLtext !== null && <p>Passive: {doctorFeedback.Motion3ndLLtext} Degrees</p>}
                        </td>
                      </tr>
                      <tr >
                        <td>IP Joint (Plantarflexion) </td>
                        <td>
                          {doctorFeedback.Motion3nRtext !== null && <p>Active: {doctorFeedback.Motion3nRtext} Degrees</p>}
                          {doctorFeedback.Motion3nlRtext !== null && <p>Passive: {doctorFeedback.Motion3nlRtext} Degrees</p>}
                        </td>
                        <td>
                          {doctorFeedback.Motion3ndRRtext !== null && <p>Active: {doctorFeedback.Motion3ndRRtext} Degrees</p>}
                          {doctorFeedback.Motion3ndRLtext !== null && <p>Passive: {doctorFeedback.Motion3ndRLtext} Degrees</p>}
                        </td>
                      </tr>
                      <tr >
                        <th>STABILITY</th>
                      </tr>
                      <tr >
                        <td>MTP-IP, in all directions</td>
                        <td>{doctorFeedback.SpecifyRradio23rd === "0" ? "Stable" : doctorFeedback.SpecifyRradio23rd === "1" ? "Unstable" : ""}</td>

                        <td>{doctorFeedback.SpecifyLradio23rd === "0" ? "Stable" : doctorFeedback.SpecifyLradio23rd === "1" ? "Unstable" : ""}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>


              {/* <div className="treatment-row mt-3">
                <div className="col">
                  <div className="treatment-row-header">4th Toe</div>
                  <div className="treatment-row mt-3">
                    <div className="treatment-col">
                      <label>Alignment (Right)</label>
                      {doctorFeedback.LesserR24th === "1"
                        ? "Good, lesser toes well aligned"
                        : doctorFeedback.LesserR24th === "2"
                          ? "Fair, some degree of lesser toe malalignment observed, no symptoms"
                          : doctorFeedback.LesserR24th === "3"
                            ? "Poor, severe malalignment, symptoms"
                            : ""}
                    </div>
                    <div className="treatment-col">
                      <label>Alignment (Left)</label>
                      {doctorFeedback.LesserL24th === "1"
                        ? "Good, lesser toes well aligned"
                        : doctorFeedback.LesserL24th === "2"
                          ? "Fair, some degree of lesser toe malalignment observed, no symptoms"
                          : doctorFeedback.LesserL24th === "3"
                            ? "Poor, severe malalignment, symptoms"
                            : ""}
                    </div>
                  </div>
                  <div className="treatment-row mt-3">
                    <div className="treatment-col">
                      <label>Callus(Right)</label>
                      {doctorFeedback.LesserradioR24th === "0"
                        ? "Absent"
                        : doctorFeedback.LesserradioR24th === "1" ? "Present" : ''}
                      {doctorFeedback.LesserradioR24th === "1" && (

                        <p> {doctorFeedback.LesserrRdrop24th}</p>
                      )}
                    </div>
                    <div className="treatment-col">
                      <label>Callus(Left)</label>
                      {doctorFeedback.LesserradioL24th === "0"
                        ? "Absent"
                        : doctorFeedback.LesserradioL24th === "1" ? "Present" : ''}
                      {doctorFeedback.LesserradioL24th === "1" && (

                        <p>{doctorFeedback.LesserrLdrop24th}</p>
                      )}
                    </div>
                  </div>
                  

                  <div className="whitebox padding-none">
                    <div className="">
                      <div className="treatment-row-header">Motion</div>
                      <div className="treatment-row mt-3">
                        <div className="treatment-table">
                          <div className="treatment-row mt-3">
                           
                            <div className="treatment-col">
                              <label>
                                MTP Joint (Dorsiflexion plus Plantarflexion) Right:
                              </label>
                              {doctorFeedback.Motion4ndRtext !== null && (
                                <p>
                                  Active: {doctorFeedback.Motion4ndRtext}{" "}
                                  Degrees
                                </p>
                              )}
                              {doctorFeedback.Motion4ndLtext !== null && (
                                <p>
                                  Passive: {doctorFeedback.Motion4ndLtext}{" "}
                                  Degrees
                                </p>
                              )}
                            </div>
                            
                            
                            <div className="treatment-col">
                              <label>
                                MTP Joint (Dorsiflexion plus Plantarflexion) Left:
                              </label>
                              {doctorFeedback.Motion4ndLRtext !== null && (
                                <p>
                                  Active: {doctorFeedback.Motion4ndLRtext}{" "}
                                  Degrees
                                </p>
                              )}
                              {doctorFeedback.Motion4ndLLtext !== null && (
                                <p>
                                  Passive: {doctorFeedback.Motion4ndLLtext}{" "}
                                  Degrees
                                </p>
                              )}
                            </div>
                            
                          </div>

                          <div className="treatment-row mt-3">
                            
                            <div className="treatment-col">
                              <label>
                                IP Joint (Plantarflexion) Right:
                              </label>
                              {doctorFeedback.Motion4nRtext !== null && (
                                <p>
                                  Active: {doctorFeedback.Motion4nRtext} Degrees
                                </p>
                              )}
                              {doctorFeedback.Motion4nlRtext !== null && (
                                <p>
                                  Passive: {doctorFeedback.Motion4nlRtext}{" "}
                                  Degrees
                                </p>
                              )}
                            </div>
                            
                            
                            <div className="treatment-col">
                              <label>
                                IP Joint (Plantarflexion) Left:
                              </label>
                              {doctorFeedback.Motion4ndRRtext !== null && (
                                <p>
                                  Active: {doctorFeedback.Motion4ndRRtext}{" "}
                                  Degrees
                                </p>
                              )}
                              {doctorFeedback.Motion4ndRLtext !== null && (
                                <p>
                                  Passive: {doctorFeedback.Motion4ndRLtext}{" "}
                                  Degrees
                                </p>
                              )}
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="treatment-row-header">STABILITY</div>
                  <div className="treatment-row mt-3">
                    <div className="treatment-col">
                      <label>MTP-IP, in all directions (Right):</label>
                      {doctorFeedback.SpecifyRradio24th === "0"
                        ? "Stable"
                        : doctorFeedback.SpecifyRradio24th === "1" ? "Unstable" : ''}
                    </div>
                    <div className="treatment-col">
                      <label>MTP-IP, in all directions (Left):</label>
                      {doctorFeedback.SpecifyLradio24th === "0"
                        ? "Stable"
                        : doctorFeedback.SpecifyLradio24th === "1" ? "Unstable" : ''}
                    </div>
                  </div>
                </div>
                </div> */}

              <div className="treatment-row mt-3">
                <div className="treatment-table">
                  <table className="treatment-table">
                    <tr>
                      <th></th>
                      <th>Right</th>
                      <th>Left</th>
                    </tr>
                    <tr className="treatment-row-header mt-3">
                      <th>4th Toe</th>
                      <th></th>
                      <th></th>
                    </tr>
                    <tr >
                      <td>Alignment </td>
                      <td>
                        {doctorFeedback.LesserR24th === "1"
                          ? "Good, lesser toes well aligned"
                          : doctorFeedback.LesserR24th === "2"
                            ? "Fair, some degree of lesser toe malalignment observed, no symptoms"
                            : doctorFeedback.LesserR24th === "3"
                              ? "Poor, severe malalignment, symptoms"
                              : ""}
                      </td>
                      <td>
                        {doctorFeedback.LesserL24th === "1"
                          ? "Good, lesser toes well aligned"
                          : doctorFeedback.LesserL24th === "2"
                            ? "Fair, some degree of lesser toe malalignment observed, no symptoms"
                            : doctorFeedback.LesserL24th === "3"
                              ? "Poor, severe malalignment, symptoms"
                              : ""}
                      </td>
                    </tr>
                    <tr >
                      <td>Callus </td>
                      <td>
                        {doctorFeedback.LesserradioR24th === "0" ? "Absent" : doctorFeedback.LesserradioR24th === "1" ? "Present" : ""}
                        {doctorFeedback.LesserradioR24th === "1" && <p> {doctorFeedback.LesserrRdrop24th}</p>}
                      </td>
                      <td>
                        {/* <label>Callus (Left)</label> */}
                        {doctorFeedback.LesserradioL24th === "0" ? "Absent" : doctorFeedback.LesserradioL24th === "1" ? "Present" : ""}
                        {doctorFeedback.LesserradioL24th === "1" && <p>{doctorFeedback.LesserrLdrop24th}</p>}
                      </td>
                    </tr>
                    <tr >
                      <th>Motion</th>
                    </tr>
                    <tr >
                      <td>MTP Joint (Dorsiflexion plus Plantarflexion) </td>
                      <td>
                        {doctorFeedback.Motion4ndRtext !== null && <p>Active: {doctorFeedback.Motion4ndRtext} Degrees</p>}
                        {doctorFeedback.Motion4ndLtext !== null && <p>Passive: {doctorFeedback.Motion4ndLtext} Degrees</p>}
                      </td>
                      <td>
                        {doctorFeedback.Motion4ndLRtext !== null && <p>Active: {doctorFeedback.Motion4ndLRtext} Degrees</p>}
                        {doctorFeedback.Motion4ndLLtext !== null && <p>Passive: {doctorFeedback.Motion4ndLLtext} Degrees</p>}
                      </td>
                    </tr>
                    <tr >
                      <td>IP Joint (Plantarflexion) </td>
                      <td>
                        {doctorFeedback.Motion4nRtext !== null && <p>Active: {doctorFeedback.Motion4nRtext} Degrees</p>}
                        {doctorFeedback.Motion4nlRtext !== null && <p>Passive: {doctorFeedback.Motion4nlRtext} Degrees</p>}
                      </td>
                      <td>
                        {doctorFeedback.Motion4ndRRtext !== null && <p>Active: {doctorFeedback.Motion4ndRRtext} Degrees</p>}
                        {doctorFeedback.Motion4ndRLtext !== null && <p>Passive: {doctorFeedback.Motion4ndRLtext} Degrees</p>}
                      </td>
                    </tr>
                    <tr >
                      <th>STABILITY</th>
                    </tr>
                    <tr >
                      <td>MTP-IP, in all directions</td>
                      <td>{doctorFeedback.SpecifyRradio24th === "0" ? "Stable" : doctorFeedback.SpecifyRradio24th === "1" ? "Unstable" : ""}</td>

                      <td>{doctorFeedback.SpecifyLradio24th === "0" ? "Stable" : doctorFeedback.SpecifyLradio24th === "1" ? "Unstable" : ""}</td>
                    </tr>
                  </table>
                </div>
              </div>

              {/* <div className="treatment-row mt-3">
                <div className="col">
                  <div >5th Toe</div>
                  <div className="treatment-row mt-3">
                    <div className="treatment-col">
                      <label>Alignment (Right)</label>
                      {doctorFeedback.LesserR25th === "1"
                        ? "Good, lesser toes well aligned"
                        : doctorFeedback.LesserR25th === "2"
                          ? "Fair, some degree of lesser toe malalignment observed, no symptoms"
                          : doctorFeedback.LesserR25th === "3"
                            ? "Poor, severe malalignment, symptoms"
                            : ""}
                    </div>
                    <div className="treatment-col">
                      <label>Alignment (Left)</label>
                      {doctorFeedback.LesserL25th === "1"
                        ? "Good, lesser toes well aligned"
                        : doctorFeedback.LesserL25th === "2"
                          ? "Fair, some degree of lesser toe malalignment observed, no symptoms"
                          : doctorFeedback.LesserL25th === "3"
                            ? "Poor, severe malalignment, symptoms"
                            : ""}
                    </div>
                  </div>
                  <div className="treatment-row mt-3">
                    <div className="treatment-col">
                      <label>Callus(Right)</label>
                      {doctorFeedback.LesserradioR25th === "0"
                        ? "Absent"
                        : doctorFeedback.LesserradioR25th === "1" ? "Present" : ''}
                      {doctorFeedback.LesserradioR25th === "1" && (

                        <p> {doctorFeedback.LesserrRdrop25th}</p>
                      )}
                    </div>
                    <div className="treatment-col">
                      <label>Callus(Left)</label>
                      {doctorFeedback.LesserradioL25th === "0"
                        ? "Absent"
                        : doctorFeedback.LesserradioL25th === "1" ? "Present" : ''}
                      {doctorFeedback.LesserradioL25th === "1" && (

                        <p>{doctorFeedback.LesserrLdrop25th}</p>
                      )}
                    </div>
                  </div>


                  <div className="whitebox padding-none">
                    <div className="">
                      <div className="treatment-row-header">Motion</div>
                      <div className="treatment-row mt-3">
                        <div className="treatment-table">
                          <div className="treatment-row mt-3">

                            <div className="treatment-col">
                              <label>
                                MTP Joint (Dorsiflexion plus Plantarflexion) Right:
                              </label>
                              {doctorFeedback.Motion5ndRtext !== null && (
                                <p>
                                  Active: {doctorFeedback.Motion5ndRtext}{" "}
                                  Degrees
                                </p>
                              )}
                              {doctorFeedback.Motion5ndLtext !== null && (
                                <p>
                                  Passive: {doctorFeedback.Motion5ndLtext}{" "}
                                  Degrees
                                </p>
                              )}
                            </div>

                            <div className="treatment-col">
                              <label>
                                MTP Joint (Dorsiflexion plus Plantarflexion) Left:
                              </label>
                              {doctorFeedback.Motion5ndLRtext !== null && (
                                <p>
                                  Active: {doctorFeedback.Motion5ndLRtext}{" "}
                                  Degrees
                                </p>
                              )}
                              {doctorFeedback.Motion5ndLLtext !== null && (
                                <p>
                                  Passive: {doctorFeedback.Motion5ndLLtext}{" "}
                                  Degrees
                                </p>
                              )}
                            </div>

                          </div>

                          <div className="treatment-row mt-3">

                            <div className="treatment-col">
                              <label>
                                IP Joint (Plantarflexion) Right:
                              </label>
                              {doctorFeedback.Motion5nRtext !== null && (
                                <p>
                                  Active: {doctorFeedback.Motion5nRtext} Degrees
                                </p>
                              )}
                              {doctorFeedback.Motion5nlRtext !== null && (
                                <p>
                                  Passive: {doctorFeedback.Motion5nlRtext}{" "}
                                  Degrees
                                </p>
                              )}
                            </div>

                            <div className="treatment-col">
                              <label>
                                IP Joint (Plantarflexion) Left:
                              </label>
                              {doctorFeedback.Motion5ndRRtext !== null && (
                                <p>
                                  Active: {doctorFeedback.Motion5ndRRtext}{" "}
                                  Degrees
                                </p>
                              )}
                              {doctorFeedback.Motion5ndRLtext !== null && (
                                <p>
                                  Passive: {doctorFeedback.Motion5ndRLtext}{" "}
                                  Degrees
                                </p>
                              )}
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="treatment-row-header">STABILITY</div>
                  <div className="treatment-row mt-3">
                    <div className="treatment-col">
                      <label>MTP-IP, in all directions (Right):</label>
                      {doctorFeedback.SpecifyRradio25th === "0"
                        ? "Stable"
                        : doctorFeedback.SpecifyRradio25th === "1" ? "Unstable" : ''}
                    </div>
                    <div className="treatment-col">
                      <label>MTP-IP, in all directions (Left):</label>
                      {doctorFeedback.SpecifyLradio25th === "0"
                        ? "Stable"
                        : doctorFeedback.SpecifyLradio25th === "1" ? "Unstable" : ''}
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="treatment-row mt-3">
                <div className="treatment-table">
                  <table className="treatment-table">
                    <tr>
                      <th></th>
                      <th>Right</th>
                      <th>Left</th>
                    </tr>
                    <tr className="treatment-row-header mt-3">
                      <th>5th Toe</th>
                      <th></th>
                      <th></th>
                    </tr>
                    <tr >
                      <td>Alignment </td>
                      <td>
                        {doctorFeedback.LesserR25th === "1"
                          ? "Good, lesser toes well aligned"
                          : doctorFeedback.LesserR25th === "2"
                            ? "Fair, some degree of lesser toe malalignment observed, no symptoms"
                            : doctorFeedback.LesserR25th === "3"
                              ? "Poor, severe malalignment, symptoms"
                              : ""}
                      </td>
                      <td>
                        {doctorFeedback.LesserL25th === "1"
                          ? "Good, lesser toes well aligned"
                          : doctorFeedback.LesserL25th === "2"
                            ? "Fair, some degree of lesser toe malalignment observed, no symptoms"
                            : doctorFeedback.LesserL25th === "3"
                              ? "Poor, severe malalignment, symptoms"
                              : ""}
                      </td>
                    </tr>
                    <tr >
                      <td>Callus </td>
                      <td>
                        {doctorFeedback.LesserradioR25th === "0" ? "Absent" : doctorFeedback.LesserradioR25th === "1" ? "Present" : ""}
                        {doctorFeedback.LesserradioR25th === "1" && <p> {doctorFeedback.LesserrRdrop25th}</p>}
                      </td>
                      <td>
                        {/* <label>Callus (Left)</label> */}
                        {doctorFeedback.LesserradioL25th === "0" ? "Absent" : doctorFeedback.LesserradioL25th === "1" ? "Present" : ""}
                        {doctorFeedback.LesserradioL25th === "1" && <p>{doctorFeedback.LesserrLdrop25th}</p>}
                      </td>
                    </tr>
                    <tr className="treatment-row mt-3">
                      <th>Motion</th>
                    </tr>
                    <tr >
                      <td>MTP Joint (Dorsiflexion plus Plantarflexion) </td>
                      <td>
                        {doctorFeedback.Motion5ndRtext !== null && <p>Active: {doctorFeedback.Motion5ndRtext} Degrees</p>}
                        {doctorFeedback.Motion5ndLtext !== null && <p>Passive: {doctorFeedback.Motion5ndLtext} Degrees</p>}
                      </td>
                      <td>
                        {doctorFeedback.Motion5ndLRtext !== null && <p>Active: {doctorFeedback.Motion5ndLRtext} Degrees</p>}
                        {doctorFeedback.Motion5ndLLtext !== null && <p>Passive: {doctorFeedback.Motion5ndLLtext} Degrees</p>}
                      </td>
                    </tr>
                    <tr >
                      <td>IP Joint (Plantarflexion) </td>
                      <td>
                        {doctorFeedback.Motion5nRtext !== null && <p>Active: {doctorFeedback.Motion5nRtext} Degrees</p>}
                        {doctorFeedback.Motion5nlRtext !== null && <p>Passive: {doctorFeedback.Motion5nlRtext} Degrees</p>}
                      </td>
                      <td>
                        {doctorFeedback.Motion5ndRRtext !== null && <p>Active: {doctorFeedback.Motion5ndRRtext} Degrees</p>}
                        {doctorFeedback.Motion5ndRLtext !== null && <p>Passive: {doctorFeedback.Motion5ndRLtext} Degrees</p>}
                      </td>
                    </tr>
                    <tr >
                      <th>STABILITY</th>
                      <th>                      </th>
                      <th></th>
                    </tr>
                    <tr >
                      <td>MTP-IP, in all directions</td>
                      <td>{doctorFeedback.SpecifyRradio25th === "0" ? "Stable" : doctorFeedback.SpecifyRradio25th === "1" ? "Unstable" : ""}</td>

                      <td>{doctorFeedback.SpecifyLradio25th === "0" ? "Stable" : doctorFeedback.SpecifyLradio25th === "1" ? "Unstable" : ""}</td>
                    </tr>
                  </table>
                </div>
              </div>

            </div>
          </div>

          {/* <div className="treatment-row-header"> </div> */}
          {/* <div className="treatment-row mt-3">
            <div className="treatment-col">
              <label>Right Foot and Ankle:</label>
              <p>
                {doctorFeedback.ir4Right === "0"
                  ? "Absent"
                  : doctorFeedback.ir4Right === "1"
                    ? "Present"
                    : ""}
              </p>

             
              {doctorFeedback.ir4Right === "1" && (
                <>
                  {doctorFeedback.value.map((x) => (
                    <p>{x.label}</p>
                  ))}
                </>
              )}
              <p>{doctorFeedback.RDEFORMITY}</p>
            </div>
            <div className="treatment-col">
              <label>Left Foot and Ankle:</label>
              <p>
                {doctorFeedback.ir4Left === "0"
                  ? "Absent"
                  : doctorFeedback.ir4Left === "1"
                    ? "Present"
                    : ""}
              </p>
              {doctorFeedback.ir4Left === "1" && (
                <>
                  {doctorFeedback.value2.map((y) => (
                    <p>{y.label}</p>
                  ))}
                </>
              )}

              <p>{doctorFeedback.LDEFORMITY}</p>
            </div>
          </div> */}


          <div className="treatment-row mt-3">
            <div className="treatment-table">
              <table className="treatment-table">



                <tr>
                  <th>OTHER DEFORMITY</th>
                  <th>Right</th>
                  <th>Left</th>
                </tr>
                <tr>
                  <td>
                    Deformity
                  </td>
                  <td>
                    <p>
                      {doctorFeedback.ir4Right === "0" ? "Absent" : doctorFeedback.ir4Right === "1" ? "Present" : ""}
                    </p>
                    {doctorFeedback.ir4Right === "1" && doctorFeedback.value.map((x) => <p>{x.label}</p>)}
                    <p>{doctorFeedback.RDEFORMITY}</p>
                  </td>
                  <td>

                    <p>
                      {doctorFeedback.ir4Left === "0" ? "Absent" : doctorFeedback.ir4Left === "1" ? "Present" : ""}
                    </p>
                    {doctorFeedback.ir4Left === "1" && doctorFeedback.value2.map((y) => <p>{y.label}</p>)}
                    <p>{doctorFeedback.LDEFORMITY}</p>
                  </td>
                </tr>

                {/* </div>
          </div> */}



                {/* <div className="treatment-row-header">Ligamentous laxity</div>
          <div className="treatment-row mt-3">
            <div className="treatment-col">
              <label>Right Ligamentous laxity:</label>
              <p>{doctorFeedback.LigamentousR}</p>
            </div>
            <div className="treatment-col">
              <label>Left Ligamentous laxity:</label>
              <p>{doctorFeedback.LigamentousL}</p>
            </div>
          </div>
          <div className="treatment-row-header">Other tests</div>
          <div className="treatment-row mt-3">
            <div className="treatment-col">
              <label>Right Foot and Ankle:</label>
              <p>{doctorFeedback.LigamentousRtext}</p>
            </div>
            <div className="treatment-col">
              <label>Left Foot and Ankle:</label>
              <p>{doctorFeedback.LigamentousLtext}</p>
            </div>
          </div> */}
                {/* <div className="treatment-row mt-3"> */}

                <tr>
                  <th>Ligamentous laxity</th>
                  <th>Right</th>
                  <th>Left</th>
                </tr>
                <tr><td></td>
                  <td>

                    <p>{doctorFeedback.LigamentousR}</p>
                  </td>
                  <td>

                    <p>{doctorFeedback.LigamentousL}</p>
                  </td>
                </tr>

                {/* </div>
          <div className="treatment-row mt-3"> */}

                <tr>
                  <th>Other tests</th>
                  <th>Right</th>
                  <th>Left</th>
                </tr>
                <tr>
                  <td></td>
                  <td>

                    <p>{doctorFeedback.LigamentousRtext}</p>
                  </td>
                  <td>

                    <p>{doctorFeedback.LigamentousLtext}</p>
                  </td>
                </tr>

                {/* </div> */}
              </table>
            </div>
          </div>
        </div>
      </div>


      {/*  ===================Step 2 ============== */}

      {/* <div className="whitebox padding-none">

                <div className="treatment-content">
                    <div className="treatment-row-header">MOTION</div>
                    <div className="treatment-row">
                        <div className="treatment-table">
                            <table cellpadding="0" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th></th>
                                        {AllData.anklePain === "1" || AllData.anklePain === "3" ? (
                                            <th>Right Knee</th>
                                        ) : (
                                            ""
                                        )}
                                        {AllData.anklePain === "2" || AllData.anklePain === "3" ? (
                                            <th>left Knee</th>
                                        ) : (
                                            ""
                                        )}
                                    </tr>
                                </thead>
                                <tbody>

                                    <h5><b>Ankle</b></h5>
                                    <tr>
                                        <td>Dorsiflexion:</td>
                                        <td>{doctorFeedback.doraR === "0" ? "Active" : doctorFeedback.doraR === "1" ? "Passive" : ""}</td>
                                        <td>{doctorFeedback.doraL === "0" ? "Active" : doctorFeedback.doraL === "1" ? "Passive" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Plantarflexion:</td>
                                        <td>{doctorFeedback.plantR === "0" ? "Active" : doctorFeedback.plantR === "1" ? "Passive" : ""}</td>
                                        <td>{doctorFeedback.plantL === "0" ? "Active" : doctorFeedback.plantL === "1" ? "Passive" : ""}</td>
                                    </tr>


                                    <h5 className='mt-4'><b>AOFAS Hindfoot</b></h5>
                                    <tr>
                                        <td>Inversion:</td>
                                        <td>{doctorFeedback.inverR === "0" ? "Active" : doctorFeedback.doraR === "1" ? "Passive" : ""}</td>
                                        <td>{doctorFeedback.inverL === "0" ? "Active" : doctorFeedback.doraL === "1" ? "Passive" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Eversion:</td>
                                        <td>{doctorFeedback.everR === "0" ? "Active" : doctorFeedback.plantR === "1" ? "Passive" : ""}</td>
                                        <td>{doctorFeedback.everL === "0" ? "Active" : doctorFeedback.plantL === "1" ? "Passive" : ""}</td>
                                    </tr>

                                    <h5 className='mt-4'><b>MPTJ (Dorsiflexion plus plantarflexion)</b></h5>
                                    <tr>
                                        <td>Hallux :</td>
                                        <td>{doctorFeedback.halluxR === "0" ? "Active" : doctorFeedback.halluxR === "1" ? "Passive" : ""}</td>
                                        <td>{doctorFeedback.halluxL === "0" ? "Active" : doctorFeedback.halluxL === "1" ? "Passive" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>2nd:</td>
                                        <td>{doctorFeedback.hallux2R === "0" ? "Active" : doctorFeedback.hallux2R === "1" ? "Passive" : ""}</td>
                                        <td>{doctorFeedback.hallux2L === "0" ? "Active" : doctorFeedback.hallux2L === "1" ? "Passive" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>3rd :</td>
                                        <td>{doctorFeedback.hallux3R === "0" ? "Active" : doctorFeedback.hallux3R === "1" ? "Passive" : ""}</td>
                                        <td>{doctorFeedback.hallux3L === "0" ? "Active" : doctorFeedback.hallux3L === "1" ? "Passive" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>4th:</td>
                                        <td>{doctorFeedback.hallux4R === "0" ? "Active" : doctorFeedback.hallux4R === "1" ? "Passive" : ""}</td>
                                        <td>{doctorFeedback.hallux4L === "0" ? "Active" : doctorFeedback.hallux4L === "1" ? "Passive" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>5th:</td>
                                        <td>{doctorFeedback.hallux5R === "0" ? "Active" : doctorFeedback.hallux5R === "1" ? "Passive" : ""}</td>
                                        <td>{doctorFeedback.hallux5L === "0" ? "Active" : doctorFeedback.hallux5L === "1" ? "Passive" : ""}</td>
                                    </tr>

                                    <h5 className='mt-4'><b>PIPJ (Plantarflexion)</b></h5>
                                    <tr>
                                        <td>Hallux :</td>
                                        <td>{doctorFeedback.halluxRpi === "0" ? "Active" : doctorFeedback.halluxRpi === "1" ? "Passive" : ""}</td>
                                        <td>{doctorFeedback.halluxLpi === "0" ? "Active" : doctorFeedback.halluxLpi === "1" ? "Passive" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>2nd:</td>
                                        <td>{doctorFeedback.hallux2Rpi === "0" ? "Active" : doctorFeedback.hallux2Rpi === "1" ? "Passive" : ""}</td>
                                        <td>{doctorFeedback.hallux2Lpi === "0" ? "Active" : doctorFeedback.hallux2Lpi === "1" ? "Passive" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>3rd :</td>
                                        <td>{doctorFeedback.hallux3Rpi === "0" ? "Active" : doctorFeedback.hallux3Rpi === "1" ? "Passive" : ""}</td>
                                        <td>{doctorFeedback.hallux3Lpi === "0" ? "Active" : doctorFeedback.hallux3Lpi === "1" ? "Passive" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>4th:</td>
                                        <td>{doctorFeedback.hallux4Rpi === "0" ? "Active" : doctorFeedback.hallux4Rpi === "1" ? "Passive" : ""}</td>
                                        <td>{doctorFeedback.hallux4Lpi === "0" ? "Active" : doctorFeedback.hallux4Lpi === "1" ? "Passive" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>5th:</td>
                                        <td>{doctorFeedback.hallux5Rpi === "0" ? "Active" : doctorFeedback.hallux5Rpi === "1" ? "Passive" : ""}</td>
                                        <td>{doctorFeedback.hallux5Lpi === "0" ? "Active" : doctorFeedback.hallux5Lpi === "1" ? "Passive" : ""}</td>
                                    </tr>


                                    <h5 className='mt-4'><b>DIPJ (Dorsiflexion plus plantarflexion)</b></h5>
                                    <tr>
                                        <td>Hallux :</td>
                                        <td>{doctorFeedback.halluxRdo === "0" ? "Active" : doctorFeedback.halluxRdo === "1" ? "Passive" : ""}</td>
                                        <td>{doctorFeedback.halluxLdo === "0" ? "Active" : doctorFeedback.halluxLdo === "1" ? "Passive" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>2nd:</td>
                                        <td>{doctorFeedback.hallux2Rdo === "0" ? "Active" : doctorFeedback.hallux2Rdo === "1" ? "Passive" : ""}</td>
                                        <td>{doctorFeedback.hallux2Ldo === "0" ? "Active" : doctorFeedback.hallux2Ldo === "1" ? "Passive" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>3rd :</td>
                                        <td>{doctorFeedback.hallux3Rdo === "0" ? "Active" : doctorFeedback.hallux3Rdo === "1" ? "Passive" : ""}</td>
                                        <td>{doctorFeedback.hallux3Ldo === "0" ? "Active" : doctorFeedback.hallux3Ldo === "1" ? "Passive" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>4th:</td>
                                        <td>{doctorFeedback.hallux4Rdo === "0" ? "Active" : doctorFeedback.hallux4Rdo === "1" ? "Passive" : ""}</td>
                                        <td>{doctorFeedback.hallux4Ldo === "0" ? "Active" : doctorFeedback.hallux4Ldo === "1" ? "Passive" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>5th:</td>
                                        <td>{doctorFeedback.hallux5Rdo === "0" ? "Active" : doctorFeedback.hallux5Rdo === "1" ? "Passive" : ""}</td>
                                        <td>{doctorFeedback.hallux5Ldo === "0" ? "Active" : doctorFeedback.hallux5Ldo === "1" ? "Passive" : ""}</td>
                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div> */}

      {/*  ===================Step 3 ============== */}

      {/* <div className="whitebox padding-none">
                <div className="treatment-header">
                    <h2>STABILITY</h2>
                    <div className="treatment-header-right">
                        <p>Assessment Date : {AllData.assessment_date}</p>
                    </div>
                </div>
                <div className="treatment-content">
                    <div className="treatment-row mt-3">
                        <div className="treatment-col">
                            <label>
                                Ankle-hindfoot (AP, varus-valgus) (Right):
                            </label>
                            <p>{doctorFeedback.ankleR === "0" ? "Definitely unstable" : doctorFeedback.ankleR === "1" ? "Stable" : ""}</p>
                        </div>
                        <div className="treatment-col">
                            <label>
                                Ankle-hindfoot (AP, varus-valgus) (Left)
                            </label>
                            <p>{doctorFeedback.ankleL === "0" ? "Definitely unstable" : doctorFeedback.ankleL === "1" ? "Stable" : ""}</p>
                        </div>
                    </div>

                    <div className="treatment-row mt-3">
                        <div className="treatment-col">
                            <label>
                                Right Foot and Ankle:
                            </label>
                            <p>{doctorFeedback.mtpipR === "1" ? "Hallux" : doctorFeedback.mtpipR === "2" ? "2nd toe" : doctorFeedback.mtpipR === "3" ? "3rd toe" : doctorFeedback.mtpipR === "4" ? "4th toe" : doctorFeedback.mtpipR === "5" ? "5th toe" : ""}</p>
                        </div>
                        <div className="treatment-col">
                            <label>
                                Left Foot and Ankle:
                            </label>
                            <p>{doctorFeedback.mtpipL === "1" ? "Hallux" : doctorFeedback.mtpipL === "2" ? "2nd toe" : doctorFeedback.mtpipL === "3" ? "3rd toe" : doctorFeedback.mtpipL === "4" ? "4th toe" : doctorFeedback.mtpipL === "5" ? "5th toe" : ""}</p>
                        </div>
                    </div>

                    <div className="treatment-row mt-3">
                        <div className="treatment-col">
                            <label>
                                MTP-IP (Right):
                            </label>
                            <p>{doctorFeedback.mtpR === "0" ? "Callus, symptomatic" : doctorFeedback.mtpR === "1" ? "Definitely unstable or able to dislocate" : doctorFeedback.mtpR === "2" ? "Stable" : ""}</p>
                        </div>
                        <div className="treatment-col">
                            <label>
                                MTP-IP (Left)
                            </label>
                            <p>{doctorFeedback.mtpL === "0" ? "Callus, symptomatic" : doctorFeedback.mtpL === "1" ? "Definitely unstable or able to dislocate" : doctorFeedback.mtpL === "2" ? "Stable" : ""}</p>
                        </div>
                    </div>


                    <div className="treatment-row">
                        <div className="treatment-table">
                            <table cellpadding="0" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th></th>
                                        {AllData.anklePain === "1" || AllData.anklePain === "3" ? (
                                            <th>Right Knee</th>
                                        ) : (
                                            ""
                                        )}
                                        {AllData.anklePain === "2" || AllData.anklePain === "3" ? (
                                            <th>left Knee</th>
                                        ) : (
                                            ""
                                        )}
                                    </tr>
                                </thead>
                                <tbody>

                                    <h5><b>SPECIAL TESTS</b></h5>
                                    <tr>
                                        <td>Silfverskiold test:</td>
                                        <td>{doctorFeedback.silfR === "0" ? "Positive" : doctorFeedback.silfR === "1" ? "Negative" : ""}</td>
                                        <td>{doctorFeedback.silfL === "0" ? "Positive" : doctorFeedback.silfL === "1" ? "Negative" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Too many toes sign:</td>
                                        <td>{doctorFeedback.toesR === "0" ? "Positive" : doctorFeedback.toesR === "1" ? "Negative" : ""}</td>
                                        <td>{doctorFeedback.toesL === "0" ? "Positive" : doctorFeedback.toesL === "1" ? "Negative" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Peek-a-boo heel sign:</td>
                                        <td>{doctorFeedback.peekR === "0" ? "Positive" : doctorFeedback.peekR === "1" ? "Negative" : ""}</td>
                                        <td>{doctorFeedback.peekL === "0" ? "Positive" : doctorFeedback.peekL === "1" ? "Negative" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Anterior drawer test:</td>
                                        <td>{doctorFeedback.antR === "0" ? "Positive" : doctorFeedback.antR === "1" ? "Negative" : ""}</td>
                                        <td>{doctorFeedback.antL === "0" ? "Positive" : doctorFeedback.antL === "1" ? "Negative" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Posterior drawer test:</td>
                                        <td>{doctorFeedback.postR === "0" ? "Positive" : doctorFeedback.postR === "1" ? "Negative" : ""}</td>
                                        <td>{doctorFeedback.postL === "0" ? "Positive" : doctorFeedback.postL === "1" ? "Negative" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Talar tilt test Inversion:</td>
                                        <td>{doctorFeedback.inversionR === "0" ? "Positive" : doctorFeedback.inversionR === "1" ? "Negative" : ""}</td>
                                        <td>{doctorFeedback.inversionL === "0" ? "Positive" : doctorFeedback.inversionL === "1" ? "Negative" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Talar tilt test Eversion:</td>
                                        <td>{doctorFeedback.eversionR === "0" ? "Positive" : doctorFeedback.eversionR === "1" ? "Negative" : ""}</td>
                                        <td>{doctorFeedback.eversionR === "0" ? "Positive" : doctorFeedback.eversionR === "1" ? "Negative" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Calf compression or “squeeze” test:</td>
                                        <td>{doctorFeedback.calfR === "0" ? "Positive" : doctorFeedback.calfR === "1" ? "Negative" : ""}</td>
                                        <td>{doctorFeedback.calfL === "0" ? "Positive" : doctorFeedback.calfL === "1" ? "Negative" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>External rotation stress or Kleiger’s test :</td>
                                        <td>{doctorFeedback.externalR === "0" ? "Positive" : doctorFeedback.externalR === "1" ? "Negative" : ""}</td>
                                        <td>{doctorFeedback.externalL === "0" ? "Positive" : doctorFeedback.externalL === "1" ? "Negative" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Coleman block test:</td>
                                        <td>{doctorFeedback.colemanR === "0" ? "Positive" : doctorFeedback.colemanR === "1" ? "Negative" : ""}</td>
                                        <td>{doctorFeedback.colemanL === "0" ? "Positive" : doctorFeedback.colemanL === "1" ? "Negative" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Double-leg heel raise:</td>
                                        <td>{doctorFeedback.doubleR === "0" ? "Positive" : doctorFeedback.doubleR === "1" ? "Negative" : ""}</td>
                                        <td>{doctorFeedback.doubleL === "0" ? "Positive" : doctorFeedback.doubleL === "1" ? "Negative" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Single-leg heel raise:</td>
                                        <td>{doctorFeedback.singleR === "0" ? "Positive" : doctorFeedback.singleR === "1" ? "Negative" : ""}</td>
                                        <td>{doctorFeedback.singleL === "0" ? "Positive" : doctorFeedback.singleL === "1" ? "Negative" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Thompson’s test:</td>
                                        <td>{doctorFeedback.thompR === "0" ? "Positive" : doctorFeedback.thompR === "1" ? "Negative" : ""}</td>
                                        <td>{doctorFeedback.thompL === "0" ? "Positive" : doctorFeedback.thompL === "1" ? "Negative" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Mulder’s test:</td>
                                        <td>{doctorFeedback.mulderR === "0" ? "Positive" : doctorFeedback.mulderR === "1" ? "Negative" : ""}</td>
                                        <td>{doctorFeedback.mulderL === "0" ? "Positive" : doctorFeedback.mulderL === "1" ? "Negative" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Posterior crunch test:</td>
                                        <td>{doctorFeedback.crunchR === "0" ? "Positive" : doctorFeedback.crunchR === "1" ? "Negative" : ""}</td>
                                        <td>{doctorFeedback.crunchL === "0" ? "Positive" : doctorFeedback.crunchL === "1" ? "Negative" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Ligamentous laxity (Beighton Score):</td>
                                        <td>{doctorFeedback.laxityR}</td>
                                        <td>{doctorFeedback.laxityL}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div> */}
    </>
  );
}
