import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import ResultCircleProgress from "../../../custom/result-circle-progress/ResultCircleProgress";
import Button from "../../../custom/button/Button";
import { useSelector, useDispatch } from "react-redux";
import useAxios from "../../../../axiosinstance";
import useTransition from "../../../customHooks/translations";
import Tab from "../../tab-section/ShoulderTab";
import SubmitModel from "../../SubmissionModel/SubmitModel";
import Share from "../../Share";
import {
  updateShoulder,
  updateShoulderPainFieldScore,
  updateShoulderPainScores,
  updateShoulderFunctionScores,
  updateWOSIFunctionScores,
  updateSACSFunctionScores,
  updateSatisfactionFunctionScores,
  updateTotalScores,
} from "../../../../redux/actions/shoulderAction";
import NameCustom from "../../../custom/name/NameCustom";
function Result() {
  const { rId } = useParams();
  const axiosInstance = useAxios();
  const shoulserScore = useSelector(
    (state) => state.shoulderScore.shoulserScore
  );
  const dispatch = useDispatch();
  const translation = useTransition();
  const [isLoading, setIsLoading] = useState(true);
  const [totalScore] = useSelector((state) => state.shoulderScore.TotalScores);
  const [chk, setChk] = useState(false);
  const json = useSelector((state) => state.shoulderScore);
  const [status, setStatus] = useState("");

  let type = 4;
  const [isdoctorform, setisdoctorform] = useState(Number());
  const navigate = useNavigate();
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const continueRedirect2 = (e) => {
    setDisplayConfirmationModal(true);


    // if (isdoctorform == 1) {
    //   window.open(process.env.REACT_APP_DOCTOR_URL, "patient-list");
    //   // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-list";
    // } else {
    //   window.open(process.env.REACT_APP_PATIENT_URL, "_blank");
    //   // window.location.href = process.env.REACT_APP_PATIENT_URL;
    // }
  };
  // const continueRedirect2 = (e) => {
  //   window.open(process.env.REACT_APP_PATIENT_URL, "_blank");

  // };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  const save = () => {
    axiosInstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=4`)
      .then((res) => {
        dispatch(updateShoulder(res.data.data2[0].shoulserScore));
        dispatch(updateShoulderPainScores(res.data.data2[0].painScore));
        dispatch(updateShoulderFunctionScores(res.data.data2[0].functionScore));
        dispatch(updateWOSIFunctionScores(res.data.data2[0].wosiScore));
        dispatch(updateSACSFunctionScores(res.data.data2[0].sacsScore));
        dispatch(
          updateSatisfactionFunctionScores(res.data.data2[0].safisScore)
        );
        dispatch(updateTotalScores(res.data.data2[0].TotalScores));
        setisdoctorform(res.data.data2[0].is_doctor_form);
        setChk(true);
        setStatus(res.data.data2[0].status);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };
  useEffect(() => {
    save();
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="result_screen mb_100">
          <div className="row justify-content-center">
            <div className="col-xxl-8 col-lg-10 col-md-12 col-sm-12">
              <div className="panel">
                <div className="panel-body p_25">
                  {/* <Tab/> */}
                  <div className="row">
                    <div className="col-xxl-8 col-lg-7 col-md-6 col-sm-6">
                      <h3 className="text-start f_22 ">
                        {translation.patient_shoulder_results}
                      </h3>
                      <p className="answer_txt">
                        {translation.patient_shoulder_results_text1}
                      </p>
                    </div>
                    <div className="col-xxl-4 col-lg-5 col-md-6 col-sm-6">
                      <NameCustom />
                    </div>
                  </div>

                  {status && <div className="row">
                    { status === "1" ? (
                      <div className="pb_50 text-center">
                        <Button
                          onClick={continueRedirect2}
                          value={translation.Sign_out}
                          buttonStyle="btn_fill result_submit_btn"
                        />

                      </div>
                    ) : (
                      <div className="pb_50 text-center">
                        <Share type={type} rId={rId} />
                      </div>
                    )}

                  </div>}

                  <div className="row result_scorebox">
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <>
                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                          <div className="box_d">
                            <ResultCircleProgress
                              total="10"
                              title={translation.patient_shoulder_results_pain}
                              type={
                                translation.patient_shoulder_results_selfevaluation
                              }
                              arm={translation.Left_Arm}
                              value={totalScore.pain_today_rating_left}
                              persent={totalScore.pain_today_rating_left * 10}
                              date="22/09/2022"
                              color="#ff0000"
                              min_score={0}
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <>
                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                          <div className="box_d">
                            <ResultCircleProgress
                              total="10"
                              title={translation.patient_shoulder_results_pain}
                              type={
                                translation.patient_shoulder_results_selfevaluation
                              }
                              arm={translation.Right_Arm}
                              persent={totalScore.pain_today_rating_right * 10}
                              value={totalScore.pain_today_rating_right}
                              date="22/09/2022"
                              color="#ff0000"
                              min_score={0}
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                        <div className="box_d">
                          <ResultCircleProgress
                            total="100"
                            title={translation.patient_shoulder_results_function}
                            type={
                              translation.patient_shoulder_results_selfevaluation
                            }
                            arm={translation.Left_Arm}
                            value={totalScore.ases_score_left}
                            date="22/09/2022"
                            color="linear-gradient(to top left, #33ccff 0%, #ff99cc 100%)"
                            min_score={0}
                          />
                        </div>
                      </div>
                    ) : null}
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                        <div className="box_d">
                          <ResultCircleProgress
                            total="100"
                            title={translation.patient_shoulder_results_function}
                            type={
                              translation.patient_shoulder_results_selfevaluation
                            }
                            arm={translation.Right_Arm}
                            value={totalScore.ases_score_right}
                            date="22/09/2022"
                            color="linear-gradient(to top left, #33ccff 0%, #ff99cc 100%)"
                            min_score={0}
                          />
                        </div>
                      </div>
                    ) : null}
                    {shoulserScore === "2" || shoulserScore === "3" ? (
                      <>
                        {/* {totalScore.wosiScoreLeft != 0.00 && totalScore.wosiScoreLeft !==null&& ( */}
                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                          <div className="box_d">
                            <ResultCircleProgress
                              total="100"
                              title={
                                translation.patient_shoulder_results_instability
                              }
                              type={
                                translation.patient_shoulder_results_selfevaluation
                              }
                              arm={translation.Left_Arm}
                              value={totalScore.wosiScoreLeft}
                              date="22/09/2022"
                              color="##006DFF"
                              min_score={0}
                            />
                          </div>
                        </div>
                        {/* )} */}
                      </>
                    ) : null}
                    {shoulserScore === "1" || shoulserScore === "3" ? (
                      <>
                        {/* {totalScore.wosi_score_right !== 0.00 &&totalScore.wosi_score_right !==null && ( */}
                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                          <div className="box_d">
                            <ResultCircleProgress
                              total="100"
                              title={
                                translation.patient_shoulder_results_instability
                              }
                              type={
                                translation.patient_shoulder_results_selfevaluation
                              }
                              arm={translation.Right_Arm}
                              value={totalScore.wosi_score_right}
                              date="22/09/2022"
                              color="##006DFF"
                              min_score={0}
                            />
                          </div>
                        </div>
                        {/* )} */}
                      </>
                    ) : null}
                  </div>


                </div>
              </div>
            </div>
          </div>

        </div>
      )}
      <SubmitModel showModal={displayConfirmationModal} patientID={window.btoa(sessionStorage.getItem("patient_akid"))} />
    </>
  );
}

export default Result;
