import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { validateInput } from "../../../utilities/Validator";
import degreeWidgetStyles from "./AssessmentDegree.module.css";

function AssessmentDegreeNWgative({
    title,
    value,
    validators,
    labelR,
    labelL,
    nameR,
    nameL,
    onChange,
    onChangeR,
    onChangeL,
    valueR,
    valueL,
    validator,
    validatorL,
    addview
}) {
    const [countR, setCountR] = useState(valueR);
    const [countL, setCountL] = useState(valueL);
    const [errorValR, setErrorValR] = useState(false);
    const [errorValL, setErrorValL] = useState(false);
    const [errorR, setErrorR] = useState(false);
    const [errorL, setErrorL] = useState(false);
    const [errorMsgR, setErrorMsgR] = useState("Number Should be -20 to 100");
    const [errorMsgL, setErrorMsgL] = useState("Number Should be 0 to 180");

    useEffect(() => {
        setCountR(valueR);
        setCountL(valueL);
    }, [valueR, valueL]);

    // regex for avoiding negative values
    const regex = /^\-?[1-9]\d{0,2}(\.\d*)?$/;

    const handleChangeR = (event) => {
        const { value } = event.target;
        let newValue = value;
        setErrorValR(validateInput(validators, value));
        if (newValue >= -20 && newValue <= 100.0) {
            setCountR(newValue);
            onChangeR(newValue);
            setErrorR(false);
            // console.log("✅ num is between values");
        } else {
            // console.log("⛔️ num is NOT between values");
            setCountR(-20);
            setErrorR(true);
        }
    };


    const handleKeyDown = (e) => {
        // Allow only numeric keys and some special keys
        if (
          !/^\d$/.test(e.key) && // Allow digits
          e.key !== 'Backspace' && // Allow Backspace
          e.key !== 'ArrowLeft' && // Allow Arrow Left
          e.key !== 'ArrowRight' && // Allow Arrow Right
          e.key !== 'Delete' && // Allow Delete
          e.key !== 'Tab' // Allow Tab
        ) {
          e.preventDefault();
        }
      };
    // Create handleIncrement event handler
    const handleIncrementR = () => {
        if (addview === "knee") {
            setCountR(countR >= 180 ? 180 :  Number(countR) + 1);
            onChangeR(countR >= 180 ? 180 : Number(countR) + 1);

        } if (addview === undefined) {
            if (isNaN(countR) && countR === "") {
                setCountR(-20);
            } else {
                setCountR(countR >= 100 ? 100 : parseFloat(countR) + 1);
                onChangeR(countR >= 100 ? 100 : parseFloat(countR) + 1);


            }
        }

    };

    //Create handleDecrement event handler
    const handleDecrementR = () => {

        setCountR(countR <= -20 ? -20 : parseFloat(countR) - 1);
        onChangeR(countR <= -20 ? -20 : parseFloat(countR) - 1);
    };

    const handleChangeL = (event) => {
        const { value } = event.target;
        let newValue = value;
        setErrorValL(validateInput(validators, value));

        if (newValue >= 0 && newValue <= 180.0) {
            setCountL(parseFloat(newValue));
            onChangeL(parseFloat(newValue));
            setErrorL(false);
            // console.log("✅ num is between values");
        } else {
            // console.log("⛔️ num is NOT between values");
            setCountL(0);
            setErrorL(true);
        }
    };

    // Create handleIncrement event handler
    const handleIncrementL = () => {
        if(isNaN(countL)){
            setCountL(1)
        }else{
            setCountL(countL >= 180 ? 180 : Number(countL) + 1);
            onChangeL(countL >= 180 ? 180 : Number(countL) + 1);
        }
       
    };

    //Create handleDecrement event handler
    const handleDecrementL = () => {
        setCountL(countL <= 0 ? 0 : countL - 1);
        onChangeL(countL <= 0 ? 0 : countL - 1);

    };

    return (
        <>
            <div className={`${degreeWidgetStyles.input_box_main}`}>
                <h4 className={`${degreeWidgetStyles.title_txt}`}>{title}</h4>
                <div className={`${degreeWidgetStyles.number_box_row}`}>
                    <div className={`${degreeWidgetStyles.number_box_col}`}>
                        <label className={`${degreeWidgetStyles.requiredFieldRightF}`}>
                            {labelR}
                        </label>
                        <div className={`${degreeWidgetStyles.degree_box}`}>
                            <span
                                className={`${degreeWidgetStyles.qty_minus}`}
                                onClick={handleDecrementR}
                            >
                                -
                            </span>
                            <input
                                type="number"
                                max={100}
                                min={-20}
                                step={0.01}
                                className="qty trim"
                                name={nameR}
                                value={countR}
                                placeholder="000"
                                onChange={handleChangeR}
                                onBlur={handleChangeR}
                                onKeyDown={handleKeyDown}
                            />
                            <span
                                className={`${degreeWidgetStyles.qty_plus}`}
                                onClick={handleIncrementR}
                            >
                                +
                            </span>
                        </div>

                        <span className={`${degreeWidgetStyles.degree_txt}`}>Degrees</span>
                        {/* {valueR ==0 || valueR==null  &&(
              <> */}
                        {errorR && (
                            <span className={`${degreeWidgetStyles.ValidationErrors}`}>
                                {errorMsgR}
                            </span>
                        )}
                        {/* </>
            )} */}

                        {valueR == "" || valueR == null ? (
                            <>
                                {validator === true && (
                                    <span className={`${degreeWidgetStyles.ValidationErrors}`}>
                                        This field is required
                                    </span>
                                )}
                            </>
                        ) : null}

                        {errorValR && (
                            <span className="text-danger">{errorValR.message}</span>
                        )}
                    </div>
                    <span className="mt-4">
                        <div className={`${degreeWidgetStyles.number_box_col}`}>
                            <label className={`${degreeWidgetStyles.requiredFieldRightF}`}>
                                to
                            </label>
                        </div>
                    </span>
                    <div className={`${degreeWidgetStyles.number_box_col}`}>
                        <label className={`${degreeWidgetStyles.requiredFieldLeft}`}>
                            {labelL}
                        </label>
                        <div className={`${degreeWidgetStyles.degree_box}`}>
                            <span
                                className={`${degreeWidgetStyles.qty_minus}`}
                                onClick={handleDecrementL}
                            >
                                -
                            </span>
                            <input
                                type="number"
                                max={100}
                                min={-20}
                                step={0.01}
                                className="qty trim"
                                name={nameL}
                                value={countL}
                                placeholder="000"
                                onChange={handleChangeL}
                                onBlur={handleChangeL}
                            />
                            <span
                                className={`${degreeWidgetStyles.qty_plus}`}
                                onClick={handleIncrementL}
                            >
                                +
                            </span>
                        </div>
                        <span className={`${degreeWidgetStyles.degree_txt}`}>Degrees</span>
                        {valueL !== "" && (
                            <>
                                {errorL && (
                                    <span className={`${degreeWidgetStyles.ValidationErrors}`}>
                                        {errorMsgL}
                                    </span>
                                )}
                            </>
                        )}

                        {valueL == "" || valueL == null ? (
                            <>
                                {validatorL === true && (
                                    <span className={`${degreeWidgetStyles.ValidationErrors}`}>
                                        This field is required
                                    </span>
                                )}
                            </>
                        ) : null}
                        {errorValL && (
                            <span className="text-danger">{errorValL.message}</span>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

AssessmentDegreeNWgative.propTypes = {
    validators: PropTypes.array,
};

AssessmentDegreeNWgative.defaultProps = {
    value: "",
    title: "",
    labelR: "",
    labelL: "",
    nameR: "",
    nameL: "",
    validators: [],
};

export default AssessmentDegreeNWgative;
