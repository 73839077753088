import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import RangeSlider from "../../../custom/range-slider/RangeSlider";
import HipRangeSlider from "../../../custom/hip-range-slider/HipRangeSlider";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import {
  updateHipQOLFieldScore,
  updateHIPQOLScores,
  updateHIPfunctionScores,
  updateHIPPainScores,
  updateHIPSymptomsScores,
  updateHipScores,
  updateHIPSatisfactionScores,
  updateHipFeedbackAll,
  updateAllData,
} from "../../../../redux/actions/hipAction";
import useAxios from "../../../../axiosinstance";
import useTranslation from "../../../customHooks/translations";
import HipTab from "../../tab-section/HipTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import KneeScoreRangeSlider from "../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import { LeftSide, LeftTrans, RightSide, RigthTrans } from "../../../../utilities/RightLeftFormat";
import NameCustom from "../../../custom/name/NameCustom";

function HipQualityOfLife() {
  const translation = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosInstance = useAxios();
  const [aware, setAware] = useState(0);
  const [awareL, setAwareL] = useState(0);
  const [modified, setModified] = useState(0);
  const [modifiedL, setModifiedL] = useState(0);
  const [concerned, setConcerned] = useState(0);
  const [concernedL, setConcernedL] = useState(0);
  const [troubled, setTroubled] = useState(0);
  const [troubledL, setTroubledL] = useState(0);
  const [distraction, setDistration] = useState(0);
  const [distractionL, setDistrationL] = useState(0);
  const [difficulty, setDifficulty] = useState(0);
  const [difficultyL, setDifficultyL] = useState(0);
  const [depressed, setDepressed] = useState(0);
  // const [depressedL, setDepressedL] = useState(0);
  const [health, setHealth] = useState("100");
  const [initialAssesment, setInitialAssesment] = useState(0);

  // const [healthL, setHealthL] = useState("100");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [updateTotal, setUpdateTotal] = useState(false);
  const [chk, setChk] = useState(false);
  const evlHipPain = useSelector((state) => state.hipScoreReducer.evlHipPain);
  const qolScore = useSelector((state) => state.hipScoreReducer.qolScore[0]);
  // console.log(functionScore)
  const json = useSelector((state) => state.hipScoreReducer);

  useEffect(() => {
    setAware(qolScore.aware);
    setAwareL(qolScore.awareL);
    setModified(qolScore.modified);
    setModifiedL(qolScore.modifiedL);
    setConcerned(qolScore.concerned);
    setConcernedL(qolScore.concernedL);
    setTroubled(qolScore.troubled);
    setTroubledL(qolScore.troubledL);
    setDistration(qolScore.distraction);
    setDistrationL(qolScore.distractionL);
    setDifficulty(qolScore.difficulty);
    setDifficultyL(qolScore.difficultyL);
    setDepressed(qolScore.depressed);
    // setDepressedL(qolScore.depressedL);
    setHealth(qolScore.health);
    // setHealthL(qolScore.healthL);
  }, [qolScore]);
  const save = () => {
    axiosInstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=6`)
      .then((res) => {
        setInitialAssesment(res.data.data2[0].initial_assessment);
        dispatch(updateHIPPainScores(res.data.data2[0].painScore));
        dispatch(updateHIPSymptomsScores(res.data.data2[0].symptomsScore));
        dispatch(updateHipScores(res.data.data2[0].evlHipPain));
        dispatch(updateHIPfunctionScores(res.data.data2[0].functionScore));
        dispatch(updateHIPQOLScores(res.data.data2[0].qolScore));
        dispatch(
          updateHIPSatisfactionScores(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateHipFeedbackAll(res.data.data2[0].doctorFeedback));
        setChk(true);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };
  useEffect(() => {
    save();
  }, []);

  const updateDate = () => {
    axiosInstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [qolScore, updateTotal]);

  const handleAware = (e) => {
    dispatch(
      updateHipQOLFieldScore({
        key: "aware",
        value: e,
      })
    );
    setAware(e);
  };
  const handleAwareL = (e) => {
    dispatch(
      updateHipQOLFieldScore({
        key: "awareL",
        value: e,
      })
    );
    setAwareL(e);
  };
  const handleModified = (e) => {
    dispatch(
      updateHipQOLFieldScore({
        key: "modified",
        value: e,
      })
    );
    setModified(e);
  };
  const handleModifiedL = (e) => {
    dispatch(
      updateHipQOLFieldScore({
        key: "modifiedL",
        value: e,
      })
    );
    setModifiedL(e);
  };
  const handleConcerned = (e) => {
    dispatch(
      updateHipQOLFieldScore({
        key: "concerned",
        value: e,
      })
    );
    setConcerned(e);
  };
  const handleConcernedL = (e) => {
    dispatch(
      updateHipQOLFieldScore({
        key: "concernedL",
        value: e,
      })
    );
    setConcernedL(e);
  };
  const handleTroubled = (e) => {
    dispatch(
      updateHipQOLFieldScore({
        key: "troubled",
        value: e,
      })
    );
    setTroubled(e);
  };
  const handleTroubledL = (e) => {
    dispatch(
      updateHipQOLFieldScore({
        key: "troubledL",
        value: e,
      })
    );
    setTroubledL(e);
  };
  const handleDistraction = (e) => {
    dispatch(
      updateHipQOLFieldScore({
        key: "distraction",
        value: e,
      })
    );
    setDistration(e);
  };
  const handleDistractionL = (e) => {
    dispatch(
      updateHipQOLFieldScore({
        key: "distractionL",
        value: e,
      })
    );
    setDistrationL(e);
  };
  const handleDifficulty = (e) => {
    dispatch(
      updateHipQOLFieldScore({
        key: "difficulty",
        value: e,
      })
    );
    setDifficulty(e);
  };
  const handleDifficultyL = (e) => {
    dispatch(
      updateHipQOLFieldScore({
        key: "difficultyL",
        value: e,
      })
    );
    setDifficultyL(e);
  };
  const handleDepressed = (e) => {
    dispatch(
      updateHipQOLFieldScore({
        key: "depressed",
        value: e,
      })
    );
    setDepressed(e);
  };
  // const handleDepressedL = (e) => {
  //   dispatch(
  //     updateHipQOLFieldScore({
  //       key: "depressedL",
  //       value: e,
  //     })
  //   );
  //   setDepressedL(e);
  // };
  const handleHealth = (e) => {
    dispatch(
      updateHipQOLFieldScore({
        key: "health",
        value: e,
      })
    );
    setHealth(e);
  };
  // const handleHealthL = (e) => {
  //   dispatch(
  //     updateHipQOLFieldScore({
  //       key: "healthL",
  //       value: e,
  //     })
  //   );
  //   setHealthL(e);
  // };
  const continueRedirect = (e) => {
    dispatch(
      updateHIPQOLScores([
        {
          aware: aware,
          awareL: awareL,
          modified: modified,
          modifiedL: modifiedL,
          concerned: concerned,
          concernedL: concernedL,
          troubled: troubled,
          troubledL: troubledL,
          distraction: distraction,
          distractionL: distractionL,
          difficulty: difficulty,
          difficultyL: difficultyL,
          depressed: depressed,
          // depressedL: depressedL,
          health: health,
          // healthL: healthL,
        },
      ])
    );

      navigate("/hip-satisfaction/" + rId);
    
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);


  let akpt = searchParams.get("akpt");
  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update);
    setmsgType("success");
     setTimeout(() => {
     let view_circular=  sessionStorage.getItem("view_circular")
      // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
      // window.location.href = process.env.REACT_APP_DOCTOR_URL +`view-management-plan/${akpt}/${window.btoa(
      //   rId
      // )}/?org=${window.btoa(sessionStorage.getItem("orgid")||'')}&formType=${
      //   window.btoa('6') || ""
      // }&view_circular=${view_circular==='false'?'false':'true'}`
      window.location.href = process.env.REACT_APP_DOCTOR_URL +`patient-details/${akpt}?formid=6`
      setMsg('')
      setmsgType('success')
    }, 2000);
  };

  const handleNext=()=>{
    navigate(`/hip-satisfaction/${rId}?d=true&akpt=${akpt}`);

  }

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
          <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <HipTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />
            
              <h4 className="ph_title f_40">{translation.patient_hip_quality_of_life}</h4>

              <div className="function_formtop">
                <div className="row">
                  <div className="col-md-12 col-sm-12 function_head">
                    <label>{translation.patient_hip_quality_of_life_instruction}</label> <br />
                    {/* <span>
                      (<strong>0</strong> = {translation.none},{" "}
                      <strong>10</strong> ={" "}
                      {translation.Extreme_Constantly_Extremely} )
                    </span> */}
                  </div>
                </div>
                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={`mb_20 ${evlHipPain === "3" ? "col-sm-6 col-12" : ""}`+RightSide()}>
                      <RangeSlider
                        id="hipPer"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        leftTitle={translation.patient_hip_quality_of_life_q3_a1}
                        rightTitle={translation.patient_hip_quality_of_life_q3_a2}
                        label={translation.patient_hip_quality_of_life_q3 + RigthTrans(evlHipPain, translation)}
                        value={concerned}
                        onChange={handleConcerned}
                      />
                    </div>

                  ) : (
                    ""
                  )}

                  {evlHipPain === "2" || evlHipPain === "3" ? (

                    <div className={`mb_20 ${evlHipPain === "3" ? "col-sm-6 col-12" : ""}`+LeftSide()}>
                      <RangeSlider
                        id="hipPer"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        leftTitle={translation.patient_hip_quality_of_life_q3_a1}
                        rightTitle={translation.patient_hip_quality_of_life_q3_a2}
                        label={translation.patient_hip_quality_of_life_q3 + LeftTrans(evlHipPain, translation)}
                        value={concernedL}
                        onChange={handleConcernedL}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>


                <div className="row mt-5">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={`mb_20 ${evlHipPain === "3" ? "col-sm-6 col-12" : ""}`+RightSide()}>
                      <RangeSlider
                        id="hipPer"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        leftTitle={translation.patient_hip_quality_of_life_q5_a1}
                        rightTitle={translation.patient_hip_quality_of_life_q5_a2}
                        label={translation.patient_hip_quality_of_life_q5 + RigthTrans(evlHipPain, translation)}
                        value={distraction}
                        onChange={handleDistraction}
                      />
                    </div>

                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={`mb_20 ${evlHipPain === "3" ? "col-sm-6 col-12" : ""}`+LeftSide()}>
                      <RangeSlider
                        id="hipPer"
                        min="0"
                        max="10"
                        step="1"
                        type="text"
                        leftTitle={translation.patient_hip_quality_of_life_q5_a1}
                        rightTitle={translation.patient_hip_quality_of_life_q5_a2}
                        label={translation.patient_hip_quality_of_life_q5 + LeftTrans(evlHipPain, translation)}
                        value={distractionL}
                        onChange={handleDistractionL}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row mt-5">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={`mb_20 ${evlHipPain === "3" ? "col-sm-6 col-12" : ""}`+RightSide()}>

                      <KneeScoreRangeSlider
                        label={translation.patient_hip_quality_of_life_q1 + RigthTrans(evlHipPain, translation)}
                        value={aware}
                        onChange={handleAware}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_quality_of_life_q1_a1 },
                          { score: "1", label: translation.patient_hip_quality_of_life_q1_a2 },
                          { score: "2", label: translation.patient_hip_quality_of_life_q1_a3 },
                          { score: "3", label: translation.patient_hip_quality_of_life_q1_a4 },
                          { score: "4", label: translation.patient_hip_quality_of_life_q1_a5 },
                        ]}
                      />

                      {/* <RangeSlider
                        id="hipPer"
                        min="0"
                        max="10"
                        step="1"
                        hipSlider="1"
                        // type="text"
                       
                        label={translation.patient_hip_quality_of_life_q1 + RigthTrans(evlHipPain, translation)}
                        value={aware}
                        onChange={handleAware}
                        sliderData={aware === "0"
                            ? translation.patient_hip_quality_of_life_q1_a1
                            : aware === "1"
                              ? translation.patient_hip_quality_of_life_q1_a2
                              : aware === "2"|| aware === "3"
                                ? translation.patient_hip_quality_of_life_q1_a3
                                : aware === "4" || aware === "5" || aware === "6"
                                  ? translation.patient_hip_quality_of_life_q1_a4
                                  : aware === "7" || aware === "8" || aware === "9" || aware === "10"
                                    ? translation.patient_hip_quality_of_life_q1_a5
                                    : ""}
                      /> */}

                    </div>

                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (

                    <div className={`mb_20 ${evlHipPain === "3" ? "col-sm-6 col-12" : ""}`+LeftSide()}>

                      <KneeScoreRangeSlider
                        label={translation.patient_hip_quality_of_life_q1 + LeftTrans(evlHipPain, translation)}
                        value={awareL}
                        onChange={handleAwareL}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_quality_of_life_q1_a1 },
                          { score: "1", label: translation.patient_hip_quality_of_life_q1_a2 },
                          { score: "2", label: translation.patient_hip_quality_of_life_q1_a3 },
                          { score: "3", label: translation.patient_hip_quality_of_life_q1_a4 },
                          { score: "4", label: translation.patient_hip_quality_of_life_q1_a5 },
                        ]}
                      />
                      {/* <RangeSlider
                        id="hipPer"
                        min="0"
                        max="10"
                        hipSlider="1"
                        step="1"
                        // type="text"

                        label={translation.patient_hip_quality_of_life_q1 + LeftTrans(evlHipPain, translation)}
                        value={awareL}
                        onChange={handleAwareL}
                        sliderData={awareL === "0"
                          ? translation.patient_hip_quality_of_life_q1_a1
                          : awareL === "1"
                            ? translation.patient_hip_quality_of_life_q1_a2
                            : awareL === "2" || awareL === "3"
                              ? translation.patient_hip_quality_of_life_q1_a3
                              : awareL === "4" || awareL === "5" || awareL === "6"
                                ? translation.patient_hip_quality_of_life_q1_a4
                                : awareL === "7" || awareL === "8" || awareL === "9" || awareL === "10"
                                  ? translation.patient_hip_quality_of_life_q1_a5
                                  : ""}
                      /> */}
                    </div>

                  ) : (
                    ""
                  )}
                </div>

                <div className="row mt-3">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <div className="mb_20">
                        <KneeScoreRangeSlider
                          label={translation.patient_hip_quality_of_life_q2 + RigthTrans(evlHipPain, translation)}
                          value={modified}
                          onChange={handleModified}
                          sliderData={[
                            { score: "0", label: translation.patient_hip_quality_of_life_q2_a1 },
                            { score: "1", label: translation.patient_hip_quality_of_life_q2_a2 },
                            { score: "2", label: translation.patient_hip_quality_of_life_q2_a3 },
                            { score: "3", label: translation.patient_hip_quality_of_life_q2_a4 },
                            { score: "4", label: translation.patient_hip_quality_of_life_q2_a5 },
                          ]}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <div className="mb_20">
                        <KneeScoreRangeSlider
                          label={translation.patient_hip_quality_of_life_q2 + LeftTrans(evlHipPain, translation)}
                          value={modifiedL}
                          onChange={handleModifiedL}
                          sliderData={[
                            { score: "0", label: translation.patient_hip_quality_of_life_q2_a1 },
                            { score: "1", label: translation.patient_hip_quality_of_life_q2_a2 },
                            { score: "2", label: translation.patient_hip_quality_of_life_q2_a3 },
                            { score: "3", label: translation.patient_hip_quality_of_life_q2_a4 },
                            { score: "4", label: translation.patient_hip_quality_of_life_q2_a5 },
                          ]}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>





                <div className="row mt-3">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <div className="mb_20">
                        <KneeScoreRangeSlider
                          label={translation.patient_hip_quality_of_life_q4 + RigthTrans(evlHipPain, translation)}
                          value={troubled}
                          onChange={handleTroubled}
                          sliderData={[
                            { score: "0", label: translation.patient_hip_quality_of_life_q4_a1 },
                            { score: "1", label: translation.patient_hip_quality_of_life_q4_a2 },
                            { score: "2", label: translation.patient_hip_quality_of_life_q4_a3 },
                            { score: "3", label: translation.patient_hip_quality_of_life_q4_a4 },
                            { score: "4", label: translation.patient_hip_quality_of_life_q4_a5 },
                          ]}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <div className="mb_20">
                        <KneeScoreRangeSlider
                          label={translation.patient_hip_quality_of_life_q4 + LeftTrans(evlHipPain, translation)}
                          value={troubledL}
                          onChange={handleTroubledL}
                          sliderData={[
                            { score: "0", label: translation.patient_hip_quality_of_life_q4_a1 },
                            { score: "1", label: translation.patient_hip_quality_of_life_q4_a2 },
                            { score: "2", label: translation.patient_hip_quality_of_life_q4_a3 },
                            { score: "3", label: translation.patient_hip_quality_of_life_q4_a4 },
                            { score: "4", label: translation.patient_hip_quality_of_life_q4_a5 },
                          ]}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>



                <div className="row mt-3">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+RightSide()}>
                      <div className="mb_20">
                        <KneeScoreRangeSlider
                          label={translation.patient_hip_quality_of_life_q6 + RigthTrans(evlHipPain, translation)}
                          value={difficulty}
                          onChange={handleDifficulty}
                          sliderData={[
                            { score: "0", label: translation.patient_hip_quality_of_life_q6_a1 },
                            { score: "1", label: translation.patient_hip_quality_of_life_q6_a2 },
                            { score: "2", label: translation.patient_hip_quality_of_life_q6_a3 },
                            { score: "3", label: translation.patient_hip_quality_of_life_q6_a4 },
                            { score: "4", label: translation.patient_hip_quality_of_life_q6_a5 },
                          ]}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10"+LeftSide()}>
                      <div className="mb_20">
                        <KneeScoreRangeSlider
                          label={translation.patient_hip_quality_of_life_q6 + LeftTrans(evlHipPain, translation)}
                          value={difficultyL}
                          onChange={handleDifficultyL}
                          sliderData={[
                            { score: "0", label: translation.patient_hip_quality_of_life_q6_a1 },
                            { score: "1", label: translation.patient_hip_quality_of_life_q6_a2 },
                            { score: "2", label: translation.patient_hip_quality_of_life_q6_a3 },
                            { score: "3", label: translation.patient_hip_quality_of_life_q6_a4 },
                            { score: "4", label: translation.patient_hip_quality_of_life_q6_a5 },
                          ]}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {evlHipPain === "1" || evlHipPain === "3" ? (
                  <div className="row">
                    <div className="mb_20">
                      <KneeScoreRangeSlider
                        label={translation.patient_hip_quality_of_life_q7}
                        value={depressed}
                        onChange={handleDepressed}
                        sliderData={[
                          { score: "0", label: translation.patient_hip_quality_of_life_q7_a1 },
                          { score: "1", label: translation.patient_hip_quality_of_life_q7_a2 },
                          { score: "2", label: translation.patient_hip_quality_of_life_q7_a3 },
                          { score: "3", label: translation.patient_hip_quality_of_life_q7_a4 },
                          { score: "4", label: translation.patient_hip_quality_of_life_q7_a5 },
                        ]}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {/* {evlHipPain === "2" || evlHipPain === "3" ? (
                  <div className="row">
                    <div className="mb_20">
                      <RangeSlider
                        id="hipPer"
                        min="0"
                        max="10"
                        step="1"
                        type="image"
                        label={evlHipPain === "3" ? translation.How_anxious_depressed_are_you_todayL : translation.How_anxious_depressed_are_you_today}
                        value={depressedL}
                        onChange={handleDepressedL}
                      />
                    </div>
                  </div>
                ) : ""} */}

                {evlHipPain === "1" || evlHipPain === "3" ? (
                  <div className="row">
                    <div className="mb_20">
                      <RangeSlider
                        id="hipPer"
                        min="0"
                        max="100"
                        step="1"
                        leftTitle={translation.patient_hip_quality_of_life_q8_a1}
                        rightTitle={translation.patient_hip_quality_of_life_q8_a2}
                        type="both1"
                        colorcode="1"
                        label={translation.patient_hip_quality_of_life_q8}
                        value={health}
                        onChange={handleHealth}
                        formGroupClass="label_style mb_40"
                      />
                    </div>    
                  </div>
                ) : (
                  ""
                )}

                {/* {evlHipPain === "2" || evlHipPain === "3" ? (
                  <div className="row">
                    <div className="mb_20">
                      <HipRangeSlider
                        id="hipPer"
                        min="0"
                        max="100"
                        step="1"
                        leftTitle={translation.Worst_health}
                        rightTitle={translation.Best_health}
                        type="text"
                        label={evlHipPain === "3" ?translation.How_good_bad_is_your_health_todayL:translation.How_good_bad_is_your_health_today}
                        value={healthL}
                        onChange={handleHealthL}
                        formGroupClass="label_style mb_40"
                      />
                    </div>
                  </div>
                ) : ""} */}
              </div>
            </div>
          </div>
          {doctorEnable === "true" && (
            <div className="row mb_30 button_box">
              <div className="col-md-6 col-sm-6"></div>
              <div className="col-md-6 col-sm-6 text-end">
              <button
                      className="next_btn btn_fill me-3"
                      onClick={() => handleNext()}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                <button
                  className="next_btn btn_fill"
                  onClick={() => handleSubmit()}
                >
                  {translation.submit}
                  <span>
                    <img src={nextIcon} alt="" />
                    {/* <img src={nextWhiteIcon} alt="" /> */}
                  </span>
                </button>
              </div>
            </div>
          )}
          {doctorEnable !== "true" && (
            <div className="row mb_30 button_box">
              <div className="col-md-6 col-sm-6 col-6">
                <Link to={`/hip-function/${rId}`} className="assm_back_btn">
                  <img src={backIcon} alt="" />
                  <span>{translation.back_button}</span>
                </Link>
              </div>
              <div className="col-md-6 col-sm-6 col-6 text-end">
                <button
                  className="next_btn btn_fill"
                  onClick={continueRedirect}
                >
                  {translation.next_button}
                  <span>
                    <img src={nextIcon} alt="" />
                    <img src={nextWhiteIcon} alt="" />
                  </span>
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default HipQualityOfLife;
