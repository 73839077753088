import React from "react";
import { UserDateFormat } from "../../../utilities/DateFormate";

export default function HandWristDoctor({ doctorFeedback, AllData,profileAllData }) {
  return (
    <>
      {/* {Step ============ 1} */}

      <div className="whitebox padding-none">
        <div className="treatment-header">
          <h2>Doctor Feedback Form</h2>
          <div className="treatment-header-right">
            <p>Assessment Date : {AllData.assessment_date}
              {/* {UserDateFormat(AllData.assessment_date,profileAllData.ing_date_formate)} */}
            </p>
          </div>
        </div>
        <div className="treatment-header">
          <h2>Inspection</h2>
        </div>
        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th>Right </th>
                    <th>Left </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Scars or open wounds:</td>
                    <td>{doctorFeedback.ScarsL === "0"
                      ? "Negative"
                      : doctorFeedback.ScarsL === "1"
                        ? "Positive"
                        : ""}

                    </td>
                    <td> {doctorFeedback.ScarsR === "0"
                      ? "Negative"
                      : doctorFeedback.ScarsR === "1"
                        ? "Positive"
                        : ""}

                    </td>

                  </tr>
                  <tr>
                    <td>Description:</td>
                    <td>
                      <p>{doctorFeedback.ScarsLText}</p>
                    </td>
                    <td>
                      <p>{doctorFeedback.ScarsRtext}</p>
                    </td>

                  </tr>

                  <tr>
                    <td>
                      Atrophy:
                    </td>
                    <td>
                      {doctorFeedback.AtrophyR === "0"
                        ? "Negative"
                        : doctorFeedback.AtrophyR === "1"
                          ? "Positive"
                          : ""}
                    </td>
                    <td>
                      {doctorFeedback.AtrophyL === "0"
                        ? "Negative"
                        : doctorFeedback.AtrophyL === "1"
                          ? "Positive"
                          : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Description:</td>
                    <td>
                      <p>{doctorFeedback.AtrophyRText}</p>
                    </td>
                    <td>
                      <p>{doctorFeedback.AtrophyLText}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Deformity:
                    </td>
                    <td>
                      {doctorFeedback.DeformityR === "0"
                        ? "Negative"
                        : doctorFeedback.DeformityR === "1"
                          ? "Positive"
                          : ""}
                      {doctorFeedback.DeformityR === "1" && (
                        <>
                          <p>
                            {Array.isArray(doctorFeedback.deformityRI) && doctorFeedback.deformityRI.map((item) => item.label).join(" , ")}
                            {/* {doctorFeedback.deformityRI === "2"
                              ? "Clawed but mobile hand"
                              : doctorFeedback.deformityRI === "3"
                                ? "Contracted or stiff joints"
                                : doctorFeedback.deformityRI === "4"
                                  ? "Amputation/Absorption"
                                  : doctorFeedback.deformityRI === "5"
                                    ? "Wrist drop"
                                    : doctorFeedback.deformityRI === "7"
                                      ? "Asymmetry/angulation /rotation"
                                      : doctorFeedback.deformityRI === "8"
                                        ? "Mass"
                                        : doctorFeedback.deformityRI === "9"
                                          ? "Boutonniere deformity"
                                          : doctorFeedback.deformityRI === "10"
                                            ? "Swan neck deformity"
                                            : doctorFeedback.deformityRI === "11"
                                              ? "Others"
                                              : ""} */}
                          </p>
                          {/* {doctorFeedback.deformityRI === "11" && ( */}
                          {Array.isArray(doctorFeedback.deformityRI) && doctorFeedback.deformityRI.filter((Item) => Item.value === "11").length !== 0 ? (
                            <p>{doctorFeedback.deformityTextRI}</p>
                          ) : ""}

                        </>
                      )}
                    </td>
                    <td>
                      {doctorFeedback.DeformityL === "0"
                        ? "Negative"
                        : doctorFeedback.DeformityL === "1"
                          ? "Positive"
                          : ""}
                      {doctorFeedback.DeformityL === "1" && (
                        <>
                          <p>
                            {Array.isArray(doctorFeedback.deformityLI) && doctorFeedback.deformityLI.map((item) => item.label).join(" , ")}
                            {/* {doctorFeedback.deformityLI === "2"
                              ? "Clawed but mobile hand"
                              : doctorFeedback.deformityLI === "3"
                                ? "Contracted or stiff joints"
                                : doctorFeedback.deformityLI === "4"
                                  ? "Amputation/Absorption"
                                  : doctorFeedback.deformityLI === "5"
                                    ? "Wrist drop"
                                    : doctorFeedback.deformityLI === "7"
                                      ? "Asymmetry/angulation /rotation"
                                      : doctorFeedback.deformityLI === "8"
                                        ? "Mass"
                                        : doctorFeedback.deformityLI === "9"
                                          ? "Boutonniere deformity"
                                          : doctorFeedback.deformityLI === "10"
                                            ? "Swan neck deformity"
                                            : doctorFeedback.deformityLI === "11"
                                              ? "Others"
                                              : ""} */}
                          </p>
                          {Array.isArray(doctorFeedback.deformityLI) && doctorFeedback.deformityLI.filter((Item) => Item.value === "11").length !== 0 ? (
                            <p>{doctorFeedback.deformityTextLI}</p>
                          ) : ""}
                        </>
                      )}
                    </td>
                  </tr>
                  {/* <tr>
                    <td>
                      Right deformity
                    </td>
                    <td>

                    </td>
                    <td>
                      <label>Description:</label>
                      <p>{doctorFeedback.deformityTextRI}</p>
                    </td>
                  </tr> */}
                  {/* <tr>
                    <td>
                      Left deformity
                    </td>
                    <td>
                      <p>
                        {doctorFeedback.deformityLI === "2"
                          ? "Clawed but mobile hand"
                          : doctorFeedback.deformityLI === "3"
                            ? "Contracted or stiff joints"
                            : doctorFeedback.deformityLI === "4"
                              ? "Amputation/Absorption"
                              : doctorFeedback.deformityLI === "5"
                                ? "Wrist drop"
                                : doctorFeedback.deformityLI === "7"
                                  ? "Asymmetry/angulation /rotation"
                                  : doctorFeedback.deformityLI === "8"
                                    ? "Mass"
                                    : doctorFeedback.deformityLI === "9"
                                      ? "Boutonniere deformity"
                                      : doctorFeedback.deformityLI === "10"
                                        ? "Swan neck deformity"
                                        : doctorFeedback.deformityLI === "11"
                                          ? "Others"
                                          : ""}
                      </p>
                    </td>
                    <td>
                      <label>Description:</label>
                      <p>{doctorFeedback.deformityTextLI}</p>
                    </td>
                  </tr> */}

                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="treatment-header">
          <h2>Wrist  </h2>
        </div>
        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th>Right </th>
                    <th>Left </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Active Flexion:</td>

                    {doctorFeedback.wristflexionR !== "" && (
                      <td>{doctorFeedback.wristflexionR} Degrees</td>
                    )}
                    {doctorFeedback.wristflexionL !== "" && (
                      <td>{doctorFeedback.wristflexionL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive Flexion:</td>

                    {doctorFeedback.wristflexionPR !== "" && (
                      <td>{doctorFeedback.wristflexionPR} Degrees</td>
                    )}
                    {doctorFeedback.wristflexionPL !== "" && (
                      <td>{doctorFeedback.wristflexionPL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Active Extension:</td>

                    {doctorFeedback.wristextensionR !== "" && (
                      <td>{doctorFeedback.wristextensionR} Degrees</td>
                    )}
                    {doctorFeedback.wristextensionL !== "" && (
                      <td>{doctorFeedback.wristextensionL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive Extension:</td>

                    {doctorFeedback.wristextensionPR !== "" && (
                      <td>{doctorFeedback.wristextensionPR} Degrees</td>
                    )}
                    {doctorFeedback.wristextensionPL !== "" && (
                      <td>{doctorFeedback.wristextensionPL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Active Radial Deviation:</td>

                    {doctorFeedback.radialDeviationR !== "" && (
                      <td>{doctorFeedback.radialDeviationR} Degrees</td>
                    )}
                    {doctorFeedback.radialDeviationL !== "" && (
                      <td>{doctorFeedback.radialDeviationL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive Radial Deviation:</td>

                    {doctorFeedback.radialDeviationPR !== "" && (
                      <td>{doctorFeedback.radialDeviationPR} Degrees</td>
                    )}
                    {doctorFeedback.radialDeviationPL !== "" && (
                      <td>{doctorFeedback.radialDeviationPL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Active Ulnar Deviation:</td>

                    {doctorFeedback.ulnarR !== "" && (
                      <td>{doctorFeedback.ulnarR} Degrees</td>
                    )}
                    {doctorFeedback.ulnarL !== "" && (
                      <td>{doctorFeedback.ulnarL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive Ulnar Deviation:</td>

                    {doctorFeedback.ulnarPR !== "" && (
                      <td>{doctorFeedback.ulnarPR} Degrees</td>
                    )}
                    {doctorFeedback.ulnarPL !== "" && (
                      <td>{doctorFeedback.ulnarPL} Degrees</td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="treatment-header">
          <h2>Thumb  </h2>
        </div>
        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th>Right </th>
                    <th>Left </th>
                  </tr>
                </thead>
                <tbody>
                  <h6 className="mt-2">
                    {/* <b>Thumb(Right)</b> */}
                  </h6>

                  <tr>
                    <td>Active Palmar Abduction:</td>

                    {doctorFeedback.actPalmerR !== "" && (
                      <td>{doctorFeedback.actPalmerR} Degrees</td>
                    )}
                    {doctorFeedback.actPalmerL !== "" && (
                      <td>{doctorFeedback.actPalmerL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive Palmar Abduction:</td>

                    {doctorFeedback.pasPalmerR !== "" && (
                      <td>{doctorFeedback.pasPalmerR} Degrees</td>
                    )}
                    {doctorFeedback.pasPalmerL !== "" && (
                      <td>{doctorFeedback.pasPalmerL} Degrees</td>
                    )}
                  </tr>
                  <h6 className="mt-2">
                    {/* <b>Thumb(Left)</b> */}
                  </h6>

                  <tr>
                    <td>Active Radial Abduction:</td>

                    {doctorFeedback.actRadialR !== "" && (
                      <td>{doctorFeedback.actRadialR} Degrees</td>
                    )}
                    {doctorFeedback.actRadialL !== "" && (
                      <td>{doctorFeedback.actRadialL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive Radial Abduction:</td>

                    {doctorFeedback.pasRadialR !== "" && (
                      <td>{doctorFeedback.pasRadialR} Degrees</td>
                    )}
                    {doctorFeedback.pasRadialL !== "" && (
                      <td>{doctorFeedback.pasRadialL} Degrees</td>
                    )}
                  </tr>


                  <tr>
                    <td>Active MCP Flexion:</td>

                    {doctorFeedback.MCPR !== "" && (
                      <td>{doctorFeedback.MCPR} Degrees</td>
                    )}
                    {doctorFeedback.MCPL !== "" && (
                      <td>{doctorFeedback.MCPL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive MCP Flexion:</td>

                    {doctorFeedback.LeftMCPR !== "" && (
                      <td>{doctorFeedback.LeftMCPR} Degrees</td>
                    )}
                    {doctorFeedback.LeftMCPL !== "" && (
                      <td>{doctorFeedback.LeftMCPL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Active MCP Extension:</td>

                    {doctorFeedback.actMCPR !== "" && (
                      <td>{doctorFeedback.actMCPR} Degrees</td>
                    )}
                    {doctorFeedback.actMCPL !== "" && (
                      <td>{doctorFeedback.actMCPL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive MCP Extension:</td>

                    {doctorFeedback.passLeftMCPR !== "" && (
                      <td>{doctorFeedback.passLeftMCPR} Degrees</td>
                    )}
                    {doctorFeedback.passLeftMCPL !== "" && (
                      <td>{doctorFeedback.passLeftMCPL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Active IP Flexion:</td>

                    {doctorFeedback.IPR !== "" && (
                      <td>{doctorFeedback.IPR} Degrees</td>
                    )}
                    {doctorFeedback.IPL !== "" && (
                      <td>{doctorFeedback.IPL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive IP Flexion:</td>

                    {doctorFeedback.LeftIPR !== "" && (
                      <td>{doctorFeedback.LeftIPR} Degrees</td>
                    )}
                    {doctorFeedback.LeftIPL !== "" && (
                      <td>{doctorFeedback.LeftIPL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Active IP Extension:</td>

                    {doctorFeedback.actIPR !== "" && (
                      <td>{doctorFeedback.actIPR} Degrees</td>
                    )}
                    {doctorFeedback.actIPL !== "" && (
                      <td>{doctorFeedback.actIPL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive IP Extension:</td>

                    {doctorFeedback.passLeftIPR !== "" && (
                      <td>{doctorFeedback.passLeftIPR} Degrees</td>
                    )}
                    {doctorFeedback.passLeftIPL !== "" && (
                      <td>{doctorFeedback.passLeftIPL} Degrees</td>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div >
        <div className="treatment-header">
          <h2>Index Finger  </h2>
        </div>
        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th>Right </th>
                    <th>Left </th>
                  </tr>
                </thead>
                <tbody>
                  <h6 className="mt-2">
                    {/* <b>Index finger (Right)</b> */}
                  </h6>

                  <tr>
                    <td>Active MCP Flexion:</td>

                    {doctorFeedback.IndexmcpR !== "" && (
                      <td>{doctorFeedback.IndexmcpR} Degrees</td>
                    )}
                    {doctorFeedback.IndexmcpL !== "" && (
                      <td>{doctorFeedback.IndexmcpL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive MCP Flexion:</td>

                    {doctorFeedback.LeftIndexmcpR !== "" && (
                      <td>{doctorFeedback.LeftIndexmcpR} Degrees</td>
                    )}
                    {doctorFeedback.LeftIndexmcpL !== "" && (
                      <td>{doctorFeedback.LeftIndexmcpL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Active MCP Extension:</td>

                    {doctorFeedback.ExtIndexmcpR !== "" && (
                      <td>{doctorFeedback.ExtIndexmcpR} Degrees</td>
                    )}
                    {doctorFeedback.ExtIndexmcpL !== "" && (
                      <td>{doctorFeedback.ExtIndexmcpL} Degrees</td>
                    )}
                  </tr>

                  <h6 className="mt-2">
                    {/* <b>Index finger (Left)</b> */}
                  </h6>

                  <tr>
                    <td>Passive MCP Extension:</td>

                    {doctorFeedback.LeftExtIndexmcpR !== "" && (
                      <td>{doctorFeedback.LeftExtIndexmcpR} Degrees</td>
                    )}
                    {doctorFeedback.LeftExtIndexmcpL !== "" && (
                      <td>{doctorFeedback.LeftExtIndexmcpL} Degrees</td>
                    )}
                  </tr>

                  {/* ---------- */}
                  <tr>
                    <td>Active PIP Flexion:</td>

                    {doctorFeedback.IndexpipR !== "" && (
                      <td>{doctorFeedback.IndexpipR} Degrees</td>
                    )}
                    {doctorFeedback.IndexpipL !== "" && (
                      <td>{doctorFeedback.IndexpipL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive PIP Flexion:</td>

                    {doctorFeedback.LeftIndexpipR !== "" && (
                      <td>{doctorFeedback.LeftIndexpipR} Degrees</td>
                    )}
                    {doctorFeedback.LeftIndexpipL !== "" && (
                      <td>{doctorFeedback.LeftIndexpipL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Active PIP Extension:</td>

                    {doctorFeedback.actIndexpipR !== "" && (
                      <td>{doctorFeedback.actIndexpipR} Degrees</td>
                    )}
                    {doctorFeedback.actIndexpipL !== "" && (
                      <td>{doctorFeedback.actIndexpipL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive PIP Extension:</td>

                    {doctorFeedback.passLeftIndexpipR !== "" && (
                      <td>{doctorFeedback.passLeftIndexpipR} Degrees</td>
                    )}
                    {doctorFeedback.passLeftIndexpipL !== "" && (
                      <td>{doctorFeedback.passLeftIndexpipL} Degrees</td>
                    )}
                  </tr>
                  {/* ---- */}




                  <tr>
                    <td>Active DIP Flexion:</td>

                    {doctorFeedback.IndexdipR !== "" && (
                      <td>{doctorFeedback.IndexdipR} Degrees</td>
                    )}
                    {doctorFeedback.IndexdipL !== "" && (
                      <td>{doctorFeedback.IndexdipL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive DIP Flexion:</td>

                    {doctorFeedback.LeftIndexdipR !== "" && (
                      <td>{doctorFeedback.LeftIndexdipR} Degrees</td>
                    )}
                    {doctorFeedback.LeftIndexdipL !== "" && (
                      <td>{doctorFeedback.LeftIndexdipL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Active DIP Extension:</td>

                    {doctorFeedback.actIndexdipR !== "" && (
                      <td>{doctorFeedback.actIndexdipR} Degrees</td>
                    )}
                    {doctorFeedback.actIndexdipL !== "" && (
                      <td>{doctorFeedback.actIndexdipL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive DIP Extension:</td>

                    {doctorFeedback.passLeftIndexdipR !== "" && (
                      <td>{doctorFeedback.passLeftIndexdipR} Degrees</td>
                    )}
                    {doctorFeedback.passLeftIndexdipL !== "" && (
                      <td>{doctorFeedback.passLeftIndexdipL} Degrees</td>
                    )}
                  </tr>



                </tbody>
              </table>
            </div>
          </div>
        </div >
        <div className="treatment-header">
          <h2>Middle Finger  </h2>
        </div>
        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th>Right </th>
                    <th>Left </th>
                  </tr>
                </thead>
                <tbody>
                  <h6 className="mt-2">
                    {/* <b>Middle finger (Right)</b> */}
                  </h6>

                  <tr>
                    <td>Active MCP Flexion:</td>


                    {doctorFeedback.MiddlemcpR !== "" && (
                      <td>{doctorFeedback.MiddlemcpR} Degrees</td>
                    )}
                    {doctorFeedback.MiddlemcpL !== "" && (
                      <td>{doctorFeedback.MiddlemcpL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive MCP Flexion:</td>

                    {doctorFeedback.LeftMiddlemcpR !== "" && (
                      <td>{doctorFeedback.LeftMiddlemcpR} Degrees</td>
                    )}
                    {doctorFeedback.LeftMiddlemcpL !== "" && (
                      <td>{doctorFeedback.LeftMiddlemcpL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Active MCP Extension:</td>

                    {doctorFeedback.ActMiddlemcpR !== "" && (
                      <td>{doctorFeedback.ActMiddlemcpR} Degrees</td>
                    )}
                    {doctorFeedback.ActMiddlemcpL !== "" && (
                      <td>{doctorFeedback.ActMiddlemcpL} Degrees</td>
                    )}
                  </tr>

                  <h6 className="mt-2">
                    {/* <b>Middle finger (Left)</b> */}
                  </h6>

                  <tr>
                    <td>Passive MCP Extension:</td>

                    {doctorFeedback.PasLeftMiddlemcpR !== "" && (
                      <td>{doctorFeedback.PasLeftMiddlemcpR} Degrees</td>
                    )}
                    {doctorFeedback.PasLeftMiddlemcpL !== "" && (
                      <td>{doctorFeedback.PasLeftMiddlemcpL} Degrees</td>
                    )}
                  </tr>



                  <tr>
                    <td>Active PIP Flexion:</td>

                    {doctorFeedback.MiddlepipR !== "" && (
                      <td>{doctorFeedback.MiddlepipR} Degrees</td>
                    )}
                    {doctorFeedback.MiddlepipL !== "" && (
                      <td>{doctorFeedback.MiddlepipL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive PIP Flexion:</td>

                    {doctorFeedback.LeftMiddlepipR !== "" && (
                      <td>{doctorFeedback.LeftMiddlepipR} Degrees</td>
                    )}
                    {doctorFeedback.LeftMiddlepipL !== "" && (
                      <td>{doctorFeedback.LeftMiddlepipL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Active PIP Extension:</td>

                    {doctorFeedback.ActMiddlepipR !== "" && (
                      <td>{doctorFeedback.ActMiddlepipR} Degrees</td>
                    )}
                    {doctorFeedback.ActMiddlepipL !== "" && (
                      <td>{doctorFeedback.ActMiddlepipL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive PIP Extension:</td>

                    {doctorFeedback.pasLeftMiddlepipR !== "" && (
                      <td>{doctorFeedback.pasLeftMiddlepipR} Degrees</td>
                    )}
                    {doctorFeedback.pasLeftMiddlepipL !== "" && (
                      <td>{doctorFeedback.pasLeftMiddlepipL} Degrees</td>
                    )}
                  </tr>



                  <tr>
                    <td>Active DIP Flexion:</td>

                    {doctorFeedback.MiddledipR !== "" && (
                      <td>{doctorFeedback.MiddledipR} Degrees</td>
                    )}
                    {doctorFeedback.MiddledipL !== "" && (
                      <td>{doctorFeedback.MiddledipL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive DIP Flexion:</td>

                    {doctorFeedback.LeftMiddledipR !== "" && (
                      <td>{doctorFeedback.LeftMiddledipR} Degrees</td>
                    )}
                    {doctorFeedback.LeftMiddledipL !== "" && (
                      <td>{doctorFeedback.LeftMiddledipL} Degrees</td>
                    )}
                  </tr>


                  <tr>
                    <td>Active DIP Extension:</td>

                    {doctorFeedback.ActMiddledipR !== "" && (
                      <td>{doctorFeedback.ActMiddledipR} Degrees</td>
                    )}
                    {doctorFeedback.ActMiddledipL !== "" && (
                      <td>{doctorFeedback.ActMiddledipL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive DIP Extension:</td>

                    {doctorFeedback.pasLeftMiddledipR !== "" && (
                      <td>{doctorFeedback.pasLeftMiddledipR} Degrees</td>
                    )}
                    {doctorFeedback.pasLeftMiddledipL !== "" && (
                      <td>{doctorFeedback.pasLeftMiddledipL} Degrees</td>
                    )}
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div >

        <div className="treatment-header">
          <h2>Ring finger  </h2>
        </div>
        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th>Right </th>
                    <th>Left </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Active MCP Flexion:</td>

                    {doctorFeedback.RingmcpR !== "" && (
                      <td>{doctorFeedback.RingmcpR} Degrees</td>
                    )}
                    {doctorFeedback.RingmcpL !== "" && (
                      <td>{doctorFeedback.RingmcpL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive MCP Flexion:</td>

                    {doctorFeedback.LeftRingmcpR !== "" && (
                      <td>{doctorFeedback.LeftRingmcpR} Degrees</td>
                    )}
                    {doctorFeedback.LeftRingmcpL !== "" && (
                      <td>{doctorFeedback.LeftRingmcpL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Active MCP Extension:</td>

                    {doctorFeedback.ActRingmcpR !== "" && (
                      <td>{doctorFeedback.ActRingmcpR} Degrees</td>
                    )}
                    {doctorFeedback.ActRingmcpL !== "" && (
                      <td>{doctorFeedback.ActRingmcpL} Degrees</td>
                    )}
                  </tr>

                  <h6 className="mt-2">
                    {/* <b>Ring finger (Left)</b> */}
                  </h6>

                  <tr>
                    <td>Passive MCP Extension:</td>

                    {doctorFeedback.PasLeftRingmcpR !== "" && (
                      <td>{doctorFeedback.PasLeftRingmcpR} Degrees</td>
                    )}
                    {doctorFeedback.PasLeftRingmcpL !== "" && (
                      <td>{doctorFeedback.PasLeftRingmcpL} Degrees</td>
                    )}
                  </tr>

                  <tr>
                    <td>Active PIP Flexion:</td>

                    {doctorFeedback.RingpipR !== "" && (
                      <td>{doctorFeedback.RingpipR} Degrees</td>
                    )}
                    {doctorFeedback.RingpipL !== "" && (
                      <td>{doctorFeedback.RingpipL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive PIP Flexion:</td>

                    {doctorFeedback.LeftRingpipR !== "" && (
                      <td>{doctorFeedback.LeftRingpipR} Degrees</td>
                    )}
                    {doctorFeedback.LeftRingpipL !== "" && (
                      <td>{doctorFeedback.LeftRingpipL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Active PIP Extension:</td>

                    {doctorFeedback.ActRingpipR !== "" && (
                      <td>{doctorFeedback.ActRingpipR} Degrees</td>
                    )}
                    {doctorFeedback.ActRingpipL !== "" && (
                      <td>{doctorFeedback.ActRingpipL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive PIP Extension:</td>

                    {doctorFeedback.PasLeftRingpipR !== "" && (
                      <td>{doctorFeedback.PasLeftRingpipR} Degrees</td>
                    )}
                    {doctorFeedback.PasLeftRingpipL !== "" && (
                      <td>{doctorFeedback.PasLeftRingpipL} Degrees</td>
                    )}
                  </tr>



                  <tr>
                    <td>Active DIP Flexion:</td>

                    {doctorFeedback.ActRingdipR !== "" && (
                      <td>{doctorFeedback.ActRingdipR} Degrees</td>
                    )}
                    {doctorFeedback.ActRingdipL !== "" && (
                      <td>{doctorFeedback.ActRingdipL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive DIP Flexion:</td>

                    {doctorFeedback.PasLeftRingdipR !== "" && (
                      <td>{doctorFeedback.PasLeftRingdipR} Degrees</td>
                    )}
                    {doctorFeedback.PasLeftRingdipL !== "" && (
                      <td>{doctorFeedback.PasLeftRingdipL} Degrees</td>
                    )}
                  </tr>


                  <tr>
                    <td>Active DIP Extension:</td>

                    {doctorFeedback.Act1RingdipR !== "" && (
                      <td>{doctorFeedback.Act1RingdipR} Degrees</td>
                    )}
                    {doctorFeedback.Act1RingdipL !== "" && (
                      <td>{doctorFeedback.Act1RingdipL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive DIP Extension:</td>

                    {doctorFeedback.Pas2LeftRingdipR !== "" && (
                      <td>{doctorFeedback.Pas2LeftRingdipR} Degrees</td>
                    )}
                    {doctorFeedback.Pas2LeftRingdipL !== "" && (
                      <td>{doctorFeedback.Pas2LeftRingdipL} Degrees</td>
                    )}
                  </tr>


                </tbody>
              </table>
            </div>
          </div>
        </div >

        <div className="treatment-header">
          <h2>Small finger </h2>
        </div>
        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th>Right</th>
                    <th>Left</th>
                  </tr>
                </thead>
                <tbody>
                  <h6 className="mt-2">
                    {/* <b>Small finger (Right)</b> */}
                  </h6>

                  <tr>
                    <td>Active MCP Flexion:</td>

                    {doctorFeedback.SmallmcpR !== "" && (
                      <td>{doctorFeedback.SmallmcpR} Degrees</td>
                    )}
                    {doctorFeedback.SmallmcpL !== "" && (
                      <td>{doctorFeedback.SmallmcpL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive MCP Flexion:</td>

                    {doctorFeedback.LeftSmallmcpR !== "" && (
                      <td>{doctorFeedback.LeftSmallmcpR} Degrees</td>
                    )}
                    {doctorFeedback.LeftSmallmcpL !== "" && (
                      <td>{doctorFeedback.LeftSmallmcpL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Active MCP Extension:</td>

                    {doctorFeedback.ActSmallmcpR !== "" && (
                      <td>{doctorFeedback.ActSmallmcpR} Degrees</td>
                    )}
                    {doctorFeedback.ActSmallmcpL !== "" && (
                      <td>{doctorFeedback.ActSmallmcpL} Degrees</td>
                    )}
                  </tr>

                  <h6 className="mt-2">
                    {/* <b>Small finger (Left)</b> */}
                  </h6>

                  <tr>
                    <td>Passive MCP Extension:</td>

                    {doctorFeedback.PasLeftSmallmcpR !== "" && (
                      <td>{doctorFeedback.PasLeftSmallmcpR} Degrees</td>
                    )}
                    {doctorFeedback.PasLeftSmallmcpL !== "" && (
                      <td>{doctorFeedback.PasLeftSmallmcpL} Degrees</td>
                    )}
                  </tr>
                  {/* ---- */}
                  {/* -- */}
                  <tr>
                    <td>Active PIP Flexion:</td>

                    {doctorFeedback.SmallpipR !== "" && (
                      <td>{doctorFeedback.SmallpipR} Degrees</td>
                    )}
                    {doctorFeedback.SmallpipL !== "" && (
                      <td>{doctorFeedback.SmallpipL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive PIP Flexion:</td>

                    {doctorFeedback.LeftSmallpipR !== "" && (
                      <td>{doctorFeedback.LeftSmallpipR} Degrees</td>
                    )}
                    {doctorFeedback.LeftSmallpipL !== "" && (
                      <td>{doctorFeedback.LeftSmallpipL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Active PIP Extension:</td>

                    {doctorFeedback.ActSmallpipR !== "" && (
                      <td>{doctorFeedback.ActSmallpipR} Degrees</td>
                    )}
                    {doctorFeedback.ActSmallpipL !== "" && (
                      <td>{doctorFeedback.ActSmallpipL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive PIP Extension:</td>

                    {doctorFeedback.PasLeftSmallpipR !== "" && (
                      <td>{doctorFeedback.PasLeftSmallpipR} Degrees</td>
                    )}
                    {doctorFeedback.PasLeftSmallpipL !== "" && (
                      <td>{doctorFeedback.PasLeftSmallpipL} Degrees</td>
                    )}
                  </tr>
                  {/* -- */}

                  <tr>
                    <td>Active DIP Flexion:</td>

                    {doctorFeedback.SmalldipR !== "" && (
                      <td>{doctorFeedback.SmalldipR} Degrees</td>
                    )}
                    {doctorFeedback.SmalldipL !== "" && (
                      <td>{doctorFeedback.SmalldipL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive DIP Flexion:</td>

                    {doctorFeedback.LeftSmalldipR !== "" && (
                      <td>{doctorFeedback.LeftSmalldipR} Degrees</td>
                    )}
                    {doctorFeedback.LeftSmalldipL !== "" && (
                      <td>{doctorFeedback.LeftSmalldipL} Degrees</td>
                    )}
                  </tr>



                  <tr>
                    <td>Active DIP Extension:</td>

                    {doctorFeedback.ActSmalldipR !== "" && (
                      <td>{doctorFeedback.ActSmalldipR} Degrees</td>
                    )}
                    {doctorFeedback.ActSmalldipL !== "" && (
                      <td>{doctorFeedback.ActSmalldipL} Degrees</td>
                    )}
                  </tr>
                  <tr>
                    <td>Passive DIP Extension:</td>

                    {doctorFeedback.PasLeftSmalldipR !== "" && (
                      <td>{doctorFeedback.PasLeftSmalldipR} Degrees</td>
                    )}
                    {doctorFeedback.PasLeftSmalldipL !== "" && (
                      <td>{doctorFeedback.PasLeftSmalldipL} Degrees</td>
                    )}
                  </tr>




                </tbody>
              </table>
            </div>
          </div>
        </div >

        {/* {Step ============ 2} */}
        < div className="treatment-header" >
          <h2>Sensory Testing</h2>
        </div >
        <div className="treatment-content">
          {/* <div className="treatment-row-header">PLC</div> */}
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th>Semmes-Weinstein monofilament test (Right)</th>
                    <th>Semmes-Weinstein monofilament test (Left)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Point 1 :</td>
                    <td>{doctorFeedback.Weinstein1R} </td>
                    <td>{doctorFeedback.Weinstein1L} </td>
                  </tr>
                  <tr>
                    <td>Point 2 :</td>
                    <td>{doctorFeedback.Weinstein2R} </td>
                    <td>{doctorFeedback.Weinstein2L} </td>
                  </tr>
                  <tr>
                    <td>Point 3 :</td>
                    <td>{doctorFeedback.Weinstein3R} </td>
                    <td>{doctorFeedback.Weinstein3L} </td>
                  </tr>
                  <tr>
                    <td>Point 4 :</td>
                    <td>{doctorFeedback.Weinstein4R} </td>
                    <td>{doctorFeedback.Weinstein4L} </td>
                  </tr>
                  <tr>
                    <td>Point 5 :</td>
                    <td>{doctorFeedback.Weinstein5R} </td>
                    <td>{doctorFeedback.Weinstein5L} </td>
                  </tr>
                  <tr>
                    <td>Point 6 :</td>
                    <td>{doctorFeedback.Weinstein6R} </td>
                    <td>{doctorFeedback.Weinstein6L} </td>
                  </tr>
                  <tr>
                    <td>Point 7 :</td>
                    <td>{doctorFeedback.Weinstein7R} </td>
                    <td>{doctorFeedback.Weinstein7L} </td>
                  </tr>
                </tbody>
              </table>
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>

                    <th>2-pt discrimination (Right)</th>
                    <th>2-pt discrimination (Left)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Point 1 :</td>
                    <td>{doctorFeedback.discrimination1R !== "" ? `${doctorFeedback.discrimination1R + " mm"}` : ""} </td>
                    <td>{doctorFeedback.discrimination1L !== "" ? `${doctorFeedback.discrimination1L + " mm"}` : ""} </td>
                  </tr>
                  <tr>
                    <td>Point 2 :</td>
                    <td>{doctorFeedback.discrimination2R !== "" ? `${doctorFeedback.discrimination2R + " mm"}` : ""}</td>
                    <td>{doctorFeedback.discrimination2L !== "" ? `${doctorFeedback.discrimination2L + " mm"}` : ""}</td>
                  </tr>
                  <tr>
                    <td>Point 3 :</td>
                    <td>{doctorFeedback.discrimination3R !== "" ? `${doctorFeedback.discrimination3R + " mm"}` : ""}</td>
                    <td>{doctorFeedback.discrimination3L !== "" ? `${doctorFeedback.discrimination3L + " mm"}` : ""}</td>
                  </tr>
                  <tr>
                    <td>Point 4 :</td>
                    <td>{doctorFeedback.discrimination4R !== "" ? `${doctorFeedback.discrimination4R + " mm"}` : ""}</td>
                    <td>{doctorFeedback.discrimination4L !== "" ? `${doctorFeedback.discrimination4L + " mm"}` : ""}</td>
                  </tr>
                  <tr>
                    <td>Point 5 :</td>
                    <td>{doctorFeedback.discrimination5R !== "" ? `${doctorFeedback.discrimination5R + " mm"}` : ""}</td>
                    <td>{doctorFeedback.discrimination5L !== "" ? `${doctorFeedback.discrimination5L + " mm"}` : ""}</td>
                  </tr>
                  <tr>
                    <td>Point 6 :</td>
                    <td>{doctorFeedback.discrimination6R !== "" ? `${doctorFeedback.discrimination6R + " mm"}` : ""}</td>
                    <td>{doctorFeedback.discrimination6L !== "" ? `${doctorFeedback.discrimination6L + " mm"}` : ""}</td>
                  </tr>
                  <tr>
                    <td>Point 7 :</td>
                    <td>{doctorFeedback.discrimination7R !== "" ? `${doctorFeedback.discrimination7R + " mm"}` : ""}</td>
                    <td>{doctorFeedback.discrimination7L !== "" ? `${doctorFeedback.discrimination7L + " mm"}` : ""}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="treatment-header">
          <h2>MOTOR TESTING</h2>
        </div>
        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th>Right</th>
                    <th>Left</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Wrist extension(Radial nerve):</td>
                    <td>{doctorFeedback.wristR} </td>
                    <td>{doctorFeedback.wristL}</td>
                  </tr>
                  <tr>
                    <td>Thumb extension(PIN):</td>
                    <td>{doctorFeedback.ThumbR} </td>
                    <td>{doctorFeedback.ThumbL}</td>
                  </tr>
                  <tr>
                    <td>Finger MCP extension(PIN):</td>
                    <td>{doctorFeedback.FingerR} </td>
                    <td>{doctorFeedback.FingerL}</td>
                  </tr>
                  <tr>
                    <td>Thumb palmar abduction(Median nerve):</td>
                    <td>{doctorFeedback.ThumbpalmarR} </td>
                    <td>{doctorFeedback.ThumbpalmarL}</td>
                  </tr>
                  <tr>
                    <td>Thumb to small finger(Median nerve):</td>
                    <td>{doctorFeedback.ThumbsmallR} </td>
                    <td>{doctorFeedback.ThumbsmallL}</td>
                  </tr>
                  <tr>
                    <td>Finger flexion(Median nerve):</td>
                    <td>{doctorFeedback.FingerflexionR} </td>
                    <td>{doctorFeedback.FingerflexionL}</td>
                  </tr>
                  <tr>
                    <td>Finger abduction/adduction(Ulnar nerve):</td>
                    <td>{doctorFeedback.FingerabductionR} </td>
                    <td>{doctorFeedback.FingerabductionL}</td>
                  </tr>
                  <tr>
                    <td>OK sign(AIN):</td>
                    <td>{doctorFeedback.OksingR} </td>
                    <td>{doctorFeedback.OksingL}</td>
                  </tr>
                  <tr>
                    <td>Grip strength(kg):</td>
                    <td>{doctorFeedback.GripR} </td>
                    <td>{doctorFeedback.GripL}</td>
                  </tr>
                  {/* <tr>
                    <td>SPECIAL TESTS:</td>

                    <td>
                      {doctorFeedback.SpecialtestsR === "0"
                        ? "None"
                        : doctorFeedback.SpecialtestsR === "1"
                          ? "Cascade sign"
                          : doctorFeedback.SpecialtestsR === "2"
                            ? "Grind test (CMC)"
                            : doctorFeedback.SpecialtestsR === "3"
                              ? "Finkelstein’s test"
                              : doctorFeedback.SpecialtestsR === "4"
                                ? "Bunnel’s test"
                                : doctorFeedback.SpecialtestsR === "5"
                                  ? "Watson’s test (scaphoid shift test)"
                                  : doctorFeedback.SpecialtestsR === "6"
                                    ? "Reagan test (Lunotriquetral ballottement test)"
                                    : doctorFeedback.SpecialtestsR === "7"
                                      ? "Kleinman shear test (LT instability)"
                                      : doctorFeedback.SpecialtestsR === "8"
                                        ? "Lichtman test (midcarpal instability)"
                                        : doctorFeedback.SpecialtestsR === "9"
                                          ? "TFCC grind"
                                          : doctorFeedback.SpecialtestsR === "10"
                                            ? "ECU snap test (ECU instability)"
                                            : doctorFeedback.SpecialtestsR === "11"
                                              ? "Piano key sign"
                                              : doctorFeedback.SpecialtestsR === "12"
                                                ? "Fovea sign"
                                                : doctorFeedback.SpecialtestsR === "13"
                                                  ? "Gamekeeper’s test"
                                                  : doctorFeedback.SpecialtestsR === "14"
                                                    ? "Tinel sign"
                                                    : doctorFeedback.SpecialtestsR === "15"
                                                      ? "Phalen’s test"
                                                      : doctorFeedback.SpecialtestsR === "16"
                                                        ? "Froment’s sign"
                                                        : doctorFeedback.SpecialtestsR === "17"
                                                          ? "Wartenberg’s sign"
                                                          : doctorFeedback.SpecialtestsR === "18"
                                                            ? "Jeanne’s sign"
                                                            : doctorFeedback.SpecialtestsR === "19"
                                                              ? "Pope’s hand"
                                                              : doctorFeedback.SpecialtestsR === "20"
                                                                ? "Others"
                                                                : ""}
                      <p>{doctorFeedback.SpecialtestsTextR}</p>
                    </td>
                    <td>
                      {doctorFeedback.SpecialtestsL === "0"
                        ? "None"
                        : doctorFeedback.SpecialtestsL === "1"
                          ? "Cascade sign"
                          : doctorFeedback.SpecialtestsL === "2"
                            ? "Grind test (CMC)"
                            : doctorFeedback.SpecialtestsL === "3"
                              ? "Finkelstein’s test"
                              : doctorFeedback.SpecialtestsL === "4"
                                ? "Bunnel’s test"
                                : doctorFeedback.SpecialtestsL === "5"
                                  ? "Watson’s test (scaphoid shift test)"
                                  : doctorFeedback.SpecialtestsL === "6"
                                    ? "Reagan test (Lunotriquetral ballottement test)"
                                    : doctorFeedback.SpecialtestsL === "7"
                                      ? "Kleinman shear test (LT instability)"
                                      : doctorFeedback.SpecialtestsL === "8"
                                        ? "Lichtman test (midcarpal instability)"
                                        : doctorFeedback.SpecialtestsL === "9"
                                          ? "TFCC grind"
                                          : doctorFeedback.SpecialtestsL === "10"
                                            ? "ECU snap test (ECU instability)"
                                            : doctorFeedback.SpecialtestsL === "11"
                                              ? "Piano key sign"
                                              : doctorFeedback.SpecialtestsL === "12"
                                                ? "Fovea sign"
                                                : doctorFeedback.SpecialtestsL === "13"
                                                  ? "Gamekeeper’s test"
                                                  : doctorFeedback.SpecialtestsL === "14"
                                                    ? "Tinel sign"
                                                    : doctorFeedback.SpecialtestsL === "15"
                                                      ? "Phalen’s test"
                                                      : doctorFeedback.SpecialtestsL === "16"
                                                        ? "Froment’s sign"
                                                        : doctorFeedback.SpecialtestsL === "17"
                                                          ? "Wartenberg’s sign"
                                                          : doctorFeedback.SpecialtestsL === "18"
                                                            ? "Jeanne’s sign"
                                                            : doctorFeedback.SpecialtestsL === "19"
                                                              ? "Pope’s hand"
                                                              : doctorFeedback.SpecialtestsL === "20"
                                                                ? "Others"
                                                                : ""}
                      <p>{doctorFeedback.SpecialtestsTextL}</p>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="treatment-header">
          <h2>Special Tests-Palpation</h2>
        </div>
        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th> Right</th>
                    <th> Left</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Grind test :</td>
                    <td>{doctorFeedback.Grind2LeftVal === "1" ? "Positive" : doctorFeedback.Grind2LeftVal === "0" ? "Negative" : ""} </td>
                    <td>{doctorFeedback.Grind1LeftVal === "1" ? "Positive" : doctorFeedback.Grind1LeftVal === "0" ? "Negative" : ""} </td>
                  </tr>
                  <tr>
                    <td>Finkelstein's test :</td>
                    <td>{doctorFeedback.Grind4LeftVal === "1" ? "Positive" : doctorFeedback.Grind4LeftVal === "0" ? "Negative" : ""} </td>
                    <td>{doctorFeedback.Grind3LeftVal === "1" ? "Positive" : doctorFeedback.Grind3LeftVal === "0" ? "Negative" : ""} </td>
                  </tr>

                </tbody>
              </table>

            </div>
          </div>
        </div>

        <div className="treatment-header">
          <h2>  Special Tests - Motion</h2>
        </div>
        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th> Right</th>
                    <th> Left</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Cascade sign :</td>
                    <td>{doctorFeedback.Grind6LeftVal === "1" ? "Positive" : doctorFeedback.Grind6LeftVal === "0" ? "Negative" : ""} </td>
                    <td>{doctorFeedback.Grind5LeftVal === "1" ? "Positive" : doctorFeedback.Grind5LeftVal === "0" ? "Negative" : ""} </td>
                  </tr>
                  <tr>
                    <td> Bunnel's test :</td>
                    <td>{doctorFeedback.Grind8LeftVal === "1" ? "Positive" : doctorFeedback.Grind8LeftVal === "0" ? "Negative" : ""} </td>
                    <td>{doctorFeedback.Grind7LeftVal === "1" ? "Positive" : doctorFeedback.Grind7LeftVal === "0" ? "Negative" : ""} </td>
                  </tr>

                </tbody>
              </table>

            </div>
          </div>
        </div>

        <div className="treatment-header">
          <h2>Special Tests - Stability</h2>
        </div>
        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th> Right</th>
                    <th> Left</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Watson test:</td>
                    <td>{doctorFeedback.Grind10LeftVal === "1" ? "Positive" : doctorFeedback.Grind10LeftVal === "0" ? "Negative" : ""} </td>
                    <td>{doctorFeedback.Grind9LeftVal === "1" ? "Positive" : doctorFeedback.Grind9LeftVal === "0" ? "Negative" : ""} </td>
                  </tr>
                  <tr>
                    <td> Reagan test :</td>
                    <td>{doctorFeedback.Grind12LeftVal === "1" ? "Positive" : doctorFeedback.Grind12LeftVal === "0" ? "Negative" : ""} </td>
                    <td>{doctorFeedback.Grind11LeftVal === "1" ? "Positive" : doctorFeedback.Grind11LeftVal === "0" ? "Negative" : ""} </td>
                  </tr>
                  <tr>
                    <td>  Kleinman shear test :</td>
                    <td>{doctorFeedback.Grind14LeftVal === "1" ? "Positive" : doctorFeedback.Grind14LeftVal === "0" ? "Negative" : ""} </td>
                    <td>{doctorFeedback.Grind13LeftVal === "1" ? "Positive" : doctorFeedback.Grind13LeftVal === "0" ? "Negative" : ""} </td>
                  </tr>
                  <tr>
                    <td> Lichtman test :</td>
                    <td>{doctorFeedback.Grind16LeftVal === "1" ? "Positive" : doctorFeedback.Grind16LeftVal === "0" ? "Negative" : ""} </td>
                    <td>{doctorFeedback.Grind15LeftVal === "1" ? "Positive" : doctorFeedback.Grind15LeftVal === "0" ? "Negative" : ""} </td>
                  </tr>
                  <tr>
                    <td> ECU snap test :</td>
                    <td>{doctorFeedback.Grind18LeftVal === "1" ? "Positive" : doctorFeedback.Grind18LeftVal === "0" ? "Negative" : ""} </td>
                    <td>{doctorFeedback.Grind17LeftVal === "1" ? "Positive" : doctorFeedback.Grind17LeftVal === "0" ? "Negative" : ""} </td>
                  </tr>
                  <tr>
                    <td> Piano key sign :</td>
                    <td>{doctorFeedback.Grind20LeftVal === "1" ? "Positive" : doctorFeedback.Grind20LeftVal === "0" ? "Negative" : ""} </td>
                    <td>{doctorFeedback.Grind19LeftVal === "1" ? "Positive" : doctorFeedback.Grind19LeftVal === "0" ? "Negative" : ""} </td>
                  </tr>
                  <tr>
                    <td>  Gamekeeper's test :</td>
                    <td>{doctorFeedback.Grind22LeftVal === "1" ? "Positive" : doctorFeedback.Grind22LeftVal === "0" ? "Negative" : ""} </td>
                    <td>{doctorFeedback.Grind21LeftVal === "1" ? "Positive" : doctorFeedback.Grind21LeftVal === "0" ? "Negative" : ""} </td>
                  </tr>

                </tbody>
              </table>

            </div>
          </div>
        </div>

        <div className="treatment-header">
          <h2>Special Test - TFCC</h2>
        </div>
        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th> Right</th>
                    <th> Left</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> TFCC grind test :</td>
                    <td>{doctorFeedback.Grind24LeftVal === "1" ? "Positive" : doctorFeedback.Grind24LeftVal === "0" ? "Negative" : ""} </td>
                    <td>{doctorFeedback.Grind23LeftVal === "1" ? "Positive" : doctorFeedback.Grind23LeftVal === "0" ? "Negative" : ""} </td>
                  </tr>
                  <tr>
                    <td> Fovea sign :</td>
                    <td>{doctorFeedback.Grind26LeftVal === "1" ? "Positive" : doctorFeedback.Grind26LeftVal === "0" ? "Negative" : ""} </td>
                    <td>{doctorFeedback.Grind25LeftVal === "1" ? "Positive" : doctorFeedback.Grind25LeftVal === "0" ? "Negative" : ""} </td>
                  </tr>
                  <tr>
                    <td> TFCC compression test :</td>
                    <td>{doctorFeedback.Grind28LeftVal === "1" ? "Positive" : doctorFeedback.Grind28LeftVal === "0" ? "Negative" : ""} </td>
                    <td>{doctorFeedback.Grind27LeftVal === "1" ? "Positive" : doctorFeedback.Grind27LeftVal === "0" ? "Negative" : ""} </td>
                  </tr>
                  <tr>
                    <td> Press test :</td>
                    <td>{doctorFeedback.Grind30LeftVal === "1" ? "Positive" : doctorFeedback.Grind30LeftVal === "0" ? "Negative" : ""} </td>
                    <td>{doctorFeedback.Grind29LeftVal === "1" ? "Positive" : doctorFeedback.Grind29LeftVal === "0" ? "Negative" : ""} </td>
                  </tr>
                  <tr>
                    <td> Supination test :</td>
                    <td>{doctorFeedback.Grind32LeftVal === "1" ? "Positive" : doctorFeedback.Grind32LeftVal === "0" ? "Negative" : ""} </td>
                    <td>{doctorFeedback.Grind31LeftVal === "1" ? "Positive" : doctorFeedback.Grind31LeftVal === "0" ? "Negative" : ""} </td>
                  </tr>


                </tbody>
              </table>

            </div>
          </div>
        </div>

        <div className="treatment-header">
          <h2>Special Tests - Nerve</h2>
        </div>
        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th> Right</th>
                    <th> Left</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Tinel sign:</td>
                    <td>{doctorFeedback.Grind34LeftVal === "1" ? "Positive" : doctorFeedback.Grind34LeftVal === "0" ? "Negative" : ""} </td>
                    <td>{doctorFeedback.Grind33LeftVal === "1" ? "Positive" : doctorFeedback.Grind33LeftVal === "0" ? "Negative" : ""} </td>
                  </tr>
                  <tr>
                    <td> Phalen's test :</td>
                    <td>{doctorFeedback.Grind44LeftVal === "1" ? "Positive" : doctorFeedback.Grind44LeftVal === "0" ? "Negative" : ""} </td>
                    <td>{doctorFeedback.Grind35LeftVal === "1" ? "Positive" : doctorFeedback.Grind35LeftVal === "0" ? "Negative" : ""} </td>
                  </tr>
                  <tr>
                    <td> Froment's sign :</td>
                    <td>{doctorFeedback.Grind37LeftVal === "1" ? "Positive" : doctorFeedback.Grind37LeftVal === "0" ? "Negative" : ""} </td>
                    <td>{doctorFeedback.Grind36LeftVal === "1" ? "Positive" : doctorFeedback.Grind36LeftVal === "0" ? "Negative" : ""} </td>
                  </tr>
                  <tr>
                    <td> Wartenberg's sign :</td>
                    <td>{doctorFeedback.Grind39LeftVal === "1" ? "Positive" : doctorFeedback.Grind39LeftVal === "0" ? "Negative" : ""} </td>
                    <td>{doctorFeedback.Grind38LeftVal === "1" ? "Positive" : doctorFeedback.Grind38LeftVal === "0" ? "Negative" : ""} </td>
                  </tr>
                  <tr>
                    <td> Jeanne's sign :</td>
                    <td>{doctorFeedback.Grind41LeftVal === "1" ? "Positive" : doctorFeedback.Grind41LeftVal === "0" ? "Negative" : ""} </td>
                    <td>{doctorFeedback.Grind40LeftVal === "1" ? "Positive" : doctorFeedback.Grind40LeftVal === "0" ? "Negative" : ""} </td>
                  </tr>
                  <tr>
                    <td> Pope's hand :</td>
                    <td>{doctorFeedback.Grind43LeftVal === "1" ? "Positive" : doctorFeedback.Grind43LeftVal === "0" ? "Negative" : ""} </td>
                    <td>{doctorFeedback.Grind42LeftVal === "1" ? "Positive" : doctorFeedback.Grind42LeftVal === "0" ? "Negative" : ""} </td>
                  </tr>

                </tbody>
              </table>

            </div>
          </div>
        </div>
        <div className="treatment-header">
          <h2>Other Tests</h2>
        </div>
        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th> Right</th>
                    <th> Left</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Other Tests :</td>
                    <td>{doctorFeedback.SpecialtestsTextL} </td>
                    <td>{doctorFeedback.SpecialtestsTextR} </td>
                  </tr>

                </tbody>
              </table>

            </div>
          </div>
        </div>

      </div >
    </>
  );
}
