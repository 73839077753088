import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import "./TopHeader.css";
import leftlogo from "../../images/akunah-hr-logo.svg";
// import backArrow from "../../images/head-back-arr.svg";
// import chatIcon from "../../images/chat-icon.svg";
import notificationIcon from "../../images/notification.svg";
// import signOutIcon from "../../images/Sign out.svg";
import Button from "../custom/button/Button";
// import InputField from "../custom/inputfield/InputField";
// import Dropdown from "../custom/dropdown/Dropdown";
// import { Validators } from "../../utilities/Validator";
// import Modal from 'react-bootstrap/Modal';
import LanguageHandler from "../LanguageHandler";
import useAxios from "../../axiosinstance";
import useTranslation from "../customHooks/translations";
import platform from "platform";
import { useLanguageContext } from "../contexts/LanguageContext";
const TopHeader = ({ setLanguage, ak_dc }) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [patientId, setPatientId] = useState("");
  const [resID, setResID] = useState("");
  const [Akptid, setAkptid] = useState("");
  const { keycloak, initialized } = useKeycloak();
  const [patientid, setPatientdataId] = useState("");
  const [doctorid, setDoctordataId] = useState("");
  const queryParams = new URLSearchParams(location.search);
  // const patient_id = queryParams.get('patient');
  const doctor_id = queryParams.get("doctor");
  const doctor_ids = queryParams.get("d");
  const is_doctor_form = queryParams.get("is_doctor_form");
  const axiosInstance = useAxios();
  let formlink = localStorage.getItem("formlink");
  useEffect(() => {
    if (
      splitLocation[1] === "personal-data" ||
      splitLocation[1] === "consent-form" ||
      splitLocation[1] === "patient-personal-data" ||
      splitLocation[1] === "personal-data-edit"
    ) {
      // console.log(window.atob(splitLocation[2]) , "forms")
      setPatientId(window.atob(splitLocation[2]));
      setPatientdataId(window.atob(splitLocation[2]));
    } else {
      if (splitLocation[1] === "shoulder-treatment-form") {
        setResID(splitLocation[3]);
      }
      if (splitLocation[1] === "consent-form-share") {
        setResID(window.atob(splitLocation[6]));
      }
      if (
        splitLocation[1] != "personal-data" &&
        splitLocation[1] != "consent-form" &&
        splitLocation[1] != "patient-personal-data" &&
        splitLocation[1] != "personal-data-edit" &&
        splitLocation[1] != "shoulder-treatment-form" &&
        splitLocation[1] != "consent-form-share"
      ) {
        setResID(splitLocation[2]);
      }
    }
    // console.log()
  }, [splitLocation]);

  // console.log(splitLocation[2],"MMM",window.atob(splitLocation[6]));

  const [langData, setlangData] = useState([]);

  const [langDataDefault, setlangDataDefault] = useState("en");
  let lang = sessionStorage.getItem("language");
  let akptid = sessionStorage.getItem("patient_akid");

  useEffect(() => {
    setLanguage(lang);
  }, [lang]);

  // console.log(lan[0].id,"gfhgfj");
  // let lan = langData.filter((val) => val.value === lang)[0];

  // const handleChangeLanguage = async (e) => {
  //   await axiosInstance.current
  //     .get(
  //       `extapp/patients/saveUserLanguage/${e}?lang_id=${lan !== undefined && lan.id
  //       }`
  //     )
  //     .then((res) => {
  //       console.log(res);
  //     });
  // };
  // useEffect(() => {
  //   if (lan) {

  //     handleChangeLanguage(Akptid);
  //   }
  // }, [lan]);
  let isdoctorForm = localStorage.getItem("isdoctorForm");

  const [browser, setBrowser] = useState("");
  let id = isdoctorForm === "true" ? ak_dc : Akptid;

  useEffect(() => {
    const deviceInfo = platform;
    setBrowser(deviceInfo.description.toLowerCase().replace(/\s/g, ""));
  }, []);

  const backData = async () => {
    await axiosInstance.current
      .get(`extapp/forms/urgent_data?resource_id=${resID}`)
      .then((res) => {
        setPatientdataId(res.data.data.ak_pt);
        setDoctordataId(res.data.data.ak_dc);
        sessionStorage.setItem("ak_dc", res.data.data.ak_dc);
        sessionStorage.setItem("ptid", res.data.data.ak_pt);
        sessionStorage.setItem("orgid", res.data.data.org_id);
      })
      .catch((err) => {
        setPatientdataId(id);
      });
  };
  useEffect(() => {
    if (resID) {
      backData();
    }
  }, [resID]);
  const json = JSON.stringify({
    ak_id: isdoctorForm === "true" ? doctorid : patientid,
    status: "login",
    browser: browser,
  });
  const RegisterLogin = async () => {
    try {
      const result = await axiosInstance.current.post(
        `extapp/Registerlogin`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (err) {
      // setMessage(err.message || "Unexpected Error!");
      console.log(err.Messages);
      // setMessageType("error");
    } finally {
      // console.log("api response finally");
    }
  };
  useEffect(() => {
    if (browser || patientid) {
      RegisterLogin();
    }
  }, [browser, patientid]);

  const getLogin = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/fetchsessions?ak_id=${
          resID ? "" : id
        }&browser=${browser}&resource_id=${resID}`
      );
      if (result) {
        // console.log(result.data.data, "mydats")
      }
    } catch (err) {
      if (err.response.status === 400) {
        localStorage.setItem("URL", locationurl);
        window.location.reload();
        keycloak.logout({ redirectUri: logout_url });
      }
    }
  };

  useEffect(() => {
    if (resID || id) {
      if (
        splitLocation[1] === "medical-treatment" ||
        splitLocation[1] === "medical-history" ||
        splitLocation[1] === "medical-past" ||
        splitLocation[1] === "medical-social" ||
        splitLocation[1] === "consent-form-view" ||
        splitLocation[1] === "consent-form-share" ||
        formlink !== ""
      ) {
        console.log(splitLocation[1]);
      } else {
        const intervalId = setInterval(() => {
          getLogin();
          // console.log('medical-treatment1')
        }, 30000);
        return () => clearInterval(intervalId);
      }
    }
  }, [id, resID]);
  //create initial menuCollapse state using useState hook
  const [isOpened, setIsOpened] = useState(false);
  const [chatPopup, setChatPopup] = useState(false);
  const [editProfileShow, setEditProfileShow] = useState(false);
  const [needHelpShow, setNeedHelpShow] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const handleClose = () => setEditProfileShow(false);
  const handleShow = () => setEditProfileShow(true);
  const handleNeedHelpClose = () => setNeedHelpShow(false);
  const handleNeedHelpShow = () => setNeedHelpShow(true);
  const translation = useTranslation();
  const handleFirstName = (e) => {
    setFirstName(e);
  };
  const handleLastName = (e) => {
    setLastName(e);
  };
  const handlePhone = (e) => {
    setPhone(e);
  };
  const handleEmail = (e) => {
    setEmail(e);
  };
  const handleTimeZone = (e) => {
    setTimeZone(e);
  };
  const userIconClick = () => {
    isOpened ? setIsOpened(false) : setIsOpened(true);
  };
  const handleChatPopup = () => {
    setNeedHelpShow(false);
    setChatPopup(!chatPopup);
  };
  const [patient, setPatient] = useState("");
  const getData = async (e) => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/patients/getLanguages/patient_id=${e}`
      );
      if (is_doctor_form === "true") {
        setPatient("");
      } else {
        setPatient(e);
      }
      // console.log(result);
      if (result.status === 200) {
        setlangData(result.data.data);
      }
    } catch (err) {
      console.log(err.Messages);
    } finally {
      // console.log("api response finally");
    }
  };

  let doctor = sessionStorage.getItem("doctor_id");

  let userId1 = patient ? patient : doctor;
  let userId2 = isdoctorForm === "true" ? doctorid : "";
  // let userId = userId1!==null ? userId1 : userId2
  let userId = userId1 ?? userId2;

  // console.log("patientpatient123",userId,"userId1",userId1,"userid2",userId2,ak_dc,isdoctorForm,doctorid);
  sessionStorage.setItem("userId", userId);
  // let newpatient= !patientId ?window.atob(resID): patientId;
 const { language, changeLanguage } = useLanguageContext();
  const getData1 = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/forms/getUserLanguage?res_id=${resID}&pat_id=${patientId}&ak_dc=${ak_dc}&is_patient=${
          is_doctor_form === "true" ? "" : "1"
        }`
      );
      // console.log(result)
      if (result.status === 200) {
        setlangDataDefault(result.data.data);
        changeLanguage(result.data.data)
        setAkptid(
          result.data.patient_akid ? result.data.patient_akid : patientId
        );

        sessionStorage.setItem("name", result.data.name);
        sessionStorage.setItem("dob", result.data.dob);
        sessionStorage.setItem("age", result.data.age);
        sessionStorage.setItem(
          "patient_akid",
          result.data.patient_akid ? result.data.patient_akid : patientId
        );
        sessionStorage.setItem("dateformat", result.data.date);
        sessionStorage.setItem("timeZone", result.data.timeZone);
      }
    } catch (err) {
      console.log(err.Messages);
    } finally {
      // console.log("api response finally");
    }
  };
  // const getData2 = async () => {
  //   try {
  //     const result = await axiosInstance.current.get(
  //       `extapp/forms/getUserLanguage?res_id=${resID}&pat_id=${window.atob(patient_id)}`
  //     );
  //     // console.log(result)
  //     if (result.status === 200) {
  //       setlangDataDefault(result.data.data);
  //       setAkptid(result.data.patient_akid);

  //       sessionStorage.setItem("name", result.data.name)
  //       sessionStorage.setItem("age", result.data.age)
  //       sessionStorage.setItem("patient_akid", result.data.patient_akid)
  //       sessionStorage.setItem("dateformat", result.data.date)
  //     }
  //   } catch (err) {
  //     console.log(err.Messages);
  //   } finally {
  //     console.log("api response finally");
  //   }
  // };

  let locationurl = window.location.href;
  // useEffect(()=>{
  //   localStorage.setItem("URL",locationurl)
  // },[locationurl])
  // console.log(locationurl,"locationurl");
  //   useEffect(()=>{
  //     localStorage.setItem("isdoctorForm",is_doctor_form)
  // },[is_doctor_form])
  const handleLogoClick = () => {
    const goback = localStorage.getItem("isdoctorForm");

    if (goback === "true") {
      localStorage.removeItem("URL");
      window.location.href = process.env.REACT_APP_DOCTOR_URL;
      // window.close();
    } else {
      localStorage.removeItem("URL");
      window.location.href = process.env.REACT_APP_PATIENT_URL;
      // window.close();
    }
  };

  useEffect(() => {
    getData(Akptid);
  }, [Akptid]);

  useEffect(() => {
    if (
      langData.length !== 0 &&
      (splitLocation[1] === "consent-form-share") == false
    ) {
      getData1();
    }
  }, [langData]);
  const logout_url = window.location.origin;
  const Logout = async () => {
    localStorage.setItem("URL", locationurl);
    keycloak.logout({ redirectUri: logout_url });
    try {
      const result = await axiosInstance.current.put(
        `extapp/Logout?ak_id=${
          resID ? "" : id
        }&browser=${browser}&resource_id=${resID}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (result.data.status) {
        localStorage.setItem("URL", locationurl);
        keycloak.logout({ redirectUri: logout_url });
      }
    } catch (err) {
      // setMessage(err.message);
      // setMessageType("error");
    } finally {
      // console.log("api response finally");
    }
  };

  return (
    <>
      <div className="top__header">
        <div className="nav_inner_left">
          {/* <Link to="/" className="leftLogo">
            <img src={leftlogo} className="left-logo" alt="logo" />
          </Link> */}
          <span className="leftLogo c_pointer" onClick={handleLogoClick}>
            <img src={leftlogo} className="left-logo" alt="logo" />
          </span>
        </div>
        <div className="nav_inner_search"></div>
        <div className="nav_inner_right">
          <div className="right_user_menu">
            <ul className="list-inline">
              <li className="chat-menu list-inline-item">
                {doctor_id ? (
                  ""
                ) : doctor_ids === "true" ? (
                  ""
                ) : (
                  <LanguageHandler
                    langData={langData}
                    langDataDefault={langDataDefault}
                    Akptid={Akptid}
                  ></LanguageHandler>
                )}
              </li>
              <li className="chat-menu list-inline-item">
                {/* <img
                  src={notificationIcon}
                  title="Notifications"
                  alt=""
                  onClick={handleNeedHelpShow}
                /> */}
              </li>
              <li className="list-inline-item use_lett">
                {/* <label
                  onClick={() => {
                    userIconClick(); 
                  }}
                  className="user-letter"
                >
                  J
                </label> */}
                <Button
                  value={translation.logout}
                  buttonStyle="btn_outline w-auto ms-3"
                  // width="104px"
                  onClick={() => Logout()}
                />
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* {isOpened && (
        <div className="sidenav_right">
          <div className="rightnavInner">
            <div className="row">
              <div className="col-sm-6">
                <h6 className="left_title">Profile</h6>
              </div>
              <div className="col-sm-6">
                <span
                  onClick={() => {
                    userIconClick();
                  }}
                  className="close_btn"
                >
                  &#10006;
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="pf_details">
                  <label className="user-letter large">J</label>
                  <h4>Dr. Michael Jordan</h4>
                  <button
                    type="button"
                    className="btn_outline"
                    onClick={handleShow}
                  >
                    Edit Profile
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <p className="pf_left_btext">
                  <strong>Name:</strong>
                </p>
              </div>
              <div className="col-sm-8">
                <p>Dr. Michael Jordan</p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <p className="pf_left_btext">
                  <strong>Akunah ID:</strong>
                </p>
              </div>
              <div className="col-sm-8">
                <p>AKDC000001</p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <p className="pf_left_btext">
                  <strong>Phone:</strong>
                </p>
              </div>
              <div className="col-sm-8">
                <p>0123456789</p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <p className="pf_left_btext">
                  <strong>Email:</strong>
                </p>
              </div>
              <div className="col-sm-8">
                <p>michael_jordan@gmail.com</p>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <p className="pf_left_btext">
                  <strong>Local time:</strong>
                </p>
              </div>
              <div className="col-sm-8">
                <p>04:28 PM</p>
              </div>
            </div>

            <span className="signout_btn" onClick={() => keycloak.logout()}>
              <img src={signOutIcon} alt="icon" />
              Log out
            </span>
          </div>
        </div>
      )}
      {chatPopup && (
        <>
          <div
            className="chat__popup--main"
            id="chatPopup"
            style={{ display: "block" }}
          >
            <div className="chat_main">
              <div className="chat_head">
                <div className="row">
                  <div className="col-md-9">
                    <img
                      src={chatIcon}
                      title="Chat"
                      alt=""
                      className="chat_left_img"
                    />
                    <span className="chat_title">Chat with Akunah</span>
                  </div>
                  <div className="col-md-3 text-end">
                    <button
                      className="circle__btn"
                      id="chatClose"
                      data-animation="simpleRotate"
                      data-remove="200"
                      data-lastmsgid="3792"
                      onClick={() => {
                        setChatPopup(!chatPopup);
                      }}
                    >
                      ✖
                    </button>
                  </div>
                </div>
              </div>
              <div className="chat_body">
                <div className="message">
                  <div className="prof">
                    <p>L</p>
                  </div>
                  <div className="messArea">
                    <p id="sdate">Marine • 8:41 am</p>
                    <div className="textM">Hi! How can I help you today?</div>
                  </div>
                </div>
                <div className="message mMess">
                  <div className="messArea">
                    <p id="sdate">Lorem • 8:42 am</p>
                    <div className="textM">
                      Just a quick question, when is my model arriving?{" "}
                    </div>
                  </div>
                  <div className="prof" style={{ backgroundColor: "#ddb06c" }}>
                    <p>J</p>
                  </div>
                </div>
                <div className="message">
                  <div className="prof">
                    <p>L</p>
                  </div>
                  <div className="messArea">
                    <p id="sdate">Marine • 8:42 am</p>
                    <div className="textM">
                      I’ll just check that for you, one moment. 😊
                    </div>
                  </div>
                </div>
              </div>
              <div className="chat_footer"></div>
            </div>
          </div>
        </>
      )}
      <Modal
        show={needHelpShow}
        onHide={handleNeedHelpClose}
        backdrop="static"
        keyboard={false}
        dialogClassName=""
        className="need_help_popup"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img
              src={chatIcon}
              title="Chat"
              alt=""
              className="need_help_left_img"
            />
            Need help?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="needhelp_ppup_bdy">
          <div className="chat-cont-p">
            <p className="start_cht_btm-txt">
              Start a secured chat with one of our team members here at Akunah.
              <br />
              <span id="chatlnkspan">
                <span className="chatlnk" onClick={handleChatPopup}>
                  Click here
                </span>{" "}
                to start a chat with one of our engineers. The chat window will
                open in a new tab. <br />
                Your unique password: <span id="chatlnkPass"></span>
              </span>
            </p>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={editProfileShow}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName=""
        className="dr_edit_pf_popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit your profile</Modal.Title>
        </Modal.Header>
        <Modal.Body className="edit_ppup_bdy">
          <div className="pf_details">
            <label className="user-letter large">
              <img
                src="https://akunah-dicom-dev.s3.ap-southeast-2.amazonaws.com/APP_FILES/default/files/AKDC000001/5f85a7bb065d2010f568c4e21656413097.png?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&amp;X-Amz-Algorithm=AWS4-HMAC-SHA256&amp;X-Amz-Credential=AKIA4CSAPIDIUZOKVAOY%2F20220906%2Fap-southeast-2%2Fs3%2Faws4_request&amp;X-Amz-Date=20220906T051946Z&amp;X-Amz-SignedHeaders=host&amp;X-Amz-Expires=518400&amp;X-Amz-Signature=4657cbbe0ed3c397a161af51c19a6e2041d05bb3e57a01e68bf25e1e7f2c0662"
                alt=""
              />
            </label>
            <input
              type="file"
              name="profileImage"
              id="profileImage"
              hidden
              style={{ display: "none" }}
            />
            <label for="profileImage" className="btn_outline upload_pf_btn">
              Upload an Image
            </label>
            <p className="remove_pf_txt">Remove photo</p>
          </div>
          <div className="pf_form">
            <div>
              <InputField
                label="First name:"
                value={firstName}
                type="text"
                placeholder="First Name"
                onChange={handleFirstName}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                ]}
                styleclassName="mxw_100 br_10"
              />
            </div>
            <div>
              <InputField
                label="Last name:"
                value={lastName}
                type="text"
                placeholder="Last Name"
                onChange={handleLastName}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                ]}
                styleclassName="mxw_100 br_10"
              />
            </div>
            <div>
              <InputField
                label="Phone:"
                value={phone}
                type="text"
                placeholder="Phone"
                onChange={handlePhone}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                ]}
                styleclassName="mxw_100 br_10"
              />
            </div>
            <div>
              <InputField
                label="Email:"
                value={email}
                type="text"
                placeholder="Email"
                onChange={handleEmail}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                ]}
                styleclassName="mxw_100 br_10"
              />
            </div>
            <div>
              <Dropdown
                data={[
                  {
                    value: "Australia/Brisbane",
                    label: "(UTC+10:00) Brisbane",
                  },
                  {
                    value: "Australia/Canberra",
                    label: "(GMT+10:00) Canberra",
                  },
                  {
                    value: "Australia/Adelaide",
                    label: "(GMT+09:30) Adelaide",
                  },
                  { value: "Australia/Darwin", label: "(GMT+09:30) Darwin" },
                  { value: "Australia/Hobart", label: "(GMT+10:00) Hobart" },
                  {
                    value: "Australia/Melbourne",
                    label: "(GMT+10:00) Melbourne",
                  },
                  { value: "Australia/Sydney", label: "(GMT+10:00) Sydney" },
                ]}
                label="Time Zone:"
                value={timeZone}
                placeholder="Select Timezone"
                onChange={handleTimeZone}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                ]}
                styleclassName="mxw_100 br_10"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "flex-start" }}>
          <Button
            onClick={handleClose}
            value="Cancel"
            buttonStyle="btn_outline mr_20"
            width="154px"
          />
          <Button
            value="Save Changes"
            buttonStyle="btn_green_outline mr_20"
            width="154px"
          />
        </Modal.Footer>
      </Modal> */}
    </>
  );
};

export default TopHeader;
