export default{
  "patient_shoulder_pain": "Ból",
  "patient_shoulder_pain_q1": "Czy odczuwasz ból barku?",
  "patient_shoulder_pain_q1_a1": "Nie",
  "patient_shoulder_pain_q1_a2": "Tak",
  "patient_shoulder_pain_q2": "Na skali od 0 do 15, jaki był najwyższy poziom bólu, który odczuwałeś w ramieniu podczas zwykłych czynności w ciągu ostatnich 24 godzin? (0 oznacza brak bólu, 15 oznacza nieznośny ból)",
  "patient_shoulder_pain_q2_a1": "Nieznośny ból",
  "patient_shoulder_pain_q2_a2": "Brak bólu",
  "patient_shoulder_pain_q3": "Czy Twój sen jest zakłócany przez ból barku?",
  "patient_shoulder_pain_q3_a1": "Niezakłócony sen",
  "patient_shoulder_pain_q3_a2": "Okazjonalne zakłóceni",
  "patient_shoulder_pain_q3_a3": "Zakłócenie każdej noc",
  "patient_shoulder_pain_q4": "Jakie jest nasilenie bólu w dniu dzisiejszym?",
  "patient_shoulder_pain_q5": "Czy jesteś aktywnym palaczem?",
  "patient_shoulder_pain_q5_a1": "NIE",
  "patient_shoulder_pain_q5_a2": "Tak",
  "patient_shoulder_pain_q5_a3": "Były palacz",
  "patient_shoulder_pain_q5_a2_q1": "Ile papierosów dziennie palisz?",
  "patient_shoulder_pain_q5_a3_q1": "Ile lat temu rzuciłeś palenie?",
  "patient_shoulder_pain_q5_a3_q2": "Ile lat paliłeś?",
  "patient_shoulder_pain_q6": "Czy zażywasz leki przeciwbólowe (np. paracetamol itp.)?",
  "patient_shoulder_pain_q6_a1": "NIE",
  "patient_shoulder_pain_q6_a2": "Tak",
  "patient_shoulder_pain_q6_a2_q1": "Czy zażywasz silne leki przeciwbólowe (np. kodeinę, tramadol, morfinę)?",
  "patient_shoulder_pain_q6_a2_q1_a1": "NIE",
  "patient_shoulder_pain_q6_a2_q1_a2": "Tak",
  "patient_shoulder_pain_q6_a2_q2": "Średnio ile tabletek dziennie zażywasz?",
  "patient_shoulder_pain_q7": "Które z poniższych stwierdzeń najlepiej opisuje ból?",
  "patient_shoulder_pain_q7_a1": "Brak",
  "patient_shoulder_pain_q7_a2": "Sporadyczny i niewielki",
  "patient_shoulder_pain_q7_a3": "Występuje podczas ciężkiej lub szczególnej aktywności, aspiryna stosowana sporadycznie",
  "patient_shoulder_pain_q7_a4": "Występuje podczas lekkiej aktywności, w spoczynku jest niewielki lub brak, często stosowana aspiryna",
  "patient_shoulder_pain_q7_a5": "Ciągły, znośny, czasami potrzebne silne leki",
  "patient_shoulder_pain_q7_a6": "Ciągły, nie do zniesienia, często potrzebne silne leki",
  "patient_shoulder_pain_q8": "Czy odczuwasz mrowienie lub mrowienie w ramieniu?",
  "patient_shoulder_pain_q8_a1": "NIE",
  "patient_shoulder_pain_q8_a2": "Tak",
  "patient_shoulder_pain_q9": "W którym miejscu pojawia się ból?",
  "patient_shoulder_pain_q9_a1": "Z przodu",
  "patient_shoulder_pain_q9_a2": "Z tyłu",
  "patient_shoulder_function": "Funkcja",
  "patient_shoulder_function_q1": "Które z poniższych stwierdzeń najlepiej opisuje funkcje chorego barku?",
  "patient_shoulder_function_q1_a1": "Prawidłowa",
  "patient_shoulder_function_q1_a2": "Z niewielkimi ograniczeniami, możliwa praca z rękami uniesionymi powyżej linii barków",
  "patient_shoulder_function_q1_a3": "Możliwa większość prac domowych, robienie zakupów, prowadzenie samochodu, czesanie, ubierania się/rozbieranie",
  "patient_shoulder_function_q1_a4": "Mogę wykonać lekkie prace domowe i większość codziennych czynności",
  "patient_shoulder_function_q1_a5": "Możliwe są tylko lekkie aktywności",
  "patient_shoulder_function_q1_a6": "Niezdolny do użycia kończyny",
  "patient_shoulder_function_instruction": "Wybierz liczbę wskazującą, że potrafisz wykonać następujące czynności",
  "patient_shoulder_function_instruction_0": "Niezdolny",
  "patient_shoulder_function_instruction_1": "Bardzo trudne",
  "patient_shoulder_function_instruction_2": "Utrudnione",
  "patient_shoulder_function_instruction_3": "Łatwe",
  "patient_shoulder_function_note": "Uwaga: Proszę odpowiedzieć na następujące pytania dotyczące funkcji barku.",
  "patient_shoulder_function_q2": "Założenie płaszcza/koszuli",
  "patient_shoulder_function_q2_a0": "Niemożliwe",
  "patient_shoulder_function_q2_a1": "Bardzo trudne",
  "patient_shoulder_function_q2_a2": "Utrudnione",
  "patient_shoulder_function_q2_a3": "Łatwe",
  "patient_shoulder_function_q3": "Spanie na bolesnej lub dotkniętej chorobą stronie",
  "patient_shoulder_function_q3_a0": "Niemożliwe",
  "patient_shoulder_function_q3_a1": "Bardzo trudne",
  "patient_shoulder_function_q3_a2": "Utrudnione",
  "patient_shoulder_function_q3_a3": "Łatwe",
  "patient_shoulder_function_q4": "Umyj plecy/załóż stanik",
  "patient_shoulder_function_q4_a0": "Niemożliwe",
  "patient_shoulder_function_q4_a1": "Bardzo trudne",
  "patient_shoulder_function_q4_a2": "Utrudnione",
  "patient_shoulder_function_q4_a3": "Łatwe",
  "patient_shoulder_function_q5": "Korzystanie z toalety ",
  "patient_shoulder_function_q5_a0": "Niemożliwe",
  "patient_shoulder_function_q5_a1": "Bardzo trudne",
  "patient_shoulder_function_q5_a2": "Utrudnione",
  "patient_shoulder_function_q5_a3": "Łatwe",
  "patient_shoulder_function_q6": "Czesanie włosów ",
  "patient_shoulder_function_q6_a0": "Niemożliwe",
  "patient_shoulder_function_q6_a1": "Bardzo trudne",
  "patient_shoulder_function_q6_a2": "Utrudnione",
  "patient_shoulder_function_q6_a3": "Łatwe",
  "patient_shoulder_function_q7": "Golenie się/makijaż",
  "patient_shoulder_function_q7_a0": "Niemożliwe",
  "patient_shoulder_function_q7_a1": "Bardzo trudne",
  "patient_shoulder_function_q7_a2": "Utrudnione",
  "patient_shoulder_function_q7_a3": "Łatwe",
  "patient_shoulder_function_q8": "Mycie zębów",
  "patient_shoulder_function_q8_a0": "Niemożliwe",
  "patient_shoulder_function_q8_a1": "Bardzo trudne",
  "patient_shoulder_function_q8_a2": "Utrudnione",
  "patient_shoulder_function_q8_a3": "Łatwe",
  "patient_shoulder_function_q9": "Napełnienie szklanki pełną butelką wody",
  "patient_shoulder_function_q9_a0": "Niemożliwe",
  "patient_shoulder_function_q9_a1": "Bardzo trudne",
  "patient_shoulder_function_q9_a2": "Utrudnione",
  "patient_shoulder_function_q9_a3": "Łatwe",
  "patient_shoulder_function_q10": "Picie (podniesienie pełnej szklanki do ust)",
  "patient_shoulder_function_q10_a0": "Niemożliwe",
  "patient_shoulder_function_q10_a1": "Bardzo trudne",
  "patient_shoulder_function_q10_a2": "Utrudnione",
  "patient_shoulder_function_q10_a3": "Łatwe",
  "patient_shoulder_function_q11": "Jedzenie zupy (uniesienie pełnej łyżki do ust)",
  "patient_shoulder_function_q11_a0": "Niemożliwe",
  "patient_shoulder_function_q11_a1": "Bardzo trudne",
  "patient_shoulder_function_q11_a2": "Utrudnione",
  "patient_shoulder_function_q11_a3": "Łatwe",
  "patient_shoulder_function_q12": "Uściśnięcie komuś dłoni / otwarcie drzwi",
  "patient_shoulder_function_q12_a0": "Niemożliwe",
  "patient_shoulder_function_q12_a1": "Bardzo trudne",
  "patient_shoulder_function_q12_a2": "Utrudnione",
  "patient_shoulder_function_q12_a3": "Łatwe",
  "patient_shoulder_function_q13": "Korzystanie z telefonu (na poziomie ucha)",
  "patient_shoulder_function_q13_a0": "Niemożliwe",
  "patient_shoulder_function_q13_a1": "Bardzo trudne",
  "patient_shoulder_function_q13_a2": "Utrudnione",
  "patient_shoulder_function_q13_a3": "Łatwe",
  "patient_shoulder_function_q14": "Pisanie listu / podpisanie dokumentu / używanie klawiatury",
  "patient_shoulder_function_q14_a0": "Niemożliwe",
  "patient_shoulder_function_q14_a1": "Bardzo trudne",
  "patient_shoulder_function_q14_a2": "Utrudnione",
  "patient_shoulder_function_q14_a3": "Łatwe",
  "patient_shoulder_function_q15": "Dosięgnięcie do półki powyżej lini ramion",
  "patient_shoulder_function_q15_a0": "Niemożliwe",
  "patient_shoulder_function_q15_a1": "Bardzo trudne",
  "patient_shoulder_function_q15_a2": "Utrudnione",
  "patient_shoulder_function_q15_a3": "Łatwe",
  "patient_shoulder_function_q16": "Uniesienie 0,5kg powyżej ramion",
  "patient_shoulder_function_q16_a0": "Niemożliwe",
  "patient_shoulder_function_q16_a1": "Bardzo trudne",
  "patient_shoulder_function_q16_a2": "Utrudnione",
  "patient_shoulder_function_q16_a3": "Łatwe",
  "patient_shoulder_function_q17": "Uniesienie 4,5kg powyżej ramion",
  "patient_shoulder_function_q17_a0": "Niemożliwe",
  "patient_shoulder_function_q17_a1": "Bardzo trudne",
  "patient_shoulder_function_q17_a2": "Utrudnione",
  "patient_shoulder_function_q17_a3": "Łatwe",
  "patient_shoulder_function_q18": "Noś 10 kg (22 funty) u boku, na dotkniętym ramieniu",
  "patient_shoulder_function_q18_a0": "Niemożliwe",
  "patient_shoulder_function_q18_a1": "Bardzo trudne",
  "patient_shoulder_function_q18_a2": "Utrudnione",
  "patient_shoulder_function_q18_a3": "Łatwe",
  "patient_shoulder_function_q19": "Rzut piłką od dołu (co najmniej 3 metry)",
  "patient_shoulder_function_q19_a0": "Niemożliwe",
  "patient_shoulder_function_q19_a1": "Bardzo trudne",
  "patient_shoulder_function_q19_a2": "Utrudnione",
  "patient_shoulder_function_q19_a3": "Łatwe",
  "patient_shoulder_function_q20": "Rzut piłką znad głowy (co najmniej 6 metrów)",
  "patient_shoulder_function_q20_a0": "Niemożliwe",
  "patient_shoulder_function_q20_a1": "Bardzo trudne",
  "patient_shoulder_function_q20_a2": "Utrudnione",
  "patient_shoulder_function_q20_a3": "Łatwe",
  "patient_shoulder_function_q21": "Codzienna, typowa praca",
  "patient_shoulder_function_q21_a0": "Niemożliwe",
  "patient_shoulder_function_q21_a1": "Bardzo trudne",
  "patient_shoulder_function_q21_a2": "Utrudnione",
  "patient_shoulder_function_q21_a3": "Łatwe",
  "patient_shoulder_function_q22": "Ile ze swoich normalnych obowiązków pozwala Ci wykonać Twoje ramię?",
  "patient_shoulder_function_q22_a0": "Żadnych",
  "patient_shoulder_function_q22_a1": "Wszystkie",
  "patient_shoulder_function_q23": "Uprawianie sportu, hobby",
  "patient_shoulder_function_q23_a0": "Niemożliwe",
  "patient_shoulder_function_q23_a1": "Bardzo trudne",
  "patient_shoulder_function_q23_a2": "Utrudnione",
  "patient_shoulder_function_q23_a3": "Łatwe",
  "patient_shoulder_function_q24": "Ile ze swoich normalnych zajęć rekreacyjnych pozwala Ci wykonywać Twoje ramię?",
  "patient_shoulder_function_q24_a0": "Żadnych",
  "patient_shoulder_function_q24_a1": "Wszystkie",
  "patient_shoulder_function_q25": "Proszę wymienić czynności związane z pracą",
  "patient_shoulder_function_q26": "Proszę wymienić hobby/zajęcia sportowe",
  "patient_shoulder_feeling_instability": "Poczucie niestabilności",
  "patient_shoulder_feeling_instability_q1": "Czy Twój bark wydaje się niestabilny lub luźny, jakby mógł wyskoczyć? Czy kiedykolwiek zdiagnozowano lub leczono u Ciebie zwichnięty lub niestabilny bark?",
  "patient_shoulder_feeling_instability_q1_a1": "Nie",
  "patient_shoulder_feeling_instability_q1_a2": "Tak",
  "patient_shoulder_feeling_instability_q1_a2_q1": "Proszę określić poziom niestabilności barku",
  "patient_shoulder_wosi": "Wskaźnik niestabilności barku Western Ontario Shoulder Instability Index",
  "patient_shoulder_wosi_instruction": "Instrukcja: W tej części kwestionariusza zostaniesz poproszony o wskazanie natężenia objawów związanych z chorym barkiem, które wystąpiły w ubiegłym tygodniu. Ustaw wskaźnik na linii, tak, żeby odpowiadał nasileniu objawów.",
  "patient_shoulder_wosi_note": "Uwaga: Im dalej przesuniesz wskaźnik w prawo tym większe nasilenie dolegliwości. Im dalej przesuniesz wskaźnik w lewo tym mniejsze nasilenie dolegliwości. Jeśli masz jakieś wątpliwości dotyczące treści pytania, prosimy o kontakt.",
  "patient_shoulder_wosi_sectionA": "Dział A: Objawy",
  "patient_shoulder_wosi_q1": "Jak silny ból odczuwasz podczas aktywności wykonywanych powyżej głowy.",
  "patient_shoulder_wosi_q1_a1": "Bez bólu",
  "patient_shoulder_wosi_q1_a2": "Skrajny ból",
  "patient_shoulder_wosi_q2": "Jak silny ból lub pulsowanie odczuwasz w ramieniu?",
  "patient_shoulder_wosi_q2_a1": "Bez bólu/pulsowania",
  "patient_shoulder_wosi_q2_a2": "Skrajny ból/pulsowanie",
  "patient_shoulder_wosi_q3": "Jak duże osłabienie lub ubytek siły odczuwasz w chorym barku?",
  "patient_shoulder_wosi_q3_a1": "Brak osłabienia",
  "patient_shoulder_wosi_q3_a2": "Skrajne osłabienie",
  "patient_shoulder_wosi_q4": "Jak duże zmęczenie lub utratę żywotności odczuwasz w chorym barku?",
  "patient_shoulder_wosi_q4_a1": "Brak zmęczenia",
  "patient_shoulder_wosi_q4_a2": "Skrajne zmęczenie",
  "patient_shoulder_wosi_q5": "Jak często odczuwasz klikanie, pękanie lub trzaskanie w ramieniu? ",
  "patient_shoulder_wosi_q5_a1": "Bez trzeszczenia",
  "patient_shoulder_wosi_q5_a2": "Skrajne trzeszczenie",
  "patient_shoulder_wosi_q6": "Jak dużą sztywność barku odczuwasz?",
  "patient_shoulder_wosi_q6_a1": "Brak sztywności",
  "patient_shoulder_wosi_q6_a2": "Skrajna sztywność",
  "patient_shoulder_wosi_q7": "Jak duży dyskomfort mięśni szyi odczywasz w związku z chorym barkiem?",
  "patient_shoulder_wosi_q7_a1": "Brak dyskomfortu",
  "patient_shoulder_wosi_q7_a2": "Skrajny dyskomfort",
  "patient_shoulder_wosi_q8": "Jak duże jest uczucie niestabilności barku?",
  "patient_shoulder_wosi_q8_a1": "Brak niestabilności",
  "patient_shoulder_wosi_q8_a2": "Skrajna niestabilność",
  "patient_shoulder_wosi_q9": "W jakim stopniu kompensujesz chory bark innymi partiami mięśni?",
  "patient_shoulder_wosi_q9_a1": "Brak kompensacji",
  "patient_shoulder_wosi_q9_a2": "Znaczna kompensacja",
  "patient_shoulder_wosi_q10": "Jak duże ograniczenie zakresu ruchomości barku odczuwasz?",
  "patient_shoulder_wosi_q10_a1": "Bez ograniczenia",
  "patient_shoulder_wosi_q10_a2": "Skrajne ograniczenie",
  "patient_shoulder_wosi_sectionB": "Dział B: Sport / Rekreacja / Praca",
  "patient_shoulder_wosi_q11": "W jakim stopniu Twój bark ogranicza uczestnictwo w zajęciach sportowych lub rekreacyjnych?",
  "patient_shoulder_wosi_q11_a1": "Bez ograniczenia",
  "patient_shoulder_wosi_q11_a2": "Skrajne ograniczenie",
  "patient_shoulder_wosi_q12": "W jakim stopniu chory bark wpłynął na Twoją zdolność do wykonywania określonych umiejętności wymaganych w sporcie lub pracy?",
  "patient_shoulder_wosi_q12_note": "Jeśli Twoje ramię wpływa zarówno na sport, jak i na pracę, odnieś się do tego obszaru, na który wywiera największy wpływ.",
  "patient_shoulder_wosi_q12_a1": "Brak wpływu",
  "patient_shoulder_wosi_q12_a2": "Znaczny wpływ",
  "patient_shoulder_wosi_q13": "Jak bardzo odczuwasz potrzebę ochrony barku podczas wykonywania czynności?",
  "patient_shoulder_wosi_q13_a1": "Nie odczuwam",
  "patient_shoulder_wosi_q13_a2": "Bardzo odczuwam",
  "patient_shoulder_wosi_q14": "Jak duże trudności sprawia Ci podnoszenie ciężkich przedmiotów poniżej poziomu ramion?",
  "patient_shoulder_wosi_q14_a1": "Żadnych trudności",
  "patient_shoulder_wosi_q14_a2": "Niezwykła trudność",
  "patient_shoulder_wosi_sectionC": "Dział C: Styl życia",
  "patient_shoulder_wosi_q15": "Jak bardzo boisz się, że upadniesz na ramię?",
  "patient_shoulder_wosi_q15_a1": "Bez obawy",
  "patient_shoulder_wosi_q15_a2": "Niezwykła obawa",
  "patient_shoulder_wosi_q16": "Jak duże trudności sprawia Ci utrzymanie pożądanego poziomu sprawności?",
  "patient_shoulder_wosi_q16_a1": "Żadnych trudności",
  "patient_shoulder_wosi_q16_a2": "Niezwykłe trudności",
  "patient_shoulder_wosi_q17": "Jak duże trudnosci sprawia Ci wygłupianie się i zabawa z rodziną lub przyjaciółmi? ",
  "patient_shoulder_wosi_q17_a1": "Żadnych trudności",
  "patient_shoulder_wosi_q17_a2": "Niezwykłe trudności",
  "patient_shoulder_wosi_q18": "Jak bardzo utrudniony jest sen z powodu barku?",
  "patient_shoulder_wosi_q18_a1": "Bez utrudnienia",
  "patient_shoulder_wosi_q18_a2": "Niezwykle utrudniony",
  "patient_shoulder_wosi_sectionD": "Dział D: Emocje",
  "patient_shoulder_wosi_q19": "Jak bardzo świadomy jesteś stanu swojego barku?",
  "patient_shoulder_wosi_q19_a1": "Nieświadomy",
  "patient_shoulder_wosi_q19_a2": "Wysoce świadomy",
  "patient_shoulder_wosi_q20": "Jak bardzo martwisz się pogorszeniem stanu barku?",
  "patient_shoulder_wosi_q20_a1": "Bez obaw",
  "patient_shoulder_wosi_q20_a2": "Niezwykle zaniepokojony",
  "patient_shoulder_wosi_q21": "Jak bardzo odczuwasz frustrację z powodu barku?",
  "patient_shoulder_wosi_q21_a1": "Bez frustracji",
  "patient_shoulder_wosi_q21_a2": "Niezwykle sfrustrowany",
  "patient_shoulder_ac_joint": "Staw barkowo-obojczykowy",
  "patient_shoulder_ac_joint_q1": "Czy masz niestabilność stawu barkowo-obojczykowego?",
  "patient_shoulder_ac_joint_q1_note": "Uwaga: staw barkowo-oboczykowy to staw w górnej części barku, łączący obojczyk z przednim brzegiem wyrostka barkowego łopatki",
  "patient_shoulder_ac_joint_q1_a1": "Nie",
  "patient_shoulder_ac_joint_q1_a2": "Tak",
  "patient_shoulder_sacs": "Wynik SACS",
  "patient_shoulder_sacs_explanation": "Wyjaśnienie: Przy każdym pytaniu zostaniesz poproszony o ocenę poziomu bólu lub trudności w wykonywaniu różnych zadań. Skala ocen została zaprojektowana w taki sposób, że 0 oznacza brak bólu lub trudności, a 10 oznacza największy poziom bólu lub trudności.",
  "patient_shoulder_sacs_note": "Uwaga: ocena 2 oznaczałaby minimalny poziom bólu. Jeśli masz jakiekolwiek pytania dotyczące treści konkretnego pytania, prosimy o kontakt.",
  "patient_shoulder_sacs_sectionA": "Część A: Ból",
  "patient_shoulder_sacs_a1": "Oceń poziom bólu barku, gdy jest w fazie największej intensywności",
  "patient_shoulder_sacs_a1_a1": "Brak bólu",
  "patient_shoulder_sacs_a1_a2": "Największy ból",
  "patient_shoulder_sacs_a2": "Oceń poziom bólu barku w fazie najmniejszej intensywności",
  "patient_shoulder_sacs_a2_a1": "Brak bólu",
  "patient_shoulder_sacs_a2_a2": "Największy ból",
  "patient_shoulder_sacs_a3": "Oceń poziom bólu podczas dotykania lub ucisku górnej części barku",
  "patient_shoulder_sacs_a3_note": "np. Pasek do laptopa lub torebki",
  "patient_shoulder_sacs_a3_a1": "Brak bólu",
  "patient_shoulder_sacs_a3_a2": "Największy ból",
  "patient_shoulder_sacs_a4": "Oceń poziom bólu, napięcia lub uczucia ciągnięcia, które odczuwasz w szyi i/lub dotkniętym obszarze łopatki",
  "patient_shoulder_sacs_a4_a1": "Brak bólu",
  "patient_shoulder_sacs_a4_a2": "Największy ból",
  "patient_shoulder_sacs_sectionB": "Część B: Funkcja",
  "patient_shoulder_sacs_b1": "Oceń stopień klikania i/lub zgrzytania i/lub stukania, które odczuwasz w ramieniu",
  "patient_shoulder_sacs_b1_a1": "Brak klikania",
  "patient_shoulder_sacs_b1_a2": "Ekstremalne klikanie",
  "patient_shoulder_sacs_b2": "Oceń, czy Twój bark potrzebuje podparcia podczas chodzenia",
  "patient_shoulder_sacs_b2_note": "np. Spoczywanie ręki w kieszeni lub użycie temblaka",
  "patient_shoulder_sacs_b2_a1": "Nie ma potrzeby podparcia",
  "patient_shoulder_sacs_b2_a2": "Znaczna potrzeba podparcia",
  "patient_shoulder_sacs_b3": "Oceń poziom trudności w podnoszeniu ciężarów lub pochylaniu się przez ramię",
  "patient_shoulder_sacs_b3_note": "np. Pompki, jazda na rowerze, joga",
  "patient_shoulder_sacs_b3_a1": "Żadnych trudności",
  "patient_shoulder_sacs_b3_a2": "Ekstremalna trudność lub niemożność wykonania",
  "patient_shoulder_sacs_b4": "Oceń poziom trudności w noszeniu ciężkich przedmiotów u boku przez dłużej niż 5 minut",
  "patient_shoulder_sacs_b4_note": "np. Torba na zakupy",
  "patient_shoulder_sacs_b4_a1": "Żadnych trudności",
  "patient_shoulder_sacs_b4_a2": "Ekstremalna trudność lub niemożność wykonania",
  "patient_shoulder_sacs_b5": "Oceń poziom trudności w sięganiu po ciele chorym ramieniem",
  "patient_shoulder_sacs_b5_note": "np. Sięgnąć w poprzek i dotknąć zdrowego ramienia",
  "patient_shoulder_sacs_b5_a1": "Żadnych trudności",
  "patient_shoulder_sacs_b5_a2": "Ekstremalna trudność lub niemożność wykonania",
  "patient_shoulder_sacs_b6": "Oceń poziom trudności w wypychaniu ciężkich przedmiotów lub ciężarów ponad głowę",
  "patient_shoulder_sacs_b6_note": "np. Więcej niż pięć kilogramów",
  "patient_shoulder_sacs_b6_a1": "Żadnych trudności",
  "patient_shoulder_sacs_b6_a2": "Ekstremalna trudność lub niemożność wykonania",
  "patient_shoulder_sacs_b7": "Oceń poziom trudności w trzymaniu chorego ramienia przed sobą przez dłuższy czas",
  "patient_shoulder_sacs_b7_note": "np. Trzymanie kierownicy podczas jazdy",
  "patient_shoulder_sacs_b7_a1": "Żadnych trudności",
  "patient_shoulder_sacs_b7_a2": "Ekstremalna trudność lub niemożność wykonania",
  "patient_shoulder_sacs_b8": "Oceń poziom trudności podczas wykonywania swojego zawodu",
  "patient_shoulder_sacs_b8_a1": "Żadnych trudności",
  "patient_shoulder_sacs_b8_a2": "Ekstremalna trudność lub niemożność wykonania",
  "patient_shoulder_sacs_b8_a3": "Nie dotyczy",
  "patient_shoulder_sacs_b9": "Oceń, jak bardzo musiałeś zmodyfikować swój zawód, aby ograniczyć problemy z barkiem",
  "patient_shoulder_sacs_b9_a1": "Żadnych trudności",
  "patient_shoulder_sacs_b9_a2": "Ekstremalna trudność lub niemożność wykonania",
  "patient_shoulder_sacs_b9_a3": "Nie dotyczy",
  "patient_shoulder_sacs_b10": "Oceń poziom trudności podczas wykonywania podstawowej aktywności sportowej",
  "patient_shoulder_sacs_b10_a1": "Żadnych trudności",
  "patient_shoulder_sacs_b10_a2": "Ekstremalna trudność lub niemożność wykonania",
  "patient_shoulder_sacs_b10_a3": "Nie dotyczy",
  "patient_shoulder_sacs_b11": "Oceń, jak bardzo musiałeś zmodyfikować swoją podstawową aktywność sportową, aby ograniczyć problemy z barkiem",
  "patient_shoulder_sacs_b11_a1": "Żadnych trudności",
  "patient_shoulder_sacs_b11_a2": "Ekstremalna trudność lub niemożność wykonania",
  "patient_shoulder_sacs_b11_a3": "Nie dotyczy/nie dotyczy",
  "patient_shoulder_sacs_sectionC": "Częśc C: Jakość życia i satysfakcja",
  "patient_shoulder_sacs_c1": "Oceń różnicę w wyglądzie kości na szczycie barku porównując ze zdrowym barkiem",
  "patient_shoulder_sacs_c1_note": "W tym obecność guzka",
  "patient_shoulder_sacs_c1_a1": "Bez różnicy",
  "patient_shoulder_sacs_c1_a2": "Całkowicie inny",
  "patient_shoulder_sacs_c2": "Oceń ogólny poziom niepokoju lub stresu, jaki spowodował u Ciebie problem z barkiem.",
  "patient_shoulder_sacs_c2_a1": "Żadnego niepokoju/stresu",
  "patient_shoulder_sacs_c2_a2": "Ciężki poziom niepokoju/stresu",
  "patient_shoulder_sacs_c3": "Jak często zdajesz sobie sprawę ze swojego problemu z barkiem?",
  "patient_shoulder_sacs_c3_a1": "Nigdy",
  "patient_shoulder_sacs_c3_a2": "Stale",
  "patient_shoulder_sacs_c4": "Czy zmodyfikowałeś swój styl życia, aby uniknąć potencjalnego uszkodzenia lub zaostrzenia problemu z barkiem?",
  "patient_shoulder_sacs_c4_a1": "Zupełnie nie",
  "patient_shoulder_sacs_c4_a2": "Całkowicie",
  "patient_shoulder_sacs_c5": "Jak bardzo jesteś nieszczęślwy/niezadowolony ze swojego ramienia?",
  "patient_shoulder_sacs_c5_a1": "Bardzo szczęśliwy",
  "patient_shoulder_sacs_c5_a2": "Bardzo nieszczęśliwy",
  "patient_shoulder_sacs_c6": "Jak ogólnie oceniłbyś swoje ramię w procentach w porównaniu z oczekiwaniami wobec „normalnego” barku, gdzie 0% to słabo, a 100% to bardzo dobrze?",
  "patient_shoulder_satisfaction": "Zadowolenie",
  "patient_shoulder_satisfaction_q1": "W związku z Twoim chorym barkiem, obecnie jesteś",
  "patient_shoulder_satisfaction_q1_a1": "Zadowolony i/lub lepiej",
  "patient_shoulder_satisfaction_q1_a2": "Niezadowolony i/lub gorzej",
  "patient_shoulder_complete": "Koniec ankiety",
  "patient_shoulder_complete_text1": "Dziękujemy za poświęcenie czasu na wypełnienie tego formularza.",
  "patient_shoulder_complete_text2": "Kliknij przycisk Prześlij, aby zakończyć i zobaczyć swoje wyniki.",
  "patient_shoulder_complete_text3": "Twoje odpowiedzi będą poufne, a wszelkie raporty będą wykorzystywać wyłącznie informacje pozbawione danych umożliwiających identyfikację.",
  "patient_shoulder_results": "Wyniki",
  "patient_shoulder_results_text1": "Poniżej znajdują się Twoje wyniki w zakresie bólu i funkcji barków, które zostały obliczone na podstawie Twojego kwestionariusza.",
  "patient_shoulder_results_pain": "Ból",
  "patient_shoulder_results_function": "Funkcja",
  "patient_shoulder_results_instability": "Niestabilność",
  "patient_shoulder_results_selfevaluation": "Shoulder Scoring Self-Evaluation",
  "patient_shoulder_results_text2": "Twój lekarz wkrótce do Ciebie dotrze",
  "patient_hip_pain": "Ból",
  "patient_hip_pain_q1": "OGÓLNIE, jak opisałbyś ból biodra, który odczuwałeś W OSTATNIM MIESIĄCU?",
  "patient_hip_pain_q1_a1": "Żadnych",
  "patient_hip_pain_q1_a2": "Bardzo łagodny",
  "patient_hip_pain_q1_a3": "Łagodny",
  "patient_hip_pain_q1_a4": "Umiarkowany",
  "patient_hip_pain_q1_a5": "Silny",
  "patient_hip_pain_q2": "Jak silny jest ból biodra W TYM CZASIE? (0 oznacza brak bólu i 10 oznacza najcięższy ból, jaki możesz sobie wyobrazić)",
  "patient_hip_pain_q2_a1": "Brak bólu",
  "patient_hip_pain_q2_a2": "Najcięższy ból",
  "patient_hip_pain_q3": "Które z poniższych najlepiej opisuje ból?",
  "patient_hip_pain_q3_a1": "Brak/do zignorowania",
  "patient_hip_pain_q3_a2": "Niewielki, sporadyczny, bez kompromisów w działaniu",
  "patient_hip_pain_q3_a3": "Łagodny ból, bez wpływu na przeciętną aktywność, ból pojawia się po aktywności, stosuje aspirynę",
  "patient_hip_pain_q3_a4": "Umiarkowany, do zniesienia, ustępuje, okazjonalnie kodeina",
  "patient_hip_pain_q3_a5": "Wyraźny ból, poważne ograniczenie",
  "patient_hip_pain_q3_a6": "Całkowicie niepełnosprawny",
  "patient_hip_pain_q4": "Jak CZĘSTO boli Cię biodro?",
  "patient_hip_pain_q4_a1": "Nigdy",
  "patient_hip_pain_q4_a2": "Co miesiąc",
  "patient_hip_pain_q4_a3": "Co tydzień",
  "patient_hip_pain_q4_a4": "Codziennie",
  "patient_hip_pain_q4_a5": "Zawsze",
  "patient_hip_pain_q5": "W jakim stopniu ból biodra ZAKŁÓCAŁ TWOJĄ PRACĘ (w tym prace domowe)?",
  "patient_hip_pain_q5_a1": "W ogóle",
  "patient_hip_pain_q5_a2": "Trochę",
  "patient_hip_pain_q5_a3": "Umiarkowanie",
  "patient_hip_pain_q5_a4": "Bardzo",
  "patient_hip_pain_q5_a5": "Całkowicie",
  "patient_hip_pain_instruction": "Jak dużego BÓLU BIODRA doświadczyłeś podczas następujących czynności:",
  "patient_hip_pain_instruction_0": "Brak",
  "patient_hip_pain_instruction_1": "Łagodny",
  "patient_hip_pain_instruction_2": "Umiarkowany",
  "patient_hip_pain_instruction_3": "Silny",
  "patient_hip_pain_instruction_4": "Skrajny",
  "patient_hip_pain_note": "Uwaga: Proszę odpowiedzieć na następujące pytania dotyczące aktywności bioder.",
  "patient_hip_pain_q6": "Całkowite wyprostowanie bioder",
  "patient_hip_pain_q6_a1": "Brak",
  "patient_hip_pain_q6_a2": "Łagodny",
  "patient_hip_pain_q6_a3": "Umiarkowany",
  "patient_hip_pain_q6_a4": "Silny",
  "patient_hip_pain_q6_a5": "Skrajny",
  "patient_hip_pain_q7": "Całkowite zgięcie biodra",
  "patient_hip_pain_q7_a1": "Brak",
  "patient_hip_pain_q7_a2": "Łagodny",
  "patient_hip_pain_q7_a3": "Umiarkowany",
  "patient_hip_pain_q7_a4": "Silny",
  "patient_hip_pain_q7_a5": "Skrajny",
  "patient_hip_pain_q8": "Chodzenie po płaskiej powierzchni",
  "patient_hip_pain_q8_a1": "Brak",
  "patient_hip_pain_q8_a2": "Łagodny",
  "patient_hip_pain_q8_a3": "Umiarkowany",
  "patient_hip_pain_q8_a4": "Silny",
  "patient_hip_pain_q8_a5": "Skrajny",
  "patient_hip_pain_q9": "Jak długo możesz chodzić, zanim ból biodra stanie się silny? (z laską lub bez)",
  "patient_hip_pain_q9_a1": "Bez bólu/Więcej niż 30 minut/Nieograniczone",
  "patient_hip_pain_q9_a2": "16-30 minut/6 bloków",
  "patient_hip_pain_q9_a3": "5-15 minut/2-3 bloki",
  "patient_hip_pain_q9_a4": "Wokół domu/Tylko w pomieszczeniu",
  "patient_hip_pain_q9_a5": "Nie mogę w ogóle chodzić/silny ból podczas chodzenia/tylko łóżko i krzesło",
  "patient_hip_pain_q10": "Chodzenie po twardej powierzchni (asfalt, beton itp.)",
  "patient_hip_pain_q10_a1": "Brak",
  "patient_hip_pain_q10_a2": "Łagodny",
  "patient_hip_pain_q10_a3": "Umiarkowany",
  "patient_hip_pain_q10_a4": "Silny",
  "patient_hip_pain_q10_a5": "Skrajny",
  "patient_hip_pain_q11": "Chodzenie po nierównej powierzchni",
  "patient_hip_pain_q11_a1": "Brak",
  "patient_hip_pain_q11_a2": "Łagodny",
  "patient_hip_pain_q11_a3": "Umiarkowany",
  "patient_hip_pain_q11_a4": "Silny",
  "patient_hip_pain_q11_a5": "Skrajny",
  "patient_hip_pain_q12": "Wchodzenie lub schodzenie po schodach",
  "patient_hip_pain_q12_a1": "Brak",
  "patient_hip_pain_q12_a2": "Łagodny",
  "patient_hip_pain_q12_a3": "Umiarkowany",
  "patient_hip_pain_q12_a4": "Silny",
  "patient_hip_pain_q12_a5": "Skrajny",
  "patient_hip_pain_q13": "Wieczorem, będąc w łóżku",
  "patient_hip_pain_q13_a1": "Brak",
  "patient_hip_pain_q13_a2": "Łagodny",
  "patient_hip_pain_q13_a3": "Umiarkowany",
  "patient_hip_pain_q13_a4": "Silny",
  "patient_hip_pain_q13_a5": "Skrajny",
  "patient_hip_pain_q14": "Jak często w nocy, w łózku, dokuczał Ci ból biodra?",
  "patient_hip_pain_q14_a1": "Żadnych nocy",
  "patient_hip_pain_q14_a2": "Tylko 1 lub 2 noce",
  "patient_hip_pain_q14_a3": "Niektóre noce",
  "patient_hip_pain_q14_a4": "Większość nocy",
  "patient_hip_pain_q14_a5": "Każda noc",
  "patient_hip_pain_q15": "Siedzenie lub leżenie",
  "patient_hip_pain_q15_a1": "Brak",
  "patient_hip_pain_q15_a2": "Łagodny",
  "patient_hip_pain_q15_a3": "Umiarkowany",
  "patient_hip_pain_q15_a4": "Silny",
  "patient_hip_pain_q15_a5": "Skrajny",
  "patient_hip_pain_q16": "Stanie wyprostowanym",
  "patient_hip_pain_q16_a1": "Brak",
  "patient_hip_pain_q16_a2": "Łagodny",
  "patient_hip_pain_q16_a3": "Umiarkowany",
  "patient_hip_pain_q16_a4": "Silny",
  "patient_hip_pain_q16_a5": "Skrajny",
  "patient_hip_pain_q17": "Wstawanie z krzesła",
  "patient_hip_pain_q17_a1": "Brak",
  "patient_hip_pain_q17_a2": "Łagodny",
  "patient_hip_pain_q17_a3": "Umiarkowany",
  "patient_hip_pain_q17_a4": "Silny",
  "patient_hip_pain_q17_a5": "Skrajny",
  "patient_hip_pain_q18": "Po aktywności",
  "patient_hip_pain_q18_a1": "Brak",
  "patient_hip_pain_q18_a2": "Łagodny",
  "patient_hip_pain_q18_a3": "Umiarkowany",
  "patient_hip_pain_q18_a4": "Silny",
  "patient_hip_pain_q18_a5": "Skrajny",
  "patient_hip_other_symptoms": "Inne objawy",
  "patient_hip_other_symptoms_instruction1": "Wybierz odpowiedź, która najlepiej odpowiada Twoim objawom stawu biodrowego.",
  "patient_hip_other_symptoms_instruction2": "Jeśli nie jesteś pewien, jak odpowiedzieć na pytanie, podaj najlepszą możliwą odpowiedź.",
  "patient_hip_other_symptoms_instruction3": "Czy masz:",
  "patient_hip_other_symptoms_instruction_0": "brak",
  "patient_hip_other_symptoms_instruction_1": "łagodny",
  "patient_hip_other_symptoms_instruction_2": "umiarkowany",
  "patient_hip_other_symptoms_instruction_3": "Silny",
  "patient_hip_other_symptoms_instruction_4": "skrajny",
  "patient_hip_other_symptoms_q1": "Zgrzytanie, klikanie lub jakikolwiek inny dźwięk wydobywający się z biodra",
  "patient_hip_other_symptoms_q1_a1": "Nigdy",
  "patient_hip_other_symptoms_q1_a2": "Rzadko",
  "patient_hip_other_symptoms_q1_a3": "Czasami",
  "patient_hip_other_symptoms_q1_a4": "Często",
  "patient_hip_other_symptoms_q1_a5": "Zawsze",
  "patient_hip_other_symptoms_q2": "Chwytanie lub zablokowanie",
  "patient_hip_other_symptoms_q2_a1": "Brak",
  "patient_hip_other_symptoms_q2_a2": "Łagodny",
  "patient_hip_other_symptoms_q2_a3": "Umiarkowany",
  "patient_hip_other_symptoms_q2_a4": "Silny",
  "patient_hip_other_symptoms_q2_a5": "Skrajny",
  "patient_hip_other_symptoms_q3": "Sztywność po przebudzeniu rano",
  "patient_hip_other_symptoms_q3_a1": "Brak",
  "patient_hip_other_symptoms_q3_a2": "Łagodny",
  "patient_hip_other_symptoms_q3_a3": "Umiarkowany",
  "patient_hip_other_symptoms_q3_a4": "Silny",
  "patient_hip_other_symptoms_q3_a5": "Skrajny",
  "patient_hip_other_symptoms_q4": "Sztywność po siedzeniu, leżeniu lub odpoczynku w ciągu dnia",
  "patient_hip_other_symptoms_q4_a1": "Brak",
  "patient_hip_other_symptoms_q4_a2": "Łagodny",
  "patient_hip_other_symptoms_q4_a3": "Umiarkowany",
  "patient_hip_other_symptoms_q4_a4": "Silny",
  "patient_hip_other_symptoms_q4_a5": "Skrajny",
  "patient_hip_other_symptoms_q5": "Utykanie",
  "patient_hip_other_symptoms_q5_a1": "Brak, rzadko/nigdy",
  "patient_hip_other_symptoms_q5_a2": "Niewielkie, czasami lub tylko na początku",
  "patient_hip_other_symptoms_q5_a3": "Umiarkowane, często, nie tylko na początku",
  "patient_hip_other_symptoms_q5_a4": "Silne, przez większość czasu",
  "patient_hip_other_symptoms_q5_a5": "Cały czas lub nie mogę chodzić",
  "patient_hip_other_symptoms_q6": "Nagły, silny ból – „rozdzierający”, „kłujący” lub „skurcze” biodra",
  "patient_hip_other_symptoms_q6_a1": "Nigdy",
  "patient_hip_other_symptoms_q6_a2": "Czasem, 1 lub 2 dni",
  "patient_hip_other_symptoms_q6_a3": "Niektóre dni",
  "patient_hip_other_symptoms_q6_a4": "Większość dni",
  "patient_hip_other_symptoms_q6_a5": "Codziennie",
  "patient_hip_other_symptoms_q7": "Biodro ustępuje",
  "patient_hip_other_symptoms_q7_a1": "Brak",
  "patient_hip_other_symptoms_q7_a2": "Łagodny",
  "patient_hip_other_symptoms_q7_a3": "Umiarkowany",
  "patient_hip_other_symptoms_q7_a4": "Silny",
  "patient_hip_other_symptoms_q7_a5": "Skrajny",
  "patient_hip_other_symptoms_q8": "Zmniejszony zakres ruchomości",
  "patient_hip_other_symptoms_q8_a1": "Brak",
  "patient_hip_other_symptoms_q8_a2": "Łagodny",
  "patient_hip_other_symptoms_q8_a3": "Umiarkowany",
  "patient_hip_other_symptoms_q8_a4": "Silny",
  "patient_hip_other_symptoms_q8_a5": "Skrajny",
  "patient_hip_other_symptoms_q9": "Czy używasz jakiegoś wsparcia podczas chodzenia?",
  "patient_hip_other_symptoms_q9_a1": "Żadnego",
  "patient_hip_other_symptoms_q9_a2": "Laska/kijki na długie spacery",
  "patient_hip_other_symptoms_q9_a3": "Laska/kijki przez większość czasu ",
  "patient_hip_other_symptoms_q9_a4": "Jedna kula",
  "patient_hip_other_symptoms_q9_a5": "Dwie laski/dwa kijki ",
  "patient_hip_other_symptoms_q9_a6": "Dwie kule lub brak możliwości chodzenia",
  "patient_hip_function": "Funkcja",
  "patient_hip_function_instruction": "Wskaż STOPIEŃ TRUDNOŚCI, jakiego doświadczyłeś ze względu na stan biodra w ostatnim tygodniu podczas wykonywania następujących czynności ",
  "patient_hip_function_instruction_0": "Brak",
  "patient_hip_function_instruction_1": "Lekki",
  "patient_hip_function_instruction_2": "Umiarkowany",
  "patient_hip_function_instruction_3": "Ciężki",
  "patient_hip_function_instruction_4": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_note": "Uwaga: jeśli nie wykonujesz określonej czynności, wyobraź sobie, jak czułoby się Twoje biodro, gdybyś musiał jej spróbować",
  "patient_hip_function_q1": "Schodzenie po schodach",
  "patient_hip_function_q1_a1": "Brak",
  "patient_hip_function_q1_a2": "Lekki",
  "patient_hip_function_q1_a3": "Umiarkowany",
  "patient_hip_function_q1_a4": "Ciężki",
  "patient_hip_function_q1_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q2": "Wchodzenie po schodach",
  "patient_hip_function_q2_a1": "Brak",
  "patient_hip_function_q2_a2": "Lekki",
  "patient_hip_function_q2_a3": "Umiarkowany",
  "patient_hip_function_q2_a4": "Ciężki",
  "patient_hip_function_q2_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q3": "Siedzenie",
  "patient_hip_function_q3_a1": "Brak",
  "patient_hip_function_q3_a2": "Lekki",
  "patient_hip_function_q3_a3": "Umiarkowany",
  "patient_hip_function_q3_a4": "Ciężki",
  "patient_hip_function_q3_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q4": "Wstawanie z pozycji siedzącej",
  "patient_hip_function_q4_a1": "Brak",
  "patient_hip_function_q4_a2": "Lekki",
  "patient_hip_function_q4_a3": "Umiarkowany",
  "patient_hip_function_q4_a4": "Ciężki",
  "patient_hip_function_q4_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q5": "Wstawanie z podłogi i siadanie",
  "patient_hip_function_q5_a1": "Brak",
  "patient_hip_function_q5_a2": "Lekki",
  "patient_hip_function_q5_a3": "Umiarkowany",
  "patient_hip_function_q5_a4": "Ciężki",
  "patient_hip_function_q5_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q6": "Stanie",
  "patient_hip_function_q6_a1": "Brak",
  "patient_hip_function_q6_a2": "Lekki",
  "patient_hip_function_q6_a3": "Umiarkowany",
  "patient_hip_function_q6_a4": "Ciężki",
  "patient_hip_function_q6_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q7": "Stanie przez 15 minut",
  "patient_hip_function_q7_a1": "Brak",
  "patient_hip_function_q7_a2": "Lekki",
  "patient_hip_function_q7_a3": "Umiarkowany",
  "patient_hip_function_q7_a4": "Ciężki",
  "patient_hip_function_q7_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q8": "Szerokie rozstawianie nóg",
  "patient_hip_function_q8_a1": "Brak",
  "patient_hip_function_q8_a2": "Lekki",
  "patient_hip_function_q8_a3": "Umiarkowany",
  "patient_hip_function_q8_a4": "Ciężki",
  "patient_hip_function_q8_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q9": "Wyjdź podczas chodzenia",
  "patient_hip_function_q9_a1": "Brak",
  "patient_hip_function_q9_a2": "Lekki",
  "patient_hip_function_q9_a3": "Umiarkowany",
  "patient_hip_function_q9_a4": "Ciężki",
  "patient_hip_function_q9_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q10": "Pochylanie się do podłogi/podnoszenie przedmiotu",
  "patient_hip_function_q10_a1": "Brak",
  "patient_hip_function_q10_a2": "Lekki",
  "patient_hip_function_q10_a3": "Umiarkowany",
  "patient_hip_function_q10_a4": "Ciężki",
  "patient_hip_function_q10_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q11": "Chodzenie po płaskiej powierzchni",
  "patient_hip_function_q11_a1": "Brak",
  "patient_hip_function_q11_a2": "Lekki",
  "patient_hip_function_q11_a3": "Umiarkowany",
  "patient_hip_function_q11_a4": "Ciężki",
  "patient_hip_function_q11_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q12": "Chodzenie po nierównej powierzchni",
  "patient_hip_function_q12_a1": "Brak",
  "patient_hip_function_q12_a2": "Lekki",
  "patient_hip_function_q12_a3": "Umiarkowany",
  "patient_hip_function_q12_a4": "Ciężki",
  "patient_hip_function_q12_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q13": "Chodzenie na duże odległości",
  "patient_hip_function_q13_a1": "Brak",
  "patient_hip_function_q13_a2": "Lekki",
  "patient_hip_function_q13_a3": "Umiarkowany",
  "patient_hip_function_q13_a4": "Ciężki",
  "patient_hip_function_q13_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q14": "Chodzenie w celu ćwiczeń",
  "patient_hip_function_q14_a1": "Brak",
  "patient_hip_function_q14_a2": "Lekki",
  "patient_hip_function_q14_a3": "Umiarkowany",
  "patient_hip_function_q14_a4": "Ciężki",
  "patient_hip_function_q14_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q15": "Chodzenie po stromych wzgórzach",
  "patient_hip_function_q15_a1": "Brak",
  "patient_hip_function_q15_a2": "Lekki",
  "patient_hip_function_q15_a3": "Umiarkowany",
  "patient_hip_function_q15_a4": "Ciężki",
  "patient_hip_function_q15_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q16": "Schodzenie ze stromych wzgórz",
  "patient_hip_function_q16_a1": "Brak",
  "patient_hip_function_q16_a2": "Lekki",
  "patient_hip_function_q16_a3": "Umiarkowany",
  "patient_hip_function_q16_a4": "Ciężki",
  "patient_hip_function_q16_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q17": "Początkowo chodzenie",
  "patient_hip_function_q17_a1": "Brak",
  "patient_hip_function_q17_a2": "Lekki",
  "patient_hip_function_q17_a3": "Umiarkowany",
  "patient_hip_function_q17_a4": "Ciężki",
  "patient_hip_function_q17_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q18": "Spacer około 10 minut",
  "patient_hip_function_q18_a1": "Brak",
  "patient_hip_function_q18_a2": "Lekki",
  "patient_hip_function_q18_a3": "Umiarkowany",
  "patient_hip_function_q18_a4": "Ciężki",
  "patient_hip_function_q18_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q19": "Spacer 15 minut lub dłużej",
  "patient_hip_function_q19_a1": "Brak",
  "patient_hip_function_q19_a2": "Lekki",
  "patient_hip_function_q19_a3": "Umiarkowany",
  "patient_hip_function_q19_a4": "Ciężki",
  "patient_hip_function_q19_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q20": "Wchodzenie i schodzenie z krawężników",
  "patient_hip_function_q20_a1": "Brak",
  "patient_hip_function_q20_a2": "Lekki",
  "patient_hip_function_q20_a3": "Umiarkowany",
  "patient_hip_function_q20_a4": "Ciężki",
  "patient_hip_function_q20_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q21": "Wsiadanie/wysiadanie z samochodu lub transportu publicznego (autobusu), w zależności od tego, z którego najczęściej korzystasz",
  "patient_hip_function_q21_a1": "Brak",
  "patient_hip_function_q21_a2": "Lekki",
  "patient_hip_function_q21_a3": "Umiarkowany",
  "patient_hip_function_q21_a4": "Ciężki",
  "patient_hip_function_q21_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q22": "Chodzenie na zakupy",
  "patient_hip_function_q22_a1": "Brak",
  "patient_hip_function_q22_a2": "Lekki",
  "patient_hip_function_q22_a3": "Umiarkowany",
  "patient_hip_function_q22_a4": "Ciężki",
  "patient_hip_function_q22_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q23": "Zakładanie skarpet/pończoch",
  "patient_hip_function_q23_a1": "Brak",
  "patient_hip_function_q23_a2": "Lekki",
  "patient_hip_function_q23_a3": "Umiarkowany",
  "patient_hip_function_q23_a4": "Ciężki",
  "patient_hip_function_q23_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q24": "Zdejmowanie skarpet/pończoch",
  "patient_hip_function_q24_a1": "Brak",
  "patient_hip_function_q24_a2": "Lekki",
  "patient_hip_function_q24_a3": "Umiarkowany",
  "patient_hip_function_q24_a4": "Ciężki",
  "patient_hip_function_q24_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q25": "Leżenie w łóżku (przewracanie się, utrzymywanie pozycji bioder)",
  "patient_hip_function_q25_a1": "Brak",
  "patient_hip_function_q25_a2": "Lekki",
  "patient_hip_function_q25_a3": "Umiarkowany",
  "patient_hip_function_q25_a4": "Ciężki",
  "patient_hip_function_q25_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q26": "Przewracanie się w łóżku",
  "patient_hip_function_q26_a1": "Brak",
  "patient_hip_function_q26_a2": "Lekki",
  "patient_hip_function_q26_a3": "Umiarkowany",
  "patient_hip_function_q26_a4": "Ciężki",
  "patient_hip_function_q26_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q27": "Wstawanie z łóżka",
  "patient_hip_function_q27_a1": "Brak",
  "patient_hip_function_q27_a2": "Lekki",
  "patient_hip_function_q27_a3": "Umiarkowany",
  "patient_hip_function_q27_a4": "Ciężki",
  "patient_hip_function_q27_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q28": "Wchodzenie i wychodzenie z wanny",
  "patient_hip_function_q28_a1": "Brak",
  "patient_hip_function_q28_a2": "Lekki",
  "patient_hip_function_q28_a3": "Umiarkowany",
  "patient_hip_function_q28_a4": "Ciężki",
  "patient_hip_function_q28_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q29": "Siadanie i wstawanie  z toalety",
  "patient_hip_function_q29_a1": "Brak",
  "patient_hip_function_q29_a2": "Lekki",
  "patient_hip_function_q29_a3": "Umiarkowany",
  "patient_hip_function_q29_a4": "Ciężki",
  "patient_hip_function_q29_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q30": "Mycie się i wycieranie (całego ciała)",
  "patient_hip_function_q30_a1": "Brak",
  "patient_hip_function_q30_a2": "Lekki",
  "patient_hip_function_q30_a3": "Umiarkowany",
  "patient_hip_function_q30_a4": "Ciężki",
  "patient_hip_function_q30_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q31": "Praca lekka i umiarkowana (stojąca, chodząca)",
  "patient_hip_function_q31_a1": "Brak",
  "patient_hip_function_q31_a2": "Lekki",
  "patient_hip_function_q31_a3": "Umiarkowany",
  "patient_hip_function_q31_a4": "Ciężki",
  "patient_hip_function_q31_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q32": "Ciężkie prace domowe (przenoszenie ciężkich pudeł, szorowanie podłóg itp.)",
  "patient_hip_function_q32_a1": "Brak",
  "patient_hip_function_q32_a2": "Lekki",
  "patient_hip_function_q32_a3": "Umiarkowany",
  "patient_hip_function_q32_a4": "Ciężki",
  "patient_hip_function_q32_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q33": "Lekkie prace domowe (gotowanie, odkurzanie itp.)",
  "patient_hip_function_q33_a1": "Brak",
  "patient_hip_function_q33_a2": "Lekki",
  "patient_hip_function_q33_a3": "Umiarkowany",
  "patient_hip_function_q33_a4": "Ciężki",
  "patient_hip_function_q33_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q34": "Odbieranie lub noszenie dzieci",
  "patient_hip_function_q34_a1": "Brak",
  "patient_hip_function_q34_a2": "Lekki",
  "patient_hip_function_q34_a3": "Umiarkowany",
  "patient_hip_function_q34_a4": "Ciężki",
  "patient_hip_function_q34_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q35": "Kucanie",
  "patient_hip_function_q35_a1": "Brak",
  "patient_hip_function_q35_a2": "Lekki",
  "patient_hip_function_q35_a3": "Umiarkowany",
  "patient_hip_function_q35_a4": "Ciężki",
  "patient_hip_function_q35_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q36": "Jogging",
  "patient_hip_function_q36_a1": "Brak",
  "patient_hip_function_q36_a2": "Lekki",
  "patient_hip_function_q36_a3": "Umiarkowany",
  "patient_hip_function_q36_a4": "Ciężki",
  "patient_hip_function_q36_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q37": "Bieganie",
  "patient_hip_function_q37_a1": "Brak",
  "patient_hip_function_q37_a2": "Lekki",
  "patient_hip_function_q37_a3": "Umiarkowany",
  "patient_hip_function_q37_a4": "Ciężki",
  "patient_hip_function_q37_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q38": "Skręcanie/obracanie na obciążonej nodze",
  "patient_hip_function_q38_a1": "Brak",
  "patient_hip_function_q38_a2": "Lekki",
  "patient_hip_function_q38_a3": "Umiarkowany",
  "patient_hip_function_q38_a4": "Ciężki",
  "patient_hip_function_q38_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q39": "Aktywność seksualna",
  "patient_hip_function_q39_a1": "Brak",
  "patient_hip_function_q39_a2": "Lekki",
  "patient_hip_function_q39_a3": "Umiarkowany",
  "patient_hip_function_q39_a4": "Ciężki",
  "patient_hip_function_q39_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q39_not_relevant": "To nie ma dla mnie znaczenia",
  "patient_hip_function_q40": "Wymagające sporty, obejmujące sprint lub szybkie zmiany pozycji (np. piłka nożna, koszykówka, tenis, aerobik wysiłkowy)",
  "patient_hip_function_q40_a1": "Brak",
  "patient_hip_function_q40_a2": "Lekki",
  "patient_hip_function_q40_a3": "Umiarkowany",
  "patient_hip_function_q40_a4": "Ciężki",
  "patient_hip_function_q40_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q41": "Mało wymagające sporty (np. golf, kręgle)",
  "patient_hip_function_q41_a1": "Brak",
  "patient_hip_function_q41_a2": "Lekki",
  "patient_hip_function_q41_a3": "Umiarkowany",
  "patient_hip_function_q41_a4": "Ciężki",
  "patient_hip_function_q41_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q42": "Zajęcia rekreacyjne",
  "patient_hip_function_q42_a1": "Brak",
  "patient_hip_function_q42_a2": "Lekki",
  "patient_hip_function_q42_a3": "Umiarkowany",
  "patient_hip_function_q42_a4": "Ciężki",
  "patient_hip_function_q42_a5": "Skrajny/niemożliwy do zrobienia",
  "patient_hip_function_q43": "Jak dziś oceniłbyś swoje biodra jako procent normy? (0% do 100%, przy czym 100% to wartość normalna)",
  "patient_hip_function_q44": "Proszę podać najwyższy poziom aktywności, w jakich możesz uczestniczyć",
  "patient_hip_function_q44_q1": "Przed urazem biodra/problemem z biodrem",
  "patient_hip_function_q44_q1_a1": "Wyczynowy",
  "patient_hip_function_q44_q1_a2": "Rekreacyjny, sprawny fizycznie",
  "patient_hip_function_q44_q1_a3": "Żadnych sportów, tylko praca",
  "patient_hip_function_q44_q1_a4": "Tylko czynności życia codziennego",
  "patient_hip_function_q44_q1_a5": "Zwolnienie lekarskie lub renta z powodu problemów zdrowotnych",
  "patient_hip_function_q44_q1_a1_o1": "Sporty wyczynowe uprawiane na poziomie światowym, olimpijskim lub zawodowym",
  "patient_hip_function_q44_q1_a1_o2": "Sporty wyczynowe, takie jak lekkoatletyka, sporty rakietowe/piłkowe, gimnastyka, wioślarstwo, narciarstwo lub sztuki walki rozgrywane na poziomie krajowym lub międzynarodowym",
  "patient_hip_function_q44_q1_a1_o3": "Sporty wyczynowe, takie jak sporty wodne, jazda na rowerze, hokej, curling lub jazda konno/rodeo rozgrywane na poziomie krajowym lub międzynarodowym",
  "patient_hip_function_q44_q1_a2_o1": "Sporty rekreacyjne, takie jak bieganie, sporty piłkowe/rakietowe, trening siłowy, curling, wioślarstwo, golf, joga lub gimnastyka co najmniej 5 razy w tygodniu",
  "patient_hip_function_q44_q1_a2_o2": "Sporty rekreacyjne, takie jak pływanie, sporty wodne, narciarstwo, hokej, jazda na rolkach, jazda na rowerze, jazda konno lub wspinaczka górska co najmniej 5 razy w tygodniu",
  "patient_hip_function_q44_q1_a2_o3": "Sporty rekreacyjne co najmniej dwa razy w tygodniu",
  "patient_hip_function_q44_q1_a2_o4": "Sporty rekreacyjne raz w tygodniu lub rzadziej",
  "patient_hip_function_q44_q1_a2_o5": "Sporty rekreacyjne rzadziej niż raz w miesiącu",
  "patient_hip_function_q44_q1_a3_o1": "Ciężka praca, np. budowlana",
  "patient_hip_function_q44_q1_a3_o2": "Umiarkowanie ciężka praca, np. jazda ciężarówką",
  "patient_hip_function_q44_q1_a3_o3": "Lekkie prace, takie jak pielęgniarstwo",
  "patient_hip_function_q44_q1_a3_o4": "Praca siedząca, taka jak sekretarka lub praca przy komputerze",
  "patient_hip_function_q44_q1_a4_o1": "Codzienne czynności, takie jak prace w ogrodzie, wchodzenie po wielu schodach, przenoszenie ciężarów, pchanie/ciągnięcie ciężaru, bieganie w przypadku spóźnienia",
  "patient_hip_function_q44_q1_a4_o2": "Codzienne czynności, takie jak sprzątanie domu, wchodzenie po 1 lub 2 piętrach lub dźwiganie lekkiego ciężaru ",
  "patient_hip_function_q44_q1_a4_o3": "Ograniczone codzienne czynności (np. trudność w chodzeniu schodach, niemożność noszenia ciężarów)",
  "patient_hip_function_q44_q2": "Aktualny stan",
  "patient_hip_function_q44_q2_a1": "Wyczynowy",
  "patient_hip_function_q44_q2_a2": "Rekreacyjny, sprawny fizycznie",
  "patient_hip_function_q44_q2_a3": "Żadnych sportów, tylko praca",
  "patient_hip_function_q44_q2_a4": "Tylko czynności życia codziennego",
  "patient_hip_function_q44_q2_a5": "Zwolnienie lekarskie lub renta z powodu problemów zdrowotnych",
  "patient_hip_function_q44_q2_a1_o1": "Sporty wyczynowe uprawiane na poziomie światowym, olimpijskim lub zawodowym",
  "patient_hip_function_q44_q2_a1_o2": "Sporty wyczynowe, takie jak lekkoatletyka, sporty rakietowe/piłkowe, gimnastyka, wioślarstwo, narciarstwo lub sztuki walki rozgrywane na poziomie krajowym lub międzynarodowym",
  "patient_hip_function_q44_q2_a1_o3": "Sporty wyczynowe, takie jak sporty wodne, jazda na rowerze, hokej, curling lub jazda konno/rodeo rozgrywane na poziomie krajowym lub międzynarodowym",
  "patient_hip_function_q44_q2_a2_o1": "Sporty rekreacyjne, takie jak bieganie, sporty piłkowe/rakietowe, trening siłowy, curling, wioślarstwo, golf, joga lub gimnastyka co najmniej 5 razy w tygodniu",
  "patient_hip_function_q44_q2_a2_o2": "Sporty rekreacyjne, takie jak pływanie, sporty wodne, narciarstwo, hokej, jazda na rolkach, jazda na rowerze, jazda konno lub wspinaczka górska co najmniej 5 razy w tygodniu",
  "patient_hip_function_q44_q2_a2_o3": "Sporty rekreacyjne co najmniej dwa razy w tygodniu",
  "patient_hip_function_q44_q2_a2_o4": "Sporty rekreacyjne raz w tygodniu lub rzadziej",
  "patient_hip_function_q44_q2_a2_o5": "Sporty rekreacyjne rzadziej niż raz w miesiącu",
  "patient_hip_function_q44_q2_a3_o1": "Ciężka praca, np. budowlana",
  "patient_hip_function_q44_q2_a3_o2": "Umiarkowanie ciężka praca, np. jazda ciężarówką",
  "patient_hip_function_q44_q2_a3_o3": "Lekkie prace, takie jak pielęgniarstwo",
  "patient_hip_function_q44_q2_a3_o4": "Praca siedząca, taka jak sekretarka lub praca przy komputerze",
  "patient_hip_function_q44_q2_a4_o1": "Codzienne czynności, takie jak prace w ogrodzie, wchodzenie po wielu schodach, przenoszenie ciężarów, pchanie/ciągnięcie ciężaru, bieganie w przypadku spóźnienia",
  "patient_hip_function_q44_q2_a4_o2": "Codzienne czynności, takie jak sprzątanie domu, wchodzenie po 1 lub 2 piętrach lub dźwiganie lekkiego ciężaru ",
  "patient_hip_function_q44_q2_a4_o3": "Ograniczone codzienne czynności (np. trudność w chodzeniu schodach, niemożność noszenia ciężarów)",
  "patient_hip_quality_of_life": "Jakość życia",
  "patient_hip_quality_of_life_instruction": "Poniższe pytania dotyczą obaw społecznych, emocjonalnych i związanych ze stylem życia, które możesz odczuwać w związku z chorobą biodra. Proszę, pomyśl o tym, jak się czułeś przez większość czasu w związku z biodrem.",
  "patient_hip_quality_of_life_q1": "Jak często jesteś świadomy swoich problemów z biodrem?",
  "patient_hip_quality_of_life_q1_a1": "Nigdy",
  "patient_hip_quality_of_life_q1_a2": "Co miesiąc",
  "patient_hip_quality_of_life_q1_a3": "Co tydzień",
  "patient_hip_quality_of_life_q1_a4": "Codziennie",
  "patient_hip_quality_of_life_q1_a5": "Stale",
  "patient_hip_quality_of_life_q2": "Czy zmodyfikowałeś swój styl życia, aby uniknąć potencjalnie szkodliwych czynności dla biodra?",
  "patient_hip_quality_of_life_q2_a1": "Zupełnie nie",
  "patient_hip_quality_of_life_q2_a2": "Lekko",
  "patient_hip_quality_of_life_q2_a3": "Miernie",
  "patient_hip_quality_of_life_q2_a4": "Znacznie",
  "patient_hip_quality_of_life_q2_a5": "Całkowicie",
  "patient_hip_quality_of_life_q3": "Jak bardzo obawiasz się o swoją zdolność do utrzymania pożądanego poziomu sprawności fizycznej?",
  "patient_hip_quality_of_life_q3_a1": "W ogóle się tym nie przejmuję",
  "patient_hip_quality_of_life_q3_a2": "Niezwykle zaniepokojony",
  "patient_hip_quality_of_life_q4": "Jak bardzo dokucza Ci niepewność związana z biodrem?",
  "patient_hip_quality_of_life_q4_a1": "Zupełnie nie",
  "patient_hip_quality_of_life_q4_a2": "Lekko",
  "patient_hip_quality_of_life_q4_a3": "Miernie",
  "patient_hip_quality_of_life_q4_a4": "Znacznie",
  "patient_hip_quality_of_life_q4_a5": "Niezwykle",
  "patient_hip_quality_of_life_q5": "Jak bardzo rozprasza Cię problem z biodrem?",
  "patient_hip_quality_of_life_q5_a1": "Nie rozprasza",
  "patient_hip_quality_of_life_q5_a2": "Skrajnie rozprasza",
  "patient_hip_quality_of_life_q6": "Ogólnie rzecz biorąc, jak duże masz problemy z biodrem?",
  "patient_hip_quality_of_life_q6_a1": "Brak",
  "patient_hip_quality_of_life_q6_a2": "Lekkie",
  "patient_hip_quality_of_life_q6_a3": "Umiarkowane",
  "patient_hip_quality_of_life_q6_a4": "Duże",
  "patient_hip_quality_of_life_q6_a5": "Skrajne",
  "patient_hip_quality_of_life_q7": "Jak bardzo niespokojny/przygnębiony jesteś dzisiaj?",
  "patient_hip_quality_of_life_q7_a1": "W ogóle",
  "patient_hip_quality_of_life_q7_a2": "Lekko",
  "patient_hip_quality_of_life_q7_a3": "Umiarkowanie",
  "patient_hip_quality_of_life_q7_a4": "Znacznie",
  "patient_hip_quality_of_life_q7_a5": "Skrajnie",
  "patient_hip_quality_of_life_q8": "Jak dobry/zły jest dzisiaj Twój stan zdrowia? (100 oznacza najlepsze zdrowie, 0 oznacza najgorsze zdrowie, jakie możesz sobie wyobrazić)",
  "patient_hip_quality_of_life_q8_a1": "Najgorsze zdrowie",
  "patient_hip_quality_of_life_q8_a2": "Najlepsze zdrowie",
  "patient_hip_satisfaction": "Zadowolenie",
  "patient_hip_satisfaction_instructions": "Jak bardzo jesteś zadowolony z wyników operacji/leczenia stawu biodrowego?",
  "patient_hip_satisfaction_instructions_1": "Bardzo nieusatysfakcjonowany",
  "patient_hip_satisfaction_instructions_2": "Nieco niezadowolony",
  "patient_hip_satisfaction_instructions_3": "Częściowo usatysfakcjonowany",
  "patient_hip_satisfaction_instructions_4": "Bardzo zadowolony",
  "patient_hip_satisfaction_q1": "Ogólnie",
  "patient_hip_satisfaction_q1_not_applicable": "Nie dotyczy",
  "patient_hip_satisfaction_q1_a1": "Bardzo nieusatysfakcjonowany",
  "patient_hip_satisfaction_q1_a2": "Nieco niezadowolony",
  "patient_hip_satisfaction_q1_a3": "Częściowo usatysfakcjonowany",
  "patient_hip_satisfaction_q1_a4": "Bardzo zadowolony",
  "patient_hip_satisfaction_q2": "W związku z poprawą dolegliwości bólowych?",
  "patient_hip_satisfaction_q2_not_applicable": "Nie dotyczy",
  "patient_hip_satisfaction_q2_a1": "Bardzo nieusatysfakcjonowany",
  "patient_hip_satisfaction_q2_a2": "Nieco niezadowolony",
  "patient_hip_satisfaction_q2_a3": "Częściowo usatysfakcjonowany",
  "patient_hip_satisfaction_q2_a4": "Bardzo zadowolony",
  "patient_hip_satisfaction_q3": "W związku z poprawą umiejętności wykonywania prac w domu lub na podwórku?",
  "patient_hip_satisfaction_q3_not_applicable": "Nie dotyczy",
  "patient_hip_satisfaction_q3_a1": "Bardzo nieusatysfakcjonowany",
  "patient_hip_satisfaction_q3_a2": "Nieco niezadowolony",
  "patient_hip_satisfaction_q3_a3": "Częściowo usatysfakcjonowany",
  "patient_hip_satisfaction_q3_a4": "Bardzo zadowolony",
  "patient_hip_satisfaction_q4": "W związku z poprawą w zakresie zajęć rekreacyjnych?",
  "patient_hip_satisfaction_q4_not_applicable": "Nie dotyczy",
  "patient_hip_satisfaction_q4_a1": "Bardzo nieusatysfakcjonowany",
  "patient_hip_satisfaction_q4_a2": "Nieco niezadowolony",
  "patient_hip_satisfaction_q4_a3": "Częściowo usatysfakcjonowany",
  "patient_hip_satisfaction_q4_a4": "Bardzo zadowolony",
  "patient_hip_complete": "Koniec ankiety",
  "patient_hip_complete_text1": "Dziękujemy za poświęcenie czasu na wypełnienie tego formularza.",
  "patient_hip_complete_text2": "Kliknij przycisk Prześlij, aby zakończyć i zobaczyć swoje wyniki.",
  "patient_hip_complete_text3": "Twoje odpowiedzi będą traktowane poufnie, a wszelkie raporty będą wykorzystywać wyłącznie informacje pozbawione danych umożliwiających identyfikację.",
  "patient_hip_results": "Wyniki",
  "patient_hip_results_text1": "Poniżej znajdują się Twoje wyniki w zakresie bólu i funkcji stawu biodrowego, które zostały obliczone na podstawie Twojego kwestionariusza.",
  "patient_hip_results_pain": "Ból",
  "patient_hip_results_function": "Funkcja",
  "patient_hip_results_health_perception": "Ogólne postrzeganie zdrowia",
  "patient_hip_results_self_evaluation": "Skala samooceny biodra",
  "patient_hip_results_text2": "Twój lekarz wkrótce do Ciebie dotrze",
  "": "",
  "patient_adult_knee_pain": "Ból",
  "patient_adult_knee_pain_q1": "Jaki jest najwyższy poziom aktywności, jaki możesz wykonywać bez odczuwalnego bólu kolana?",
  "patient_adult_knee_pain_q1_a1": "Bardzo wyczerpujące czynności, takie jak skakanie lub obracanie się, jak w koszykówce lub piłce nożnej",
  "patient_adult_knee_pain_q1_a2": "Intensywne zajęcia, takie jak ciężka praca fizyczna, jazda na nartach lub tenis",
  "patient_adult_knee_pain_q1_a3": "Umiarkowana aktywność, taka jak umiarkowana praca fizyczna, bieganie lub jogging",
  "patient_adult_knee_pain_q1_a4": "Lekkie zajęcia, takie jak spacery, prace domowe lub prace w ogrodzie",
  "patient_adult_knee_pain_q1_a5": "Nie mogę wykonywać żadnej z powyższych czynności z powodu bólu kolana",
  "patient_adult_knee_pain_q2": "Jak często odczuwałeś ból?",
  "patient_adult_knee_pain_q2_a1": "Nigdy",
  "patient_adult_knee_pain_q2_a2": "Co miesiąc",
  "patient_adult_knee_pain_q2_a3": "Co tydzień",
  "patient_adult_knee_pain_q2_a4": "Codziennie",
  "patient_adult_knee_pain_q2_a5": "Stale",
  "patient_adult_knee_pain_q3": "Które z poniższych stwierdzeń najlepiej opisuje ból?",
  "patient_adult_knee_pain_q3_a1": "Bez bólu, kolano prawidłowe, sprawne w 100%",
  "patient_adult_knee_pain_q3_a2": "Sporadyczny ból przy intensywnym sporcie i ciężkiej pracy, kolano nie do końca prawidłowe, pewne ograniczenia, ale niewielkie i znośne",
  "patient_adult_knee_pain_q3_a3": "Ból zwykle powodowany przez uprawianie sportu, lekkie zajęcia rekreacyjne lub umiarkowaną pracę. Czasami występuje podczas chodzenia na dystansie dłuższym niż 2 km, podczas stania lub przy lekkiej pracy",
  "patient_adult_knee_pain_q3_a4": "Sporadyczny ból związany z lekkimi sportami rekreacyjnymi lub umiarkowaną pracą, bieganiem lub ciężkim wysiłkiem, forsownymi sportami",
  "patient_adult_knee_pain_q3_a5": "Ból jest znaczący przy prostych czynnościach, takich jak chodzenie <2 km, ustępuje po odpoczynku, nie można uprawiać sportu",
  "patient_adult_knee_pain_q3_a6": "Stały",
  "patient_adult_knee_pain_q4": "Jeśli odczuwasz ból, jak silny jest on?",
  "patient_adult_knee_pain_q4_a1": "Brak",
  "patient_adult_knee_pain_q4_a2": "Bardzo łagodny",
  "patient_adult_knee_pain_q4_a3": "Łagodny",
  "patient_adult_knee_pain_q4_a4": "Umiarkowany",
  "patient_adult_knee_pain_q4_a5": "Silny",
  "patient_adult_knee_pain_q5": "W jakim stopniu ból kolana zakłócał Twoją pracę (w tym prace domowe)?",
  "patient_adult_knee_pain_q5_a1": "W ogóle",
  "patient_adult_knee_pain_q5_a2": "Trochę",
  "patient_adult_knee_pain_q5_a3": "Umiarkowanie",
  "patient_adult_knee_pain_q5_a4": "Bardzo",
  "patient_adult_knee_pain_q5_a5": "Całkowicie",
  "patient_adult_knee_pain_instruction": "Jaki bólu kolana odczuwałeś podczas wykonywania następujących czynności:",
  "patient_adult_knee_pain_instruction_0": "Brak",
  "patient_adult_knee_pain_instruction_1": "Łagodny",
  "patient_adult_knee_pain_instruction_2": "Umiarkowany",
  "patient_adult_knee_pain_instruction_3": "Silny",
  "patient_adult_knee_pain_instruction_4": "Skrajny",
  "patient_adult_knee_pain_q6": "Skręcanie/obrotowe kolano",
  "patient_adult_knee_pain_q6_a1": "Brak",
  "patient_adult_knee_pain_q6_a2": "Łagodny",
  "patient_adult_knee_pain_q6_a3": "Umiarkowany",
  "patient_adult_knee_pain_q6_a4": "Silny",
  "patient_adult_knee_pain_q6_a5": "Skrajny",
  "patient_adult_knee_pain_q7": "Pełny wyprost kolana",
  "patient_adult_knee_pain_q7_a1": "Brak",
  "patient_adult_knee_pain_q7_a2": "Łagodny",
  "patient_adult_knee_pain_q7_a3": "Umiarkowany",
  "patient_adult_knee_pain_q7_a4": "Silny",
  "patient_adult_knee_pain_q7_a5": "Skrajny",
  "patient_adult_knee_pain_q8": "Pełne zgięcie kolana",
  "patient_adult_knee_pain_q8_a1": "Brak",
  "patient_adult_knee_pain_q8_a2": "Łagodny",
  "patient_adult_knee_pain_q8_a3": "Umiarkowany",
  "patient_adult_knee_pain_q8_a4": "Silny",
  "patient_adult_knee_pain_q8_a5": "Skrajny",
  "patient_adult_knee_pain_q9": "Chodzenie po płaskiej powierzchni",
  "patient_adult_knee_pain_q9_a1": "Brak",
  "patient_adult_knee_pain_q9_a2": "Łagodny",
  "patient_adult_knee_pain_q9_a3": "Umiarkowany",
  "patient_adult_knee_pain_q9_a4": "Silny",
  "patient_adult_knee_pain_q9_a5": "Skrajny",
  "patient_adult_knee_pain_q10": "Jak długo możesz chodzić, zanim ból kolana stanie się silny? (z kijkami lub bez)",
  "patient_adult_knee_pain_q10_a1": "Ponad 30 minut",
  "patient_adult_knee_pain_q10_a2": "16-30 minut",
  "patient_adult_knee_pain_q10_a3": "5-15 minut",
  "patient_adult_knee_pain_q10_a4": "Tylko wokół domu",
  "patient_adult_knee_pain_q10_a5": "Nie mogę w ogóle chodzić/silny ból podczas chodzenia",
  "patient_adult_knee_pain_q11": "Wchodzenie lub schodzenie po schodach",
  "patient_adult_knee_pain_q11_a1": "Brak",
  "patient_adult_knee_pain_q11_a2": "Łagodny",
  "patient_adult_knee_pain_q11_a3": "Umiarkowany",
  "patient_adult_knee_pain_q11_a4": "Silny",
  "patient_adult_knee_pain_q11_a5": "Skrajny",
  "patient_adult_knee_pain_q12": "Wieczorem, będąc w łóżku",
  "patient_adult_knee_pain_q12_a1": "Brak",
  "patient_adult_knee_pain_q12_a2": "Łagodny",
  "patient_adult_knee_pain_q12_a3": "Umiarkowany",
  "patient_adult_knee_pain_q12_a4": "Silny",
  "patient_adult_knee_pain_q12_a5": "Skrajny",
  "patient_adult_knee_pain_q13": "Jak często, w nocy, w łóżku dokuczał Ci ból kolana?",
  "patient_adult_knee_pain_q13_a1": "Nigdy",
  "patient_adult_knee_pain_q13_a2": "1 lub 2 noce",
  "patient_adult_knee_pain_q13_a3": "Niektóre noce",
  "patient_adult_knee_pain_q13_a4": "Większość nocy",
  "patient_adult_knee_pain_q13_a5": "Każda noc",
  "patient_adult_knee_pain_q14": "Siedzenie lub leżenie",
  "patient_adult_knee_pain_q14_a1": "Brak",
  "patient_adult_knee_pain_q14_a2": "Łagodny",
  "patient_adult_knee_pain_q14_a3": "Umiarkowany",
  "patient_adult_knee_pain_q14_a4": "Silny",
  "patient_adult_knee_pain_q14_a5": "Skrajny",
  "patient_adult_knee_pain_q15": "Pozycja stojąca",
  "patient_adult_knee_pain_q15_a1": "Brak",
  "patient_adult_knee_pain_q15_a2": "Łagodny",
  "patient_adult_knee_pain_q15_a3": "Umiarkowany",
  "patient_adult_knee_pain_q15_a4": "Silny",
  "patient_adult_knee_pain_q15_a5": "Skrajny",
  "patient_adult_knee_pain_q16": "Wstawanie z krzesła",
  "patient_adult_knee_pain_q16_a1": "Brak",
  "patient_adult_knee_pain_q16_a2": "Łagodny",
  "patient_adult_knee_pain_q16_a3": "Umiarkowany",
  "patient_adult_knee_pain_q16_a4": "Silny",
  "patient_adult_knee_pain_q16_a5": "Skrajny",
  "patient_adult_knee_other_symptoms": "Inne objawy",
  "patient_adult_knee_other_symptoms_instruction1": "Wybierz odpowiedź, która najlepiej odpowiada dolegliwościom kolana.",
  "patient_adult_knee_other_symptoms_instruction2": "Jeśli nie jesteś pewien, jak odpowiedzieć na pytanie, podaj najlepszą możliwą odpowiedź.",
  "patient_adult_knee_other_symptoms_instruction3": "Czy masz:",
  "patient_adult_knee_other_symptoms_instruction_0": "Brak",
  "patient_adult_knee_other_symptoms_instruction_1": "Łagodny",
  "patient_adult_knee_other_symptoms_instruction_2": "Umiarkowany",
  "patient_adult_knee_other_symptoms_instruction_3": "Silny",
  "patient_adult_knee_other_symptoms_instruction_4": "Skrajny",
  "patient_adult_knee_other_symptoms_q1": "Obrzęk",
  "patient_adult_knee_other_symptoms_q1_a1": "Nigdy",
  "patient_adult_knee_other_symptoms_q1_a2": "Rzadko",
  "patient_adult_knee_other_symptoms_q1_a3": "Czasami",
  "patient_adult_knee_other_symptoms_q1_a4": "Często",
  "patient_adult_knee_other_symptoms_q1_a5": "Zawsze",
  "patient_adult_knee_other_symptoms_q2": "Zgrzytanie, klikanie lub inny rodzaj hałasu podczas ruchu kolana",
  "patient_adult_knee_other_symptoms_q2_a1": "Nigdy",
  "patient_adult_knee_other_symptoms_q2_a2": "Rzadko",
  "patient_adult_knee_other_symptoms_q2_a3": "Czasami",
  "patient_adult_knee_other_symptoms_q2_a4": "Często",
  "patient_adult_knee_other_symptoms_q2_a5": "Zawsze",
  "patient_adult_knee_other_symptoms_q3": "Chwytanie lub blokowanie",
  "patient_adult_knee_other_symptoms_q3_a1": "Nigdy",
  "patient_adult_knee_other_symptoms_q3_a2": "Rzadko",
  "patient_adult_knee_other_symptoms_q3_a3": "Czasami",
  "patient_adult_knee_other_symptoms_q3_a4": "Często",
  "patient_adult_knee_other_symptoms_q3_a5": "Zawsze",
  "patient_adult_knee_other_symptoms_q4": "Utykanie",
  "patient_adult_knee_other_symptoms_q4_a1": "Rzadko/Nigdy",
  "patient_adult_knee_other_symptoms_q4_a2": "Czasami lub tylko na początku",
  "patient_adult_knee_other_symptoms_q4_a3": "Często, nie tylko na początku",
  "patient_adult_knee_other_symptoms_q4_a4": "Większość czasu",
  "patient_adult_knee_other_symptoms_q4_a5": "Cały czas",
  "patient_adult_knee_other_symptoms_q5": "Jak sztywne lub spuchnięte było Twoje kolano w ciągu ostatnich 4 tygodni lub od czasu kontuzji kolana?",
  "patient_adult_knee_other_symptoms_q5_a1": "W ogóle",
  "patient_adult_knee_other_symptoms_q5_a2": "Łagodnie",
  "patient_adult_knee_other_symptoms_q5_a3": "Umiarkowanie",
  "patient_adult_knee_other_symptoms_q5_a4": "Bardzo",
  "patient_adult_knee_other_symptoms_q5_a5": "Skrajnie",
  "patient_adult_knee_other_symptoms_q6": "Jaki jest najwyższy poziom aktywności, jaki możesz wykonywać bez obrzęku kolana?",
  "patient_adult_knee_other_symptoms_q6_a1": "Bardzo wyczerpujące czynności, takie jak skakanie lub obracanie się, jak w koszykówce lub piłce nożnej",
  "patient_adult_knee_other_symptoms_q6_a2": "Intensywne zajęcia, takie jak ciężka praca fizyczna, jazda na nartach lub tenis",
  "patient_adult_knee_other_symptoms_q6_a3": "Umiarkowana aktywność, taka jak umiarkowana praca fizyczna, bieganie lub jogging",
  "patient_adult_knee_other_symptoms_q6_a4": "Lekkie zajęcia, takie jak spacery, prace domowe lub prace w ogrodzie",
  "patient_adult_knee_other_symptoms_q6_a5": "Niemożność wykonywania żadnej z powyższych czynności z powodu obrzęku kolana",
  "patient_adult_knee_other_symptoms_q7": "Jak bardzo sztywny jest staw:",
  "patient_adult_knee_other_symptoms_q7_0": "W ogóle",
  "patient_adult_knee_other_symptoms_q7_1": "Lekko",
  "patient_adult_knee_other_symptoms_q7_2": "Umiarkowanie",
  "patient_adult_knee_other_symptoms_q7_3": "Bardzo",
  "patient_adult_knee_other_symptoms_q7_4": "Skrajnie",
  "patient_adult_knee_other_symptoms_q7_q1": "Po pierwszym przebudzeniu rano?",
  "patient_adult_knee_other_symptoms_q7_q1_a1": "W ogóle",
  "patient_adult_knee_other_symptoms_q7_q1_a2": "Lekko",
  "patient_adult_knee_other_symptoms_q7_q1_a3": "Umiarkowanie",
  "patient_adult_knee_other_symptoms_q7_q1_a4": "Bardzo",
  "patient_adult_knee_other_symptoms_q7_q1_a5": "Skrajnie",
  "patient_adult_knee_other_symptoms_q7_q2": "Po siedzeniu, leżeniu lub odpoczynku w ciągu dnia?",
  "patient_adult_knee_other_symptoms_q7_q2_a1": "W ogóle",
  "patient_adult_knee_other_symptoms_q7_q2_a2": "Lekko",
  "patient_adult_knee_other_symptoms_q7_q2_a3": "Umiarkowanie",
  "patient_adult_knee_other_symptoms_q7_q2_a4": "Bardzo",
  "patient_adult_knee_other_symptoms_q7_q2_a5": "Skrajnie",
  "patient_adult_knee_other_symptoms_q8": "Jaki jest najwyższy poziom aktywności, który możesz wykonać bez uciekania kolana?",
  "patient_adult_knee_other_symptoms_q8_a1": "Bez uciekania",
  "patient_adult_knee_other_symptoms_q8_a2": "Sporadycznie ucieka podczas wytężonych sportów lub ciężkiej pracy, mogę uprawiać wszystkie sporty, ale występują pewne ograniczenia. Rzadko podczas lekkoatletyki lub innego ciężkiego wysiłku",
  "patient_adult_knee_other_symptoms_q8_a3": "Często podczas lekkoatletyki lub innego ciężkiego wysiłku. Sporadycznie ucieka przy lekkich sportach lub umiarkowanej pracy. Potrafię kompensować, ale ogranicza energiczne aktywności, sport lub ciężką pracę, nie jestem w stanie nagle zawrócić ani skręcić",
  "patient_adult_knee_other_symptoms_q8_a4": "Uciekanie ogranicza uprawianie sportu lub umiarkowaną pracę, zdarza się rzadko przy chodzeniu lub lekkiej pracy (ok. 3 razy w roku). Czasem w codziennych czynnościach",
  "patient_adult_knee_other_symptoms_q8_a5": "Ucieka podczas spacerów i lekkiej pracy. Występuje raz w miesiącu, wymaga wspomagania. Często w codziennych czynnościach",
  "patient_adult_knee_other_symptoms_q8_a6": "Poważny problem z prostymi czynnościami związanymi z chodzeniem, uciekanie kolana podczas każdego kroku, brak możliwości obrotu lub skręcenia podczas chodzenia bez uciekania",
  "patient_adult_knee_other_symptoms_q9": "Czy używasz jakiegoś WSPARCIA podczas chodzenia?",
  "patient_adult_knee_other_symptoms_q9_a1": "Brak",
  "patient_adult_knee_other_symptoms_q9_a2": "Kij lub kula",
  "patient_adult_knee_other_symptoms_q9_a3": "Obciążanie niemożliwe",
  "patient_adult_knee_function": "Funkcja",
  "patient_adult_knee_function_instruction": "Wskaż STOPIEŃ TRUDNOŚCI, jakiego doświadczyłeś w ostatnim tygodniu podczas wykonywania następujących czynności w związku z kolanem",
  "patient_adult_knee_function_instruction_0": "Żadnych trudności",
  "patient_adult_knee_function_instruction_1": "Minimalna trudność",
  "patient_adult_knee_function_instruction_2": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_instruction_3": "Ekstremalna trudność",
  "patient_adult_knee_function_instruction_4": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_note": "Uwaga: jeśli nie wykonujesz określonej czynności, wyobraź sobie, jak czułoby się Twoje kolano, gdybyś musiał jej spróbować.",
  "patient_adult_knee_function_q1": "Wchodzenie po schodach",
  "patient_adult_knee_function_q1_a1": "Żadnych trudności",
  "patient_adult_knee_function_q1_a2": "Minimalna trudność",
  "patient_adult_knee_function_q1_a3": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_q1_a4": "Ekstremalna trudność",
  "patient_adult_knee_function_q1_a5": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_q2": "Schodzenie ze schodów",
  "patient_adult_knee_function_q2_a1": "Żadnych trudności",
  "patient_adult_knee_function_q2_a2": "Minimalna trudność",
  "patient_adult_knee_function_q2_a3": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_q2_a4": "Ekstremalna trudność",
  "patient_adult_knee_function_q2_a5": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_q3": "Uklęknięcie na kolanie",
  "patient_adult_knee_function_q3_a1": "Żadnych trudności",
  "patient_adult_knee_function_q3_a2": "Minimalna trudność",
  "patient_adult_knee_function_q3_a3": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_q3_a4": "Ekstremalna trudność",
  "patient_adult_knee_function_q3_a5": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_q4": "Prostowanie kolana",
  "patient_adult_knee_function_q4_a1": "Żadnych trudności",
  "patient_adult_knee_function_q4_a2": "Minimalna trudność",
  "patient_adult_knee_function_q4_a3": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_q4_a4": "Ekstremalna trudność",
  "patient_adult_knee_function_q4_a5": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_q5": "Zginanie kolana",
  "patient_adult_knee_function_q5_a1": "Żadnych trudności",
  "patient_adult_knee_function_q5_a2": "Minimalna trudność",
  "patient_adult_knee_function_q5_a3": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_q5_a4": "Ekstremalna trudność",
  "patient_adult_knee_function_q5_a5": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_q6": "Przysiad",
  "patient_adult_knee_function_q6_a1": "Żadnych trudności",
  "patient_adult_knee_function_q6_a2": "Minimalna trudność",
  "patient_adult_knee_function_q6_a3": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_q6_a4": "Ekstremalna trudność",
  "patient_adult_knee_function_q6_a5": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_q7": "Siedzenie na ugiętych kolanach",
  "patient_adult_knee_function_q7_a1": "Żadnych trudności",
  "patient_adult_knee_function_q7_a2": "Minimalna trudność",
  "patient_adult_knee_function_q7_a3": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_q7_a4": "Ekstremalna trudność",
  "patient_adult_knee_function_q7_a5": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_q8": "Wstanie z krzesła",
  "patient_adult_knee_function_q8_a1": "Żadnych trudności",
  "patient_adult_knee_function_q8_a2": "Minimalna trudność",
  "patient_adult_knee_function_q8_a3": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_q8_a4": "Ekstremalna trudność",
  "patient_adult_knee_function_q8_a5": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_q9": "Wstanie z łóżka",
  "patient_adult_knee_function_q9_a1": "Żadnych trudności",
  "patient_adult_knee_function_q9_a2": "Minimalna trudność",
  "patient_adult_knee_function_q9_a3": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_q9_a4": "Ekstremalna trudność",
  "patient_adult_knee_function_q9_a5": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_q10": "Leżenie w łóżku (przewracanie się, utrzymywanie pozycji kolan)",
  "patient_adult_knee_function_q10_a1": "Żadnych trudności",
  "patient_adult_knee_function_q10_a2": "Minimalna trudność",
  "patient_adult_knee_function_q10_a3": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_q10_a4": "Ekstremalna trudność",
  "patient_adult_knee_function_q10_a5": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_q11": "Pochylanie się do podłogi/podnoszenie przedmiotu",
  "patient_adult_knee_function_q11_a1": "Żadnych trudności",
  "patient_adult_knee_function_q11_a2": "Minimalna trudność",
  "patient_adult_knee_function_q11_a3": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_q11_a4": "Ekstremalna trudność",
  "patient_adult_knee_function_q11_a5": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_q12": "Zakładanie skarpet/pończoch",
  "patient_adult_knee_function_q12_a1": "Żadnych trudności",
  "patient_adult_knee_function_q12_a2": "Minimalna trudność",
  "patient_adult_knee_function_q12_a3": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_q12_a4": "Ekstremalna trudność",
  "patient_adult_knee_function_q12_a5": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_q13": "Zdejmowanie skarpet/pończoch",
  "patient_adult_knee_function_q13_a1": "Żadnych trudności",
  "patient_adult_knee_function_q13_a2": "Minimalna trudność",
  "patient_adult_knee_function_q13_a3": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_q13_a4": "Ekstremalna trudność",
  "patient_adult_knee_function_q13_a5": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_q14": "Stanie",
  "patient_adult_knee_function_q14_a1": "Żadnych trudności",
  "patient_adult_knee_function_q14_a2": "Minimalna trudność",
  "patient_adult_knee_function_q14_a3": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_q14_a4": "Ekstremalna trudność",
  "patient_adult_knee_function_q14_a5": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_q15": "Chód",
  "patient_adult_knee_function_q15_a1": "Żadnych trudności",
  "patient_adult_knee_function_q15_a2": "Minimalna trudność",
  "patient_adult_knee_function_q15_a3": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_q15_a4": "Ekstremalna trudność",
  "patient_adult_knee_function_q15_a5": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_q16": "Bieg",
  "patient_adult_knee_function_q16_a1": "Żadnych trudności",
  "patient_adult_knee_function_q16_a2": "Minimalna trudność",
  "patient_adult_knee_function_q16_a3": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_q16_a4": "Ekstremalna trudność",
  "patient_adult_knee_function_q16_a5": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_q17": "Skakanie i lądowanie na chorej nodze",
  "patient_adult_knee_function_q17_a1": "Żadnych trudności",
  "patient_adult_knee_function_q17_a2": "Minimalna trudność",
  "patient_adult_knee_function_q17_a3": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_q17_a4": "Ekstremalna trudność",
  "patient_adult_knee_function_q17_a5": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_q18": "Szybkie zatrzymanie i ruszanie",
  "patient_adult_knee_function_q18_a1": "Żadnych trudności",
  "patient_adult_knee_function_q18_a2": "Minimalna trudność",
  "patient_adult_knee_function_q18_a3": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_q18_a4": "Ekstremalna trudność",
  "patient_adult_knee_function_q18_a5": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_q19": "Skręcanie/obracanie się na kontuzjowanym kolanie",
  "patient_adult_knee_function_q19_a1": "Żadnych trudności",
  "patient_adult_knee_function_q19_a2": "Minimalna trudność",
  "patient_adult_knee_function_q19_a3": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_q19_a4": "Ekstremalna trudność",
  "patient_adult_knee_function_q19_a5": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_q20": "Wsiadanie i wysiadanie z samochodu lub korzystanie z transportu publicznego",
  "patient_adult_knee_function_q20_a1": "Żadnych trudności",
  "patient_adult_knee_function_q20_a2": "Minimalna trudność",
  "patient_adult_knee_function_q20_a3": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_q20_a4": "Ekstremalna trudność",
  "patient_adult_knee_function_q20_a5": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_q21": "Wchodzenie i wychodzenie z wanny",
  "patient_adult_knee_function_q21_a1": "Żadnych trudności",
  "patient_adult_knee_function_q21_a2": "Minimalna trudność",
  "patient_adult_knee_function_q21_a3": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_q21_a4": "Ekstremalna trudność",
  "patient_adult_knee_function_q21_a5": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_q22": "Siadanie i wstawanie z toalety",
  "patient_adult_knee_function_q22_a1": "Żadnych trudności",
  "patient_adult_knee_function_q22_a2": "Minimalna trudność",
  "patient_adult_knee_function_q22_a3": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_q22_a4": "Ekstremalna trudność",
  "patient_adult_knee_function_q22_a5": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_q23": "Mycie się i wycieranie",
  "patient_adult_knee_function_q23_a1": "Żadnych trudności",
  "patient_adult_knee_function_q23_a2": "Minimalna trudność",
  "patient_adult_knee_function_q23_a3": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_q23_a4": "Ekstremalna trudność",
  "patient_adult_knee_function_q23_a5": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_q24": "Zakupy domowe",
  "patient_adult_knee_function_q24_a1": "Żadnych trudności",
  "patient_adult_knee_function_q24_a2": "Minimalna trudność",
  "patient_adult_knee_function_q24_a3": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_q24_a4": "Ekstremalna trudność",
  "patient_adult_knee_function_q24_a5": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_q25": "Ciężkie prace domowe (przenoszenie ciężkich pudeł, szorowanie podłóg itp.)",
  "patient_adult_knee_function_q25_a1": "Żadnych trudności",
  "patient_adult_knee_function_q25_a2": "Minimalna trudność",
  "patient_adult_knee_function_q25_a3": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_q25_a4": "Ekstremalna trudność",
  "patient_adult_knee_function_q25_a5": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_q26": "Lekkie prace domowe (gotowanie, odkurzanie itp.)",
  "patient_adult_knee_function_q26_a1": "Żadnych trudności",
  "patient_adult_knee_function_q26_a2": "Minimalna trudność",
  "patient_adult_knee_function_q26_a3": "Umiarkowany poziom trudności",
  "patient_adult_knee_function_q26_a4": "Ekstremalna trudność",
  "patient_adult_knee_function_q26_a5": "Niemożliwe do zrobienia",
  "patient_adult_knee_function_q27": "Jak oceniasz AKTUALNĄ funkcję swojego kolana w skali 0-10?",
  "patient_adult_knee_function_q28": "10 oznacza pełną funkcję, a 0 oznacza niezdolność do wykonywania jakichkolwiek codziennych czynności, które mogą obejmować sport",
  "patient_adult_knee_function_q28_a1": "Najsłabsza",
  "patient_adult_knee_function_q28_a2": "Najlepsza",
  "patient_adult_knee_function_q29": "Jaki jest najwyższy poziom aktywności, w której możesz regularnie uczestniczyć?",
  "patient_adult_knee_function_q29_a1": "Bardzo wyczerpujące czynności, takie jak skakanie lub obracanie się, jak w koszykówce lub piłce nożnej",
  "patient_adult_knee_function_q29_a2": "Intensywne zajęcia, takie jak ciężka praca fizyczna, jazda na nartach lub tenis",
  "patient_adult_knee_function_q29_a3": "Umiarkowana aktywność, taka jak umiarkowana praca fizyczna, bieganie lub jogging",
  "patient_adult_knee_function_q29_a4": "Lekkie zajęcia, takie jak spacery, prace domowe lub prace w ogrodzie",
  "patient_adult_knee_function_q29_a5": "Nie mogę wykonywać żadnej z powyższych czynności z powodu bólu kolana",
  "patient_adult_knee_function_q30": "Proszę podać najwyższy poziom aktywności, w jakich możesz uczestniczyć",
  "patient_adult_knee_function_q30_q1": "Przed kontuzją kolana/problemem z kolanem",
  "patient_adult_knee_function_q30_q1_a1": "Wyczynowy",
  "patient_adult_knee_function_q30_q1_a2": "Rekreacyjny, sprawny fizycznie",
  "patient_adult_knee_function_q30_q1_a3": "Żadnych sportów, tylko praca",
  "patient_adult_knee_function_q30_q1_a4": "Tylko czynności życia codziennego",
  "patient_adult_knee_function_q30_q1_a5": "Zwolnienie lekarskie lub renta z powodu problemów zdrowotnych",
  "patient_adult_knee_function_q30_q1_a1_o1": "Sporty wyczynowe uprawiane na poziomie światowym, olimpijskim lub zawodowym",
  "patient_adult_knee_function_q30_q1_a1_o2": "Sporty wyczynowe, takie jak lekkoatletyka, sporty rakietowe/piłkowe, gimnastyka, wioślarstwo, narciarstwo lub sztuki walki rozgrywane na poziomie krajowym lub międzynarodowym",
  "patient_adult_knee_function_q30_q1_a1_o3": "Sporty wyczynowe, takie jak sporty wodne, jazda na rowerze, hokej, curling lub jazda konno/rodeo rozgrywane na poziomie krajowym lub międzynarodowym",
  "patient_adult_knee_function_q30_q1_a2_o1": "Sporty rekreacyjne, takie jak bieganie, sporty piłkowe/rakietowe, trening siłowy, curling, wioślarstwo, golf, joga lub gimnastyka co najmniej 5 razy w tygodniu",
  "patient_adult_knee_function_q30_q1_a2_o2": "Sporty rekreacyjne, takie jak pływanie, sporty wodne, narciarstwo, hokej, jazda na rolkach, jazda na rowerze, jazda konno lub wspinaczka górska co najmniej 5 razy w tygodniu",
  "patient_adult_knee_function_q30_q1_a2_o3": "Sporty rekreacyjne co najmniej dwa razy w tygodniu",
  "patient_adult_knee_function_q30_q1_a2_o4": "Sporty rekreacyjne raz w tygodniu lub rzadziej",
  "patient_adult_knee_function_q30_q1_a2_o5": "Sporty rekreacyjne rzadziej niż raz w miesiącu",
  "patient_adult_knee_function_q30_q1_a3_o1": "Ciężka praca, np. budowlana",
  "patient_adult_knee_function_q30_q1_a3_o2": "Umiarkowanie ciężka praca, np. jazda ciężarówką",
  "patient_adult_knee_function_q30_q1_a3_o3": "Lekkie prace, takie jak pielęgniarstwo",
  "patient_adult_knee_function_q30_q1_a3_o4": "Praca siedząca, taka jak sekretarka lub praca przy komputerze",
  "patient_adult_knee_function_q30_q1_a4_o1": "Codzienne czynności, takie jak prace w ogrodzie, wchodzenie po wielu schodach, przenoszenie ciężarów, pchanie/ciągnięcie ciężaru, bieganie w przypadku spóźnienia",
  "patient_adult_knee_function_q30_q1_a4_o2": "Codzienne czynności, takie jak sprzątanie domu, wchodzenie po 1 lub 2 piętrach lub dźwiganie lekkiego ciężaru ",
  "patient_adult_knee_function_q30_q1_a4_o3": "Ograniczone codzienne czynności (np. trudność w chodzeniu schodach, niemożność noszenia ciężarów)",
  "patient_adult_knee_function_q30_q2": "Aktualny stan",
  "patient_adult_knee_function_q30_q2_a1": "Wyczynowy",
  "patient_adult_knee_function_q30_q2_a2": "Rekreacyjny, sprawny fizycznie",
  "patient_adult_knee_function_q30_q2_a3": "Żadnych sportów, tylko praca",
  "patient_adult_knee_function_q30_q2_a4": "Tylko czynności życia codziennego",
  "patient_adult_knee_function_q30_q2_a5": "Zwolnienie lekarskie lub renta z powodu problemów zdrowotnych",
  "patient_adult_knee_function_q30_q2_a1_o1": "Sporty wyczynowe uprawiane na poziomie światowym, olimpijskim lub zawodowym",
  "patient_adult_knee_function_q30_q2_a1_o2": "Sporty wyczynowe, takie jak lekkoatletyka, sporty rakietowe/piłkowe, gimnastyka, wioślarstwo, narciarstwo lub sztuki walki rozgrywane na poziomie krajowym lub międzynarodowym",
  "patient_adult_knee_function_q30_q2_a1_o3": "Sporty wyczynowe, takie jak sporty wodne, jazda na rowerze, hokej, curling lub jazda konno/rodeo rozgrywane na poziomie krajowym lub międzynarodowym",
  "patient_adult_knee_function_q30_q2_a2_o1": "Sporty rekreacyjne, takie jak bieganie, sporty piłkowe/rakietowe, trening siłowy, curling, wioślarstwo, golf, joga lub gimnastyka co najmniej 5 razy w tygodniu",
  "patient_adult_knee_function_q30_q2_a2_o2": "Sporty rekreacyjne, takie jak pływanie, sporty wodne, narciarstwo, hokej, jazda na rolkach, jazda na rowerze, jazda konno lub wspinaczka górska co najmniej 5 razy w tygodniu",
  "patient_adult_knee_function_q30_q2_a2_o3": "Sporty rekreacyjne co najmniej dwa razy w tygodniu",
  "patient_adult_knee_function_q30_q2_a2_o4": "Sporty rekreacyjne raz w tygodniu lub rzadziej",
  "patient_adult_knee_function_q30_q2_a2_o5": "Sporty rekreacyjne rzadziej niż raz w miesiącu",
  "patient_adult_knee_function_q30_q2_a3_o1": "Ciężka praca, np. budowlana",
  "patient_adult_knee_function_q30_q2_a3_o2": "Umiarkowanie ciężka praca, np. jazda ciężarówką",
  "patient_adult_knee_function_q30_q2_a3_o3": "Lekkie prace, takie jak pielęgniarstwo",
  "patient_adult_knee_function_q30_q2_a3_o4": "Praca siedząca, taka jak sekretarka lub praca przy komputerze",
  "patient_adult_knee_function_q30_q2_a4_o1": "Codzienne czynności, takie jak prace w ogrodzie, wchodzenie po wielu schodach, przenoszenie ciężarów, pchanie/ciągnięcie ciężaru, bieganie w przypadku spóźnienia",
  "patient_adult_knee_function_q30_q2_a4_o2": "Codzienne czynności, takie jak sprzątanie domu, wchodzenie po 1 lub 2 piętrach lub dźwiganie lekkiego ciężaru ",
  "patient_adult_knee_function_q30_q2_a4_o3": "Ograniczone codzienne czynności (np. trudność w chodzeniu schodach, niemożność noszenia ciężarów)",
  "patient_adult_knee_function_q31": "Czy rzepka zwicha się lub czujesz jakby miałą się zwichnąć?",
  "patient_adult_knee_function_q31_a1": "Nie",
  "patient_adult_knee_function_q31_a2": "Tak",
  "patient_adult_knee_patellofemoral_instability": "Niestabilność stawu rzepkowo-udowego",
  "patient_adult_knee_patellofemoral_instability_instruction": "Wybierz to, co najlepiej opisuje JAK CZĘSTO masz wrażenie, że rzepka „wyskakuje” ZE STAWU lub WYDAJE SIĘ NIESTABILNA, gdy wykonujesz następujace czynności",
  "patient_adult_knee_patellofemoral_instability_instruction_0": "Nigdy",
  "patient_adult_knee_patellofemoral_instability_instruction_1": "Rzadko",
  "patient_adult_knee_patellofemoral_instability_instruction_2": "Czasami",
  "patient_adult_knee_patellofemoral_instability_instruction_3": "Często",
  "patient_adult_knee_patellofemoral_instability_instruction_4": "Zawsze",
  "patient_adult_knee_patellofemoral_instability_q1": "Skręcanie/zmiana kierunku podczas uprawiania sportu/gier",
  "patient_adult_knee_patellofemoral_instability_q1_do_not_do": "Nie dzieje się",
  "patient_adult_knee_patellofemoral_instability_q1_a1": "Nigdy",
  "patient_adult_knee_patellofemoral_instability_q1_a2": "Rzadko",
  "patient_adult_knee_patellofemoral_instability_q1_a3": "Czasami",
  "patient_adult_knee_patellofemoral_instability_q1_a4": "Często",
  "patient_adult_knee_patellofemoral_instability_q1_a5": "Zawsze",
  "patient_adult_knee_patellofemoral_instability_q2": "Zmiana kierunku podczas biegu",
  "patient_adult_knee_patellofemoral_instability_q2_do_not_do": "Nie dzieje się",
  "patient_adult_knee_patellofemoral_instability_q2_a1": "Nigdy",
  "patient_adult_knee_patellofemoral_instability_q2_a2": "Rzadko",
  "patient_adult_knee_patellofemoral_instability_q2_a3": "Czasami",
  "patient_adult_knee_patellofemoral_instability_q2_a4": "Często",
  "patient_adult_knee_patellofemoral_instability_q2_a5": "Zawsze",
  "patient_adult_knee_patellofemoral_instability_q3": "Bieganie po linii prostej po NIERÓWNEJ nawierzchni",
  "patient_adult_knee_patellofemoral_instability_q3_do_not_do": "Nie dzieje się",
  "patient_adult_knee_patellofemoral_instability_q3_a1": "Nigdy",
  "patient_adult_knee_patellofemoral_instability_q3_a2": "Rzadko",
  "patient_adult_knee_patellofemoral_instability_q3_a3": "Czasami",
  "patient_adult_knee_patellofemoral_instability_q3_a4": "Często",
  "patient_adult_knee_patellofemoral_instability_q3_a5": "Zawsze",
  "patient_adult_knee_patellofemoral_instability_q4": "Chodzenie po śliskich, mokrych lub oblodzonych nawierzchniach",
  "patient_adult_knee_patellofemoral_instability_q4_do_not_do": "Nie dzieje się",
  "patient_adult_knee_patellofemoral_instability_q4_a1": "Nigdy",
  "patient_adult_knee_patellofemoral_instability_q4_a2": "Rzadko",
  "patient_adult_knee_patellofemoral_instability_q4_a3": "Czasami",
  "patient_adult_knee_patellofemoral_instability_q4_a4": "Często",
  "patient_adult_knee_patellofemoral_instability_q4_a5": "Zawsze",
  "patient_adult_knee_patellofemoral_instability_q5": "Bieganie bokiem",
  "patient_adult_knee_patellofemoral_instability_q5_do_not_do": "Nie dzieje się",
  "patient_adult_knee_patellofemoral_instability_q5_a1": "Nigdy",
  "patient_adult_knee_patellofemoral_instability_q5_a2": "Rzadko",
  "patient_adult_knee_patellofemoral_instability_q5_a3": "Czasami",
  "patient_adult_knee_patellofemoral_instability_q5_a4": "Często",
  "patient_adult_knee_patellofemoral_instability_q5_a5": "Zawsze",
  "patient_adult_knee_patellofemoral_instability_q6": "Skakanie",
  "patient_adult_knee_patellofemoral_instability_q6_do_not_do": "Nie dzieje się",
  "patient_adult_knee_patellofemoral_instability_q6_a1": "Nigdy",
  "patient_adult_knee_patellofemoral_instability_q6_a2": "Rzadko",
  "patient_adult_knee_patellofemoral_instability_q6_a3": "Czasami",
  "patient_adult_knee_patellofemoral_instability_q6_a4": "Często",
  "patient_adult_knee_patellofemoral_instability_q6_a5": "Zawsze",
  "patient_adult_knee_patellofemoral_instability_q7": "Skoki",
  "patient_adult_knee_patellofemoral_instability_q7_do_not_do": "Nie dzieje się",
  "patient_adult_knee_patellofemoral_instability_q7_a1": "Nigdy",
  "patient_adult_knee_patellofemoral_instability_q7_a2": "Rzadko",
  "patient_adult_knee_patellofemoral_instability_q7_a3": "Czasami",
  "patient_adult_knee_patellofemoral_instability_q7_a4": "Często",
  "patient_adult_knee_patellofemoral_instability_q7_a5": "Zawsze",
  "patient_adult_knee_patellofemoral_instability_q8": "Bieganie po linii prostej po RÓWNYCH nawierzchniach",
  "patient_adult_knee_patellofemoral_instability_q8_do_not_do": "Nie dzieje się",
  "patient_adult_knee_patellofemoral_instability_q8_a1": "Nigdy",
  "patient_adult_knee_patellofemoral_instability_q8_a2": "Rzadko",
  "patient_adult_knee_patellofemoral_instability_q8_a3": "Czasami",
  "patient_adult_knee_patellofemoral_instability_q8_a4": "Często",
  "patient_adult_knee_patellofemoral_instability_q8_a5": "Zawsze",
  "patient_adult_knee_patellofemoral_instability_q9": "Schodzenie po schodach",
  "patient_adult_knee_patellofemoral_instability_q9_do_not_do": "Nie rób",
  "patient_adult_knee_patellofemoral_instability_q9_a1": "Nigdy",
  "patient_adult_knee_patellofemoral_instability_q9_a2": "Rzadko",
  "patient_adult_knee_patellofemoral_instability_q9_a3": "Czasami",
  "patient_adult_knee_patellofemoral_instability_q9_a4": "Często",
  "patient_adult_knee_patellofemoral_instability_q9_a5": "Zawsze",
  "patient_adult_knee_patellofemoral_instability_q10": "Przysiad",
  "patient_adult_knee_patellofemoral_instability_q10_do_not_do": "Nie rób",
  "patient_adult_knee_patellofemoral_instability_q10_a1": "Nigdy",
  "patient_adult_knee_patellofemoral_instability_q10_a2": "Rzadko",
  "patient_adult_knee_patellofemoral_instability_q10_a3": "Czasami",
  "patient_adult_knee_patellofemoral_instability_q10_a4": "Często",
  "patient_adult_knee_patellofemoral_instability_q10_a5": "Zawsze",
  "patient_adult_knee_patellofemoral_instability_q11": "Klękanie",
  "patient_adult_knee_patellofemoral_instability_q11_do_not_do": "Nie rób",
  "patient_adult_knee_patellofemoral_instability_q11_a1": "Nigdy",
  "patient_adult_knee_patellofemoral_instability_q11_a2": "Rzadko",
  "patient_adult_knee_patellofemoral_instability_q11_a3": "Czasami",
  "patient_adult_knee_patellofemoral_instability_q11_a4": "Często",
  "patient_adult_knee_patellofemoral_instability_q11_a5": "Zawsze",
  "patient_adult_knee_patellofemoral_instability_q12": "Chodzenie po linii prostej po NIERÓWNEJ powierzchni",
  "patient_adult_knee_patellofemoral_instability_q12_do_not_do": "Nie rób",
  "patient_adult_knee_patellofemoral_instability_q12_a1": "Nigdy",
  "patient_adult_knee_patellofemoral_instability_q12_a2": "Rzadko",
  "patient_adult_knee_patellofemoral_instability_q12_a3": "Czasami",
  "patient_adult_knee_patellofemoral_instability_q12_a4": "Często",
  "patient_adult_knee_patellofemoral_instability_q12_a5": "Zawsze",
  "patient_adult_knee_patellofemoral_instability_q13": "Wchodzenie po schodach",
  "patient_adult_knee_patellofemoral_instability_q13_do_not_do": "Nie rób",
  "patient_adult_knee_patellofemoral_instability_q13_a1": "Nigdy",
  "patient_adult_knee_patellofemoral_instability_q13_a2": "Rzadko",
  "patient_adult_knee_patellofemoral_instability_q13_a3": "Czasami",
  "patient_adult_knee_patellofemoral_instability_q13_a4": "Często",
  "patient_adult_knee_patellofemoral_instability_q13_a5": "Zawsze",
  "patient_adult_knee_patellofemoral_instability_q14": "Wchodzenie na wysoki stopień lub przechodzenie przez niego",
  "patient_adult_knee_patellofemoral_instability_q14_do_not_do": "Nie rób",
  "patient_adult_knee_patellofemoral_instability_q14_a1": "Nigdy",
  "patient_adult_knee_patellofemoral_instability_q14_a2": "Rzadko",
  "patient_adult_knee_patellofemoral_instability_q14_a3": "Czasami",
  "patient_adult_knee_patellofemoral_instability_q14_a4": "Często",
  "patient_adult_knee_patellofemoral_instability_q14_a5": "Zawsze",
  "patient_adult_knee_patellofemoral_instability_q15": "Siadanie z założonymi nogami",
  "patient_adult_knee_patellofemoral_instability_q15_do_not_do": "Nie rób",
  "patient_adult_knee_patellofemoral_instability_q15_a1": "Nigdy",
  "patient_adult_knee_patellofemoral_instability_q15_a2": "Rzadko",
  "patient_adult_knee_patellofemoral_instability_q15_a3": "Czasami",
  "patient_adult_knee_patellofemoral_instability_q15_a4": "Często",
  "patient_adult_knee_patellofemoral_instability_q15_a5": "Zawsze",
  "patient_adult_knee_patellofemoral_instability_q16": "Chodzenie po linii prostej po PŁASKICH powierzchniach",
  "patient_adult_knee_patellofemoral_instability_q16_do_not_do": "Nie rób",
  "patient_adult_knee_patellofemoral_instability_q16_a1": "Nigdy",
  "patient_adult_knee_patellofemoral_instability_q16_a2": "Rzadko",
  "patient_adult_knee_patellofemoral_instability_q16_a3": "Czasami",
  "patient_adult_knee_patellofemoral_instability_q16_a4": "Często",
  "patient_adult_knee_patellofemoral_instability_q16_a5": "Zawsze",
  "patient_adult_knee_patellofemoral_instability_q17": "Wsiadanie lub wysiadanie z samochodu",
  "patient_adult_knee_patellofemoral_instability_q17_do_not_do": "Nie rób",
  "patient_adult_knee_patellofemoral_instability_q17_a1": "Nigdy",
  "patient_adult_knee_patellofemoral_instability_q17_a2": "Rzadko",
  "patient_adult_knee_patellofemoral_instability_q17_a3": "Czasami",
  "patient_adult_knee_patellofemoral_instability_q17_a4": "Często",
  "patient_adult_knee_patellofemoral_instability_q17_a5": "Zawsze",
  "patient_adult_knee_patellofemoral_instability_q18": "Zawracanie ciężkiego wózka w alejce supermarketu",
  "patient_adult_knee_patellofemoral_instability_q18_do_not_do": "Nie rób",
  "patient_adult_knee_patellofemoral_instability_q18_a1": "Nigdy",
  "patient_adult_knee_patellofemoral_instability_q18_a2": "Rzadko",
  "patient_adult_knee_patellofemoral_instability_q18_a3": "Czasami",
  "patient_adult_knee_patellofemoral_instability_q18_a4": "Często",
  "patient_adult_knee_patellofemoral_instability_q18_a5": "Zawsze",
  "patient_adult_knee_patellofemoral_instability_q19": "Odwracanie się, żeby spojrzeć przez ramię",
  "patient_adult_knee_patellofemoral_instability_q19_do_not_do": "Nie rób",
  "patient_adult_knee_patellofemoral_instability_q19_a1": "Nigdy",
  "patient_adult_knee_patellofemoral_instability_q19_a2": "Rzadko",
  "patient_adult_knee_patellofemoral_instability_q19_a3": "Czasami",
  "patient_adult_knee_patellofemoral_instability_q19_a4": "Często",
  "patient_adult_knee_patellofemoral_instability_q19_a5": "Zawsze",
  "patient_adult_knee_quality_of_life": "Jakość życia",
  "patient_adult_knee_quality_of_life_instruction": "Poniższe pytania dotyczą obaw społecznych, emocjonalnych i związanych ze stylem życia, które możesz odczuwać w związku z chorym kolanem. Proszę, pomyśl o tym, co czułeś przez większość czasu w związku ze swoim kolanem.",
  "patient_adult_knee_quality_of_life_q1": "Jak często jesteś świadomy swoich problemów z kolanem?",
  "patient_adult_knee_quality_of_life_q1_a1": "Nigdy",
  "patient_adult_knee_quality_of_life_q1_a2": "Co miesiąc",
  "patient_adult_knee_quality_of_life_q1_a3": "Co tydzień",
  "patient_adult_knee_quality_of_life_q1_a4": "Codziennie",
  "patient_adult_knee_quality_of_life_q1_a5": "Stale",
  "patient_adult_knee_quality_of_life_q2": "Czy zmodyfikowałeś swój styl życia, aby uniknąć potencjalnie szkodliwych czynności dla kolana?",
  "patient_adult_knee_quality_of_life_q2_a1": "Zupełnie nie",
  "patient_adult_knee_quality_of_life_q2_a2": "Lekko",
  "patient_adult_knee_quality_of_life_q2_a3": "Miernie",
  "patient_adult_knee_quality_of_life_q2_a4": "Znacznie",
  "patient_adult_knee_quality_of_life_q2_a5": "Całkowicie",
  "patient_adult_knee_quality_of_life_q3": "Jak bardzo dokucza Ci brak pewności w kolanie?",
  "patient_adult_knee_quality_of_life_q3_a1": "W ogóle",
  "patient_adult_knee_quality_of_life_q3_a2": "Lekko",
  "patient_adult_knee_quality_of_life_q3_a3": "Miernie",
  "patient_adult_knee_quality_of_life_q3_a4": "Znacznie",
  "patient_adult_knee_quality_of_life_q3_a5": "Niezwykle",
  "patient_adult_knee_quality_of_life_q4": "Ogólnie rzecz biorąc, jak duże masz problemy z kolanem?",
  "patient_adult_knee_quality_of_life_q4_a1": "Brak",
  "patient_adult_knee_quality_of_life_q4_a2": "Lekkie",
  "patient_adult_knee_quality_of_life_q4_a3": "Umiarkowane",
  "patient_adult_knee_quality_of_life_q4_a4": "Duże",
  "patient_adult_knee_quality_of_life_q4_a5": "Skrajne",
  "patient_adult_knee_quality_of_life_q5": "Jak bardzo niespokojny/przygnębiony jesteś dzisiaj?",
  "patient_adult_knee_quality_of_life_q5_a1": "Nie jestem niespokojny/depresyjny",
  "patient_adult_knee_quality_of_life_q5_a2": "Nieznacznie",
  "patient_adult_knee_quality_of_life_q5_a3": "Miernie",
  "patient_adult_knee_quality_of_life_q5_a4": "Znacznie",
  "patient_adult_knee_quality_of_life_q5_a5": "Skrajnie",
  "patient_adult_knee_quality_of_life_q6": "Jak dobry/zły jest dzisiaj Twój stan zdrowia?",
  "patient_adult_knee_quality_of_life_q6_instruction": "100 oznacza najlepsze zdrowie, 0 oznacza najgorsze zdrowie, jakie możesz sobie wyobrazić",
  "patient_adult_knee_quality_of_life_q6_instruction_a1": "Najgorszy",
  "patient_adult_knee_quality_of_life_q6_instruction_a2": "Najlepszy",
  "patient_adult_knee_satisfaction": "Zadowolenie",
  "patient_adult_knee_satisfaction_instructions": "Jak bardzo zadowolony jesteś z wyników operacji/leczenia kolana?",
  "patient_adult_knee_satisfaction_instructions_1": "Bardzo nieusatysfakcjonowany",
  "patient_adult_knee_satisfaction_instructions_2": "Nieco niezadowolony",
  "patient_adult_knee_satisfaction_instructions_3": "Częściowo usatysfakcjonowany",
  "patient_adult_knee_satisfaction_instructions_4": "Bardzo zadowolony",
  "patient_adult_knee_satisfaction_q1": "Ogólnie",
  "patient_adult_knee_satisfaction_q1_not_applicable": "Nie dotyczy",
  "patient_adult_knee_satisfaction_q1_a1": "Bardzo nieusatysfakcjonowany",
  "patient_adult_knee_satisfaction_q1_a2": "Nieco niezadowolony",
  "patient_adult_knee_satisfaction_q1_a3": "Częściowo usatysfakcjonowany",
  "patient_adult_knee_satisfaction_q1_a4": "Bardzo zadowolony",
  "patient_adult_knee_satisfaction_q2": "W związku z poprawą dolegliwości bólowych?",
  "patient_adult_knee_satisfaction_q2_not_applicable": "Nie dotyczy",
  "patient_adult_knee_satisfaction_q2_a1": "Bardzo nieusatysfakcjonowany",
  "patient_adult_knee_satisfaction_q2_a2": "Nieco niezadowolony",
  "patient_adult_knee_satisfaction_q2_a3": "Częściowo usatysfakcjonowany",
  "patient_adult_knee_satisfaction_q2_a4": "Bardzo zadowolony",
  "patient_adult_knee_satisfaction_q3": "W związku z poprawą umiejętności wykonywania prac w domu lub na podwórku?",
  "patient_adult_knee_satisfaction_q3_not_applicable": "Nie dotyczy",
  "patient_adult_knee_satisfaction_q3_a1": "Bardzo nieusatysfakcjonowany",
  "patient_adult_knee_satisfaction_q3_a2": "Nieco niezadowolony",
  "patient_adult_knee_satisfaction_q3_a3": "Częściowo usatysfakcjonowany",
  "patient_adult_knee_satisfaction_q3_a4": "Bardzo zadowolony",
  "patient_adult_knee_satisfaction_q4": "W związku z poprawą w zakresie zajęć rekreacyjnych?",
  "patient_adult_knee_satisfaction_q4_not_applicable": "Nie dotyczy",
  "patient_adult_knee_satisfaction_q4_a1": "Bardzo nieusatysfakcjonowany",
  "patient_adult_knee_satisfaction_q4_a2": "Nieco niezadowolony",
  "patient_adult_knee_satisfaction_q4_a3": "Częściowo usatysfakcjonowany",
  "patient_adult_knee_satisfaction_q4_a4": "Bardzo zadowolony",
  "patient_adult_knee_complete": "Koniec ankiety",
  "patient_adult_knee_complete_text1": "Dziękujemy za poświęcenie czasu na wypełnienie tego formularza.",
  "patient_adult_knee_complete_text2": "Kliknij przycisk Prześlij, aby zakończyć i zobaczyć swoje wyniki.",
  "patient_adult_knee_complete_text3": "Twoje odpowiedzi będą traktowane poufnie, a wszelkie raporty będą wykorzystywać wyłącznie informacje pozbawione danych umożliwiających identyfikację.",
  "patient_adult_knee_results": "Wyniki",
  "patient_adult_knee_results_text1": "Poniżej znajdują się Twoje wyniki w zakresie bólu i funkcji kolana, które zostały obliczone na podstawie Twojego kwestionariusza.",
  "patient_adult_knee_results_pain": "Ból",
  "patient_adult_knee_results_function": "Funkcja",
  "patient_adult_knee_results_health_perception": "Ogólne postrzeganie zdrowia",
  "patient_adult_knee_results_self_evaluation": "Skala samooceny kolana",
  "patient_adult_knee_results_text2": "Twój lekarz wkrótce do Ciebie dotrze",
  "patient_adolescent_knee_pain": "Ból",
  "patient_adolescent_knee_function_q23": "Chodzenie na zakupy",
  "patient_adolescent_knee_function_q23_a1": "Żadnych trudności",
  "patient_adolescent_knee_function_q23_a2": "Minimalna trudność",
  "patient_adolescent_knee_function_q23_a3": "Umiarkowany poziom trudności",
  "patient_adolescent_knee_function_q23_a4": "Ekstremalna trudność",
  "patient_adolescent_knee_function_q23_a5": "Niemożliwe do zrobienia",
  "patient_pedia_knee_pain": "Ból",
  "patient_pedia_knee_pain_q1": "W ciągu ostatnich 4 tygodni lub od czasu kontuzji, jak często bolało Cię kontuzjowane kolano?",
  "patient_pedia_knee_pain_q1_a1": "Nigdy nie boli",
  "patient_pedia_knee_pain_q1_a2": "Rzadko",
  "patient_pedia_knee_pain_q1_a3": "Czasami",
  "patient_pedia_knee_pain_q1_a4": "Często",
  "patient_pedia_knee_pain_q1_a5": "Stale",
  "patient_pedia_knee_pain_q2": "Jak bardzo dzisiaj boli Cię kontuzjowane kolano?",
  "patient_pedia_knee_pain_q2_a1": "W ogóle nie boli",
  "patient_pedia_knee_pain_q2_a2": "Boli tak bardzo, że nie mogę tego znieść",
  "patient_pedia_knee_pain_instructions": "Jaki ból kolana odczuwałeś w ciągu ostatnich 7 dni podczas wykonywania poniższych czynności?",
  "patient_pedia_knee_pain_instructions_0": "Bez bólu",
  "patient_pedia_knee_pain_instructions_1": "Mały ból",
  "patient_pedia_knee_pain_instructions_2": "Umiarkowany ból",
  "patient_pedia_knee_pain_instructions_3": "Duży ból",
  "patient_pedia_knee_pain_instructions_4": "Ekstremalny ból",
  "patient_pedia_knee_pain_note": "Uwaga: Proszę odpowiedzieć na następujące pytania dotyczące aktywności kolana.",
  "patient_pedia_knee_pain_q3": "Skręty/obroty kolana podczas chodzenia/stania/biegania",
  "patient_pedia_knee_pain_q3_a1": "Bez bólu",
  "patient_pedia_knee_pain_q3_a2": "Mały ból",
  "patient_pedia_knee_pain_q3_a3": "Umiarkowany ból",
  "patient_pedia_knee_pain_q3_a4": "Duży ból",
  "patient_pedia_knee_pain_q3_a5": "Ekstremalny ból",
  "patient_pedia_knee_pain_q4": "Pełny wyprost kolana",
  "patient_pedia_knee_pain_q4_a1": "Bez bólu",
  "patient_pedia_knee_pain_q4_a2": "Mały ból",
  "patient_pedia_knee_pain_q4_a3": "Umiarkowany ból",
  "patient_pedia_knee_pain_q4_a4": "Duży ból",
  "patient_pedia_knee_pain_q4_a5": "Ekstremalny ból",
  "patient_pedia_knee_pain_q5": "Pełne zgięcie kolana",
  "patient_pedia_knee_pain_q5_a1": "Bez bólu",
  "patient_pedia_knee_pain_q5_a2": "Mały ból",
  "patient_pedia_knee_pain_q5_a3": "Umiarkowany ból",
  "patient_pedia_knee_pain_q5_a4": "Duży ból",
  "patient_pedia_knee_pain_q5_a5": "Ekstremalny ból",
  "patient_pedia_knee_pain_q6": "Wchodzenie po schodach",
  "patient_pedia_knee_pain_q6_a1": "Bez bólu",
  "patient_pedia_knee_pain_q6_a2": "Mały ból",
  "patient_pedia_knee_pain_q6_a3": "Umiarkowany ból",
  "patient_pedia_knee_pain_q6_a4": "Duży ból",
  "patient_pedia_knee_pain_q6_a5": "Ekstremalny ból",
  "patient_pedia_knee_pain_q7": "Schodzenie ze schodów",
  "patient_pedia_knee_pain_q7_a1": "Bez bólu",
  "patient_pedia_knee_pain_q7_a2": "Mały ból",
  "patient_pedia_knee_pain_q7_a3": "Umiarkowany ból",
  "patient_pedia_knee_pain_q7_a4": "Duży ból",
  "patient_pedia_knee_pain_q7_a5": "Ekstremalny ból",
  "patient_pedia_knee_pain_q8": "Siedzenie z ugiętym kolanem",
  "patient_pedia_knee_pain_q8_a1": "Bez bólu",
  "patient_pedia_knee_pain_q8_a2": "Mały ból",
  "patient_pedia_knee_pain_q8_a3": "Umiarkowany ból",
  "patient_pedia_knee_pain_q8_a4": "Duży ból",
  "patient_pedia_knee_pain_q8_a5": "Ekstremalny ból",
  "patient_pedia_knee_pain_q9": "Stanie prosto na obu nogach przez jakikolwiek czas",
  "patient_pedia_knee_pain_q9_a1": "Bez bólu",
  "patient_pedia_knee_pain_q9_a2": "Mały ból",
  "patient_pedia_knee_pain_q9_a3": "Umiarkowany ból",
  "patient_pedia_knee_pain_q9_a4": "Duży ból",
  "patient_pedia_knee_pain_q9_a5": "Ekstremalny ból",
  "patient_pedia_knee_other_symptoms": "Inne objawy",
  "patient_pedia_knee_other_symptoms_instruction1": "Jak często zdarzały Ci się:",
  "patient_pedia_knee_other_symptoms_instruction1_0": "Nigdy",
  "patient_pedia_knee_other_symptoms_instruction1_1": "Rzadko",
  "patient_pedia_knee_other_symptoms_instruction1_2": "Czasami",
  "patient_pedia_knee_other_symptoms_instruction1_3": "Często",
  "patient_pedia_knee_other_symptoms_instruction1_4": "Zawsze",
  "patient_pedia_knee_other_symptoms_q1": "Kolano wydaje jakieś dźwięki",
  "patient_pedia_knee_other_symptoms_q1_a1": "Nigdy",
  "patient_pedia_knee_other_symptoms_q1_a2": "Rzadko",
  "patient_pedia_knee_other_symptoms_q1_a3": "Czasami",
  "patient_pedia_knee_other_symptoms_q1_a4": "Często",
  "patient_pedia_knee_other_symptoms_q1_a5": "Zawsze",
  "patient_pedia_knee_other_symptoms_q2": "Zablokowanie kolana",
  "patient_pedia_knee_other_symptoms_q2_a1": "Nigdy",
  "patient_pedia_knee_other_symptoms_q2_a2": "Rzadko",
  "patient_pedia_knee_other_symptoms_q2_a3": "Czasami",
  "patient_pedia_knee_other_symptoms_q2_a4": "Często",
  "patient_pedia_knee_other_symptoms_q2_a5": "Zawsze",
  "patient_pedia_knee_other_symptoms_q3": "Uczucie, jakby zablokowało kolano, ale nadal można nim poruszać",
  "patient_pedia_knee_other_symptoms_q3_a1": "Nigdy",
  "patient_pedia_knee_other_symptoms_q3_a2": "Rzadko",
  "patient_pedia_knee_other_symptoms_q3_a3": "Czasami",
  "patient_pedia_knee_other_symptoms_q3_a4": "Często",
  "patient_pedia_knee_other_symptoms_q3_a5": "Zawsze",
  "patient_pedia_knee_other_symptoms_q4": "Obrzęk/opuchnięte kolano",
  "patient_pedia_knee_other_symptoms_q4_a1": "Nigdy",
  "patient_pedia_knee_other_symptoms_q4_a2": "Rzadko",
  "patient_pedia_knee_other_symptoms_q4_a3": "Czasami",
  "patient_pedia_knee_other_symptoms_q4_a4": "Często",
  "patient_pedia_knee_other_symptoms_q4_a5": "Zawsze",
  "patient_pedia_knee_other_symptoms_q5": "Jak bardzo spuchnięte (LUB obrzęknięte) było Twoje kolano?",
  "patient_pedia_knee_other_symptoms_q5_a1": "W ogóle nie spuchniete",
  "patient_pedia_knee_other_symptoms_q5_a2": "Lekko spuchnięte",
  "patient_pedia_knee_other_symptoms_q5_a3": "Umiarkowanie spuchnięte",
  "patient_pedia_knee_other_symptoms_q5_a4": "Bardzo spuchnięte",
  "patient_pedia_knee_other_symptoms_q5_a5": "Skrajnie spuchnięte",
  "patient_pedia_knee_other_symptoms_instruction2": "Gdyby poproszono Cię o wykonanie poniższych czynności, jaką największą mógłbyś zrobić DZIŚ...",
  "patient_pedia_knee_other_symptoms_instruction2_0": "Bardzo wymagające aktywności, takie jak skakanie lub szybkie skręty w celu zmiany kierunku, jak w koszykówce lub piłce nożnej",
  "patient_pedia_knee_other_symptoms_instruction2_1": "Cięzkie aktywności, takie jak podnoszenie ciężarów, jazda na nartach lub tenis",
  "patient_pedia_knee_other_symptoms_instruction2_2": "Pewne ciężkie aktywności, takich jak szybkie chodzenie lub jogging",
  "patient_pedia_knee_other_symptoms_instruction2_3": "Lekkie czynności, takie jak chodzenie z normalną prędkością",
  "patient_pedia_knee_other_symptoms_instruction2_4": "Nie mogę wykonywać żadnej z czynności wymienionych powyżej",
  "patient_pedia_knee_other_symptoms_q6": "Aktywności nie powodujące silnego bólu kolana",
  "patient_pedia_knee_other_symptoms_q6_a1": "Bardzo wymagające aktywności",
  "patient_pedia_knee_other_symptoms_q6_a2": "Ciężkie aktywności",
  "patient_pedia_knee_other_symptoms_q6_a3": "Pewne ciężkie aktywności",
  "patient_pedia_knee_other_symptoms_q6_a4": "Lekkie aktywności",
  "patient_pedia_knee_other_symptoms_q6_a5": "Nie mogę wykonać żadnego z powyższych",
  "patient_pedia_knee_other_symptoms_q7": "Aktywności nie powodujące obrzeku kolana",
  "patient_pedia_knee_other_symptoms_q7_a1": "Bardzo wymagające aktywności",
  "patient_pedia_knee_other_symptoms_q7_a2": "Ciężkie aktywności",
  "patient_pedia_knee_other_symptoms_q7_a3": "Pewne ciężkie aktywności",
  "patient_pedia_knee_other_symptoms_q7_a4": "Lekkie aktywności",
  "patient_pedia_knee_other_symptoms_q7_a5": "Nie mogę wykonać żadnego z powyższych",
  "patient_pedia_knee_other_symptoms_q8": "Aktywności nie powodujące uciekania kolana",
  "patient_pedia_knee_other_symptoms_q8_a1": "Bardzo wymagające aktywności",
  "patient_pedia_knee_other_symptoms_q8_a2": "Ciężkie aktywności",
  "patient_pedia_knee_other_symptoms_q8_a3": "Pewne ciężkie aktywności",
  "patient_pedia_knee_other_symptoms_q8_a5": "Nie mogę wykonać żadnego z powyższych",
  "patient_pedia_knee_other_symptoms_q9": "Przez większość czasu",
  "patient_pedia_knee_other_symptoms_q9_a1": "Bardzo wymagające aktywności",
  "patient_pedia_knee_other_symptoms_q9_a2": "Ciężkie aktywności",
  "patient_pedia_knee_other_symptoms_q9_a3": "Pewne ciężkie aktywności",
  "patient_pedia_knee_other_symptoms_q9_a4": "Lekkie aktywności",
  "patient_pedia_knee_other_symptoms_q9_a5": "Nie mogę wykonać żadnego z powyższych",
  "patient_pedia_knee_function": "Funkcja",
  "patient_pedia_knee_function_instruction": "Jak duże trudności w związku z kolanem miałeś z wykonaniem następujących czynności:",
  "patient_pedia_knee_function_instruction_0": "Żadnych trudności",
  "patient_pedia_knee_function_instruction_1": "Lekkie",
  "patient_pedia_knee_function_instruction_2": "Umiarkowane",
  "patient_pedia_knee_function_instruction_3": "Duże",
  "patient_pedia_knee_function_instruction_4": "Ekstremalna trudność/nie do zrobienia",
  "patient_pedia_knee_function_note": "Uwaga: jeśli nie wykonujesz określonej czynności, wyobraź sobie, jak czułoby się Twoje kolano, gdybyś musiał jej spróbować.",
  "patient_pedia_knee_function_q1": "Wychodzenie w górę",
  "patient_pedia_knee_function_q1_a1": "Żadnych trudności",
  "patient_pedia_knee_function_q1_a2": "Lekkie",
  "patient_pedia_knee_function_q1_a3": "Umiarkowane",
  "patient_pedia_knee_function_q1_a4": "Duże",
  "patient_pedia_knee_function_q1_a5": "Ekstremalna trudność/nie do zrobienia",
  "patient_pedia_knee_function_q2": "Schodzenie w dół",
  "patient_pedia_knee_function_q2_a1": "Żadnych trudności",
  "patient_pedia_knee_function_q2_a2": "Lekkie",
  "patient_pedia_knee_function_q2_a3": "Umiarkowane",
  "patient_pedia_knee_function_q2_a4": "Duże",
  "patient_pedia_knee_function_q2_a5": "Ekstremalna trudność/nie do zrobienia",
  "patient_pedia_knee_function_q3": "Uklęknięcie na kolanie",
  "patient_pedia_knee_function_q3_a1": "Żadnych trudności",
  "patient_pedia_knee_function_q3_a2": "Lekkie",
  "patient_pedia_knee_function_q3_a3": "Umiarkowane",
  "patient_pedia_knee_function_q3_a4": "Duże",
  "patient_pedia_knee_function_q3_a5": "Ekstremalna trudność/nie do zrobienia",
  "patient_pedia_knee_function_q4": "Pełne wyprostowanie kolana",
  "patient_pedia_knee_function_q4_a1": "Żadnych trudności",
  "patient_pedia_knee_function_q4_a2": "Lekkie",
  "patient_pedia_knee_function_q4_a3": "Umiarkowane",
  "patient_pedia_knee_function_q4_a4": "Duże",
  "patient_pedia_knee_function_q4_a5": "Ekstremalna trudność/nie do zrobienia",
  "patient_pedia_knee_function_q5": "Pełne zgięcie kolana",
  "patient_pedia_knee_function_q5_a1": "Żadnych trudności",
  "patient_pedia_knee_function_q5_a2": "Lekkie",
  "patient_pedia_knee_function_q5_a3": "Umiarkowane",
  "patient_pedia_knee_function_q5_a4": "Duże",
  "patient_pedia_knee_function_q5_a5": "Ekstremalna trudność/nie do zrobienia",
  "patient_pedia_knee_function_q6": "Poruszanie kolanem zaraz po przebudzeniu rano",
  "patient_pedia_knee_function_q6_a1": "Żadnych trudności",
  "patient_pedia_knee_function_q6_a2": "Lekkie",
  "patient_pedia_knee_function_q6_a3": "Umiarkowane",
  "patient_pedia_knee_function_q6_a4": "Duże",
  "patient_pedia_knee_function_q6_a5": "Ekstremalna trudność/nie do zrobienia",
  "patient_pedia_knee_function_q7": "Poruszanie kolanem w ciągu dnia, po dłuższym siedzeniu",
  "patient_pedia_knee_function_q7_a1": "Żadnych trudności",
  "patient_pedia_knee_function_q7_a2": "Lekkie",
  "patient_pedia_knee_function_q7_a3": "Umiarkowane",
  "patient_pedia_knee_function_q7_a4": "Duże",
  "patient_pedia_knee_function_q7_a5": "Ekstremalna trudność/nie do zrobienia",
  "patient_pedia_knee_function_q8": "Kucnięcie jak chwytacz w baseballu",
  "patient_pedia_knee_function_q8_a1": "Żadnych trudności",
  "patient_pedia_knee_function_q8_a2": "Lekkie",
  "patient_pedia_knee_function_q8_a3": "Umiarkowane",
  "patient_pedia_knee_function_q8_a4": "Duże",
  "patient_pedia_knee_function_q8_a5": "Ekstremalna trudność/nie do zrobienia",
  "patient_pedia_knee_function_q9": "Siadanie na krześle z ugiętymi kolanami i stopami płasko na podłodze",
  "patient_pedia_knee_function_q9_a1": "Żadnych trudności",
  "patient_pedia_knee_function_q9_a2": "Lekkie",
  "patient_pedia_knee_function_q9_a3": "Umiarkowane",
  "patient_pedia_knee_function_q9_a4": "Duże",
  "patient_pedia_knee_function_q9_a5": "Ekstremalna trudność/nie do zrobienia",
  "patient_pedia_knee_function_q10": "Wstawanie z krzesła",
  "patient_pedia_knee_function_q10_a1": "Żadnych trudności",
  "patient_pedia_knee_function_q10_a2": "Lekkie",
  "patient_pedia_knee_function_q10_a3": "Umiarkowane",
  "patient_pedia_knee_function_q10_a4": "Duże",
  "patient_pedia_knee_function_q10_a5": "Ekstremalna trudność/nie do zrobienia",
  "patient_pedia_knee_function_q11": "Pochylenie się i podniesienie przedmiotu z podłogi",
  "patient_pedia_knee_function_q11_a1": "Żadnych trudności",
  "patient_pedia_knee_function_q11_a2": "Lekkie",
  "patient_pedia_knee_function_q11_a3": "Umiarkowane",
  "patient_pedia_knee_function_q11_a4": "Duże",
  "patient_pedia_knee_function_q11_a5": "Ekstremalna trudność/nie do zrobienia",
  "patient_pedia_knee_function_q12": "Bieganie podczas gier lub zajęć sportowych",
  "patient_pedia_knee_function_q12_a1": "Żadnych trudności",
  "patient_pedia_knee_function_q12_a2": "Lekkie",
  "patient_pedia_knee_function_q12_a3": "Umiarkowane",
  "patient_pedia_knee_function_q12_a4": "Duże",
  "patient_pedia_knee_function_q12_a5": "Ekstremalna trudność/nie do zrobienia",
  "patient_pedia_knee_function_q13": "Skok podczas gier lub zajęć sportowych",
  "patient_pedia_knee_function_q13_a1": "Żadnych trudności",
  "patient_pedia_knee_function_q13_a2": "Lekkie",
  "patient_pedia_knee_function_q13_a3": "Umiarkowane",
  "patient_pedia_knee_function_q13_a4": "Duże",
  "patient_pedia_knee_function_q13_a5": "Ekstremalna trudność/nie do zrobienia",
  "patient_pedia_knee_function_q14": "Szybkie zatrzymanie i ruszanie",
  "patient_pedia_knee_function_q14_a1": "Żadnych trudności",
  "patient_pedia_knee_function_q14_a2": "Lekkie",
  "patient_pedia_knee_function_q14_a3": "Umiarkowane",
  "patient_pedia_knee_function_q14_a4": "Duże",
  "patient_pedia_knee_function_q14_a5": "Ekstremalna trudność/nie do zrobienia",
  "patient_pedia_knee_function_q15": "Skręty/obroty podczas gier lub zajęć sportowych",
  "patient_pedia_knee_function_q15_a1": "Żadnych trudności",
  "patient_pedia_knee_function_q15_a2": "Lekkie",
  "patient_pedia_knee_function_q15_a3": "Umiarkowane",
  "patient_pedia_knee_function_q15_a4": "Duże",
  "patient_pedia_knee_function_q15_a5": "Ekstremalna trudność/nie do zrobienia",
  "patient_pedia_knee_function_q16": "Utrzymywanie równowagi podczas chodzenia/biegania po nierównym podłożu",
  "patient_pedia_knee_function_q16_a1": "Żadnych trudności",
  "patient_pedia_knee_function_q16_a2": "Lekkie",
  "patient_pedia_knee_function_q16_a3": "Umiarkowane",
  "patient_pedia_knee_function_q16_a4": "Duże",
  "patient_pedia_knee_function_q16_a5": "Ekstremalna trudność/nie do zrobienia",
  "patient_pedia_knee_function_q17": "Uprawianie sportu",
  "patient_pedia_knee_function_q17_a1": "Żadnych trudności",
  "patient_pedia_knee_function_q17_a2": "Lekkie",
  "patient_pedia_knee_function_q17_a3": "Umiarkowane",
  "patient_pedia_knee_function_q17_a4": "Duże",
  "patient_pedia_knee_function_q17_a5": "Ekstremalna trudność/nie do zrobienia",
  "patient_pedia_knee_function_q18": "Wsiadanie/wysiadanie z samochodu",
  "patient_pedia_knee_function_q18_a1": "Żadnych trudności",
  "patient_pedia_knee_function_q18_a2": "Lekkie",
  "patient_pedia_knee_function_q18_a3": "Umiarkowane",
  "patient_pedia_knee_function_q18_a4": "Duże",
  "patient_pedia_knee_function_q18_a5": "Ekstremalna trudność/nie do zrobienia",
  "patient_pedia_knee_function_q19": "Wstawanie z łóżka",
  "patient_pedia_knee_function_q19_a1": "Żadnych trudności",
  "patient_pedia_knee_function_q19_a2": "Lekkie",
  "patient_pedia_knee_function_q19_a3": "Umiarkowane",
  "patient_pedia_knee_function_q19_a4": "Duże",
  "patient_pedia_knee_function_q19_a5": "Ekstremalna trudność/nie do zrobienia",
  "patient_pedia_knee_function_q20": "Zmiana pozycji kolan, leżąc w łóżku",
  "patient_pedia_knee_function_q20_a1": "Żadnych trudności",
  "patient_pedia_knee_function_q20_a2": "Lekkie",
  "patient_pedia_knee_function_q20_a3": "Umiarkowane",
  "patient_pedia_knee_function_q20_a4": "Duże",
  "patient_pedia_knee_function_q20_a5": "Ekstremalna trudność/nie do zrobienia",
  "patient_pedia_knee_function_q21": "Wchodzenie/wychodzenie z wanny/prysznica",
  "patient_pedia_knee_function_q21_a1": "Żadnych trudności",
  "patient_pedia_knee_function_q21_a2": "Lekkie",
  "patient_pedia_knee_function_q21_a3": "Umiarkowane",
  "patient_pedia_knee_function_q21_a4": "Duże",
  "patient_pedia_knee_function_q21_a5": "Ekstremalna trudność/nie do zrobienia",
  "patient_pedia_knee_function_q22": "Noszczenie ciężkich toreb/plecaki itp",
  "patient_pedia_knee_function_q22_a1": "Żadnych trudności",
  "patient_pedia_knee_function_q22_a2": "Lekkie",
  "patient_pedia_knee_function_q22_a3": "Umiarkowane",
  "patient_pedia_knee_function_q22_a4": "Duże",
  "patient_pedia_knee_function_q22_a5": "Ekstremalna trudność/nie do zrobienia",
  "patient_pedia_knee_function_q23": "Wykonywanie lekkich prac, takich jak sprzątanie pokoju, napełnianie/opróżnianie zmywarki, ścielenie łóżka itp",
  "patient_pedia_knee_function_q23_a1": "Żadnych trudności",
  "patient_pedia_knee_function_q23_a2": "Lekkie",
  "patient_pedia_knee_function_q23_a3": "Umiarkowane",
  "patient_pedia_knee_function_q23_a4": "Duże",
  "patient_pedia_knee_function_q23_a5": "Ekstremalna trudność/nie do zrobienia",
  "patient_pedia_knee_function_q24": "Dotarcie do szkoły lub poruszanie się po szkole (wchodzenie po schodach, otwieranie drzwi, noszenie książek, uczestnictwo w przerwie)",
  "patient_pedia_knee_function_q24_a1": "Żadnych trudności",
  "patient_pedia_knee_function_q24_a2": "Lekkie",
  "patient_pedia_knee_function_q24_a3": "Umiarkowane",
  "patient_pedia_knee_function_q24_a4": "Duże",
  "patient_pedia_knee_function_q24_a5": "Ekstremalna trudność/nie do zrobienia",
  "patient_pedia_knee_function_q25": "Robienie różnych rzeczy z przyjaciółmi",
  "patient_pedia_knee_function_q25_a1": "Żadnych trudności",
  "patient_pedia_knee_function_q25_a2": "Lekkie",
  "patient_pedia_knee_function_q25_a3": "Umiarkowane",
  "patient_pedia_knee_function_q25_a4": "Duże",
  "patient_pedia_knee_function_q25_a5": "Ekstremalna trudność/nie do zrobienia",
  "patient_pedia_knee_function_q26": "Jak dobrze działało Twoje kolano, zanim doznałeś kontuzji?",
  "patient_pedia_knee_function_q26_instruction": "10 oznacza prawidłowe działanie, a 0 oznacza niezdolność do wykonywania jakichkolwiek codziennych czynności, w tym uprawiania sportu",
  "patient_pedia_knee_function_q26_instruction_a1": "Nie mogłem w ogóle nic zrobić",
  "patient_pedia_knee_function_q26_instruction_a2": "Mogłem zrobić wszystko, co chciałem",
  "patient_pedia_knee_function_q27": "Jak dobrze TERAZ działa kolano?",
  "patient_pedia_knee_function_q27_instruction": "10 oznacza prawidłowe działanie, a 0 oznacza niezdolność do wykonywania jakichkolwiek codziennych czynności, w tym uprawiania sportu",
  "patient_pedia_knee_function_q27_instruction_a1": "Nie jestem w stanie nic zrobić",
  "patient_pedia_knee_function_q27_instruction_a2": "Jestem w stanie zrobić wszystko, co chcę",
  "patient_pedia_knee_function_q28": "Czy rzepka zwicha się lub czujesz jakby miałą się zwichnąć?",
  "patient_pedia_knee_function_q28_a1": "Nie",
  "patient_pedia_knee_function_q28_a2": "Tak",
  "patient_pedia_knee_instability": "Niestabilność stawu rzepkowo-udowego",
  "patient_pedia_knee_instability_instruction": "Co najlepiej opisuje JAK CZĘSTO masz wrażenie, że rzepka „wyskakuje” ZE STAWU lub WYDAJE SIĘ NIESTABILNA, gdy wykonujesz następujace czynności",
  "patient_pedia_knee_instability_instruction_0": "Nigdy",
  "patient_pedia_knee_instability_instruction_1": "Rzadko",
  "patient_pedia_knee_instability_instruction_2": "Czasami",
  "patient_pedia_knee_instability_instruction_3": "Często",
  "patient_pedia_knee_instability_instruction_4": "Zawsze",
  "patient_pedia_knee_instability_note": "Uwaga: Proszę odpowiedzieć na następujące pytania dotyczące aktywności kolana",
  "patient_pedia_knee_instability_q1": "Skręcanie/zmiana kierunku podczas uprawiania sportu/gier",
  "patient_pedia_knee_instability_q1_do_not_do": "Nie rób",
  "patient_pedia_knee_instability_q1_a1": "Nigdy",
  "patient_pedia_knee_instability_q1_a2": "Rzadko",
  "patient_pedia_knee_instability_q1_a3": "Czasami",
  "patient_pedia_knee_instability_q1_a4": "Często",
  "patient_pedia_knee_instability_q1_a5": "Zawsze",
  "patient_pedia_knee_instability_q2": "Zmiana kierunku podczas biegu",
  "patient_pedia_knee_instability_q2_do_not_do": "Nie rób",
  "patient_pedia_knee_instability_q2_a1": "Nigdy",
  "patient_pedia_knee_instability_q2_a2": "Rzadko",
  "patient_pedia_knee_instability_q2_a3": "Czasami",
  "patient_pedia_knee_instability_q2_a4": "Często",
  "patient_pedia_knee_instability_q2_a5": "Zawsze",
  "patient_pedia_knee_instability_q3": "Bieganie po linii prostej po NIERÓWNEJ nawierzchni",
  "patient_pedia_knee_instability_q3_do_not_do": "Nie rób",
  "patient_pedia_knee_instability_q3_a1": "Nigdy",
  "patient_pedia_knee_instability_q3_a2": "Rzadko",
  "patient_pedia_knee_instability_q3_a3": "Czasami",
  "patient_pedia_knee_instability_q3_a4": "Często",
  "patient_pedia_knee_instability_q3_a5": "Zawsze",
  "patient_pedia_knee_instability_q4": "Chodzenie po śliskich, mokrych lub oblodzonych nawierzchniach",
  "patient_pedia_knee_instability_q4_do_not_do": "Nie rób",
  "patient_pedia_knee_instability_q4_a1": "Nigdy",
  "patient_pedia_knee_instability_q4_a2": "Rzadko",
  "patient_pedia_knee_instability_q4_a3": "Czasami",
  "patient_pedia_knee_instability_q4_a4": "Często",
  "patient_pedia_knee_instability_q4_a5": "Zawsze",
  "patient_pedia_knee_instability_q5": "Bieganie bokiem",
  "patient_pedia_knee_instability_q5_do_not_do": "Nie rób",
  "patient_pedia_knee_instability_q5_a1": "Nigdy",
  "patient_pedia_knee_instability_q5_a2": "Rzadko",
  "patient_pedia_knee_instability_q5_a3": "Czasami",
  "patient_pedia_knee_instability_q5_a4": "Często",
  "patient_pedia_knee_instability_q5_a5": "Zawsze",
  "patient_pedia_knee_instability_q6": "Skakanie",
  "patient_pedia_knee_instability_q6_do_not_do": "Nie rób",
  "patient_pedia_knee_instability_q6_a1": "Nigdy",
  "patient_pedia_knee_instability_q6_a2": "Rzadko",
  "patient_pedia_knee_instability_q6_a3": "Czasami",
  "patient_pedia_knee_instability_q6_a4": "Często",
  "patient_pedia_knee_instability_q6_a5": "Zawsze",
  "patient_pedia_knee_instability_q7": "Skoki",
  "patient_pedia_knee_instability_q7_do_not_do": "Nie rób",
  "patient_pedia_knee_instability_q7_a1": "Nigdy",
  "patient_pedia_knee_instability_q7_a2": "Rzadko",
  "patient_pedia_knee_instability_q7_a3": "Czasami",
  "patient_pedia_knee_instability_q7_a4": "Często",
  "patient_pedia_knee_instability_q7_a5": "Zawsze",
  "patient_pedia_knee_instability_q8": "Bieganie po linii prostej po RÓWNYCH nawierzchniach",
  "patient_pedia_knee_instability_q8_do_not_do": "Nie rób",
  "patient_pedia_knee_instability_q8_a1": "Nigdy",
  "patient_pedia_knee_instability_q8_a2": "Rzadko",
  "patient_pedia_knee_instability_q8_a3": "Czasami",
  "patient_pedia_knee_instability_q8_a4": "Często",
  "patient_pedia_knee_instability_q8_a5": "Zawsze",
  "patient_pedia_knee_instability_q9": "Schodzenie po schodach",
  "patient_pedia_knee_instability_q9_do_not_do": "Nie rób",
  "patient_pedia_knee_instability_q9_a1": "Nigdy",
  "patient_pedia_knee_instability_q9_a2": "Rzadko",
  "patient_pedia_knee_instability_q9_a3": "Czasami",
  "patient_pedia_knee_instability_q9_a4": "Często",
  "patient_pedia_knee_instability_q9_a5": "Zawsze",
  "patient_pedia_knee_instability_q10": "Przysiad",
  "patient_pedia_knee_instability_q10_do_not_do": "Nie rób",
  "patient_pedia_knee_instability_q10_a1": "Nigdy",
  "patient_pedia_knee_instability_q10_a2": "Rzadko",
  "patient_pedia_knee_instability_q10_a3": "Czasami",
  "patient_pedia_knee_instability_q10_a4": "Często",
  "patient_pedia_knee_instability_q10_a5": "Zawsze",
  "patient_pedia_knee_instability_q11": "Klękanie",
  "patient_pedia_knee_instability_q11_do_not_do": "Nie rób",
  "patient_pedia_knee_instability_q11_a1": "Nigdy",
  "patient_pedia_knee_instability_q11_a2": "Rzadko",
  "patient_pedia_knee_instability_q11_a3": "Czasami",
  "patient_pedia_knee_instability_q11_a4": "Często",
  "patient_pedia_knee_instability_q11_a5": "Zawsze",
  "patient_pedia_knee_instability_q12": "Chodzenie po linii prostej po NIERÓWNEJ powierzchni",
  "patient_pedia_knee_instability_q12_do_not_do": "Nie rób",
  "patient_pedia_knee_instability_q12_a1": "Nigdy",
  "patient_pedia_knee_instability_q12_a2": "Rzadko",
  "patient_pedia_knee_instability_q12_a3": "Czasami",
  "patient_pedia_knee_instability_q12_a4": "Często",
  "patient_pedia_knee_instability_q12_a5": "Zawsze",
  "patient_pedia_knee_instability_q13": "Wchodzenie po schodach",
  "patient_pedia_knee_instability_q13_do_not_do": "Nie rób",
  "patient_pedia_knee_instability_q13_a1": "Nigdy",
  "patient_pedia_knee_instability_q13_a2": "Rzadko",
  "patient_pedia_knee_instability_q13_a3": "Czasami",
  "patient_pedia_knee_instability_q13_a4": "Często",
  "patient_pedia_knee_instability_q13_a5": "Zawsze",
  "patient_pedia_knee_instability_q14": "Wchodzenie na wysoki stopień lub przechodzenie przez niego",
  "patient_pedia_knee_instability_q14_do_not_do": "Nie rób",
  "patient_pedia_knee_instability_q14_a1": "Nigdy",
  "patient_pedia_knee_instability_q14_a2": "Rzadko",
  "patient_pedia_knee_instability_q14_a3": "Czasami",
  "patient_pedia_knee_instability_q14_a4": "Często",
  "patient_pedia_knee_instability_q14_a5": "Zawsze",
  "patient_pedia_knee_instability_q15": "Siadanie z założonymi nogami",
  "patient_pedia_knee_instability_q15_do_not_do": "Nie rób",
  "patient_pedia_knee_instability_q15_a1": "Nigdy",
  "patient_pedia_knee_instability_q15_a2": "Rzadko",
  "patient_pedia_knee_instability_q15_a3": "Czasami",
  "patient_pedia_knee_instability_q15_a4": "Często",
  "patient_pedia_knee_instability_q15_a5": "Zawsze",
  "patient_pedia_knee_instability_q16": "Chodzenie po linii prostej po PŁASKICH powierzchniach",
  "patient_pedia_knee_instability_q16_do_not_do": "Nie rób",
  "patient_pedia_knee_instability_q16_a1": "Nigdy",
  "patient_pedia_knee_instability_q16_a2": "Rzadko",
  "patient_pedia_knee_instability_q16_a3": "Czasami",
  "patient_pedia_knee_instability_q16_a4": "Często",
  "patient_pedia_knee_instability_q16_a5": "Zawsze",
  "patient_pedia_knee_instability_q17": "Wsiadanie lub wysiadanie z samochodu",
  "patient_pedia_knee_instability_q17_do_not_do": "Nie rób",
  "patient_pedia_knee_instability_q17_a1": "Nigdy",
  "patient_pedia_knee_instability_q17_a2": "Rzadko",
  "patient_pedia_knee_instability_q17_a3": "Czasami",
  "patient_pedia_knee_instability_q17_a4": "Często",
  "patient_pedia_knee_instability_q17_a5": "Zawsze",
  "patient_pedia_knee_instability_q18": "Zawracanie ciężkiego wózka w alejce supermarketu",
  "patient_pedia_knee_instability_q18_do_not_do": "Nie rób",
  "patient_pedia_knee_instability_q18_a1": "Nigdy",
  "patient_pedia_knee_instability_q18_a2": "Rzadko",
  "patient_pedia_knee_instability_q18_a3": "Czasami",
  "patient_pedia_knee_instability_q18_a4": "Często",
  "patient_pedia_knee_instability_q18_a5": "Zawsze",
  "patient_pedia_knee_instability_q19": "Odwracanie się, żeby spojrzeć przez ramię",
  "patient_pedia_knee_instability_q19_do_not_do": "Nie rób",
  "patient_pedia_knee_instability_q19_a1": "Nigdy",
  "patient_pedia_knee_instability_q19_a2": "Rzadko",
  "patient_pedia_knee_instability_q19_a3": "Czasami",
  "patient_pedia_knee_instability_q19_a4": "Często",
  "patient_pedia_knee_instability_q19_a5": "Zawsze",
  "patient_pedia_knee_quality_of_life": "Jakość życia",
  "patient_pedia_knee_quality_of_life_instruction": "Jak kontuzja wpłynęła na Twoje życie?",
  "patient_pedia_knee_quality_of_life_q1": "Jak często myślisz o problemie z kolanem?",
  "patient_pedia_knee_quality_of_life_q1_a1": "Nigdy",
  "patient_pedia_knee_quality_of_life_q1_a2": "Rzadko",
  "patient_pedia_knee_quality_of_life_q1_a3": "Czasami",
  "patient_pedia_knee_quality_of_life_q1_a4": "Często",
  "patient_pedia_knee_quality_of_life_q1_a5": "Cały czas",
  "patient_pedia_knee_problem_q2": "Jak bardzo zmieniłeś swój styl życia z powodu kontuzji kolana?",
  "patient_pedia_knee_problem_q2_a1": "W ogóle",
  "patient_pedia_knee_problem_q2_a2": "Nieznacznie",
  "patient_pedia_knee_problem_q2_a3": "Trochę",
  "patient_pedia_knee_problem_q2_a4": "Znacznie",
  "patient_pedia_knee_problem_q2_a5": "Bardzo",
  "patient_pedia_knee_problem_q3": "Jak bardzo ufasz kontuzjowanemu kolanu?",
  "patient_pedia_knee_problem_q3_a1": "Całkowicie",
  "patient_pedia_knee_problem_q3_a2": "Bardzo",
  "patient_pedia_knee_problem_q3_a3": "Trochę",
  "patient_pedia_knee_problem_q3_a4": "Nieznacznie",
  "patient_pedia_knee_problem_q3_a5": "W ogóle",
  "patient_pedia_knee_problem_q4": "Ogólnie rzecz biorąc, jak duże masz trudności z kontuzjowanym kolanem?",
  "patient_pedia_knee_problem_q4_a1": "Żadnych trudności",
  "patient_pedia_knee_problem_q4_a2": "Nieznaczne",
  "patient_pedia_knee_problem_q4_a3": "Umiarkowane",
  "patient_pedia_knee_problem_q4_a4": "Duże",
  "patient_pedia_knee_problem_q4_a5": "Ekstremalne trudności",
  "patient_pedia_knee_satisfaction": "Zadowolenie",
  "patient_pedia_knee_satisfaction_instruction": "Jak bardzo jesteś zadowolony z wyników operacji/leczenia kolana?",
  "patient_pedia_knee_satisfaction_instruction_a1": "Bardzo nieusatysfakcjonowany",
  "patient_pedia_knee_satisfaction_instruction_a2": "Nieco niezadowolony",
  "patient_pedia_knee_satisfaction_instruction_a3": "Częściowo usatysfakcjonowany",
  "patient_pedia_knee_satisfaction_instruction_a4": "Bardzo zadowolony",
  "patient_pedia_knee_satisfaction_q1": "Ogólnie",
  "patient_pedia_knee_satisfaction_q1_NA": "Nie dotyczy",
  "patient_pedia_knee_satisfaction_q1_a1": "Bardzo nieusatysfakcjonowany",
  "patient_pedia_knee_satisfaction_q1_a2": "Nieco niezadowolony",
  "patient_pedia_knee_satisfaction_q1_a3": "Częściowo usatysfakcjonowany",
  "patient_pedia_knee_satisfaction_q1_a4": "Bardzo zadowolony",
  "patient_pedia_knee_satisfaction_q2": "W związku z poprawą dolegliwości bólowych?",
  "patient_pedia_knee_satisfaction_q2_NA": "Nie dotyczy",
  "patient_pedia_knee_satisfaction_q2_a1": "Bardzo nieusatysfakcjonowany",
  "patient_pedia_knee_satisfaction_q2_a2": "Nieco niezadowolony",
  "patient_pedia_knee_satisfaction_q2_a3": "Częściowo usatysfakcjonowany",
  "patient_pedia_knee_satisfaction_q2_a4": "Bardzo zadowolony",
  "patient_pedia_knee_satisfaction_q3": "W związku z poprawą umiejętności wykonywania prac w domu lub na podwórku?",
  "patient_pedia_knee_satisfaction_q3_NA": "Nie dotyczy",
  "patient_pedia_knee_satisfaction_q3_a1": "Bardzo nieusatysfakcjonowany",
  "patient_pedia_knee_satisfaction_q3_a2": "Nieco niezadowolony",
  "patient_pedia_knee_satisfaction_q3_a3": "Częściowo usatysfakcjonowany",
  "patient_pedia_knee_satisfaction_q3_a4": "Bardzo zadowolony",
  "patient_pedia_knee_satisfaction_q4": "W związku z poprawą w zakresie zajęć rekreacyjnych?",
  "patient_pedia_knee_satisfaction_q4_NA": "Nie dotyczy",
  "patient_pedia_knee_satisfaction_q4_a1": "Bardzo nieusatysfakcjonowany",
  "patient_pedia_knee_satisfaction_q4_a2": "Nieco niezadowolony",
  "patient_pedia_knee_satisfaction_q4_a3": "Częściowo usatysfakcjonowany",
  "patient_pedia_knee_satisfaction_q4_a4": "Bardzo zadowolony",
  "patient_pedia_knee_complete": "Koniec ankiety",
  "patient_pedia_knee_complete_text1": "Dziękujemy za poświęcenie czasu na wypełnienie tego formularza.",
  "patient_pedia_knee_complete_text2": "Kliknij przycisk Prześlij, aby zakończyć i zobaczyć swoje wyniki.",
  "patient_pedia_knee_complete_text3": "Twoje odpowiedzi będą traktowane poufnie, a wszelkie raporty będą wykorzystywać wyłącznie informacje pozbawione danych umożliwiających identyfikację.",
  "patient_pedia_knee_results": "Wyniki",
  "patient_pedia_knee_results_text1": "Poniżej znajdują się Twoje wyniki w zakresie bólu i funkcji kolana, które zostały obliczone na podstawie Twojego kwestionariusza.",
  "patient_pedia_knee_results_pain": "Ból",
  "patient_pedia_knee_results_function": "Funkcja",
  "patient_pedia_knee_results_self_evaluation": "Skala samooceny kolana",
  "patient_pedia_knee_results_text2": "Twój lekarz wkrótce do Ciebie dotrze",
  "patient_foot_ankle_pain": "Ból",
  "patient_foot_ankle_pain_q1": "Jaki jest AKTUALNY POZIOM BÓLU stopy/kostki?",
  "patient_foot_ankle_pain_q1_0": "Brak",
  "patient_foot_ankle_pain_q1_1": "Najgorszy ból, jaki można sobie wyobrazić",
  "patient_foot_ankle_pain_q2": "Jaki jest najlepszy opis BÓLU stopy/kostki?",
  "patient_foot_ankle_pain_q2_a1": "Brak",
  "patient_foot_ankle_pain_q2_a2": "Łagodny, okazjonalny",
  "patient_foot_ankle_pain_q2_a3": "Umiarkowany, codziennie",
  "patient_foot_ankle_pain_q2_a4": "Ciężki, prawie zawsze obecny",
  "patient_foot_ankle_pain_q3": "Jak CZĘSTO odczuwasz ból stóp/kostek?",
  "patient_foot_ankle_pain_q3_a1": "Nigdy",
  "patient_foot_ankle_pain_q3_a2": "Raz w miesiącu",
  "patient_foot_ankle_pain_q3_a3": "Co tydzień",
  "patient_foot_ankle_pain_q3_a4": "Codziennie",
  "patient_foot_ankle_pain_q3_a5": "Zawsze",
  "patient_foot_ankle_pain_instruction": "Jakiego nasilenia bólu stóp/kostek doświadczyłeś podczas następujących czynności:",
  "patient_foot_ankle_pain_instruction_0": "Brak",
  "patient_foot_ankle_pain_instruction_1": "Łagodny",
  "patient_foot_ankle_pain_instruction_2": "Umiarkowany",
  "patient_foot_ankle_pain_instruction_3": "Silny",
  "patient_foot_ankle_pain_instruction_4": "Skrajny",
  "patient_foot_ankle_pain_q4": "Ogólny poziom bólu",
  "patient_foot_ankle_pain_q4_a1": "Brak",
  "patient_foot_ankle_pain_q4_a2": "Łagodny",
  "patient_foot_ankle_pain_q4_a3": "Umiarkowany",
  "patient_foot_ankle_pain_q4_a4": "Silny",
  "patient_foot_ankle_pain_q4_a5": "Skrajny",
  "patient_foot_ankle_pain_q5": "W spoczynku",
  "patient_foot_ankle_pain_q5_a1": "Brak",
  "patient_foot_ankle_pain_q5_a2": "Łagodny",
  "patient_foot_ankle_pain_q5_a3": "Umiarkowany",
  "patient_foot_ankle_pain_q5_a4": "Silny",
  "patient_foot_ankle_pain_q5_a5": "Skrajny",
  "patient_foot_ankle_pain_q6": "Podczas normalnej aktywności",
  "patient_foot_ankle_pain_q6_a1": "Brak",
  "patient_foot_ankle_pain_q6_a2": "Łagodny",
  "patient_foot_ankle_pain_q6_a3": "Umiarkowany",
  "patient_foot_ankle_pain_q6_a4": "Silny",
  "patient_foot_ankle_pain_q6_a5": "Skrajny",
  "patient_foot_ankle_pain_q7": "Zaraz po przebudzeniu",
  "patient_foot_ankle_pain_q7_a1": "Brak",
  "patient_foot_ankle_pain_q7_a2": "Łagodny",
  "patient_foot_ankle_pain_q7_a3": "Umiarkowany",
  "patient_foot_ankle_pain_q7_a4": "Silny",
  "patient_foot_ankle_pain_q7_a5": "Skrajny",
  "patient_foot_ankle_pain_q8": "Skręcanie/obrót na stopie/kostce",
  "patient_foot_ankle_pain_q8_a1": "Brak",
  "patient_foot_ankle_pain_q8_a2": "Łagodny",
  "patient_foot_ankle_pain_q8_a3": "Umiarkowany",
  "patient_foot_ankle_pain_q8_a4": "Silny",
  "patient_foot_ankle_pain_q8_a5": "Skrajny",
  "patient_foot_ankle_pain_q9": "Całkowite wyprostowanie stopy/kostki",
  "patient_foot_ankle_pain_q9_a1": "Brak",
  "patient_foot_ankle_pain_q9_a2": "Łagodny",
  "patient_foot_ankle_pain_q9_a3": "Umiarkowany",
  "patient_foot_ankle_pain_q9_a4": "Silny",
  "patient_foot_ankle_pain_q9_a5": "Skrajny",
  "patient_foot_ankle_pain_q10": "Pełne zginanie stopy/kostki",
  "patient_foot_ankle_pain_q10_a1": "Brak",
  "patient_foot_ankle_pain_q10_a2": "Łagodny",
  "patient_foot_ankle_pain_q10_a3": "Umiarkowany",
  "patient_foot_ankle_pain_q10_a4": "Silny",
  "patient_foot_ankle_pain_q10_a5": "Skrajny",
  "patient_foot_ankle_pain_q11": "Chodzenie po płaskiej powierzchni",
  "patient_foot_ankle_pain_q11_a1": "Brak",
  "patient_foot_ankle_pain_q11_a2": "Łagodny",
  "patient_foot_ankle_pain_q11_a3": "Umiarkowany",
  "patient_foot_ankle_pain_q11_a4": "Silny",
  "patient_foot_ankle_pain_q11_a5": "Skrajny",
  "patient_foot_ankle_pain_q12": "Wchodzenie lub schodzenie po schodach",
  "patient_foot_ankle_pain_q12_a1": "Brak",
  "patient_foot_ankle_pain_q12_a2": "Łagodny",
  "patient_foot_ankle_pain_q12_a3": "Umiarkowany",
  "patient_foot_ankle_pain_q12_a4": "Silny",
  "patient_foot_ankle_pain_q12_a5": "Skrajny",
  "patient_foot_ankle_pain_q13": "Wieczorem, będąc w łóżku",
  "patient_foot_ankle_pain_q13_a1": "Brak",
  "patient_foot_ankle_pain_q13_a2": "Łagodny",
  "patient_foot_ankle_pain_q13_a3": "Umiarkowany",
  "patient_foot_ankle_pain_q13_a4": "Silny",
  "patient_foot_ankle_pain_q13_a5": "Skrajny",
  "patient_foot_ankle_pain_q14": "Siedzenie lub leżenie",
  "patient_foot_ankle_pain_q14_a1": "Brak",
  "patient_foot_ankle_pain_q14_a2": "Łagodny",
  "patient_foot_ankle_pain_q14_a3": "Umiarkowany",
  "patient_foot_ankle_pain_q14_a4": "Silny",
  "patient_foot_ankle_pain_q14_a5": "Skrajny",
  "patient_foot_ankle_pain_q15": "Stojąc wyprostowanym",
  "patient_foot_ankle_pain_q15_a1": "Brak",
  "patient_foot_ankle_pain_q15_a2": "Łagodny",
  "patient_foot_ankle_pain_q15_a3": "Umiarkowany",
  "patient_foot_ankle_pain_q15_a4": "Silny",
  "patient_foot_ankle_pain_q15_a5": "Skrajny",
  "patient_foot_ankle_instability": "Niestabilność",
  "patient_foot_ankle_instability_q1": "Czy doświadczasz nawracających skręceń, uciekania kostki lub masz wrażenie, że zaraz się zwichnie?",
  "patient_foot_ankle_instability_q1_a1": "Nie",
  "patient_foot_ankle_instability_q1_a2": "Tak",
  "patient_foot_ankle_instability_q2": "Czy kiedykolwiek zdiagnozowano/leczono u Ciebie niestabilność stawu skokowego/nawracające skręcenia?",
  "patient_foot_ankle_instability_q2_a1": "Nie",
  "patient_foot_ankle_instability_q2_a2": "Tak",
  "patient_foot_ankle_instability_q3": "Czy Twoja zdrowa kostka jest całkowicie stabilna?",
  "patient_foot_ankle_instability_q3_a1": "Nie",
  "patient_foot_ankle_instability_q3_a2": "Tak",
  "patient_foot_ankle_cumberland": "CUMBERLAND ANKLE INSTABILITY TOOL",
  "patient_foot_ankle_cumberland_instruction": "W każdym pytaniu wybierz jedno stwierdzenie, które najlepiej opisuje Twoją kostkę.",
  "patient_foot_ankle_cumberland_q1": "Boli mnie kostka podczas",
  "patient_foot_ankle_cumberland_q1_a1": "Nigdy",
  "patient_foot_ankle_cumberland_q1_a2": "Sportu",
  "patient_foot_ankle_cumberland_q1_a3": "Biegania po nierównych powierzchniach",
  "patient_foot_ankle_cumberland_q1_a4": "Biegania po równych powierzchniach",
  "patient_foot_ankle_cumberland_q1_a5": "Chodzenia po nierównych powierzchniach",
  "patient_foot_ankle_cumberland_q1_a6": "Chodzenia po płaskich powierzchniach",
  "patient_foot_ankle_cumberland_q2": "Moja kostka jest NIESTABILNA",
  "patient_foot_ankle_cumberland_q2_a1": "Nigdy",
  "patient_foot_ankle_cumberland_q2_a2": "Czasami podczas uprawiania sportu (nie za każdym razem)",
  "patient_foot_ankle_cumberland_q2_a3": "Często podczas uprawiania sportu (za każdym razem)",
  "patient_foot_ankle_cumberland_q2_a4": "Czasem podczas codziennych zajęć",
  "patient_foot_ankle_cumberland_q2_a5": "Często podczas codziennych zajęć",
  "patient_foot_ankle_cumberland_q3": "Kiedy wykonuję GWAŁTOWNE zwroty, moja kostka wydaje się NIESTABILNA",
  "patient_foot_ankle_cumberland_q3_a1": "Nigdy",
  "patient_foot_ankle_cumberland_q3_a2": "Czasami podczas biegania",
  "patient_foot_ankle_cumberland_q3_a3": "Często podczas biegania",
  "patient_foot_ankle_cumberland_q3_a4": "Podczas chodzenia",
  "patient_foot_ankle_cumberland_q4": "Kiedy schodzę po schodach, czuję, że moja kostka jest NIESTABILNA",
  "patient_foot_ankle_cumberland_q4_a1": "Nigdy",
  "patient_foot_ankle_cumberland_q4_a2": "Jeśli schodzę szybko",
  "patient_foot_ankle_cumberland_q4_a3": "Sporadycznie",
  "patient_foot_ankle_cumberland_q4_a4": "Zawsze",
  "patient_foot_ankle_cumberland_q5": "Moja kostka wydaje się NIESTABILNA, gdy stoję na JEDNEJ nodze",
  "patient_foot_ankle_cumberland_q5_a1": "Nigdy",
  "patient_foot_ankle_cumberland_q5_a2": "Na śródstopiu",
  "patient_foot_ankle_cumberland_q5_a3": "Na płaskiej stopie",
  "patient_foot_ankle_cumberland_q6": "Moja kostka wydaje się NIESTABILNA, kiedy",
  "patient_foot_ankle_cumberland_q6_a1": "Nigdy",
  "patient_foot_ankle_cumberland_q6_a2": "Przeskakuje z boku na bok",
  "patient_foot_ankle_cumberland_q6_a3": "Podskakuję w miejscu",
  "patient_foot_ankle_cumberland_q6_a4": "Skaczę",
  "patient_foot_ankle_cumberland_q7": "Moja kostka wydaje się NIESTABILNA, kiedy",
  "patient_foot_ankle_cumberland_q7_a1": "Nigdy",
  "patient_foot_ankle_cumberland_q7_a2": "Biegam po nierównych nawierzchniach",
  "patient_foot_ankle_cumberland_q7_a3": "Truchtam po nierównych powierzchniach",
  "patient_foot_ankle_cumberland_q7_a4": "Chodzę po nierównych powierzchniach",
  "patient_foot_ankle_cumberland_q7_a5": "Chodzę po płaskich powierzchniach",
  "patient_foot_ankle_cumberland_q8": "ZWYKLE, gdy zaczynam się przewracać na kostce (lub „skręcać”), mogę temu zapobiec ",
  "patient_foot_ankle_cumberland_q8_a1": "Natychmiast",
  "patient_foot_ankle_cumberland_q8_a2": "Często",
  "patient_foot_ankle_cumberland_q8_a3": "Czasami",
  "patient_foot_ankle_cumberland_q8_a4": "Nigdy",
  "patient_foot_ankle_cumberland_q8_a5": "Nigdy nie przewróciłem się przez kostkę",
  "patient_foot_ankle_cumberland_q9": "Po TYPOWYM incydencie polegającym na wykręceniu się kostki, kostka wraca do „normy”",
  "patient_foot_ankle_cumberland_q9_a1": "Prawie natychmiast",
  "patient_foot_ankle_cumberland_q9_a2": "Mniej niż jeden dzień",
  "patient_foot_ankle_cumberland_q9_a3": "1-2 dni",
  "patient_foot_ankle_cumberland_q9_a4": "Ponad 2 dni",
  "patient_foot_ankle_cumberland_q9_a5": "Nigdy nie skręciłem kostki",
  "patient_foot_ankle_other_symptoms": "Inne objawy",
  "patient_foot_ankle_other_symptoms_instruction1": "Wybierz odpowiedź, która najlepiej odpowiada Twoim objawom dotyczącym stóp/kostek.",
  "patient_foot_ankle_other_symptoms_instruction2": "Jeśli nie jesteś pewien, jak odpowiedzieć na pytanie, podaj najlepszą możliwą odpowiedź.",
  "patient_foot_ankle_other_symptoms_note": "Uwaga: na te pytania należy odpowiedzieć, biorąc pod uwagę objawy stóp/kostek, które wystąpiły w ostatnim tygodniu.",
  "patient_foot_ankle_other_symptoms_instruction3": "Czy masz:",
  "patient_foot_ankle_other_symptoms_instruction3_0": "Nigdy",
  "patient_foot_ankle_other_symptoms_instruction3_1": "Rzadko",
  "patient_foot_ankle_other_symptoms_instruction3_2": "Czasami",
  "patient_foot_ankle_other_symptoms_instruction3_3": "Często",
  "patient_foot_ankle_other_symptoms_instruction3_4": "Zawsze",
  "patient_foot_ankle_other_symptoms_q1": "Obrzęk stopy/kostki",
  "patient_foot_ankle_other_symptoms_q1_a1": "Nigdy",
  "patient_foot_ankle_other_symptoms_q1_a2": "Rzadko",
  "patient_foot_ankle_other_symptoms_q1_a3": "Czasami",
  "patient_foot_ankle_other_symptoms_q1_a4": "Często",
  "patient_foot_ankle_other_symptoms_q1_a5": "Zawsze",
  "patient_foot_ankle_other_symptoms_q2": "Trzeszczenie, przeskakiwanie lub słyszenie dowolnego rodzaju hałasu podczas ruchu stopy/kostki",
  "patient_foot_ankle_other_symptoms_q2_a1": "Nigdy",
  "patient_foot_ankle_other_symptoms_q2_a2": "Rzadko",
  "patient_foot_ankle_other_symptoms_q2_a3": "Czasami",
  "patient_foot_ankle_other_symptoms_q2_a4": "Często",
  "patient_foot_ankle_other_symptoms_q2_a5": "Zawsze",
  "patient_foot_ankle_other_symptoms_q3": "Zaciśnięcie lub zablokowanie podczasu ruchu",
  "patient_foot_ankle_other_symptoms_q3_a1": "Nigdy",
  "patient_foot_ankle_other_symptoms_q3_a2": "Rzadko",
  "patient_foot_ankle_other_symptoms_q3_a3": "Czasami",
  "patient_foot_ankle_other_symptoms_q3_a4": "Często",
  "patient_foot_ankle_other_symptoms_q3_a5": "Zawsze",
  "patient_foot_ankle_other_symptoms_instruction4": "Jak duża jest SZTYWNOŚĆ Twojej stopy/kostki:",
  "patient_foot_ankle_other_symptoms_instruction4_0": "Brak",
  "patient_foot_ankle_other_symptoms_instruction4_1": "Łagodna",
  "patient_foot_ankle_other_symptoms_instruction4_2": "Umiarkowana",
  "patient_foot_ankle_other_symptoms_instruction4_3": "Duża",
  "patient_foot_ankle_other_symptoms_instruction4_4": "Skrajna",
  "patient_foot_ankle_other_symptoms_q4": "Po pierwszym przebudzeniu rano",
  "patient_foot_ankle_other_symptoms_q4_a1": "Brak",
  "patient_foot_ankle_other_symptoms_q4_a2": "Łagodna",
  "patient_foot_ankle_other_symptoms_q4_a3": "Umiarkowana",
  "patient_foot_ankle_other_symptoms_q4_a4": "Duża",
  "patient_foot_ankle_other_symptoms_q4_a5": "Skrajna",
  "patient_foot_ankle_other_symptoms_q5": "Po siedzeniu, leżeniu lub odpoczynku w ciągu dnia",
  "patient_foot_ankle_other_symptoms_q5_a1": "Brak",
  "patient_foot_ankle_other_symptoms_q5_a2": "Łagodna",
  "patient_foot_ankle_other_symptoms_q5_a3": "Umiarkowana",
  "patient_foot_ankle_other_symptoms_q5_a4": "Duża",
  "patient_foot_ankle_other_symptoms_q5_a5": "Skrajna",
  "patient_foot_ankle_function": "Funkcja",
  "patient_foot_ankle_function_q1": "Jakie masz ograniczenia w codziennej aktywności ze względu na stopę/kostkę?",
  "patient_foot_ankle_function_q1_a1": "Żadnych ograniczeń, bez wsparcia",
  "patient_foot_ankle_function_q1_a2": "Żadnych ograniczeń codziennych zajęć, ograniczenie zajęć rekreacyjnych, bez wsparcia",
  "patient_foot_ankle_function_q1_a3": "Ograniczona aktywność codzienna i rekreacyjna, wsparcie laską",
  "patient_foot_ankle_function_q1_a4": "Poważne ograniczenie codziennych i rekreacyjnych czynności, chodzik, kule, wózek inwalidzki, orteza",
  "patient_foot_ankle_function_q2": "Jakie obuwie najczęściej nosisz?",
  "patient_foot_ankle_function_q2_a1": "Modne, konwencjonalne buty, niewymagające wkładek",
  "patient_foot_ankle_function_q2_a2": "Obuwie komfortowe, wkładka do butów",
  "patient_foot_ankle_function_q2_a3": "Zmodyfikowane buty lub orteza",
  "patient_foot_ankle_function_q3": "Po jakiej nawierzchni masz trudności z chodzeniem?",
  "patient_foot_ankle_function_q3_a1": "Żadnych trudności na każdej powierzchni",
  "patient_foot_ankle_function_q3_a2": "Pewne trudności na nierównym terenie, schodach, pochyłościach, drabinach",
  "patient_foot_ankle_function_q3_a3": "Poważne trudności na nierównym terenie, schodach, pochyłościach, drabinach",
  "patient_foot_ankle_function_instruction": "Wskaż STOPIEŃ TRUDNOŚCI, jakiej doświadczyłeś w ostatnim tygodniu Z powodu swojej stopy/kostki",
  "patient_foot_ankle_function_instruction_0": "Żadnych trudności",
  "patient_foot_ankle_function_instruction_1": "Niewielkie trudności",
  "patient_foot_ankle_function_instruction_2": "Umiarkowane trudności",
  "patient_foot_ankle_function_instruction_3": "Ekstremalna trudność",
  "patient_foot_ankle_function_instruction_4": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_note": "Uwaga: jeśli dana aktywność jest ograniczona przez coś innego niż stopa lub kostka, zaznacz „Nie dotyczy” w pozycjach, w których jest to dostępna opcja. W przeciwnym razie proszę odpowiedzieć na każde pytanie jedną odpowiedzią, która najlepiej opisuje Twój stan w ciągu ostatniego tygodnia.",
  "patient_foot_ankle_function_q4": "Pełne wyprostowanie stopy/kostki",
  "patient_foot_ankle_function_q4_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q4_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q4_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q4_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q4_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q5": "Pełne zginanie stopy/kostki",
  "patient_foot_ankle_function_q5_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q5_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q5_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q5_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q5_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q6": "Schodzenie po schodach",
  "patient_foot_ankle_function_q6_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q6_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q6_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q6_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q6_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q7": "Wchodzenie po schodach",
  "patient_foot_ankle_function_q7_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q7_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q7_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q7_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q7_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q8": "Wstawanie z siedzenia",
  "patient_foot_ankle_function_q8_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q8_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q8_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q8_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q8_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q9": "Stojąc",
  "patient_foot_ankle_function_q9_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q9_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q9_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q9_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q9_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q10": "Pochylanie się do podłogi/podnoszenie przedmiotu",
  "patient_foot_ankle_function_q10_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q10_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q10_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q10_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q10_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q11": "Chodzenie po płaskiej powierzchni",
  "patient_foot_ankle_function_q11_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q11_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q11_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q11_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q11_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q12": "Jaki jest maksymalny dystans, jaki możesz przejść?",
  "patient_foot_ankle_function_q12_a1": "Więcej niż 6 bloków",
  "patient_foot_ankle_function_q12_a2": "4-6 bloków",
  "patient_foot_ankle_function_q12_a3": "1-3 bloki",
  "patient_foot_ankle_function_q12_a4": "Mniej niż 1 blok",
  "patient_foot_ankle_function_q13": "Chodzenie po równym podłożu bez butów",
  "patient_foot_ankle_function_q13_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q13_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q13_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q13_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q13_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q14": "Schodzenie z góry",
  "patient_foot_ankle_function_q14_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q14_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q14_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q14_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q14_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q15": "Podchodzenie pod góre",
  "patient_foot_ankle_function_q15_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q15_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q15_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q15_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q15_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q16": "Chodzenie po nierównym terenie",
  "patient_foot_ankle_function_q16_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q16_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q16_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q16_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q16_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q17": "Wchodzenie i schodzenie z krawężników",
  "patient_foot_ankle_function_q17_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q17_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q17_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q17_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q17_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q18": "Dochodząc do palców u nóg",
  "patient_foot_ankle_function_q18_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q18_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q18_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q18_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q18_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q19": "Początkowo chodzenie",
  "patient_foot_ankle_function_q19_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q19_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q19_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q19_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q19_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q20": "Spacer 5 minut lub mniej",
  "patient_foot_ankle_function_q20_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q20_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q20_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q20_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q20_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q21": "Spacer około 10 minut",
  "patient_foot_ankle_function_q21_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q21_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q21_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q21_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q21_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q22": "Chodzenie przez 15 minut lub dłużej",
  "patient_foot_ankle_function_q22_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q22_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q22_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q22_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q22_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q23": "Wsiadanie/wysiadanie z samochodu",
  "patient_foot_ankle_function_q23_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q23_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q23_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q23_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q23_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q24": "Chodzenie na zakupy",
  "patient_foot_ankle_function_q24_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q24_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q24_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q24_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q24_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q25": "Zakładanie skarpet/pończoch",
  "patient_foot_ankle_function_q25_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q25_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q25_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q25_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q25_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q26": "Zdejmowanie skarpet/pończoch",
  "patient_foot_ankle_function_q26_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q26_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q26_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q26_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q26_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q27": "Wstawanie z łóżka",
  "patient_foot_ankle_function_q27_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q27_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q27_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q27_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q27_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q28": "Leżenie w łóżku (przewracanie się, utrzymywanie pozycji stóp/kostek)",
  "patient_foot_ankle_function_q28_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q28_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q28_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q28_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q28_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q29": "Spanie",
  "patient_foot_ankle_function_q29_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q29_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q29_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q29_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q29_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q30": "Wchodzenie i wychodzenie z wanny",
  "patient_foot_ankle_function_q30_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q30_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q30_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q30_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q30_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q31": "Siedzenie",
  "patient_foot_ankle_function_q31_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q31_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q31_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q31_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q31_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q32": "Siadanie i schodzenie z toalety",
  "patient_foot_ankle_function_q32_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q32_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q32_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q32_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q32_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q33": "Obowiązki domowe",
  "patient_foot_ankle_function_q33_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q33_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q33_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q33_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q33_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q34": "Czynności życia codziennego",
  "patient_foot_ankle_function_q34_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q34_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q34_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q34_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q34_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q35": "Higiena osobista (w tym samodzielne mycie i ubieranie się)",
  "patient_foot_ankle_function_q35_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q35_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q35_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q35_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q35_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q36": "Ciężka praca (pchanie/ciągnięcie, wspinanie się, przenoszenie, przenoszenie ciężkich pudeł, szorowanie podłóg itp.)",
  "patient_foot_ankle_function_q36_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q36_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q36_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q36_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q36_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q37": "Lekkie prace domowe (gotowanie, odkurzanie itp.)",
  "patient_foot_ankle_function_q37_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q37_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q37_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q37_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q37_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q38": "Praca lekka i umiarkowana (na stojąco, chodząc)",
  "patient_foot_ankle_function_q38_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q38_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q38_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q38_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q38_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q39": "Zajęcia rekreacyjne",
  "patient_foot_ankle_function_q39_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q39_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q39_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q39_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q39_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q40": "Przysiady",
  "patient_foot_ankle_function_q40_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q40_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q40_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q40_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q40_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q41": "Skręcanie/obrót na stopie/kostce",
  "patient_foot_ankle_function_q41_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q41_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q41_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q41_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q41_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q42": "Klęczenie",
  "patient_foot_ankle_function_q42_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q42_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q42_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q42_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q42_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q43": "Bieganie",
  "patient_foot_ankle_function_q43_not_applicable": "Nie dotyczy\nNiewielkie trudności\nUmiarkowane trudności\nEkstremalna trudność\nNiemożliwe do zrobienia",
  "patient_foot_ankle_function_q43_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q43_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q43_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q43_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q43_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q44": "Skoki",
  "patient_foot_ankle_function_q44_not_applicable": "Nie dotyczy\nNiewielkie trudności\nUmiarkowane trudności\nEkstremalna trudność\nNiemożliwe do zrobienia",
  "patient_foot_ankle_function_q44_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q44_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q44_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q44_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q44_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q45": "Lądowanie",
  "patient_foot_ankle_function_q45_not_applicable": "Nie dotyczy",
  "patient_foot_ankle_function_q45_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q45_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q45_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q45_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q45_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q46": "Nagły start i zatrzymywanie",
  "patient_foot_ankle_function_q46_not_applicable": "Nie dotyczy",
  "patient_foot_ankle_function_q46_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q46_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q46_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q46_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q46_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q47": "Cięcie / ruchy boczne",
  "patient_foot_ankle_function_q47_not_applicable": "Nie dotyczy",
  "patient_foot_ankle_function_q47_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q47_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q47_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q47_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q47_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q48": "Mało wymagające aktywności",
  "patient_foot_ankle_function_q48_not_applicable": "Nie dotyczy",
  "patient_foot_ankle_function_q48_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q48_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q48_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q48_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q48_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q49": "Zdolność do wykonywania czynności przy użyciu naturalnej techniki",
  "patient_foot_ankle_function_q49_not_applicable": "Nie dotyczy",
  "patient_foot_ankle_function_q49_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q49_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q49_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q49_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q49_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q50": "Możliwość uprawiania wybranego sportu tak długo, jak chcesz",
  "patient_foot_ankle_function_q50_not_applicable": "Nie dotyczy",
  "patient_foot_ankle_function_q50_a1": "Żadnych trudności",
  "patient_foot_ankle_function_q50_a2": "Niewielkie trudności",
  "patient_foot_ankle_function_q50_a3": "Umiarkowane trudności",
  "patient_foot_ankle_function_q50_a4": "Ekstremalna trudność",
  "patient_foot_ankle_function_q50_a5": "Niemożliwe do zrobienia",
  "patient_foot_ankle_function_q51": "Jak oceniłbyś dzisiaj swoją kostkę/stopę jako procent normy? (0 do 100%, 100% to wartość normalna)",
  "patient_foot_ankle_function_q51_a1": "Normalna",
  "patient_foot_ankle_function_q52": "Jaki jest najwyższy poziom aktywności, w której możesz regularnie uczestniczyć?",
  "patient_foot_ankle_function_q52_q1": "Przed urazem/problemem stopy lub kostki",
  "patient_foot_ankle_function_q52_q1_a1": "Najwyższy poziom",
  "patient_foot_ankle_function_q52_q1_a2": "Niższy wyczynowy",
  "patient_foot_ankle_function_q52_q1_a3": "Rekreacyjny",
  "patient_foot_ankle_function_q52_q1_a4": "Żadnej aktywności sportowej",
  "patient_foot_ankle_function_q52_q1_a5": "Żadnego sportu, żadnej pracy",
  "patient_foot_ankle_function_q52_q1_a4_o7": "Niezdolny do chodzenia, niepełnosprawny z powodu problemów z kostką",
  "patient_foot_ankle_function_q52_q1_a1_title": "Najwyższy poziom (elita międzynarodowa, profesjonalista, drużyna narodowa lub pierwsza liga)",
  "patient_foot_ankle_function_q52_q1_a2_title": "Niższy wyczynowy",
  "patient_foot_ankle_function_q52_q1_a3_title": "Rekreacyjny",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o1": "Aerobik, fitness",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o2": "Narciarstwo alpejskie i snowboard",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o3": "Futbol amerykański",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o4": "Badminton",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o5": "Baseball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o6": "Koszykówka",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o7": "Kręgle/curling",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_08": "Boks",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o9": "Biegi przełajowe",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o10": "Kolarstwo",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o11": "Taniec",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o12": "Nurkowanie",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o13": "Jeździectwo konne",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o14": "Szermierka",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o15": "Unihokej",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o16": "Freestyle snowboarding",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o17": "Golf",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o18": "Gimnastyka",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o19": "Gra w piłkę ręczną",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o20": "Hokej",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o21": "Hokej na lodzie",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o22": "Korfball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o23": "Sztuki walki",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o24": "Pięciobój nowoczesny",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o25": "Sporty motorowe, sporty techniczne",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o26": "Wspinaczka górska i spacer górski",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o27": "Kolarstwo górskie/bmx",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o28": "Narciarstwo klasyczne",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o29": "Bieg na orientację",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o30": "Spadochroniarstwo",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o31": "Trójbój siłowy",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o32": "Gimnastyka artystyczna",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o33": "Wioślarstwo, kajakarstwo",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o34": "Rugby",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o35": "Żeglarstwo",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o36": "Nurkowanie",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o37": "Strzelanie, łucznictwo",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o38": "Jazda na łyżwach, jazda na rolkach",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o39": "Piłka nożna",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o40": "Softball",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o41": "Squash",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o42": "Surfing, windsurfing",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o43": "Tenis stołowy",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o44": "Tenis",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o45": "Lekkoatletyka: wydarzenia terenowe",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o46": "Lekkoatletyka: konkurencje biegowe",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o47": "Triatlon",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o48": "Siatkówka",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o49": "Piłka wodna i pływanie",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o50": "Narciarstwo wodne",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o51": "Podnoszenie ciężarów, kulturystyka",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o52": "Zapasy",
  "patient_foot_ankle_function_q52_q1_a4_o1": "Ciężka praca fizyczna",
  "patient_foot_ankle_function_q52_q1_a4_o2": "Praca fizyczna",
  "patient_foot_ankle_function_q52_q1_a4_o3": "Zawody specjalne: baletnica, żołnierz zawodowy, ratownik specjalny, kaskader",
  "patient_foot_ankle_function_q52_q1_a4_o4": "Możliwość chodzenia po każdym nierównym podłożu",
  "patient_foot_ankle_function_q52_q1_a4_o5": "Codzienne zajęcia nie są ograniczone",
  "patient_foot_ankle_function_q52_q1_a4_o6": "Potrafi chodzić po równym podłożu, ale codzienne czynności są ograniczone",
  "patient_foot_ankle_function_q52_q2": "Aktualny stan",
  "patient_foot_ankle_function_q52_q2_a1": "Najwyższy poziom",
  "patient_foot_ankle_function_q52_q2_a2": "Niższy poziom konkurencji",
  "patient_foot_ankle_function_q52_q2_a3": "Rekreacyjne",
  "patient_foot_ankle_function_q52_q2_a4": "Żadnej aktywności sportowej",
  "patient_foot_ankle_function_q52_q2_a5": "Żadnego sportu, żadnej pracy",
  "patient_foot_ankle_function_q52_q2_a4_o7": "Nie może chodzić, jest niepełnosprawny z powodu problemów z kostką",
  "patient_foot_ankle_function_q52_q2_a1_title": "Najwyższy poziom (elita międzynarodowa, profesjonalista, drużyna narodowa lub pierwsza liga)",
  "patient_foot_ankle_function_q52_q2_a2_title": "Niższy poziom konkurencji",
  "patient_foot_ankle_function_q52_q2_a3_title": "Rekreacyjne",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o1": "Aerobik, fitness",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o2": "Narciarstwo alpejskie i snowboard",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o3": "Futbol amerykański",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o4": "Badminton",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o5": "Baseball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o6": "Koszykówka",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o7": "Kręgle/curling",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o8": "Boks",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o9": "Biegi przełajowe",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o10": "Kolarstwo",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o11": "Taniec",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o12": "Nurkowanie",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o13": "Jazda konno",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o14": "Szermierka",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o15": "Unihokej",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o16": "Freestyle snowboarding",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o17": "Golf",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o18": "Gimnastyka",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o19": "Gra w piłkę ręczną",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o20": "Hokej",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o21": "Hokej na lodzie",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o22": "Korfball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o23": "Sztuki walki",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o24": "Pięciobój nowoczesny",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o25": "Sporty motorowe, sporty techniczne",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o26": "Wspinaczka górska i spacer górski",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o27": "Kolarstwo górskie/bmx",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o28": "Narciarstwo klasyczne",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o29": "Bieg na orientację",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o30": "Spadochroniarstwo",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o31": "Trójbój siłowy",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o32": "Gimnastyka artystyczna",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o33": "Wioślarstwo, kajakarstwo",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o34": "Rugby",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o35": "Żeglarstwo",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o36": "Nurkowanie",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o37": "Strzelanie, łucznictwo",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o38": "Jazda na łyżwach, jazda na rolkach",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o39": "Piłka nożna",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o40": "Softball",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o41": "Squash",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o42": "Surfing, windsurfing",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o43": "Tenis stołowy",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o44": "Tenis",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o45": "Lekkoatletyka: wydarzenia terenowe",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o46": "Lekkoatletyka: konkurencje biegowe",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o47": "Triatlon",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o48": "Siatkówka",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o49": "Piłka wodna i pływanie",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o50": "Narciarstwo wodne",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o51": "Podnoszenie ciężarów, kulturystyka",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o52": "Zapasy",
  "patient_foot_ankle_function_q52_q2_a4_o1": "Ciężka praca fizyczna",
  "patient_foot_ankle_function_q52_q2_a4_o2": "Praca fizyczna",
  "patient_foot_ankle_function_q52_q2_a4_o3": "Zawody specjalne: tancerka baletowa, żołnierz zawodowy, ratownik specjalny, kaskader",
  "patient_foot_ankle_function_q52_q2_a4_o4": "Zdolny do chodzenia po każdym nierównym podłożu",
  "patient_foot_ankle_function_q52_q2_a4_o5": "Codzienne zajęcia nie są ograniczone",
  "patient_foot_ankle_function_q52_q2_a4_o6": "Zdolny do chodzenia po równym podłożu, wszystkie czynności są ograniczone",
  "patient_foot_ankle_quality_of_life": "Jakość życia",
  "patient_foot_ankle_quality_of_life_instructions": "Poniższe pytania dotyczą obaw społecznych, emocjonalnych i związanych ze stylem życia, które możesz odczuwać w związku z problemem stopy/kostki. Proszę, pomyśl o tym, jak się czułeś przez większość czasu w związku ze stopą/kostką.",
  "patient_foot_ankle_quality_of_life_q1": "Jak często jesteś świadomy swojego problemu ze stopą/kostką?",
  "patient_foot_ankle_quality_of_life_q1_a1": "Nigdy",
  "patient_foot_ankle_quality_of_life_q1_a2": "Raz w miesiącu",
  "patient_foot_ankle_quality_of_life_q1_a3": "Co tydzień",
  "patient_foot_ankle_quality_of_life_q1_a4": "Codziennie",
  "patient_foot_ankle_quality_of_life_q1_a5": "Stale",
  "patient_foot_ankle_quality_of_life_q2": "Czy zmodyfikowałeś swój styl życia, aby unikać potencjalnie szkodliwych czynności dla stopy/kostki?",
  "patient_foot_ankle_quality_of_life_q2_a1": "Zupełnie nie",
  "patient_foot_ankle_quality_of_life_q2_a2": "Nieznacznie",
  "patient_foot_ankle_quality_of_life_q2_a3": "Łagodnie",
  "patient_foot_ankle_quality_of_life_q2_a4": "Znacznie",
  "patient_foot_ankle_quality_of_life_q2_a5": "Całkowicie",
  "patient_foot_ankle_quality_of_life_q3": "Jak bardzo niepokoi Cię brak pewności w stopie/kostce?",
  "patient_foot_ankle_quality_of_life_q3_a1": "Zupełnie nie",
  "patient_foot_ankle_quality_of_life_q3_a2": "Nieznacznie",
  "patient_foot_ankle_quality_of_life_q3_a3": "Łagodnie",
  "patient_foot_ankle_quality_of_life_q3_a4": "Bardzo",
  "patient_foot_ankle_quality_of_life_q3_a5": "Niezwykle",
  "patient_foot_ankle_quality_of_life_q4": "Ogólnie rzecz biorąc, jak duże masz trudności ze stopą/kostką?",
  "patient_foot_ankle_quality_of_life_q4_a1": "Brak",
  "patient_foot_ankle_quality_of_life_q4_a2": "Łagodne",
  "patient_foot_ankle_quality_of_life_q4_a3": "Umiarkowane",
  "patient_foot_ankle_quality_of_life_q4_a4": "Duże",
  "patient_foot_ankle_quality_of_life_q4_a5": "Bardzo duże",
  "patient_foot_ankle_quality_of_life_q5": "Jak bardzo niespokojny/przygnębiony jesteś dzisiaj?",
  "patient_foot_ankle_quality_of_life_q5_a1": "Nie jestem niespokojny/depresyjny",
  "patient_foot_ankle_quality_of_life_q5_a2": "Nieznacznie",
  "patient_foot_ankle_quality_of_life_q5_a3": "Miernie",
  "patient_foot_ankle_quality_of_life_q5_a4": "Bardzo",
  "patient_foot_ankle_quality_of_life_q5_a5": "Niezwykle",
  "patient_foot_ankle_quality_of_life_q6": "Jak dobre/złe jest dzisiaj Twoje ZDROWIE? (100 oznacza najlepsze zdrowie, 0 oznacza najgorsze zdrowie, jakie możesz sobie wyobrazić)",
  "patient_foot_ankle_quality_of_life_q6_a1": "Najgorsze zdrowie",
  "patient_foot_ankle_quality_of_life_q6_a2": "Najlepsze zdrowie",
  "patient_foot_ankle_satisfaction": "Zadowolenie",
  "patient_foot_ankle_satisfaction_instruction": "Jak bardzo jesteś zadowolony z wyników operacji lub leczenia stopy/kostki?",
  "patient_foot_ankle_satisfaction_instruction_a1": "Bardzo nieusatysfakcjonowany",
  "patient_foot_ankle_satisfaction_instruction_a2": "Nieco niezadowolony",
  "patient_foot_ankle_satisfaction_instruction_a3": "W pewnym stopniu usatysfakcjonowany",
  "patient_foot_ankle_satisfaction_instruction_a4": "Bardzo zadowolony",
  "patient_foot_ankle_satisfaction_q1": "Za złagodzenie objawów?",
  "patient_foot_ankle_satisfaction_q1_not_applicable": "Nie dotyczy",
  "patient_foot_ankle_satisfaction_q1_a1": "Bardzo nieusatysfakcjonowany",
  "patient_foot_ankle_satisfaction_q1_a2": "Nieco niezadowolony",
  "patient_foot_ankle_satisfaction_q1_a3": "W pewnym stopniu usatysfakcjonowany",
  "patient_foot_ankle_satisfaction_q1_a4": "Bardzo zadowolony",
  "patient_foot_ankle_satisfaction_q2": "Za poprawę zdolności do wykonywania codziennych czynności.",
  "patient_foot_ankle_satisfaction_q2_not_applicable": "Nie dotyczy",
  "patient_foot_ankle_satisfaction_q2_a1": "Bardzo nieusatysfakcjonowany",
  "patient_foot_ankle_satisfaction_q2_a2": "Nieco niezadowolony",
  "patient_foot_ankle_satisfaction_q2_a3": "W pewnym stopniu usatysfakcjonowany",
  "patient_foot_ankle_satisfaction_q2_a4": "Bardzo zadowolony",
  "patient_foot_ankle_satisfaction_q3": "Za „zapomnienie” o problemie ze stopą/kostką? (świadomość problemu ze stopą/kostką)",
  "patient_foot_ankle_satisfaction_q3_not_applicable": "Nie dotyczy",
  "patient_foot_ankle_satisfaction_q3_a1": "Bardzo nieusatysfakcjonowany",
  "patient_foot_ankle_satisfaction_q3_a2": "Nieco niezadowolony",
  "patient_foot_ankle_satisfaction_q3_a3": "W pewnym stopniu usatysfakcjonowany",
  "patient_foot_ankle_satisfaction_q3_a4": "Bardzo zadowolony",
  "patient_foot_ankle_complete": "Koniec ankiety",
  "patient_foot_ankle_complete_text1": "Dziękujemy za poświęcenie czasu na wypełnienie tego formularza.",
  "patient_foot_ankle_complete_text2": "Kliknij przycisk Prześlij, aby zakończyć i zobaczyć swoje wyniki.",
  "patient_foot_ankle_complete_text3": "Twoje odpowiedzi będą traktowane poufnie, a wszelkie raporty będą wykorzystywać wyłącznie informacje pozbawione danych umożliwiających identyfikację.",
  "patient_foot_ankle_results": "Wyniki",
  "patient_foot_ankle_results_text1": "Poniżej znajdują się Twoje wyniki w zakresie bólu i funkcji stopy/kostki, które zostały obliczone na podstawie Twojego kwestionariusza.",
  "patient_foot_ankle_results_pain": "Ból",
  "patient_foot_ankle_results_function": "Funkcja",
  "patient_foot_ankle_results_overall_health_perception": "Ogólne postrzeganie zdrowia",
  "patient_foot_ankle_results_feeling_of_instability": "Uczucie niestabilności",
  "patient_foot_ankle_results_self_evaluation": "Samoocena punktacji stopy/kostki",
  "patient_foot_ankle_results_text2": "Twój lekarz będzie z tobą wkrótce.",
  "patient_elbow_pain": "Ból",
  "patient_elbow_pain_q1": "Czy odczuwasz ból w łokciu?",
  "patient_elbow_pain_q1_a1": "Nie",
  "patient_elbow_pain_q1_a2": "Tak",
  "patient_elbow_pain_q2": "Jaki jest najlepszy opis bólu łokcia?",
  "patient_elbow_pain_q2_a1": "Brak lub pomijalny",
  "patient_elbow_pain_q2_a2": "Łagodny, z aktywnością, sporadycznie używanie leków/środków przeciwbólowych",
  "patient_elbow_pain_q2_a3": "Umiarkowany, z aktywnością lub bez, codziennie stosuje leki przeciwbólowe",
  "patient_elbow_pain_q2_a4": "Umiarkowany w spoczynku lub w nocy",
  "patient_elbow_pain_q2_a5": "Ciężki w spoczynku, ciągłe przyjmowanie leków, kalectwo",
  "patient_elbow_pain_instruction": "Oceń swój BÓL w łokciu",
  "patient_elbow_pain_q3": "W tym momencie",
  "patient_elbow_pain_q3_a1": "Brak bólu",
  "patient_elbow_pain_q3_a2": "Najgorszy ból, nie do zniesienia",
  "patient_elbow_pain_q4": "Przeciętny ból występujący przez ostatnie 4 tygodnie",
  "patient_elbow_pain_q4_a1": "Brak bólu",
  "patient_elbow_pain_q4_a2": "Najgorszy ból, nie do zniesienia",
  "patient_elbow_pain_q5": "Najgorszy ból w ciągu ostatnich 4 tygodni",
  "patient_elbow_pain_q5_a1": "Brak bólu",
  "patient_elbow_pain_q5_a2": "Najgorszy ból, nie do zniesienia",
  "patient_elbow_pain_q6": "W spoczynku",
  "patient_elbow_pain_q6_a1": "Brak bólu",
  "patient_elbow_pain_q6_a2": "Najgorszy ból, nie do zniesienia",
  "patient_elbow_pain_q7": "Podnoszenie ciężkiego przedmiotu",
  "patient_elbow_pain_q7_a1": "Brak bólu",
  "patient_elbow_pain_q7_a2": "Najgorszy ból, nie do zniesienia",
  "patient_elbow_pain_q8": "Podczas wykonywania czynności z powtarzającymi się ruchami łokci",
  "patient_elbow_pain_q8_a1": "Brak bólu",
  "patient_elbow_pain_q8_a2": "Najgorszy ból, nie do zniesienia",
  "patient_elbow_pain_q9": "W nocy",
  "patient_elbow_pain_q9_a1": "Brak bólu",
  "patient_elbow_pain_q9_a2": "Najgorszy ból, nie do zniesienia",
  "patient_elbow_pain_q10": "Jak często w nocy dokuczał Ci ból łokcia w łóżku?",
  "patient_elbow_pain_q10_a1": "W ogóle",
  "patient_elbow_pain_q10_a2": "1-2 noce",
  "patient_elbow_pain_q10_a3": "Niektóre noce",
  "patient_elbow_pain_q10_a4": "Większość nocy",
  "patient_elbow_pain_q10_a5": "Każda noc",
  "patient_elbow_pain_q11": "Jak często ból łokcia zakłócał sen?",
  "patient_elbow_pain_q11_a1": "W ogóle",
  "patient_elbow_pain_q11_a2": "Sporadycznie",
  "patient_elbow_pain_q11_a3": "Czasami",
  "patient_elbow_pain_q11_a4": "Większość czasu",
  "patient_elbow_pain_q11_a5": "Cały czas",
  "patient_elbow_function": "Funkcja",
  "patient_elbow_function_q1": "Jaki jest najlepszy opis FUNKCJI Twojego łokcia?",
  "patient_elbow_function_q1_a1": "Żadnych ograniczeń",
  "patient_elbow_function_q1_a2": "Niewielkie ograniczenia, żadnych ograniczeń w czynnościach życia codziennego",
  "patient_elbow_function_q1_a3": "Nie można podnosić przedmiotów o wadze > 4,5 kg",
  "patient_elbow_function_q1_a4": "Umiarkowane ograniczenia w czynnościach życia codziennego",
  "patient_elbow_function_q1_a5": "Niezdolny czesać włosy ani dotykać głowy",
  "patient_elbow_function_q1_a6": "Niezdolny się wyżywić",
  "patient_elbow_function_q2": "Jaki jest najlepszy opis AKTYWNOŚCI, jaką możesz wykonać?",
  "patient_elbow_function_q2_a1": "Nieograniczone użycie łokcia",
  "patient_elbow_function_q2_a2": "Ograniczone wykorzystanie do celów rekreacyjnych",
  "patient_elbow_function_q2_a3": "Ogranicza się do działalności domowej i zawodowej",
  "patient_elbow_function_q2_a4": "Potrafi zadbać o siebie",
  "patient_elbow_function_q2_a5": "Brak aktywnoości",
  "patient_elbow_function_q3": "Jak długo możesz używać łokcia?",
  "patient_elbow_function_q3_a1": "Potrafi wykonywać czynności przez 30 minut",
  "patient_elbow_function_q3_a2": "Potrafi wykonywać czynności przez 15 minut",
  "patient_elbow_function_q3_a3": "Potrafi wykonywać czynności przez 5 minut",
  "patient_elbow_function_q3_a4": "Nie można używać łokcia",
  "patient_elbow_function_instruction": "Wskaż STOPIEŃ TRUDNOŚCI wykonania poniższych czynności w ostatnim tygodniu z powodu łokcia",
  "patient_elbow_function_instruction_0": "Żadnych trudności",
  "patient_elbow_function_instruction_1": "Łagodny",
  "patient_elbow_function_instruction_2": "Umiarkowany",
  "patient_elbow_function_instruction_3": "Ciężki : silny",
  "patient_elbow_function_instruction_4": "Niezdolny",
  "patient_elbow_function_q4": "Otwórz szczelny lub nowy słoik",
  "patient_elbow_function_q4_a1": "Żadnych trudności",
  "patient_elbow_function_q4_a2": "Łagodny",
  "patient_elbow_function_q4_a3": "Umiarkowany",
  "patient_elbow_function_q4_a4": "Ciężki : silny",
  "patient_elbow_function_q4_a5": "Niezdolny",
  "patient_elbow_function_q5": "Noszenie torby na zakupy lub teczki",
  "patient_elbow_function_q5_a1": "Żadnych trudności",
  "patient_elbow_function_q5_a2": "Łagodny",
  "patient_elbow_function_q5_a3": "Umiarkowany",
  "patient_elbow_function_q5_a4": "Ciężki : silny",
  "patient_elbow_function_q5_a5": "Niezdolny",
  "patient_elbow_function_q6": "Podnoszenie rzeczy w domu, na przykład wyrzucanie śmieci",
  "patient_elbow_function_q6_a1": "Żadnych trudności",
  "patient_elbow_function_q6_a2": "Łagodny",
  "patient_elbow_function_q6_a3": "Umiarkowany",
  "patient_elbow_function_q6_a4": "Ciężki : silny",
  "patient_elbow_function_q6_a5": "Niezdolny",
  "patient_elbow_function_q7": "Mycie pleców",
  "patient_elbow_function_q7_a1": "Żadnych trudności",
  "patient_elbow_function_q7_a2": "Łagodny",
  "patient_elbow_function_q7_a3": "Umiarkowany",
  "patient_elbow_function_q7_a4": "Ciężki : silny",
  "patient_elbow_function_q7_a5": "Niezdolny",
  "patient_elbow_function_q8": "Zarządzanie toaletą",
  "patient_elbow_function_q8_a1": "Żadnych trudności",
  "patient_elbow_function_q8_a2": "Łagodny",
  "patient_elbow_function_q8_a3": "Umiarkowany",
  "patient_elbow_function_q8_a4": "Ciężki : silny",
  "patient_elbow_function_q8_a5": "Niezdolny",
  "patient_elbow_function_q9": "Mycie całego ciała",
  "patient_elbow_function_q9_a1": "Żadnych trudności",
  "patient_elbow_function_q9_a2": "Łagodny",
  "patient_elbow_function_q9_a3": "Umiarkowany",
  "patient_elbow_function_q9_a4": "Ciężki : silny",
  "patient_elbow_function_q9_a5": "Niezdolny",
  "patient_elbow_function_q10": "Przestrzeganie higieny osobistej",
  "patient_elbow_function_q10_a1": "Żadnych trudności",
  "patient_elbow_function_q10_a2": "Łagodny",
  "patient_elbow_function_q10_a3": "Umiarkowany",
  "patient_elbow_function_q10_a4": "Ciężki : silny",
  "patient_elbow_function_q10_a5": "Niezdolny",
  "patient_elbow_function_q11": "Używanie noża do krojenia jedzenia",
  "patient_elbow_function_q11_a1": "Żadnych trudności",
  "patient_elbow_function_q11_a2": "Łagodny",
  "patient_elbow_function_q11_a3": "Umiarkowany",
  "patient_elbow_function_q11_a4": "Ciężki : silny",
  "patient_elbow_function_q11_a5": "Niezdolny",
  "patient_elbow_function_q12": "Jedzenie za pomocą sztućcy",
  "patient_elbow_function_q12_a1": "Żadnych trudności",
  "patient_elbow_function_q12_a2": "Łagodny",
  "patient_elbow_function_q12_a3": "Umiarkowany",
  "patient_elbow_function_q12_a4": "Ciężki : silny",
  "patient_elbow_function_q12_a5": "Niezdolny",
  "patient_elbow_function_q13": "Zajęcia rekreacyjne, podczas których nakładasz pewną siłę lub uderzenie na bark, ramię lub dłoń (np. golf, używanie młotka, tenis itp.)",
  "patient_elbow_function_q13_a1": "Żadnych trudności",
  "patient_elbow_function_q13_a2": "Łagodny",
  "patient_elbow_function_q13_a3": "Umiarkowany",
  "patient_elbow_function_q13_a4": "Ciężki : silny",
  "patient_elbow_function_q13_a5": "Niezdolny",
  "patient_elbow_function_q14": "Problemy ze spaniem z powodu bólu ramienia, barku lub dłoni",
  "patient_elbow_function_q14_a1": "Żadnych trudności",
  "patient_elbow_function_q14_a2": "Łagodny",
  "patient_elbow_function_q14_a3": "Umiarkowany",
  "patient_elbow_function_q14_a4": "Ciężki : silny",
  "patient_elbow_function_q14_a5": "Niezdolny",
  "patient_elbow_function_q15": "Zakładanie koszuli",
  "patient_elbow_function_q15_a1": "Żadnych trudności",
  "patient_elbow_function_q15_a2": "Łagodny",
  "patient_elbow_function_q15_a3": "Umiarkowany",
  "patient_elbow_function_q15_a4": "Ciężki : silny",
  "patient_elbow_function_q15_a5": "Niezdolny",
  "patient_elbow_function_q16": "Zapninanie guzików na koszuli",
  "patient_elbow_function_q16_a1": "Żadnych trudności",
  "patient_elbow_function_q16_a2": "Łagodny",
  "patient_elbow_function_q16_a3": "Umiarkowany",
  "patient_elbow_function_q16_a4": "Ciężki : silny",
  "patient_elbow_function_q16_a5": "Niezdolny",
  "patient_elbow_function_q17": "Ubieranie się",
  "patient_elbow_function_q17_a1": "Żadnych trudności",
  "patient_elbow_function_q17_a2": "Łagodny",
  "patient_elbow_function_q17_a3": "Umiarkowany",
  "patient_elbow_function_q17_a4": "Ciężki : silny",
  "patient_elbow_function_q17_a5": "Niezdolny",
  "patient_elbow_function_q18": "Czesać włosy",
  "patient_elbow_function_q18_a1": "Żadnych trudności",
  "patient_elbow_function_q18_a2": "Łagodny",
  "patient_elbow_function_q18_a3": "Umiarkowany",
  "patient_elbow_function_q18_a4": "Ciężki : silny",
  "patient_elbow_function_q18_a5": "Niezdolny",
  "patient_elbow_function_q19": "Załóż buty",
  "patient_elbow_function_q19_a1": "Żadnych trudności",
  "patient_elbow_function_q19_a2": "Łagodny",
  "patient_elbow_function_q19_a3": "Umiarkowany",
  "patient_elbow_function_q19_a4": "Ciężki : silny",
  "patient_elbow_function_q19_a5": "Niezdolny",
  "patient_elbow_function_q20": "Zawiązywanie butów",
  "patient_elbow_function_q20_a1": "Żadnych trudności",
  "patient_elbow_function_q20_a2": "Łagodny",
  "patient_elbow_function_q20_a3": "Umiarkowany",
  "patient_elbow_function_q20_a4": "Ciężki : silny",
  "patient_elbow_function_q20_a5": "Niezdolny",
  "patient_elbow_function_q21": "Noszenie ciężkiego przedmiotu",
  "patient_elbow_function_q21_a1": "Żadnych trudności",
  "patient_elbow_function_q21_a2": "Łagodny",
  "patient_elbow_function_q21_a3": "Umiarkowany",
  "patient_elbow_function_q21_a4": "Ciężki : silny",
  "patient_elbow_function_q21_a5": "Niezdolny",
  "patient_elbow_function_q22": "Wstań z krzesła, popychając się ramieniem",
  "patient_elbow_function_q22_a1": "Żadnych trudności",
  "patient_elbow_function_q22_a2": "Łagodny",
  "patient_elbow_function_q22_a3": "Umiarkowany",
  "patient_elbow_function_q22_a4": "Ciężki : silny",
  "patient_elbow_function_q22_a5": "Niezdolny",
  "patient_elbow_function_q23": "Wykonuj ciężkie prace domowe (np. myj ściany, podłogi)",
  "patient_elbow_function_q23_a1": "Żadnych trudności",
  "patient_elbow_function_q23_a2": "Łagodny",
  "patient_elbow_function_q23_a3": "Umiarkowany",
  "patient_elbow_function_q23_a4": "Ciężki : silny",
  "patient_elbow_function_q23_a5": "Niezdolny",
  "patient_elbow_function_q24": "Przekręć klucz",
  "patient_elbow_function_q24_a1": "Żadnych trudności",
  "patient_elbow_function_q24_a2": "Łagodny",
  "patient_elbow_function_q24_a3": "Umiarkowany",
  "patient_elbow_function_q24_a4": "Ciężki : silny",
  "patient_elbow_function_q24_a5": "Niezdolny",
  "patient_elbow_function_q25": "Rzuć piłkę",
  "patient_elbow_function_q25_a1": "Żadnych trudności",
  "patient_elbow_function_q25_a2": "Łagodny",
  "patient_elbow_function_q25_a3": "Umiarkowany",
  "patient_elbow_function_q25_a4": "Ciężki : silny",
  "patient_elbow_function_q25_a5": "Niezdolny",
  "patient_elbow_function_q26": "Wykonywanie zwykłej pracy z powodu bólu ramion, barków lub dłoni (praca obejmuje prowadzenie domu, jeśli jest to Twoja główna rola)",
  "patient_elbow_function_q26_a1": "Żadnych trudności",
  "patient_elbow_function_q26_a2": "Łagodny",
  "patient_elbow_function_q26_a3": "Umiarkowany",
  "patient_elbow_function_q26_a4": "Ciężki : silny",
  "patient_elbow_function_q26_a5": "Niezdolny",
  "patient_elbow_function_q27": "Określ typową pracę",
  "patient_elbow_function_q27_placeholder": "Określ pracę",
  "patient_elbow_function_i_donot_work": "ja nie pracuję",
  "patient_elbow_function_q28": "Używanie zwykłej techniki pracy",
  "patient_elbow_function_q28_a1": "Żadnych trudności",
  "patient_elbow_function_q28_a2": "Łagodny",
  "patient_elbow_function_q28_a3": "Umiarkowany",
  "patient_elbow_function_q28_a4": "Ciężki : silny",
  "patient_elbow_function_q28_a5": "Niezdolny",
  "patient_elbow_function_q29": "Wykonuj swoją pracę tak dobrze, jak chcesz",
  "patient_elbow_function_q29_a1": "Żadnych trudności",
  "patient_elbow_function_q29_a2": "Łagodny",
  "patient_elbow_function_q29_a3": "Umiarkowany",
  "patient_elbow_function_q29_a4": "Ciężki : silny",
  "patient_elbow_function_q29_a5": "Niezdolny",
  "patient_elbow_function_q30": "Spędzanie zwykłej ilości czasu na wykonywaniu swojej pracy",
  "patient_elbow_function_q30_a1": "Żadnych trudności",
  "patient_elbow_function_q30_a2": "Łagodny",
  "patient_elbow_function_q30_a3": "Umiarkowany",
  "patient_elbow_function_q30_a4": "Ciężki : silny",
  "patient_elbow_function_q30_a5": "Niezdolny",
  "patient_elbow_function_q31": "Gra na instrumencie muzycznym lub uprawianie sportu z powodu bólu ramion, barków lub dłoni",
  "patient_elbow_function_q31_a1": "Żadnych trudności",
  "patient_elbow_function_q31_a2": "Łagodny",
  "patient_elbow_function_q31_a3": "Umiarkowany",
  "patient_elbow_function_q31_a4": "Ciężki : silny",
  "patient_elbow_function_q31_a5": "Niezdolny",
  "patient_elbow_function_q32": "Określ sport lub instrument muzyczny, na którym grasz",
  "patient_elbow_function_q32_placeholder": "Określ sport/instrument muzyczny",
  "patient_elbow_function_i_donot_play": "Nie uprawiam żadnego sportu ani nie gram na żadnym instrumencie",
  "patient_elbow_function_q33": "Używanie zwykłej techniki gry na instrumencie lub uprawiania sportu",
  "patient_elbow_function_q33_a1": "Żadnych trudności",
  "patient_elbow_function_q33_a2": "Łagodne trudności",
  "patient_elbow_function_q33_a3": "Umiarkowane trudności",
  "patient_elbow_function_q33_a4": "Znaczne trudności",
  "patient_elbow_function_q33_a5": "Niezdolny",
  "patient_elbow_function_q34": "Gra na instrumencie muzycznym lub uprawianie sportu tak dobrze, jak chcesz",
  "patient_elbow_function_q34_a1": "Żadnych trudności",
  "patient_elbow_function_q34_a2": "Łagodne trudności",
  "patient_elbow_function_q34_a3": "Umiarkowane trudności",
  "patient_elbow_function_q34_a4": "Znaczne trudności",
  "patient_elbow_function_q34_a5": "Niezdolny",
  "patient_elbow_function_q35": "Spędzanie zwykłej ilości czasu na ćwiczeniu, grze na instrumencie lub sporcie",
  "patient_elbow_function_q35_a1": "Żadnych trudności",
  "patient_elbow_function_q35_a2": "Łagodne trudności",
  "patient_elbow_function_q35_a3": "Umiarkowane trudności",
  "patient_elbow_function_q35_a4": "Znaczne trudności",
  "patient_elbow_function_q35_a5": "Niezdolny",
  "patient_elbow_function_q36": "W jakim stopniu w ciągu ostatniego tygodnia problemy z ręką, barkiem lub dłonią zakłócały Twoje NORMALNE ZAJĘCIA SPOŁECZNE/CZAS WOLNY z rodziną, przyjaciółmi, sąsiadami ?",
  "patient_elbow_function_q36_a1": "Zupełnie nie",
  "patient_elbow_function_q36_a2": "Nieznacznie",
  "patient_elbow_function_q36_a3": "Miernie",
  "patient_elbow_function_q36_a4": "Trochę",
  "patient_elbow_function_q36_a5": "Znacznie",
  "patient_elbow_function_q37": "Czy w ciągu ostatniego tygodnia miałeś ograniczenia w PRACY LUB INNYCH REGULARNYCH CODZIENNYCH AKTYWNOŚCIACH z powodu problemów z ramionami, barkami lub rękami?",
  "patient_elbow_function_q37_a1": "Zupełnie nie",
  "patient_elbow_function_q37_a2": "Nieznacznie",
  "patient_elbow_function_q37_a3": "Miernie",
  "patient_elbow_function_q37_a4": "Trochę",
  "patient_elbow_function_q37_a5": "Niezwykle",
  "patient_elbow_symptoms": "Inne objawy",
  "patient_elbow_symptoms_instruction": "Proszę ocenić nasilenie następujących objawów w ostatnim tygodniu:",
  "patient_elbow_symptoms_instruction_0": "Żadnych",
  "patient_elbow_symptoms_instruction_1": "Łagodny",
  "patient_elbow_symptoms_instruction_2": "Umiarkowany",
  "patient_elbow_symptoms_instruction_3": "Ciężki : silny",
  "patient_elbow_symptoms_instruction_4": "Skrajny",
  "patient_elbow_symptoms_q1": "Ból ramienia, barku lub dłoni",
  "patient_elbow_symptoms_q1_a1": "Żadnych",
  "patient_elbow_symptoms_q1_a2": "Łagodny",
  "patient_elbow_symptoms_q1_a3": "Umiarkowany",
  "patient_elbow_symptoms_q1_a4": "Ciężki : silny",
  "patient_elbow_symptoms_q1_a5": "Skrajny",
  "patient_elbow_symptoms_q2": "Mrowienie w ramieniu, barku lub dłoni",
  "patient_elbow_symptoms_q2_a1": "Żadnych",
  "patient_elbow_symptoms_q2_a2": "Łagodny",
  "patient_elbow_symptoms_q2_a3": "Umiarkowany",
  "patient_elbow_symptoms_q2_a4": "Ciężki : silny",
  "patient_elbow_symptoms_q2_a5": "Skrajny",
  "patient_elbow_symptoms_q3": "Jak oceniłbyś dzisiaj swój łokieć jako procent normy? (0-100%, przy czym 100% to wartość normalna)",
  "patient_elbow_quality_of_life": "Jakość życia",
  "patient_elbow_quality_of_life_instruction": "Poniższe pytania dotyczą problemów społecznych, emocjonalnych i związanych ze stylem życia, które możesz odczuwać w związku z problemem łokcia. Proszę, pomyśl o tym, jak się czułeś przez większość czasu w związku ze swoim łokciem.",
  "patient_elbow_quality_of_life_q1": "Czy w ciągu ostatnich 4 tygodni czułeś, że problem z łokciem „kontroluje Twoje życie”?",
  "patient_elbow_quality_of_life_q1_a1": "Zupełnie nie",
  "patient_elbow_quality_of_life_q1_a2": "Sporadycznie",
  "patient_elbow_quality_of_life_q1_a3": "Kilka dni",
  "patient_elbow_quality_of_life_q1_a4": "Większość dni",
  "patient_elbow_quality_of_life_q1_a5": "Codziennie",
  "patient_elbow_quality_of_life_q2": "Jak często w ciągu ostatnich 4 tygodni łokieć „chodził ci po głowie”?",
  "patient_elbow_quality_of_life_q2_a1": "Zupełnie nie",
  "patient_elbow_quality_of_life_q2_a2": "Czasami",
  "patient_elbow_quality_of_life_q2_a3": "Całkiem często",
  "patient_elbow_quality_of_life_q2_a4": "Większość czasu",
  "patient_elbow_quality_of_life_q2_a5": "Cały czas",
  "patient_elbow_quality_of_life_instruction1": "Oceń poniższe, aby najlepiej opisać Twoje ZDROWIE DZISIAJ:",
  "patient_elbow_quality_of_life_instruction1_0": "Bez problemu",
  "patient_elbow_quality_of_life_instruction1_1": "Niewielki",
  "patient_elbow_quality_of_life_instruction1_2": "Umiarkowany",
  "patient_elbow_quality_of_life_instruction1_3": "Ciężki : silny",
  "patient_elbow_quality_of_life_instruction1_4": "Ekstremalne/niemożliwe",
  "patient_elbow_quality_of_life_q3": "Problem z chodzeniem",
  "patient_elbow_quality_of_life_q3_a1": "Bez problemu",
  "patient_elbow_quality_of_life_q3_a2": "Niewielki",
  "patient_elbow_quality_of_life_q3_a3": "Umiarkowany",
  "patient_elbow_quality_of_life_q3_a4": "Ciężki : silny",
  "patient_elbow_quality_of_life_q3_a5": "Niezdolny",
  "patient_elbow_quality_of_life_q4": "Problem z samodzielnym myciem i ubieraniem się",
  "patient_elbow_quality_of_life_q4_a1": "Bez problemu",
  "patient_elbow_quality_of_life_q4_a2": "Niewielki",
  "patient_elbow_quality_of_life_q4_a3": "Umiarkowany",
  "patient_elbow_quality_of_life_q4_a4": "Ciężki : silny",
  "patient_elbow_quality_of_life_q4_a5": "Niezdolny",
  "patient_elbow_quality_of_life_q5": "Problem z wykonywaniem zwykłych czynności (np. pracy, nauki, prac domowych, rodzinnych aktywności lub zajęć rekreacyjnych)",
  "patient_elbow_quality_of_life_q5_a1": "Bez problemu",
  "patient_elbow_quality_of_life_q5_a2": "Niewielki",
  "patient_elbow_quality_of_life_q5_a3": "Umiarkowany",
  "patient_elbow_quality_of_life_q5_a4": "Ciężki : silny",
  "patient_elbow_quality_of_life_q5_a5": "Niezdolny",
  "patient_elbow_quality_of_life_q6": "Ból lub dyskomfort",
  "patient_elbow_quality_of_life_q6_a1": "Żadnych",
  "patient_elbow_quality_of_life_q6_a2": "Niewielki",
  "patient_elbow_quality_of_life_q6_a3": "Umiarkowany",
  "patient_elbow_quality_of_life_q6_a4": "Ciężki : silny",
  "patient_elbow_quality_of_life_q6_a5": "Skrajny",
  "patient_elbow_quality_of_life_q7": "Lęk/Depresja",
  "patient_elbow_quality_of_life_q7_a1": "Nie jestem niespokojny/depresyjny",
  "patient_elbow_quality_of_life_q7_a2": "Lekko niespokojny/przygnębiony",
  "patient_elbow_quality_of_life_q7_a3": "Umiarkowanie niespokojny/depresyjny",
  "patient_elbow_quality_of_life_q7_a4": "Silny niepokój/depresja",
  "patient_elbow_quality_of_life_q7_a5": "Bardzo niespokojny/depresyjny",
  "patient_elbow_quality_of_life_q8": "Jak dobre/złe jest Twoje zdrowie DZIŚ?",
  "patient_elbow_quality_of_life_q8_instruction": "100 oznacza najlepsze zdrowie, 0 oznacza najgorsze zdrowie, jakie możesz sobie wyobrazić",
  "patient_elbow_quality_of_life_q8_a1": "Najgorsze",
  "patient_elbow_quality_of_life_q8_a2": "Najlepsze",
  "patient_elbow_satisfaction": "Zadowolenie",
  "patient_elbow_satisfaction_q1": "Czy jesteś zadowolony z leczenia łokcia?",
  "patient_elbow_satisfaction_na": "Nie dotyczy",
  "patient_elbow_satisfaction_q1_a1": "Wcale nie zadowolony",
  "patient_elbow_satisfaction_q1_a2": "Bardzo zadowolony",
  "patient_elbow_complete": "Koniec ankiety",
  "patient_elbow_complete_text1": "Dziękujemy za poświęcenie czasu na wypełnienie tego formularza.",
  "patient_elbow_complete_text2": "Kliknij przycisk Prześlij, aby zakończyć i zobaczyć swoje wyniki.",
  "patient_elbow_complete_text3": "Twoje odpowiedzi będą traktowane poufnie, a wszelkie raporty będą wykorzystywać wyłącznie informacje pozbawione danych umożliwiających identyfikację",
  "patient_elbow_results": "Wyniki",
  "patient_elbow_results_text1": "Poniżej znajdują się Twoje wyniki w zakresie bólu i funkcji łokcia, które zostały obliczone na podstawie Twojego kwestionariusza.",
  "patient_elbow_results_function": "Funkcjonowanie",
  "patient_elbow_results_pain": "Ból",
  "patient_elbow_results_health_perception": "Ogólne postrzeganie zdrowia",
  "patient_elbow_results_self_evaluation": "Samoocena punktacji łokcia",
  "patient_elbow_results_text4": "Twój lekarz będzie z tobą wkrótce.",
  "patient_hand_pain": "Ból",
  "patient_hand_pain_q1": "Opisz ból dłoni/nadgarstka w OSTATNIM TYGODNIU",
  "patient_hand_pain_q1_a1": "Żadny lub bardzo łagodny",
  "patient_hand_pain_q1_a2": "Łagodny",
  "patient_hand_pain_q1_a3": "Umiarkowany",
  "patient_hand_pain_q1_a4": "Ciężki : silny",
  "patient_hand_pain_q1_a5": "Bardzo poważny/ekstremalny",
  "patient_hand_pain_q2": "Jak silny jest ból w Twojej dłoni/nadgarstku W TEJ CHWILI?",
  "patient_hand_pain_q2_a1": "Bez bólu",
  "patient_hand_pain_q2_a2": "Najgorszy ból",
  "patient_hand_pain_q3": "Jak często ból dłoni/nadgarstka zakłócał Twoje codzienne czynności (takie jak jedzenie lub kąpiel) w ciągu ostatniego tygodnia?",
  "patient_hand_pain_q3_a1": "Nigdy",
  "patient_hand_pain_q3_a2": "Rzadko",
  "patient_hand_pain_q3_a3": "Czasami",
  "patient_hand_pain_q3_a4": "Często",
  "patient_hand_pain_q3_a5": "Zawsze",
  "patient_hand_symptoms": "Inne objawy",
  "patient_hand_symptoms_q1": "Jak silne było mrowienie w ramieniu, barku lub dłoni w ciągu ostatniego tygodnia?",
  "patient_hand_symptoms_q1_a1": "Żadnych",
  "patient_hand_symptoms_q1_a2": "Łagodne",
  "patient_hand_symptoms_q1_a3": "Umiarkowane",
  "patient_hand_symptoms_q1_a4": "Silne",
  "patient_hand_symptoms_q1_a5": "Bardzo silne",
  "patient_hand_symptoms_q2": "Jakie było czucie w Twojej dłoni w ciągu ostatniego tygodnia?",
  "patient_hand_symptoms_q2_a1": "Bardzo dobre",
  "patient_hand_symptoms_q2_a2": "Dobre",
  "patient_hand_symptoms_q2_a3": "W porządku",
  "patient_hand_symptoms_q2_a4": "Słabe",
  "patient_hand_symptoms_q2_a5": "Bardzo słabe",
  "patient_hand_symptoms_q3": "Wygląd mojej dłoni utrudnia mi normalne, codzienne czynności",
  "patient_hand_symptoms_q3_a1": "Kategorycznie się nie zgadzam",
  "patient_hand_symptoms_q3_a2": "Nie zgadzam się",
  "patient_hand_symptoms_q3_a3": "Ani się zgadzam, ani się nie zgadzam",
  "patient_hand_symptoms_q3_a4": "Zgadzam się",
  "patient_hand_symptoms_q3_a5": "Stanowczo się zgadzam",
  "patient_hand_symptoms_q3_a6": "Jak oceniłbyś swoją rękę/nadgarstek dzisiaj jako procent normy? (0-100%, przy czym 100% to wartość normalna)",
  "patient_hand_symptoms_q3_a6_normal": "Normalna",
  "patient_hand_function": "Funkcjonalna",
  "patient_hand_function_instruction1": "Jeśli nie wykonasz jakiegoś zadania, proszę oszacuj trudność, z jaką będziesz miał je wykonać.",
  "patient_hand_function_instruction2": "Jeśli nie jesteś pewien, jak odpowiedzieć na pytanie, podaj najlepszą odpowiedź, jaką możesz.",
  "patient_hand_function_q1": "Ogólnie rzecz biorąc, jak dobrze sprawowała się Twoja ręka w ciągu ostatniego tygodnia?",
  "patient_hand_function_q1_a1": "Bardzo dobrze",
  "patient_hand_function_q1_a2": "Dobrze",
  "patient_hand_function_q1_a3": "W porządku",
  "patient_hand_function_q1_a4": "Słabo",
  "patient_hand_function_q1_a5": "Bardzo słabo",
  "patient_hand_function_q2": "Jak często NIE MOŻESZ WYKONAĆ PRACY z powodu problemów z ręką/nadgarstkiem?",
  "patient_hand_function_q2_a1": "Nigdy",
  "patient_hand_function_q2_a2": "Rzadko",
  "patient_hand_function_q2_a3": "Czasami",
  "patient_hand_function_q2_a4": "Często",
  "patient_hand_function_q2_a5": "Zawsze",
  "patient_hand_function_q3": "Jak często wykonywanie zadań w pracy zajmowało Ci DŁUŻEJ z powodu problemów z ręką/nadgarstkiem?",
  "patient_hand_function_q3_a1": "Nigdy",
  "patient_hand_function_q3_a2": "Rzadko",
  "patient_hand_function_q3_a3": "Czasami",
  "patient_hand_function_q3_a4": "Często",
  "patient_hand_function_q3_a5": "Zawsze",
  "patient_hand_function_instruction": "Wskaż STOPIEŃ TRUDNOŚCI wykonania poniższych czynności w ostatnim tygodniu ze względu na problemy z ręką/nadgarstkiem",
  "patient_hand_function_instruction_0": "Żadnych trudności",
  "patient_hand_function_instruction_1": "Łagodne trudności",
  "patient_hand_function_instruction_2": "Umiarkowany poziom trudności",
  "patient_hand_function_instruction_3": "Poważne trudności",
  "patient_hand_function_instruction_4": "Ekstremalna trudność/niemożność",
  "patient_hand_function_q4": "Otwieranie szczelnego lub nowego słoika",
  "patient_hand_function_q4_a1": "Żadnych trudności",
  "patient_hand_function_q4_a2": "Łagodne trudności",
  "patient_hand_function_q4_a3": "Umiarkowany poziom trudności",
  "patient_hand_function_q4_a4": "Poważne trudności",
  "patient_hand_function_q4_a5": "Ekstremalna trudność/niemożność",
  "patient_hand_function_q5": "Powstrzymaj palący ból",
  "patient_hand_function_q5_a1": "Żadnych trudności",
  "patient_hand_function_q5_a2": "Łagodne trudności",
  "patient_hand_function_q5_a3": "Umiarkowany poziom trudności",
  "patient_hand_function_q5_a4": "Poważne trudności",
  "patient_hand_function_q5_a5": "Ekstremalna trudność/niemożność",
  "patient_hand_function_q6": "Zapinanie koszulki lub bluzki",
  "patient_hand_function_q6_a1": "Żadnych trudności",
  "patient_hand_function_q6_a2": "Łagodne trudności",
  "patient_hand_function_q6_a3": "Umiarkowany poziom trudności",
  "patient_hand_function_q6_a4": "Poważne trudności",
  "patient_hand_function_q6_a5": "Ekstremalna trudność/niemożność",
  "patient_hand_function_q7": "Wykonywanie ciężkich prac domowych (np. mycie ścian, podłóg)",
  "patient_hand_function_q7_a1": "Żadnych trudności",
  "patient_hand_function_q7_a2": "Łagodne trudności",
  "patient_hand_function_q7_a3": "Umiarkowany poziom trudności",
  "patient_hand_function_q7_a4": "Poważne trudności",
  "patient_hand_function_q7_a5": "Ekstremalna trudność/niemożność",
  "patient_hand_function_q8": "Noszenie torby na zakupy lub teczki",
  "patient_hand_function_q8_a1": "Żadnych trudności",
  "patient_hand_function_q8_a2": "Łagodne trudności",
  "patient_hand_function_q8_a3": "Umiarkowany poziom trudności",
  "patient_hand_function_q8_a4": "Poważne trudności",
  "patient_hand_function_q8_a5": "Ekstremalna trudność/niemożność",
  "patient_hand_function_q9": "Mycie pleców",
  "patient_hand_function_q9_a1": "Żadnych trudności",
  "patient_hand_function_q9_a2": "Łagodne trudności",
  "patient_hand_function_q9_a3": "Umiarkowany poziom trudności",
  "patient_hand_function_q9_a4": "Poważne trudności",
  "patient_hand_function_q9_a5": "Ekstremalna trudność/niemożność",
  "patient_hand_function_q10": "Używanie noża do krojenia jedzenia",
  "patient_hand_function_q10_a1": "Żadnych trudności",
  "patient_hand_function_q10_a2": "Łagodne trudności",
  "patient_hand_function_q10_a3": "Umiarkowany poziom trudności",
  "patient_hand_function_q10_a4": "Poważne trudności",
  "patient_hand_function_q10_a5": "Ekstremalna trudność/niemożność",
  "patient_hand_function_q11": "Zajęcia rekreacyjne, podczas których przyjmujesz pewną siłę lub uderzenie przez ramię, ramię lub dłoń (np. golf,uderzanie młotkiem, tenis itp.)",
  "patient_hand_function_q11_a1": "Żadnych trudności",
  "patient_hand_function_q11_a2": "Łagodne trudności",
  "patient_hand_function_q11_a3": "Umiarkowany poziom trudności",
  "patient_hand_function_q11_a4": "Poważne trudności",
  "patient_hand_function_q11_a5": "Ekstremalna trudność/niemożność",
  "patient_hand_function_q12": "Spanie z powodu bólu ramienia, barku lub dłoni",
  "patient_hand_function_q12_a1": "Żadnych trudności",
  "patient_hand_function_q12_a2": "Łagodne trudności",
  "patient_hand_function_q12_a3": "Umiarkowany poziom trudności",
  "patient_hand_function_q12_a4": "Poważne trudności",
  "patient_hand_function_q12_a5": "Ekstremalna trudność/niemożność",
  "patient_hand_function_q13": "Wykonywanie zwykłej pracy z powodu bólu ramion, barków lub dłoni (praca obejmuje prowadzenie domu, jeśli jest to Twoja główna rola)",
  "patient_hand_function_q13_a1": "Żadnych trudności",
  "patient_hand_function_q13_a2": "Łagodne trudności",
  "patient_hand_function_q13_a3": "Umiarkowany poziom trudności",
  "patient_hand_function_q13_a4": "Poważne trudności",
  "patient_hand_function_q13_a5": "Ekstremalna trudność/niemożność",
  "patient_hand_function_q14": "Określ typową pracę",
  "patient_hand_function_q14_placeholder": "Określ pracę",
  "patient_hand_function_do_dot_work": "ja nie pracuję",
  "patient_hand_function_q15": "Używanie zwykłej techniki pracy",
  "patient_hand_function_q15_a1": "Żadnych trudności",
  "patient_hand_function_q15_a2": "Łagodne trudności",
  "patient_hand_function_q15_a3": "Umiarkowany poziom trudności",
  "patient_hand_function_q15_a4": "Poważne trudności",
  "patient_hand_function_q15_a5": "Ekstremalna trudność/niemożność",
  "patient_hand_function_q16": "Wykonywanie swojej pracy tak dobrze, jak chcesz",
  "patient_hand_function_q16_a1": "Żadnych trudności",
  "patient_hand_function_q16_a2": "Łagodne trudności",
  "patient_hand_function_q16_a3": "Umiarkowany poziom trudności",
  "patient_hand_function_q16_a4": "Poważne trudności",
  "patient_hand_function_q16_a5": "Ekstremalna trudność/niemożność",
  "patient_hand_function_q17": "Spędzanie zwykłej ilości czasu na wykonywaniu swojej pracy",
  "patient_hand_function_q17_a1": "Żadnych trudności",
  "patient_hand_function_q17_a2": "Łagodne trudności",
  "patient_hand_function_q17_a3": "Umiarkowany poziom trudności",
  "patient_hand_function_q17_a4": "Poważne trudności",
  "patient_hand_function_q17_a5": "Ekstremalna trudność/niemożność",
  "patient_hand_function_q18": "Gra na instrumencie muzycznym lub uprawianie sportu z powodu bólu ramion, barków lub dłoni",
  "patient_hand_function_q18_a1": "Żadnych trudności",
  "patient_hand_function_q18_a2": "Łagodne trudności",
  "patient_hand_function_q18_a3": "Umiarkowany poziom trudności",
  "patient_hand_function_q18_a4": "Poważne trudności",
  "patient_hand_function_q18_a5": "Ekstremalna trudność/niemożność",
  "patient_hand_function_q19": "Określ sport lub instrument muzyczny, na którym grasz",
  "patient_hand_function_q19_placeholder": "Określ instrument sportowy/muzyczny",
  "patient_hand_function_donot_play": "Nie uprawiam żadnego sportu ani nie gram na żadnym instrumencie",
  "patient_hand_function_20": "Używanie twojej zwykłej techniki gry na instrumencie lub uprawianie sportu",
  "patient_hand_function_q20_a1": "Żadnych trudności",
  "patient_hand_function_q20_a2": "Łagodne trudności",
  "patient_hand_function_q20_a3": "Umiarkowany poziom trudności",
  "patient_hand_function_q20_a4": "Poważne trudności",
  "patient_hand_function_q20_a5": "Ekstremalna trudność/niemożność",
  "patient_hand_function_q21": "Granie na instrumencie muzycznym lub uprawianie sportu tak dobrze, jak chcesz",
  "patient_hand_function_q21_a1": "Żadnych trudności",
  "patient_hand_function_q21_a2": "Łagodne trudności",
  "patient_hand_function_q21_a3": "Umiarkowany poziom trudności",
  "patient_hand_function_q21_a4": "Poważne trudności",
  "patient_hand_function_q21_a5": "Ekstremalna trudność/niemożność",
  "patient_hand_function_q22": "Spędzanie zwykłej ilości czasu na ćwiczeniu lub grze na instrumencie lub sporcie",
  "patient_hand_function_q22_a1": "Żadnych trudności",
  "patient_hand_function_q22_a2": "Łagodne trudności",
  "patient_hand_function_q22_a3": "Umiarkowany poziom trudności",
  "patient_hand_function_q22_a4": "Poważne trudności",
  "patient_hand_function_q22_a5": "Ekstremalna trudność/niemożność",
  "patient_hand_function_q23": "W jakim stopniu w ciągu ostatniego tygodnia problemy z ręką, barkiem lub dłonią zakłócały Twoje ZAJĘCIA SPOŁECZNE/CZAS WOLNY z rodziną, przyjaciółmi, sąsiadami lub grupami?",
  "patient_hand_function_q23_a1": "Zupełnie nie",
  "patient_hand_function_q23_a2": "Nieznacznie",
  "patient_hand_function_q23_a3": "Trochę",
  "patient_hand_function_q23_a4": "Znacznie",
  "patient_hand_function_q23_a5": "Bardzo znacznie",
  "patient_hand_function_q24": "Czy w ciągu ostatniego tygodnia miałeś ograniczenia w PRACY LUB INNYCH REGULARNYCH CODZIENNYCH CZYNNOŚCIACH z powodu problemów z ramionami, barkami lub dłońmi?",
  "patient_hand_function_q24_a1": "Zupełnie nie",
  "patient_hand_function_q24_a2": "Nieznacznie",
  "patient_hand_function_q24_a3": "Trohę",
  "patient_hand_function_q24_a4": "Znacznie",
  "patient_hand_function_q24_a5": "Bardzo znacznie",
  "patient_hand_questionnaire": "Kwestionariusz Bostońskiego zespołu cieśni nadgarstka",
  "patient_hand_questionnaire_symptom": "Objawy",
  "patient_hand_questionnaire_symptom_instruction": "Wybierz odpowiedź, która najlepiej odpowiada Twoim objawom.",
  "patient_hand_questionnaire_symptom_instruction_0": "normalne",
  "patient_hand_questionnaire_symptom_instruction_1": "niewielkie",
  "patient_hand_questionnaire_symptom_instruction_2": "średni",
  "patient_hand_questionnaire_symptom_instruction_3": "ciężkie",
  "patient_hand_questionnaire_symptom_instruction_4": "bardzo poważne",
  "patient_hand_questionnaire_symptom_q1": "Jak silny jest ból dłoni lub nadgarstka występujący w nocy?",
  "patient_hand_questionnaire_symptom_q1_a1": "Normalny",
  "patient_hand_questionnaire_symptom_q1_a2": "Niewielki",
  "patient_hand_questionnaire_symptom_q1_a3": "Średni",
  "patient_hand_questionnaire_symptom_q1_a4": "Silny",
  "patient_hand_questionnaire_symptom_q1_a5": "Bardzo poważny",
  "patient_hand_questionnaire_symptom_q2": "Jak często w ciągu ostatnich 2 tygodni budził Cię ból dłoni lub nadgarstka podczas typowej nocy?",
  "patient_hand_questionnaire_symptom_q2_a1": "Normalnie",
  "patient_hand_questionnaire_symptom_q2_a2": "Raz",
  "patient_hand_questionnaire_symptom_q2_a3": "2-3 razy",
  "patient_hand_questionnaire_symptom_q2_a4": "4-5 razy",
  "patient_hand_questionnaire_symptom_q2_a5": "Więcej niż 5 razy",
  "patient_hand_questionnaire_symptom_q3": "Czy zazwyczaj odczuwasz ból dłoni lub nadgarstka w ciągu dnia?",
  "patient_hand_questionnaire_symptom_q3_a1": "Nie odczuwam",
  "patient_hand_questionnaire_symptom_q3_a2": "Niewielki",
  "patient_hand_questionnaire_symptom_q3_a3": "Średni",
  "patient_hand_questionnaire_symptom_q3_a4": "Silny",
  "patient_hand_questionnaire_symptom_q3_a5": "Bardzo poważny",
  "patient_hand_questionnaire_symptom_q4": "Jak często odczuwasz ból dłoni lub nadgarstka w ciągu dnia?",
  "patient_hand_questionnaire_symptom_q4_a1": "Normalna",
  "patient_hand_questionnaire_symptom_q4_a2": "1-2 razy dziennie",
  "patient_hand_questionnaire_symptom_q4_a3": "3-5 razy dziennie",
  "patient_hand_questionnaire_symptom_q4_a4": "Więcej niż 5 razy dziennie",
  "patient_hand_questionnaire_symptom_q4_a5": "Nieprzerwany",
  "patient_hand_questionnaire_symptom_q5": "Jak długo średnio trwa epizod bólu w ciągu dnia?",
  "patient_hand_questionnaire_symptom_q5_a1": "Normalna",
  "patient_hand_questionnaire_symptom_q5_a2": "<10 minut",
  "patient_hand_questionnaire_symptom_q5_a3": "Kontynuowano 10-60 minut",
  "patient_hand_questionnaire_symptom_q5_a4": "> 60 minut",
  "patient_hand_questionnaire_symptom_q5_a5": "Nieprzerwany",
  "patient_hand_questionnaire_symptom_q6": "Czy odczuwasz drętwienie (utratę czucia) w dłoni?",
  "patient_hand_questionnaire_symptom_q6_a1": "Normalne",
  "patient_hand_questionnaire_symptom_q6_a2": "Niewielkie",
  "patient_hand_questionnaire_symptom_q6_a3": "Średnie",
  "patient_hand_questionnaire_symptom_q6_a4": "Silne",
  "patient_hand_questionnaire_symptom_q6_a5": "Bardzo poważne",
  "patient_hand_questionnaire_symptom_q7": "Czy masz osłabienie w dłoni lub nadgarstku?",
  "patient_hand_questionnaire_symptom_q7_a1": "Normalne",
  "patient_hand_questionnaire_symptom_q7_a2": "Niewielkie",
  "patient_hand_questionnaire_symptom_q7_a3": "Średnie",
  "patient_hand_questionnaire_symptom_q7_a4": "Silne",
  "patient_hand_questionnaire_symptom_q7_a5": "Bardzo poważne",
  "patient_hand_questionnaire_symptom_q8": "Czy odczuwasz mrowienie w dłoni?",
  "patient_hand_questionnaire_symptom_q8_a1": "Normalne",
  "patient_hand_questionnaire_symptom_q8_a2": "Niewielkie",
  "patient_hand_questionnaire_symptom_q8_a3": "Średnie",
  "patient_hand_questionnaire_symptom_q8_a4": "Silne",
  "patient_hand_questionnaire_symptom_q8_a5": "Bardzo poważne",
  "patient_hand_questionnaire_symptom_q9": "Jak poważne jest drętwienie (utrata czucia) lub mrowienie w nocy?",
  "patient_hand_questionnaire_symptom_q9_a1": "Normalne",
  "patient_hand_questionnaire_symptom_q9_a2": "Niewielkie",
  "patient_hand_questionnaire_symptom_q9_a3": "Średnie",
  "patient_hand_questionnaire_symptom_q9_a4": "Silne",
  "patient_hand_questionnaire_symptom_q9_a5": "Bardzo poważne",
  "patient_hand_questionnaire_symptom_q10": "Jak często w ciągu ostatnich 2 tygodni budziło Cię drętwienie lub mrowienie dłoni podczas nocy?",
  "patient_hand_questionnaire_symptom_q10_a1": "Normalnie",
  "patient_hand_questionnaire_symptom_q10_a2": "Raz",
  "patient_hand_questionnaire_symptom_q10_a3": "2-3 razy",
  "patient_hand_questionnaire_symptom_q10_a4": "4-5 razy",
  "patient_hand_questionnaire_symptom_q10_a5": "Więcej niż 5 razy",
  "patient_hand_questionnaire_function": "Funkcjonować",
  "patient_hand_questionnaire_function_instructions": "Oceń stopień trudności w wykonywaniu następujących czynności ze względu na objawy:",
  "patient_hand_questionnaire_function_instructions_0": "Żadnych trudności",
  "patient_hand_questionnaire_function_instructions_1": "Mała trudność",
  "patient_hand_questionnaire_function_instructions_2": "Umiarkowany poziom trudności",
  "patient_hand_questionnaire_function_instructions_3": "Intensywny poziom trudności",
  "patient_hand_questionnaire_function_instructions_4": "Brak możliwości wykonania",
  "patient_hand_questionnaire_function_q1": "Trudności w chwytaniu i używaniu małych przedmiotów, takich jak klucze lub długopisy",
  "patient_hand_questionnaire_function_q1_a1": "Żadnych trudności",
  "patient_hand_questionnaire_function_q1_a2": "Mała trudność",
  "patient_hand_questionnaire_function_q1_a3": "Umiarkowany poziom trudności",
  "patient_hand_questionnaire_function_q1_a4": "Intensywny poziom trudności",
  "patient_hand_questionnaire_function_q1_a5": "Brak możliwości wykonania",
  "patient_hand_questionnaire_function_q2": "Pismo",
  "patient_hand_questionnaire_function_q2_a1": "Żadnych trudności",
  "patient_hand_questionnaire_function_q2_a2": "Mała trudność",
  "patient_hand_questionnaire_function_q2_a3": "Umiarkowany poziom trudności",
  "patient_hand_questionnaire_function_q2_a4": "Intensywny poziom trudności",
  "patient_hand_questionnaire_function_q2_a5": "Brak możliwości wykonania",
  "patient_hand_questionnaire_function_q3": "Zapinanie ubrań",
  "patient_hand_questionnaire_function_q3_a1": "Żadnych trudności",
  "patient_hand_questionnaire_function_q3_a2": "Mała trudność",
  "patient_hand_questionnaire_function_q3_a3": "Umiarkowany poziom trudności",
  "patient_hand_questionnaire_function_q3_a4": "Intensywny poziom trudności",
  "patient_hand_questionnaire_function_q3_a5": "Brak możliwości wykonania",
  "patient_hand_questionnaire_function_q4": "Trzymanie książki podczas czytania",
  "patient_hand_questionnaire_function_q4_a1": "Żadnych trudności",
  "patient_hand_questionnaire_function_q4_a2": "Mała trudność",
  "patient_hand_questionnaire_function_q4_a3": "Umiarkowany poziom trudności",
  "patient_hand_questionnaire_function_q4_a4": "Intensywny poziom trudności",
  "patient_hand_questionnaire_function_q4_a5": "Brak możliwości wykonania",
  "patient_hand_questionnaire_function_q5": "Chwytanie za rączkę telefonu",
  "patient_hand_questionnaire_function_q5_a1": "Żadnych trudności",
  "patient_hand_questionnaire_function_q5_a2": "Mała trudność",
  "patient_hand_questionnaire_function_q5_a3": "Umiarkowany poziom trudności",
  "patient_hand_questionnaire_function_q5_a4": "Intensywny poziom trudności",
  "patient_hand_questionnaire_function_q5_a5": "Brak możliwości wykonania",
  "patient_hand_questionnaire_function_q6": "Otwieranie słoików",
  "patient_hand_questionnaire_function_q6_a1": "Żadnych trudności",
  "patient_hand_questionnaire_function_q6_a2": "Mała trudność",
  "patient_hand_questionnaire_function_q6_a3": "Umiarkowany poziom trudności",
  "patient_hand_questionnaire_function_q6_a4": "Intensywny poziom trudności",
  "patient_hand_questionnaire_function_q6_a5": "Brak możliwości wykonania",
  "patient_hand_questionnaire_function_q7": "Obowiązki domowe",
  "patient_hand_questionnaire_function_q7_a1": "Żadnych trudności",
  "patient_hand_questionnaire_function_q7_a2": "Mała trudność",
  "patient_hand_questionnaire_function_q7_a3": "Umiarkowany poziom trudności",
  "patient_hand_questionnaire_function_q7_a4": "Intensywny poziom trudności",
  "patient_hand_questionnaire_function_q7_a5": "Brak możliwości wykonania",
  "patient_hand_questionnaire_function_q8": "Noszenie koszyka z zakupami",
  "patient_hand_questionnaire_function_q8_a1": "Żadnych trudności",
  "patient_hand_questionnaire_function_q8_a2": "Mała trudność",
  "patient_hand_questionnaire_function_q8_a3": "Umiarkowany poziom trudności",
  "patient_hand_questionnaire_function_q8_a4": "Intensywny poziom trudności",
  "patient_hand_questionnaire_function_q8_a5": "Brak możliwości wykonania",
  "patient_hand_questionnaire_function_q9": "Kąpiel i ubieranie",
  "patient_hand_questionnaire_function_q9_a1": "Żadnych trudności",
  "patient_hand_questionnaire_function_q9_a2": "Mała trudność",
  "patient_hand_questionnaire_function_q9_a3": "Umiarkowany poziom trudności",
  "patient_hand_questionnaire_function_q9_a4": "Intensywny poziom trudności",
  "patient_hand_questionnaire_function_q9_a5": "Brak możliwości wykonania",
  "patient_hand_uram": "Skala URAM dłoni i nadgarstka",
  "patient_hand_uram_canyou": "Czy możesz...",
  "patient_hand_uram_q1": "Umyj się flanelą, trzymając dłonie płasko",
  "patient_hand_uram_q1_a1": "Bez trudności",
  "patient_hand_uram_q1_a2": "Z bardzo niewielkimi trudnościami",
  "patient_hand_uram_q1_a3": "Z lekkim trudem",
  "patient_hand_uram_q1_a4": "Z wielkim trudem",
  "patient_hand_uram_q1_a5": "Prawie niemożliwe",
  "patient_hand_uram_q1_a6": "Niemożliwe",
  "patient_hand_uram_q2": "Umyj swoją twarz",
  "patient_hand_uram_q2_a1": "Bez trudności",
  "patient_hand_uram_q2_a2": "Z bardzo niewielkimi trudnościami",
  "patient_hand_uram_q2_a3": "Z lekkim trudem",
  "patient_hand_uram_q2_a4": "Z wielkim trudem",
  "patient_hand_uram_q2_a5": "Prawie niemożliwe",
  "patient_hand_uram_q2_a6": "Niemożliwe",
  "patient_hand_uram_q3": "Trzymaj butelkę w jednej ręce",
  "patient_hand_uram_q3_a1": "Bez trudności",
  "patient_hand_uram_q3_a2": "Z bardzo niewielkimi trudnościami",
  "patient_hand_uram_q3_a3": "Z lekkim trudem",
  "patient_hand_uram_q3_a4": "Z wielkim trudem",
  "patient_hand_uram_q3_a5": "Prawie niemożliwe",
  "patient_hand_uram_q3_a6": "Niemożliwe",
  "patient_hand_uram_q4": "Uścisnąć komuś rękę",
  "patient_hand_uram_q4_a1": "Bez trudności",
  "patient_hand_uram_q4_a2": "Z bardzo niewielkimi trudnościami",
  "patient_hand_uram_q4_a3": "Z lekkim trudem",
  "patient_hand_uram_q4_a4": "Z wielkim trudem",
  "patient_hand_uram_q4_a5": "Prawie niemożliwe",
  "patient_hand_uram_q4_a6": "Niemożliwe",
  "patient_hand_uram_q5": "Pogłaskaj coś lub pogłaszcz kogoś",
  "patient_hand_uram_q5_a1": "Bez trudności",
  "patient_hand_uram_q5_a2": "Z bardzo niewielkimi trudnościami",
  "patient_hand_uram_q5_a3": "Z lekkim trudem",
  "patient_hand_uram_q5_a4": "Z wielkim trudem",
  "patient_hand_uram_q5_a5": "Prawie niemożliwe",
  "patient_hand_uram_q5_a6": "Niemożliwe",
  "patient_hand_uram_q6": "Klaszcz",
  "patient_hand_uram_q6_a1": "Bez trudności",
  "patient_hand_uram_q6_a2": "Z bardzo niewielkimi trudnościami",
  "patient_hand_uram_q6_a3": "Z lekkim trudem",
  "patient_hand_uram_q6_a4": "Z wielkim trudem",
  "patient_hand_uram_q6_a5": "Prawie niemożliwe",
  "patient_hand_uram_q6_a6": "Niemożliwe",
  "patient_hand_uram_q7": "Rozłóż palce",
  "patient_hand_uram_q7_a1": "Bez trudności",
  "patient_hand_uram_q7_a2": "Z bardzo niewielkimi trudnościami",
  "patient_hand_uram_q7_a3": "Z lekkim trudem",
  "patient_hand_uram_q7_a4": "Z wielkim trudem",
  "patient_hand_uram_q7_a5": "Prawie niemożliwe",
  "patient_hand_uram_q7_a6": "Niemożliwe",
  "patient_hand_uram_q8": "Oprzyj się na dłoni",
  "patient_hand_uram_q8_a1": "Bez trudności",
  "patient_hand_uram_q8_a2": "Z bardzo niewielkimi trudnościami",
  "patient_hand_uram_q8_a3": "Z lekkim trudem",
  "patient_hand_uram_q8_a4": "Z wielkim trudem",
  "patient_hand_uram_q8_a5": "Prawie niemożliwe",
  "patient_hand_uram_q8_a6": "Niemożliwe",
  "patient_hand_uram_q9": "Podnoś małe przedmioty kciukiem i palcem wskazującym",
  "patient_hand_uram_q9_a1": "Bez trudności",
  "patient_hand_uram_q9_a2": "Z bardzo niewielkimi trudnościami",
  "patient_hand_uram_q9_a3": "Z lekkim trudem",
  "patient_hand_uram_q9_a4": "Z wielkim trudem",
  "patient_hand_uram_q9_a5": "Prawie niemożliwe",
  "patient_hand_uram_q9_a6": "Niemożliwe",
  "patient_hand_quality_of_life": "Jakość życia",
  "patient_hand_quality_of_life_instruction": "Oceń poniższe, aby najlepiej opisać Twoje ZDROWIE DZISIAJ:",
  "patient_hand_quality_of_life_instruction_0": "Bez problemu",
  "patient_hand_quality_of_life_instruction_1": "Niewielkie problemy",
  "patient_hand_quality_of_life_instruction_2": "Umiarkowane problemy",
  "patient_hand_quality_of_life_instruction_3": "Duże problemy",
  "patient_hand_quality_of_life_instruction_4": "Ekstremalne/niemożliwe",
  "patient_hand_quality_of_life_q1": "Problem z chodzeniem",
  "patient_hand_quality_of_life_q1_a1": "Bez problemu",
  "patient_hand_quality_of_life_q1_a2": "Niewielki",
  "patient_hand_quality_of_life_q1_a3": "Umiarkowany",
  "patient_hand_quality_of_life_q1_a4": "Duży",
  "patient_hand_quality_of_life_q1_a5": "Niezdolny",
  "patient_hand_quality_of_life_q2": "Problem z samodzielnym myciem i ubieraniem się",
  "patient_hand_quality_of_life_q2_a1": "Bez problemu",
  "patient_hand_quality_of_life_q2_a2": "Niewielki",
  "patient_hand_quality_of_life_q2_a3": "Umiarkowany",
  "patient_hand_quality_of_life_q2_a4": "Duży",
  "patient_hand_quality_of_life_q2_a5": "Niezdolny",
  "patient_hand_quality_of_life_q3": "Problem z wykonywaniem zwykłych czynności (np. pracy, nauki, prac domowych, rodzinnych lub zajęć rekreacyjnych)",
  "patient_hand_quality_of_life_q3_a1": "Bez problemu",
  "patient_hand_quality_of_life_q3_a2": "Niewielki",
  "patient_hand_quality_of_life_q3_a3": "Umiarkowany",
  "patient_hand_quality_of_life_q3_a4": "Duży",
  "patient_hand_quality_of_life_q3_a5": "Niezdolny",
  "patient_hand_quality_of_life_q4": "Ból lub dyskomfort",
  "patient_hand_quality_of_life_q4_a1": "Żadnych",
  "patient_hand_quality_of_life_q4_a2": "Niewielki",
  "patient_hand_quality_of_life_q4_a3": "Umiarkowany",
  "patient_hand_quality_of_life_q4_a4": "Ciężki : silny",
  "patient_hand_quality_of_life_q4_a5": "Skrajny",
  "patient_hand_quality_of_life_q5": "Lęk/Depresja",
  "patient_hand_quality_of_life_q5_a1": "Nie jestem niespokojny/depresyjny",
  "patient_hand_quality_of_life_q5_a2": "Lekko niespokojny/przygnębiony",
  "patient_hand_quality_of_life_q5_a3": "Umiarkowanie niespokojny/depresyjny",
  "patient_hand_quality_of_life_q5_a4": "Silny niepokój/depresja",
  "patient_hand_quality_of_life_q5_a5": "Bardzo niespokojny/przygnębiony",
  "patient_hand_quality_of_life_q6": "Jak dobre lub złe jest Twoje zdrowie DZIŚ? (100 oznacza najlepsze zdrowie, 0 oznacza najgorsze zdrowie, jakie możesz sobie wyobrazić)",
  "patient_hand_quality_of_life_q6_a1": "Najgorsze",
  "patient_hand_quality_of_life_q6_a2": "Najlepsze",
  "patient_hand_satisfaction": "Zadowolenie",
  "patient_hand_satisfaction_instrucion": "Proszę określić, w jakim stopniu jesteś zadowolony z następujących elementów:",
  "patient_hand_satisfaction_instrucion_0": "Bardzo nieusatysfakcjonowany",
  "patient_hand_satisfaction_instrucion_1": "Niezadowolony",
  "patient_hand_satisfaction_instrucion_2": "Ani usatysfakcjonowany, ani nieusatysfakcjonowany",
  "patient_hand_satisfaction_instrucion_3": "W pewnym stopniu usatysfakcjonowany",
  "patient_hand_satisfaction_instrucion_4": "Bardzo zadowolony",
  "patient_hand_satisfaction_q1": "Ruch Twoich PALCÓW",
  "patient_hand_satisfaction_q1_na": "Nie dotyczy",
  "patient_hand_satisfaction_q1_a1": "Bardzo nieusatysfakcjonowany",
  "patient_hand_satisfaction_q1_a2": "Niezadowolony",
  "patient_hand_satisfaction_q1_a3": "Ani usatysfakcjonowany, ani nieusatysfakcjonowany",
  "patient_hand_satisfaction_q1_a4": "W pewnym stopniu usatysfakcjonowany",
  "patient_hand_satisfaction_q1_a5": "Bardzo zadowolony",
  "patient_hand_satisfaction_q2": "Ruch nadgarstka",
  "patient_hand_satisfaction_q2_na": "Nie dotyczy",
  "patient_hand_satisfaction_q2_a1": "Bardzo nieusatysfakcjonowany",
  "patient_hand_satisfaction_q2_a2": "Niezadowolony",
  "patient_hand_satisfaction_q2_a3": "Ani usatysfakcjonowany, ani nieusatysfakcjonowany",
  "patient_hand_satisfaction_q2_a4": "W pewnym stopniu usatysfakcjonowany",
  "patient_hand_satisfaction_q2_a5": "Bardzo zadowolony",
  "patient_hand_satisfaction_q3": "Spójrz na swoją DŁOŃ",
  "patient_hand_satisfaction_q3_na": "Nie dotyczy",
  "patient_hand_satisfaction_q3_a1": "Bardzo nieusatysfakcjonowany",
  "patient_hand_satisfaction_q3_a2": "Niezadowolony",
  "patient_hand_satisfaction_q3_a3": "Ani usatysfakcjonowany, ani nieusatysfakcjonowany",
  "patient_hand_satisfaction_q3_a4": "W pewnym stopniu usatysfakcjonowany",
  "patient_hand_satisfaction_q3_a5": "Bardzo zadowolony",
  "patient_hand_satisfaction_q4": "Twój zabieg dłoni/nadgarstków",
  "patient_hand_satisfaction_q4_na": "Nie dotyczy",
  "patient_hand_satisfaction_q4_a1": "Bardzo nieusatysfakcjonowany",
  "patient_hand_satisfaction_q4_a2": "Niezadowolony",
  "patient_hand_satisfaction_q4_a3": "Ani usatysfakcjonowany, ani nieusatysfakcjonowany",
  "patient_hand_satisfaction_q4_a4": "W pewnym stopniu usatysfakcjonowany",
  "patient_hand_satisfaction_q4_a5": "Bardzo zadowolony",
  "patient_hand_complete": "Koniec ankiety",
  "patient_hand_complete_text1": "Dziękujemy za poświęcenie czasu na wypełnienie tego formularza.",
  "patient_hand_complete_text2": "Kliknij przycisk Prześlij, aby zakończyć i zobaczyć swoje wyniki.",
  "patient_hand_complete_text3": "Twoje odpowiedzi będą traktowane poufnie, a wszelkie raporty będą wykorzystywać wyłącznie informacje pozbawione danych umożliwiających identyfikację",
  "patient_hand_result": "Wyniki",
  "patient_hand_result_text1": "Poniżej znajdują się Twoje wyniki w zakresie bólu oraz funkcji dłoni i nadgarstków, które zostały obliczone na podstawie Twojego kwestionariusza.",
  "patient_hand_result_function": "Funkcjonować",
  "patient_hand_result_pain": "Ból",
  "patient_hand_result_health_perception": "Ogólne postrzeganie zdrowia",
  "patient_hand_result_self_evaluation": "Samoocena punktacji dłoni i nadgarstków",
  "patient_hand_result_text2": "Twój lekarz będzie z tobą wkrótce.",
  "patient_general_history": "HISTORIA OBECNEJ SKARGI/USZKODZENIA/CHOROBY",
  "patient_general_history_q1": "Główna skarga (wyjaśnij swoją skargę własnymi słowami)",
  "patient_general_history_q2": "Zaczął się problem z datą",
  "patient_general_history_q3": "Czy miałeś jakieś obrazowanie?",
  "patient_general_history_q3_a1": "Żadnych",
  "patient_general_history_q3_a2": "Rentgen",
  "patient_general_history_q3_a3": "Ultradźwięk",
  "patient_general_history_q3_a4": "Rezonans magnetyczny",
  "patient_general_history_q3_a5": "Artrogram MRI (MRI z barwnikiem w stawie)",
  "patient_general_history_q3_a6": "Tomografia komputerowa",
  "patient_general_history_q3_a7": "Artrogram CT (CT z barwnikiem w stawie)",
  "patient_general_history_q4": "Czy przeprowadziłeś dalsze badania dotyczące swojego stanu zdrowia, które nie zostały wymienione powyżej?",
  "patient_general_history_q4_placeholder": "Inne badania",
  "patient_general_past_history": "PRZESZŁA HISTORIA MEDYCZNA / BIEŻĄCE PROBLEMY MEDYCZNE",
  "patient_general_past_history_q1": "Czy masz jakieś alergie? (lek lub żywność)",
  "patient_general_past_history_q1_a1": "NIE",
  "patient_general_past_history_q1_a2": "Tak",
  "patient_general_past_history_placeholder": "Określ alergię i rodzaj reakcji",
  "patient_general_past_history_q2": "Czy jesteś uczulony na lateks?",
  "patient_general_past_history_q2_a1": "NIE",
  "patient_general_past_history_q2_a2": "Tak",
  "patient_general_past_history_q3": "Czy chorujesz lub leczysz się na nadciśnienie?",
  "patient_general_past_history_q3_a1": "NIE",
  "patient_general_past_history_q3_a2": "Tak",
  "patient_general_past_history_q3_a2_placeholder": "Podaj leki na nadciśnienie",
  "patient_general_past_history_q4": "Czy obecnie lub w przeszłości cierpiałeś na jakieś choroby serca?",
  "patient_general_past_history_q4_a1": "NIE",
  "patient_general_past_history_q4_a2": "Tak",
  "patient_general_past_history_q4_a2_o1": "Wybierz warunek",
  "patient_general_past_history_q4_a2_o2": "Dusznica",
  "patient_general_past_history_q4_a2_o3": "Zawał serca",
  "patient_general_past_history_q4_a2_o4": "Niewydolność serca (płyn w płucach)",
  "patient_general_past_history_q4_a2_o5": "Szmer lub problem z zastawką serca",
  "patient_general_past_history_q4_a2_o6": "Kołatanie serca/nieregularne bicie serca",
  "patient_general_past_history_q4_a2_o7": "Wszczepione urządzenie (ICD, rozrusznik serca)",
  "patient_general_past_history_q4_a2_o8": "Kardiochirurgia",
  "patient_general_past_history_q4_a2_o9": "Operacja tętnicy szyjnej",
  "patient_general_past_history_q4_a2_o10": "Inna choroba serca",
  "patient_general_past_history_q4_a2_o8_q1_placeholder": "Wskaż operację kardiochirurgiczną",
  "patient_general_past_history_q4_a2_o10_q1_placeholder": "Określ inną chorobę serca",
  "patient_general_past_history_q5": "Czy masz kardiologa?",
  "patient_general_past_history_q5_a1": "NIE",
  "patient_general_past_history_q5_a2": "Tak",
  "patient_general_past_history_q5_a2_placeholder": "Imię i praktyka",
  "patient_general_past_history_q6": "Czy masz problemy z krążeniem w nogach (choroba naczyń obwodowych)?",
  "patient_general_past_history_q6_a1": "Nie",
  "patient_general_past_history_q6_a2": "Tak",
  "patient_general_past_history_q6_a2_placeholder": "Opisz",
  "patient_general_past_history_q7": "Czy w ciągu ostatniego miesiąca zażywałeś leki rozrzedzające krew?",
  "patient_general_past_history_q7_a1": "NIE",
  "patient_general_past_history_q7_a2": "Tak",
  "patient_general_past_history_q7_a2_q1": "Co to jest ?",
  "patient_general_past_history_q7_a2_q1_a1": "Aspiryna",
  "patient_general_past_history_q7_a2_q1_a2": "Klopidogrel/Plavix",
  "patient_general_past_history_q7_a2_q1_a3": "Riwaroksaban",
  "patient_general_past_history_q7_a2_q1_a4": "Eliquis",
  "patient_general_past_history_q7_a2_q1_a5": "Inni",
  "patient_general_past_history_q7_a2_q1_a5_placeholder": "Określ środek rozrzedzający krew",
  "patient_general_past_history_q8": "Czy miałeś jakieś problemy z płucami lub klatką piersiową?",
  "patient_general_past_history_q8_a1": "NIE",
  "patient_general_past_history_q8_a2": "Tak",
  "patient_general_past_history_q8_a2_o1": "Kaszel z plwociną?",
  "patient_general_past_history_q8_a2_o2": "Jakieś problemy z oddychaniem?",
  "patient_general_past_history_q8_a2_o3": "Inni",
  "patient_general_past_history_q8_a2_o2_o1": "Astma",
  "patient_general_past_history_q8_a2_o2_o2": "Wysokie ciśnienie krwi w płucach (nadciśnienie płucne)",
  "patient_general_past_history_q8_a2_o2_o3": "Zwłóknienie płuc",
  "patient_general_past_history_q8_a2_o2_o4": "Sarkoidoza",
  "patient_general_past_history_q8_a2_o2_o5": "POChP lub rozedma płuc",
  "patient_general_past_history_q8_a2_o2_o6": "Mukowiscydoza",
  "patient_general_past_history_q8_a2_o2_o7": "Gruźlica",
  "patient_general_past_history_q8_a2_o2_o8": "Inni",
  "patient_general_past_history_q8_a2_o3_placehold": "Sprecyzować",
  "patient_general_past_history_q8_a2_o2_o8_placeholder": "Sprecyzować",
  "patient_general_past_history_q9": "Czy w ciągu ostatniego roku przyjmowałeś sterydy (prednizon lub kortyzon)?",
  "patient_general_past_history_q9_a1": "NIE",
  "patient_general_past_history_q9_a2": "Tak",
  "patient_general_past_history_q10": "Czy korzystasz z domowego tlenu?",
  "patient_general_past_history_q10_a1": "NIE",
  "patient_general_past_history_q10_a2": "Tak",
  "patient_general_past_history_q11": "Czy masz specjalistę od chorób układu oddechowego?",
  "patient_general_past_history_q11_a1": "NIE",
  "patient_general_past_history_q11_a2": "Tak",
  "patient_general_past_history_q11_a2_placeholder": "Imię i zawód",
  "patient_general_past_history_q12": "Czy cierpisz na nadmierne chrapanie lub bezdech senny?",
  "patient_general_past_history_q12_a1": "NIE",
  "patient_general_past_history_q12_a2": "Tak",
  "patient_general_past_history_q12_a2_q1": "Czy Twój bezdech senny jest leczony za pomocą CPAP czy BiPAP?",
  "patient_general_past_history_q12_a2_q1_a1": "NIE",
  "patient_general_past_history_q12_a2_q1_a2": "Tak",
  "patient_general_past_history_q13": "Czy kiedykolwiek miałeś problemy z wątrobą?",
  "patient_general_past_history_q13_a1": "NIE",
  "patient_general_past_history_q13_a2": "Tak",
  "patient_general_past_history_q13_a2_o1": "Wybrany",
  "patient_general_past_history_q13_a2_o2": "Marskość wątroby",
  "patient_general_past_history_q13_a2_o3": "Wirusowe zapalenie wątroby typu A, B lub C",
  "patient_general_past_history_q13_a2_o4": "Żółtaczka",
  "patient_general_past_history_q13_a2_o5": "Inni",
  "patient_general_past_history_q13_a2_o5_placeholder": "Sprecyzować",
  "patient_general_past_history_q14": "Czy często cierpisz na zgagę, wrzody lub przepuklinę rozworu przełykowego?",
  "patient_general_past_history_q14_a1": "NIE",
  "patient_general_past_history_q14_a2": "Tak",
  "patient_general_past_history_q15": "Czy kiedykolwiek miałeś chorobę nerek?",
  "patient_general_past_history_q15_a1": "NIE",
  "patient_general_past_history_q15_a2": "Tak",
  "patient_general_past_history_q15_a2_q1": "Czy jesteś dializowanym pacjentem? Otrzewnowy? Hemodializa",
  "patient_general_past_history_q15_a2_q1_a1": "NIE",
  "patient_general_past_history_q15_a2_q1_a2": "Tak",
  "patient_general_past_history_q15_a2_placeholder": "Określ chorobę nerek",
  "patient_general_past_history_q15_a2_q1_a2_placeholder": "Określ rodzaj i harmonogram dializy",
  "patient_general_past_history_q16": "Czy choruje Pan na cukrzycę?",
  "patient_general_past_history_q16_a1": "NIE",
  "patient_general_past_history_q16_a2": "Tak",
  "patient_general_past_history_q16_a2_q1": "Jak leczy się Twoją cukrzycę? Dieta/leki doustne czy insulina?",
  "patient_general_past_history_q16_a2_q1_placeholder": "Sprecyzować",
  "patient_general_past_history_q17": "Czy masz problemy z tarczycą?",
  "patient_general_past_history_q17_a1": "NIE",
  "patient_general_past_history_q17_a2": "Tak",
  "patient_general_past_history_q18": "Czy kiedykolwiek miałeś epilepsję, drgawki lub drgawki?",
  "patient_general_past_history_q18_a1": "NIE",
  "patient_general_past_history_q18_a2": "Tak",
  "patient_general_past_history_q18_a2_q1": "Kiedy miałeś ostatni atak?",
  "patient_general_past_history_q18_a2_q1_placeholder": "Sprecyzować",
  "patient_general_past_history_q19": "Czy miałeś problemy z nerwami lub mięśniami?",
  "patient_general_past_history_q19_a1": "NIE",
  "patient_general_past_history_q19_a2": "Tak",
  "patient_general_past_history_q19_a2_o1": "Ataki",
  "patient_general_past_history_q19_a2_o1_placeholder": "Kiedy ?",
  "patient_general_past_history_q19_a2_o2": "TIA (przejściowy atak niedokrwienny/miniudar) lub udar",
  "patient_general_past_history_q19_a2_o2_placeholder": "Kiedy ?",
  "patient_general_past_history_q19_a2_o3": "Osłabienie twarzy, nóg lub ramion",
  "patient_general_past_history_q19_a2_o4": "Zaburzenia neurologiczne (przykłady: stwardnienie rozsiane, ALS, choroba Alzheimera)",
  "patient_general_past_history_q19_a2_o4_placeholder": "Sprecyzować",
  "patient_general_past_history_q19_a2_o5": "Zaburzenia mięśni (przykłady: miastenia, dystrofia mięśniowa)",
  "patient_general_past_history_q19_a2_o5_placeholder": "Sprecyzować",
  "patient_general_past_history_q19_a2_o6": "Problemy ze słuchem, wzrokiem lub pamięcią",
  "patient_general_past_history_q19_a2_o7": "Chroniczny ból",
  "patient_general_past_history_q19_a2_o8": "Inni",
  "patient_general_past_history_q19_a2_o8_placeholder": "Sprecyzować",
  "patient_general_past_history_q20": "Czy masz zapalenie stawów?",
  "patient_general_past_history_q20_a1": "NIE",
  "patient_general_past_history_q20_a2": "Tak",
  "patient_general_past_history_q20_a2_o1": "Choroba zwyrodnieniowa stawów",
  "patient_general_past_history_q20_a2_o2": "Reumatoidalne zapalenie stawów",
  "patient_general_past_history_q20_a2_o3": "Inni",
  "patient_general_past_history_q20_a2_o3_placeholder": "Sprecyzować",
  "patient_general_past_history_q21": "Czy leczyłeś się kiedyś na chorobę psychiczną?",
  "patient_general_past_history_q21_a1": "NIE",
  "patient_general_past_history_q21_a2": "Tak",
  "patient_general_past_history_q21_a2_o1": "Silny niepokój",
  "patient_general_past_history_q21_a2_o2": "Depresja",
  "patient_general_past_history_q21_a2_o3": "Inni",
  "patient_general_past_history_q21_a2_o3_placeholder": "Sprecyzuj",
  "patient_general_past_history_q22": "Czy kiedykolwiek zdiagnozowano u Ciebie zaburzenia krzepnięcia?",
  "patient_general_past_history_q22_a1": "NIE",
  "patient_general_past_history_q22_a2": "Tak",
  "patient_general_past_history_q22_a2_o1": "Białaczka lub chłoniak",
  "patient_general_past_history_q22_a2_o2": "Hemofilia",
  "patient_general_past_history_q22_a2_o3": "Zakrzepy",
  "patient_general_past_history_q22_a2_o4": "Niedokrwistość",
  "patient_general_past_history_q22_a2_o5": "Anemia sierpowata",
  "patient_general_past_history_q22_a2_o6": "Inni",
  "patient_general_past_history_q22_a2_o6_placeholder": "Sprecyzować",
  "patient_general_past_history_q23": "Czy masz anemię?",
  "patient_general_past_history_q23_a1": "NIE",
  "patient_general_past_history_q23_a2": "Tak",
  "patient_general_past_history_q24": "Czy kiedykolwiek miałeś zakrzepy krwi w nogach lub płucach?",
  "patient_general_past_history_q24_a1": "NIE",
  "patient_general_past_history_q24_a2": "Tak",
  "patient_general_past_history_q25": "Czy Ty lub ktoś z Twojej rodziny miał poważne problemy z:",
  "patient_general_past_history_q25_a1": "Krwotok z nosa",
  "patient_general_past_history_q25_a1_o1": "NIE",
  "patient_general_past_history_q25_a1_o2": "Tak",
  "patient_general_past_history_q25_a2": "Krwawienie podczas ekstrakcji zębów",
  "patient_general_past_history_q25_a2_o1": "NIE",
  "patient_general_past_history_q25_a2_o2": "Tak",
  "patient_general_past_history_q25_a3": "Krwawienie po operacji",
  "patient_general_past_history_q25_a3_o1": "NIE",
  "patient_general_past_history_q25_a3_o2": "Tak",
  "patient_general_past_history_q26": "Czy kiedykolwiek zdiagnozowano u Ciebie raka?",
  "patient_general_past_history_q26_a1": "NIE",
  "patient_general_past_history_q26_a2": "Tak",
  "patient_general_past_history_q26_a2_placeholder1": "Określ rodzaj nowotworu",
  "patient_general_past_history_q26_a2_placeholder2": "Kiedy?",
  "patient_general_past_history_q27": "Czy kiedykolwiek leczyłeś się chemioterapią lub radioterapią?",
  "patient_general_past_history_q27_a1": "Nie",
  "patient_general_past_history_q27_a2": "Tak",
  "patient_general_past_history_q27_a2_placeholder1": "Nazwa leczenia",
  "patient_general_past_history_q27_a2_placeholder2": "Ostatnia data leczenia",
  "patient_general_past_history_q28": "Czy Ty lub Twój krewny mieliście problemy ze znieczuleniem?",
  "patient_general_past_history_q28_a1": "Nie",
  "patient_general_past_history_q28_a2": "Tak",
  "patient_general_past_history_q28_a2_q1": "Które problemy?",
  "patient_general_past_history_q28_a2_q1_placeholder": "Wybierać",
  "patient_general_past_history_q28_a2_q1_o1": "Ciężkie nudności lub wymioty po operacji",
  "patient_general_past_history_q28_a2_q1_o2": "Hipertermia złośliwa (w rodzinie spokrewnionej lub u Ciebie)",
  "patient_general_past_history_q28_a2_q1_o3": "Problem z oddychaniem lub trudności z założeniem rurki oddechowej do znieczulenia?",
  "patient_general_past_history_q28_a2_q1_o4": "Problemy z założeniem rurki oddechowej",
  "patient_general_past_history_q28_a2_q1_o5": "Inni",
  "patient_general_past_history_q28_a2_q1_o5_placeholder": "Sprecyzować",
  "patient_general_past_history_q29": "Czy masz któreś z poniższych?",
  "patient_general_past_history_q29_q1": "Wyszczerbione lub luźne zęby, protezy, częściowe",
  "patient_general_past_history_q29_q1_a1": "Nie",
  "patient_general_past_history_q29_q1_a2": "Tak",
  "patient_general_past_history_q29_q2": "Problemy z poruszaniem szyją",
  "patient_general_past_history_q29_q2_a1": "Nie",
  "patient_general_past_history_q29_q2_a2": "Tak",
  "patient_general_past_history_q29_q3": "Problemy z otwieraniem ust",
  "patient_general_past_history_q29_q3_a1": "Nie",
  "patient_general_past_history_q29_q3_a2": "Tak",
  "patient_general_past_history_q30": "Czy możesz być w ciąży?",
  "patient_general_past_history_q30_a1": "Nie",
  "patient_general_past_history_q30_a2": "Tak",
  "patient_general_past_history_q30_not_applicable": "Nie dotyczy",
  "patient_general_past_history_q30_a2_q1": "Ostatnia miesiączka",
  "patient_general_past_history_q30_a2_q1_placeholder": "Ostatnia miesiączka",
  "patient_general_past_history_q31": "Historia medyczna: Proszę wymienić wszelkie dodatkowe choroby, na które cierpisz, a które nie zostały wymienione powyżej",
  "patient_general_past_history_q31_q1": "Wypisz wszystkie leki (w tym leki dostępne bez recepty, inhalatory, plastry, krople, witaminy, minerały, suplementy, zioła)",
  "patient_general_past_history_q31_q2": "Podaj nazwę leku, dawkę, częstotliwość i powód jego stosowania",
  "patient_general_past_history_q31_q2_placeholder": "Nazwa leku, dawka, częstotliwość, powód zażywania",
  "patient_general_past_history_q31_q3": "Dawka",
  "patient_general_past_history_q31_q4": "Często",
  "patient_general_past_history_q31_q5": "Powód wzięcia tego",
  "patient_general_past_history_q31_q6": "Historia chirurgiczna",
  "patient_general_past_history_q31_q6_placeholder": "Podaj procedurę i datę zabiegu",
  "patient_general_social_history": "HISTORIA SPOŁECZNA",
  "patient_general_social_history_q1": "Czy mieszkasz sam?",
  "patient_general_social_history_q1_a1": "Nie",
  "patient_general_social_history_q1_a2": "Tak",
  "patient_general_social_history_q1_a1_q1": "Kto z tobą mieszka?",
  "patient_general_social_history_q1_a1_q1_placeholder": "Kto z tobą mieszka?",
  "patient_general_social_history_q2": "Czy palisz papierosy?",
  "patient_general_social_history_q2_a1": "Nie",
  "patient_general_social_history_q2_a2": "Tak",
  "patient_general_social_history_q2_a2_q1": "Ile papierosów dziennie?",
  "patient_general_social_history_q2_a2_q1_placeholder": "Liczba papierosów",
  "patient_general_social_history_q2_a2_q2": "Liczba lat?",
  "patient_general_social_history_q2_a2_q2_placeholder": "Lata",
  "patient_general_social_history_q3": "Czy paliłeś w przeszłości?",
  "patient_general_social_history_q3_a1": "Nie",
  "patient_general_social_history_q3_a2": "Tak",
  "patient_general_social_history_q3_a2_q1": "Kiedy rzuciłeś palenie?",
  "patient_general_social_history_q3_a2_q1_placeholder": "Kiedy rzuciłeś palenie?",
  "patient_general_social_history_q3_a2_q2_placeholder": "Lata",
  "patient_general_social_history_q4": "Czy pijasz alkohol?",
  "patient_general_social_history_q4_a1": "Nie",
  "patient_general_social_history_q4_a2": "Tak",
  "patient_general_social_history_q4_a2_q1_placeholder": "Liczba standardowych drinków w tygodniu",
  "patient_general_social_history_q5": "Czy zażywasz lub kiedykolwiek zażywałeś narkotyki rekreacyjne lub uliczne (nielegalne), marihuanę lub opioidy, które nie zostały Ci przepisane?",
  "patient_general_social_history_q5_a1": "Nie",
  "patient_general_social_history_q5_a2": "Tak",
  "patient_general_social_history_q5_a2_placeholder": "Określ narkotyki",
  "patient_general_complete": "Koniec ankiety",
  "patient_general_complete_text1": "Dziękujemy za poświęcenie czasu na wypełnienie tego formularza.",
  "patient_general_complete_text2": "Kliknij przycisk Prześlij, aby zakończyć i zobaczyć swoje wyniki.",
  "patient_general_complete_text3": "Twoje odpowiedzi będą traktowane poufnie, a wszelkie raporty będą wykorzystywać wyłącznie informacje pozbawione danych umożliwiających identyfikację",
  "AppVersion": "Wersja aplikacji",
  "Complete_personal_info": "Kompletne dane osobowe",
  "To_complete_the_sign_up": "Aby dokończyć rejestrację, wpisz poniżej swoje dane.",
  "Select_Existing_Profile": "Wybierz opcję Istniejący profil",
  "Get_In": "Wchodzić",
  "Create_new_patient": "Utwórz nowego pacjenta",
  "myProfile": "Mój profil",
  "First_Name": "Imię*",
  "Last_Name": "Nazwisko*",
  "DOB": "Data urodzenia*",
  "Sex": "Płeć*",
  "Address": "Adres*",
  "Postcode": "Kod pocztowy*",
  "Suburb": "Przedmieście*",
  "Country_Code": "Kod pocztowy",
  "Mobile_Phone": "Telefon komórkowy",
  "Home_Phone": "Telefon domowy",
  "Email": "E-mail*",
  "Emergency_Contact": "Kontakt w nagłych wypadkach*",
  "Phone": "Telefon*",
  "Relationship": "Relacja*",
  "Regular_GP_Name": "Imię i nazwisko lekarza rodzinnego*",
  "Practice_Name": "Nazwa praktyki*",
  "Medicare_Card_Number": "Numer karty Medicare",
  "Ref": "Nr ref",
  "Message": "Wiadomości",
  "Expiry": "Wygaśnięcie",
  "Private_Health_Fund": "Prywatny Fundusz Zdrowia",
  "Membership_Number": "Numer członkowski",
  "Claim_Type": "Typ roszczenia",
  "Work_Cover": "Osłona robocza",
  "Employer": "Pracodawca",
  "Third_Party_Claim_Number": "Numer roszczenia strony trzeciej",
  "Third_Party_Name": "Nazwa strony trzeciej",
  "DVA_Concession_Card": "Karta Koncesyjna DVA",
  "Number": "Numer",
  "Defence_Personnel": "Personel Obrony",
  "I_agree": "Wyrażam zgodę na przetwarzanie moich danych osobowych i zapoznałem się z treścią",
  "Privacy_Policy": "Polityka prywatności.",
  "visitClinic": "Zarezerwuj spotkanie",
  "selectdocOrg": "Wybierz lekarza i organizację*",
  "doctorOrganization": "Organizacja Lekarzy”",
  "Select_Treatment_Category": "Wybierz kategorię leczenia*",
  "Do_you_want_to_submit_patient_form_or_not": "Czy chcesz przesłać formularz pacjenta czy nie?",
  "areyousurewanttocontinueyourform": "Twój poprzedni formularz oczekuje na realizację. Czy chcesz kontynuować?",
  "myVisit": "Poprzednie wizyty",
  "View_score": "Zobacz wynik",
  "upcoming_visits": "Nadchodzące",
  "No_data_found": "Nie znaleziono danych",
  "Doctor_Name": "Imię lekarza",
  "Appointment_Date_Time": "Data i godzina spotkania",
  "Clinic_Name": "Nazwa kliniki",
  "Clinic_Address": "Adres kliniki",
  "akmed": "Akunah Med",
  "Type": "Typ",
  "Date_Time": "Data i godzina",
  "Action": "Działanie",
  "View": "Pogląd",
  "No_Messages": "Brak wiadomości",
  "settings": "Ustawienia",
  "Language": "Język",
  "language": "Język i region",
  "timezone": "Strefa czasowa",
  "Timeformat": "Format czasu",
  "List_of_my_profile": "Moje profile",
  "next_button": "Następny",
  "back_button": "Z powrotem",
  "Continue": "Kontynuować",
  "Close": "Zamknąć",
  "submit": "Składać",
  "Sign_out": "Powrót do profilu",
  "Results": "Wyniki",
  "update": "Formularz został pomyślnie zaktualizowany",
  "Personal_Data": "Dane osobiste",
  "Date_of_Birth": "Data urodzenia",
  "Hip_arthritis": "Zapalenie stawów biodrowych?*",
  "Area_of_foot_ankle": "Obszar stopy/kostki, którego dotyczy problem?*",
  "Diagnosis": "Diagnoza ?*",
  "Evaluated_Shoulder": "Oceniane ramię",
  "Dominant_Hand": "Dominująca ręka*",
  "Age": "Wiek",
  "No": "Nie",
  "Yes": "Tak",
  "visitNo": "wizytaNie",
  "visitYes": "wizytaTak",
  "shoulderPainR": "ból barku P",
  "Is_it_initial_visit": "Czy to Twoja pierwsza wizyta?",
  "for_the_problem": "z powodu tego problemu?*",
  "Diagnosis_Procedure": "Diagnoza / Procedura",
  "Procedure": "Procedura",
  "Evaluated_sdfs": "Oceniana strona*",
  "Please_Select_previous_visits": "Proszę wybrać poprzednie wizyty*",
  "Follow_Up": "Podejmować właściwe kroki",
  "Assessment_Date": "Data oceny",
  "Do_you_have_Dupuytren_disease": "Czy cierpisz na chorobę Dupuytrena?",
  "Do_you_have_carpal_tunnel_syndrome": "Czy masz zespół cieśni nadgarstka?",
  "Is_your_unaffected_shoulder_pain": "Czy twój zdrowy bark jest bezbolesny i pozwala na normalne funkcjonowanie?",
  "Is_your_unaffected_hip_pain": "Czy Twoje zdrowe biodro jest wolne od bólu i umożliwia normalne funkcjonowanie?",
  "Is_your_unaffected_knee_pain": "Czy Twoje zdrowe kolano jest wolne od bólu i umożliwia normalne funkcjonowanie?",
  "Is_your_unaffected_elbow_pain": "Czy Twój zdrowy łokieć jest bezbolesny i umożliwia normalne funkcjonowanie?",
  "Is_your_unaffected_foot_and_ankle_pain": "Czy Twoja zdrowa stopa i kostka nie powoduje bólu i pozwala na normalne funkcjonowanie?",
  "Is_your_unaffected_hand_pain": "Czy Twoja zdrowa dłoń i nadgarstek nie powoduje bólu i umożliwia normalne funkcjonowanie?",
  "Patient_Consent": "Informacje dla pacjentów Akunah i bezpłatny formularz wcześniejszej zgody",
  "Patient_Last_Name": "Nazwisko pacjenta",
  "Patient_First_Name": "Imię pacjenta",
  "Patient_Date_of_Birth": "Data urodzenia pacjenta",
  "information": "Informacja",
  "I_am_informedDDD": "W celu oceny, planowania i oceny wyników Twojego leczenia ortopedycznego, Twój lekarz,",
  "Clinician": "(zwana dalej „Lekarzem”) korzysta z usług oceny, gromadzenia i analizy danych dotyczących wyników i/lub usług planowania przedoperacyjnego oraz oprogramowania zapewnianego przez firmę Akunah Medical Technology Pty Ltd i jej spółki zależne („Akunah”).",
  "For_that": "W tym celu Lekarz przekaże firmie Akunah Twoje dane osobowe, w tym skany tomografii komputerowej (CT) Twojego ciała i inne dane medyczne.",
  "Akunah_softawre": "Oprogramowanie i usługi Akunah mogą obejmować ocenę obrazów, ocenę danych medycznych, tworzenie spersonalizowanych modeli 3D anatomii użytkownika wraz z kluczowymi pomiarami anatomicznymi, planowanie operacji i wsparcie podczas operacji, które zostanie udostępnione lekarzowi.",
  "the_clinic": "Lekarz wykorzysta ocenę i planowanie przedoperacyjne Twojego leczenia ortopedycznego.",
  "oportunnity": "Masz również możliwość podania danych osobowych za pośrednictwem portalu pacjentów Akunah i uzyskania dostępu do modeli i planów Akunah.",
  "akunah_approch": "Podejście Akunah do prywatności i bezpieczeństwa",
  "akunah_approch1": "Dbamy przede wszystkim o prywatność i bezpieczeństwo danych, które Akunah będzie od Ciebie zbierać, aby pomóc Twojemu lekarzowi.",
  "akunah_approch2": "Akunah dobrowolnie przestrzega obowiązujących europejskich przepisów o ochronie danych, w tym ogólnego rozporządzenia UE o ochronie danych UE/2016/679, ponieważ przepisy te stanowią najsilniejsze przepisy dotyczące prywatności uchwalane na całym świecie. Przepisy te wykraczają poza przepisy dotyczące prywatności uchwalone w Australii, Stanach Zjednoczonych i innych krajach.",
  "akunah_approch3": "„Kodeks prywatności dotyczący przetwarzania danych osobowych klientów indywidualnych” firmy Akunah opisuje, w jaki sposób firma Akunah przestrzega obowiązujących europejskich przepisów o ochronie danych (tzw.",
  "akunah_approch4": "Kodeks prywatności",
  "akunah_approch5": "„Umowa o przetwarzaniu danych” firmy Akunah opisuje ustalenia, jakie firma zawarła z lekarzami w celu ochrony i zabezpieczenia danych osobowych użytkownika (tzw.",
  "akunah_approch6": "Umowa o przetwarzaniu danych",
  "akunah_approch7": "Zasady te są dostępne na stronie internetowej Akunah pod adresem",
  "terms_": "https://akunah.com/termsofservices",
  "akunah_approch8": "W przypadku jakiejkolwiek niespójności pomiędzy Kodeksem Prywatności, Umową o Przetwarzaniu Danych i niniejszym dokumentem, wówczas w zakresie jakiejkolwiek niezgodności z niniejszym dokumentem będą miały zastosowanie warunki pierwszego wymienionego dokumentu.",
  "Data_Collection_and_Processing": "Gromadzenie i przetwarzanie danych",
  "I_am_informed": "Akunah może gromadzić Twoje dane, w tym informacje dotyczące Twoich danych kontaktowych, uwierzytelniania, identyfikacji, zawodu i danych demograficznych. Akunah może również gromadzić dane medyczne dotyczące Twojego zdrowia, takie jak",
  "Assessment_and_evaluation": "• Dane dotyczące oceny i ewaluacji, cechy fizyczne i inne informacje istotne dla proponowanych metod leczenia i wyników;",
  "Previous_surgery": "• Wcześniejsze operacje, diagnoza, skany radiologiczne, modele 3D, anatomia, pomiary anatomiczne; I",
  "Health_and_medical_history": "• Historia zdrowia i choroby, w tym leki stosowane przy objawach oraz wszelkie wcześniejsze diagnozy i leczenie.",
  "I_have_been_assured": "Akunah zawsze zbiera informacje w sposób zgodny z prawem i uczciwy, niezależnie od tego, czy bezpośrednio od pacjenta, udostępniony przez lekarza, czy za pośrednictwem produktów Akunah. Akunah będzie przetwarzać te dane zgodnie z Twoją zgodą wyrażoną poniżej, aby w razie potrzeby umożliwić świadczenie usług oceny i planowania przedoperacyjnego.",
  "My_data": "Ponadto Akunah może wykorzystywać Twoje dane osobowe w celu zapewnienia zgodności ze wszystkimi obowiązującymi przepisami i regulacjami",
  "your_data": "Twoje dane mogą zostać udostępnione także podmiotom trzecim, jeśli wymagają tego cele świadczenia oferowanych usług lub przepisy prawa.",
  "Data_Access_and_Storage": "Dostęp do danych, przechowywanie i bezpieczeństwo",
  "Personal_data_provided": "Dostęp do danych dostarczonych przez Ciebie i Twojego Lekarza będą mieli wyłącznie pracownicy Akunah oraz osoby trzecie, które przyczyniają się do Twojego leczenia lub powrotu do zdrowia (np. anestezjolog, fizjoterapeuta) i muszą mieć dostęp do Twoich danych.",
  "data1": "Dane wprowadzane za pośrednictwem oprogramowania Akunah są bezpiecznie przechowywane na terenie Wspólnoty Australijskiej przez zatwierdzonego dostawcę usług hostingowych. Zapewnia to odpowiedni poziom poufności i bezpieczeństwa odzwierciedlający charakter Twoich danych przetwarzanych za pośrednictwem oprogramowania Akunah.",
  "data2": "Jeśli znajdujesz się poza Wspólnotą Australijską i/lub dane wprowadzone za pośrednictwem oprogramowania Akunah mogą być dostępne dla spółek zależnych Akunah zlokalizowanych poza Wspólnotą Australijską, w tym w Unii Europejskiej lub Stanach Zjednoczonych, mogą obowiązywać inne standardy prywatności dotyczące sposobu, w jaki Twoje dane jest używany i chroniony w tych krajach. W takim przypadku Twoje dane osobowe będą przechowywane w tym państwie.",
  "data3": "Akunah posiada odpowiednie zabezpieczenia zgodnie ze wszystkimi obowiązującymi wymogami prawnymi, aby zapewnić, że dane są poufne i chronione przy odpowiednim poziomie ochrony danych w stosunku do wszystkich stron trzecich. Od wszystkich osób mających dostęp wymagamy przyjęcia standardów zapewniających poziom ochrony danych równoważny poziomowi przyjętemu przez Akunah.",
  "data4": "Twój lekarz jest współodpowiedzialny za gromadzenie, dostęp, analizowanie i udostępnianie Twoich danych zgodnie z wymogami prawnymi.",
  "p_data1": "Twoje dane osobowe będą przechowywane przez Akunah przez różny okres czasu. Okres przechowywania zostanie określony na podstawie następujących kryteriów: (i) cel, w jakim Akunah lub Lekarz wykorzystuje Twoje dane osobowe (będą musieli przechowywać dane tylko tak długo, jak jest to konieczne do tego celu); oraz (ii) obowiązujące przepisy prawa lub regulacje, które mogą określać minimalny okres, przez jaki Akunah musi przechowywać Twoje dane osobowe.",
  "Deidentified_Data": "Dane zdezidentyfikowane",
  "d_txt1": "Akunah może usunąć dane umożliwiające identyfikację, a następnie wykorzystać je – zwykle w połączeniu z danymi innych pacjentów, które nie pozwalają na identyfikację – do celów archiwalnych, badań i rozwoju, analizy danych, rozwoju oprogramowania/urządzeń oraz udoskonalania produktów i usług Akunah . Dzięki temu firma Akunah może stale ulepszać swoje produkty i usługi z korzyścią dla pacjentów takich jak Ty.",
  "Patient_Rights": "Twoje prawa",
  "I_understand_that": "Możesz odmówić wyrażenia zgody na przekazanie swoich danych osobowych firmie Akunah. W takim przypadku należy porozmawiać ze swoim lekarzem, ponieważ może on nie być w stanie zapewnić Państwu usług medycznych lub leczenia bez korzystania z oprogramowania i usług Akunah.",
  "I_understand_that_1txt": "Masz prawo wycofać swoją zgodę w dowolnym momencie i w dowolny sposób, a także, jeśli ma to zastosowanie, masz prawo: (i) uzyskania informacji dotyczących przetwarzania Twoich danych osobowych oraz dostępu do danych osobowych; (ii) żądania ich sprostowania, jeśli są nieprawidłowe; (iii) żądania ograniczenia przetwarzania Twoich danych osobowych, w tym wniesienia sprzeciwu wobec niektórych rodzajów przetwarzania Twoich danych osobowych oraz żądania wycofania udzielonej przez Ciebie zgody; (iv) żądania usunięcia swoich danych osobowych; (v) określenia wytycznych dotyczących przetwarzania Twoich danych po Twojej śmierci; oraz (vi) uzyskania kopii danych osobowych przetwarzanych na powyższych warunkach, w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego. Z praw tych mogą Państwo skorzystać kierując swoją prośbę bezpośrednio do Lekarza.",
  "I_understand_that_2txt": "Twoje prawa podlegają obowiązującym wyjątkom i ograniczeniom wynikającym z przepisów prawa.",
  "I_understand_that_3txt": "Po wycofaniu zgody Twój lekarz może nie być w stanie zapewnić Ci usług medycznych lub leczenia, jeśli skorzystasz z tych praw przed proponowaną procedurą.",
  "I_understand_that_4txt": "Możesz także złożyć skargę dotyczącą przetwarzania Twoich danych osobowych do organu nadzorczego w Twoim kraju zamieszkania.",
  "I_understand_that_5txt": "Dane zdezidentyfikowane nie podlegają takim samym ograniczeniom prawnym jak dane osobowe. Pamiętaj, że nie możesz wycofać zgody na dostęp do swoich zdezidentyfikowanych danych i na ich wykorzystanie ani zażądać usunięcia tych danych, ponieważ Akunah nie będzie w stanie znaleźć Twoich danych. Po deidentyfikacji Akunah nie może powiązać danych z Tobą.",
  "Contact": "Zgoda",
  "I_am_able_to_contact": "Przeczytałem i zrozumiałem powyższą sekcję Informacje lub ktoś mi ją przeczytał w zrozumiałym dla mnie języku.",
  "or_by_post": "Miałem możliwość zadawania pytań i jestem usatysfakcjonowany odpowiedziami, które otrzymałem. Rozumiem, że moje dane pozostaną poufne.",
  "or_by_post2": "Wyrażam zgodę dla mojego lekarza",
  "or_by_post2_1": "(„Klinik”) do udostępniania moich danych osobowych firmie Akunah.",
  "or_by_post3": "Wyrażam zgodę na gromadzenie, przechowywanie, wykorzystywanie i udostępnianie przez Akunah moich danych osobowych i danych osobowych, w sposób opisany w sekcji Informacje powyżej.",
  "or_by_post4": "Rozumiem, że w każdej chwili mogę wycofać zgodę.",
  "or_by_post5": "Rozumiem, że otrzymam podpisany egzemplarz tego dokumentu do przechowywania.",
  "or_by_post6": "Mam możliwość skontaktowania się z firmą Akunah w sprawie przetwarzania moich danych drogą mailową na adres",
  "info_akunah": "info@akunah.com",
  "or_by_post7": "lub pocztą na adres Suite 307 Nicholson Street Specialist Centre, Level 9, 121 Newdegate Street, Greenslopes, QLD, 4120, Australia.",
  "Clear": "Jasne",
  "Date": "Data",
  "Signed_by_Mouse": "Podpisano myszką *",
  "Name_if_consent_on_behalf_of_patient": "Imię i nazwisko (jeśli wyrazi zgodę w imieniu pacjenta)",
  "Relationship_to_patient_if_applicable": "Stosunek do pacjenta, jeśli ma to zastosowanie",
  "Choose_Sign_Type": "Wybierz typ znaku*",
  "Draw": "Rysować",
  "Signed_by_Keyboard": "Podpisano za pomocą klawiatury *",
  "Agreement": "Porozumienie",
  "agree_that_I_have_read": "Zgadzam się, że przeczytałem i zrozumiałem niniejszy formularz zgody pacjenta oraz wyrażam wyraźną zgodę na przetwarzanie moich danych osobowych w celach opisanych powyżej.",
  "NOTE": "NOTATKA",
  "If_the_patient": "Jeżeli pacjent ma mniej niż 18 lat, oświadczenie musi podpisać jego rodzic lub opiekun prawny.",
  "If_the_patient_is_deceased": "Jeżeli pacjent nie żyje, oświadczenie musi podpisać jego najbliższy krewny.",
  "patient_signature": "Podpis pacjenta",
  "profile_updated": "Profil zaktualizowany",
  "profile_not_updated": "Profil nie został zaktualizowany",
  "video": "Wideo",
  "radiology_form": "Formularz radiologiczny",
  "doc_document": "Dokument",
  "pathology_form": "Formularz patologii",
  "text": "Tekst",
  "Document_Name": "Nazwa dokumentu",
  "email_sent_successfully": "Żądanie usunięcia zostało wysłane pomyślnie.",
  "messages": "Wiadomości",
  "adult_knee": "Dorosłe kolano",
  "paediatric_knee": "Kolano pediatryczne",
  "document": "Moje dokumenty",
  "Check_in": "Zameldować się",
  "info_icon": "Wypełnij ankietę pacjenta",
  "reminder": "Notatki",
  "Search_with_minimum_three_characters": "Szukaj za pomocą minimum trzech znaków",
  "Select": "Wybierać",
  "delete_account": "Prośba o usunięcie mojego konta",
  "DD_MM_YYYY": "DD / MM / RRRR",
  "Female": "Kobieta",
  "Male": "Mężczyzna",
  "want_to_disclose": "Nie chcę ujawniać",
  "enter_address": "Podaj adres",
  "logout": "Wyloguj",
  "full_name": "Pełne imię i nazwisko",
  "mmyy": "MM / RR",
  "health_found": "Fundusz zdrowia",
  "Not_Applicable": "Nie dotyczy",
  "not_available": "Niedostępne",
  "available": "Dostępny",
  "shared": "Wspólny",
  "private": "Prywatny",
  "add_document": "Dodaj dokument",
  "drap_and_drop": "Przeciągnij i upuść pliki tutaj",
  "or_browser_files": "lub przeglądaj pliki na swoim komputerze",
  "browse_files": "Przeglądaj pliki",
  "download": "pobierać",
  "Oops_error": "Ups! Nie znaleziono żądanych danych.",
  "go_to_home": "Wracać do domu",
  "This_field_is_required": "To pole jest wymagane",
  "PendingForm": "Formularz oczekujący",
  "NoFollowUpRecords": "Brak dalszych zapisów",
  "Right": "Prawy",
  "Both": "Obydwa",
  "Left": "Lewy",
  "shoulder": "Ramię",
  "hip_form": "Biodro",
  "Foot_And_Ankle": "Stopa I Kostka",
  "Hand_And_Wrist": "Ręka I Nadgarstek",
  "General": "Ogólny",
  "adolescent_knee": "Kolano nastolatka",
  "Elbow": "Łokieć",
  "title": "Tytuł",
  "description": "Opis",
  "enter_your_description_here": "Wpisz tutaj opis",
  "enter_your_title_here": "Wpisz tutaj tytuł",
  "progress": "Nierozstrzygnięty",
  "done": "Zakończony",
  "reset": "Resetowanie",
  "send": "Wysłać",
  "Edit": "Edytować",
  "delete": "Usuwać",
  "Are_you_sure_want_to_done_the_remainder": "Czy na pewno chcesz uzupełnić tę notatkę?",
  "Confirm": "Potwierdź",
  "Update": "Aktualizacja",
  "success": "Powodzenie",
  "error": "Błąd",
  "Are_you_sure_you_want_to_delete": "Czy jesteś pewien, że chcesz usunąć?",
  "cancel": "Anulować",
  "Hour_Time": "Czas 24-godzinny",
  "Knee": "Kolano",
  "no": "Nie",
  "yes": "Tak",
  "Year": "0 lat, 0 miesięcy, 0 dni",
  "Bilateral": "Oburęczny",
  "Left_Arm": "Lewe ramię",
  "Right_Arm": "Prawe ramię",
  "out_of": "poza",
  "Right_Elbow": "Prawy łokieć",
  "Left_Elbow": "Lewy łokieć",
  "right_foot_ankle": "Prawa stopa/kostka",
  "left_foot_ankle": "Lewa stopa/kostka",
  "Right_hand_and_wrist": "Prawa ręka i nadgarstek",
  "Left_hand_and_wrist": "Lewa ręka i nadgarstek",
  "Hip_Function_R": "Prawe biodro",
  "Hip_Function_L": "Lewe biodro",
  "Right_Knee": "Prawe kolano",
  "Left_Knee": "Lewe kolano",
  "Right_Pedi_Knee": "Prawe kolano",
  "Left_Pedi_Knee": "Lewe kolano",
  "Scoring_Self_Evaluation": "Punktacja samooceny",
  "presciption_form": "Recepta",
  "insurance_form": "Ubezpieczenie",
  "letter_form": "List",
  "refferal_form": "Skierowanie",
  "medical_form": "Medyczny",
  "are_you_sure_delete_account": "Czy na pewno chcesz usunąć swoje konto?",
  "Adult_Knee_Pain": "Ból kolana u dorosłych",
  "Knee_pain_13_18_year": "Ból kolana",
  "day_pdata": "Dzień",
  "days_pdata": "Dni",
  "month_pdata": "Miesiąc",
  "months_pdata": "Miesiące",
  "year_pdata": "Rok",
  "years_pdata": "Lata",
  "Name": "Nazwa",
  "akunah_id": "Identyfikator Akunah",
  "mobile": "Telefon",
  "email": "E-mail",
  "upload_a_profile": "Prześlij obraz",
  "No_special_characters_are_allowed": "Żadne znaki specjalne nie są dozwolone",
  "numbers_are_not_allowed": "Numery nie są dozwolone",
  "maximum_50_character_are_allowed": "Dopuszczalnych jest maksymalnie 50 znaków",
  "invalid_character": "Nieprawidłowy znak",
  "fields_only_contain_10_character": "Pole zawiera tylko 10 znaków",
  "maximum_10_Numbers_are_allowed": "Dozwolonych jest maksymalnie 10 numerów",
  "Only_I": "Dozwolone są tylko liczby",
  "invalid_home_phone_number": "Nieprawidłowy numer telefonu domowego",
  "special_characters_are_not_allowed": "Znaki specjalne są nie dozwolone",
  "maximum_digit_are_allowed": "Dozwolona jest maksymalnie 1 cyfra",
  "invalid_date": "Nieprawidłowa data",
  "maximum_10_digits_are_allowed": "Dopuszczalnych jest maksymalnie 10 cyfr",
  "switch_profile": "Zmień profil",
  "loading": "Ładowanie",
  "error_max_profile_limit": "Osiągnąłeś maksymalną liczbę kont dozwolonych przy użyciu jednego adresu e-mail",
  "error_upcoming_appointments": "Nie znaleziono nadchodzących spotkań",
  "error_past_visits": "Nie znaleziono wcześniejszych wizyt",
  "error_documents": "Nie znaleziono żadnych dokumentów",
  "country_hawaii": "(UTC-10:00) Czas na Hawajach",
  "country_tahiti": "(UTC-10:00) Tahiti",
  "country_pitcairn": "(UTC-08:00) Pitcairn",
  "country_niue": "(UTC-11:00) Niue",
  "country_mountain": "(UTC-07:00) Czas górski",
  "country_arizona": "(UTC-07:00) Czas górski – Arizona",
  "country_whitehorse": "(UTC-08:00) Czas pacyficzny – Whitehorse",
  "country_chihuahua": "(UTC-07:00) Czas górski – Chihuahua, Mazatlan",
  "country_edmonton": "(UTC-07:00) Czas górski – Edmonton",
  "country_gambier": "(UTC-09:00) Gracz",
  "country_yellowknife": "(UTC-07:00) Czas górski – Yellowknife",
  "country_dawson": "(UTC-07:00) Czas górski – Dawson Creek",
  "country_belize": "(UTC-06:00) Belize",
  "country_hermosillo": "(UTC-07:00) Czas górski – Hermosillo",
  "country_mexico": "(UTC-06:00) Czas środkowy – Meksyk",
  "country_regina": "(UTC-06:00) Czas centralny – Regina",
  "country_tegucigalpa": "(UTC-06:00) Czas centralny – Tegucigalpa",
  "country_centraltime": "(UTC-06:00) Czas centralny",
  "country_rarotonga": "(UTC-10:00) Rarotonga",
  "country_pago": "(UTC-11:00) Pago Pago",
  "country_pacific": "(UTC-08:00) Czas pacyficzny",
  "country_alaska": "(UTC-09:00) Czas na Alasce",
  "country_vancouver": "(UTC-08:00) Czas pacyficzny – Vancouver",
  "country_tijuana": "(UTC-08:00) Czas pacyficzny – Tijuana",
  "country_salvador": "(UTC-06:00) Salwador",
  "country_costa": "(UTC-06:00) Kostaryka",
  "country_winnipeg": "(UTC-06:00) Czas centralny – Winnipeg",
  "country_galapagos": "(UTC-06:00) Galapagos",
  "country_managua": "(UTC-06:00) Managua",
  "country_america_cancun": "(UTC-05:00) Ameryka Cancun",
  "country_guatemala": "(UTC-06:00) Gwatemala",
  "country_bogota": "(UTC-05:00) Bogota",
  "country_easter_island": "(UTC-05:00) Wyspa Wielkanocna",
  "country_iqaluit": "(UTC-05:00) Czas wschodni – Iqaluit",
  "country_toronto": "(UTC-05:00) Czas wschodni – Toronto",
  "country_eastern_time": "(UTC-05:00) Czas wschodni",
  "country_havana": "(UTC-05:00) Hawana",
  "country_lima": "(UTC-05:00) Lima",
  "country_jamaica": "(UTC-05:00) Jamajka",
  "country_panama": "(UTC-05:00) Panama",
  "country_port_au_prince": "(UTC-05:00) Port-au-Prince",
  "country_nassau": "(UTC-05:00) Nassau",
  "country_rio_branco": "(UTC-05:00) Rio Branco",
  "country_barbados": "(UTC-04:00) Barbados",
  "country_halifax": "(UTC-04:00) Czas atlantycki – Halifax",
  "country_bermuda": "(UTC-04:00) Bermudy",
  "country_boa_vista": "(UTC-04:00) Boa Vista",
  "country_curacao": "(UTC-04:00) Curaçao",
  "country_marquesas": "(UTC-09:30) Markiz",
  "country_caracas": "(UTC-04:00) Karakas",
  "country_grand_turk": "(UTC-04:00) Wielki Turk",
  "country_la_paz": "(UTC-04:00) La Paz",
  "timezone_guyana": "(UTC-04:00) Gujana",
  "timezone_port_of_spain": "(UTC-04:00) Port w Hiszpanii",
  "timezone_manaus": "(UTC-04:00) Manaus",
  "timezone_martinique": "(UTC-04:00) Martynika",
  "timezone_porto_velho": "(UTC-04:00) Porto Velho",
  "timezone_santo_domingo": "(UTC-04:00) Santo Domingo",
  "timezone_puerto_rico": "(UTC-04:00) Portoryko",
  "timezone_araguaina": "(UTC-03:00) Araguaina",
  "timezone_belem": "(UTC-03:00) Belem",
  "timezone_buenos_aires": "(UTC-03:00) Buenos Aires",
  "timezone_newfound_time": "(UTC-03:30) Czas nowofundlandzki – St. Johns",
  "timezone_asuncion": "(UTC-03:00) Asuncion",
  "timezone_campo": "(UTC-03:00) Campo Grande",
  "timezone_cayenne": "(UTC-03:00) Kajenna",
  "timezone_cuiaba": "(UTC-03:00) Cuiaba",
  "timezone_maceio": "(UTC-03:00) Maceio",
  "timezone_godthab": "(UTC-03:00) Godhab",
  "timezone_fortaleza": "(UTC-03:00) Fortaleza",
  "timezone_miquelon": "(UTC-03:00) Miquelon",
  "timezone_palmer": "(UTC-03:00) Palmera",
  "timezone_montevideo": "(UTC-03:00) Montevideo",
  "timezone_recife": "(UTC-03:00) Recife",
  "timezone_rothera": "(UTC-03:00) Rothera",
  "timezone_paramaribo": "(UTC-03:00) Paramaribo",
  "timezone_punta_arenas": "(UTC-03:00) Punta Arenas",
  "timezone_santig": "(UTC-03:00) Santiago",
  "timezone_salvador": "(UTC-03:00) Salwador",
  "timezone_stanley": "(UTC-03:00) Stanleya",
  "timezone_south_georgia": "(UTC-02:00) Gruzja Południowa",
  "timezone_noronha": "(UTC-02:00) Noronha",
  "timezone_sao_pulo": "(UTC-02:00) São Paulo",
  "timezone_azores": "(UTC-01:00) Azory",
  "timezone_abidjan": "(UTC+00:00) Abidżan",
  "timezone_cape_verde": "(UTC-01:00) Republika Zielonego Przylądka",
  "timezone_scoresbysund": "(UTC-01:00) Scoresbysund",
  "timezone_bissau": "(UTC+00:00) Bissau",
  "timezone_accra": "(UTC+00:00) Akra",
  "timezone_danmarkshavn": "(UTC+00:00) Danmarkshavn",
  "timezone_casablanca": "(UTC+00:00) Casablanka",
  "timezone_dublin": "(UTC+00:00) Dublin",
  "timezone_canary_islands": "(UTC+00:00) Wyspy Kanaryjskie",
  "timezone_el_aaiun": "(UTC+00:00) El Aaiun",
  "timezone_thule": "(UTC-04:00) Thule",
  "timezone_gmt": "(UTC+00:00) GMT (bez czasu letniego)",
  "timezone_lisbon": "(UTC+00:00) Lizbona",
  "timezone_faeroe": "(UTC+00:00) Wyspy Owcze",
  "timezone_reykjavik": "(UTC+00:00) Reykjavik",
  "timezone_monrovia": "(UTC+00:00) Monrowia",
  "timezone_algiers": "(UTC+01:00) Algier",
  "timezone_london": "(UTC+00:00) Londyn",
  "timezone_amsterdam": "(UTC+01:00) Amsterdamu",
  "timezone_andorra": "(UTC+01:00) Andora",
  "timezone_berlin": "(UTC+01:00) Berlina",
  "timezone_budapest": "(UTC+01:00) Budapeszt",
  "timezone_ceuta": "(UTC+01:00) Ceuta",
  "timezone_brussels": "(UTC+01:00) Bruksela",
  "timezone_copenhagen": "(UTC+01:00) Kopenhaga",
  "timezone_gibrltar": "(UTC+01:00) Gibraltar",
  "timezone_prague": "(UTC+01:00) Czas środkowoeuropejski – Praga",
  "timezone_lagos": "(UTC+01:00) Lagos",
  "timezone_luxembourg": "(UTC+01:00) Luksemburg",
  "timezone_madrid": "(UTC+01:00) Madryt",
  "timezone_malta": "(UTC+01:00) Malta",
  "timezone_ndjamena": "(UTC+01:00) Ndżamena",
  "timezone_paris": "(UTC+01:00) Paryż",
  "timezone_oslo": "(UTC+01:00) Oslo",
  "timezone_monaco": "(UTC+01:00) Monako",
  "timezone_tirane": "(UTC+01:00) Tiran",
  "timezone_rome": "(UTC+01:00) Rzym",
  "timezone_vienna": "(UTC+01:00) Wiedeń",
  "timezone_tunis": "(UTC+01:00) Tunis",
  "timezone_warsaw": "(UTC+01:00) Warszawa",
  "timezone_amman": "(UTC+02:00) Amman",
  "timezone_zurich": "(UTC+01:00) Zurych",
  "timezone_athens": "(UTC+02:00) Ateny",
  "timezone_burcharest": "(UTC+02:00) Bukareszt",
  "timezone_chisinau": "(UTC+02:00) Kiszyniów",
  "timezone_stockholm": "(UTC+01:00) Sztokholm",
  "timezone_cairo": "(UTC+02:00) Kair",
  "timezone_gaza": "(UTC+02:00) Gaza",
  "timezone_jerusalem": "(UTC+02:00) Jerozolima",
  "timezone_johannesburg": "(UTC+02:00) Johannesburg",
  "timezone_helsinki": "(UTC+02:00) Helsinki",
  "timezone_khartoum": "(UTC+02:00) Chartum",
  "timezone_belgrade": "(UTC+01:00) Czas środkowoeuropejski – Belgrad",
  "timezone_damascus": "(UTC+02:00) Damaszek",
  "timezone_maputo": "(UTC+02:00) Maputo",
  "timezone_kaliningrad": "(UTC+02:00) Moskwa-01 - Kaliningrad",
  "timezone_riga": "(UTC+02:00) Ryga",
  "timezone_kiev": "(UTC+02:00) Kijów",
  "timezone_nicosia": "(UTC+02:00) Nikozja",
  "timezone_tallinn": "(UTC+02:00) Tallin",
  "timezone_tripoli": "(UTC+02:00) Trypolis",
  "timezone_sofia": "(UTC+02:00) Sofia",
  "timezone_vilnius": "(UTC+02:00) Wilno",
  "timezone_istanbul": "(UTC+03:00) Stambuł",
  "timezone_baghdad": "(UTC+03:00) Bagdad",
  "timezone_minsk": "(UTC+03:00) Mińsk",
  "timezone_windhoek": "(UTC+02:00) Windhuk",
  "timezone_nairobi": "(UTC+03:00) Nairobi",
  "timezone_moscow": "(UTC+03:00) Moskwa+00 - Moskwa",
  "timezone_qatar": "(UTC+03:00) Katar",
  "timezone_beirut": "(UTC+02:00) Bejrut",
  "timezone_riyadh": "(UTC+03:00) Rijad",
  "timezone_syowa": "(UTC+03:00) Siowa",
  "timezone_tehran": "(UTC+03:30) Teheran",
  "timezone_baku": "(UTC+04:00) Baku",
  "timezone_dubai": "(UTC+04:00) Dubaj",
  "timezone_mahe": "(UTC+04:00) Hej",
  "timezone_mauritius": "(UTC+04:00) Mauritius",
  "timezone_tbilisi": "(UTC+04:00) Tbilisi",
  "timezone_samara": "(UTC+04:00) Moskwa+01 – Samara",
  "timezone_reunion": "(UTC+04:00) Spotkanie",
  "timezone_yerevan": "(UTC+04:00) Erewan",
  "timezone_kabul": "(UTC+04:30) Kabul",
  "timezone_aqtau": "(UTC+05:00) Aktau",
  "timezone_aqtobe": "(UTC+05:00) Aktobe",
  "timezone_ashgabat": "(UTC+05:00) Aszchabad",
  "timezone_dushanbe": "(UTC+05:00) Duszanbe",
  "timezone_maldives": "(UTC+05:00) Malediwy",
  "timezone_yekaterinburg": "(UTC+05:00) Moskwa+02 - Jekaterynburg",
  "timezone_kerguelen": "(UTC+05:00) Kerguelen",
  "timezone_mawson": "(UTC+05:00) Mawsona",
  "timezone_tashkent": "(UTC+05:00) Taszkent",
  "timezone_colombo": "(UTC+05:30) Kolombo",
  "timezone_kathmandu": "(UTC+05:45) Katmandu",
  "timezone_omsk": "(UTC+06:00) Moskwa+03 - Omsk",
  "timezone_india_standard_time": "(UTC+05:30) Czas standardowy w Indiach",
  "timezone_chagos": "(UTC+06:00) Chagos",
  "timezone_dhaka": "(UTC+06:00) Dhaka",
  "timezone_bishkek": "(UTC+06:00) Biszkek",
  "timezone_cocos": "(UTC+06:30) Kokosy",
  "timezone_thimphu": "(UTC+06:00) Thimphu",
  "timezone_almaty": "(UTC+06:00) Ałmaty",
  "timezone_vostok": "(UTC+06:00) Wostok",
  "timezone_rangoon": "(UTC+06:30) Rangun",
  "timezone_christmas": "(UTC+07:00) Boże Narodzenie",
  "timezone_davis": "(UTC+07:00) Davisa",
  "timezone_karachi": "(UTC+05:00) Karaczi",
  "timezone_hanoi": "(UTC+07:00) Hanoi",
  "timezone_beijing": "(UTC+08:00) Czas chiński – Pekin",
  "timezone_hongkong": "(UTC+08:00) Hongkong",
  "timezone_hovd": "(UTC+07:00) Hovd",
  "timezone_jakarta": "(UTC+07:00) Dżakarta",
  "timezone_kuala_lumpur": "(UTC+08:00) Kuala Lumpur",
  "timezone_bangkok": "(UTC+07:00) Bangkok",
  "timezone_krasnoyarsk": "(UTC+07:00) Moskwa+04 - Krasnojarsk",
  "timezone_brunei": "(UTC+08:00) Brunei",
  "timezone_macau": "(UTC+08:00) Makau",
  "timezone_makassar": "(UTC+08:00) Makassar",
  "timezone_choibalsan": "(UTC+08:00) Choibalsan",
  "timezone_ulaanbaatar": "(UTC+08:00) Ułan Bator",
  "timezone_manila": "(UTC+08:00) Manila",
  "timezone_pyongyang": "(UTC+08:30) Pjongjang",
  "timezone_singapore": "(UTC+08:00) Singapur",
  "timezone_irkutsk": "(UTC+08:00) Moskwa+05 - Irkuck",
  "timezone_taipei": "(UTC+08:00) Tajpej",
  "timezone_jayapura": "(UTC+09:00) Dżajapura",
  "timezone_dili": "(UTC+09:00) Dili",
  "timezone_perth": "(UTC+08:00) Czas zachodni – Perth",
  "timezone_yakutsk": "(UTC+09:00) Moskwa+06 - Jakuck",
  "timezone_palau": "(UTC+09:00) Palau",
  "timezone_seoul": "(UTC+09:00) Seul",
  "timezone_tokyo": "(UTC+09:00) Tokio",
  "timezone_darwin": "(UTC+09:30) Czas centralny – Darwin",
  "timezone_dumont": "(UTC+10:00) Dumont D'Urville",
  "timezone_brisbane": "(UTC+10:00) Czas wschodni – Brisbane",
  "timezone_guam": "(UTC+10:00) Guam",
  "timezone_vladivostok": "(UTC+10:00) Moskwa+07 - Władywostok",
  "timezone_port_moresby": "(UTC+10:00) Port Moresby",
  "timezone_truk": "(UTC+10:00) Truk",
  "timezone_adelaide": "(UTC+10:30) Czas centralny – Adelajda",
  "timezone_casey": "(UTC+11:00) Czas wschodni – Hobart",
  "timezone_melbourne_sydney": "(UTC+11:00) Czas wschodni – Melbourne, Sydney",
  "timezone_kosrae": "(UTC+11:00) Kosrae",
  "timezone_efate": "(UTC+11:00) Los",
  "timezone_guadalcanal": "(UTC+11:00) Guadalcanal",
  "timezone_ponape": "(UTC+11:00) Ponape",
  "timezone_norfolk": "(UTC+11:00) Norfolk",
  "timezone_magadan": "(UTC+11:00) Moskwa+08 - Magadan",
  "timezone_noumea": "(UTC+11:00) Numea",
  "timezone_majuro": "(UTC+12:00) Majuro",
  "timezone_funafuti": "(UTC+12:00) Funafuti",
  "timezone_nauru": "(UTC+12:00) Nauru",
  "timezone_tarawa": "(UTC+12:00) Tarawa",
  "timezone_kwajalein": "(UTC+12:00) Kwajalein",
  "timezone_enderbury": "(UTC+13:00) Enderbury",
  "timezone_wake": "(UTC+12:00) Wake",
  "timezone_fakaofo": "(UTC+13:00) Fakaofo",
  "timezone_wallis": "(UTC+12:00) Wallis",
  "timezone_fiji": "(UTC+13:00) Fidżi",
  "timezone_apia": "(UTC+14:00) Apia",
  "timezone_kiritimati": "(UTC+14:00) Kiritimati",
  "timezone_tongatapu": "(UTC+13:00) Tongatapu",
  "timezone_auckland": "(UTC+13:00) Okland",
  "dateFormet": "Format daty",
  "Org_success": "Prośba o dołączenie do organizacji została wysłana pomyślnie",
  "Org_err": "Dołączyłeś już do organizacji",
  "Request_Organization": "Poproś o organizację",
  "organization_requested": "Organizacja zwróciła się z prośbą",
  "join": "Dołącz",
  "your_email": "e-mail@adres.com",
  "medical_summary": "Nie wypełniłeś jeszcze swojej karty zdrowia.",
  "medical_summary_msg": "Moje karta zdrowia",
  "medical_success": "Karta zdrowia przesłana pomyślnie",
  "patient_form_popup": "Czy chcesz kontynuować uzupełnianie kwestionariusza w miejscu, gdzie wcześniej zakończyłeś?",
  "Please_confirm_that_your_details_are_correct": "Proszę potwierdzić poprawność Twoich danych.",
  "Welcome_to_Akunah_your_Health_Passport": "Witamy w Akunah, oto Twój paszport zdrowia.",
  "Your_helth_is_in_your_hands": "Twoje zdrowie jest w Twoich rękach.",
  "Lets_begin_your_journey": "Rozpocznijmy Twoją podróż.",
  "Please_complete_your_profile": "Proszę uzupełnić Twój profil.",
  "Profile_complete": "Profil uzupełniony!",
  "Would_you_like_to_add_another_profile_for_a_family_member": "Czy chcesz dodać kolejny profil dla członka Twojej rodziny?",
  "Alert_detected": "Uwaga: Wykryto wiele prób logowania",
  "To_safeguard_your_data": "Aby chronić Twoją prywatność i bezpieczeństwo danych, strona internetowa została automatycznie wylogowana z powodu braku aktywności.",
  "Kindly_close_all_other_Akunah": "Zamknij wszystkie inne karty Akunah i naciśnij „Powrót do strony głównej”, aby kontynuować.",
  "Akunah_Team_is_dedicated": "Zespół Akunah dokłada wszelkich starań, aby chronić Twoje dane.",
  "Last_Created": "Ostatnio utworzone",
  "First_Created": "Najwcześniej utworzone",
  "Alphabetically": "Alfabetycznie (A-Z)",
  "Alphabetically1": "Alfabetycznie (Z-A)",
  "Your_form_submission_was_successful_Clinician": "Twój formularz zgłoszeniowy został pomyślnie przesłany. Teraz automatycznie przekierowujemy Cię do aplikacji Lekarz.",
  "Your_form_submission_was_successful_Patient": "Twój formularz zgłoszeniowy został pomyślnie przesłany. Teraz automatycznie przekierowujemy Cię do aplikacji Pacjent.",
  "If_this_doesnt_happen_within": "Jeśli to nie nastąpi w ciągu 5 sekund, prosimy",
  "click_here": "Kliknij tutaj",
  "Save": "Zapisz",
  "Proceed_to_my_dashboard": "Przejdź do mojego pulpitu",
  "myJourney": "Moja podróż",
  "Home": "Główna",
  "No_journey": "Nie znaleziono podróży",
  "Share_results_with_your_doctor": "Udostępnij wyniki swojemu lekarzowi",
  "If_you_would_like_to_share_your_results_with": "Jeśli chcesz udostępnić wyniki swojemu lekarzowi, wybierz opcję Udostępnij TERAZ poniżej",
  "Link_PROMs": "Linki PROM-ów",
  "Welcome_to_akunah": "Witamy w akunah",
  "Share_to_your_Doctor_using_one_of_the_3_options_below": "Podziel się z lekarzem, korzystając z jednej z 3 poniższych opcji",
  "Doctor_Code": "Kod doktora",
  "Enter_the_code_that_has_been_provided_to_you_by_your": "Wprowadź kod, który został Ci przekazany przez lekarza w e-mailu lub w klinice. Jeśli kod jest niedostępny, sprawdź swoje ostatnie wizyty lub wyszukaj swojego lekarza",
  "Recent_Visits": "Ostatnie wizyty",
  "Select_your_recent_doctor_or": "Wybierz swojego ostatniego lekarza lub",
  "organisation_here": "organizacje tutaj",
  "Search": "Szukaj",
  "Search_for_your_doctor_or": "Wyszukaj swojego lekarza lub",
  "Please_confirm_that_the_following_details": "Proszę potwierdzić, że poniższe dane są poprawne",
  "Organisation": "Organizacja",
  "PROMs_Specialty_Date": "Specjalności i daty PROMs",
  "Get_started_by_completing_your": "Zacznij od wypełnienia kwestionariuszy PROMs",
  "and_exploring_your_progress": "i odkryj swoje postępy",
  "Fill_up_your_PROMs_questionnaire": "Wypełnij kwestionariusz PROMs",
  "Hip": "Biodro",
  "My_Progress": "Mój postęp",
  "Completed": "Zakończony",
  "Expired": "Wygasły",
  "Incomplete": "Niekompletny",
  "Specialty": "Specjalność",
  "Doctor": "Lekarz",
  "Status": "Status",
  "View_form": "Wyświetl formularz",
  "Share_to_another_doctor": "Podziel się z innym lekarzem",
  "Edit_form": "Edytuj formularz",
  "Answer_the_form": "Odpowiedz na formularz",
  "Complete_the_form": "Wypełnij formularz",
  "Delete_the_form": "Usuń formularz",
  "Share_to_doctor": "Podziel się z lekarzem",
  "Your_account_has_been_created": "Twoje konto zostało utworzone",
  "Please_click_below_to_proceed_to_your_dashboard": "Kliknij poniżej, aby przejść do pulpitu nawigacyjnego",
  "If_you_would_like_to_add_another_profile_for_a_family": "Jeśli chcesz dodać kolejny profil członka rodziny,",
  "Share": "Udział",
  "Hip_Scoring_Self_Evaluation_Questionnaire": "Kwestionariusz samooceny stawu biodrowego",
  "Hand_and_Wrist_Scoring_Self_Evaluation_Questionnaire": "Kwestionariusz samooceny dłoni i nadgarstka",
  "Right_Hand_nd_Wrist": "Prawa ręka i nadgarstek",
  "Left_Hand_nd_Wrist": "Lewa ręka i nadgarstek",
  "DOB_1": "Data urodzenia",
  "Sex_1": "Płeć",
  "Address_1": "Adres",
  "Please_Choose_PROMs_to_link": "Proszę wybrać PROM-y do połączenia",
  "Please_select_atleast_one_form_or_close": "Proszę wybrać co najmniej jeden PROM lub zamknąć",
  "cant_find_appropriate_PROMs_to_link": "Nie możesz znaleźć odpowiednich PROM-ów do linkowania?",
  "Request_New_PROMs": "Poproś o nowe PROM-y",
  "Warning_This_patient_has_an_incomplete_patient_PROMs_from_a_recent_visit": "Ostrzeżenie: Posiadasz niekompletny kwestionariusz z ostatniej wizyty.",
  "If_you_select_No_a_new_patient_PROMs_will_be_created_and_the_incomplete_PROMs_will_be_deleted": "Jeśli wybierzesz „Nie”, zostanie utworzony nowy kwestionariusz, a niekompletny kwestionariusz zostanie usunięty.",
  "Would_you_like_to_continue_this_form": "Czy chcesz kontynuować?",
  "Note": "Notatka:",
  "Expiring_in": "Wygasa za",
  "Date_Created": "Data utworzenia",
  "Days": "Dni",
  "Shared": "Wspólny",
  "Pending": "Aż do",
  "Initial": "Wstępny",
  "Recent": "Ostatni",
  "Pain": "Ból",
  "Function": "Funkcjonować",
  "Share_Now": "Udostępnij teraz",
  "Share_Later": "Udostępnij później",
  "Updated": "Zaktualizowano",
  "Display_More": "Pokaż więcej",
  "Address_Now": "Adres teraz",
  "Address_Later": "Adres później",
  "You_have_pending_questionnaires": "Masz oczekujące kwestionariusze",
  "Notifications": "Powiadomienia",
  "Form_shared_successfully": "Formularz udostępniony pomyślnie",
  "You_have_already_shared_with_same_doctor_and_organization": "Udostępniłeś już to temu samemu lekarzowi i organizacji",
  "No_recent_notifications": "Brak ostatnich powiadomień",
  "Have_a_good_day": "Miłego dnia",
  "Patient_scores": "Wyniki pacjentów",
  "Display less": "Wyświetl mniej",
  "myJourney_Shoulder": "Bark",
  "myJourney_Shoulder_txt": "Wybierz Bark, aby wyświetlić Rehabilitację Barku",
  "myJourney_Elbow": "Łokieć",
  "myJourney_Elbow_txt": "Wybierz łokieć, aby zobaczyć swoją Rehabilitację Łokcia",
  "myJourney_Hand_Wrist": "Dłoń i nadgarstek",
  "myJourney_Hand_Wrist_txt": "Wybierz opcję Dłoń i nadgarstek, aby wyświetlić swoją Rehabilitację Dłoni i nadgarstka",
  "myJourney_Hip": "Biodro",
  "myJourney_Hip_txt": "Wybierz Biodro, aby zobaczyć swoją Rehabilitację Biodra",
  "myJourney_knee": "Kolano",
  "myJourney_knee_txt": "Wybierz Kolano, aby zobaczyć swoją Rehabilitację Kolana",
  "myJourney_Foot_Ankle": "Stopa i kostka",
  "myJourney_Foot_Ankle_txt": "Wybierz opcję Stopa i kostka, aby wyświetlić swoją historię dotyczącą stopy i kostki",
  "data_sort": "Sortować",
  "Visit_Clinic": "Odwiedź klinikę",
  "My_Visits": "Moje wizyty",
  "Patient_Scoring_Forms": "Formularze oceny pacjenta",
  "Other_findings": "Inne ustalenia",
  "Organization": "Organizacja",
  "more": "Więcej",
  "mediacal_summary": "Podsumowanie medyczne",
  "my_consent": "Moje zgody",
  "Are_you_sure_you_want_to_reject": "Czy na pewno chcesz zablokować to polecenie?",
  "reject": "Odrzucić",
  "i_accept_consent": "Wyrażam na to zgodę",
  "grant": "Dotacja",
  "no_conscent_found": "Nie znaleziono zgody",
  "rejected": "Odrzucony",
  "No_Time_Lines": "Brak osi czasu",
  "Hide_Score": "Ukryj wynik",
  "Show_Score": "Pokaż wynik",
  "patient_sex": "Płeć",
  "Dominant_hand_1": "Dominująca ręka",
  "patient_Initial_Assessment": "Ocena wstępna",
  "patient_Treatment_Category": "Kategoria leczenia",
  "View_all": "Zobacz wszystko",
  "Display_Less": "Wyświetl mniej",
  "patient_general_past_Medical_history": "Historia medyczna",
  "patient_general_past_Medication_name": "Lista leków",
  "Evaluated_Knee": "Ocenione kolano",
  "Is_your_unaffected_Hand_Wrist_pain_free_and_allows_normal_function": "Czy odczuwasz ból w swojej zdrowej ręce i łokciu oraz czy zostały zachowane ich naturalne funkcje motoryczne?",
  "Evaluated_Hip": "Oceniony staw biodrowy",
  "Evaluated_Foot_Ankle": "Oceniono stopę i kostkę",
  "patient_Evaluated_Elbow": "Oceniony łokieć",
  "Is_your_unaffected_Foot_Ankle_pain_free_and_allows_normal_function": "Czy odczuwasz ból w niedotkniętej stopie i kostce i czy mogą normalnie funkcjonować?",
  "Evaluated_Hand_Wrist": "Oceniono dłoń i nadgarstek",
  "patient_shoulder_feeling_instability_q2": "Proszę wskazać, jak niestabilny jest Twój bark",
  "patient_shoulder_feeling_instability_q3": "Proszę wskazać, jak niestabilny jest Twój bark",
  "patient_Hand_BCTQ": "BCTQ dłoni i nadgarstka",
  "Paediatric_Knee_Satisfaction": "Satysfakcja z kolan pediatrycznych",
  "patient_foot_ankle_symptoms": "Stopa i kostka - inne objawy",
  "Right_Foot_and_Ankle": "Prawa stopa i kostka",
  "Left_Foot_and_Ankle": "Lewa stopa i kostka",
  "summary": "Streszczenie",
  "Shoulder_Treatment_Form": "Formularz leczenia barku",
  "Adolescent_knee_Treatment_Form": "Formularz leczenia kolana u nastolatków",
  "Adult_Knee_Treatment_Form": "Formularz leczenia kolana u dorosłych",
  "Paediatric_Knee_Treatment_Form": "Formularz leczenia kolana u dzieci",
  "Hip_Treatment_Form": "Formularz leczenia stawu biodrowego",
  "Foot_Ankle_Treatment_Form": "Formularz leczenia stóp i kostek",
  "Elbow_Treatment_Form": "Formularz leczenia łokcia",
  "Hand_Wrist_Treatment_Form": "Formularz leczenia dłoni i nadgarstka",
  "Upload_complete": "Przesyłanie zakończone",
  "Select_Date_Format": "Wybierz format daty",
  "timezone_casey1": "(UTC+11:00) Casey",
  "timezone_Kamchatskiy": "(UTC+12:00) Moskwa+09 - Pietropawłowsk Kamczacki",
  "timezone_Guayaquil": "(UTC-05:00) Guayaquil",
  "requested": "poprosił o",
  "Display_name": "Nazwa wyświetlana",
  "Are_you_sure_you_want_to_delete_Consent": "Czy na pewno chcesz odrzucić tę zgodę?",
  "form": "formularz",
  "Requested_by": "Na prośbę",
  "requested_hip_form": "poproszono o formularz dotyczący barku",
  "requested_shoulder_form": "poproszono o formularz dotyczący barku",
  "requested_knee_form": "poproszono o formularz dotyczący kolana",
  "requested_foot_form": "poproszono o formularz dotyczący stóp i kostek",
  "requested_hand_form": "poproszono o formularz dłoni i nadgarstka",
  "requested_elbow_form": "poproszono o formularz Elbow",
  "requested_General_form": "poproszono o formularz ogólny",
  "requested_Paediatric_form": "poproszono o formularz dotyczący kolana pediatrycznego",
  "requested_Adolescent_form": "poproszono o formularz Adolescent Knee",
  "Expiring_in_Tomorrow": "Wygasa jutro",
  "PROMs_questionnaire": "Dr [imię i nazwisko lekarza, który poprosił] poprosił Cię o wypełnienie kwestionariusza PROMs dotyczącego [specjalizacji]",
  "new_message": "Masz nową wiadomość od [pełna nazwa nadawcy wiadomości]",
  "new_appointment": "Masz nową wizytę u doktora [imię i nazwisko doktora]",
  "upcoming_appointment": "Przypomnienie: nadchodząca wizyta u dr. [imię i nazwisko] w dniu [data]",
  "pending_message": "Przypomnienie: masz oczekującą ankietę PROMs [specjalność]",
  "document_message": "Dr [imię i nazwisko osoby, która udostępniła] udostępnił Ci dokument",
  "requested_message": "Lekarz [imię i nazwisko osoby, która złożyła wniosek] zwrócił się o wyrażenie zgody",
  "patient_added_message": "Zostałeś dodany jako pacjent dr [imię i nazwisko] w [organizacja]",
  "cancelled_appointment_message": "Twoja wizyta u dr. [imię i nazwisko] w dniu [data wizyty] została odwołana.",
  "Mark_all_read": "Oznacz wszystkie jako przeczytane",
  "Delete_All": "Usuń wszystko",
  "No_Notification": "Nie znaleziono powiadomień",
  "You_have_not": "Jeszcze nie rozpocząłeś swojej podróży. Wypełnij kwestionariusz PROMs, aby rozpocząć swoją podróż.",
  "pass_verification1": "Hasło powinno mieć co najmniej 8 znaków *",
  "pass_verification2": "Hasło musi zawierać co najmniej 1 wielką literę*",
  "pass_verification3": "Hasło musi zawierać co najmniej 1 małą literę*",
  "pass_verification4": "Hasło musi zawierać co najmniej 1 cyfrę *",
  "loginTitle": "Akunah:Zaloguj się ::",
  "Create_patient_mobile": "Utwórz nowy profil",
  "Select_Profile_mobile": "Wybierz swój profil",
  "Profile_Created_mobile": "Profil utworzony",
  "Your_account_has_been_created_mobile": "Twoje konto zostało utworzone.",
  "Please_tap_below_to_sign_in_to_your_workspace_mobile": "Kliknij poniżej, aby zalogować się do swojego obszaru roboczego.",
  "Start_Journey_mobile": "Rozpocznij podróż",
  "If_add_another_profile_mobile": "Jeśli chcesz dodać kolejny profil członka rodziny,",
  "Create_Profile_mobile": "Utwórz profil",
  "change_Profile_Pic_mobile": "Zmień zdjęcie profilowe",
  "MobileImp_mobile": "Mobilny*",
  "Gender_mobile": "Płeć",
  "Country_1_mobile": "Kraj",
  "Med_1_mobile": "Śr.",
  "Appoinments_mobile": "Spotkania",
  "View_Profile_mobile": "Zobacz profil",
  "Sign_out_mobile": "Wyloguj się",
  "My_Appointments_mobile": "Moje spotkania",
  "Appoinments_Past_mobile": "Przeszłość",
  "No_appointments_mobile": "Brak spotkań",
  "When_appointment_available_mobile": "Gdy Twój następny termin będzie dostępny, zostanie on tutaj wyświetlony",
  "Available_soon_mobile": "Dostępne wkrótce!",
  "please_akunah_desktop_mobile": "Aby uzyskać do niego dostęp, zaloguj się na swoim komputerze stacjonarnym na me.akunah.com.",
  "All_Notes_mobile": "Wszystkie notatki",
  "AddButton_mobile": "Dodaj przycisk",
  "Start_your_note_mobile": "Rozpocznij notatkę...",
  "Done_mobile": "Zrobione",
  "Note_Title_mobile": "Tytuł notatki",
  "New_Consents_mobile": "Nowe zgody",
  "Signed_Consents_mobile": "Podpisane zgody",
  "Consent_mobile": "Zgoda",
  "Signed_Type_mobile": "Podpisane przez typ",
  "Submit_Consent_mobile": "Prześlij zgodę",
  "Reject_Consent_mobile": "Odrzuć zgodę",
  "Type_name_mobile": "Wpisz swoje imię",
  "Signature_mobile": "Podpis",
  "No_consents_mobile": "Brak zgód",
  "Any_display_ready_mobile": "Wszystkie nowe lub podpisane zgody będą wyświetlane tutaj, gdy będą gotowe",
  "Clinician_Documents_mobile": "Dokumenty lekarza",
  "Uploaded_Documents_mobile": "Przesłane dokumenty",
  "Upload_Document_mobile": "Prześlij dokument",
  "Upload_file_mobile": "Prześlij plik",
  "Upload_photo_mobile": "Prześlij zdjęcie",
  "Take_photo_mobile": "Zrób zdjęcie",
  "Uploading_1_mobile": "Przesyłanie",
  "New_1_mobile": "Nowy",
  "Share_document_mobile": "Udostępnij ten dokument",
  "Enter_email_mobile": "Wprowadź adres e-mail",
  "Add_message_like_mobile": "Jeśli chcesz, dodaj wiadomość.",
  "Are_delete_account_mobile": "Czy na pewno chcesz usunąć swoje konto?",
  "No_display_mobile": "Brak dokumentów do wyświetlenia",
  "All_clinician_documents_mobile": "Wszystkie dokumenty lekarzy będą wyświetlane tutaj",
  "New_Messages_mobile": "Nowe wiadomości",
  "Read_Messages_mobile": "Przeczytaj wiadomości",
  "Any_new_unread_messages_ready_mobile": "Wszystkie nowe lub nieprzeczytane wiadomości będą wyświetlane tutaj, gdy będą gotowe",
  "Shoulder_Journey_mobile": "Rehabilitacja barku",
  "Hip_Journey_mobile": "Rehabilitacja biodra",
  "Foot_Ankle_Journey_mobile": "Rehabilitacja stopy i kostki",
  "Elbow_Journey_mobile": "Rehabilitacja łokcia",
  "Knee_Journey_mobile": "Rehabilitacja kolana",
  "Hand_Wrists_Journey_mobile": "Rehabilitacja dłoni i nadgarstka",
  "New_Form_mobile": "Nowy formularz",
  "Start_new_form_mobile": "Rozpocznij nowy formularz",
  "Requested_Forms_mobile": "Wymagane formularze",
  "Incomplete_Forms_mobile": "Niekompletne formularze",
  "Completed_Forms_mobile": "Wypełnione formularze",
  "No_Available_mobile": "Brak dostępnych formularzy",
  "Start_new_journey_mobile": "Wypełnij nowy formularz, aby rozpocząć swoją podróż.",
  "View_Results_mobile": "Wyświetl wyniki",
  "Complete_Form_mobile": "Wypełnij formularz",
  "Delete_Form_mobile": "Usuń formularz",
  "Edit_Details_mobile": "Edytuj szczegóły",
  "Personal_Details_mobile": "Dane osobowe",
  "DOB_1_mobile": "Data urodzenia",
  "Unknown_Gender_mobile": "Nieznana płeć",
  "Mobile_mobile": "Przenośny",
  "Next_kin’s_name_mobile": "Imię i nazwisko najbliższego krewnego",
  "Next_kin’s_relationship_mobile": "Relacje pokrewieństwa",
  "GP_Details_mobile": "Szczegóły GP",
  "GP_Name_mobile": "Imię lekarza rodzinnego",
  "Practice_mobile": "Praktyka",
  "Edit_Profile_mobile": "Edytuj profil",
  "Full_Name_mobile": "Pełne imię i nazwisko",
  "Country_Code_mobile": "Kod kraju",
  "GP_Full_Name_mobile": "Imię i nazwisko lekarza rodzinnego",
  "Doctor’s_full_name_mobile": "Pełne imię i nazwisko lekarza",
  "All_mobile": "Wszystko",
  "No_notifications_mobile": "Brak ostatnich powiadomień",
  "Any_new_available_mobile": "Wszystkie nowe lub nieprzeczytane powiadomienia będą wyświetlane tutaj, gdy będą dostępne",
  "Account_mobile": "Konto",
  "Manage_My_Account_mobile": "Zarządzaj moim kontem",
  "Change_Password_mobile": "Zmień hasło",
  "Privacy_mobile": "Prywatność",
  "Time_mobile": "Czas",
  "Date_Format_mobile": "Data i format",
  "Support_mobile": "Wsparcie",
  "All_Notifications_mobile": "Wszystkie powiadomienia",
  "Receive_notifications_mobile": "Otrzymuj wszystkie powiadomienia",
  "Journey_Updates_mobile": "Aktualności z podróży",
  "Receive_appointment_notifications_mobile": "Otrzymuj wszystkie powiadomienia o spotkaniach",
  "Start_Form_mobile": "Rozpocznij formularz",
  "Your_journey_starts_here_mobile": "Twoja podróż zaczyna się tutaj",
  "To_recovery_questions_mobile": "Aby rozpocząć Twoją podróż ku wyzdrowieniu, prosimy Cię o udzielenie odpowiedzi na poniższe pytania.",
  "Please_estimate_questions_mobile": "Na odpowiedź na te pytania należy przeznaczyć około 12–15 minut.",
  "Shoulder_Form_mobile": "Forma barku",
  "Save_and_Continue_mobile": "Zapisz i kontynuuj",
  "How_years_smoke_mobile": "Przez ile lat paliłeś?",
  "patient_shoulder_pain_inner_value_mobile": "(0 oznacza brak bólu, 15 oznacza ból nie do zniesienia)",
  "Scoring_Key_mobile": "Klucz do punktacji",
  "patient_shoulder_wosi_1_mobile": "Wskaźnik niestabilności barku w zachodnim Ontario (WOSI)",
  "patient_shoulder_wosi_sectionA_phy_mobile": "OBJAWY FIZYCZNE",
  "patient_shoulder_wosi_sectionB_sp_mobile": "SPORT/REKREACJA/PRACA",
  "patient_shoulder_wosi_sectionC_life_mobile": "STYL ŻYCIA",
  "patient_shoulder_wosi_sectionD_emo_mobile": "EMOCJE",
  "Specific_AC_Score_mobile": "Szczegółowy wynik AC (SACS)",
  "patient_shoulder_sacs_sectionA_pain_mobile": "Ból",
  "patient_shoulder_sacs_sectionB_function_mobile": "FUNKCJA",
  "patient_shoulder_sacs_sectionC_Qua_mobile": "Jakość życia i satysfakcja",
  "Finish_mobile": "Koniec",
  "Share_Results_mobile": "Udostępnij wyniki",
  "Share_Doctor_mobile": "Udostępnij swojemu lekarzowi",
  "Choose_share_results_doctor_mobile": "Wybierz jedną z 3 opcji poniżej, aby udostępnić wyniki swojemu lekarzowi",
  "Share_your_Clinician_mobile": "Podziel się z lekarzem",
  "Select_recent_doctor_organisation_mobile": "Wybierz tutaj swojego ostatniego lekarza lub organizację",
  "Clinician_Search_mobile": "Wyszukiwanie klinicystów",
  "Search_doctor_organisation_mobile": "Wyszukaj tutaj swojego lekarza lub organizację",
  "Clinician_Code_mobile": "Kodeks klinicysty",
  "Enter_doctor_code_search_mobile": "Wprowadź kod, który został Ci przekazany przez lekarza w e-mailu lub w klinice. Jeśli kod jest niedostępny, sprawdź ostatnie wizyty lub skorzystaj z wyszukiwarki.",
  "Skip_sharing_now_mobile": "Na razie pomiń udostępnianie",
  "Code_please_try_again_mobile": "Kod jest nieprawidłowy. Spróbuj ponownie.",
  "Confirm_details_mobile": "Potwierdź szczegóły",
  "PROMs_Speciality_Date_mobile": "Specjalności i daty PROMs",
  "Results_Shared_mobile": "Wyniki udostępnione!",
  "Your_successfully_doctor_mobile": "Twoje wyniki zostały pomyślnie udostępnione Twojemu lekarzowi.",
  "Please_results_mobile": "Kliknij poniżej, aby wyświetlić wyniki.",
  "If_another_doctor_mobile": "Jeśli chcesz udostępnić dane innemu lekarzowi",
  "please_mobile": "Proszę",
  "click_here_mobile": "Kliknij tutaj",
  "Pain_Score_mobile": "Wynik bólu",
  "Recent_Scores_mobile": "Ostatnie wyniki",
  "Right_Arm_mobile": "Prawa ręka",
  "SACS_Score_mobile": "SACS",
  "complete_mobile": "Kompletny",
  "Add_Note_mobile": "Dodaj notatkę",
  "Pending_Forms_mobile": "Oczekujące formularze",
  "Your_pending__will_display_mobile": "Twoje oczekujące formularze zostaną wyświetlone tutaj",
  "Your_Incomplete_display_mobile": "Twoje niekompletne formularze będą wyświetlane tutaj",
  "Your_complete_display_mobile": "Twoje kompletne formularze będą tutaj wyświetlane",
  "PROMs_Shoulder_request_mobile": "Prośba o ramię PROMs",
  "New_message_mobile": "Nowa wiadomość",
  "New_appointment_mobile": "Nowe spotkanie",
  "New_Consent_request_mobile": "Nowe żądanie zgody",
  "New_shared_document_mobile": "Nowy udostępniony dokument",
  "New_Organisation_Clinician_mobile": "Nowa organizacja/lekarz",
  "This_field_cannot_empty_mobile": "To pole nie może być puste.",
  "List_work_activities_mobile": "Wypisz czynności zawodowe ...",
  "List_hobby_activities_mobile": "Wymień swoje hobby i aktywności sportowe...",
  "patient_shoulder_wosi_sectionA_mobile": "SEKCJA A:",
  "patient_shoulder_wosi_sectionB_mobile": "SEKCJA B:",
  "patient_shoulder_wosi_sectionC_mobile": "Sekcja C:",
  "patient_shoulder_wosi_sectionD_mobile": "Sekcja D:",
  "File_size_should_than_10MB_mobile": "File size should not more than 10MB",
  "Share_this_document_mobile": "Udostępnij ten dokument",
  "sac_explaination": "Wyjaśnienie",
  "patient_shoulder_sacs_explanation_mobile": "W przypadku każdego pytania zostaniesz poproszony o ocenę swojego poziomu bólu lub trudności w wykonywaniu różnych zadań. Skala ocen jest zaprojektowana tak, że 0 oznacza brak bólu lub trudności, a 10 oznacza najsilniejszy poziom bólu lub trudności.",
  "patient_shoulder_sacs_a3_note_mobile": "(np. pasek do laptopa lub torby).",
  "patient_shoulder_sacs_b2_note_mobile": "(np. oparcie ręki w kieszeni lub użycie temblaka).",
  "patient_shoulder_sacs_b3_note_mobile": "(np. pompki, jazda na rowerze, joga).",
  "patient_shoulder_sacs_b4_note_mobile": "(np. Torba z zakupami).",
  "patient_shoulder_sacs_b5_note_mobile": "(np. wyciągnąć rękę i dotknąć nieuszkodzonego ramienia).",
  "patient_shoulder_sacs_b6_note_mobile": "(np. Ponad pięć kilogramów).",
  "patient_shoulder_sacs_b7_note_mobile": "(np. trzymanie kierownicy podczas jazdy).",
  "patient_shoulder_sacs_c1_note_mobile": "(W tym obecność guzka).",
  "Function_Score_mobile": "Wynik funkcji",
  "Instability_Score_mobile": "Wynik niestabilności",
  "patient_shoulder_pain_q2_mobile": "W skali od 0 do 15, jaki jest najwyższy poziom bólu, którego doświadczyłeś/aś w ramieniu podczas wykonywania codziennych czynności w ciągu ostatnich 24 godzin?",
  "patient_shoulder_pain_q6_mobile": "Czy bierzesz leki przeciwbólowe?",
  "patient_shoulder_pain_q6_mobile_sub": "(np. paracetamol itp.)?",
  "patient_shoulder_pain_q6_a2_q1_mobile": "Czy bierzesz silne leki przeciwbólowe?",
  "patient_shoulder_pain_q6_a2_q1_mobile_sub": "(np. kodeina, tramadol, morfina itp.)?",
  "patient_shoulder_feeling_instability_q1_b1_mobile_sub": "(jakby miało się zwichnąć)?",
  "patient_shoulder_feeling_instability_q1_mobile": "Czy czujesz, że twoje ramię jest niestabilne?",
  "patient_shoulder_wosi_instruction_mobile": "INSTRUKCJE",
  "Next_of_kins_number_mobile": "Numer najbliższej rodziny",
  "Next_of_Kin_mobile": "Najbliższa rodzina",
  "Doctor_Name_mobile": "Nazwisko lekarza",
  "Doctors_practice_mobile": "Praktyka lekarska",
  "Doctors_practice_address_mobile": "Adres gabinetu lekarskiego",
  "Language_mobile": "Języki",
  "No_Internet_Connection_mobile": "Brak połączenia internetowego",
  "Please_type_your_name_mobile": "Proszę wpisać swoje imię",
  "Choose_Sign_Type_mobile": "Wybierz typ znaku",
  "Signed_by_Draw_mobile": "Podpisane przez Draw",
  "select_image_mobile": "wybierz obraz",
  "Consent_rejected_mobile": "Zgoda odrzucona",
  "Consent_submitted_mobile": "Zgoda została wysłana",
  "Today_mobile": "Today",
  "New_mobile": "New",
  "No_recent_scores_display_mobile": "No recent scores to display",
  "All_recent_scores_display_here_mobile": "All recent scores will display here",
  "Shared_Documents_mobile": "Shared Documents"
}