import React, { useState, useEffect } from "react";
import Button from "../../custom/button/Button";
import AdultKneeFunction from "./AdultKneeFunction";
import AdultKneePainScoring from "./AdultKneePainScoring";
import AdultKneeQOL from "./AdultKneeQOL";
import AdultKneeSatisfaction from "./AdultKneeSatisfaction";
import AdultKneeSymtoms from "./AdultKneeSymtoms";
import PersonalInformation from "./PersonalInformation";
import AdultKneeDoctor from "./AdultKneeDoctor";
import "../../../css/ViewTreatmentForm.css";
import useAxios from "../../../axiosinstance";
import { useParams, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../custom/loader/LoadingSpinner";
import DeleteConfirmation from "../../custom/delete-confirmation/DeleteConfirmation";
import { calculateAge } from "../../../utilities/AgeCalculate";
import useTranslation from "../../customHooks/translations";
export default function AdultKneeTreatmentForm() {
  const translation = useTranslation();
  const { rId } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const q = queryParams.get("patient");
  const pId = queryParams.get("patient");
  const patientEdit = queryParams.get("patientEdit");
  const doctor_id = queryParams.get("doctor");
  const [editShow1, seteditShow1] = useState(false);
  const management = queryParams.get("management");
  const pScore = queryParams.get("form");
  const action = queryParams.get("action");
  //   console.log(window.atob(q))
  const navigate = useNavigate();
  const axiosInstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  let isdoctorForm = queryParams.get("is_doctor_form");
  let orgid=sessionStorage.getItem("orgid")
  useEffect(() => {
    localStorage.setItem("isdoctorForm", isdoctorForm);
  }, [isdoctorForm]);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  const [AllData, setAllData] = useState("");
  const [profileData, setProfileData] = useState({});
  const [adultPain, setAdultPain] = useState({});
  const [adultFunction, setAdultFunction] = useState({});
  const [adultSymtoms, setAdultSymtoms] = useState({});
  const [adultQOL, setAdultQOL] = useState({});
  const [adultSatisfaction, setAdultSatisfaction] = useState({});
  const [adultNorwich, setAdultNorwich] = useState({});
  const [editShow, seteditShow] = useState(false);
  const [adultDoctorScore, setAdultDoctorScore] = useState({});
  const [viewDoctor, setViewDoctor] = useState("0");
  const [initialAssesment, setInitialAssesment] = useState(0);
  const [viewPatient, setViewPatient] = useState("0");
  const [doctorView, setdoctorView] = useState(false);

  const [displayConfirmationModalDelete, setDisplayConfirmationModalDelete] =
    useState(false);
  const save = () => {
    axiosInstance.current
      .get(
        `extapp/forms/all_forms?resource_id=${rId}&type=5&akpt=${window.atob(
          q
        )}`
      )
      .then((res) => {
        setInitialAssesment(res.data.data2[0].initial_assessment);
        setAllData(res.data.data2[0]);
        setProfileData(res.data.data1);
        setAdultPain(res.data.data2[0].painScore[0]);
        setAdultFunction(res.data.data2[0].functionScore[0]);
        setAdultSymtoms(res.data.data2[0].symptomsScore[0]);
        setAdultQOL(res.data.data2[0].qolScore[0]);
        setAdultSatisfaction(res.data.data2[0].satisfactionScore[0]);
        setAdultNorwich(res.data.data2[0].norwichInstability[0]);
        seteditShow(res.data.status);
        setdoctorView(res.data.doctorStatus);
        setAdultDoctorScore(res.data.data2[0].doctorFeedback[0]);
        seteditShow1(res.data.doctorStatus);
        setViewDoctor(res.data.data2[0].doctor_scoring_date);
        setViewPatient(res.data.data2[0].patient_scoring_date);
        setIsLoading(false);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };
  useEffect(() => {
    save();
  }, []);
  const handleEdit = () => {

    // navigate(
    //   "/adult-knee-pain/" + rId + "?d=true&akpt=" + pId + "&is_doctor_form=true"
    // );
    navigate(
      `/personal-data-edit/${
        pId
      }/${window.btoa(doctor_id)}/${window.btoa(orgid)}/${window.btoa(
        5
      )}?is_doctor_form=true&doctorvalue=${0}&doctor=true&resource_id=${rId}`
    );
  };

  const handleBack = () => {
    window.open(process.env.REACT_APP_DOCTOR_URL + 'patient-view' + "/" + pId);
    window.close();
  };

  const handleEdit1 = () => {
    // localStorage.setItem("doctor",doctor_id)
    // navigate("/elbow-pain/" + rId + "?d=true&akpt=" + pId+"&is_doctor_form=true");

    window.open(
      "/knee-feedback/" +
      rId +
      `?doctor=${doctor_id}&patient=${pId}&is_doctor_form=true`
    );
  };

  const handlePatientEdit = () => {
    navigate("/adult-knee-pain/" + rId + "?is_doctor_form=null&edit=true");
  };
  const hideConfirmationModal = () => {
    setDisplayConfirmationModalDelete(false);
  };
  const submitDelete = (type, e) => {
    handleDelByrID();
  };
  const handleDelByrID = async () => {
    try {
      const result = await axiosInstance.current.patch(
        `extapp/forms/soft_delete_form?resource_id=${rId}`
      );
      if (result.status === 200) {
        window.open(
          process.env.REACT_APP_DOCTOR_URL + "patient-list",
          "_blank"
        );
        window.close();
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      // console.log("api response finally");
    }
  };
  const handleDelete = (e, te) => {
    setDisplayConfirmationModalDelete(true);
  };
  const profileAllData = React.useMemo(() => profileData, [profileData]);

  let userage = calculateAge(profileAllData.pat_dob);

  //  console.log(userage,"userageuserageuserage",profileAllData.ing_dob,typeof(userage));

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="view_treatment_form">
          <div className="row page_header">
            <div className="col-sm-6 col-md-6">
              <h4>
                {" "}
                {userage < 18
                  ? translation.Adolescent_knee_Treatment_Form
                  : translation.Adult_Knee_Treatment_Form}
              </h4>
            </div>
            {management === "true" && (
              <div className={`col-sm-6 col-md-6 text-end`}>
                <Button
                  onClick={() => handleDelete()}
                  value={translation.Delete_Form_mobile}
                  buttonStyle="btn_fill"
                />
                {editShow || doctorView ? (
                  <Button
                    onClick={() => handleEdit()}
                    value={translation.Edit_form}
                    buttonStyle="btn_fill ml_15 "
                  />
                ) : (
                  <Button
                    onClick={() => console.log("")}
                    value={translation.Edit_form}
                    buttonStyle=" ml_15 button_disabled "
                  />
                )}
              </div>
            )}

            {isdoctorForm && management !== "true"&&(
              <div className={`col-sm-6 col-md-6 text-end`}>
                <Button
                  onClick={() => handleBack()}
                  value={translation.back_button}
                  buttonStyle="btn_fill ml_15 "
                />

                {editShow === true && (
                  <Button
                    onClick={() => handleEdit1()}
                    value={translation.Edit_form}
                    buttonStyle="btn_fill ml_15 "
                  />
                )}
              </div>
            )}

            {patientEdit === "true" && (
              <div className={`col-sm-6 col-md-6 text-end`}>
                {editShow && (
                  <Button
                    onClick={() => handlePatientEdit()}
                    value={translation.Edit_form}
                    buttonStyle="btn_fill ml_15 "
                  />
                )}
              </div>
            )}
            <DeleteConfirmation
              showModal={displayConfirmationModalDelete}
              confirmModal={submitDelete}
              hideModal={hideConfirmationModal}
              message={"Form"}
              type="task_id"
              id={rId}
            />
          </div>
          {pScore === "true" && action === "1" && (
            <>
              <PersonalInformation
                profileAllData={profileAllData}
                AllData={AllData}
                userage={userage}
              />
              {viewPatient === "0" ? (
                <div className="no_data">Patient form is not filled yet </div>
              ) : (
                <>
                  <AdultKneePainScoring
                    adultPain={adultPain}
                    AllData={AllData}
                    userage={userage}
                    profileAllData={profileAllData}
                  />
                  <AdultKneeSymtoms
                    adultSymtoms={adultSymtoms}
                    AllData={AllData}
                  />
                  <AdultKneeFunction
                    adultFunction={adultFunction}
                    AllData={AllData}
                    adultNorwich={adultNorwich}
                    initialAssesment={initialAssesment}
                  />
                  <AdultKneeQOL adultQOL={adultQOL} AllData={AllData} />
                 
                    <AdultKneeSatisfaction
                      adultSatisfaction={adultSatisfaction}
                      AllData={AllData}
                    />
                  
                </>
              )}
            </>
          )}
          {pScore === "true" && action === "2" && (
            <AdultKneeDoctor
              doctorFeedback={adultDoctorScore}
              AllData={AllData}
              profileAllData={profileAllData}

            />
          )}

          {management === "true" && (
            <>
              <>
                <PersonalInformation
                  profileAllData={profileAllData}
                  AllData={AllData}
                  userage={userage}
                />
                {viewPatient === "0" ? (
                  <div className="no_data">Patient form is not filled yet </div>
                ) : (
                  <>
                    <AdultKneePainScoring
                      adultPain={adultPain}
                      AllData={AllData}
                      userage={userage}
                      profileAllData={profileAllData}
                    />
                    <AdultKneeSymtoms
                      adultSymtoms={adultSymtoms}
                      AllData={AllData}
                    />
                    <AdultKneeFunction
                      adultFunction={adultFunction}
                      AllData={AllData}
                      adultNorwich={adultNorwich}
                      initialAssesment={initialAssesment}
                    />
                    <AdultKneeQOL adultQOL={adultQOL} AllData={AllData} />
                   
                      <AdultKneeSatisfaction
                        adultSatisfaction={adultSatisfaction}
                        AllData={AllData}
                      />
                 
                  </>
                )}
              </>
            </>
          )}
          {viewDoctor !== "0" && management === "true" && (
            <>
              <AdultKneeDoctor
                doctorFeedback={adultDoctorScore}
                AllData={AllData}
                profileAllData={profileAllData}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}
